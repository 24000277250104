import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { Course } from '../../entities/Course';
import { Lesson } from '../../entities/Lesson';
import BasicToggleButton from '../core/BasicToggleButton/BasicToggleButton';
import { Employee } from '../../entities/Employee';
import { useTranslation } from 'react-i18next';
export interface SelectEntityButtonProps {
  isSelected: boolean;
  entity: Lesson | Course | Employee;
  onChangeSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelectedText?: string;
  notSelectedText?: string;
}

const ToggleButtonStyle = {
  borderRadius: '25px',
  padding: '4px 10px'
};

const SelectEntityButton: React.FC<SelectEntityButtonProps> = ({
  isSelected,
  entity,
  onChangeSelection,
  isSelectedText = 'Deselect',
  notSelectedText = 'Select'
}: SelectEntityButtonProps) => {
  const { t } = useTranslation(['common']);

  return (
    <ButtonGroup>
      <BasicToggleButton
        id={entity.id}
        value={entity.id}
        name={entity.id}
        color={isSelected ? 'alternative' : 'primary'}
        checked={isSelected}
        onChange={onChangeSelection}
        style={ToggleButtonStyle}
        width={'110px'}
        height={'34px'}
      >
        {isSelected &&
          (isSelectedText === 'Deselect'
            ? t('deselect', { ns: 'common' })
            : isSelectedText)}
        {!isSelected &&
          (notSelectedText === 'Select'
            ? t('select', { ns: 'common' })
            : notSelectedText)}
      </BasicToggleButton>
    </ButtonGroup>
  );
};

export default SelectEntityButton;

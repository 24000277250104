import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';

interface ChipSubtitlesProps {
  subtitlesText: string;
  color?: string;
}

export const Subtitles: FC<ChipSubtitlesProps> = ({
  subtitlesText,
  color = '#FFF'
}) => (
  <Stack direction="row" alignItems="center" spacing={0.5}>
    <ArticleIcon fontSize="small" style={{ color: color }} />
    <Typography
      variant="caption"
      gutterBottom
      style={{ color: color, paddingLeft: 5, width: '100px' }}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        wordBreak: 'break-all'
      }}
    >
      {subtitlesText}
    </Typography>
  </Stack>
);

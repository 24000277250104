import { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import {
  OnsiteTrainingClass,
  OnsiteTrainingClassDayPut,
  OnsiteTrainingClassPut
} from '../../../entities/OnsiteTraining';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import ManageUpcomingOnsiteTrainingForm from './ManageUpcomingOnsiteTrainingForm';
import { ApplicationState } from '../../../store';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useAppDispatch } from '../../../store/hooks';

interface ManageUpcomingOnsiteTrainingModalProps {
  onsiteTrainingClass: OnsiteTrainingClass;
  onCloseModal: () => void;
}

const ManageUpcomingOnsiteTrainingModal = ({
  onsiteTrainingClass,
  onCloseModal
}: ManageUpcomingOnsiteTrainingModalProps) => {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['onsiteTraining', 'common']);
  const [showModal, setShowModal] = useState<boolean>(true);
  const modalTitle = `${t('manage', { ns: 'onsiteTraining' })} ${
    onsiteTrainingClass.onsiteTraining.name
  }`;
  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );

  useEffect(() => {
    if (!activeEmployees) {
      const companyId = user?.profile[UserClaims.CompanyId] as string;
      dispatch(employeeActions.requestActiveEmployees(companyId));
    }
  }, []);

  const onsiteTrainingClassToEdit = useMemo((): OnsiteTrainingClassPut => {
    const classDates = onsiteTrainingClass.classDays
      .map(classDay => classDay.classDateTime)
      .sort((date1, date2) => (date1 > date2 ? 1 : -1));

    const nonRemovableMomentClassDates = onsiteTrainingClass.classDays
      .filter(
        day =>
          day.isComplete ||
          (moment(day.classDateTime) <= moment() && !day.isComplete)
      )
      .map(classDay => classDay.classDateTime)
      .sort((date1, date2) => (date1 > date2 ? 1 : -1));

    const nonRemovableClassDates = nonRemovableMomentClassDates.map(date =>
      absUtcDateToLocalMoment(date).startOf('date').toDate()
    );

    const nonRemovableClassDays = nonRemovableMomentClassDates.map(
      (date): OnsiteTrainingClassDayPut => ({
        classDateTime: date,
        IsNotEditable: true
      })
    );

    const nonRemovableClassStartTimes = nonRemovableMomentClassDates.map(date =>
      absUtcDateToLocalMoment(date).format('HH:mm')
    );

    return {
      id: onsiteTrainingClass.id,
      instructorId: onsiteTrainingClass.instructorId,
      location: onsiteTrainingClass.location,
      numberOfDays: onsiteTrainingClass.numberOfDays,
      nonRemovableClassDates: nonRemovableClassDates,
      classDates: classDates.map(date =>
        absUtcDateToLocalMoment(date).startOf('date').toDate()
      ),
      nonRemovableTimes: nonRemovableClassStartTimes,
      classStartTimes: classDates.map(date =>
        absUtcDateToLocalMoment(date).format('HH:mm')
      ),
      classDays: nonRemovableClassDays
    };
  }, [onsiteTrainingClass]);

  const handleCloseModal = () => {
    setShowModal(false);
    onCloseModal();
  };

  return (
    <ModalComponent
      showModal={showModal}
      onClose={handleCloseModal}
      title={modalTitle}
      saveButtonText=""
      savingInProgress={false}
      maxWidth="md"
      showSaveButton={false}
    >
      <ManageUpcomingOnsiteTrainingForm
        onsiteTrainingClassToEdit={onsiteTrainingClassToEdit}
        activeEmployees={activeEmployees}
        onCloseModal={handleCloseModal}
      />
    </ModalComponent>
  );
};

export default ManageUpcomingOnsiteTrainingModal;

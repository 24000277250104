import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router';

import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import HorizontalLine from '../../../components/core/HorizontalLine/HorizontalLine';
import OnsiteTrainingForm from './OnsiteTrainingForm';

const CreateEditOnsiteTraining = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { action } = useParams<{ action: string }>();
  const { t } = useTranslation(['onsiteTraining']);

  return (
    <Grid container direction="column">
      <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Grid container direction={{ xs: 'column', sm: 'row' }}>
          <Grid item xs={6} md={4}>
            <GoBackOption
              textLink={
                location.state?.prevPath?.includes('/onsite-training-preview')
                  ? t('backToPreviewPage')
                  : t('backToOnsiteTraining')
              }
              onGoBack={() => navigate(-1)}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={4}
            sx={{ textAlign: { xs: 'left', sm: 'center' } }}
          >
            <Typography sx={theme.typography.h5}>
              {action === 'create'
                ? t('newOnsiteTraining')
                : t('editOnsiteTraining')}
            </Typography>
          </Grid>
          <Grid item xs={0} md={4}></Grid>
        </Grid>
      </Grid>
      <Grid item>
        <HorizontalLine color={theme.palette.grey[100]} line={2} />
      </Grid>
      <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
        <OnsiteTrainingForm
          actionType={action as 'create' | 'edit'}
          onsiteTrainingToEdit={location.state?.onsiteTraining}
        />
      </Grid>
    </Grid>
  );
};

export default CreateEditOnsiteTraining;

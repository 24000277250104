import { Grid } from '@mui/material';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';
import { AssignedOnsiteTrainingClass } from '../../../entities/LearningPlaylist';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { actionCreators as studentActions } from '../../../actions/student';
import { useNavigate } from 'react-router';
import { ApplicationState } from '../../../store';
import { OnsiteTrainingAttendanceType } from '../../../core/enums';
import { useAppDispatch } from '../../../store/hooks';

export const OnsiteTrainingClassDetailsBottomActions = ({
  onsiteTrainingClass
}: {
  onsiteTrainingClass: AssignedOnsiteTrainingClass;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['onsiteTraining']);
  const currentDate = moment().format('MM.DD.YYYY');
  const todayAssignment = onsiteTrainingClass.assignments.find(item => {
    const formattedDate = absUtcDateToLocalMoment(item.dateUtc).format(
      'MM.DD.YYYY'
    );
    return formattedDate === currentDate;
  });
  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const isBeforeCheckTime = todayAssignment
    ? moment().isBefore(absUtcDateToLocalMoment(todayAssignment.dateUtc)) &&
      !todayAssignment.isComplete
    : false;

  const alreadyRequestedAttendance =
    todayAssignment?.attendance === OnsiteTrainingAttendanceType.Attended ||
    todayAssignment?.attendance === OnsiteTrainingAttendanceType.Requested;
  const handleCheckIn = () => {
    if (todayAssignment && todayAssignment.assignmentId) {
      dispatch(
        studentActions.performOnsiteTrainingAssignmentCheckIn(
          todayAssignment.assignmentId,
          navigate
        )
      );
    }
  };

  return (
    <Grid container style={{ padding: '50px 20px 30px 20px' }} gap={1}>
      <Grid item>
        <BasicButton
          disable={
            isBeforeCheckTime || studentIsLoading || alreadyRequestedAttendance
          }
          onClick={() => {
            handleCheckIn();
          }}
          color="primary"
          style={{ width: '155px' }}
        >
          {t('onsiteTrainingClassCheckIn')}
        </BasicButton>
      </Grid>
    </Grid>
  );
};

import { CSSProperties, FC } from 'react';
import { Grid, Typography } from '@mui/material';
import BasicButton from '../core/BasicButton/BasicButton';
import FreeTrialBWIcon from '../../assets/freeTrialBWIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useUserRole from '../../hooks/useUserRole';
import { UserRoles } from '../../core/constants';

export interface FreeTrialProps {
  daysRemaining?: number;
  employees?: number;
}

const FreeTrialNav: FC<FreeTrialProps> = ({ daysRemaining, employees }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['registerEmployee']);
  const [userHasRole] = useUserRole();
  const isCompanyAdmin = userHasRole(UserRoles.CompanyAdmin);

  return (
    <div style={freeTrialDiv}>
      <Grid container style={containerGrid}>
        <Grid xs={4} sm={4} md={4} style={leftSection}>
          <img
            src={FreeTrialBWIcon}
            style={{ margin: '5px 10px 2px 5px' }}
            alt="Black icon check"
          />
          <Typography style={fontFreeTrialStyle}>
            {t('freeTrial', { ns: 'registerEmployee' })}
          </Typography>
        </Grid>
        <Grid xs={8} sm={8} md={8} style={rightSection}>
          <Typography style={fontRightStyle}>
            <span style={boldText}>{daysRemaining}</span>{' '}
            {t('daysRemaining', { ns: 'registerEmployee' })}
          </Typography>
          <div style={vl}></div>
          <Typography style={fontRightStyle}>
            <span style={boldText}>{employees}</span>{' '}
            {t('employeesLabel', { ns: 'registerEmployee' })}
          </Typography>
          {isCompanyAdmin && (
            <BasicButton
              color="trial"
              style={paddingElements}
              onClick={() => {
                navigate(`/trial/add-employee`);
              }}
            >
              <Typography style={fontButtonStyle}>
                {t('addEmployeesLabel', { ns: 'registerEmployee' })}
              </Typography>
            </BasicButton>
          )}
          <BasicButton
            color="trial"
            style={paddingElements}
            onClick={() => {
              window.open(
                'https://info.buildwitt.com/knowledge/getting-started'
              );
            }}
          >
            <Typography style={fontButtonStyle}>
              {t('viewTutorials', { ns: 'registerEmployee' })}
            </Typography>
          </BasicButton>
          {/* <BasicButton color="trial">
            <Typography style={fontButtonStyle}>{t('supportLabel', { ns: 'registerEmployee' })}</Typography>
            <ChatBubbleOutlineIcon
              fontSize="small"
              style={{ marginLeft: '5px', color: '#0E0D0D' }}
            />
          </BasicButton> */}
        </Grid>
      </Grid>
    </div>
  );
};

const freeTrialDiv: CSSProperties = {
  backgroundColor: '#FF9E00',
  position: 'fixed',
  zIndex: 3,
  top: 0,
  width: '100%',
  height: '50px'
};

const containerGrid: CSSProperties = {
  paddingTop: '5px'
};

const paddingElements: CSSProperties = {
  marginRight: 5,
  marginLeft: 15
};

const fontFreeTrialStyle: CSSProperties = {
  color: '#0E0D0D',
  fontSize: '18px',
  fontWeight: 'bold',
  paddingTop: '3px'
};

const fontRightStyle: CSSProperties = {
  color: '#0E0D0D',
  fontSize: '16px',
  fontWeight: 400
};

const fontButtonStyle: CSSProperties = {
  color: '#0E0D0D',
  fontSize: '16px'
};

const rightSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingRight: 15
};

const leftSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingLeft: 15
};

const vl: CSSProperties = {
  borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
  height: '20px',
  marginRight: '15px',
  marginLeft: '15px'
};

const boldText: CSSProperties = {
  color: '#0E0D0D',
  fontSize: '18px',
  fontWeight: 'bolder'
};

export default FreeTrialNav;

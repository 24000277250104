import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import { useAnalyticsEngagementModal } from '../../../hooks/useAnalyticsEngagementModal';
import EmployeeCompletedAssignmentList from './EmployeeCompletedAssignmentList';

interface EmployeeCompletedAssignmentsModalProps {
  employeeId?: string;
  employeeName?: string;
  onCloseModal: () => void;
}

const EmployeeCompletedAssignmentsModal = ({
  employeeId,
  employeeName,
  onCloseModal
}: EmployeeCompletedAssignmentsModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { sortOptions, openModal, employeeCompletedAssignmentsData },
    api: { closeModal, handleSorting, handleDownloadReport }
  } = useAnalyticsEngagementModal(
    'employeeCompletedAssignments',
    undefined,
    employeeId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t(`totalCompletedAssignments`)}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
        onDownloadReport={handleDownloadReport}
        subtitle={employeeName}
      >
        <EmployeeCompletedAssignmentList
          completedAssignmentsData={employeeCompletedAssignmentsData}
        />
      </AssignmentStatusModal>
    </>
  );
};

export default EmployeeCompletedAssignmentsModal;

import { useEffect, useState, useContext } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { HaltsDataGrid } from '../../../components/Settings';
import { getFullNameColumnCalendar } from './utilSettingsColumnDefinition';
import { getButtonColumn } from '../../analytics/common/utilAnalyticsColumnDefinition';
import { actionCreators as haltActions } from '../../../actions/halts';
import { ApplicationState } from '../../../store';
import { HaltEmployee } from '../../../entities/Halt';
import { SettingsContext } from '../../../contexts/SettingsContext';
import RemoveAllSelectedComp from './RemoveAllSelectedComp';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';
interface EmployeeHaltsProps {
  searchKey: string;
  mode: 'todaysHalts' | 'pickedDateHalts';
}

const EmployeeHalts = ({ searchKey, mode }: EmployeeHaltsProps) => {
  const dispatch = useAppDispatch();
  const { initDeleteHalt } = useContext(SettingsContext);
  const { t } = useTranslation(['settings']);
  const [halts, setHalts] = useState<HaltEmployee[]>();
  const todayHalts = useSelector(
    (state: ApplicationState) => state.halts?.todayHalts
  );
  const pickedCalendarDateHalts = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDateHalts
  );
  const pickedCalendarDate = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDate
  );
  const [showBulkRemoveButtons, setShowBulkRemoveButtons] =
    useState<boolean>(false);
  useEffect(() => {
    if (!todayHalts && mode === 'todaysHalts') {
      dispatch(haltActions.getTodayHalts());
    }
    if (pickedCalendarDate && mode === 'pickedDateHalts') {
      dispatch(haltActions.getHaltsByDate(pickedCalendarDate!));
    }

    return () => {
      setHalts(undefined);
    };
  }, [pickedCalendarDate]);

  useEffect(() => {
    if (todayHalts && mode === 'todaysHalts') {
      const updatedHalts = todayHalts.map(halt => {
        return { ...halt, isSelected: false };
      });
      setHalts(updatedHalts);
      setShowBulkRemoveButtons(!!todayHalts?.length);
    }

    if (pickedCalendarDateHalts && mode === 'pickedDateHalts') {
      const updatedHalts = pickedCalendarDateHalts.map(halt => {
        return { ...halt, isSelected: false };
      });
      setHalts(updatedHalts);
      setShowBulkRemoveButtons(!!pickedCalendarDateHalts?.length);
    }
  }, [todayHalts, pickedCalendarDateHalts]);

  useEffect(() => {
    if (
      (!todayHalts && mode === 'todaysHalts') ||
      (!pickedCalendarDateHalts && mode === 'pickedDateHalts')
    )
      return;

    const source =
      mode === 'todaysHalts' ? todayHalts : pickedCalendarDateHalts;
    setTimeout(() => {
      const key = searchKey.toLowerCase();
      const filteredData = source!.filter(
        halt =>
          `${halt.firstName} ${halt.lastName}`.toLowerCase().includes(key) ||
          (halt.email ?? '').includes(key) ||
          (halt.phoneNumber ?? '').includes(key)
      );
      setHalts(filteredData);
    }, 1000);
  }, [searchKey]);

  const handleInitDeleteHalt = async (params: GridRenderCellParams<any>) => {
    if (initDeleteHalt) initDeleteHalt([params.row.id], mode);
  };
  const handleChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let updatedHalts: HaltEmployee[] | undefined = [];
    updatedHalts = halts?.map(halt => {
      if (halt.id === id) {
        return { ...halt, isSelected: checked };
      }
      return halt;
    });
    setHalts(updatedHalts);
  };
  const columns: GridColDef[] = [
    getFullNameColumnCalendar('', handleChangeCheckBox, true),
    getButtonColumn(
      'id',
      '',
      t('removeHalt', { ns: 'settings' }),
      150,
      handleInitDeleteHalt
    )
  ];
  const handleChangeSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (halts) {
      const updatedHalts = halts?.map(halt => {
        return { ...halt, isSelected: checked };
      });
      setHalts(updatedHalts);
    }
  };
  const removeAllFunc = () => {
    const haltsToDelete = halts
      ?.filter(halt => halt.isSelected)
      .map(halt => halt.id);
    if (haltsToDelete?.length) {
      if (initDeleteHalt) initDeleteHalt(haltsToDelete!, mode);
      return;
    }
    enqueueSnackbar(t('noHaltsSelected', { ns: 'settings' }), {
      variant: 'error'
    });
  };
  return (
    <>
      {showBulkRemoveButtons && (
        <RemoveAllSelectedComp
          removeAllFunc={removeAllFunc}
          handleChangeSelectAll={handleChangeSelectAll}
        />
      )}

      <HaltsDataGrid
        rowId="id"
        rows={halts}
        columns={columns}
        noRowsMessage={t('noHaltsToDisplay', { ns: 'settings' })}
      />
    </>
  );
};

export default EmployeeHalts;

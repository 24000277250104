import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

interface EmployeesCertificationsModalProps {
  showModal: boolean;
  title: string;
  onCloseModal: () => void;
  children: React.ReactNode;
  maxWidth: 'sm' | 'md' | 'lg';
  height: string;
  closeWhenClickBackdrop?: boolean;
  backdropColor?: string;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    border: 1px solid #999;
    border-radius: 5px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const EmployeesCertificationsModal = ({
  showModal,
  title,
  onCloseModal,
  children,
  maxWidth,
  height,
  closeWhenClickBackdrop = false,
  backdropColor = 'rgba(0, 0, 0, 0.5)'
}: EmployeesCertificationsModalProps) => {
  const theme = useTheme();
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={closeWhenClickBackdrop ? onCloseModal : undefined}
      maxWidth={maxWidth}
      fullWidth={true}
      sx={{
        '& .MuiPaper-root': {
          height: height
        }
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: backdropColor
          }
        }
      }}
    >
      <DialogTitle>
        {title}
        <IconButton
          size="large"
          aria-label="close"
          onClick={onCloseModal}
          sx={{ ...iconStyle }}
        >
          <CancelIcon fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </CustomDialog>
  );
};
export default EmployeesCertificationsModal;

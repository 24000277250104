import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import { Lesson } from '../../../entities/Lesson';
import {
  LessonFormModel,
  ContentMedium,
  SubtitleEditMode,
  NewLessonType
} from '../../../entities/LessonFormModel';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import FormLabel from '../../core/FormLabel/FormLabel';
import { Tag } from '../../../entities/Tag';
import { Guid } from 'guid-typescript';
import { ProgressBar } from '../../core/ProgressBar/ProgressBar';
import { truncateAzureUrl } from '../../../utils/stringUtils';
import LessonBackground from '../../../assets/LessonBackground.png';
import HttpStatusError from '../../../core/CustomErrors/HttpRequestError';
import { HttpStatusEnum } from '../../../core/enums';
import { FormErrors } from '../../core/FormErrors/FormErrors';
import ThumbnailLibraryPanel from '../../ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../../entities/AzureBlobInfo';
import {
  getYouTubeVideoIdByUrl,
  validateYouTubeVideoId
} from '../../../utils/matchYoutubeUrl';
import LessonTypeOptions from './LessonTypeOptions';
import FooterActionButtons from './FooterActionButtons';
import LessonInputsForm from './LessonInputsForm';
import {
  isValidURL,
  IsValidVideoFormat,
  IsValidAudioFormat
} from '../../../utils/validationsUtils';
import AssessmentSection, { QuestionEditMode } from './AssessmentSection';
import {
  LessonVideo,
  LessonQuiz,
  LessonImage,
  LessonAudio
} from '../../../core/constants';
import { Question } from '../../../entities/Assessment';
import { Checkbox, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  convertDurationInSecondsToHHmm,
  convertHHmmToSecondsInt
} from '../../../utils/lessonUtils';
import config from '../../../config';
const emptyformModel = {
  id: '',
  title: '',
  description: '',
  contentMedium: 0,
  thumbnailFile: null,
  thumbnailUrl: '',
  video: null,
  videoUrl: '',
  lessonFile: null,
  lessonFileUrl: '',
  audioFile: null,
  audioUrl: '',
  externalVideoUrl: '',
  tags: [],
  subtitleFile: null,
  subtitles: [],
  attachments: [],
  deletedAttachments: [],
  savedAttachments: [],
  visibleForAdminsOnly: false,
  lessonDuration: '00:00',
  lessonCode: ''
};
export interface LessonFormProps {
  lesson: Lesson;
  companyTags: Array<Tag>;
  handleSaveLesson: (
    lessonModel: LessonFormModel,
    isEdit: boolean,
    attachments: File[]
  ) => Promise<string | undefined>;
  handleUpdateLesson: (
    lessonModel: LessonFormModel,
    isEdit: boolean,
    attachments: File[]
  ) => Promise<void>;
  handleSaveLessonAssessment: (
    questions: Question[],
    correctAnswers: number,
    lessonId: string,
    isEditing: boolean,
    updateAssessmentId: boolean,
    assessmentId?: string
  ) => Promise<void>;
  onSaveComplete: () => void;
  isEdit: boolean;
  showProgress?: boolean;
  progressValue?: number;
  setIsConfirmationModalOpen?: (isOpen: boolean) => void;
}

let subtitleCounter = 0;

export const getNextSubtitleId = () => {
  return `subtitle-${subtitleCounter++}`;
};

const LessonForm: React.FC<LessonFormProps> = ({
  lesson,
  companyTags,
  handleSaveLesson,
  handleUpdateLesson,
  handleSaveLessonAssessment,
  onSaveComplete,
  isEdit,
  showProgress = false,
  progressValue = 0,
  setIsConfirmationModalOpen
}) => {
  const [lessonData, setLessonData] = useState<LessonFormModel>(emptyformModel);
  const [contentMedium, setContentMedium] = useState<number>(
    ContentMedium.Video
  );
  const [showYoutubeInput, setShowYoutubeInput] = useState<boolean>(false);
  const [fileError, setFileError] = useState<boolean>(false);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [tags, setTags] = useState<Array<Tag>>([]);
  const [subtitles, setSubtitles] = useState<SubtitleEditMode[]>([]);
  const [questions, setQuestions] = useState<QuestionEditMode[]>([]);
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [assessmentValidationMsg, setAssessmentValidationMsg] =
    useState<string>('');
  const questionCounterRef = useRef<number>(0);
  const { t } = useTranslation(['common', 'lessons']);
  const [isVisibleForAdminsOnly, setIsVisibleForAdminsOnly] =
    useState<boolean>(false);
  const [acceptUploadPolicy, setAcceptUploadPolicy] = useState<boolean>(isEdit);

  const mapLessonToModel = (lesson: Lesson) => {
    const res: LessonFormModel = {
      id: lesson.id,
      title: lesson.title,
      description: lesson.description,
      contentMedium: lesson.contentMedium,
      thumbnailFile: null,
      thumbnailUrl: lesson.thumbnailUrl,
      video: null,
      videoUrl: lesson.videoUrl,
      lessonFile: null,
      lessonFileUrl: lesson.learningMaterialUrl,
      audioFile: null,
      audioUrl: lesson.audioUrl,
      externalVideoUrl: lesson.externalVideoUrl,
      tags: lesson.tags,
      assessmentId: lesson.assessmentId,
      subtitles: lesson.subtitles,
      attachments: [],
      savedAttachments: lesson.attachments,
      deletedAttachments: [],
      visibleForAdminsOnly: lesson.visibleForAdminsOnly,
      lessonDuration: convertDurationInSecondsToHHmm(lesson.lessonDuration),
      lessonCode: lesson.lessonCode,
      hlsUrl: lesson.hlsUrl
    };
    return res;
  };

  useEffect(() => {
    if (lesson) {
      const formData = mapLessonToModel(lesson);
      setLessonData(formData);
      if (lesson.externalVideoUrl) {
        setShowYoutubeInput(true);
      }
      if (lesson.contentMedium === ContentMedium.File) {
        setContentMedium(ContentMedium.File);
      }
      if (lesson.contentMedium === ContentMedium.Quiz) {
        setContentMedium(ContentMedium.Quiz);
      }
      if (lesson.contentMedium === ContentMedium.Audio) {
        setContentMedium(ContentMedium.Audio);
      }
      setTags(lesson.tags ? lesson.tags : []);
      setIsVisibleForAdminsOnly(lesson.visibleForAdminsOnly);
    }
  }, [lesson, lesson.id]);

  useEffect(() => {
    if (lesson?.subtitles) {
      const subtitlesToEdit = lesson.subtitles!.map(q => {
        return { ...q, _id: getNextSubtitleId() };
      });
      setSubtitles(subtitlesToEdit);
    }
  }, [lesson?.subtitles]);

  useEffect(() => {
    if (lesson.questions) {
      const questionsToEdit = lesson.questions.map(q => {
        return { ...q, _id: getNextQuestionId() };
      });
      setQuestions(questionsToEdit);
      if (lesson.lessonType === LessonQuiz) {
        setCorrectAnswers(lesson.correctAnswersToPass ?? 0);
      }
    }
  }, [lesson.questions, lesson.correctAnswersToPass, lesson.lessonType]);

  const getNextQuestionId = () => {
    questionCounterRef.current = questionCounterRef.current + 1;
    return `question-${questionCounterRef.current}`;
  };

  const handleChangeContentMedium = (showYoutubeField: boolean) => (e: any) => {
    setContentMedium(parseInt(e.target.value));
    setShowYoutubeInput(showYoutubeField);
    setLessonData({ ...lessonData, contentMedium: parseInt(e.target.value) });
  };

  const isValidYouTubeVideo = async (lessonData: LessonFormModel) => {
    lessonData.youTubeVideoId = undefined;
    const ytId = getYouTubeVideoIdByUrl(lessonData.externalVideoUrl);
    if (!ytId) {
      return false;
    }

    const response = await validateYouTubeVideoId(ytId);
    const idFromResponse = response.items[0]?.id;

    if (!idFromResponse) {
      return false;
    }

    lessonData.youTubeVideoId = ytId;
    return true;
  };

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    return validImageTypes.includes(fileType);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, thumbnailFile: file, thumbnailUrl: '' });
      const fileType = file['type'];
      if (IsValidImageFormat(fileType)) {
        setFileError(false);
      } else {
        setFileError(true);
      }
    } else {
      setFileError(true);
    }
  };
  const validImageFormats = [
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/jfif',
    'image/bmp'
  ];

  const validateLessonBeforeSaving = async (
    subType: (string | null)[] | undefined
  ) => {
    if (
      lessonData &&
      lessonData.title &&
      (lessonData.thumbnailFile === null
        ? LessonBackground
        : (lessonData.thumbnailFile &&
            IsValidImageFormat(lessonData.thumbnailFile.type)) ||
          lessonData.thumbnailUrl) &&
      subType?.length === 0 &&
      ((lessonData.contentMedium === ContentMedium.File &&
        ((lessonData.lessonFile &&
          (lessonData.lessonFile.type === 'application/pdf' ||
            validImageFormats.includes(lessonData.lessonFile!.type))) ||
          lessonData.lessonFileUrl)) ||
        (lessonData.contentMedium === ContentMedium.Video &&
          ((!showYoutubeInput &&
            lessonData.video &&
            IsValidVideoFormat(lessonData.video.type)) ||
            lessonData.videoUrl ||
            lessonData.hlsUrl ||
            (showYoutubeInput &&
              lessonData.externalVideoUrl &&
              isValidURL(lessonData.externalVideoUrl) &&
              (await isValidYouTubeVideo(lessonData))))) ||
        (lessonData.contentMedium === ContentMedium.Audio &&
          ((lessonData.audioFile &&
            IsValidAudioFormat(lessonData.audioFile.type)) ||
            lessonData.audioUrl ||
            lessonData.hlsUrl))) &&
      !fileError
    ) {
      if (!acceptUploadPolicy) {
        return false;
      }
      return true;
    }
    return false;
  };

  const displayFormLessonErrors = (subType: (string | null)[] | undefined) => {
    const fieldErrorsArray: string[] = [];
    const formErrorsArray: string[] = [];

    if (!lessonData.title) {
      fieldErrorsArray.push(t('titleRequired', { ns: 'common' }));
    }

    if (
      lessonData.thumbnailFile &&
      !IsValidImageFormat(lessonData.thumbnailFile.type) &&
      !lessonData.thumbnailUrl
    ) {
      formErrorsArray.push(t('invalidImageFormat', { ns: 'common' }));
    }

    if (subType!.length > 0) {
      formErrorsArray.push(t('invalidSubtitleFormat', { ns: 'common' }));
    }

    if (
      lessonData.contentMedium === ContentMedium.Video &&
      !showYoutubeInput &&
      lessonData.video &&
      !IsValidVideoFormat(lessonData.video.type)
    ) {
      formErrorsArray.push(t('videoInvalidFormatMsg', { ns: 'common' }));
    }

    if (
      lessonData.contentMedium === ContentMedium.Video &&
      !showYoutubeInput &&
      !lessonData.videoUrl &&
      !lessonData.hlsUrl &&
      !lessonData.video
    ) {
      fieldErrorsArray.push(t('videoFileRequired', { ns: 'common' }));
    }

    if (
      lessonData.contentMedium === ContentMedium.Video &&
      showYoutubeInput &&
      !lessonData.externalVideoUrl
    ) {
      fieldErrorsArray.push(t('youtubeUrlIsRequired', { ns: 'common' }));
    }

    if (
      lessonData.contentMedium === ContentMedium.Video &&
      lessonData.externalVideoUrl &&
      !lessonData.youTubeVideoId
    ) {
      fieldErrorsArray.push(t('youtubeID', { ns: 'common' }));
    }
    if (
      lessonData.contentMedium === ContentMedium.Audio &&
      lessonData.audioFile &&
      !IsValidAudioFormat(lessonData.audioFile.type)
    ) {
      fieldErrorsArray.push(t('audioInvalidFormatMsg', { ns: 'common' }));
    }

    if (
      lessonData.contentMedium === ContentMedium.Audio &&
      !lessonData.audioFile
    ) {
      fieldErrorsArray.push(t('audioFileRequired', { ns: 'common' }));
    }

    if (assessmentValidationMsg.length) {
      formErrorsArray.push(assessmentValidationMsg);
    }

    if (!acceptUploadPolicy) {
      fieldErrorsArray.push(t('acceptUploadPolicyWarning', { ns: 'lessons' }));
    }
    setFieldErrors(fieldErrorsArray);
    setFormErrors(formErrorsArray);

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const getSubtitleDataForValidation = () => {
    const infoSub = lessonData.subtitles?.map(sub =>
      sub.fileType !== 'text/vtt' && sub.fileType !== undefined
        ? sub.fileType
        : ''
    );

    const clean = (arr: (string | null)[] | undefined) =>
      arr?.reduce((acc: (string | null)[] | undefined, el: string | null) => {
        if (el) {
          acc?.push(el);
        }
        return acc;
      }, []);

    return clean(infoSub);
  };

  const handleSaveAssessment = async (lessonId: string) => {
    let isEditing = false;
    let updateAssessmentIdInLesson: boolean =
      contentMedium === ContentMedium.Quiz ||
      contentMedium === ContentMedium.File ||
      contentMedium === ContentMedium.Video ||
      contentMedium === ContentMedium.Audio;

    if (isEdit) {
      isEditing = !!lesson.assessmentId && lesson.assessmentId !== Guid.EMPTY;
      updateAssessmentIdInLesson =
        lesson.lessonContentType ===
          (NewLessonType.Quiz ||
            NewLessonType.File ||
            NewLessonType.Video ||
            NewLessonType.Audio) &&
        (!lesson.assessmentId || lesson.assessmentId === Guid.EMPTY);
    }

    return await handleSaveLessonAssessment(
      questions,
      correctAnswers,
      lessonId,
      isEditing,
      updateAssessmentIdInLesson,
      lesson.assessmentId
    );
  };

  const handleSave = async (lessonData: LessonFormModel) => {
    const subType = getSubtitleDataForValidation();

    try {
      setFieldErrors([]);
      setFormErrors([]);
      const validLesson = await validateLessonBeforeSaving(subType);

      if (validLesson && !assessmentValidationMsg.length) {
        setSaveInProgress(true);

        if (lessonData.externalVideoUrl && lessonData.youTubeVideoId) {
          lessonData.externalVideoUrl = `https://www.youtube.com/watch?v=${lessonData.youTubeVideoId}`;
        }

        if (isEdit) {
          await handleUpdateLesson(
            {
              ...lessonData,
              tags: tags,
              visibleForAdminsOnly: isVisibleForAdminsOnly,
              lessonDuration: convertHHmmToSecondsInt(lessonData.lessonDuration)
            },
            true,
            []
          );
          await handleSaveAssessment(lesson.id);
        } else {
          const createdLessonId = await handleSaveLesson(
            {
              ...lessonData,
              tags: tags,
              visibleForAdminsOnly: isVisibleForAdminsOnly,
              lessonDuration: convertHHmmToSecondsInt(lessonData.lessonDuration)
            },
            false,
            []
          );
          if (questions.length && createdLessonId) {
            await handleSaveAssessment(createdLessonId!);
          }
        }

        setSaveInProgress(false);
        onSaveComplete();
      } else {
        displayFormLessonErrors(subType);
      }
    } catch (e) {
      if (e instanceof HttpStatusError) {
        if (e.status === HttpStatusEnum.Conflict) {
          setFormErrors([e.message]);
        }
      } else {
        setFormErrors([t('anErrorOccurred', { ns: 'common' })]);
      }
      setSaveInProgress(false);
    }
  };

  const handleSaveQuiz = async (lessonData: LessonFormModel) => {
    try {
      setFieldErrors([]);
      setFormErrors([]);
      if (
        lessonData &&
        lessonData.title &&
        lessonData.contentMedium &&
        !assessmentValidationMsg.length &&
        acceptUploadPolicy
      ) {
        setSaveInProgress(true);

        const lessonToSave = {
          ...lessonData,
          visibleForAdminsOnly: isVisibleForAdminsOnly,
          lessonDuration: convertHHmmToSecondsInt(lessonData.lessonDuration),
          assessmentId:
            lessonData.assessmentId && lessonData.assessmentId !== Guid.EMPTY
              ? lessonData.assessmentId
              : Guid.EMPTY
        };

        if (isEdit) {
          await handleUpdateLesson(lessonToSave, true, []);
          await handleSaveAssessment(lesson.id);
        } else {
          const createdLessonId = await handleSaveLesson(
            lessonToSave,
            true,
            []
          );
          if (createdLessonId) {
            await handleSaveAssessment(createdLessonId!);
          }
        }

        setSaveInProgress(false);
        onSaveComplete();
      } else {
        const errorsArray: string[] = [];
        if (!lessonData.title) {
          errorsArray.push(t('titleRequired', { ns: 'common' }));
        }
        if (assessmentValidationMsg.length) {
          errorsArray.push(assessmentValidationMsg);
        }
        if (!acceptUploadPolicy) {
          errorsArray.push(t('acceptUploadPolicyWarning', { ns: 'lessons' }));
        }
        setFormErrors(errorsArray);
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    } catch (e) {
      if (e instanceof HttpStatusError) {
        if (e.status === HttpStatusEnum.Conflict) {
          setFormErrors([e.message]);
        }
      } else {
        setFormErrors([t('anErrorOccurred', { ns: 'common' })]);
      }

      setSaveInProgress(false);
    }
  };

  const handleLibraryItemSelected = (item: AzureBlobImageInfo) => {
    if (item) {
      setLessonData({
        ...lessonData,
        thumbnailFile: null,
        thumbnailUrl: item.url
      });
    }
  };

  const getLessonType = (cMedium: number) => {
    if (cMedium === ContentMedium.Video) {
      return LessonVideo;
    } else if (cMedium === ContentMedium.Quiz) {
      return LessonQuiz;
    } else if (cMedium === ContentMedium.Audio) {
      return LessonAudio;
    } else {
      return LessonImage;
    }
  };

  return (
    <Grid container id="lessonFormContainer">
      <Grid item xs={12}>
        {!!fieldErrors.length && <FormErrors errors={fieldErrors} />}
      </Grid>
      <Grid item xs={12}>
        {!!formErrors.length && (
          <FormErrors
            errors={formErrors}
            title={t('followingErrorsWerefound', { ns: 'common' })}
          />
        )}
      </Grid>
      <Grid item>
        <FormLabel>
          {t('contentMedium', { ns: 'common' })}
          <span className="text-danger">*</span>
        </FormLabel>
        <LessonTypeOptions
          contentMedium={contentMedium}
          onChangeContentMedium={handleChangeContentMedium}
          disabled={isEdit || saveInProgress}
          showYoutubeInput={showYoutubeInput}
        />
      </Grid>
      <Grid
        item
        style={{ paddingLeft: '10px !important' }}
        id="ovContainer"
        xs={12}
        md={12}
        lg={12}
      >
        <Stack direction="row" spacing={1} id="ovContainer2">
          <FormControlLabel
            control={
              <Checkbox
                id="visibleForAdminsOnly"
                name="visibleForAdminsOnly"
                checked={isVisibleForAdminsOnly}
                onChange={() =>
                  setIsVisibleForAdminsOnly(!isVisibleForAdminsOnly)
                }
                data-testid="onlyAdminsCheckbox"
                data-trackid="onlyAdminsCheckbox"
              />
            }
            label={t('hideLesson', { ns: 'lessons' })}
          />
        </Stack>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LessonInputsForm
            lessonData={lessonData}
            saveInProgress={saveInProgress}
            contentMedium={contentMedium}
            tags={tags}
            companyTags={companyTags}
            subtitles={subtitles}
            showYoutubeInput={showYoutubeInput}
            setSubtitles={setSubtitles}
            setLessonData={setLessonData}
            setTags={setTags}
            getNextSubtitleId={getNextSubtitleId}
            onFilesUpdated={(files: File[]) => {
              setLessonData({ ...lessonData, attachments: files });
            }}
            onFileRemoved={(fileName: string) => {
              const dAttch = lessonData.deletedAttachments.slice();
              dAttch.push(fileName);
              setLessonData({ ...lessonData, deletedAttachments: dAttch });
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {(contentMedium === ContentMedium.Video ||
            contentMedium === ContentMedium.Audio ||
            contentMedium === ContentMedium.File) && (
            <>
              <FormLabel>{t('lessonThumbnail', { ns: 'lessons' })}</FormLabel>
              <Box maxHeight="600px" sx={{ height: 'calc(100% - 25px)' }}>
                <ThumbnailLibraryPanel
                  onChange={handleChangeFile}
                  thumbnailUrl={lessonData.thumbnailUrl}
                  onItemSelected={(item: AzureBlobImageInfo) => {
                    handleLibraryItemSelected(item);
                  }}
                  maxContentHeight="430px"
                />
                {lessonData.thumbnailUrl && (
                  <span data-testid="lessonThumbnailUrl">
                    {truncateAzureUrl(lessonData.thumbnailUrl)}
                  </span>
                )}
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} paddingTop="2rem">
        <FormLabel>
          {contentMedium === ContentMedium.Quiz
            ? t('lessonQuiz', { ns: 'lessons' })
            : t('lessonAssessment', { ns: 'lessons' })}
        </FormLabel>
      </Grid>
      <Grid item xs={12} paddingBottom="1.5rem">
        <AssessmentSection
          lesson={lesson}
          lessonType={getLessonType(contentMedium)}
          questions={questions}
          correctAnswersNumber={correctAnswers}
          setQuestions={setQuestions}
          setAssessmentValidationMsg={setAssessmentValidationMsg}
          setCorrectAnswers={setCorrectAnswers}
          getNextQuestionId={getNextQuestionId}
        />
      </Grid>
      <Grid item xs={12}>
        {showProgress &&
          saveInProgress &&
          (lessonData.video || lessonData.audioFile) && (
            <ProgressBar
              progress={progressValue}
              text={t('uploadProgress', { ns: 'common' })}
            />
          )}
      </Grid>
      <Grid
        container
        item
        xs={12}
        display={'flex'}
        direction={'column'}
        style={{ marginBottom: '20px' }}
        spacing={'10px'}
      >
        <Grid item>
          <Typography style={{ fontSize: '18px', color: '#C4C4C4' }}>
            {t('uploadPolicyText', { ns: 'lessons' })}
            <a
              href={`${config.UPLOADPOLICYLINK!}`}
              style={{ textDecoration: 'underline', color: '#0097BF' }}
              target="_blank"
              rel="noreferrer"
            >
              {t('uploadPolicy', { ns: 'lessons' })}
            </a>
            ?<span className="text-danger">*</span>
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            style={{ paddingLeft: '15px' }}
            control={
              <Checkbox
                id="agreeTerms"
                data-testid="acceptLessonPolicyCheckbox"
                data-trackid="acceptLessonPolicyCheckbox"
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
                checked={acceptUploadPolicy}
                size="medium"
                onChange={() => setAcceptUploadPolicy(!acceptUploadPolicy)}
              />
            }
            label={t('yes', { ns: 'lessons' })}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} justifyContent="space-between" display="flex">
        <FooterActionButtons
          lessonData={lessonData}
          isEdit={isEdit}
          contentMedium={contentMedium}
          saveInProgress={saveInProgress}
          questionsInQuiz={questions.length}
          onSetIsConfirmationModalOpen={setIsConfirmationModalOpen}
          onSaveQuizLesson={handleSaveQuiz}
          onSaveVideoLesson={handleSave}
        />
      </Grid>
      <Grid item xs={12} paddingTop="2rem"></Grid>
    </Grid>
  );
};

export default LessonForm;

import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { defaultTheme } from '@buildwitt/component-library';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface ChipAverageTimeProps {
  showComplete: boolean;
  averageLessonTime: string;
}

const ChipAverageTime: FC<ChipAverageTimeProps> = ({
  showComplete,
  averageLessonTime
}) => {
  const { t } = useTranslation(['lessons']);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Stack direction="row" spacing={1}>
        <Chip
          color="secondary"
          label={
            showComplete
              ? `${t('lessonOrQuiz', { ns: 'lessons' })} - ${averageLessonTime}`
              : averageLessonTime
          }
          size="small"
        />
      </Stack>
    </ThemeProvider>
  );
};

export default ChipAverageTime;

import { useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { StyledCompetenceDetailContainer } from './SkillComponentsHelper';
import MySkillDetail from './MySkillDetail';
import MyCertificateDetail from './MyCertificateDetail';
import { CompetenceLearningUnitType } from '../../core/enums';

const MyCompetenceDetailFactory = () => {
  const selectedCompetence = useSelector(
    (state: ApplicationState) => state.achievements?.selectedCompetence
  );

  if (!selectedCompetence) {
    return <StyledCompetenceDetailContainer sx={{ height: '540px' }} />;
  }

  const competenceLearningUnitType =
    selectedCompetence.competence.competenceLearningUnitType;

  if (
    competenceLearningUnitType === CompetenceLearningUnitType.CourseSkill ||
    competenceLearningUnitType === CompetenceLearningUnitType.LearningPlanSkill
  ) {
    return <MySkillDetail competenceItem={selectedCompetence} />;
  }

  return <MyCertificateDetail competenceItem={selectedCompetence} />;
};

export default MyCompetenceDetailFactory;

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/tags';
import { Tag } from '../entities/Tag';

import { TAGS_SET_TAGS, TAGS_LOADING } from '../actions/actionTypes';

export interface TagsState {
  tags: Tag[];
  loading: boolean;
}

const unloadedState: TagsState = {
  tags: [],
  loading: false
};

export const TagsReducer: Reducer<TagsState> = (
  state: TagsState | undefined,
  incomingAction: Action
): TagsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case TAGS_SET_TAGS:
      return { ...state, tags: action.tags };
    case TAGS_LOADING:
      return { ...state, loading: action.loading };
    default:
      return state;
  }
};

import { styled, Chip } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { defaultTheme } from '@buildwitt/component-library';
import { AllowMultipleValueType, SelectedContent } from './types';

type OnDeleteFunc = (deletedTag: SelectedContent) => void;

interface TagInputTriggerProps<
  AllowMultiple extends boolean | undefined = false
> {
  onTrigger: () => void;
  tags: AllowMultipleValueType<AllowMultiple>;
  onDelete?: OnDeleteFunc;
}

const AddButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: '100%',
  borderRadius: '3px',
  width: '80px',
  border: '1px solid #d1d1d1',
  cursor: 'pointer',
  fontSize: '24px',
  minHeight: '47px'
}));

const InputBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  padding: '5px',
  borderRadius: '5px',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gridGap: '8px'
}));

const Tag = ({
  tag,
  onDelete
}: {
  tag: SelectedContent;
  onDelete?: OnDeleteFunc;
}) => (
  <Chip
    label={tag.label}
    color={'primary'}
    onDelete={onDelete && (() => onDelete(tag))}
  />
);

export const TagInputTrigger = ({
  onTrigger,
  tags,
  onDelete
}: TagInputTriggerProps<boolean | undefined>) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box display={'flex'} border={'1px solid #d1d1d1'} borderRadius={'5px'}>
        <InputBox flexGrow={1}>
          {Array.isArray(tags)
            ? tags.map(tag => <Tag tag={tag} onDelete={onDelete} />)
            : tags && <Tag tag={tags} onDelete={onDelete} />}
        </InputBox>
        <AddButton onClick={onTrigger}>+</AddButton>
      </Box>
    </ThemeProvider>
  );
};

import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { defaultTheme } from '@buildwitt/component-library';
import { ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

interface ChipTakenTimesProps {
  takenTimes: number;
}

const ChipTakenTimes: FC<ChipTakenTimesProps> = ({ takenTimes }) => {
  const { t } = useTranslation(['assignments']);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Stack direction="row" spacing={1}>
        <Chip
          color={'success'}
          label={`${t('taken', { ns: 'assignments' })} ${takenTimes} 
            ${takenTimes > 1 ? t('times', { ns: 'assignments' }) : t('time', { ns: 'assignments' })}`}
          size="small"
          style={{ fontWeight: 'bold' }}
        />
      </Stack>
    </ThemeProvider>
  );
};

export default ChipTakenTimes;

import { Grid, Typography, useTheme } from '@mui/material';
import { AnswerAverage, Question } from '../../../entities/Assessment';
import { useTranslation } from 'react-i18next';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';
import { CSSProperties } from 'react';

export interface NumberAverageQuestionProps {
  answerAverage: AnswerAverage;
  question: Question;
}

const NumberAverageQuestion: React.FC<NumberAverageQuestionProps> = ({
  answerAverage,
  question
}) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const wrongAnswerLabel = `${t('respondedWrongAnswer', { ns: 'assessments' })}`;
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;
  const backGroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;
  const badResponseAverage = 100 - answerAverage.numberAnswerAverage;
  return (
    <Grid style={{ backgroundColor: backGroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
      />
      <Grid container direction={'row'} style={{ paddingTop: '12px' }}>
        <Grid
          container
          item
          direction={'column'}
          style={gridContainerCorrectResponse}
        >
          <Grid
            container
            item
            direction={'row'}
            style={gridCorrectReponseIcon}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography style={correctResponseLabelStyle}>
              {correctReponseLabel}
            </Typography>
            <Typography
              style={{ ...gradeLabelStyle, color: greenColor }}
              align={'right'}
            >
              {`${answerAverage.numberAnswerAverage}%`}
            </Typography>
            <Grid
              style={{
                paddingLeft: '10px',
                paddingRight: '22px',
                borderLeft: '1px solid #363636'
              }}
            >
              <Grid style={{ padding: '10px 0px 10px 10px' }}>
                <Typography style={{ color: greenColor }}>
                  {question.numberCorrectAnswer}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            direction={'row'}
            style={gridCorrectReponseIcon}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography style={correctResponseLabelStyle}>{''}</Typography>
            <Typography
              style={{ ...gradeLabelStyle, color: redColor }}
              align={'right'}
            >
              {`${badResponseAverage}%`}
            </Typography>
            <Grid
              style={{
                paddingLeft: '10px',
                paddingRight: '22px',
                borderLeft: '1px solid #363636'
              }}
            >
              <Grid style={{ padding: '10px 0px 10px 10px' }}>
                <Typography>{wrongAnswerLabel}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const gradeLabelStyle: CSSProperties = {
  width: '75px',
  fontSize: '24px',
  height: '35px',
  fontWeight: '600',
  paddingRight: '15px'
};
const gridCorrectReponseIcon: CSSProperties = {
  borderRight: '1px solid #363636'
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const gridContainerCorrectResponse: CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};
export default NumberAverageQuestion;

import { Stack, Divider } from '@mui/material';
import React from 'react';

interface SelectContentViewContainerProps {
  filtersSection: React.ReactNode;
  contentSection: React.ReactNode;
}

export const SelectContentViewContainer = ({
  filtersSection,
  contentSection
}: SelectContentViewContainerProps) => {
  return (
    <Stack
      direction={'column'}
      spacing={2}
      divider={<Divider orientation="horizontal" flexItem />}
      sx={theme => ({
        backgroundColor: theme.palette.grey['800'],
        border: `1px solid ${theme.palette.grey['100']}`,
        borderRadius: '10px',
        padding: 2
      })}
    >
      {filtersSection}
      {contentSection}
    </Stack>
  );
};

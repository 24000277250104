import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsCurrentAssignmentsForGroups } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getAssignedOnColumn,
  getStandardColumn,
  getButtonColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';

interface GroupCurrentAssignmentListProps {
  groupAssignmentsData?: AnalyticsCurrentAssignmentsForGroups[] | null;
  onEditDueDate: (
    assignmentId: string,
    learningUnitType: string,
    dueDate: Date
  ) => void;
}

const GroupCurrentAssignmentList = ({
  groupAssignmentsData,
  onEditDueDate
}: GroupCurrentAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const handleEditDueDate = (params: GridRenderCellParams<any>) => {
    const { assignmentId, learningUnitType, dueDate } = params.row;
    onEditDueDate(assignmentId, learningUnitType, dueDate);
  };

  const getTooltipText = (params: GridRenderCellParams<any>) => {
    const { courseAssignmentId, learningPlanAssignmentId, dueDate } =
      params.row;
    let levelName = '';
    if (!dueDate) {
      return t('notADueDateAssignment', { ns: 'analytics' });
    }

    switch (params.row.learningUnitType) {
      case 'Lesson':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        else if (courseAssignmentId) levelName = 'course';
        break;
      case 'Course':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        break;
      default:
        break;
    }

    return levelName.length
      ? t('assignedAt', {
          ns: 'analytics',
          level: t(levelName, { ns: 'assignments' })
        })
      : levelName;
  };

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      300,
      learningUnitNames
    ),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getStandardColumn(
      'timesCompleted',
      t('timesCompleted', { ns: 'analytics' }),
      200
    ),
    getButtonColumn(
      'editableDueDate',
      '',
      t('edit', { ns: 'common' }),
      100,
      handleEditDueDate,
      getTooltipText
    )
  ])();

  return <ModalDataGrid rows={groupAssignmentsData} columns={columns} />;
};

export default GroupCurrentAssignmentList;

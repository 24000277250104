import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import PendingCompletionList from './PendingCompletionList';
import { useAnalyticsModal } from '../../../hooks/useAnalyticsModal';
import UpdateDueDateModal from '../common/UpdateDueDateModal';

interface PendingCompletionModalProps {
  learningUnitId?: string;
  learningUnitType: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onCloseModal: () => void;
}

const PendingCompletionModal = ({
  learningUnitId,
  learningUnitType,
  includeSelfAssignments,
  filterOption,
  onCloseModal
}: PendingCompletionModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: {
      pendingCompletionData,
      sortOptions,
      openModal,
      assignmentId,
      dueDate
    },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved
    }
  } = useAnalyticsModal(
    'pendingCompletions',
    learningUnitType,
    includeSelfAssignments,
    filterOption,
    learningUnitId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t('manageDueDates')}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
      >
        <PendingCompletionList
          pedingCompletionData={pendingCompletionData}
          onEditDueDate={handleEditDueDate}
        />
      </AssignmentStatusModal>
      <UpdateDueDateModal
        learningUnitType={learningUnitType}
        assignmentId={assignmentId}
        dueDate={dueDate}
        onClose={handleCloseUpdateDueDateModal}
        onSuccessfulSaved={handleSuccessfulSaved}
      />
    </>
  );
};

export default PendingCompletionModal;

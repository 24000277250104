import React, { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { actionCreators as studentActions } from '../../actions/student';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ApplicationState } from '../../store';
import { UserClaims } from '../../core/constants';
import AssignedLessons from './DailyLessons';
import { SxProps } from '@mui/system';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as courseActions } from '../../actions/courses';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { useTranslation } from 'react-i18next';
import { InfoBubble } from '../../components/InfoBubble/InfoBubble';
import { useLogEvent } from '../../hooks/useLogEvent';
import AssignmentTabs from './AssignmentTabs';
import {
  ITakenTimes,
  TakenTimesContext
} from '../../contexts/TakenTimesContext';

import { useAppDispatch } from '../../store/hooks';

const Assignments: React.FC = () => {
  const { t } = useTranslation(['assignments']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [takenTimes, setTakenTimes] = useState<ITakenTimes>({});

  const logEvent = useLogEvent();
  const learningPlayList = useSelector(
    (state: ApplicationState) => state.student?.learningPlayList
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );
  const takenTimesLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLearningPlans
  );

  useEffect(() => {
    if (employeeId) {
      dispatch(studentActions.getEmployeePlaylist(employeeId));
    }
  }, [employeeId]);

  useEffect(() => {
    dispatch(lessonActions.setCurrentLessons([]));
    dispatch(courseActions.setCurrentCourse(null));
    dispatch(learningPlanActions.setCurrentLearningPlan(null));
    dispatch(learningPlanActions.setLearningPlanCourses([]));
    dispatch(studentActions.setCompletedCourses([]));
    dispatch(studentActions.getTakenTimesLessons(employeeId));
    dispatch(studentActions.getTakenTimesCourses(employeeId));
    dispatch(studentActions.getTakenTimesLearningPlans(employeeId));
  }, []);

  useEffect(() => {
    if (takenTimesLessons && takenTimesCourses && takenTimesLearningPlans) {
      setTakenTimes({
        takenTimesLessons,
        takenTimesCourses,
        takenTimesLearningPlans
      });
    }
  }, [takenTimesLessons, takenTimesCourses, takenTimesLearningPlans]);

  return (
    <PageContainer>
      <Grid container>
        <Grid sx={containerDailyLessonsStyle} item xs={12}>
          <Typography style={typoStyle}>
            {t('dailyLessons', { ns: 'assignments' })}

            <InfoBubble
              onHover={() => logEvent.logViewDailyLessonsHelpEvent()}
              text={t('dailyLessonsDescription', { ns: 'assignments' })}
            />
          </Typography>
          {!isLoading && learningPlayList ? (
            <AssignedLessons
              dailyLessons={learningPlayList?.dailyLessons}
              isHorizontalView={true}
              takenTimesLessons={takenTimesLessons}
            />
          ) : (
            <div style={progressContainerStyle}>
              <CircularProgress />
            </div>
          )}
        </Grid>
        <Grid item xs={12} paddingTop="10px">
          <Typography style={assignmentTitleStyle}>
            {t('assignments', { ns: 'assignments' })}

            <InfoBubble
              onHover={() => logEvent.logViewAssignmentsHelpEvent()}
              text={t('assignmentsDescription', { ns: 'assignments' })}
            />
          </Typography>
          {!isLoading && learningPlayList ? (
            <TakenTimesContext.Provider value={takenTimes}>
              <AssignmentTabs learningPlaylist={learningPlayList} />
            </TakenTimesContext.Provider>
          ) : (
            <div style={progressContainerStyle}>
              <CircularProgress />
            </div>
          )}
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const typoStyle: CSSProperties = {
  fontSize: '24px',
  paddingBottom: '1rem'
};

const assignmentTitleStyle: CSSProperties = {
  ...typoStyle,
  paddingTop: '2rem'
};

const progressContainerStyle: CSSProperties = {
  textAlign: 'center'
};

const containerDailyLessonsStyle: SxProps = {
  paddingBottom: '1rem'
};

export default Assignments;

export enum TransactionStatusEnum {
  None,
  Successfull,
  Failed
}

export enum BasicButtonTypeEnum {
  Button = 'button',
  Submit = 'submit',
  Reset = 'reset'
}

export enum EntityTypeEnum {
  Course,
  Lesson,
  LearningPlan
}

export enum LessonTypeViewEnum {
  LessonType,
  CourseLessonTypesCount
}

export enum FormModeEnum {
  Create,
  Edit
}

export enum HttpStatusEnum {
  OK = 200,
  NoContent = 204,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500
}

export enum LibraryTypesEnum {
  Company,
  Buildwitt,
  CompanyAndBuildwitt
}

export enum ContentLibraryTabNames {
  AllContent = 'AllContent',
  Company = 'Company',
  Buildwitt = 'BuildWitt',
  ClickSafety = 'ClickSafety',
  Community = 'Community',
  Partner = 'Partner'
}

export enum AnalyticsTabNames {
  ContentLibrary = 'Content Library',
  Engagement = 'Engagement',
  Skills = 'Skills',
  Quizzes = 'Quizzes'
}

export enum LessonContentTypeEnum {
  Video = 1,
  Quiz = 2,
  File = 3,
  Audio = 4,
  Flashcard = 5
}

export enum CompleteUnit {
  None = 0,
  Lesson,
  Course,
  LearningPlan
}

export enum SelfAssignContentType {
  Lesson = 'Lesson',
  Course = 'Course',
  LearningPlan = 'Learning Plan'
}

export enum EngagementType {
  Groups = 'groups',
  Employees = 'employees',
  GroupDetail = 'groupDetail'
}

export enum AnalyticsQuizzesViewType {
  Overview = 'overview',
  Detail = 'detail'
}

export enum HaltType {
  CompanyHalt = 'CompanyHalt',
  PersonalHalt = 'PersonalHalt'
}

export enum CompetenceLearningUnitType {
  Skill = 'Skill',
  CourseSkill = 'CourseSkill',
  LearningPlanSkill = 'LearningPlanSkill',
  Certificate = 'Certificate',
  CourseCertificate = 'CourseCertificate',
  LearningPlanCertificate = 'LearningPlanCertificate'
}

export enum OverseerScope {
  Admin,
  Supervisor,
  GroupLead
}
export enum CompetenceType {
  Skill = 'Skill',
  Certificate = 'Certificate'
}

export enum CRUDAction {
  Create = 'Create',
  Edit = 'Edit'
}

export enum ErrorCode {
  DuplicatedName = 1,
  LearningUnitAlreadyUsed = 2
}

export enum DashboardDetailsType {
  TotalOverdueLessons = 0,
  TotalAssignedLesson = 1,
  TotalCompletedLessons = 2,
  None = 4
}

export enum DashboardDetailsSubscribedUsersType {
  LearningUsers = 0,
  NonLearningUsers = 1,
  None = 2
}

export type DashboardContentType = Exclude<
  DashboardDetailsType,
  DashboardDetailsType.None
>;
export type DashboardContentTypeSubUsers = Exclude<
  DashboardDetailsSubscribedUsersType,
  DashboardDetailsSubscribedUsersType.None
>;

export enum LearningUnitType {
  lesson = 'Lesson',
  course = 'Course',
  learningPlan = 'LearningPlan'
}

export enum OnsiteTrainingAttendanceType {
  Pending,
  Requested,
  Attended,
  Missed
}

export enum AnalyticsLearningUnitTypeDropdown {
  courses = 0,
  lessons = 1,
  learningPlans = 2
}

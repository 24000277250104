import { FC, CSSProperties } from 'react';
import { useTheme } from '@mui/material';

interface ChipCommentsAndTimeProps {
  comments: string;
  months: string;
}

const ChipCommentsAndTime: FC<ChipCommentsAndTimeProps> = ({
  comments,
  months
}) => {
  const theme = useTheme();

  const commentsAndMonthsStyle: CSSProperties = {
    color: `${theme.palette.common.white}`,
    display: 'inline-block',
    fontStyle: 'normal',
    fontSize: '12px',
    marginRight: '12px'
  };
  return (
    <div>
      <span style={commentsAndMonthsStyle} className="mt-1 align-top">
        {comments}
      </span>
      <span style={commentsAndMonthsStyle} className="mt-1 align-top">
        {months}
      </span>
    </div>
  );
};

export default ChipCommentsAndTime;

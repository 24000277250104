import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { SxProps } from '@mui/system';
import { StyledTooltip } from '../../components/core/StyledTooltip/StyledTooltip';
import searchIcon from '../../assets/search.svg';
import { UserRoles } from '../../core/constants';
import config from '../../config';
import useUserRole from '../../hooks/useUserRole';

export interface OverviewSlotProps {
  label: string | undefined;
  description: string;
  labelColor: string;
  tooltipText?: string;
  onClick?: () => void;
}

const Overview: React.FC<OverviewSlotProps> = ({
  label,
  description,
  labelColor,
  tooltipText,
  onClick
}) => {
  const [userHasRole] = useUserRole();
  const numberInfoCardStyle = { ...numberInfoCardBaseStyle, color: labelColor };
  const [isHovered, setIsHovered] = useState(false);
  const isAdminUser = userHasRole([
    UserRoles.CompanyAdmin,
    UserRoles.GroupLead,
    config.USE_ORG_CHART ? UserRoles.Supervisor : ''
  ]);

  const handleMouseEnter = () => {
    setIsHovered(isAdminUser && Number(label) > 0);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const CardComponent = (
    <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
      <Card
        style={{
          cursor: onClick && isHovered ? 'pointer' : 'auto',
          position: 'relative'
        }}
        onClick={isAdminUser ? onClick : undefined}
        sx={{
          ...cardSize,
          ...(onClick && isHovered
            ? {
                ':hover': {
                  border: '2px solid white'
                }
              }
            : {})
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div>
          <CardContent>
            <Typography sx={numberInfoCardStyle}>
              {label !== undefined ? label : 'loading...'}
            </Typography>
            <Typography sx={textInfoCard}>{description}</Typography>
          </CardContent>
          {!!onClick && isHovered && (
            <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
              <img src={searchIcon} alt="search icon" />
            </div>
          )}
        </div>
      </Card>
    </Grid>
  );
  return (
    <>
      {tooltipText && (
        <StyledTooltip title={tooltipText as string}>
          {CardComponent}
        </StyledTooltip>
      )}
      {!tooltipText && CardComponent}
    </>
  );
};

const numberInfoCardBaseStyle: SxProps = {
  fontSize: '36px',
  color: '#FF9E00'
};

const textInfoCard: SxProps = {
  fontSize: '15px',
  color: '#C4C4C4'
};

const cardSize: SxProps = {
  height: '102px',
  borderRadius: '10px',
  borderColor: '#999999',
  borderStyle: 'solid',
  borderWidth: '1px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '5px'
};

export default Overview;

import MovieLineIcon from '../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../assets/flash-card-pdf.svg';
import QuizIcon from '../../assets/quiz.svg';
import { CSSProperties } from 'react';

export const courseCardContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%'
};

export const InfoSection: CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column' as const
};

export const EditSection: CSSProperties = {
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '20%'
};

export const labelStyle: CSSProperties = {
  color: '#c4c4c4',
  fontSize: '12px'
};

export const cardTitle: CSSProperties = {
  color: '#fff',
  fontSize: '20px',
  fontWeight: '600'
};

export const MovieLineIconStyle: CSSProperties = {
  width: 16,
  height: 16,
  display: 'inline-block',
  backgroundImage: `url(${MovieLineIcon})`
};

export const FlashCardPdfIconStyle: CSSProperties = {
  ...MovieLineIconStyle,
  width: 12,
  height: 12,
  marginLeft: '2px',
  marginRight: '10px',
  backgroundImage: `url(${FlashCardPdfIcon})`
};

export const QuizIconStyle: CSSProperties = {
  ...MovieLineIconStyle,
  backgroundImage: `url(${QuizIcon})`
};

export const editStyle: CSSProperties = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textDecoration: 'underline',
  height: '30px',
  padding: '0'
};

export const styleIcon: CSSProperties = {
  marginLeft: '3px',
  fontSize: '12px'
};

export const iconStyle: CSSProperties = {
  width: 16,
  height: 16,
  display: 'inline-block',
  marginRight: 8
};

export const iconPDFStyle: CSSProperties = {
  width: 12,
  height: 12,
  marginLeft: '2px',
  marginRight: '9px',
  display: 'inline-block'
};

export const spanStyle: CSSProperties = {
  fontSize: '14px',
  color: '#fff'
};

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { ToggleButton, ToggleButtonProps } from 'react-bootstrap';

export interface BasicToggleButtonProps extends ToggleButtonProps {
  width?: string;
  height?: string;
  color: 'primary' | 'secondary' | 'alternative';
}

const BasicToggleButton: React.FC<BasicToggleButtonProps> = ({
  children,
  width,
  height,
  color,
  style,
  ...rest
}) => {
  const theme = useTheme();
  const definedStyle = { ...style };
  const buttonStyle = {
    ...definedStyle,
    textTransform: 'none' as const,
    paddingLeft: '10px',
    paddingRight: '10px',
    borderColor:
      color === 'primary'
        ? theme.palette.primary.main
        : color === 'secondary'
          ? theme.palette.grey[100]
          : theme.palette.grey[800],
    backgroundColor:
      color === 'primary'
        ? theme.palette.primary.main
        : color === 'secondary'
          ? theme.palette.secondary.dark
          : theme.palette.grey[50],
    color:
      color === 'primary'
        ? theme.palette.common.black
        : color === 'secondary'
          ? theme.palette.grey[100]
          : theme.palette.grey[800]
  };
  return (
    <ToggleButton
      type="checkbox"
      className="mb-0"
      variant="contained"
      style={
        width && height
          ? { ...buttonStyle, width: width, height: height }
          : buttonStyle
      }
      {...rest}
    >
      {children}
    </ToggleButton>
  );
};

export default BasicToggleButton;

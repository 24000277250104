import React from 'react';
import { CoursePlayAssignment } from '../../../entities/Assignment';
import StartQuiz from '../../Quizzes/StartQuiz';

export interface QuizSectionProps {
  origin: string;
  isPreviewMode?: boolean;
  assignmentId?: string;
  courseContext?: CoursePlayAssignment | null;
}

const QuizSection: React.FC<QuizSectionProps> = ({
  origin,
  isPreviewMode,
  assignmentId,
  courseContext
}) => {
  return (
    <div
      style={{
        height: '649px',
        backgroundColor: '#212121'
      }}
      className="rounded d-flex flex-column justify-content-center"
    >
      <StartQuiz
        origin={origin}
        isPreviewMode={isPreviewMode}
        assignmentId={assignmentId}
        courseContext={courseContext}
      />
    </div>
  );
};

export default QuizSection;

import Stack from '@mui/material/Stack';
import RadioButton from '../../core/RadioButton/RadioButton';
import { useTranslation } from 'react-i18next';

import { ContentMedium } from '../../../entities/LessonFormModel';
import { Box } from '@mui/material';

export interface LessonTypesOptionsProps {
  contentMedium: number;
  disabled: boolean;
  showYoutubeInput: boolean;
  onChangeContentMedium: (showYoutubeField: boolean) => (option: any) => void;
}

const LessonTypeOptions: React.FC<LessonTypesOptionsProps> = ({
  contentMedium,
  disabled,
  showYoutubeInput,
  onChangeContentMedium
}) => {
  const { t } = useTranslation(['lessons']);

  return (
    <Stack direction="row" spacing={1} sx={{ flexWrap: { xs: 'wrap' } }}>
      <RadioButton
        id="video"
        name="medium"
        value={ContentMedium.Video}
        checked={contentMedium === ContentMedium.Video && !showYoutubeInput}
        onChange={onChangeContentMedium(false)}
        labelText="Video"
        disabled={disabled}
        dataTestId="lessonVideoTypeRadio"
        dataTrackId="lessonVideoTypeRadio"
      />
      <RadioButton
        id="videoYoutube"
        name="medium"
        value={ContentMedium.Video}
        checked={contentMedium === ContentMedium.Video && showYoutubeInput}
        onChange={onChangeContentMedium(true)}
        labelText="Youtube Video"
        disabled={disabled}
        dataTestId="lessonYouTubeVideoTypeRadio"
        dataTrackId="lessonYouTubeVideoTypeRadio"
      />
      <RadioButton
        id="quiz"
        name="medium"
        value={ContentMedium.Quiz}
        checked={contentMedium === ContentMedium.Quiz}
        onChange={onChangeContentMedium(false)}
        labelText={t('quiz', { ns: 'lessons' })}
        disabled={disabled}
        dataTestId="lessonQuizTypeRadio"
        dataTrackId="lessonQuizTypeRadio"
      />
      <Box
        sx={{
          marginLeft: {
            xs: '10px !important',
            sm: '8px !important',
            md: '8px !important',
            lg: '8px !important'
          }
        }}
      >
        <RadioButton
          id="files"
          name="medium"
          value={ContentMedium.File}
          checked={contentMedium === ContentMedium.File}
          onChange={onChangeContentMedium(false)}
          labelText={t('files', { ns: 'lessons' })}
          disabled={disabled}
          dataTestId="lessonFileTypeRadio"
          dataTrackId="lessonFileTypeRadio"
        />
      </Box>
      <Box
        sx={{
          marginLeft: {
            xs: '0px !important',
            sm: '8px !important',
            md: '8px !important',
            lg: '8px !important'
          }
        }}
      >
        <RadioButton
          id="audio"
          name="medium"
          value={ContentMedium.Audio}
          checked={contentMedium === ContentMedium.Audio}
          onChange={onChangeContentMedium(true)}
          labelText="Audio"
          disabled={disabled}
          dataTestId="lessonAudioTypeRadio"
          dataTrackId="lessonAudioTypeRadio"
        />
      </Box>
    </Stack>
  );
};

export default LessonTypeOptions;

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Course } from '../entities/Course';
import { LearningPlan } from '../entities/LearningPlan';
import { Lesson, CommunityLesson } from '../entities/Lesson';
import { ApplicationState } from '../store';
import useCurrentUser from '../hooks/useCurrentUser';

export const useGetCardOwnerShip = (
  entity: Lesson | CommunityLesson | Course | LearningPlan
) => {
  const { t } = useTranslation(['common', 'lessons']);
  const user = useCurrentUser();
  const thirdPartyCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.thirdPartyCompanies
  );

  const buildWittCompanyId = user
    ? (user?.profile['BuildWitt.Library.CompanyId'] as string)
    : null;

  // BuildWitt
  if (
    entity.companyId === null ||
    (buildWittCompanyId && entity.companyId === buildWittCompanyId)
  ) {
    return { key: t('createdBy', { ns: 'common' }), value: 'BuildWitt' };
  }
  // Partner
  if (thirdPartyCompanies) {
    const partnerCompany = thirdPartyCompanies!.find(
      element => element.id === entity.companyId
    );
    if (partnerCompany) {
      return {
        key: t('partner', { ns: 'common' }),
        value: partnerCompany.name
      };
    }
  }
  // Community
  if ((entity as CommunityLesson).userProfileName) {
    const communityLessonEntity = entity as CommunityLesson;
    return {
      key: t('contributor', { ns: 'lessons' }),
      value: communityLessonEntity.userProfileName
    };
  }
  return null;
};

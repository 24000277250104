import React, { useEffect, useState } from 'react';
import { AnswerStatus, Question2ContainerProps } from './Types';
import { Option } from '../../entities/Assessment';
import MultipleChoiceQuestion2 from './MultipleChoiceQuestion2';

export interface MultipleChoiceQuestion2ContainerProps
  extends Question2ContainerProps {
  options: Option[];
}

const MultipleChoiceQuestion2Container: React.FC<
  MultipleChoiceQuestion2ContainerProps
> = ({
  question,
  savedAnswer,
  setAnswer,
  isShowingResultsView,
  options,
  shouldShowCorrectAnswer
}) => {
  const [answerStatus, setAnswerStatus] = useState(
    isShowingResultsView ? AnswerStatus.AnswerSubmitted : AnswerStatus.NoAnswer
  );

  useEffect(() => {
    if (isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnswerSubmitted);
    }
  }, [isShowingResultsView]);

  const onSelectAnswer = (answer?: Option) => {
    if (!isShowingResultsView) {
      setAnswerStatus(AnswerStatus.AnsweredSelected);
      setAnswer({
        questionId: question.id,
        type: question.type!,
        isCorrect: answer?.isCorrect,
        order: question.order,
        selectedOptionId: answer!.id
      });
    }
  };

  let receivedAnswer: undefined | Option = undefined;
  if (savedAnswer?.selectedOptionId !== undefined) {
    receivedAnswer = {
      id: savedAnswer.selectedOptionId,
      isCorrect: savedAnswer.isCorrect!,
      optionText: '',
      optionLabel: ''
    };
  }

  return (
    <MultipleChoiceQuestion2
      imageUrl={question.imageUrl}
      questionText={question.questionText}
      showFeedback={isShowingResultsView && shouldShowCorrectAnswer}
      correctAnswer={question.options!.find(o => o.isCorrect)!}
      answerStatus={answerStatus}
      onAnswerReceived={onSelectAnswer}
      receivedAnswer={receivedAnswer}
      options={options}
    />
  );
};
export default MultipleChoiceQuestion2Container;

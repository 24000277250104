import { AwaitableDialog, DialogHeader } from '@buildwitt/component-library';
import { Dispatch, useEffect, useState } from 'react';
import DatePickerInput from '../core/DatePickerInput/DatePickerInput';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel
} from '@mui/material';
import axios from 'axios';
import { getUpdateAssignmentEndpoint } from './utils';
import { actions } from './state';
import { Alert } from '@mui/lab';

export type Assignment = {
  dueDateUtc: string;
  id: string;
};

interface Props {
  assignment?: Assignment;
  removeCurrentAssingment: () => void;
  assignmentType: string;
  dispatch: Dispatch<{ type: string; payload?: any }>;
}

export const DueDateModal = ({
  assignment,
  removeCurrentAssingment,
  assignmentType,
  dispatch
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState<Date>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (assignment && !isOpen) {
      setIsOpen(true);
      setDate(new Date(assignment.dueDateUtc));
    }
  }, [assignment?.id]);

  const handleClose = () => {
    removeCurrentAssingment();
    setIsOpen(false);
    setError(undefined);
  };

  const handleDueDateUpdate = () => {
    if (assignment) {
      axios
        .put(getUpdateAssignmentEndpoint(assignmentType, assignment.id), {
          ...assignment,
          dueDateUtc: date
        })
        .then(response => {
          dispatch({ type: actions.UPDATE_DUEDATE, payload: response.data });
          handleClose();
        })
        .catch(() => {
          setError('Could not update the due date. Try again.');
        });
    }
  };

  return (
    <AwaitableDialog isLoading={false} open={isOpen}>
      <DialogHeader onClose={handleClose}>
        <DialogTitle>Manage Due Date</DialogTitle>
      </DialogHeader>
      <DialogContent>
        <Box height={'300px'}>
          <FormControl fullWidth>
            <FormLabel>Due Date</FormLabel>
            <DatePickerInput value={date} onChange={date1 => setDate(date1)} />
          </FormControl>
          {error && <Alert severity={'error'}>{error}</Alert>}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button onClick={handleDueDateUpdate}>Save</Button>
      </DialogActions>
    </AwaitableDialog>
  );
};

import React, { useState, CSSProperties, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Question, Answer } from '../../entities/Assessment';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export interface TrueFalseQuestionProps {
  question: Question;
  questionResult?: Answer;
  setAssessmentResult: (answer: Answer) => void;
}

const TrueFalseQuestion: React.FC<TrueFalseQuestionProps> = ({
  question,
  questionResult,
  setAssessmentResult
}) => {
  const theme = useTheme();
  const [trueFalse, setTrueFalse] = useState<boolean | undefined>();
  const { t } = useTranslation(['assessments']);

  useEffect(() => {
    setTrueFalse(undefined);
  }, [question]);

  useEffect(() => {
    setTrueFalse(questionResult?.answerChoice);
  }, [questionResult]);

  const answersContainerStyle: CSSProperties = {
    paddingBottom: '15px'
  };

  const answerStyle: CSSProperties = {
    backgroundColor: theme.palette.grey[600],
    fontSize: '24px',
    cursor: !questionResult ? 'pointer' : 'auto',
    borderRadius: '5px',
    maxHeight: '130px',
    height: '125px'
  };

  const trueStyle: CSSProperties = (() => {
    const color = !trueFalse
      ? theme.palette.common.white
      : trueFalse && question.trueIsCorrectAnswer
        ? theme.palette.success.main
        : theme.palette.error.light;

    const borderColor = !trueFalse
      ? theme.palette.grey[600]
      : trueFalse && question.trueIsCorrectAnswer
        ? theme.palette.success.main
        : theme.palette.error.light;

    return {
      ...answerStyle,
      color: color,
      border: `1px solid ${borderColor}`
    };
  })();

  const falseStyle: CSSProperties = (() => {
    const color =
      trueFalse === undefined || trueFalse
        ? theme.palette.common.white
        : !trueFalse && !question.trueIsCorrectAnswer
          ? theme.palette.success.main
          : theme.palette.error.light;

    const borderColor =
      trueFalse === undefined || trueFalse
        ? theme.palette.grey[600]
        : !trueFalse && !question.trueIsCorrectAnswer
          ? theme.palette.success.main
          : theme.palette.error.light;

    return {
      ...answerStyle,
      color: color,
      border: `1px solid ${borderColor}`
    };
  })();

  const answerMessageStyle: CSSProperties = {
    color: theme.palette.common.white,
    fontSize: '14px'
  };

  const questionTextStyle: CSSProperties = {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '155%'
  };

  const handleTrueFalse = (event: React.MouseEvent<HTMLDivElement>) => {
    if (questionResult) return;

    const { id } = event.target as any;
    const answer: boolean = id === 'true';
    setTrueFalse(answer);
    setAssessmentResult({
      questionId: question && question.id ? question.id : '',
      isCorrect: question.trueIsCorrectAnswer === answer,
      type: question && question.type ? question.type : '',
      answerChoice: answer,
      order: question.order
    });
  };

  return (
    <Row>
      <Col>
        <Typography style={questionTextStyle}>
          {question.questionText}
        </Typography>
        <Row className="mt-5">
          <Col lg={6} style={answersContainerStyle}>
            <div
              id="true"
              onClick={handleTrueFalse}
              className="d-flex w-100 align-items-center justify-content-center"
              style={trueStyle}
            >
              {t('true', { ns: 'assessments' })}
            </div>
          </Col>
          <Col lg={6} style={answersContainerStyle}>
            <div
              id="false"
              onClick={handleTrueFalse}
              className="d-flex w-100 align-items-center justify-content-center"
              style={falseStyle}
            >
              {t('false', { ns: 'assessments' })}
            </div>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col>
            <span style={answerMessageStyle}>
              {trueFalse === question.trueIsCorrectAnswer
                ? t('correctAnswer', { ns: 'assessments' })
                : trueFalse !== undefined
                  ? question.incorrectAnswerText
                  : ''}
            </span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TrueFalseQuestion;

import React, { CSSProperties } from 'react';
import MovieLineIcon from '../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../assets/flash-card-pdf.svg';
import QuizIcon from '../../assets/quiz.svg';
import { LessonTypeViewEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';
import { iconStyle } from '../CourseCard/CourseCardStyles';

export interface LessonTypeProps {
  viewType: LessonTypeViewEnum;
  type?: string;
  videosCount?: number;
  flashCardsCount?: number;
  quizesCount?: number;
}

const LessonType: React.FC<LessonTypeProps> = ({
  type,
  viewType,
  videosCount,
  flashCardsCount,
  quizesCount
}) => {
  const { t } = useTranslation(['lessons']);

  const MainIconContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '13px',
    flexWrap: 'wrap'
  };

  return (
    <>
      {viewType === LessonTypeViewEnum.CourseLessonTypesCount ? (
        <div style={MainIconContainerStyle}>
          <div className="d-inline-flex align-items-center">
            <img alt="MovieLine" src={MovieLineIcon} style={iconStyle} />
            <div className="pe-2">
              <span className="text-white">{videosCount}</span> Videos
            </div>
          </div>
          <div className="d-inline-flex align-items-center">
            <img alt="MovieLine" src={QuizIcon} style={iconStyle} />
            <span className="pe-2">
              <span className="text-white">{quizesCount}</span>{' '}
              {t('quizzes', { ns: 'lessons' })}
            </span>
          </div>
        </div>
      ) : (
        <>
          {
            <>
              <img alt="MovieLine" src={FlashCardPdfIcon} style={iconStyle} />
              <span className="pe-4">{` ${type === 'PDF' ? 'File' : type}`}</span>
            </>
          }
        </>
      )}
    </>
  );
};

export default LessonType;

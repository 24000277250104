import React, { CSSProperties } from 'react';
import { CommunityLesson, Lesson } from '../../entities/Lesson';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import { SxProps, Theme } from '@mui/system';

import LessonBackground from '../../assets/LessonBackground.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  isNewContent,
  isOverdue,
  secondsToHHMMSS
} from '../../utils/dateUtils';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import ChipAverageTime from '../core/ChipAverageTime/ChipAverageTime';
import {
  cardContentStyle,
  commentsStyle,
  divContributorSpan,
  titleStyle,
  infoContainerStyle,
  childrenButtonsStyle,
  infoAvgLessonStyle,
  lessonInfoContainerStyle,
  contributorAndEditStyle,
  divEditSpan,
  childrenCommunityButtonsStyle,
  divEditSpanWithBanner,
  lessonInfoSubContainerStyle
} from './LessonCardStyle';
import { Subtitles } from '../core/ChipSubtitles/ChipSubtitles';
import ChipLessonType from '../core/ChipLessonType/ChipLessonType';
import ChipCommentsAndTime from '../core/ChipCommentsAndTime/ChipCommentsAndTime';
import {
  FailedHeader,
  NewContentHeader,
  OverdueHeader,
  PassedHeader
} from '../core/InfoCardHeader/InfoCardHeader';
import { ILessonCardProps } from '../../entities/Card';
import { useGetCardOwnerShip } from '../../hooks/useGetCardOwnership';
import { useLesson } from '../../pages/lessons/useLesson';
import { useUserCanEditContent } from '../../hooks/useUserCanEditContent';
import ChipTakenTimes from '../core/ChipTakenTimes/ChipTakenTimes';
import VideoEncodingInfo from './atoms/VideoEncodingInfo';
import EditInfoLesson from './atoms/EditInfoLesson';

export interface ContentLessonCardProps extends ILessonCardProps {
  isComplete?: boolean | null;
  children?: React.ReactNode;
  dueDate?: Date;
  listFailedQuiz?: string[];
  showLessonType?: boolean;
  showCreateDate?: boolean;
  isWorkloadAssignment?: boolean;
  showEdit?: boolean;
  height?: string;
  onStartPreviewMode?: (lesson: Lesson) => void;
}

const ContentLessonCard: React.FC<ContentLessonCardProps> = ({
  lesson,
  isComplete = false,
  children,
  dueDate,
  listFailedQuiz,
  showLessonType = true,
  lessonCardType,
  isWorkloadAssignment = false,
  showEdit = true,
  height,
  showCompletedAsNormal = false,
  takenTimes,
  onStartPreviewMode
}) => {
  const { t } = useTranslation(['common', 'contentLibrary']);
  const theme = useTheme();
  const ownership = useGetCardOwnerShip(lesson);
  const [userCanEditContent] = useUserCanEditContent();
  const isFailed = listFailedQuiz?.includes(lesson.assessmentId!);
  const pastDueDate = moment(dueDate);
  const isPreviewMode = !!onStartPreviewMode;
  const isOverdueLesson = isOverdue(pastDueDate);
  const creationLessonDate = moment(lesson?.createdAt).local();
  const isNewContentLesson = isNewContent(creationLessonDate);
  const checkOverdueLesson = !isComplete && !isFailed && isOverdueLesson;
  const checkCompleteLesson = lesson.lessonType !== 'Quiz' && isComplete;
  const checkPassQuiz =
    lesson.lessonType === 'Quiz' && isComplete && !showCompletedAsNormal;
  const checkFailQuiz = lesson.lessonType === 'Quiz' && isFailed;
  const baseTextNumberStyle: CSSProperties = {
    width: '20px',
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center'
  };

  const createGreenStyle = () => {
    return { ...baseTextNumberStyle, color: theme.palette.success.main };
  };

  const createRedStyle = () => {
    return { ...baseTextNumberStyle, color: theme.palette.error.main };
  };
  const votes = (lesson as CommunityLesson).likesCount;
  const profilePicture = (lesson as CommunityLesson).contributorImage;

  const {
    api: { handleEditTrainingLesson }
  } = useLesson(false);

  const completeLessonBorder =
    lesson.lessonType !== 'Quiz' && isComplete && !showCompletedAsNormal
      ? theme.palette.success.light
      : `${theme.palette.grey['100']}`;
  const passQuizBorder =
    lesson.lessonType === 'Quiz' && isComplete && !showCompletedAsNormal
      ? theme.palette.success.light
      : `${theme.palette.grey['100']}`;
  const newLessonBorder =
    isNewContentLesson && !showCompletedAsNormal
      ? theme.palette.primary.main
      : `${theme.palette.grey['100']}`;
  const failedQuizBorder =
    lesson.lessonType === 'Quiz' && isFailed
      ? theme.palette.error.main
      : `${theme.palette.grey['100']}`;
  const overdueLessonBorder = checkOverdueLesson
    ? theme.palette.error.main
    : `${theme.palette.grey['100']}`;

  const cardStyle: SxProps<Theme> = theme => ({
    borderRadius: '14px',
    borderColor: isPreviewMode
      ? `${theme.palette.grey['100']}`
      : checkCompleteLesson
        ? completeLessonBorder
        : checkPassQuiz
          ? passQuizBorder
          : isNewContentLesson && !checkOverdueLesson && !checkFailQuiz
            ? newLessonBorder
            : isOverdueHeader
              ? overdueLessonBorder
              : checkFailQuiz
                ? failedQuizBorder
                : `${theme.palette.grey['100']}`,
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: `${theme.palette.grey['400']} !important`,
    position: 'relative',
    height: height ? height : 'auto'
  });

  const ownershipStyle: CSSProperties = {
    marginTop: ownership === null ? '10px' : '0px'
  };

  const truncateLessonTitle = (title: string) => {
    return title.length > 200 ? title.substring(0, 197) + '..' : title;
  };

  const calculateTimeAgo = (createdDate: Date) => {
    return moment(createdDate).fromNow();
  };

  const thumbnailPicture = !lesson.thumbnailUrl
    ? LessonBackground
    : lesson.thumbnailUrl;

  const passedQuizHeader = checkPassQuiz && !isPreviewMode && (
    <PassedHeader label={t('quizPass', { ns: 'assignments' })} />
  );

  const isOverdueHeader = checkOverdueLesson && (
    <OverdueHeader label={t('overdue', { ns: 'assignments' })} />
  );

  const failedQuizHeader = checkFailQuiz && (
    <FailedHeader label={t('quizFail', { ns: 'assignments' })} />
  );

  const isNewContentHeader = isNewContentLesson &&
    !checkOverdueLesson &&
    !checkFailQuiz &&
    !checkPassQuiz &&
    !checkCompleteLesson && (
      <NewContentHeader label={t('newContent', { ns: 'common' })} />
    );

  const stylesChildren =
    lessonCardType === 'Community'
      ? childrenCommunityButtonsStyle
      : childrenButtonsStyle;

  const handleStart = () => {
    if (onStartPreviewMode && isPreviewMode) {
      onStartPreviewMode(lesson);
    }
  };

  return (
    <Card sx={cardStyle}>
      {passedQuizHeader}
      {isOverdueHeader}
      {failedQuizHeader}
      {isNewContentHeader}
      <Grid container direction="row" style={contributorAndEditStyle}>
        {lessonCardType === 'Community' && ownership && (
          <Grid item style={divContributorSpan} marginLeft={2.5}>
            <InfoBySpan
              leftText={ownership.key}
              rightText={ownership.value}
              showProfilePicture={true}
              isOverdue={false}
              profilePicture={profilePicture}
            />
          </Grid>
        )}
        {showEdit && userCanEditContent(lesson.companyId) && (
          <Grid
            item
            style={
              passedQuizHeader || isNewContentHeader
                ? divEditSpanWithBanner
                : divEditSpan
            }
          >
            <EditInfoLesson id={lesson.id} onEdit={handleEditTrainingLesson} />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" style={lessonInfoContainerStyle}>
        <div style={lessonInfoSubContainerStyle}>
          <div style={infoContainerStyle}>
            {showLessonType && (
              <Grid item>
                <ChipLessonType lessonType={lesson.lessonContentType} />
              </Grid>
            )}
            <Grid item style={{ marginTop: '2px' }}>
              {lesson.subtitles?.find(
                subtitle => subtitle.language.toLowerCase() === 'spanish'
              ) && (
                <Subtitles
                  subtitlesText={`${t('spanishSubtitles', {
                    ns: 'contentLibrary'
                  })}`}
                  color={`${theme.palette.common.white}`}
                />
              )}
            </Grid>
          </div>
          <div style={infoAvgLessonStyle}>
            {takenTimes && (
              <div style={{ paddingRight: '4px' }}>
                <ChipTakenTimes takenTimes={takenTimes} />
              </div>
            )}
            {!lesson.isVideoEncoding && (
              <ChipAverageTime
                showComplete={false}
                averageLessonTime={secondsToHHMMSS(
                  parseInt(lesson.lessonDuration)
                )}
              />
            )}
          </div>
        </div>
      </Grid>
      <CardMedia
        onClick={handleStart}
        component="img"
        height="150px"
        style={{
          cursor: isPreviewMode ? 'pointer' : 'default',
          opacity: '40%'
        }}
        image={thumbnailPicture}
        alt={`thumbnail ${lesson.title} lesson`}
      />

      <CardContent sx={cardContentStyle}>
        <Grid container direction="column">
          {lessonCardType !== 'Community' && ownership && (
            <Grid item style={divContributorSpan}>
              <InfoBySpan
                leftText={ownership.key}
                rightText={ownership.value}
                showProfilePicture={false}
                isOverdue={false}
              />
            </Grid>
          )}
          <Grid
            onClick={handleStart}
            style={{ cursor: isPreviewMode ? 'pointer' : 'default' }}
            item
            sx={titleStyle}
          >
            <p>{truncateLessonTitle(lesson.title)}</p>
          </Grid>
          {lessonCardType === 'Community' && (
            <Grid item sx={commentsStyle}>
              <ChipCommentsAndTime
                comments={`${
                  (lesson as CommunityLesson).commentCount
                } comments`}
                months={`${calculateTimeAgo(
                  (lesson as CommunityLesson).createdAt
                )}`}
              />
            </Grid>
          )}
        </Grid>
        <Grid item>
          <VideoEncodingInfo lesson={lesson} />
        </Grid>
        {lessonCardType === 'Assigned' ? (
          <div>{children}</div>
        ) : (
          <Grid
            direction="row"
            style={{ ...ownershipStyle, ...stylesChildren }}
          >
            <>
              {lessonCardType === 'Community' && (
                <Grid item>
                  <span
                    style={votes >= 0 ? createGreenStyle() : createRedStyle()}
                  >
                    {votes >= 0 ? votes : votes.toString().replace('-', '')}
                  </span>
                </Grid>
              )}
            </>
          </Grid>
        )}
      </CardContent>
      {lessonCardType !== 'Assigned' && (
        <CardActions>{!lesson.isVideoEncoding && children}</CardActions>
      )}
    </Card>
  );
};

export default ContentLessonCard;

import { alpha, styled } from '@mui/material/styles';
import {
  DataGrid,
  GridRowClassNameParams,
  GridValidRowModel,
  gridClasses
} from '@mui/x-data-grid';

const ODD_OPACITY = 0.2;

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  backgroundColor: theme.palette.common.black,
  borderRadius: '8px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: theme.palette.grey.A100
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.common.black
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: theme.palette.grey.A200
  },
  [`& .${gridClasses.row}`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent'
      }
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          )
        }
      }
    }
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  },
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
    width: '0 !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: 'auto'
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export default CustomDataGrid;

export const getCustomRowClassName = (
  params: GridRowClassNameParams<GridValidRowModel>
) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd');

import React, { useState, useEffect, CSSProperties } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { useTheme } from '@mui/material/styles';
import { Employee } from '../../entities/Employee';
import EmployeeListItem from '../EmployeeListItem/EmployeeListItem';
import { useTranslation } from 'react-i18next';
import { EmployeeAssignmentStatus } from '../../entities/Assignment';

export interface EmployeeListProps {
  employees: Employee[];
  groupIds: string[];
  assignedEmployeeIds: string[];
  applyToAllChecked: boolean;
  learningUnitAssigmentStatus: EmployeeAssignmentStatus[];
  layoutType?: 'standard' | 'clickSafety';
  onUpdateAssignedEmployees?: (assignedIds: string[]) => void;
}

const EmployeeList: React.FC<EmployeeListProps> = ({
  employees,
  groupIds,
  assignedEmployeeIds,
  applyToAllChecked,
  learningUnitAssigmentStatus,
  layoutType = 'standard',
  onUpdateAssignedEmployees = null
}) => {
  const [assignedEmployees, setAssignedEmployees] =
    useState<string[]>(assignedEmployeeIds);
  const [groups, setGroups] = useState<string[]>([]);
  const theme = useTheme();
  const { t } = useTranslation(['employees']);

  useEffect(() => {
    setAssignedEmployees(assignedEmployeeIds);
  }, [assignedEmployeeIds.length]);

  useEffect(() => {
    if (applyToAllChecked) {
      setGroups([]);
      return;
    }

    if (groupIds.length > groups.length) {
      const filtered = employees.filter(employee =>
        employee.groups.some(group => groupIds.includes(group.id))
      );
      const employeeIds = filtered.map(employee => employee.id);
      const assigned = [...assignedEmployees, ...employeeIds];
      const unique = assigned.filter(
        (item, index, array) => array.indexOf(item) === index
      );

      setAssignedEmployees(unique);
      if (onUpdateAssignedEmployees) onUpdateAssignedEmployees(unique);
    } else if (groupIds.length < groups.length) {
      const removedGroups = groups.filter(
        groupId => !groupIds.includes(groupId)
      );
      const employeesGroup = employees.filter(employee =>
        employee.groups.some(group => removedGroups.includes(group.id))
      );
      const keepEmpployees = employeesGroup.filter(employee =>
        employee.groups.some(group => groupIds.includes(group.id))
      );
      const removeEmployees = employeesGroup.filter(
        employee => !keepEmpployees.includes(employee)
      );
      const employeeIds = removeEmployees.map(employee => employee.id);
      const newEmployeeIds = assignedEmployees.filter(
        employeeId => !employeeIds.includes(employeeId)
      );

      setAssignedEmployees(newEmployeeIds);
      if (onUpdateAssignedEmployees) onUpdateAssignedEmployees(newEmployeeIds);
    }

    setGroups(groupIds);
  }, [groupIds]);

  const handleChangeAssign = (e: React.ChangeEvent<HTMLInputElement>) => {
    let updatedAssignedEmployees: string[] = [];

    if (e.target.checked)
      updatedAssignedEmployees = [...assignedEmployees, e.target.id];
    else
      updatedAssignedEmployees = assignedEmployees.filter(
        employeeId => employeeId !== e.target.id
      );

    setAssignedEmployees(updatedAssignedEmployees);

    if (onUpdateAssignedEmployees)
      onUpdateAssignedEmployees(updatedAssignedEmployees);
  };

  const grayStyle: CSSProperties = {
    color: theme.palette.grey[100]
  };

  const containerStyle: CSSProperties = {
    maxHeight: layoutType === 'clickSafety' ? '285px' : '320px'
  };

  return (
    <Row>
      <Col
        className="overflow-auto card-container me-2-half"
        style={containerStyle}
      >
        <Table striped bordered hover variant="dark">
          <thead style={grayStyle}>
            <tr>
              <th>{t('employee', { ns: 'employees' })}</th>
              <th>{t('group', { ns: 'employees' })}</th>
              {!!employees.length &&
                employees[0].hasSubscription !== undefined && <th></th>}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {employees.map((e: Employee, index) => {
              const assignmentStatus = learningUnitAssigmentStatus
                ? learningUnitAssigmentStatus.find(
                    assignment => assignment.employeeId === e.id
                  )
                : undefined;

              return (
                <EmployeeListItem
                  key={`${e.id}`}
                  employee={e}
                  isAssgined={assignedEmployees.includes(e.id)}
                  handleChangeAssign={handleChangeAssign}
                  learningUnitAssigmentStatus={assignmentStatus}
                />
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default EmployeeList;

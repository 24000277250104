import React, { CSSProperties, PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import BasicButton from '../../../components/core/BasicButton/BasicButton';

interface OnsiteTrainingModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  savingInProgress: boolean;
  nextButtonText: string;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const dialogActionStyle: SxProps = {
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};

const OnsiteTrainingModal = ({
  showModal,
  children,
  onCloseModal,
  savingInProgress,
  nextButtonText
}: PropsWithChildren<OnsiteTrainingModalProps>) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining', 'assignments']);
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onCloseModal}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography style={modalTitle}>
          {t('completeOnstieTitleModal', { ns: 'onsiteTraining' })}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{ ...iconStyle }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <label style={labelStyle}>
          {`${t('selectEmployeesToAsign', { ns: 'assignments' })}`}
          <span className="text-danger">*</span>
        </label>
        <div style={childreContainer}>{children}</div>
      </DialogContent>
      <DialogActions sx={dialogActionStyle}>
        <BasicButton
          color="primary"
          width="160px"
          height="45px"
          loading={savingInProgress}
        >
          {nextButtonText}
        </BasicButton>
      </DialogActions>
    </CustomDialog>
  );
};

export const modalTitle: CSSProperties = {
  color: '#FFF',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.2px'
};

export const labelStyle: CSSProperties = {
  color: '#FFF',
  lineHeight: '18px',
  fontSize: '15px',
  marginTop: '5px',
  marginLeft: '50px'
};

export const childreContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
};

export default OnsiteTrainingModal;

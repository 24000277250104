import Button from '@mui/material/Button';
import { CSSProperties, useState, FC } from 'react';
import AccordionComponent from '../Accordion/AccordionComponent';
import TrueFalseQuestionDefinition from '../AssessmentQuestions/TrueFalseQuestionDefinition';
import FormLabel from '../core/FormLabel/FormLabel';
import DropDownlist from '../core/DropDownList/DropDownList';
import MultipleChoiceQuestionDefinition from '../AssessmentQuestions/MultipleChoiceQuestionDefinition';
import MultipleChoiceImageQuestionDefinition from '../AssessmentQuestions/MultipleChoiceImageQuestionDefinition';
import NumberQuestionDefinition from '../AssessmentQuestions/NumberQuestionDefinition';
import { QuestionTypes, LessonQuiz } from '../../core/constants';
import { useTranslation } from 'react-i18next';
import { QuestionEditMode } from '../LessonForm/v1.2/AssessmentSection';

interface AssessmentAccordionProps {
  questions: QuestionEditMode[];
  setQuestions: (questions: QuestionEditMode[]) => void;
  lessonType?: string;
  variant?: 'light' | 'dark' | undefined;
  getNextQuestionId: () => string;
}

interface AssessmentOptionsMenu {
  value: string;
  label: string;
}

const AssessmentAccordion: FC<AssessmentAccordionProps> = ({
  questions,
  setQuestions,
  lessonType,
  variant = 'light',
  getNextQuestionId
}) => {
  const [expanded, setExpanded] = useState<string | null>('');
  const { t } = useTranslation(['assessments', 'quizzes', 'common']);

  const updateQuestions = (
    question: QuestionEditMode,
    prop: string,
    value: any
  ) => {
    const newQuestions = questions.map(q =>
      q._id === question._id ? { ...question, [prop]: value } : q
    );

    setQuestions(newQuestions);
  };

  const setQuestionProp =
    (question: QuestionEditMode) => (prop: string, value: any) => {
      updateQuestions(question, prop, value);
    };

  const handleChange =
    (questionId: string) => (_event: any, isExpanded: boolean) => {
      setExpanded(isExpanded ? questionId : '');
    };

  const onAddElementClick = () => {
    const newQuestion = {
      _id: getNextQuestionId(),
      id: '',
      type: '',
      questionText: '',
      trueIsCorrectAnswer: false,
      options: [],
      imageUrl: '',
      numberCorrectAnswer: ''
    };
    setQuestions(questions.concat(newQuestion));

    setExpanded(newQuestion._id);
  };

  const onDeleteQuestion = (question: QuestionEditMode) => () => {
    const newQuestions = questions.filter(q => question !== q);

    setQuestions(newQuestions);
  };

  const handleAssessmentChange =
    (question: QuestionEditMode) => (newValue: unknown | null) => {
      if (newValue) {
        const option = newValue as AssessmentOptionsMenu;
        updateQuestions(question, 'type', option.value);
      }
    };

  const AssessmentOptions: AssessmentOptionsMenu[] = [
    {
      value: QuestionTypes.TrueFalse,
      label: t('trueOrFalse', { ns: 'assessments' })
    },
    {
      value: QuestionTypes.MultipleChoiceText,
      label: t('multipleChoice', { ns: 'assessments' })
    },
    {
      value: QuestionTypes.Number,
      label: t('textNumberResponse', { ns: 'assessments' })
    }
  ];

  return (
    <>
      <div>
        {questions.map((question: QuestionEditMode, index) => (
          <AccordionComponent
            expanded={expanded === question._id!}
            handleChange={handleChange(question._id!)}
            key={`question-${index}`}
            title={
              lessonType === LessonQuiz
                ? `${t('quizQuestion', { ns: 'quizzes' })} ${index + 1}`
                : `${t('assessmentQuestion', { ns: 'assessments' })} ${
                    index + 1
                  }`
            }
            onDelete={onDeleteQuestion(question)}
            variant={variant}
          >
            <FormLabel>
              {t('questionType', { ns: 'assessments' })}
              <span className="text-danger">*</span>
            </FormLabel>
            <DropDownlist
              data-testid={`questionTypeSelect${index}`}
              id={`questionTypeSelect${index}`}
              placeholder={t('selectOption', { ns: 'common' })}
              options={AssessmentOptions}
              value={AssessmentOptions.find(
                assessmentOption => assessmentOption.value === question.type
              )}
              onChange={handleAssessmentChange(question)}
              color={variant === 'light' ? 'primary' : 'secundary'}
            />
            <br />
            {question.type === QuestionTypes.TrueFalse && (
              <TrueFalseQuestionDefinition
                question={question}
                changeHandler={setQuestionProp(question)}
                variant={variant}
                index={index}
              />
            )}
            {question.type === QuestionTypes.MultipleChoiceText && (
              <MultipleChoiceQuestionDefinition
                question={question}
                changeHandler={setQuestionProp(question)}
                variant={variant}
                index={index}
              />
            )}
            {question.type === QuestionTypes.MultipleChoiceImage && (
              <MultipleChoiceImageQuestionDefinition
                question={question}
                changeHandler={setQuestionProp(question)}
                variant={variant}
              />
            )}
            {question.type === QuestionTypes.Number && (
              <NumberQuestionDefinition
                question={question}
                changeHandler={setQuestionProp(question)}
                variant={variant}
                index={index}
              />
            )}
          </AccordionComponent>
        ))}
      </div>
      <div style={buttonContainer}>
        <Button
          style={buttonStyle}
          onClick={onAddElementClick}
          data-testid="addQuestionButton"
          data-trackid="addQuestionButton"
        >
          {t('addQuestion', { ns: 'assessments' })}
        </Button>
      </div>
    </>
  );
};

const buttonContainer: CSSProperties = {
  marginTop: 25,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent: 'center'
};

const buttonStyle: CSSProperties = {
  borderRadius: '5px',
  width: '100%',
  border: '0',
  backgroundColor: '#FF9E00',
  color: '#0E0D0D'
};

export default AssessmentAccordion;

import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import SearchBox from '../core/SearchBox/SearchBox';
import FormCheckBox from '../core/FormCheckBox/FormCheckBox';
import TextDropdownList from '../core/TextDropdownList/TextDropdownList';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import { InfoBubble } from '../../components/InfoBubble/InfoBubble';
import themeConfig from '../../themes/theme';
import { SortEmployeeOption } from '../../entities/SortEmployeeOption';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import {
  LessonGroupAssignment,
  CourseGroupAssignment,
  LearningPlanGroupAssignment,
  EmployeeGroupInfo,
  EmployeeGroupAssignmentDefinition
} from '../../entities/Assignment';
import { dropDownItem } from '../../entities/Common';
import { EnrollmentTypes } from '../../core/constants';
import GroupList from '../GroupList/GroupList';
import { Employee, EmployeeGroup } from '../../entities/Employee';

export interface AssignGroupsPanelProps {
  groupAssignment:
    | LessonGroupAssignment
    | CourseGroupAssignment
    | LearningPlanGroupAssignment;
  employeeGroups: EmployeeGroup[];
  groupAssignmentDefinitions?: EmployeeGroupAssignmentDefinition[] | null;
  subscribedEmployees: Employee[];
  setGroupAssignment: (groupAssignment: any) => void;
}

const AssignGroupsPanel: React.FC<AssignGroupsPanelProps> = ({
  groupAssignment,
  employeeGroups,
  groupAssignmentDefinitions,
  subscribedEmployees,
  setGroupAssignment
}) => {
  let searchTimeout: NodeJS.Timeout;
  const theme = createTheme(themeConfig);
  const [applyToAllChecked, setApplyToAllChecked] = useState<boolean>(false);
  const [employeeGroupsToShow, setEmployeeGroupsToShow] =
    useState<EmployeeGroup[]>();
  const [sortOrder, setSortOrder] = useState<number>(
    SortEmployeeOption.Assigned
  );
  const [assignedGroups, setAssignedGroups] = useState<EmployeeGroupInfo[]>(
    groupAssignment.groups
  );
  const { t } = useTranslation(['common', 'assignments']);
  const assignByOptions: dropDownItem[] = [
    {
      label: t('employeesWorkloadBudget', { ns: 'assignments' }),
      value: EnrollmentTypes.EmployeesWorkloadBudget
    },
    {
      label: t('noDueDate', { ns: 'assignments' }),
      value: EnrollmentTypes.NoDueDate
    }
  ];

  useEffect(() => {
    if (employeeGroups) {
      setEmployeeGroupsToShow(
        removeUnsubscribedAndInactiveEmployeesFromGroups(employeeGroups)
      );
    }
  }, [employeeGroups]);

  useEffect(() => {
    if (employeeGroupsToShow?.length) {
      setEmployeeGroupsToShow(prevEmployeeGroupsToShow =>
        sortGroups(sortOrder, prevEmployeeGroupsToShow!)
      );
    }
  }, [sortOrder]);

  const filterSubscribedAndActiveEmployees = (employees: Employee[]) => {
    return employees.filter(
      employee =>
        subscribedEmployees.some(
          subscribedEmp => subscribedEmp.id === employee.id
        ) || employee.isActive
    );
  };

  const filterSubscribedEmployees = (employees: Employee[]) => {
    return employees.filter(employee =>
      subscribedEmployees.some(
        subscribedEmp => subscribedEmp.id === employee.id
      )
    );
  };

  const removeUnsubscribedAndInactiveEmployeesFromGroups = (
    groups: EmployeeGroup[]
  ) => {
    return groups.map((group: EmployeeGroup) => ({
      ...group,
      employees: filterSubscribedAndActiveEmployees(group.employees)
    }));
  };

  const handleGroupSelection = (isChecked: boolean, groupId: string) => {
    const selectedGroup = employeeGroups.find(group => group.id === groupId);
    if (selectedGroup) {
      let groups = groupAssignment.groups.slice();
      if (isChecked) {
        groups.push({
          groupId: selectedGroup.id,
          employeeIds: filterSubscribedEmployees(selectedGroup.employees).map(
            employee => employee.id
          )
        });
      } else {
        groups = groups.filter(group => group.groupId !== groupId);
      }

      setGroupAssignment({ ...groupAssignment, groups });
      setAssignedGroups(groups);
    }
  };

  const handleSearch = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const filteredGroups = employeeGroups.filter(group =>
        group.name.toLowerCase().includes(value.toLowerCase())
      );
      const sortedGroups = sortGroups(sortOrder, filteredGroups);
      setEmployeeGroupsToShow(
        removeUnsubscribedAndInactiveEmployeesFromGroups(sortedGroups)
      );
    }, 1000);
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setApplyToAllChecked(checked);

    if (!checked) {
      setGroupAssignment({ ...groupAssignment, groups: [] });
      setAssignedGroups([]);
    } else {
      const allGroups: EmployeeGroupInfo[] = employeeGroups
        .filter(
          group =>
            !groupAssignmentDefinitions?.some(
              definition => definition.employeeGroupId === group.id
            )
        )
        .map(group => ({
          groupId: group.id,
          employeeIds: filterSubscribedEmployees(group.employees).map(
            employee => employee.id
          )
        }));
      setGroupAssignment({ ...groupAssignment, groups: allGroups });
      setAssignedGroups(allGroups);
    }
  };

  const handleSortChange = (e: any) => {
    setSortOrder(parseInt(e.target.value));
  };

  const sortGroups = (option: number, groupsToSort: EmployeeGroup[]) => {
    switch (option) {
      case SortEmployeeOption.Assigned:
        return orderByAssigned(groupsToSort).reverse();
      case SortEmployeeOption.Unassigned:
        return orderByAssigned(groupsToSort);
      case SortEmployeeOption.Az:
        return groupsToSort
          .slice()
          .sort((a, b) => a.name.trim().localeCompare(b.name.trim()));
      case SortEmployeeOption.Za:
        return groupsToSort
          .slice()
          .sort((a, b) => a.name.trim().localeCompare(b.name.trim()))
          .reverse();
      default:
        return groupsToSort;
    }
  };

  const orderByAssigned = (groups: EmployeeGroup[]) => {
    const assignedGroupList = groups.slice().map(group => {
      return {
        group,
        assigned: assignedGroups.some(
          assignedGroup => assignedGroup.groupId === group.id
        )
      };
    });
    const sorted = assignedGroupList.sort(
      (a, b) => Number(a.assigned) - Number(b.assigned)
    );

    return sorted.map(x => x.group);
  };

  const handleOnChangeAssignBy = (values: string[]) => {
    const value = values[0];
    setGroupAssignment({ ...groupAssignment, assignBy: value });
  };

  const assignLabel = (() => {
    const objectAssignment: any = groupAssignment;
    let label = '';

    if (objectAssignment.learningPlanId !== undefined)
      label = t('learningPlan', { ns: 'learningPlans' });
    else if (objectAssignment.lessonId !== undefined)
      label = t('lesson', { ns: 'lessons' });
    else if (objectAssignment.courseId !== undefined)
      label = t('course', { ns: 'courses' });
    return label;
  })();

  const boxContainerStyle: SxProps = {
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '1rem 0 !important',
    borderRadius: '10px',
    backgroundColor: theme.palette.common.black
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container direction="column">
        <Grid item>
          <FormLabel>
            <InfoBubble
              placement="right"
              text={t('groupsToAssignInfoBubble', { ns: 'assignments' })}
            />
            {t('selectGroupsToAssign', { ns: 'assignments' })}
          </FormLabel>
        </Grid>
        <Box sx={boxContainerStyle}>
          <Grid item sx={containerStyle}>
            <SearchBox
              id="searchGroups"
              value=""
              name="searchGroups"
              width="250px"
              height="38px"
              placeholderText={t('search', { ns: 'common' })}
              variant="dark"
              onChangeValue={handleSearch}
            />
            <FormCheckBox
              onChange={handleCheckBoxChange}
              checked={applyToAllChecked}
              label={t('assignToAllGroups', { ns: 'assignments' })}
            />
          </Grid>
          <Grid
            item
            sx={{
              textAlign: 'right',
              paddingRight: '1rem',
              position: 'relative'
            }}
          >
            <TextDropdownList
              onChange={handleSortChange}
              label={`${t('sort', { ns: 'common' })}:`}
              items={[
                {
                  value: SortEmployeeOption.Assigned,
                  text: t('assigned', { ns: 'common' })
                },
                {
                  value: SortEmployeeOption.Unassigned,
                  text: t('unassigned', { ns: 'common' })
                },
                { value: SortEmployeeOption.Az, text: 'a-z' },
                { value: SortEmployeeOption.Za, text: 'z-a' }
              ]}
            />
          </Grid>
          <Grid
            item
            sx={{
              textAlign: 'center'
            }}
          >
            <>
              {!!employeeGroupsToShow && !!groupAssignmentDefinitions && (
                <GroupList
                  employeeGroups={employeeGroupsToShow}
                  groupAssignmentDefinitions={groupAssignmentDefinitions}
                  assignedGroups={assignedGroups}
                  onGroupSelection={handleGroupSelection}
                />
              )}
              {!employeeGroupsToShow ||
                (!groupAssignmentDefinitions && <CircularProgress />)}
            </>
          </Grid>
        </Box>
        <Grid item paddingTop="1rem">
          <>
            <FormLabel>
              <InfoBubble
                placement="right"
                text={t('assignByInfoBubble', { ns: 'assignments' })}
              />
              {`${t('assign', { ns: 'common' })} ${assignLabel} ${t('by', {
                ns: 'common'
              })}`}
              <span className="text-danger">*</span>
            </FormLabel>
            <BasicSelect
              labelId="assignBy"
              id="assignBy"
              options={assignByOptions}
              defaultValue=""
              placeholder={t('select', { ns: 'common' })}
              multiple={false}
              value={groupAssignment.assignBy ? [groupAssignment.assignBy] : []}
              handleOnChange={handleOnChangeAssignBy}
              theme="dark"
            />
          </>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const containerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '1rem',
  paddingRight: '1rem'
};

export default AssignGroupsPanel;

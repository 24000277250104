import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import ModalSkillGrid from './modals/ModalSkillGrid';
import {
  getContentNameColumn,
  getRemoveColumn
} from './utils/utilSkillsColumnDefinition';
import { LinkedContentBase } from '../../entities/Achievements';
import ConfirmationModal from '../../components/core/ConfirmationModal/ConfirmationModal';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { actionCreators as achievementsActions } from '../../actions/achievements';
import { CircularProgress, Grid, Stack } from '@mui/material';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useAppDispatch } from '../../store/hooks';
interface OverdueListProps {
  contentInfo?: LinkedContentBase[];
  skillSelectedId: string;
  setLinkedContent: Dispatch<SetStateAction<LinkedContentBase[]>>;
  numberOfContent: number;
}

const LinkedContentList = ({
  contentInfo,
  skillSelectedId,
  setLinkedContent,
  numberOfContent
}: OverdueListProps) => {
  const { t } = useTranslation(['skills', 'settings']);
  const dispatch = useAppDispatch();
  const [contentLinkedToDelete, setContentLinkedToDelete] =
    useState<LinkedContentBase | null>(null);
  const onDeletePressed = (linkedContentToDelete: LinkedContentBase) => {
    setContentLinkedToDelete(linkedContentToDelete);
  };
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('ContentName', { ns: 'skills' })),
    getRemoveColumn(t('remove', { ns: 'skills' }), onDeletePressed)
  ])();

  const handleDeleteLinkedContent = async () => {
    if (contentLinkedToDelete?.id) {
      setIsDeleting(true);
      await dispatch<Promise<void>>(
        achievementsActions.deleteCompetenceContentLinked(
          contentLinkedToDelete,
          skillSelectedId
        )
      );
      const newContentInfo = contentInfo?.filter(
        x => x.id !== contentLinkedToDelete.id
      );
      setLinkedContent(newContentInfo!);
      setContentLinkedToDelete(null);
      setIsDeleting(false);
    }
  };
  useEffect(() => {
    return () => {
      if (numberOfContent !== contentInfo?.length) {
        dispatch(achievementsActions.getCompetenceDetail(skillSelectedId));
      }
    };
  }, [contentInfo]);
  return (
    <>
      <ModalSkillGrid rows={contentInfo} columns={columns} />
      <ConfirmationModal
        show={!!contentLinkedToDelete}
        showSave={false}
        showCancel={false}
        maxWidth="xs"
        onSave={handleDeleteLinkedContent}
        onCancel={() => {
          setContentLinkedToDelete(null);
        }}
      >
        <Stack spacing={3}>
          <span>
            {t('remove', { ns: 'skills' }) + ' ' + contentLinkedToDelete?.name}
          </span>
          <Stack direction="row" spacing={3} sx={{ justifyContent: 'center' }}>
            {isDeleting ? (
              <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <BasicButton
                  onClick={() => {
                    setContentLinkedToDelete(null);
                  }}
                  color="secondary"
                >
                  {t('cancel', { ns: 'settings' })}
                </BasicButton>
                <BasicButton
                  onClick={handleDeleteLinkedContent}
                  color="confirm"
                >
                  {t('remove', { ns: 'skills' })}
                </BasicButton>
              </>
            )}
          </Stack>
        </Stack>
      </ConfirmationModal>
    </>
  );
};

export default LinkedContentList;

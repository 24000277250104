import EmployeesCertificationsModal from '../../EmployeesCertificationsModal/EmployeesCertificationsModal';
import { Box, Grid, Typography } from '@mui/material';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

interface AwardEmployeeCertificateOverrideModalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  handleContinue: () => void;
  handleBack: () => void;
}

const AwardEmployeeCertificateOverrideModal = ({
  openModal,
  handleCloseModal,
  handleContinue,
  handleBack
}: AwardEmployeeCertificateOverrideModalProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  return (
    <EmployeesCertificationsModal
      showModal={openModal}
      onCloseModal={handleCloseModal}
      title={t('employeeCertificateOverride', { ns: 'certifications' })}
      maxWidth={'md'}
      height={'320px'}
      backdropColor="transparent"
    >
      <Grid
        display={'flex'}
        justifyContent={'center'}
        sx={{ padding: '25px 60px 20px 60px' }}
        direction={'column'}
        gap={4}
        container
      >
        <Typography>
          {t('awardConfirmationOverride', { ns: 'certifications' })}
        </Typography>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          sx={{ paddingTop: '25px' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <BasicButton
            color="primary"
            style={{
              height: '45px',
              width: '88px',
              minWidth: '80px'
            }}
            data-testid="overrideModalNextButtonTestId"
            data-trackid="overrideModalNextButtonTrackId"
            onClick={handleContinue}
          >
            {t('continue', { ns: 'certifications' })}
          </BasicButton>
          <BasicButton
            color="primaryOutlined"
            style={{
              width: '88px',
              minWidth: '80px',
              height: '43px'
            }}
            onClick={handleBack}
          >
            {t('back', { ns: 'certifications' })}
          </BasicButton>
        </Box>
      </Grid>
    </EmployeesCertificationsModal>
  );
};

export default AwardEmployeeCertificateOverrideModal;

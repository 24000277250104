import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actionCreators as analyticsActions } from '../actions/analytics';
import { ApplicationState } from '../store';
import {
  AnalyticsCurrentAssignmentsForGroups,
  AnalyticsEmployeeCompletedAssignment,
  AnalyticsEmployeeOverdueAssignment,
  AnalyticsEmployeeCurrentAssignment,
  AnalyticsGroupCompletedAssigments,
  AnalyticsEmployeeSelfAssignment
} from '../entities/Analytics';
import { sortByKey } from '../utils/sorting';
import {
  upperCaseFirstLetter,
  lowerCaseFirstLetter
} from '../utils/stringUtils';
import { AnalyticsFilterContext } from '../contexts/AnalyticsContext';
import {
  downloadAnalyticsGroupCurrentAssignmentReport,
  downloadAnalyticsEmployeeCompletedAssignmentReport,
  downloadAnalyticsEmployeeOverdueAssignmentReport,
  downloadAnalyticsEmployeeCurrentAssignmentReport,
  downloadAnalyticsGroupCompletedAssignmentReport,
  downloadAnalyticsEmployeeSelfAssignmentReport
} from '../services/report-service';
import { absUtcDateToLocalMoment } from '../utils/dateUtils';
import { EnrollmentTypes } from '../core/constants';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsCommonFilterContext } from '../contexts/AnalyticsContext';
import { useAppDispatch } from '../store/hooks';

export const useAnalyticsEngagementModal = (
  modalType:
    | 'groupCurrentAssignments'
    | 'employeeCompletedAssignments'
    | 'employeeOverdueAssignments'
    | 'employeeCurrentAssignments'
    | 'groupCompletedAssignments'
    | 'employeeSelfAssignments',
  groupId?: string,
  employeeId?: string
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['analytics', 'assignments']);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<string>();
  const [learningUnitType, setLearningUnitType] = useState<string>('');
  const [dueDate, setDueDate] = useState<Date>();
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const context = useContext(AnalyticsFilterContext);
  const { groupId: filterGroupId, supervisorId } = useContext(
    AnalyticsCommonFilterContext
  );
  const {
    includeSelfAssignments,
    activeOnly,
    subscribedOnly,
    employeesGroupId
  } = context;
  const groupCurrentAssignmentsData = useSelector(
    (state: ApplicationState) => state?.analytics?.groupCurrentAssignmentsData
  );
  const employeeCompletedAssignmentsData = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.employeeCompletedAssignmentsData
  );
  const employeeOverdueAssignmentsData = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.employeeOverdueAssignmentsData
  );
  const employeeCurrentAssignmentsData = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.employeeCurrentAssignmentsData
  );
  const groupCompletedAssignmentsData = useSelector(
    (state: ApplicationState) => state?.analytics?.groupCompletedAssignmentsData
  );
  const employeeSelfAssignmentsData = useSelector(
    (state: ApplicationState) => state?.analytics?.employeeSelfAssignmentsData
  );

  useEffect(() => {
    switch (modalType) {
      case 'groupCurrentAssignments':
        if (groupId) {
          dispatch(
            analyticsActions.getGroupCurrentAssignmentsData(
              groupId,
              includeSelfAssignments,
              activeOnly,
              subscribedOnly
            )
          );
          setOpenModal(true);
        }
        break;
      case 'employeeCompletedAssignments':
        if (employeeId) {
          dispatch(
            analyticsActions.getEmployeeCompletedAssignmentsData(
              employeeId,
              includeSelfAssignments
            )
          );
          setOpenModal(true);
        }
        break;
      case 'employeeOverdueAssignments':
        if (employeeId) {
          dispatch(
            analyticsActions.getEmployeeOverdueAssignmentsData(
              employeeId,
              includeSelfAssignments
            )
          );
          setOpenModal(true);
        }
        break;
      case 'employeeCurrentAssignments':
        if (employeeId) {
          dispatch(
            analyticsActions.getEmployeeCurrentAssignmentsData(
              employeeId,
              includeSelfAssignments
            )
          );
          setOpenModal(true);
        }
        break;
      case 'groupCompletedAssignments':
        if (groupId) {
          dispatch(
            analyticsActions.getGroupCompletedAssignmentsData(
              groupId,
              includeSelfAssignments,
              activeOnly,
              subscribedOnly
            )
          );
          setOpenModal(true);
        }
        break;
      case 'employeeSelfAssignments':
        if (employeeId) {
          dispatch(analyticsActions.getEmployeeSelfAssignmentsData(employeeId));
          setOpenModal(true);
        }
        break;
      default:
        break;
    }
  }, [groupId, employeeId]);

  const sortOptions = (() => {
    const optionListBase = [
      { label: t('lessons', { ns: 'analytics' }), value: 'lesson' },
      { label: t('courses', { ns: 'analytics' }), value: 'course' },
      { label: t('learningPlans', { ns: 'analytics' }), value: 'learningPlan' },
      { label: t('ascending', { ns: 'analytics' }), value: 'ascending' },
      { label: t('decending', { ns: 'analytics' }), value: 'decending' }
    ];

    switch (modalType) {
      case 'groupCurrentAssignments':
        optionListBase.push({
          label: t('assignedDate', { ns: 'analytics' }),
          value: 'assignedDate'
        });
        break;
      case 'employeeOverdueAssignments':
        optionListBase.push({
          label: t('dueDate', { ns: 'analytics' }),
          value: 'dueDate'
        });
        break;
      case 'employeeCompletedAssignments':
      case 'groupCompletedAssignments':
        optionListBase.push({
          label: t('completionDate', { ns: 'analytics' }),
          value: 'completionDate'
        });
        break;
      case 'employeeCurrentAssignments':
        optionListBase.push({
          label: t('assignedDate', { ns: 'analytics' }),
          value: 'assignedDate'
        });
        optionListBase.push({
          label: t('pending', { ns: 'analytics' }),
          value: 'pending'
        });
        optionListBase.push({
          label: t('overdue', { ns: 'analytics' }),
          value: 'overdue'
        });
        optionListBase.push({
          label: t('workloadDueDate', { ns: 'analytics' }),
          value: 'workloadDueDate'
        });
        break;
    }

    return optionListBase;
  })();

  const handleSorting = (option: string) => {
    let sortedData: any;
    switch (modalType) {
      case 'groupCurrentAssignments':
        sortedData = handleSortingData<AnalyticsCurrentAssignmentsForGroups>(
          option,
          groupCurrentAssignmentsData
        );
        dispatch(analyticsActions.setGroupCurrentAssignmentsData(sortedData));
        break;
      case 'employeeCompletedAssignments':
        sortedData = handleSortingData<AnalyticsEmployeeCompletedAssignment>(
          option,
          employeeCompletedAssignmentsData
        );
        dispatch(
          analyticsActions.setEmployeeCompletedAssignmentsData(sortedData)
        );
        break;
      case 'employeeOverdueAssignments':
        sortedData = handleSortingData<AnalyticsEmployeeOverdueAssignment>(
          option,
          employeeOverdueAssignmentsData
        );
        dispatch(
          analyticsActions.setEmployeeOverdueAssignmentsData(sortedData)
        );
        break;
      case 'employeeCurrentAssignments':
        sortedData = handleSortingData<AnalyticsEmployeeCurrentAssignment>(
          option,
          employeeCurrentAssignmentsData
        );
        dispatch(
          analyticsActions.setEmployeeCurrentAssignmentsData(sortedData)
        );
        break;
      case 'groupCompletedAssignments':
        sortedData = handleSortingData<AnalyticsGroupCompletedAssigments>(
          option,
          groupCompletedAssignmentsData
        );
        dispatch(analyticsActions.setGroupCompletedAssignmentsData(sortedData));
        break;
      case 'employeeSelfAssignments':
        sortedData = handleSortingData<AnalyticsEmployeeSelfAssignment>(
          option,
          employeeSelfAssignmentsData
        );
        dispatch(analyticsActions.setEmployeeSelfAssignmentsData(sortedData));
        break;
      default:
        break;
    }
  };

  const handleSortingData = <T>(option: string, data: any) => {
    switch (option) {
      case 'lesson':
      case 'course':
      case 'learningPlan': {
        const filtered = data.filter(
          (item: any) => item.learningUnitType === upperCaseFirstLetter(option)
        );
        const remainig = data.filter(
          (item: any) => item.learningUnitType !== upperCaseFirstLetter(option)
        );
        return filtered
          .sort(sortByKey<T>('learningUnitName' as keyof T))
          .concat(remainig);
      }
      case 'ascending':
        return data.slice().sort(sortByKey<T>('learningUnitName' as keyof T));
      case 'decending':
        return data
          .slice()
          .sort(sortByKey<T>('learningUnitName' as keyof T))
          .reverse();
      case 'assignedDate':
        return data
          .slice()
          .sort(sortByKey<T>('assignedOn' as keyof T))
          .reverse();
      case 'completionDate':
        return data
          .slice()
          .sort(sortByKey<T>('completionDate' as keyof T))
          .reverse();
      case 'dueDate':
        return data
          .slice()
          .sort(sortByKey<T>('dueDate' as keyof T))
          .reverse();
      case 'pending': {
        const pendings = data.filter((item: any) => !item.isOverdue);
        return pendings
          .sort(sortByKey<T>('learningUnitName' as keyof T))
          .concat(data.filter((item: any) => item.isOverdue));
      }
      case 'overdue': {
        const overdues = data.filter((item: any) => item.isOverdue);
        return overdues
          .sort(sortByKey<T>('learningUnitName' as keyof T))
          .concat(data.filter((item: any) => !item.isOverdue));
      }
      case 'workloadDueDate': {
        const workloads = data.filter(
          (item: any) => item.assignmentType === 'Workload'
        );
        const dueDates = data.filter(
          (item: any) => item.assignmentType === EnrollmentTypes.DueDate
        );
        const noDueDates = data.filter(
          (item: any) => item.assignmentType === EnrollmentTypes.NoDueDate
        );
        const enrollmentTypeArray = [
          'Workload',
          EnrollmentTypes.DueDate,
          EnrollmentTypes.NoDueDate
        ];

        return workloads
          .sort(sortByKey<T>('learningUnitName' as keyof T))
          .concat(dueDates)
          .concat(noDueDates)
          .concat(
            data.filter(
              (item: any) => !enrollmentTypeArray.includes(item.assignmentType)
            )
          );
      }
      default: {
        return data.slice();
      }
    }
  };

  const closeModal = () => {
    switch (modalType) {
      case 'groupCurrentAssignments':
        dispatch(analyticsActions.setGroupCurrentAssignmentsData(null));
        break;
      case 'employeeCompletedAssignments':
        dispatch(analyticsActions.setEmployeeCompletedAssignmentsData(null));
        break;
      case 'employeeOverdueAssignments':
        dispatch(analyticsActions.setEmployeeOverdueAssignmentsData(null));
        break;
      case 'employeeCurrentAssignments':
        dispatch(analyticsActions.setEmployeeCurrentAssignmentsData(null));
        break;
      case 'groupCompletedAssignments':
        dispatch(analyticsActions.setGroupCompletedAssignmentsData(null));
        break;
      case 'employeeSelfAssignments':
        dispatch(analyticsActions.setEmployeeSelfAssignmentsData(null));
        break;
    }
  };

  const handleEditDueDate = (
    assignmentId: string,
    learningUnitType: string,
    dueDate: Date
  ) => {
    setAssignmentId(assignmentId);
    setLearningUnitType(learningUnitType);
    setDueDate(dueDate);
  };

  const handleCloseUpdateDueDateModal = () => {
    setAssignmentId(undefined);
    setLearningUnitType('');
    setDueDate(undefined);
  };

  const handleSuccessfulSaved = async () => {
    switch (modalType) {
      case 'groupCurrentAssignments':
        await dispatch<Promise<void>>(
          analyticsActions.getGroupCurrentAssignmentsData(
            groupId!,
            includeSelfAssignments,
            activeOnly,
            subscribedOnly
          )
        );
        break;
      case 'employeeOverdueAssignments':
        await dispatch<Promise<void>>(
          analyticsActions.getEmployeeOverdueAssignmentsData(
            employeeId!,
            includeSelfAssignments
          )
        );
        employeesGroupId
          ? await dispatch<Promise<void>>(
              analyticsActions.getGroupEngagementDetailData(
                employeesGroupId,
                includeSelfAssignments,
                activeOnly,
                subscribedOnly
              )
            )
          : await dispatch<Promise<void>>(
              analyticsActions.getEmployeeEngagementData(
                includeSelfAssignments,
                activeOnly,
                subscribedOnly,
                filterGroupId,
                supervisorId
              )
            );
        break;
      case 'employeeCurrentAssignments':
        await dispatch<Promise<void>>(
          analyticsActions.getEmployeeCurrentAssignmentsData(
            employeeId!,
            includeSelfAssignments
          )
        );
        break;
      default:
        break;
    }
  };

  const getCompletionInfo = (item: AnalyticsEmployeeCompletedAssignment) => {
    let completionInfo;

    if (
      item.assignmentType === EnrollmentTypes.DueDate &&
      item.isCompletedOnTime
    ) {
      completionInfo = t('completedOnTime', { ns: 'analytics' });
    } else if (
      item.assignmentType === EnrollmentTypes.DueDate &&
      !item.isCompletedOnTime
    ) {
      completionInfo = t('completedLate', { ns: 'analytics' });
    } else {
      completionInfo = t(lowerCaseFirstLetter(item.assignmentType), {
        ns: 'assignments'
      });
    }

    return completionInfo;
  };
  const handleDownloadReport = () => {
    if (downloadingReport) return;

    try {
      switch (modalType) {
        case 'groupCurrentAssignments':
          if (groupCurrentAssignmentsData) {
            setDownloadingReport(true);
            downloadAnalyticsGroupCurrentAssignmentReport(
              groupCurrentAssignmentsData
            );
          }
          break;
        case 'employeeCompletedAssignments':
          if (employeeCompletedAssignmentsData) {
            setDownloadingReport(true);
            const dataForReport = employeeCompletedAssignmentsData.map(
              (item: AnalyticsEmployeeCompletedAssignment) => ({
                assigmentName: item.learningUnitName,
                leaningUnitType: t(
                  lowerCaseFirstLetter(item.learningUnitType),
                  { ns: 'assignments' }
                ),
                assignedOn: absUtcDateToLocalMoment(item.assignedOn).format(
                  'MM.DD.YY'
                ),
                completionDate: absUtcDateToLocalMoment(
                  item.completionDate
                ).format('MM.DD.YY'),
                completionInfo: getCompletionInfo(item)
              })
            );
            downloadAnalyticsEmployeeCompletedAssignmentReport(dataForReport);
          }
          break;
        case 'employeeOverdueAssignments':
          if (employeeOverdueAssignmentsData) {
            setDownloadingReport(true);
            const dataForReport = employeeOverdueAssignmentsData.map(
              (item: AnalyticsEmployeeOverdueAssignment) => ({
                assigmentName: item.learningUnitName,
                leaningUnitType: t(
                  lowerCaseFirstLetter(item.learningUnitType),
                  { ns: 'assignments' }
                ),
                assignedOn: absUtcDateToLocalMoment(item.assignedOn).format(
                  'MM.DD.YY'
                ),
                dueDate: absUtcDateToLocalMoment(item.dueDate).format(
                  'MM.DD.YY'
                )
              })
            );
            downloadAnalyticsEmployeeOverdueAssignmentReport(dataForReport);
          }
          break;
        case 'employeeCurrentAssignments':
          if (employeeCurrentAssignmentsData) {
            setDownloadingReport(true);
            const dataForReport = employeeCurrentAssignmentsData.map(
              (item: AnalyticsEmployeeCurrentAssignment) => ({
                assigmentName: item.learningUnitName,
                leaningUnitType: t(
                  lowerCaseFirstLetter(item.learningUnitType),
                  { ns: 'assignments' }
                ),
                assignedOn: absUtcDateToLocalMoment(item.assignedOn).format(
                  'MM.DD.YY'
                ),
                completionStatus: item.isOverdue
                  ? t('overdue', { ns: 'analytics' })
                  : t('pending', { ns: 'analytics' }),
                dueDate:
                  item.assignmentType === EnrollmentTypes.DueDate
                    ? absUtcDateToLocalMoment(item.dueDate).format('MM.DD.YY')
                    : t(lowerCaseFirstLetter(item.assignmentType), {
                        ns: 'assignments'
                      })
              })
            );
            downloadAnalyticsEmployeeCurrentAssignmentReport(dataForReport);
          }
          break;
        case 'groupCompletedAssignments':
          if (groupCompletedAssignmentsData) {
            setDownloadingReport(true);
            const dataForReport = groupCompletedAssignmentsData.map(
              (item: AnalyticsGroupCompletedAssigments) => ({
                assigmentName: item.learningUnitName,
                leaningUnitType: t(
                  lowerCaseFirstLetter(item.learningUnitType),
                  { ns: 'assignments' }
                ),
                completedBy: item.employeeName,
                completionOn: absUtcDateToLocalMoment(
                  item.completionDate
                ).format('MM.DD.YY')
              })
            );
            downloadAnalyticsGroupCompletedAssignmentReport(dataForReport);
          }
          break;
        case 'employeeSelfAssignments':
          if (employeeSelfAssignmentsData) {
            setDownloadingReport(true);
            const dataForReport = employeeSelfAssignmentsData.map(
              (item: AnalyticsEmployeeSelfAssignment) => ({
                assigmentName: item.learningUnitName,
                leaningUnitType: t(
                  lowerCaseFirstLetter(item.learningUnitType),
                  { ns: 'assignments' }
                ),
                assignedOn: absUtcDateToLocalMoment(item.assignedOn).format(
                  'MM.DD.YY'
                ),
                completionDate:
                  moment(item.completionDate).year() === 1
                    ? absUtcDateToLocalMoment(item.completionDate).format(
                        'MM.DD.YY'
                      )
                    : 'Pending'
              })
            );
            downloadAnalyticsEmployeeSelfAssignmentReport(dataForReport);
          }
          break;
        default:
          break;
      }
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setDownloadingReport(false);
    }
  };

  return {
    state: {
      sortOptions,
      openModal,
      assignmentId,
      dueDate,
      learningUnitType,
      groupCurrentAssignmentsData,
      employeeCompletedAssignmentsData,
      employeeOverdueAssignmentsData,
      employeeCurrentAssignmentsData,
      groupCompletedAssignmentsData,
      employeeSelfAssignmentsData
    },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved,
      handleDownloadReport
    }
  };
};

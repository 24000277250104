import { useTranslation } from 'react-i18next';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { useState } from 'react';
import { useBwBreakpoints } from '../../../hooks/useBwBreakpoints';
interface SearchInputProps {
  onSearch: (value: string) => void;
  defaultValue?: string;
}
const SearchInput: React.FC<SearchInputProps> = ({
  onSearch,
  defaultValue = ''
}) => {
  const { t } = useTranslation(['common']);
  const [value, setValue] = useState(defaultValue);
  const [is640] = useBwBreakpoints();
  const onChange = (searchValue: string) => {
    setValue(searchValue);
    onSearch(searchValue);
  };

  return (
    <SearchBox
      id="searchContent"
      name="searchContent"
      value={value}
      onChangeValue={onChange}
      placeholderText={t('search', { ns: 'common' })}
      width={is640 ? '100%' : '170px'}
      height="45px"
    />
  );
};

export default SearchInput;

import React from 'react';
import { SxProps } from '@mui/material/styles';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';

export interface SelectDurationProps {
  selectValue: string;
  id: string;
  labelId: string;
  disabled: boolean;
  onChange: (items: string[]) => void;
}

const SelectDuration: React.FC<SelectDurationProps> = ({
  onChange,
  selectValue,
  id,
  labelId,
  disabled
}) => {
  const value = selectValue ? [selectValue] : [];

  return (
    <BasicSelect
      labelId={labelId}
      disabled={disabled}
      id={id}
      options={[
        {
          label: 'Default',
          value: '0',
          disabled: false,
          hidden: false
        },
        {
          label: 'Expiration Date',
          value: '1',
          disabled: false,
          hidden: false
        },
        {
          label: 'Duration',
          value: '3',
          disabled: false,
          hidden: false
        },
        {
          label: 'Skill Never Expires',
          value: '2',
          disabled: false,
          hidden: false
        }
      ]}
      value={value}
      handleOnChange={onChange}
      placeholder="Skill Duration"
      theme="dark"
      multiple={false}
      sx={filterStyle}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '200px',
  width: '100%',
  height: '45px'
};

export default SelectDuration;

import useUserRole from './useUserRole';
import { UserRoles } from '../core/constants';

export const useCertifierRole = () => {
  const [userHasRole] = useUserRole();
  const isCertifier = userHasRole([UserRoles.Certifier]);
  const isAdminUser = userHasRole([UserRoles.CompanyAdmin]);
  const userIsAdminOrCertifier = isCertifier || isAdminUser;
  return [userIsAdminOrCertifier];
};

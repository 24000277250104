import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/student';

import {
  STUDENT_MARK_LESSON_AS_COMPLETED,
  STUDENT_SET_COURSE_COMPLETED_LESSONS,
  STUDENT_SET_IS_LOADING,
  STUDENT_SET_LEARNING_PLAY_LIST,
  STUDENT_SET_ALL_LEARNING_PLAY_LIST,
  STUDENT_UPDATE_TRANSACTION_STATUS,
  STUDENT_RESET_TRANSACTION_STATUS,
  STUDENT_SET_ASSIGNMENT,
  STUDENT_SET_COMPLETED_COURSES,
  STUDENT_SET_COMPLETED_LEARNING_PLANS,
  STUDENT_SET_LEARNING_PROFILE,
  STUDENT_WEEKLY_STATISTICS,
  STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO,
  STUDENT_SET_COMPLETED_COURSES_BASIC_INFO,
  STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO,
  STUDENT_SET_TAKEN_TIMES_LESSONS,
  STUDENT_SET_TAKEN_TIMES_COURSES,
  STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS,
  STUDENT_RECENTLY_WORKED_ON_PLAY_LIST,
  STUDENT_SET_DASHBOARD_OVERVIEW_DATA,
  STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA,
  STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA,
  STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA,
  STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA
} from '../actions/actionTypes';
import {
  CompletedLessonInfo,
  CompletedLessonBasicInfo,
  TakenTimesObject
} from '../entities/CompletedLessonInfo';
import {
  LearningPlaylistItems,
  AssignmentsPlaylistItems,
  EmployeePlayList
} from '../entities/LearningPlaylist';
import {
  DashboardContentType,
  DashboardContentTypeSubUsers,
  DashboardDetailsType,
  DashboardDetailsSubscribedUsersType,
  TransactionStatusEnum
} from '../core/enums';
import {
  CompletedCourseInfo,
  CompletedCourseBasicInfo
} from '../entities/CompletedCourseInfo';
import {
  CompletedLearningPlanInfo,
  CompletedLearningPlanBasicInfo
} from '../entities/CompletedLearningPlanInfo';
import { ResultDTO } from '../entities/Dto/responseDTO';
import { LearningProfile } from '../entities/LearningProfile';
import { WeeklyStatistics } from '../entities/WeeklyStatistics';
import { RecentlyWorkedOnPlaylistItems } from '../entities/RecentlyWorkedOnPlaylistItems';
import {
  AssignmentContentOverview,
  DashboardOverview,
  DashboardTopEmployees
} from '../entities/Dto/Dashboard';
import { Employee } from '../entities/Employee';
import { PagedResult } from '../entities/Common';

export interface StudentState {
  errorMessage: string;
  isLoading: boolean;
  completedLessons: CompletedLessonInfo[];
  learningPlayList: LearningPlaylistItems | null;
  employeesPlaylists: ResultDTO<EmployeePlayList[]> | null;
  transactionStatus: TransactionStatusEnum;
  assignmentsItems: AssignmentsPlaylistItems[] | null;
  completedCourses: CompletedCourseInfo[];
  completedLearningPlans: CompletedLearningPlanInfo[];
  learningProfile: LearningProfile | null;
  weeklyStatistics: WeeklyStatistics[] | null;
  completedLessonsBasicInfo: CompletedLessonBasicInfo[];
  completedCoursesBasicInfo: CompletedCourseBasicInfo[];
  completedLearningPlansBasicInfo: CompletedLearningPlanBasicInfo[];
  recentlyWorkedOnPlaylistItems: RecentlyWorkedOnPlaylistItems | null;
  takenTimesLessons: TakenTimesObject | null;
  takenTimesCourses: TakenTimesObject | null;
  takenTimesLearningPlans: TakenTimesObject | null;
  dashboardOverview: DashboardOverview | null;
  dashboardTopEmployees: DashboardTopEmployees | null;
  dashboardOverviewDetail: Record<
    DashboardContentType,
    PagedResult<AssignmentContentOverview> | null
  >;
  subscribedUsersDetail: Record<DashboardContentTypeSubUsers, Employee | null>;
}

const unloadedState: StudentState = {
  errorMessage: '',
  isLoading: false,
  completedLessons: [],
  learningPlayList: null,
  transactionStatus: TransactionStatusEnum.None,
  assignmentsItems: null,
  completedCourses: [],
  completedLearningPlans: [],
  employeesPlaylists: null,
  learningProfile: null,
  weeklyStatistics: null,
  completedLessonsBasicInfo: [],
  completedCoursesBasicInfo: [],
  completedLearningPlansBasicInfo: [],
  recentlyWorkedOnPlaylistItems: null,
  takenTimesLessons: null,
  takenTimesCourses: null,
  takenTimesLearningPlans: null,
  dashboardOverview: null,
  dashboardTopEmployees: null,
  dashboardOverviewDetail: {
    [DashboardDetailsType.TotalAssignedLesson]: null,
    [DashboardDetailsType.TotalOverdueLessons]: null,
    [DashboardDetailsType.TotalCompletedLessons]: null
  },
  subscribedUsersDetail: {
    [DashboardDetailsSubscribedUsersType.LearningUsers]: null,
    [DashboardDetailsSubscribedUsersType.NonLearningUsers]: null
  }
};

export const StudentReducer: Reducer<StudentState> = (
  state: StudentState | undefined,
  incomingAction: Action
): StudentState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case STUDENT_SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };

    case STUDENT_SET_COURSE_COMPLETED_LESSONS:
      return {
        ...state,
        completedLessons: action.completedLessons
      };
    case STUDENT_MARK_LESSON_AS_COMPLETED: {
      const lessonWithNewCompleted = state.completedLessons.filter(
        lesson => lesson.lessonId !== action.completedLesson.lessonId
      );
      lessonWithNewCompleted.push(action.completedLesson);

      return {
        ...state,
        completedLessons: lessonWithNewCompleted
      };
    }
    case STUDENT_SET_LEARNING_PLAY_LIST:
      return { ...state, learningPlayList: action.learningPlaylistIems };
    case STUDENT_SET_ALL_LEARNING_PLAY_LIST:
      return { ...state, employeesPlaylists: action.employeesPlaylists };
    case STUDENT_SET_COMPLETED_COURSES:
      return { ...state, completedCourses: action.completedCourses };
    case STUDENT_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case STUDENT_RESET_TRANSACTION_STATUS:
      return { ...state, transactionStatus: TransactionStatusEnum.None };
    case STUDENT_SET_ASSIGNMENT:
      return { ...state, assignmentsItems: action.assignmentsItems };
    case STUDENT_WEEKLY_STATISTICS:
      return { ...state, weeklyStatistics: action.weeklyStatistics };
    case STUDENT_SET_COMPLETED_LEARNING_PLANS:
      return {
        ...state,
        completedLearningPlans: action.completedLearningPlans
      };
    case STUDENT_SET_LEARNING_PROFILE:
      return { ...state, learningProfile: action.learningProfile };
    case STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO:
      return {
        ...state,
        completedLessonsBasicInfo: action.completedLessonsBasicInfo
      };
    case STUDENT_SET_COMPLETED_COURSES_BASIC_INFO:
      return {
        ...state,
        completedCoursesBasicInfo: action.completedCoursesBasicInfo
      };
    case STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO:
      return {
        ...state,
        completedLearningPlansBasicInfo: action.completedLearningPlansBasicInfo
      };
    case STUDENT_SET_TAKEN_TIMES_LESSONS:
      return { ...state, takenTimesLessons: action.takenTimesLessons };
    case STUDENT_SET_TAKEN_TIMES_COURSES:
      return { ...state, takenTimesCourses: action.takenTimesCourses };
    case STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS:
      return {
        ...state,
        takenTimesLearningPlans: action.takenTimesLearningPlans
      };
    case STUDENT_RECENTLY_WORKED_ON_PLAY_LIST:
      return {
        ...state,
        recentlyWorkedOnPlaylistItems: action.recentlyWorkedOnPlaylistItems
      };
    case STUDENT_SET_DASHBOARD_OVERVIEW_DATA:
      return {
        ...state,
        dashboardOverview: action.dashboardOverview
      };
    case STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA:
      return {
        ...state,
        dashboardTopEmployees: action.dashboardTopEmployees
      };
    case STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA:
      return {
        ...state,
        dashboardOverviewDetail: {
          ...state.dashboardOverviewDetail,
          [action.contentType]: action.content
        }
      };
    case STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA:
      return {
        ...state,
        subscribedUsersDetail: {
          ...state.subscribedUsersDetail,
          [action.contentType]: action.content
        }
      };
    case STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA:
      return {
        ...state,
        dashboardOverviewDetail: {
          [DashboardDetailsType.TotalAssignedLesson]: null,
          [DashboardDetailsType.TotalOverdueLessons]: null,
          [DashboardDetailsType.TotalCompletedLessons]: null
        }
      };
    default:
      return state;
  }
};

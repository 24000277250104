import { Stack, Typography, useTheme, styled, Chip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';

import {
  SvgSkillIcon,
  StyledCompetenceDetailContainer,
  StyledDetailSection,
  StyledSpanTitle,
  StyledStackContent
} from './SkillComponentsHelper';
import { CompetencePaginatedListItem } from '../../entities/Achievements';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import { downloadBlob } from '../../services/blobStorage-service';
import config from '../../config';

interface MySkillDetailProps {
  competenceItem?: CompetencePaginatedListItem;
}

const StyleChipFile = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.16)',
  color: theme.palette.common.white
}));

const MySkillDetail = ({ competenceItem }: MySkillDetailProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['skills']);

  const handleDownloadFile = (url: string, fileName: string) => {
    downloadBlob(url, fileName, config.BLOB_SKILLATTACHMENTS_CONTAINER!);
  };

  if (!competenceItem) {
    return (
      <StyledCompetenceDetailContainer
        sx={{
          display: 'flex'
        }}
      ></StyledCompetenceDetailContainer>
    );
  }

  const { name, description } = competenceItem.competence;
  const { expirationDate, employeeCompetenceAttachments } =
    competenceItem.employeeCompetence!;

  return (
    <StyledCompetenceDetailContainer>
      <StyledDetailSection>
        <StyledStackContent spacing={1}>
          <Typography sx={theme.typography.h5}>{name}</Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ color: '#929292', fontSize: '15px' }}
          >
            <SvgSkillIcon />
            <span>{t('skill')}</span>
          </Stack>
        </StyledStackContent>
      </StyledDetailSection>
      <StyledDetailSection>
        <StyledStackContent
          sx={{
            width: '100%',
            borderRight: `1px solid ${theme.palette.grey[100]}`
          }}
        >
          <StyledSpanTitle>{t('description')}</StyledSpanTitle>
          {description}
        </StyledStackContent>
        <StyledStackContent sx={{ width: '200px' }}>
          <StyledSpanTitle>{t('expirationDate')}</StyledSpanTitle>
          {expirationDate
            ? absUtcDateToLocalMoment(expirationDate).format('MM.DD.YYYY')
            : t('none')}
        </StyledStackContent>
      </StyledDetailSection>
      <StyledDetailSection sx={{ borderBottom: 'none' }}>
        <StyledStackContent spacing={1}>
          <StyledSpanTitle>{t('attachments')}</StyledSpanTitle>
          <StyledStackContent spacing={1} direction="row" sx={{ padding: 0 }}>
            {employeeCompetenceAttachments.map(file => {
              const { name, url } = file.competenceFile;

              return (
                <StyleChipFile
                  icon={<DownloadIcon />}
                  label={name}
                  onClick={() => handleDownloadFile(url, name)}
                />
              );
            })}
          </StyledStackContent>
        </StyledStackContent>
      </StyledDetailSection>
    </StyledCompetenceDetailContainer>
  );
};

export default MySkillDetail;

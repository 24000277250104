import { useContext, useState } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';
import { TypeOptions } from '../../../core/constants';
import { LearningUnitType } from '../../../hooks/useContentLibraryNavigation';

const useLearningUnitFilter = () => {
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const defaultInitialValue =
    userNavigation?.learningUnitFilter ?? TypeOptions[0].value;

  const [learningUnitType, setLearningUnitType] =
    useState<string>(defaultInitialValue);

  const handleChangeLearningUnitType = (items: string[]) => {
    const option = items[0] as LearningUnitType;
    userNavigationApi?.changeLearningUnit(option);
    setLearningUnitType(option);
  };

  return [learningUnitType, handleChangeLearningUnitType] as const;
};

export default useLearningUnitFilter;

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Box, Grid, Typography } from '@mui/material';
import { AsteriskIcon, Pill } from '@buildwitt/component-library';
import { EmployeeCertification } from '../../../entities/EmployeeCertification';
import { TemporalNewId } from '../../../core/constants';

export const getEmployeesFullName = (
  headerName: string,
  pillLabel: string,
  pillShowIcon: boolean,
  width: number,
  maxWidth: number,
  flex: number
) => ({
  headerName: headerName,
  field: 'employeesCertified',
  renderCell: (params: GridRenderCellParams<EmployeeCertification>) => (
    <Grid
      container
      display={'flex'}
      style={{ width: '100%' }}
      justifyContent={'space-between'}
      direction={'row'}
      alignItems={'center'}
    >
      <Grid item>
        <Typography>{`${params.row.employeeFullName}`}</Typography>
      </Grid>
      {params.row.certificationId !== TemporalNewId && (
        <Grid item>
          {!pillShowIcon ? (
            <Pill
              id={params.row.employeeId}
              label={pillLabel}
              color="success"
            />
          ) : (
            <Pill
              id={params.row.employeeId}
              label={''}
              color="success"
              icon={
                <Box
                  style={{
                    position: 'relative',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-60%, -50%)'
                  }}
                >
                  <AsteriskIcon />
                </Box>
              }
            />
          )}
        </Grid>
      )}
    </Grid>
  ),
  flex: flex,
  width: width,
  maxWidth: maxWidth
});

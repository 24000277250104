import { ListChildComponentProps } from 'react-window';
import { LearningPlan } from '../../../entities/LearningPlan';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { restrictedLpShouldShow } from '../../../utils/lessonUtils';
import Grid from '@mui/material/Grid';
import React from 'react';
import LearningPlanRowWrapper from '../common/LearningPlanRowWrapper';

interface LearningPlanVirtualRowProps
  extends ListChildComponentProps<LearningPlan[][]> {
  children: (learningPlan: LearningPlan) => React.ReactNode;
}

const LearningPlanVirtualRow: React.FC<LearningPlanVirtualRowProps> = ({
  data,
  index,
  style,
  children
}) => {
  const user = useCurrentUser();
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="parentContainer">
        {data[index]
          .filter(x => restrictedLpShouldShow(x, userRoles))
          .map(learningPlan => {
            return (
              <LearningPlanRowWrapper key={learningPlan.id}>
                {children(learningPlan)}
              </LearningPlanRowWrapper>
            );
          })}
      </Grid>
    </div>
  );
};

export default LearningPlanVirtualRow;

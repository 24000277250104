import Box from '@mui/material/Box';
import { t } from 'i18next';
import { EnrollmentTypes } from '../../core/constants';
import { AssignedLesson } from '../../entities/LearningPlaylist';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import NewLessonCard from './NewLessonCard';
import { divButtonStyle, lessonWrapperStyle } from './CommonStyles';
import useLessonCard from './useLessonCard';
import ButtonsSelfAssignSection from '../AssignmentsButtonsSection/ButtonsSelfAssignSection';
import { Grid } from '@mui/material';

interface CompleteLessonCardProps {
  assignedLesson: AssignedLesson;
}

const CompleteLessonCard: React.FC<CompleteLessonCardProps> = ({
  assignedLesson
}) => {
  const { handlePlayLesson, takenTimesLessons } = useLessonCard();
  const { lesson, dueDateMoment, dueDateUtc } = assignedLesson;
  const { id, companyId, title } = lesson;
  return (
    <Box sx={lessonWrapperStyle}>
      <NewLessonCard
        key={id}
        lesson={lesson}
        lessonCardType={'Assigned'}
        showCompletedAsNormal={true}
        isComplete={true}
        showEdit={false}
        dueDate={dueDateUtc}
        height="327px"
        takenTimes={takenTimesLessons ? takenTimesLessons[id] : undefined}
      >
        <Grid style={divButtonStyle} position="absolute" bottom="18px">
          <div style={{ alignSelf: 'flex-end' }}>
            {assignedLesson.assignmentType === EnrollmentTypes.DueDate && (
              <InfoBySpan
                leftText={t('dueDate', { ns: 'assignments' })}
                rightText={
                  dueDateMoment ? dueDateMoment!.format('MM.DD.YY') : ''
                }
                showProfilePicture={false}
                isOverdue={false}
                showAsComplete={true}
              />
            )}
          </div>
          <ButtonsSelfAssignSection
            onClickStart={() => handlePlayLesson(lesson)}
            startButtonCaption={t('reenroll', { ns: 'common' })}
            startButtonColor={'revisit'}
            resourceType={'Lesson'}
            id={id}
            companyId={companyId}
            title={title}
          />
        </Grid>
      </NewLessonCard>
    </Box>
  );
};

export default CompleteLessonCard;

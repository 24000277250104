import { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { EmployeeStatsDto } from '../../entities/Dto/Dashboard';
import { SxProps } from '@mui/system';
import Grid from '@mui/material/Grid';

const StyledListItem = styled(ListItem)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#282828'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#1B1C1C'
  },
  '&:last-child': {
    borderRadius: '14px'
  },
  height: 65
}));

const ListEmployee = ({ employees }: { employees?: EmployeeStatsDto[] }) => {
  if (employees === undefined) {
    return <Typography sx={loadingStyle}>loading...</Typography>;
  }
  return (
    <List style={styledListStyle}>
      {employees?.map((row, index) => (
        <StyledListItem
          key={row.id}
          secondaryAction={
            <Typography style={completeLessonsStyle}>
              {row.completedCount}
            </Typography>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <img
                className={`img-profile rounded-circle my-4`}
                style={{ border: '2px solid white' }}
                width="40"
                height="40"
                alt={`${row.firstName} ${row.lastName}`}
                src={row.photoUrl ?? require('../../assets/user.png')}
              />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography style={nameStyle}>
                      {`${row.firstName} ${row.lastName}`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography style={jobTitleStyle}>
                      {`${row.title}`}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          />
        </StyledListItem>
      ))}
    </List>
  );
};

const loadingStyle: SxProps = {
  fontSize: '36px',
  color: '#FF9E00',
  margin: '20px'
};

const nameStyle: CSSProperties = {
  color: '#FFFFFFDE',
  fontSize: '14px',
  wordWrap: 'break-word',
  width: 'calc(80%)'
};

const jobTitleStyle: CSSProperties = {
  color: '#C4C4C4',
  fontSize: '12px',
  wordWrap: 'break-word'
};

const completeLessonsStyle: CSSProperties = {
  color: '#FFFFFFDE',
  fontSize: '24px'
};

const styledListStyle: CSSProperties = {
  paddingTop: '0px',
  paddingBottom: '0px'
};

export default ListEmployee;

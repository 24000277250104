import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useNavigate } from 'react-router-dom';

export interface CompletedCourseNoticeProps {
  nextCourseAssignmentId?: string;
  contextLearningPlanAssignmentId?: string;
  onClose: () => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const CompleteCourseNoticeModal: React.FC<CompletedCourseNoticeProps> = ({
  nextCourseAssignmentId,
  contextLearningPlanAssignmentId,
  onClose
}) => {
  const theme = useTheme();
  const defaultDialogWidth: DialogProps['maxWidth'] = 'md';
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  const onNextCourseClick = () => {
    navigate(`/courses/assignment/play/${nextCourseAssignmentId!}`);
  };

  const onBackToLearningPlanClick = () => {
    navigate(
      `/learning-plans/assignment/play/${contextLearningPlanAssignmentId}`
    );
  };

  const shouldShowContinueOnly =
    !nextCourseAssignmentId && !contextLearningPlanAssignmentId;

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
    >
      <DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h4 style={{ textAlign: 'center' }}>
          {t('congratulationsFinishedCourse', { ns: 'common' })}
        </h4>
        {nextCourseAssignmentId && (
          <p style={{ textAlign: 'center' }}>
            {t('promptContinueNextCourse', { ns: 'common' })}
          </p>
        )}
      </DialogContent>
      <DialogActions>
        {shouldShowContinueOnly && (
          <BasicButton
            style={continueBtnStyle}
            color="primary"
            onClick={() => {
              navigate(`/assignments`);
            }}
          >
            {t('continue', { ns: 'common' })}
          </BasicButton>
        )}
        {contextLearningPlanAssignmentId && (
          <BasicButton
            style={continueBtnStyle}
            color="secondary"
            onClick={onBackToLearningPlanClick}
          >
            {t('backToLearningPlan', { ns: 'common' })}
          </BasicButton>
        )}
        {nextCourseAssignmentId && (
          <BasicButton
            style={continueBtnStyle}
            color="primary"
            onClick={onNextCourseClick}
          >
            {t('nextCourse', { ns: 'common' })}
          </BasicButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

const continueBtnStyle = {
  marginTop: '20px',
  width: '180px'
};

export default CompleteCourseNoticeModal;

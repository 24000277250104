import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import React, { CSSProperties } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export interface ThumbnailListViewProps {
  items: AzureBlobImageInfo[];
  onItemSelected: (item: any) => void;
  selectedImgUrl: string;
  maxContentHeight?: string;
  dataTestId?: string;
  dataTrackId?: string;
}

const selectedItemStyle: CSSProperties = {
  borderColor: '#FF9E00',
  borderWidth: '5px',
  borderStyle: 'solid'
};

export const ThumbnailListView: React.FC<ThumbnailListViewProps> = ({
  items,
  onItemSelected,
  selectedImgUrl,
  maxContentHeight,
  dataTestId,
  dataTrackId
}: ThumbnailListViewProps) => {
  const [thumbs, setThumbs] = React.useState<AzureBlobImageInfo[]>([]);
  const [selectedItem, setSelectedItem] =
    React.useState<AzureBlobImageInfo | null>(null);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    setThumbs(items);
    const selItem = items.find(x => x.url === selectedImgUrl);
    if (selItem) {
      setSelectedItem(selItem);
    }
  }, [items, setThumbs, setSelectedItem, selectedImgUrl]);

  const handleSelectItem = (item: AzureBlobImageInfo) => {
    setSelectedItem(item);
    if (onItemSelected) {
      onItemSelected(item);
    }
  };

  const imgContainerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    border: '3px solid black',
    width: '100%',
    minHeight: '100px',
    maxHeight: maxContentHeight ? maxContentHeight : '240px',
    flexWrap: 'wrap',
    overflowY: 'scroll',
    marginLeft: '8px'
  };

  const imgItemStyle: CSSProperties = {
    borderRadius: '5px',
    minWidth: matches ? '155px' : '180px',
    margin: '3px',
    cursor: 'pointer'
  };

  return (
    <div
      style={imgContainerStyle}
      data-testid={dataTestId}
      data-trackid={dataTrackId}
    >
      {thumbs.map((x, index) => (
        <img
          data-testid={`${dataTestId}Item${index}`}
          data-trackid={`${dataTrackId}Item${index}`}
          src={x.url}
          key={x.versionId}
          onClick={() => handleSelectItem(x)}
          loading="eager"
          style={
            selectedItem?.name === x.name
              ? { ...imgItemStyle, ...selectedItemStyle }
              : imgItemStyle
          }
          height="100"
          alt={`thumbnail ${x.name}`}
        />
      ))}
    </div>
  );
};

import React from 'react';
import TopMenuAccount from './TopMenuAccount';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../assets/Blount_Logo.png';
import Box from '@mui/material/Box';
import BasicButton from '../core/BasicButton/BasicButton';
import { UserClaims } from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTranslation } from 'react-i18next';
import Notifications from '../Notifications/Notifications';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';

export interface TopMenuProps {
  isMobile: boolean;
  handleDrawerToggle: () => void;
  isFreeTrial?: boolean;
}

const logoStyle = {
  maxWidth: '120px',
  maxHeight: '30px'
};

const TopMenu: React.FC<TopMenuProps> = ({
  isMobile,
  handleDrawerToggle,
  isFreeTrial
}) => {
  const user = useCurrentUser();
  const companyLogo =
    (user?.profile[UserClaims.CompanyLogoUrl] as string) || logo;
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    variables: { newLayoutStyle }
  } = useDevCycleSdk();

  return (
    <nav
      className="navbar navbar-expand navbar-light topbar mb-4 static-top shadow appNavBar"
      style={{
        paddingTop: isFreeTrial ? '100px' : '0px',
        justifyContent: 'space-between',
        backgroundColor: `${newLayoutStyle.headerBackgroundColor}`
      }}
    >
      {isMobile && (
        <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            style={{ margin: 0 }}
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon fontSize={'large'} />
          </IconButton>
          <Box sx={{ mr: 2, display: { md: 'none' } }}>
            <img style={logoStyle} src={companyLogo} alt="Logo" />
          </Box>
        </Box>
      )}
      <Box
        style={{
          display: 'flex',
          marginTop: isFreeTrial ? '-40px' : '0px'
        }}
      >
        <ul className="navbar-nav ml-auto">
          <li className="nav-item bellContainer">
            <BasicButton
              onClick={() => {
                window.open(
                  'https://www.buildwitt.com/software/construction-training-software/support'
                );
              }}
              color="secondary"
              style={{ fontSize: matches ? '14px' : '12px' }}
            >
              {t('needHelp', { ns: 'common' })}
            </BasicButton>
          </li>
          <li className="nav-item dropdown no-arrow bellContainer">
            <Notifications />
          </li>
          <li className="nav-item bellContainer">
            <TopMenuAccount />
          </li>
        </ul>
      </Box>
    </nav>
  );
};

export default TopMenu;

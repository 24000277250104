import { styled } from '@mui/system';
import { Theme } from '@mui/material';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';

const escapeProps = [
  'isNewContentCourse',
  'thumbnail',
  'width',
  'height',
  'isComplete',
  'theme',
  'isOverdue',
  'isOverdueCourse',
  'completeCourse',
  'isPreviewMode'
];

export const BaseCourseCardWrapper = styled('div')<{ thumbnail: string }>(
  ({ theme, thumbnail }) => ({
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${
      thumbnail ?? BackgroundCoursePattern
    }')`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '14px',
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    minWidth: '200px',
    minHeight: '200px',
    flexDirection: 'column',
    padding: '18px',
    width: '100%',
    height: '325px',
    position: 'relative',
    borderColor: theme.palette.grey['100']
  })
);

export const CourseCardWrapper = styled(BaseCourseCardWrapper, {
  shouldForwardProp: prop => !escapeProps.includes(prop as string)
})<{
  thumbnail: string;
  isComplete: boolean;
  isOverdue: boolean;
  isNewContentCourse: string;
  isOverdueCourse: string;
  completeCourse: string;
  isPreviewMode: boolean;
  theme: Theme;
}>(props => ({
  borderColor: props.isPreviewMode
    ? `${props.theme.palette.grey['100']}`
    : props.isComplete
      ? props.completeCourse
      : props.isOverdue
        ? props.isOverdueCourse
        : props.isNewContentCourse
          ? props.isNewContentCourse
          : `${props.theme.palette.grey['100']}`
}));

import React, { useState, useEffect, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AssignEmployeesToOnsiteTrainingDto,
  OnsiteTraining
} from '../../../entities/OnsiteTraining';
import { Employee, EmployeeGroup } from '../../../entities/Employee';

import { Row, Col } from 'react-bootstrap';
import Panel from '../../../components/core/Panel/Panel';
import EmployeeList from '../../../components/EmployeeList/EmployeeList';
import SearchBox from '../../../components/core/SearchBox/SearchBox';

import { dropDownItem } from '../../../entities/Common';
import { SortEmployeeOption } from '../../../entities/SortEmployeeOption';
import FormCheckBox from '../../../components/core/FormCheckBox/FormCheckBox';
import TextDropdownList from '../../../components/core/TextDropdownList/TextDropdownList';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { EmployeeAssignmentStatus } from '../../../entities/Assignment';

export interface AssignEmployeesToOnsiteTrainingPanelProps {
  onsiteTraining: OnsiteTraining | undefined | null;

  employees: Employee[] | undefined;
  employeeGroups: EmployeeGroup[] | undefined;
  assignment: AssignEmployeesToOnsiteTrainingDto;
  showAssignmentType?: boolean;
  layoutType?: 'standard' | 'clickSafety';
  learningUnitAssigmentStatus?: EmployeeAssignmentStatus[] | null;
  setAssignment: (assigment: any) => void;
}

const AssignEmployeesToOnsiteTrainingPanel: React.FC<
  AssignEmployeesToOnsiteTrainingPanelProps
> = ({
  employees,
  employeeGroups,
  assignment,
  learningUnitAssigmentStatus,
  setAssignment
}) => {
  const { t } = useTranslation([
    'common',
    'assignments',
    'lessons',
    'courses',
    'learningPlans',
    'employees',
    'onsiteTraining'
  ]);

  let searchTimeOut: NodeJS.Timeout;
  const [groups, setGroups] = useState<dropDownItem[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [employeesToShow, setEmployeesToShow] = useState<
    Employee[] | undefined
  >([]);
  const [sortOrder, setSortOrder] = useState<number>(
    SortEmployeeOption.Assigned
  );

  const [applyAllChecked, setApplyAllChecked] = useState<boolean>(false);
  const [assignedEmployees, setAssignedEmployees] = useState<string[]>([]);

  useEffect(() => {
    setGroups(getListItems(employeeGroups));
  }, [employeeGroups!.length]);

  useEffect(() => {
    if (employeesToShow && employeesToShow.length) {
      setEmployeesToShow(prevEmployeesToShow =>
        sortEmployees(sortOrder, prevEmployeesToShow)
      );
    }
  }, [sortOrder]);

  useEffect(() => {
    setAssignedEmployees(assignment.employeesIds);
  }, [assignment.employeesIds.length]);
  useEffect(() => {
    setAssignment({ ...assignment, employeesIds: [...assignedEmployees] });
  }, [assignedEmployees.length]);

  const getListItems = (list: EmployeeGroup[] | undefined) => {
    if (!list) return [];
    return list
      .filter(
        group =>
          group.employees &&
          group.employees.some(employee => employee.hasTrainingSubscription)
      )
      .map(item => ({ label: item.name, value: item.id }));
  };

  const dropdownContainerStyle: CSSProperties = {
    width: '100%',
    textAlign: 'right',
    paddingTop: '0px'
  };

  const handleOnChangeGroupList = (values: string[]) => {
    setGroupIds(values);
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      const employeeIds = !employees
        ? []
        : employees.map(employee => employee.id);
      setGroupIds([]);
      setAssignedEmployees(employeeIds);
      setAssignment({
        ...assignment,
        employeesIds: employeeIds
      });
    } else {
      setAssignedEmployees([]);
      setAssignment({
        ...assignment,
        employeesIds: []
      });
    }

    setApplyAllChecked(checked);
    setAssignment({ ...assignment, assignToAllEmployees: checked });
  };

  const handleSortChange = (e: any) => {
    setSortOrder(parseInt(e.target.value));
  };

  const handleSearch = (value: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    searchTimeOut = setTimeout(() => {
      const safeValue = value.toLowerCase();
      const filtered = !employees
        ? []
        : employees.filter(
            employee =>
              `${employee.firstName} ${employee.lastName}`
                .toLowerCase()
                .includes(safeValue) ||
              (employee.email ?? '').toLowerCase().includes(safeValue) ||
              (employee.phoneNumber ?? '').toLowerCase().includes(safeValue) ||
              employee.groups.some(group =>
                group.name.toLowerCase().includes(safeValue)
              )
          );

      setEmployeesToShow(sortEmployees(sortOrder, filtered));
    }, 1000);
  };

  const orderByAssigned = (emps: Employee[] | undefined) => {
    if (!emps) return;
    const assignedEmps = emps.slice().map(emp => {
      return {
        employee: emp,
        assigned: assignedEmployees.includes(emp.id)
      };
    });
    const sorted = assignedEmps.sort(
      (a, b) => Number(a.assigned) - Number(b.assigned)
    );
    return sorted.map(x => x.employee);
  };

  useEffect(() => {
    return setEmployeesToShow(sortEmployees(sortOrder, employees));
  }, [employees]);

  const sortEmployees = (
    option: number,
    employeesToSort: Employee[] | undefined
  ) => {
    if (!employeesToSort) return [];
    switch (option) {
      case SortEmployeeOption.Assigned:
        return orderByAssigned(employeesToSort)!.reverse();
      case SortEmployeeOption.Unassigned:
        return orderByAssigned(employeesToSort);
      case SortEmployeeOption.Az:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()));
      case SortEmployeeOption.Za:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()))
          .reverse();
      default:
        return employeesToSort;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Panel width="100%" height="100%" backgroundColor="#1B1C1C">
            <Row className="pb-1">
              <Col md={12} lg={6} className="pt-1">
                <SearchBox
                  id="search"
                  value=""
                  name="search"
                  width="100%"
                  height="38px"
                  placeholderText={t('search', { ns: 'common' })}
                  variant="dark"
                  onChangeValue={handleSearch}
                />
              </Col>
              <Col md={12} lg={3} className="pt-1">
                <BasicSelect
                  labelId="groups"
                  id="groups"
                  options={groups}
                  defaultValue=""
                  value={groupIds}
                  placeholder={t('quickAssignToGroup', {
                    ns: 'assignments'
                  })}
                  handleOnChange={handleOnChangeGroupList}
                  disabled={applyAllChecked}
                />
              </Col>
              <Col md={12} lg={3} className="pt-1">
                <FormCheckBox
                  onChange={handleCheckBoxChange}
                  checked={applyAllChecked}
                  label={t('applyToAllEmployees', { ns: 'employees' })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={dropdownContainerStyle}>
                  <TextDropdownList
                    onChange={handleSortChange}
                    label={`${t('sort', { ns: 'common' })}:`}
                    items={[
                      {
                        value: SortEmployeeOption.Assigned,
                        text: t('assigned', { ns: 'common' })
                      },
                      {
                        value: SortEmployeeOption.Unassigned,
                        text: t('unassigned', { ns: 'common' })
                      },
                      { value: SortEmployeeOption.Az, text: 'a-z' },
                      { value: SortEmployeeOption.Za, text: 'z-a' }
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <EmployeeList
                  employees={employeesToShow ?? []}
                  groupIds={groupIds}
                  assignedEmployeeIds={assignedEmployees}
                  onUpdateAssignedEmployees={setAssignedEmployees}
                  applyToAllChecked={applyAllChecked}
                  learningUnitAssigmentStatus={learningUnitAssigmentStatus!}
                  layoutType={'standard'}
                />
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default AssignEmployeesToOnsiteTrainingPanel;

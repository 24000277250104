import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';

import {
  TitilliumWebNormal,
  TitilliumWebBold,
  TitilliumWebSemiBold
} from '../../../core/constants';
import BwTrainingLogo from '../../../assets/BWTrainingLogo.png';
import BWlogo from '../../../assets/buildwitt-logo-dark.png';
import { PdfCertification } from '../../../entities/Certification';

interface CertificatePDFTemplateProps {
  pdfData?: PdfCertification;
}

Font.register({
  family: 'Titillium Web',
  fonts: [
    {
      src: TitilliumWebSemiBold,
      fontWeight: 600
    },
    {
      src: TitilliumWebNormal
    },
    {
      fontWeight: 'bold',
      src: TitilliumWebBold
    }
  ]
});

const styles = StyleSheet.create({
  pageStyle: {
    display: 'flex',
    backgroundColor: '#FFF',
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentVieWStyle: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #999',
    paddingRight: '32px',
    paddingLeft: '32px',
    height: '490px',
    width: '90%',
    paddingTop: '20px'
  },
  logoStyle: {
    height: '40px',
    objectFit: 'contain',
    width: '200px'
  },
  certificateNameStyle: {
    fontFamily: 'Titillium Web',
    fontSize: '30px',
    paddingTop: '16px',
    fontWeight: 700,
    paddingBottom: '25px'
  },
  standardTextStyle: {
    fontSize: '14px',
    fontFamily: 'Titillium Web'
  },
  employeeNameStyle: {
    fontWeight: 600,
    fontFamily: 'Titillium Web',
    fontSize: '25px',
    color: '#FF9E00'
  },
  subContentViewStyle: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '30px',
    alignItems: 'flex-end'
  },
  contentItemViewStyle: {
    display: 'flex',
    maxWidth: '250px',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  itemViewStyle: {
    justifyContent: 'center',
    display: 'flex',
    minWidth: '160px',
    alignItems: 'center',
    borderBottom: '0.5px solid #999'
  },
  styledText1Style: {
    fontSize: '16px',
    fontFamily: 'Titillium Web'
  },
  bwTrainingLogoStyle: {
    height: '70px',
    objectFit: 'contain',
    width: '130px'
  },
  subContentViewAlternativeStyle: {
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'row',
    padding: '30px',
    width: '100%',
    alignItems: 'flex-end'
  }
});

const CertificateContentWithExpirationDate = ({
  pdfData
}: CertificatePDFTemplateProps) => {
  return (
    <View style={styles.subContentViewStyle}>
      <View style={styles.contentItemViewStyle}>
        <View style={styles.itemViewStyle}>
          <Text style={styles.styledText1Style}>
            {pdfData?.formattedCreatedAt}
          </Text>
        </View>
        <Text style={styles.standardTextStyle}>DATE Awarded</Text>
      </View>
      <View style={styles.contentItemViewStyle}>
        <View style={styles.itemViewStyle}>
          <Text style={styles.styledText1Style}>
            {pdfData?.formattedExpirationDate}
          </Text>
        </View>
        <Text style={styles.standardTextStyle}>EXPIRATION DATE</Text>
      </View>
      <View style={styles.contentItemViewStyle}>
        <View style={styles.itemViewStyle}>
          <Text style={styles.styledText1Style}>{pdfData?.awarderName}</Text>
        </View>
        <Text style={styles.standardTextStyle}>AWARDER</Text>
      </View>
    </View>
  );
};

const CertificateContentWithoutExpirationDate = ({
  pdfData
}: CertificatePDFTemplateProps) => {
  return (
    <View style={styles.subContentViewAlternativeStyle}>
      <View style={styles.contentItemViewStyle}>
        <View style={styles.itemViewStyle}>
          <Text style={styles.styledText1Style}>
            {pdfData?.formattedCreatedAt}
          </Text>
        </View>
        <Text style={styles.standardTextStyle}>DATE Awarded</Text>
      </View>
      <View style={styles.contentItemViewStyle}>
        <View style={styles.itemViewStyle}>
          <Text style={styles.styledText1Style}>{pdfData?.awarderName}</Text>
        </View>
        <Text style={styles.standardTextStyle}>AWARDER</Text>
      </View>
    </View>
  );
};

const CertificatePDFTemplate = ({ pdfData }: CertificatePDFTemplateProps) => {
  const ContentSection = pdfData?.formattedExpirationDate.length
    ? CertificateContentWithExpirationDate
    : CertificateContentWithoutExpirationDate;

  const companyLogoURL = pdfData?.companyLogoUrl
    ? pdfData?.companyLogoUrl
    : BWlogo;
  return (
    <Document>
      <Page style={styles.pageStyle} orientation="landscape">
        <View style={styles.contentVieWStyle}>
          <Image source={companyLogoURL} style={styles.logoStyle} />
          <Text style={styles.certificateNameStyle}>{pdfData?.name}</Text>
          <Text style={styles.standardTextStyle}>PRESENTED TO</Text>
          <Text style={styles.employeeNameStyle}>{pdfData?.employeeName}</Text>
          <ContentSection pdfData={pdfData} />
          <Image style={styles.bwTrainingLogoStyle} source={BwTrainingLogo} />
        </View>
      </Page>
    </Document>
  );
};

export default CertificatePDFTemplate;

import { CSSProperties } from 'react';

export const leftDivStyle: CSSProperties = {
  width: '98%',
  height: '100%',
  display: 'flex',
  minHeight: '325px',
  flexDirection: 'column' as const,
  padding: '18px',
  justifyContent: 'space-between'
};

export const planTitleDefault: CSSProperties = {
  fontWeight: 'bold',
  fontSize: '24px',
  cursor: 'default'
};
export const planTitlePointer: CSSProperties = {
  fontWeight: 'bold',
  fontSize: '24px',
  cursor: 'pointer'
};

export const coursesCount: CSSProperties = {
  fontSize: '12px',
  marginLeft: 0
};

export const editContainerStyle: CSSProperties = {
  fontSize: '15px',
  textDecoration: 'underline',
  cursor: 'pointer'
};

export const editIconStyle: CSSProperties = {
  marginLeft: '0.5rem'
};

export const iconContainerStyle: CSSProperties = {
  textAlign: 'right'
};

export const assignButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

export const startButtonStyle: CSSProperties = {
  ...assignButtonStyle,
  marginRight: '1rem'
};

export const infoSection: CSSProperties = {
  display: 'flex',
  flexDirection: 'row' as const,
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '0.3rem',
  paddingBottom: '0.3rem',
  paddingTop: '8px'
};

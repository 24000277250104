import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';

import { Course } from '../../entities/Course';
import LessonType from '../LessonType/LessonType';
import { LessonTypeViewEnum } from '../../core/enums';
import ProgressLine from '../ProgressLine/ProgressLine';
import RichTextInput from '../core/RichTextInput/RichTextInput';

export interface CourseInfoPanelProps {
  course: Course;
  totalLessonsCount: number;
  completedLessonsCount: number;
  isLoadingInfo?: boolean;
  isPreviewMode?: boolean;
}

const CourseInfoPanel: React.FC<CourseInfoPanelProps> = ({
  course,
  completedLessonsCount,
  totalLessonsCount,
  isLoadingInfo,
  isPreviewMode
}) => {
  const { videosCount, flashCardsCount, quizesCount } = course;
  const { t } = useTranslation(['lessons', 'courses']);

  return (
    <Stack>
      <Stack direction="row" sx={{ pt: '0.5rem' }}>
        <LessonType
          viewType={LessonTypeViewEnum.CourseLessonTypesCount}
          videosCount={videosCount}
          flashCardsCount={flashCardsCount}
          quizesCount={quizesCount}
        />
      </Stack>
      <Stack sx={{ pt: '1.5rem', maxWidth: { xs: '100%', lg: '70%' } }}>
        {!isLoadingInfo && (
          <RichTextInput value={course?.description} disabled={true} />
        )}
        <Stack direction="row" sx={{ pt: '1.5rem', flexWrap: 'wrap' }}>
          {course?.tags.map(tag => (
            <div
              key={tag.id}
              className="tag"
              style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
            >
              {tag.name}
            </div>
          ))}
        </Stack>
      </Stack>
      {!isPreviewMode && totalLessonsCount > 0 && (
        <Stack sx={{ maxWidth: { xs: '100%', lg: '70%' } }}>
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', mt: '2rem', mb: '0.5rem' }}
          >
            <span>{t('courseProgress', { ns: 'courses' })}</span>
            <span>
              {isLoadingInfo ? '?' : completedLessonsCount} /{' '}
              {totalLessonsCount} {t('lessons', { ns: 'lessons' })}
            </span>
          </Stack>
          <Stack sx={{ height: '20%', width: '100%' }}>
            {isLoadingInfo && <LinearProgress />}
            {!isLoadingInfo && (
              <ProgressLine
                color={'#44d47a'}
                value={(completedLessonsCount / totalLessonsCount) * 100}
                width="100%"
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CourseInfoPanel;

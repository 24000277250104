import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const TrashIcon: React.FC<SvgIconProps> = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.26905 4.26841H2.43979V15.0489C2.43979 15.4888 2.61452 15.9106 2.92556 16.2217C3.2366 16.5327 3.65845 16.7074 4.09832 16.7074H12.391C12.8309 16.7074 13.2527 16.5327 13.5638 16.2217C13.8748 15.9106 14.0495 15.4888 14.0495 15.0489V4.26841H3.26905ZM6.58613 14.2196H4.92759V6.75622H6.58613V14.2196ZM11.5617 14.2196H9.9032V6.75622H11.5617V14.2196ZM12.0742 1.78061L10.7325 0.12207H5.75686L4.4151 1.78061H0.78125V3.43914H15.7081V1.78061H12.0742Z"
        fill="#1B1C1C"
      />
    </SvgIcon>
  );
};

export default TrashIcon;

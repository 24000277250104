import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LessonAssignment } from '../../entities/Lesson';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  newLessonAssignment,
  newLessonGroupAssignment
} from '../../reducers/LessonsReducer';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { EnrollmentTypes, UserRoles, UserClaims } from '../../core/constants';
import { TransactionStatusEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';
import AssignModal from '../../components/AssignModal/AssignModal';
import { useLogEvent } from '../../hooks/useLogEvent';
import InfoModal from '../../components/core/InfoModal/InfoModal';
import { LessonGroupAssignment } from '../../entities/Assignment';
import useUserRole from '../../hooks/useUserRole';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';

export interface AssignLessonProps {
  open: boolean;
  onClose: () => void;
}

const AssignLesson: React.FC<AssignLessonProps> = ({ open, onClose }) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons?.currentLesson
  );
  const transactionStatus = useSelector(
    (state: ApplicationState) => state.lessons?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const lessonAssignmentStatus = useSelector(
    (state: ApplicationState) => state?.lessons?.lessonAssignmentStatus
  );
  const groupAssignmentDefinitions = useSelector(
    (state: ApplicationState) => state?.lessons?.groupAssignmentDefinitions
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [lessonAssignment, setLessonAssignment] = useState<LessonAssignment>({
    ...newLessonAssignment
  });
  const [lessonGroupAssignment, setLessonGroupAssignment] =
    useState<LessonGroupAssignment>({
      ...newLessonGroupAssignment
    });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation(['lessons', 'common', 'assignments']);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (currentLesson && !lessonAssignment.lessonId.length) {
      dispatch(
        lessonActions.requestStatusOfEmployeesLessonAssigments(currentLesson.id)
      );
      dispatch(
        lessonActions.requestLessonAssignmentDefinitions(currentLesson.id)
      );

      setLessonAssignment({
        ...newLessonAssignment,
        lessonId: currentLesson.id,
        assignBy: ''
      });
      setLessonGroupAssignment({
        ...newLessonGroupAssignment,
        lessonId: currentLesson.id,
        assignBy: ''
      });
    }
  }, [currentLesson]);

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }

    return () => {
      dispatch(lessonActions.SetStatusOfEmployeesLessonAssigments(null));
      dispatch(lessonActions.setLessonAssignmentDefinitions(null));
    };
  }, []);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionStatus === TransactionStatusEnum.Failed) {
        const message = t('anExceptionOccurred', { ns: 'common' });
        setErrorMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      } else if (transactionStatus === TransactionStatusEnum.Successfull) {
        setShowAssignmentConfirmation(true);
        setErrorMessage('');
      }
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(lessonActions.resetTransactionStatus());

    return () => {
      dispatch(lessonActions.resetTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message = '';

    if (!lessonAssignment.employeeIds.length)
      message = t('selectEmployee', { ns: 'common' });
    else if (
      !lessonAssignment.assignBy ||
      (lessonAssignment.assignBy === EnrollmentTypes.DueDate &&
        !lessonAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });

    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(lessonActions.assignLessonToEmployees(lessonAssignment));
    lessonAssignment.employeeIds.forEach(employeeId => {
      logEvent.logAssignLessonEvent(
        lessonAssignment.lessonId,
        employeeId,
        currentLesson?.title
      );
    });
  };

  const handleSaveGroupAssignment = async () => {
    let message = '';

    if (!lessonGroupAssignment.groups.length) {
      message = t('mustSelectAtLeastOneGroup', { ns: 'assignments' });
    } else if (
      !lessonGroupAssignment.assignBy ||
      (lessonGroupAssignment.assignBy === EnrollmentTypes.DueDate &&
        !lessonGroupAssignment.dueDate)
    ) {
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });
    }

    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    try {
      await dispatch<Promise<void>>(
        lessonActions.saveLessonGroupAssignments(lessonGroupAssignment)
      );
      setShowAssignmentConfirmation(true);
      setErrorMessage('');
      lessonGroupAssignment.groups.forEach(group => {
        logEvent.logAssignLessonToGroupEvent(
          lessonGroupAssignment.lessonId,
          group.groupId
        );
      });
    } catch (e) {
      message = t('saveGroupAssignmentsErrorMsg', { ns: 'assignments' });
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
      setSaveInProgress(false);
    }
  };

  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };

  const setLessonAssignmentProxy = (value: Partial<LessonAssignment>) => {
    setLessonAssignment(prev => ({
      ...prev,
      ...value,
      lessonId: value.lessonId || prev.lessonId
    }));
  };

  return (
    <>
      <AssignModal
        showModal={open}
        assignToEntity={currentLesson}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onSaveGroupAssignment={handleSaveGroupAssignment}
        onClose={onClose}
        employees={employees ?? []}
        employeeGroups={employeeGroups ?? []}
        assignment={lessonAssignment}
        groupAssignment={lessonGroupAssignment}
        setAssignment={setLessonAssignmentProxy}
        setGroupAssignment={setLessonGroupAssignment}
        learningUnitAssigmentStatus={lessonAssignmentStatus}
        groupAssignmentDefinitions={groupAssignmentDefinitions}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignLesson;

import { Dispatch, SetStateAction, useRef } from 'react';
import { Guid } from 'guid-typescript';

import { EmployeeCertification } from '../../../entities/EmployeeCertification';
import UploadCertifyingModal from './UploadCertifyingModal/UploadCertifyingModal';
import AwardEmployeeCertificateOverrideModal from './UploadCertifyingModal/AwardEmployeeCertificateOverrideModal';
import AwardConfirmationModal from './UploadCertifyingModal/AwardConfirmationModal';
import CertificatePdfPreviewModal from './CertificatePdfPreviewModal';
import { Certification } from '../../../entities/Certification';

export enum ModalKeys {
  uploadFiles = 'uploadFiles',
  certificationOverride = 'certificationOverride',
  certificationSignOff = 'certificationSignOff',
  certificationPreview = 'certificationPreview',
  none = 'none'
}

interface AwardCertificationModalFactoryProps {
  employeesToAward: EmployeeCertification[];
  savingInProgress: boolean;
  modalKey: ModalKeys;
  certification: Certification;
  setModalKey: Dispatch<SetStateAction<ModalKeys>>;
  setEmployeesToAward: Dispatch<SetStateAction<EmployeeCertification[]>>;
  handleSave: () => Promise<void>;
  handleCloseAwardModal: () => void;
}

const AwardCertificationModalFactory = ({
  employeesToAward,
  savingInProgress,
  modalKey,
  certification,
  setModalKey,
  setEmployeesToAward,
  handleSave,
  handleCloseAwardModal
}: AwardCertificationModalFactoryProps) => {
  const modalKeyHistory = useRef<ModalKeys[]>([]);

  const employeesAlreadyHaveCertification = () => {
    return employeesToAward.some(emp => emp.certificationId !== Guid.EMPTY);
  };

  const handleUploadModalNextButton = () => {
    const key = employeesAlreadyHaveCertification()
      ? ModalKeys.certificationOverride
      : ModalKeys.certificationSignOff;
    modalKeyHistory.current.push(modalKey);
    setModalKey(key);
  };

  const handleOverrideModalNextButton = () => {
    modalKeyHistory.current.push(modalKey);
    setModalKey(ModalKeys.certificationSignOff);
  };

  const handleGoBackModal = () => {
    if (!modalKeyHistory.current.length) {
      setModalKey(ModalKeys.none);
    }

    const previousModalKey = modalKeyHistory.current.pop();
    setModalKey(previousModalKey!);
  };

  return (
    <>
      {modalKey === ModalKeys.uploadFiles && (
        <UploadCertifyingModal
          openModal={true}
          handleCloseModal={() => setModalKey(ModalKeys.none)}
          employeesSelectedToBeAwarded={employeesToAward}
          setEmployeesSelected={setEmployeesToAward}
          handleShowNextModal={handleUploadModalNextButton}
          handleBack={handleGoBackModal}
        />
      )}
      {modalKey === ModalKeys.certificationOverride && (
        <AwardEmployeeCertificateOverrideModal
          openModal={true}
          handleCloseModal={() => setModalKey(ModalKeys.none)}
          handleContinue={handleOverrideModalNextButton}
          handleBack={handleGoBackModal}
        />
      )}
      {modalKey === ModalKeys.certificationSignOff && (
        <AwardConfirmationModal
          openModal={true}
          handleCloseModal={() => setModalKey(ModalKeys.none)}
          savingInProgress={savingInProgress}
          certificationName={certification.name}
          handleSave={handleSave}
          handleBack={handleGoBackModal}
        />
      )}
      {modalKey === ModalKeys.certificationPreview && (
        <CertificatePdfPreviewModal
          certification={certification}
          employee={employeesToAward[0]}
          onCloseModal={handleCloseAwardModal}
        />
      )}
    </>
  );
};

export default AwardCertificationModalFactory;

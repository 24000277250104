import { Typography, styled, useTheme } from '@mui/material';

const FormFieldName = ({
  name,
  isMandatory = false
}: {
  name: string;
  isMandatory?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Typography sx={theme.typography.subtitle1}>
      {name}
      {isMandatory && <MandatoryMark>*</MandatoryMark>}
    </Typography>
  );
};

const MandatoryMark = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

export default FormFieldName;

import { Grid, Typography } from '@mui/material';

export const OnsiteTrainingDetailsDescription = ({
  description
}: {
  description: string;
}) => {
  return (
    <Grid container display={'flex'} style={{ padding: '16px 20px 0px 20px' }}>
      <Typography fontSize={16} color={'#C4C4C4'}>
        {description}
      </Typography>
    </Grid>
  );
};

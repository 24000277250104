import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { BasicButtonTypeEnum } from '../../../core/enums';

type PartialMaterialButtonProps = Omit<
  LoadingButtonProps,
  | 'children'
  | 'onClick'
  | 'width'
  | 'height'
  | 'color'
  | 'disable'
  | 'style'
  | 'type'
>;

export interface BasicButtonProps extends PartialMaterialButtonProps {
  children: any;
  onClick?: () => void;
  width?: string;
  height?: string;
  color:
    | 'primary'
    | 'secondary'
    | 'alternative'
    | 'delete'
    | 'confirm'
    | 'revisit'
    | 'grayButton'
    | 'trial'
    | 'primaryOutlined'
    | 'deleteFilled';
  disable?: boolean;
  style?: React.CSSProperties;
  type?: BasicButtonTypeEnum;
}

const BasicButton: React.FC<BasicButtonProps> = ({
  children,
  onClick,
  width,
  height,
  color,
  disable,
  style,
  loading,
  type = BasicButtonTypeEnum.Button,
  ...props
}) => {
  const theme = useTheme();
  const buttonStyle: CSSProperties = {
    borderRadius: '25px',
    textTransform: 'none' as const,
    opacity: disable ? 0.5 : 1,
    border:
      color === 'alternative'
        ? `1px solid ${theme.palette.error.light}`
        : color === 'delete'
          ? '1px solid red'
          : color === 'primaryOutlined'
            ? `2px solid ${theme.palette.primary.main}`
            : 'none',
    backgroundColor:
      color === 'primary'
        ? `${theme.palette.primary.main}`
        : color === 'secondary'
          ? `${theme.palette.primary.light}`
          : color === 'grayButton'
            ? `#C4C4C4`
            : color === 'confirm' || color === 'deleteFilled'
              ? theme.palette.error.main
              : color === 'revisit'
                ? `${theme.palette.grey[900]}`
                : color === 'trial'
                  ? `${theme.palette.background.default}`
                  : `${theme.palette.common.black}`,
    color: loading
      ? 'transparent'
      : color === 'primary'
        ? `${theme.palette.grey[800]}`
        : color === 'secondary' || color === 'deleteFilled'
          ? `${theme.palette.common.black}`
          : color === 'grayButton'
            ? `${theme.palette.common.black}`
            : color === 'trial'
              ? `${theme.palette.background.default}`
              : `${theme.palette.common.white}`,
    ...style
  };
  return (
    <LoadingButton
      disabled={disable}
      variant="contained"
      onClick={onClick}
      type={type}
      loading={loading}
      style={
        width && height
          ? { ...buttonStyle, width: width, height: height }
          : buttonStyle
      }
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default BasicButton;

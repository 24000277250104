import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useUserRole from '../../../hooks/useUserRole';
import {
  AssignEmployeesToOnsiteTrainingDto,
  OnsiteTraining
} from '../../../entities/OnsiteTraining';
import { Employee } from '../../../entities/Employee';

import { ApplicationState } from '../../../store';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { useSelector } from 'react-redux';
import { UserRoles, UserClaims } from '../../../core/constants';
import { TransactionStatusEnum } from '../../../core/enums';
import { enqueueSnackbar } from 'notistack';

import InfoModal from '../../../components/core/InfoModal/InfoModal';
import AssignEmployeesToOnsiteTrainingModal from './AssignEmployeesToOnsiteTrainingModal';
import { useAppDispatch } from '../../../store/hooks';

export interface AssignEmployeesToOnsiteTrainingProps {
  open: boolean;
  onClose: () => void;
  onsiteTraining: OnsiteTraining | undefined | null;
  onsiteTrainingClassId: string;
}

const AssignEmployeesToOnsiteTraining: React.FC<
  AssignEmployeesToOnsiteTrainingProps
> = ({ open, onClose, onsiteTraining, onsiteTrainingClassId }) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();

  const transactionStatus = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );

  const onsiteTrainingAssignmentStatus = useSelector(
    (state: ApplicationState) =>
      state?.onsiteTrainings?.onsiteTrainingAssignmentStatus
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const emptyOnsiteTrainingAssignment: AssignEmployeesToOnsiteTrainingDto = {
    onsiteTrainingId: '',
    employeesIds: [],
    assignToAllEmployees: false,
    onsiteTrainingClassId: ''
  };

  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation([
    'common',
    'assignments',
    'lessons',
    'courses',
    'learningPlans',
    'employees',
    'onsiteTraining'
  ]);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);
  const [onsiteTrainingAssignment, setOnsiteTrainingAssignment] =
    useState<AssignEmployeesToOnsiteTrainingDto>({
      ...emptyOnsiteTrainingAssignment
    });

  const [employeesNotAssigned, setEmployeesNotAssigned] = useState<
    Employee[] | undefined
  >([]);

  useEffect(() => {
    if (onsiteTrainingAssignmentStatus && employees) {
      const assignedEmps = onsiteTrainingAssignmentStatus.map(
        x => x.employeeId
      );
      const availableEmps = employees.filter(
        x => !assignedEmps.some(i => i == x.id)
      );
      setEmployeesNotAssigned(availableEmps);
    }
  }, [onsiteTrainingAssignmentStatus, employees]);

  useEffect(() => {
    if (onsiteTraining) {
      dispatch(
        onsiteTrainingActions.requestStatusOfEmployeesOnsiteTrainingAssigments(
          onsiteTraining.id,
          onsiteTrainingClassId
        )
      );

      setOnsiteTrainingAssignment({
        ...emptyOnsiteTrainingAssignment,
        onsiteTrainingId: onsiteTraining.id
      });
    }
  }, [onsiteTraining]);

  useEffect(() => {
    if (onsiteTrainingClassId) {
      setOnsiteTrainingAssignment({
        ...onsiteTrainingAssignment,
        onsiteTrainingClassId: onsiteTrainingClassId
      });
    }
  }, [onsiteTrainingClassId]);

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }

    return () => {
      dispatch(
        onsiteTrainingActions.setStatusOfEmployeesOnsiteTrainingAssigments(null)
      );
      dispatch(
        onsiteTrainingActions.setOnsiteTrainingAssignmentDefinitions(null)
      );
    };
  }, []);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionStatus === TransactionStatusEnum.Failed) {
        const message = t('anExceptionOccurred', { ns: 'common' });
        setErrorMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      } else if (transactionStatus === TransactionStatusEnum.Successfull) {
        dispatch(
          onsiteTrainingActions.requestStatusOfEmployeesOnsiteTrainingAssigments(
            onsiteTrainingAssignment.onsiteTrainingId
          )
        );
        setShowAssignmentConfirmation(true);
        setErrorMessage('');
      }
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(onsiteTrainingActions.resetTransactionStatus());

    return () => {
      dispatch(onsiteTrainingActions.resetTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message = '';

    if (!onsiteTrainingAssignment.employeesIds.length)
      message = t('selectEmployee', { ns: 'common' });

    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(
      onsiteTrainingActions.addEmployeesToOnsiteTraining({
        ...onsiteTrainingAssignment,
        onsiteTrainingId: onsiteTraining!.id
      })
    );
  };

  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };

  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
  }, []);

  return (
    <>
      <AssignEmployeesToOnsiteTrainingModal
        showModal={open}
        onsiteTraining={onsiteTraining}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onClose={onClose}
        employees={employeesNotAssigned || []}
        employeeGroups={employeeGroups || []}
        assignment={onsiteTrainingAssignment}
        setAssignment={setOnsiteTrainingAssignment}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignEmployeesToOnsiteTraining;

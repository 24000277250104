import React, { CSSProperties } from 'react';
import { Course } from '../../entities/Course';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { useTranslation } from 'react-i18next';
import { CompletedCourseBasicInfo } from '../../entities/CompletedCourseInfo';
import CourseCardNew from '../CourseCard/CourseCardNew';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import { Grid } from '@mui/material';
import CourseRowWrapper from '../../pages/contentLibrary/common/CourseRowWrapper';

export interface CourseCardListProps {
  courses: Course[];
  completedCourses: CompletedCourseBasicInfo[];
  cardComplement: React.FC<{
    course: Course;
    cardAuxiliarFunction?: (id?: string) => void;
    isComplete: boolean;
    isPreviewMode: boolean;
  }>;
  shouldShowDrafCourses: boolean;
  cardAuxiliarFunction?: (id?: string) => void;
  isPreviewMode?: boolean;
  takenTimesCourses?: TakenTimesObject | null;
}

const CourseCardListNew: React.FC<CourseCardListProps> = ({
  courses,
  completedCourses,
  cardComplement: Component,
  shouldShowDrafCourses,
  takenTimesCourses,
  isPreviewMode,
  ...restProps
}) => {
  const user = useCurrentUser();
  const userCompanyId = user?.profile[UserClaims.CompanyId];
  const { t } = useTranslation(['courses']);
  let internalCourses = [...courses];
  if (!shouldShowDrafCourses) {
    internalCourses = internalCourses.filter(c => !c.isDraft);
  }

  return (
    <div style={containerStyle}>
      <Grid container spacing={2}>
        {internalCourses.length ? (
          internalCourses.map(course => {
            const isCompleted = completedCourses.some(
              courseItem => courseItem.courseId === course.id
            );
            return (
              <CourseRowWrapper key={course.id}>
                <CourseCardNew
                  isPreviewMode={isPreviewMode}
                  course={course}
                  averageLessonTime={
                    course.avgLessonTime
                      ? secondsToHHMMSS(course.avgLessonTime)
                      : '00:00:00'
                  }
                  isDraft={course.isDraft}
                  status={isCompleted ? 'Completed' : 'Clear'}
                  hasSpanishSubtitles={course.hasSpanishSubtitles}
                  isAdmin={course.companyId === userCompanyId}
                  takenTimes={
                    takenTimesCourses ? takenTimesCourses[course.id] : undefined
                  }
                >
                  <Component
                    isComplete={isCompleted}
                    isPreviewMode={
                      isPreviewMode === undefined ? false : isPreviewMode
                    }
                    course={course}
                    {...restProps}
                  />
                </CourseCardNew>
              </CourseRowWrapper>
            );
          })
        ) : (
          <h3>{t('noCoursesAvailableMsg', { ns: 'courses' })}</h3>
        )}
      </Grid>
    </div>
  );
};

const containerStyle: CSSProperties = {
  width: '100%'
};

export default CourseCardListNew;

import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

export interface SearchBoxProps {
  value: string;
  onChangeValue?: (value: any) => void;
  onSearchClick?: (value: any) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width: string;
  height: string;
  variant?: 'dark' | 'light' | undefined;
  maxWidth?: string;
  minWidth?: string;
}
const SearchBox: React.FC<SearchBoxProps> = ({
  id,
  name,
  value,
  onChangeValue,
  placeholderText,
  width,
  height,
  onSearchClick,
  variant = 'dark',
  maxWidth,
  minWidth
}) => {
  const [inputValue, setInputValue] = React.useState(value);
  const theme = useTheme();
  const inputStyle: CSSProperties = {
    backgroundColor: variant === 'dark' ? theme.palette.common.black : 'white',
    color:
      variant === 'dark'
        ? theme.palette.common.white
        : theme.palette.common.black,
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderTop: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    borderBottom: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    borderRight: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    borderLeft: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily,
    paddingLeft: '0.5rem',
    width: width,
    maxWidth: maxWidth,
    minWidth: minWidth
  };

  const contStyle = {
    display: 'flex',
    flexDirection: 'row' as const,
    height: height
  };

  const labelStyle: CSSProperties = {
    backgroundColor: variant === 'dark' ? theme.palette.common.black : 'white',
    height: height,
    margin: 0,
    width: '30px',
    textAlign: 'center' as const,
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderTop: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    borderBottom: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    borderLeft: `1px solid ${
      variant === 'dark' ? theme.palette.grey.A400 : theme.palette.common.black
    }`,
    cursor: 'pointer' as const,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px'
  };
  const handleChange = (e: any) => {
    setInputValue(e.target.value);
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  };
  return (
    <div style={contStyle}>
      <div
        style={labelStyle}
        onClick={() => {
          if (onSearchClick) {
            onSearchClick(inputValue);
          }
        }}
      >
        <FontAwesomeIcon
          icon={faSearch}
          color={
            variant === 'dark'
              ? theme.palette.grey.A700
              : theme.palette.grey[100]
          }
        />
      </div>
      <input
        type="text"
        width={width}
        height={height}
        id={id}
        name={name}
        value={inputValue}
        onChange={e => handleChange(e)}
        style={inputStyle}
        placeholder={placeholderText}
        className={variant === 'dark' ? 'css-dark-search' : ''}
      />
    </div>
  );
};

export default SearchBox;

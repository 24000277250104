import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BasicSelect from '../../core/BasicSelect/BasicSelect';
import {
  LessonFormModel,
  SubtitleEditMode
} from '../../../entities/LessonFormModel';
import FileInput from '../../core/FileInput/FileInput';
import TrashIcon from '../../Icons/TrashIcon';
import {
  buttonAddContainer,
  fontAddButton,
  addLanguageStyle
} from '../../../utils/buttonStyles';
import { LanguagesSubtitles } from '../../../core/constants';
import { truncateLargeAzureUrl } from '../../../utils/stringUtils';
import BasicButton from '../../core/BasicButton/BasicButton';

export interface SubtitlesSectionProps {
  lessonData: LessonFormModel;
  subtitles: SubtitleEditMode[];
  setSubtitles: (subtitles: Array<SubtitleEditMode>) => void;
  setLessonData: (lessonData: LessonFormModel) => void;
  getNextSubtitleId: () => string;
}

const SubtitlesSection: React.FC<SubtitlesSectionProps> = ({
  lessonData,
  subtitles,
  setSubtitles,
  setLessonData,
  getNextSubtitleId
}) => {
  const { t } = useTranslation(['common']);

  const getSubtitles = (
    subtitleEdit: SubtitleEditMode,
    fileType: string,
    file: File
  ) => {
    const newSubtitles = subtitles.map(opt =>
      opt._id === subtitleEdit._id
        ? { ...subtitleEdit, subtitleFile: file, fileType: fileType }
        : opt
    );
    return newSubtitles;
  };

  const setValidStates = (
    subtitleEdit: SubtitleEditMode,
    fileType: string,
    file: File
  ) => {
    const newSubtitles = getSubtitles(subtitleEdit, fileType, file);
    setSubtitles(newSubtitles);
    setLessonData({ ...lessonData, subtitles: newSubtitles });
  };

  const handleAddSubtitle =
    (subtitleEdit: SubtitleEditMode) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const fileExtension = file['name'].split('.').pop();
        const fileType =
          fileExtension === 'vtt' ? 'text/vtt' : 'other/fileType';
        if (IsValidSubtitleFormat(fileType)) {
          setValidStates(subtitleEdit, fileType, file);
        }
      }
    };

  const handleOnChangeSubtitle =
    (subtitleEdit: SubtitleEditMode) => (items: string[]) => {
      const subSelect = items[0] as string;
      const newSubtitles = subtitles.map(q =>
        q._id === subtitleEdit._id
          ? { ...subtitleEdit, language: subSelect }
          : q
      );
      setSubtitles(newSubtitles);
      setLessonData({ ...lessonData, subtitles: newSubtitles });
    };

  const onDeleteSubtitle = (subtitle: SubtitleEditMode) => {
    const newSubtitle = subtitles.filter(st => subtitle !== st);
    setLessonData({ ...lessonData, subtitles: newSubtitle });
    setSubtitles(newSubtitle);
  };

  const onAddSubtitleClick = () => {
    const newSubtitle = {
      _id: getNextSubtitleId(),
      id: '',
      language: '',
      url: ''
    };
    setSubtitles(subtitles.concat(newSubtitle));
  };

  const IsValidSubtitleFormat = (fileType: string) => {
    const validSubtitleTypes = ['text/vtt'];
    return validSubtitleTypes.includes(fileType);
  };

  return (
    <>
      <Grid container direction="row" spacing={1}>
        {subtitles.map((subtitle: SubtitleEditMode, index: number) => (
          <React.Fragment key={`subtitle-${index}`}>
            <Grid item xs={12} sm={4} className="pb-2">
              <BasicSelect
                id="subLanguage"
                data-testid={`lessonSubtitleSelect${index}`}
                data-trackid={`lessonSubtitleSelect${index}`}
                placeholder={t('selectLanguage', { ns: 'common' })}
                options={LanguagesSubtitles}
                value={subtitle.language === '' ? [] : [subtitle.language]}
                multiple={false}
                handleOnChange={handleOnChangeSubtitle(subtitle)}
                theme="dark"
              />
            </Grid>
            <Grid item xs={12} sm={7} className="pb-2">
              <FileInput
                initialText={t('uploadSubtitle', { ns: 'common' })}
                onChange={handleAddSubtitle(subtitle)}
                dataTestId={`lessonSubtitleFileInput${index}`}
                dataTrackId={`lessonSubtitleFileInput${index}`}
                id="fileInput"
                name="fileInput"
                type="vtt"
                variant="dark"
              />
              {subtitle.url && (
                <span
                  style={subtitleSpan}
                  data-testid={`lessonSubtitleUrl${index}`}
                  data-trackid={`lessonSubtitleUrl${index}`}
                >
                  {truncateLargeAzureUrl(subtitle.url)}
                </span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={1}
              md={1}
              style={buttonAddContainer}
              className={subtitle.url ? 'pb-5' : 'pb-2'}
            >
              <IconButton
                data-testid={`lessonSubtitleDeleteButton${index}`}
                data-trackid={`lessonSubtitleDeleteButton${index}`}
                color="default"
                onClick={() => onDeleteSubtitle(subtitle)}
                aria-label="Delete"
                style={trashIconStyle}
                size="small"
              >
                <TrashIcon viewBox="-9 -9 34 34" />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={2} style={buttonAddContainer} paddingTop="0.5rem">
        <BasicButton
          data-testid="lessonSubtitlesAddButton"
          data-trackid="lessonSubtitlesAddButton"
          color="primary"
          onClick={onAddSubtitleClick}
          style={addLanguageStyle}
        >
          <Typography sx={fontAddButton}>+</Typography>
        </BasicButton>
      </Grid>
    </>
  );
};

const trashIconStyle: CSSProperties = {
  background: '#999999',
  width: '35px',
  height: '35px'
};

const subtitleSpan: CSSProperties = {
  marginBottom: '10px'
};

export default SubtitlesSection;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { ApplicationState } from '../../store';
import LessonPlayer from '../lessons/LessonPlayer';
import LinearProgress from '@mui/material/LinearProgress';
import { useAppDispatch } from '../../store/hooks';

const CoursePlayAssignmentLessons: React.FC = () => {
  const params: any = useParams();
  const dispatch = useAppDispatch();

  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const coursePlayAssignment = useSelector(
    (state: ApplicationState) => state.courses?.coursePlayAssignment
  );

  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const lessons = coursePlayAssignment?.lessonAssignments.map(c => c.lesson);

  useEffect(() => {
    return () => {
      dispatch(actionCreators.clearCourseAssignment());
    };
  }, []);

  useEffect(() => {
    if (params && params.id) {
      dispatch(actionCreators.getCourseAssignment(params.id));
    }
  }, [params]);

  useEffect(() => {
    if (coursePlayAssignment?.lessonAssignments) {
      const quizzes = coursePlayAssignment?.lessonAssignments.filter(
        l => l.lesson.lessonType === 'Quiz'
      );
      const assessmentIds = quizzes.map(q => q.lesson.assessmentId!);
      const assignmentIds = quizzes.map(q => q.id!);
      dispatch(
        actionAssesments.failedAssignmentQuizzesResult(
          assessmentIds,
          assignmentIds
        )
      );
    }
  }, [coursePlayAssignment?.lessonAssignments]);

  if ((studentIsLoading && !appIsLoading) || !coursePlayAssignment) {
    return <LinearProgress />;
  }

  return (
    <LessonPlayer
      lessons={lessons!}
      courseContext={coursePlayAssignment}
      lessonAssignments={coursePlayAssignment?.lessonAssignments}
      entryPointLessonId={params.startingLessonId}
      isPreview={false}
    />
  );
};

export default CoursePlayAssignmentLessons;

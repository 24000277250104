import React, { CSSProperties } from 'react';
import {
  ImageListItem,
  ImageListItemBar,
  ImageListItemBarProps,
  Theme
} from '@mui/material';

import { Option } from '../../entities/Assessment';
import { styled } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const feedbackColor = '#C4C4C4';
enum ImageListItemStates {
  Selected = 'Selected',
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  Feedback = 'Feedback'
}

export const getListItemStyle = (
  theme: Theme,
  optionState?: ImageListItemStates
) => {
  let borderColor = 'transparent';

  switch (optionState) {
    case ImageListItemStates.Selected:
    case ImageListItemStates.Correct:
      borderColor = theme.palette.success.main;
      break;
    case ImageListItemStates.Incorrect:
      borderColor = theme.palette.error.main;
      break;
    case ImageListItemStates.Feedback:
      borderColor = feedbackColor;
      break;
  }

  return {
    borderColor: borderColor
  };
};

export interface MultipleQuestionImageListItemProps {
  option: Option;
  isAnswerSubmitted: boolean;
  answer?: Option;
  onSelectImage: (e: React.MouseEvent<HTMLElement>) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  answerOption: (props: { optionState?: ImageListItemStates }) => ({
    borderStyle: 'solid',
    ...getListItemStyle(theme, props.optionState)
  })
}));

const imageStyle: CSSProperties = {
  float: 'left',
  width: 220,
  height: 220,
  objectFit: 'cover'
};

export interface ImageListItemBarResultProps extends ImageListItemBarProps {
  type: string;
}

const ImageItemBarResult = styled(
  ImageListItemBar
)<ImageListItemBarResultProps>(({ theme, type }) => ({
  backgroundColor:
    type === ImageListItemStates.Selected ||
    type === ImageListItemStates.Correct
      ? theme.palette.success.main
      : type === ImageListItemStates.Feedback
        ? feedbackColor
        : theme.palette.error.main,
  textAlign: 'right',
  '&.MuiImageListItemBar-root': {
    left: type === ImageListItemStates.Feedback ? 55 : 165
  }
}));

const calculateOptionState = (
  isAnswerSubmitted: boolean,
  option: Option,
  answer?: Option
) => {
  const isCurrentOption = option.id === answer?.id;

  if (isAnswerSubmitted && answer && !answer.isCorrect && option.isCorrect) {
    return ImageListItemStates.Feedback;
  }

  if (!isAnswerSubmitted && answer && isCurrentOption) {
    return ImageListItemStates.Selected;
  }

  if (isAnswerSubmitted && isCurrentOption) {
    return answer && answer!.isCorrect
      ? ImageListItemStates.Correct
      : ImageListItemStates.Incorrect;
  }
};

export const MultipleQuestionImageListItem: React.FC<
  MultipleQuestionImageListItemProps
> = ({ option, isAnswerSubmitted, answer, onSelectImage }) => {
  const optionState = calculateOptionState(isAnswerSubmitted, option, answer);
  const classes = useStyles({ optionState });
  const { t } = useTranslation(['assessments', 'common']);

  const imageItemTitle =
    optionState === ImageListItemStates.Feedback
      ? t('thisIsCorrectImage', { ns: 'assessments' })
      : t('selected', { ns: 'common' });
  return (
    <ImageListItem className={classes.answerOption} onClick={onSelectImage}>
      <img src={option.imageUrl} alt={'img answer'} style={imageStyle} />

      {optionState && (
        <ImageItemBarResult title={imageItemTitle} type={optionState!} />
      )}
    </ImageListItem>
  );
};

import { useTheme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsPendingCompletionDetail } from '../../../entities/Analytics';
import { ModalDataGrid } from '../../../components/Analytics';
import {
  getFullNameColumn,
  getAssignedOnColumn,
  getCompletionStatusColumn,
  getDueDateColumn
} from '../common/utilAnalyticsColumnDefinition';

interface PendingCompletionListProps {
  pedingCompletionData?: AnalyticsPendingCompletionDetail[] | null;
  onEditDueDate: (assignmentId: string, dueDate: Date) => void;
}

const PendingCompletionList = ({
  pedingCompletionData,
  onEditDueDate
}: PendingCompletionListProps) => {
  const theme = useTheme();
  const { t } = useTranslation([
    'employees',
    'analytics',
    'assignments',
    'common'
  ]);

  const columns: GridColDef[] = (() => [
    getFullNameColumn(t('employee', { ns: 'employees' })),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getCompletionStatusColumn(
      t('completionStatus', { ns: 'analytics' }),
      <span style={{ color: theme.palette.primary.main }}>
        {t('pending', { ns: 'assignments' })}
      </span>
    ),
    getDueDateColumn(
      t('dueDate', { ns: 'assignments' }),
      onEditDueDate,
      '',
      t('edit', { ns: 'common' })
    )
  ])();

  return <ModalDataGrid rows={pedingCompletionData} columns={columns} />;
};

export default PendingCompletionList;

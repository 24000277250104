import { SyntheticEvent, useMemo, useState } from 'react';
import { AutocompleteChangeReason, Box, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { enqueueSnackbar } from 'notistack';

import FormFieldName from '../common/FormFieldName';
import TagInput from '../../../components/TagInput/TagInput';
import { Tag } from '../../../entities/Tag';
import { OnsiteTrainingClassPut } from '../../../entities/OnsiteTraining';
import MultiDatesPicker from '../../../components/core/MultiDatesPicker/MultiDatesPicker';
import MultiTimePicker from '../../../components/core/MultiTimePicker/MultiTimePicker';
import TextBox from '../../../components/core/TextBox/TextBox';
import { Employee } from '../../../entities/Employee';
import { convertDateToUtcAndAddTime } from '../../../utils/dateUtils';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { useAppDispatch } from '../../../store/hooks';

interface ManageUpcomingOnsiteTrainingFormProps {
  onsiteTrainingClassToEdit: OnsiteTrainingClassPut;
  activeEmployees?: Employee[] | null;
  onCloseModal: () => void;
}

interface OnsiteTrainingClassFormValidation {
  showInstructorMsg: boolean;
  showNumberOfDaysMsg: boolean;
  showSelectedDatesMsg: boolean;
  showStartTimeMsg: boolean;
}

const initFormValidation: OnsiteTrainingClassFormValidation = {
  showInstructorMsg: false,
  showNumberOfDaysMsg: false,
  showSelectedDatesMsg: false,
  showStartTimeMsg: false
};

const ManageUpcomingOnsiteTrainingForm = ({
  onsiteTrainingClassToEdit,
  activeEmployees,
  onCloseModal
}: ManageUpcomingOnsiteTrainingFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['onsiteTraining', 'common']);
  const [selectedInstructor, setSelectedInstructor] = useState<Tag[]>([]);
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [onsiteTrainingClassToManage, setOnsiteTrainingClassToManage] =
    useState<OnsiteTrainingClassPut>(onsiteTrainingClassToEdit);
  const [formValidation, setFormValidation] =
    useState<OnsiteTrainingClassFormValidation>(initFormValidation);

  const employeeTags = useMemo(() => {
    if (activeEmployees) {
      const tags = activeEmployees.map((employee): Tag => {
        const name = employee.firstName.length
          ? `${employee.firstName} ${employee.lastName}`
          : employee.email && employee.email.length
            ? employee.email
            : employee.phoneNumber!;
        return {
          id: employee.id,
          name
        };
      });

      const currentInstructor = tags.find(
        tag => tag.id === onsiteTrainingClassToManage.instructorId
      );
      if (!!currentInstructor && !selectedInstructor.length) {
        setSelectedInstructor([currentInstructor]);
      }

      return tags;
    }
  }, [activeEmployees]);

  const handleChangeClassDates = (dates: Date[]) => {
    setOnsiteTrainingClassToManage(current => ({
      ...current,
      classDates: dates
    }));
  };

  const handleChangeClassStartTimes = (times: string[]) => {
    setOnsiteTrainingClassToManage(current => ({
      ...current,
      classStartTimes: times
    }));
  };

  const handleChangeLocation = (value: any) => {
    setOnsiteTrainingClassToManage(current => ({
      ...current,
      location: value as string
    }));
  };

  const handleChangeDurationDays = (value: any) => {
    setOnsiteTrainingClassToManage(current => ({
      ...current,
      numberOfDays: new Number(value).valueOf()
    }));
  };

  const handleChangeInstructor = (
    event: SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'removeOption' || reason === 'clear') {
      setSelectedInstructor([]);
      setOnsiteTrainingClassToManage(current => ({
        ...current,
        instructorId: ''
      }));
    } else if (reason === 'selectOption') {
      setSelectedInstructor([value[value.length - 1]]);
      setOnsiteTrainingClassToManage(current => ({
        ...current,
        instructorId: value[value.length - 1].id
      }));
    }
  };

  const handleInitSave = () => {
    if (handleFormValidation()) {
      handleSave();
    }
  };

  const handleSave = async () => {
    const updatedOnsiteTrainingClass = { ...onsiteTrainingClassToManage };
    const handleOneSingleTime =
      updatedOnsiteTrainingClass.classStartTimes.length === 1;
    for (
      let index = updatedOnsiteTrainingClass.classDays.length;
      index < updatedOnsiteTrainingClass.classDates.length;
      index++
    ) {
      const timeIndex = handleOneSingleTime ? 0 : index;
      updatedOnsiteTrainingClass.classDays.push({
        classDateTime: convertDateToUtcAndAddTime(
          updatedOnsiteTrainingClass.classDates[index],
          updatedOnsiteTrainingClass.classStartTimes[timeIndex]
        ),
        IsNotEditable: false
      });
    }

    try {
      setSavingInProgress(true);
      await dispatch<Promise<void>>(
        onsiteTrainingActions.editOnsiteTrainingClass(
          updatedOnsiteTrainingClass
        )
      );
      dispatch(onsiteTrainingActions.forceRefreshOnsiteTrainingClasses(true));
      enqueueSnackbar(
        t('onsiteTrainingClassSuccessfullyUpdated', { ns: 'onsiteTraining' }),
        { variant: 'success' }
      );
    } catch (e) {
      enqueueSnackbar(
        t('errorWhileUpdatingOnsiteTrainingClass', { ns: 'onsiteTraining' }),
        { variant: 'error' }
      );
    } finally {
      setSavingInProgress(false);
      onCloseModal();
    }
  };

  const handleFormValidation = () => {
    setFormValidation(initFormValidation);
    const { instructorId, numberOfDays, classDates, classStartTimes } =
      onsiteTrainingClassToManage;
    const validationResult: OnsiteTrainingClassFormValidation = {
      showInstructorMsg: !instructorId.length,
      showNumberOfDaysMsg: numberOfDays != classDates.length,
      showSelectedDatesMsg: numberOfDays != classDates.length,
      showStartTimeMsg:
        !classStartTimes.length ||
        (classStartTimes.length > 1 && classStartTimes.length != numberOfDays)
    };
    const witoutErrors =
      !validationResult.showInstructorMsg &&
      !validationResult.showNumberOfDaysMsg &&
      !validationResult.showSelectedDatesMsg &&
      !validationResult.showStartTimeMsg;
    if (!witoutErrors) {
      enqueueSnackbar(
        t('errorWhenTrytoSaveClassChanges', { ns: 'onsiteTraining' }),
        { variant: 'error' }
      );
      setFormValidation(validationResult);
    }
    return witoutErrors;
  };

  if (!activeEmployees) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <FormContainer>
      <FormSection spacing={1}>
        <FormFieldName name={t('certifiyingBody', { ns: 'onsiteTraining' })} />
        <TagInput
          options={employeeTags ?? []}
          value={selectedInstructor}
          onChange={handleChangeInstructor}
          placeholder={t('searchUsers', { ns: 'onsiteTraining' })}
        />
        {formValidation.showInstructorMsg && (
          <ErrorSpan>
            {t('youHaveToSelectAnInstructor', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection spacing={1}>
        <FormFieldName
          name={t('onsiteTrainingDaysLong', { ns: 'onsiteTraining' })}
        />
        <TextBox
          id="onsiteTrainingDays"
          name="onsiteTrainingDays"
          width="100%"
          height="40px"
          variant="dark"
          value={`${onsiteTrainingClassToManage.numberOfDays}`}
          placeholderText={t('enterDayslong', {
            ns: 'onsiteTraining'
          })}
          onChangeValue={handleChangeDurationDays}
          isTypeNumber
          isNumber
          minNumericValue={1}
          maxLength={3}
          maxNumericValue={999}
          allowEmptyNumber={false}
        />
        {formValidation.showNumberOfDaysMsg && (
          <ErrorSpan>
            {t('numberOfDatesDontMatch', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection spacing={1}>
        <FormFieldName
          name={t('selecteDateOrDaysForOnsiteTraining', {
            ns: 'onsiteTraining'
          })}
        />
        <MultiDatesPicker
          variant="dark"
          selectedDates={onsiteTrainingClassToManage.classDates}
          maxDatesSelected={onsiteTrainingClassToManage.numberOfDays}
          onDatesUpdated={handleChangeClassDates}
          preExistingNonRemovableDates={
            onsiteTrainingClassToManage.nonRemovableClassDates
          }
        />
        {formValidation.showSelectedDatesMsg && (
          <ErrorSpan>
            {t('numberOfDatesDontMatch', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection spacing={1}>
        <FormFieldName name={t('selectStartTime', { ns: 'onsiteTraining' })} />
        <MultiTimePicker
          variant="dark"
          selectedTimes={onsiteTrainingClassToManage.classStartTimes}
          onTimesSelected={handleChangeClassStartTimes}
          maxTimesSelected={onsiteTrainingClassToManage.numberOfDays}
          preExistingNonRemovableTimes={
            onsiteTrainingClassToManage.nonRemovableTimes
          }
        />
        {formValidation.showStartTimeMsg && (
          <ErrorSpan>
            {t('datesAndDaysNotMatching', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection spacing={1}>
        <FormFieldName name={t('trainingLocation', { ns: 'onsiteTraining' })} />
        <TextBox
          id="txtLocation"
          value={onsiteTrainingClassToManage.location}
          width="100%"
          height="40px"
          name="txtLocation"
          variant="dark"
          placeholderText={t('typeLocation', {
            ns: 'onsiteTraining'
          })}
          onChangeValue={handleChangeLocation}
        />
      </FormSection>
      <FormSection sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <BasicButton
          color="primary"
          width="160px"
          height="45px"
          onClick={handleInitSave}
          loading={savingInProgress}
        >
          {t('save', { ns: 'common' })}
        </BasicButton>
      </FormSection>
    </FormContainer>
  );
};

const FormContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '1rem'
}));

const FormSection = styled(Stack)(() => ({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem'
}));

const ErrorSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

export default ManageUpcomingOnsiteTrainingForm;

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/notifications';
import { Notification } from '../entities/Notification';

import {
  NOTIFICATIONS_DISMISS_NOTIFICATION,
  NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED,
  NOTIFICATIONS_SET_NOTIFICATIONS
} from '../actions/actionTypes';
import { NotificationStatus } from '../core/constants';

export interface NotificationsState {
  notifications: Notification[];
}

const unloadedState: NotificationsState = {
  notifications: []
};

export const NotificationsReducer: Reducer<NotificationsState> = (
  state: NotificationsState | undefined,
  incomingAction: Action
): NotificationsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case NOTIFICATIONS_SET_NOTIFICATIONS: {
      const sortedNotifications = [...action.notifications].sort(
        (a, b) =>
          Number(new Date(b.createdTimestampUtc)) -
          Number(new Date(a.createdTimestampUtc))
      );
      return { ...state, notifications: sortedNotifications };
    }
    case NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED:
      return {
        ...state,
        notifications: state.notifications.map(n => ({
          ...n,
          status: NotificationStatus.Viewed
        }))
      };
    case NOTIFICATIONS_DISMISS_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map(n => {
          if (n.id === action.notificationId) {
            return { ...n, status: NotificationStatus.Dismissed };
          }
          return n;
        })
      };
    default:
      return state;
  }
};

import { useState, useEffect, CSSProperties, useContext } from 'react';
import Grid from '@mui/material/Grid';
import { Box, FormControl, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { ApplicationState } from '../../store';
import { DefaultSearchDebounceTime } from '../../core/constants';
import { AiccCourse } from '../../entities/Course';
import AssignClickSafety from './ClickSafety/AssignClickSafety';
import SortContentSelect from './common/SortContentSelect';
import useLearningUnitSorting from './hooks/useLearningUnitSorting';
import {
  filterLearningUnitsBySearchTerm,
  sortCoursesOrLearninpPlans
} from '../../utils/contentLibraryUtils';
import { Sorting } from '../../hooks/useContentLibraryNavigation';
import SearchInput from './common/SearchInput';
import { UserNavigationContext } from './UserNavigationContext';
import { useDebounce } from '../../hooks/useDebounce';
import CourseRowVirtual from './ClickSafety/CourseRowVirtual';
import ContentLibraryVirtualList from '../../components/ContentLibraryVirtualList/ContentLibraryVirtualList';
import ContentIndicators from './common/ContentIndicators';
import { useLogEvent } from '../../hooks/useLogEvent';

export const ClickSafetyNew = () => {
  const { t } = useTranslation(['common', 'courses']);
  const theme = useTheme();
  const logEvent = useLogEvent();
  const [showAssignmentModal, setShowAssignmentModal] =
    useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<AiccCourse | null>(null);
  const [activeSort, handleChangeSorting] = useLearningUnitSorting();
  const [clickSafetyCourses, setClickSafetyCourses] = useState<AiccCourse[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const courses = useSelector(
    (state: ApplicationState) => state.courses?.aiccCourses
  );

  const completedCourses = useSelector(
    (state: ApplicationState) => state.student?.completedCoursesBasicInfo
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    userNavigation?.search ?? ''
  );

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  useEffect(() => {
    if (searchTerm) {
      logEvent.logLibrarySearchEvent(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    userNavigationApi?.changeSearch(searchTerm);

    if (courses) {
      const coursesToShow = generateCoursesToShow(
        courses,
        searchTerm,
        activeSort as Sorting
      );
      setClickSafetyCourses(coursesToShow);
      setIsLoading(false);
    }
  }, [courses, activeSort, searchTerm]);

  const generateCoursesToShow = (
    courses: AiccCourse[],
    searchTerm: string | null,
    activeSort: Sorting
  ) => {
    let coursesOutput = courses;

    if (searchTerm) {
      coursesOutput = filterLearningUnitsBySearchTerm(
        searchTerm,
        item => item.title,
        item => item.description,
        coursesOutput
      ) as AiccCourse[];
    }

    return sortCoursesOrLearninpPlans(
      coursesOutput,
      activeSort,
      item => item.title,
      item => item.createdAt!,
      item => item.isDraft
    );
  };

  const handleCloseAssignmentModal = () => {
    setShowAssignmentModal(false);
    setSelectedCourse(null);
  };

  return (
    <>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          justifyContent={'space-between'}
        >
          <FormControl style={formControlContainer} variant="standard">
            <SortContentSelect
              value={activeSort}
              onChange={handleChangeSorting}
            />
          </FormControl>
          <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
        </Box>

        <Grid item xs={12} sx={coursesContainer}>
          <ContentIndicators
            isLoading={isLoading}
            totalItems={courses?.length ?? 0}
            noContentMessage={t('noCoursesAvailableMsg', { ns: 'courses' })}
          />
          <ContentLibraryVirtualList
            items={clickSafetyCourses}
            learningPlanUnit={'courses'}
            isLoading={isLoading}
            itemSize={341}
          >
            {({ data, index, style }) => (
              <CourseRowVirtual
                onOpenAssignModal={setShowAssignmentModal}
                completedCourses={completedCourses}
                takenTimesCourses={takenTimesCourses}
                index={index}
                style={style}
                data={data}
                onSelectCourse={setSelectedCourse}
              />
            )}
          </ContentLibraryVirtualList>
        </Grid>
      </Box>
      {showAssignmentModal && selectedCourse && (
        <AssignClickSafety
          show={showAssignmentModal}
          clickSafetyCourse={selectedCourse}
          onClose={handleCloseAssignmentModal}
        />
      )}
    </>
  );
};

const coursesContainer: SxProps = {
  paddingTop: '2rem'
};

const formControlContainer: CSSProperties = {
  width: '200px'
};

import { Dispatch, StoreEnhancer, UnknownAction, combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory, History } from 'history';
import { ApplicationState, reducers } from './';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  Persistor
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  EnhancedStore,
  ThunkDispatch,
  Tuple,
  configureStore
} from '@reduxjs/toolkit';

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ApplicationState;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = ThunkDispatch<any, undefined, UnknownAction>;

export type AppStore = EnhancedStore<
  any,
  any,
  Tuple<
    [
      StoreEnhancer<{
        dispatch: Dispatch<any>;
      }>,
      StoreEnhancer
    ]
  >
>;

export type MainStore = {
  store: AppStore | null;
  persistor: Persistor | null;
  history:
    | (History & {
        listenObject: boolean;
      })
    | null;
};

export const mainStore: MainStore = {
  store: null,
  persistor: null,
  history: null
};

export const setupStore = (preloadedState?: Partial<RootState>) => {
  const history = createBrowserHistory();

  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({ history });

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['router'],
    whitelist: ['appState', 'learningPlans']
  };

  const rootReducer = combineReducers({
    ...reducers,
    router: routerReducer
  });

  const persistedReducer = persistReducer<any, any>(persistConfig, rootReducer);

  mainStore.store = configureStore({
    reducer: persistedReducer,
    preloadedState: preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(routerMiddleware)
  });

  mainStore.persistor = persistStore(mainStore.store);
  mainStore.history = createReduxHistory(mainStore.store);
};

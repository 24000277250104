import { CSSProperties, ReactNode } from 'react';
import { IconButton, Stack, styled } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { EnrollmentTypes } from '../../../core/constants';
import { getFormattedTime } from '../../../utils/commonUtils';
import { KeyValuePair } from '../../../entities/Common';
import { StyledTooltip } from '../../../components/core/StyledTooltip/StyledTooltip';

export const getFullNameColumn = (
  headerName: string,
  field?: string,
  sortable?: boolean
): GridColDef => ({
  field: field ?? 'firstName',
  headerName: headerName,
  minWidth: 250,
  sortable: sortable ?? false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <div className="d-flex ps-1 pt-2 pb-2">
      <img
        className={`img-profile rounded-circle`}
        width="36"
        height="36"
        alt={`${params.row.firstName} ${params.row.lastName}`}
        src={
          params.row.photoUrl && params.row.photoUrl.length
            ? params.row.photoUrl
            : require('../../../assets/user.png')
        }
      />
      <div className="d-flex flex-column px-3">
        <span
          style={{ fontSize: 14 }}
        >{`${params.row.firstName} ${params.row.lastName}`}</span>
        <span style={{ fontSize: 12, color: '#999999' }}>
          {params.row.jobTitle}
        </span>
      </div>
    </div>
  )
});

export const getAssignedOnColumn = (headerName: string): GridColDef => ({
  field: 'assignedOn',
  headerName: headerName,
  minWidth: 120,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any>) => (
    <span>
      {absUtcDateToLocalMoment(params.row.assignedOn).format('MM.DD.YYYY')}
    </span>
  )
});

export const getCompletionStatusColumn = (
  headerName: string,
  content: ReactNode
): GridColDef => ({
  field: 'assignmentType',
  headerName: headerName,
  minWidth: 150,
  sortable: false,
  renderCell: _ => content
});

export const getDueDateColumn = (
  headerName: string,
  onEditDueDate: (id: string, dueDate: Date) => void,
  dueDateAlternativeText: string,
  buttonCaption: string,
  getTooltipText?: (params: GridRenderCellParams<any>) => string
): GridColDef => ({
  field: 'dueDate',
  headerName: headerName,
  minWidth: 160,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any, string>) => {
    const toolTipTitle =
      params.row.editableDueDate || !getTooltipText
        ? ''
        : getTooltipText(params);

    return (
      <Stack direction="row" spacing={2}>
        <span style={{ lineHeight: '35px' }}>
          {params.row.dueDate &&
          params.row.assignmentType === EnrollmentTypes.DueDate
            ? absUtcDateToLocalMoment(params.row.dueDate).format('MM.DD.YYYY')
            : dueDateAlternativeText}
        </span>
        {!!params.row.dueDate &&
          params.row.assignmentType === EnrollmentTypes.DueDate && (
            <StyledTooltip title={toolTipTitle}>
              <span>
                <BasicButton
                  onClick={() =>
                    onEditDueDate(params.row.assignmentId, params.row.dueDate)
                  }
                  color="grayButton"
                  disable={!params.row.editableDueDate}
                  style={{ width: '50px', height: '35px' }}
                  sx={{
                    '&.MuiLoadingButton-root': {
                      minWidth: '50px'
                    }
                  }}
                >
                  {buttonCaption}
                </BasicButton>
              </span>
            </StyledTooltip>
          )}
      </Stack>
    );
  }
});

export const getAssignmentMethodColumn = (headerName: string): GridColDef => ({
  field: 'assignmentMethod',
  headerName: headerName,
  minWidth: 200,
  sortable: false
});

export const getCompletedOnColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number
): GridColDef => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any>) =>
    absUtcDateToLocalMoment(params.row[fieldName]).format('MM.DD.YYYY')
});

export const getGenericValueActionColumn = (
  fieldName: string,
  headerName: string,
  buttonCaption: string,
  minWidth: number,
  onClickFunc: (params: GridRenderCellParams<any>) => void,
  getNodeValue?: (params: GridRenderCellParams<any>) => JSX.Element,
  getTooltipText?: (params: GridRenderCellParams<any>) => string
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any>) => {
    const toolTipTitle = !getTooltipText ? '' : getTooltipText(params);

    return (
      <div style={{ ...cellButtonsStyle, minWidth: minWidth - 20 }}>
        {!!getNodeValue && getNodeValue(params)}
        <StyledTooltip title={toolTipTitle}>
          <span>
            <CustomIconButton
              aria-label={fieldName}
              size="large"
              color="info"
              disabled={!params.row[fieldName]}
              onClick={() => onClickFunc(params)}
            >
              {buttonCaption}
            </CustomIconButton>
          </span>
        </StyledTooltip>
      </div>
    );
  }
});

export const getGenericValueColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number,
  getNodeValue?: (params: GridRenderCellParams<any>) => JSX.Element
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any>) => {
    return (
      <div
        style={{
          ...cellButtonsStyle,
          minWidth: minWidth - 20,
          marginLeft: '12px'
        }}
      >
        {!!getNodeValue && getNodeValue(params)}
      </div>
    );
  }
});

export const getTimeSpentInTrainingColumn = (
  headerName: string,
  minWidth: number
): GridColDef => ({
  field: 'timeSpentInTraining',
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <span style={params.row.timeSpentInTraining > 0 ? {} : ZeroValueFontStyle}>
      {params.row.timeSpentInTraining > 0
        ? getFormattedTime(params.row.timeSpentInTraining)
        : params.row.timeSpentInTraining}
    </span>
  )
});

export const getAssignmentNameColumn = (
  fieldName: string,
  leaningUnitTypeFieldName: string,
  headerName: string,
  minWidth: number,
  learningunitNames: KeyValuePair[]
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, number>) => (
    <Stack direction="column">
      <span style={highlightedFontStyle}>{params.row[fieldName]}</span>
      <span style={subTextFontStyle}>
        {learningunitNames.find(
          item => item.key === params.row[leaningUnitTypeFieldName]
        )?.value ?? ''}
      </span>
    </Stack>
  )
});

export const getCompletedByColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any, number>) => (
    <span>{`${params.row.firstName} ${params.row.lastName}`}</span>
  )
});

export const getStandardColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number,
  flex?: number
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  flex,
  renderCell: (params: GridRenderCellParams<any>) => (
    <span>{params.row[fieldName]}</span>
  )
});

export const getButtonColumn = (
  fieldName: string,
  headerName: string,
  buttonCaption: string,
  minWidth: number,
  onClickFunc: (params: GridRenderCellParams<any>) => void | Promise<void>,
  getTooltipText?: (params: GridRenderCellParams<any>) => string
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: false,
  renderCell: (params: GridRenderCellParams<any>) => {
    const toolTipTitle =
      params.row[fieldName] || !getTooltipText ? '' : getTooltipText(params);

    return (
      <Stack
        direction="row"
        spacing={2}
        style={{
          ...rowStyle,
          width: minWidth
        }}
      >
        <StyledTooltip title={toolTipTitle}>
          <CustomIconButton
            aria-label={fieldName}
            size="large"
            color="info"
            disabled={!params.row[fieldName]}
            onClick={() => onClickFunc(params)}
          >
            {buttonCaption}
          </CustomIconButton>
        </StyledTooltip>
      </Stack>
    );
  }
});

export const getFormatedValueColumn = (
  fieldName: string,
  headerName: string,
  minWidth: number,
  getNodeValue: (params: GridRenderCellParams<any>) => JSX.Element,
  sortable: boolean,
  flex?: number
) => ({
  field: fieldName,
  headerName: headerName,
  minWidth: minWidth,
  sortable: sortable,
  flex,
  renderCell: (params: GridRenderCellParams<any>) => {
    return getNodeValue(params);
  }
});

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  height: '30px',
  borderRadius: '35px',
  '&.MuiIconButton-root': {
    backgroundColor: theme.palette.grey[50],
    color: 'rgba(0, 0, 0, 0.87) !important',
    fontSize: 15
  },
  '&.Mui-disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: `${theme.palette.grey[100]} !important`
  }
}));

const ZeroValueFontStyle: CSSProperties = {
  color: '#FF0000'
};

const cellButtonsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between'
};

const highlightedFontStyle: CSSProperties = {
  fontWeight: 600
};

const subTextFontStyle: CSSProperties = {
  fontSize: '12px',
  textAlign: 'left',
  color: '#999999'
};

const rowStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  justifyContent: 'center'
};

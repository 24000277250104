import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';

export interface PanelProps {
  width: string;
  height?: string;
  paddingLeft?: string;
  paddingRight?: string;
  backgroundColor?: string;
  paddingTop?: string;
  paddingBottom?: string;
}

const Panel: React.FC<React.PropsWithChildren<PanelProps>> = ({
  width,
  height,
  paddingLeft = '20px',
  paddingRight = '20px',
  paddingTop = '20px',
  paddingBottom = '20px',
  backgroundColor,
  children
}) => {
  const theme = useTheme();

  const cardStyle: CSSProperties = {
    backgroundColor: backgroundColor ?? `${theme.palette.secondary.main}`,
    borderRadius: '14px',
    borderColor: `${theme.palette.grey[100]}`,
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'flex',
    minWidth: '200px',
    minHeight: '200px',
    flexDirection: 'column' as const,
    paddingTop,
    paddingBottom
  };

  return (
    <div
      style={{
        ...cardStyle,
        width: width,
        height: height,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight
      }}
    >
      {children}
    </div>
  );
};

export default Panel;

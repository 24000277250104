import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/learningPlans';
import { LearningPlan, LearningPlanAssignment } from '../entities/LearningPlan';
import { TemporalNewId } from '../core/constants';
import { TransactionStatusEnum } from '../core/enums';

import {
  LEARNING_PLAN_NEW,
  LEARNING_PLAN_SET_PLANS,
  LEARNING_PLAN_UPDATE,
  LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
  LEARNING_PLAN_RESET_TRANSACTION_STATUS,
  LEARNING_PLAN_SET_COURSES,
  LEARNING_PLAN_UPDATE_ASSIGNMENT,
  LEARNING_PLAN_IS_LOADING,
  LEARNING_PLAN_SET_ASSIGNMENT_STATUS,
  LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS,
  LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  LEARNING_PLAN_PLAY
} from '../actions/actionTypes';
import { Course } from '../entities/Course';
import { sortByKey } from '../utils/sorting';
import {
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition,
  LearningPlanGroupAssignment,
  LearningPlanPlayAssignment
} from '../entities/Assignment';

export interface LearningPlanState {
  learningPlans: LearningPlan[];
  partnersLearningPlans: LearningPlan[];
  currentLearningPlan: LearningPlan | null;
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  currentLearningPlanCourses: Course[];
  currentLearningPlanAssignment: LearningPlanAssignment | null;
  isLoading: boolean;
  learningPlanAssignmentStatus: EmployeeAssignmentStatus[] | null;
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
  learningPlanPlayAssignment: LearningPlanPlayAssignment | null;
}

const unloadedState: LearningPlanState = {
  currentLearningPlan: null,
  learningPlans: [],
  partnersLearningPlans: [],
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  currentLearningPlanCourses: [],
  currentLearningPlanAssignment: null,
  isLoading: false,
  learningPlanAssignmentStatus: null,
  groupAssignmentDefinitions: null,
  learningPlanPlayAssignment: null
};

export const newLearningPlan: LearningPlan = {
  id: TemporalNewId,
  name: '',
  description: '',
  certificate: undefined,
  thumbnailUrl: '',
  courseCount: 0,
  assignedEmployeesCount: 0,
  isDraft: true,
  entries: [],
  companyId: '',
  revisionNumber: 0,
  visibleForAdminsOnly: false,
  isPartnerCompany: false,
  learningPlanCode: ''
};

export const newLearningPlanAssignment: LearningPlanAssignment = {
  learningPlanId: '',
  learningPlanCompanyId: '',
  employeeIds: []
};

export const newLearningPlanGroupAssignment: LearningPlanGroupAssignment = {
  learningPlanId: '',
  groups: [],
  applyToAll: false
};

export const LearningPlansReducer: Reducer<LearningPlanState> = (
  state: LearningPlanState | undefined,
  incomingAction: Action
): LearningPlanState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case LEARNING_PLAN_NEW:
      return {
        ...state,
        currentLearningPlan: newLearningPlan,
        currentLearningPlanCourses: [],
        currentLearningPlanAssignment: null
      };
    case LEARNING_PLAN_UPDATE:
      return { ...state, currentLearningPlan: action.learningPlan };
    case LEARNING_PLAN_SET_COURSES:
      return {
        ...state,
        currentLearningPlanCourses: action.learningPlanCourses
      };
    case LEARNING_PLAN_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case LEARNING_PLAN_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    case LEARNING_PLAN_SET_PLANS:
      return {
        ...state,
        learningPlans: action.plans
          .slice()
          .sort(sortByKey<LearningPlan>('name'))
      };
    case LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS:
      return { ...state, partnersLearningPlans: action.learningPlansPartner };
    case LEARNING_PLAN_UPDATE_ASSIGNMENT:
      return {
        ...state,
        currentLearningPlanAssignment: action.learningPlanAssigment
      };
    case LEARNING_PLAN_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case LEARNING_PLAN_SET_ASSIGNMENT_STATUS:
      return {
        ...state,
        learningPlanAssignmentStatus: action.learningPlanAssignmentStatus
      };
    case LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS:
      return {
        ...state,
        groupAssignmentDefinitions: action.groupAssignmentDefinitions
      };
    case LEARNING_PLAN_PLAY:
      return {
        ...state,
        learningPlanPlayAssignment: action.learningPlanPlayAssignment
      };
    default:
      return state;
  }
};

import React, { CSSProperties } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/BW-Logo-WithText.png';
import trainingLogo from '../../assets/new-buildwitt-training.png';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';

import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import usePermissions from '../../hooks/usePermissions';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import HorizontalLine from '../core/HorizontalLine/HorizontalLine';
import { useTranslation } from 'react-i18next';

export interface ILeftMenuLink {
  url: string;
  label: string;
  localizationKey: string;
  iconName?: string;
  isSeparator: boolean;
  permission: string;
  show?: boolean;
  testId: string;
}

export interface ILeftMenuProps {
  routes: ILeftMenuLink[];
  vWindow?: () => Window;
  mobileOpen: boolean;
  isFreeTrial?: boolean;
  setMobileOpen: () => void;
}

const drawerWidth = 200;

const LeftMenu: React.FC<ILeftMenuProps> = ({
  routes,
  vWindow,
  mobileOpen,
  setMobileOpen,
  isFreeTrial
}) => {
  const theme = useTheme();
  const hasPermission = usePermissions();
  const user = useCurrentUser();
  const companyLogo =
    (user?.profile[UserClaims.CompanyLogoUrl] as string) || logo;
  const { t } = useTranslation(['leftMenu']);

  const sideBarstyle: CSSProperties = {
    backgroundColor: '#1D1D1E',
    borderColor: theme.palette.grey['100'],
    borderWidth: '1px',
    height: '100%',
    borderRightStyle: 'solid' as const,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: isFreeTrial ? '50px' : '0px'
  };

  const menuFooterStyle = {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column' as const,
    padding: '24px'
  };

  const imageFooterStyle = {
    width: '100%'
  };
  const logoStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  };
  const listStyle = {
    listStyle: 'none',
    marginTop: '40px',
    paddingLeft: '0px'
  };
  const listItemStyle = {
    lineHeight: '40px',
    paddingLeft: '20px'
  };

  const logoContainer = {
    display: 'flex',
    minHeight: '69px',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const withoutFeatureFlag = (result: ILeftMenuLink) => {
    if (
      result.label === 'Learning Plans' ||
      result.label === 'Courses' ||
      result.label === 'Lessons'
    )
      return false;

    return true;
  };

  const mapRoutes = routes.filter(withoutFeatureFlag);

  const container =
    vWindow !== undefined ? () => new Window().document.body : undefined;
  const drawer = (
    <div style={sideBarstyle}>
      <Toolbar style={logoContainer}>
        <img src={companyLogo} alt="Logo" style={logoStyle} />
      </Toolbar>
      <Divider />
      <ul style={listStyle}>
        {mapRoutes
          .filter(
            route => hasPermission(route.permission) && (route.show ?? true)
          )
          .map(route => {
            const sep = route.isSeparator ? (
              <>
                <br />
                <HorizontalLine color={'#999999'} line={2} />
              </>
            ) : (
              ''
            );

            return (
              <React.Fragment key={route.url}>
                {sep}
                <li className="nav-item" style={listItemStyle}>
                  <NavLink
                    className={({ isActive, isPending, isTransitioning }) =>
                      [isActive ? 'current-link' : '', 'nav-link'].join(' ')
                    }
                    to={route.url}
                    data-testid={route.testId}
                  >
                    <span
                      style={{
                        width: '25px',
                        textAlign: 'center',
                        display: 'inline-table'
                      }}
                    >
                      <img src={route.iconName} alt={route.iconName} />
                    </span>
                    <span style={{ marginLeft: '15px' }}>
                      {t(route.localizationKey, { ns: 'leftMenu' })}
                    </span>
                  </NavLink>
                </li>
              </React.Fragment>
            );
          })}
        {/* bottom logo */}
        {/*<Box className="bottomLogo" md={{ mr: 2, display: { sm: 'none' } }}>
          <img src={BWLogo} alt="Buildwitt Logo" style={{ width: '10%' }} />
          <img
            src={BWLogoText}
            alt="Buidwitt Logo text"
            style={{ width: '40%', height: '95%' }}
          />
      </Box>*/}
      </ul>
      <div style={menuFooterStyle}>
        <img style={imageFooterStyle} src={trainingLogo} alt="Training" />
      </div>
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 1 }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={setMobileOpen}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
export default LeftMenu;

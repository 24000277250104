export const getCookie = (key: string) => {
  try {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${key}=`))
      ?.split('=')[1];
    return cookieValue;
  } catch (e) {
    console.log('error getting cookie' + e);
    return '';
  }
};

export const removeCookie = (key: string) => {
  try {
    document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  } catch (e) {
    console.log('error removing cookie' + e);
  }
};

export const CookieNames = {
  selectedPage: 'selectedPage'
};

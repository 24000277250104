import { useTranslation } from 'react-i18next';

export const useCalendarHelper = () => {
  const { t } = useTranslation(['settings']);

  const calendarHeaderNames = [
    t('sundayAbbreviation'),
    t('mondayAbbreviation'),
    t('tuesdayAbbreviation'),
    t('wednesdayAbbreviation'),
    t('thursdayAbbreviation'),
    t('fridayAbbreviation'),
    t('saturdayAbbreviation')
  ];
  const monthNames = [
    t('january'),
    t('february'),
    t('march'),
    t('april'),
    t('may'),
    t('june'),
    t('july'),
    t('august'),
    t('september'),
    t('october'),
    t('november'),
    t('december')
  ];

  return {
    constants: {
      calendarHeaderNames,
      monthNames
    }
  };
};

import { CSSProperties, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Guid } from 'guid-typescript';
import Box from '@mui/material/Box';
import { CircularProgress } from '@mui/material';

import { Lesson } from '../../../entities/Lesson';
import { ApplicationState } from '../../../store';
import { actionCreators as tagActions } from '../../../actions/tags';
import { actionCreators as lessonActions } from '../../../actions/lessons';
import { LessonQuiz } from '../../../core/constants';
import LessonForm from '../../../components/LessonForm/v1.2/LessonForm';
import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import { useLesson } from '../useLesson';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { LessonFormModel } from '../../../entities/LessonFormModel';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';

const emptyLesson: Lesson = {
  id: '',
  title: '',
  description: '',
  order: 0,
  companyId: '',
  courseId: '',
  contentMedium: 0,
  videoUrl: '',
  audioUrl: '',
  videoId: '',
  thumbnailUrl: '',
  learningMaterialUrl: '',
  externalVideoUrl: '',
  lessonType: '',
  tags: [],
  subtitles: [],
  isVideoEncoding: false,
  isEncodingFailed: false,
  lessonContentType: 0,
  attachments: [],
  audioDuration: '',
  audioId: '',
  visibleForAdminsOnly: false,
  lessonDuration: '0',
  lessonCode: '',
  hlsUrl: '',
  dashUrl: ''
};

const CreateEditLesson: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(['contentLibrary', 'lessons', 'common']);
  const {
    api: { handleSaveLesson, handleUpdateLesson, handleSaveLessonAssessment },
    state: { uploadVideoProgress, createError }
  } = useLesson(location.state?.isInCourses ?? false);
  const [lesson, setLesson] = useState<Lesson>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const [deleteLessonError, setDeleteLessonError] = useState<boolean>(false);
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons!.currentLesson
  );
  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );
  const isEdit = location.state?.isEditing ?? false;

  useEffect(() => {
    if (!currentLesson && isEdit && params.id) {
      getLesson(location.state.isInCourses!, params.id);
    }

    if (!companyTags?.length) {
      dispatch(tagActions.requestTags());
    }

    return () => {
      dispatch(lessonActions.setCurrentLesson(null));
    };
  }, []);

  useEffect(() => {
    if (!isEdit && !lesson) {
      setLesson(emptyLesson);
      return;
    }

    requestAssessmentInfo();

    if (currentLesson && currentLesson.questions && !lesson && isEdit) {
      setLesson(currentLesson);
    }
  }, [currentLesson, isEdit]);

  useEffect(() => {
    if (!!createError.length || deleteLessonError) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [createError, deleteLessonError]);

  const requestAssessmentInfo = () => {
    if (isEdit && currentLesson && !currentLesson.questions) {
      if (
        currentLesson.lessonType === LessonQuiz &&
        currentLesson.assessmentId !== Guid.EMPTY
      )
        dispatch(
          lessonActions.requestQuizQuestions(currentLesson.assessmentId!)
        );
      else if (
        currentLesson.lessonType !== LessonQuiz ||
        (currentLesson.lessonType === LessonQuiz &&
          currentLesson.assessmentId === Guid.EMPTY)
      )
        dispatch(lessonActions.requestAssessmentQuestions(currentLesson.id));
    }
  };

  const getLesson = async (isInCourses: boolean, lessonId: string) => {
    if (isInCourses) {
      await dispatch<Promise<void>>(
        lessonActions.requestLessonByEntryId(lessonId)
      );
    } else {
      await dispatch<Promise<void>>(lessonActions.requestLessonById(lessonId));
    }
  };

  const handleSaveTrainingLesson = async (
    lessonModel: LessonFormModel,
    isEdit: boolean
  ) => {
    const lessonId = await handleSaveLesson(
      lessonModel,
      isEdit,
      location.state?.courseId
    );

    return lessonId;
  };

  const handleSaveComplete = () => {
    const message = isEdit
      ? t('lessonuUpdatedSuccessfully', { ns: 'lessons' })
      : t('lessonCreatedSuccessfully', { ns: 'lessons' });
    enqueueSnackbar(message, { variant: 'success' });
    dispatch(tagActions.requestTags());
    handleGoBack();
  };

  const handleGoBack = () => {
    if (location.state?.courseId && location.state?.isInCourses) {
      navigate(`/content-library/courses/${location.state?.courseId}/edit`, {
        state: { isEditing: true }
      });
    } else {
      navigate(-1);
    }
  };

  const handleDelete = async () => {
    try {
      await dispatch<Promise<void>>(lessonActions.deleteLesson(lesson!.id));
      setIsConfirmationModalOpen(false);
      setDeleteLessonError(false);
      handleGoBack();
    } catch (e) {
      setDeleteLessonError(true);
    }
  };

  return (
    <Grid
      container
      direction="column"
      paddingLeft="1.5rem"
      paddingRight="1.5rem"
      id="lessonContainer"
      sx={{ paddingLeft: { xs: '0px' }, paddingRight: { xs: '0px' } }}
    >
      <Grid item paddingBottom="0.5rem" paddingTop="1rem">
        <GoBackOption
          textLink={t(
            location.state?.isInCourses ? 'backToCourse' : 'backContentLibrary',
            { ns: 'contentLibrary' }
          )}
          onGoBack={handleGoBack}
        />
      </Grid>
      <Grid item paddingBottom="1.5rem" paddingTop="0.5rem">
        <h4>
          {isEdit
            ? t('editALesson', { ns: 'lessons' })
            : t('createALesson', { ns: 'lessons' })}
        </h4>
      </Grid>
      <Grid item id="lessonFormParentContainer" sx={{ width: { xs: '100%' } }}>
        {!lesson && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        )}
        {lesson && (
          <>
            {!!createError.length && (
              <span style={errorStyle}>{createError}</span>
            )}
            {deleteLessonError && (
              <span style={errorStyle}>
                {t('deleteLessonException', { ns: 'lessons' })}
              </span>
            )}
            <LessonForm
              lesson={lesson}
              companyTags={companyTags || []}
              handleSaveLesson={handleSaveTrainingLesson}
              handleUpdateLesson={handleUpdateLesson}
              handleSaveLessonAssessment={handleSaveLessonAssessment}
              onSaveComplete={handleSaveComplete}
              isEdit={isEdit}
              showProgress={true}
              progressValue={uploadVideoProgress}
              setIsConfirmationModalOpen={setIsConfirmationModalOpen}
            />
            <ConfirmationModal
              show={isConfirmationModalOpen}
              showSave={false}
              showCancel={false}
              maxWidth="sm"
              onSave={handleDelete}
              onCancel={() => setIsConfirmationModalOpen(false)}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={12} sx={gridItemStyles}>
                  <Typography variant="h6">
                    {t('areYouSureToDelete', { ns: 'common' })} &quot;
                    {lesson.title}
                    &quot;?
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={gridItemStyles}>
                  <BasicButton
                    onClick={() => setIsConfirmationModalOpen(false)}
                    color="secondary"
                    width={'200px'}
                    height={'50px'}
                  >
                    {t('cancel', { ns: 'common' })}
                  </BasicButton>
                </Grid>
                <Grid item xs={6} sx={gridItemStyles}>
                  <BasicButton
                    onClick={handleDelete}
                    color="confirm"
                    width={'240px'}
                    height={'50px'}
                  >
                    {t('deleteLesson', { ns: 'lessons' })}
                  </BasicButton>
                </Grid>
              </Grid>
            </ConfirmationModal>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const gridItemStyles: SxProps = {
  textAlign: 'center'
};

const errorStyle: CSSProperties = {
  color: 'red',
  paddingBottom: '1rem'
};

export default CreateEditLesson;

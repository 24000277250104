import config from '../config';
import { appPermissions } from '../core/constants';
import useDevCycleSdk from './useDevCycleSdk';
import certification from '@buildwitt/component-library/src/icons/certification.svg';
import dashboard from '@buildwitt/component-library/src/icons/dashboard.svg';
import assign from '@buildwitt/component-library/src/icons/assign.svg';
import contentLibrary from '@buildwitt/component-library/src/icons/contentLibrary.svg';
import onsiteTraining from '@buildwitt/component-library/src/icons/onsite-training.svg';
import learningPlans from '@buildwitt/component-library/src/icons/learningPlans.svg';
import courses from '@buildwitt/component-library/src/icons/courses.svg';
import lessons from '@buildwitt/component-library/src/icons/lessons.svg';
import employees from '@buildwitt/component-library/src/icons/employees.svg';
import analytics from '@buildwitt/component-library/src/icons/analytics.svg';
import skills from '@buildwitt/component-library/src/icons/skills.svg';
import settings from '@buildwitt/component-library/src/icons/settings.svg';
import { useCertifierRole } from '../hooks/useCertifierRole';

const useRoutes = () => {
  const {
    variables: { skillsAndCertifications, newCertificatesFlow }
  } = useDevCycleSdk();

  const [userIsAdminOrCertifier] = useCertifierRole();
  const routes = [
    {
      url: '/dashboard',
      label: 'Dashboard',
      localizationKey: 'dashboard',
      iconName: dashboard,
      permission: appPermissions.VIEW_DASHBOARD,
      isSeparator: false,
      testId: 'dashboardMenuOptionTestId'
    },
    {
      url: '/assignments',
      label: 'Assignments',
      localizationKey: 'learningHub',
      iconName: assign,
      permission: appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
      isSeparator: false,
      testId: 'assignmentsMenuOptionTestId'
    },
    {
      url: '/content-library',
      label: 'ContentLibrary',
      localizationKey: 'contentLibrary',
      iconName: contentLibrary,
      permission: appPermissions.VIEW_CONTENT_LIBRARY,
      isSeparator: false,
      testId: 'contentLibraryMenuOptionTestId'
    },
    {
      url: '/onsite-training',
      label: 'Onsite Training',
      localizationKey: 'onsiteTraining',
      iconName: onsiteTraining,
      permission: appPermissions.VIEW_ONSITE_TRAINING,
      isSeparator: false,
      show: config.ONSITE_TRAINING,
      testId: 'onsiteTrainingMenuOptionTestId'
    },
    {
      url: '/learning-plans',
      label: 'Learning Plans',
      localizationKey: 'learningPlans',
      iconName: learningPlans,
      permission: appPermissions.VIEW_LEARNING_PLANS,
      isSeparator: false,
      testId: 'learningPlansMenuOptionTestId'
    },
    {
      url: '/courses',
      label: 'Courses',
      localizationKey: 'courses',
      iconName: courses,
      permission: appPermissions.VIEW_COURSES,
      isSeparator: false,
      testId: 'coursesMenuOptionTestId'
    },
    {
      url: '/lessons',
      label: 'Lessons',
      localizationKey: 'lessons',
      iconName: lessons,
      permission: appPermissions.VIEW_LESSONS,
      isSeparator: false,
      testId: 'lessonsMenuOptionTestId'
    },
    {
      url: '/employees',
      label: 'Employees',
      localizationKey: 'employees',
      iconName: employees,
      permission: appPermissions.VIEW_EMPLOYEES,
      isSeparator: false,
      testId: 'employeesMenuOptionTestId'
    },
    {
      url: '/analytics',
      label: 'Analytics',
      localizationKey: 'analytics',
      iconName: analytics,
      permission: appPermissions.VIEW_ANALYTICS,
      isSeparator: false,
      testId: 'analyticsMenuOptionTestId'
    },
    {
      url: '/skills',
      label: 'Skills',
      localizationKey: 'skills',
      iconName: skills,
      permission: appPermissions.VIEW_SKILLS,
      isSeparator: false,
      show: skillsAndCertifications.skillsAndCertifications,
      testId: 'skillsMenuOptionTestId'
    },
    {
      url: '/certifications',
      label: 'Certifications',
      localizationKey: 'certifications',
      iconName: certification,
      permission: userIsAdminOrCertifier
        ? appPermissions.PREVIEW_COURSE
        : appPermissions.VIEW_CERTIFICATIONS /*workaround to allow access for certifiers until we refactor permissions overriden*/,
      isSeparator: false,
      show: newCertificatesFlow.newCertificatesFlow && userIsAdminOrCertifier,
      testId: 'certificationsMenuOptionTestId'
    },
    {
      url: '/settings',
      label: 'Settings',
      localizationKey: 'settings',
      iconName: settings,
      permission: appPermissions.VIEW_SETTINGS,
      isSeparator: true,
      testId: 'settingsMenuOptionTestId'
    }
  ];

  return [routes];
};

export default useRoutes;

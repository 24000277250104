import { CSSProperties } from 'react';

export const cardContainer: CSSProperties = {
  minHeight: 550,
  borderRadius: 5,
  marginTop: 20,
  border: '2px solid #999999'
};

export const actionButtonsContainer: CSSProperties = {
  position: 'absolute',
  display: 'flex',
  gap: '7px',
  margin: '12px',
  width: '100px',
  justifyContent: 'space-between'
};

export const title: CSSProperties = {
  fontSize: '12px',
  background: '#1b1c1c',
  padding: '0px 10px',
  borderRadius: 22,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Alert } from '@mui/lab';
import axios from 'axios';
import moment from 'moment';

import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import DatePickerInput from '../../../components/core/DatePickerInput/DatePickerInput';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import config from '../../../config';

interface UpdateDueDateModalProps {
  learningUnitType: string;
  assignmentId?: string;
  dueDate?: Date;
  onClose: () => void;
  onSuccessfulSaved: () => Promise<void>;
}

const UpdateDueDateModal = ({
  learningUnitType,
  assignmentId,
  dueDate,
  onClose,
  onSuccessfulSaved
}: UpdateDueDateModalProps) => {
  const [newdueDate, setNewDueDate] = useState<Date>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation(['analytics', 'common', 'assignments']);

  useEffect(() => {
    if (assignmentId && dueDate) {
      const localDueDate = absUtcDateToLocalMoment(dueDate).toDate();
      setNewDueDate(localDueDate);
      setOpenModal(true);
    }
  }, [assignmentId, dueDate]);

  const handleClose = () => {
    setOpenModal(false);
    setNewDueDate(undefined);
    onClose();
  };

  const handleSave = async () => {
    setError('');
    setSavingInProgress(true);
    const url = `${config.STUDENT_API_URL}assignments/${learningUnitType}/${assignmentId}/dueDate`;

    try {
      await axios.put(url, {
        dueDateUtc: moment(newdueDate).startOf('day').toDate()
      });
      await onSuccessfulSaved();
      handleClose();
    } catch (e) {
      setError(t('updateDueDateErrorMsg', { ns: 'analytics' }));
    } finally {
      setSavingInProgress(false);
    }
  };

  return (
    <ModalComponent
      showModal={openModal}
      title={t('updateDueDate', { ns: 'analytics' })}
      savingInProgress={savingInProgress}
      maxWidth="sm"
      saveButtonText={t('save', { ns: 'common' })}
      onClose={handleClose}
      onSave={handleSave}
    >
      <Box sx={{ height: '300px' }}>
        <span>{t('dueDate', { ns: 'assignments' })}</span>
        <DatePickerInput
          value={newdueDate}
          disablePastDays={true}
          onChange={date => setNewDueDate(date)}
        />
        {!!error.length && <Alert severity={'error'}>{error}</Alert>}
      </Box>
    </ModalComponent>
  );
};

export default UpdateDueDateModal;

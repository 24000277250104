import { BaseCourseCardWrapper } from './CourseCardWrapper';
import { ICourseReadonlyCardProps } from '../../entities/Card';
import {
  cardTitle,
  courseCardContainerStyle,
  EditSection,
  InfoSection,
  labelStyle,
  spanStyle
} from './CourseCardStyles';
import { CourseUnitTypes } from '../../core/constants';
import MovieIcon from '../Icons/MovieIcon';
import { Stack } from '@mui/material';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import { Subtitles } from '../core/ChipSubtitles/ChipSubtitles';
import ChipAverageTime from '../core/ChipAverageTime/ChipAverageTime';
import React, { CSSProperties } from 'react';
import moment from 'moment/moment';
import { isNewContent } from '../../utils/dateUtils';
import { useTranslation } from 'react-i18next';
import { useGetCardOwnerShip } from '../../hooks/useGetCardOwnership';
import { NewContentHeader } from '../core/InfoCardHeader/InfoCardHeader';

const buttonSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'space-between',
  alignItems: 'end'
};

/** TODO: CREATE BASE COMPONENT FOR THIS AND COURSECARDNEW COMPONENT **/

export const CourseReadonlyCard = ({
  course,
  type,
  hasSpanishSubtitles,
  averageLessonTime,
  children
}: React.PropsWithChildren<ICourseReadonlyCardProps>) => {
  const creationCourseDate = moment(course?.createdAt).local();
  const isNewContentCourse = isNewContent(creationCourseDate);
  const { t } = useTranslation(['common', 'assignments', 'courses', 'lessons']);
  const ownership = useGetCardOwnerShip(course);

  const renderOwnership = () => {
    if (ownership) {
      return (
        <InfoBySpan
          leftText={ownership.key}
          rightText={ownership.value}
          showProfilePicture={false}
          isOverdue={false}
        />
      );
    }

    return null;
  };

  return (
    <BaseCourseCardWrapper thumbnail={course.thumbnailUrl}>
      {isNewContentCourse && !course.isDraft && (
        <NewContentHeader label={t('newContent', { ns: 'common' })} />
      )}
      <div style={courseCardContainerStyle}>
        <div
          style={{
            ...InfoSection
          }}
        >
          <span style={labelStyle}>
            {t('course', { ns: 'courses' }).toUpperCase()}
          </span>
          <div
            style={{
              ...cardTitle
            }}
            className="breaking-text"
          >
            {course.title}
          </div>
          {!course.isDraft ? (
            <div style={{ cursor: 'default' }}>
              {renderOwnership()}
              {(type === undefined ||
                (type && type !== CourseUnitTypes.clickSafetyCourse)) && (
                <>
                  <div>
                    <MovieIcon />
                    <span style={spanStyle}>
                      {course.totalLessonCount}{' '}
                      {course.totalLessonCount === 1
                        ? t('lessonOrQuiz', { ns: 'lessons' })
                        : t('lessonsOrQuizzes', { ns: 'lessons' })}
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <span style={spanStyle}>{t('draftTitle', { ns: 'common' })}</span>
          )}
        </div>
        <div style={EditSection}></div>
      </div>
      <div style={buttonSection}>
        <Stack direction="column" alignItems="flex-start" spacing={0.5}>
          {hasSpanishSubtitles && (
            <Subtitles subtitlesText="Spanish Subtitles" />
          )}

          {!course.isDraft && averageLessonTime && (
            <ChipAverageTime
              showComplete={true}
              averageLessonTime={averageLessonTime}
            />
          )}
        </Stack>
        {children}
      </div>
    </BaseCourseCardWrapper>
  );
};

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/courses';
import {
  Course,
  CourseLessonName,
  AssignCourseToEmployeeDto,
  AiccCourse
} from '../entities/Course';
import { Tag } from '../entities/Tag';
import {
  COURSES_SET_COURSES,
  COURSES_SET_BW_COURSES,
  COURSES_SET_EDITING,
  COURSES_SET_TAGS,
  COURSE_BY_ID,
  COURSES_SET_LESSON_NAMES,
  COURSE_ERROR,
  COURSE_RESET_ERROR,
  COURSES_SET_COURSE_LIST,
  COURSES_UPDATE_TRANSACTION_STATUS,
  COURSES_UPDATE,
  COURSES_RESET_TRANSACTION_STATUS,
  COURSES_SET_THIRD_PARTY_COURSES,
  COURSE_SET_CLICKSAFETY_COURSE_LESSONS,
  COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID,
  COURSE_IS_LOADING,
  COURSES_SET_ASSIGNMENT_STATUS,
  COURSES_SET_PARTNERS_COURSES,
  COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  COURSE_PLAY,
  COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE
} from '../actions/actionTypes';
import { TransactionStatusEnum } from '../core/enums';
import { sortByKey } from '../utils/sorting';
import {
  CourseGroupAssignment,
  CoursePlayAssignment,
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition
} from '../entities/Assignment';
import { createSelector } from 'reselect';
import { ApplicationState } from '../store';
import { selectAvailableCourses } from './AchievementsReducer';

export interface CoursesState {
  courses: Course[];
  courseList: Course[];
  coursesBW: Course[];
  partnersCourses: Course[] | null;
  currentCourse: Course | null;
  tags: Tag[];
  courseLessonNames: CourseLessonName[];
  error: string;
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  aiccCourses: AiccCourse[] | null;
  clickSafetyCourseLessons: any[];
  clickSafetyLessonSessionId: any;
  isLoading: boolean;
  courseAssignmentStatus: EmployeeAssignmentStatus[] | null;
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
  coursePlayAssignment: CoursePlayAssignment | null;
}

const unloadedState: CoursesState = {
  courses: [],
  courseList: [],
  coursesBW: [],
  partnersCourses: [],
  currentCourse: null,
  tags: [],
  courseLessonNames: [],
  error: '',
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  aiccCourses: null,
  clickSafetyCourseLessons: [],
  clickSafetyLessonSessionId: null,
  isLoading: false,
  courseAssignmentStatus: null,
  groupAssignmentDefinitions: null,
  coursePlayAssignment: null
};

export const newAssignCourseToEmployeeDto: AssignCourseToEmployeeDto = {
  courseId: '',
  employeeIds: []
};

export const newCourseGroupAssignment: CourseGroupAssignment = {
  courseId: '',
  groups: [],
  applyToAll: false
};

export const CoursesReducer: Reducer<CoursesState> = (
  state: CoursesState | undefined,
  incomingAction: Action
): CoursesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case COURSES_SET_COURSES:
      return {
        ...state,
        courses: action.courses.slice().sort(sortByKey<Course>('title'))
      };
    case COURSES_SET_BW_COURSES:
      return {
        ...state,
        coursesBW: action.coursesBW.slice().sort(sortByKey<Course>('title'))
      };
    case COURSES_SET_PARTNERS_COURSES:
      return { ...state, partnersCourses: action.partnersCourses };
    case COURSES_SET_EDITING:
      return { ...state, currentCourse: action.course };
    case COURSES_SET_TAGS:
      return { ...state, tags: action.tags };
    case COURSE_BY_ID:
      return { ...state, currentCourse: action.course };
    case COURSES_SET_LESSON_NAMES:
      return { ...state, courseLessonNames: action.lessonNames };
    case COURSE_ERROR:
      return { ...state, error: action.error };
    case COURSE_RESET_ERROR:
      return { ...state, error: '' };
    case COURSES_SET_COURSE_LIST:
      return { ...state, courseList: action.courseList };
    case COURSES_UPDATE:
      return { ...state, currentCourse: action.course };
    case COURSES_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case COURSES_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    case COURSES_SET_THIRD_PARTY_COURSES:
      return { ...state, aiccCourses: action.aiccCourses };
    case COURSE_SET_CLICKSAFETY_COURSE_LESSONS:
      return {
        ...state,
        clickSafetyCourseLessons: action.clickSafetyCourseLessons
      };
    case COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID:
      return {
        ...state,
        clickSafetyLessonSessionId: action.clickSafetyLessonSessionId
      };
    case COURSE_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case COURSES_SET_ASSIGNMENT_STATUS:
      return {
        ...state,
        courseAssignmentStatus: action.courseAssignmentStatus
      };
    case COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS:
      return {
        ...state,
        groupAssignmentDefinitions: action.groupAssignmentDefinitions
      };
    case COURSE_PLAY:
      return { ...state, coursePlayAssignment: action.coursePlayAssignment };
    case COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE:
      return {
        ...state,
        coursePlayAssignment: {
          ...state.coursePlayAssignment!,
          lessonAssignments: state.coursePlayAssignment!.lessonAssignments.map(
            la =>
              la.id === action.lessonAssignmentId
                ? { ...la, isComplete: true }
                : la
          )
        }
      };
    default:
      return state;
  }
};

const base = createSelector(
  (state: ApplicationState) => state,
  state => state.courses
);

const selectCompanyCourses = createSelector(base, state => state.courses);
export const selectCoursesWithoutCompetence = createSelector(
  selectCompanyCourses,
  selectAvailableCourses,
  (courses, availableCoursesLearningUnit) => {
    const hash = availableCoursesLearningUnit.reduce((set, curr) => {
      set.add(curr.id);
      return set;
    }, new Set<string>());

    return courses.filter(course => hash.has(course.id));
  }
);

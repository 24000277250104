import moment from 'moment';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { Delete, CheckCircle } from '@mui/icons-material';
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import axios from 'axios';
import config from '../../config';
import { Assignment } from './DueDateModal';
import { LessonContentTypeEnum } from '../../core/enums';

const lessonNameGetter = (params: GridRenderCellParams) =>
  params.row.lesson?.title;
const courseNameGetter = (params: GridRenderCellParams) =>
  params.row.course?.title;
const learningPlanNameGetter = (params: GridRenderCellParams) =>
  params.row.learningPlanRevision?.name;

const dateGetter = (params: GridRenderCellParams) =>
  params.value ? moment(new Date(params.value)) : null;

const dateFormat = (params: GridValueFormatterParams) =>
  params.value ? moment(params.value).format('MM.DD.YY') : '';

const getCompletionDateColumn = (): GridColDef => ({
  field: 'completionDateUtc',
  headerName: 'Completed On',
  width: 120,
  valueGetter: params => (params.row.isComplete ? dateGetter(params) : ''),
  valueFormatter: dateFormat
});

const getLearningUnitTypeHeaderName = (assignmentType: string) => {
  switch (assignmentType) {
    case 'Lessons':
      return 'Lesson Names';
    case 'Courses':
      return 'Course Names';
    case 'LearningPlans':
      return 'Learning Plan Names';
    case 'Quizzes':
      return 'Quizz Names';
    default:
      return '';
  }
};

const getNameColumn = (assignmentType: string): GridColDef => ({
  field: 'name',
  headerName: getLearningUnitTypeHeaderName(assignmentType),
  valueGetter: params => {
    switch (assignmentType) {
      case 'Courses':
        return courseNameGetter(params);
      case 'Lessons':
      case 'Quizzes':
        return lessonNameGetter(params);
      case 'LearningPlans':
        return learningPlanNameGetter(params);
    }
  },
  flex: 1
});

const getColSpan =
  (assignmentType: string) =>
  (params: GridCellParams): number => {
    switch (assignmentType) {
      case 'Courses':
        return params.row.learningPlanId ? 2 : 1;
      case 'Lessons':
      case 'Quizzes':
        return !!params.row.learningPlanId || !!params.row.courseId ? 2 : 1;
      default:
        return 1;
    }
  };

const getHighLevelAssignmentLabel = (
  assignmentType: string,
  params: GridRenderCellParams
) => {
  let highLevelAssignmentLabel = '';

  switch (assignmentType) {
    case 'Courses':
      highLevelAssignmentLabel = params.row.learningPlanId
        ? 'Assigned at LP Level'
        : '';
      break;
    case 'Lessons':
    case 'Quizzes':
      highLevelAssignmentLabel =
        !params.row.learningPlanId && !!params.row.courseId
          ? 'Assigned at Course Level'
          : '';
      if (!highLevelAssignmentLabel.length) {
        highLevelAssignmentLabel = params.row.learningPlanId
          ? 'Assigned at LP Level'
          : '';
      }
      break;
  }

  return highLevelAssignmentLabel;
};

const getRemoveCompleteColumn = (
  assignmentType: string,
  unassign: unassignFunc,
  showCompleteAssignmentWarning: showCompleteAssignmentWarningFunc,
  showCompleteAssignmentWarningText: string,
  width = 120
): GridColDef => ({
  field: 'removeComplete',
  headerName: 'RemoveComplete',
  renderHeader: () => <></>,
  width: width,
  renderCell: params => {
    const highLevelAssignmentLabel = getHighLevelAssignmentLabel(
      assignmentType,
      params
    );
    if (highLevelAssignmentLabel.length) {
      return (
        <HighLevelAssignmentCell
          highLevelAssignmentLabel={highLevelAssignmentLabel}
          params={params}
          showCompleteAssignmentWarningText={showCompleteAssignmentWarningText}
          showCompleteAssignmentWarning={() =>
            showCompleteAssignmentWarning(assignmentType, params.row)
          }
        />
      );
    }

    return params.row.isComplete ? (
      ''
    ) : (
      <Box display={'flex'} justifyContent={'center'} width={'100%'}>
        <Stack direction="row" spacing={1}>
          <Button
            color={'secondary'}
            sx={{ minWidth: 'unset' }}
            size={'small'}
            onClick={() => unassign(assignmentType, params.id.toString())}
          >
            <Delete />
          </Button>
          <Tooltip title={showCompleteAssignmentWarningText}>
            <Button
              color={'secondary'}
              sx={{ minWidth: 'unset' }}
              size={'small'}
              onClick={() =>
                showCompleteAssignmentWarning(assignmentType, params.row)
              }
            >
              <CheckCircle />
            </Button>
          </Tooltip>
        </Stack>
      </Box>
    );
  }
});

export const getDueDateColumns = (
  assignmentType: string,
  unassign: unassignFunc,
  setAssignment: (assignment: Assignment) => void,
  showCompleteAssignmentWarning: showCompleteAssignmentWarningFunc,
  showCompleteAssignmentWarningText: string
): GridColDef[] => [
  getNameColumn(assignmentType),
  {
    field: 'createdTimestampUtc',
    headerName: 'Assigned On',
    width: 120,
    valueGetter: dateGetter,
    valueFormatter: dateFormat
  },
  {
    field: 'dueDateUtc',
    headerName: 'Due Date',
    width: 120,
    valueGetter: dateGetter,
    valueFormatter: dateFormat
  },
  getCompletionDateColumn(),
  {
    field: 'actions',
    headerName: ' ',
    minWidth: 120,
    colSpan: getColSpan(assignmentType),
    renderCell: params => {
      const highLevelAssignmentLabel = getHighLevelAssignmentLabel(
        assignmentType,
        params
      );
      if (highLevelAssignmentLabel.length) {
        return (
          <HighLevelAssignmentCell
            highLevelAssignmentLabel={highLevelAssignmentLabel}
            params={params}
            showCompleteAssignmentWarningText={
              showCompleteAssignmentWarningText
            }
            showCompleteAssignmentWarning={() =>
              showCompleteAssignmentWarning(assignmentType, params.row)
            }
          />
        );
      }

      return params.row.isComplete || !params.row.dueDateUtc ? (
        ''
      ) : (
        <Button
          color={'secondary'}
          size={'small'}
          sx={{ minWidth: 'unset' }}
          onClick={() => setAssignment(params.row)}
        >
          Edit Due Date
        </Button>
      );
    }
  },
  getRemoveCompleteColumn(
    assignmentType,
    unassign,
    showCompleteAssignmentWarning,
    showCompleteAssignmentWarningText
  )
];

export const getWorkloadColumns = (
  assignmentType: string,
  unassign: unassignFunc,
  showCompleteAssignmentWarning: showCompleteAssignmentWarningFunc,
  showCompleteAssignmentWarningText: string
): GridColDef[] => [
  getNameColumn(assignmentType),
  getCompletionDateColumn(),
  getRemoveCompleteColumn(
    assignmentType,
    unassign,
    showCompleteAssignmentWarning,
    showCompleteAssignmentWarningText,
    assignmentType !== 'LearningPlans' ? 210 : 90
  )
];

export const getAssignments = (assignmentType: string, employeeId: string) =>
  axios.get(
    `${config.STUDENT_API_URL!}assignments/${employeeId}/${assignmentType.toLowerCase()}`
  );

export const getLessonAssignments = async (employeeId: string) => {
  const lessonsResponse = await getAssignments('lessons', employeeId);
  return {
    data: lessonsResponse.data.filter(
      (x: any) => x.lesson?.lessonContentType !== LessonContentTypeEnum.Quiz
    )
  };
};

export const getQuizLessonAssignments = async (employeeId: string) => {
  const lessonsResponse = await getAssignments('lessons', employeeId);
  return {
    data: lessonsResponse.data.filter(
      (x: any) => x.lesson?.lessonContentType === LessonContentTypeEnum.Quiz
    )
  };
};

export const getAssignmentFetcher = async (
  assignmentType: string,
  employeeId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return getAssignments(assignmentType, employeeId);
    case 'Lessons':
      return getLessonAssignments(employeeId);
    case 'Quizzes':
      return getQuizLessonAssignments(employeeId);
    case 'LearningPlans':
      return getAssignments(assignmentType, employeeId);
  }
};

type unassignFunc = (assignmentType: string, assignmentId: string) => void;
type showCompleteAssignmentWarningFunc = (
  assignmentType: string,
  assignment: any
) => void;
const unassignAssignment = (assignmentType: string, assignmentId: string) =>
  axios.delete(
    `${config.STUDENT_API_URL!}assignments/unassign/${assignmentType.toLowerCase()}/${assignmentId}`
  );
const unassignLessons = (assignmentId: string) =>
  unassignAssignment('lesson', assignmentId);
const unassignCourses = (assignmentId: string) =>
  unassignAssignment('course', assignmentId);
const unassignLearningPlans = (assignmentId: string) =>
  unassignAssignment('learningplan', assignmentId);

export const getUnassignmentCall = async (
  assignmentType: string,
  assignmentId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return unassignCourses(assignmentId);
    case 'Lessons':
      return unassignLessons(assignmentId);
    case 'Quizzes':
      return unassignLessons(assignmentId);
    case 'LearningPlans':
      return unassignLearningPlans(assignmentId);
  }
};

const putAssignment = (assignmentType: string, assignmentId: string) =>
  `${config.STUDENT_API_URL!}assignments/${assignmentType}/${assignmentId}`;
const putLesson = (assignmentId: string) =>
  putAssignment('lesson', assignmentId);
const putCourse = (assignmentId: string) =>
  putAssignment('course', assignmentId);
const putLearningPlan = (assignmentId: string) =>
  putAssignment('learningplan', assignmentId);
export const getUpdateAssignmentEndpoint = (
  assignmentType: string,
  assignmentId: string
) => {
  switch (assignmentType) {
    case 'Courses':
      return putCourse(assignmentId);
    case 'Lessons':
      return putLesson(assignmentId);
    case 'Quizzes':
      return putLesson(assignmentId);
    case 'LearningPlans':
      return putLearningPlan(assignmentId);
    default:
      return '';
  }
};

interface HighLevelAssignmentCellProps {
  highLevelAssignmentLabel: string;
  params: any;
  showCompleteAssignmentWarningText: string;
  showCompleteAssignmentWarning: () => void;
}
const HighLevelAssignmentCell: React.FC<HighLevelAssignmentCellProps> = ({
  highLevelAssignmentLabel,
  params,
  showCompleteAssignmentWarningText,
  showCompleteAssignmentWarning
}) => {
  return (
    <>
      {highLevelAssignmentLabel}{' '}
      {!params.row.isComplete && (
        <Tooltip title={showCompleteAssignmentWarningText}>
          <Button
            color={'secondary'}
            sx={{ minWidth: 'unset', marginLeft: '8px' }}
            size={'small'}
            onClick={() => showCompleteAssignmentWarning()}
          >
            <CheckCircle />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

import { AppThunk } from '../store/index';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  NOTIFICATIONS_DISMISS_NOTIFICATION,
  NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED,
  NOTIFICATIONS_SET_NOTIFICATIONS
} from './actionTypes';
import config from './../config';
import { Notification } from '../entities/Notification';

export interface RequestNotificationsAction {
  type: 'NOTIFICATIONS_SET_NOTIFICATIONS';
  notifications: Notification[];
}

export interface MarkNotificationsAsViewedAction {
  type: 'NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED';
}

export interface DismissNotificationAction {
  type: 'NOTIFICATIONS_DISMISS_NOTIFICATION';
  notificationId: string;
}

export const actionCreators = {
  requestNotifications: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.STUDENT_API_URL}notifications/me`);
      dispatch({
        type: NOTIFICATIONS_SET_NOTIFICATIONS,
        notifications: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  markUpToDateNotifications: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      await axios.put(
        `${config.STUDENT_API_URL}notifications/me/mark-notifications-as-viewed`
      );
      dispatch({
        type: NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  dismissNotification:
    (notificationId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        await axios.put(
          `${config.STUDENT_API_URL}notifications/me/mark-notification-as-dismissed/${notificationId}`
        );
        dispatch({
          type: NOTIFICATIONS_DISMISS_NOTIFICATION,
          notificationId: notificationId
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
};

export type KnownAction =
  | DismissNotificationAction
  | MarkNotificationsAsViewedAction
  | RequestNotificationsAction
  | SetLoadingAction;

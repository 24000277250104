import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserNavigationContext } from '../pages/contentLibrary/UserNavigationContext';
import { LearningUnitType } from './useContentLibraryNavigation';

export const useGetNumberOfColumns = (learningPlanUnit: LearningUnitType) => {
  const isLg = useMediaQuery('(min-width:1200px)');
  const isXl = useMediaQuery('(min-width:1536px)');
  const isXXL = useMediaQuery('(min-width:1980px)');

  if (learningPlanUnit === 'learningPlans') {
    return 1;
  }

  if (isXXL) {
    return 4;
  } else if (isXl) {
    return 3;
  } else if (isLg) {
    return 2;
  } else {
    return 1;
  }
};
export function useVirtualizedContentLibraryList<T>(
  listRef: React.RefObject<HTMLElement>,
  items?: T[],
  learningPlanUnit: LearningUnitType = 'courses',
  isLoading = false
) {
  const [isScrollInit, setIsScrollInit] = useState(false);
  const [isMatrixInit, setIsMatrixInit] = useState(false);

  const [elementsMatrix, setElementsMatrix] = useState<T[][]>();
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigationApi = userNavigationState?.api;

  const numberOfColumns = useGetNumberOfColumns(learningPlanUnit);

  useEffect(() => {
    if (!isLoading && items?.length && items.length > 0) {
      const result = items.reduce((resultArray: T[][], item: T, index) => {
        const chunkIndex = Math.floor(index / numberOfColumns);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      setElementsMatrix(result);
      setIsMatrixInit(true);
    } else {
      setElementsMatrix([]);
    }
  }, [items, numberOfColumns, isLoading]);

  useEffect(() => {
    if (!isLoading && isMatrixInit && !isScrollInit) {
      setIsScrollInit(true);
      // Hack since we don't have an onload event on the list
      if (userNavigationState?.state.scrollPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: userNavigationState.state.scrollPosition
          });
        }, 100);
      }
    }
  }, [isScrollInit, isLoading, isMatrixInit]);

  const onScroll = useCallback(
    (_: any) => {
      if (isMatrixInit && !isLoading) {
        userNavigationApi?.changeScrollPosition(window.scrollY);
      }
    },
    [isMatrixInit, isLoading]
  );

  return [
    elementsMatrix,
    onScroll,
    userNavigationState?.state.scrollPosition
  ] as const;
}

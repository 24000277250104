import { Box } from '@mui/material';
import { CSSProperties } from 'react';

const NoResultsFound = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="50vh"
  >
    <span style={messageStyle}>There are no results available</span>
  </Box>
);

const messageStyle: CSSProperties = {
  fontSize: '17px'
};

export default NoResultsFound;

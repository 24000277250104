import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import {
  getColorThumbnails,
  getBWThumbnails
} from '../../services/blobStorage-service';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import { ThumbnailListView } from '../ThumbnailListView/ThumbnailListView';
import { InputFileUpload } from '../InputFileUpload/InputFileUpload';
import CircularProgress from '@mui/material/CircularProgress';
import { CSSProperties } from 'react';
import { makeStyles } from '@mui/styles';

interface ThumbnailLibraryPanelProps {
  onItemSelected: (item: any) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  thumbnailUrl: string;
  maxContentHeight?: string;
  acceptedImageTypes?: string;
  onRemoveUploadedFile?: () => void;
}
interface ThumbnailLibraryTabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabStyle: CSSProperties = {
  backgroundColor: 'Transparent !important',
  textTransform: 'capitalize',
  height: '100%'
};

const tabLoaderStyle: CSSProperties = {
  textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const ThumbnailLibraryTab = (props: ThumbnailLibraryTabProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`library-tabpanel-${index}`}
      aria-labelledby={`library-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: { xs: '0px', sm: '10px' } }}>{children}</Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `library-tab-${index}`,
    'aria-controls': `library-tabpanel-${index}`
  };
};

const ThumbnailLibraryPanel = ({
  onItemSelected,
  onChange,
  thumbnailUrl,
  maxContentHeight,
  acceptedImageTypes = '*.*',
  onRemoveUploadedFile
}: ThumbnailLibraryPanelProps) => {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedFileName, setSelectedFileName] = React.useState<string>('');
  const [colorThumbnails, setColorThumbnails] = React.useState<
    AzureBlobImageInfo[]
  >([]);
  const [bwThumbnails, setBwThumbnails] = React.useState<AzureBlobImageInfo[]>(
    []
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleItemClick = (item: AzureBlobImageInfo) => {
    if (selectedFileName.length) {
      setSelectedFileName('');
      if (onRemoveUploadedFile) {
        onRemoveUploadedFile();
      }
    }

    if (onItemSelected) {
      onItemSelected(item);
    }
  };

  const loadThumbnails = async () => {
    setIsLoading(true);
    const colorThumbs = await getColorThumbnails();
    const bwThumbs = await getBWThumbnails();
    setColorThumbnails(colorThumbs);
    setBwThumbnails(bwThumbs);
    setIsLoading(false);
  };

  React.useEffect(() => {
    loadThumbnails();
  }, []);

  const TabContainerStyle: CSSProperties = {
    borderRadius: '5px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.grey[100],
    height: '100%'
  };

  const useStyles = makeStyles(() => ({
    tabs: {
      '& .MuiTab-root.Mui-selected': {
        fontWeight: 900
      },
      '& .MuiTabs-indicator': {
        height: '4px'
      }
    }
  }));

  const classes = useStyles();
  return (
    <Box sx={{ width: '100%' }} style={TabContainerStyle}>
      {isLoading ? (
        <Box sx={{ display: 'flex' }} style={tabLoaderStyle}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={selectedTab}
              onChange={handleChangeTab}
              aria-label="Thumbnail Library"
              id="panelTabs"
              className={classes.tabs}
            >
              <Tab
                data-testid="thumbnailLibraryUploadTab"
                data-trackid="thumbnailLibraryUploadTab"
                label="Upload"
                {...a11yProps(0)}
                style={TabStyle}
              />
              <Tab
                data-testid="thumbnailLibraryColorTab"
                data-trackid="thumbnailLibraryColorTab"
                label="Color"
                {...a11yProps(1)}
                style={TabStyle}
              />
              <Tab
                data-testid="thumbnailLibraryBlackWhiteTab"
                data-trackid="thumbnailLibraryBlackWhiteTab"
                label="Black and White"
                {...a11yProps(2)}
                style={TabStyle}
              />
            </Tabs>
          </Box>
          <ThumbnailLibraryTab value={selectedTab} index={0}>
            <InputFileUpload
              thumbnailUrl={thumbnailUrl}
              initialText=""
              onChange={onChange}
              acceptedTypes={acceptedImageTypes}
              selectedFileName={selectedFileName}
              setSelectedFileName={setSelectedFileName}
              onRemoveFile={onRemoveUploadedFile}
            />
          </ThumbnailLibraryTab>
          <ThumbnailLibraryTab value={selectedTab} index={1}>
            <ThumbnailListView
              items={colorThumbnails}
              onItemSelected={handleItemClick}
              selectedImgUrl={thumbnailUrl}
              maxContentHeight={maxContentHeight}
              dataTestId="thumbnailLibraryColorListView"
              dataTrackId="thumbnailLibraryColorListView"
            />
          </ThumbnailLibraryTab>
          <ThumbnailLibraryTab value={selectedTab} index={2}>
            <ThumbnailListView
              items={bwThumbnails}
              onItemSelected={handleItemClick}
              selectedImgUrl={thumbnailUrl}
              maxContentHeight={maxContentHeight}
              dataTestId="thumbnailLibraryBlackWhiteListView"
              dataTrackId="thumbnailLibraryBlackWhiteListView"
            />
          </ThumbnailLibraryTab>
        </>
      )}
    </Box>
  );
};

export default ThumbnailLibraryPanel;

import React, { CSSProperties } from 'react';
import { Breakpoint } from '@mui/system';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { fontButton } from '../../../utils/buttonStyles';
import ModalComponent from '../ModalPage/ModalComponent';

export interface ModalComponentProps {
  showModal: boolean;
  title: string;
  savingInProgress: boolean;
  maxWidth: Breakpoint | false;
  saveButtonText: string;
  showSaveButton?: boolean;
  showCloseIcon?: boolean;
  content: string;
  onClose: () => void;
  onSave: () => void;
}

const textmodalComponentStyle: CSSProperties = {
  color: '#ffffff',
  fontSize: 20
};
const InfoModal: React.FC<React.PropsWithChildren<any>> = ({
  content,
  showModal,
  onClose,
  onSave
}) => {
  const { t } = useTranslation(['common']);

  return (
    <ModalComponent
      showModal={showModal}
      title={''}
      savingInProgress={false}
      maxWidth="sm"
      saveButtonText={t('continue', { ns: 'common' })}
      onClose={onClose}
      onSave={onSave}
      showCloseIcon={false}
    >
      <Typography style={{ ...fontButton, ...textmodalComponentStyle }}>
        {content}
      </Typography>
    </ModalComponent>
  );
};

export default InfoModal;

import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AssignmentTypes } from '../../entities/LearningPlaylist';
import { isOverdue } from '../../utils/dateUtils';
import AssignmentCardList from './AssignmentCardList';
import EmptyCard from '../../components/EmptyCard/EmptyCard';

export interface OverdueAssignmentsTabProps {
  dueDateLearningUnits?: Array<AssignmentTypes>;
}

const OverdueAssignmentsTab: React.FC<OverdueAssignmentsTabProps> = ({
  dueDateLearningUnits
}) => {
  const [overDueLearningUnits, setOverDueLearningUnits] =
    useState<Array<AssignmentTypes>>();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    if (dueDateLearningUnits) {
      let overDueItems = dueDateLearningUnits.filter(
        learningUnit =>
          isOverdue(moment(learningUnit.dueDateMoment!)) &&
          !learningUnit.isComplete
      );

      overDueItems = overDueItems.sort(
        (a: AssignmentTypes, b: AssignmentTypes) =>
          a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );
      setOverDueLearningUnits(overDueItems);
    }
  }, [dueDateLearningUnits]);

  return (
    <>
      {!!overDueLearningUnits && !!overDueLearningUnits.length && (
        <AssignmentCardList assignedLearningUnits={overDueLearningUnits} />
      )}
      {!overDueLearningUnits && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!!overDueLearningUnits && !overDueLearningUnits.length && (
        <EmptyCard width="370px" height="325px">
          <h5>{t('youDontAnyOverdueAssignment', { ns: 'assignments' })}</h5>
        </EmptyCard>
      )}
    </>
  );
};

export default OverdueAssignmentsTab;

import React, { CSSProperties, useState } from 'react';
import { actionCreators } from '../../actions/lessons';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ApplicationState } from '../../store';
import LessonCard from '../../components/LessonCard/LessonCard';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import { Col, Row, ButtonGroup } from 'react-bootstrap';
import { CourseLessonName, ImportLessonDto } from '../../entities/Course';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import {
  BuildWittName,
  Buildwitt_Value,
  Company_Value,
  Partners,
  Partners_Value
} from '../../core/constants';
import SelectEntityButton from '../../components/SelectEntityButton/SelectEntityButton';
import { actionCreators as lessonActions } from '../../actions/lessons';
// TODO Alejandro: Remove .css files
import './SelectLessons.css';
import { UserClaims } from '../../core/constants';
import { useTranslation } from 'react-i18next';
import { Lesson } from '../../entities/Lesson';
import config from '../../config';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useAppDispatch } from '../../store/hooks';
import ModalComponent from '../../components/core/ModalPage/ModalComponent';

export interface SelectLessonsProps {
  showModal: boolean;
  closeModal: () => void;
  currentLessons: CourseLessonName[];
  onSave: (selectedLessons: ImportLessonDto[]) => Promise<void>;
  callBack?: () => void;
}

const SelectLessons: React.FC<SelectLessonsProps> = ({
  showModal,
  closeModal,
  currentLessons,
  onSave,
  callBack
}) => {
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [selectedLessons, setSelectedLessons] = useState<string[]>([]);
  const [lessonsSource, setLessonsSource] = useState(Company_Value);
  const [lessonsToShow, setLessonsToShow] = useState<Lesson[]>([]);
  const [lessonsList, setLessonsList] = useState<Lesson[]>([]);
  const [searchBoxValue, setSearchBoxValue] = useState<string>('');
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;
  let searchTimeout: NodeJS.Timeout;
  const allLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const partnerLessons = useSelector(
    (state: ApplicationState) => state.lessons?.partnersLessons
  );

  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const { t } = useTranslation(['common', 'lessons']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isSaving, setIsSaving] = useState(false);

  const lessonSources = [
    {
      text: companyName,
      value: Company_Value
    },
    {
      text: BuildWittName,
      value: Buildwitt_Value
    }
  ];
  if (!config.CL_PARTNER) {
    lessonSources.push({
      text: Partners,
      value: Partners_Value
    });
  }

  React.useEffect(() => {
    dispatch(actionCreators.requestAllLessons());
    if (
      partnerLessons?.length === 0 &&
      partnerCompanies &&
      partnerCompanies.length > 0
    ) {
      const companyPartnersIds = partnerCompanies.map(company => company.id);
      dispatch(lessonActions.requestPartnersLessons(companyPartnersIds));
    }
  }, [dispatch]);

  React.useEffect(() => {
    const allLessonsToShow = allLessons!.filter(lesson => {
      const notInlessons = !currentLessons.some(
        currentLesson => currentLesson.lessonId === lesson.id
      );
      const isCompanyLesson = lesson.companyId === companyId;
      return notInlessons && isCompanyLesson;
    });
    setLessonsToShow(allLessonsToShow);
    setLessonsList(allLessonsToShow);
  }, [allLessons]);

  const onChangeLessonSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLessons(currentSelection => {
      if (e.target.checked) {
        return [...currentSelection, e.target.id];
      } else {
        return currentSelection.filter(lessonId => lessonId !== e.target.id);
      }
    });
  };
  const handleSaveImportLessons = async () => {
    setIsSaving(true);
    let lessonsToImport = allLessons
      ?.filter(lesson => selectedLessons.includes(lesson.id))
      .map(lesson => ({
        lessonId: lesson.id,
        companyId: lesson.companyId
      }));
    const lessonsPartnersToImport = partnerLessons
      ?.filter(lesson => selectedLessons.includes(lesson.id))
      .map(lesson => ({
        lessonId: lesson.id,
        companyId: lesson.companyId
      }));
    lessonsToImport = lessonsToImport?.concat(lessonsPartnersToImport!);
    await onSave(lessonsToImport!);
    closeModal();

    if (callBack) callBack();
    setIsSaving(false);
  };

  const handleChangeSelectButton = (valueSource: string) => {
    setLessonsSource(valueSource);
    let allLessonsToShow: Lesson[] = [];
    switch (valueSource) {
      case Company_Value:
        allLessonsToShow = allLessons!.filter(lesson => {
          const notInlessons = !currentLessons.some(
            currentLesson => currentLesson.lessonId === lesson.id
          );
          const isCompanyLesson = lesson.companyId === companyId;
          return notInlessons && isCompanyLesson;
        });
        break;
      case Buildwitt_Value:
        allLessonsToShow = allLessons!.filter(lesson => {
          const notInlessons = !currentLessons.some(
            currentLesson => currentLesson.lessonId === lesson.id
          );
          const isBWCompanyLesson = lesson.companyId === bWCompanyId;
          return notInlessons && isBWCompanyLesson;
        });
        break;
      case Partners_Value:
        allLessonsToShow = partnerLessons!.filter(lesson => {
          const notInlessons = !currentLessons.some(
            currentLesson => currentLesson.lessonId === lesson.id
          );
          return notInlessons;
        });
        break;
      default:
        break;
    }
    const filteredLessons = searchLessonsFunc(searchBoxValue, allLessonsToShow);
    setLessonsToShow(filteredLessons);
    setLessonsList(allLessonsToShow);
  };
  const handleSearchChange = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(async () => {
      const safeValue = value.toLowerCase();
      setSearchBoxValue(safeValue);
      const filteredLessons = searchLessonsFunc(safeValue, lessonsList);
      setLessonsToShow(filteredLessons);
    }, 1000);
  };

  const searchLessonsFunc = (filter: string, lessonsToFilter: Lesson[]) => {
    return lessonsToFilter.filter(
      lesson =>
        lesson.title.toLowerCase().includes(filter) ||
        lesson.description.toLowerCase().includes(filter) ||
        lesson.tags.find(tag => tag.name.toLowerCase().includes(filter))
    );
  };

  const toggleContainerStyle: CSSProperties = {
    textAlign: isMobile ? 'center' : 'right',
    marginTop: isMobile ? '5px' : '0px',
    marginLeft: isMobile ? '-10px' : '0px'
  };

  const buttonGroupStyle: CSSProperties = {
    width: isMobile ? '100%' : '258px'
  };

  const colReverse = 'column-reverse';
  const row = 'row';
  const searchContainer: CSSProperties = {
    flexDirection: isMobile ? colReverse : row,
    width: '98%'
  };
  return (
    <ModalComponent
      showModal={showModal}
      title={t('selectLessons', { ns: 'lessons' })}
      maxWidth="md"
      saveButtonText={t('save', { ns: 'common' })}
      showCloseIcon={true}
      showCancelButton={false}
      closeWhenClickBackdrop={true}
      closeWhenPressingEscape={false}
      onSave={handleSaveImportLessons}
      onClose={() => closeModal()}
      savingInProgress={isSaving}
    >
      <Row xs={3} className="px-0 flex-shrink-0" style={searchContainer}>
        <Col md={4} style={{ paddingTop: isMobile ? '7px' : '0px' }}>
          <SearchBox
            id="searchLessons"
            name="searchLessons"
            placeholderText={t('search', { ns: 'common' })}
            width={isMobile ? '100%' : '190px'}
            height="35px"
            value={''}
            onChangeValue={handleSearchChange}
          />
        </Col>
        <Col style={toggleContainerStyle}>
          <ButtonGroup style={buttonGroupStyle}>
            {lessonSources.map(source => (
              <BasicToggleButton
                key={`select-company-${source.value}`}
                id={`select-company-${source.value}`}
                type="radio"
                name="lessonsSource"
                value={source.value}
                checked={lessonsSource === source.value}
                color={lessonsSource === source.value ? 'primary' : 'secondary'}
                onChange={e => handleChangeSelectButton(e.currentTarget.value)}
              >
                {source.text}
              </BasicToggleButton>
            ))}
          </ButtonGroup>
        </Col>
      </Row>
      <Row className="card-container flex-fill" style={{ height: '250px' }}>
        {lessonsToShow!.map(lesson => (
          <Col className=" mb-3 p-0" md={12} lg={6} key={lesson.id}>
            <div className="pr-3">
              <LessonCard lesson={lesson}>
                <SelectEntityButton
                  entity={lesson}
                  isSelected={selectedLessons.includes(lesson.id)}
                  onChangeSelection={onChangeLessonSelection}
                />
              </LessonCard>
            </div>
          </Col>
        ))}

        {lessonsToShow!.length === 0 && (
          <Col className=" mb-3 p-0" xs={12}>
            {t('noLessonsAvailableFor', { ns: 'lessons' })}{' '}
            {lessonsSource === Buildwitt_Value ? 'BuildWitt' : companyName}
          </Col>
        )}
      </Row>
    </ModalComponent>
  );
};

export default SelectLessons;

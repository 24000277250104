import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import PageDoesntExist from './PageDoesntExist';

const DeniedAccessPage = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <PageDoesntExist
      detailedInfo={
        <Stack>
          <Typography
            sx={{
              ...theme.typography.h5,
              color: theme.palette.primary.light
            }}
          >
            {t('roleWithoutAccessToViewPage')}
          </Typography>
        </Stack>
      }
    />
  );
};

export default DeniedAccessPage;

import { CSSProperties } from 'react';
import BasicButton from '../../core/BasicButton/BasicButton';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  LessonFormModel,
  ContentMedium
} from '../../../entities/LessonFormModel';
import {
  fontButton,
  fontDeleteButton,
  buttonStyle,
  buttonMediumStyle
} from '../../../utils/buttonStyles';

export interface FooterActionButtonsProps {
  lessonData: LessonFormModel;
  isEdit: boolean;
  contentMedium: number;
  saveInProgress: boolean;
  questionsInQuiz: number;
  onSetIsConfirmationModalOpen?: (isOpen: boolean) => void;
  onSaveQuizLesson: (lessonData: LessonFormModel) => void;
  onSaveVideoLesson: (lessonData: LessonFormModel) => void;
}

const FooterActionButtons: React.FC<FooterActionButtonsProps> = ({
  lessonData,
  isEdit,
  contentMedium,
  saveInProgress,
  questionsInQuiz,
  onSetIsConfirmationModalOpen,
  onSaveQuizLesson,
  onSaveVideoLesson
}) => {
  const { t } = useTranslation(['common', 'lessons']);
  const isQuiz = contentMedium === ContentMedium.Quiz;
  const onSave = (lessonData: LessonFormModel) => {
    if (isQuiz) {
      onSaveQuizLesson(lessonData);
    } else {
      onSaveVideoLesson(lessonData);
    }
  };

  const isSaveDisabled = isQuiz
    ? saveInProgress || !lessonData.title.trim() || !questionsInQuiz
    : saveInProgress;

  return (
    <>
      {isEdit && (
        <BasicButton
          onClick={() =>
            onSetIsConfirmationModalOpen && onSetIsConfirmationModalOpen(true)
          }
          color="delete"
          data-testid="deleteLessonButton"
          data-trackid="deleteLessonButton"
          disable={saveInProgress}
          style={{ ...deleteStyle, ...buttonStyle }}
        >
          <Typography sx={fontDeleteButton}>
            {t('deleteLesson', { ns: 'lessons' })}
          </Typography>
        </BasicButton>
      )}
      <BasicButton
        onClick={() => onSave(lessonData)}
        color="primary"
        data-testid={isEdit ? 'saveLessonButton' : 'publishLessonButton'}
        data-trackid={isEdit ? 'saveLessonButton' : 'publishLessonButton'}
        disable={isSaveDisabled}
        style={buttonMediumStyle}
      >
        <Typography sx={fontButton}>
          {isEdit
            ? t('save', { ns: 'common' })
            : t('publish', { ns: 'common' })}
        </Typography>
      </BasicButton>
      {saveInProgress && (lessonData.video || lessonData.audioFile) && (
        <span style={errorStyle}>
          {t('uploadInProgress', { ns: 'common' })}
        </span>
      )}
    </>
  );
};

const errorStyle = {
  color: 'red'
};

const deleteStyle: CSSProperties = {
  marginRight: 16
};

export default FooterActionButtons;

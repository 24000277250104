import { Box, Checkbox, Stack, Typography, useTheme } from '@mui/material';
import { CustomIconButton } from '../../analytics/common/utilAnalyticsColumnDefinition';
import { useTranslation } from 'react-i18next';

interface RemoveAllSelectedCompProps {
  removeAllFunc: () => void;
  handleChangeSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RemoveAllSelectedComp = ({
  removeAllFunc,
  handleChangeSelectAll
}: RemoveAllSelectedCompProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['settings']);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#1D1D1E',
        border: `0.5px solid ${theme.palette.grey[100]}`
      }}
    >
      <Stack
        direction={'row'}
        style={{ paddingLeft: '0.5rem' }}
        alignItems={'center'}
      >
        <Checkbox
          id="agreeTerms"
          size="medium"
          onChange={handleChangeSelectAll}
        />
        <Typography sx={{ paddingLeft: '0' }}>
          {t('selectAll', { ns: 'settings' })}
        </Typography>
      </Stack>
      <CustomIconButton
        aria-label={'removeAllButton'}
        size="large"
        color="info"
        onClick={removeAllFunc}
        style={{ marginRight: '0.5rem' }}
      >
        {t('removeSelectedHalts', { ns: 'settings' })}
      </CustomIconButton>
    </Box>
  );
};

export default RemoveAllSelectedComp;

import { EmployeeGroup } from '../../entities/Employee';
import themeConfig from '../../themes/theme';
import { createTheme, SxProps, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SearchBox from '../core/SearchBox/SearchBox';
import TextDropdownList from '../core/TextDropdownList/TextDropdownList';
import { SortEmployeeOption } from '../../entities/SortEmployeeOption';
import { CircularProgress } from '@mui/material';
import { containerStyle } from './EmployeesHaltTable';
import GroupsHaltTable from './GroupsHaltTable';
import { useMemo, useState } from 'react';
import { sortByKey } from '../../utils/sorting';
export interface GroupsHaltPanelProps {
  groupsList: EmployeeGroup[];
  setSelectedGroupIds: (groupIds: string[]) => void;
  selectedGroupIds: string[];
  savingInProgress: boolean;
}

const GroupsHaltPanel: React.FC<GroupsHaltPanelProps> = ({
  groupsList,
  setSelectedGroupIds,
  selectedGroupIds,
  savingInProgress
}) => {
  let searchTimeout: NodeJS.Timeout;
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['common', 'assignments']);
  const [groupsToShow, setGroupsToShow] = useState<EmployeeGroup[]>([]);

  const handleSearch = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      const searchKey = value.toLowerCase();
      const groupsSearched = groupsList.filter(group =>
        group.name.toLowerCase().includes(searchKey)
      );
      setGroupsToShow(groupsSearched);
    }, 1000);
  };

  useMemo(() => {
    if (groupsList) {
      setGroupsToShow(groupsList);
    }
  }, [groupsList]);

  const handleSortChange = (e: any) => {
    const option = parseInt(e.target.value);
    switch (option) {
      case SortEmployeeOption.Az:
        setGroupsToShow(
          groupsToShow.slice().sort(sortByKey<EmployeeGroup>('name'))
        );
        break;
      case SortEmployeeOption.Za:
        setGroupsToShow(
          groupsToShow.slice().sort(sortByKey<EmployeeGroup>('name')).reverse()
        );
        break;
    }
  };
  const boxContainerStyle: SxProps = {
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '1rem 0 !important',
    borderRadius: '10px',
    backgroundColor: theme.palette.common.black
  };
  return (
    <ThemeProvider theme={theme}>
      <Box sx={boxContainerStyle}>
        <Grid item sx={containerStyle}>
          <Grid
            item
            container
            direction={'row'}
            justifyContent={'space-between'}
          >
            <Grid item style={{ paddingLeft: '16px' }}>
              <SearchBox
                id="searchGroups"
                value=""
                name="searchGroups"
                width="250px"
                height="38px"
                placeholderText={t('search', { ns: 'common' })}
                variant="dark"
                onChangeValue={handleSearch}
              />
            </Grid>
            <Grid
              item
              sx={{
                textAlign: 'right',
                paddingRight: '1rem',
                position: 'relative'
              }}
            >
              <TextDropdownList
                onChange={handleSortChange}
                label={`${t('sort', { ns: 'common' })}:`}
                items={[
                  { value: SortEmployeeOption.Az, text: 'A-Z' },
                  { value: SortEmployeeOption.Za, text: 'Z-A' }
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          {!!groupsList && !!groupsList.length && !savingInProgress ? (
            <GroupsHaltTable
              employeeGroups={groupsToShow}
              setSelectedGroupIds={setSelectedGroupIds}
              selectedGroupIds={selectedGroupIds}
            />
          ) : (
            <Grid
              style={{ height: '400px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default GroupsHaltPanel;

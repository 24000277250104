import React, { useEffect, useState, useRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import useCurrentUser from '../../hooks/useCurrentUser';
import { BuildWittName, UserClaims } from '../../core/constants';
import {
  Buildwitt_Value,
  Company_Value,
  TemporalNewId,
  Partners,
  Partners_Value
} from '../../core/constants';
import BasicToggleButton from '../../components/core/BasicToggleButton/BasicToggleButton';
import { actionCreators } from '../../actions/courses';
import { ApplicationState } from '../../store';
import { Course } from '../../entities/Course';
import CourseCard from '../../components/CourseCard/CourseCard';
import SelectEntityButton from '../../components/SelectEntityButton/SelectEntityButton';
import { LibraryTypesEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import { Box, Stack, SxProps } from '@mui/material';
import { useAppDispatch } from '../../store/hooks';

export interface SelectCoursesProps {
  currentCourses?: Course[];
  coursesLibraryType: LibraryTypesEnum;
  getSelectedCoursesIds: (coursesIds: string[]) => void;
}

const SelectCourses: React.FC<SelectCoursesProps> = ({
  currentCourses = [],
  coursesLibraryType,
  getSelectedCoursesIds
}) => {
  const [CoursesSource, setCoursesSource] = useState(Company_Value);
  const [coursesToShow, setCoursesToShow] = useState<Course[]>([]);
  const [selectedCoursesIds, setSelectedCoursesIds] = useState<string[]>([]);
  const coursesInSource = useRef<Course[]>([]);
  const searchValue = useRef<string>('');
  const user = useCurrentUser();
  const courseList = useSelector(
    (state: ApplicationState) => state.courses?.courseList
  );
  const partnerCourses = useSelector(
    (state: ApplicationState) => state.courses?.partnersCourses
  );
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const dispatch = useAppDispatch();
  const companyName = user?.profile[UserClaims.CompanyName];
  const companyId = user?.profile[UserClaims.CompanyId];
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId];
  const { t } = useTranslation(['common', 'courses']);

  const coursesSources = [
    {
      text: companyName,
      value: Company_Value
    },
    {
      text: BuildWittName,
      value: Buildwitt_Value
    }
  ];
  if (!config.CL_PARTNER) {
    coursesSources.push({
      text: Partners,
      value: Partners_Value
    });
  }

  useEffect(() => {
    if (coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt)
      dispatch(actionCreators.requestAllCoursesForCourseListStorage());
    else if (coursesLibraryType === LibraryTypesEnum.Buildwitt)
      dispatch(actionCreators.requestBuildwittCoursesForCourseListStorage());

    if (
      partnerCourses?.length === 0 &&
      partnerCompanies &&
      partnerCompanies.length > 0
    ) {
      const companyPartnersIds = partnerCompanies.map(company => company.id);
      dispatch(actionCreators.requestPartnersCourses(companyPartnersIds));
    }

    return () => {
      dispatch(actionCreators.setCourseList([]));
    };
  }, []);

  useEffect(() => {
    getSelectedCoursesIds(selectedCoursesIds);
  }, [selectedCoursesIds]);

  useEffect(() => {
    let filteredCourses = courseList
      ? [...courseList.filter(course => !course.isDraft)]
      : [];
    if (coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt) {
      filteredCourses =
        filteredCourses?.filter(course => {
          const notInCourses = !currentCourses.some(
            currentCourse => currentCourse.id === course.id
          );

          const isInCompany =
            CoursesSource === Buildwitt_Value
              ? course.companyId === TemporalNewId ||
                course.companyId === bWCompanyId ||
                course.companyId === null
              : course.companyId === companyId;

          return notInCourses && isInCompany;
        }) || [];
    }

    coursesInSource.current = filteredCourses;

    if (searchValue.current.length) handleSearchChange(searchValue.current);
    else setCoursesToShow(filteredCourses);
  }, [courseList]);

  const handleSearchChange = (value: string) => {
    const safeValue = value.toLowerCase();
    const filteredCourses = coursesInSource.current.filter(
      course =>
        course.title.toLowerCase().includes(safeValue) ||
        course.description.toLowerCase().includes(safeValue) ||
        course.tags.find(tag => tag.name.toLowerCase().includes(safeValue))
    );

    searchValue.current = safeValue;
    setCoursesToShow(filteredCourses);
  };

  const onChangeCourseSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCoursesIds(currentSelection => {
      if (e.target.checked) {
        return [...currentSelection, e.target.id];
      } else {
        return currentSelection.filter(courseId => courseId !== e.target.id);
      }
    });
  };

  const containerSelectButtonStyle = {
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end'
  };
  const handleChangeSelectButton = (valueSource: string) => {
    setCoursesSource(valueSource);
    let filteredCourses = courseList;
    switch (valueSource) {
      case Company_Value:
        filteredCourses = filteredCourses?.filter(course => {
          const notInCourses = !currentCourses.some(
            currentCourse => currentCourse.id === course.id
          );
          const isCompanyCourse = course.companyId === companyId;
          return notInCourses && isCompanyCourse;
        });
        break;
      case Buildwitt_Value:
        filteredCourses = filteredCourses?.filter(course => {
          const notInCourses = !currentCourses.some(
            currentCourse => currentCourse.id === course.id
          );
          const isBWCompanyCourse = course.companyId === bWCompanyId;
          return notInCourses && isBWCompanyCourse;
        });
        break;
      case Partners_Value:
        filteredCourses =
          partnerCourses?.filter(course => {
            const notInCourses = !currentCourses.some(
              currentCourse => currentCourse.id === course.id
            );
            return notInCourses;
          }) ?? [];
        break;
    }
    coursesInSource.current = filteredCourses!;
    if (searchValue.current.length) handleSearchChange(searchValue.current);
    else setCoursesToShow(filteredCourses!);
  };
  return (
    <>
      <Stack gap={1} sx={headerContainerStyle}>
        <Box sx={{ width: { xs: '100%', md: '250px' } }}>
          <SearchBox
            id="searchCourses"
            name="searchCourses"
            placeholderText={t('search', { ns: 'common' })}
            width="100%"
            height="40px"
            value=""
            onChangeValue={handleSearchChange}
          />
        </Box>
        <Box sx={toggleButtonContainer}>
          {coursesLibraryType === LibraryTypesEnum.CompanyAndBuildwitt && (
            <ButtonGroup style={{ width: '100%' }}>
              {coursesSources.map(source => (
                <BasicToggleButton
                  key={`select-company-${source.value}`}
                  id={`select-company-${source.value}`}
                  type="radio"
                  name="coursesSource"
                  value={source.value}
                  checked={CoursesSource === source.value}
                  color={
                    CoursesSource === source.value ? 'primary' : 'secondary'
                  }
                  onChange={e =>
                    handleChangeSelectButton(e.currentTarget.value)
                  }
                >
                  {source.text as string}
                </BasicToggleButton>
              ))}
            </ButtonGroup>
          )}
        </Box>
      </Stack>
      <Stack
        className="card-container flex-fill"
        sx={coursesContainerStyle}
        id="coursesContainer"
      >
        <Box sx={coursesSubContainerStyle}>
          {coursesToShow.map(course => (
            <Box sx={coursesItemContainerStyle} key={course.id}>
              <CourseCard
                course={course}
                width="100%"
                height="280px"
                showEdit={false}
              >
                <div style={containerSelectButtonStyle}>
                  <SelectEntityButton
                    entity={course}
                    isSelected={selectedCoursesIds.includes(course.id)}
                    onChangeSelection={onChangeCourseSelection}
                  />
                </div>
              </CourseCard>
            </Box>
          ))}
          {coursesToShow.length === 0 && (
            <Box sx={noCoursesAvailableContainerStyle}>
              {t('noCoursesAvailable', { ns: 'courses' })}{' '}
              <>
                {CoursesSource === Buildwitt_Value
                  ? BuildWittName
                  : companyName}
              </>
            </Box>
          )}
        </Box>
      </Stack>
    </>
  );
};

const headerContainerStyle: SxProps = {
  flexDirection: { xs: 'column-reverse', md: 'row' },
  justifyContent: { xs: 'center', md: 'space-between' },
  alignItems: 'center'
};

const coursesContainerStyle: SxProps = {
  flexDirection: 'row',
  marginTop: '2rem',
  minHeight: '200px',
  height: '300px',
  overflowY: 'auto'
};

const coursesSubContainerStyle: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  padding: 0,
  paddingRight: '0.5rem',
  justifyContent: 'space-between'
};

const coursesItemContainerStyle: SxProps = {
  paddingBottom: '1rem',
  width: { xs: '100%', md: '49%' }
};

const toggleButtonContainer: SxProps = {
  width: { md: '350px' },
  textAlign: 'center'
};

const noCoursesAvailableContainerStyle: SxProps = {
  marginBottom: '2rem',
  padding: 0,
  width: '100%'
};

export default SelectCourses;

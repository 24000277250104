import React, { CSSProperties, FC } from 'react';
import LeftMenu, { ILeftMenuLink } from '../LeftMenu/LeftMenu';
import TopMenu from '../TopMenu/TopMenu';
import AppLoader from '../core/AppLoader/AppLoader';
import FreeTrialNav from './FreeTrialNav';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import moment from 'moment';
import FirstAdminLoginVideoModal from '../FirstAdminLoginVideoModal/FirstAdminLoginVideoModal';
import config from '../../config';
import { getCookie, removeCookie, CookieNames } from '../../utils/cookieUtils';
import { useNavigate } from 'react-router';
import { Footer } from '../Footer/Footer';
import { Stack, useMediaQuery } from '@mui/material';
import useRoutes from '../../hooks/useRoutes';

export interface LayoutProps {
  children?: React.ReactNode;
}

const Layout: FC<React.PropsWithChildren<LayoutProps>> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(true);
  const [routes] = useRoutes();
  const user = useCurrentUser();
  const isFirstTimeLogin = user?.profile[
    'BuildWitt.Company.Employee.IsFirstTrainingLogin'
  ] as string;
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isFreeTrial = user?.profile[UserClaims.IsFreeTrial];
  const freeTrialExpirationDate =
    user?.profile[UserClaims.FreeTrialExpirationDate];
  const freeTrialMaxUsers = user?.profile[UserClaims.FreeTrialMaxUsers];

  const expirationDate = moment(freeTrialExpirationDate as Date).format(
    'YYYY-MM-DD'
  );
  const currentDate = moment().startOf('day').format('YYYY-MM-DD');
  const daysUntil = moment
    .duration(moment(expirationDate).diff(currentDate))
    .asDays();
  const navigate = useNavigate();

  const isScreenSmall = useMediaQuery('(max-width:640px)');
  const paddingSmall = isScreenSmall ? '6px' : '1.5rem';
  const pageContentStyle: CSSProperties = {
    paddingTop: isFreeTrial ? '50px' : '0px',
    overflow: 'hidden',
    paddingBottom: '10px',
    paddingLeft: paddingSmall,
    paddingRight: paddingSmall
  };

  // handle cookie saved for initial page selected
  React.useEffect(() => {
    try {
      const selected = getCookie(CookieNames.selectedPage);
      removeCookie(CookieNames.selectedPage);
      if (selected && selected !== 'dashboard') {
        navigate(`/${selected}`);
      }
    } catch (e) {
      console.log('error loading selected page cookie' + e);
    }
  }, []);

  return (
    <>
      {isFreeTrial && (
        <FreeTrialNav
          daysRemaining={Math.ceil(daysUntil)}
          employees={freeTrialMaxUsers as number}
        />
      )}
      <LeftMenu
        routes={routes as ILeftMenuLink[]}
        mobileOpen={mobileOpen}
        setMobileOpen={handleDrawerToggle}
        isFreeTrial={isFreeTrial as boolean}
      />
      <TopMenu
        isMobile={!mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isFreeTrial={isFreeTrial as boolean}
      />

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content" style={contentStyle}>
          <Stack direction="column" justifyContent={'space-between'}>
            <div
              className="container-fluid page-content"
              style={pageContentStyle}
            >
              <AppLoader variant="linear" />
              <div style={{ minHeight: '100vh' }}>{children}</div>
            </div>
            <Footer />
          </Stack>
        </div>
      </div>

      {JSON.parse(isFirstTimeLogin.toLowerCase()) &&
        isFreeTrial &&
        (userRoles.includes('CompanyAdmin') ||
          userRoles.includes('isCompanyAdmin')) &&
        config.WELCOME_VIDEO_URL && (
          <FirstAdminLoginVideoModal
            showModal={showModal}
            video={config.WELCOME_VIDEO_URL}
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}
    </>
  );
};

const contentStyle = {
  backgroundColor: '#000',
  height: '100%'
};

export default Layout;

import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import {
  AssessmentResultWithQuestions,
  Question
} from '../../../entities/Assessment';
import { QuestionTypes } from '../../../core/constants';
import TrueFalseAnalyticsQuestion from '../Questions/TrueFalseAnalyticsQuestion';
import MultipleChoiceQuestionAnalytics from '../Questions/MultipleChoiceQuestionAnalytics';
import NumberQuestionAnalytics from '../Questions/NumberQuestionAnalytics';

export interface GradeOverViewModalSingleUserProps {
  showModal: boolean;
  employeeName: string;
  asessmentResult: AssessmentResultWithQuestions;
  onClose: () => void;
}

const CustomDialog = styled(Dialog)`
    & .MuiDialog-root {
        
    }
    & .MuiPaper-root {
        width: 745px
        height: auto;
        border: 1px solid #999;
        border-radius: 10px;
    }
    & .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
    & .MuiDialogContent-root {
        padding: 0 0;
    }`;

const GradeOverViewModalSingleUser = ({
  showModal,
  employeeName,
  asessmentResult,
  onClose
}: PropsWithChildren<GradeOverViewModalSingleUserProps>) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const [colorGrade, setColorGrade] = useState<string>(
    theme.palette.primary.main
  );
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };
  const subTitleStyle: CSSProperties = {
    fontSize: '20px',
    color: theme.palette.primary.main
  };

  useEffect(() => {
    if (Math.round(asessmentResult.grade) <= 59) {
      setColorGrade(theme.palette.error.light);
    } else if (
      Math.round(asessmentResult.grade) >= 60 &&
      Math.round(asessmentResult.grade) <= 79
    ) {
      setColorGrade(theme.palette.primary.main);
    } else {
      setColorGrade(theme.palette.success.main);
    }
  }, []);

  return (
    <CustomDialog open={showModal} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Grid container>
          <Grid item style={{ width: '545px', paddingBottom: '15px' }}>
            <Stack
              direction={'column'}
              style={{ paddingLeft: '27px', paddingTop: '15px' }}
            >
              <Typography style={{ fontSize: '24px', height: '30px' }}>
                {`${t('gradeOverview', { ns: 'analytics' })}`}
              </Typography>
              <span style={subTitleStyle}>{employeeName}</span>
            </Stack>
          </Grid>
          <Grid item style={gridSeparatorTitle}></Grid>
          <Grid item style={{ width: '200px' }}>
            <Stack direction={'column'}>
              <Typography style={{ ...gradeLabelStyle, color: colorGrade }}>
                {`${Math.round(asessmentResult.grade)}%`}
              </Typography>
              <Typography style={gradeTextLabelStyle}>
                {`${t('quizGrade', { ns: 'analytics' })}`}
              </Typography>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ ...iconStyle }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <hr style={horSeparator} />
      <DialogContent>
        {asessmentResult.questions?.map((question: Question) => {
          const answer = asessmentResult.answers.filter(
            item => item.questionId === question.id
          )[0];
          switch (question.type) {
            case QuestionTypes.TrueFalse:
              return (
                <TrueFalseAnalyticsQuestion
                  answer={answer}
                  question={question}
                />
              );
            case QuestionTypes.MultipleChoiceText:
              return (
                <MultipleChoiceQuestionAnalytics
                  answer={answer}
                  question={question}
                />
              );
            case QuestionTypes.Number:
              return (
                <NumberQuestionAnalytics answer={answer} question={question} />
              );
            default:
              return <></>;
          }
        })}
      </DialogContent>
    </CustomDialog>
  );
};
const gradeLabelStyle: CSSProperties = {
  fontSize: '24px',
  height: '30px',
  paddingLeft: '13px',
  paddingTop: '28px'
};
const gradeTextLabelStyle: CSSProperties = {
  fontSize: '13px',
  height: '16px',
  paddingLeft: '13px',
  paddingTop: '28px',
  color: '#C4C4C4'
};
const horSeparator: CSSProperties = {
  height: '1px',
  color: '#999999',
  margin: '0px'
};
const gridSeparatorTitle: CSSProperties = {
  width: '2px',
  borderRight: '1px solid #363636'
};

export default GradeOverViewModalSingleUser;

import { SelectedContent } from './types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

interface WithSelectableProps {
  onSelect: (item: SelectedContent) => void;
  selectable: SelectedContent;
  selected: boolean;
}

export const withSelectable =
  <T extends { children?: React.ReactNode }>(
    Component: React.ComponentType<
      Omit<T & WithSelectableProps, 'onSelect' | 'selectable' | 'selected'>
    >
  ) =>
  ({ onSelect, selectable, selected, ...props }: WithSelectableProps & T) => {
    const { t } = useTranslation('common');
    return (
      <Component {...props}>
        <Button
          onClick={() => {
            onSelect(selectable);
          }}
          color={selected ? 'secondary' : 'primary'}
        >
          {selected ? t('deselect') : t('select')}
        </Button>
      </Component>
    );
  };

import { CSSProperties } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { useTheme, SxProps } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

import { AnalyticsGroupEngagement } from '../../../entities/Analytics';
import { getFormattedTime } from '../../../utils/commonUtils';
import { AnalyticsDataGrid } from '../../../components/Analytics';

interface GroupEngagementTableProps {
  data?: AnalyticsGroupEngagement[] | null;
  onClickActionButton: (
    action: string,
    groupId: string,
    groupName: string
  ) => void;
  onGotoDetailView: (groupId: string, groupName: string) => void;
}

const GroupEngagementTable = ({
  data,
  onClickActionButton,
  onGotoDetailView
}: GroupEngagementTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);

  const buttonStyle: SxProps = {
    height: '30px',
    borderRadius: '35px',
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.grey[50],
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 15
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      color: theme.palette.grey[100]
    }
  };

  const ZeroValueFontStyle: CSSProperties = {
    color: theme.palette.error.main
  };

  const columns: GridColDef[] = [
    {
      field: 'employeeGroupName',
      headerName: t('groupName', { ns: 'analytics' }),
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span>{params.row.employeeGroupName}</span>
      )
    },
    {
      field: 'currentAssignments',
      headerName: t('currentAssignments', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <div style={cellButtonsStyle}>
          <span
            style={params.row.currentAssignments > 0 ? {} : ZeroValueFontStyle}
          >
            {params.row.currentAssignments}
          </span>
          <IconButton
            aria-label="currentAssignments"
            size="large"
            color="info"
            disabled={!params.row.currentAssignments}
            sx={buttonStyle}
            onClick={() =>
              onClickActionButton(
                'groupCurrentAssignments',
                params.row.employeeGroupId,
                params.row.employeeGroupName
              )
            }
          >
            {t('view', { ns: 'analytics' })}
          </IconButton>
        </div>
      )
    },
    {
      field: 'totalCompletedAssignments',
      headerName: t('totalCompletedAssignments', { ns: 'analytics' }),
      minWidth: 250,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <div style={{ ...cellButtonsStyle, minWidth: 230 }}>
          <span
            style={
              params.row.totalCompletedAssignments > 0 ? {} : ZeroValueFontStyle
            }
          >
            {params.row.totalCompletedAssignments}
          </span>
          <IconButton
            aria-label="totalCompletedAssignments"
            size="large"
            color="info"
            disabled={!params.row.totalCompletedAssignments}
            sx={buttonStyle}
            onClick={() =>
              onClickActionButton(
                'groupCompletedAssignments',
                params.row.employeeGroupId,
                params.row.employeeGroupName
              )
            }
          >
            {t('view', { ns: 'analytics' })}
          </IconButton>
        </div>
      )
    },
    {
      field: 'timeSpentInTraining',
      headerName: t('timeSpentInTraining', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span
          style={params.row.timeSpentInTraining > 0 ? {} : ZeroValueFontStyle}
        >
          {params.row.timeSpentInTraining > 0
            ? getFormattedTime(params.row.timeSpentInTraining)
            : params.row.timeSpentInTraining}
        </span>
      )
    },
    {
      field: 'employeesInGroup',
      headerName: t('EmployeesInGroup', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={cellButtonsStyle}>
          <span
            style={params.row.employeesInGroup > 0 ? {} : ZeroValueFontStyle}
          >
            {params.row.employeesInGroup}
          </span>
          <IconButton
            aria-label="buttons"
            size="large"
            color="info"
            style={circularButton}
            onClick={() =>
              onGotoDetailView(
                params.row.employeeGroupId,
                params.row.employeeGroupName
              )
            }
          >
            <ArrowForwardIosIcon fontSize="medium" sx={{ color: '#1B1C1C' }} />
          </IconButton>
        </div>
      )
    }
  ];

  return (
    <AnalyticsDataGrid columns={columns} rows={data} rowId="employeeGroupId" />
  );
};

const cellButtonsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 180
};

const circularButton: CSSProperties = {
  backgroundColor: '#C4C4C4',
  width: '35px',
  height: '35px'
};

export default GroupEngagementTable;

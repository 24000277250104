import { CSSProperties, MutableRefObject } from 'react';
import { Box, useTheme, Grid as MuiGrid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import HorizontalLine from '../../../components/core/HorizontalLine/HorizontalLine';
import SortContentSelect from '../../contentLibrary/common/SortContentSelect';
import { useBwBreakpoints } from '../../../hooks/useBwBreakpoints';
import SearchInput from '../../contentLibrary/common/SearchInput';
import LoadingContentIndicator from '../../contentLibrary/common/LoadingContentIndicator';
import NoOnsiteTrainingsProps from '../common/NoOnsiteTrainings';
import ScheduledOnsiteTrainingList from './ScheduledOnsiteTrainingList';
import { PagedResult } from '../../../entities/Common';
import { OnsiteTrainingClass } from '../../../entities/OnsiteTraining';
import NoRecordsFound from '../common/NoRecordsFound';

interface ScheduledOnsiteTrainingTabProps {
  goToLibraryTab: () => void;
  onSearch: (value: string) => void;
  onSort: (value: string[]) => void;
  searchKey: string;
  sortKey: string;
  page: MutableRefObject<number>;
  onsiteTrainingClasses?: PagedResult<OnsiteTrainingClass> | null;
}

const ScheduledOnsiteTrainingTab = ({
  goToLibraryTab,
  onSearch,
  onSort,
  searchKey,
  sortKey,
  page,
  onsiteTrainingClasses
}: ScheduledOnsiteTrainingTabProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining']);
  const [is640] = useBwBreakpoints();

  return (
    <>
      <Box sx={{ flexGrow: 1, marginBottom: '34px' }}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <MuiGrid container spacing={1}>
          <MuiGrid item xs={'auto'} flexShrink={1} sx={{ minWidth: '230px' }}>
            <SortContentSelect
              style={is640 ? selectStyleResponsive : selectStyle}
              value={sortKey}
              onChange={onSort}
            />
          </MuiGrid>
          <MuiGrid
            container
            item
            xs={'auto'}
            justifyContent={'end'}
            marginLeft={'auto'}
          >
            <SearchInput onSearch={onSearch} defaultValue={searchKey} />
          </MuiGrid>
        </MuiGrid>
      </Box>
      {!onsiteTrainingClasses && <LoadingContentIndicator />}
      {!!onsiteTrainingClasses &&
        !onsiteTrainingClasses.totalItems &&
        !searchKey.length && (
          <NoOnsiteTrainingsProps
            message={t('thereAreNoOnsiteTrainingsScheduled', {
              ns: 'onsiteTraining'
            })}
            captionButton={t('viewOnsiteTrainingLibrary', {
              ns: 'onsiteTraining'
            })}
            onClick={goToLibraryTab}
          />
        )}
      {!!onsiteTrainingClasses &&
        !onsiteTrainingClasses.totalItems &&
        !!searchKey.length && (
          <NoRecordsFound
            primaryMessage={t('noScheduledOnsiteTrainings', {
              ns: 'onsiteTraining'
            })}
            secondayMessage={t('matchingWithYourCriteria', {
              ns: 'onsiteTraining'
            })}
          />
        )}
      {!!onsiteTrainingClasses && !!onsiteTrainingClasses.totalItems && (
        <ScheduledOnsiteTrainingList
          currentSearch={searchKey}
          currentSort={sortKey}
          onsiteTrainingClasses={onsiteTrainingClasses}
          page={page}
        />
      )}
    </>
  );
};

export const selectStyleResponsive: CSSProperties = {
  height: '45px',
  width: '90px'
};

export const selectStyle: CSSProperties = {
  height: '45px'
};

export default ScheduledOnsiteTrainingTab;

import { CSSProperties } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { IconButton, useTheme } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

import { AnalyticsQuiz } from '../../../entities/Analytics';
import { AnalyticsDataGrid } from '../../../components/Analytics';
import {
  getStandardColumn,
  getGenericValueActionColumn
} from '../common/utilAnalyticsColumnDefinition';
import { GreenSpan, OrangeSpan, RedSpan, GreySpan } from '../common/StyledSpan';

interface QuizzesTableProps {
  data?: AnalyticsQuiz[] | null;
  onClickActionButton: (action: string, quizLessonId: string) => void;
  onGotoDetailView: (
    quizId: string,
    quizLessonId: string,
    quizName: string
  ) => void;
}

const standardValue =
  (fieldName: string) => (params: GridRenderCellParams<any>) => {
    let Container = GreenSpan;
    const { row } = params;

    if (!row[fieldName]) {
      Container = GreySpan;
    } else if (fieldName === 'pendingCompletion') {
      Container = OrangeSpan;
    }
    return <Container>{row[fieldName]}</Container>;
  };

const AvergageGrade = ({ params }: { params: GridRenderCellParams<any> }) => {
  const averageGrade = params.row.averageGrade.toFixed(0) as number;
  let Container;

  if (averageGrade <= 55) {
    Container = RedSpan;
  } else if (averageGrade <= 70) {
    Container = OrangeSpan;
  } else {
    Container = GreenSpan;
  }

  return <Container>{`${averageGrade}%`}</Container>;
};

const QuizzesTable = ({
  data,
  onClickActionButton,
  onGotoDetailView
}: QuizzesTableProps) => {
  const { t } = useTranslation(['analytics']);
  const theme = useTheme();

  const handleClickAction =
    (action: string) => (params: GridRenderCellParams<any>) => {
      onClickActionButton(action, params.row.quizLessonId);
    };

  const columns: GridColDef[] = [
    getStandardColumn('quizName', t('quizName', { ns: 'analytics' }), 300, 1),
    getStandardColumn(
      'employeesAssigned',
      t('employeesAssigned', { ns: 'analytics' }),
      200
    ),
    getGenericValueActionColumn(
      'pendingCompletion',
      t('pendingCompletion', { ns: 'analytics' }),
      t('manage', { ns: 'analytics' }),
      200,
      handleClickAction('pendingCompletionQuiz'),
      standardValue('pendingCompletion')
    ),
    getGenericValueActionColumn(
      'completed',
      t('completed', { ns: 'analytics' }),
      t('view', { ns: 'analytics' }),
      150,
      handleClickAction('completedQuiz'),
      standardValue('completed')
    ),
    {
      field: 'averageGrade',
      headerName: t('averageGrade', { ns: 'analytics' }),
      minWidth: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={cellButtonsStyle}>
          <AvergageGrade params={params} />
          <div>
            <span
              style={{ paddingRight: '1rem', color: theme.palette.grey[100] }}
            >
              {`${params.row.numberOfQuestions} ${t('questions', { ns: 'analytics' })}`}
            </span>
            <IconButton
              aria-label="buttons"
              size="large"
              color="info"
              style={circularButton}
              onClick={() =>
                onGotoDetailView(
                  params.row.quizId,
                  params.row.quizLessonId,
                  params.row.quizName
                )
              }
            >
              <ArrowForwardIosIcon
                fontSize="medium"
                sx={{ color: '#1B1C1C' }}
              />
            </IconButton>
          </div>
        </div>
      )
    }
  ];

  return (
    <AnalyticsDataGrid columns={columns} rows={data} rowId="quizLessonId" />
  );
};

const cellButtonsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 280,
  width: '100%'
};

const circularButton: CSSProperties = {
  backgroundColor: '#C4C4C4',
  width: '35px',
  height: '35px'
};

export default QuizzesTable;

import React, { useState, useEffect, CSSProperties } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';

import { AiccCourse } from '../../entities/Course';
import AssignEmployeesPanel, {
  AssignEmployeesPanelProps
} from '../../components/AssignEmployeesPanel/AssignEmployeesPanel';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import { dropDownItem } from '../../entities/Common';
import { SubscriptionData } from '../../entities/Subscription';
import config from './../../config';
import useCurrentUser from '../../hooks/useCurrentUser';
import { AwaitableDialog, DialogHeader } from '@buildwitt/component-library';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../core/constants';

export interface AssignClickSafetyModalProps extends AssignEmployeesPanelProps {
  showModal: boolean;
  assignToEntity?: AiccCourse | null;
  errorMessage: string;
  savingInProgress: boolean;
  subscriptionData: SubscriptionData[];
  onAssign: () => void;
  onClose: () => void;
  setSelectSubscription: (id: string) => void;
  isLoading: boolean;
}

const CustomDialog = styled(AwaitableDialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const AssignClickSafetyModal: React.FC<AssignClickSafetyModalProps> = ({
  showModal,
  assignToEntity,
  errorMessage,
  employees,
  employeeGroups,
  assignment,
  savingInProgress,
  subscriptionData,
  learningUnitAssigmentStatus,
  setAssignment,
  onAssign,
  onClose,
  setSelectSubscription,
  isLoading
}) => {
  const user = useCurrentUser();
  const theme = useTheme();
  const [title, setTitle] = useState<string>('');
  const [subscriptionOptions, setSubscriptionOptions] = useState<
    dropDownItem[]
  >([]);
  const [subscriptionsRemaining, setSubscriptionsRemaining] = useState<
    number | null
  >(null);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
  const { t } = useTranslation(['common', 'assignments']);

  useEffect(() => {
    let nameOrTitle = '';
    if (assignToEntity) {
      const entity: any = assignToEntity;
      if (entity.title) nameOrTitle = entity.title;
      else if (entity.name) nameOrTitle = entity.name;

      setTitle(`${t('assign', { ns: 'common' })} ${nameOrTitle}`);
    }
  }, [assignToEntity]);

  useEffect(() => {
    if (subscriptionData.length) {
      const options = subscriptionData.map(subscription => {
        return {
          label: subscription.label,
          value: subscription.id
        };
      });
      setSubscriptionOptions(options);
    }
  }, [subscriptionData]);

  const handleChangeSubscription = (items: string[]) => {
    const value = items[0];
    const subscription = subscriptionData.find(
      subscription => subscription.id === value
    );
    if (subscription) {
      setSubscriptionsRemaining(subscription.subscriptionsRemaining);
    }
    setSelectSubscription(value);
  };

  const openPurchaseSubscriptionsPage = () => {
    window.open(
      `${config.AUTHORITY}/Subscriptions/${companyId}?defaultTab=clicksafety`
    );
    onClose();
  };

  const titleStyle: CSSProperties = {
    color: theme.palette.grey[100],
    fontSize: '15px'
  };

  const mandatoryMarkStyle: CSSProperties = {
    color: 'red'
  };

  const remainingNumberStyle: CSSProperties = {
    color: theme.palette.primary.main
  };

  const remainingTextStyle: CSSProperties = {
    color: theme.palette.grey[100]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
      isLoading={isLoading}
    >
      <DialogHeader onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            paddingTop: '0'
          }
        }}
      >
        {!!errorMessage.length && (
          <p
            className="text-danger"
            dangerouslySetInnerHTML={{ __html: errorMessage }}
          />
        )}
        <Box>
          <Grid container direction="column">
            <Grid item>
              <FormLabel>
                {t('chooseSubscription', { ns: 'assignments' })}{' '}
                <span className="text-danger">*</span>
              </FormLabel>
            </Grid>
            <Grid
              container
              spacing={1}
              direction={{ xs: 'column', md: 'row' }}
              paddingBottom="0.5rem"
            >
              <Grid item xs={1} md={8}>
                <BasicSelect
                  labelId="subscriptions"
                  id="subscriptions"
                  options={subscriptionOptions}
                  defaultValue=""
                  style={dropDownStyle}
                  placeholder={t('chooseSubscription', { ns: 'assignments' })}
                  handleOnChange={handleChangeSubscription}
                  theme={'dark'}
                  multiple={false}
                />
              </Grid>
              <Grid item xs={1} md={4}>
                <ProtectedComponent
                  action={appPermissions.PURCHASE_CLICKSAFETY_SUBSCRIPTIONS}
                >
                  <BasicButton
                    color="grayButton"
                    width="100%"
                    height="45px"
                    onClick={openPurchaseSubscriptionsPage}
                  >
                    {t('purchaseSubscriptions', { ns: 'assignments' })}
                  </BasicButton>
                </ProtectedComponent>
              </Grid>
            </Grid>
            <Grid item sx={remainingContainerStyle}>
              {subscriptionsRemaining !== null && (
                <>
                  <span style={remainingNumberStyle}>
                    {subscriptionsRemaining}
                  </span>
                  <span style={remainingTextStyle}>
                    {` ${t('subscriptionRemaining', { ns: 'assignments' })}`}
                  </span>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
        <div className="pb-2">
          <span style={titleStyle}>
            {t('selectEmployeesToAssign', { ns: 'assignments' })}{' '}
            <span style={mandatoryMarkStyle}>*</span>
          </span>
        </div>
        <AssignEmployeesPanel
          employees={employees ?? []}
          employeeGroups={employeeGroups ?? []}
          assignment={assignment}
          setAssignment={setAssignment}
          layoutType="clickSafety"
          learningUnitAssigmentStatus={learningUnitAssigmentStatus}
        />
      </DialogContent>
      <DialogActions>
        <BasicButton
          color="primary"
          width="160px"
          height="45px"
          loading={savingInProgress}
          disabled={savingInProgress}
          onClick={onAssign}
        >
          {t('assignCourse', { ns: 'assignments' })}
        </BasicButton>
      </DialogActions>
    </CustomDialog>
  );
};

const dropDownStyle: CSSProperties = {
  width: '100%',
  height: '45px'
};

const remainingContainerStyle: SxProps = {
  fontSize: '18px',
  paddingBottom: '1rem'
};

export default AssignClickSafetyModal;

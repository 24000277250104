import { Course } from '../entities/Course';
import { LearningPlan } from '../entities/LearningPlan';
import { Lesson } from '../entities/Lesson';
import moment from 'moment';

export const restrictedLessonShouldShow = (
  lesson: Lesson,
  userRoles: string[]
) => {
  return isUserAdmin(userRoles) || !lesson.visibleForAdminsOnly;
};
const isUserAdmin = (userRoles: string[]) => {
  return (
    userRoles.includes('CompanyAdmin') || userRoles.includes('isCompanyAdmin')
  );
};

export const restrictedCourseShouldShow = (
  course: Course,
  userRoles: string[]
) => {
  return isUserAdmin(userRoles) || !course.visibleForAdminsOnly;
};

export const restrictedLpShouldShow = (
  lplan: LearningPlan,
  userRoles: string[]
) => {
  return isUserAdmin(userRoles) || !lplan.visibleForAdminsOnly;
};

export const convertDurationInSecondsToHHmm = (seconds: string) => {
  if (!seconds) return '00:00';
  const hhmmss = moment.utc(parseInt(seconds) * 1000).format('HH:mm:ss');
  return hhmmss.toString();
};

export const convertHHmmToSecondsInt = (timeStr: string) => {
  const duration = moment.duration(timeStr);
  return duration.asSeconds().toString();
};

export const getEmptyLesson = () => ({
  id: '',
  title: '',
  description: '',
  order: 0,
  companyId: '',
  courseId: '',
  contentMedium: 0,
  videoUrl: '',
  videoId: '',
  audioUrl: '',
  thumbnailUrl: '',
  learningMaterialUrl: '',
  externalVideoUrl: '',
  lessonType: '',
  tags: [],
  subtitles: [],
  isVideoEncoding: false,
  isEncodingFailed: false,
  lessonContentType: 0,
  attachments: [],
  audioDuration: '',
  audioId: '',
  visibleForAdminsOnly: false,
  lessonDuration: '0',
  lessonCode: '',
  hlsUrl: '',
  dashUrl: ''
});

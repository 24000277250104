import { CSSProperties, useEffect, useState, useContext } from 'react';
import {
  Box,
  FormControl,
  Grid,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import themeConfig from '../../themes/theme';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { useAnalyticsQuizzes } from '../../hooks/useAnalyticsQuizzes';
import { AnalyticsQuizzesViewType } from '../../core/enums';
import { actionCreators as analyticsActions } from '../../actions/analytics';
import QuizzesTable from './quizzes/QuizzesTable';
import { AnalyticsQuiz } from '../../entities/Analytics';
import { AnalyticsFilterContext } from '../../contexts/AnalyticsContext';
import QuizzesDetail from './quizzes/QuizzesDetail';
import ModalFactory from './contentLibrary/ModalFactory';
import { AnalyticsCommonFilterContext } from '../../contexts/AnalyticsContext';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import SearchControlPanel from './SearchControlPanel';
import { useAppDispatch } from '../../store/hooks';

type SelectedQuiz = {
  quizLessonId: string;
  quizId: string;
  quizName: string;
};

type ModalInfo = {
  modalType: string;
  quizLessonId: string;
};

const Quizzes = () => {
  const theme = createTheme(themeConfig);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['analytics']);
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const [selectedQuiz, setSelectedQuiz] = useState<SelectedQuiz>();
  const [modalInfo, setModalInfo] = useState<ModalInfo>();
  const {
    state: {
      sortOptions,
      employeeFilterOptions,
      sortOption,
      employeeFilterOption,
      searchFilter,
      includeSelfAssignments,
      downloadingReport,
      data
    },
    api: {
      handleChangeFilterOption,
      handleChangeSortOption,
      handleSearchData,
      setIncludeSelfAssignments,
      handleDownloadCsvReport
    }
  } = useAnalyticsQuizzes(AnalyticsQuizzesViewType.Overview);

  const contextValue = {
    includeSelfAssignments: includeSelfAssignments,
    activeOnly: employeeFilterOption === 'active',
    subscribedOnly: employeeFilterOption === 'subscribed'
  };

  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();

  useEffect(() => {
    const activeOnly = employeeFilterOption === 'active';
    const subscribedOnly = employeeFilterOption === 'subscribed';
    dispatch(
      analyticsActions.getQuizzesData(
        includeSelfAssignments,
        activeOnly,
        subscribedOnly,
        groupId,
        supervisorId
      )
    );

    return () => {
      dispatch(analyticsActions.setQuizzesData(null));
    };
  }, [includeSelfAssignments, employeeFilterOption, groupId, supervisorId]);

  const handleClickActionButton = (action: string, quizLessonId: string) => {
    setModalInfo({
      modalType: action,
      quizLessonId
    });
  };

  const handleGotoDetailView = (
    quizId: string,
    quizLessonId: string,
    quizName: string
  ) => {
    setSelectedQuiz({
      quizLessonId,
      quizId,
      quizName
    });
  };

  const handleBackToQuizzes = () => {
    setSelectedQuiz(undefined);
  };

  if (selectedQuiz) {
    const { quizId, quizLessonId, quizName } = selectedQuiz;

    return (
      <ThemeProvider theme={theme}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <AnalyticsFilterContext.Provider value={contextValue}>
          <QuizzesDetail
            quizId={quizId}
            quizLessonId={quizLessonId}
            quizName={quizName}
            onBackToQuizzes={handleBackToQuizzes}
          />
        </AnalyticsFilterContext.Provider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item flex={1}>
            {!employeeActiveUpdates.employeeActiveUpdates && (
              <FormControl
                sx={{ mr: 2, pb: 1 }}
                variant="standard"
                data-testid="filterOptionsTestId"
                data-trackid="filterOptionsTrackId"
              >
                <BasicSelect
                  labelId="filterOptions"
                  id="filterOptions"
                  options={employeeFilterOptions}
                  value={
                    employeeFilterOption.length ? [employeeFilterOption] : []
                  }
                  placeholder={t('filter', { ns: 'analytics' })}
                  handleOnChange={handleChangeFilterOption}
                  style={selectStyle}
                  theme="dark"
                  multiple={false}
                  disabled={!data}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
            )}
            <FormControl sx={{ mr: 2, pb: 1 }} variant="standard">
              <BasicSelect
                labelId="sortOptions"
                id="sortOptions"
                options={sortOptions}
                defaultValue=""
                value={[sortOption]}
                handleOnChange={handleChangeSortOption}
                style={{ ...selectStyle, width: '230px' }}
                theme="dark"
                multiple={false}
                disabled={!data}
                sx={{ backgroundColor: 'transparent' }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <SearchControlPanel
              employeeActiveUpdatesFlag={
                employeeActiveUpdates.employeeActiveUpdates
              }
              data={data}
              downloadingReport={downloadingReport}
              handleSearchData={handleSearchData}
              searchText={searchFilter}
              handleDownloadCsvReport={handleDownloadCsvReport}
              includeSelfAssignments={includeSelfAssignments}
              setIncludeSelfAssignments={setIncludeSelfAssignments}
              filterOption={employeeFilterOption}
              filterOptions={employeeFilterOptions}
              handleChangeFilterOption={handleChangeFilterOption}
            />
          </Grid>
        </Grid>
        <br />
        <QuizzesTable
          data={data ? (data as AnalyticsQuiz[]) : data}
          onClickActionButton={handleClickActionButton}
          onGotoDetailView={handleGotoDetailView}
        />
      </Box>
      <ModalFactory
        modalType={modalInfo?.modalType ?? ''}
        learningUnitId={modalInfo?.quizLessonId}
        learningUnitType={'Lesson'}
        includeSelfAssignments={includeSelfAssignments}
        onCloseModal={() => setModalInfo(undefined)}
        filterOption={sortOption}
      />
    </ThemeProvider>
  );
};

const selectStyle: CSSProperties = {
  width: '210px',
  height: '45px'
};

export default Quizzes;

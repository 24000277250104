import React, { CSSProperties } from 'react';
import { Answer, Question } from '../../../entities/Assessment';
import { Grid, Typography, useTheme } from '@mui/material';
import redCross from '../../../assets/red-cross.png';
import greenCheck from '../../../assets/green-check.png';
import { useTranslation } from 'react-i18next';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';

export interface TrueFalseAnalyticsQuestionProps {
  answer: Answer;
  question: Question;
}

const TrueFalseAnalyticsQuestion: React.FC<TrueFalseAnalyticsQuestionProps> = ({
  answer,
  question
}) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;
  const falseLabel = `${t('false', { ns: 'assessments' })}`;
  const trueLabel = `${t('true', { ns: 'assessments' })}`;
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const options = [true, false];
  const backgroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  const renderCorrectResponseIcon = (option: boolean) => {
    const isCorrectAnswer = question.trueIsCorrectAnswer === option;
    const isCorrectChoice = option === answer.answerChoice;

    if (isCorrectAnswer && isCorrectChoice) {
      return <img src={greenCheck} alt="status" style={iconStyle} />;
    }

    if (!isCorrectAnswer && isCorrectChoice) {
      return <img src={redCross} alt="status" style={iconStyle} />;
    }

    return <div style={iconStyle}></div>;
  };

  const getBorderStyle = (option: boolean) => {
    const isCorrectAnswer = question.trueIsCorrectAnswer === option;
    const isCorrectChoice = option === answer.answerChoice;

    if (isCorrectAnswer && isCorrectChoice) {
      return `solid 1px ${greenColor}`;
    }

    if (!isCorrectAnswer && isCorrectChoice) {
      return `solid 1px ${redColor}`;
    }

    return '0px';
  };

  const correctResponseWithIconComp = () => (
    <Grid>
      {options.map(option => (
        <Grid container item direction={'row'} style={gridCorrectReponseIcon}>
          <Typography style={correctResponseLabelStyle}>
            {question.trueIsCorrectAnswer === option ? correctReponseLabel : ''}
          </Typography>
          {renderCorrectResponseIcon(option)}
        </Grid>
      ))}
    </Grid>
  );

  const trueFalseComponent = () => (
    <Grid>
      {options.map(option => (
        <Grid item style={gridTrueFalse}>
          <Grid
            display={'flex'}
            alignItems={'center'}
            style={{
              ...borderTrueFalseStyle,
              border: getBorderStyle(option)
            }}
          >
            <Typography> &emsp;{option ? trueLabel : falseLabel}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid style={{ backgroundColor: backgroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
      />
      <Grid container style={{ paddingTop: '12px' }}>
        <Grid
          container
          item
          direction={'column'}
          style={gridContainerTrueFalse}
        >
          {correctResponseWithIconComp()}
        </Grid>
        <Grid item style={gridSeparatorTitle}></Grid>
        <Grid item direction={'column'} style={{ paddingTop: '10px' }}>
          {trueFalseComponent()}
        </Grid>
      </Grid>
    </Grid>
  );
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const gridTrueFalse: CSSProperties = {
  width: '479px',
  paddingLeft: '15px'
};

const gridContainerTrueFalse: CSSProperties = {
  width: '230px',
  paddingTop: '25px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};

const gridCorrectReponseIcon: CSSProperties = {
  width: '190px',
  height: '30px',
  paddingBottom: '38px'
};

const gridSeparatorTitle: CSSProperties = {
  width: '1px',
  paddingLeft: '12px',
  height: '71px',
  marginTop: '10px',
  borderRight: '1px solid #363636'
};
const iconStyle: CSSProperties = {
  paddingLeft: '30px'
};
const borderTrueFalseStyle: CSSProperties = {
  height: '39px',
  borderRadius: '5px'
};
export default TrueFalseAnalyticsQuestion;

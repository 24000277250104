import React from 'react';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

import OnsiteTrainingCardBase from './OnsiteTrainingCardBase';
import BasicButton from '../core/BasicButton/BasicButton';
import {
  OnsiteTrainingClass,
  OnsiteTrainingClassDay
} from '../../entities/OnsiteTraining';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';

interface OnsiteTrainingCardScheduledProps {
  onsiteTrainingClass: OnsiteTrainingClass;
  onStart: (onsiteTrainingClass: OnsiteTrainingClass) => void;
  onAddEmployees: (onsiteTrainingClass: OnsiteTrainingClass) => void;
  onManage: (onsiteTrainingClass: OnsiteTrainingClass) => void;
}

interface ActionButtonsProps {
  onsiteTrainingClass: OnsiteTrainingClass;
  onStart: () => void;
  onAddEmployees: () => void;
}

const OnsiteTrainingCardScheduled = ({
  onsiteTrainingClass,
  onStart,
  onAddEmployees,
  onManage
}: OnsiteTrainingCardScheduledProps) => {
  const handleStart = () => onStart(onsiteTrainingClass);
  const handleAddEmployees = () => onAddEmployees(onsiteTrainingClass);
  const handleManage = () => onManage(onsiteTrainingClass);

  return (
    <OnsiteTrainingCardBase
      onsiteTraining={onsiteTrainingClass.onsiteTraining}
      contentOverThumbnail={<ContentOverThumbnail onManage={handleManage} />}
      cardActions={
        <ActionButtons
          onsiteTrainingClass={onsiteTrainingClass}
          onStart={handleStart}
          onAddEmployees={handleAddEmployees}
        />
      }
      extraContent={
        <OnsiteTrainingDates onsiteTrainingClass={onsiteTrainingClass} />
      }
      containerStyles={{ minHeight: 320 }}
    />
  );
};

const ContentOverThumbnail = ({ onManage }: { onManage: () => void }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box display="flex">
      <div style={actionButton}>
        <Typography fontSize={12}>{t('manage')}</Typography>
        <IconButton onClick={onManage}>
          <EditIcon style={editIconStyle} />
        </IconButton>
      </div>
    </Box>
  );
};

const ActionButtons = ({
  onsiteTrainingClass,
  onStart,
  onAddEmployees
}: ActionButtonsProps) => {
  const { t } = useTranslation(['common']);
  const localDateTime = moment();
  const formattedLocalDate = localDateTime.format('MM.DD.YYYY');
  const canStart = onsiteTrainingClass.classDays.some(
    day =>
      formattedLocalDate ===
        absUtcDateToLocalMoment(day.classDateTime).format('MM.DD.YYYY') &&
      localDateTime >= absUtcDateToLocalMoment(day.classDateTime) &&
      !day.isComplete
  );

  return (
    <Stack direction="row" flexWrap="wrap">
      <BasicButton
        onClick={onStart}
        color="primary"
        style={startButton}
        disable={!canStart}
      >
        {canStart ? t('start') : t('upcoming')}
      </BasicButton>
      {!onsiteTrainingClass.isFirstClassDayCompleted && (
        <BasicButton
          onClick={onAddEmployees}
          color="grayButton"
          style={addEmployeesButton}
        >
          {t('addEmployees')}
        </BasicButton>
      )}
    </Stack>
  );
};

const OnsiteTrainingDates = ({
  onsiteTrainingClass
}: {
  onsiteTrainingClass: OnsiteTrainingClass;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining']);
  const { id } = onsiteTrainingClass;
  const assignmentDates = onsiteTrainingClass.classDays.sort(
    (a: OnsiteTrainingClassDay, b: OnsiteTrainingClassDay) =>
      a.classDateTime > b.classDateTime ? 1 : -1
  );
  const currentDate = moment().format('MM.DD.YYYY');
  return (
    <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap' }}>
      <Typography
        sx={{
          fontSize: '13px',
          color: theme.palette.grey[50]
        }}
      >
        {t('onsiteTrainingDates')}:
      </Typography>
      {assignmentDates.map(
        (
          day: OnsiteTrainingClassDay,
          index: number,
          array: OnsiteTrainingClassDay[]
        ) => {
          const formattedDate = absUtcDateToLocalMoment(
            day.classDateTime
          ).format('MM.DD.YYYY');
          const color =
            formattedDate === currentDate && !day.isComplete
              ? theme.palette.primary.main
              : theme.palette.common.white;

          return (
            <React.Fragment key={`${id}-${day.toString()}-${index}`}>
              <Typography sx={{ fontSize: '13px', color: color }}>
                {absUtcDateToLocalMoment(day.classDateTime).format(
                  'MM.DD.YYYY'
                )}
              </Typography>
              {!!(index < array.length - 1) && <span>&#8226;</span>}
            </React.Fragment>
          );
        }
      )}
    </Stack>
  );
};

const startButton: CSSProperties = {
  height: '32px',
  minWidth: '100px',
  width: '100px',
  fontSize: '15px',
  fontWeight: 600,
  bottom: 0,
  marginLeft: '10px'
};

const addEmployeesButton: CSSProperties = {
  ...startButton,
  minWidth: '140px',
  width: '140px'
};

const actionButton: CSSProperties = {
  paddingLeft: '10px',
  background: '#1b1c1c',
  borderRadius: 22,
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center'
};

const editIconStyle: CSSProperties = {
  color: 'white',
  fontSize: 14
};

export default OnsiteTrainingCardScheduled;

import { CSSProperties } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BasicButton from '../core/BasicButton/BasicButton';
import { buttonStyle, fontDeleteButton } from '../../utils/buttonStyles';
import { LearningPlan } from '../../entities/LearningPlan';

export interface FooterActionButtonsProps {
  learningPlanData: LearningPlan;
  isEdit: boolean;
  saveInProgress: boolean;
  onSave: () => void;
  onPublish: () => void;
  onConfirmationModalOpen?: (open: boolean) => void;
}

const FooterActionButtons: React.FC<FooterActionButtonsProps> = ({
  learningPlanData,
  isEdit,
  saveInProgress,
  onSave,
  onPublish,
  onConfirmationModalOpen
}) => {
  const { t } = useTranslation(['learningPlans', 'common']);

  return (
    <>
      {isEdit && (
        <BasicButton
          onClick={() =>
            onConfirmationModalOpen && onConfirmationModalOpen(true)
          }
          color="delete"
          disable={saveInProgress}
          style={deleteButtonStyle}
        >
          <Typography sx={fontDeleteButton}>
            {t('deleteLP', { ns: 'learningPlans' })}
          </Typography>
        </BasicButton>
      )}
      {learningPlanData.isDraft && (
        <div>
          <BasicButton
            onClick={onSave}
            color="secondary"
            disable={saveInProgress}
            style={{ ...customButtonStyle, marginRight: '1rem' }}
          >
            {t('saveAsDraft', { ns: 'learningPlans' })}
          </BasicButton>
          <BasicButton
            onClick={onPublish}
            color="primary"
            disable={saveInProgress}
            style={customButtonStyle}
          >
            {t('publish', { ns: 'common' })}
          </BasicButton>
        </div>
      )}
      {!learningPlanData.isDraft && (
        <BasicButton
          onClick={onSave}
          color="primary"
          disable={saveInProgress}
          style={customButtonStyle}
        >
          {t('save', { ns: 'common' })}
        </BasicButton>
      )}
    </>
  );
};

const customButtonStyle: CSSProperties = {
  minWidth: '155px',
  height: '45px',
  marginBottom: '0.5rem'
};

const deleteButtonStyle: CSSProperties = {
  ...buttonStyle,
  minWidth: '220px',
  marginBottom: '0.5rem'
};

export default FooterActionButtons;

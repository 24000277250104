import { Grid } from '@mui/material';
import { ReactNode } from 'react';

const LearningPlanRowWrapper: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  return (
    <Grid item xs={12} sm={12} md={12} lg={10} xl={8} paddingTop="10px">
      {children}
    </Grid>
  );
};

export default LearningPlanRowWrapper;

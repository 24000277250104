import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import InputMask from 'react-input-mask';

export interface TimeInputProps {
  value: string;
  onChangeValue?: (value: any) => void;
  width: string;
  height: string;
  isInvalid?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  variant?: 'dark' | 'light' | undefined;
  dataTestId?: string;
  dataTrackId?: string;
}
const TimeInput: React.FC<TimeInputProps> = ({
  onChangeValue,
  width,
  height,
  isInvalid = false,
  variant = 'dark',
  value,
  dataTestId,
  dataTrackId
}) => {
  const [locValue, setlocValue] = React.useState(value);
  const theme = useTheme();

  const handleOnChange = (e: any) => {
    setlocValue(e.target.value);
    if (onChangeValue) {
      onChangeValue(e.target.value);
    }
  };
  const preStyle: CSSProperties = {
    padding: '5px',
    borderRadius: '5px',
    outline: 'none',
    paddingLeft: '8px',
    fontFamily: theme.typography.fontFamily,
    height: height,
    borderWidth: isInvalid ? 1 : 0,
    borderStyle: isInvalid ? 'solid' : 'none',
    width: width
  };

  const lightStyle: CSSProperties = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    borderColor: isInvalid
      ? theme.palette.error.main
      : theme.palette.primary.contrastText
  };

  const darkStyle: CSSProperties = {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isInvalid ? theme.palette.error.main : theme.palette.grey[100]
  };

  const variantStyle = variant === 'light' ? lightStyle : darkStyle;

  const inputStyle: CSSProperties = {
    ...preStyle,
    ...variantStyle
  };
  return (
    <InputMask
      mask="99:99"
      onChange={(e: any) => handleOnChange(e)}
      value={locValue}
      width={width}
      height={height}
      style={{
        ...inputStyle,
        width: width,
        height: height
      }}
      data-testid={dataTestId}
      data-trackid={dataTrackId}
    />
  );
};

export default TimeInput;

import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

export interface FileInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialText: string;
  id: string;
  name: string;
  type: string;
  variant?: 'light' | 'dark' | undefined;
  dataTestId?: string;
  dataTrackId?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  onChange,
  id,
  name,
  initialText,
  type,
  dataTestId,
  dataTrackId,
  variant = 'light'
}) => {
  const theme = useTheme();
  const [text, setText] = React.useState<string>(initialText);
  const handleFileChange = (e: any) => {
    const parts = e.target.value.split('\\');
    const fileName = parts[parts.length - 1];
    const cutFileName =
      fileName.length > 30 ? fileName.substring(0, 31) + '...' : fileName;
    setText(cutFileName);
    onChange(e);
  };
  const validTypes =
    type === 'video'
      ? 'video/*'
      : type === 'pdf'
        ? '.pdf'
        : type === 'vtt'
          ? '.vtt'
          : type === 'file'
            ? '.pdf, image/*'
            : type === 'thumbnail'
              ? 'image/*'
              : 'audio/*';

  return (
    <div className="custom-file mb-1">
      <input
        type="file"
        accept={validTypes}
        className="custom-file-input"
        id={id}
        name={name}
        style={inputStyle}
        onChange={handleFileChange}
        required
        data-testid={dataTestId}
        data-trackid={dataTrackId}
      />
      <label
        className={`custom-file-label${variant === 'dark' ? '-dark' : ''}`}
        htmlFor="customFile"
      >
        <FontAwesomeIcon
          icon={faLink}
          color={
            variant === 'dark'
              ? theme.palette.grey[100]
              : theme.palette.common.black
          }
        />{' '}
        {text}
      </label>
    </div>
  );
};

const inputStyle: CSSProperties = {
  cursor: 'pointer'
};

export default FileInput;

import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormLabel from '../../core/FormLabel/FormLabel';
import TextBox from '../../core/TextBox/TextBox';
import FileInput from '../../core/FileInput/FileInput';
import SubtitlesSection from './SubtitlesSection';
import {
  LessonFormModel,
  ContentMedium,
  SubtitleEditMode
} from '../../../entities/LessonFormModel';
import { getVideoOrAudioNameFromUrl } from '../../../utils/stringUtils';
import { matchYoutubeUrl } from '../../../utils/matchYoutubeUrl';
import { Tag } from '../../../entities/Tag';
import {
  isValidURL,
  IsValidVideoFormat,
  IsValidAudioFormat
} from '../../../utils/validationsUtils';
import RichTextInput from '../../core/RichTextInput/RichTextInput';
import { InfoBubble } from '../../InfoBubble/InfoBubble';
import MultipleFilesInput from '../../core/MultipleFilesInput/MultipleFilesInput';
import { TaggingField } from '../../TagInput';
import LessonCodeInput from './components/LessonCodeInput';
import LessonDurationInput from './components/LessonDurationInput';

export interface LessonInputsFormProps {
  lessonData: LessonFormModel;
  saveInProgress: boolean;
  contentMedium: number;
  tags: Array<Tag>;
  companyTags: Array<Tag>;
  subtitles: Array<SubtitleEditMode>;
  showYoutubeInput: boolean;
  setSubtitles: (subtitles: Array<SubtitleEditMode>) => void;
  setLessonData: (lessonData: LessonFormModel) => void;
  setTags: (tags: Array<Tag>) => void;
  getNextSubtitleId: () => string;
  onFilesUpdated: (files: File[]) => void;
  onFileRemoved: (fileName: string) => void;
}

const LessonInputsForm: React.FC<LessonInputsFormProps> = ({
  lessonData,
  saveInProgress,
  contentMedium,
  tags,
  companyTags,
  subtitles,
  showYoutubeInput,
  setSubtitles,
  setLessonData,
  setTags,
  getNextSubtitleId,
  onFilesUpdated,
  onFileRemoved
}) => {
  const { t } = useTranslation(['common', 'lessons']);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [videoErrorValue, setVideoErrorValue] = React.useState<boolean>(false);
  const [audioErrorValue, setAudioErrorValue] = React.useState<boolean>(false);
  const [PrimaryFileErrorValue, setPrimaryFileErrorValue] =
    React.useState<boolean>(false);
  const [PrimaryFileError, setPrimaryFileError] =
    React.useState<boolean>(false);

  const handleChangeTitle = (e: string) => {
    if (e) {
      setTitleError(false);
    } else {
      setTitleError(true);
    }
    setLessonData({ ...lessonData, title: e });
  };

  const handleChangeVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, video: file });
      const fileType = file['type'];
      if (IsValidVideoFormat(fileType)) {
        setVideoErrorValue(false);
      } else {
        setVideoErrorValue(true);
      }
    }
  };

  const handleChangeAudio = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({ ...lessonData, audioFile: file });
      const fileType = file['type'];
      if (IsValidAudioFormat(fileType)) {
        setAudioErrorValue(false);
      } else {
        setAudioErrorValue(true);
      }
    }
  };

  const handleChangeExternalUrl = (e: string) => {
    if (e.length > 0) {
      setLessonData({ ...lessonData, externalVideoUrl: e });
      setVideoErrorValue(!isValidURL(e) || !matchYoutubeUrl(e));
    } else {
      setVideoErrorValue(false);
    }
  };

  const handleChangeDuration = (val: string) => {
    if (val) {
      setLessonData({ ...lessonData, lessonDuration: val });
    }
  };

  const IsValidImageFormat = (fileType: string) => {
    const validImageTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/bmp'
    ];
    return validImageTypes.includes(fileType);
  };

  const handleChangePrimaryFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setLessonData({
        ...lessonData,
        lessonFile: file,
        lessonFileUrl: file.name
      });
      const fileType = file['type'];
      const validTypes = ['application/pdf'];
      if (validTypes.includes(fileType) || IsValidImageFormat(fileType)) {
        setPrimaryFileErrorValue(false);
      } else {
        setPrimaryFileErrorValue(true);
      }
    } else {
      setPrimaryFileError(true);
    }
  };

  const handleChangeDescription = (e: string) => {
    setLessonData({ ...lessonData, description: e });
  };

  const handleTagging = (selectedTags: Tag[]) => {
    setTags(selectedTags);
  };

  const handleAttachmentsUpdates = (files: File[]) => {
    if (onFilesUpdated) {
      onFilesUpdated(files);
    }
  };

  const handleAttachmentRemoved = (fileIndex: number) => {
    const allAttachments = lessonData.savedAttachments
      .map(x => x.fileName)
      .concat(lessonData.attachments.map(x => x.name))
      .filter(x => !deletedAttachments.includes(x));

    const fileName = allAttachments[fileIndex];

    if (fileIndex > lessonData.savedAttachments.length - 1) {
      onFilesUpdated(lessonData.attachments.filter(f => f.name !== fileName));
      return;
    }

    onFileRemoved(fileName);
  };

  const handleLessonCode = (e: string) => {
    setLessonData({ ...lessonData, lessonCode: e.replace(/\s/g, '') });
  };

  const deletedAttachments = lessonData.deletedAttachments;
  const fileTags = lessonData.savedAttachments
    .map(x => x.fileName)
    .concat(lessonData.attachments.map(x => x.name))
    .filter(x => !deletedAttachments.includes(x));

  return (
    <>
      <Grid item paddingBottom="0.5rem">
        <FormLabel>
          {t('lessonName', { ns: 'lessons' })}
          <span className="text-danger">*</span>
        </FormLabel>
        <TextBox
          id="lessonName"
          name="lessonName"
          value={lessonData.title}
          onChangeValue={handleChangeTitle}
          placeholderText={t('name', { ns: 'common' })}
          width="100%"
          height="35px"
          disabled={saveInProgress}
          variant="dark"
          dataTestId="lessonNameInput"
          dataTrackId="lessonNameInput"
        />
        {titleError && (
          <span
            className="text-danger"
            data-testid="lessonNameInputErrorRequired"
          >
            {t('nameIsRequired', { ns: 'common' })}
          </span>
        )}
      </Grid>
      {contentMedium === ContentMedium.Quiz && (
        <>
          <Grid item id="timeContainer" xs={12} md={12} lg={12}>
            <LessonDurationInput
              value={lessonData.lessonDuration}
              onChange={handleChangeDuration}
            />
          </Grid>
          <Grid item id="lpCodeContainer" xs={12} md={12} lg={12}>
            <LessonCodeInput
              value={lessonData.lessonCode}
              onChange={handleLessonCode}
              isDisabled={saveInProgress}
            />
          </Grid>
        </>
      )}
      {(contentMedium === ContentMedium.Video ||
        contentMedium === ContentMedium.File ||
        contentMedium === ContentMedium.Audio) && (
        <>
          <Grid item paddingBottom="0.5rem">
            {contentMedium === ContentMedium.Video && !showYoutubeInput && (
              <>
                <FormLabel>
                  {t('selectVideo', { ns: 'common' })}
                  <span className="text-danger">*</span>
                </FormLabel>
                <FileInput
                  initialText={t('uploadVideo', { ns: 'common' })}
                  onChange={handleChangeVideo}
                  id="fileInput"
                  name="fileInput"
                  type="video"
                  variant="dark"
                  dataTestId="lessonVideoFileInput"
                />
                {(lessonData.videoUrl || lessonData.hlsUrl) && (
                  <>
                    <span data-testid="lessonVideoFileUrl">
                      {getVideoOrAudioNameFromUrl(
                        lessonData.videoUrl,
                        lessonData.hlsUrl
                      )}
                    </span>
                    <br />
                  </>
                )}
                {videoErrorValue && (
                  <span
                    className="text-danger"
                    data-testid="lessonVideoFileInputRequiredError"
                  >
                    {t('videoLinkRequired', { ns: 'common' })}
                  </span>
                )}
              </>
            )}
            {contentMedium === ContentMedium.Video && showYoutubeInput && (
              <>
                <FormLabel>
                  {t('selectVideo', { ns: 'common' })}
                  <span className="text-danger">*</span>
                </FormLabel>
                <TextBox
                  id="extvideoUrl"
                  name="extvideoUrl"
                  value={lessonData.externalVideoUrl}
                  onChangeValue={handleChangeExternalUrl}
                  placeholderText="Add a YouTube video link"
                  width="100%"
                  height="35px"
                  disabled={saveInProgress}
                  variant="dark"
                  data-testid="lessonYouTubeVideoUrlInput"
                />
                {videoErrorValue && (
                  <span
                    className="text-danger"
                    data-testid="lessonYouTubeVideoUrlInputErrorRequired"
                  >
                    {t('youtubeUrlIsRequired', { ns: 'common' })}
                  </span>
                )}
              </>
            )}
            {contentMedium === ContentMedium.Audio && (
              <>
                <FormLabel>
                  {t('selectAudio', { ns: 'common' })}
                  <span className="text-danger">*</span>
                </FormLabel>
                <FileInput
                  initialText={t('uploadAudio', { ns: 'common' })}
                  onChange={handleChangeAudio}
                  id="fileInputAudio"
                  name="fileInputAudio"
                  type="audio"
                  variant="dark"
                  dataTestId="lessonAudioFileInput"
                />
                {(lessonData.audioUrl || lessonData.hlsUrl) && (
                  <>
                    <span data-testid="lessonAudioFileUrl">
                      {`File uploaded: ${getVideoOrAudioNameFromUrl(
                        lessonData.audioUrl,
                        lessonData.hlsUrl
                      )}`}
                    </span>
                    <br />
                  </>
                )}
                {audioErrorValue && (
                  <span
                    className="text-danger"
                    data-testid="lessonAudioFileInputErrorInvalidFormat"
                  >
                    {t('addValidFormat', { ns: 'common' })}
                  </span>
                )}
              </>
            )}
            {contentMedium === ContentMedium.File && (
              <>
                <FormLabel>
                  <InfoBubble text={t('selectPrimaryFile', { ns: 'common' })} />
                  {t('selectPrimaryFile', { ns: 'common' })}
                  <span className="text-danger"> *</span>
                </FormLabel>
                {lessonData.lessonFileUrl && (
                  <span>{lessonData.lessonFileUrl}</span>
                )}
                <FileInput
                  initialText={t('selectPrimaryFile', { ns: 'common' })}
                  onChange={handleChangePrimaryFile}
                  id="fileInput"
                  name="fileInput"
                  type="file"
                  variant="dark"
                  dataTestId="lessonFileInput"
                />
                {PrimaryFileErrorValue && (
                  <span
                    className="text-danger"
                    data-testid="lessonFileInputErrorInvalidFormat"
                  >
                    {t('addValidFormat', { ns: 'common' })}
                  </span>
                )}
                {PrimaryFileError && (
                  <span
                    className="text-danger"
                    data-testid="lessonFileInputErrorRequired"
                  >
                    {t('videoFileRequired', { ns: 'common' })}
                  </span>
                )}
              </>
            )}
            <>
              <FormLabel>
                {' '}
                {t('attachments', { ns: 'lessons' })}
                <InfoBubble text={t('attachments', { ns: 'lessons' })} />
              </FormLabel>
              <MultipleFilesInput
                onFileAdded={(fileName, file) => {
                  const exists = fileTags.find(f => f === fileName);

                  if (exists === undefined) {
                    handleAttachmentsUpdates([...lessonData.attachments, file]);
                  }
                }}
                tags={fileTags}
                onRemoveSavedFile={handleAttachmentRemoved}
                validTypes=".pdf,image/*"
                dataTestPrefix="lesson"
                dataTrackPrefix="lesson"
              />
            </>
          </Grid>
          {(contentMedium === ContentMedium.File ||
            contentMedium === ContentMedium.Quiz) && (
            <>
              <Grid
                item
                id="timeContainer"
                xs={12}
                md={12}
                lg={12}
                paddingBottom="0.5rem"
              >
                <LessonDurationInput
                  value={lessonData.lessonDuration}
                  onChange={handleChangeDuration}
                />
              </Grid>
              <Grid item id="lpCodeContainer" xs={12} md={12} lg={12}>
                <LessonCodeInput
                  value={lessonData.lessonCode}
                  onChange={handleLessonCode}
                  isDisabled={saveInProgress}
                />
              </Grid>
            </>
          )}
          <Grid item paddingBottom="0.5rem">
            <FormLabel>{t('lessonDescription', { ns: 'lessons' })}</FormLabel>
            <RichTextInput
              dataTestId="lessonDescriptionInput"
              value={lessonData.description}
              placeholderText={t('writeLessonDescription', { ns: 'lessons' })}
              onChangeValue={handleChangeDescription}
            />
          </Grid>
          {contentMedium === ContentMedium.Video && (
            <Grid item paddingBottom="0.5rem">
              <FormLabel>{t('selectSubtitle', { ns: 'common' })}</FormLabel>
              <SubtitlesSection
                lessonData={lessonData}
                subtitles={subtitles}
                setSubtitles={setSubtitles}
                setLessonData={setLessonData}
                getNextSubtitleId={getNextSubtitleId}
              />
            </Grid>
          )}
          <Grid item paddingBottom="0.5rem">
            <TaggingField
              options={companyTags}
              value={tags}
              onChange={handleTagging}
              dataTestPrefix="lesson"
              dataTrackPrefix="lesson"
            />
          </Grid>
          <Grid item paddingBottom="0.5rem">
            <LessonCodeInput
              value={lessonData.lessonCode}
              onChange={handleLessonCode}
              isDisabled={saveInProgress}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default LessonInputsForm;

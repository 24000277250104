import React, { useState, useRef } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

export interface FirstAdminLoginModalProps {
  showModal: boolean;
  onClose: () => void;
  video: string;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const FirstAdminLoginVideoModal: React.FC<FirstAdminLoginModalProps> = ({
  showModal,
  onClose,
  video
}) => {
  const theme = useTheme();
  const player = useRef<ReactPlayer>(null);
  const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
  const [videoUrl, setVideoUrl] = useState('');
  const { t } = useTranslation(['common']);

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  React.useEffect(() => {
    if (video) {
      setVideoUrl(video);
    }
  }, [video]);

  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
    >
      <DialogTitle>
        {`${t('welcomeVideo', { ns: 'common' })}`}
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="player-wrapper">
          <ReactPlayer
            width="100%"
            height="450px"
            controls={true}
            playing={false}
            pip={true}
            muted={false}
            url={videoUrl}
            ref={player}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <BasicButton
          color="primary"
          width="160px"
          height="45px"
          onClick={() => {
            onClose();
          }}
        >
          {`${t('close', { ns: 'common' })}`}
        </BasicButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default FirstAdminLoginVideoModal;

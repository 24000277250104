import { ApplicationState, AppThunk } from '../store/index';
import axios, { AxiosError } from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  LEARNING_PLAN_NEW,
  LEARNING_PLAN_UPDATE,
  LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
  LEARNING_PLAN_RESET_TRANSACTION_STATUS,
  LEARNING_PLAN_SET_COURSES,
  LEARNING_PLAN_SET_PLANS,
  LEARNING_PLAN_UPDATE_ASSIGNMENT,
  LEARNING_PLAN_IS_LOADING,
  LEARNING_PLAN_SET_ASSIGNMENT_STATUS,
  LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS,
  LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  LEARNING_PLAN_PLAY
} from './actionTypes';
import config from './../config';
import {
  LearningPlan,
  LearningPlanAssignment,
  LearningPlanEntry
} from '../entities/LearningPlan';
import { uploadLearningPlanThumbToBlob } from '../services/blobStorage-service';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { Course } from '../entities/Course';
import { TemporalNewId, UserClaims } from '../core/constants';
import { createSelector } from 'reselect';
import {
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition,
  LearningPlanGroupAssignment,
  LearningPlanPlayAssignment
} from '../entities/Assignment';
import useCurrentUser from '../hooks/useCurrentUser';
import { orderCourses } from '../utils/courseOrderUtils';
import { handleGenericBackendError } from '../utils/errorHandling';
import { SelfAssignResponse } from '../components/SelfAssignButton/types';

export interface NewLearningPlanAction {
  type: 'LEARNING_PLAN_NEW';
}

export interface UpdateLearningPlanAction {
  type: 'LEARNING_PLAN_UPDATE';
  learningPlan: LearningPlan;
}

export interface UpdateLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
}

export interface ResetLearningPlanTransactionStatusAction {
  type: 'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
}

export interface GetLearningPlanCoursesAction {
  type: 'LEARNING_PLAN_SET_COURSES';
  learningPlanCourses: Course[];
}

export interface RequestLearningPlansAction {
  type: 'LEARNING_PLAN_SET_PLANS';
  plans: LearningPlan[];
}

export interface RequestPartnersLearningPlans {
  type: 'LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS';
  learningPlansPartner: LearningPlan[];
}

export interface ResetPartnersLearningPlans {
  type: 'LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS';
  learningPlansPartner: LearningPlan[];
}

export interface UpdateLearningPlansAssigment {
  type: 'LEARNING_PLAN_UPDATE_ASSIGNMENT';
  learningPlanAssigment: LearningPlanAssignment;
}

export interface SetLearningPlanIsLoadingAction {
  type: 'LEARNING_PLAN_IS_LOADING';
  isLoading: boolean;
}

export interface SetLearningPlanAssignmentStatusAction {
  type: 'LEARNING_PLAN_SET_ASSIGNMENT_STATUS';
  learningPlanAssignmentStatus: EmployeeAssignmentStatus[] | null;
}

export interface SetLearningPlanGroupAssignmentDefinitions {
  type: 'LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS';
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
}

export interface LearningPlanPlayAction {
  type: 'LEARNING_PLAN_PLAY';
  learningPlanPlayAssignment: LearningPlanPlayAssignment | null;
}

export const actionCreators = {
  newLearningPlan: () => ({
    type: LEARNING_PLAN_NEW
  }),
  saveLearningPlan:
    (
      learningPlanDto: LearningPlan,
      imgUrl: string,
      file?: File | undefined | null
    ): AppThunk<Promise<LearningPlan>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        if (file) {
          const blobUrl = await uploadLearningPlanThumbToBlob(file);
          learningPlanDto.thumbnailUrl = blobUrl;
        } else if (imgUrl) {
          learningPlanDto.thumbnailUrl = imgUrl;
        }

        const axiosMethod =
          learningPlanDto.id === TemporalNewId ? 'post' : 'put';
        const result = axios({
          method: axiosMethod,
          url: `${config.STUDENT_API_URL}learningplans`,
          data: learningPlanDto
        })
          .then(async response => {
            dispatch({
              type: LEARNING_PLAN_UPDATE,
              learningPlan: response.data
            });

            dispatch({
              type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
              transactionStatus: TransactionStatusEnum.Successfull,
              errorMessage: ''
            });

            return response.data;
          })
          .catch(error => {
            dispatch({
              type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
              transactionStatus: TransactionStatusEnum.Failed,
              errorMessage: ''
            });

            throw error;
          })
          .finally(() => {
            dispatch(appActions.setIsLoading(false));
          });

        return result;
      }
    },
  deleteLearningPlan:
    (learningPlanId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        let resStatus = 0;
        try {
          const res = await axios.delete(
            `${config.STUDENT_API_URL}learningplans/${learningPlanId}`
          );
          resStatus = res.status;
        } catch (e) {
          const error = e as AxiosError;
          if (error && error.response) resStatus = error.response.status;
        } finally {
          dispatch({
            type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });

          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  deleteFullLearningPlan:
    (learningPlanId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        let resStatus = 0;
        try {
          const res = await axios.delete(
            `${config.STUDENT_API_URL}learningplans/${learningPlanId}?isFull=true`
          );
          resStatus = res.status;
        } catch (e) {
          const error = e as AxiosError;
          if (error && error.response) resStatus = error.response.status;
        } finally {
          dispatch({
            type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });

          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  deleteLearningPlanCourses:
    (learningPlanId: string, courseId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.delete(
          `${config.STUDENT_API_URL}learningplans/courses/${learningPlanId}/${courseId}`
        );

        dispatch({
          type: LEARNING_PLAN_UPDATE,
          learningPlan: res.data
        });

        dispatch({
          type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            res.status === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  getLearningPlanCourses:
    (
      courseIds: string,
      useOrder = true,
      entries: LearningPlanEntry[] = []
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.put(
          `${config.COURSES_API_URL}courses/byIds`,
          `"${courseIds}"`,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        const oData = orderCourses(useOrder, entries, res.data);
        dispatch({
          type: LEARNING_PLAN_SET_COURSES,
          learningPlanCourses: oData
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  assignLearningPlanToEmployees:
    (
      assignLearningPlan: LearningPlanAssignment
    ): AppThunk<Promise<SelfAssignResponse | null>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        let resStatus = 0;
        let resMessage = '';
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}assignments/assign/learningplan/employees`,
            assignLearningPlan
          );

          resStatus = res.status;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const errorData = e.response?.data as {
              Data: { assigmentId: string };
            };
            if (errorData.Data.assigmentId) {
              resMessage = errorData.Data.assigmentId;
            }
          }
        } finally {
          dispatch({
            type: LEARNING_PLAN_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: resMessage
          });
        }

        return { status: resStatus, message: resMessage };
      }

      return null;
    },
  resetLearningPlanTransactionStatus: () => ({
    type: LEARNING_PLAN_RESET_TRANSACTION_STATUS
  }),
  setLearningPlanCourses: (courses: Course[]) => ({
    type: LEARNING_PLAN_SET_COURSES,
    learningPlanCourses: courses
  }),
  setCurrentLearningPlan: (learingPlan: LearningPlan | null) => ({
    type: LEARNING_PLAN_UPDATE,
    learningPlan: learingPlan
  }),
  setLearningPlansAssigment: (
    learingPlanAssignment: LearningPlanAssignment | null
  ) => ({
    type: LEARNING_PLAN_UPDATE_ASSIGNMENT,
    learingPlanAssignment
  }),
  updatePlans: (learingPlans: LearningPlan[]) => ({
    type: LEARNING_PLAN_SET_PLANS,
    plans: learingPlans
  }),
  requestPlans: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState && appState.courses?.courses) {
      dispatch(appActions.setIsLoading(true));
      dispatch({
        type: LEARNING_PLAN_IS_LOADING,
        isLoading: true
      });

      try {
        const res = await axios.get(`${config.STUDENT_API_URL}learningPlans`);
        dispatch({
          type: LEARNING_PLAN_SET_PLANS,
          plans: res.data
        });
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch(appActions.setIsLoading(false));
        dispatch({
          type: LEARNING_PLAN_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  requestPartnersLearningPlans:
    (companyPartnersIds: string[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          dispatch(appActions.setIsLoading(true));
          dispatch({
            type: LEARNING_PLAN_IS_LOADING,
            isLoading: true
          });
          const res = await axios.put(
            `${config.STUDENT_API_URL}learningplans/bycompany`,
            companyPartnersIds
          );
          dispatch({
            type: LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS,
            learningPlansPartner: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: LEARNING_PLAN_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  resetPartnersLearningPlans: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch({
        type: LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS,
        learningPlansPartner: []
      });
    }
  },
  requestPlan:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.STUDENT_API_URL}learningPlans/${id}`
        );
        dispatch({
          type: LEARNING_PLAN_UPDATE,
          learningPlan: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  getCoursesByLearningPlanAssignment:
    (lpAssignmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.STUDENT_API_URL}assignments/courses/${lpAssignmentId}`
        );

        dispatch({
          type: LEARNING_PLAN_SET_COURSES,
          learningPlanCourses: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  requestStatusOfEmployeesLearningPlanAssigments:
    (courseId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/learningplan/${courseId}/status`
          );

          dispatch({
            type: LEARNING_PLAN_SET_ASSIGNMENT_STATUS,
            learningPlanAssignmentStatus: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  SetStatusOfEmployeesLearningPlanAssigments: (
    assignmentStatus: EmployeeAssignmentStatus[] | null
  ) => ({
    type: LEARNING_PLAN_SET_ASSIGNMENT_STATUS,
    learningPlanAssignmentStatus: assignmentStatus
  }),
  requestLearningPlanAssignmentDefinitions:
    (learningPlanId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employee-group-assignments/assigned/learningplan/${learningPlanId}`
          );

          dispatch({
            type: LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS,
            groupAssignmentDefinitions: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setLearningPlanAssignmentDefinitions: (
    definitions: EmployeeGroupAssignmentDefinition[] | null
  ) => ({
    type: LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS,
    groupAssignmentDefinitions: definitions
  }),
  saveLearningPlanGroupAssignments:
    (
      learningPlanGroupAssignments: LearningPlanGroupAssignment
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        const { learningPlanId, assignBy, groups } =
          learningPlanGroupAssignments;
        const assignmentDefinitionIds: string[] = [];

        try {
          for (let i = 0; i < groups.length; i++) {
            const response = await axios.post(
              `${config.STUDENT_API_URL}employee-group-assignments/assign/learningplan`,
              {
                learningPlanId,
                employeeGroupId: groups[i].groupId,
                assignBy,
                employeeIds: groups[i].employeeIds
              }
            );
            assignmentDefinitionIds.push(response.data);
          }
        } catch (e) {
          for (let j = 0; j < assignmentDefinitionIds.length; j++) {
            await axios.delete(
              `${config.STUDENT_API_URL}employee-group-assignments/${assignmentDefinitionIds[j]}`
            );
          }
          throw e;
        }
      }
    },
  getLearningPlanAssignment:
    (lpAssignmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.STUDENT_API_URL}assignment-progress/learning-plan/${lpAssignmentId}`
        );

        dispatch({
          type: LEARNING_PLAN_PLAY,
          learningPlanPlayAssignment: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  clearLearningPlanAssignment: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: LEARNING_PLAN_PLAY,
        learningPlanPlayAssignment: null
      });
    }
  }
};

export const selectAllLearningPlans = (state: ApplicationState) =>
  state.learningPlans?.learningPlans;

const selectLearningPlanCompanyId = (
  _state: ApplicationState,
  companyId: string
) => companyId;

export const selectLearningPlansByCompany = createSelector(
  [selectAllLearningPlans, selectLearningPlanCompanyId],
  (learningPlans, companyId) =>
    learningPlans?.filter(learningPlan => learningPlan.companyId === companyId)
);

export const selectCompanyLearningPlans = createSelector(
  selectAllLearningPlans,
  learningPlans => {
    const user = useCurrentUser();
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    return learningPlans?.filter(lp => lp.companyId === companyId) ?? [];
  }
);

export type KnownAction =
  | NewLearningPlanAction
  | UpdateLearningPlanAction
  | UpdateLearningPlanTransactionStatusAction
  | ResetLearningPlanTransactionStatusAction
  | GetLearningPlanCoursesAction
  | RequestLearningPlansAction
  | UpdateLearningPlansAssigment
  | SetLoadingAction
  | SetLearningPlanIsLoadingAction
  | SetLearningPlanAssignmentStatusAction
  | RequestPartnersLearningPlans
  | ResetPartnersLearningPlans
  | SetLearningPlanGroupAssignmentDefinitions
  | LearningPlanPlayAction;

import { useEffect, FC, useState, useContext } from 'react';
import { Grid, SxProps } from '@mui/material';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators } from '../../../actions/lessons';
import { Lesson } from '../../../entities/Lesson';
import { useNavigate } from 'react-router-dom';
import AssignLesson from '../../lessons/AssignLesson';
import { useTranslation } from 'react-i18next';
import { useLesson } from '../../lessons/useLesson';
import { generateLessonsToShow } from '../../../utils/contentLibraryUtils';
import { ContentLibraryTabNames } from '../../../core/enums';
import { TakenTimesObject } from '../../../entities/CompletedLessonInfo';
import { enqueueSnackbar } from 'notistack';
import {
  LangContent,
  Sorting
} from '../../../hooks/useContentLibraryNavigation';
import { UserNavigationContext } from '../../contentLibrary/UserNavigationContext';
import ContentLibraryVirtualList from '../../../components/ContentLibraryVirtualList/ContentLibraryVirtualList';
import LessonVirtualRow from './LessonVirtualRow';
import ContentIndicators from '../common/ContentIndicators';
import { useEncodingStatusUpdate } from '../../../hooks/useEncodingStatusUpdate';
import { Tag } from '../../../entities/Tag';
import { useAppDispatch } from '../../../store/hooks';
interface LessonProps {
  filterOrder: string;
  filterLanguageOption: string;
  searchFilter: string;
  takenTimesLessons?: TakenTimesObject | null;
  encodedLessonId: string;
  filterTags: Tag[];
}

export const CompanyLessonContentLibrary: FC<LessonProps> = ({
  filterOrder,
  filterLanguageOption,
  searchFilter,
  encodedLessonId,
  filterTags
}) => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(
    (state: ApplicationState) => state.lessons?.isLoading
  );
  const companyLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const navigate = useNavigate();
  const [lessonsToShow, setLessonsToShow] = useState<Lesson[]>();
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigationApi = userNavigationState?.api;

  const {
    api: { handleOpenAssignLessonModal, handleCloseAssignLessonModal },
    state: { showAssignLessonModal }
  } = useLesson(false);

  const { t } = useTranslation(['common', 'lessons', 'quizzes']);

  const handleLessonsUpdate = (updatedList: any[]) => {
    const lessons = generateLessonsToShow(
      updatedList!,
      searchFilter,
      filterLanguageOption as LangContent,
      filterOrder as Sorting,
      filterTags
    );
    setLessonsToShow(lessons);
  };
  useEncodingStatusUpdate(
    encodedLessonId,
    companyLessons,
    null,
    handleLessonsUpdate
  );

  useEffect(() => {
    userNavigationApi?.changeSearch(searchFilter);

    if (companyLessons) {
      const lessons = generateLessonsToShow(
        companyLessons,
        searchFilter,
        filterLanguageOption as LangContent,
        filterOrder as Sorting,
        filterTags
      );
      setLessonsToShow(lessons);
    } else {
      setLessonsToShow([]);
    }
  }, [
    companyLessons,
    filterLanguageOption,
    filterOrder,
    searchFilter,
    filterTags
  ]);

  const handleLessonPreview = (lesson: Lesson, isPreviewMode?: boolean) => {
    if (lesson && lesson.isEncodingFailed) {
      enqueueSnackbar(t('videoLessonEcodingFailed', { ns: 'lessons' }), {
        variant: 'error'
      });
    } else {
      dispatch(actionCreators.setCurrentLessons([lesson]));
      navigate(`/lessons/preview`, {
        state: { lesson, origin: ContentLibraryTabNames.Company, isPreviewMode }
      });
    }
  };

  return (
    <>
      <Grid item xs={12} sx={lessonContainer}>
        <ContentIndicators
          isLoading={isLoading ?? false}
          totalItems={companyLessons?.length ?? 0}
          noContentMessage={t('noLessonsAvailable', { ns: 'lessons' })}
        />
        <ContentLibraryVirtualList
          items={lessonsToShow ?? []}
          learningPlanUnit={'lessons'}
          isLoading={isLoading ?? false}
          itemSize={316}
        >
          {({ data, index, style }) => (
            <LessonVirtualRow
              index={index}
              style={style}
              data={data}
              onLessonPreview={handleLessonPreview}
              onOpenAssignModal={handleOpenAssignLessonModal}
            />
          )}
        </ContentLibraryVirtualList>
      </Grid>
      {showAssignLessonModal && (
        <AssignLesson
          onClose={handleCloseAssignLessonModal}
          open={showAssignLessonModal}
        />
      )}
    </>
  );
};

const lessonContainer: SxProps = {
  paddingTop: '2rem'
};

export default CompanyLessonContentLibrary;

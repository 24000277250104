import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import { useAnalyticsEngagementModal } from '../../../hooks/useAnalyticsEngagementModal';
import GroupCompletedAssignmentList from './GroupCompletedAssignmentList';

interface GroupCompletedAssignmentsModalProps {
  groupId?: string;
  groupName?: string;
  onCloseModal: () => void;
}

const GroupCompletedAssignmentsModal = ({
  groupId,
  groupName,
  onCloseModal
}: GroupCompletedAssignmentsModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { sortOptions, openModal, groupCompletedAssignmentsData },
    api: { closeModal, handleSorting, handleDownloadReport }
  } = useAnalyticsEngagementModal('groupCompletedAssignments', groupId);

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t(`totalCompletedAssignments`)}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
        onDownloadReport={handleDownloadReport}
        subtitle={groupName}
      >
        <GroupCompletedAssignmentList
          completedAssignmentsData={groupCompletedAssignmentsData}
        />
      </AssignmentStatusModal>
    </>
  );
};

export default GroupCompletedAssignmentsModal;

import { AppStateReducer, AppState } from '../reducers/AppStateReducer';
import { CoursesReducer, CoursesState } from '../reducers/CoursesReducer';
import { LessonsReducer, LessonsState } from '../reducers/LessonsReducer';
import { TagsReducer, TagsState } from '../reducers/TagsReducer';
import { EmployeesReducer, EmployeesState } from '../reducers/EmployeesReducer';
import {
  LearningPlansReducer,
  LearningPlanState
} from '../reducers/LearningPlansReducer';
import {
  AssessmentsReducer,
  AssessmentsState
} from '../reducers/AssessmetsReducer';
import { StudentReducer, StudentState } from '../reducers/StudentReducer';
import {
  NotificationsReducer,
  NotificationsState
} from '../reducers/NotificationsReducer';
import {
  ContentLibraryReducer,
  ContentLibraryState
} from '../reducers/ContentLibraryReducer';
import {
  SubscriptionsReducer,
  SubscriptionsState
} from '../reducers/SubscriptionsReducer';
import { AnalyticsReducer, AnalyticsState } from '../reducers/AnalyticsReducer';
import { HaltsReducer, HaltsState } from '../reducers/HaltsReducer';
import {
  AchievementsReducer,
  AchievementsState
} from '../reducers/AchievementsReducer';
import {
  PreferencesReducer,
  PreferencesState
} from '../reducers/PreferencesReducer';
import {
  OnsiteTrainingsReducer,
  OnsiteTrainingsState
} from '../reducers/OnsiteTrainingsReducer';
import {
  CertificationsReducer,
  CertificationsState
} from '../reducers/CertificationsReducer';
import { UnknownAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

// The top-level state object
export interface ApplicationState {
  appState: AppState | undefined;
  courses: CoursesState;
  lessons: LessonsState | undefined;
  tags: TagsState | undefined;
  employees: EmployeesState | undefined;
  learningPlans: LearningPlanState | undefined;
  assessments: AssessmentsState | undefined;
  student: StudentState | undefined;
  notifications: NotificationsState | undefined;
  contentLibrary: ContentLibraryState | undefined;
  subscriptions: SubscriptionsState | undefined;
  analytics: AnalyticsState | undefined;
  halts: HaltsState | undefined;
  achievements: AchievementsState;
  notificationPreferences: PreferencesState | undefined;
  onsiteTrainings: OnsiteTrainingsState | undefined;
  certifications: CertificationsState | undefined;
}

export const reducers = {
  appState: AppStateReducer,
  courses: CoursesReducer,
  lessons: LessonsReducer,
  tags: TagsReducer,
  employees: EmployeesReducer,
  learningPlans: LearningPlansReducer,
  assessments: AssessmentsReducer,
  student: StudentReducer,
  notifications: NotificationsReducer,
  contentLibrary: ContentLibraryReducer,
  subscriptions: SubscriptionsReducer,
  analytics: AnalyticsReducer,
  halts: HaltsReducer,
  achievements: AchievementsReducer,
  notificationPreferencesReducer: PreferencesReducer,
  notificationPreferences: PreferencesReducer,
  onsiteTrainings: OnsiteTrainingsReducer,
  certifications: CertificationsReducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ApplicationState,
  unknown,
  UnknownAction
>;

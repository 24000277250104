import React, { useState, useEffect, CSSProperties, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import {
  Typography,
  ThemeProvider,
  FormControl,
  Tab,
  styled,
  Grid,
  Checkbox,
  Box
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  MenuButton,
  ButtonMenuItem,
  defaultTheme
} from '@buildwitt/component-library';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  ThirdPartyCompanies,
  UserClaims,
  appPermissions,
  ContentLibraryTabs
} from '../../core/constants';
import { actionCreators as contentLibraryActions } from '../../actions/contentLibrary';
import { actionCreators as coursesActions } from '../../actions/courses';
import { ApplicationState } from '../../store';
import { CompanyUserNew } from './CompanyUserNew';
import { BuildWittNew } from './BuildWittNew';
import { ClickSafetyNew } from './ClickSafetyNew';
import { AllContentNew } from './AllContentNew';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { InfoBubble } from '../../components/InfoBubble/InfoBubble';
import { useLogEvent } from '../../hooks/useLogEvent';
import config from '../../config';
import { ContentLibraryTabNames } from '../../core/enums';

import { actionCreators as learningPlansActions } from '../../actions/learningPlans';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as studentActions } from '../../actions/student';
import { Partner } from './Partner';
import { Community } from './Community';
import { useContentLibraryNavigation } from '../../hooks/useContentLibraryNavigation';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { UserNavigationContext } from './UserNavigationContext';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { ServerEventsNames } from '../../core/constants';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';
import FormControlLabel from '@mui/material/FormControlLabel';

const ContentLibrary = () => {
  const { t } = useTranslation([
    'contentLibrary',
    'lessons',
    'courses',
    'learningPlans',
    'assessments'
  ]);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const containerRef = useRef<HTMLDivElement>(null);
  const [lastTab, userNavigationTabState, userNavigationApi] =
    useContentLibraryNavigation();
  const screenSizeSmall = useMediaQuery('(max-width:640px)');
  const useStyles = makeStyles(() => ({
    tabList: {
      display: 'flex',
      '& .MuiTab-root': {
        textTransform: 'none',
        fontSize: '16px',
        minWidth: 'auto',
        minHeight: 50
      },
      '& .MuiTabScrollButton-root:first-child::before': {
        content: ''
      },
      '& .MuiTabScrollButton-root:last-child::after': {
        content: ''
      },
      '& .MuiSvgIcon-root': {
        width: '50px',
        height: '50px'
      }
    }
  }));

  const classes = useStyles();
  const isMobileOrTablet = useMediaQuery('(max-width: 1200px)');
  const [currentTab, setCurrentTab] = useState(
    lastTab ?? ContentLibraryTabNames.AllContent
  );

  const [hideSpanishContent, setHideSpanishContent] = useState(
    navigator.language.startsWith('en')
  );

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
    userNavigationApi.changeTab(newValue);
  };

  const thirdPartyCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.thirdPartyCompanies
  );
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const aiccCourses = useSelector(
    (state: ApplicationState) => state.courses?.aiccCourses
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const [clickSafetyCompanyId, setClickSafetyCompanyId] = useState<string>();
  const menuItems: Array<ButtonMenuItem> = [
    {
      component: <span>{t('createLesson', { ns: 'lessons' })}</span>,
      value: 'lesson'
    },
    {
      component: <span>{t('createCourse', { ns: 'courses' })}</span>,
      value: 'course'
    },
    {
      component: (
        <span>{t('createLearningPlan', { ns: 'learningPlans' })}</span>
      ),
      value: 'learningPlan'
    }
  ];

  const [encodingStatusSignal, setEncodingStatusSignal] = useState('');
  const [connection, setConnection] = useState<null | HubConnection>(null);

  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(`${config.COURSES_BASE_URL}/notifications`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);

    return () => {
      userNavigationApi.clearTabsSearchValue();
    };
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(
            ServerEventsNames.LessonVideoEncodingComplete,
            (lessonId: string, duration: string) => {
              try {
                setEncodingStatusSignal(`${lessonId};${duration}`);
              } catch (e: any) {
                enqueueSnackbar(e, { variant: 'error' });
              }
            }
          );
          connection.on(
            ServerEventsNames.LessonVideoEncodingFailed,
            (error: any) => {
              const message = `${t('videoEncodingError', {
                ns: 'common'
              })}: ${error}`;
              enqueueSnackbar(message, { variant: 'error' });
            }
          );
        })
        .catch((error: any) => enqueueSnackbar(error, { variant: 'error' }));
    }
  }, [connection]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        lessonActions.requestAllBWLessons(bWCompanyId, hideSpanishContent)
      );
      dispatch(lessonActions.requestCompanyLessons(hideSpanishContent));

      dispatch(coursesActions.requestBuildWittCourses(hideSpanishContent));
      dispatch(coursesActions.requestCourses(hideSpanishContent));
      dispatch(coursesActions.requestCourseTags());
      dispatch(learningPlansActions.requestPlans());
      dispatch(lessonActions.requestCommunityLessons(hideSpanishContent));

      dispatch(studentActions.getTakenTimesLessons(employeeId));
      dispatch(studentActions.getTakenTimesCourses(employeeId));
      dispatch(studentActions.getTakenTimesLearningPlans(employeeId));
    }
  }, [dispatch, hideSpanishContent]);

  useEffect(() => {
    if (!thirdPartyCompanies && !isLoading) {
      dispatch(contentLibraryActions.requestThirdPartyCompanies());
      dispatch(coursesActions.setCoursesByCompany(null));
    }
  }, []);

  useEffect(() => {
    if (partnerCompanies && partnerCompanies.length > 0) {
      const companyPartnersIds = partnerCompanies.map(company => company.id);
      dispatch(
        coursesActions.requestPartnersCourses(
          companyPartnersIds,
          hideSpanishContent
        )
      );
      dispatch(
        lessonActions.requestPartnersLessons(
          companyPartnersIds,
          hideSpanishContent
        )
      );
      dispatch(
        learningPlansActions.requestPartnersLearningPlans(companyPartnersIds)
      );
    } else {
      // Reset partnersLearningPlans state, Becuase of the Redux Persist
      dispatch(learningPlansActions.resetPartnersLearningPlans());
    }
  }, [partnerCompanies, hideSpanishContent]);

  useEffect(() => {
    if (thirdPartyCompanies) {
      const clickSafetyCompany = thirdPartyCompanies.find(
        company =>
          company.name.toLowerCase() ===
          ThirdPartyCompanies.clickSafety.toLowerCase()
      );
      if (!!clickSafetyCompany && !isLoading) {
        setClickSafetyCompanyId(clickSafetyCompany.id);
        dispatch(coursesActions.requestAiccCourses(clickSafetyCompany.id));
        return;
      }
    }
  }, [thirdPartyCompanies]);

  const handleChangeOption = (option: any) => {
    const createOption = option as unknown as string;

    switch (createOption) {
      case 'lesson':
        navigate('/content-library/lessons/create', {
          state: {
            isEditing: false,
            isInCourses: false
          }
        });
        break;
      case 'course':
        navigate('/content-library/courses/create', {
          state: { isEditing: false }
        });
        break;
      case 'learningPlan':
        navigate('/content-library/learning-plans/create', {
          state: {
            isEditing: false
          }
        });
        break;
      default:
        break;
    }
  };

  const shouldShowCommunityTab =
    !!thirdPartyCompanies &&
    thirdPartyCompanies.some(element =>
      element.name.includes('BuildWitt Community Library')
    );

  const shouldShowClickSafetyTab = clickSafetyCompanyId && aiccCourses?.length;

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Grid
          container
          justifyContent={'space-between'}
          alignItems={'baseline'}
          style={{ marginTop: '10px', marginBottom: '-50px' }}
        >
          <Grid item xs={6}>
            <Typography sx={fontTab}>
              {t('contentLibrary', { ns: 'contentLibrary' })}
              {!screenSizeSmall && (
                <InfoBubble
                  onHover={() => logEvent.logViewNewContentHelpEvent()}
                  placement="right"
                  text={t('bubbleInfo', { ns: 'contentLibrary' })}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} style={createButtonStyle}>
            <Box
              flexDirection={'column'}
              display={'flex'}
              alignItems={'flex-end'}
            >
              <FormControl variant="standard">
                <ProtectedComponent
                  action={appPermissions.CREATE_LEARNING_UNITS}
                >
                  <MenuButton
                    id="createOptions"
                    title="Create"
                    buttonSxStyles={{
                      width: '110px'
                    }}
                    onClickOption={handleChangeOption}
                    menuOptions={menuItems}
                  />
                </ProtectedComponent>
              </FormControl>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    id="hideSpanishContentId"
                    name="hideSpanishContentName"
                    checked={hideSpanishContent}
                    onChange={() => setHideSpanishContent(!hideSpanishContent)}
                  />
                }
                label={
                  <Typography>
                    {t('hideSpanishContent', { ns: 'contentLibrary' })}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        </Grid>
        <br />
        <Row>
          <Col>
            <UserNavigationContext.Provider
              value={{
                api: userNavigationApi,
                state: userNavigationTabState,
                containerRef: containerRef
              }}
            >
              <TabContext value={currentTab}>
                <TabList
                  onChange={handleChangeTab}
                  aria-label="lab API tabs example"
                  variant={isMobileOrTablet ? 'scrollable' : 'standard'}
                  className={classes.tabList}
                  allowScrollButtonsMobile
                >
                  <Tab
                    label={t('AllContent', { ns: 'contentLibrary' })}
                    value={ContentLibraryTabs.AllContent}
                  />
                  <Tab label={companyName} value={companyName} />
                  <Tab
                    label={ContentLibraryTabNames.Buildwitt}
                    value={ContentLibraryTabs.BuildWitt}
                  />
                  {shouldShowClickSafetyTab && (
                    <Tab
                      label={ContentLibraryTabNames.ClickSafety}
                      value={ContentLibraryTabs.ClickSafety}
                    />
                  )}
                  {!config.CL_PARTNER && (
                    <Tab
                      label={ContentLibraryTabNames.Partner}
                      value={ContentLibraryTabs.Partner}
                    />
                  )}
                  {shouldShowCommunityTab && (
                    <Tab
                      label={ContentLibraryTabNames.Community}
                      value={ContentLibraryTabs.Community}
                    />
                  )}
                </TabList>
                <StyledTabPanel value={ContentLibraryTabs.AllContent}>
                  <AllContentNew encodedLessonId={encodingStatusSignal} />
                </StyledTabPanel>
                <StyledTabPanel value={companyName}>
                  <CompanyUserNew encodedLessonId={encodingStatusSignal} />
                </StyledTabPanel>
                <StyledTabPanel value={ContentLibraryTabs.BuildWitt}>
                  <BuildWittNew encodedLessonId={encodingStatusSignal} />
                </StyledTabPanel>
                {shouldShowClickSafetyTab && (
                  <StyledTabPanel value={ContentLibraryTabs.ClickSafety}>
                    <ClickSafetyNew />
                  </StyledTabPanel>
                )}
                {!config.CL_PARTNER && (
                  <StyledTabPanel value={ContentLibraryTabs.Partner}>
                    <Partner />
                  </StyledTabPanel>
                )}
                {shouldShowCommunityTab && (
                  <StyledTabPanel value={ContentLibraryTabs.Community}>
                    <Community encodedLessonId={encodingStatusSignal} />
                  </StyledTabPanel>
                )}
              </TabContext>
            </UserNavigationContext.Provider>
          </Col>
        </Row>
      </PageContainer>
    </ThemeProvider>
  );
};

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;

const fontTab: CSSProperties = {
  fontSize: '24px',
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 1)'
};

const createButtonStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end'
};

export default ContentLibrary;

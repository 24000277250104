import { useState } from 'react';
import EmployeesCertificationsModal from '../../EmployeesCertificationsModal/EmployeesCertificationsModal';
import { Box, Grid, Stack, Typography } from '@mui/material';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';
import TextBox from '../../../../components/core/TextBox/TextBox';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { UserClaims } from '../../../../core/constants';

interface AAwardConfirmationModalProps {
  openModal: boolean;
  savingInProgress: boolean;
  certificationName: string;
  handleCloseModal: () => void;
  handleSave: () => Promise<void>;
  handleBack: () => void;
}

const AwardConfirmationModal = ({
  openModal,
  savingInProgress,
  certificationName,
  handleCloseModal,
  handleSave,
  handleBack
}: AAwardConfirmationModalProps) => {
  const user = useCurrentUser();
  const [inputUserName, setInputUserName] = useState<string>('');
  const { t } = useTranslation(['certifications', 'common']);
  const userName = `${user?.profile[UserClaims.FirstName] ?? ''} ${user?.profile[UserClaims.LastName] ?? ''}`;
  const saveEnabled =
    inputUserName.toLocaleLowerCase() === userName.toLocaleLowerCase();

  return (
    <EmployeesCertificationsModal
      showModal={openModal}
      onCloseModal={handleCloseModal}
      title={t('certificationSignOff', { ns: 'certifications' })}
      maxWidth={'md'}
      height={'45vh'}
      backdropColor="transparent"
    >
      <Grid
        display={'flex'}
        justifyContent={'center'}
        sx={{ padding: '25px 60px 20px 60px' }}
        direction={'column'}
        gap={4}
        container
      >
        <Typography>
          {t('signOffMessage', {
            ns: 'certifications',
            username: userName,
            certificationName: certificationName
          })}
        </Typography>
        <Stack>
          <Typography style={{ color: '#BBBBBB', fontSize: '14px' }}>
            {t('digitalSignatureRequired', { ns: 'certifications' })}
            <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextBox
            id="name"
            name="name"
            placeholderText={t('certifyingEmployeeName', {
              ns: 'certifications'
            })}
            width="100%"
            height="40px"
            maxLength={300}
            variant="dark"
            value={inputUserName}
            onChangeValue={(value: any) => {
              setInputUserName(value);
            }}
            dataTestId="confirmationNameTestId"
            dataTrackId="confirmationNameTrackId"
          />
        </Stack>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          sx={{ paddingTop: '25px' }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <BasicButton
            color={saveEnabled ? 'primary' : 'grayButton'}
            style={{
              height: '45px',
              width: '88px',
              minWidth: '80px'
            }}
            data-testid="confirmModalSaveButtonTestId"
            data-trackid="confirmModalSaveButtonTrackId"
            onClick={handleSave}
            disable={!saveEnabled || savingInProgress}
          >
            {t('save', { ns: 'certifications' })}
          </BasicButton>
          <BasicButton
            color="primaryOutlined"
            style={{
              width: '88px',
              minWidth: '80px',
              height: '43px'
            }}
            disabled={savingInProgress}
            onClick={handleBack}
          >
            {t('back', { ns: 'certifications' })}
          </BasicButton>
        </Box>
      </Grid>
    </EmployeesCertificationsModal>
  );
};

export default AwardConfirmationModal;

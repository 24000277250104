import {
  AwaitableDialog,
  defaultTheme,
  DialogHeader,
  Tabs
} from '@buildwitt/component-library';
import {
  DialogTitle,
  Button,
  DialogContent,
  DialogActions
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import {
  AllowMultipleValueType,
  ContentSelectorView,
  SelectedContent
} from './types';
import { useTranslation } from 'react-i18next';

type SelectableContextType = {
  onSelect: (value: SelectedContent) => void;
  internalSelectionHash: Set<string>;
};
export const SelectableContext = React.createContext<SelectableContextType>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelect: () => {},
  internalSelectionHash: new Set<string>()
});

interface ContentSection<T> {
  component: React.ReactElement<ContentSelectorView<T>>;
  label: string;
}

type TiggerComponentParams<AllowMultiple extends boolean | undefined = false> =
  {
    onTrigger: () => void;
    value: AllowMultipleValueType<AllowMultiple>;
    onDelete: (deletedContent: SelectedContent) => void;
  };

export interface SelectContentModalProps<
  AllowMultiple extends boolean | undefined = false
> {
  title: string;
  triggerComponent: (
    params: TiggerComponentParams<AllowMultiple>
  ) => React.ReactNode;
  sections: ContentSection<unknown>[];
  value: AllowMultipleValueType<AllowMultiple>;
  onChange: (value: AllowMultipleValueType<AllowMultiple>) => void;
  multiple?: AllowMultiple;
}

export const SelectContentModal = <
  AllowNew extends boolean | undefined = false
>({
  title,
  sections,
  triggerComponent,
  value,
  onChange,
  multiple
}: SelectContentModalProps<AllowNew>) => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState(false);

  const memoizedInitialValueOnOpen: AllowMultipleValueType<AllowNew> = useMemo<
    AllowMultipleValueType<AllowNew>
  >(() => {
    if (isOpen) return value;
    return memoizedInitialValueOnOpen;
  }, [isOpen]);
  const toggleIsOpen = () => setIsOpen(prev => !prev);

  const selectionHash = useMemo(() => {
    if (Array.isArray(value)) {
      return value.reduce((prev, curr) => {
        prev.add(curr.id);
        return prev;
      }, new Set<string>());
    }
    const singleItemSet = new Set<string>();
    if (value) singleItemSet.add(value.id);
    return singleItemSet;
  }, [value]);

  const handleSelect = (selection: SelectedContent) => {
    if (multiple && Array.isArray(value)) {
      if (!selectionHash.has(selection.id)) {
        onChange([...value, selection] as AllowMultipleValueType<AllowNew>);
      } else
        onChange(
          value.filter(
            val => val.id !== selection.id
          ) as AllowMultipleValueType<AllowNew>
        );
    } else if (!Array.isArray(value)) {
      if (value && value.id === selection.id)
        onChange(undefined as AllowMultipleValueType<AllowNew>);
      else if (!value) {
        onChange(selection as AllowMultipleValueType<AllowNew>);
      }
    }
  };

  const handleCancel = () => {
    onChange(memoizedInitialValueOnOpen);
    toggleIsOpen();
  };

  const handleSingleItemDelete = (selection: SelectedContent) => {
    handleSelect(selection);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <AwaitableDialog
        isLoading={false}
        open={isOpen}
        fullWidth
        maxWidth={'lg'}
      >
        <DialogHeader onClose={handleCancel}>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <SelectableContext.Provider
            value={{
              onSelect: handleSelect,
              internalSelectionHash: selectionHash
            }}
          >
            {sections.length === 1 ? (
              sections[0].component
            ) : (
              <Tabs tabConfig={sections} />
            )}
          </SelectableContext.Provider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={toggleIsOpen}>{t('confirmSelection')}</Button>
        </DialogActions>
      </AwaitableDialog>
      {triggerComponent({
        onTrigger: toggleIsOpen,
        value,
        onDelete: handleSingleItemDelete
      })}
    </ThemeProvider>
  );
};

import DatePicker from 'react-datepicker';
import { useTheme } from '@mui/material/styles';
import { CSSProperties, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { IconButton, IconButtonProps, SxProps } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import React from 'react';

export interface DatePickerButtonProps {
  onChange?: (date: Date) => void;
  currentExpirationDate?: Date | null | undefined;
  disabled: boolean;
}

const ReactDatePickerCustomButton = (props: IconButtonProps) => {
  const theme = useTheme();
  const buttonStyle: SxProps = {
    height: '30px',
    borderRadius: '35px',
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.grey[50],
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 15
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      color: theme.palette.grey[100]
    }
  };
  return (
    <IconButton
      {...props}
      disabled={props.disabled}
      sx={buttonStyle}
      aria-label="completed"
      size="large"
      color="info"
    >
      <span style={editSpanStyle}>Edit</span>
      <CalendarTodayIcon sx={{ color: '#1B1C1C', fontSize: '18px' }} />
    </IconButton>
  );
};

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
  onChange,
  currentExpirationDate,
  disabled
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(
    currentExpirationDate
  );

  const handleOnChange = (date: Date) => {
    setSelectedDate(date);

    if (onChange) onChange(date);
  };

  return (
    <DatePicker
      disabled={disabled}
      customInput={<ReactDatePickerCustomButton disabled={disabled} />}
      minDate={currentExpirationDate}
      selected={selectedDate}
      onChange={handleOnChange}
      popperProps={{
        strategy: 'fixed'
      }}
    />
  );
};

const editSpanStyle: CSSProperties = {
  color: '#000000DE',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  marginRight: '10px'
};

import { CSSProperties } from 'react';

const footerContainerStyle: CSSProperties = {
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '35px'
};
export const Footer = () => (
  <footer className="footer navbar navbar-expand navbar-light topbar mb-4 static-top shadow bottomAppNavBar">
    <div className="container" style={footerContainerStyle}>
      <span className="nav-link" style={{ marginLeft: '10px' }}>
        &copy; {new Date().getFullYear()} BuildWitt
      </span>

      <a
        className="nav-link"
        href="https://www.buildwitt.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>

      <a
        className="nav-link"
        href="https://info.buildwitt.com/training-terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        Terms and conditions
      </a>

      <a
        className="nav-link"
        href="https://www.buildwitt.com/upload-policy"
        target="_blank"
        rel="noreferrer"
      >
        DMCA Policy
      </a>
    </div>
  </footer>
);

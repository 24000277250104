import { AppThunk } from '../store/index';
import { Assessment, AssessmentResult } from '../entities/Assessment';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  ASSESSMENT_SET_ASSESSMENT,
  ASSESSMENT_UPDATE_TRANSACTION_STATUS,
  ASSESSMENT_RESET_TRANSACTION_STATUS,
  ASSESSMENT_LIST_FAILED_QUIZ,
  ASSESSMENT_MARK_QUIZ_AS_FAILED,
  ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES,
  ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED
} from './actionTypes';
import config from './../config';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { handleGenericBackendError } from '../utils/errorHandling';

export interface RequestAssessmentAction {
  type: 'ASSESSMENT_SET_ASSESSMENT';
  assessment: Assessment | null;
}

export interface RequestFailedQuizAction {
  type: 'ASSESSMENT_LIST_FAILED_QUIZ';
  failedQuiz: string[] | null;
}

export interface RequestFailedAssignmentQuizzesAction {
  type: 'ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES';
  failedQuizzes: string[] | null;
}

export interface MarkQuizAsFailedAction {
  type: 'ASSESSMENT_MARK_QUIZ_AS_FAILED';
  failedQuiz: string;
}

export interface MarkQuizAssignmentAsFailedAction {
  type: 'ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED';
  failedQuiz: string;
}

export interface UpdateAssessmentTransactionStatusAction {
  type: 'ASSESSMENT_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetAssessmentTransactionStatusAction {
  type: 'ASSESSMENT_RESET_TRANSACTION_STATUS';
}

export const actionCreators = {
  requestAssessment:
    (lessonId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let assessment: Assessment | null = null;
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}assessments/lesson/${lessonId}`
          );

          assessment = res.status === HttpStatusEnum.OK ? res.data : null;
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: ASSESSMENT_SET_ASSESSMENT,
            assessment
          });
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  saveAssessmentResult:
    (assessmentResult: AssessmentResult): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let resStatus = -1;
        try {
          const res = await axios.post(
            `${config.ASSESSMENT_API_URL}assessments/lesson/results`,
            assessmentResult
          );

          resStatus = res.status;
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: ASSESSMENT_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  failedQuizzesResult:
    (assesmentIDS: string[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let failedQuiz: string[] | null = null;
        try {
          const response = await axios({
            method: 'post',
            url: `${config.ASSESSMENT_API_URL}assessments/results/failed-quizzes`,
            data: JSON.stringify(assesmentIDS.toString()),
            headers: {
              'Content-Type': `application/json`
            }
          });
          failedQuiz =
            response.status === HttpStatusEnum.OK ? response.data : null;
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: ASSESSMENT_LIST_FAILED_QUIZ,
            failedQuiz
          });
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  failedAssignmentQuizzesResult:
    (assesmentIDS: string[], assignmentIds: string[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let failedAssignmentQuizzes: string[] | null = null;
        try {
          const response = await axios({
            method: 'post',
            url: `${config.ASSESSMENT_API_URL}assessments/results/failed-quizzes-info`,
            data: JSON.stringify(assesmentIDS.toString()),
            headers: {
              'Content-Type': `application/json`
            }
          });

          const result =
            response.status === HttpStatusEnum.OK ? response.data : [];

          if (result.length > 0) {
            failedAssignmentQuizzes = result
              .filter((i: any) => assignmentIds.includes(i.assignmentId))
              .map((i: any) => i.assessmentId);
          }
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES,
            failedQuizzes: failedAssignmentQuizzes
          });
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  resetAssessmentTransactionStatus: () => ({
    type: ASSESSMENT_RESET_TRANSACTION_STATUS
  }),
  markQuizAsFailed: (quizId: string) => ({
    type: ASSESSMENT_MARK_QUIZ_AS_FAILED,
    failedQuiz: quizId
  }),
  markQuizAssignmentAsFailed: (quizId: string) => ({
    type: ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED,
    failedQuiz: quizId
  })
};

export type KnownAction =
  | SetLoadingAction
  | RequestAssessmentAction
  | UpdateAssessmentTransactionStatusAction
  | ResetAssessmentTransactionStatusAction
  | RequestFailedQuizAction
  | MarkQuizAsFailedAction
  | RequestFailedAssignmentQuizzesAction
  | MarkQuizAssignmentAsFailedAction;

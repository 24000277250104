import React, { SetStateAction, useState } from 'react';
import { Stack, SxProps, Typography, styled, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import TextBox from '../../../components/core/TextBox/TextBox';
import TagInput from '../../../components/TagInput/TagInput';
import TextArea from '../../../components/core/TextArea/TextArea';
import MultipleFilesInput from '../../../components/core/MultipleFilesInput/MultipleFilesInput';
import { useSkills } from '../../../hooks/useSkills';
import { CompetencePost, CompetencePut } from '../../../entities/Achievements';
import { CompetenceType } from '../../../core/enums';
import { lowerCaseFirstLetter } from '../../../utils/stringUtils';
import FormCheckBox from '../../../components/core/FormCheckBox/FormCheckBox';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { actionCreators } from '../../../actions/achievements';
import {
  SelectContentModal,
  SelectCourseView
} from '../../../components/SelectContent';
import { selectCoursesWithoutCompetence } from '../../../reducers/CoursesReducer';
import { TagInputTrigger } from '../../../components/SelectContent/TagInputTrigger';
import { SelectedContent } from '../../../components/SelectContent/types';
import { SelectLearningPlanView } from '../../../components/SelectContent/SelectLearningPlanView';
import { selectCompanyLearningPlans } from '../../../actions/learningPlans';
import { Tag } from '../../../entities/Tag';
import { useAppDispatch } from '../../../store/hooks';

interface SkillCertificateFormProps {
  competence: CompetencePost | CompetencePut | undefined;
  isSaving: boolean;
  setCompetence: (
    value: SetStateAction<CompetencePost | CompetencePut | undefined>
  ) => void;
  onSaveForm: () => void;
  isEdit: boolean;
}

interface SkillFormValidation {
  name: boolean;
  duration: boolean;
  generatePDF: boolean;
}

const CustomStack = styled(Stack)(() => ({
  width: '100%'
}));

const FieldErrorLabel = ({ show, text }: { show: boolean; text: string }) => {
  return show ? (
    <Typography variant="subtitle2" sx={{ color: '#FF0000' }}>
      {text}
    </Typography>
  ) : (
    <></>
  );
};

const SkillCertificateForm = ({
  competence,
  isSaving,
  setCompetence,
  onSaveForm,
  isEdit
}: SkillCertificateFormProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['skills', 'common']);
  const [formValidation, setFormValidation] = useState<SkillFormValidation>({
    name: false,
    duration: false,
    generatePDF: false
  });
  const {
    constants: {
      isCertificateOptions,
      generatePDFOptions,
      durationUnitOptions,
      durationOptions,
      notificationDaysOptions,
      employeeTags,
      onsiteTrainingTags
    }
  } = useSkills();
  const isCertificate =
    competence && competence.competenceType && competence.competenceType.length
      ? [competence.competenceType]
      : [];
  const generatePDF =
    competence && competence.generatePDF !== undefined
      ? [competence.generatePDF ? 'Yes' : 'No']
      : [];
  const durationUnit =
    competence && competence.durationUnit !== 'None'
      ? [competence.durationUnit]
      : [];
  const duration =
    competence && competence.duration > 0
      ? [competence.duration.toString()]
      : [];
  const maxAwardersAllowed = 5;

  const handleDropDownChange = (propertyName: string) => (items: string[]) =>
    updateCompetence(propertyName, items[0]);

  const handleTextBoxChange = (propertyName: string) => (value: any) =>
    updateCompetence(propertyName, value);

  const handleChangeGeneratePDF = (items: string[]) =>
    updateCompetence('generatePDF', items[0] === 'Yes');

  const updateCompetence = (propertyName: string, value: any) => {
    const _competence = { ...competence! };
    _competence[propertyName as keyof CompetencePost] = value as never;
    setCompetence(_competence);
  };

  const handleChangeCheckBoxNeverExpire = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setCompetence(current => ({
        ...current!,
        duration: 0,
        durationUnit: 'None',
        durationNeverExpires: checked,
        notifyDaysBeforeExpiration: ''
      }));
      return;
    }
    setCompetence(current => ({ ...current!, durationNeverExpires: checked }));
  };

  const handleChangeCheckBoxNotifications =
    (value: string) =>
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      let notificationDays = competence!.notifyDaysBeforeExpiration;
      if (checked)
        notificationDays += notificationDays.length ? `,${value}` : value;
      else {
        const daysAfterFiltering = notificationDays
          .split(',')
          .filter(day => day !== value);
        notificationDays = daysAfterFiltering.length
          ? daysAfterFiltering.join(',')
          : '';
      }
      setCompetence(current => ({
        ...current!,
        notifyDaysBeforeExpiration: notificationDays
      }));
    };

  const isNotificationCheckBoxSelected = (value: string) =>
    competence?.notifyDaysBeforeExpiration
      .split(',')
      .some(days => days === value);

  const handleAttachFiles = (files: File[]) => {
    setCompetence(current => ({
      ...current!,
      filesToAttach: current!.filesToAttach.concat(files)
    }));
  };

  const handleRemoveFile = (fileIndex: number) => {
    const fileName = competence!.filesToAttach[fileIndex].name;
    setCompetence(current => ({
      ...current!,
      filesToAttach: current!.filesToAttach.filter(
        file => file.name !== fileName
      )
    }));
  };

  const handleAddLearningUnit = (selection: SelectedContent) => {
    setCompetence(current => ({
      ...current!,
      learningUnitType: selection.type,
      learningUnitId: selection.id,
      learningUnitName: selection.label
    }));
  };

  const handleDeleteLearningUnit = () =>
    setCompetence(current => ({
      ...current!,
      learningUnitType: 'None',
      learningUnitId: null
    }));

  const handleLearningUnitChange = (selection?: SelectedContent) => {
    if (selection) handleAddLearningUnit(selection);
    else handleDeleteLearningUnit();
  };

  const handleOnsiteTrainingChange = (
    _: React.SyntheticEvent,
    value: Tag[]
  ) => {
    const onsiteTrainingIds = value.reduce((array, tag) => {
      array.push(tag.id);
      return array;
    }, new Array<string>());
    setCompetence(curr => ({
      ...curr!,
      onsiteTrainingsLinked: onsiteTrainingIds,
      onsiteTrainingToAssociate: value
    }));
  };

  const handleAwarderChange = (_: React.SyntheticEvent, value: Tag[]) => {
    if (competence!.awarders.length === maxAwardersAllowed) {
      enqueueSnackbar(
        t('reachedMaxNumberOfAwarders', {
          ns: 'skills',
          maxAwardersAllowed: maxAwardersAllowed
        }),
        { variant: 'warning', autoHideDuration: 3000 }
      );
      return;
    }

    setCompetence(current => ({
      ...current!,
      awarders: value.reduce((idArray, currentTag) => {
        idArray.push(currentTag.id);
        return idArray;
      }, [] as string[]),
      awarderTags: value
    }));
  };

  const initializeSaveForm = async () => {
    if (validateForm()) return;
    await onSaveForm();
    dispatch(actionCreators.getCompetenceDetail(competence!.id));
  };

  const validateForm = () => {
    const validationResult = {
      name: competence!.name.trim() === '',
      generatePDF:
        competence!.competenceType === CompetenceType.Certificate &&
        competence!.generatePDF === undefined,
      duration:
        !competence!.durationNeverExpires &&
        (!competence!.duration || !competence!.durationUnit.length)
    };
    setFormValidation(validationResult);
    return (
      validationResult.name ||
      validationResult.generatePDF ||
      validationResult.duration
    );
  };

  const fileTags =
    competence && competence.filesToAttach
      ? competence!.filesToAttach!.map(x => x.name)
      : [];

  return (
    <Stack spacing={2} sx={{ width: '100%' }} id="skillForm">
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t('isThisSkillACertificate', { ns: 'skills' })}
        </Typography>
        <BasicSelect
          labelId="isThisSkillACertificate"
          id="isThisSkillACertificate"
          options={isCertificateOptions}
          value={isCertificate}
          handleOnChange={handleDropDownChange('competenceType')}
          placeholder={t('select', { ns: 'common' })}
          theme="dark"
          multiple={false}
          sx={filterStyle}
          disabled={isEdit}
        />
      </CustomStack>
      {competence?.competenceType === CompetenceType.Certificate && (
        <CustomStack spacing={1}>
          <Typography variant="subtitle2">
            {t('generateCertificatePDF', { ns: 'skills' })}
          </Typography>
          <BasicSelect
            labelId="generateCertificatePDF"
            id="generateCertificatePDF"
            options={generatePDFOptions}
            value={generatePDF}
            handleOnChange={handleChangeGeneratePDF}
            placeholder={t('select', { ns: 'common' })}
            theme="dark"
            multiple={false}
            sx={filterStyle}
          />
          <FieldErrorLabel
            show={formValidation.generatePDF}
            text={t('mustSelectGeneratePDF', { ns: 'skills' })}
          />
        </CustomStack>
      )}
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t(
            `${lowerCaseFirstLetter(
              competence?.competenceType ?? 'skill'
            )}NameID`,
            { ns: 'skills' }
          )}
        </Typography>
        <TextBox
          id="competenceName"
          name="competenceName"
          placeholderText={t('nameHere', { ns: 'skills' })}
          width="100%"
          height="40px"
          maxLength={300}
          variant="dark"
          value={competence?.name ?? ''}
          onChangeValue={handleTextBoxChange('name')}
        />
        <FieldErrorLabel
          show={formValidation.name}
          text={t('nameIsRequired', { ns: 'skills' })}
        />
      </CustomStack>
      {competence?.competenceType === CompetenceType.Certificate && (
        <CustomStack spacing={1}>
          <Typography variant="subtitle2">
            {t('governingBody', { ns: 'skills' })}
          </Typography>
          <TextBox
            id="governingBody"
            name="governingBody"
            placeholderText={t('urlLink', { ns: 'skills' })}
            width="100%"
            height="40px"
            variant="dark"
            value={competence?.governingBody ?? ''}
            onChangeValue={handleTextBoxChange('governingBody')}
          />
        </CustomStack>
      )}
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t('linkedLPOrCourse', { ns: 'skills' })}
        </Typography>
        <SelectContentModal
          title={t('linkedLPOrCourse', { ns: 'skills' })}
          value={
            competence &&
            competence.learningUnitId &&
            competence.learningUnitName &&
            competence.learningUnitType
              ? {
                  id: competence.learningUnitId,
                  label: competence.learningUnitName,
                  type: competence.learningUnitType
                }
              : undefined
          }
          onChange={handleLearningUnitChange}
          sections={[
            {
              label: 'Learning Plans',
              component: (
                <SelectLearningPlanView selector={selectCompanyLearningPlans} />
              )
            },
            {
              label: 'Courses',
              component: (
                <SelectCourseView selector={selectCoursesWithoutCompetence} />
              )
            }
          ]}
          triggerComponent={({ value, ...params }) => (
            <TagInputTrigger {...params} tags={value} />
          )}
        />
      </CustomStack>
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t('linkedOnsiteTraining', { ns: 'skills' })}
        </Typography>
        <TagInput
          id="linkedOnsiteTraining"
          value={competence?.onsiteTrainingToAssociate ?? []}
          options={onsiteTrainingTags ?? []}
          onChange={handleOnsiteTrainingChange}
          placeholder={t('searchForAnOnsiteTraining', { ns: 'skills' })}
        />
      </CustomStack>
      {competence?.competenceType === CompetenceType.Certificate && (
        <FormControl>
          <Typography variant="subtitle2">
            {t('awarder', { ns: 'skills' })}
          </Typography>
          <TagInput
            disablePortal
            options={employeeTags ?? []}
            value={competence?.awarderTags ?? []}
            onChange={handleAwarderChange}
            placeholder={t('searchForUser', { ns: 'skills' })}
          />
        </FormControl>
      )}
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {t(
            `${lowerCaseFirstLetter(
              competence?.competenceType ?? 'skill'
            )}Duration`,
            { ns: 'skills' }
          )}
        </Typography>
        <Stack direction="row" spacing={{ md: 2 }} sx={{ flexWrap: 'wrap' }}>
          <BasicSelect
            labelId="durationUnit"
            id="durationUnit"
            options={durationUnitOptions}
            value={durationUnit}
            handleOnChange={handleDropDownChange('durationUnit')}
            placeholder={t('select', { ns: 'common' })}
            theme="dark"
            multiple={false}
            disabled={competence?.durationNeverExpires ?? false}
            sx={{ ...filterStyle, width: '140px' }}
          />
          <BasicSelect
            labelId="duration"
            id="duration"
            options={durationOptions}
            value={duration}
            handleOnChange={handleDropDownChange('duration')}
            placeholder={t('select', { ns: 'common' })}
            theme="dark"
            multiple={false}
            disabled={competence?.durationNeverExpires ?? false}
            sx={{ ...filterStyle, width: '140px' }}
          />
          <FormCheckBox
            id="durationNeverExpires"
            name="durationNeverExpires"
            checked={competence?.durationNeverExpires}
            onChange={handleChangeCheckBoxNeverExpire}
            label={t(
              `${lowerCaseFirstLetter(
                competence?.competenceType ?? 'skill'
              )}NeverExpires`,
              { ns: 'skills' }
            )}
          />
        </Stack>
        <FieldErrorLabel
          show={formValidation.duration}
          text={t('specifyDuration', { ns: 'skills' })}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography variant="subtitle2">
          {t('notifiedWhenExpiring', { ns: 'skills' })}
        </Typography>
        <Stack direction="row" spacing={{ md: 2 }} sx={{ flexWrap: 'wrap' }}>
          {notificationDaysOptions.map(days => (
            <FormCheckBox
              key={days}
              id={`notified${days}DaysBefore`}
              name={`notified${days}DaysBefore`}
              checked={isNotificationCheckBoxSelected(days)}
              onChange={handleChangeCheckBoxNotifications(days)}
              label={t(`${days}daysBefore`, { ns: 'skills' })}
              disabled={competence?.durationNeverExpires ?? false}
            />
          ))}
        </Stack>
      </Stack>
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t('attachFiles', { ns: 'skills' })}
        </Typography>
        <MultipleFilesInput
          variant="dark"
          validTypes="*.*"
          onFileAdded={(fileName, file) => {
            const exists = fileTags.find(f => f === fileName);

            if (exists === undefined) {
              handleAttachFiles([file]);
            }
          }}
          onRemoveSavedFile={handleRemoveFile}
          tags={fileTags}
          alternativeBehavior={true}
        />
      </CustomStack>
      <CustomStack spacing={1}>
        <Typography variant="subtitle2">
          {t('description', { ns: 'common' })}
        </Typography>
        <TextArea
          id="description"
          name="description"
          width="100%"
          rows={5}
          variant="dark"
          placeholderText={t('writeDescriptionHere', { ns: 'skills' })}
          value={competence?.description ?? ''}
          onChangeValue={handleTextBoxChange('description')}
        />
      </CustomStack>
      <Stack sx={{ alignItems: 'flex-end' }}>
        <BasicButton
          width="115px"
          height="42px"
          color="primary"
          onClick={initializeSaveForm}
          loading={isSaving}
        >
          {t('save', { ns: 'common' })}
        </BasicButton>
      </Stack>
    </Stack>
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000',
  maxWidth: '300px',
  width: '100%',
  height: '40px'
};

export default SkillCertificateForm;

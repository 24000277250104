import React, {
  useRef,
  useEffect,
  useState,
  CSSProperties,
  useMemo
} from 'react';
import ReactPlayer, { Config } from 'react-player';
import { Lesson } from '../../../entities/Lesson';
import { actionCreators } from '../../../actions/lessons';
import CircularProgress from '@mui/material/CircularProgress';
import { Subtitles } from '../../../entities/LessonFormModel';
import { LanguagesSubtitles } from '../../../core/constants';
import { TrackProps } from 'react-player/file';
import { matchYoutubeUrl } from '../../../utils/matchYoutubeUrl';
import VolumeControl from './videoSection/VolumeControl';
import PlayPauseControl from './videoSection/PlayPauseControl';
import ProgressBarControl from './videoSection/ProgressBarControl';
import SubtitlesControl from './videoSection/SubtitlesControl';
import PlaybackRateControl from './videoSection/PlaybackRateControl';
import VideoPlayerControls from './videoSection/VideoPlayerControls';
import AttachmentSection from './AttachmentSection';
import { Grid, IconButton, useMediaQuery } from '@mui/material';
import screenfull from 'screenfull';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../store/hooks';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';

interface VideoSectionProps {
  currentlesson: Lesson;
  onVideoEnded?: () => void;
  isPreviewMode?: boolean;
}

type VideoState = {
  url: string;
  pip: boolean;
  playing: boolean;
  controls: boolean;
  light: boolean;
  volume: number;
  muted: boolean;
  played: number;
  loaded: number;
  duration: number;
  playbackRate: number;
  loop: boolean;
  volumeOpen: boolean;
  dropdownOpen: boolean;
  fullscreen: boolean;
  seeking: boolean;
  isFullscreen: boolean;
};

const VideoSection: React.FC<VideoSectionProps> = ({
  currentlesson,
  onVideoEnded,
  isPreviewMode
}) => {
  const [state, setState] = useState<VideoState>({
    url: '',
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    volumeOpen: true,
    dropdownOpen: false,
    fullscreen: false,
    seeking: false,
    isFullscreen: false
  });

  const updateState = (
    update: Partial<VideoState> | ((prev: VideoState) => VideoState)
  ) => {
    if (update instanceof Function) {
      setState(update);
    } else {
      setState(prev => ({ ...prev, ...update }));
    }
  };

  const [showControls, setShowControls] = useState(false);
  const [valueProgress, setValueProgress] = useState('');
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const [videoUrl, setVideoUrl] = useState<string>('');
  const [isLoaded, setIsLoaded] = useState(true);
  const wasVideoCompletedRef = useRef<boolean>(false);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const countForControlsVisibility = useRef<number>(0);
  const [isLastTrackingPositionLoaded, setIsLastTrackingPositionLoaded] =
    useState(false);
  const [lastTrackingPosition, setLastTrackingPosition] = useState(0);
  const [playedProgress, setPlayedProgress] = useState<number>(0);
  const dispatch = useAppDispatch();
  const playedProgressRef = useRef<number>(0);
  const [videoTracks, setVideoTracks] = useState<TrackProps[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [langShow, setLangShow] = useState(false);
  const isYouTubeVideo = matchYoutubeUrl(currentlesson.externalVideoUrl);
  const { t } = useTranslation(['common']);
  const {
    variables: { mediaServiceMigration }
  } = useDevCycleSdk();
  const player = useRef<ReactPlayer>(null);

  const useHls = useMemo(
    () =>
      !!currentlesson.hlsUrl &&
      ((mediaServiceMigration && !currentlesson.externalVideoUrl) ||
        (!currentlesson.videoUrl && !currentlesson.externalVideoUrl)),
    [mediaServiceMigration]
  );

  useEffect(() => {
    currentlesson.subtitles && loadVideoTracks(currentlesson.subtitles);
    setIsLastTrackingPositionLoaded(false);
    setLastTrackingPosition(0);
    setIsLoaded(true);
    wasVideoCompletedRef.current = false;
    initPosition();

    const videoUrl = useHls ? currentlesson.hlsUrl : currentlesson.videoUrl;
    if (
      currentlesson &&
      (videoUrl.length || currentlesson.externalVideoUrl?.trim().length)
    ) {
      const url = currentlesson.externalVideoUrl?.trim().length
        ? currentlesson.externalVideoUrl
        : videoUrl;
      setVideoUrl(url);
    } else if (videoUrl.length) {
      setVideoUrl('');
    }

    return () => {
      // When the video was completed, we don't want to save last
      // tracking position since it should be restored to 0
      if (wasVideoCompletedRef.current) {
        return;
      }
      if (isPreviewMode) {
        return;
      }
      dispatch(
        actionCreators.savePositionTracking(
          currentlesson.videoId,
          Math.floor(playedProgressRef.current)
        )
      );
    };
  }, [currentlesson]);

  useEffect(() => {
    if (player?.current) {
      player.current.seekTo(lastTrackingPosition);
    }
  }, [lastTrackingPosition]);

  const initPosition = async () => {
    if (isYouTubeVideo) {
      setIsLastTrackingPositionLoaded(true);
      return;
    }

    setIsLastTrackingPositionLoaded(true);
    if (isPreviewMode) {
      setLastTrackingPosition(0 as unknown as number);
    } else {
      const lastRemoteTrackingPosition = await dispatch<Promise<number>>(
        actionCreators.requestPositionTracking(currentlesson.videoId)
      );
      setLastTrackingPosition(lastRemoteTrackingPosition as unknown as number);
    }
  };

  const {
    pip,
    playing,
    controls,
    seeking,
    volumeOpen,
    volume,
    muted,
    loop,
    playbackRate,
    played,
    duration,
    dropdownOpen,
    isFullscreen
  } = state;

  useEffect(() => {
    playedProgressRef.current = playedProgress;
  }, [playedProgress]);

  const handlePlayerReady = (player: ReactPlayer) => {
    if (useHls) {
      if (videoTracks.length) {
        const video = document.querySelector('video');
        for (const videoTrack of videoTracks) {
          const newTrack = document.createElement('track');
          newTrack.label = videoTrack.label;
          newTrack.kind = videoTrack.kind;
          newTrack.srclang = videoTrack.srcLang;
          newTrack.src = videoTrack.src;
          newTrack.default = videoTrack.default!;
          video?.appendChild(newTrack);
        }

        const browserLanguage = getSelectedLanguageByBrowser();
        changeLanguage(browserLanguage);
      }

      const hlsPlayer = player.getInternalPlayer('hls');
      if (hlsPlayer) {
        const filteredLevels = hlsPlayer.levels
          .filter((level: any) => level.bitrate <= 2000000)
          .sort((level1: any, level2: any) =>
            level1.bitrate < level2.bitrate ? 1 : -1
          );

        if (filteredLevels.length) {
          const levelIndex = hlsPlayer.levels
            .map((level: any) => level.bitrate)
            .indexOf(filteredLevels[0].bitrate);
          hlsPlayer.currentLevel = levelIndex;
        }
      }
    }

    handlePlayPause();
  };

  const handlePlayPause = () => {
    updateState({ playing: !playing });
    countForControlsVisibility.current = 0;
  };

  const loadVideoTracks = (subtitles: Subtitles[]) => {
    const tracks: TrackProps[] = [];
    const browserLanguage = getSelectedLanguageByBrowser();
    if (subtitles) {
      subtitles.forEach(s => {
        const srcLang = LanguagesSubtitles.find(x => x.label === s.language);
        const srcLangValue = srcLang ? srcLang.srcLang : '';
        tracks.push({
          kind: 'subtitles',
          src: s.url!,
          srcLang: srcLangValue,
          label: s.language,
          default: s.language.toLowerCase() === browserLanguage.toLowerCase()
        });
      });
      setVideoTracks(tracks);
      const selectedLanguageLoaded = tracks.find(x => x.default);
      if (selectedLanguageLoaded) {
        setSelectedLanguage(selectedLanguageLoaded.label);
      }
    }
  };

  const getSelectedLanguageByBrowser = () => {
    const langItem = LanguagesSubtitles.find(x =>
      navigator.language.toLowerCase().startsWith(x.srcLang.toLowerCase())
    );
    return langItem ? langItem.label : '';
  };

  const handleVolumeChange = (e: React.ChangeEvent) => {
    const { target } = e;
    updateState({
      volume: parseFloat((target as HTMLInputElement).value)
    });
    const value: string = (target as HTMLInputElement).value;
    const style: CSSStyleDeclaration = (target as HTMLInputElement).style;
    style.backgroundSize = parseFloat(value) * 100 + '% 100%';
  };

  const handleSetPlaybackRate = (e: React.MouseEvent) => {
    const { target } = e;
    updateState({
      playbackRate: parseFloat((target as HTMLButtonElement).value),
      dropdownOpen: !dropdownOpen
    });
  };

  const handlePlay = () => {
    if (!playing) {
      updateState({ playing: true });
    }
  };

  const handleEnablePIP = () => {
    updateState({ pip: true });
  };

  const handleDisablePIP = () => {
    updateState({ pip: false });
  };

  const handlePause = () => {
    updateState({ playing: false });
  };

  const handleSeekMouseDown = (event: React.MouseEvent) => {
    const { target } = event;
    updateState({ seeking: false });
    const playedPosition = played * 100;
    const isRewinding = currentProgress > playedPosition;
    if (player?.current === undefined) {
      return;
    }
    if (isRewinding) {
      player?.current?.seekTo(parseFloat((target as HTMLInputElement).value));
    }
  };

  const handleSeekChange = (event: React.ChangeEvent) => {
    const { target } = event;
    const value: string = (target as HTMLInputElement).value;
    const style: CSSStyleDeclaration = (target as HTMLInputElement).style;
    updateState({ played: parseFloat(value) });
    style.backgroundSize = parseFloat(value) * 100 + '% 100%';
  };

  const handleSeekMouseUp = (event: React.MouseEvent) => {
    const { target } = event;
    updateState({ seeking: false });
    const playedPosition = played * 100;
    const isRewinding = currentProgress > playedPosition;
    if (player?.current === undefined) {
      return;
    }
    if (isRewinding) {
      player?.current?.seekTo(parseFloat((target as HTMLInputElement).value));
    }
  };

  const handleProgress = (stateIn: {
    played: number;
    playedSeconds: number;
  }) => {
    if (!seeking) {
      updateState({ ...stateIn });
      const progress = stateIn.played * 100 + '% 100%';
      setValueProgress(progress);
      setCurrentProgress(stateIn.played * 100);
    }
    !wasVideoCompletedRef.current && setPlayedProgress(stateIn.playedSeconds);

    if (countForControlsVisibility.current > 2) {
      if (controlsRef?.current) {
        controlsRef.current.style.visibility = 'hidden';
      }
      countForControlsVisibility.current = 0;
    }
    if (controlsRef?.current) {
      const visible = controlsRef.current.style.visibility;
      if (visible === 'visible') {
        countForControlsVisibility.current += 1;
      }
    }
  };

  const handleEnded = () => {
    onOffFullScreen();
    setPlayedProgress(0);
    if (!isPreviewMode) {
      dispatch(actionCreators.savePositionTracking(currentlesson.videoId, 0));
    }
    wasVideoCompletedRef.current = true;
    updateState({ playing: false });
    if (onVideoEnded) onVideoEnded();
  };

  const handleDuration = (duration: number) => {
    updateState({ duration });
  };

  const toggleVolume = () => {
    updateState({ volumeOpen: !volumeOpen });
  };

  const handleStart = () => {
    setShowControls(true);
    setIsLoaded(false);
  };

  const handleSwitchLanguage = (e: React.MouseEvent) => {
    const { target } = e;
    const selected = (target as HTMLButtonElement).value;
    setSelectedLanguage(selected);
    changeLanguage(selected);
    setLangShow(!langShow);
  };

  const changeLanguage = (languageLabel: string) => {
    const video = document.querySelector('video');
    if (video) {
      const ytracks = video.textTracks;
      for (let i = 0, L = ytracks.length; i < L; i++) {
        if (ytracks[i].label.toLowerCase() === languageLabel.toLowerCase()) {
          ytracks[i].mode = 'showing';
        } else {
          ytracks[i].mode = 'hidden';
        }
      }
    }
  };

  function isMobileSafari() {
    if (typeof window !== 'undefined') {
      return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    }
  }
  const createConfig = (tracks: TrackProps[], muted = false): Config => ({
    file: {
      attributes: {
        autoPlay: true,
        crossOrigin: 'anonymous'
      },
      tracks: useHls ? [] : tracks,
      forceHLS: useHls && !isMobileSafari(),
      forceVideo: true,
      hlsOptions: {
        backBufferLength: 90
      }
    },
    youtube: {
      playerVars: {
        autoplay: 1
      }
    }
  });

  const isVideoReady =
    isYouTubeVideo || (videoUrl.length && isLastTrackingPositionLoaded);
  const isVideoReadyAndStarted = isVideoReady && !isLoaded;

  const handleError = (e: any): void => {
    if (e && e.name === 'NotAllowedError') {
      setShowControls(true);
      updateState({ playing: false });
      setIsLoaded(false);
    }
    console.error(e);
  };

  const isMdScreen = useMediaQuery('(max-width:990px)');
  const isSmScreen = useMediaQuery('(max-width:640px)');

  const toggleFullScreen = () => {
    screenfull.toggle(videoContainerRef.current!);
    updateState(prev => ({ ...prev, isFullscreen: !prev.isFullscreen }));
  };

  const onOffFullScreen = () => {
    if (state.isFullscreen) {
      screenfull.toggle(videoContainerRef.current!);
      updateState({ isFullscreen: false });
      return;
    }
    updateState(prev => ({ ...prev, isFullscreen: !prev.isFullscreen }));
  };

  useEffect(() => {
    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        updateState({ isFullscreen: false });
      }
    };
    document.addEventListener('fullscreenchange', handleFullScreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleMouseMove = () => {
    if (controlsRef?.current) {
      controlsRef.current.style.visibility = 'visible';
    }
    countForControlsVisibility.current = 0;
  };

  const hanldeMouseLeave = () => {
    if (playing) {
      if (controlsRef?.current) {
        controlsRef.current.style.visibility = 'hidden';
      }
      countForControlsVisibility.current = 0;
    }
  };

  const stylevideoSection: CSSProperties = {
    position: 'relative',
    marginLeft: isSmScreen ? '-5px' : '0px',
    marginRight: isSmScreen ? '-5px' : '0px'
  };

  return (
    <div className="App App-header">
      <div>
        {isVideoReady ? (
          <div
            className="Test"
            ref={videoContainerRef}
            style={stylevideoSection}
            onMouseMove={handleMouseMove}
            onMouseLeave={hanldeMouseLeave}
          >
            <ReactPlayer
              width="100%"
              height={isMdScreen || isFullscreen ? '100%' : '650px'}
              url={videoUrl}
              ref={player}
              pip={pip}
              playing={playing}
              controls={controls}
              loop={loop}
              playbackRate={playbackRate}
              stopOnUnmount={true}
              volume={volume}
              muted={muted}
              onStart={handleStart}
              onPlay={handlePlay}
              onEnablePIP={handleEnablePIP}
              onDisablePIP={handleDisablePIP}
              onPause={handlePause}
              onEnded={handleEnded}
              onError={e => handleError(e)}
              onProgress={handleProgress}
              onDuration={handleDuration}
              onReady={handlePlayerReady}
              config={createConfig(videoTracks, muted)}
              playsinline={true}
              data-testid="ReactPlayerTestId"
            />
            <div
              ref={controlsRef}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                visibility: 'visible'
              }}
            >
              <VideoPlayerControls visible_button_refresh={showControls}>
                <Grid
                  gap={isMdScreen ? 0 : 1}
                  direction={'row'}
                  display={'flex'}
                >
                  <PlayPauseControl
                    playing={playing}
                    handlePlayPause={handlePlayPause}
                  />
                  <VolumeControl
                    volumeOpen={volumeOpen}
                    toggleVolume={toggleVolume}
                    handleVolumeChange={handleVolumeChange}
                    volume={volume}
                  />
                </Grid>
                <ProgressBarControl
                  valueProgress={valueProgress}
                  played={played}
                  duration={duration}
                  handleSeekMouseDown={handleSeekMouseDown}
                  handleSeekChange={handleSeekChange}
                  handleSeekMouseUp={handleSeekMouseUp}
                />
                <div style={rightControlStyle}>
                  {!isYouTubeVideo && (
                    <SubtitlesControl
                      langShow={langShow}
                      setLangShow={setLangShow}
                      videoTracks={videoTracks}
                      selectedLanguage={selectedLanguage}
                      handleSwitchLanguage={handleSwitchLanguage}
                    />
                  )}
                  <PlaybackRateControl
                    playbackRate={playbackRate}
                    handleSetPlaybackRate={handleSetPlaybackRate}
                  />
                </div>
                <IconButton onClick={toggleFullScreen}>
                  <FullscreenIcon
                    fontSize={!isSmScreen ? 'large' : 'small'}
                    style={{
                      color: '#fff',
                      marginTop: isSmScreen ? '5px' : '0px'
                    }}
                  />
                </IconButton>
              </VideoPlayerControls>
            </div>
            {!isVideoReadyAndStarted && (
              <CircularProgress style={styleCirProgress} />
            )}
          </div>
        ) : (
          <div style={noVideoWrapper}>
            <h3>{t('noVideoMessage', { ns: 'common' })}</h3>
          </div>
        )}

        <AttachmentSection currentLesson={currentlesson} />
      </div>
    </div>
  );
};

const styleCirProgress: CSSProperties = {
  position: 'absolute',
  top: '45%',
  left: '45%',
  bottom: 0
};

const noVideoWrapper: CSSProperties = {
  height: '600px',
  color: 'orange'
};

const rightControlStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
};

export default VideoSection;

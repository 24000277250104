import { PropsWithChildren } from 'react';
import Card from '@mui/material/Card';
import { SxProps, Theme } from '@mui/material';

export interface EmptyCardProps {
  width?: string;
  height?: string;
}

const EmptyCard: React.FC<PropsWithChildren<EmptyCardProps>> = ({
  children,
  width = '379px',
  height = '275px'
}) => {
  const cardStyle: SxProps<Theme> = theme => ({
    borderRadius: '14px',
    borderColor: `${theme.palette.grey['100']}`,
    borderStyle: 'solid',
    borderWidth: '1px',
    backgroundColor: `${theme.palette.secondary.main} !important`,
    position: 'relative',
    padding: '0.5rem 0.5rem',
    width: width,
    height: height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
    flexDirection: 'column'
  });

  return <Card sx={cardStyle}>{children}</Card>;
};

export default EmptyCard;

import { defaultTheme } from '@buildwitt/component-library';
import {
  Box,
  Card,
  CardMedia,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import BackgroundCoursePattern from '../../../assets/BackgroundCoursePattern.svg';
import { OnsiteTrainingDetailsName } from './OnsiteTrainingDetailsName';
import { OnsiteTrainingDetailsDescription } from './OnsiteTrainingDetailsDescription';
import { OnsiteTrainingDetailsAttachments } from './OnsiteTrainingDetailsAttachments';
import {
  actionButtonsContainer,
  cardContainer,
  title
} from './OnsiteTrainingDetailsStyles';
import { OnsiteTrainingDetailsBanner } from './OnsiteTrainingDetailsBanner';
import { useEffect } from 'react';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { OnsiteTrainingClassDetailsLocationAndDates } from './OnsiteTrainingClassDetailsLocationAndDates';
import { OnsiteTrainingClassDetailsBottomActions } from './OnsiteTrainingClassDetailsBottomActions';
import { useAppDispatch } from '../../../store/hooks';

export const OnsiteTrainingClassDetails = () => {
  const { onsiteTrainingClassId } = useParams<any>();
  const dispatch = useAppDispatch();

  const assignedOnsiteTrainingClass = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingClass
  );

  const { t } = useTranslation(['onsiteTraining', 'common']);
  const thumbnail = assignedOnsiteTrainingClass?.onsiteTrainingClass
    .onsiteTraining?.thumbnail
    ? assignedOnsiteTrainingClass?.onsiteTrainingClass.onsiteTraining?.thumbnail
    : BackgroundCoursePattern;

  useEffect(() => {
    dispatch(
      onsiteTrainingActions.retrieveSingleOnsiteTrainingClass(
        onsiteTrainingClassId!
      )
    );
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        container
        direction="column"
        style={{
          marginLeft: '-24px',
          marginRight: '-24px',
          width: 'calc(100% + 48px)'
        }}
        display={'flex'}
      >
        <OnsiteTrainingDetailsBanner includePreview={false} />
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
          {assignedOnsiteTrainingClass?.onsiteTrainingClass.onsiteTraining ? (
            <Card
              style={cardContainer}
              sx={{
                width: {
                  xs: 300,
                  sm: 300,
                  md: 650,
                  lg: 950,
                  xl: 1050
                }
              }}
            >
              <Box sx={actionButtonsContainer}>
                <Typography sx={title}>
                  {t('onsiteTraining', { ns: 'onsiteTraining' })}
                </Typography>
              </Box>
              <CardMedia
                component="img"
                height="305px"
                image={thumbnail}
                alt="onsite training"
              />
              <OnsiteTrainingDetailsName
                onsiteTraining={
                  assignedOnsiteTrainingClass?.onsiteTrainingClass
                    .onsiteTraining
                }
                includePreviewActions={false}
              />
              <OnsiteTrainingClassDetailsLocationAndDates
                onsiteTrainingClass={assignedOnsiteTrainingClass}
              />
              <OnsiteTrainingDetailsDescription
                description={
                  assignedOnsiteTrainingClass?.onsiteTrainingClass
                    .onsiteTraining?.description
                }
              />
              {assignedOnsiteTrainingClass?.onsiteTrainingClass.onsiteTraining
                ?.attachments && (
                <OnsiteTrainingDetailsAttachments
                  attachments={
                    assignedOnsiteTrainingClass?.onsiteTrainingClass
                      .onsiteTraining?.attachments
                  }
                />
              )}
              <OnsiteTrainingClassDetailsBottomActions
                onsiteTrainingClass={assignedOnsiteTrainingClass}
              />
            </Card>
          ) : (
            <Grid
              style={{ height: '400px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

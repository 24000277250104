import React from 'react';
import { Guid } from 'guid-typescript';
import { useSelector } from 'react-redux';
import { TransferProgressEvent } from '@azure/core-http';
import { useNavigate } from 'react-router-dom';

import { actionCreators } from '../../actions/lessons';
import { LessonQuiz } from '../../core/constants';
import { ContentMedium, LessonFormModel } from '../../entities/LessonFormModel';
import { getCall } from '../../services/rest-service';
import config from '../../config';
import {
  checkUploadSubtitle,
  uploadLargeFileToBlob
} from '../../services/blobStorage-service';
import { AssetInfo } from '../../entities/assetInfo';
import { actionCreators as tagActions } from '../../actions/tags';
import { CreateLessonDto } from '../../entities/Dto/createLessonDto';
import HttpStatusError from '../../core/CustomErrors/HttpRequestError';
import { useTranslation } from 'react-i18next';
import { actionCreators as appActions } from '../../actions/appState';
import { ApplicationState } from '../../store';
import { actionCreators as courseActions } from '../../actions/courses';
import { useParams } from 'react-router';
import { useLogEvent } from '../../hooks/useLogEvent';
import { Question } from '../../entities/Assessment';
import { UpdateLessonDto } from '../../entities/Dto/UpdateLessonDto';
import { getYoutubeVideoDuration } from '../../utils/matchYoutubeUrl';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';

export const useLesson = (isInCourses: boolean) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const { t } = useTranslation(['common', 'lessons', 'quizzes']);
  const params: any = useParams();
  const [createError, setCreateError] = React.useState<string>('');
  const [uploadVideoProgress, setUploadVideoProgress] =
    React.useState<number>(0);
  const [refresh, setRefresh] = React.useState(0);
  const doRefresh = () => setRefresh(current => current + 1);

  const [showAssignLessonModal, setShowAssignLessonModal] =
    React.useState<boolean>(false);

  const courseLessonNames = useSelector(
    (state: ApplicationState) => state.courses?.courseLessonNames
  );
  const user = useCurrentUser();
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const isPartnerCompany = partnerCompanies?.some(
    company => company.id === companyId
  );
  const {
    variables: { mediaServiceMigration }
  } = useDevCycleSdk();

  const handleUploadProgress = (
    progress: TransferProgressEvent,
    file: File
  ) => {
    const fileTotalBytes = file?.size;
    const valueProgress = Math.floor(
      (progress.loadedBytes / fileTotalBytes!) * 100
    );
    setUploadVideoProgress(valueProgress);
  };

  const getStorageAccountName = () => {
    return mediaServiceMigration
      ? config.MEDIA_SERVICE_BLOB_STORAGE_ACCOUNT_NAME
      : config.BLOB_STORAGE_ACCOUNT_NAME;
  };

  const getStorageAccountSasToken = () => {
    return mediaServiceMigration
      ? config.MEDIA_SERVICE_BLOB_SAS_TOKEN
      : config.BLOB_SAS_TOKEN;
  };

  const handleSaveLesson = async (
    lessonData: LessonFormModel,
    isEdit = true,
    courseId?: string
  ) => {
    setCreateError('');
    if (lessonData) {
      if (lessonData.contentMedium === ContentMedium.Quiz && !isEdit) {
        dispatch(
          actionCreators.setCurrentLesson({
            assessmentId: Guid.EMPTY,
            id: '',
            title: lessonData.title,
            description: '',
            order: 0,
            companyId: '',
            courseId: '',
            contentMedium: lessonData.contentMedium,
            videoUrl: '',
            audioUrl: '',
            videoId: '',
            thumbnailUrl: lessonData.thumbnailUrl,
            learningMaterialUrl: '',
            externalVideoUrl: '',
            lessonType: LessonQuiz,
            tags: [],
            subtitles: [],
            isVideoEncoding: false,
            isEncodingFailed: false,
            lessonContentType: 0,
            attachments: [],
            audioDuration: '',
            audioId: '',
            visibleForAdminsOnly: false,
            lessonDuration: lessonData.lessonDuration,
            lessonCode: lessonData.lessonCode,
            hlsUrl: '',
            dashUrl: ''
          })
        );
        return;
      }

      let assetName = '';
      let youtubeVideoDuration = '';
      setUploadVideoProgress(1);
      if (!lessonData.externalVideoUrl && lessonData.video) {
        const assetUrl = await getCall(
          `${config.COURSES_API_URL!}lessons/asset?name=${
            'vid_' + Guid.create()
          }`
        );

        const assetInfo: AssetInfo = assetUrl.data;
        assetName = assetInfo.assetName;
        await uploadLargeFileToBlob(
          lessonData.video,
          assetInfo.containerName,
          (progress: TransferProgressEvent) =>
            handleUploadProgress(progress, lessonData.video!),
          getStorageAccountName(),
          getStorageAccountSasToken()
        ); //upload video file
      } else {
        const youtubeMetadata = await getYoutubeVideoDuration(
          lessonData.youTubeVideoId!
        );
        if (youtubeMetadata.items && youtubeMetadata.items.length > 0) {
          youtubeVideoDuration =
            youtubeMetadata.items[0].contentDetails.duration;
        }
      }

      // Audio process
      if (
        lessonData.contentMedium === ContentMedium.Audio &&
        !isEdit &&
        lessonData.audioFile
      ) {
        const assetUrl = await getCall(
          `${config.COURSES_API_URL!}lessons/asset?name=${
            'aud_' + Guid.create()
          }`
        );

        const assetInfo: AssetInfo = assetUrl.data;
        assetName = assetInfo.assetName;
        await uploadLargeFileToBlob(
          lessonData.audioFile,
          assetInfo.containerName,
          (progress: TransferProgressEvent) =>
            handleUploadProgress(progress, lessonData.audioFile!),
          getStorageAccountName(),
          getStorageAccountSasToken()
        );
      }

      const lessonDto: CreateLessonDto = {
        title: lessonData.title,
        description: lessonData.description,
        order: 0,
        externalVideoUrl: lessonData.externalVideoUrl,
        tags: lessonData.tags,
        companyId: '',
        contentMedium: lessonData.contentMedium,
        thumbnailUrl: lessonData.thumbnailUrl,
        documentUrl: '',
        lessonFileName: lessonData?.video?.name ?? '',
        lessonFileContentType: '',
        assetName: assetName,
        assessmentId: lessonData.assessmentId,
        subtitles: [],
        youtubeVideoDuration: youtubeVideoDuration,
        attachments: [],
        deletedAttachments: [],
        visibleForAdminsOnly: lessonData.visibleForAdminsOnly,
        isPartnerCompany: isPartnerCompany!,
        lessonDuration: lessonData.lessonDuration,
        lessonCode: lessonData.lessonCode
      };

      if (lessonData.subtitles) {
        const checkupload = await checkUploadSubtitle(lessonData.subtitles);
        lessonDto.subtitles = checkupload;
      }

      try {
        let createdLessonId = '';
        if (isInCourses) {
          createdLessonId = await dispatch<Promise<string>>(
            actionCreators.saveLessonForCourse(
              lessonDto,
              lessonData.thumbnailFile!,
              lessonData.lessonFile!,
              params.id ?? courseId,
              lessonData.attachments,
              lessonData.audioFile!
            )
          );
        } else {
          createdLessonId = (await dispatch<Promise<string>>(
            actionCreators.saveLesson(
              lessonDto,
              lessonData.thumbnailFile!,
              lessonData.lessonFile!,
              lessonData.attachments,
              lessonData.audioFile!
            )
          )) as unknown as string;
        }

        await dispatch<Promise<void>>(tagActions.requestTags());

        if (isInCourses) {
          await dispatch<Promise<void>>(
            courseActions.requestCourseLessonNames(params.id ?? courseId)
          );
        } else {
          await dispatch<Promise<void>>(actionCreators.requestCompanyLessons());
        }

        await dispatch<Promise<void>>(actionCreators.requestCompanyLessons());

        logEvent.logCreateLessonEvent(createdLessonId, lessonData.title);
        return createdLessonId;
      } catch (e) {
        if (!(e instanceof HttpStatusError)) {
          const error = e as any;
          const errorMessage = !error.response
            ? t('networkError', { ns: 'common' })
            : t('anErrorOccurred', { ns: 'common' });
          setCreateError(errorMessage);
        }
        throw e;
      }
    }
  };

  const handleUpdateLesson = async (
    lessonData: LessonFormModel,
    isEdit = true
  ) => {
    try {
      if (lessonData) {
        let assetName = '';
        let youtubeVideoDuration = '';
        setUploadVideoProgress(1);
        if (!lessonData.externalVideoUrl && lessonData.video) {
          const assetUrl = await getCall(
            `${config.COURSES_API_URL!}lessons/asset?name=${
              'vid_' + Guid.create()
            }`
          );
          const assetInfo: AssetInfo = assetUrl.data;
          assetName = assetInfo.assetName;
          await uploadLargeFileToBlob(
            lessonData.video,
            assetInfo.containerName,
            (progress: TransferProgressEvent) =>
              handleUploadProgress(progress, lessonData.video!),
            getStorageAccountName(),
            getStorageAccountSasToken()
          ); //upload video file
        } else {
          const youtubeMetadata = await getYoutubeVideoDuration(
            lessonData.youTubeVideoId!
          );
          if (youtubeMetadata.items && youtubeMetadata.items.length > 0) {
            youtubeVideoDuration =
              youtubeMetadata.items[0].contentDetails.duration;
          }
        }

        // Audio Process
        if (
          lessonData.contentMedium === ContentMedium.Audio &&
          lessonData.audioFile
        ) {
          const assetUrl = await getCall(
            `${config.COURSES_API_URL!}lessons/asset?name=${
              'aud_' + Guid.create()
            }`
          );
          const assetInfo: AssetInfo = assetUrl.data;
          assetName = assetInfo.assetName;
          await uploadLargeFileToBlob(
            lessonData.audioFile,
            assetInfo.containerName,
            (progress: TransferProgressEvent) =>
              handleUploadProgress(progress, lessonData.audioFile!),
            getStorageAccountName(),
            getStorageAccountSasToken()
          );
        }
        const lessonDto: UpdateLessonDto = {
          id: lessonData.id,
          title: lessonData.title,
          description: lessonData.description,
          thumbnailUrl: lessonData.thumbnailUrl,
          externalVideoUrl: lessonData.externalVideoUrl,
          tags: lessonData.tags,
          order: 0,
          companyId: '',
          contentMedium: lessonData.contentMedium,
          documentUrl: '',
          lessonFileName: lessonData?.video?.name ?? '',
          lessonFileContentType: '',
          assetName: assetName,
          assessmentId: lessonData.assessmentId,
          subtitles: [],
          youtubeVideoDuration: youtubeVideoDuration,
          attachments: [],
          deletedAttachments: lessonData.deletedAttachments,
          visibleForAdminsOnly: lessonData.visibleForAdminsOnly,
          lessonDuration: lessonData.lessonDuration,
          lessonCode: lessonData.lessonCode
        };

        if (lessonData.subtitles) {
          const checkupload = await checkUploadSubtitle(lessonData.subtitles);
          lessonDto.subtitles = checkupload;
        }
        await dispatch<Promise<void>>(
          actionCreators.updateLesson(
            lessonDto,
            lessonData.thumbnailFile!,
            lessonData.lessonFile!,
            lessonData.attachments,
            lessonData.audioFile!
          )
        );
      }
    } catch (e) {
      if (!(e instanceof HttpStatusError)) {
        const error = e as any;
        const errorMessage = !error.response
          ? t('networkError', { ns: 'common' })
          : t('anErrorOccurred', { ns: 'common' });
        setCreateError(errorMessage);
      }
      throw e;
    }
  };

  const handleSaveLessonAssessment = async (
    questions: Question[],
    correctAnswers: number,
    lessonId: string,
    isEditing: boolean,
    updateAssessmentId: boolean,
    assessmentId?: string
  ) => {
    const questionsDto: Question[] = questions.map(question => ({
      questionText: question.questionText,
      trueIsCorrectAnswer: question.trueIsCorrectAnswer,
      type: question.type,
      id: question.id,
      imageFile: question.imageFile,
      imageUrl: question.imageUrl,
      options: question.options,
      numberCorrectAnswer: question.numberCorrectAnswer
    }));

    try {
      await dispatch<Promise<void>>(
        actionCreators.saveAssessment(
          questionsDto,
          lessonId,
          isEditing,
          assessmentId ?? null,
          correctAnswers,
          updateAssessmentId
        )
      );
    } catch (e) {
      if (!(e instanceof HttpStatusError)) {
        const error = e as any;
        const errorMessage = !error.response
          ? t('networkError', { ns: 'common' })
          : t('anErrorOccurred', { ns: 'common' });
        setCreateError(errorMessage);
      }
      throw e;
    }
  };

  const handleEditTrainingLesson = (lessonId: string, courseId?: string) => {
    let dataFetched = false;
    dispatch(appActions.setIsLoading(true));

    try {
      if (isInCourses) {
        dispatch(actionCreators.requestLessonByEntryId(lessonId));
      } else {
        dispatch(actionCreators.requestLessonById(lessonId));
      }

      dispatch(appActions.setIsLoading(false));

      if (isInCourses) {
        if (
          courseLessonNames &&
          courseLessonNames?.filter(x => x.isEncoding && x.id == lessonId)
            .length > 0
        ) {
          enqueueSnackbar(t('lessonVideoIsEncoding', { ns: 'lessons' }), {
            variant: 'warning'
          });
        } else {
          dataFetched = true;
        }
      } else {
        dataFetched = true;
      }
    } catch (e) {
      enqueueSnackbar(t('fechtLessonForEditingErrorMsg', { ns: 'lessons' }), {
        variant: 'error'
      });
    }

    if (dataFetched) {
      navigate(`/content-library/lessons/${lessonId}/edit`, {
        state: {
          isEditing: true,
          isInCourses,
          courseId
        }
      });
    }
  };

  const handleOpenAssignLessonModal = async (lessonId: string) => {
    dispatch(appActions.setIsLoading(true));
    await dispatch<Promise<void>>(actionCreators.requestLessonById(lessonId));

    dispatch(appActions.setIsLoading(false));
    setShowAssignLessonModal(true);
  };

  const handleCloseAssignLessonModal = () => {
    dispatch(actionCreators.setCurrentLesson(null));
    setShowAssignLessonModal(false);
    doRefresh();
  };

  return {
    api: {
      handleSaveLesson,
      handleOpenAssignLessonModal,
      handleCloseAssignLessonModal,
      handleEditTrainingLesson,
      handleSaveLessonAssessment,
      handleUpdateLesson
    },
    state: {
      uploadVideoProgress,
      showAssignLessonModal,
      createError,
      refresh
    }
  };
};

import axios from 'axios';
import {
  AssignEmployeesToOnsiteTrainingDto,
  AssignOnsiteTrainingDto,
  OnsiteTraining,
  OnsiteTrainingClass,
  OnsiteTrainingClassPut,
  OnsiteTrainingClassAssignmentsPerDayDto
} from '../entities/OnsiteTraining';
import config from '../config';
import {
  ONSITE_TRAININGS_CLEAR_ITEMS,
  ONSITE_TRAININGS_LOAD_PAGE,
  ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING,
  ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS,
  ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS,
  ONSITE_TRAININGS_RESET_TRANSACTION_STATUS,
  ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS,
  ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  ONSITE_TRAININGS_SET_CLASSES,
  ONSITE_TRAININGS_LOAD_ALL,
  ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES,
  ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY
} from './actionTypes';
import { PagedResult } from '../entities/Common';
import {
  OnsiteTrainingPagingQueryParameters,
  pagingToQueryParams
} from '../entities/OnsiteTrainingPagingQueryParameters';
import {
  uploadOnsiteTrainingAttachmentToBlob,
  uploadOnsiteTrainingImagesToBlob
} from '../services/blobStorage-service';
import { Guid } from 'guid-typescript';
import { HttpStatusEnum, TransactionStatusEnum } from '../core/enums';
import {
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition,
  OnsiteTrainingEmployeeAssignmentStatus
} from '../entities/Assignment';
import { AppThunk } from '../store';
import { AssignedOnsiteTrainingClass } from '../entities/LearningPlaylist';
import { handleGenericBackendError } from '../utils/errorHandling';

export const actionCreators = {
  loadOnsiteTrainings:
    (
      pagingOptions: OnsiteTrainingPagingQueryParameters
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}onsite-trainings?${pagingToQueryParams(
              pagingOptions
            )}`
          );

          dispatch({
            type: ONSITE_TRAININGS_LOAD_PAGE,
            onsiteTrainings: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  resetOnsiteTrainings:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: ONSITE_TRAININGS_CLEAR_ITEMS
        });
      }
    },
  createOnsiteTraining:
    (onsiteTraining: OnsiteTraining): AppThunk<Promise<void>> =>
    async (_, getState) => {
      const appState = getState();
      if (appState) {
        try {
          if (onsiteTraining.filesToAttach) {
            for (const file of onsiteTraining.filesToAttach) {
              const fileUrl = await uploadOnsiteTrainingAttachmentToBlob(file);
              onsiteTraining.attachments.push({
                id: Guid.EMPTY,
                fileName: file.name,
                fileUrl: fileUrl
              });
            }
          }

          if (onsiteTraining.thumbnailFile) {
            const imageUrl = await uploadOnsiteTrainingImagesToBlob(
              onsiteTraining.thumbnailFile
            );
            onsiteTraining.thumbnail = imageUrl;
          }

          await axios.post(
            `${config.COURSES_API_URL}onsite-trainings`,
            onsiteTraining
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  resetTransactionStatus: () => ({
    type: ONSITE_TRAININGS_RESET_TRANSACTION_STATUS
  }),
  setStatusOfEmployeesOnsiteTrainingAssigments: (
    assignmentStatus: EmployeeAssignmentStatus[] | null
  ) => ({
    type: ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS,
    onsiteTrainingAssignmentStatus: assignmentStatus
  }),
  setOnsiteTrainingAssignmentDefinitions: (
    definitions: EmployeeGroupAssignmentDefinition[] | null
  ) => ({
    type: ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
    groupAssignmentDefinitions: definitions
  }),
  requestStatusOfEmployeesOnsiteTrainingAssigments:
    (onsiteTrainingId: string, onsiteTrainingClassId?: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/onsiteTraining/${onsiteTrainingId}/status/${
              onsiteTrainingClassId || ''
            }`
          );

          dispatch({
            type: ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS,
            onsiteTrainingAssignmentStatus: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  editOnsiteTraining:
    (onsiteTraining: OnsiteTraining): AppThunk<Promise<void>> =>
    async (_, getState) => {
      const appState = getState();
      if (appState) {
        try {
          if (onsiteTraining.filesToAttach) {
            for (const file of onsiteTraining.filesToAttach) {
              const fileUrl = await uploadOnsiteTrainingAttachmentToBlob(file);
              onsiteTraining.attachments.push({
                id: Guid.EMPTY,
                fileName: file.name,
                fileUrl: fileUrl
              });
            }
          }

          if (onsiteTraining.thumbnailFile) {
            const imageUrl = await uploadOnsiteTrainingImagesToBlob(
              onsiteTraining.thumbnailFile
            );
            onsiteTraining.thumbnail = imageUrl;
          }

          await axios.put(
            `${config.COURSES_API_URL}onsite-trainings/${onsiteTraining.id}`,
            onsiteTraining
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  cloneOnsiteTraining:
    (id: string): AppThunk<Promise<OnsiteTraining>> =>
    async (_, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const { data } = await axios.post(
            `${config.COURSES_API_URL}onsite-trainings/${id}`
          );
          return data;
        } catch (e) {
          handleGenericBackendError(e);
        }
      }

      return null;
    },
  retrieveSingleOnsiteTraining:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.post(
            `${config.COURSES_API_URL}onsite-trainings/${true}`,
            [id]
          );
          dispatch({
            type: ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING,
            onsiteTraining: res.data[0]
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  retrieveSingleOnsiteTrainingClass:
    (onsiteTrainingClassId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeplaylist/onsiteTrainingClass/${onsiteTrainingClassId}`
          );
          dispatch({
            type: ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS,
            onsiteTrainingClass: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  assignOnsiteTraining:
    (onsiteTrainingAssignment: AssignOnsiteTrainingDto): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let httpStatus = 0;
        let resMessage = '';
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}assignments/onsiteTraining/assign`,
            onsiteTrainingAssignment
          );
          httpStatus = res.status;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const errorData = e.response?.data as {
              Data: { id: string };
            };
            if (errorData.Data && errorData.Data.id) {
              resMessage = errorData.Data.id;
            }
          }
        } finally {
          dispatch({
            type: ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              httpStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: resMessage
          });
        }

        return { status: httpStatus, message: resMessage };
      }
    },

  addEmployeesToOnsiteTraining:
    (onsiteTrainingAssignment: AssignEmployeesToOnsiteTrainingDto): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let httpStatus = 0;
        let resMessage = '';
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}assignments/onsiteTraining/add`,
            onsiteTrainingAssignment
          );
          httpStatus = res.status;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const errorData = e.response?.data as {
              Data: { id: string };
            };
            if (errorData.Data.id) {
              resMessage = errorData.Data.id;
            }
          }
        } finally {
          dispatch({
            type: ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              httpStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: resMessage
          });
        }

        return { status: httpStatus, message: resMessage };
      }
    },
  deleteOnsiteTraining:
    (onsiteTrainingId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.delete(
            `${config.COURSES_API_URL}onsite-trainings/${onsiteTrainingId}`
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  loadOnsiteTrainingClasses:
    (
      page: number,
      pageSize: number,
      searchKey: string,
      sortKey: string,
      filterKey: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}onsite-training-class?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&sortKey=${sortKey}&filterKey=${filterKey}`
          );

          dispatch({
            type: ONSITE_TRAININGS_SET_CLASSES,
            onsiteTrainingClasses: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setOnsiteTrainingClasses: (
    onsiteTrainingClasses: PagedResult<OnsiteTrainingClass> | null
  ) => ({
    type: ONSITE_TRAININGS_SET_CLASSES,
    onsiteTrainingClasses: onsiteTrainingClasses
  }),
  loadAllOnsiteTrainings: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      try {
        const res = await axios.get(
          `${config.COURSES_API_URL}onsite-trainings`
        );
        const dataResult: PagedResult<OnsiteTraining> = res.data;
        dispatch({
          type: ONSITE_TRAININGS_LOAD_ALL,
          onsiteTrainingsAll: dataResult.pageItems
        });
      } catch (e) {
        handleGenericBackendError(e);
      }
    }
  },
  resetAllOnsiteTrainings: () => ({
    type: ONSITE_TRAININGS_LOAD_ALL,
    onsiteTrainingsAll: null
  }),
  editOnsiteTrainingClass:
    (onsiteTrainingClass: OnsiteTrainingClassPut): AppThunk<Promise<void>> =>
    async (_, getState) => {
      const appState = getState();
      if (appState) {
        try {
          await axios.put(
            `${config.STUDENT_API_URL}onsite-training-class`,
            onsiteTrainingClass
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  forceRefreshOnsiteTrainingClasses: (force: boolean) => ({
    type: ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES,
    forceRefreshScheduleOnsiteTrainingClasses: force
  }),
  retreiveAllClassAssignmentsPerDay:
    (
      onsiteTraningClassId: string,
      onsiteTrainingClassDay: number,
      page: number,
      searchKey: string | null
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const pageSize = 20;
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeplaylist/onsiteTrainingClass/assignments/${onsiteTraningClassId}/${onsiteTrainingClassDay}/${page}/${pageSize}/${
              searchKey ?? ''
            }`
          );
          dispatch({
            type: ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY,
            onsiteTrainingClassAssignmentsPerDay: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setAllClassAssignmentsPerDay: (
    data: PagedResult<OnsiteTrainingClassAssignmentsPerDayDto>
  ) => ({
    type: ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY,
    onsiteTrainingClassAssignmentsPerDay: data
  }),
  resetAllClassAssignmentsPerDay: () => ({
    type: ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY,
    onsiteTrainingClassAssignmentsPerDay: null
  }),
  setCheckInToClassAssignment:
    (
      onsiteTrainingAssignmentId: string,
      instructorId: string,
      attendance: number,
      signature: string | undefined
    ): AppThunk<Promise<void>> =>
    async () => {
      try {
        await axios.patch(
          `${config.STUDENT_API_URL}assignments/onsiteTraining/${onsiteTrainingAssignmentId}`,
          {
            attendance: attendance,
            adminLeadingId: instructorId,
            signature: signature
          }
        );
      } catch (e) {
        handleGenericBackendError(e);
      }
    },
  resetSingleOnsiteTrainingClass: () => ({
    type: ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS,
    onsiteTrainingClass: null
  })
};

export interface LoadOnsiteTrainingsPage {
  type: 'ONSITE_TRAININGS_LOAD_PAGE';
  onsiteTrainings: PagedResult<OnsiteTraining> | null;
}

export interface ClearOnsiteTrainingItems {
  type: 'ONSITE_TRAININGS_CLEAR_ITEMS';
}

export interface DeleteOnsiteTraining {
  type: 'ONSITE_TRAINING_DELETE_SITE';
}
export interface LoadSingleOnsiteTraining {
  type: 'ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING';
  onsiteTraining: OnsiteTraining | null;
}
export interface LoadSingleOnsiteTrainingClass {
  type: 'ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS';
  onsiteTrainingClass: AssignedOnsiteTrainingClass | null;
}
export interface SetCurrentOnsiteTraining {
  type: 'ONSITE_TRAININGS_SET_CURRENT';
  currentTraining: OnsiteTraining | null | undefined;
}
export interface UpdateOnsiteTransactionStatusAction {
  type: 'ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}
export interface SetOnsiteTrainingAssignmentStatusAction {
  type: 'ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS';
  onsiteTrainingAssignmentStatus:
    | OnsiteTrainingEmployeeAssignmentStatus[]
    | null;
}
export interface SetOnsiteTrainingGroupAssignmentDefinitions {
  type: 'ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS';
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
}

export interface LoadSingleOnsiteTrainingClasses {
  type: 'ONSITE_TRAININGS_SET_CLASSES';
  onsiteTrainingClasses: PagedResult<OnsiteTrainingClass> | null;
}

export interface SetAllOnsiteTrainings {
  type: 'ONSITE_TRAININGS_LOAD_ALL';
  onsiteTrainingsAll: OnsiteTraining[] | null;
}

export interface ForceRefreshOnsiteTrainingClasses {
  type: 'ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES';
  forceRefreshScheduleOnsiteTrainingClasses: boolean;
}

export interface SetAllClassAssignmentsPerDay {
  type: 'ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY';
  onsiteTrainingClassAssignmentsPerDay: PagedResult<OnsiteTrainingClassAssignmentsPerDayDto> | null;
}

export type KnownAction =
  | LoadOnsiteTrainingsPage
  | LoadSingleOnsiteTrainingClass
  | ClearOnsiteTrainingItems
  | LoadSingleOnsiteTraining
  | SetCurrentOnsiteTraining
  | UpdateOnsiteTransactionStatusAction
  | SetOnsiteTrainingAssignmentStatusAction
  | SetOnsiteTrainingGroupAssignmentDefinitions
  | DeleteOnsiteTraining
  | LoadSingleOnsiteTrainingClasses
  | SetAllOnsiteTrainings
  | ForceRefreshOnsiteTrainingClasses
  | SetAllClassAssignmentsPerDay;

import React, { CSSProperties } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  SxProps,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { OnsiteTraining } from '../../entities/OnsiteTraining';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';

interface OnsiteTrainingCardBaseProps {
  onsiteTraining: OnsiteTraining;
  onShowPreview?: (item: OnsiteTraining) => void;
  contentOverThumbnail?: JSX.Element;
  extraContent?: JSX.Element;
  cardActions?: JSX.Element;
  containerStyles?: CSSProperties;
}

const OnsiteTrainingCardBase: React.FC<OnsiteTrainingCardBaseProps> = ({
  onsiteTraining,
  onShowPreview,
  contentOverThumbnail,
  extraContent,
  cardActions,
  containerStyles
}) => {
  const { t } = useTranslation(['courses', 'onsiteTraining']);
  const thumbnail = onsiteTraining.thumbnail
    ? onsiteTraining.thumbnail
    : BackgroundCoursePattern;

  const handleClickPreview = () => {
    if (onShowPreview) {
      onShowPreview(onsiteTraining);
    }
  };

  return (
    <Card style={{ ...cardContainer, ...containerStyles }}>
      <Box sx={actionButtonsContainer}>
        <Typography sx={title}>
          {t('onsiteTraining', { ns: 'onsiteTraining' })}
        </Typography>
        {contentOverThumbnail}
      </Box>
      <CardMedia
        onClick={handleClickPreview}
        component="img"
        height="150px"
        width="100%"
        image={thumbnail}
        alt={t('onsiteTraining', { ns: 'onsiteTraining' })}
      />
      <CardContent sx={{ padding: 0, paddingLeft: '12px' }}>
        <Typography fontSize={20} fontWeight={600}>
          {onsiteTraining.name}
        </Typography>
        <Typography>{extraContent}</Typography>
      </CardContent>
      <CardActions>{cardActions}</CardActions>
    </Card>
  );
};

const cardContainer: CSSProperties = {
  minWidth: 200,
  minHeight: 275,
  borderRadius: '5px',
  position: 'relative',
  display: 'grid'
};

const actionButtonsContainer: SxProps = {
  position: 'absolute',
  display: 'flex',
  gap: '7px',
  margin: '12px',
  width: '95%',
  justifyContent: 'space-between'
};

const title: SxProps = {
  fontSize: '12px',
  background: '#1b1c1c',
  padding: '0px 10px',
  borderRadius: '22px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default OnsiteTrainingCardBase;

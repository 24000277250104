import { Course } from '../entities/Course';
import { LearningPlanEntry } from '../entities/LearningPlan';

export const orderCourses = (
  useOrder: boolean,
  entries: LearningPlanEntry[],
  data: Course[]
) => {
  if (!useOrder || entries.length === 0) return data;
  const newList: Course[] = [];
  const entriesCopy = [...entries].sort((lp1, lp2) => lp1.order - lp2.order);
  entriesCopy.forEach(x => {
    const course = data.find(c => c.id === x.courseId);
    if (course) newList.push(course);
  });
  return newList;
};

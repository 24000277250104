import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';

export interface TextBoxProps {
  value: string;
  onChangeValue?: (value: any) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width: string;
  height: string;
  dataTestId?: string;
  dataTrackId?: string;
  isInvalid?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  isPassword?: boolean;
  isNumber?: boolean;
  disabled?: boolean;
  customStyle?: CSSProperties;
  variant?: 'dark' | 'light' | undefined;
  useMaxLength?: boolean;
  isTypeNumber?: boolean;
  minNumericValue?: number;
  maxNumericValue?: number;
  allowEmptyNumber?: boolean;
}
const TextBox: React.FC<TextBoxProps> = React.forwardRef<
  HTMLInputElement,
  TextBoxProps
>(
  (
    {
      id,
      name,
      value,
      onChangeValue,
      placeholderText,
      width,
      height,
      isInvalid = false,
      onBlur,
      maxLength = 90,
      isPassword = false,
      isNumber = false,
      disabled = false,
      customStyle = {},
      variant = 'light',
      dataTestId = '',
      dataTrackId,
      useMaxLength = true,
      isTypeNumber = false,
      allowEmptyNumber = true,
      minNumericValue,
      maxNumericValue
    },
    ref
  ) => {
    React.useEffect(() => {
      if (value !== undefined) {
        setInputValue(value);
      }
    }, [value]);
    const [inputValue, setInputValue] = React.useState(value);
    const theme = useTheme();
    const preStyle: CSSProperties = {
      padding: '5px',
      borderRadius: '5px',
      outline: 'none',
      paddingLeft: '8px',
      fontFamily: theme.typography.fontFamily,
      height: height,
      borderWidth: isInvalid ? 1 : 0,
      borderStyle: isInvalid ? 'solid' : 'none',
      width: width
    };

    const lightStyle: CSSProperties = {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.main,
      borderColor: isInvalid
        ? theme.palette.error.main
        : theme.palette.primary.contrastText
    };

    const darkStyle: CSSProperties = {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: isInvalid
        ? theme.palette.error.main
        : theme.palette.grey[100]
    };

    const variantStyle = variant === 'light' ? lightStyle : darkStyle;
    const inputType = (() => {
      return isPassword ? 'password' : isTypeNumber ? 'number' : 'text';
    })();

    const inputStyle: CSSProperties = {
      ...preStyle,
      ...variantStyle,
      ...customStyle
    };

    const isOnlyNumbersOrEmpty = (value: string) => {
      return new RegExp(/(^[0-9]*\.{0,1}[0-9]*$)|^0?\.?[0-9]*$/).test(value);
    };

    const validRangeAccepted = (value: string) => {
      if (!allowEmptyNumber) {
        if (!value) return false;
      }
      return (
        value.length <= maxLength &&
        Number(value) >= minNumericValue! &&
        Number(value) <= maxNumericValue! &&
        value != 'e'
      );
    };

    const shouldUpdateValue = (value: string) => {
      let result = true;
      result = isNumber
        ? isOnlyNumbersOrEmpty(value) && validRangeAccepted(value)
        : result;
      return result;
    };

    const handleChange = (e: any) => {
      if (shouldUpdateValue(e.target.value)) {
        setInputValue(e.target.value);
        if (onChangeValue) {
          onChangeValue(e.target.value);
        }
      }
    };

    return (
      <input
        type={inputType}
        width={width}
        height={height}
        id={id}
        name={name}
        value={inputValue}
        onChange={e => handleChange(e)}
        style={inputStyle}
        placeholder={placeholderText}
        onBlur={onBlur}
        maxLength={useMaxLength ? maxLength : undefined}
        disabled={disabled}
        min={minNumericValue}
        max={maxNumericValue}
        data-testid={dataTestId}
        data-trackid={dataTrackId}
        ref={ref}
      />
    );
  }
);

export default TextBox;

import React from 'react';
import { PropsWithChildren } from 'react';

import usePermissions from '../../../hooks/usePermissions';

interface ProtectedComponentProps {
  action: string;
}

export const ProtectedComponent: React.FC<
  React.PropsWithChildren<ProtectedComponentProps>
> = ({ action, children }: PropsWithChildren<ProtectedComponentProps>) => {
  const hasPermission = usePermissions();

  const canDoAction = hasPermission(action);
  return <>{canDoAction && children}</>;
};

export default ProtectedComponent;

import React from 'react';
import { SxProps } from '@mui/material/styles';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import {
  monthsOptions,
  daysOptions,
  yearOptions
} from '../../../core/constants';

export interface SelectDurationProps {
  selectValue: string;
  durationSelected: string;
  onChange: (items: string[]) => void;
}

const NumberSelect: React.FC<SelectDurationProps> = ({
  onChange,
  selectValue,
  durationSelected
}) => {
  const value = selectValue ? [selectValue] : [];
  let numberOptions = monthsOptions;

  if (durationSelected === 'days') {
    numberOptions = daysOptions;
  } else if (durationSelected === 'years') {
    numberOptions = yearOptions;
  }

  return (
    <BasicSelect
      labelId="skillsCategoriesFilter"
      id="skillsCategoriesFilter"
      options={numberOptions}
      value={value}
      handleOnChange={onChange}
      theme="dark"
      multiple={false}
      sx={filterStyle}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '80px',
  height: '45px'
};

export default NumberSelect;

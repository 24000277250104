import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AssignmentTypes } from '../../entities/LearningPlaylist';
import { EnrollmentTypes } from '../../core/constants';
import { isOverdue } from '../../utils/dateUtils';
import AllAssignmentsTabContainer from './AssignmentsTabContainer';
import AssignmentCardList from './AssignmentCardList';

export interface AllAssignmentsTabProps {
  learningUnits: Array<AssignmentTypes>;
  assignmentTypeFilter: string;
}

const AllAssignmentsTab: React.FC<AllAssignmentsTabProps> = ({
  learningUnits,
  assignmentTypeFilter
}) => {
  const [allLearningUnits, setAllLearningUnits] =
    useState<Array<AssignmentTypes>>();
  const [learningUnitsToShow, setAllLearningUnitsToShow] =
    useState<Array<AssignmentTypes>>();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    if (learningUnits) {
      const duedates = learningUnits.filter(
        learningUnit => learningUnit.assignmentType === EnrollmentTypes.DueDate
      );
      let overdues = duedates.filter(
        learningUnit =>
          isOverdue(moment(learningUnit.dueDateMoment!)) &&
          !learningUnit.isComplete
      );

      overdues = overdues.sort((a, b) =>
        a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );

      let dueDatesRemaining = duedates.filter(
        learningUnit =>
          !isOverdue(moment(learningUnit.dueDateMoment!)) ||
          (isOverdue(moment(learningUnit.dueDateMoment!)) &&
            learningUnit.isComplete)
      );

      dueDatesRemaining = dueDatesRemaining.sort((a, b) =>
        a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );

      let learningUnitsRemaining = learningUnits.filter(
        learningUnit => learningUnit.assignmentType !== EnrollmentTypes.DueDate
      );
      learningUnitsRemaining = learningUnitsRemaining.sort((a, b) =>
        a.createdTimestampUtc > b.createdTimestampUtc ? 1 : -1
      );

      const mixedLearningUnits = overdues
        .concat(dueDatesRemaining)
        .concat(learningUnitsRemaining);
      setAllLearningUnits(mixedLearningUnits);
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        mixedLearningUnits
      );
      setAllLearningUnitsToShow(filteredLearningUnits);
    }
  }, [learningUnits]);

  useEffect(() => {
    if (allLearningUnits) {
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        allLearningUnits
      );
      setAllLearningUnitsToShow(filteredLearningUnits);
    }
  }, [assignmentTypeFilter]);

  const filterByAssignmentType = (
    assignmentType: string,
    source: Array<AssignmentTypes>
  ) => {
    return source.filter(
      learningUnit =>
        learningUnit.assignmentType === assignmentType || assignmentType === ''
    );
  };

  return (
    <AllAssignmentsTabContainer
      isLoading={!learningUnitsToShow}
      isEmpty={!!allLearningUnits && !allLearningUnits.length}
      listIsReady={!!learningUnitsToShow && !!learningUnitsToShow.length}
      emptyText={t('youDontHaveAssignments', { ns: 'assignments' })}
    >
      <AssignmentCardList assignedLearningUnits={learningUnitsToShow!} />
    </AllAssignmentsTabContainer>
  );
};

export default AllAssignmentsTab;

import useCurrentUser from './useCurrentUser';

export default () => {
  const user = useCurrentUser();

  const userHasRole = (roleName: string | string[]) => {
    let values = [];
    if (Array.isArray(user?.profile['BuildWitt.User.Role'])) {
      values = user!.profile['BuildWitt.User.Role']!;
    } else {
      values = [user!.profile['BuildWitt.User.Role']!];
    }

    if (Array.isArray(roleName)) {
      return values.some(value => roleName.includes(value));
    }
    return values.includes(roleName);
  };

  return [userHasRole];
};

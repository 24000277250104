import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { SubmitHandler } from 'react-hook-form';
import { AxiosError } from 'axios';

import ModalComponent from '../../components/core/ModalPage/ModalComponent';
import CertificationForm from './CertificationForm';
import { UserClaims } from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as certificationActions } from '../../actions/certifications';
import { CertificationPostPut } from '../../entities/Certification';
import { useAppDispatch } from '../../store/hooks';

interface CreateCertificatonProps {
  closeModal: () => void;
}

const CreateCertificaton = ({ closeModal }: CreateCertificatonProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const newCertification: CertificationPostPut = {
    id: '',
    name: '',
    awardersTags: [],
    duration: '',
    durationUnit: '',
    durationNeverExpires: false,
    notifyDaysBeforeExpirationTags: [],
    thirdPartyCertificateRequired: false,
    description: '',
    awarders: [],
    certifyingBody: ''
  };

  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
  }, []);

  const handleOnSubmit: SubmitHandler<CertificationPostPut> = async data => {
    setSavingInProgress(true);
    try {
      await certificationActions.createCertification(data);
      dispatch(certificationActions.refetchCertifications(true));
      enqueueSnackbar(t('certificationCreated', { ns: 'certifications' }), {
        variant: 'success'
      });
      closeModal();
    } catch (e) {
      const axiosError = e as AxiosError;
      let errorDetail = '';
      if (axiosError) {
        const errorData = axiosError.response?.data as any;
        errorDetail = errorData?.detail ?? '';
      }

      enqueueSnackbar(
        errorDetail.length
          ? errorDetail
          : t('errorCreatingCertification', { ns: 'certifications' }),
        { variant: 'error' }
      );
    } finally {
      setSavingInProgress(false);
    }
  };

  const handleSaveNewCertification = () => {
    submitButtonRef.current?.click();
  };

  return (
    <ModalComponent
      showModal={true}
      title={t('newCertification', { ns: 'certifications' })}
      savingInProgress={savingInProgress}
      maxWidth="md"
      saveButtonText={t('save', { ns: 'common' })}
      showCloseIcon={false}
      showCancelButton={true}
      closeWhenClickBackdrop={false}
      closeWhenPressingEscape={false}
      onSave={handleSaveNewCertification}
      onClose={closeModal}
    >
      <CertificationForm
        certification={newCertification}
        onSubmit={handleOnSubmit}
        ref={submitButtonRef}
      />
    </ModalComponent>
  );
};

export default CreateCertificaton;

import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NotificationPanel } from '@buildwitt/component-library';

import { ApplicationState } from '../../store';
import { NotificationStatus } from '../../core/constants';
import { actionCreators as notificationsActions } from '../../actions/notifications';
import NotificationItem from './NotificationItem';
import NotificationsIcon from '../../assets/notifications.svg';
import NewNotificationsIcon from '../../assets/new-notifications.svg';
import { useAppDispatch } from '../../store/hooks';

const Notifications: React.FC = () => {
  const { t } = useTranslation(['notifications']);
  const dispatch = useAppDispatch();
  const notifications = useSelector((state: ApplicationState) =>
    state.notifications?.notifications?.filter(
      n => n.status !== NotificationStatus.Dismissed
    )
  );

  const hasNewNotifications =
    notifications?.some(
      n =>
        n.status === NotificationStatus.New ||
        n.status === NotificationStatus.Sent
    ) || false;

  const handleDismissNotification = (notificationId: string) => {
    dispatch(notificationsActions.dismissNotification(notificationId));
  };

  const handleMarkUpToDateNotifications = () => {
    dispatch(notificationsActions.markUpToDateNotifications());
  };

  return (
    <NotificationPanel
      hasNewNotifications={hasNewNotifications}
      notificationsImage={NotificationsIcon}
      newNotificationsImage={NewNotificationsIcon}
      onMarkUpToDateNotifications={handleMarkUpToDateNotifications}
    >
      {!!notifications && (
        <div style={popoverStyle}>
          {notifications?.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <NotificationItem
                onDismissNotification={handleDismissNotification}
                notification={notification}
              />

              {index !== notifications.length - 1 && (
                <hr style={divisionStyle} />
              )}
            </React.Fragment>
          ))}
          {notifications?.length === 0 && (
            <div>{t('noNewNotifications', { ns: 'notifications' })}</div>
          )}
        </div>
      )}
    </NotificationPanel>
  );
};

const popoverStyle: CSSProperties = {
  maxHeight: '500px',
  overflowY: 'auto',
  backgroundColor: '#1d1d1e'
};

const divisionStyle: CSSProperties = {
  margin: '0 0.5rem'
};

export default Notifications;

import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import CompletedList from './CompletedList';
import { useAnalyticsModal } from '../../../hooks/useAnalyticsModal';

interface CompletedModalProps {
  learningUnitId?: string;
  learningUnitType: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onCloseModal: () => void;
}

const CompletedModal = ({
  learningUnitId,
  learningUnitType,
  includeSelfAssignments,
  filterOption,
  onCloseModal
}: CompletedModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { completedData, sortOptions, openModal },
    api: { closeModal, handleSorting }
  } = useAnalyticsModal(
    'completed',
    learningUnitType,
    includeSelfAssignments,
    filterOption,
    learningUnitId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <AssignmentStatusModal
      showModal={openModal}
      title={t(`${learningUnitType}Completion`)}
      sortOptions={sortOptions}
      onClose={handleCloseModal}
      onChangeSortOption={handleSorting}
    >
      <CompletedList completedData={completedData} />
    </AssignmentStatusModal>
  );
};

export default CompletedModal;

import Grid from '@mui/material/Grid';
import { t } from 'i18next';
import { useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { Lesson } from '../../../entities/Lesson';
import LessonCardLoader from '../../LessonCardLoader/LessonCardLoader';
import { NewLessonType } from '../../../entities/LessonFormModel';

interface VideoEncodingInfoProps {
  lesson: Lesson;
}

const VideoEncodingInfo: React.FC<VideoEncodingInfoProps> = ({ lesson }) => {
  const theme = useTheme();

  const loadingTextStyle: CSSProperties = {
    color: `${theme.palette.grey['500']}`,
    paddingBottom: '5px'
  };

  return (
    <>
      {lesson.isVideoEncoding ? (
        <>
          <Grid item>
            {lesson.lessonContentType === NewLessonType.Video ? (
              <span style={loadingTextStyle}>
                {t('videoIsEncoding', { ns: 'common' })}
              </span>
            ) : (
              <span style={loadingTextStyle}>
                {t('audioIsEncoding', { ns: 'common' })}
              </span>
            )}
          </Grid>
          <Grid item>
            <LessonCardLoader variant="linear" />
          </Grid>
        </>
      ) : (
        <>
          {lesson.isEncodingFailed && (
            <span style={loadingTextStyle}>
              {t('videoEncodingFailed', { ns: 'common' })}.
            </span>
          )}
        </>
      )}
    </>
  );
};

export default VideoEncodingInfo;

import React, { CSSProperties } from 'react';
import ListEmployee from './ListEmployees';
import Panel from '../../components/core/Panel/Panel';
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';
import { useTranslation } from 'react-i18next';
import DashboardWidget from './DashboardWidget';
import { useSelector } from 'react-redux';
import { actionCreators as studentActions } from '../../actions/student';
import { ApplicationState } from '../../store';
import { DashboardTopEmployees } from '../../entities/Dto/Dashboard';
import { useAppDispatch } from '../../store/hooks';

const topQuantity = 10;
enum ContentTypesTop {
  lesson = 'lessonTopEmployees',
  course = 'courseTopEmployees',
  learningPlan = 'learningPlanTopEmployees'
}

const TopEmployees = ({
  groupId,
  supervisorId,
  filterSelection,
  daysFilter
}: {
  groupId: string;
  supervisorId: string;
  filterSelection: boolean;
  daysFilter: string;
}) => {
  const dispatch = useAppDispatch();
  let topEmployees: DashboardTopEmployees = {};
  const [currentTopContent, setCurrentTopContent] = React.useState<string>(
    ContentTypesTop.lesson
  );
  const dashboardTopEmployeesData = useSelector(
    (state: ApplicationState) => state?.student?.dashboardTopEmployees
  );
  const { t } = useTranslation(['dashboard']);

  React.useEffect(() => {
    if (filterSelection) {
      dispatch(
        studentActions.getDashboardTopEmployeesData(
          topQuantity,
          supervisorId,
          true,
          daysFilter
        )
      );
    } else {
      dispatch(
        studentActions.getDashboardTopEmployeesData(
          topQuantity,
          groupId,
          false,
          daysFilter
        )
      );
    }
    return () => {
      dispatch(studentActions.setDashboardTopEmployeesData(null));
    };
  }, [groupId, supervisorId, filterSelection, daysFilter]);

  topEmployees = React.useMemo(
    () => ({
      lessonTopEmployees: dashboardTopEmployeesData?.lessonTopEmployees,
      courseTopEmployees: dashboardTopEmployeesData?.courseTopEmployees,
      learningPlanTopEmployees:
        dashboardTopEmployeesData?.learningPlanTopEmployees
    }),
    [dashboardTopEmployeesData]
  );

  const handleChange = (event: { target: { value: string } }) => {
    setCurrentTopContent(event.target.value);
  };
  const PersonalizedInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #999999',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      color: '#ffffff',
      fontWeight: 400,
      // Use the system font instead of the default Roboto font.
      fontFamily: [theme.typography.fontFamily].join(',')
    },
    '.MuiNativeSelect-icon ': {
      color: '#FFFFFF8F'
    }
  }));

  return (
    <DashboardWidget
      title={`${t('top', { ns: 'dashboard' })} ${topQuantity} ${t(
        'topEmployees',
        { ns: 'dashboard' }
      )}`}
    >
      <Panel
        width="100%"
        height="auto"
        paddingLeft="0px"
        paddingRight="0px"
        paddingTop="0px"
        paddingBottom="0px"
      >
        <FormControl sx={{ mb: 2, ml: 2, mr: 2, mt: 2 }} variant="standard">
          <NativeSelect
            id="native-select-option"
            value={currentTopContent}
            onChange={handleChange}
            input={<PersonalizedInput />}
          >
            <option value={ContentTypesTop.lesson} style={optionStyle}>
              {t('lessonsCompleted', { ns: 'dashboard' })}
            </option>
            <option value={ContentTypesTop.course} style={optionStyle}>
              {t('coursesCompleted', { ns: 'dashboard' })}
            </option>
            <option value={ContentTypesTop.learningPlan} style={optionStyle}>
              {t('learningPlansCompleted', { ns: 'dashboard' })}
            </option>
          </NativeSelect>
        </FormControl>
        <ListEmployee
          employees={
            topEmployees[currentTopContent as keyof DashboardTopEmployees]
          }
        />
      </Panel>
    </DashboardWidget>
  );
};

const optionStyle: CSSProperties = {
  backgroundColor: 'black'
};

export default TopEmployees;

import { SxProps } from '@mui/material/styles';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import { useTranslation } from 'react-i18next';
import { CommonFilterProps } from './CommonFilterProps';

const GroupFilter: React.FC<CommonFilterProps> = ({
  onChange,
  filterValue,
  items,
  isDisabled
}) => {
  const { t } = useTranslation(['common', 'dashboard', 'assignments']);
  const value = filterValue ? [filterValue] : [];
  return (
    <BasicSelect
      labelId="groupFilter"
      id="groupFilter"
      options={items}
      value={value}
      handleOnChange={onChange}
      placeholder={t('filterByGroup', { ns: 'dashboard' })}
      theme="dark"
      multiple={false}
      sx={groupFilterStyle}
      disabled={isDisabled}
    />
  );
};

const groupFilterStyle: SxProps = {
  backgroundColor: 'transparent',
  minWidth: '200px',
  height: '45px'
};

export default GroupFilter;

import { Grid, Typography, useTheme } from '@mui/material';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

export const OnsiteTrainingPreviewDetailsBanner = () => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining', 'common']);
  return (
    <Grid item xs={6} md={4}>
      <Grid
        container
        direction={{ xs: 'column', md: 'row' }}
        display={'flex'}
        style={{ backgroundColor: '#FF9E00', minHeight: '58px' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Grid item style={{ marginLeft: '24px' }}>
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: `${theme.palette.common.black}`
            }}
          >
            {t('onsitePreviewMessage', { ns: 'onsiteTraining' })}
          </Typography>
        </Grid>
        <Grid
          item
          style={{ marginRight: '24px', marginLeft: '24px' }}
          alignItems={'center'}
        >
          <BasicButton color="secondary">
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { CSSProperties, PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';

import BasicSelect from '../../core/BasicSelect/BasicSelect';
import { dropDownItem } from '../../../entities/Common';

export interface AssignmentStatusModalProps {
  showModal: boolean;
  title: string;
  sortOptions: dropDownItem[];
  onClose: () => void;
  onChangeSortOption: (option: string) => void;
  onDownloadReport?: () => void;
  subtitle?: string;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const AssignmentStatusModal = ({
  showModal,
  title,
  subtitle,
  sortOptions,
  children,
  onClose,
  onChangeSortOption,
  onDownloadReport
}: PropsWithChildren<AssignmentStatusModalProps>) => {
  const theme = useTheme();
  const handleChangeSortOption = (items: string[]) => {
    const option = items[0];
    onChangeSortOption(option);
  };

  const subTitleStyle: CSSProperties = {
    fontSize: '20px',
    color: theme.palette.primary.main
  };

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        {title}
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0 1.5rem'
          }}
        >
          <span style={subTitleStyle}>{subtitle}</span>
          <Stack direction="row">
            <BasicSelect
              labelId="sortOptions"
              id="sortOptions"
              options={sortOptions}
              placeholder="Sort"
              handleOnChange={handleChangeSortOption}
              style={selectStyle}
              theme={'dark'}
              multiple={false}
              sx={{ backgroundColor: 'transparent' }}
            />
            {!!onDownloadReport && (
              <IconButton
                aria-label="downloadCSV"
                size="large"
                onClick={onDownloadReport}
                sx={{ color: theme.palette.grey[100], paddingTop: 0 }}
              >
                <DownloadForOfflineRoundedIcon style={{ fontSize: 45 }} />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {children}
      </DialogContent>
    </CustomDialog>
  );
};

const selectStyle: CSSProperties = {
  width: '200px',
  height: '45px'
};

export default AssignmentStatusModal;

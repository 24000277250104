import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { AssignedCourse } from '../../entities/LearningPlaylist';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import CourseCardNew from './CourseCardNew';
import useCourseCard from './useCourseCard';
import { courseLessonWrapperStyle } from './CommonStyles';
import { EnrollmentTypes } from '../../core/constants';
import ButtonsSelfAssignSection from '../AssignmentsButtonsSection/ButtonsSelfAssignSection';

interface CompleteCourseCardProps {
  assignedCourse: AssignedCourse;
}

const CompleteCourseCard: React.FC<CompleteCourseCardProps> = ({
  assignedCourse
}) => {
  const { course, dueDateMoment } = assignedCourse;
  const { id, revisionNumber, companyId, title } = course;

  const { getCourseType, takenTimesCourses, handleStartCourse } =
    useCourseCard();
  const { t } = useTranslation(['common']);
  return (
    <Box sx={courseLessonWrapperStyle}>
      <CourseCardNew
        key={id}
        averageLessonTime={
          course.avgLessonTime
            ? secondsToHHMMSS(course.avgLessonTime)
            : '00:00:00'
        }
        isDraft={false}
        status={'Completed'}
        hasSpanishSubtitles={course.hasSpanishSubtitles}
        course={course}
        isAdmin={false}
        showCompletedAsNormal={true}
        assignmentDueDate={
          assignedCourse.assignmentType === EnrollmentTypes.DueDate
            ? dueDateMoment!
            : undefined
        }
        type={getCourseType(course.isAicc)}
        takenTimes={takenTimesCourses![id]}
      >
        <ButtonsSelfAssignSection
          onClickStart={() =>
            handleStartCourse(id, revisionNumber, course.title, course.isAicc)
          }
          startButtonCaption={t('reenroll', { ns: 'common' })}
          startButtonColor={'revisit'}
          resourceType={'Course'}
          id={id}
          companyId={companyId}
          title={title}
        />
      </CourseCardNew>
    </Box>
  );
};

export default CompleteCourseCard;

import { useState } from 'react';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { LearningPlanUnit, LearningPlan } from '../../entities/LearningPlan';
import { CompletedLearningPlanInfo } from '../../entities/CompletedLearningPlanInfo';
import AssignLearningPlan from '../../pages/learningPlans/AssignLearningPlan';
import { useNavigate } from 'react-router';
import { useLogEvent } from '../../hooks/useLogEvent';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import ContentLibraryVirtualList from '../ContentLibraryVirtualList/ContentLibraryVirtualList';
import LearningPlanVirtualRow from '../ContentLibraryVirtualList/LearningPlanVirtualRow';
import ContentIndicators from '../../pages/contentLibrary/common/ContentIndicators';
import { useAppDispatch } from '../../store/hooks';

export interface AllLearningPlansProps {
  learningPlanUnits: Array<LearningPlanUnit>;
  completedLearningPlans: Array<CompletedLearningPlanInfo> | undefined;
  isLoading: boolean;
  takenTimesLearningPlans?: TakenTimesObject | null;
  totalItems: number;
}

const AllLearningPlansNew = ({
  learningPlanUnits,
  isLoading,
  completedLearningPlans,
  takenTimesLearningPlans,
  totalItems
}: AllLearningPlansProps) => {
  const { t } = useTranslation(['courses']);
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const dispatch = useAppDispatch();
  const [selectedPlan, setSelectedPlan] = useState<LearningPlan>();
  const [showAssignLearningPlanModal, setShowAssignLearningPlanModal] =
    useState<boolean>(false);

  const handleAssign = (learningPlan: LearningPlan) => {
    setSelectedPlan(learningPlan);
    setShowAssignLearningPlanModal(true);
  };
  const handleCloseAssignmodal = () => {
    setShowAssignLearningPlanModal(false);
    dispatch(learningPlanActions.setCurrentLearningPlan(null));
    setSelectedPlan(undefined);
  };

  const handleStartLearningPlan = (
    learningPlan: LearningPlan,
    isPreviewMode?: boolean
  ) => {
    dispatch(learningPlanActions.setCurrentLearningPlan(learningPlan));
    logEvent.logStartLearningPlanEvent(learningPlan.id, learningPlan.name);
    navigate(`/learning-plans/preview/${learningPlan.id}`, {
      state: {
        isPreviewMode
      }
    });
  };
  const handleEditLearningPlan = (learningPlan: LearningPlan) => {
    dispatch(learningPlanActions.requestPlan(learningPlan.id));
    navigate(`/content-library/learning-plans/${learningPlan.id}/edit`, {
      state: {
        isEditing: true
      }
    });
  };

  return (
    <Grid item xs={12} sx={containerStyle}>
      <ContentIndicators
        isLoading={isLoading}
        totalItems={totalItems}
        noContentMessage={t('noLearningPlansAvailableMsg', {
          ns: 'learningPlans'
        })}
      />
      <ContentLibraryVirtualList
        items={learningPlanUnits}
        learningPlanUnit={'learningPlans'}
        isLoading={isLoading}
        itemSize={375}
      >
        {({ data, index, style }) => (
          <LearningPlanVirtualRow
            key={`item-${data[index].map(i => i.learningPlan.id).join('-')}`}
            index={index}
            style={style}
            data={data}
            completedLearningPlans={completedLearningPlans}
            takenTimesLearningPlans={takenTimesLearningPlans}
            onEditLearningPlan={handleEditLearningPlan}
            onStartLearningPlan={handleStartLearningPlan}
            onAssign={handleAssign}
          />
        )}
      </ContentLibraryVirtualList>
      {showAssignLearningPlanModal && selectedPlan && (
        <AssignLearningPlan
          show={showAssignLearningPlanModal}
          learningPlan={selectedPlan}
          onClose={handleCloseAssignmodal}
        />
      )}
    </Grid>
  );
};

const containerStyle: SxProps = {
  paddingTop: '2rem'
};

export default AllLearningPlansNew;

import { CSSProperties, useState } from 'react';
import {
  Box,
  Stack,
  Typography,
  styled,
  useTheme,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextBox from '../../../components/core/TextBox/TextBox';
import TextArea from '../../../components/core/TextArea/TextArea';
import MultipleFilesInput from '../../../components/core/MultipleFilesInput/MultipleFilesInput';
import FormCheckBox from '../../../components/core/FormCheckBox/FormCheckBox';
import TagInput from '../../../components/TagInput/TagInput';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { dropDownItem } from '../../../entities/Common';
import ThumbnailLibraryPanel from '../../../components/ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../../entities/AzureBlobInfo';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useOnsiteTraining } from '../../../hooks/useOnsiteTraining';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import { TaggingField } from '../../../components/TagInput';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';

const FormContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '5px',
  width: '100%',
  maxWidth: '778px',
  padding: '1.5rem',
  marginBottom: '3rem',
  marginTop: '1rem'
}));

const MandatoryMark = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

const FormSection = styled(Stack)(() => ({
  paddingBottom: '1rem'
}));

const ErrorSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

const FieldName = ({
  name,
  isMandatory = false
}: {
  name: string;
  isMandatory?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Typography sx={theme.typography.subtitle1}>
      {name}
      {isMandatory && <MandatoryMark>*</MandatoryMark>}
    </Typography>
  );
};

interface OnsiteTrainingFormProps {
  actionType: 'create' | 'edit';
  onsiteTrainingToEdit?: OnsiteTraining;
}

const OnsiteTrainingForm = ({
  actionType,
  onsiteTrainingToEdit
}: OnsiteTrainingFormProps) => {
  const { t } = useTranslation(['onsiteTraining', 'common', 'skills']);
  const YesNoOptions: dropDownItem[] = [
    { label: t('yes', { ns: 'common' }), value: 'yes' },
    { label: 'No', value: 'no' }
  ];
  const {
    const: { onsiteTraining, attachmentNames },
    state: { companyTags, formValidationResult, isSaving, tagsLoading },
    api: {
      handleChangeTextBox,
      handleUpdatedFiles,
      handleRemoveFile,
      handleCheckBoxChange,
      handleTagging,
      handleDropDownChange,
      handleLibraryThumbnailSelected,
      handleThumbnailFileChange,
      handlePublish,
      handleSaveAsDraft,
      handleRemoveUploadedThumbnail,
      handleConfirmDelete
    }
  } = useOnsiteTraining(actionType, onsiteTrainingToEdit);
  const thumbnailName = (() => {
    const { thumbnail } = onsiteTraining;
    if (!thumbnail || !thumbnail.length) return '';
    const thumbnailArray = thumbnail.split('/');
    return thumbnailArray[thumbnailArray.length - 1];
  })();
  const getEquivalentBooleanValue = (value: boolean) => (value ? 'yes' : 'no');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  if (tagsLoading) {
    return <CircularProgress />;
  }

  return (
    <FormContainer>
      <FormSection>
        <FieldName name={t('name', { ns: 'common' })} isMandatory />
        <TextBox
          id="name"
          name="name"
          width="100%"
          height="40px"
          variant="dark"
          value={onsiteTraining.name}
          placeholderText={t('namePlaceHolder', { ns: 'onsiteTraining' })}
          onChangeValue={handleChangeTextBox('name')}
        />
        {!formValidationResult.nameIsValid && (
          <ErrorSpan>
            {t('nameIsMandatory', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection>
        <FieldName name={t('description', { ns: 'common' })} isMandatory />
        <TextArea
          id="description"
          name="description"
          width="100%"
          height="110px"
          rows={5}
          variant="dark"
          value={onsiteTraining.description}
          placeholderText={t('descriptionPlaceHolder', {
            ns: 'onsiteTraining'
          })}
          onChangeValue={handleChangeTextBox('description')}
        />
        {!formValidationResult.descriptionIsValid && (
          <ErrorSpan>
            {t('descriptionIsMandatory', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection>
        <FieldName name={t('attachments', { ns: 'common' })} />
        <MultipleFilesInput
          variant="dark"
          validTypes="*.*"
          tags={attachmentNames}
          alternativeBehavior={true}
          onFileAdded={handleUpdatedFiles}
          onRemoveSavedFile={handleRemoveFile}
        />
        <FormCheckBox
          label={t('hideAttachments', { ns: 'onsiteTraining' })}
          onChange={(event, checked) => handleCheckBoxChange(event, checked)}
          checked={onsiteTraining.hideAttachments}
        />
      </FormSection>
      <FormSection>
        <TaggingField
          required
          onChange={handleTagging}
          value={onsiteTraining.tags}
          options={companyTags ?? []}
        />
        {!formValidationResult.tagsAreValid && (
          <ErrorSpan>
            {t('mustEnterAtLeastOneTag', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection>
        <FieldName
          name={t('selectSkillsOrCertificates', { ns: 'onsiteTraining' })}
        />
        <TagInput
          value={[]}
          options={[]}
          placeholder={t('writeSkillCertificateHere', { ns: 'skills' })}
          noTagsText={t('noMatchingSkillsCertificates', {
            ns: 'skills'
          })}
          loading={false}
        />
      </FormSection>
      <FormSection>
        <FieldName
          name={t('doesAdminNeedToApproveEmployeesEarning', {
            ns: 'onsiteTraining'
          })}
        />
        <BasicSelect
          labelId="adminShouldApprove"
          id="adminShouldApprove"
          options={YesNoOptions}
          value={[
            getEquivalentBooleanValue(onsiteTraining.requireApprovalForAward)
          ]}
          handleOnChange={handleDropDownChange('requireApprovalForAward')}
          placeholder={t('select', { ns: 'common' })}
          theme="dark"
          multiple={false}
          style={dropdownStyle}
        />
      </FormSection>
      <FormSection>
        <FieldName
          name={t('signatureRequired', { ns: 'onsiteTraining' })}
          isMandatory
        />
        <BasicSelect
          labelId="requireSignature"
          id="requireSignature"
          options={YesNoOptions}
          value={[
            getEquivalentBooleanValue(
              onsiteTraining.requireSignatureForAttendance
            )
          ]}
          handleOnChange={handleDropDownChange('requireSignatureForAttendance')}
          placeholder={t('select', { ns: 'common' })}
          theme="dark"
          multiple={false}
          style={dropdownStyle}
        />
      </FormSection>
      <FormSection>
        <FieldName
          name={t('adminSignatureRequired', { ns: 'onsiteTraining' })}
          isMandatory
        />
        <BasicSelect
          labelId="requireAdminSignature"
          id="requireAdminSignature"
          options={YesNoOptions}
          value={[
            getEquivalentBooleanValue(
              onsiteTraining.requireSignatureForCompletion
            )
          ]}
          handleOnChange={handleDropDownChange('requireSignatureForCompletion')}
          placeholder={t('select', { ns: 'common' })}
          theme="dark"
          multiple={false}
          style={dropdownStyle}
        />
      </FormSection>
      <FormSection>
        <FieldName
          name={t('onsiteTrainingDaysLong', { ns: 'onsiteTraining' })}
          isMandatory
        />
        <TextBox
          id="onsiteTrainingDays"
          name="onsiteTrainingDays"
          width="100%"
          height="40px"
          variant="dark"
          value={
            !onsiteTraining.trainingDays
              ? ''
              : onsiteTraining.trainingDays.toString()
          }
          placeholderText={t('enterDayslong', { ns: 'onsiteTraining' })}
          onChangeValue={handleChangeTextBox('trainingDays', true)}
          isTypeNumber
          isNumber
          minNumericValue={1}
        />
        {!formValidationResult.trainingDaysIsvalid && (
          <ErrorSpan>
            {t('enterNumericValues', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <FormSection>
        <FieldName name={t('thumbnail', { ns: 'onsiteTraining' })} />
        <Box maxHeight="600px" sx={{ height: 'calc(100% - 25px)' }}>
          <ThumbnailLibraryPanel
            onChange={handleThumbnailFileChange}
            thumbnailUrl={onsiteTraining.thumbnail ?? ''}
            onItemSelected={(item: AzureBlobImageInfo) => {
              handleLibraryThumbnailSelected(item);
            }}
            maxContentHeight="430px"
            acceptedImageTypes="image/gif, image/jpeg, image/png, image/svg+xml"
            onRemoveUploadedFile={handleRemoveUploadedThumbnail}
          />
        </Box>
        {thumbnailName}
        {!formValidationResult.thumbnailIsValid && (
          <ErrorSpan>
            {t('uploadValidImage', { ns: 'onsiteTraining' })}
          </ErrorSpan>
        )}
      </FormSection>
      <Stack
        direction="row"
        sx={{
          flexWrap: 'wrap',
          marginTop: '1rem'
        }}
      >
        <BasicButton
          onClick={handlePublish}
          color="primary"
          width={'150px'}
          height={'42px'}
          style={{
            marginRight: '1rem',
            marginBottom: '0.5rem'
          }}
          loading={isSaving}
        >
          {onsiteTraining.isDraft
            ? t('publish', { ns: 'common' })
            : t('save', { ns: 'common' })}
        </BasicButton>
        {onsiteTraining.isDraft && (
          <BasicButton
            onClick={handleSaveAsDraft}
            color="secondary"
            width={'150px'}
            height={'42px'}
            loading={isSaving}
          >
            {t('saveAsDraft', { ns: 'common' })}
          </BasicButton>
        )}
        {actionType !== 'create' && (
          <BasicButton
            onClick={() => {
              setShowDeleteModal(true);
            }}
            color="confirm"
            width={'auto'}
            height={'42px'}
            style={{
              marginRight: '1rem',
              marginBottom: '0.5rem',
              marginLeft: '1rem'
            }}
            loading={isSaving}
          >
            {t('deleteOnSite', { ns: 'onsiteTraining' })}
          </BasicButton>
        )}
      </Stack>
      {showDeleteModal && (
        <ConfirmationModal
          show={true}
          showSave={true}
          showCancel={true}
          maxWidth="sm"
          saveText={'Ok'}
          cancelText={t('cancel', { ns: 'common' })}
          title={t('deleteOnSiteModal', { ns: 'onsiteTraining' })}
          onSave={handleConfirmDelete}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          isGrayCancelButton={true}
          showModalContentCentered={true}
        >
          <p>{t('confirmatioDeleteOnSite', { ns: 'onsiteTraining' })}</p>
        </ConfirmationModal>
      )}
    </FormContainer>
  );
};

const dropdownStyle: CSSProperties = {
  height: '42px'
};

export default OnsiteTrainingForm;

import { Grid, Stack, Typography } from '@mui/material';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export interface QuestionTitleAnalyticsProps {
  index: number;
  questionText: string;
  image?: string;
}

const QuestionTitleAnalytics: React.FC<QuestionTitleAnalyticsProps> = ({
  index,
  questionText,
  image
}) => {
  const { t } = useTranslation(['analytics']);

  return (
    <Grid display={'flex'} style={{ paddingLeft: '34px', paddingTop: '25px' }}>
      <Stack>
        <Typography style={labelStyle}>
          {`${t('question', { ns: 'analytics' })} ${index} ${t('response', { ns: 'analytics' })}`}
        </Typography>
        {image && (
          <Grid style={{ padding: '12px 0px 12px 0px' }}>
            <img src={image} alt="status" height={'146px'} />
          </Grid>
        )}
        <Typography style={titleStyle}>{questionText}</Typography>
      </Stack>
    </Grid>
  );
};

const titleStyle: CSSProperties = {
  fontSize: '18px'
};
const labelStyle: CSSProperties = {
  color: '#FF9E00',
  fontSize: '13px'
};
export default QuestionTitleAnalytics;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';

import { AnalyticsEmployeeCompletedAssignment } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getAssignedOnColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { EnrollmentTypes } from '../../../core/constants';
import { lowerCaseFirstLetter } from '../../../utils/stringUtils';

interface EmployeeCompletedAssignmentListProps {
  completedAssignmentsData?: AnalyticsEmployeeCompletedAssignment[] | null;
}

const RedSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

const GreenSpan = styled('span')(({ theme }) => ({
  color: theme.palette.success.main
}));

const CompletionIndicator = ({
  params
}: {
  params: GridRenderCellParams<any>;
}) => {
  const { t } = useTranslation(['analytics', 'assignments']);
  const { isCompletedOnTime, assignmentType } = params.row;

  if (assignmentType === EnrollmentTypes.DueDate && isCompletedOnTime) {
    return <GreenSpan>{t('completedOnTime', { ns: 'analytics' })}</GreenSpan>;
  }

  if (assignmentType === EnrollmentTypes.DueDate && !isCompletedOnTime) {
    return <RedSpan>{t('completedLate', { ns: 'analytics' })}</RedSpan>;
  }

  return (
    <span style={{ fontSize: '17px' }}>
      {t(lowerCaseFirstLetter(assignmentType), { ns: 'assignments' })}
    </span>
  );
};

const EmployeeCompletedAssignmentList = ({
  completedAssignmentsData
}: EmployeeCompletedAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      300,
      learningUnitNames
    ),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    {
      field: 'completionDate',
      headerName: t('completionDate', { ns: 'analytics' }),
      minWidth: 300,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Grid
            container
            direction="row"
            sx={{ textAlign: 'left', fontSize: '16px' }}
          >
            <Grid item xs={6}>
              <span>
                {absUtcDateToLocalMoment(params.row.completionDate).format(
                  'MM.DD.YYYY'
                )}
              </span>
            </Grid>
            <Grid item xs={6}>
              <CompletionIndicator params={params} />
            </Grid>
          </Grid>
        );
      }
    }
  ])();

  return <ModalDataGrid rows={completedAssignmentsData} columns={columns} />;
};

export default EmployeeCompletedAssignmentList;

import { Link, Stack, styled, useTheme } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

interface GoBackComponentProps {
  linkText: string;
  onBackTo: () => void;
}

const TextLinkSpan = styled('span')(({ theme }) => ({
  fontSize: '16px',
  paddingLeft: '0.5rem',
  verticalAlign: 'middle'
}));

const GoBackComponent = ({ linkText, onBackTo }: GoBackComponentProps) => {
  const theme = useTheme();

  return (
    <Stack direction="row">
      <Link underline="none" sx={{ cursor: 'pointer' }} onClick={onBackTo}>
        <ExpandCircleDownIcon
          fontSize="large"
          sx={{
            transform: 'rotate(90deg)',
            color: theme.palette.primary.light
          }}
        />
        <TextLinkSpan>{linkText}</TextLinkSpan>
      </Link>
    </Stack>
  );
};

export default GoBackComponent;

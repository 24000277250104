import React, { useEffect, CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionLessons } from '../../actions/lessons';
import { actionCreators as actionStudent } from '../../actions/student';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { ApplicationState } from '../../store';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import CourseInfoPanel from '../../components/CourseInfoPanel/CourseInfoPanel';
import { CompletedLessonInfo } from '../../entities/CompletedLessonInfo';
import { CommunityLesson, Lesson } from '../../entities/Lesson';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import NewLessonCard from '../../components/LessonCard/NewLessonCard';
import {
  CommunityLessonCardActions,
  LessonCardActions
} from '../../components/LessonCardActions';
import PreviewModeBanner from '../../components/PreviewMode/PreviewModeBanner';
import { EntityTypeEnum } from '../../core/enums';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { Box, Grid, Stack, Typography } from '@mui/material';
import LessonRowWrapper from '../contentLibrary/common/LessonRowWrapper';
import { useAppDispatch } from '../../store/hooks';

const CoursePreview: React.FC = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const location = useLocation();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const [courseCompletedLessons, setCourseCompletedLessons] = useState<
    CompletedLessonInfo[]
  >([]);

  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const completedLessons = useSelector(
    (state: ApplicationState) => state.student?.completedLessons
  );

  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );

  const { t } = useTranslation(['common', 'lessons']);
  const isPreviewMode = location?.state?.isPreviewMode;
  const isClickSafetyCourse = location?.state?.isClickSafetyCourse;

  const BackwardIconStyle: CSSProperties = {
    display: 'inline-block',
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundImage: `url(${BackwardIconGray})`
  };

  const ContainerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity',
    paddingBottom: 30
  };

  const titleStyle: CSSProperties = {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.15px'
  };

  const quizAssesmentId: string[] = [];
  {
    lessons?.map(lesson => {
      if (lesson.lessonType === 'Quiz') {
        quizAssesmentId.push(lesson.assessmentId!);
      }
    });
  }

  useEffect(() => {
    if (params && params.id && params.revisionNumber) {
      dispatch(
        actionCreators.requestCourseByIdAndRevisionNumber(
          params.id,
          params.revisionNumber
        )
      );
      dispatch(
        actionLessons.requestCourseLessonsByRevision(
          params.id,
          params.revisionNumber
        )
      );
    }
  }, [params]);

  useEffect(() => {
    if (course) {
      dispatch(
        actionStudent.getCompletedLessonsByCourseId(
          course!.id,
          course!.revisionNumber
        )
      );
    }
  }, [course]);

  useEffect(() => {
    dispatch(actionAssesments.failedQuizzesResult(quizAssesmentId));
  }, [lessons]);

  useEffect(() => {
    dispatch(actionStudent.getTakenTimesLessons(employeeId));
  }, [employeeId]);

  useEffect(() => {
    if (completedLessons) {
      setCourseCompletedLessons(completedLessons);
    }
  }, [completedLessons]);

  const backToCourses = () => {
    navigate(-1);
  };

  const isCommunityLesson = (lesson: Lesson) => {
    if ((lesson as CommunityLesson).userProfileName) {
      return true;
    }
    return false;
  };

  const handlePlayLesson = (
    lesson: Lesson,
    isLessonComplete: boolean,
    isPreviewMode: boolean
  ) => {
    logEvent.logPlayLessonEvent(lesson.id, lesson.title);
    logEvent.logStartCourseEvent(
      course?.id,
      course?.revisionNumber,
      course?.title
    );

    navigate(`/courses/${course?.id}/revision/${course?.revisionNumber}/play`, {
      state: { lesson, isRevisit: isLessonComplete, isPreviewMode }
    });
  };

  if (!course) return <></>;
  else
    return (
      <PageContainer style={ContainerStyle}>
        {isPreviewMode && course && (
          <PreviewModeBanner
            contentToAssign={course}
            contentType={EntityTypeEnum.Course}
            isClickSafetyCourse={isClickSafetyCourse}
          />
        )}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          sx={{ paddingTop: '1rem' }}
        >
          <Box>
            <i
              className="fas fa-sm fa-fw mr-2 text-gray-400"
              style={BackwardIconStyle}
              onClick={backToCourses}
            />
          </Box>
          <Stack sx={{ width: '100%' }}>
            <Typography style={titleStyle}>{course?.title}</Typography>
            <CourseInfoPanel
              course={course}
              totalLessonsCount={lessons?.length ?? 0}
              completedLessonsCount={courseCompletedLessons.length}
              isLoadingInfo={studentIsLoading}
              isPreviewMode={isPreviewMode}
            />
            <Box sx={{ pt: '3rem' }}>
              <h4>{t('lessons', { ns: 'lessons' })}</h4>
            </Box>
            <Box sx={{ pt: '1.5rem' }}>
              <Grid container spacing={2} id="parentContainer">
                {lessons?.map(lesson => {
                  const isLessonComplete =
                    completedLessons?.some(
                      completeLesson => completeLesson.lessonId === lesson.id
                    ) ?? false;
                  return (
                    <LessonRowWrapper key={lesson.id}>
                      <div className="pt-2">
                        {isCommunityLesson(lesson) ? (
                          <NewLessonCard
                            lesson={lesson}
                            lessonCardType={'Community'}
                            isComplete={isLessonComplete}
                            onStartPreviewMode={() =>
                              handlePlayLesson(
                                lesson,
                                isLessonComplete,
                                isPreviewMode
                              )
                            }
                          >
                            <CommunityLessonCardActions
                              communityLesson={lesson as CommunityLesson}
                              isPreviewMode={isPreviewMode}
                              isComplete={isLessonComplete}
                              onPlay={() =>
                                handlePlayLesson(
                                  lesson,
                                  isLessonComplete,
                                  isPreviewMode
                                )
                              }
                            />
                          </NewLessonCard>
                        ) : (
                          <NewLessonCard
                            lesson={lesson}
                            takenTimes={
                              takenTimesLessons
                                ? takenTimesLessons[lesson.id]
                                : undefined
                            }
                            lessonCardType={'Company'}
                            isComplete={isLessonComplete}
                            onStartPreviewMode={() =>
                              handlePlayLesson(
                                lesson,
                                isLessonComplete,
                                isPreviewMode
                              )
                            }
                          >
                            <LessonCardActions
                              lesson={lesson as Lesson}
                              isPreviewMode={isPreviewMode}
                              isComplete={isLessonComplete}
                              onStart={() =>
                                handlePlayLesson(
                                  lesson,
                                  isLessonComplete,
                                  isPreviewMode
                                )
                              }
                            />
                          </NewLessonCard>
                        )}
                      </div>
                    </LessonRowWrapper>
                  );
                })}
              </Grid>
            </Box>
          </Stack>
        </Stack>
      </PageContainer>
    );
};

export default CoursePreview;

import { CSSProperties } from 'react';
import { SxProps } from '@mui/system';

export const videoLessonTypeStyle: CSSProperties = {
  display: 'inline-block',
  backgroundRepeat: 'no-repeat',
  textAlign: 'center',
  width: '15px',
  height: '15px'
};

export const titleStyle: SxProps = {
  color: '#FBFBFB',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontSize: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '28px'
};

export const commentsStyle: SxProps = {
  color: '#FBFBFB',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontSize: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const iconStyle: CSSProperties = {
  width: 17,
  height: 17,
  display: 'inline-block',
  marginRight: 5
};

export const cardContentStyle: SxProps = {
  minHeight: '80px',
  paddingLeft: '18px',
  paddingTop: '5px',
  paddingBottom: '0px !important'
};

export const divContributorSpan: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap'
};
export const spanStyle: CSSProperties = {
  fontSize: '12px',
  color: 'rgba(255, 255, 255, 1)',
  textShadow: '1px 1px 2px black'
};

export const typeLessonAndAVGStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px'
};

export const infoContainerStyle: CSSProperties = {
  display: 'flex',
  paddingLeft: '18px'
};

export const infoAvgLessonStyle: CSSProperties = {
  display: 'flex',
  paddingRight: '18px',
  paddingLeft: '18px',
  paddingBottom: '0.5rem'
};

export const headerCardImgStyle: CSSProperties = {
  opacity: '40%'
};

export const childrenButtonsStyle: CSSProperties = {
  width: '100%'
};

export const childrenCommunityButtonsStyle: CSSProperties = {
  ...childrenButtonsStyle
};

export const gridContainteStyles: CSSProperties = {
  top: '120px',
  position: 'absolute',
  zIndex: 2,
  justifyContent: 'space-between'
};

export const contributorAndEditStyle: CSSProperties = {
  position: 'absolute',
  zIndex: 10,
  textAlign: 'left',
  top: 18
};

export const divEditButtonStyle = {
  display: 'flex',
  justifyContent: 'flex-end'
};

export const editStyle = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textDecoration: 'underline',
  width: '95px',
  height: '35px'
};

export const styleIcon = {
  marginLeft: '5px',
  fontSize: '15px'
};

export const divEditSpan: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  flexWrap: 'wrap'
};

export const divEditSpanWithBanner: CSSProperties = {
  ...divEditSpan,
  marginTop: '12px'
};

export const lessonInfoContainerStyle: CSSProperties = {
  top: 0,
  position: 'absolute',
  zIndex: 2,
  justifyContent: 'space-between',
  display: 'flex',
  height: '150px',
  alignItems: 'end'
};

export const lessonInfoSubContainerStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  height: 'fit-content',
  justifyContent: 'space-between',
  width: '100%'
};

import AttachedFilesModal from './modals/AttachedFilesModal';
import EmployeesSkillModal from './modals/EmployeesSkillModal';
import LinkedContentModal from './modals/LinkedContentModal';
import EmployeesAssignedModal from './modals/EmployeesAssignedModal';
import { SkillsModalTypes } from '../../core/constants';
import LinkedContentList from './LinkedContentList';
import AttachedFilesList from './AttachedFilesList';
import EmployeeSkillContentList from './EmployeeSkillContentList';
import EmployeeWithSkill from './EmployeeWithSkill';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useMemo, useState } from 'react';
import { LinkedContentBase } from '../../entities/Achievements';

interface ModalSkillsFactoryProps {
  modalType: string;
  showModal: boolean;
  title: string;
  onCloseModal: () => void;
  skillSelected: string;
}

const ModalSkillsFactory = ({
  modalType,
  showModal,
  title,
  onCloseModal,
  skillSelected
}: ModalSkillsFactoryProps) => {
  const [linkedContent, setLinkedContent] = useState<LinkedContentBase[]>([]);
  const [numberOfContentLinked, setNumberOfContentLinked] = useState(0);
  const competenceAttachments = useSelector(
    (state: ApplicationState) => state?.achievements?.competenceAttachments
  );

  const employeesWithSkill = useSelector(
    (state: ApplicationState) =>
      state?.achievements?.employeesByCompetence?.pageItems
  );

  const skill = useSelector(
    (state: ApplicationState) =>
      state?.achievements?.competencesById[skillSelected]
  );

  useMemo(() => {
    const linkedContentVar: LinkedContentBase[] = [];
    if (skill?.learningUnitId) {
      linkedContentVar.push({
        id: skill.learningUnitId,
        name: skill.learningUnitName ?? '',
        learningUnitType: skill.competenceLearningUnitType
          ?.toLowerCase()
          .includes('course')
          ? 'Course'
          : 'LearningPlan'
      });
    }
    if (skill?.onsiteTrainingsLinked.length) {
      skill?.onsiteTrainingsLinked.map(otl => {
        linkedContentVar.push({
          id: otl.onsiteTrainingId,
          name: otl.name,
          learningUnitType: 'OnsiteTraining'
        });
      });
    }
    setLinkedContent(linkedContentVar);
    setNumberOfContentLinked(linkedContentVar.length);
  }, [skillSelected]);

  switch (modalType) {
    case SkillsModalTypes.LinkedTraining:
      return (
        <LinkedContentModal
          showModal={showModal}
          onCloseModal={onCloseModal}
          title={title}
        >
          <LinkedContentList
            contentInfo={linkedContent}
            skillSelectedId={skillSelected}
            setLinkedContent={setLinkedContent}
            numberOfContent={numberOfContentLinked}
          />
        </LinkedContentModal>
      );
    case SkillsModalTypes.AttachedFiles:
      return (
        <AttachedFilesModal
          showModal={showModal}
          onCloseModal={onCloseModal}
          title={title}
        >
          <AttachedFilesList
            fileNames={competenceAttachments ? competenceAttachments : []}
          />
        </AttachedFilesModal>
      );
    case SkillsModalTypes.EmployeesSkill:
      return (
        <EmployeesSkillModal
          showModal={showModal}
          onCloseModal={onCloseModal}
          title={title}
        >
          <EmployeeWithSkill rows={employeesWithSkill || []} />
        </EmployeesSkillModal>
      );
    case SkillsModalTypes.EmployeesAssigned:
      return (
        <EmployeesAssignedModal
          showModal={showModal}
          onCloseModal={onCloseModal}
          title={title}
        >
          <EmployeeSkillContentList />
        </EmployeesAssignedModal>
      );
    default:
      return <></>;
  }
};

export default ModalSkillsFactory;

import React, { CSSProperties, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ModalSkillsFactory from './ModalSkillsFactory';
import { SkillsModalTypes, TrainingItemType } from '../../core/constants';
import { StyledCompetenceDetailContainer } from './SkillComponentsHelper';
import SkillIcon from '../../assets/skill.svg';
import CertificateIcon from '../../assets/certificate.svg';
import { CompetenceLearningUnitType, CRUDAction } from '../../core/enums';
import { SvgBigSkillIcon } from './SkillComponentsHelper';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/achievements';
import {
  lowerCaseFirstLetter,
  upperCaseFirstLetter
} from '../../utils/stringUtils';
import EditSkillCertificate from './editSkill/EditSkillCertificate';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';

interface SkillDetailProps {
  skillSelected: string;
  courseId?: string;
  learningPlanId?: string;
  onSelectSkill: (id: string) => void;
}

const StyledTableRow = styled(TableRow)(() => ({
  height: 40,
  '&:nth-of-type(odd)': {
    backgroundColor: '#1B1C1C',
    border: '1px solid rgba(228, 228, 228, 0.15);'
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(40, 40, 40, 0.62)',
    border: '1px solid rgba(228, 228, 228, 0.15);'
  },
  '&:nth-of-type(odd) td, &:nth-of-type(even) td': {
    borderBottom: 'none',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#FFFFFF'
  },
  '&:nth-of-type(odd) th, &:nth-of-type(even) th': {
    borderRight: '1px solid rgba(228, 228, 228, 0.15);',
    borderBottom: 'none',
    width: '250px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '23px',
    letterSpacing: '0.01em',
    color: '#FFFFFF'
  }
}));

const SkillDetail: React.FC<SkillDetailProps> = ({
  skillSelected,
  courseId,
  learningPlanId,
  onSelectSkill
}) => {
  const { t } = useTranslation(['skills', 'common']);
  const [modalType, setModalType] = useState<string>('');
  const [modalTitle, setModalTilte] = useState<string>('');
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const skill = useSelector(
    (state: ApplicationState) =>
      state?.achievements?.competencesById[skillSelected]
  );
  const skillIsLoading = useSelector(
    (state: ApplicationState) => state?.achievements?.isLoading
  );

  const handleAwardSkill = () => {
    navigate(`/grant/${skill?.id}/${skill?.name}`);
  };

  const handleOpenModal = (modalSkillType: string, titleModal: string) => {
    setOpen(true);
    setModalTilte(titleModal);
    setModalType(modalSkillType);
    switch (modalSkillType) {
      case SkillsModalTypes.AttachedFiles:
        dispatch(actionCreators.GetCompetenceAttachmentsById(skillSelected));
        break;
      case SkillsModalTypes.EmployeesAssigned:
        dispatch(actionCreators.getEmployeesWithAssignedSkill(skillSelected));
        break;
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setModalTilte('');
    setModalType('');
    switch (modalType) {
      case SkillsModalTypes.AttachedFiles:
        dispatch(actionCreators.cleanCompetenceAttachments());
        break;
    }
  };

  useEffect(() => {
    setIsEdit(false);
  }, [skillSelected]);

  const renderSkill = () => {
    const isCertificate =
      skill?.competenceLearningUnitType ===
        CompetenceLearningUnitType.LearningPlanCertificate ||
      skill?.competenceLearningUnitType ===
        CompetenceLearningUnitType.CourseCertificate ||
      skill?.competenceLearningUnitType ===
        CompetenceLearningUnitType.Certificate;

    const learningUnitType = skill?.competenceLearningUnitType.startsWith(
      upperCaseFirstLetter(TrainingItemType.learningPlan)
    )
      ? TrainingItemType.learningPlan
      : skill?.competenceLearningUnitType.startsWith(
            upperCaseFirstLetter(TrainingItemType.course)
          )
        ? TrainingItemType.course
        : '';

    return (
      <Grid minHeight="670">
        {!isEdit ? (
          <>
            <Grid
              container
              style={{
                paddingLeft: 15,
                paddingTop: 15
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  paddingRight: 10
                }}
              >
                <Typography style={titleSkill}>{skill?.name}</Typography>
                <Box style={subtitleContainer}>
                  <img
                    src={isCertificate ? CertificateIcon : SkillIcon}
                    style={{
                      width: 20,
                      height: 16,
                      marginRight: 10,
                      marginTop: 3
                    }}
                    alt="Icon for Skill"
                  />
                  <Typography style={subtitleSkill}>
                    {t(!isCertificate ? 'skill' : 'certificate', {
                      ns: 'skills'
                    })}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  paddingRight: 10
                }}
              >
                <BasicButton
                  color="grayButton"
                  style={buttonEditStyle}
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  <Typography sx={fontButton}>
                    {t('edit', { ns: 'common' })}
                  </Typography>
                </BasicButton>
              </Grid>
            </Grid>

            <Grid item xs={12} style={containerTable}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t(isCertificate ? 'certificateName' : 'skillName', {
                          ns: 'skills'
                        })}
                      </TableCell>
                      <TableCell align="left">{skill?.name}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('linkedCourses', { ns: 'skills' })}
                      </TableCell>
                      <TableCell style={rowButtonStyle}>
                        <div className="d-flex flex-column">
                          <span>{skill?.learningUnitName ?? ''}</span>
                          <span style={{ color: '#999999' }}>
                            {!!learningUnitType.length &&
                              t(learningUnitType, { ns: 'skills' })}
                          </span>
                        </div>
                        <BasicButton
                          color="grayButton"
                          disable={
                            !skill?.learningUnitId &&
                            skill?.onsiteTrainingsLinked.length === 0
                          }
                          style={buttonModalStyle}
                          onClick={() =>
                            handleOpenModal(
                              SkillsModalTypes.LinkedTraining,
                              t('linkedContent', { ns: 'skills' })
                            )
                          }
                        >
                          <Typography sx={fontButton}>
                            {t('view', { ns: 'skills' })}
                          </Typography>
                        </BasicButton>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('skillDuration', { ns: 'skills' })}
                      </TableCell>
                      <TableCell align="left">
                        {skill?.duration
                          ? `${skill?.duration} ${t(
                              lowerCaseFirstLetter(`${skill?.durationUnit}s`),
                              { ns: 'skills' }
                            )}`
                          : t('neverExpires', { ns: 'skills' })}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('attachedFiles', { ns: 'skills' })}
                      </TableCell>
                      <TableCell style={rowButtonStyle}>
                        {skill?.attachments.length}
                        <BasicButton
                          color="grayButton"
                          disable={skill?.attachments.length === 0}
                          style={buttonModalStyle}
                          onClick={() =>
                            handleOpenModal(
                              SkillsModalTypes.AttachedFiles,
                              t('attachedFiles', { ns: 'skills' })
                            )
                          }
                        >
                          <Typography sx={fontButton}>
                            {t('view', { ns: 'skills' })}
                          </Typography>
                        </BasicButton>
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('description', { ns: 'skills' })}
                      </TableCell>
                      <TableCell align="left">{skill?.description}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('employeeswithSkill', { ns: 'skills' })}
                      </TableCell>
                      <TableCell style={rowButtonStyle}>
                        {skill?.employeesWithThisCompetence}
                        {!!skill?.employeesWithThisCompetence && (
                          <BasicButton
                            color="grayButton"
                            disable={skill?.employeesWithThisCompetence === 0}
                            style={buttonModalStyle}
                            onClick={() => {
                              dispatch(
                                actionCreators.getEmployeesByCompetence(
                                  skill?.id || '',
                                  1
                                )
                              );
                              handleOpenModal(
                                SkillsModalTypes.EmployeesSkill,
                                t('employeeswithSkill', { ns: 'skills' })
                              );
                            }}
                          >
                            <Typography sx={fontButton}>
                              {t('view', { ns: 'skills' })}
                            </Typography>
                          </BasicButton>
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell component="th" scope="row">
                        {t('employeesAssigned', { ns: 'skills' })}
                      </TableCell>
                      <TableCell style={rowButtonStyle} component="td">
                        {skill?.employeesWithCompetenceTasks}
                        <BasicButton
                          color="grayButton"
                          disable={skill?.employeesWithCompetenceTasks === 0}
                          style={buttonModalStyle}
                          onClick={() =>
                            handleOpenModal(
                              SkillsModalTypes.EmployeesAssigned,
                              t('employeesAssigned', { ns: 'skills' })
                            )
                          }
                        >
                          <Typography sx={fontButton}>
                            {t('view', { ns: 'skills' })}
                          </Typography>
                        </BasicButton>
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                paddingRight: 10,
                paddingTop: 20,
                paddingBottom: 20
              }}
            >
              <BasicButton
                color="primary"
                style={buttonAwardStyle}
                onClick={handleAwardSkill}
              >
                <Typography sx={fontButton}>
                  {t(isCertificate ? 'awardCertificate' : 'awardSkill', {
                    ns: 'skills'
                  })}
                </Typography>
              </BasicButton>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} style={containerTable}>
            <EditSkillCertificate
              action={CRUDAction.Edit}
              onClose={() => setIsEdit(false)}
              competence={skill}
              onSelectSkill={onSelectSkill}
            />
          </Grid>
        )}
        <ModalSkillsFactory
          modalType={modalType}
          showModal={open}
          title={modalTitle}
          onCloseModal={handleCloseModal}
          skillSelected={skillSelected}
        />
      </Grid>
    );
  };

  const emptySkill = () => (
    <Grid
      container
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      height={670}
      paddingX={3}
      textAlign="center"
    >
      <span style={styleEarnedSkillIcon}>
        <SvgBigSkillIcon />
      </span>
      <span
        style={{
          fontWeight: 600,
          fontSize: '24px',
          paddingTop: '1rem'
        }}
      >
        {t('manageSkillsNoOneSelected', { ns: 'skills' })}
      </span>
    </Grid>
  );

  if (skillIsLoading) {
    return (
      <StyledCompetenceDetailContainer sx={{ height: 'fit-content' }}>
        <Grid
          container
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          height={670}
          paddingX={3}
          textAlign="center"
        >
          <CircularProgress />
        </Grid>
      </StyledCompetenceDetailContainer>
    );
  }

  return (
    <StyledCompetenceDetailContainer sx={{ height: 'fit-content' }}>
      {skill ? renderSkill() : emptySkill()}
    </StyledCompetenceDetailContainer>
  );
};

export const buttonEditStyle: CSSProperties = {
  maxWidth: '95px',
  width: '95px',
  height: '42px'
};

export const buttonAwardStyle: CSSProperties = {
  minWidth: '120px',
  maxWidth: 'auto',
  height: '42px'
};

export const buttonModalStyle: CSSProperties = {
  maxWidth: '75px',
  width: '75px',
  height: '30px'
};

export const fontButton: CSSProperties = {
  fontSize: '16px',
  color: '#0E0D0D',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px'
};

export const itemsLabel: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '21px',
  color: '#FFFFFF'
};

export const boxStyle: CSSProperties = {
  height: '60vh',
  width: '100%',
  border: `1px solid #999999`,
  backgroundColor: '#232424',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px'
};

export const listStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  paddingRight: 10
};

const titleSkill: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '18px',
  color: '#FFFFFF'
};

const subtitleSkill: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '23px',
  letterSpacing: '0.01em',
  color: '#999999'
};

const rowButtonStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center'
};

const subtitleContainer: CSSProperties = {
  marginTop: 6,
  display: 'flex'
};

const containerTable: CSSProperties = {
  paddingTop: 12,
  borderBottom: '1px solid #999',
  paddingBottom: '50px'
};

const styleEarnedSkillIcon: CSSProperties = {
  position: 'relative',
  left: 40
};

export default SkillDetail;

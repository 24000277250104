import { useTranslation } from 'react-i18next';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { AnalyticsQuizDetail } from '../../../entities/Analytics';
import { AnalyticsDataGrid } from '../../../components/Analytics';
import {
  getFullNameColumn,
  getGenericValueActionColumn
} from '../common/utilAnalyticsColumnDefinition';
import { GreenSpan, RedSpan, OrangeSpan } from '../common/StyledSpan';

interface QuizzesDetailTableProps {
  data?: AnalyticsQuizDetail[] | null;
  quizName: string;
  onClickActionButton: (
    action: string,
    employeeId: string,
    employeeName: string,
    resultId: string
  ) => void;
}

const getNumberOfTimesCompleted = (params: GridRenderCellParams<any>) => {
  return <span>{params.row.numberOfTimesCompleted}</span>;
};

const getLastGrade = (params: GridRenderCellParams<any>) => {
  const lastGrade = params.row.lastGrade.toFixed(0) as number;
  const Container =
    lastGrade <= 55 ? RedSpan : lastGrade <= 70 ? OrangeSpan : GreenSpan;
  return <Container>{`${lastGrade}%`}</Container>;
};

const QuizzesDetailTable = ({
  data,
  quizName,
  onClickActionButton
}: QuizzesDetailTableProps) => {
  const { t } = useTranslation(['analytics']);

  const handleClickActionButton =
    (action: string) => (params: GridRenderCellParams<any>) => {
      onClickActionButton(
        action,
        params.row.employeeId,
        `${params.row.firstName} ${params.row.lastName}`,
        params.row.lastResultId
      );
    };

  const columns: GridColDef[] = [
    getFullNameColumn(quizName),
    getGenericValueActionColumn(
      'numberOfTimesCompleted',
      t('numberOfTimesTaken', { ns: 'analytics' }),
      t('viewAllGrades', { ns: 'analytics' }),
      350,
      handleClickActionButton('viewAllGrades'),
      getNumberOfTimesCompleted
    ),
    getGenericValueActionColumn(
      'hasQuizResults',
      t('lastGrade', { ns: 'analytics' }),
      t('viewQuestionResponses', { ns: 'analytics' }),
      400,
      handleClickActionButton('viewQuestionResponses'),
      getLastGrade
    )
  ];

  return <AnalyticsDataGrid columns={columns} rows={data} rowId="employeeId" />;
};

export default QuizzesDetailTable;

import { Tag } from '../../../entities/Tag';
import { Lesson, LessonUnit } from '../../../entities/Lesson';
import { Course, CourseUnit } from '../../../entities/Course';

type LessonSourceType = Lesson[] | LessonUnit[] | null;
type CourseSourceType = Course[] | CourseUnit[] | null;
type LearningUnitType = 'lessons' | 'courses' | string;
type LearningUnit = Lesson | Course;

const extractTags = (
  learningUnits: LearningUnit[],
  entitySelector: (item: LearningUnit) => Tag[]
) => {
  const tags: Tag[] = [];
  learningUnits
    .filter(item => entitySelector(item).length)
    .forEach(item => {
      for (const tag of entitySelector(item)) {
        if (!tags.some(t => t.name === tag.name.trim().toLowerCase())) {
          tags.push({
            ...tag,
            name: tag.name.trim().toLowerCase()
          });
        }
      }
    });

  return tags.sort((tag1, tag2) => (tag1.name > tag2.name ? 1 : -1));
};

const extractTagsFromLessonSource = (
  lessonsSource: LessonUnit[] | Lesson[]
): Tag[] => {
  if (!lessonsSource?.length) return [];

  const lessons =
    'lesson' in lessonsSource[0]
      ? (lessonsSource as LessonUnit[]).map(item => item.lesson)
      : (lessonsSource as Lesson[]);

  if (!lessons?.length) return [];

  return extractTags(lessons, item => item.tags);
};

const extractTagsFromCourseSource = (
  coursesSource: CourseUnit[] | Course[]
): Tag[] => {
  if (!coursesSource?.length) return [];

  const courses =
    'course' in coursesSource[0]
      ? (coursesSource as CourseUnit[]).map(item => item.course)
      : (coursesSource as Course[]);
  if (!courses?.length) return [];

  return extractTags(courses, item => item.tags);
};

const useLearningUnitTag = (
  learningUniType: LearningUnitType,
  lessonsSource?: LessonSourceType,
  coursesSource?: CourseSourceType
) => {
  switch (learningUniType) {
    case 'lessons':
      return extractTagsFromLessonSource(lessonsSource ?? []);
    case 'courses':
      return extractTagsFromCourseSource(coursesSource ?? []);
    default:
      return [];
  }
};

export default useLearningUnitTag;

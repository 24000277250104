import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { SxProps } from '@mui/system';
import { styled, useTheme } from '@mui/material/styles';

export interface FormCheckBoxProps extends CheckboxProps {
  label: string;
  formControlLabelSx?: SxProps;
}

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-disabled ': {
    color: theme.palette.grey[100]
  }
}));

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: theme.palette.common.white
  }
}));

const FormCheckBox = React.forwardRef<HTMLButtonElement, FormCheckBoxProps>(
  ({ label, checked, onChange, formControlLabelSx, ...props }, ref) => {
    const theme = useTheme();
    const checkBoxStyle: SxProps = {
      color: theme.palette.primary.main
    };

    const checkBoxLabelStyle: SxProps = {
      color: theme.palette.common.white,
      ...formControlLabelSx
    };

    return (
      <CustomFormControlLabel
        control={
          <CustomCheckBox
            ref={ref}
            sx={checkBoxStyle}
            onChange={onChange}
            {...props}
          />
        }
        sx={checkBoxLabelStyle}
        checked={checked}
        label={label}
      />
    );
  }
);

export default FormCheckBox;

import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';

import { actionCreators as haltActions } from '../actions/halts';
import { ApplicationState } from '../store';
import { HaltType } from '../core/enums';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../store/hooks';
import { handleGenericBackendError } from '../utils/errorHandling';

type HaltListType = 'yourHalts' | 'todaysHalts' | 'pickedDateHalts' | string;

export const useHalts = () => {
  const dispatch = useAppDispatch();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [numberOfIds, setNumberOfIds] = useState<number>(0);
  const [haltListType, setHaltListType] = useState<HaltListType>('');
  const [selectedCreationOption, setSelectedCreationOption] =
    useState<string>('');
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const monthHalts = useSelector(
    (state: ApplicationState) => state.halts?.monthHalts
  );
  const pickedCalendarDate = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDate
  );
  const haltIds = useRef<string[]>([]);
  const haltDateRef = useRef<Moment>();

  const handleInitDeleteHalt = async (
    ids: string[],
    listType: string,
    haltDate?: Moment
  ) => {
    haltIds.current = ids;
    haltDateRef.current = haltDate;
    setHaltListType(listType);
    setOpenConfirmationModal(true);
    setNumberOfIds(ids.length);
  };

  const handleDeleteHalt = async () => {
    switch (haltListType) {
      case 'todaysHalts':
      case 'pickedDateHalts':
        await handleDeleteHaltForTodayOrPickedDateHalts();
        break;
      case 'yourHalts':
        await handleDeleteYourHalt();
        break;
      default:
        break;
    }
  };

  const handleDeleteHaltForTodayOrPickedDateHalts = async () => {
    const momentReferenceDate =
      haltListType === 'todaysHalts'
        ? moment()
        : moment(pickedCalendarDate, 'YYYY-DD-MM');

    setIsDeleting(true);
    try {
      await dispatch<Promise<void>>(haltActions.deleteHalts(haltIds.current));
      if (
        monthHalts &&
        monthHalts.length &&
        moment(monthHalts[0].haltDateUtc).isBetween(
          momentReferenceDate.clone().startOf('month'),
          momentReferenceDate.clone().endOf('month')
        )
      ) {
        dispatch(
          haltActions.getMonthHalts(
            momentReferenceDate.year(),
            momentReferenceDate.month() + 1
          )
        );
      }

      if (
        haltListType === 'todaysHalts' ||
        (momentReferenceDate.month() === moment().month() &&
          momentReferenceDate.year() === moment().year())
      ) {
        await dispatch<Promise<void>>(haltActions.getTodayHalts());
      }

      if (haltListType === 'pickedDateHalts') {
        await dispatch<Promise<void>>(
          haltActions.getHaltsByDate(pickedCalendarDate!)
        );
      }
    } catch (e) {
      handleGenericBackendError();
    } finally {
      handleCloseConfirmationModal();
      setIsDeleting(false);
    }
  };

  const handleDeleteYourHalt = async () => {
    setIsDeleting(true);
    try {
      await dispatch<Promise<void>>(haltActions.deleteHalts(haltIds.current));
      if (
        monthHalts &&
        monthHalts.length &&
        moment(monthHalts[0].haltDateUtc).isBetween(
          haltDateRef.current!.clone().startOf('month'),
          haltDateRef.current!.clone().endOf('month')
        )
      ) {
        dispatch(
          haltActions.getMonthHalts(
            haltDateRef.current!.year(),
            haltDateRef.current!.month() + 1
          )
        );
      }

      await dispatch<Promise<void>>(
        haltActions.getEmployeeHalts(HaltType.PersonalHalt)
      );
      if (
        haltDateRef.current!.month() === moment().month() &&
        haltDateRef.current!.year() === moment().year()
      ) {
        await dispatch<Promise<void>>(haltActions.getTodayHalts());
      }
    } catch (e) {
      handleGenericBackendError(e);
    } finally {
      handleCloseConfirmationModal();
      setIsDeleting(false);
    }
  };

  const handleCloseConfirmationModal = () => {
    haltIds.current = [];
    haltDateRef.current = undefined;
    setHaltListType('');
    setOpenConfirmationModal(false);
    setNumberOfIds(0);
  };

  const handleOpenCreateHaltModal = (option: string) => {
    setSelectedCreationOption(option);
    setOpenCreateModal(true);
  };

  const handleCloseCreateHaltModal = () => {
    setSelectedCreationOption('');
    setOpenCreateModal(false);
  };

  const handleSaveHalt = async (
    startDate: Date,
    endDate: Date,
    employeeIds?: string[],
    groupIds?: string[]
  ) => {
    setSavingInProgress(true);

    try {
      switch (selectedCreationOption) {
        case 'newCompanyHalt':
          await dispatch<Promise<void>>(
            haltActions.saveHaltsForAllCompanyMembers(startDate, endDate)
          );
          dispatch(haltActions.refreshCalendar(true));

          getTodayHalts(startDate, endDate);

          break;
        case 'newPersonalHalt':
          await dispatch<Promise<void>>(
            haltActions.savePersonalHalt(startDate, endDate)
          );
          dispatch(haltActions.refreshCalendar(true));
          dispatch(haltActions.getEmployeeHalts(HaltType.PersonalHalt));
          break;
        case 'newEmployeeHalt':
          if (employeeIds?.length) {
            await dispatch<Promise<void>>(
              haltActions.saveCompanyHaltsForEmployees(
                startDate,
                endDate,
                employeeIds
              )
            );
            dispatch(haltActions.refreshCalendar(true));
            getTodayHalts(startDate, endDate);
          }
          if (groupIds?.length) {
            await dispatch<Promise<void>>(
              haltActions.saveCompanyHaltsForGroups(
                startDate,
                endDate,
                groupIds
              )
            );
            dispatch(haltActions.refreshCalendar(true));
            getTodayHalts(startDate, endDate);
          }
          break;
        default:
          break;
      }
    } catch (e: any) {
      enqueueSnackbar(
        e.isDataError
          ? e.message
          : 'An error occurred while creating halts. Please, try again later.',
        { variant: 'error' }
      );
    } finally {
      setSavingInProgress(false);
      handleCloseCreateHaltModal();
    }
  };

  const getTodayHalts = (startDate: Date, endDate: Date) => {
    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    if (
      (momentStartDate.month() === moment().month() &&
        momentStartDate.year() === moment().year()) ||
      (momentEndDate.month() === moment().month() &&
        momentEndDate.year() === moment().year())
    ) {
      dispatch(haltActions.getTodayHalts());
    }
  };
  return {
    state: {
      isDeleting,
      openConfirmationModal,
      openCreateModal,
      savingInProgress,
      selectedCreationOption,
      numberOfIds
    },
    api: {
      handleInitDeleteHalt,
      handleDeleteHalt,
      handleCloseConfirmationModal,
      handleOpenCreateHaltModal,
      handleCloseCreateHaltModal,
      handleSaveHalt
    }
  };
};

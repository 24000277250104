import React from 'react';
import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';

export interface DashboardWidgetProps {
  title: string;
}

const DashboardWidget: React.FC<
  React.PropsWithChildren<DashboardWidgetProps>
> = ({ title, children }) => {
  return (
    <>
      <Typography sx={titlesStyle}>{title}</Typography>
      {children}
    </>
  );
};

const titlesStyle: SxProps = {
  fontSize: '24px',
  marginBottom: '17px'
};

export default DashboardWidget;

import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import BasicButton from '../../../components/core/BasicButton/BasicButton';
import {
  AssignEmployeesToOnsiteTrainingDto,
  OnsiteTraining
} from '../../../entities/OnsiteTraining';
import { Employee, EmployeeGroup } from '../../../entities/Employee';
import { InfoBubble } from '../../../components/InfoBubble/InfoBubble';
import AssignEmployeesToExistingTrainingPanel from './AssignEmployeesToExistingTrainingPanel';

export interface AssignEmployeesToOnsiteTrainingModalProps {
  showModal: boolean;
  onsiteTraining: OnsiteTraining | undefined | null;
  errorMessage: string;
  savingInProgress: boolean;
  assignment: AssignEmployeesToOnsiteTrainingDto;
  onAssign: () => void;
  onClose: () => void;
  employees: Employee[];
  employeeGroups: EmployeeGroup[];
  showAssignmentType?: boolean;
  layoutType?: 'standard' | 'clickSafety';
  setAssignment: (assigment: any) => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const AssignEmployeesToOnsiteTrainingModal: React.FC<
  AssignEmployeesToOnsiteTrainingModalProps
> = ({
  showModal,
  onsiteTraining,
  errorMessage,
  employees,
  employeeGroups,
  assignment,
  savingInProgress,
  setAssignment,
  onAssign,
  onClose
}) => {
  const theme = useTheme();
  const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
  const { t } = useTranslation([
    'common',
    'assignments',
    'lessons',
    'courses',
    'learningPlans',
    'employees'
  ]);

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };
  const labelStyle = {
    color: theme.palette.common.white,
    lineHeight: '18px',
    fontSize: '15px',
    marginTop: '5px',
    marginLeft: '10px'
  };

  const buttonStyle = {
    fontWeight: 'bold'
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
    >
      <DialogTitle style={{ fontSize: '24px', marginLeft: '10px' }}>
        {`${t('assign', { ns: 'common' })} ${onsiteTraining?.name}`}
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <>
          <label style={labelStyle}>
            {`${t('selectEmployeesToAssign', { ns: 'assignments' })}`}
            <span className="text-danger">*</span>
            <InfoBubble
              text={t('selectEmployeesToAssign', { ns: 'assignments' })}
            />
          </label>
          <AssignEmployeesToExistingTrainingPanel
            onsiteTraining={onsiteTraining}
            employees={employees ?? []}
            employeeGroups={employeeGroups ?? []}
            assignment={assignment}
            setAssignment={setAssignment}
          />
          <p className="text-danger">{errorMessage}</p>
        </>
      </DialogContent>
      <DialogActions>
        {savingInProgress && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {!savingInProgress && (
          <BasicButton
            color="primary"
            width="200px"
            height="45px"
            style={buttonStyle}
            onClick={onAssign}
          >
            {`${t('btnAssignOnsiteTraining', { ns: 'onsiteTraining' })}`}
          </BasicButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default AssignEmployeesToOnsiteTrainingModal;

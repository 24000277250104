import React, { useState } from 'react';
import { Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import OverviewSlot from './OverviewSlot';
import DashboardWidget from './DashboardWidget';
import { secondsToHm } from '../../utils/dashboardUtils';
import { actionCreators as studentActions } from '../../actions/student';
import { ApplicationState } from '../../store';
import OverLessonAssignmentModal from '../../components/Analytics/Modal/OverLessonAssignmentModal';
import SubscribedUsersModal from '../../components/Analytics/Modal/SubscribedUsersModal';
import {
  DashboardDetailsSubscribedUsersType,
  DashboardDetailsType
} from '../../core/enums';
import { AssignmentContentOverview } from '../../entities/Dto/Dashboard';
import { downloadOverLessonAssignmentReport } from '../../services/report-service';
import { enqueueSnackbar } from 'notistack';
import { GridColDef } from '@mui/x-data-grid';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import { useAppDispatch } from '../../store/hooks';

const Overview = ({
  groupId,
  supervisorId,
  filterSelection,
  daysFilter,
  groupLabel,
  supervisorLabel
}: {
  groupId: string;
  supervisorId: string;
  filterSelection: boolean;
  daysFilter: string;
  groupLabel?: string;
  supervisorLabel?: string;
}) => {
  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();
  const [showOverLessonsModal, setShowOverLessonsModal] =
    useState<DashboardDetailsType>(DashboardDetailsType.None);
  const [showSubsUsersModal, setShowSubsUsersModal] =
    useState<DashboardDetailsSubscribedUsersType>(
      DashboardDetailsSubscribedUsersType.None
    );
  const [page, setPage] = useState<number>(1);
  const [isDownloadingReport, setIsDownloadingReport] =
    useState<boolean>(false);
  const { t } = useTranslation(['dashboard']);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const dashboardOverviewData = useSelector(
    (state: ApplicationState) => state?.student?.dashboardOverview
  );
  const PAGE_SIZE = 50;

  React.useEffect(() => {
    dispatch(
      studentActions.getDashboardOverviewData(
        filterSelection ? supervisorId : groupId,
        filterSelection,
        daysFilter
      )
    );
    return () => {
      dispatch(studentActions.setDashboardOverviewData(null));
    };
  }, [groupId, supervisorId, filterSelection, daysFilter]);

  const handleOverdueLessonDetails = (type: DashboardDetailsType) => {
    getOverdueLessonDetails(type, page);
    setShowOverLessonsModal(type);
  };

  const handleOverdueLessonDetailsPageChange = (pageNumber: number) => {
    setPage(pageNumber);
    getOverdueLessonDetails(showOverLessonsModal, pageNumber);
    dispatch(studentActions.resetDashboardOvewviewDetail());
  };

  const handleCloseOverdueLessonsModal = () => {
    setPage(1);
    setShowOverLessonsModal(DashboardDetailsType.None);
    dispatch(studentActions.resetDashboardOvewviewDetail());
  };

  const downloadAssignmentsOverviewReport = async (columns: GridColDef[]) => {
    try {
      setIsDownloadingReport(true);
      const data = await dispatch<Promise<AssignmentContentOverview | null>>(
        studentActions.getDashboardOverviewDetailForReport(
          showOverLessonsModal,
          filterSelection ? supervisorId : groupId,
          filterSelection,
          daysFilter
        )
      );

      downloadOverLessonAssignmentReport(data, columns, showOverLessonsModal);
    } catch (e) {
      enqueueSnackbar(t('downloadReportErrorMsg'), { variant: 'error' });
    } finally {
      setIsDownloadingReport(false);
    }
  };

  const handleSubscribedUsersDetails = (
    type: DashboardDetailsSubscribedUsersType
  ) => {
    if (filterSelection) {
      dispatch(
        studentActions.getDashboardsubscribedUsersDetail(
          type,
          supervisorId,
          filterSelection,
          daysFilter
        )
      );
    } else {
      dispatch(
        studentActions.getDashboardsubscribedUsersDetail(
          type,
          groupId,
          filterSelection,
          daysFilter
        )
      );
    }
    setShowSubsUsersModal(type);
  };

  const getOverdueLessonDetails = (
    type: DashboardDetailsType,
    pageNumber: number
  ) => {
    dispatch(
      studentActions.getDashboardOverviewDetail(
        type,
        filterSelection ? supervisorId : groupId,
        filterSelection,
        daysFilter,
        pageNumber,
        PAGE_SIZE
      )
    );
  };

  return (
    <DashboardWidget title={t('overview', { ns: 'dashboard' })}>
      <Grid container spacing={2}>
        <OverviewSlot
          label={dashboardOverviewData?.totalAssignedLessons?.toString()}
          description={t('assignedLessons')}
          labelColor={theme.palette.primary.main}
          tooltipText={t('assignedLessonsTooltip')}
          onClick={() =>
            dashboardOverviewData?.totalAssignedLessons
              ? handleOverdueLessonDetails(
                  DashboardDetailsType.TotalAssignedLesson
                )
              : undefined
          }
        />
        <OverviewSlot
          label={dashboardOverviewData?.totalCompletedLesons?.toString()}
          description={t('totalCompletedLessons')}
          labelColor={theme.palette.success.main}
          onClick={() =>
            dashboardOverviewData?.totalCompletedLesons
              ? handleOverdueLessonDetails(
                  DashboardDetailsType.TotalCompletedLessons
                )
              : undefined
          }
        />
        <OverviewSlot
          label={dashboardOverviewData?.totalOverdueLessons?.toString()}
          description={t('totalOverdueLessons')}
          labelColor={theme.palette.error.light}
          onClick={() =>
            dashboardOverviewData?.totalOverdueLessons
              ? handleOverdueLessonDetails(
                  DashboardDetailsType.TotalOverdueLessons
                )
              : undefined
          }
        />
        <OverviewSlot
          label={secondsToHm(
            dashboardOverviewData?.totalTimeSpentWatchingLessons ?? 0
          )}
          description={t('totalTimeSpentWatchingLessons')}
          labelColor={theme.palette.primary.main}
        />
        <OverviewSlot
          label={dashboardOverviewData?.totalSubscribedLearningUsers?.toString()}
          description={
            employeeActiveUpdates.employeeActiveUpdates
              ? t('activeUsersLabel')
              : t('totalActiveUsers')
          }
          labelColor={theme.palette.success.main}
          tooltipText={
            employeeActiveUpdates.employeeActiveUpdates
              ? t('totalActiveUsersTooltipNewLabel')
              : t('totalActiveUsersTooltip')
          }
          onClick={() =>
            dashboardOverviewData?.totalSubscribedLearningUsers
              ? handleSubscribedUsersDetails(
                  DashboardDetailsSubscribedUsersType.LearningUsers
                )
              : undefined
          }
        />
        <OverviewSlot
          label={dashboardOverviewData?.totalSubscribedNonLearningUsers?.toString()}
          description={
            employeeActiveUpdates.employeeActiveUpdates
              ? t('inactiveUsersLabel')
              : t('totalInactiveUsers')
          }
          labelColor={theme.palette.error.light}
          tooltipText={
            employeeActiveUpdates.employeeActiveUpdates
              ? t('totalInactiveUsersTooltipNewLabel')
              : t('totalInactiveUsersTooltip')
          }
          onClick={() =>
            dashboardOverviewData?.totalSubscribedNonLearningUsers
              ? handleSubscribedUsersDetails(
                  DashboardDetailsSubscribedUsersType.NonLearningUsers
                )
              : undefined
          }
        />
      </Grid>
      <OverLessonAssignmentModal
        modalType={showOverLessonsModal}
        onClose={handleCloseOverdueLessonsModal}
        groupLabel={!filterSelection ? groupLabel : ''}
        supervisorLabel={filterSelection ? supervisorLabel : ''}
        daysFilter={daysFilter}
        page={page}
        pageSize={PAGE_SIZE}
        onPageChange={handleOverdueLessonDetailsPageChange}
        onDownloadingReport={downloadAssignmentsOverviewReport}
        isDownloadingReport={isDownloadingReport}
      />
      <SubscribedUsersModal
        modalType={showSubsUsersModal}
        onClose={() =>
          setShowSubsUsersModal(DashboardDetailsSubscribedUsersType.None)
        }
        groupLabel={!filterSelection ? groupLabel : ''}
        supervisorLabel={filterSelection ? supervisorLabel : ''}
        daysFilter={daysFilter}
      />
    </DashboardWidget>
  );
};

export default Overview;

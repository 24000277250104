import React, { CSSProperties, useState } from 'react';
import { AssignCourseToEmployeeDto } from '../../entities/Course';
import { useTranslation } from 'react-i18next';
import BasicButton from '../core/BasicButton/BasicButton';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { actionCreators as courseActions } from '../../actions/courses';
import { LessonAssignment } from '../../entities/Lesson';
import { EnrollmentTypes, UserClaims } from '../../core/constants';
import { useLogEvent } from '../../hooks/useLogEvent';
import { LearningPlanAssignment } from '../../entities/LearningPlan';
import { HttpStatusEnum, SelfAssignContentType } from '../../core/enums';
import ConfirmationModal from '../core/ConfirmationModal/ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';
import { SelfAssignResponse } from './types';
import useCurrentUser from '../../hooks/useCurrentUser';

export interface SelfAssignButtonProps {
  id: string;
  companyId: string;
  title: string;
  type: string;
  style: CSSProperties;
  width: string;
  height: string;
  color?:
    | 'primary'
    | 'secondary'
    | 'alternative'
    | 'delete'
    | 'confirm'
    | 'revisit'
    | 'grayButton'
    | 'trial';
}

export const SelfAssignButton: React.FC<SelfAssignButtonProps> = ({
  id,
  companyId,
  title,
  type,
  style,
  width,
  height,
  color
}: SelfAssignButtonProps) => {
  const { t } = useTranslation(['assignments']);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const [contentAssignId, setContentAssignId] = useState<string>('');
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const contentTypeLabel =
    type === SelfAssignContentType.LearningPlan
      ? t('learningPlan', { ns: 'assignments' })
      : type === SelfAssignContentType.Course
        ? t('course', { ns: 'assignments' })
        : t('lesson', { ns: 'assignments' });

  const processResponse = (result: SelfAssignResponse) => {
    if (result.status === HttpStatusEnum.OK) {
      enqueueSnackbar(
        contentTypeLabel +
          ' ' +
          t('correctlySelfAssigned', { ns: 'assignments' }),
        { variant: 'success' }
      );

      return;
    }

    if (result.message) {
      setContentAssignId(result.message);
    } else {
      enqueueSnackbar(t('anExceptionOccurred', { ns: 'common' }), {
        variant: 'error'
      });
    }
  };

  const onSelfAssign = async () => {
    setSaveInProgress(true);
    let result: SelfAssignResponse | null = null;
    switch (type) {
      case SelfAssignContentType.Lesson: {
        const lessonAssignment: LessonAssignment = {
          lessonId: id,
          employeeIds: [employeeId],
          assignBy: EnrollmentTypes.SelfAssign,
          applyToAll: false
        };
        result = await dispatch<Promise<SelfAssignResponse | null>>(
          lessonActions.assignLessonToEmployees(lessonAssignment)
        );
        logEvent.logAssignLessonEvent(id, employeeId, title);
        break;
      }
      case SelfAssignContentType.Course: {
        const courseAssignment: AssignCourseToEmployeeDto = {
          courseId: id,
          employeeIds: [employeeId],
          assignBy: EnrollmentTypes.SelfAssign
        };
        result = await dispatch<Promise<SelfAssignResponse | null>>(
          courseActions.assignCourseToEmployees(courseAssignment)
        );
        logEvent.logAssignCourseEvent(id, employeeId);
        break;
      }
      case SelfAssignContentType.LearningPlan: {
        const learningPlanAssignment: LearningPlanAssignment = {
          learningPlanId: id,
          learningPlanCompanyId: companyId,
          employeeIds: [employeeId],
          assignBy: EnrollmentTypes.SelfAssign
        };
        result = await dispatch<Promise<SelfAssignResponse | null>>(
          learningPlanActions.assignLearningPlanToEmployees(
            learningPlanAssignment
          )
        );
        logEvent.logAssignLearningPlanEvent(id, companyId, employeeId, title);
        break;
      }
    }

    if (result) {
      processResponse(result);
    }
    setSaveInProgress(false);
  };

  const handleClose = () => {
    setContentAssignId('');
  };

  const handleGoToAssignment = () => {
    switch (type) {
      case SelfAssignContentType.LearningPlan:
        navigate(`/learning-plans/assignment/play/${contentAssignId}`);
        break;
      case SelfAssignContentType.Course:
        navigate(`/courses/assignment/play/${contentAssignId}`);
        break;
      case SelfAssignContentType.Lesson:
        navigate(`/lessons/assignment/play/${contentAssignId}`);
        break;
    }
    setContentAssignId('');
  };

  return (
    <>
      <BasicButton
        color={color ? color : 'primary'}
        width={width}
        height={height}
        style={style}
        onClick={onSelfAssign}
        disabled={saveInProgress}
        loading={saveInProgress}
      >
        {t('selfAssign', { ns: 'assignments' })}
      </BasicButton>
      {!!contentAssignId && (
        <ConfirmationModal
          show={true}
          showSave={true}
          showCancel={true}
          maxWidth="sm"
          saveText={t('goToAssignment', { ns: 'assignments' })}
          cancelText={t('cancel', { ns: 'common' })}
          title={contentTypeLabel + ' (' + title + ')'}
          onSave={handleGoToAssignment}
          onCancel={handleClose}
          isGrayCancelButton={true}
          showModalContentCentered={true}
        >
          <p>{t('alreadyAssigned', { ns: 'assignments' })}</p>
        </ConfirmationModal>
      )}
    </>
  );
};

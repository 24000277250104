import { ReactNode } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PageContainer from '../components/core/PageContainer/PageContainer';

interface PageDoesntExistProps {
  detailedInfo?: ReactNode;
}

const PageDoesntExist = ({ detailedInfo }: PageDoesntExistProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <PageContainer>
      <Stack sx={{ textAlign: 'center' }}>
        <Box sx={{ paddingTop: '3rem' }}>
          <Typography
            sx={{
              ...theme.typography.h4,
              padding: '0.5rem',
              color: theme.palette.primary.main
            }}
          >
            Oops!
          </Typography>
          <Typography sx={theme.typography.h4}>
            {t('thatPageDoesntExist')}
          </Typography>
        </Box>
        <Box sx={{ paddingTop: '3rem' }}>
          {!detailedInfo ? <DefaultDetailedInfo /> : detailedInfo}
        </Box>
        <Typography
          sx={{
            fontSize: '17px',
            fontWeight: 'normal',
            padding: '1rem'
          }}
        >
          {t('contactCustomSuccessSupportMsg')}
        </Typography>
      </Stack>
    </PageContainer>
  );
};

const DefaultDetailedInfo = () => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <Box>
      <Typography
        sx={{
          ...theme.typography.h5,
          color: theme.palette.primary.light
        }}
      >
        {t('pageDoesntExistDefaultDetail')}
      </Typography>
    </Box>
  );
};

export default PageDoesntExist;

import { useAuth } from 'react-oidc-context';
import { persistStore } from 'redux-persist';
import { useStore } from 'react-redux';

const useCurrentUser = () => {
  return useAuth().user;
};

export const useSignOut = () => {
  const user = useAuth();
  const store = useStore();

  const signOut = () => {
    persistStore(store).purge();
    user.clearStaleState();
    user.removeUser();
    localStorage.removeItem('company');
    sessionStorage.removeItem('contentLibraryNavigation');

    return user.signoutRedirect({
      id_token_hint: user.user?.id_token
    });
  };

  return { signOut };
};

export default useCurrentUser;

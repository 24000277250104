import {
  Skeleton,
  Card,
  CardContent,
  useMediaQuery,
  Grid as MuiGrid
} from '@mui/material';
import { CSSProperties, MutableRefObject, ReactNode, useState } from 'react';
import {
  Index,
  ListRowProps,
  WindowScroller,
  InfiniteLoader,
  AutoSizer,
  List
} from 'react-virtualized';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';

import { DefaultPageSize } from '../../../core/constants';
import { PagedResult } from '../../../entities/Common';
import {
  OnsiteTraining,
  OnsiteTrainingClass
} from '../../../entities/OnsiteTraining';
import OnsiteTrainingCardScheduled from '../../../components/OnsiteTrainingCard/OnsiteTrainingCardScheduled';
import AssignEmployeesToOnsiteTraining from '../assignments/AssignEmployeesToOnsiteTraining';
import { useOnsiteTrainingClassStart } from '../../../hooks/useOnsiteTrainingClassStart';
import ManageUpcomingOnsiteTrainingModal from '../manageUpcomingOnsiteTraining/ManageUpcomingOnsiteTrainingModal';
import { useAppDispatch } from '../../../store/hooks';

const useGetNumberOfColumns = () => {
  const isOneColumn = useMediaQuery('(max-width:640px)');
  const twoColumns = useMediaQuery('(max-width:1100px)');

  if (isOneColumn) {
    return 1;
  } else if (twoColumns) {
    return 2;
  } else {
    return 3;
  }
};

interface ScheduledOnsiteTrainingListProps {
  onsiteTrainingClasses: PagedResult<OnsiteTrainingClass> | null | undefined;
  currentSearch: string;
  currentSort: string;
  page: MutableRefObject<number>;
}

const ScheduledOnsiteTrainingList: React.FC<
  ScheduledOnsiteTrainingListProps
> = ({ onsiteTrainingClasses, currentSearch, currentSort, page }) => {
  const dispatch = useAppDispatch();
  const numberOfColumns = useGetNumberOfColumns();
  const { start } = useOnsiteTrainingClassStart();
  const [showAddEmpsModal, setShowAddEmpsModal] = useState(false);
  const [showManageModal, setShowManageModal] = useState<boolean>(false);
  const [selectedOnsiteTraining, setSelectedOnsiteTraining] =
    useState<OnsiteTraining | null>(null);
  const [selectedOnsiteTrainingClassId, setSelectedOnsiteTrainingClassId] =
    useState<string>('');
  const [selectedOnsiteTrainingClass, setSelectedOnsiteTrainingClass] =
    useState<OnsiteTrainingClass | null>(null);
  let rowCount = 0;
  if (onsiteTrainingClasses) {
    rowCount = onsiteTrainingClasses.totalItems / numberOfColumns;

    if (rowCount < 1) {
      rowCount = 1;
    }

    if (onsiteTrainingClasses.hasNextPage) {
      rowCount += 1;
    }
    rowCount = Math.ceil(rowCount);
  }

  const isRowLoaded = ({ index }: Index) => {
    const realIndex = index * numberOfColumns;
    return (
      !onsiteTrainingClasses?.hasNextPage ||
      realIndex < (onsiteTrainingClasses?.pageItems.length ?? 0)
    );
  };

  const loadNextPage = async () => {
    page.current += 1;
    await dispatch<Promise<void>>(
      onsiteTrainingActions.loadOnsiteTrainingClasses(
        page.current,
        DefaultPageSize,
        currentSearch,
        currentSort,
        'incomplete'
      )
    );
  };

  const renderRowWrapper = (
    content: ReactNode,
    style: CSSProperties,
    key: string
  ) => {
    return (
      <div style={style} key={key}>
        <MuiGrid container spacing={2}>
          {content}
        </MuiGrid>
      </div>
    );
  };

  const loadMoreRows = !onsiteTrainingClasses
    ? () => new Promise<any>(resolve => resolve)
    : loadNextPage;

  const renderRow = (props: ListRowProps) => {
    const { index, style, key } = props;
    const data = onsiteTrainingClasses ? onsiteTrainingClasses.pageItems : [];
    let content;

    if (!isRowLoaded({ index })) {
      content = Array.from({ length: numberOfColumns }, (v, i) => i).map(() => (
        <MuiGrid item xs={12 / numberOfColumns}>
          <Card sx={{ width: '100%' }}>
            <Skeleton
              sx={{ height: 190 }}
              animation="wave"
              variant="rectangular"
            />

            <CardContent>
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={10} width="80%" />
              </>
            </CardContent>
          </Card>
        </MuiGrid>
      ));

      return renderRowWrapper(content, style, key);
    }

    const realIndex = index * numberOfColumns;
    const startItemIndex = realIndex;
    const endIndex = startItemIndex + numberOfColumns;

    const items = [];
    for (let i = startItemIndex; i < endIndex && i < data.length; i++) {
      if (!data[i]) {
        break;
      }

      items.push(data[i]);
    }

    content = items.map(item => (
      <MuiGrid item xs={12 / numberOfColumns}>
        <OnsiteTrainingCardScheduled
          onStart={() => {
            start(item);
          }}
          onAddEmployees={() => {
            handleAddEmployees(item);
          }}
          onManage={() => handleOpenManageModal(item)}
          onsiteTrainingClass={item}
          key={item.id}
        />
      </MuiGrid>
    ));

    return renderRowWrapper(content, style, key);
  };

  const handleAddEmployees = (item: OnsiteTrainingClass) => {
    setSelectedOnsiteTraining(item.onsiteTraining);
    setSelectedOnsiteTrainingClassId(item.id);
    setShowAddEmpsModal(true);
  };

  const handleCloseEmployeesModal = () => {
    setShowAddEmpsModal(false);
    setSelectedOnsiteTraining(null);
    setSelectedOnsiteTrainingClassId('');
  };

  const handleOpenManageModal = (onsiteTrainingClass: OnsiteTrainingClass) => {
    setSelectedOnsiteTrainingClass(onsiteTrainingClass);
    setShowManageModal(true);
  };

  const handleCloseManageModal = () => {
    setShowManageModal(false);
    setSelectedOnsiteTrainingClass(null);
  };

  return (
    <>
      <WindowScroller scrollElement={window}>
        {({ height, onChildScroll, scrollTop, isScrolling }) => (
          <div
            style={{
              flex: '1 1 auto'
            }}
          >
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={rowCount}
              threshold={10}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      autoHeight
                      className="window-scroller-override"
                      height={height}
                      ref={registerChild}
                      onRowsRendered={onRowsRendered}
                      onScroll={onChildScroll}
                      rowCount={rowCount}
                      rowHeight={336}
                      isScrolling={isScrolling}
                      rowRenderer={renderRow}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        )}
      </WindowScroller>
      {showAddEmpsModal && (
        <AssignEmployeesToOnsiteTraining
          onClose={handleCloseEmployeesModal}
          open={showAddEmpsModal}
          onsiteTraining={selectedOnsiteTraining}
          onsiteTrainingClassId={selectedOnsiteTrainingClassId}
        />
      )}
      {showManageModal && !!selectedOnsiteTrainingClass && (
        <ManageUpcomingOnsiteTrainingModal
          onsiteTrainingClass={selectedOnsiteTrainingClass}
          onCloseModal={handleCloseManageModal}
        />
      )}
    </>
  );
};

export default ScheduledOnsiteTrainingList;

import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuAccount } from '@buildwitt/component-library';
import { MenuAccountItem } from '@buildwitt/component-library/dist/components/MenuAccount/MenuAccount';

import useCurrentUser, { useSignOut } from '../../hooks/useCurrentUser';
import config from '../../config';
import usePermissions from '../../hooks/usePermissions';
import { appPermissions } from '../../core/constants';
import ProfilePicture from '../../assets/profilePicture.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';

const TopMenuAccount: React.FC = () => {
  const { signOut } = useSignOut();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const user = useCurrentUser();
  const userName = user?.profile['BuildWitt.User.Username'] as string;
  const profileImageUrl = user?.profile[
    'BuildWitt.User.ProfileImageUrl'
  ] as string;
  const hasPermission = usePermissions();
  const { t } = useTranslation(['common']);
  const {
    variables: {
      newCertificatesFlow: { newCertificatesFlow }
    }
  } = useDevCycleSdk();

  const logout = () => {
    signOut();
  };

  const menuItems: Array<MenuAccountItem> = user
    ? [
        {
          title: t('manageAccount', { ns: 'common' }),
          url: `${config.IDENTITY_URL}/Identity/Account/Manage/ChangePassword`,
          show: true
        },
        {
          title: t('manageCompany', { ns: 'common' }),
          url: `${config.IDENTITY_URL}/Settings?id=${user.profile['BuildWitt.Company.CompanyId']}`,
          show: hasPermission(appPermissions.MANAGE_COMPANY)
        },
        {
          title: t('manageEmployees', { ns: 'common' }),
          url: `${config.IDENTITY_URL}/Employees/${user.profile['BuildWitt.Company.CompanyId']}#/${user.profile['BuildWitt.Company.CompanyId']}/employees`,
          show: hasPermission(appPermissions.MANAGE_COMPANY)
        },
        {
          title: t('manageSubscriptions', { ns: 'common' }),
          url: `${config.IDENTITY_URL}/Subscriptions/${user.profile['BuildWitt.Company.CompanyId']}`,
          show: hasPermission(appPermissions.MANAGE_COMPANY)
        },
        {
          title: t('profile', { ns: 'common' }),
          url: '/profile',
          show: newCertificatesFlow
        },
        {
          title: t('signOut', { ns: 'common' }),
          url: '#',
          show: true,
          onClick: logout
        }
      ]
    : [];

  return (
    <div style={menuContainerStyle}>
      <MenuAccount
        id="menu-account"
        userName={userName}
        menuItems={menuItems}
        userProfileImageUrl={profileImageUrl ?? ProfilePicture}
        iconSize={matches ? 35 : 32}
      />
    </div>
  );
};

const menuContainerStyle: CSSProperties = {
  paddingRight: '0.5rem'
};

export default TopMenuAccount;

import DurationSelect from './DurationSelect';
import NumberSelect from './NumberSelect';
import { DurationUnit } from './state';

interface DurationEditorProps {
  durationUnit: DurationUnit;
  durationValue: number;
  onChangeDurationUnit: (durationUnit: DurationUnit) => void;
  onChangeDurationValue: (durationValue: number) => void;
}

export const DurationEditor: React.FC<DurationEditorProps> = ({
  durationUnit,
  durationValue,
  onChangeDurationUnit,
  onChangeDurationValue
}) => (
  <div className="d-flex flex-row px-2">
    <DurationSelect
      selectValue={durationUnit}
      onChange={items => onChangeDurationUnit(items[0] as DurationUnit)}
    />
    <div className="d-flex flex-row px-2">
      <NumberSelect
        selectValue={durationValue.toString()}
        onChange={items => onChangeDurationValue(parseInt(items[0]))}
        durationSelected={durationUnit}
      />
    </div>
  </div>
);

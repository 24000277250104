import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';
import { appPermissions } from '../../core/constants';
import { CompletedLearningPlanInfo } from '../../entities/CompletedLearningPlanInfo';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import { LearningPlanUnit, LearningPlan } from '../../entities/LearningPlan';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useUserCanEditContent } from '../../hooks/useUserCanEditContent';
import LearningPlanRowWrapper from '../../pages/contentLibrary/common/LearningPlanRowWrapper';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import { restrictedLpShouldShow } from '../../utils/lessonUtils';
import {
  assignButtonStyle,
  startButtonStyle
} from '../LearningPlanCard/LearningPlanStyles';
import NewLearningPlanCard from '../LearningPlanCard/NewLearningPlanCard';
import { SelfAssignButton } from '../SelfAssignButton/SelfAssignButton';
import BasicButton from '../core/BasicButton/BasicButton';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import Grid from '@mui/material/Grid';
import { CSSProperties } from 'react';

interface LearningPlanVirtualRowProps
  extends ListChildComponentProps<LearningPlanUnit[][]> {
  completedLearningPlans?: CompletedLearningPlanInfo[] | undefined;
  takenTimesLearningPlans?: TakenTimesObject | null;
  onEditLearningPlan: (learningPlan: LearningPlan) => void;
  onStartLearningPlan: (
    learningPlan: LearningPlan,
    isPreviewMode?: boolean
  ) => void;
  onAssign: (learningPlan: LearningPlan) => void;
}

const LearningPlanVirtualRow: React.FC<LearningPlanVirtualRowProps> = ({
  data,
  index,
  style,
  takenTimesLearningPlans,
  completedLearningPlans,
  onEditLearningPlan,
  onStartLearningPlan,
  onAssign
}) => {
  const { t } = useTranslation(['courses']);
  const [userCanEditContent] = useUserCanEditContent();
  const user = useCurrentUser();
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="parentContainer">
        {data[index]
          .filter(x => restrictedLpShouldShow(x.learningPlan, userRoles))
          .map(learningPlanUnit => {
            return (
              <LearningPlanRowWrapper key={learningPlanUnit.learningPlan.id}>
                <NewLearningPlanCard
                  key={learningPlanUnit.learningPlan.id}
                  averageLessonTime={
                    learningPlanUnit.learningPlan.avgLessonTime
                      ? secondsToHHMMSS(
                          learningPlanUnit.learningPlan.avgLessonTime
                        )
                      : '00:00:00'
                  }
                  wrapperWidth={'100%'}
                  isDraft={learningPlanUnit.learningPlan.isDraft}
                  learningPlan={learningPlanUnit.learningPlan}
                  showEdit={userCanEditContent(
                    learningPlanUnit.learningPlan.companyId
                  )}
                  onEdit={() =>
                    onEditLearningPlan(learningPlanUnit.learningPlan)
                  }
                  takenTimes={
                    takenTimesLearningPlans
                      ? takenTimesLearningPlans[
                          learningPlanUnit.learningPlan.id
                        ]
                      : undefined
                  }
                  onStartPreviewMode={(learningPlan: LearningPlan) => {
                    onStartLearningPlan(learningPlan, true);
                  }}
                >
                  {!learningPlanUnit.learningPlan.isDraft && (
                    <div style={buttonSection}>
                      <ProtectedComponent
                        action={appPermissions.ASSIGN_LEARNING_PLANS}
                      >
                        <BasicButton
                          onClick={() => {
                            onAssign(learningPlanUnit.learningPlan);
                          }}
                          color="primary"
                          style={assignButtonStyle}
                        >
                          {t('assign', { ns: 'common' })}
                        </BasicButton>
                      </ProtectedComponent>
                      <SelfAssignButton
                        type="Learning Plan"
                        style={startButtonStyle}
                        id={learningPlanUnit.learningPlan.id}
                        companyId={learningPlanUnit.learningPlan.companyId}
                        title={learningPlanUnit.learningPlan.name}
                        width="105px"
                        height="35px"
                        color="grayButton"
                      />
                    </div>
                  )}
                </NewLearningPlanCard>
              </LearningPlanRowWrapper>
            );
          })}
      </Grid>
    </div>
  );
};

const buttonSection: CSSProperties = {
  display: 'flex',
  flexDirection: 'row' as const,
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px'
};

export default LearningPlanVirtualRow;

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { SubmitHandler } from 'react-hook-form';
import { AxiosError } from 'axios';

import ModalComponent from '../../components/core/ModalPage/ModalComponent';
import CertificationForm from './CertificationForm';
import { UserClaims } from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as certificationActions } from '../../actions/certifications';
import {
  CertificationPostPut,
  DurationUnit,
  Certification
} from '../../entities/Certification';
import { useAppDispatch } from '../../store/hooks';
import { Tag } from '../../entities/Tag';
import DeleteCertificationConfirmationModal from './Common/DeleteCertificationConfirmationModal';

interface EditCertificatonProps {
  closeModal: () => void;
  certification: Certification;
}

const EditCertificaton = ({
  closeModal,
  certification
}: EditCertificatonProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const [updatingInProgress, setUpdatingInProgress] = useState<boolean>(false);
  const [deletingInProgress, setDeletingInProgress] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const returnDayTags = (days: string) => {
    if (!days.length) return [];
    return days.split(',').map(
      day =>
        ({
          id: day,
          name: `${day} days before`
        }) as Tag
    );
  };
  const editCertification = useMemo(
    () =>
      ({
        id: certification.id,
        name: certification.name,
        awardersTags: [],
        duration: !certification.duration
          ? ''
          : certification.duration.toString(),
        durationUnit:
          certification.durationUnit === DurationUnit.None
            ? ''
            : certification.durationUnit,
        durationNeverExpires: certification.durationNeverExpires,
        notifyDaysBeforeExpirationTags: returnDayTags(
          certification.notifyDaysBeforeExpiration!
        ),
        thirdPartyCertificateRequired:
          certification.thirdPartyCertificateRequired,
        description: certification.description!,
        awarders: certification.awarders,
        certifyingBody: certification.certifyingBody
      }) as CertificationPostPut,
    [certification]
  );

  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
  }, []);

  const getAxiosError = (error: unknown) => {
    const axiosError = error as AxiosError;
    if (axiosError) {
      const errorData = axiosError.response?.data as any;
      return errorData?.detail ?? '';
    }

    return '';
  };

  const handleOnSubmit: SubmitHandler<CertificationPostPut> = async data => {
    setUpdatingInProgress(true);
    try {
      await certificationActions.updateCertification(data);
      dispatch(certificationActions.refetchCertifications(true));
      enqueueSnackbar(t('certificationUpdated', { ns: 'certifications' }), {
        variant: 'success'
      });
      closeModal();
    } catch (e) {
      const errorDetail = getAxiosError(e);
      enqueueSnackbar(
        errorDetail.length
          ? errorDetail
          : t('errorUpdatingCertification', { ns: 'certifications' }),
        { variant: 'error' }
      );
    } finally {
      setUpdatingInProgress(false);
    }
  };

  const handleEditCertification = () => {
    submitButtonRef.current?.click();
  };

  const handleDeleteCertification = async () => {
    setDeletingInProgress(true);
    try {
      await certificationActions.deleteCertification(certification.id);
      dispatch(certificationActions.refetchCertifications(true));
      enqueueSnackbar(t('certificationDeleted', { ns: 'certifications' }), {
        variant: 'success'
      });
      setShowConfirmationModal(false);
      closeModal();
    } catch (e) {
      const errorDetail = getAxiosError(e);
      enqueueSnackbar(
        errorDetail.length
          ? errorDetail
          : t('errorDeletingCertification', { ns: 'certifications' }),
        { variant: 'error' }
      );
    } finally {
      setDeletingInProgress(false);
    }
  };

  return (
    <>
      <ModalComponent
        showModal={true}
        title={t('editCertificationTitle', { ns: 'certifications' })}
        savingInProgress={updatingInProgress}
        maxWidth="md"
        saveButtonText={t('save', { ns: 'common' })}
        showCloseIcon={false}
        showCancelButton={true}
        closeWhenClickBackdrop={false}
        closeWhenPressingEscape={false}
        showDeleteButton={true}
        deleteButtonCaption={t('deleteCertification', { ns: 'certifications' })}
        onSave={handleEditCertification}
        onClose={closeModal}
        onDelete={() => setShowConfirmationModal(true)}
      >
        <CertificationForm
          certification={editCertification}
          onSubmit={handleOnSubmit}
          ref={submitButtonRef}
        />
      </ModalComponent>
      <DeleteCertificationConfirmationModal
        show={showConfirmationModal}
        deletingInProgress={deletingInProgress}
        title={t('deletingCertification', { ns: 'certifications' })}
        content={t('doYouWantToDeleteThisCertification', {
          ns: 'certifications'
        })}
        onCancel={() => setShowConfirmationModal(false)}
        onDelete={handleDeleteCertification}
      />
    </>
  );
};

export default EditCertificaton;

import React, { CSSProperties, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tab, { TabProps } from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Overview from './Overview';
import TopEmployees from './TopEmployees';
import WeeklyStatistics from './WeeklyStatistics';
import { UserClaims, UserRoles } from '../../core/constants';
import { actionCreators as studentActions } from '../../actions/student';
import config from '../../config';
import SupervisorFilter from '../../components/CommonFilters/SupervisorFilter';
import useCommonFilters from '../../components/CommonFilters/useCommonFilters';
import GroupFilter from '../../components/CommonFilters/GroupFilter';
import LastDaysFilter from '../../components/CommonFilters/LastDaysFilter';
import { useAppDispatch } from '../../store/hooks';

const StyleTab = styled(Tab)<TabProps>(() => ({
  '&.Mui-selected': {
    backgroundColor: 'transparent !important'
  },
  '&.MuiTab-root': {
    minWidth: 'auto',
    minHeight: 50
  }
}));

const Dashboard = () => {
  const theme = useTheme();
  const user = useCurrentUser();
  const [userHasRole] = useUserRole();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'dashboard', 'assignments']);
  const [value, setValue] = useState<string>('admin');
  const onlySupervisor =
    userHasRole([UserRoles.Supervisor]) &&
    !userHasRole([UserRoles.CompanyAdmin, UserRoles.GroupLead]);
  const {
    supervisorItems,
    supervisorFilterValue,
    groupFilterValue,
    groupItems,
    handleChangeGroup,
    handleChangesupervisor
  } = useCommonFilters();

  const [filterSelection, setFilterSelection] =
    useState<boolean>(onlySupervisor); // False: Filter by Group, true: Filter by Supervisor

  const [daysFilter, setDaysFilter] = React.useState<string>('90');
  const [groupIndex, setGroupIndex] = React.useState<string>('');
  const [groupLabel, setGroupLabel] = React.useState<string>('');
  const [supervisorIndex, setSupervisorIndex] = React.useState<string>();
  const [supervisorLabel, setSupervisorLabel] = React.useState<string>();

  useEffect(() => {
    setGroupLabel(
      groupItems.filter(item => item.value == groupIndex)[0]?.label
    );
  }, [groupIndex]);

  useEffect(() => {
    setSupervisorLabel(
      supervisorItems.filter(item => item.value == supervisorIndex)[0]?.label
    );
  }, [supervisorIndex]);

  useEffect(() => {
    if (employeeId) {
      dispatch(studentActions.getEmployeePlaylist(employeeId));
    }
  }, [employeeId]);

  const fontTab: CSSProperties = {
    fontSize: '16px',
    textTransform: 'capitalize',
    color: theme.palette.primary.main
  };

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setValue(newValue);
  };

  const AdminTabs = (
    <TabContext value={value}>
      <Grid container sx={boxTabListStyle}>
        <TabList onChange={handleChange} aria-label="Dashboard Tab">
          <StyleTab
            label={
              <Typography
                sx={{
                  ...fontTab,
                  color: `${
                    value === 'admin'
                      ? theme.palette.primary.main
                      : theme.palette.grey[100]
                  }`
                }}
              >
                {t('admin', { ns: 'dashboard' })}
              </Typography>
            }
            value="admin"
          />
        </TabList>
        <Grid item display={'flex'} alignItems={'end'}>
          <Grid container direction={'row'} spacing={'8px'}>
            {config.SUPERVISORS_FILTER && (
              <Grid item xs={12} sm={12} md={4}>
                <SupervisorFilter
                  items={supervisorItems}
                  filterValue={supervisorFilterValue}
                  onChange={items => {
                    setSupervisorIndex(items[0]);
                    handleChangesupervisor(items);
                    setFilterSelection(true);
                  }}
                />
              </Grid>
            )}
            {userHasRole([
              UserRoles.CompanyAdmin,
              UserRoles.GroupLead,
              UserRoles.BuildWittAdmin
            ]) && (
              <Grid item xs={12} sm={12} md={4}>
                <GroupFilter
                  items={groupItems}
                  filterValue={groupFilterValue}
                  onChange={items => {
                    setGroupIndex(items[0]);
                    handleChangeGroup(items);
                    setFilterSelection(false);
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={4}>
              <LastDaysFilter
                filterValue={daysFilter}
                onChange={items => {
                  setDaysFilter(items[0]);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value="admin" sx={tabPanelStyle}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item container direction={'column'} xs={12} lg={8} xl={9}>
              <Grid item>
                <Overview
                  groupId={groupFilterValue}
                  filterSelection={filterSelection}
                  supervisorId={supervisorFilterValue}
                  daysFilter={daysFilter}
                  groupLabel={groupLabel}
                  supervisorLabel={supervisorLabel}
                />
                <br />
                <WeeklyStatistics
                  groupId={groupFilterValue}
                  filterSelection={filterSelection}
                  supervisorId={supervisorFilterValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} xl={3}>
              <TopEmployees
                groupId={groupFilterValue}
                filterSelection={filterSelection}
                supervisorId={supervisorFilterValue}
                daysFilter={daysFilter}
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </TabContext>
  );

  return (
    <PageContainer>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <Box sx={boxContainerStyle}>{AdminTabs}</Box>
        </Col>
      </Row>
    </PageContainer>
  );
};

const boxContainerStyle: SxProps = {
  width: '100%'
};

const boxStyle: SxProps = {
  borderBottom: 1,
  borderColor: 'divider'
};

const boxTabListStyle: SxProps = {
  ...boxStyle,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
};

const tabPanelStyle: SxProps = {
  paddingLeft: '0',
  paddingRight: '0',
  '&.MuiTabPanel-root': {
    padding: '1px',
    paddingLeft: 0,
    paddingRight: 0
  }
};

export default Dashboard;

import { CSSProperties } from 'react';
import Duration from '../Duration';

interface ProgressBarControlProps {
  valueProgress: string;
  played: number;
  duration: number;
  handleSeekMouseDown: (event: React.MouseEvent) => void;
  handleSeekChange: (event: React.ChangeEvent) => void;
  handleSeekMouseUp: (event: React.MouseEvent) => void;
}
const ProgressBarControl: React.FC<ProgressBarControlProps> = ({
  valueProgress,
  played,
  handleSeekChange,
  handleSeekMouseDown,
  handleSeekMouseUp,
  duration
}) => {
  const styleInputProgressbar = {
    appearance: 'none' as const,
    height: '8px',
    opacity: 1,
    outline: 'none',
    margin: '0 auto',
    width: '90%',
    backgroundSize: valueProgress,
    backgroundImage: `linear-gradient(orange, orange)`,
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      background: '#efefef'
    }
  };
  return (
    <div
      style={styleProgressbar}
      className="d-flex align-items-center videoProgress"
    >
      <input
        style={{ ...styleInputProgressbar }}
        type="range"
        className="slider"
        min={0}
        max={0.999999}
        step="any"
        value={played}
        onMouseDown={handleSeekMouseDown}
        onChange={handleSeekChange}
        onMouseUp={handleSeekMouseUp}
      />
      <Duration seconds={duration * (1 - played)} className="time" />
    </div>
  );
};

const styleProgressbar: CSSProperties = {
  width: '70%'
};

export default ProgressBarControl;

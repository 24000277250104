import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import { useAnalyticsEngagementModal } from '../../../hooks/useAnalyticsEngagementModal';
import EmployeeSelfAssignmentList from './EmployeeSelfAssignmentList';

interface EmployeeCompletedAssignmentsModalProps {
  employeeId?: string;
  employeeName?: string;
  onCloseModal: () => void;
}

const EmployeeSelfAssignmentsModal = ({
  employeeId,
  employeeName,
  onCloseModal
}: EmployeeCompletedAssignmentsModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { sortOptions, openModal, employeeSelfAssignmentsData },
    api: { closeModal, handleSorting, handleDownloadReport }
  } = useAnalyticsEngagementModal(
    'employeeSelfAssignments',
    undefined,
    employeeId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t(`selfAssignedTraining`)}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
        onDownloadReport={handleDownloadReport}
        subtitle={employeeName}
      >
        <EmployeeSelfAssignmentList
          selfAssignmentsData={employeeSelfAssignmentsData}
        />
      </AssignmentStatusModal>
    </>
  );
};

export default EmployeeSelfAssignmentsModal;

import React from 'react';
import { SxProps } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';

export interface SkillsCategoriesProps {
  filterValue: string;
  onChange: (items: string[]) => void;
}

const SkillsCategories: React.FC<SkillsCategoriesProps> = ({
  onChange,
  filterValue
}) => {
  const { t } = useTranslation(['skills']);
  const value = filterValue ? [filterValue] : [];

  return (
    <BasicSelect
      labelId="skillsCategoriesFilter"
      id="skillsCategoriesFilter"
      options={[
        {
          label: t('all', { ns: 'skills' }),
          value: '',
          disabled: false,
          hidden: false
        },
        {
          label: t('skills', { ns: 'skills' }),
          value: 'Skill',
          disabled: false,
          hidden: false
        },
        {
          label: t('certificates', { ns: 'skills' }),
          value: 'Certificate',
          disabled: false,
          hidden: false
        }
      ]}
      value={value}
      handleOnChange={onChange}
      placeholder={t('categories', { ns: 'skills' })}
      theme="dark"
      multiple={false}
      sx={filterStyle}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '200px',
  width: '100%',
  height: '45px'
};

export default SkillsCategories;

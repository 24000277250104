import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormLabel from '../core/FormLabel/FormLabel';
import redCross from '../../assets/red-cross.png';
import greenCheck from '../../assets/green-check.png';
import { styled } from '@mui/system';
import { Question2Props, AnswerStatus, renderFeedback } from './Types';
import { CorrectAnswerNotice } from './CorrectAnswerNotice';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

function firstLetterUpper(answer: boolean) {
  const word = answer.toString();
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const TrueFalseQuestion2: React.FC<Question2Props<boolean>> = ({
  questionText,
  showFeedback,
  receivedAnswer,
  correctAnswer,
  answerStatus,
  onAnswerReceived
}) => {
  const { t } = useTranslation(['assessments']);

  const answersContainerStyle: CSSProperties = {
    paddingBottom: '15px'
  };

  const questionTextStyle: CSSProperties = {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '155%'
  };

  const onAnswerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { id } = event.target as any;
    const answer: boolean = id === 'true';
    onAnswerReceived(answer);
  };

  const options = [true, false];

  const renderStatusIcon = (
    answerStatus: AnswerStatus,
    option: boolean,
    correctAnswer: boolean,
    receivedAnswer?: boolean
  ) => {
    if (answerStatus !== AnswerStatus.AnswerSubmitted) {
      return null;
    }

    if (receivedAnswer === option) {
      const icon = correctAnswer === option ? greenCheck : redCross;
      return <img src={icon} alt="status" style={{ marginRight: '14px' }} />;
    }

    return null;
  };

  return (
    <Row>
      <Col>
        <FormLabel>{t('selectCorrectAnswer', { ns: 'assessments' })}</FormLabel>
        <Typography style={questionTextStyle}>{questionText}</Typography>
        <Row className="mt-5">
          {options.map(option => (
            <Col
              lg={3}
              md={6}
              style={answersContainerStyle}
              key={option.toString()}
            >
              <AnswerWrapper
                id={option.toString()}
                option={option}
                answerStatus={answerStatus}
                correctAnswer={correctAnswer}
                receivedAnswer={receivedAnswer}
                onClick={onAnswerClick}
                className="d-flex w-100 align-items-center justify-content-center"
              >
                {renderStatusIcon(
                  answerStatus,
                  option,
                  correctAnswer,
                  receivedAnswer
                )}
                {t(option.toString(), { ns: 'assessments' })}
              </AnswerWrapper>
            </Col>
          ))}
        </Row>
        <Row className="mb-3">
          <Col>
            <CorrectAnswerNotice>
              {receivedAnswer !== undefined &&
                showFeedback &&
                renderFeedback<boolean>(
                  correctAnswer,
                  firstLetterUpper(correctAnswer),
                  t('correctAnswer', { ns: 'assessments' }),
                  t('isTheCorrectAnswer', { ns: 'assessments' }),
                  receivedAnswer
                )}
            </CorrectAnswerNotice>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const escapeProps = [
  'option',
  'answerStatus',
  'correctAnswer',
  'receivedAnswer'
];

const AnswerWrapper = styled('div', {
  shouldForwardProp: prop => !escapeProps.includes(prop as string)
})<{
  option: boolean;
  answerStatus: AnswerStatus;
  correctAnswer: boolean;
  receivedAnswer?: boolean;
}>(props => {
  const { option, theme, answerStatus, correctAnswer, receivedAnswer } = props;
  let borderColor = theme.palette.grey[600];
  let backgroundColor = theme.palette.grey[600];
  let color = theme.palette.common.white;

  if (receivedAnswer !== undefined && option === receivedAnswer) {
    switch (answerStatus) {
      case AnswerStatus.NoAnswer:
        borderColor = theme.palette.common.white;
        break;
      case AnswerStatus.AnsweredSelected:
        borderColor = 'orange';
        backgroundColor = '#FF9E00';
        color = theme.palette.grey[800];
        break;
      case AnswerStatus.AnswerSubmitted:
        borderColor =
          correctAnswer === receivedAnswer
            ? theme.palette.success.main
            : theme.palette.error.light;

        backgroundColor = theme.palette.grey[900];
        break;
    }
  }

  return {
    backgroundColor: backgroundColor,
    color: color,
    fontSize: '24px',
    cursor: answerStatus !== AnswerStatus.AnswerSubmitted ? 'pointer' : 'auto',
    border: `1px solid ${borderColor}`,
    borderRadius: '5px',
    height: '81px'
  };
});

export default TrueFalseQuestion2;

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Grid, IconButton, Typography } from '@mui/material';

import EditIcon from '@buildwitt/component-library/src/icons/edit.svg';
import { EmployeeCertification } from '../../../entities/EmployeeCertification';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { LightStyleTooltip } from '../../../components/core/StyledTooltip/StyledTooltip';

export const getEmployeeId = (
  headerName: string,
  rows: EmployeeCertification[]
) => ({
  headerName: headerName,
  field: 'employeeId',
  width: 280,
  flex: 1,
  groupable: true,
  display: false,
  renderCell: (params: any) => {
    if (
      params.rowNode.type === 'group' &&
      params.field === params.rowNode.groupingField
    ) {
      return '';
    }
    const record = rows.find(x => x.employeeId === params.value);
    return <Typography>{record?.employeeFullName}</Typography>;
  }
});

export const getCertificationName = (headerName: string) => ({
  headerName: headerName,
  field: 'certificationName',
  renderCell: (params: GridRenderCellParams<EmployeeCertification>) => (
    <Typography>{params.row.certificationName}</Typography>
  ),
  sortable: true,
  groupable: false,
  width: 190,
  flex: 1
});

export const getExpirationDate = (
  headerName: string,
  neverExpiresLabel: string
) => ({
  headerName: headerName,
  field: 'expiresAt',
  renderCell: (params: GridRenderCellParams<EmployeeCertification>) => {
    if (params.rowNode.type === 'group' && params.field === 'expiresAt') {
      return '';
    }

    function expireDateToString() {
      const { certificationNeverExpires, expiresAt } = params.row;

      if (!certificationNeverExpires) {
        if (expiresAt) {
          return absUtcDateToLocalMoment(expiresAt).format('MM/DD/YYYY');
        } else {
          return '';
        }
      } else {
        return neverExpiresLabel;
      }
    }

    return <Typography>{expireDateToString()}</Typography>;
  },
  width: 140,
  groupable: false
});

export const getAttachFiles = (headerName: string, tooltipContent: string) => ({
  headerName: headerName,
  field: 'attachmentsDto',
  renderCell: (params: GridRenderCellParams<EmployeeCertification>) => {
    if (params.row.attachmentsDto) {
      return (
        <LightStyleTooltip title={tooltipContent}>
          <Typography
            sx={{
              color: '#FF9E00',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {params?.row?.attachmentsDto[0]?.name}
          </Typography>
        </LightStyleTooltip>
      );
    }
  },
  sortable: false,
  width: 250,
  groupable: false
});

export const getEditCol = (
  onEditHandle: (employeeId: string, employeeName: string) => void,
  rows: EmployeeCertification[]
) => ({
  field: 'edit',
  headerName: 'Edit',
  width: 60,
  sortable: false,
  groupable: false,
  renderCell: (params: any) => {
    const employeeId = params.rowNode.groupingKey;
    const employee = rows.find(row => row.employeeId === employeeId);

    return (
      <>
        {typeof params.row.id !== 'string' && (
          <Grid
            display={'flex'}
            style={{ width: '100%' }}
            justifyContent={'center'}
          >
            <IconButton
              style={{ padding: 0 }}
              onClick={() =>
                onEditHandle(
                  employee?.employeeId ?? '',
                  employee?.employeeFullName ?? ''
                )
              }
            >
              <img src={EditIcon} alt={`EditIcon-${params.row.id}`} />
            </IconButton>
          </Grid>
        )}
      </>
    );
  }
});

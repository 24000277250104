import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';

import { QuestionTypes } from '../../core/constants';
import NumberQuestion2Container from '../../components/AssessmentQuestions2/NumberQuestion2Container';
import MultipleChoiceQuestion2Container from '../../components/AssessmentQuestions2/MultipleChoiceQuestion2Container';
import TrueFalseQuestion2Container from '../../components/AssessmentQuestions2/TrueFalseQuestion2Container';
import { Answer, AssessmentResult, Question } from '../../entities/Assessment';

export const orderStyle: CSSProperties = {
  color: '#FF9E00',
  fontSize: '45px',
  width: '60px',
  marginRight: '24px',
  textAlign: 'right'
};

export const isQuestionAnswered = (answer: Answer) => {
  switch (answer.type) {
    case QuestionTypes.TrueFalse:
      return answer.answerChoice !== undefined;
    case QuestionTypes.Number:
      return answer.answerNumber !== undefined;
    case QuestionTypes.MultipleChoiceText:
      return answer.selectedOptionId !== undefined;
  }
};

export const renderQuestions = (
  saveAnswer: (answer: Answer) => void,
  quizResult: AssessmentResult,
  isFeedback: boolean,
  questions?: Question[],
  passed?: boolean
) => {
  const content = questions?.map(question => {
    let questionComponent = null;
    switch (question.type) {
      case QuestionTypes.TrueFalse:
        questionComponent = (
          <TrueFalseQuestion2Container
            question={question}
            setAnswer={saveAnswer}
            savedAnswer={quizResult?.answers.find(
              a => a.questionId === question.id
            )}
            isShowingResultsView={isFeedback}
            shouldShowCorrectAnswer={passed ?? false}
          />
        );
        break;
      case QuestionTypes.Number:
        questionComponent = (
          <NumberQuestion2Container
            question={question}
            setAnswer={saveAnswer}
            savedAnswer={quizResult?.answers.find(
              a => a.questionId === question.id
            )}
            isShowingResultsView={isFeedback}
            shouldShowCorrectAnswer={passed ?? false}
          />
        );
        break;
      case QuestionTypes.MultipleChoiceText:
        questionComponent = (
          <MultipleChoiceQuestion2Container
            question={question}
            setAnswer={saveAnswer}
            options={question.options!}
            savedAnswer={quizResult?.answers.find(
              a => a.questionId === question.id
            )}
            isShowingResultsView={isFeedback}
            shouldShowCorrectAnswer={passed ?? false}
          />
        );
        break;
    }

    return (
      <Grid container key={question.id}>
        <Grid item xs="auto" style={orderStyle}>
          {question.order! + 1}.
        </Grid>
        <Grid item xs>
          {questionComponent}
          <hr />
        </Grid>
      </Grid>
    );
  });

  return content;
};

export const buildAnswer = (question: Question, results?: AssessmentResult) => {
  if (!results) {
    return {
      type: question.type!,
      answerChoice: undefined,
      answerNumber: undefined,
      isCorrect: undefined,
      order: question.order,
      questionId: question.id
    };
  }

  return results.answers.find(q => q.questionId === question.id)!;
};

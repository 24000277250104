import React from 'react';
import { SxProps } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { SortingOptions, SortingSpanishOptions } from '../../core/constants';

export interface SortSkillsProps {
  filterValue: string;
  onChange: (items: string[]) => void;
}

const SortSkills: React.FC<SortSkillsProps> = ({ onChange, filterValue }) => {
  const { t } = useTranslation(['skills']);
  const value = filterValue ? [filterValue] : ['desc'];
  const userLang = navigator.language.substring(0, 2);
  const SortingFilterOptions =
    userLang === 'es' ? SortingSpanishOptions : SortingOptions;

  return (
    <BasicSelect
      labelId="skillsCategoriesFilter"
      id="skillsCategoriesFilter"
      options={SortingFilterOptions}
      value={value}
      handleOnChange={onChange}
      placeholder={t('sortBy', { ns: 'skills' })}
      theme="dark"
      multiple={false}
      sx={filterStyle}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '200px',
  width: '100%',
  height: '45px'
};

export default SortSkills;

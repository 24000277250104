import { CSSProperties, useState, useEffect, useContext, FC } from 'react';
import { useSelector } from 'react-redux';
import { defaultTheme } from '@buildwitt/component-library';
import { ThemeProvider, useTheme, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { ApplicationState } from '../../store';
import {
  UserRoles,
  UserClaims,
  CourseUnitTypes,
  LessonUnitTypes,
  LearningPlanUnitTypes,
  DefaultSearchDebounceTime
} from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';
import { LessonUnit } from '../../entities/Lesson';
import {
  filterLearningUnitsByContentStatus,
  filterLearningUnitsBySearchTerm,
  filterLearningUnitsByTags,
  sortCoursesOrLearninpPlans,
  sortLessons
} from '../../utils/contentLibraryUtils';
import { CourseUnit } from '../../entities/Course';
import { LearningPlanUnit } from '../../entities/LearningPlan';
import AllCoursesNew from '../../components/ContentLibrary/AllCoursesNew';
import AllLearningPlansNew from '../../components/ContentLibrary/AllLearningPlansNew';
import AllLessonsNew from '../../components/ContentLibrary/AllLessonsNew';
import { ContentLibraryTabNames } from '../../core/enums';
import LearningUnitTypeFilter from './common/LearningUnitTypeFilter';
import ContentStatusFilter from './common/ContentStatusFilter';
import SortContentSelect from './common/SortContentSelect';
import SearchInput from './common/SearchInput';
import { UserNavigationContext } from './UserNavigationContext';
import useLearningUnitFilter from './hooks/useLearningUnitFilter';
import useLearningUnitStatusFilter from './hooks/useLearningUnitStatusFilter';
import useLearningUnitSorting from './hooks/useLearningUnitSorting';
import { useDebounce } from '../../hooks/useDebounce';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import { useEncodingStatusUpdate } from '../../hooks/useEncodingStatusUpdate';
import { TaggingField } from '../../components/TagInput/TaggingField';
import useLearningUnitTagFilter from './hooks/useLearningUnitTagFilter';
import useLearningUnitTag from './hooks/useLearningUnitTag';

interface AllContentNewProps {
  encodedLessonId: string;
}

export const AllContentNew: FC<AllContentNewProps> = ({ encodedLessonId }) => {
  const user = useCurrentUser();
  const theme = useTheme();
  const [userHasRole] = useUserRole();
  const logEvent = useLogEvent();
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId];
  const companyId = user?.profile[UserClaims.CompanyId];
  const isCompanyAdmin = userHasRole(UserRoles.CompanyAdmin);
  const isBuildwittAdmin = isCompanyAdmin && bWCompanyId === companyId;
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const [learningUnitType, handleChangeLearningUnitType] =
    useLearningUnitFilter();

  const [filterLearningUnitStatus, handleChangeLearningUnitStatus] =
    useLearningUnitStatusFilter();

  const [activeSort, handleChangeSorting] = useLearningUnitSorting();

  const [tagFilter, handleChangeLearningUnitTag] = useLearningUnitTagFilter();

  const [searchTerm, setSearchTerm] = useState<string>(
    userNavigation?.search ?? ''
  );

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  const [lessonsToShow, setLessonsToShow] = useState<LessonUnit[]>();
  const [coursesToShow, setCoursesToShow] = useState<CourseUnit[]>();
  const [learningPlansToShow, setLearningPlansToShow] =
    useState<LearningPlanUnit[]>();

  const isLessonLoading = useSelector(
    (state: ApplicationState) => state.lessons?.isLoading
  );
  const isCourseLoading = useSelector(
    (state: ApplicationState) => state.courses?.isLoading
  );
  const isLearningPlanLoading = useSelector(
    (state: ApplicationState) => state.learningPlans?.isLoading
  );
  const learningPlans = useSelector(
    (state: ApplicationState) => state.learningPlans?.learningPlans
  );
  const companyLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const buildwittLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allBWLessons
  );
  const communityLessons = useSelector(
    (state: ApplicationState) => state.lessons?.communityLessons
  );
  const companyCourses = useSelector(
    (state: ApplicationState) => state.courses?.courses
  );
  const buildwittCourses = useSelector(
    (state: ApplicationState) => state.courses?.coursesBW
  );
  const clickSafetyCourses = useSelector(
    (state: ApplicationState) => state.courses?.aiccCourses
  );
  const completedCourses = useSelector(
    (state: ApplicationState) => state.student?.completedCoursesBasicInfo
  );
  const completedLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.completedLearningPlans
  );
  //Partner content
  const partnerCourses = useSelector(
    (state: ApplicationState) => state.courses?.partnersCourses
  );
  const partnerLessons = useSelector(
    (state: ApplicationState) => state.lessons?.partnersLessons
  );
  const partnerLearningPlans = useSelector(
    (state: ApplicationState) => state.learningPlans?.partnersLearningPlans
  );

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );
  const takenTimesLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLearningPlans
  );
  const { t } = useTranslation([
    'common',
    'lessons',
    'quizzes',
    'contentLibrary'
  ]);

  const [sourceLessons, setSourceLessons] = useState<Array<LessonUnit>>();
  const [sourceCourses, setSourceCourses] = useState<Array<CourseUnit>>();
  const [sourceLps, setSourceLps] = useState<Array<LearningPlanUnit>>();

  const tags = useLearningUnitTag(
    learningUnitType,
    sourceLessons,
    sourceCourses
  );

  useEffect(() => {
    if (searchTerm) {
      logEvent.logLibrarySearchEvent(searchTerm);
    }
  }, [searchTerm]);

  useEncodingStatusUpdate(
    encodedLessonId,
    null,
    sourceLessons,
    setSourceLessons
  );

  useEffect(() => {
    userNavigationApi?.changeSearch(searchTerm);

    switch (learningUnitType) {
      case 'lessons':
        if (sourceLessons && !isLessonLoading) {
          const lessons = getLessonsToShow(sourceLessons);
          setLessonsToShow(lessons);
        }
        break;
      case 'courses':
        if (sourceCourses && !isCourseLoading) {
          const courses = getCoursesToShow(sourceCourses);
          setCoursesToShow(courses);
        }
        break;
      case 'learningPlans':
        if (sourceLps && !isLearningPlanLoading) {
          const learningPlans = getLearningPlansToShow(sourceLps);
          setLearningPlansToShow(learningPlans);
        }
        break;
      default:
        break;
    }
  }, [
    learningUnitType,
    sourceLessons,
    sourceCourses,
    sourceLps,
    activeSort,
    filterLearningUnitStatus,
    searchTerm,
    isLearningPlanLoading,
    isCourseLoading,
    isLessonLoading,
    tagFilter
  ]);

  useEffect(() => {
    if (
      !isLessonLoading &&
      companyLessons &&
      buildwittLessons &&
      partnerLessons
    ) {
      let lessonUnits: LessonUnit[] = companyLessons!.map(lesson => ({
        lesson,
        lessonType: LessonUnitTypes.companyLesson
      }));

      lessonUnits = lessonUnits.concat(
        buildwittLessons.map(lesson => ({
          lesson,
          lessonType: LessonUnitTypes.bWLesson
        }))
      );

      if (partnerLessons.length > 0) {
        lessonUnits = lessonUnits.concat(
          partnerLessons
            .filter(lesson => lesson.companyId !== companyId)
            .map(lesson => ({
              lesson,
              lessonType: LessonUnitTypes.partnerLesson
            }))
        );
      }

      if (communityLessons) {
        lessonUnits = lessonUnits.concat(
          communityLessons.map(lesson => ({
            lesson,
            lessonType: LessonUnitTypes.communityLesson
          }))
        );
      }

      setSourceLessons(lessonUnits);
    }
  }, [
    isLessonLoading,
    companyLessons,
    communityLessons,
    buildwittLessons,
    partnerLessons
  ]);

  useEffect(() => {
    if (
      !isCourseLoading &&
      companyCourses &&
      buildwittCourses &&
      partnerCourses
    ) {
      let courseUnits: CourseUnit[] = companyCourses!.map(course => ({
        course,
        courseType: CourseUnitTypes.companyCourse
      }));

      courseUnits = courseUnits.concat(
        buildwittCourses
          .filter(course => isBuildwittAdmin || !course.isDraft)
          .map(course => ({ course, courseType: CourseUnitTypes.bWCourse }))
      );
      if (partnerCourses.length > 0) {
        courseUnits = courseUnits.concat(
          partnerCourses
            .filter(course => course.companyId !== companyId)
            .map(course => ({
              course,
              courseType: CourseUnitTypes.partnerCourse
            }))
        );
      }

      if (clickSafetyCourses) {
        courseUnits = courseUnits.concat(
          clickSafetyCourses.map(course => ({
            course,
            courseType: CourseUnitTypes.clickSafetyCourse
          }))
        );
      }

      setSourceCourses(courseUnits);
    }
  }, [
    isCourseLoading,
    companyCourses,
    buildwittCourses,
    clickSafetyCourses,
    partnerCourses
  ]);

  useEffect(() => {
    if (!isLearningPlanLoading && learningPlans && partnerLearningPlans) {
      let learningPlanUnits: LearningPlanUnit[] = learningPlans
        .filter(
          learningPlan =>
            learningPlan.companyId === companyId ||
            (learningPlan.companyId === bWCompanyId && !learningPlan.isDraft)
        )
        .map(learningPlan => ({
          learningPlan,
          learningPlanType:
            learningPlan.companyId === bWCompanyId
              ? LearningPlanUnitTypes.bWLearningPlan
              : LearningPlanUnitTypes.companyLearningPlan
        }));
      if (partnerLearningPlans.length > 0) {
        learningPlanUnits = learningPlanUnits.concat(
          partnerLearningPlans
            .filter(learningPlan => learningPlan.companyId !== companyId)
            .map(learningPlan => ({
              learningPlan,
              learningPlanType: LearningPlanUnitTypes.partnerLearningPlan
            }))
        );
      }
      setSourceLps(learningPlanUnits);
    }
  }, [isLearningPlanLoading, learningPlans, partnerLearningPlans]);

  const getLearningPlansToShow = (learningPlans: LearningPlanUnit[]) => {
    let lpsOutput = filterLearningUnitsBySearchTerm<LearningPlanUnit>(
      searchTerm,
      item => item.learningPlan.name,
      item => item.learningPlan.description,
      learningPlans
    ) as LearningPlanUnit[];

    lpsOutput = filterLearningUnitsByContentStatus<LearningPlanUnit>(
      lpsOutput,
      item => item.learningPlan.isDraft,
      filterLearningUnitStatus
    );

    return sortCoursesOrLearninpPlans(
      lpsOutput,
      activeSort,
      item => item.learningPlan.name,
      item => item.learningPlan.createdTimestampUtc!,
      item => item.learningPlan.isDraft
    );
  };

  const getCoursesToShow = (courses: CourseUnit[]) => {
    let coursesOutput = filterLearningUnitsBySearchTerm<CourseUnit>(
      searchTerm,
      item => item.course.title,
      item => item.course.description,
      courses,
      item => item.course.tags
    ) as CourseUnit[];

    coursesOutput = filterLearningUnitsByContentStatus(
      coursesOutput,
      item => item.course.isDraft,
      filterLearningUnitStatus
    ) as CourseUnit[];

    coursesOutput = filterLearningUnitsByTags<CourseUnit>(
      tagFilter,
      item => item.course.tags,
      coursesOutput
    ) as CourseUnit[];

    return sortCoursesOrLearninpPlans(
      coursesOutput,
      activeSort,
      item => item.course.title,
      item => item.course.createdAt!,
      item => item.course.isDraft
    );
  };

  const handleChangeLearningUnitTypeEvent = (items: string[]) => {
    handleChangeLearningUnitTag([]);
    handleChangeLearningUnitType(items);
  };

  const getLessonsToShow = (lessons: LessonUnit[]) => {
    let lessonsOutput = filterLearningUnitsBySearchTerm<LessonUnit>(
      searchTerm,
      item => item.lesson.title,
      item => item.lesson.description,
      lessons,
      item => item.lesson.tags
    ) as LessonUnit[];

    lessonsOutput = filterLearningUnitsByTags<LessonUnit>(
      tagFilter,
      item => item.lesson.tags,
      lessonsOutput
    ) as LessonUnit[];

    return sortLessons<LessonUnit>(
      lessonsOutput,
      activeSort,
      item => item.lesson.title,
      item => item.lesson.createdAt!
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          flexDirection={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          sx={{
            flexDirection: {
              '@media (max-width: 1080px)': {
                flexDirection: 'column'
              },
              lg: 'row'
            }
          }}
          gap={2}
        >
          <Box
            flexDirection={'row'}
            display={'flex'}
            gap={2}
            justifyContent={'space-between'}
          >
            <FormControl style={formControlContainer} variant="standard">
              <LearningUnitTypeFilter
                value={learningUnitType}
                onChange={handleChangeLearningUnitTypeEvent}
              />
            </FormControl>
            <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
          </Box>
          <Box
            flexDirection={'row'}
            display={'flex'}
            gap={2}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            {learningUnitType !== 'lessons' &&
              userHasRole(UserRoles.CompanyAdmin) && (
                <FormControl style={formControlContainer} variant="standard">
                  <ContentStatusFilter
                    value={filterLearningUnitStatus}
                    onChange={handleChangeLearningUnitStatus}
                  />
                </FormControl>
              )}
            <FormControl style={formControlContainer} variant="standard">
              <SortContentSelect
                value={activeSort}
                onChange={handleChangeSorting}
              />
            </FormControl>
            {learningUnitType !== 'learningPlans' && (
              <FormControl
                sx={{
                  width: { xs: '100%', md: '400px' }
                }}
              >
                <TaggingField
                  options={tags}
                  value={tagFilter}
                  onChange={handleChangeLearningUnitTag}
                  showAddTagsLabel={false}
                  allowNewTags={false}
                  minHeight="45px"
                  placeholder={t('searchTagsHere', { ns: 'contentLibrary' })}
                />
              </FormControl>
            )}
          </Box>
        </Box>

        {learningUnitType === 'lessons' && (
          <AllLessonsNew
            totalItems={sourceLessons?.length ?? 0}
            isLoading={isLessonLoading!}
            lessonUnits={lessonsToShow ?? []}
            origin={ContentLibraryTabNames.AllContent}
            takenTimesLessons={takenTimesLessons}
          />
        )}

        {learningUnitType === 'courses' && (
          <AllCoursesNew
            totalItems={sourceCourses?.length ?? 0}
            courseUnits={coursesToShow ?? []}
            isLoading={isCourseLoading!}
            completedCourses={completedCourses}
            takenTimesCourses={takenTimesCourses}
          />
        )}

        {learningUnitType === 'learningPlans' && (
          <AllLearningPlansNew
            totalItems={sourceLps?.length ?? 0}
            learningPlanUnits={learningPlansToShow ?? []}
            isLoading={isLearningPlanLoading!}
            completedLearningPlans={completedLearningPlans}
            takenTimesLearningPlans={takenTimesLearningPlans}
          />
        )}
      </Box>
      <br />
    </ThemeProvider>
  );
};

const formControlContainer: CSSProperties = {
  width: '200px'
};

import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { actionCreators as analyticsActions } from '../actions/analytics';
import { EngagementType } from '../core/enums';
import {
  AnalyticsGroupEngagement,
  AnalyticsGroupEngagementDetail
} from '../entities/Analytics';
import { ApplicationState } from '../store';
import { sortByKey } from '../utils/sorting';
import {
  downloadAnalyticsGroupEngagementDetailReport,
  downloadAnalyticsGroupEngagementReport
} from '../services/report-service';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsCommonFilterContext } from '../contexts/AnalyticsContext';
import { useAppDispatch } from '../store/hooks';

export type EngagementDataType =
  | AnalyticsGroupEngagement[]
  | AnalyticsGroupEngagementDetail[];

export const useAnalyticsEngagement = (engagementType: EngagementType) => {
  let searchTimeout: NodeJS.Timeout;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['analytics']);
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const sortOptions = (() => {
    switch (engagementType) {
      case EngagementType.Groups:
        return [
          {
            label: t('mostCurrentAssignments', { ns: 'analytics' }),
            value: 'mostCurrentAssignments'
          },
          {
            label: t('mostEmployeesInGroup', { ns: 'analytics' }),
            value: 'mostEmployeesInGroup'
          },
          {
            label: t('mostTotalCompletedAssignments', { ns: 'analytics' }),
            value: 'mostTotalCompletedAssignments'
          },
          {
            label: t('groupWithMostTimeSpentInTraining', { ns: 'analytics' }),
            value: 'groupWithMostTimeSpentInTraining'
          }
        ];
      case EngagementType.Employees:
      case EngagementType.GroupDetail:
        return [
          {
            label: t('mostAssignments', { ns: 'analytics' }),
            value: 'mostAssignments'
          },
          {
            label: t('mostSelfAssigned', { ns: 'analytics' }),
            value: 'mostSelfAssigned'
          },
          {
            label: t('mostOverdue', { ns: 'analytics' }),
            value: 'mostOverdue'
          },
          {
            label: t('mostTotalCompletions', { ns: 'analytics' }),
            value: 'mostTotalCompletions'
          },
          {
            label: t('mostTimeSpentLearning', { ns: 'analytics' }),
            value: 'mostTimeSpentLearning'
          },
          { label: t('lastActive', { ns: 'analytics' }), value: 'lastActive' },
          { label: t('notActive', { ns: 'analytics' }), value: 'notActive' },
          { label: 'A-Z', value: 'az' },
          { label: 'Z-A', value: 'za' }
        ];
    }
  })();
  const [data, setData] = useState<EngagementDataType | null>();
  const [includeSelfAssignments, setIncludeSelfAssignments] =
    useState<boolean>(true);
  const [filterOption, setFilterOption] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [selectedGroupId, setSelectedGroupId] = useState<string>();
  const [selectedGroupName, setSelectedGroupName] = useState<string>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();
  const [selectedEmployeeName, setSelectedEmployeeName] = useState<string>();
  const [modalType, setModalType] = useState<string>('');
  const groupEngagementDetailData = useSelector(
    (state: ApplicationState) => state.analytics?.groupEngagementDetailData
  );
  const groupEngagementData = useSelector(
    (state: ApplicationState) => state.analytics?.groupEngagementData
  );

  useEffect(() => {
    let contentData: EngagementDataType | null | undefined;
    switch (engagementType) {
      case EngagementType.Groups:
        contentData = groupEngagementData
          ? sortData(groupEngagementData, sortOption)
          : groupEngagementData;
        break;
      case EngagementType.Employees:
      case EngagementType.GroupDetail:
        contentData = groupEngagementDetailData
          ? sortData(groupEngagementDetailData, sortOption)
          : groupEngagementDetailData;
        break;
    }
    setData(contentData);
  }, [groupEngagementDetailData, groupEngagementData]);

  useEffect(() => {
    setSortOption(sortOptions[0].value);
  }, [engagementType]);

  const fetchDataForEngagementOverview = () => {
    const activeOnly = filterOption === 'active';
    const subscribedOnly = filterOption === 'subscribed';
    switch (engagementType) {
      case EngagementType.Groups:
        dispatch(
          analyticsActions.getGroupEngagementData(
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            groupId
          )
        );
        break;
      case EngagementType.Employees:
        dispatch(
          analyticsActions.getEmployeeEngagementData(
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            groupId,
            supervisorId
          )
        );
    }
  };

  const fetchDataForGroupEngagementDetail = (
    groupId: string,
    includeSelfAssignments: boolean,
    filterOption: string
  ) => {
    const activeOnly = filterOption === 'active';
    const subscribedOnly = filterOption === 'subscribed';
    dispatch(
      analyticsActions.getGroupEngagementDetailData(
        groupId,
        includeSelfAssignments,
        activeOnly,
        subscribedOnly
      )
    );
  };

  const sortData = (source: EngagementDataType, sortOption: string) => {
    if (engagementType === EngagementType.Groups) {
      const groupSource = source as unknown as AnalyticsGroupEngagement[];
      switch (sortOption) {
        case 'mostCurrentAssignments':
          return groupSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagement>('currentAssignments'))
            .reverse();
        case 'mostEmployeesInGroup':
          return groupSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagement>('employeesInGroup'))
            .reverse();
        case 'mostTotalCompletedAssignments':
          return groupSource
            .slice()
            .sort(
              sortByKey<AnalyticsGroupEngagement>('totalCompletedAssignments')
            )
            .reverse();
        case 'groupWithMostTimeSpentInTraining':
          return groupSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagement>('timeSpentInTraining'))
            .reverse();
        default:
          return groupSource;
      }
    } else {
      const employeeSource =
        source as unknown as AnalyticsGroupEngagementDetail[];
      switch (sortOption) {
        case 'mostAssignments':
          return employeeSource
            .slice()
            .sort(
              sortByKey<AnalyticsGroupEngagementDetail>('currentAssignments')
            )
            .reverse();
        case 'mostSelfAssigned':
          return employeeSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagementDetail>('selfAssigned'))
            .reverse();
        case 'mostOverdue':
          return employeeSource
            .slice()
            .sort(
              sortByKey<AnalyticsGroupEngagementDetail>('overdueAssignments')
            )
            .reverse();
        case 'mostTotalCompletions':
          return employeeSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagementDetail>('totalCompleted'))
            .reverse();
        case 'mostTimeSpentLearning':
          return employeeSource
            .slice()
            .sort(
              sortByKey<AnalyticsGroupEngagementDetail>('timeSpentInTraining')
            )
            .reverse();
        case 'lastActive':
        case 'notActive': {
          const withLastActive = employeeSource
            .slice()
            .filter(item => item.lastActive)
            .sort(sortByKey<AnalyticsGroupEngagementDetail>('lastActive'))
            .reverse();
          const withoutLastActive = employeeSource.filter(
            item => !item.lastActive
          );
          return sortOption === 'lastActive'
            ? withLastActive.concat(withoutLastActive)
            : withoutLastActive.concat(withLastActive);
        }
        case 'az':
          return employeeSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagementDetail>('firstName'));
        case 'za':
          return employeeSource
            .slice()
            .sort(sortByKey<AnalyticsGroupEngagementDetail>('firstName'))
            .reverse();
        default:
          return employeeSource;
      }
    }
  };

  const handleSearchData = (searchFilter: string) => {
    if (
      (engagementType === EngagementType.Groups && !groupEngagementData) ||
      (engagementType !== EngagementType.Groups && !groupEngagementDetailData)
    ) {
      return;
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      let filteredData: EngagementDataType = [];
      switch (engagementType) {
        case EngagementType.Groups:
          filteredData = groupEngagementData!.filter(item =>
            item.employeeGroupName
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
          );
          break;
        case EngagementType.Employees:
        case EngagementType.GroupDetail:
          filteredData = groupEngagementDetailData!.filter(item =>
            `${item.firstName} ${item.lastName}`
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
          );
          break;
      }

      const sortedData = sortData(filteredData, sortOption);
      setData(sortedData);
      setSearchFilter(searchFilter);
    }, 1000);
  };

  const handleChangeSortOption = (items: string[]) => {
    if (!data) {
      return;
    }

    const sortOption = items[0] as string;
    const sortedData = sortData(data, sortOption);
    setData(sortedData);
    setSortOption(sortOption);
  };

  const handleChangeFilterOption = (items: string[]) => {
    setFilterOption(items[0]);
  };

  const handleDownloadCsvReport = () => {
    if (!data) {
      return;
    }

    setDownloadingReport(true);
    try {
      if (engagementType === EngagementType.Groups) {
        downloadAnalyticsGroupEngagementReport(
          data as unknown as AnalyticsGroupEngagement[]
        );
      } else {
        downloadAnalyticsGroupEngagementDetailReport(
          data as unknown as AnalyticsGroupEngagementDetail[]
        );
      }
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setDownloadingReport(false);
    }
  };

  const resetData = () => {
    setData(undefined);
  };

  const handleClickGroupActionButton = (
    action: string,
    groupId: string,
    groupName: string
  ) => {
    setModalType(action);
    setSelectedGroupId(groupId);
    setSelectedGroupName(groupName);
  };

  const handleClickEmployeeActionButton = (
    action: string,
    employeeId: string,
    employeeName: string
  ) => {
    setModalType(action);
    setSelectedEmployeeId(employeeId);
    setSelectedEmployeeName(employeeName);
  };

  const handleCloseModal = () => {
    setModalType('');
    setSelectedGroupId(undefined);
    setSelectedGroupName(undefined);
    setSelectedEmployeeId(undefined);
    setSelectedEmployeeName(undefined);
  };

  return {
    state: {
      data,
      sortOptions,
      searchFilter,
      sortOption,
      filterOption,
      includeSelfAssignments,
      downloadingReport,
      selectedGroupId,
      selectedGroupName,
      selectedEmployeeId,
      selectedEmployeeName,
      modalType
    },
    api: {
      fetchDataForEngagementOverview,
      fetchDataForGroupEngagementDetail,
      handleSearchData,
      handleChangeSortOption,
      handleChangeFilterOption,
      setIncludeSelfAssignments,
      handleDownloadCsvReport,
      resetData,
      handleClickGroupActionButton,
      handleClickEmployeeActionButton,
      handleCloseModal
    }
  };
};

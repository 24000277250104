import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';

export interface NumericInputProps {
  value: string;
  onChangeValue?: (value: number) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width: string;
  height: string;
  disabled?: boolean;
  customStyle?: CSSProperties;
  variant?: 'dark' | 'light';
  useMaxLength?: boolean;
  minValue: number;
  maxValue?: number;
  maxLength?: number;
  isInvalid?: boolean;
  dataTestId?: string;
  dataTrackId?: string;
}
const NumericInput: React.FC<NumericInputProps> = ({
  id,
  name,
  value,
  onChangeValue,
  placeholderText,
  width,
  height,
  maxLength = 90,
  disabled = false,
  customStyle = {},
  variant = 'light',
  useMaxLength = false,
  isInvalid = false,
  dataTestId = '',
  dataTrackId = '',
  minValue,
  maxValue = Number.MAX_VALUE
}) => {
  React.useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);
  const [inputValue, setInputValue] = React.useState(value);
  const theme = useTheme();
  const preStyle: CSSProperties = {
    padding: '5px',
    borderRadius: '5px',
    outline: 'none',
    paddingLeft: '8px',
    fontFamily: theme.typography.fontFamily,
    height: height,
    borderWidth: isInvalid ? 1 : 0,
    borderStyle: isInvalid ? 'solid' : 'none',
    width: width
  };

  const lightStyle: CSSProperties = {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.secondary.main,
    borderColor: isInvalid
      ? theme.palette.error.main
      : theme.palette.primary.contrastText
  };

  const darkStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isInvalid ? theme.palette.error.main : theme.palette.grey[100]
  };

  const variantStyle = variant === 'light' ? lightStyle : darkStyle;

  const inputStyle: CSSProperties = {
    ...preStyle,
    ...variantStyle,
    ...customStyle
  };

  const shouldUpdateValue = (value: string) => {
    if (maxLength) {
      if (value.toString().length <= maxLength) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };

  const handleChange = (e: any) => {
    if (shouldUpdateValue(e.target.value)) {
      setInputValue(e.target.value);
      if (onChangeValue) {
        onChangeValue(e.target.value);
      }
    }
  };

  return (
    <input
      type={'number'}
      width={width}
      height={height}
      id={id}
      name={name}
      value={inputValue}
      onChange={e => handleChange(e)}
      style={inputStyle}
      placeholder={placeholderText}
      maxLength={useMaxLength ? maxLength : undefined}
      disabled={disabled}
      min={minValue}
      max={maxValue}
      onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
      data-testid={dataTestId}
      data-trackid={dataTrackId}
    />
  );
};

export default NumericInput;

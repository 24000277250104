import { useTheme, Box, CircularProgress } from '@mui/material';
import {
  GridColDef,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridValidRowModel
} from '@mui/x-data-grid';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../CustomDataGrid/CustomDataGrid';

interface AnalyticsDataGridProps {
  rows?: GridValidRowModel[] | null;
  columns: GridColDef[];
  rowId: string;
  noRowsMessage: string;
  rowHeightFunc?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
}

const HaltsDataGrid = ({
  rows,
  columns,
  rowId,
  noRowsMessage,
  rowHeightFunc
}: AnalyticsDataGridProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '47vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.black
      }}
    >
      {!rows && <CircularProgress />}
      {rows && !!rows.length && (
        <CustomDataGrid
          getRowId={row => row[rowId]}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          getRowClassName={getCustomRowClassName}
          rowSelection={false}
          disableColumnMenu
          getRowHeight={rowHeightFunc}
        />
      )}
      {rows && !rows.length && <span>{noRowsMessage}</span>}
    </Box>
  );
};

export default HaltsDataGrid;

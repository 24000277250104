import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { CircularProgress, Stack } from '@mui/material';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Guid } from 'guid-typescript';

import { useLogEvent } from '../../../hooks/useLogEvent';
import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import { LearningPlan } from '../../../entities/LearningPlan';
import { ApplicationState } from '../../../store';
import LearningPlanForm from '../../../components/LearningPlanForm/LearningPlanForm';
import { newLearningPlan } from '../../../reducers/LearningPlansReducer';
import { actionCreators as learningPlanActions } from '../../../actions/learningPlans';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { Course } from '../../../entities/Course';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import { LibraryTypesEnum, LearningUnitType } from '../../../core/enums';
import SelectCourses from '../../courses/SelectCourses';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';

const CreateEditLearningPlan: React.FC = () => {
  const logEvent = useLogEvent();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const [learningPlan, setLearningPlan] = useState<LearningPlan>();
  const [learningPlanCourses, setLearningPlanCourses] = useState<Course[]>();
  const [showSelectCourses, setShowSelectCourse] = useState<boolean>(false);
  const [
    isDeleteCourseConfirmationModalOpen,
    setIsDeleteCourseConfirmationModalOpen
  ] = useState<boolean>(false);
  const [isDeleteLPConfirmationModalOpen, setIsDeleteLPConfirmationModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation([
    'common',
    'learningPlans',
    'contentLibrary',
    'courses'
  ]);
  const currentLearningPlan = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlan
  );
  const currentLearningPlanCourses = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlanCourses
  );
  const competenciesAssignedById = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesAssignedById
  );
  const user = useCurrentUser();
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const isPartnerCompany = partnerCompanies?.some(
    company => company.id === companyId
  );
  const forceUpdateLearningPlanState = useRef<boolean>(false);
  const forceUpdateLearningPlanCoursesState = useRef<boolean>(false);
  const fetchCourseLearningPlans = useRef<boolean>(
    location.state?.isEditing ?? false
  );
  const selectedCourseEntryId = useRef<string>('');
  const selectedCoursesRef = useRef<string[]>([]);
  const isEdit = location.state?.isEditing ?? false;
  const [isAddingCourses, setIsAddingCourses] = useState(false);

  useEffect(() => {
    if (!currentLearningPlan && isEdit && params.id) {
      dispatch(learningPlanActions.requestPlan(params.id));
    }

    const unloadCallback = () =>
      dispatch(learningPlanActions.setLearningPlanCourses([]));
    !isEdit && window.addEventListener('beforeunload', unloadCallback);
    return () => {
      dispatch(learningPlanActions.setCurrentLearningPlan(null));
      dispatch(learningPlanActions.setLearningPlanCourses([]));
      !isEdit && window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!isEdit && (!learningPlan || !learningPlanCourses)) {
      setLearningPlan(newLearningPlan);
      setLearningPlanCourses([]);
      return;
    }

    if (
      currentLearningPlan &&
      ((isEdit && fetchCourseLearningPlans.current) ||
        fetchCourseLearningPlans.current)
    ) {
      getLearningPlanCourses();
    }

    if (
      (currentLearningPlan && !learningPlan && isEdit) ||
      (currentLearningPlan && forceUpdateLearningPlanState.current)
    ) {
      setLearningPlan(currentLearningPlan);
      forceUpdateLearningPlanState.current = false;
    }
  }, [currentLearningPlan]);

  useEffect(() => {
    if (
      currentLearningPlanCourses &&
      (isEdit || forceUpdateLearningPlanCoursesState.current)
    ) {
      setLearningPlanCourses(currentLearningPlanCourses);
      forceUpdateLearningPlanCoursesState.current = false;
    }
  }, [currentLearningPlanCourses?.length]);

  const getLearningPlanCourses = async () => {
    fetchCourseLearningPlans.current = false;

    if (
      currentLearningPlan?.entries &&
      currentLearningPlan?.entries.some(e => !!e.courseId.length)
    ) {
      const courses = currentLearningPlan?.entries.filter(
        e => !!e.courseId.length
      );

      const courseIds = courses.map(course => course.courseId);
      const courseIdsStr = courseIds.join(',');
      try {
        forceUpdateLearningPlanCoursesState.current = true;
        await dispatch<Promise<void>>(
          learningPlanActions.getLearningPlanCourses(courseIdsStr)
        );
      } catch (e) {
        forceUpdateLearningPlanCoursesState.current = false;
      }
    }
  };

  const handleGoBack = () => {
    navigate('/content-library');
  };

  const handleSaveLearningPlan = async (
    learningPlanToSave: LearningPlan,
    imgFromLibrary: string,
    saveAndStay: boolean,
    isPublishing: boolean,
    thumbnailFile?: File | null
  ) => {
    try {
      if (isPublishing) learningPlanToSave.isPartnerCompany = isPartnerCompany!;
      const isCreating = learningPlanToSave.id === Guid.EMPTY;
      forceUpdateLearningPlanState.current = true;
      fetchCourseLearningPlans.current = true;

      const savedLearningPlan = await dispatch<Promise<LearningPlan>>(
        learningPlanActions.saveLearningPlan(
          learningPlanToSave,
          imgFromLibrary,
          thumbnailFile
        )
      );

      if (isPublishing) {
        logEvent.logPublishLearningPlanEvent(
          savedLearningPlan.id,
          savedLearningPlan.companyId,
          savedLearningPlan.name
        );
      }

      if (!saveAndStay) {
        const translationKey = isCreating
          ? 'learningPlanCreatedSucessfully'
          : 'learningPlanSucessfullyUpdated';
        enqueueSnackbar(t(translationKey, { ns: 'learningPlans' }), {
          variant: 'success'
        });
        handleGoBack();
        return;
      }
    } catch (e) {
      forceUpdateLearningPlanState.current = false;
      fetchCourseLearningPlans.current = false;
      throw e;
    }
  };

  const handleDeleteCourseFromLearningPlan = async () => {
    try {
      fetchCourseLearningPlans.current = true;
      forceUpdateLearningPlanState.current = true;
      await dispatch<Promise<void>>(
        learningPlanActions.deleteLearningPlanCourses(
          learningPlan!.id,
          selectedCourseEntryId.current
        )
      );
      if (learningPlanCourses?.length === 1) {
        dispatch(learningPlanActions.setLearningPlanCourses([]));
      }
    } catch (e) {
      fetchCourseLearningPlans.current = false;
      forceUpdateLearningPlanState.current = false;
      enqueueSnackbar(
        t('deleteCourseFromLPErrorMsg', { ns: 'learningPlans' }),
        { variant: 'error' }
      );
    } finally {
      setIsDeleteCourseConfirmationModalOpen(false);
    }
  };

  const handleDeleteLearningPlan = async () => {
    try {
      dispatch(
        achievementActions.deleteLearningUnitToCompetence(
          competenciesAssignedById,
          LearningUnitType.learningPlan
        )
      );
      await dispatch<Promise<void>>(
        learningPlanActions.deleteLearningPlan(learningPlan!.id!)
      );
      setIsDeleteLPConfirmationModalOpen(false);
      handleGoBack();
    } catch (e) {
      enqueueSnackbar(t('deleteLPErrorMsg', { ns: 'learningPlans' }), {
        variant: 'error'
      });
    }
  };

  const handleDeleteFullLearningPlan = async () => {
    try {
      dispatch(
        achievementActions.deleteLearningUnitToCompetence(
          competenciesAssignedById,
          LearningUnitType.learningPlan
        )
      );
      await dispatch<Promise<void>>(
        learningPlanActions.deleteFullLearningPlan(learningPlan!.id!)
      );
      setIsDeleteLPConfirmationModalOpen(false);
      handleGoBack();
    } catch (e) {
      enqueueSnackbar(t('deleteLPErrorMsg', { ns: 'learningPlans' }), {
        variant: 'error'
      });
    }
  };

  const hanleOpenDeleteCourseConfirmationModal = (courseId: string) => {
    selectedCourseEntryId.current = courseId;
    setIsDeleteCourseConfirmationModalOpen(true);
  };

  const handleCloseDeleteCourseConfirmationModal = () => {
    selectedCourseEntryId.current = '';
    setIsDeleteCourseConfirmationModalOpen(false);
  };

  const handleSaveCoursesToLearningPlan = async () => {
    setIsAddingCourses(true);
    if (!selectedCoursesRef.current.length) {
      enqueueSnackbar(t('noCoursesSelected', { ns: 'courses' }), {
        variant: 'warning'
      });
      return;
    }

    if (currentLearningPlan) {
      const entries = [...currentLearningPlan.entries];

      selectedCoursesRef.current.forEach(courseId => {
        entries!.push({
          order: 0,
          lessonId: '',
          courseId: courseId,
          assessmentId: ''
        });
        logEvent.logAddCourseToLearningPlanEvent(
          courseId,
          currentLearningPlan.id
        );
      });

      const learningPlanToSave = { ...currentLearningPlan, entries };
      try {
        fetchCourseLearningPlans.current = true;
        forceUpdateLearningPlanState.current = true;
        await dispatch<Promise<LearningPlan>>(
          learningPlanActions.saveLearningPlan(learningPlanToSave, '')
        );
      } catch (e) {
        fetchCourseLearningPlans.current = false;
        forceUpdateLearningPlanState.current = false;
        enqueueSnackbar(t('addCoursesToLPErrorMsg', { ns: 'learningPlans' }), {
          variant: 'error'
        });
      } finally {
        setShowSelectCourse(false);
      }
    }
    setIsAddingCourses(false);
  };

  const handleGetSelectedCoursesIds = (coursesIds: string[]) => {
    selectedCoursesRef.current = coursesIds;
  };

  const getDeleteAllButton = () => {
    return (
      <BasicButton onClick={handleDeleteFullLearningPlan} color="primary">
        {t('deleteAll', { ns: 'common' })}
      </BasicButton>
    );
  };

  return (
    <Stack direction="column" sx={{ paddingTop: '1rem' }}>
      <Box sx={{ width: '100%', paddingBottom: '0.5rem', paddingTop: '1rem' }}>
        <GoBackOption
          textLink={t('backContentLibrary', { ns: 'contentLibrary' })}
          onGoBack={handleGoBack}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Stack paddingBottom="1rem" paddingTop="0.5rem">
          <h4>
            {isEdit
              ? t('editLP', { ns: 'learningPlans' })
              : t('createALearningPlan', { ns: 'learningPlans' })}
          </h4>
        </Stack>
        <Stack>
          {!learningPlan && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress />
            </Box>
          )}
          {learningPlan && learningPlanCourses && (
            <>
              <LearningPlanForm
                learningPlan={learningPlan}
                learningPlanCourses={learningPlanCourses}
                isEdit={isEdit}
                setOpenSelectCourseModal={setShowSelectCourse}
                openDeleteLPConfirmationModal={
                  setIsDeleteLPConfirmationModalOpen
                }
                saveLearningPlan={handleSaveLearningPlan}
                openDeleteCourseConfirmationModal={
                  hanleOpenDeleteCourseConfirmationModal
                }
              />
              <ConfirmationModal
                show={isDeleteCourseConfirmationModalOpen}
                showSave={true}
                showCancel={true}
                maxWidth="sm"
                saveText={t('deleteCourse', { ns: 'courses' })}
                cancelText={t('cancel', { ns: 'common' })}
                title={t('deleteCourseFromLearningPlan', { ns: 'courses' })}
                onSave={handleDeleteCourseFromLearningPlan}
                onCancel={handleCloseDeleteCourseConfirmationModal}
              >
                <p>
                  {' '}
                  {t('removeCourseConfirmation', { ns: 'courses' })}: &quot;
                  {selectedCourseEntryId.current.length
                    ? learningPlanCourses?.find(
                        c => c.id === selectedCourseEntryId.current
                      )?.title
                    : ''}{' '}
                  {t('fromThisLearningPlan', { ns: 'learningPlans' })}&quot;?
                </p>
              </ConfirmationModal>
              <ConfirmationModal
                show={isDeleteLPConfirmationModalOpen}
                showSave={true}
                showCancel={true}
                maxWidth="sm"
                saveText={t('deleteLP', { ns: 'learningPlans' })}
                cancelText={t('cancel', { ns: 'common' })}
                title={t('learningPlanDeletion', { ns: 'learningPlans' })}
                onSave={handleDeleteLearningPlan}
                onCancel={() => setIsDeleteLPConfirmationModalOpen(false)}
                extraOptions={[getDeleteAllButton()]}
              >
                <p>
                  {' '}
                  {t('removeLPConfirmation', { ns: 'learningPlans' })}: &quot;
                  {learningPlan?.name}&quot;?
                </p>
              </ConfirmationModal>
              <ModalComponent
                showModal={showSelectCourses}
                title={t('addCourses', { ns: 'courses' })}
                maxWidth="md"
                saveButtonText={t('save', { ns: 'common' })}
                showCloseIcon={true}
                showCancelButton={false}
                closeWhenClickBackdrop={true}
                closeWhenPressingEscape={false}
                onSave={handleSaveCoursesToLearningPlan}
                onClose={() => setShowSelectCourse(false)}
                savingInProgress={isAddingCourses}
              >
                <SelectCourses
                  currentCourses={learningPlanCourses!}
                  coursesLibraryType={LibraryTypesEnum.CompanyAndBuildwitt}
                  getSelectedCoursesIds={handleGetSelectedCoursesIds}
                />
              </ModalComponent>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default CreateEditLearningPlan;

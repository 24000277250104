import axios from 'axios';
import { AppThunk } from '../store/index';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import config from './../config';
import {
  CompleteUnit,
  DashboardDetailsType,
  HttpStatusEnum,
  DashboardDetailsSubscribedUsersType,
  TransactionStatusEnum
} from '../core/enums';
import {
  CompletedLessonInfo,
  CompletedLessonBasicInfo,
  TakenTimesObject
} from '../entities/CompletedLessonInfo';
import {
  AssignmentsPlaylistItems,
  EmployeePlayList,
  LearningPlaylistItems
} from '../entities/LearningPlaylist';
import {
  STUDENT_MARK_LESSON_AS_COMPLETED,
  STUDENT_SET_COURSE_COMPLETED_LESSONS,
  STUDENT_SET_IS_LOADING,
  STUDENT_SET_LEARNING_PLAY_LIST,
  STUDENT_SET_ALL_LEARNING_PLAY_LIST,
  STUDENT_UPDATE_TRANSACTION_STATUS,
  STUDENT_RESET_TRANSACTION_STATUS,
  STUDENT_SET_ASSIGNMENT,
  STUDENT_SET_COMPLETED_COURSES,
  STUDENT_SET_COMPLETED_LEARNING_PLANS,
  STUDENT_SET_LEARNING_PROFILE,
  STUDENT_WEEKLY_STATISTICS,
  STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO,
  STUDENT_SET_COMPLETED_COURSES_BASIC_INFO,
  STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO,
  STUDENT_RECENTLY_WORKED_ON_PLAY_LIST,
  STUDENT_SET_TAKEN_TIMES_LESSONS,
  STUDENT_SET_TAKEN_TIMES_COURSES,
  STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS,
  STUDENT_SET_DASHBOARD_OVERVIEW_DATA,
  STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA,
  STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA,
  STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA,
  STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA
} from './actionTypes';
import {
  CompletedCourseInfo,
  CompletedCourseBasicInfo
} from '../entities/CompletedCourseInfo';
import {
  CompletedLearningPlanInfo,
  CompletedLearningPlanBasicInfo
} from '../entities/CompletedLearningPlanInfo';
import { ResultDTO } from './../entities/Dto/responseDTO';
import { LearningProfile } from '../entities/LearningProfile';
import { WeeklyStatistics } from '../entities/WeeklyStatistics';
import {
  ClientTimezoneOffset,
  WeeklyStatisticsDefault
} from '../core/constants';
import { RecentlyWorkedOnPlaylistItems } from '../entities/RecentlyWorkedOnPlaylistItems';
import {
  AssignmentContentOverview,
  DashboardOverview,
  DashboardTopEmployees
} from '../entities/Dto/Dashboard';

import { Employee } from '../entities/Employee';
import { enqueueSnackbar } from 'notistack';
import { NavigateFunction } from 'react-router';
import { handleGenericBackendError } from '../utils/errorHandling';

export const actionCreators = {
  getCompletedLessons:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let completedLessons: CompletedLessonInfo[] = [];
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}lessonprogress/all`
          );

          completedLessons = res.status === HttpStatusEnum.OK ? res.data : null;

          dispatch({
            type: STUDENT_SET_COURSE_COMPLETED_LESSONS,
            completedLessons: completedLessons
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  getCompletedLessonsByCourseId:
    (courseId: string, revisionNumber: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let completedLessons: CompletedLessonInfo[] = [];
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}lessonprogress/${courseId}/${revisionNumber}`
          );

          completedLessons = res.status === HttpStatusEnum.OK ? res.data : null;

          dispatch({
            type: STUDENT_SET_COURSE_COMPLETED_LESSONS,
            completedLessons: completedLessons
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  markLessonAsCompleted:
    (
      lessonId: string,
      courseId?: string,
      courseRevisionNumber?: number,
      employeeId?: string
    ): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const completedLesson: CompletedLessonInfo = {
          completionDateUtc: new Date(),
          lessonId,
          courseId,
          courseRevisionNumber
        };

        let wasCourseCompleted = false;
        let postData;
        if (employeeId) {
          postData = {
            courseId,
            courseRevisionNumber,
            employeeId
          };
        } else {
          postData = {
            courseId,
            courseRevisionNumber
          };
        }

        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}lessonprogress/${lessonId}`,
            { ...postData }
          );

          wasCourseCompleted = res.data;

          dispatch({
            type: STUDENT_MARK_LESSON_AS_COMPLETED,
            completedLesson
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch(appActions.setIsLoading(false));
        return wasCourseCompleted;
      }

      return false;
    },
  markLessonAssignmentAsCompleted:
    (
      lessonAssignmentId: string,
      employeeId?: string
    ): AppThunk<Promise<CompleteUnit>> =>
    async (dispatch, getState) => {
      const appState = getState();

      let completeUnit = CompleteUnit.None;

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        let postData;
        if (employeeId) {
          postData = {
            employeeId
          };
        } else {
          postData = {};
        }

        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}lessonprogress/complete-assignment/${lessonAssignmentId}`,
            { ...postData }
          );

          completeUnit = res.data as CompleteUnit;
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch(appActions.setIsLoading(false));
      }

      return completeUnit;
    },
  completeCourse:
    (
      courseId: string,
      courseRevisionNumber: number,
      courseAssignmentId: string,
      employeeId: string
    ): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let wasCourseCompleted = false;

        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}lessonprogress/completeCourse/${courseId}`,
            {
              courseAssignmentId,
              courseRevisionNumber,
              employeeId
            }
          );

          wasCourseCompleted = res.data;
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch(appActions.setIsLoading(false));
        return wasCourseCompleted;
      }

      return false;
    },
  completeLearningPlan:
    (
      learningPlanId: string,
      learningPlanRevisionNumber: number,
      learningPlanAssignmentId: string,
      employeeId: string
    ): AppThunk<Promise<boolean>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let wasLearningPlanCompleted = false;

        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}lessonprogress/completeLearningPlan/${learningPlanId}`,
            {
              learningPlanAssignmentId,
              learningPlanRevisionNumber,
              employeeId
            }
          );

          wasLearningPlanCompleted = res.data;
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch(appActions.setIsLoading(false));
        return wasLearningPlanCompleted;
      }

      return false;
    },
  getEmployeePlaylist:
    (employeeId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let resStatus = 0;
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeplaylist/${employeeId}/assignments2`
          );

          resStatus = res.status;
          dispatch({
            type: STUDENT_SET_LEARNING_PLAY_LIST,
            learningPlaylistIems:
              resStatus === HttpStatusEnum.OK ? res.data : null
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: STUDENT_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });

          dispatch({
            type: STUDENT_SET_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  getAllAssignments: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let resStatus = 0;
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL}employeeplaylist/assignments/all`
        );
        resStatus = res.status;
        dispatch({
          type: STUDENT_SET_ASSIGNMENT,
          assignmentsItems: resStatus === HttpStatusEnum.OK ? res.data : null
        });
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch({
          type: STUDENT_UPDATE_TRANSACTION_STATUS,
          transactionStatus:
            resStatus === HttpStatusEnum.OK
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed,
          errorMessage: ''
        });
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getCompletedCoursesByLearningPlanId:
    (learningPlanId: string, revisionNumber: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let resStatus = 0;
        let completedCourses: CompletedCourseInfo[] = [];
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}courseprogress/${learningPlanId}/${revisionNumber}`
          );
          resStatus = res.status;
          if (resStatus === HttpStatusEnum.OK) completedCourses = res.data;

          dispatch({
            type: STUDENT_SET_COMPLETED_COURSES,
            completedCourses: completedCourses
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: STUDENT_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
          dispatch({
            type: STUDENT_SET_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  getAllEmployeePlaylist: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    const initialChunk = 30;

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL}employeeplaylist/assignmentsPaginate/all?offset=0&limit=${initialChunk}`
        );

        dispatch({
          type: STUDENT_SET_ALL_LEARNING_PLAY_LIST,
          employeesPlaylists: res.status === HttpStatusEnum.OK ? res.data : null
        });

        if (res.data.itemTotal > initialChunk) {
          const rest = await axios.get(
            `${config.STUDENT_API_URL}employeeplaylist/assignmentsPaginate/all?offset=${initialChunk}&limit=${res.data.itemTotal}`
          );

          const allPlayList = { ...res.data };
          allPlayList.result = [...allPlayList.result, ...rest.data.result];

          dispatch({
            type: STUDENT_SET_ALL_LEARNING_PLAY_LIST,
            employeesPlaylists:
              res.status === HttpStatusEnum.OK ? allPlayList : null
          });
        }
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  getCompletedLearningPlans: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: STUDENT_SET_IS_LOADING,
        isLoading: true
      });

      let completedLearningPlans: CompletedLearningPlanInfo[] = [];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL}learningplanprogress/all`
        );

        if (res.status === HttpStatusEnum.OK) completedLearningPlans = res.data;

        dispatch({
          type: STUDENT_SET_COMPLETED_LEARNING_PLANS,
          completedLearningPlans: completedLearningPlans
        });
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    }
  },
  createLearningProfile:
    (employeeId: string, companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        const employeeWorkloadDto: LearningProfile = {
          employeeId,
          companyId
        };

        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}employeeLearningProfile`,
            {
              ...employeeWorkloadDto
            }
          );

          dispatch({
            type: STUDENT_SET_LEARNING_PROFILE,
            learningProfile: res.status === HttpStatusEnum.OK ? res.data : null
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getWeeklyStatistics:
    (
      startDate: string,
      endDate: string,
      groupId: string,
      filterSelection: boolean
    ): AppThunk =>
    async (dispatch, getState) => {
      let weeklyStatistics: WeeklyStatistics[] = [];
      const weeklyStatisticsTransform: WeeklyStatistics[] = [
        ...WeeklyStatisticsDefault
      ];
      try {
        const res = await axios.get(
          `${config.STUDENT_API_URL}courseprogress/lessons/statistics2?startDate=${startDate}&endDate=${endDate}&groupId=${groupId}&filterSelection=${filterSelection}&timezoneOffset=${ClientTimezoneOffset}`
        );
        if (res.status === HttpStatusEnum.OK) weeklyStatistics = res.data;

        // Transform
        if (weeklyStatistics.length > 0) {
          for (const statistic of weeklyStatistics) {
            const dayIndex = weeklyStatisticsTransform.findIndex(
              item => item.dayOfWeek === statistic.dayOfWeek
            );
            weeklyStatisticsTransform[dayIndex] = statistic;
          }
        }

        dispatch({
          type: STUDENT_WEEKLY_STATISTICS,
          weeklyStatistics: weeklyStatisticsTransform
        });
      } catch (e) {
        dispatch({
          type: STUDENT_WEEKLY_STATISTICS,
          weeklyStatistics: [...WeeklyStatisticsDefault]
        });
      }
    },
  getCompletedLessonsBasicInfo:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}lessonprogress/complete/byCurrentEmployee`
          );

          dispatch({
            type: STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO,
            completedLessonsBasicInfo:
              res.status === HttpStatusEnum.OK ? res.data : []
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getCompletedCoursesBasicInfo:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}courseprogress/complete/byCurrentEmployee`
          );

          dispatch({
            type: STUDENT_SET_COMPLETED_COURSES_BASIC_INFO,
            completedCoursesBasicInfo:
              res.status === HttpStatusEnum.OK ? res.data : []
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getCompletedLearningPlansBasicInfo:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}learningplanprogress/complete/byCurrentEmployee`
          );

          dispatch({
            type: STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO,
            completedLearningPlansBasicInfo:
              res.status === HttpStatusEnum.OK ? res.data : []
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCompletedCourses: (completedCourses: CompletedCourseInfo[]) => ({
    type: STUDENT_SET_COMPLETED_COURSES,
    completedCourses: completedCourses
  }),
  resetTransactionStatus: () => ({
    type: STUDENT_RESET_TRANSACTION_STATUS
  }),
  setWeeklyStatistics: (weeklyStatistics: WeeklyStatistics[] | null) => ({
    type: STUDENT_WEEKLY_STATISTICS,
    weeklyStatistics
  }),
  getRecentlyWorkedOnPlaylist:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeplaylist/recently/workedOn/${employeeId}`
          );

          dispatch({
            type: STUDENT_RECENTLY_WORKED_ON_PLAY_LIST,
            recentlyWorkedOnPlaylistItems: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setRecentlyWorkedOnPlaylist: (
    recentlyWorkedOnPlaylist: RecentlyWorkedOnPlaylistItems | null
  ) => ({
    type: STUDENT_RECENTLY_WORKED_ON_PLAY_LIST,
    recentlyWorkedOnPlaylistItems: recentlyWorkedOnPlaylist
  }),
  getTakenTimesLessons:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let takenTimesLessons: TakenTimesObject = {};
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/lesson/takenTimes/${employeeId}`
          );

          takenTimesLessons =
            res.status === HttpStatusEnum.OK ? res.data : null;

          dispatch({
            type: STUDENT_SET_TAKEN_TIMES_LESSONS,
            takenTimesLessons: takenTimesLessons
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  getTakenTimesCourses:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let takenTimesCourses: TakenTimesObject = {};
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/course/takenTimes/${employeeId}`
          );

          takenTimesCourses =
            res.status === HttpStatusEnum.OK ? res.data : null;

          dispatch({
            type: STUDENT_SET_TAKEN_TIMES_COURSES,
            takenTimesCourses: takenTimesCourses
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  getTakenTimesLearningPlans:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        let takenTimesLearningPlans: TakenTimesObject = {};
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/learningPlan/takenTimes/${employeeId}`
          );

          takenTimesLearningPlans =
            res.status === HttpStatusEnum.OK ? res.data : null;

          dispatch({
            type: STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS,
            takenTimesLearningPlans: takenTimesLearningPlans
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  getDashboardOverviewData:
    (groupId: string, filterSelection: boolean, daysFilter: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/dashboard/overview/${groupId}/${filterSelection}/${daysFilter}`
          );

          dispatch({
            type: STUDENT_SET_DASHBOARD_OVERVIEW_DATA,
            dashboardOverview: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  getDashboardOverviewDetail:
    (
      type: DashboardDetailsType,
      groupId: string,
      filterSelection: boolean,
      daysFilter: string,
      page: number,
      pageSize: number
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/dashboard/overview/details/${type}/${groupId}/${filterSelection}/${daysFilter}?page=${page}&pageSize=${pageSize}`
          );

          dispatch({
            type: STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA,
            content: res.data,
            contentType: type
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  resetDashboardOvewviewDetail: () => ({
    type: STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA
  }),
  getDashboardOverviewDetailForReport:
    (
      type: DashboardDetailsType,
      groupId: string,
      filterSelection: boolean,
      daysFilter: string
    ): AppThunk<Promise<AssignmentContentOverview | null>> =>
    async (_, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/dashboard/overview/details/${type}/${groupId}/${filterSelection}/${daysFilter}`
          );

          return res.data;
        } catch (e) {
          handleGenericBackendError(e);
        }
      }

      return null;
    },
  getDashboardsubscribedUsersDetail:
    (
      type: DashboardDetailsSubscribedUsersType,
      groupId: string,
      filterSelection: boolean,
      daysFilter: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/dashboard/subscribedUsers/${type}/${groupId}/${filterSelection}/${daysFilter}`
          );

          dispatch({
            type: STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA,
            content: res.data,
            contentType: type
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  setDashboardOverviewData: (data: DashboardOverview | null) => ({
    type: STUDENT_SET_DASHBOARD_OVERVIEW_DATA,
    dashboardOverview: data
  }),
  getDashboardTopEmployeesData:
    (
      topQuantity: number,
      groupId: string,
      filterSelection: boolean,
      daysFilter: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeLearningProfile/users/learningUnits/top/${topQuantity}/${groupId}/${filterSelection}/${daysFilter}`
          );

          dispatch({
            type: STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA,
            dashboardTopEmployees: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }

        dispatch({
          type: STUDENT_SET_IS_LOADING,
          isLoading: false
        });
      }
    },
  setDashboardTopEmployeesData: (data: DashboardTopEmployees | null) => ({
    type: STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA,
    dashboardTopEmployees: data
  }),
  performOnsiteTrainingAssignmentCheckIn:
    (
      onsiteTrainingAssignmentId: string,
      navigate: NavigateFunction
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      const requestedAttendance = 1;
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let wasCheckedIn = false;

        try {
          const res = await axios.patch(
            `${config.STUDENT_API_URL}assignments/onsiteTraining/${onsiteTrainingAssignmentId}`,
            {
              attendance: requestedAttendance
            }
          );

          wasCheckedIn = res.data;
          const message = 'Check-in succesfull.';
          enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 3000
          });
          navigate('/assignments');
        } catch (e) {
          const messageError = 'An error occurred. Please, try again later.';
          enqueueSnackbar(messageError, {
            variant: 'error',
            autoHideDuration: 3000
          });
          throw e;
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
        return wasCheckedIn;
      }
    }
};

export interface SetStudentLoading {
  type: 'STUDENT_SET_IS_LOADING';
  isLoading: boolean;
}

export interface GetCompletedLessonsByCourseId {
  type: 'STUDENT_SET_COURSE_COMPLETE_LESSONS';
  completedLessons: CompletedLessonInfo[];
}

export interface MarkLessonAsCompleted {
  type: 'STUDENT_MARK_LESSON_AS_COMPLETED';
  completedLesson: CompletedLessonInfo;
}

export interface SetEmployeePlaylist {
  type: 'STUDENT_SET_LEARNING_PLAY_LIST';
  learningPlaylistIems: LearningPlaylistItems;
}

export interface SetEmployeersPlaylists {
  type: 'STUDENT_SET_ALL_LEARNING_PLAY_LIST';
  employeesPlaylists: ResultDTO<EmployeePlayList[]>;
}

export interface UpdateStudentTransactionStatusAction {
  type: 'STUDENT_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetStudentTransactionStatusAction {
  type: 'STUDENT_RESET_TRANSACTION_STATUS';
}

export interface SetAssignmentPlaylist {
  type: 'STUDENT_SET_ASSIGNMENT';
  assignmentsItems: AssignmentsPlaylistItems[];
}

export interface GetStudentWeeklyStatistics {
  type: 'STUDENT_WEEKLY_STATISTICS';
  weeklyStatistics: WeeklyStatistics[];
}
export interface GetCompletedCourses {
  type: 'STUDENT_SET_COMPLETED_COURSES';
  completedCourses: CompletedCourseInfo[];
}

export interface GetCompletedLearningPlans {
  type: 'STUDENT_SET_COMPLETED_LEARNING_PLANS';
  completedLearningPlans: CompletedLearningPlanInfo[];
}

export interface SetLearningProfile {
  type: 'STUDENT_SET_LEARNING_PROFILE';
  learningProfile: LearningProfile;
}

export interface GetCompletedLessonsBasicInfo {
  type: 'STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO';
  completedLessonsBasicInfo: CompletedLessonBasicInfo[];
}

export interface GetCompletedCoursesBasicInfo {
  type: 'STUDENT_SET_COMPLETED_COURSES_BASIC_INFO';
  completedCoursesBasicInfo: CompletedCourseBasicInfo[];
}

export interface GetCompletedLearningPlansBasicInfo {
  type: 'STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO';
  completedLearningPlansBasicInfo: CompletedLearningPlanBasicInfo[];
}

export interface GetRecentlyWorkedOnPlaylist {
  type: 'STUDENT_RECENTLY_WORKED_ON_PLAY_LIST';
  recentlyWorkedOnPlaylistItems: RecentlyWorkedOnPlaylistItems | null;
}

export interface GetTakenTimesLessons {
  type: 'STUDENT_SET_TAKEN_TIMES_LESSONS';
  takenTimesLessons: TakenTimesObject;
}
export interface GetTakenTimesCourses {
  type: 'STUDENT_SET_TAKEN_TIMES_COURSES';
  takenTimesCourses: TakenTimesObject;
}
export interface GetTakenTimesLearningPlans {
  type: 'STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS';
  takenTimesLearningPlans: TakenTimesObject;
}

export interface GetDashboardOverviewData {
  type: 'STUDENT_SET_DASHBOARD_OVERVIEW_DATA';
  dashboardOverview: DashboardOverview | null;
}

export interface GetDashboardTopEmployeesData {
  type: 'STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA';
  dashboardTopEmployees: DashboardTopEmployees | null;
}

export interface getDashboardOverviewDetail {
  type: 'STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA';
  content: AssignmentContentOverview;
  contentType: DashboardDetailsType;
}

export interface getDashboardOverviewSubscribedUsersDetail {
  type: 'STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA';
  content: Employee;
  contentType: DashboardDetailsSubscribedUsersType;
}

export interface resetDashboardOverviewDetail {
  type: 'STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA';
}

export type KnownAction =
  | SetLoadingAction
  | GetCompletedLessonsByCourseId
  | SetStudentLoading
  | SetEmployeePlaylist
  | SetEmployeersPlaylists
  | UpdateStudentTransactionStatusAction
  | ResetStudentTransactionStatusAction
  | SetAssignmentPlaylist
  | GetCompletedCourses
  | GetCompletedLearningPlans
  | MarkLessonAsCompleted
  | SetLearningProfile
  | GetStudentWeeklyStatistics
  | GetCompletedLessonsBasicInfo
  | GetCompletedCoursesBasicInfo
  | GetCompletedLearningPlansBasicInfo
  | GetStudentWeeklyStatistics
  | GetRecentlyWorkedOnPlaylist
  | GetTakenTimesLessons
  | GetTakenTimesCourses
  | GetTakenTimesLearningPlans
  | GetDashboardOverviewData
  | GetDashboardTopEmployeesData
  | getDashboardOverviewDetail
  | getDashboardOverviewSubscribedUsersDetail
  | resetDashboardOverviewDetail;

import { Grid } from '@mui/material';
import { useGetNumberOfColumns } from '../../../hooks/useVirtualizedContentLibraryList';
import { ReactNode } from 'react';

const LessonRowWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const numberOfColumns = useGetNumberOfColumns('lessons');
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={numberOfColumns === 4 ? 3 : 4}
      paddingTop="10px"
    >
      {children}
    </Grid>
  );
};

export default LessonRowWrapper;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  AssignOnsiteTrainingDto,
  OnsiteTraining
} from '../../../entities/OnsiteTraining';
import { ApplicationState } from '../../../store';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { UserRoles, UserClaims } from '../../../core/constants';
import { TransactionStatusEnum } from '../../../core/enums';
import { useTranslation } from 'react-i18next';
import InfoModal from '../../../components/core/InfoModal/InfoModal';
import useUserRole from '../../../hooks/useUserRole';
import { enqueueSnackbar } from 'notistack';
import AssignOnsiteTrainingModal from './AssignOnsiteTrainingModal';
import { convertDateToUtcAndAddTime } from '../../../utils/dateUtils';
import { useAppDispatch } from '../../../store/hooks';

export interface AssignOnsiteTrainingProps {
  open: boolean;
  onClose: () => void;
  onsiteTraining: OnsiteTraining | undefined | null;
}

const AssignOnsiteTraining: React.FC<AssignOnsiteTrainingProps> = ({
  open,
  onClose,
  onsiteTraining
}) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();

  const transactionStatus = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const onsiteTrainingAssignmentStatus = useSelector(
    (state: ApplicationState) =>
      state?.onsiteTrainings?.onsiteTrainingAssignmentStatus
  );
  const groupAssignmentDefinitions = useSelector(
    (state: ApplicationState) => state?.lessons?.groupAssignmentDefinitions
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const emptyOnsiteTrainingAssignment: AssignOnsiteTrainingDto = {
    onsiteTrainingId: '',
    employeesIds: [],
    adminLeadingId: '',
    classesDatesUtc: [],
    times: [],
    location: '',
    numberOfDays: 1,
    assignToAllEmployees: false,
    onsiteTrainingName: ''
  };
  const [onsiteTrainingAssignment, setOnsiteTrainingAssignment] =
    useState<AssignOnsiteTrainingDto>({
      ...emptyOnsiteTrainingAssignment
    });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation([
    'lessons',
    'common',
    'assignments',
    'onsiteTraining'
  ]);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (onsiteTraining) {
      dispatch(
        onsiteTrainingActions.requestStatusOfEmployeesOnsiteTrainingAssigments(
          onsiteTraining.id
        )
      );

      setOnsiteTrainingAssignment({
        ...emptyOnsiteTrainingAssignment,
        onsiteTrainingId: onsiteTraining.id
      });
    }
  }, [onsiteTraining]);

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }

    return () => {
      dispatch(
        onsiteTrainingActions.setStatusOfEmployeesOnsiteTrainingAssigments(null)
      );
      dispatch(
        onsiteTrainingActions.setOnsiteTrainingAssignmentDefinitions(null)
      );
    };
  }, []);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionStatus === TransactionStatusEnum.Failed) {
        const message = t('anExceptionOccurred', { ns: 'common' });
        setErrorMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      } else if (transactionStatus === TransactionStatusEnum.Successfull) {
        dispatch(
          onsiteTrainingActions.requestStatusOfEmployeesOnsiteTrainingAssigments(
            onsiteTrainingAssignment.onsiteTrainingId
          )
        );
        setShowAssignmentConfirmation(true);
        setErrorMessage('');
      }
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(onsiteTrainingActions.resetTransactionStatus());

    return () => {
      dispatch(onsiteTrainingActions.resetTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message = '';

    if (!onsiteTrainingAssignment.employeesIds.length)
      message = t('selectEmployee', { ns: 'common' });
    if (
      !onsiteTrainingAssignment.adminLeadingId ||
      !onsiteTrainingAssignment.classesDatesUtc.length ||
      !onsiteTrainingAssignment.times.length
    ) {
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });
    }
    if (
      onsiteTrainingAssignment.classesDatesUtc.length !=
      onsiteTrainingAssignment.numberOfDays
    ) {
      message = t('numberOfDatesDontMatch', { ns: 'onsiteTraining' });
    }
    if (
      onsiteTrainingAssignment.classesDatesUtc.length ==
        onsiteTrainingAssignment.times.length ||
      onsiteTrainingAssignment.times.length == 1
    ) {
      if (onsiteTrainingAssignment.times.length == 1) {
        const singleTime = onsiteTrainingAssignment.times[0];
        for (
          let i = 0;
          i < onsiteTrainingAssignment.classesDatesUtc.length;
          i++
        ) {
          onsiteTrainingAssignment.classesDatesUtc[i] =
            convertDateToUtcAndAddTime(
              onsiteTrainingAssignment.classesDatesUtc[i],
              singleTime
            );
        }
      } else {
        for (
          let i = 0;
          i < onsiteTrainingAssignment.classesDatesUtc.length;
          i++
        ) {
          onsiteTrainingAssignment.classesDatesUtc[i] =
            convertDateToUtcAndAddTime(
              onsiteTrainingAssignment.classesDatesUtc[i],
              onsiteTrainingAssignment.times[i]
            );
        }
      }
    } else {
      // show numbers of days and times don't match
      message = t('datesAndDaysNotMatching', { ns: 'onsiteTraining' });
    }
    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(
      onsiteTrainingActions.assignOnsiteTraining({
        ...onsiteTrainingAssignment,
        onsiteTrainingName: onsiteTraining!.name,
        onsiteTrainingId: onsiteTraining!.id
      })
    );
  };

  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };

  return (
    <>
      <AssignOnsiteTrainingModal
        showModal={open}
        assignToEntity={onsiteTraining}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onClose={onClose}
        employees={employees || []}
        employeeGroups={employeeGroups || []}
        assignment={onsiteTrainingAssignment}
        setAssignment={setOnsiteTrainingAssignment}
        learningUnitAssigmentStatus={onsiteTrainingAssignmentStatus}
        groupAssignmentDefinitions={groupAssignmentDefinitions}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignOnsiteTraining;

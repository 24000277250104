import { useEffect, useRef, useState } from 'react';
import { Box, Stack, styled } from '@mui/system';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { Typography } from '@mui/material';

import { MyCertificationStatus } from '../../../entities/Certification';
import CardListActionsToolbar from './CardListActionsToolbar';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { actionCreators as certificationActions } from '../../../actions/certifications';
import MyCertificationList from './MyCertificationList';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';

type toolbarActions = {
  sortOption: string;
  filterOption: MyCertificationStatus[];
  searchKey: string;
};

const MyCertificationTab = () => {
  const user = useCurrentUser();
  const { t } = useTranslation(['certifications']);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const pageSize = 25;
  const pageRef = useRef<number>(1);
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const [toolbarActions, setToolbarActions] = useState<toolbarActions>({
    sortOption: 'createdAt:asc',
    filterOption: [],
    searchKey: ''
  });
  const myCertificationsPageResult = useAppSelector(
    x => x.certifications?.myCertificationsPageResult
  );
  const transactionError = useAppSelector(
    x => x.certifications?.transactionError
  );

  const fetchMyCertificationsData = () => {
    pageRef.current = 1;
    const { sortOption, filterOption, searchKey } = toolbarActions;
    dispatch(
      certificationActions.getMyCertifications(
        1,
        pageSize,
        sortOption,
        searchKey,
        filterOption,
        employeeId
      )
    );
  };

  useEffect(() => {
    fetchMyCertificationsData();

    return () => {
      dispatch(certificationActions.setMyCertifications(null));
    };
  }, []);

  useEffect(() => {
    if (myCertificationsPageResult) {
      dispatch(certificationActions.setMyCertifications(null));
      fetchMyCertificationsData();
    }
  }, [toolbarActions]);

  useEffect(() => {
    if (transactionError) {
      enqueueSnackbar(t('fetchCertificationsError'), {
        variant: 'error'
      });
      dispatch(certificationActions.setTransactionError(false));
    }
  }, [transactionError]);

  const handleUpdateToolbarActionValues = (field: string) => (value: any) => {
    const newToolbarActions = { ...toolbarActions };
    newToolbarActions[field as keyof toolbarActions] = value;
    setToolbarActions(newToolbarActions);
  };

  return (
    <Stack>
      <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
        <CardListActionsToolbar
          onUpdateToolbarActionValues={handleUpdateToolbarActionValues}
        />
      </Stack>
      <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
        {!!myCertificationsPageResult &&
          !!myCertificationsPageResult.pageItems.length && (
            <MyCertificationList
              myCertificationsPageResult={myCertificationsPageResult}
              searchKey={toolbarActions.searchKey}
              sortOption={toolbarActions.sortOption}
              filterOption={toolbarActions.filterOption}
              page={pageRef}
              pageSize={pageSize}
            />
          )}
        {!myCertificationsPageResult && (
          <Container>
            <CircularProgress />
          </Container>
        )}
        {!!myCertificationsPageResult &&
          !myCertificationsPageResult.pageItems.length && (
            <Container>
              <Typography>{t('noRecords')}</Typography>
            </Container>
          )}
      </Stack>
    </Stack>
  );
};

const Container = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '40vh',
  justifyContent: 'center',
  alignItems: 'center'
}));

export default MyCertificationTab;

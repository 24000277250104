import { useTheme } from '@mui/material/styles';
import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsOverdueAssignmentDetail } from '../../../entities/Analytics';
import { ModalDataGrid } from '../../../components/Analytics';
import {
  getFullNameColumn,
  getAssignedOnColumn,
  getCompletionStatusColumn,
  getDueDateColumn
} from '../common/utilAnalyticsColumnDefinition';

interface OverdueListProps {
  overdueData?: AnalyticsOverdueAssignmentDetail[] | null;
  onEditDueDate: (assignmentId: string, dueDate: Date) => void;
}

const OverdueList = ({ overdueData, onEditDueDate }: OverdueListProps) => {
  const theme = useTheme();
  const { t } = useTranslation([
    'employees',
    'analytics',
    'assignments',
    'common'
  ]);

  const columns: GridColDef[] = (() => [
    getFullNameColumn(t('employee', { ns: 'employees' })),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getCompletionStatusColumn(
      t('completionStatus', { ns: 'analytics' }),
      <span style={{ color: theme.palette.error.main }}>
        {t('overdue', { ns: 'assignments' })}
      </span>
    ),
    getDueDateColumn(
      t('dueDate', { ns: 'assignments' }),
      onEditDueDate,
      t('workload', { ns: 'assignments' }),
      t('edit', { ns: 'common' })
    )
  ])();

  return <ModalDataGrid rows={overdueData} columns={columns} />;
};

export default OverdueList;

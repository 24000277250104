import React, {
  CSSProperties,
  useState,
  useRef,
  HTMLAttributes,
  useMemo
} from 'react';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import './MultiDatesPicker.css';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export interface MultiDatesPickerProps {
  variant?: 'dark' | 'light';
  selectedDates: Date[];
  onDatesUpdated?: (dates: Date[]) => void;
  maxDatesSelected: number;
  preExistingNonRemovableDates?: Date[];
}

const MultiDatesPicker: React.FC<MultiDatesPickerProps> = ({
  variant,
  selectedDates,
  onDatesUpdated,
  maxDatesSelected,
  preExistingNonRemovableDates
}) => {
  const preSelectedDateObjects = useMemo(
    () => selectedDates.map(d => new DateObject(d)),
    []
  );

  const [dates, setDates] = useState<any | null>(preSelectedDateObjects);
  const theme = useTheme();
  const { t } = useTranslation(['common']);
  const dateRef = useRef<any>(null);

  const nonRemovableDateObjects = useMemo(() => {
    return preExistingNonRemovableDates
      ? preExistingNonRemovableDates.map(date => new DateObject(date))
      : [];
  }, [preExistingNonRemovableDates]);

  const baseStyle: CSSProperties = {
    width: '95%',
    borderRight: 'none',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    cursor: 'pointer'
  };

  const darkStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    borderColor: theme.palette.grey[100],
    borderRadius: '5px',
    color: theme.palette.grey[100]
  };

  const ligthStyle: CSSProperties = {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  };

  const clickableSectionStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    borderColor: 'rgb(153, 153, 153)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeft: 'none',
    height: '100%',
    minHeight: '39px',
    marginTop: '1px',
    cursor: 'pointer',
    width: '23px'
  };
  const containerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  };

  const iconButtonStyle: CSSProperties = {
    backgroundColor: 'black',
    marginTop: '5px',
    cursor: 'pointer',
    height: '99%',
    marginRight: '5px'
  };

  const yellowSection: CSSProperties = {
    backgroundColor: theme.palette.primary.main,
    width: '150px',
    borderRadius: '5px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    height: '100%',
    minHeight: '39px',
    marginTop: '1px',
    cursor: 'pointer',
    textAlign: 'center'
  };

  const yellowSectionIcon: CSSProperties = {
    color: theme.palette.common.black,
    marginTop: '5px'
  };

  const yellowSectionSpan: CSSProperties = {
    fontSize: '15px',
    marginRight: '5px',
    fontWeight: 'bold'
  };

  const handleUpdateDates = (selection: any) => {
    setDates(selection);
    if (onDatesUpdated) {
      const dates = selection.map((date: DateObject) => date.toDate());
      onDatesUpdated(dates);
    }
  };

  const clearDates = () => {
    const updatedDateObjects: DateObject[] =
      nonRemovableDateObjects.length && dates
        ? dates.filter((date: DateObject) =>
            nonRemovableDateObjects.find(
              item => item.format() === date.format()
            )
          )
        : [];
    setDates(updatedDateObjects);

    if (onDatesUpdated) {
      const updatedDates = updatedDateObjects.length
        ? updatedDateObjects.map((date: DateObject) => date.toDate())
        : [];
      onDatesUpdated(updatedDates);
    }
  };

  return (
    <div style={containerStyle}>
      <DatePicker
        style={
          variant == 'dark'
            ? { ...baseStyle, ...darkStyle }
            : { ...baseStyle, ...ligthStyle }
        }
        value={dates}
        className="calendar-popup"
        onChange={(selection: any) => {
          handleUpdateDates(selection);
        }}
        placeholder={`- ${t('select', { ns: 'common' })} -`}
        ref={dateRef}
        mapDays={({ date }) => {
          const yesterday = new DateObject().add(-1, 'day');
          const props: HTMLAttributes<HTMLSpanElement> & {
            disabled?: boolean;
            hidden?: boolean;
          } = {};
          props.style = { color: '#FFF' };

          const allRequiredDatesWereSelected =
            dates &&
            dates.length >= maxDatesSelected &&
            !dates.find((d: DateObject) => d.format() === date.format());

          const dateShouldntBeEnabled =
            date <= yesterday ||
            nonRemovableDateObjects.find(d => d.format() === date.format());

          if (allRequiredDatesWereSelected || dateShouldntBeEnabled) {
            props.style.color = '#595959';
            props.disabled = true;
          }

          return props;
        }}
      />
      <div style={clickableSectionStyle}>
        <IconButton
          style={iconButtonStyle}
          color="default"
          onClick={() => {
            clearDates();
          }}
          aria-label="Clear"
          size="small"
        >
          <FontAwesomeIcon icon={faClose} color={theme.palette.grey[100]} />
        </IconButton>
      </div>
      <div style={yellowSection}>
        <IconButton
          style={yellowSectionIcon}
          color="default"
          onClick={() => {
            if (dateRef && dateRef.current) {
              if (!dateRef.current.isOpen) {
                dateRef.current.openCalendar();
              }
            }
          }}
          aria-label="Clear"
          size="small"
        >
          <>
            <span style={yellowSectionSpan}>{`${t('select', {
              ns: 'common'
            })} `}</span>
            <FontAwesomeIcon
              icon={faCalendarDay}
              color={theme.palette.common.black}
            />
          </>
        </IconButton>
      </div>
    </div>
  );
};

export default MultiDatesPicker;

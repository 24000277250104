import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appPermissions } from '../../core/constants';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ChipAverageTime from '../core/ChipAverageTime/ChipAverageTime';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import {
  NewContentHeader,
  OverdueHeader
} from '../core/InfoCardHeader/InfoCardHeader';
import { ILearningPlanCardProps } from '../../entities/Card';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useGetCardOwnerShip } from '../../hooks/useGetCardOwnership';
import { isOverdue, isNewContent } from '../../utils/dateUtils';
import {
  coursesCount,
  editContainerStyle,
  editIconStyle,
  iconContainerStyle,
  leftDivStyle,
  planTitlePointer,
  planTitleDefault,
  startButtonStyle,
  infoSection
} from './LearningPlanStyles';
import { LearningPlanWrapper } from './LearningPlanWrapper';
import ChipTakenTimes from '../core/ChipTakenTimes/ChipTakenTimes';
import { LearningPlan } from '../../entities/LearningPlan';
import MovieIcon from '../Icons/MovieIcon';

export interface LearningPlanCardProps extends ILearningPlanCardProps {
  showEdit?: boolean;
  dueDate?: string;
  onEdit?: () => void;
  isComplete?: boolean | null;
  wrapperWidth?: string;
  onStartPreviewMode?: (learningPlan: LearningPlan) => void;
}

const NewLearningPlanCard: React.FC<
  React.PropsWithChildren<LearningPlanCardProps>
> = ({
  learningPlan,
  showEdit = true,
  dueDate,
  onEdit,
  isComplete = false,
  children,
  isDraft,
  contributorProfileImage,
  averageLessonTime,
  wrapperWidth,
  takenTimes,
  showCompletedAsNormal = false,
  onStartPreviewMode
}) => {
  const { t } = useTranslation([
    'assignments',
    'common',
    'learningPlans',
    'courses'
  ]);
  const theme = useTheme();
  const ownership = useGetCardOwnerShip(learningPlan);
  const isPreviewMode = !!onStartPreviewMode;
  const today = moment();
  const pastDueDate = moment(dueDate);
  const creationLPDate = moment(learningPlan?.createdTimestampUtc).local();
  const isOverdueLP = isOverdue(pastDueDate) && !showCompletedAsNormal;
  const isNewContentLP = isNewContent(creationLPDate) && !showCompletedAsNormal;

  const completeLearningPlan =
    isComplete && !showCompletedAsNormal
      ? theme.palette.success.light
      : `${theme.palette.grey['100']}`;
  const newLearningPlan =
    isNewContentLP && !isComplete && !isDraft
      ? theme.palette.primary.main
      : `${theme.palette.grey['100']}`;
  const failedOverdueLearningPlan = isOverdueLP
    ? theme.palette.error.main
    : `${theme.palette.grey['100']}`;

  const rightDivStyle: CSSProperties = {
    width: '2%',
    minWidth: '30px',
    minHeight: '325px',
    height: '100%',
    backgroundColor: `${theme.palette.primary.main}`,
    borderTopRightRadius: '14px',
    borderBottomRightRadius: '14px',
    textAlign: 'center' as const
  };

  const rightHeaderDivStyle: CSSProperties = {
    width: '3%',
    minWidth: '30px',
    minHeight: '200px',
    marginTop: '40px',
    backgroundColor: `${theme.palette.primary.main}`,
    borderBottomRightRadius: '14px',
    textAlign: 'center' as const
  };

  const cardSelector: CSSProperties = {
    textTransform: 'uppercase' as const,
    marginLeft: '15px',
    width: '100%',
    minWidth: '325px',
    textAlign: 'center' as const,
    paddingRight: '25px',
    color: theme.palette.grey[800],
    fontWeight: '600'
  };

  const cardHeaderSelector: CSSProperties = {
    textTransform: 'uppercase' as const,
    marginLeft: '16px',
    width: '100%',
    minWidth: '240px',
    textAlign: 'center' as const,
    paddingRight: '25px',
    color: theme.palette.grey[800],
    fontWeight: '600'
  };

  const learningPlanTitle: CSSProperties = {
    fontSize: '15px',
    fontWeight: '400',
    color: theme.palette.primary.main
  };

  const iconContainerHeaderStyle: CSSProperties = {
    textAlign: 'right',
    paddingTop: isComplete || isNewContentLP ? '25px' : '0'
  };

  const separatorStyle: CSSProperties = {
    padding: '0 0.7rem',
    color: theme.palette.grey[100]
  };

  const headerInfoAndEditStyle: CSSProperties = {
    display: 'flex',
    justifyContent: ownership === null ? 'flex-end' : 'space-between'
  };

  const handleEdit = () => {
    if (onEdit) onEdit();
  };

  const renderOwnership = () => {
    if (!isDraft && ownership) {
      return (
        <InfoBySpan
          leftText={ownership.key}
          rightText={ownership.value}
          showProfilePicture={false}
          isOverdue={false}
        />
      );
    }
    return null;
  };

  const handleStartPreviewMode = () => {
    if (isPreviewMode && onStartPreviewMode) {
      onStartPreviewMode(learningPlan);
    }
  };

  const handleStartPreviewModeCard = (event: any) => {
    if (event.target === event.currentTarget) {
      handleStartPreviewMode();
    }
  };

  return (
    <LearningPlanWrapper
      thumbnail={learningPlan?.thumbnailUrl}
      isComplete={isComplete}
      isOverdueLP={isOverdueLP}
      theme={theme}
      completeLearningPlan={completeLearningPlan}
      newLearningPlan={newLearningPlan}
      failedOverdueLearningPlan={failedOverdueLearningPlan}
      width={wrapperWidth}
    >
      {!isComplete && isOverdueLP && (
        <OverdueHeader label={t('overdue', { ns: 'assignments' })} />
      )}
      {isNewContentLP && !isComplete && !isDraft && !isOverdueLP && (
        <NewContentHeader label={t('newContent', { ns: 'common' })} />
      )}
      <div
        onClick={handleStartPreviewModeCard}
        style={{
          ...leftDivStyle,
          cursor: isPreviewMode ? 'pointer' : 'default'
        }}
      >
        <div style={headerInfoAndEditStyle}>
          {renderOwnership()}
          {showEdit && isDraft === false && (
            <div
              style={
                showEdit && isDraft === false
                  ? iconContainerHeaderStyle
                  : iconContainerStyle
              }
            >
              <ProtectedComponent action={appPermissions.ASSIGN_LEARNING_PLANS}>
                <span style={editContainerStyle} onClick={handleEdit}>
                  {t('edit', { ns: 'common' })}
                  <FontAwesomeIcon
                    icon={faPen}
                    color="white"
                    style={editIconStyle}
                  />
                </span>
              </ProtectedComponent>
            </div>
          )}
        </div>
        <div style={{ cursor: 'default' }}>
          <span style={learningPlanTitle}>
            {t('learningPlan', { ns: 'learningPlans' })}
          </span>
          <br />
          <span
            onClick={handleStartPreviewMode}
            style={isPreviewMode ? planTitlePointer : planTitleDefault}
          >
            {learningPlan?.name}
          </span>
          {isDraft === false ? (
            <div>
              <div style={infoSection}>
                <MovieIcon />
                <span style={coursesCount}>
                  {` ${learningPlan?.courseCount} ${t('courses', {
                    ns: 'courses'
                  })}`}
                </span>
                {dueDate && moment(dueDate).isValid() && (
                  <>
                    <span style={separatorStyle}>|</span>
                    <InfoBySpan
                      leftText={t('dueDate', { ns: 'assignments' })}
                      rightText={dueDate}
                      showProfilePicture={false}
                      isOverdue={
                        !isComplete && pastDueDate.isBefore(today, 'day')
                      }
                      showAsComplete={showCompletedAsNormal}
                    />
                  </>
                )}
                <br />
              </div>
              {takenTimes && (
                <div style={{ paddingBottom: '4px' }}>
                  <ChipTakenTimes takenTimes={takenTimes} />
                </div>
              )}
              {averageLessonTime && (
                <ChipAverageTime
                  showComplete={true}
                  averageLessonTime={averageLessonTime}
                />
              )}
            </div>
          ) : (
            <div style={infoSection}>
              {' '}
              <span style={{ fontSize: '12px' }}>
                {t('draftTitle', { ns: 'common' })}
              </span>
            </div>
          )}
          <br />
          {children}

          {isDraft && (
            <BasicButton
              onClick={handleEdit}
              color="primary"
              style={startButtonStyle}
            >
              {t('editDraft', { ns: 'common' })}
            </BasicButton>
          )}
        </div>
      </div>
      {isDraft ? (
        <div style={rightDivStyle}>
          <p className="vertical-text" style={cardSelector}>
            {t('learningPlan', { ns: 'learningPlans' })}
          </p>
        </div>
      ) : (
        <div
          style={
            isOverdueLP ||
            (isComplete && !showCompletedAsNormal) ||
            isNewContentLP
              ? rightHeaderDivStyle
              : rightDivStyle
          }
        >
          <p
            className="vertical-text"
            style={
              isOverdueLP ||
              (isComplete && !showCompletedAsNormal) ||
              isNewContentLP
                ? cardHeaderSelector
                : cardSelector
            }
          >
            {t('learningPlan', { ns: 'learningPlans' })}
          </p>
        </div>
      )}
    </LearningPlanWrapper>
  );
};

export default NewLearningPlanCard;

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';

import { AnalyticsEmployeeSelfAssignment } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getAssignedOnColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import moment from 'moment';

interface EmployeeCompletedAssignmentListProps {
  selfAssignmentsData?: AnalyticsEmployeeSelfAssignment[] | null;
}

const EmployeeSelfAssignmentList = ({
  selfAssignmentsData
}: EmployeeCompletedAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      300,
      learningUnitNames
    ),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    {
      field: 'completionDate',
      headerName: t('completionDate', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Grid container direction="row" sx={{ textAlign: 'left' }}>
            <Grid item xs={6}>
              <span>
                {moment(params.row.completionDate).year() === 1
                  ? absUtcDateToLocalMoment(params.row.completionDate).format(
                      'MM.DD.YYYY'
                    )
                  : t('pending', { ns: 'analytics' })}
              </span>
            </Grid>
          </Grid>
        );
      }
    }
  ])();

  return <ModalDataGrid rows={selfAssignmentsData} columns={columns} />;
};

export default EmployeeSelfAssignmentList;

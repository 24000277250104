import React, { CSSProperties } from 'react';
import { styled } from '@mui/system';

const CompleteHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-0.1px',
  background: '#2ED47A',
  color: '#0E0D0D',
  width: '120px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem',
  zIndex: 2
});

const PassedHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-0.1px',
  background: '#2ED47A',
  color: '#0E0D0D',
  width: '120px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem',
  zIndex: 2
});

const NewContentHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-0.1px',
  background: '#FF9E00',
  color: '#0E0D0D',
  width: '120px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem',
  zIndex: 2
});

const OverdueHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-0.1px',
  background: '#FF2A20',
  color: '#0E0D0D',
  width: '120px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem',
  zIndex: 2
});

const FailedHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-0.1px',
  background: '#FF2A20',
  color: '#0E0D0D',
  width: '120px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem',
  zIndex: 2
});

export const CompletedHeader = ({ label }: { label: string }) => (
  <CompleteHeaderComponent>{label}</CompleteHeaderComponent>
);

export const OverdueHeader = ({ label }: { label: string }) => (
  <OverdueHeaderComponent>
    <span style={overdueSpanStyle}>{label}</span>
  </OverdueHeaderComponent>
);

export const FailedHeader = ({ label }: { label: string }) => (
  <FailedHeaderComponent>
    <span style={overdueSpanStyle}>{label}</span>
  </FailedHeaderComponent>
);

export const NewContentHeader = ({ label }: { label: string }) => (
  <NewContentHeaderComponent>{label}</NewContentHeaderComponent>
);

export const PassedHeader = ({ label }: { label: string }) => (
  <PassedHeaderComponent>{label}</PassedHeaderComponent>
);

const overdueSpanStyle: CSSProperties = {
  fontSize: '17px'
};

import { useEffect, useState, useMemo } from 'react';
import { Box, CircularProgress, Stack, SxProps, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAuth } from 'react-oidc-context';

import { useCalendarHelper } from '../../../hooks/useCalendarHelper';
import { actionCreators as haltActions } from '../../../actions/halts';
import { ApplicationState } from '../../../store';
import { UserClaims, UserRoles } from '../../../core/constants';
import { HaltType } from '../../../core/enums';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import useUserRole from '../../../hooks/useUserRole';
import { useAppDispatch } from '../../../store/hooks';
import { Halt } from '../../../entities/Halt';

const HaltsCalendar = () => {
  const dispatch = useAppDispatch();
  const [userRole] = useUserRole();
  const { t } = useTranslation(['settings']);
  const theme = useTheme();
  const auth = useAuth();
  const employeeId = auth.user?.profile[UserClaims.EmployeeId];
  const hasAdminPermissions = userRole([
    UserRoles.CompanyAdmin,
    UserRoles.GroupLead,
    UserRoles.Supervisor
  ]);
  const {
    constants: { calendarHeaderNames, monthNames }
  } = useCalendarHelper();
  const [firstDayInMonth, setFirstDayInMonth] = useState<Moment>(
    moment().startOf('month')
  );
  const monthHalts = useSelector(
    (state: ApplicationState) => state.halts?.monthHalts
  );
  const pickedCalendarDate = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDate
  );
  const refreshCalendar = useSelector(
    (state: ApplicationState) => state.halts?.refreshCalendar
  );

  useEffect(() => {
    fetchDataForCalendar();

    return () => {
      dispatch(haltActions.setMonthHalts(null));
    };
  }, [firstDayInMonth]);

  useEffect(() => {
    if (refreshCalendar) {
      fetchDataForCalendar();
    }
  }, [refreshCalendar]);

  useEffect(() => {
    if (monthHalts && refreshCalendar)
      dispatch(haltActions.refreshCalendar(false));
  }, [monthHalts]);

  const fetchDataForCalendar = () => {
    if (hasAdminPermissions) {
      dispatch(
        haltActions.getMonthHalts(
          firstDayInMonth.year(),
          firstDayInMonth.month() + 1
        )
      );
    } else {
      dispatch(
        haltActions.getMonthHaltsForEmployee(
          firstDayInMonth.year(),
          firstDayInMonth.month() + 1
        )
      );
    }
  };

  const getFormatedDate = (year: number, day: number, month: number) => {
    const strDay = day < 10 ? '0' + day.toString() : day.toString();
    const strMonth = month < 10 ? '0' + month.toString() : month.toString();
    return `${year}-${strDay}-${strMonth}`;
  };

  const generateWeek = (
    lastDayPreviousMonth: number,
    dayOfWeek: number,
    datePreviousMonth: Moment
  ) => {
    const week = [];

    if (dayOfWeek === 1) {
      week.push({
        day: lastDayPreviousMonth,
        belongsToThisMonth: false,
        id: getFormatedDate(
          datePreviousMonth.year(),
          lastDayPreviousMonth,
          datePreviousMonth.month() + 1
        )
      });
    } else {
      for (
        let j = lastDayPreviousMonth - (dayOfWeek - 1);
        j <= lastDayPreviousMonth;
        j++
      ) {
        week.push({
          day: j,
          belongsToThisMonth: false,
          id: getFormatedDate(
            datePreviousMonth.year(),
            j,
            datePreviousMonth.month() + 1
          )
        });
      }
    }

    console.log(week);
    return week;
  };

  const generateDaysForMonth = (
    firstDayInMonth: Moment,
    daysInMonth: number,
    personalHalts: Halt[],
    companyHalts: Halt[],
    numberOfRows: number
  ) => {
    const dayOfWeek = firstDayInMonth.day();
    let dayOfMonth = 1;
    const dataForCalendar = [];

    for (let i = 0; i < numberOfRows; i++) {
      const week: any[] = [];

      if (i === 0 && dayOfWeek > 0) {
        const datePreviousMonth = firstDayInMonth
          .clone()
          .add(-1, 'months')
          .endOf('month');
        const lastDayPreviousMonth = datePreviousMonth.date();

        week.push(
          ...generateWeek(lastDayPreviousMonth, dayOfWeek, datePreviousMonth)
        );
      }

      while (week.length < 7 && dayOfMonth <= daysInMonth) {
        week.push({
          day: dayOfMonth,
          belongsToThisMonth: true,
          isPersonalTrainingHalt: !!personalHalts.find(
            halt =>
              absUtcDateToLocalMoment(halt.haltDateUtc).date() === dayOfMonth
          ),
          isCompanyTrainingHalt: !!companyHalts.find(
            halt =>
              absUtcDateToLocalMoment(halt.haltDateUtc).date() === dayOfMonth
          ),
          id: getFormatedDate(
            firstDayInMonth.year(),
            dayOfMonth,
            firstDayInMonth.month() + 1
          )
        });
        dayOfMonth++;
      }

      if (week.length < 7 && dayOfMonth > daysInMonth) {
        const firstDayNextMonth = firstDayInMonth.clone().add(1, 'months');
        let dayNextMonth = 1;
        while (week.length < 7) {
          week.push({
            day: dayNextMonth,
            belongsToThisMonth: false,
            id: getFormatedDate(
              firstDayNextMonth.year(),
              dayNextMonth,
              firstDayNextMonth.month() + 1
            )
          });
          dayNextMonth++;
        }
      }
      dataForCalendar.push(week);
    }

    console.log(dataForCalendar);

    return dataForCalendar;
  };

  const calendarData: Array<any[]> = useMemo(() => {
    if (!monthHalts) return [];

    const daysInMonth = firstDayInMonth.daysInMonth();
    const dayOfWeek = firstDayInMonth.day();
    const numberOfRows =
      dayOfWeek === 0
        ? Math.ceil(daysInMonth / 7)
        : Math.ceil((daysInMonth - (7 - dayOfWeek)) / 7) + 1;

    const personalHalts = monthHalts.filter(
      halt =>
        halt.employeeId === employeeId &&
        halt.haltType === HaltType.PersonalHalt
    );
    const companyHalts = monthHalts.filter(
      halt => halt.haltType === HaltType.CompanyHalt
    );

    return generateDaysForMonth(
      firstDayInMonth,
      daysInMonth,
      personalHalts,
      companyHalts,
      numberOfRows
    );
  }, [monthHalts]);

  const moveNextMonth = () =>
    setFirstDayInMonth(state => state.clone().add(1, 'months'));
  const movePreviousMonth = () =>
    setFirstDayInMonth(state => state.clone().add(-1, 'months'));
  const handlePickDate = (e: any) => {
    const { id: date } = e.target;
    dispatch(haltActions.setPickedCalendarDate(date));
  };

  const handleSetDayCellBakgroundColor = (subItem: any) => {
    if (subItem.id === pickedCalendarDate) {
      return theme.palette.primary.main;
    }

    let color;

    if (!subItem.belongsToThisMonth) {
      color = theme.palette.grey[100];
    } else if (subItem.isPersonalTrainingHalt) {
      color = theme.palette.error.main;
    } else {
      color = '';
    }

    return color;
  };

  const cellDayBorderStyle = `1px solid ${theme.palette.grey[50]}`;

  return (
    <Box
      flexDirection="column"
      sx={{
        ...containerStyle,
        justifyContent: !monthHalts ? 'center' : 'flex-start',
        border: `1px solid ${theme.palette.grey[100]}`
      }}
    >
      {!monthHalts && <CircularProgress />}
      {monthHalts && (
        <>
          <Box sx={calendarHeaderStyle}>
            <Stack direction="row" sx={{ paddingLeft: '15px' }}>
              <span style={{ fontWeight: 600 }}>
                {`${
                  monthNames[firstDayInMonth.month()]
                } ${firstDayInMonth.year()}`}
              </span>
            </Stack>
            <Stack direction="row" spacing={3}>
              <IconButton onClick={movePreviousMonth}>
                <KeyboardArrowLeftIcon
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
              <IconButton onClick={moveNextMonth}>
                <KeyboardArrowRightIcon
                  sx={{ color: theme.palette.common.white }}
                />
              </IconButton>
            </Stack>
          </Box>
          <Stack direction="row" sx={rowCalendarStyle}>
            {calendarHeaderNames.map((headerName: string) => (
              <Box sx={cellDayStyle}>
                <span>{headerName}</span>
              </Box>
            ))}
          </Stack>
          {calendarData.map((item: any[], index: number) => {
            return (
              <Stack
                key={`week-${index}`}
                direction="row"
                sx={{
                  ...rowCalendarStyle,
                  border: cellDayBorderStyle,
                  borderTop: index === 0 ? cellDayBorderStyle : 'none'
                }}
              >
                {item.map((subItem: any, index: number) => {
                  return (
                    <Box
                      key={subItem.id}
                      id={subItem.id}
                      sx={{
                        ...cellDayStyle,
                        borderRight: index < 6 ? cellDayBorderStyle : 'none',
                        backgroundColor: handleSetDayCellBakgroundColor(subItem)
                      }}
                      className={
                        subItem.isCompanyTrainingHalt ? 'crossed-out' : ''
                      }
                      onClick={hasAdminPermissions ? handlePickDate : undefined}
                    >
                      {subItem.day}
                    </Box>
                  );
                })}
              </Stack>
            );
          })}
          <Stack direction="row" spacing={2} sx={calendarFooterStyle}>
            <Stack
              direction="row"
              spacing={1}
              sx={{ color: theme.palette.grey[50] }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: theme.palette.error.main
                }}
              />
              <span>{t('yourTrainingHalts')}</span>
            </Stack>
            <Stack
              direction="row"
              sx={{ color: theme.palette.grey[50] }}
              spacing={1}
            >
              <Box
                sx={{ width: 20, height: 20, border: cellDayBorderStyle }}
                className="crossed-out"
              />
              <span>{t('companyTrainingHalts')}</span>
            </Stack>
          </Stack>
        </>
      )}
    </Box>
  );
};

const cellDayStyle: SxProps = {
  width: 56,
  height: 56,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
};

const rowCalendarStyle: SxProps = {
  height: 56,
  width: 392,
  fontSize: '14px'
};

const containerStyle: SxProps = {
  width: '430px',
  height: '505px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  backgroundColor: '#1D1D1E'
};

const calendarFooterStyle: SxProps = {
  paddingTop: '1rem',
  paddingBottom: '1rem',
  width: '100%',
  paddingLeft: '20px'
};

const calendarHeaderStyle: SxProps = {
  width: '100%',
  display: 'flex',
  marginTop: '15px',
  justifyContent: 'space-between'
};

export default HaltsCalendar;

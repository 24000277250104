import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/onsiteTrainings';
import {
  ONSITE_TRAININGS_CLEAR_ITEMS,
  ONSITE_TRAININGS_LOAD_PAGE,
  ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING,
  ONSITE_TRAININGS_SET_CURRENT,
  ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS,
  ONSITE_TRAININGS_RESET_TRANSACTION_STATUS,
  ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS,
  ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  ONSITE_TRAININGS_SET_CLASSES,
  ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS,
  ONSITE_TRAININGS_LOAD_ALL,
  ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES,
  ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY
} from '../actions/actionTypes';
import {
  OnsiteTraining,
  OnsiteTrainingClass,
  OnsiteTrainingClassAssignmentsPerDayDto
} from '../entities/OnsiteTraining';
import { PagedResult } from '../entities/Common';
import { TransactionStatusEnum } from '../core/enums';
import {
  EmployeeGroupAssignmentDefinition,
  OnsiteTrainingEmployeeAssignmentStatus
} from '../entities/Assignment';
import { AssignedOnsiteTrainingClass } from '../entities/LearningPlaylist';

export interface OnsiteTrainingsState {
  onsiteTrainings: PagedResult<OnsiteTraining> | null;
  onsiteTraining: OnsiteTraining | null;
  onsiteTrainingClass: AssignedOnsiteTrainingClass | null;
  currentTraining: OnsiteTraining | null;
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  onsiteTrainingAssignmentStatus:
    | OnsiteTrainingEmployeeAssignmentStatus[]
    | null;
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
  onsiteTrainingClasses: PagedResult<OnsiteTrainingClass> | null;
  onsiteTrainingsAll: OnsiteTraining[] | null;
  forceRefreshScheduleOnsiteTrainingClasses: boolean;
  onsiteTrainingClassAssignmentsPerDay: PagedResult<OnsiteTrainingClassAssignmentsPerDayDto> | null;
}

const unloadedState: OnsiteTrainingsState = {
  onsiteTrainings: null,
  onsiteTraining: null,
  onsiteTrainingClass: null,
  currentTraining: null,
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  onsiteTrainingAssignmentStatus: [],
  groupAssignmentDefinitions: [],
  onsiteTrainingClasses: null,
  onsiteTrainingsAll: null,
  forceRefreshScheduleOnsiteTrainingClasses: false,
  onsiteTrainingClassAssignmentsPerDay: null
};

export const OnsiteTrainingsReducer: Reducer<OnsiteTrainingsState> = (
  state: OnsiteTrainingsState | undefined,
  incomingAction: Action
): OnsiteTrainingsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case ONSITE_TRAININGS_CLEAR_ITEMS:
      if (state.onsiteTrainings) {
        return {
          ...state,
          onsiteTrainings: {
            ...state.onsiteTrainings,
            pageItems: []
          },
          onsiteTraining: null
        };
      }
      return state;
    case ONSITE_TRAININGS_LOAD_PAGE: {
      const incrementedOnsiteTrainings = state.onsiteTrainings
        ? state.onsiteTrainings.pageItems
            .slice()
            .concat(action.onsiteTrainings!.pageItems)
        : action.onsiteTrainings!.pageItems;

      return {
        ...state,
        onsiteTrainings: {
          ...action.onsiteTrainings!,
          pageItems: incrementedOnsiteTrainings,
          totalItems: action.onsiteTrainings!.totalItems
        }
      };
    }
    case ONSITE_TRAININGS_SET_CURRENT:
      if (state.currentTraining) {
        return {
          ...state,
          currentTraining: action.currentTraining!
        };
      }
      return state;
    case ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case ONSITE_TRAININGS_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    case ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS:
      return {
        ...state,
        onsiteTrainingAssignmentStatus: action.onsiteTrainingAssignmentStatus
      };
    case ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS:
      return {
        ...state,
        groupAssignmentDefinitions: action.groupAssignmentDefinitions
      };
    case ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING:
      return {
        ...state,
        onsiteTraining: action.onsiteTraining
      };
    case ONSITE_TRAININGS_SET_CLASSES: {
      if (!action.onsiteTrainingClasses) {
        return {
          ...state,
          onsiteTrainingClasses: action.onsiteTrainingClasses
        };
      }
      const incrementedOnsiteTrainingsClasses = state.onsiteTrainingClasses
        ? state.onsiteTrainingClasses.pageItems
            .slice()
            .concat(action.onsiteTrainingClasses!.pageItems)
        : action.onsiteTrainingClasses!.pageItems;

      return {
        ...state,
        onsiteTrainingClasses: {
          ...action.onsiteTrainingClasses!,
          pageItems: incrementedOnsiteTrainingsClasses,
          totalItems: action.onsiteTrainingClasses!.totalItems
        }
      };
    }
    case ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS:
      return {
        ...state,
        onsiteTrainingClass: action.onsiteTrainingClass
      };
    case ONSITE_TRAININGS_LOAD_ALL:
      return {
        ...state,
        onsiteTrainingsAll: action.onsiteTrainingsAll
      };
    case ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES:
      return {
        ...state,
        forceRefreshScheduleOnsiteTrainingClasses:
          action.forceRefreshScheduleOnsiteTrainingClasses
      };
    case ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY:
      return {
        ...state,
        onsiteTrainingClassAssignmentsPerDay:
          action.onsiteTrainingClassAssignmentsPerDay
      };
    default:
      return state;
  }
};

import { Guid } from 'guid-typescript';
import React, { CSSProperties, RefObject, useRef } from 'react';
import { ReactTags, Tag, ClassNames } from 'react-tag-autocomplete';
import './multipleFileInput.css';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

const AttachmentsInputId = 'AttachmentsInput';
const AttachmentsTagsInputId = 'AttachmentsTagsInputId';
const AttachmentsAddButtonId = 'AttachmentsAddButton';

export type FileTag = Tag;
export interface MultipleFilesInputProps {
  variant?: 'light' | 'dark' | undefined;
  tags: string[];
  onFileAdded: (fileName: string, file: File) => void;
  onRemoveSavedFile: (index: number) => void;
  validTypes: string;
  alternativeBehavior?: boolean;
  disabled?: boolean;
  dataTestPrefix?: string;
  dataTrackPrefix?: string;
  rowGridDesign?: boolean;
  isWorking?: boolean;
  width?: string;
}

const AddFileButton = ({
  style,
  disabled = false,
  fileInputRef,
  dataTestId,
  dataTrackId,
  label = '+'
}: {
  style: CSSProperties;
  disabled?: boolean;
  fileInputRef: RefObject<HTMLInputElement>;
  dataTestId?: string;
  dataTrackId?: string;
  label?: string;
}) => (
  <button
    data-testid={dataTestId}
    data-trackid={dataTrackId}
    style={style}
    disabled={disabled}
    onClick={() => {
      fileInputRef.current?.click();
    }}
  >
    {label}
  </button>
);

const MultipleFilesInput: React.FC<MultipleFilesInputProps> = ({
  variant = 'dark',
  tags,
  onFileAdded,
  onRemoveSavedFile,
  validTypes,
  alternativeBehavior = false,
  disabled = false,
  dataTestPrefix,
  dataTrackPrefix,
  rowGridDesign = false,
  isWorking = false,
  width
}) => {
  const { t } = useTranslation(['skills']);

  const fileTags: FileTag[] = tags.map(item => ({
    id: Guid.create().toString(),
    name: item,
    label: item,
    value: item
  }));
  function getRoot() {
    const rowGridDesignResult = rowGridDesign
      ? 'react-tags-dark-row-grid'
      : 'react-tags-dark';
    if (variant === 'light') {
      return 'react-tags';
    }
    return `${
      alternativeBehavior ? 'react-tags-without-borders' : rowGridDesignResult
    } react-tags-dark-files`;
  }

  const customClass: ClassNames = {
    root: getRoot(),
    rootIsActive: 'is-active',
    rootIsDisabled: 'is-disabled',
    rootIsInvalid: 'is-invalid',
    label: 'react-tags__label',
    tagList:
      rowGridDesign && fileTags.length
        ? 'react-tags__selected_row_grid'
        : 'react-tags__selected',
    tagListItem: 'react-tags__list-item',
    tag: rowGridDesign
      ? 'react-tags__selected-tag-row-grid'
      : 'react-tags__selected-tag',
    tagName: 'react-tags__selected-tag-name',
    comboBox: 'react-tags__combobox',
    input:
      rowGridDesign && fileTags.length
        ? 'react-tags__combobox-input-row-grid'
        : 'react-tags__combobox-input',
    listBox: 'react-tags__listbox',
    option: 'react-tags__listbox-option',
    optionIsActive: 'is-active',
    highlight: 'react-tags__listbox-option-highlight'
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDeleteFile = (index: number) => {
    onRemoveSavedFile(index);
  };

  const layoutStyle: CSSProperties = {
    display: 'flex',
    flex: 1,
    flexDirection: 'row'
  };

  const leftSide: CSSProperties = {
    width: width ?? '87%',
    height: '100%'
  };

  const alternativeLeftSide: CSSProperties = {
    width: '100%',
    height: '100%',
    position: 'relative',
    border: '1px solid #d1d1d1',
    borderRadius: '3px'
  };

  const rightSide: CSSProperties = {
    width: alternativeBehavior ? '0' : '13%',
    height: '100%'
  };

  const containerAlternativeButtonStyle: CSSProperties = {
    position: 'absolute',
    right: 0,
    bottom: 0
  };

  const handleFileChange = (e: any) => {
    const parts = e.target.value.split('\\');
    const fileName = parts[parts.length - 1];
    const selectedFile = e.target.files[0];
    onFileAdded(fileName, selectedFile);
    fileInputRef.current!.value = '';
  };

  return (
    <div style={layoutStyle}>
      <div style={alternativeBehavior ? alternativeLeftSide : leftSide}>
        {' '}
        {!isWorking ? (
          <ReactTags
            labelText=""
            classNames={customClass}
            selected={fileTags}
            suggestions={fileTags}
            onDelete={handleDeleteFile}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onAdd={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onInput={(input: string) => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onFocus={() => {}}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onBlur={() => {}}
            allowNew={false}
            placeholderText={t('selectFiles', { ns: 'skills' })}
            renderInput={({ classNames, inputWidth, ...inputProps }) => {
              return (
                <input
                  className={classNames.input}
                  style={{ width: inputWidth }}
                  {...inputProps}
                  id="multipleFilesInputId"
                  disabled
                  data-testid={`${dataTestPrefix}${AttachmentsTagsInputId}`}
                  data-trackid={`${dataTrackPrefix}${AttachmentsTagsInputId}`}
                />
              );
            }}
          />
        ) : (
          <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
        )}
        {alternativeBehavior && (
          <div style={containerAlternativeButtonStyle}>
            <AddFileButton
              dataTestId={`${dataTestPrefix}${AttachmentsAddButtonId}`}
              dataTrackId={`${dataTrackPrefix}${AttachmentsAddButtonId}`}
              style={buttonStyle2}
              disabled={disabled}
              fileInputRef={fileInputRef}
            />
          </div>
        )}
      </div>
      <div style={rightSide}>
        <input
          ref={fileInputRef}
          type="file"
          disabled={disabled}
          accept={validTypes}
          className="mInputContainer-file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          required
          width="30px"
          data-testid={`${dataTestPrefix}${AttachmentsInputId}`}
          data-trackid={`${dataTrackPrefix}${AttachmentsInputId}`}
        />
        {!alternativeBehavior && (
          <AddFileButton
            style={rowGridDesign ? buttonStyleRowGrid : buttonStyle}
            disabled={disabled || isWorking}
            fileInputRef={fileInputRef}
            dataTestId={`${dataTestPrefix}${AttachmentsAddButtonId}`}
            dataTrackId={`${dataTrackPrefix}${AttachmentsAddButtonId}`}
            label={rowGridDesign ? t('upload', { ns: 'skills' }) : '+'}
          />
        )}
      </div>
    </div>
  );
};

const buttonStyle: CSSProperties = {
  backgroundColor: '#ff9e00',
  height: '100%',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  width: '100%',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'white',
  cursor: 'pointer',
  fontSize: '24px',
  borderLeftStyle: 'none',
  minHeight: '47px'
};

const buttonStyleRowGrid: CSSProperties = {
  backgroundColor: '#FF9E00',
  height: '100%',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  width: '75px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'transparent',
  cursor: 'pointer',
  fontSize: '16px',
  borderLeftStyle: 'none',
  minHeight: '45px'
};

const buttonStyle2: CSSProperties = {
  backgroundColor: '#ff9e00',
  height: '100%',
  borderRadius: '3px',
  width: '80px',
  border: '1px solid #d1d1d1',
  cursor: 'pointer',
  fontSize: '24px',
  minHeight: '47px'
};

export default MultipleFilesInput;

import { Grid, ThemeProvider, createTheme } from '@mui/material';
import themeConfig from '../../../themes/theme';
import HorizontalLine from '../../../components/core/HorizontalLine/HorizontalLine';
import EmployeeAvatarEditor from '../employeeInfo/EmployeeAvatarEditor';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';

const EmployeeInfoTab = () => {
  const user = useCurrentUser();

  const name = user?.profile[UserClaims.FirstName] as string;
  const lastName = user?.profile[UserClaims.LastName] as string;
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  const theme = createTheme(themeConfig);

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Grid container direction="column" sx={{ paddingTop: '0.5rem' }}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        ></Grid>
        <Grid
          item
          sx={{ paddingTop: '1.5rem' }}
          justifyContent="start"
          alignItems="start"
        >
          <EmployeeAvatarEditor
            employeeName={`${name} ${lastName}`}
            employeeId={employeeId}
          />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default EmployeeInfoTab;

import Box from '@mui/material/Box';
import { t } from 'i18next';

import { AssignedLearningPlan } from '../../entities/LearningPlaylist';
import { secondsToHHMMSS } from '../../utils/dateUtils';

import NewLearningPlanCard from './NewLearningPlanCard';
import useLearningPlanCard from './useLearningPlanCard';
import ButtonsSection from '../AssignmentsButtonsSection/ButtonSection';
import { learningPlanWrapperStyle } from './CommonStyles';
import { EntityTypeEnum } from '../../core/enums';

interface AssignmentLearningPlanCardProps {
  assignedLearningPlan: AssignedLearningPlan;
}

const AssignmentLearningPlanCard: React.FC<AssignmentLearningPlanCardProps> = ({
  assignedLearningPlan
}) => {
  const { learningPlan, isComplete, dueDateMoment, assignmentId } =
    assignedLearningPlan;
  const { id } = learningPlan;
  const fomatedDueDate = dueDateMoment ? dueDateMoment!.format('MM.DD.YY') : '';

  const { takenTimesLearningPlans, handleStartLearningPlan } =
    useLearningPlanCard();

  const buttonCaption = isComplete
    ? t('revisit', { ns: 'common' })
    : t('start', { ns: 'common' });

  return (
    <Box sx={learningPlanWrapperStyle}>
      <NewLearningPlanCard
        key={id}
        learningPlan={learningPlan}
        isComplete={isComplete ?? false}
        showEdit={false}
        isDraft={false}
        dueDate={fomatedDueDate}
        averageLessonTime={
          learningPlan.avgLessonTime
            ? secondsToHHMMSS(learningPlan.avgLessonTime)
            : '00:00:00'
        }
        wrapperWidth="100%"
        takenTimes={
          takenTimesLearningPlans ? takenTimesLearningPlans[id] : undefined
        }
      >
        <ButtonsSection
          onClick={() =>
            handleStartLearningPlan(learningPlan, fomatedDueDate, assignmentId!)
          }
          startButtonCaption={buttonCaption}
          startButtonColor={isComplete ? 'secondary' : 'primary'}
          entityType={EntityTypeEnum.LearningPlan}
          assignment={assignedLearningPlan}
        />
      </NewLearningPlanCard>
    </Box>
  );
};

export default AssignmentLearningPlanCard;

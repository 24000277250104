import { CSSProperties, useEffect, useState, useRef } from 'react';
import {
  Grid,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  useTheme,
  Box,
  CircularProgress,
  SxProps
} from '@mui/material';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useTranslation } from 'react-i18next';
import { Index, InfiniteLoader, List, ListRowProps } from 'react-virtualized';

import { useSelector } from 'react-redux';

import SearchBox from '../../components/core/SearchBox/SearchBox';
import SkillsCategories from './SkillsCategories';
import SortSkills from './SortSkills';
import { ApplicationState } from '../../store';
import { actionCreators as achievementActions } from '../../actions/achievements';
import { CompetencePaginatedListItem } from '../../entities/Achievements';
import { SvgBigSkillIcon, SvgSkillIcon } from './SkillComponentsHelper';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { CompetenceLearningUnitType } from '../../core/enums';
import { useAppDispatch } from '../../store/hooks';

interface SkillCertificateListProps {
  mode: 'mySkills' | 'manage';
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.main
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(40, 40, 40, 0.62)',
    borderTop: `1px solid rgba(228, 228, 228, 0.15)`,
    borderBottom: `1px solid rgba(228, 228, 228, 0.15)`
  },
  height: 65
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected .MuiTypography-root': {
    color: `${theme.palette.primary.main} !important`
  },
  '&.Mui-selected': {
    backgroundColor: 'unset !important'
  }
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',
  width: '100%',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '10px',
  backgroundColor: theme.palette.secondary.main,
  display: 'inline-table',
  [`@media (max-width: 1300px)`]: {
    maxWidth: '100%'
  }
}));

const SkillCertificateList = ({ mode }: SkillCertificateListProps) => {
  let searchTimeout: NodeJS.Timeout;
  const user = useCurrentUser();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['skills']);
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const [searchKey, setSearchKey] = useState<string>('');
  const [categoryOption, setCategoryOption] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>('');
  const myCompetences = useSelector(
    (state: ApplicationState) => state.achievements?.myCompetences
  );
  const selectedCompetence = useSelector(
    (state: ApplicationState) => state.achievements?.selectedCompetence
  );
  const page = useRef<number>(1);
  const pageSize = 50;
  const rowCount =
    myCompetences && myCompetences.hasNextPage
      ? myCompetences.totalItems + 1
      : myCompetences?.totalItems ?? 0;

  useEffect(() => {
    if (mode === 'mySkills') {
      fetchCompetences(1);
    }
  }, []);

  useEffect(() => {
    if (!myCompetences) return;

    fetchCompetences(1);
    return () => {
      dispatch(achievementActions.setMyCompetences(null));
    };
  }, [searchKey, categoryOption, sortOption]);

  const loadNextPage = async () => {
    page.current += 1;
    await dispatch<Promise<void>>(
      achievementActions.getMyCompetences(
        employeeId,
        page.current,
        pageSize,
        searchKey,
        categoryOption,
        sortOption,
        false
      )
    );
    return new Promise<any>(resolve => {
      return resolve;
    });
  };

  const fetchCompetences = (pageNumber: number) => {
    page.current = pageNumber;
    dispatch(
      achievementActions.getMyCompetences(
        employeeId,
        pageNumber,
        pageSize,
        searchKey,
        categoryOption,
        sortOption,
        true
      )
    );
  };

  const loadMoreRows = !myCompetences
    ? () => new Promise<any>(resolve => resolve)
    : loadNextPage;

  const isRowLoaded = ({ index }: Index) => {
    return (
      !myCompetences?.hasNextPage ||
      index < (myCompetences?.pageItems.length ?? 0)
    );
  };

  const handleSearch = (value: any) => {
    if (!myCompetences) return;

    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setSearchKey(value as string);
    }, 1000);
  };

  const handleFilterByCategory = (items: string[]) =>
    setCategoryOption(items[0]);
  const handleSort = (items: string[]) => setSortOption(items[0]);
  const handleSelectCompetence = (competence: CompetencePaginatedListItem) =>
    dispatch(achievementActions.selectCompetence(competence));

  const renderRow =
    (
      selectedId: string,
      onClick: (competence: CompetencePaginatedListItem) => void
    ) =>
    (props: ListRowProps) => {
      if (!myCompetences) {
        return <></>;
      }

      const { index, style, key } = props;
      const data = myCompetences ? myCompetences.pageItems : [];
      let content;

      const handleClickItem =
        (competence: CompetencePaginatedListItem) => () => {
          if (onClick) onClick(competence);
        };

      if (!isRowLoaded({ index })) {
        content = (
          <ListItemText style={itemsLoadingLabel} primary={'Loading...'} />
        );
      } else {
        const { competence, employeeCompetence } = data[index];

        content = (
          <StyledListItemButton
            selected={employeeCompetence?.id === selectedId}
            onClick={handleClickItem(data[index])}
          >
            <ListItemIcon style={{ minWidth: '30px' }}>
              {competence.competenceLearningUnitType ===
                CompetenceLearningUnitType.CourseSkill ||
              competence.competenceLearningUnitType ===
                CompetenceLearningUnitType.LearningPlanSkill ? (
                <SvgSkillIcon />
              ) : (
                <WorkspacePremiumIcon sx={{ color: '#929292' }} />
              )}
            </ListItemIcon>
            <ListItemText style={itemsLabel} primary={competence.name} />
          </StyledListItemButton>
        );
      }

      return (
        <StyledListItem style={style} key={key} disablePadding>
          {content}
        </StyledListItem>
      );
    };

  return (
    <StyledGridContainer container>
      <Grid
        item
        direction="column"
        xs={12}
        sx={{
          padding: '1rem',
          borderBottom: `1px solid ${theme.palette.grey[100]}`,
          height: 'fit-content'
        }}
      >
        <SearchBox
          id="search"
          value=""
          name="search"
          width="100%"
          height="45px"
          placeholderText={t('search', { ns: 'common' })}
          variant="dark"
          onChangeValue={handleSearch}
        />
        <Stack direction="row" spacing={2} sx={{ marginTop: '16px' }}>
          <SkillsCategories
            filterValue={categoryOption}
            onChange={handleFilterByCategory}
          />
          <SortSkills filterValue={sortOption} onChange={handleSort} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {myCompetences && !!myCompetences.totalItems && (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
            threshold={10}
          >
            {({ onRowsRendered, registerChild }) => (
              <List
                ref={registerChild}
                onRowsRendered={onRowsRendered}
                rowRenderer={renderRow(
                  selectedCompetence?.employeeCompetence?.id ?? '',
                  handleSelectCompetence
                )}
                containerStyle={{
                  width: '100%',
                  maxWidth: '100%'
                }}
                style={{
                  width: '100%'
                }}
                height={400}
                width={448}
                rowHeight={40}
                rowCount={myCompetences?.totalItems ?? 0}
              />
            )}
          </InfiniteLoader>
        )}
        {(!myCompetences || !myCompetences.totalItems) && (
          <Box sx={compentencesContainerStyle}>
            {!myCompetences ? (
              <CircularProgress />
            ) : (
              <>
                <span style={styleEarnedSkillIcon}>
                  <SvgBigSkillIcon />
                </span>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: '24px',
                    paddingTop: '1rem'
                  }}
                >
                  {t('mySkillsNoSkillsFoundMsg')}
                </span>
              </>
            )}
          </Box>
        )}
      </Grid>
    </StyledGridContainer>
  );
};

const itemsLabel: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '18px',
  color: '#FFFFFF'
};

const itemsLoadingLabel: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '18px',
  color: '#FFFFFF',
  paddingLeft: '2rem'
};

const styleEarnedSkillIcon: CSSProperties = {
  position: 'relative',
  left: 40
};

const compentencesContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '450px',
  width: '100%',
  height: '400px',
  flexDirection: 'column'
};

export default SkillCertificateList;

import React, { useEffect, CSSProperties, useState, ReactNode } from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/system';
import { Lesson } from '../../entities/Lesson';
import { useSelector } from 'react-redux';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { actionCreators as studentActions } from '../../actions/student';
import { DailyLesson } from '../../entities/LearningPlaylist';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import { ApplicationState } from '../../store';
import moment from 'moment';
import { Box, useTheme } from '@mui/material';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import NewLessonCard from '../../components/LessonCard/NewLessonCard';
import InfoBySpan from '../../components/core/InfoByCardSpan/InfoBySpan';
import EmptyCard from '../../components/EmptyCard/EmptyCard';
import { DefaultLearningPlanForSelfAssignment } from '../../core/constants';
import { LearningPlanAssignment } from '../../entities/LearningPlan';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import CircularProgress from '@mui/material/CircularProgress';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import { useAppDispatch } from '../../store/hooks';
import { SelfAssignResponse } from '../../components/SelfAssignButton/types';

export interface DailyLessonsProps {
  dailyLessons: DailyLesson[];
  isHorizontalView?: boolean;
  takenTimesLessons?: TakenTimesObject | null;
  parentView?: 'Dashboard' | 'Assignments';
}

const DailyLessons: React.FC<DailyLessonsProps> = ({
  dailyLessons,
  isHorizontalView = false,
  parentView = 'Assignments',
  takenTimesLessons
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const { t } = useTranslation([
    'common',
    'assignments',
    'dashboard',
    'assessments'
  ]);
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;

  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedQuizzes
  );

  const handleStartLesson = (lesson: Lesson, assignment: DailyLesson) => {
    dispatch(lessonActions.setCurrentLessons([lesson]));
    logEvent.logPlayLessonEvent(lesson.id, lesson.title);

    navigate(`/lessons/assignment/play/${assignment.assignmentId}`);
  };

  const quizAssesmentId: string[] = [];
  dailyLessons?.map(lesson => {
    if (lesson.lesson.lessonType === 'Quiz') {
      quizAssesmentId.push(lesson.lesson.assessmentId!);
    }
  });

  useEffect(() => {
    dispatch(actionAssesments.failedQuizzesResult(quizAssesmentId));
  }, [dailyLessons]);

  const Container = isHorizontalView ? HorizontalContainer : VerticalContainer;

  const today = moment();

  const handleClickLearningPlanlink = async () => {
    const lPSelfAssignment: LearningPlanAssignment = {
      learningPlanId: DefaultLearningPlanForSelfAssignment,
      employeeIds: [employeeId],
      assignBy: 'SelfAssign',
      learningPlanCompanyId: bWCompanyId
    };
    await dispatch<Promise<SelfAssignResponse | null>>(
      learningPlanActions.assignLearningPlanToEmployees(lPSelfAssignment)
    );
    await dispatch<Promise<void>>(
      studentActions.getEmployeePlaylist(employeeId)
    );
  };

  return (
    <>
      <Container>
        {dailyLessons.length ? (
          dailyLessons.map(item => {
            const { lesson, isComplete, workloadAssignmentDate } = item;
            const workloadAssignmentDateLocalized = absUtcDateToLocalMoment(
              workloadAssignmentDate
            );
            const itemProps: GridProps = isHorizontalView
              ? {}
              : {
                  xs: 1,
                  md: 6,
                  lg: 4
                };

            return (
              <Grid item key={item.assignmentId} {...itemProps}>
                <div style={{ width: '300px' }}>
                  <NewLessonCard
                    lesson={lesson}
                    lessonCardType={'Assigned'}
                    isComplete={isComplete ?? false}
                    listFailedQuiz={listFailedQuiz!}
                    dueDate={workloadAssignmentDateLocalized.toDate()}
                    isWorkloadAssignment={true}
                    showEdit={false}
                    takenTimes={
                      takenTimesLessons
                        ? takenTimesLessons[lesson.id]
                        : undefined
                    }
                  >
                    <div style={divButtonStyle}>
                      <div style={spanDateStyle}>
                        {isComplete === true ? (
                          <span />
                        ) : (
                          <InfoBySpan
                            leftText={t('assignedDate', {
                              ns: 'assignments'
                            })}
                            rightText={workloadAssignmentDateLocalized.format(
                              'MM.DD.YY'
                            )}
                            showProfilePicture={false}
                            isOverdue={
                              !isComplete &&
                              workloadAssignmentDateLocalized.isBefore(
                                today,
                                'day'
                              )
                            }
                          />
                        )}
                      </div>
                      <div>
                        <BasicButton
                          onClick={() => handleStartLesson(lesson, item)}
                          color={isComplete ? 'revisit' : 'primary'}
                          style={startButtonStyle}
                        >
                          {isComplete
                            ? t('revisit', { ns: 'common' })
                            : t('play', { ns: 'common' })}
                        </BasicButton>
                      </div>
                    </div>
                  </NewLessonCard>
                </div>
              </Grid>
            );
          })
        ) : parentView == 'Assignments' ? (
          <EmptyCard>
            <NoDailyLessonsCardContent
              onClickLearningPlanlink={handleClickLearningPlanlink}
            />
          </EmptyCard>
        ) : parentView == 'Dashboard' ? (
          <EmptyCard width="100%" height="325px">
            <h5>{t('dueTodayEmptyCardTitle', { ns: 'dashboard' })}</h5>
          </EmptyCard>
        ) : (
          <Grid item>
            <h5>{t('noDailyLessons', { ns: 'assignments' })}</h5>
          </Grid>
        )}
      </Container>
    </>
  );
};

const NoDailyLessonsCardContent = ({
  onClickLearningPlanlink
}: {
  onClickLearningPlanlink: () => Promise<void>;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['learningPlans', 'assignments']);
  const [assigning, setAssigning] = useState<boolean>(false);

  const handleClick = async () => {
    setAssigning(true);
    await onClickLearningPlanlink();
    setAssigning(false);
  };

  const contentStyle: CSSProperties = {
    textAlign: 'center',
    fontSize: '13px',
    color: theme.palette.grey[50]
  };

  const linkStyle: CSSProperties = {
    cursor: 'pointer',
    color: theme.palette.primary.main
  };

  return (
    <>
      {!assigning && (
        <>
          <h5>{t('noDailyLessonsCardContentTitle', { ns: 'assignments' })}</h5>
          <span style={contentStyle}>
            {`${t('noDailyLessonsCardContent', { ns: 'assignments' })} `}
            <span style={linkStyle} onClick={handleClick}>{`${t(
              'learningPlan',
              { ns: 'learningPlans' }
            )}!`}</span>
          </span>
        </>
      )}
      {assigning && <CircularProgress />}
    </>
  );
};
interface ContainerProps {
  children: ReactNode;
}

const HorizontalContainer: React.FC<ContainerProps> = ({ children }) => (
  <Box
    className="horizontalContainer"
    sx={{
      display: 'flex',
      gridTemplateRows: 'minmax(150px, 1fr)',
      gridAutoFlow: 'column',
      overflowX: 'auto',
      paddingBottom: '20px !important',
      gap: '30px'
    }}
  >
    {children}
  </Box>
);

const VerticalContainer: React.FC<ContainerProps> = ({ children }) => (
  <Grid
    container
    sx={containerStyle}
    spacing={4}
    columns={{ xs: 1, md: 12, lg: 12 }}
  >
    {children}
  </Grid>
);

const containerStyle: SxProps = {
  paddingTop: '1rem'
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '105px',
  fontSize: '15px'
};

const divButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '20px',
  marginBottom: '20px'
};

const spanDateStyle = {
  marginTop: '10px'
};
export default DailyLessons;

import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import { useAnalyticsEngagementModal } from '../../../hooks/useAnalyticsEngagementModal';
import EmployeeCurrentAssignmentList from './EmployeeCurrentAssignmentList';
import UpdateDueDateModal from '../common/UpdateDueDateModal';

interface EmployeeCurrentAssignmentsModalProps {
  employeeId?: string;
  employeeName?: string;
  onCloseModal: () => void;
}

const EmployeeCurrentAssignmentsModal = ({
  employeeId,
  employeeName,
  onCloseModal
}: EmployeeCurrentAssignmentsModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: {
      sortOptions,
      openModal,
      assignmentId,
      dueDate,
      learningUnitType,
      employeeCurrentAssignmentsData
    },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved,
      handleDownloadReport
    }
  } = useAnalyticsEngagementModal(
    'employeeCurrentAssignments',
    undefined,
    employeeId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t(`currentAssignments`)}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
        onDownloadReport={handleDownloadReport}
        subtitle={employeeName}
      >
        <EmployeeCurrentAssignmentList
          currentAssignmentsData={employeeCurrentAssignmentsData}
          onEditDueDate={handleEditDueDate}
        />
      </AssignmentStatusModal>
      <UpdateDueDateModal
        learningUnitType={learningUnitType}
        assignmentId={assignmentId}
        dueDate={dueDate}
        onClose={handleCloseUpdateDueDateModal}
        onSuccessfulSaved={handleSuccessfulSaved}
      />
    </>
  );
};

export default EmployeeCurrentAssignmentsModal;

import { useState } from 'react';

import { MyCertification } from '../../../../entities/Certification';
import { StyledCard } from './CertificationCardComponents';
import CertificationCardOverview from './CertificationCardOverview';
import AttachedFilesView from './AttachedFilesView';

interface EmployeeCertificationCardProps {
  employeeCertification: MyCertification;
}

const EmployeeCertificationCard = ({
  employeeCertification
}: EmployeeCertificationCardProps) => {
  const [showAttachedFiles, setShowAttachedFiles] = useState<boolean>(false);

  return (
    <StyledCard>
      {!showAttachedFiles && (
        <CertificationCardOverview
          employeeCertification={employeeCertification}
          onViewAttachedFiles={() => setShowAttachedFiles(true)}
        />
      )}
      {showAttachedFiles && (
        <AttachedFilesView
          employeeCertification={employeeCertification}
          onBackToOverview={() => setShowAttachedFiles(false)}
        />
      )}
    </StyledCard>
  );
};

export default EmployeeCertificationCard;

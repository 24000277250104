import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AnalyticsQuiz, AnalyticsQuizDetail } from '../entities/Analytics';
import { ApplicationState } from '../store';
import { AnalyticsQuizzesViewType } from '../core/enums';
import { sortByKey } from '../utils/sorting';
import {
  downloadAnalyticsQuizzesReport,
  downloadAnalyticsQuizDetailReport
} from '../services/report-service';
import { absUtcDateToLocalMoment } from '../utils/dateUtils';
import { enqueueSnackbar } from 'notistack';

export type QuizDataType = AnalyticsQuiz[] | AnalyticsQuizDetail[];

export const useAnalyticsQuizzes = (viewType: AnalyticsQuizzesViewType) => {
  let searchTimeout: NodeJS.Timeout;
  const { t } = useTranslation(['analytics', 'common']);
  const sortOptions = (() => {
    switch (viewType) {
      case AnalyticsQuizzesViewType.Overview:
        return [
          {
            label: t('mostEmployeesAssigned', { ns: 'analytics' }),
            value: 'mostEmployeesAssigned'
          },
          {
            label: t('leastEmployeesAssigned', { ns: 'analytics' }),
            value: 'leastEmployeesAssigned'
          },
          {
            label: t('mostPendingCompletion', { ns: 'analytics' }),
            value: 'mostPendingCompletion'
          },
          {
            label: t('mostCompleted', { ns: 'analytics' }),
            value: 'mostCompleted'
          }
        ];
      case AnalyticsQuizzesViewType.Detail:
        return [
          {
            label: t('timesCompleted', { ns: 'analytics' }),
            value: 'timesCompleted'
          },
          { label: t('bestGrade', { ns: 'analytics' }), value: 'bestGrade' },
          { label: t('lowestGrade', { ns: 'analytics' }), value: 'lowestGrade' }
        ];
      default:
        return [];
    }
  })();
  const employeeFilterOptions = [
    { label: t('viewAll', { ns: 'analytics' }), value: '' },
    { label: t('active', { ns: 'analytics' }), value: 'active' },
    {
      label: t('subscribedAndActive', { ns: 'analytics' }),
      value: 'subscribed'
    }
  ];
  const [data, setData] = useState<QuizDataType | null>();
  const [includeSelfAssignments, setIncludeSelfAssignments] =
    useState<boolean>(true);
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [employeeFilterOption, setEmployeeFilterOption] = useState<string>('');
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);

  const quizzesData = useSelector(
    (state: ApplicationState) => state.analytics?.quizzesData
  );
  const quizDetailData = useSelector(
    (state: ApplicationState) => state.analytics?.quizDetailData
  );

  const handleChangeFilterOption = (items: string[]) => {
    setEmployeeFilterOption(items[0]);
  };

  useEffect(() => {
    let contentData: QuizDataType | null | undefined;
    switch (viewType) {
      case AnalyticsQuizzesViewType.Overview:
        contentData = quizzesData
          ? handleSortingData(quizzesData, sortOption)
          : quizzesData;
        break;
      case AnalyticsQuizzesViewType.Detail:
        contentData = quizDetailData
          ? handleSortingData(quizDetailData, sortOption)
          : quizDetailData;
        break;
      default:
        break;
    }
    setData(contentData);
  }, [quizzesData, quizDetailData]);

  const handleChangeSortOption = (items: string[]) => {
    if (!data) {
      return;
    }

    const sortOption = items[0] as string;
    const sortedData = handleSortingData(data, sortOption);
    setData(sortedData);
    setSortOption(sortOption);
  };

  const handleSortingData = <T>(data: any, option: string) => {
    switch (option) {
      case 'mostEmployeesAssigned':
        return data
          .slice()
          .sort(sortByKey<T>('employeesAssigned' as keyof T))
          .reverse();
      case 'leastEmployeesAssigned':
        return data.slice().sort(sortByKey<T>('employeesAssigned' as keyof T));
      case 'mostPendingCompletion':
        return data
          .slice()
          .sort(sortByKey<T>('pendingCompletion' as keyof T))
          .reverse();
      case 'mostCompleted':
        return data
          .slice()
          .sort(sortByKey<T>('completed' as keyof T))
          .reverse();
      case 'timesCompleted':
        return data
          .slice()
          .sort(sortByKey<T>('numberOfTimesCompleted' as keyof T))
          .reverse();
      case 'bestGrade':
        return data
          .slice()
          .sort(sortByKey<T>('lastGrade' as keyof T))
          .reverse();
      case 'lowestGrade':
        return data.slice().sort(sortByKey<T>('lastGrade' as keyof T));
      default:
        return data.slice();
    }
  };

  const handleSearchData = (searchFilter: string) => {
    if (viewType === AnalyticsQuizzesViewType.Overview && !quizzesData) return;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      let filteredData: QuizDataType = [];
      switch (viewType) {
        case AnalyticsQuizzesViewType.Overview:
          filteredData = quizzesData!.filter(item =>
            item.quizName.toLowerCase().includes(searchFilter.toLowerCase())
          );
          break;
        case AnalyticsQuizzesViewType.Detail:
          filteredData = quizDetailData!.filter(item =>
            `${item.firstName} ${item.lastName}`
              .toLowerCase()
              .includes(searchFilter.toLowerCase())
          );
          break;
        default:
          break;
      }
      const sortedData = handleSortingData(filteredData, sortOption);
      setData(sortedData);
      setSearchFilter(searchFilter);
    }, 1000);
  };

  const getPassedValue = (passed: boolean | null) => {
    if (passed === null) return '';

    return passed ? t('yes', { ns: 'common' }) : 'No';
  };

  const getAnsweredCorrectlyValue = (answeredCorrectly: boolean | null) => {
    if (answeredCorrectly === null) return '';

    return answeredCorrectly
      ? t('correct', { ns: 'analytics' })
      : t('incorrect', { ns: 'analytics' });
  };

  const handleDownloadCsvReport = () => {
    if (!data) {
      return;
    }

    setDownloadingReport(true);
    try {
      if (viewType === AnalyticsQuizzesViewType.Overview) {
        downloadAnalyticsQuizzesReport(data as unknown as AnalyticsQuiz[]);
      } else if (viewType === AnalyticsQuizzesViewType.Detail) {
        const detailData = data as AnalyticsQuizDetail[];
        const dataForReport: any[] = [];
        const headerColumns: string[] = [];
        detailData.forEach(quizItem => {
          if (!quizItem.quizResults.length) {
            const itemReport: any = {};
            itemReport.a1_employeeName = `${quizItem.firstName} ${quizItem.lastName}`;
            dataForReport.push(itemReport);
          }
          quizItem.quizResults.forEach(quizResult => {
            const isHeaderColumnsEmpty = !headerColumns.length;
            const itemReport: any = {};
            itemReport.a1_employeeName = `${quizItem.firstName} ${quizItem.lastName}`;
            itemReport.a2_passed = getPassedValue(quizResult.passed);
            itemReport.a3_takenAt = absUtcDateToLocalMoment(
              quizResult.createdTimestampUtc
            ).format('MM.DD.YYYY');
            itemReport.a4_grade =
              quizResult.grade !== null ? quizResult.grade.toFixed(0) : '';

            let counter = 5;
            quizResult.questionResponses.forEach(item => {
              itemReport[`a${counter}_answer`] = getAnsweredCorrectlyValue(
                item.answeredCorrectly
              );
              if (isHeaderColumnsEmpty) {
                headerColumns.push(
                  `Question ${headerColumns.length + 1} - ${item.questionText}`
                );
              }
              counter++;
            });
            dataForReport.push(itemReport);
          });
        });
        downloadAnalyticsQuizDetailReport(dataForReport, headerColumns);
      }
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setDownloadingReport(false);
    }
  };

  return {
    state: {
      sortOptions,
      employeeFilterOptions,
      sortOption,
      employeeFilterOption,
      searchFilter,
      includeSelfAssignments,
      downloadingReport,
      data
    },
    api: {
      handleChangeFilterOption,
      handleChangeSortOption,
      handleSearchData,
      setIncludeSelfAssignments,
      handleDownloadCsvReport
    }
  };
};

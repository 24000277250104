import React, { CSSProperties, useEffect, useRef, useState, memo } from 'react';
import {
  createTheme,
  CircularProgress,
  Typography,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Box,
  SxProps
} from '@mui/material';
import themeConfig from '../../themes/theme';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import SkillsCategories from './SkillsCategories';
import SortSkills from './SortSkills';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { Index, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import { styled } from '@mui/material/styles';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import SkillIcon from '../../assets/skill.svg';
import CertificateIcon from '../../assets/certificate.svg';
import { CompetenceLearningUnitType } from '../../core/enums';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/achievements';
import { useAppDispatch } from '../../store/hooks';

interface SkillManageListProps {
  onSelectSkill: (id: string) => void;
  onCreateSkill: () => void;
  creatingSkillInProgress?: boolean;
  skillSelected?: string;
}

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.secondary.main
  },
  '&:nth-of-type(even)': {
    backgroundColor: 'rgba(40, 40, 40, 0.62)',
    borderTop: `1px solid rgba(228, 228, 228, 0.15)`,
    borderBottom: `1px solid rgba(228, 228, 228, 0.15)`
  },
  height: 65
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  '&.Mui-selected .MuiTypography-root': {
    color: `${theme.palette.primary.main} !important`
  },
  '&.Mui-selected': {
    backgroundColor: 'unset !important'
  }
}));

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  maxWidth: '450px',
  width: '100%',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '10px',
  backgroundColor: theme.palette.secondary.main,
  display: 'inline-table',
  [`@media (max-width: 1300px)`]: {
    maxWidth: '100%'
  }
}));

const SkillManageList: React.FC<SkillManageListProps> = memo(
  ({
    onSelectSkill,
    onCreateSkill,
    creatingSkillInProgress,
    skillSelected
  }) => {
    const theme = createTheme(themeConfig);
    const { t } = useTranslation(['skills']);
    const [categoryOption, setCategoryOption] = useState<string>('');
    const [sortOption, setSortOption] = useState<string>('');
    const [searchKey, setSearchKey] = useState<string>('');
    const dispatch = useAppDispatch();
    const skills = useSelector(
      (state: ApplicationState) => state.achievements?.competences
    );
    const forceGetAllCompetencies = useSelector(
      (state: ApplicationState) => state.achievements?.forceGetAllCompetencies
    );
    const page = useRef<number>(1);
    const rowCount =
      skills && skills.hasNextPage
        ? skills.totalItems + 1
        : skills?.totalItems ?? 0;

    let searchTimeout: NodeJS.Timeout;

    useEffect(() => {
      if (skills) return;

      fetchCompetences(1);
    }, []);

    useEffect(() => {
      if (!skills) return;

      resetAndFetchCompetencies();
    }, [searchKey, categoryOption, sortOption]);

    useEffect(() => {
      if (forceGetAllCompetencies) {
        resetAndFetchCompetencies();
        dispatch(actionCreators.forceGetAllCompetencies(false));
      }
    }, [forceGetAllCompetencies]);

    const resetAndFetchCompetencies = () => {
      dispatch(actionCreators.cleanAllCompetences());
      fetchCompetences(1);
    };

    const fetchCompetences = (pageNumber: number) => {
      page.current = pageNumber;

      dispatch(
        actionCreators.getAllCompetences(
          pageNumber,
          searchKey,
          categoryOption,
          sortOption
        )
      );
    };

    const loadNextPage = async () => {
      page.current += 1;

      await dispatch<Promise<void>>(
        actionCreators.getAllCompetences(
          page.current,
          searchKey,
          categoryOption,
          sortOption
        )
      );

      return new Promise<any>(resolve => {
        return resolve;
      });
    };

    const loadMoreRows = !skills
      ? () => new Promise<any>(resolve => resolve)
      : loadNextPage;

    const handleSearch = (value: string) => {
      if (!skills) return;

      if (searchTimeout) clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        setSearchKey(value);
      }, 1000);
    };

    const isRowLoaded = ({ index }: Index) => {
      return !skills?.hasNextPage || index < (skills?.pageItems.length ?? 0);
    };

    const handleFilterByCategory = (items: string[]) =>
      setCategoryOption(items[0]);
    const handleSort = (items: string[]) => setSortOption(items[0]);

    const handleSelectSkill = (id: string) => {
      dispatch(actionCreators.getCompetenceDetail(id));
      onSelectSkill(id);
    };

    const renderRow =
      (selectedId: string, onClick: (id: string) => void) =>
      (props: ListRowProps) => {
        const { index, style, key } = props;
        const data = skills ? skills.pageItems : [];
        const item = data[index];
        const isCertificate =
          item?.competenceLearningUnitType ===
            CompetenceLearningUnitType.LearningPlanCertificate ||
          item?.competenceLearningUnitType ===
            CompetenceLearningUnitType.CourseCertificate ||
          item?.competenceLearningUnitType ===
            CompetenceLearningUnitType.Certificate;
        return (
          <StyledListItem style={style} key={key} disablePadding>
            <StyledListItemButton
              selected={item?.id === selectedId}
              onClick={() => onClick(item.id || '')}
            >
              <ListItemIcon style={{ minWidth: '30px', marginRight: 10 }}>
                <img
                  src={isCertificate ? CertificateIcon : SkillIcon}
                  style={{ width: 24, height: 24 }}
                  alt="Icon for Skill"
                />
              </ListItemIcon>
              <ListItemText style={itemsLabel} primary={item?.name} />
            </StyledListItemButton>
          </StyledListItem>
        );
      };

    return (
      <StyledGridContainer container>
        <Grid
          item
          direction="column"
          xs={12}
          sx={{
            padding: '1rem',
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            height: 'fit-content'
          }}
        >
          <SearchBox
            id="search"
            value={searchKey}
            name="search"
            width="100%"
            height="45px"
            placeholderText={t('search', { ns: 'common' })}
            variant="dark"
            onChangeValue={handleSearch}
          />
          <Stack direction="row" spacing={2} sx={{ marginTop: '16px' }}>
            <SkillsCategories
              filterValue={categoryOption}
              onChange={handleFilterByCategory}
            />
            <SortSkills filterValue={sortOption} onChange={handleSort} />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={listContainer}>
          {skills && !!skills.totalItems && (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={rowCount}
              threshold={10}
            >
              {({ onRowsRendered, registerChild }) => (
                <List
                  containerStyle={{
                    width: '100%',
                    maxWidth: '100%'
                  }}
                  style={{
                    width: '100%'
                  }}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={renderRow(
                    skillSelected ?? '',
                    handleSelectSkill
                  )}
                  height={412}
                  width={448}
                  rowHeight={42}
                  rowCount={skills?.totalItems ?? 0}
                />
              )}
            </InfiniteLoader>
          )}
          {(!skills || !skills.totalItems) && (
            <Box sx={loaderContainerStyle}>
              {!skills ? (
                <CircularProgress />
              ) : (
                <>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: '24px',
                      paddingTop: '1rem'
                    }}
                  >
                    {t('emptySkill')}
                  </span>
                </>
              )}
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '20px 10px'
          }}
        >
          <BasicButton
            color="primary"
            style={buttonStyle}
            onClick={onCreateSkill}
            disable={!!creatingSkillInProgress}
          >
            <Typography sx={fontButton}>
              {t('createNewSkill', { ns: 'skills' })}
            </Typography>
          </BasicButton>
        </Grid>
      </StyledGridContainer>
    );
  }
);

export const buttonStyle: CSSProperties = {
  maxWidth: '250px',
  width: 'auto',
  height: '42px'
};

export const fontButton: CSSProperties = {
  fontSize: '16px',
  color: '#0E0D0D',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px'
};

export const itemsLabel: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '18px',
  color: '#FFFFFF'
};

export const itemsLabelActive: CSSProperties = {
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '18px',
  lineHeight: '18px',
  color: '#FF9E00'
};

export const boxStyle: CSSProperties = {
  height: '60vh',
  width: '100%',
  border: `1px solid #999999`,
  backgroundColor: '#232424',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px'
};

const loaderContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '450px',
  width: '100%',
  height: '400px',
  flexDirection: 'column'
};

const listContainer: SxProps = {
  borderBottom: '1px solid #999',
  paddingBottom: '30px'
};

export default SkillManageList;

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export interface GoBackOptionProps {
  textLink: string;
  onGoBack?: () => void;
}

const GoBackLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer'
}));

const TextLinkSpan = styled('span')(({ theme }) => ({
  color: `${theme.palette.grey[100]} !important`,
  fontSize: '14px',
  paddingLeft: '0.5rem'
}));

const GoBackOption = ({ textLink, onGoBack }: GoBackOptionProps) => {
  const handleGoBack = () => {
    if (onGoBack) {
      onGoBack();
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <GoBackLink underline="none" onClick={handleGoBack}>
        <>
          <ExpandCircleDownIcon sx={{ transform: 'rotate(90deg)' }} />
          <TextLinkSpan>{textLink}</TextLinkSpan>
        </>
      </GoBackLink>
    </Stack>
  );
};

export default GoBackOption;

import { Button, Grid, Stack, Typography, createTheme } from '@mui/material';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';
import themeConfig from '../../../themes/theme';
import { ChangeEvent, useEffect, useState } from 'react';
import { uploadEmployeeProfilePhotoToBlob } from '../../../services/blobStorage-service';
import {
  getEmployeeInfoById,
  updateEmployeeProfilePhoto
} from '../../../services/employeeService';
import { enqueueSnackbar } from 'notistack';

interface EmployeeAvatarEditorProps {
  employeeName: string;
  employeeId: string;
}

const EmployeeAvatarEditor: React.FC<EmployeeAvatarEditorProps> = ({
  employeeName,
  employeeId
}) => {
  const { t } = useTranslation(['settings']);
  const [initialPhotoUrl, setInitialPhotoUrl] = useState<string>('');
  const [currentPhotoUrl, setCurrentPhotoUrl] = useState<string>('');
  const [photoFile, setPhotoFile] = useState<File | null>(null);

  useEffect(() => {
    getEmployeeInfoById(employeeId).then(result => {
      if (result?.data?.profileImageUrl) {
        setCurrentPhotoUrl(result.data.profileImageUrl);
        setInitialPhotoUrl(result.data.profileImageUrl);
      }
    });
  }, []);

  const theme = createTheme(themeConfig);
  const onRemove = () => {
    setCurrentPhotoUrl('');
    setPhotoFile(null);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];

    setPhotoFile(file);

    const reader = new FileReader();
    reader.onload = function (e) {
      setCurrentPhotoUrl(e!.target!.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onSave = async () => {
    let profilePhotoUrl = currentPhotoUrl;
    if (photoFile) {
      profilePhotoUrl = await uploadEmployeeProfilePhotoToBlob(photoFile!);
    }

    await updateEmployeeProfilePhoto(employeeId, profilePhotoUrl!);

    setInitialPhotoUrl(profilePhotoUrl);
    setCurrentPhotoUrl(profilePhotoUrl);
    setPhotoFile(null);

    enqueueSnackbar(t('employeeProfilePhotoUpdated'), {
      variant: 'success',
      autoHideDuration: 1000 * 8
    });
  };

  const pendingChanges =
    initialPhotoUrl !== currentPhotoUrl || photoFile !== null;

  return (
    <Stack direction="row" sx={{ flexWrap: 'wrap' }} gap={3}>
      <Typography variant="h5" color={theme.palette.primary.light}>
        {t('avatar')}
      </Typography>
      <Grid
        direction={'row'}
        container
        justifyContent="start"
        alignItems="center"
        gap={3}
      >
        <Grid item>
          <img
            className={`img-profile rounded-circle`}
            width="135"
            height="135"
            alt={employeeName}
            src={
              currentPhotoUrl && currentPhotoUrl.length
                ? currentPhotoUrl
                : require('../../../assets/user.png')
            }
          />
        </Grid>
        <Grid item>
          <Button
            color="inherit"
            variant="contained"
            component="label"
            sx={{ borderRadius: 25, color: '#000', textTransform: 'none' }}
          >
            Upload
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              hidden
              multiple={false}
              onChange={handleFileUpload}
            />
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            onClick={onRemove}
            sx={{ borderRadius: 25, textTransform: 'none' }}
          >
            <Typography>{t('remove')}</Typography>
          </Button>
        </Grid>
      </Grid>
      <BasicButton
        color="primary"
        style={{ width: 123, marginTop: '20px', color: '#000' }}
        onClick={onSave}
        disable={!pendingChanges}
      >
        {t('save')}
      </BasicButton>
    </Stack>
  );
};

export default EmployeeAvatarEditor;

import { Grid } from '@mui/material';
import { useState } from 'react';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import AssignOnsiteTraining from '../assignments/AssignOnsiteTraining';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import { useTranslation } from 'react-i18next';

export const OnsiteTrainingPreviewDetailsBottomActions = ({
  onsiteTraining
}: {
  onsiteTraining: OnsiteTraining;
}) => {
  const [showAssignModal, setShowAssignModal] = useState(false);
  const { t } = useTranslation(['onsiteTraining', 'common']);
  const handleAssignOnsiteTraining = () => {
    setShowAssignModal(true);
  };
  return (
    <Grid container style={{ padding: '50px 20px 30px 20px' }} gap={1}>
      <Grid item>
        <BasicButton
          onClick={() => {
            handleAssignOnsiteTraining();
          }}
          color="primary"
          style={{ width: '155px' }}
        >
          {t('assign', { ns: 'common' })}
        </BasicButton>
      </Grid>
      <Grid item>
        <BasicButton color="grayButton" style={{ width: '155px' }}>
          {t('start', { ns: 'common' })}
        </BasicButton>
      </Grid>
      <Grid item>
        {showAssignModal && (
          <AssignOnsiteTraining
            onClose={() => setShowAssignModal(false)}
            open={showAssignModal}
            onsiteTraining={onsiteTraining}
          />
        )}
      </Grid>
    </Grid>
  );
};

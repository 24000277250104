import { useMediaQuery } from '@mui/material';
import { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
interface PlaybackRateControlProps {
  playbackRate: number;
  handleSetPlaybackRate: (e: React.MouseEvent) => void;
}

const PlaybackRateControl: React.FC<PlaybackRateControlProps> = ({
  playbackRate,
  handleSetPlaybackRate
}) => {
  const isSmScreen = useMediaQuery('(max-width:640px)');
  const responsiveStyles: CSSProperties = {
    textAlign: 'center',
    width: isSmScreen ? '33px' : '49px',
    height: isSmScreen ? '15px' : '34px',
    fontSize: isSmScreen ? '7px' : '13px',
    lineHeight: isSmScreen ? '0' : 'normal'
  };
  return (
    <div className="">
      <Dropdown>
        <Dropdown.Toggle style={{ ...toggleRate, ...responsiveStyles }}>
          {`${playbackRate}x`}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={0.5}
            >
              0.5x
            </button>
          </div>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={0.75}
            >
              0.75x
            </button>
          </div>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={1}
            >
              1x
            </button>
          </div>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={1.25}
            >
              1.25x
            </button>
          </div>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={1.5}
            >
              1.5x
            </button>
          </div>
          <div>
            <button
              style={styleBtnPlaybackRate}
              onClick={handleSetPlaybackRate}
              value={2}
            >
              2x
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const styleBtnPlaybackRate: CSSProperties = {
  width: '100%',
  margin: '5px 0px',
  border: 'none',
  color: '#0E0D0D'
};

const toggleRate: CSSProperties = {
  background: '#fa9e04',
  borderColor: '#fa9e04',
  color: '#0E0D0D',
  textAlign: 'right',
  fontFamily: 'Titillium Web',

  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px',
  padding: 0
};

export default PlaybackRateControl;

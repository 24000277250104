import axios from 'axios';
import { AppThunk } from '../store/index';
import config from './../config';
import {
  ACHIEVEMENTS_SET_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_SELECT_MY_COMPETENCE,
  ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
  ACHIEVEMENTS_GET_ALL,
  CLEAN_ALL_COMPETENCES,
  ACHIEVEMENTS_SELECT_COMPETENCE,
  ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS,
  COMPETENCIES_NOT_ASSIGNED,
  COMPETENCIES_ASSIGNED_BY_ID,
  ACHIEVEMENTS_FORCE_GET_ALL,
  SET_COMPETENCE_ATTACHMENTS,
  CLEAN_COMPETENCE_ATTACHMENTS,
  SET_COMPETENCE_ATTACHMENT_REMOVED,
  ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL,
  ACHIEVEMENTS_SET_IS_LOADING,
  ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE,
  ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT,
  ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE,
  ACHIEVEMENTS_LOADING_EMPLOYEE_INFO,
  ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
  ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT,
  ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE,
  ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE
} from './actionTypes';
import {
  CompetencePaginatedListItem,
  Competence,
  CompetencePost,
  LearningUnit,
  CompetenceAttachment,
  EmployeeWithAssignedSkill,
  CompetencePut,
  AssociatedCompetence,
  EmployeeCompetence,
  Attachment,
  EmployeeAttachmentPost,
  EmployeeAnalyticsCompetence,
  CompetenceExpirationPut,
  CompetenceFile,
  EmployeeCompetenceAttachment,
  CompetenceBulk,
  LinkedContentBase
} from '../entities/Achievements';
import { PagedResult } from '../entities/Common';
import { enqueueSnackbar } from 'notistack';
import { NavigateFunction } from 'react-router';
import { handleGenericBackendError } from '../utils/errorHandling';

export const actionCreators = {
  getMyCompetences:
    (
      employeeId: string,
      page: number,
      pageSize: number,
      searchKey: string,
      filterKey: string,
      sortKey: string,
      isFirstLoad: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}employees/${employeeId}/competencies?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&filterKey=${filterKey}&sortKey=${sortKey}`
          );

          dispatch({
            type: isFirstLoad
              ? ACHIEVEMENTS_SET_MY_COMPETENCES_DATA
              : ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
            myCompetences: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getAllCompetences:
    (
      page: number,
      searchKey: string,
      filterKey: string,
      sortKey: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      const pageSize = 50;
      let url = `${config.ACHIEVEMENTS_API_URL}competencies?page=${page}&pageSize=${pageSize}`;
      if (searchKey !== '') {
        url += `&searchKey=${searchKey}`;
      }

      if (filterKey !== '') {
        url += `&filterKey=${filterKey}`;
      }

      url += `&sortBy=${sortKey || 'A-Z'}`;

      if (appState) {
        try {
          const res = await axios.get(url);

          dispatch({
            type: ACHIEVEMENTS_GET_ALL,
            competences: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getCompetenceDetail:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          dispatch({ type: ACHIEVEMENTS_SET_IS_LOADING, isLoading: true });
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/${id}`
          );
          dispatch({
            type: ACHIEVEMENTS_SELECT_COMPETENCE,
            competence: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({ type: ACHIEVEMENTS_SET_IS_LOADING, isLoading: false });
        }
      }
    },
  GetCompetenceAttachmentsById:
    (competenceId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/attachments/${competenceId}`
          );
          dispatch({
            type: SET_COMPETENCE_ATTACHMENTS,
            competenceAttachments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  deleteCompetenceAttachment:
    (competenceAttachment: CompetenceAttachment): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        await axios.delete(
          `${config.ACHIEVEMENTS_API_URL}competencies/attachment/${competenceAttachment.id}`
        );
        dispatch({
          type: SET_COMPETENCE_ATTACHMENT_REMOVED,
          competenceAttachment: competenceAttachment
        });
      }
    },
  setMyCompetences: (
    data: PagedResult<CompetencePaginatedListItem> | null
  ) => ({
    type: ACHIEVEMENTS_SET_MY_COMPETENCES_DATA,
    myCompetences: data
  }),
  selectCompetence: (data: CompetencePaginatedListItem | null) => ({
    type: ACHIEVEMENTS_SELECT_MY_COMPETENCE,
    selectedCompetence: data
  }),
  cleanAllCompetences: () => ({
    type: CLEAN_ALL_COMPETENCES
  }),
  getAvailableLearningUnits:
    (competenceId?: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/learning-units`,
            {
              params: competenceId
                ? { includeCompetenceIdLearningUnits: competenceId }
                : undefined
            }
          );

          dispatch({
            type: ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS,
            availableLearningUnits: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setAvailableLearningUnits: (data: LearningUnit[] | null) => ({
    type: ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS,
    availableLearningUnits: data
  }),
  getCompetenciesNotAssigned: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      try {
        const res = await axios.get(
          `${config.ACHIEVEMENTS_API_URL}competencies/notAssigned`
        );
        dispatch({
          type: COMPETENCIES_NOT_ASSIGNED,
          competenciesNotAssigned: res.data
        });
      } catch (e) {
        handleGenericBackendError(e);
      }
    }
  },
  setCompetenciesNotAssigned: (data?: Array<AssociatedCompetence>) => ({
    type: COMPETENCIES_NOT_ASSIGNED,
    competenciesNotAssigned: data
  }),
  getCompetenciesAssignedById:
    (learningUnitId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/assignedById/${learningUnitId}`
          );
          dispatch({
            type: COMPETENCIES_ASSIGNED_BY_ID,
            competenciesAssignedById: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCompetenciesAssignedById: (data: Array<AssociatedCompetence>) => ({
    type: COMPETENCIES_ASSIGNED_BY_ID,
    competenciesAssignedById: data
  }),
  deleteLearningUnitToCompetence:
    (
      notAssociatedcompetencies: Array<AssociatedCompetence>,
      learningUnitType: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          for (let i = 0; i < notAssociatedcompetencies.length; i++) {
            notAssociatedcompetencies[i].learningUnitType = learningUnitType;
            await axios.put(
              `${config.ACHIEVEMENTS_API_URL}competencies/associateLearningUnit?competenceId=${notAssociatedcompetencies[i].id}&learningUnitId=&learningUnitType=${notAssociatedcompetencies[i].learningUnitType}`,
              null
            );
          }
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  associateCompetenceToLearningUnit:
    (
      learningUnitId: string,
      learningUnitType: string,
      associatedCompetencies: Array<AssociatedCompetence>
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          for (let i = 0; i < associatedCompetencies.length; i++) {
            associatedCompetencies[i].learningUnitId = learningUnitId;
            associatedCompetencies[i].learningUnitType = learningUnitType;
            await axios.put(
              `${config.ACHIEVEMENTS_API_URL}competencies/associateLearningUnit?competenceId=${associatedCompetencies[i].id}&learningUnitId=${associatedCompetencies[i].learningUnitId}&learningUnitType=${associatedCompetencies[i].learningUnitType}`,
              null
            );
          }
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  createCompetence:
    (competencePost: CompetencePost): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.post(
            `${config.ACHIEVEMENTS_API_URL}competencies`,
            competencePost
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  updateCompetence:
    (competencePut: CompetencePut): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.put(
            `${config.ACHIEVEMENTS_API_URL}competencies`,
            competencePut
          );
          dispatch({
            type: ACHIEVEMENTS_SELECT_COMPETENCE,
            competence: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  deleteCompetence:
    (id: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.delete(
            `${config.ACHIEVEMENTS_API_URL}competencies/${id}`
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  forceGetAllCompetencies: (force: boolean) => ({
    type: ACHIEVEMENTS_FORCE_GET_ALL,
    forceGetAllCompetencies: force
  }),
  cleanCompetenceAttachments: () => ({
    type: CLEAN_COMPETENCE_ATTACHMENTS
  }),
  getEmployeesWithAssignedSkill:
    (competencyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: ACHIEVEMENTS_SET_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/employeesWithAssignedContent/${competencyId}`
          );

          dispatch({
            type: ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL,
            employeesWithAssignedSkill: res.data as EmployeeWithAssignedSkill[]
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: ACHIEVEMENTS_SET_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  getEmployeesByCompetence:
    (id: string, page: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      const pageSize = 50;
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}competencies/${id}/employees?page=${page}&pageSize=${pageSize}`
          );
          dispatch({
            type: ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE,
            employees: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  createEmployeeAttachment:
    (file: EmployeeAttachmentPost): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          const res = await axios.post(
            `${config.ACHIEVEMENTS_API_URL}employees/attachment`,
            file
          );
          dispatch({
            type: ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT,
            attachment: res.data,
            employee: file.employeeId || ''
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  awardBulkCompetence:
    (awardCompetences: CompetenceBulk, navigate: NavigateFunction): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          await axios.post(
            `${config.ACHIEVEMENTS_API_URL}employees/competencies/bulk`,
            awardCompetences
          );
          const message = 'Award Skill/Certificate succesfully.';
          enqueueSnackbar(message, {
            variant: 'success',
            autoHideDuration: 3000
          });
          navigate('/skills');
        } catch (e) {
          const messageError = 'An error occurred. Please, try again later.';
          enqueueSnackbar(messageError, {
            variant: 'error',
            autoHideDuration: 3000
          });
          throw e;
        }
      }
    },
  getMyCompetencesAnalytics:
    (
      employeeId: string,
      page: number,
      pageSize: number,
      isFirstLoad: boolean,
      onlyCertificates: boolean
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}employees/analytics/${employeeId}/competencies?page=${page}&pageSize=${pageSize}&onlyCertificates=${onlyCertificates}`
          );

          dispatch({
            type: isFirstLoad
              ? ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA
              : ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
            myCompetencesAnalytics: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  deleteEmployeeFromCompetence:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          await axios.delete(`${config.ACHIEVEMENTS_API_URL}employees/${id}`, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          });
          dispatch({
            type: ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE,
            employee: id
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  cleanMyCompetencesAnalytics: () => ({
    type: ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA
  }),
  updateExpirationCompetence:
    (competenceExpirationPut: CompetenceExpirationPut): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.put(
            `${config.ACHIEVEMENTS_API_URL}employees/competence/expiration-date`,
            competenceExpirationPut
          );
          dispatch({
            type: ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE,
            employeeCompetence: res.data
          });
        } catch (e) {
          console.log('Error while updating expiration date', e);
        }
      }
    },
  deleteEmployeeFromCompetenceAnalytics:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          await axios.delete(`${config.ACHIEVEMENTS_API_URL}employees/${id}`, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          });
          dispatch({
            type: ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA,
            employee: id
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  createEmployeeAnalyticsAttachment:
    (file: EmployeeAttachmentPost): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        try {
          const res = await axios.post(
            `${config.ACHIEVEMENTS_API_URL}employees/attachment`,
            file
          );
          dispatch({
            type: ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT,
            employeeCompetenceAttachments: res.data,
            employee: file.employeeCompeteceId || ''
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  deleteEmployeeAnalyticsAttachment:
    (
      employeeCompetenceAttachment: EmployeeCompetenceAttachment,
      elementId: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({ type: ACHIEVEMENTS_LOADING_EMPLOYEE_INFO });
      if (appState) {
        await axios.delete(
          `${config.ACHIEVEMENTS_API_URL}employees/competencies/attachments/${employeeCompetenceAttachment.id}`
        );
        dispatch({
          type: ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE,
          employeeCompetenceAttachment: employeeCompetenceAttachment,
          elementId: elementId
        });
      }
    },
  deleteCompetenceContentLinked:
    (
      LinkedContent: LinkedContentBase,
      competenceId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        await axios.delete(
          `${config.ACHIEVEMENTS_API_URL}competencies/linkedContent/${competenceId}/${LinkedContent.id}/${LinkedContent.learningUnitType}`
        );
      }
    }
};

export interface GetMyCompetences {
  type: 'ACHIEVEMENTS_SET_MY_COMPETENCES_DATA';
  myCompetences: PagedResult<CompetencePaginatedListItem> | null;
}

export interface GetMyCompetencesNextPage {
  type: 'ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA';
  myCompetences: PagedResult<CompetencePaginatedListItem> | null;
}

export interface GetAllCompetences {
  type: 'ACHIEVEMENTS_GET_ALL';
  competences: PagedResult<Competence> | null;
}

export interface SelectMyCompetence {
  type: 'ACHIEVEMENTS_SELECT_MY_COMPETENCE';
  selectedCompetence: CompetencePaginatedListItem | null;
}

export interface CleanAllCompetences {
  type: 'CLEAN_ALL_COMPETENCES';
}

export interface SelectCompetence {
  type: 'ACHIEVEMENTS_SELECT_COMPETENCE';
  competence: Competence;
}

export interface GetAvailableLearningUnits {
  type: 'ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS';
  availableLearningUnits: LearningUnit[] | null;
}
export interface CleanAllCompetences {
  type: 'CLEAN_ALL_COMPETENCES';
}

export interface SelectCompetence {
  type: 'ACHIEVEMENTS_SELECT_COMPETENCE';
  competence: Competence;
}

export interface ForceGetAllCompetencies {
  type: 'ACHIEVEMENTS_FORCE_GET_ALL';
  forceGetAllCompetencies: boolean;
}

export interface setCompetenceAttachments {
  type: 'SET_COMPETENCE_ATTACHMENTS';
  competenceAttachments: CompetenceAttachment[] | null;
}
export interface CleanCompetenceAttachments {
  type: 'CLEAN_COMPETENCE_ATTACHMENTS';
}

export interface removeCompetenceAttachment {
  type: 'SET_COMPETENCE_ATTACHMENT_REMOVED';
  competenceAttachment: CompetenceAttachment;
}

export interface EmployeesWithAssignedSkill {
  type: 'ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL';
  employeesWithAssignedSkill: EmployeeWithAssignedSkill[];
}

export interface SetIsLoading {
  type: 'ACHIEVEMENTS_SET_IS_LOADING';
  isLoading: boolean;
}

export interface GetCompetenciesAssignedById {
  type: 'COMPETENCIES_ASSIGNED_BY_ID';
  competenciesAssignedById: AssociatedCompetence | null;
}
export interface GetCompetenciesNotAssigned {
  type: 'COMPETENCIES_NOT_ASSIGNED';
  competenciesNotAssigned: AssociatedCompetence | null;
}
export interface GetEmployeesByCompetence {
  type: 'ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE';
  employees: PagedResult<EmployeeCompetence> | null;
}
export interface CreateEmployeeAttachment {
  type: 'ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT';
  attachment: Attachment;
  employee: string;
}
export interface RemoveCompetenceFromEmployee {
  type: 'ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE';
  employee: string;
}
export interface LoadingEmployeeInfo {
  type: 'ACHIEVEMENTS_LOADING_EMPLOYEE_INFO';
}

export interface GetMyCompetencesAnalytics {
  type: 'ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA';
  myCompetencesAnalytics: PagedResult<EmployeeAnalyticsCompetence> | null;
}

export interface GetMyCompetencesAnalyticsNextPage {
  type: 'ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA';
  myCompetencesAnalytics: PagedResult<EmployeeAnalyticsCompetence> | null;
}

export interface ClearMyCompetencesAnalytics {
  type: 'ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA';
}

export interface RemoveCompetenceAnalyticsFromEmployee {
  type: 'ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA';
  employee: string;
}

export interface CreateEmployeeAnalyticsAttachment {
  type: 'ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT';
  employeeCompetenceAttachments: CompetenceFile;
  employee: string;
}

export interface UpdateEmployeeAnalyticsExpirationDate {
  type: 'ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE';
  employeeCompetence: EmployeeAnalyticsCompetence;
}

export interface RemoveEmployeeCompetenceAttachment {
  type: 'ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE';
  employeeCompetenceAttachment: EmployeeCompetenceAttachment;
  elementId: string;
}

export type KnownAction =
  | GetMyCompetences
  | GetAllCompetences
  | GetMyCompetencesNextPage
  | SelectMyCompetence
  | SelectCompetence
  | CleanAllCompetences
  | GetAvailableLearningUnits
  | ForceGetAllCompetencies
  | setCompetenceAttachments
  | CleanCompetenceAttachments
  | removeCompetenceAttachment
  | EmployeesWithAssignedSkill
  | GetCompetenciesNotAssigned
  | GetCompetenciesAssignedById
  | GetEmployeesByCompetence
  | CreateEmployeeAttachment
  | RemoveCompetenceFromEmployee
  | LoadingEmployeeInfo
  | SetIsLoading
  | GetMyCompetencesAnalytics
  | GetMyCompetencesAnalyticsNextPage
  | ClearMyCompetencesAnalytics
  | RemoveCompetenceAnalyticsFromEmployee
  | CreateEmployeeAnalyticsAttachment
  | UpdateEmployeeAnalyticsExpirationDate
  | RemoveEmployeeCompetenceAttachment;

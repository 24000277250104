import React, { CSSProperties, FC, useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import BasicButton from '../core/BasicButton/BasicButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../core/constants';
import { SelfAssignButton } from '../SelfAssignButton/SelfAssignButton';
import { Lesson } from '../../entities/Lesson';
import { useLesson } from '../../pages/lessons/useLesson';
import AssignLesson from '../../pages/lessons/AssignLesson';
import { LearningPlan } from '../../entities/LearningPlan';
import { Course } from '../../entities/Course';
import { EntityTypeEnum } from '../../core/enums';
import AssignLearningPlan from '../../pages/learningPlans/AssignLearningPlan';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import AssignCourse from '../../pages/courses/AssignCourse';
import { actionCreators as coursesActions } from '../../actions/courses';
import { useAppDispatch } from '../../store/hooks';

export interface PreviewModeBannerProps {
  contentToAssign: Lesson | LearningPlan | Course;
  contentType: EntityTypeEnum;
  isClickSafetyCourse?: boolean;
}

const PreviewModeBanner: FC<PreviewModeBannerProps> = ({
  contentToAssign,
  contentType,
  isClickSafetyCourse
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['assignments']);

  const {
    api: { handleOpenAssignLessonModal, handleCloseAssignLessonModal },
    state: { showAssignLessonModal }
  } = useLesson(false);

  const [selectedPlan, setSelectedPlan] = useState<LearningPlan>();
  const [showAssignLearningPlanModal, setShowAssignLearningPlanModal] =
    useState<boolean>(false);

  const [showAssignCourseModal, setShowAssignCourseModal] =
    React.useState<boolean>(false);

  const handleAssign = (learningPlan: LearningPlan) => {
    setSelectedPlan(learningPlan);
    setShowAssignLearningPlanModal(true);
  };

  const handleCloseAssignmodal = () => {
    setShowAssignLearningPlanModal(false);
    dispatch(learningPlanActions.setCurrentLearningPlan(null));
    setSelectedPlan(undefined);
  };

  const handleCloseCourseAssignModal = () => {
    setShowAssignCourseModal(false);
  };

  const handleOpenAssignModalCourse = (id?: string) => {
    dispatch(coursesActions.requestCourseById(id!));
    setShowAssignCourseModal(true);
  };

  const getContentTypes = useCallback(() => {
    let typeForSelfAssign = '';
    let contentTypeString = '';
    let contentTypeTitle = '';

    switch (contentType) {
      case EntityTypeEnum.Lesson:
        typeForSelfAssign = 'Lesson';
        contentTypeString = t('lesson', { ns: 'lessons' }).toLowerCase();
        contentTypeTitle = (contentToAssign as Lesson).title;
        break;
      case EntityTypeEnum.Course:
        typeForSelfAssign = 'Course';
        contentTypeString = t('course', { ns: 'assignments' }).toLowerCase();
        contentTypeTitle = (contentToAssign as Course).title;
        break;
      case EntityTypeEnum.LearningPlan:
        typeForSelfAssign = 'Learning Plan';
        contentTypeString = t('learningPlan', {
          ns: 'learningPlans'
        }).toLowerCase();
        contentTypeTitle = (contentToAssign as LearningPlan).name;
        break;
    }

    return {
      typeForSelfAssign,
      contentTypeString,
      contentTypeTitle
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentToAssign, contentType]);

  return (
    <Paper style={containerStyle} elevation={2} sx={{ p: 2, sp: 1 }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{ xs: 'center', md: 'space-between' }}
        sx={{ flexWrap: 'wrap' }}
      >
        <Typography style={fontRightStyle}>
          {t('previewModeMessage', {
            contentType: getContentTypes().contentTypeString,
            ns: 'common'
          })}
        </Typography>
        <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
          <ProtectedComponent action={appPermissions.ASSIGN_LESSON}>
            <BasicButton
              style={paddingElements}
              width="70px"
              height="35px"
              color="grayButton"
              onClick={() => {
                switch (contentType) {
                  case EntityTypeEnum.Lesson:
                    handleOpenAssignLessonModal(contentToAssign.id);
                    break;
                  case EntityTypeEnum.LearningPlan:
                    handleAssign(contentToAssign as LearningPlan);
                    break;
                  case EntityTypeEnum.Course:
                    if (!isClickSafetyCourse) {
                      handleOpenAssignModalCourse(contentToAssign.id);
                    }
                    break;
                }
              }}
            >
              {t('assign', { ns: 'common' })}
            </BasicButton>
          </ProtectedComponent>
          <SelfAssignButton
            type={getContentTypes().typeForSelfAssign}
            color={'grayButton'}
            style={paddingElements}
            id={contentToAssign.id}
            companyId={contentToAssign.companyId}
            title={getContentTypes().contentTypeTitle}
            width="100px"
            height="35px"
          />
        </Stack>
      </Stack>
      {showAssignLessonModal && (
        <AssignLesson
          onClose={handleCloseAssignLessonModal}
          open={showAssignLessonModal}
        />
      )}
      {showAssignLearningPlanModal && selectedPlan && (
        <AssignLearningPlan
          show={showAssignLearningPlanModal}
          learningPlan={selectedPlan}
          onClose={handleCloseAssignmodal}
        />
      )}
      {showAssignCourseModal && (
        <AssignCourse
          open={showAssignCourseModal}
          onClose={() => handleCloseCourseAssignModal()}
        />
      )}
    </Paper>
  );
};

const paddingElements: CSSProperties = {
  marginRight: 5,
  marginLeft: 15,
  marginBottom: 5,
  minWidth: '120px'
};

const fontRightStyle: CSSProperties = {
  color: '#0E0D0D',
  fontSize: '20px',
  fontWeight: 600,
  marginBottom: '0.5rem'
};

const containerStyle: CSSProperties = {
  backgroundColor: '#FF9E00',
  borderRadius: 0,
  marginTop: -5,
  marginLeft: -30,
  marginRight: -30,
  marginBottom: 30
};

export default PreviewModeBanner;

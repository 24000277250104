import axios from 'axios';
import { AppThunk } from '../store/index';
import config from './../config';
import {
  CERTIFICATIONS_SET_CERTIFICATIONS,
  CERTIFICATIONS_SET_ISLOADING,
  CERTIFICATIONS_REFETCH_CERTIFICATIONS,
  CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS,
  CERTIFICATIONS_SET_CERTIFICATION_NAMES,
  CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION,
  CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET,
  CERTIFICATIONS_SET_MY_CERTIFICATIONS,
  CERTIFICATIONS_SET_TRANSACTION_ERROR,
  CERTIFICATIONS_SET_EMPLOYEES_NAMES,
  CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS
} from './actionTypes';
import { EmployeeAttachmentPost } from '../entities/Achievements';
import { GridResponse, PagedResult } from '../entities/Common';
import { EmployeeCertification } from '../entities/EmployeeCertification';
import {
  Certification,
  CertificationPostPut,
  MyCertificationStatus,
  MyCertification
} from '../entities/Certification';
import { Employee } from '../entities/Employee';

function extractEmployeeNames(data: Employee[], search: string) {
  const employeeNamesArray = [];

  for (const obj of data) {
    employeeNamesArray.push(obj.firstName + ' ' + obj.lastName);
  }

  return employeeNamesArray;
}

function sortAndFilterNames(
  namesArray: string[] | Iterable<string> | null | undefined
) {
  const uniqueSet = new Set(namesArray);
  return [...uniqueSet].sort((a, b) => a.trim().localeCompare(b.trim()));
}
export const actionCreators = {
  getCertifications:
    (
      page: number,
      pageSize: number,
      sortBy: string,
      search: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: CERTIFICATIONS_SET_ISLOADING,
          isLoading: true
        });
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}certifications`,
            {
              params: {
                Page: page,
                PageSize: pageSize,
                SortBy: sortBy,
                Search: search
              }
            }
          );
          dispatch({
            type: CERTIFICATIONS_SET_CERTIFICATIONS,
            certifications: res.data
          });
          dispatch({
            type: CERTIFICATIONS_SET_ISLOADING,
            isLoading: false
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_SET_ISLOADING,
            isLoading: false
          });
          throw e;
        }
      }
    },
  resetCertifications: () => ({
    type: CERTIFICATIONS_SET_CERTIFICATIONS,
    refetchCertifications: null
  }),
  createCertification: async (certificationPost: CertificationPostPut) => {
    const postInput = {
      name: certificationPost.name,
      description: certificationPost.description,
      duration: certificationPost.durationNeverExpires
        ? 0
        : certificationPost.duration,
      durationUnit: certificationPost.durationNeverExpires
        ? 'None'
        : certificationPost.durationUnit,
      durationNeverExpires: certificationPost.durationNeverExpires,
      notifyDaysBeforeExpiration:
        certificationPost.notifyDaysBeforeExpirationTags
          .map(option => option.id)
          .join(','),
      awarders: certificationPost.awardersTags.map(awarder => awarder.id),
      thirdPartyCertificateRequired:
        certificationPost.thirdPartyCertificateRequired,
      certifyingBody: certificationPost.certifyingBody
    };

    await axios.post(`${config.ACHIEVEMENTS_API_URL}certifications`, postInput);
  },
  refetchCertifications: (refresh: boolean) => ({
    type: CERTIFICATIONS_REFETCH_CERTIFICATIONS,
    refetchCertifications: refresh
  }),
  getEmployeeCertifications:
    (
      page: number,
      pageSize: number,
      sortBy: string,
      includeAllEmployees: boolean,
      search?: string | undefined
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: CERTIFICATIONS_SET_ISLOADING,
          isLoading: true
        });
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}certifications/employees`,
            {
              params: {
                Page: page,
                PageSize: pageSize,
                SortBy: sortBy,
                IncludeAllEmployees: includeAllEmployees,
                search: search
              }
            }
          );
          dispatch({
            type: CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS,
            employeeCertifications: res.data
          });
          dispatch({
            type: CERTIFICATIONS_SET_ISLOADING,
            isLoading: false
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_SET_ISLOADING,
            isLoading: false
          });
          throw e;
        }
      }
    },
  getEmployeesByCertification:
    (
      certificationId: string,
      page: number,
      pageSize: number
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING,
          employeesByCertificationIsLoading: true
        });
        dispatch({
          type: CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION,
          employeesByCertification: []
        });
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}certifications/${certificationId}/awarded-employees`,
            {
              params: {
                Page: page,
                PageSize: pageSize
              }
            }
          );
          dispatch({
            type: CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION,
            employeesByCertification: res.data
          });
          dispatch({
            type: CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING,
            employeesByCertificationIsLoading: false
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING,
            employeesByCertificationIsLoading: false
          });
          throw e;
        }
      }
    },
  resetEmployeesByCertification: () => ({
    type: CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION,
    employeesByCertification: []
  }),
  deleteAwardeeById: async (id: string) => {
    await axios.delete(`${config.ACHIEVEMENTS_API_URL}employees/${id}`, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    });
  },
  employeeAwardeeAddAttachment: async (file: EmployeeAttachmentPost) => {
    await axios.post(
      `${config.ACHIEVEMENTS_API_URL}employees/attachment`,
      file
    );
  },
  updateCertification: async (certificationPut: CertificationPostPut) => {
    const putInput = {
      name: certificationPut.name,
      description: certificationPut.description,
      duration: certificationPut.durationNeverExpires
        ? 0
        : certificationPut.duration,
      durationUnit: certificationPut.durationNeverExpires
        ? 'None'
        : certificationPut.durationUnit,
      durationNeverExpires: certificationPut.durationNeverExpires,
      notifyDaysBeforeExpiration:
        certificationPut.notifyDaysBeforeExpirationTags
          .map(option => option.id)
          .join(','),
      awarders: certificationPut.awardersTags.map(awarder => awarder.id),
      thirdPartyCertificateRequired:
        certificationPut.thirdPartyCertificateRequired,
      certifyingBody: certificationPut.certifyingBody
    };

    await axios.put(
      `${config.ACHIEVEMENTS_API_URL}certifications/${certificationPut.id}`,
      putInput
    );
  },
  deleteCertification: async (id: string) => {
    await axios.delete(`${config.ACHIEVEMENTS_API_URL}certifications/${id}`);
  },
  resetEmployeeCertifications: () => ({
    type: CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS,
    refetchCertifications: null
  }),
  getCertificationNames:
    (search: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}certifications/names`,
            {
              params: {
                Search: search
              }
            }
          );
          dispatch({
            type: CERTIFICATIONS_SET_CERTIFICATION_NAMES,
            certificationNames: res.data
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_SET_CERTIFICATION_NAMES,
            certificationNames: []
          });
          throw e;
        }
      }
    },
  resetCertificationsNames: () => ({
    type: CERTIFICATIONS_SET_CERTIFICATION_NAMES,
    refetchCertifications: []
  }),
  getEmployeesNames:
    (companyId: string, search: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      try {
        const appState = getState();
        if (!appState) return;

        const res = await axios.get(
          `${config.IDENTITY_URL}/api/employee/paginateWithGroups`,
          {
            params: {
              companyId,
              skip: 0,
              take: 100,
              search,
              sorting: 'asc',
              filter: null
            }
          }
        );

        const employeeNamesArray = extractEmployeeNames(res.data.rows, search);
        const uniqueArraySorted = sortAndFilterNames(employeeNamesArray);

        dispatch({
          type: CERTIFICATIONS_SET_EMPLOYEES_NAMES,
          employeesNames: uniqueArraySorted
        });
      } catch (e) {
        dispatch({
          type: CERTIFICATIONS_SET_EMPLOYEES_NAMES,
          employeesNames: []
        });
        throw e;
      }
    },
  resetEmployeesNames: () => ({
    type: CERTIFICATIONS_SET_EMPLOYEES_NAMES,
    employeesNames: []
  }),
  getEmployeesToAward:
    (
      certificateId: string,
      page: number,
      pageSize: number,
      search: { value: string; firstSearch: boolean },
      groupId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      dispatch({
        type: CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING,
        isLoadingEmployeesToAward: true
      });
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}certifications/${certificateId}/award-employees`,
            {
              params: {
                Page: page,
                PageSize: pageSize,
                search: search.value,
                groupId: groupId
              }
            }
          );

          dispatch({
            type: CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD,
            employeesToBeAward: {
              data: res.data,
              concatenateFlag: !search.firstSearch
            }
          });
          dispatch({
            type: CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING,
            isLoadingEmployeesToAward: false
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING,
            isLoadingEmployeesToAward: false
          });
          throw e;
        }
      }
    },
  resetEmployeesToAward: () => ({
    type: CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET,
    resetEmployeesToAward: { totalCount: 0, rows: [] }
  }),
  getMyCertifications:
    (
      page: number,
      pageSize: number,
      sortBy: string,
      search: string,
      filterStatusBy: MyCertificationStatus[],
      employeeId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}employees/certifications/${employeeId}`,
            {
              params: {
                page: page,
                pageSize: pageSize,
                sortBy: sortBy,
                search: search,
                status: filterStatusBy
              },
              paramsSerializer: {
                indexes: null
              }
            }
          );
          dispatch({
            type: CERTIFICATIONS_SET_MY_CERTIFICATIONS,
            myCertificationsPageResult: res.data
          });
        } catch (e) {
          dispatch({
            type: CERTIFICATIONS_SET_TRANSACTION_ERROR,
            transactionError: true
          });
        }
      }
    },
  setMyCertifications: (
    myCertificationsPageResult: PagedResult<MyCertification> | null
  ) => ({
    type: CERTIFICATIONS_SET_MY_CERTIFICATIONS,
    myCertificationsPageResult
  }),
  setTransactionError:
    (errorOccurred: boolean): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: CERTIFICATIONS_SET_TRANSACTION_ERROR,
          transactionError: errorOccurred
        });
      }
    },
  deleteEmployeeCertification: async (employeeCertificationId: string) => {
    await axios.delete(
      `${config.ACHIEVEMENTS_API_URL}employees/${employeeCertificationId}`
    );
  },
  forceFetchEmployeeCertifications: (forceFetch: boolean) => ({
    type: CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS,
    forceFetchEmployeeCertifications: forceFetch
  }),
  deleteEmployeeCertificationAttachment: async (attachmentId: string) => {
    await axios.delete(
      `${config.ACHIEVEMENTS_API_URL}employees/competencies/attachments/${attachmentId}`
    );
  },
  awardCertification: async (
    employeesToAward: EmployeeCertification[],
    certificationId: string,
    awarderId: string,
    awarderFullName: string
  ) => {
    await axios.post(`${config.ACHIEVEMENTS_API_URL}certifications/award`, {
      employeesToAward,
      certificationId,
      awarderId,
      awarderFullName
    });
  }
};

export interface GetCertifications {
  type: 'CERTIFICATIONS_SET_CERTIFICATIONS';
  certifications: PagedResult<Certification> | null;
}

export interface SetCertificationsIsLoadingAction {
  type: 'CERTIFICATIONS_SET_ISLOADING';
  isLoading: boolean;
}

export interface RefetchCertifications {
  type: 'CERTIFICATIONS_REFETCH_CERTIFICATIONS';
  refetchCertifications: boolean;
}

export interface GetEmployeeCertifications {
  type: 'CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS';
  employeeCertifications: GridResponse<EmployeeCertification> | null;
}

export interface GetCertificationNames {
  type: 'CERTIFICATIONS_SET_CERTIFICATION_NAMES';
  certificationNames: string[];
}

export interface GetEmployeesByCertification {
  type: 'CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION';
  employeesByCertification: GridResponse<EmployeeCertification> | null;
}

export interface SetEmployeesByCertificationIsLoadingAction {
  type: 'CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING';
  employeesByCertificationIsLoading: boolean;
}

export interface GetEmployeesToAward {
  type: 'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD';
  employeesToBeAward: {
    data: GridResponse<EmployeeCertification>;
    concatenateFlag: boolean;
  };
}

export interface SetEmployeesToAwardIsLoadingAction {
  type: 'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING';
  isLoadingEmployeesToAward: boolean;
}

export interface ResetEmployeesToAwardAction {
  type: 'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET';
  resetEmployeesToAward: GridResponse<EmployeeCertification>;
}

export interface GetMyCertifications {
  type: 'CERTIFICATIONS_SET_MY_CERTIFICATIONS';
  myCertificationsPageResult: PagedResult<MyCertification> | null;
}

export interface SetTransactionError {
  type: 'CERTIFICATIONS_SET_TRANSACTION_ERROR';
  transactionError: boolean;
}

export interface GetEmployeesNames {
  type: 'CERTIFICATIONS_SET_EMPLOYEES_NAMES';
  employeesNames: string[];
}

export interface ForceFetchEmployeeCertifications {
  type: 'CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS';
  forceFetchEmployeeCertifications: boolean;
}

export type KnownAction =
  | GetCertifications
  | SetCertificationsIsLoadingAction
  | RefetchCertifications
  | GetEmployeeCertifications
  | GetCertificationNames
  | SetEmployeesByCertificationIsLoadingAction
  | GetEmployeesByCertification
  | GetEmployeesToAward
  | SetEmployeesToAwardIsLoadingAction
  | ResetEmployeesToAwardAction
  | GetMyCertifications
  | GetEmployeesNames
  | SetTransactionError
  | ForceFetchEmployeeCertifications;

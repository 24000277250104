import {
  BlobServiceClient,
  BlobUploadCommonResponse,
  ContainerClient
} from '@azure/storage-blob';
import config from '../config';
import { Guid } from 'guid-typescript';
import { Question } from '../entities/Assessment';
import { QuestionTypes } from '../core/constants';
import { TransferProgressEvent } from '@azure/core-http';
import { SubtitlesDTO } from '../entities/Dto/createLessonDto';
import { Subtitles } from '../entities/LessonFormModel';
import { AzureBlobImageInfo } from '../entities/AzureBlobInfo';
import { getExtension } from '../utils/stringUtils';
import fileDownload from 'js-file-download';
import { enqueueSnackbar } from 'notistack';
import JSZip from 'jszip';

const createBlobInContainer = async (
  containerClient: ContainerClient,
  file: File,
  blobName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number
): Promise<BlobUploadCommonResponse> => {
  const blobClient = containerClient.getBlockBlobClient(blobName);
  let options: any = {
    blobHTTPHeaders: { blobContentType: file.type }
  };
  if (onProgress) {
    options = {
      ...options,
      onProgress: onProgress!,
      maxSingleShotSize: blockSize ? blockSize : 104857600
    };
  }

  return await blobClient.uploadData(file, options);
};

export const uploadCourseThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_COURSEIMAGES_CONTAINER!);
};

export const uploadLessonThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONIMAGES_CONTAINER!);
};

export const uploadLessonSubtitleToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONSUBTITLES_CONTAINER!);
};

export const uploadLessonDocToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LESSONDOCS_CONTAINER!);
};

export const uploadLearningPlanThumbToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_LEARNINGPLANIMAGES_CONTAINER!);
};

export const uploadSkillAttachmentToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_SKILLATTACHMENTS_CONTAINER!);
};

export const uploadOnsiteTrainingAttachmentToBlob = async (file: File) => {
  return uploadFileToBlob(
    file,
    config.BLOB_ONSITE_TRAINING_ATTACHMENTS_CONTAINER!
  );
};

export const uploadOnsiteTrainingImagesToBlob = async (file: File) => {
  return uploadFileToBlob(file, config.BLOB_ONSITE_TRAINING_IMAGES_CONTAINER!);
};

export const uploadOnsiteTrainingSignaturesToBlob = async (file: File) => {
  return uploadFileToBlob(
    file,
    config.BLOB_ONSITE_TRAINING_SIGNATURES_CONTAINER!
  );
};

export const uploadEmployeeProfilePhotoToBlob = async (file: File) => {
  return uploadFileToBlob(
    file,
    config.BLOB_CONTAINER_COMPANY_LOGOS!,
    undefined,
    undefined,
    config.BLOB_BW_STORAGE_ACCOUNT!,
    config.BLOB_BW_STORAGE_SAS_TOKEN
  );
};

export const uploadLargeFileToBlob = async (
  file: File,
  containerName: string,
  onProgress: (progress: TransferProgressEvent) => void,
  storageAccountName?: string,
  storageAccountSasToken?: string,
  blockSize?: number
) => {
  return uploadFileToBlobName(
    file,
    containerName,
    onProgress,
    blockSize,
    storageAccountName ?? config.BLOB_STORAGE_ACCOUNT_NAME,
    storageAccountSasToken ?? config.BLOB_SAS_TOKEN
  );
};

export const uploadFileToBlob = async (
  file: File,
  containerName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number,
  storageAccount?: string,
  storageSASToken?: string
) => {
  if (!File) throw new Error('file cannot be null or undefined');

  const blobUrlBase = `https://${storageAccount ?? config.BLOB_STORAGE_ACCOUNT}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${storageSASToken ?? config.BLOB_STORAGE_SAS_TOKEN}`
  );

  const containerClient = blobService.getContainerClient(containerName || '');
  const blobName = Guid.create().toString() + getExtension(file.name);

  const response = await createBlobInContainer(
    containerClient,
    file,
    blobName,
    onProgress,
    blockSize
  );

  if (response.errorCode !== undefined)
    throw new Error(`Blob storage error : ${response.errorCode || ''}`);

  return `${blobUrlBase}/${containerName}/${blobName}`;
};

export const uploadFileToBlobName = async (
  file: File,
  containerName: string,
  onProgress?: (progress: TransferProgressEvent) => void,
  blockSize?: number,
  storageAccount?: string,
  storageSASToken?: string
) => {
  if (!File) throw new Error('file cannot be null or undefined');

  const blobUrlBase = `https://${storageAccount ?? config.BLOB_STORAGE_ACCOUNT}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${storageSASToken ?? config.BLOB_STORAGE_SAS_TOKEN}`
  );

  const containerClient = blobService.getContainerClient(containerName || '');
  const blobName = Guid.create().toString() + getExtension(file.name);

  const response = await createBlobInContainer(
    containerClient,
    file,
    blobName,
    onProgress,
    blockSize
  );

  if (response.errorCode !== undefined)
    throw new Error(`Blob storage error : ${response.errorCode || ''}`);

  return `${blobName}`;
};

export const checkUploadImageQuestion = async (questions: Question[]) => {
  for (let index = 0; index < questions.length; index++) {
    const currentQuestion = questions[index];
    if (currentQuestion.type === QuestionTypes.MultipleChoiceText) {
      try {
        if (currentQuestion.imageFile) {
          const imageUrl = await uploadCourseThumbToBlob(
            currentQuestion.imageFile
          );
          currentQuestion.imageUrl = imageUrl;
        }
      } catch (error) {
        enqueueSnackbar('Error while uploading thumbnail. Please try again.', {
          variant: 'error',
          autoHideDuration: 3000
        });
      }
    } else if (currentQuestion.type === QuestionTypes.MultipleChoiceImage) {
      if (currentQuestion.options) {
        for (
          let indexOptions = 0;
          indexOptions < currentQuestion.options.length;
          indexOptions++
        ) {
          const currentOption = currentQuestion.options[indexOptions];
          try {
            if (currentOption.imageFile) {
              const imageUrl = await uploadCourseThumbToBlob(
                currentOption.imageFile
              );
              currentOption.imageUrl = imageUrl;
            }
          } catch (error) {
            enqueueSnackbar(
              'Error while uploading thumbnail. Please try again.',
              {
                variant: 'error',
                autoHideDuration: 3000
              }
            );
          }
        }
      }
    }
  }
  return questions;
};

export const checkUploadSubtitle = async (subtitles: Subtitles[]) => {
  const subtitlesDTO: SubtitlesDTO[] = [];
  for (let index = 0; index < subtitles.length; index++) {
    const currentSubtitle = subtitles[index];
    let subtitleUrl = '';
    try {
      if (currentSubtitle.subtitleFile) {
        subtitleUrl = await uploadLessonSubtitleToBlob(
          currentSubtitle.subtitleFile
        );
      } else if (currentSubtitle.url) {
        subtitleUrl = currentSubtitle.url;
      }
      subtitlesDTO.push({
        id: currentSubtitle.id === '' ? Guid.EMPTY : currentSubtitle.id,
        language: currentSubtitle.language,
        url: subtitleUrl
      });
    } catch (error) {
      enqueueSnackbar('Error while uploading thumbnail. Please try again.', {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  }
  return subtitlesDTO;
};

const listThumbnailsFromContainer = async (
  containerName: string,
  listKey: string
) => {
  const result: AzureBlobImageInfo[] = [];
  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT_NAME}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(`${blobUrlBase}/?${listKey}`);

  const containerClient = blobService.getContainerClient(containerName || '');
  if (containerClient) {
    for await (const blob of containerClient.listBlobsFlat()) {
      result.push({
        ...blob,
        url: `${blobUrlBase}/${containerName}/${blob.name}`
      });
    }
  }
  return result;
};

export const getColorThumbnails = async () => {
  return await listThumbnailsFromContainer(
    config.BLOB_LESSONGALLERY_COLORCONTAINER!,
    config.BLOB_STORAGE_COLORCONTAINER_LIST_KEY!
  );
};

export const getBWThumbnails = async () => {
  return await listThumbnailsFromContainer(
    config.BLOB_LESSONGALLERY_BWCONTAINER!,
    config.BLOB_STORAGE_BWCONTAINER_LIST_KEY!
  );
};

export const downloadBlob = async (
  url: string,
  fileName: string,
  containerName: string
) => {
  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${config.BLOB_STORAGE_SAS_TOKEN}`
  );

  const urlSplit = url.split('/');
  const blobName = urlSplit[urlSplit.length - 1];
  const containerClient = blobService.getContainerClient(containerName);
  const blobClient = containerClient.getBlobClient(blobName);

  const downloadBlockBlobResponse = await blobClient.download();
  const downloaded = await downloadBlockBlobResponse.blobBody;
  if (downloaded) {
    fileDownload(downloaded, fileName);
  }
};

export const downloadAndCreateZipBlob = async (
  blobsInfo: { url: string; fileName: string }[],
  containerName: string,
  zipFileName: string,
  existingBlobs?: { blob: Blob; name: string }[]
) => {
  const blobUrlBase = `https://${config.BLOB_STORAGE_ACCOUNT}.blob.core.windows.net`;
  const blobService = new BlobServiceClient(
    `${blobUrlBase}/?${config.BLOB_STORAGE_SAS_TOKEN}`
  );

  const containerClient = blobService.getContainerClient(containerName);
  const zip = new JSZip();

  for (const blobInfo of blobsInfo) {
    const { fileName, url } = blobInfo;
    const urlSplit = url.split('/');
    const blobName = urlSplit[urlSplit.length - 1];
    const blobClient = containerClient.getBlobClient(blobName);

    const downloadBlockBlobResponse = await blobClient.download();
    const downloaded = await downloadBlockBlobResponse.blobBody;
    if (downloaded) {
      zip.file(fileName, downloaded);
    }
  }

  if (existingBlobs?.length) {
    existingBlobs.forEach(item => zip.file(item.name, item.blob));
  }

  zip
    .generateAsync({ type: 'blob' })
    .then(zipBlob => fileDownload(zipBlob, zipFileName));
};

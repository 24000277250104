import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { useTranslation } from 'react-i18next';
import { GridRowSelectionModel } from '@mui/x-data-grid-premium';

import { actionCreators as certificationActions } from '../actions/certifications';
import { MyCertification } from '../entities/Certification';
import { uploadSkillAttachmentToBlob } from '../services/blobStorage-service';
import { EmployeeAttachmentPost } from '../entities/Achievements';
import { systemGeneratedPdf } from '../core/constants';

const useEditEmployeeCertifications = (employeeId: string) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['certifications']);
  const pageSize = 25;
  const sortOption = 'createdAt:asc';
  const [fileProcessInProgres, setFileProcessInProgres] =
    useState<boolean>(false);
  const [deletingInProgress, setDeletingInProgress] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState<boolean>(false);
  const [selectedCertifications, setSelectedCertifications] =
    useState<GridRowSelectionModel>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [urlFileToView, setUrlFileToView] = useState<string>('');
  const pageRef = useRef<number>(1);
  const myCertifications = useAppSelector(
    x => x.certifications?.myCertificationsPageResult
  );

  const fetchMyCertifications = () => {
    dispatch(
      certificationActions.getMyCertifications(
        pageRef.current,
        pageSize,
        sortOption,
        searchKey,
        [],
        employeeId
      )
    );
  };

  const handleReFetchCertifications = () => {
    pageRef.current = 1;
    dispatch(certificationActions.setMyCertifications(null));
    fetchMyCertifications();
  };

  useEffect(() => {
    fetchMyCertifications();
    return () => {
      dispatch(certificationActions.setMyCertifications(null));
    };
  }, []);

  useEffect(() => {
    if (myCertifications) {
      handleReFetchCertifications();
    }
  }, [searchKey]);

  const handleAddFile =
    (myCertification: MyCertification) =>
    async (fileName: string, file: File) => {
      if (!file) return;

      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      const fileType = file.type;
      setFileProcessInProgres(true);

      try {
        const url = await uploadSkillAttachmentToBlob(file);
        if (url) {
          const data: EmployeeAttachmentPost = {
            employeeCompeteceId: myCertification.employeeCertificationId,
            employeeId: employeeId,
            attachment: {
              fileExtension,
              fileType,
              url,
              name: fileName
            }
          };

          await certificationActions.employeeAwardeeAddAttachment(data);
          handleReFetchCertifications();
          enqueueSnackbar(t('filesWereAttached'), { variant: 'success' });
        }
      } catch (e) {
        enqueueSnackbar(t('errorAttachingFiles'), { variant: 'error' });
      } finally {
        setFileProcessInProgres(false);
      }
    };

  const handleRemoveFile = async (fileId: string) => {
    setFileProcessInProgres(true);
    try {
      await certificationActions.deleteEmployeeCertificationAttachment(fileId);
      handleReFetchCertifications();
      enqueueSnackbar(t('fileWasDeleted'), { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(t('errorDeletingFiles'), { variant: 'error' });
    } finally {
      setFileProcessInProgres(false);
    }
  };

  const handleInitDeleteCertification = () => {
    if (!selectedCertifications.length) {
      enqueueSnackbar(t('noCertificationsToDelete'), {
        variant: 'warning'
      });
      return;
    }

    setShowDeleteConfirmationModal(true);
  };

  const handleFileClick = (url: string) => {
    if (url === systemGeneratedPdf) {
      return;
    }

    setUrlFileToView(url);
  };

  const handleDeleteCertification = async () => {
    setDeletingInProgress(true);
    const noDeletedCertificationNames = [];
    for (const id of selectedCertifications) {
      const employeeCertificationId = id as string;
      try {
        await certificationActions.deleteEmployeeCertification(
          employeeCertificationId
        );
      } catch (e) {
        const noDeletedCertification = myCertifications?.pageItems.find(
          certification =>
            certification.employeeCertificationId === employeeCertificationId
        );
        if (noDeletedCertification) {
          noDeletedCertificationNames.push(
            noDeletedCertification.certificationName
          );
        }
      }
    }

    handleReFetchCertifications();
    dispatch(certificationActions.forceFetchEmployeeCertifications(true));
    setShowDeleteConfirmationModal(false);
    setDeletingInProgress(false);

    return noDeletedCertificationNames;
  };

  const handleCloseFileViewer = () => {
    setUrlFileToView('');
  };

  return {
    ref: {
      pageRef
    },
    state: {
      myCertifications,
      fileProcessInProgres,
      selectedCertifications,
      showDeleteConfirmationModal,
      deletingInProgress,
      urlFileToView
    },
    api: {
      setSearchKey,
      setSelectedCertifications,
      fetchMyCertifications,
      handleAddFile,
      handleRemoveFile,
      handleInitDeleteCertification,
      setShowDeleteConfirmationModal,
      handleDeleteCertification,
      handleFileClick,
      handleCloseFileViewer
    }
  };
};

export default useEditEmployeeCertifications;

import { CSSProperties, useEffect, useState, useContext } from 'react';
import { ThemeProvider, createTheme, Grid, Box } from '@mui/material';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import FormControl from '@mui/material/FormControl';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ContentLibraryTable from './Tables/ContentLibraryTable';
import { actionCreators as analyticsActions } from '../../actions/analytics';
import { ApplicationState } from '../../store';
import themeConfig from '../../themes/theme';
import { AnalyticsContentLibrary } from '../../entities/Analytics';
import { sortByKey } from '../../utils/sorting';
import { downloadAnalyticsContentLibraryReport } from '../../services/report-service';
import ModalFactory from './contentLibrary/ModalFactory';
import ContentLibraryAnalyticsDetail from './contentLibrary/ContentLibraryAnalyticsDetail';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsCommonFilterContext } from '../../contexts/AnalyticsContext';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import SearchControlPanel from './SearchControlPanel';
import { useAppDispatch } from '../../store/hooks';
import { AnalyticsLearningUnitTypeDropdown } from '../../core/enums';

const ContentLibraryAnalytics = () => {
  let searchTimeout: NodeJS.Timeout;
  const dispatch = useAppDispatch();
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['common', 'analytics']);

  const lessonTypes = [
    { label: t('allLessonTypes', { ns: 'analytics' }), value: '' },
    { label: 'Videos', value: 'Video' },
    { label: 'Audio', value: 'Audio' },
    { label: t('file', { ns: 'analytics' }), value: 'File' }
  ];

  const learningUnits = [
    { label: t('courses', { ns: 'analytics' }), value: 'Course' },
    { label: t('lessons', { ns: 'analytics' }), value: 'Lesson' },
    { label: t('learningPlans', { ns: 'analytics' }), value: 'LearningPlan' }
  ];

  const librarySources = [
    { label: t('allLibraries', { ns: 'analytics' }), value: 'All' },
    { label: t('buildwittContent', { ns: 'analytics' }), value: 'BuildWitt' },
    { label: t('companyContent', { ns: 'analytics' }), value: 'Company' },
    { label: t('partnerContent', { ns: 'analytics' }), value: 'Partner' }
  ];

  const sortOptions = [
    {
      label: t('mostEmployeesAssigned', { ns: 'analytics' }),
      value: 'mostEmployeesAssigned'
    },
    {
      label: t('leastEmployeesAssigned', { ns: 'analytics' }),
      value: 'leastEmployeesAssigned'
    },
    { label: t('mostOverdue', { ns: 'analytics' }), value: 'mostOverdue' },
    {
      label: t('mostPendingCompletion', { ns: 'analytics' }),
      value: 'mostPendingCompletion'
    },
    { label: t('mostCompleted', { ns: 'analytics' }), value: 'mostCompleted' }
  ];

  const filterOptions = [
    { label: t('viewAll', { ns: 'analytics' }), value: '' },
    { label: t('active', { ns: 'analytics' }), value: 'active' },
    {
      label: t('subscribedAndActive', { ns: 'analytics' }),
      value: 'subscribed'
    }
  ];

  const contentLibraryData = useSelector(
    (state: ApplicationState) => state.analytics?.contentLibraryData
  );
  const [data, setData] = useState<AnalyticsContentLibrary[] | null>();
  const [learningUnitType, setLearningUnitType] = useState<string>(
    learningUnits[AnalyticsLearningUnitTypeDropdown.courses].value
  );
  const [libraySourceType, setLibraySourceType] = useState<string>(
    librarySources[0].value
  );
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value);
  const [filterOption, setFilterOption] = useState<string>(
    filterOptions[0].value
  );
  const [lessonType, setLessonType] = useState<string>(lessonTypes[0].value);
  const [includeSelfAssignments, setIncludeSelfAssignments] =
    useState<boolean>(true);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [selectedLearningUnitId, setSelectedLearningUnitId] =
    useState<string>();
  const [modalType, setModalType] = useState<string>('');
  const [learningUnitIdForDetailView, setLearningUnitIdForDetailView] =
    useState<string>('');
  const [learningUnitNameForDetailView, setLearningUnitNameForDetailView] =
    useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const commonFilters = useContext(AnalyticsCommonFilterContext);
  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();

  useEffect(() => {
    const activeOnly = filterOption === 'active';
    const subscribedOnly = filterOption === 'subscribed';
    dispatch(
      analyticsActions.getContentLibraryData({
        contentType: learningUnitType,
        librarySource: libraySourceType,
        includeSelfAssignments,
        activeOnly,
        subscribedOnly,
        groupId: commonFilters.groupId,
        supervisorId: commonFilters.supervisorId,
        lessonType
      })
    );

    return () => {
      dispatch(analyticsActions.setContentLibraryData(null));
    };
  }, [
    learningUnitType,
    libraySourceType,
    lessonType,
    includeSelfAssignments,
    filterOption,
    commonFilters.groupId,
    commonFilters.supervisorId
  ]);

  useEffect(() => {
    const contentData = contentLibraryData
      ? sortData(contentLibraryData, sortOption)
      : contentLibraryData;
    setData(contentData);
  }, [contentLibraryData]);

  const handleChangeLearningUnitType = (values: string[]) => {
    const type = values[0] as string;
    if (
      learningUnitType == learningUnits[1].value &&
      type !== learningUnits[1].value
    ) {
      setLessonType(lessonTypes[0].value);
    }
    setLearningUnitType(type);
  };

  const handleChangeSortOption = (values: string[]) => {
    if (!data) {
      return;
    }

    const sortOption = values[0] as string;
    const sortedData = sortData(data, sortOption);
    setData(sortedData);
    setSortOption(sortOption);
  };

  const sortData = (data: AnalyticsContentLibrary[], sortOption: string) => {
    switch (sortOption) {
      case 'mostEmployeesAssigned':
        return data
          .slice()
          .sort(sortByKey<AnalyticsContentLibrary>('employeesAssigned'))
          .reverse();
      case 'leastEmployeesAssigned':
        return data
          .slice()
          .sort(sortByKey<AnalyticsContentLibrary>('employeesAssigned'));
      case 'mostOverdue':
        return data
          .slice()
          .sort(sortByKey<AnalyticsContentLibrary>('overdue'))
          .reverse();
      case 'mostPendingCompletion':
        return data
          .slice()
          .sort(sortByKey<AnalyticsContentLibrary>('pendingCompletion'))
          .reverse();
      case 'mostCompleted':
        return data
          .slice()
          .sort(sortByKey<AnalyticsContentLibrary>('completed'))
          .reverse();
      default:
        return data;
    }
  };

  const handleSearch = (searchFilter: string) => {
    if (!contentLibraryData) {
      return;
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const filteredData = contentLibraryData.filter(item =>
        item.learningUnitName.toLowerCase().includes(searchFilter.toLowerCase())
      );
      const sortedData = sortData(filteredData, sortOption);
      setData(sortedData);
      setSearchFilter(searchFilter);
    }, 1000);
  };

  const handleCreateReport = () => {
    if (!data) {
      return;
    }

    setDownloadingReport(true);
    try {
      downloadAnalyticsContentLibraryReport(data, learningUnitType);
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setDownloadingReport(false);
    }
  };

  const handleClickActionButton = (
    assignmentStatus: string,
    learningUnitId: string
  ) => {
    setSelectedLearningUnitId(learningUnitId);
    setModalType(assignmentStatus);
  };

  const handleCloseModal = () => {
    setSelectedLearningUnitId('');
    setModalType('');
  };

  const handleGoToDetailView = (
    learningUnitId: string,
    learningUnitName: string
  ) => {
    setLearningUnitIdForDetailView(learningUnitId);
    setLearningUnitNameForDetailView(learningUnitName);
  };

  const BackToMainView = () => {
    setLearningUnitIdForDetailView('');
    setLearningUnitNameForDetailView('');
  };

  const handleChangeFilterOption = (items: string[]) => {
    setFilterOption(items[0]);
  };
  if (learningUnitIdForDetailView.length) {
    return (
      <ThemeProvider theme={theme}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <ContentLibraryAnalyticsDetail
          learningUnitId={learningUnitIdForDetailView}
          learningUnitName={learningUnitNameForDetailView}
          includeSelfAssignments={includeSelfAssignments}
          learningUnitType={learningUnitType}
          filterOption={filterOption}
          onBackToContentLibrary={BackToMainView}
        />
      </ThemeProvider>
    );
  }

  if (!learningUnitIdForDetailView.length) {
    return (
      <ThemeProvider theme={theme}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container direction="row">
            <Grid item flex={1}>
              <FormControl
                sx={{ mr: 2, pb: 1 }}
                variant="standard"
                data-testid="learningUnitFilterTestId"
                data-trackid="learningUnitFilterTrackId"
              >
                <BasicSelect
                  labelId="learningUnitFilter"
                  id="learningUnitFilter"
                  options={learningUnits}
                  defaultValue=""
                  value={[learningUnitType]}
                  handleOnChange={handleChangeLearningUnitType}
                  style={selectStyle}
                  theme="dark"
                  multiple={false}
                  disabled={!data}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
              {learningUnitType === learningUnits[1].value && (
                <FormControl
                  sx={{ mr: 2, pb: 1 }}
                  variant="standard"
                  data-testid="lessonTypeFilterTestId"
                  data-trackid="lessonTypeFilterTrackId"
                >
                  <BasicSelect
                    labelId="lessonTypeFilter"
                    id="lessonTypeFilter"
                    options={lessonTypes}
                    defaultValue=""
                    value={[lessonType]}
                    handleOnChange={options => setLessonType(options[0])}
                    style={selectStyle}
                    theme="dark"
                    multiple={false}
                    disabled={!data}
                    sx={{ backgroundColor: 'transparent' }}
                  />
                </FormControl>
              )}
              <FormControl
                sx={{ mr: 2, pb: 1 }}
                variant="standard"
                data-testid="librarySourceFilterTestId"
                data-trackid="librarySourceFilterTrackId"
              >
                <BasicSelect
                  labelId="librarySourceFilter"
                  id="librarySourceFilter"
                  options={librarySources}
                  defaultValue=""
                  value={[libraySourceType]}
                  handleOnChange={options => setLibraySourceType(options[0])}
                  style={selectStyle}
                  theme="dark"
                  multiple={false}
                  disabled={!data}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
              <FormControl
                sx={{ mr: 2, pb: 1 }}
                variant="standard"
                data-testid="sortOptionsTestId"
                data-trackid="sortOptionsTrackId"
              >
                <BasicSelect
                  labelId="sortOptions"
                  id="sortOptions"
                  options={sortOptions}
                  defaultValue=""
                  value={[sortOption]}
                  handleOnChange={handleChangeSortOption}
                  style={{ ...selectStyle, width: '230px' }}
                  theme="dark"
                  multiple={false}
                  disabled={!data}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
              {!employeeActiveUpdates.employeeActiveUpdates && (
                <FormControl
                  sx={{ mr: 2, pb: 1 }}
                  variant="standard"
                  data-testid="filterOptionsTestId"
                  data-trackid="filterOptionsTrackId"
                >
                  <BasicSelect
                    labelId="filterOptions"
                    id="filterOptions"
                    options={filterOptions}
                    value={[filterOption]}
                    placeholder={t('filter', { ns: 'analytics' })}
                    handleOnChange={items => setFilterOption(items[0])}
                    style={selectStyle}
                    theme="dark"
                    multiple={false}
                    disabled={!data}
                    sx={{ backgroundColor: 'transparent' }}
                  />
                </FormControl>
              )}
            </Grid>
            <Grid item>
              <SearchControlPanel
                employeeActiveUpdatesFlag={
                  employeeActiveUpdates.employeeActiveUpdates
                }
                data={data}
                downloadingReport={downloadingReport}
                handleSearchData={handleSearch}
                searchText={searchFilter}
                handleDownloadCsvReport={handleCreateReport}
                includeSelfAssignments={includeSelfAssignments}
                setIncludeSelfAssignments={setIncludeSelfAssignments}
                filterOption={filterOption}
                filterOptions={filterOptions}
                handleChangeFilterOption={handleChangeFilterOption}
              />
            </Grid>
          </Grid>
          <br />
          <ContentLibraryTable
            data={data}
            learningUnitType={learningUnitType}
            onClickActionButton={handleClickActionButton}
            onGotoDetailView={handleGoToDetailView}
          />
        </Box>
        <ModalFactory
          modalType={modalType}
          learningUnitId={selectedLearningUnitId}
          learningUnitType={learningUnitType}
          includeSelfAssignments={includeSelfAssignments}
          onCloseModal={handleCloseModal}
          filterOption={filterOption}
        />
      </ThemeProvider>
    );
  }

  return <></>;
};

const selectStyle: CSSProperties = {
  width: '180px',
  height: '45px'
};

export default ContentLibraryAnalytics;

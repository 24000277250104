import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import BasicButton from '../core/BasicButton/BasicButton';
import { useNavigate } from 'react-router-dom';

export interface CompletedCourseNoticeProps {
  onClose: () => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const CompleteLearningPlanNoticeModal: React.FC<CompletedCourseNoticeProps> = ({
  onClose
}) => {
  const theme = useTheme();
  const defaultDialogWidth: DialogProps['maxWidth'] = 'md';
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();

  const onClick = () => {
    navigate(`/assignments`);
  };

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={true}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
    >
      <DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h4 style={{ textAlign: 'center' }}>
          {t('congratulationsFinishedLearningPlan', { ns: 'common' })}
        </h4>
      </DialogContent>
      <DialogActions>
        <BasicButton style={continueBtnStyle} color="primary" onClick={onClick}>
          {t('continue', { ns: 'common' })}
        </BasicButton>
      </DialogActions>
    </CustomDialog>
  );
};

const continueBtnStyle = {
  marginTop: '20px',
  width: '180px'
};

export default CompleteLearningPlanNoticeModal;

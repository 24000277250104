import LoadingContentIndicator from './LoadingContentIndicator';
import NoContentNotice from './NoContentNotice';

interface ContentIndicatorsProps {
  isLoading: boolean;
  totalItems: number;
  noContentMessage: string;
}

const ContentIndicators: React.FC<ContentIndicatorsProps> = ({
  isLoading,
  totalItems,
  noContentMessage
}) => {
  if (isLoading) {
    return <LoadingContentIndicator />;
  }

  if (!isLoading && totalItems === 0) {
    return <NoContentNotice message={noContentMessage} />;
  }

  return null;
};

export default ContentIndicators;

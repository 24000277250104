import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/analytics';

import {
  ANALYTICS_SET_CONTENT_LIBRARY_DATA,
  ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS,
  ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS,
  ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS,
  ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA,
  ANALYTICS_SET_GROUP_ENGAGEMENT_DATA,
  ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA,
  ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA,
  ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA,
  ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA,
  ANALYTICS_SET_QUIZZES_DATA,
  ANALYTICS_SET_QUIZ_DETAIL_DATA,
  ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS,
  ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS,
  ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE,
  ANALYTICS_SET_QUIZ_RESULT,
  ANALYTICS_SET_SKILLS_DATA
} from '../actions/actionTypes';
import {
  AnalyticsContentLibrary,
  AnalyticsCompletedAssignmentDetail,
  AnalyticsOverdueAssignmentDetail,
  AnalyticsPendingCompletionDetail,
  AnalyticsContentLibraryDetail,
  AnalyticsGroupEngagement,
  AnalyticsGroupEngagementDetail,
  AnalyticsCurrentAssignmentsForGroups,
  AnalyticsEmployeeCompletedAssignment,
  AnalyticsEmployeeOverdueAssignment,
  AnalyticsEmployeeCurrentAssignment,
  AnalyticsGroupCompletedAssigments,
  AnalyticsEmployeeSelfAssignment,
  AnalyticsQuiz,
  AnalyticsQuizDetail,
  AnalyticsPendingCompletionQuizAssignments,
  AnalyticsCompletedQuizAssignments,
  AnalyticsSkills
} from '../entities/Analytics';
import { AssessmentsAverage } from '../entities/Assessment';
import { AssessmentResultWithQuestions } from '../entities/Assessment';
export interface AnalyticsState {
  contentLibraryData: AnalyticsContentLibrary[] | null;
  overdueLearningUnitAssignments: AnalyticsOverdueAssignmentDetail[] | null;
  pendingCompletionAssignments: AnalyticsPendingCompletionDetail[] | null;
  completedLearningUnitAssignments: AnalyticsCompletedAssignmentDetail[] | null;
  contentLibraryDetailData: AnalyticsContentLibraryDetail[] | null;
  groupEngagementData: AnalyticsGroupEngagement[] | null;
  groupEngagementDetailData: AnalyticsGroupEngagementDetail[] | null;
  groupCurrentAssignmentsData: AnalyticsCurrentAssignmentsForGroups[] | null;
  employeeCompletedAssignmentsData:
    | AnalyticsEmployeeCompletedAssignment[]
    | null;
  employeeOverdueAssignmentsData: AnalyticsEmployeeOverdueAssignment[] | null;
  employeeCurrentAssignmentsData: AnalyticsEmployeeCurrentAssignment[] | null;
  groupCompletedAssignmentsData: AnalyticsGroupCompletedAssigments[] | null;
  employeeSelfAssignmentsData: AnalyticsEmployeeSelfAssignment[] | null;
  quizzesData: AnalyticsQuiz[] | null;
  quizDetailData: AnalyticsQuizDetail[] | null;
  pendingCompletionQuizAssignments:
    | AnalyticsPendingCompletionQuizAssignments[]
    | null;
  completedQuizAssignments: AnalyticsCompletedQuizAssignments[] | null;
  assessmentResultsAverage: AssessmentsAverage | null;
  quizResult: AssessmentResultWithQuestions | null;
  skillsData: AnalyticsSkills | null;
}

const unloadedState: AnalyticsState = {
  contentLibraryData: null,
  overdueLearningUnitAssignments: null,
  pendingCompletionAssignments: null,
  completedLearningUnitAssignments: null,
  contentLibraryDetailData: null,
  groupEngagementData: null,
  groupEngagementDetailData: null,
  groupCurrentAssignmentsData: null,
  employeeCompletedAssignmentsData: null,
  employeeOverdueAssignmentsData: null,
  employeeCurrentAssignmentsData: null,
  groupCompletedAssignmentsData: null,
  employeeSelfAssignmentsData: null,
  quizzesData: null,
  quizDetailData: null,
  pendingCompletionQuizAssignments: null,
  completedQuizAssignments: null,
  assessmentResultsAverage: null,
  quizResult: null,
  skillsData: null
};

export const AnalyticsReducer: Reducer<AnalyticsState> = (
  state: AnalyticsState | undefined,
  incomingAction: Action
): AnalyticsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ANALYTICS_SET_CONTENT_LIBRARY_DATA:
      return {
        ...state,
        contentLibraryData: action.contentLibraryData
      };
    case ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS:
      return {
        ...state,
        overdueLearningUnitAssignments: action.overdueLearningUnitAssignments
      };
    case ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS:
      return {
        ...state,
        pendingCompletionAssignments: action.pendingCompletionAssignments
      };
    case ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS:
      return {
        ...state,
        completedLearningUnitAssignments:
          action.completedLearningUnitAssignments
      };
    case ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA:
      return {
        ...state,
        contentLibraryDetailData: action.contentLibraryDetailData
      };
    case ANALYTICS_SET_GROUP_ENGAGEMENT_DATA:
      return {
        ...state,
        groupEngagementData: action.groupEngagementData
      };
    case ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA:
      return {
        ...state,
        groupEngagementDetailData: action.groupEngagementDetailData
      };
    case ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA:
      return {
        ...state,
        groupEngagementDetailData: action.employeeEngagementData
      };
    case ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA:
      return {
        ...state,
        groupCurrentAssignmentsData: action.groupCurrentAssignmentsData
      };
    case ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA:
      return {
        ...state,
        employeeCompletedAssignmentsData:
          action.employeeCompletedAssignmentsData
      };
    case ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA:
      return {
        ...state,
        employeeOverdueAssignmentsData: action.employeeOverdueAssignmentsData
      };
    case ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA:
      return {
        ...state,
        employeeCurrentAssignmentsData: action.employeeCurrentAssignmentsData
      };
    case ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA:
      return {
        ...state,
        groupCompletedAssignmentsData: action.groupCompletedAssignmentsData
      };
    case ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA:
      return {
        ...state,
        employeeSelfAssignmentsData: action.employeeSelfAssignmentsData
      };
    case ANALYTICS_SET_QUIZZES_DATA:
      return {
        ...state,
        quizzesData: action.quizzesData
      };
    case ANALYTICS_SET_QUIZ_DETAIL_DATA:
      return {
        ...state,
        quizDetailData: action.quizDetailData
      };
    case ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS:
      return {
        ...state,
        pendingCompletionQuizAssignments:
          action.pendingCompletionQuizAssignments
      };
    case ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS:
      return {
        ...state,
        completedQuizAssignments: action.completedQuizAssignments
      };
    case ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE:
      return {
        ...state,
        assessmentResultsAverage: action.assessmentResultsAverage
      };
    case ANALYTICS_SET_QUIZ_RESULT:
      return {
        ...state,
        quizResult: action.quizResult
      };
    case ANALYTICS_SET_SKILLS_DATA:
      return {
        ...state,
        skillsData: action.skillsData
      };
    default:
      return state;
  }
};

import { useEffect } from 'react';
import {
  CircularProgress,
  Grid,
  Stack,
  ThemeProvider,
  Typography,
  createTheme
} from '@mui/material';
import themeConfig from '../../../themes/theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import MenuButton, {
  ButtonMenuItem
} from '../../../components/core/MenuButton/Menubutton';
import HaltsCalendar from './HaltsCalendar';
import HaltsDetail from './HaltsDetail';
import HorizontalLine from '../../../components/core/HorizontalLine/HorizontalLine';
import { actionCreators as haltActions } from '../../../actions/halts';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../../core/constants';
import { ApplicationState } from '../../../store';
import HaltsOfPickedDay from './HaltsOfPickedDay';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useHalts } from '../../../hooks/useHalts';
import { SettingsContext } from '../../../contexts/SettingsContext';
import CreateHaltModal from './CreateHaltModal';
import NewEmployeeTrainingHaltModal from '../../../components/CalendarModals/NewEmployeeTrainingHaltModal';
import { useAppDispatch } from '../../../store/hooks';

const CompanyCalendar = () => {
  const dispatch = useAppDispatch();
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['settings']);
  const pickedCalendarDate = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDate
  );
  const {
    state: {
      isDeleting,
      openConfirmationModal,
      openCreateModal,
      savingInProgress,
      selectedCreationOption,
      numberOfIds
    },
    api: {
      handleInitDeleteHalt,
      handleDeleteHalt,
      handleCloseConfirmationModal,
      handleOpenCreateHaltModal,
      handleCloseCreateHaltModal,
      handleSaveHalt
    }
  } = useHalts();

  const menuItems: Array<ButtonMenuItem> = [
    {
      component: <span>{t('newEmployeeHalt')}</span>,
      value: 'newEmployeeHalt'
    },
    {
      component: <span>{t('newCompanyHalt')}</span>,
      value: 'newCompanyHalt'
    },
    {
      component: <span>{t('personalHalt')}</span>,
      value: 'newPersonalHalt'
    }
  ];

  useEffect(() => {
    return () => {
      dispatch(haltActions.setTodayHalts(null));
      dispatch(haltActions.setHaltsByDate(null));
      dispatch(haltActions.setPickedCalendarDate(''));
      dispatch(haltActions.setEmployeeHalts(null));
    };
  }, []);

  const handleCreatePersonalHalt = () => {
    handleOpenCreateHaltModal('newPersonalHalt');
  };

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Grid container direction="column" sx={{ paddingTop: '0.5rem' }}>
        <Grid
          item
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap'
          }}
        >
          <Stack>
            <Typography sx={theme.typography.h5}>
              {t('companyCalendar')}
            </Typography>
            <ProtectedComponent
              action={appPermissions.CREATE_COMPANY_TRAINING_HALTS}
            >
              <Typography sx={theme.typography.subtitle1}>
                {t('companyCalendarSubtile')}
              </Typography>
            </ProtectedComponent>
          </Stack>
          <Stack>
            <ProtectedComponent
              action={appPermissions.CREATE_COMPANY_TRAINING_HALTS}
            >
              <MenuButton
                id="createHaltOptions"
                buttonText={t('newTrainingHalt')}
                onClickOption={handleOpenCreateHaltModal}
                menuItems={menuItems}
              />
            </ProtectedComponent>
            <ProtectedComponent
              action={appPermissions.CREATE_ONLY_PERSONAL_HALTS}
            >
              <BasicButton onClick={handleCreatePersonalHalt} color="primary">
                {t('createHalt', { ns: 'settings' })}
              </BasicButton>
            </ProtectedComponent>
          </Stack>
        </Grid>
        <Grid item sx={{ paddingTop: '1.5rem' }}>
          <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
            <HaltsCalendar />
            <SettingsContext.Provider
              value={{ initDeleteHalt: handleInitDeleteHalt }}
            >
              {!!pickedCalendarDate?.length && <HaltsOfPickedDay />}
              {!pickedCalendarDate?.length && <HaltsDetail />}
            </SettingsContext.Provider>
          </Stack>
        </Grid>
      </Grid>
      <ConfirmationModal
        show={openConfirmationModal}
        showSave={false}
        showCancel={false}
        maxWidth="xs"
        onSave={handleDeleteHalt}
        onCancel={handleCloseConfirmationModal}
      >
        <Stack spacing={3}>
          <span>
            {numberOfIds > 1
              ? t('deleteSeveralHalts', { ns: 'settings' })
              : t('doYouWantToDeleteHalt', { ns: 'settings' })}
          </span>
          <Stack direction="row" spacing={3} sx={{ justifyContent: 'center' }}>
            {isDeleting ? (
              <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <BasicButton
                  onClick={handleCloseConfirmationModal}
                  color="secondary"
                >
                  {t('cancel', { ns: 'settings' })}
                </BasicButton>
                <BasicButton onClick={handleDeleteHalt} color="confirm">
                  {numberOfIds > 1
                    ? t('deleteHalts', { ns: 'settings' })
                    : t('deleteHalt', { ns: 'settings' })}
                </BasicButton>
              </>
            )}
          </Stack>
        </Stack>
      </ConfirmationModal>
      {selectedCreationOption === 'newCompanyHalt' && (
        <CreateHaltModal
          showModal={openCreateModal}
          savingInProgress={savingInProgress}
          datePickerLabel={t('datePickerLabelCompanyHaltCreation')}
          onClose={handleCloseCreateHaltModal}
          onSave={handleSaveHalt}
        />
      )}
      {selectedCreationOption === 'newPersonalHalt' && (
        <CreateHaltModal
          showModal={openCreateModal}
          savingInProgress={savingInProgress}
          datePickerLabel={t('datePickerLabelPersonalHaltCreation')}
          onClose={handleCloseCreateHaltModal}
          onSave={handleSaveHalt}
          datePickerTitle={t('newPersonalTrainingHalt')}
        />
      )}
      {selectedCreationOption === 'newEmployeeHalt' && (
        <NewEmployeeTrainingHaltModal
          showModal={openCreateModal}
          onClose={handleCloseCreateHaltModal}
          savingInProgress={savingInProgress}
          onSave={handleSaveHalt}
        />
      )}
    </ThemeProvider>
  );
};

export default CompanyCalendar;

import { useEffect, useRef, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Theme, Typography, useTheme } from '@mui/material';

import { AnalyticsDataGrid } from '../../../components/Analytics';
import { AnalyticsEmployeeCompetence } from '../../../entities/Analytics';
import {
  getFullNameColumn,
  getFormatedValueColumn,
  getButtonColumn
} from '../common/utilAnalyticsColumnDefinition';
import { PagedResult } from '../../../entities/Common';
import EmployeeSkillCertModal from '../modals/EmployeeSkillCertModal';
import EmployeeSkillCertTable from '../Tables/EmployeeSkillCertTable';
import { useSelector } from 'react-redux';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { ApplicationState } from '../../../store';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';
interface SkillTableProps {
  data?: PagedResult<AnalyticsEmployeeCompetence> | null;
  pageSize?: number;
  onPageChange: (page: number) => void;
  onSortModelChange: (field: string, sort: string) => void;
  onlyCertificates: boolean;
}

const GetSkillNumbers =
  (fieldName: string, theme: Theme) => (params: GridRenderCellParams<any>) => {
    const value = params.row[fieldName] as number;
    const mappedColors: { [name: string]: string } = {
      awarded: theme.palette.success.main,
      expiring: theme.palette.primary.main,
      incomplete: theme.palette.grey[100],
      expired: theme.palette.error.light
    };

    return (
      <Typography
        sx={{
          color: value ? mappedColors[fieldName] : theme.palette.common.white
        }}
      >
        {value}
      </Typography>
    );
  };

const SkillTable = ({
  data,
  pageSize,
  onPageChange,
  onSortModelChange,
  onlyCertificates
}: SkillTableProps) => {
  const { t } = useTranslation(['analytics']);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [rowCountState, setRowCountState] = useState<number>(
    data?.totalItems || 0
  );

  const pageModal = useRef<number>(1);

  const myCompetencesAnalytics = useSelector(
    (state: ApplicationState) => state.achievements?.myCompetencesAnalytics
  );

  const dataCompetences = myCompetencesAnalytics
    ? myCompetencesAnalytics.pageItems
    : [];

  useEffect(() => {
    setRowCountState(prevRowCountState =>
      data?.totalItems !== undefined ? data?.totalItems : prevRowCountState
    );
  }, [data?.totalItems, setRowCountState]);

  const handlePageChange = (page: number) => onPageChange(page);
  const PAGE_SIZE = 50;

  const fetchCompetences = (pageNumber: number, employeeId: string) => {
    pageModal.current = pageNumber;
    dispatch(
      achievementActions.getMyCompetencesAnalytics(
        employeeId,
        pageNumber,
        PAGE_SIZE,
        true,
        onlyCertificates
      )
    );
  };

  const handleClickManageButton = (params: GridRenderCellParams<any>) => {
    const { expired, awarded, expiring, incomplete } = params.row;
    if (!expired && !awarded && !expiring && !incomplete) {
      enqueueSnackbar(
        t('noSkillsToManage').replace(
          'name',
          `${params.row.firstName} ${params.row.lastName}`
        ),
        { variant: 'warning' }
      );
      return;
    }
    fetchCompetences(1, params.row.employeeId);
    setOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(achievementActions.cleanMyCompetencesAnalytics());
    setOpen(false);
  };

  const columns: GridColDef[] = [
    getFullNameColumn(t('employee', { ns: 'analytics' }), 'name', true),
    getFormatedValueColumn(
      'awarder',
      t('awarded', { ns: 'analytics' }),
      170,
      GetSkillNumbers('awarded', theme),
      true
    ),
    getFormatedValueColumn(
      'expiring',
      t('expiring', { ns: 'analytics' }),
      170,
      GetSkillNumbers('expiring', theme),
      true
    ),
    getFormatedValueColumn(
      'incomplete',
      t('incomplete', { ns: 'analytics' }),
      170,
      GetSkillNumbers('incomplete', theme),
      true
    ),
    getFormatedValueColumn(
      'expired',
      t('expired', { ns: 'analytics' }),
      170,
      GetSkillNumbers('expired', theme),
      true
    ),
    getButtonColumn(
      'employeeId',
      t('manage', { ns: 'analytics' }),
      t('manage', { ns: 'analytics' }),
      130,
      handleClickManageButton
    )
  ];

  if (onlyCertificates) {
    columns.splice(3, 1);
    columns.splice(4, 1);
  }

  return (
    <>
      <AnalyticsDataGrid
        pageSize={pageSize}
        columns={columns}
        rows={data?.pageItems}
        rowId="employeeId"
        paginationMode="server"
        rowCount={rowCountState}
        onPageChange={handlePageChange}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
      />

      <EmployeeSkillCertModal
        showModal={open}
        onCloseModal={handleCloseModal}
        onlyCertificates={onlyCertificates}
      >
        <EmployeeSkillCertTable
          data={dataCompetences}
          onlyCertificates={onlyCertificates}
        />
      </EmployeeSkillCertModal>
    </>
  );
};

export default SkillTable;

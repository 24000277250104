import { MaterialDesignContent } from 'notistack';
import { styled } from '@mui/styles';

const NotificationToast = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-default': {
    backgroundColor: '#C4C4C4',
    color: '#0E0D0D',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2ED47A',
    color: '#0E0D0D',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#FF2A20',
    color: '#0E0D0D',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: '#FF9E00',
    color: '#0E0D0D',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: '#C4C4C4',
    color: '#0E0D0D',
    fontFamily: 'Titillium Web',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px'
  }
}));

//   default, success, error, warning, info

export default NotificationToast;

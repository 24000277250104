import { useState } from 'react';
import { useTheme, Box, CircularProgress } from '@mui/material';
import {
  GridCallbackDetails,
  GridColDef,
  GridPaginationModel,
  GridRowHeightParams,
  GridRowHeightReturnValue,
  GridSortModel,
  GridValidRowModel
} from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import CustomDataGrid, {
  getCustomRowClassName
} from '../CustomDataGrid/CustomDataGrid';

interface AnalyticsDataGridProps {
  pageSize?: number;
  rows?: GridValidRowModel[] | null;
  columns: GridColDef[];
  rowId: string;
  paginationMode?: 'client' | 'server';
  rowCount?: number;
  onPageChange?: (page: number) => void;
  sortingMode?: 'client' | 'server';
  onSortModelChange?: (field: string, sort: string) => void;
  rowHeightFunc?: (params: GridRowHeightParams) => GridRowHeightReturnValue;
}

const AnalyticsDataGrid = ({
  pageSize = 50,
  rows,
  columns,
  rowId,
  paginationMode = 'client',
  rowCount,
  onPageChange,
  sortingMode = 'client',
  onSortModelChange,
  rowHeightFunc
}: AnalyticsDataGridProps) => {
  const theme = useTheme();
  const [page, setPage] = useState<number>(0);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const getPage = () => {
    if (paginationMode === 'server') return page;
  };

  const handlePageChange = (page: number) => {
    if (onPageChange) {
      onPageChange(page + 1);
    }
    setPage(page);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    if (onSortModelChange && model.length) {
      onSortModelChange(model[0].field, model[0].sort!.toUpperCase());
    }
  };

  let onPaginationModelChange = undefined;
  let initialState: GridInitialStateCommunity | undefined = undefined;
  let paginationModel = undefined;
  if (paginationMode === 'client') {
    initialState = {
      pagination: {
        paginationModel: {
          pageSize: pageSize
        }
      }
    };
  } else {
    paginationModel = {
      pageSize,
      page: getPage() ?? 0
    };

    onPaginationModelChange = (
      model: GridPaginationModel,
      details: GridCallbackDetails
    ) => handlePageChange(model.page);
  }

  return (
    <Box
      sx={{
        height: '55vh',
        width: '100%',
        border: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.grey.A200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px'
      }}
    >
      {!rows && <CircularProgress />}
      {rows && (
        <CustomDataGrid
          getRowId={row => row[rowId]}
          rows={rows}
          columns={columns}
          initialState={initialState}
          paginationModel={paginationModel}
          pageSizeOptions={[pageSize]}
          getRowClassName={getCustomRowClassName}
          rowSelection={false}
          disableColumnMenu
          getRowHeight={rowHeightFunc}
          paginationMode={paginationMode}
          sortingMode={sortingMode}
          rowCount={rowCount}
          onPaginationModelChange={onPaginationModelChange}
          onSortModelChange={handleSortModelChange}
          sortModel={sortModel}
        />
      )}
    </Box>
  );
};

export default AnalyticsDataGrid;

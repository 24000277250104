import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const errorStyle = { color: 'red' };

export const FormErrors = ({
  errors,
  title
}: {
  errors: string[];
  title?: string;
}) => {
  const { t } = useTranslation(['common']);
  const errorTitleRef = useRef<string>(
    title ?? t('fillRequiredFields', { ns: 'common' })
  );

  return (
    <span style={errorStyle}>
      {errorTitleRef.current}:
      <ul>
        {errors.map(error => (
          <li>{error}</li>
        ))}
      </ul>
    </span>
  );
};

import { Grid, Typography, useTheme } from '@mui/material';
import { AssignedOnsiteTrainingClass } from '../../../entities/LearningPlaylist';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { OnsiteTrainingDates } from '../../../components/OnsiteTrainingAssignmentCard/OnsiteTrainingDates';
import { useTranslation } from 'react-i18next';

export const OnsiteTrainingClassDetailsLocationAndDates = ({
  onsiteTrainingClass
}: {
  onsiteTrainingClass: AssignedOnsiteTrainingClass;
}) => {
  const onsiteTrainingClassTime = absUtcDateToLocalMoment(
    onsiteTrainingClass.assignments[0].dateUtc
  ).format('LT');

  const { t } = useTranslation(['onsiteTraining']);
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ padding: '16px 20px 0px 20px' }}
      spacing={0.5}
      wrap={'wrap'}
    >
      <Grid item>
        <Typography fontSize={13} color={theme.palette.common.white}>
          Location:
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={13} color={theme.palette.primary.main}>
          {onsiteTrainingClass.onsiteTrainingClass.location}
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={13} color={theme.palette.common.white}>
          |
        </Typography>
      </Grid>
      <Grid item>
        <OnsiteTrainingDates
          assignedClass={onsiteTrainingClass}
          caption={`${t('onsiteTrainingClassDates')}:`}
        />
      </Grid>
      <Grid item>
        <Typography fontSize={13} color={theme.palette.common.white}>
          at
        </Typography>
      </Grid>
      <Grid item>
        <Typography fontSize={13} color={theme.palette.primary.main}>
          {onsiteTrainingClassTime}
        </Typography>
      </Grid>
    </Grid>
  );
};

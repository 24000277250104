import { CSSProperties, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { OnsiteTrainingEmployees } from '../../entities/OnsiteTraining';
import ChipAttendanceOnsite from '../../components/core/ChipAttendance/ChipAttendanceOnsite';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import Panel from '../../components/core/Panel/Panel';
import { Row, Col } from 'react-bootstrap';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import TextDropdownList from '../../components/core/TextDropdownList/TextDropdownList';
import { SortEmployeeOption } from '../../entities/SortEmployeeOption';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../components/CustomDataGrid/CustomDataGrid';

interface ApproveEmployeeOnsiteTableProps {
  data?: OnsiteTrainingEmployees[] | null;
}

const ApproveEmployeeOnsiteTable = ({
  data
}: ApproveEmployeeOnsiteTableProps) => {
  const { t } = useTranslation(['onsiteTraining', 'employees', 'common']);
  const [attendanceEmployee, setAttendanceEmployee] = useState<boolean>(false);
  const [applyAllChecked, setApplyAllChecked] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: t('employee', { ns: 'employees' }),
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div className="d-flex ps-1 pt-2 pb-2">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../assets/user.png')
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: '#999999' }}>
              {params.row.email}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'attendance',
      headerName: t('attendance', { ns: 'onsiteTraining' }),
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <ChipAttendanceOnsite
          color={params.row.attendance}
          attendanceText={
            params.row.attendance === 'Attend'
              ? 'Attended All 3 Days'
              : 'Missed Day 2'
          }
        />
      )
    },
    {
      field: 'buttons',
      headerName: '',
      width: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <FormCheckBox
          onChange={() => setAttendanceEmployee(!attendanceEmployee)}
          checked={attendanceEmployee}
          label={t('approve', { ns: 'common' })}
        />
      )
    }
  ];

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setApplyAllChecked(checked);
  };

  return (
    <Panel width="90%" height="100%" backgroundColor="#1B1C1C">
      <Row className="pb-1">
        <Col md={12} lg={6} className="pt-1">
          <SearchBox
            id="search"
            value=""
            name="search"
            width="100%"
            height="38px"
            placeholderText={t('search', { ns: 'common' })}
            variant="dark"
          />
        </Col>
        <Col md={12} lg={6} className="pt-1">
          <FormCheckBox
            onChange={handleCheckBoxChange}
            checked={applyAllChecked}
            label={t('approveAll', { ns: 'onsiteTraining' })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={dropdownContainerStyle}>
            <TextDropdownList
              label={`${t('sort', { ns: 'common' })}:`}
              items={[
                { value: SortEmployeeOption.Az, text: 'A-Z' },
                { value: SortEmployeeOption.Za, text: 'Z-A' }
              ]}
            />
          </div>
        </Col>
      </Row>
      <Box
        sx={{
          height: '60vh',
          width: '100%'
        }}
      >
        {data && !!data.length && (
          <ModifiedCustomDataGrid
            getRowId={data => data.employeeId}
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 100
                }
              }
            }}
            pageSizeOptions={[100]}
            getRowClassName={getCustomRowClassName}
            rowSelection={false}
            disableColumnMenu
          />
        )}
      </Box>
    </Panel>
  );
};

const dropdownContainerStyle: CSSProperties = {
  width: '100%',
  textAlign: 'right',
  paddingTop: '0px'
};

const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  fontSize: '16px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid #2D2D2D`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid #2D2D2D`,
    maxHeight: 'none !important',
    lineHeight: 'unset !important',
    whiteSpace: 'normal'
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid #2D2D2D`,
    borderLeft: `1px solid #2D2D2D`,
    maxHeight: 'none !important',
    lineHeight: 'unset !important',
    whiteSpace: 'normal'
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid #2D2D2D`
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'auto',
    overflowY: 'auto'
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important'
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important'
  }
}));

export default ApproveEmployeeOnsiteTable;

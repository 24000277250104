import { ContentLibraryTabs } from '../core/constants';
import { Tag } from '../entities/Tag';
import { usePersistentStorageValue } from './usePersistentStorage';
import { persistentStorage } from '../entities/PersistentStorage';

export type LearningUnitType = 'learningPlans' | 'courses' | 'lessons';
export type ContentStatus = 'allContent' | 'published' | 'draft';
export type LangContent = 'all' | 'spanishEnabled';
export type Sorting = 'A-Z' | 'Z-A' | 'desc' | 'asc';
export type Navigation = 'ContentLibrary';

export interface ContentLibraryTabState {
  learningUnitFilter: LearningUnitType;
  contentStatusFilter: ContentStatus;
  langFilter: LangContent;
  sorting: Sorting;
  search: string;
  scrollPosition: number;
  tagFilter: Tag[];
}

export interface ContentLibraryNavigationApi {
  changeTab: (value: string) => void;

  changeScrollPosition: (value: number) => void;

  changeSorting: (value: Sorting) => void;

  changeSearch: (value: string) => void;

  changeLearningUnit: (value: LearningUnitType) => void;

  changeContentStatus: (value: ContentStatus) => void;

  changeLangFilter: (value: LangContent) => void;

  changeTagFilter: (value: Tag[]) => void;

  clearTabsSearchValue: () => void;
}

interface IContentLibraryNavigation {
  lastTab: string;
  tabsState: { [key: string]: ContentLibraryTabState };
}

const defaultInitialTabState: ContentLibraryTabState = {
  contentStatusFilter: 'allContent',
  learningUnitFilter: 'courses',
  search: '',
  langFilter: 'all',
  sorting: 'A-Z',
  scrollPosition: 0,
  tagFilter: []
};

const partnerInitialTabState: ContentLibraryTabState = {
  contentStatusFilter: 'allContent',
  learningUnitFilter: 'courses',
  search: '',
  langFilter: 'all',
  sorting: 'A-Z',
  scrollPosition: 0,
  tagFilter: []
};

const initialContentLibraryNavigationState: IContentLibraryNavigation = {
  lastTab: 'allcontent',
  tabsState: {
    allcontent: { ...defaultInitialTabState }
  }
};

export const useContentLibraryNavigation = () => {
  const [state, setState] =
    usePersistentStorageValue<IContentLibraryNavigation>(
      'contentLibraryNavigation',
      initialContentLibraryNavigationState
    );

  const changeScrollPosition = (value: number) => {
    setTabState(state.lastTab, 'scrollPosition', value);
  };

  const changeSorting = (value: Sorting) => {
    setTabState(state.lastTab, 'sorting', value);
  };

  const changeSearch = (value: string) => {
    setTabState(state.lastTab, 'search', value);
  };

  const changeLearningUnit = (value: LearningUnitType) => {
    setTabState(state.lastTab, 'learningUnitFilter', value);
  };

  const changeContentStatus = (value: ContentStatus) => {
    setTabState(state.lastTab, 'contentStatusFilter', value);
  };

  const changeLangFilter = (value: LangContent) => {
    setTabState(state.lastTab, 'langFilter', value);
  };

  const changeTagFilter = (value: Tag[]) => {
    setTabState(state.lastTab, 'tagFilter', value);
  };

  const changeTab = (tab: string) => {
    if (state.tabsState[tab] !== undefined) {
      setState(prevState => ({ ...prevState, lastTab: tab }));
    } else {
      const initialTabState =
        tab === ContentLibraryTabs.Partner
          ? partnerInitialTabState
          : defaultInitialTabState;

      setState(prevState => ({
        ...prevState,
        lastTab: tab,
        tabsState: { ...prevState.tabsState, [tab]: { ...initialTabState } }
      }));
    }
  };
  const setTabState = (
    tab: string,
    key: keyof ContentLibraryTabState,
    value: any
  ) => {
    setState(prevState => {
      const tabState = prevState.tabsState[tab] ?? {};
      return {
        ...prevState,
        lastTab: tab,
        tabsState: {
          ...prevState.tabsState,
          [tab]: { ...tabState, [key]: value }
        }
      };
    });
  };

  const clearTabsSearchValue = () => {
    const valueFromStorage = persistentStorage.getItem(
      'contentLibraryNavigation'
    );
    for (const key in valueFromStorage.tabsState) {
      valueFromStorage.tabsState[key].search = '';
    }
    persistentStorage.setItem('contentLibraryNavigation', valueFromStorage);
  };

  return [
    state.lastTab,
    state.tabsState[state.lastTab],
    {
      changeTab,
      changeLangFilter,
      changeContentStatus,
      changeLearningUnit,
      changeScrollPosition,
      changeSearch,
      changeSorting,
      changeTagFilter,
      clearTabsSearchValue
    }
  ] as const;
};

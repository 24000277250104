import { AppThunk } from '../store/index';
import axios from 'axios';
import {
  SUBSCRIPTION_IS_LOADING,
  SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS
} from './actionTypes';
import config from './../config';
import { SubscriptionWithSubscribers } from '../entities/Subscription';
import { HttpStatusEnum } from '../core/enums';
import { handleGenericBackendError } from '../utils/errorHandling';

export interface SetIsLoadingAction {
  type: 'SUBSCRIPTION_IS_LOADING';
  isLoading: boolean;
}

export interface SetSubscriptionsSubscribersAction {
  type: 'SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS';
  subscriptionsSubscribers: SubscriptionWithSubscribers[];
}

export const actionCreators = {
  requestSubscriptionsSubscribers:
    (companyId: string, productHandle: string[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: SUBSCRIPTION_IS_LOADING,
          isLoading: true
        });

        try {
          const subscriptions: SubscriptionWithSubscribers[] = [];
          for (const handle of productHandle) {
            const res = await axios.get(
              `${config.AUTHORITY_API_URL}subscriptions/${companyId}/product/${handle}`
            );
            if (res.status === HttpStatusEnum.OK) {
              subscriptions.push(...res.data);
            }
          }

          dispatch({
            type: SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS,
            subscriptionsSubscribers: subscriptions
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: SUBSCRIPTION_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  setSubscriptionsSubscribers:
    (subscriptionsSubscribers: SubscriptionWithSubscribers[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS,
          subscriptionsSubscribers
        });
      }
    }
};

export type KnownAction =
  | SetIsLoadingAction
  | SetSubscriptionsSubscribersAction;

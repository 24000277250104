import { CSSProperties } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import {
  getFullNameColumn,
  getGenericValueActionColumn,
  getTimeSpentInTrainingColumn
} from '../common/utilAnalyticsColumnDefinition';
import { AnalyticsDataGrid } from '../../../components/Analytics';
import { AnalyticsGroupEngagementDetail } from '../../../entities/Analytics';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';

interface GroupEngagementDetailTableProps {
  data?: AnalyticsGroupEngagementDetail[] | null;
  onClickActionButton: (
    action: string,
    employeeId: string,
    employeeName: string
  ) => void;
}

const getNodeValue = (field: string) => (params: GridRenderCellParams<any>) => {
  return (
    <span style={params.row[field] > 0 ? {} : ZeroValueFontStyle}>
      {params.row[field]}
    </span>
  );
};

const GroupEngagementDetailTable = ({
  data,
  onClickActionButton
}: GroupEngagementDetailTableProps) => {
  const { t } = useTranslation(['analytics']);

  const handleClickActionButton =
    (action: string) => (params: GridRenderCellParams<any, number>) => {
      const { employeeId, firstName, lastName } = params.row;
      onClickActionButton(action, employeeId, `${firstName} ${lastName}`);
    };

  const columns: GridColDef[] = [
    getFullNameColumn(t('employeeName', { ns: 'analytics' })),
    getGenericValueActionColumn(
      'currentAssignments',
      t('currentAssignments', { ns: 'analytics' }),
      t('manage', { ns: 'analytics' }),
      200,
      handleClickActionButton('employeeCurrentAssignments'),
      getNodeValue('currentAssignments')
    ),
    getGenericValueActionColumn(
      'selfAssigned',
      t('selfAssigned', { ns: 'analytics' }),
      t('view', { ns: 'analytics' }),
      180,
      handleClickActionButton('employeeSelfAssignments'),
      getNodeValue('selfAssigned')
    ),
    getGenericValueActionColumn(
      'overdueAssignments',
      t('overdueAssignments', { ns: 'analytics' }),
      t('manage', { ns: 'analytics' }),
      200,
      handleClickActionButton('employeeOverdueAssignments'),
      getNodeValue('overdueAssignments')
    ),
    getGenericValueActionColumn(
      'totalCompleted',
      t('totalCompleted', { ns: 'analytics' }),
      t('view', { ns: 'analytics' }),
      180,
      handleClickActionButton('employeeCompletedAssignments'),
      getNodeValue('totalCompleted')
    ),
    getTimeSpentInTrainingColumn(
      t('timeSpentInTraining', { ns: 'analytics' }),
      180
    ),
    {
      field: 'lastActive',
      headerName: t('lastActive', { ns: 'analytics' }),
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span style={params.row.lastActive ? {} : ZeroValueFontStyle}>
          {params.row.lastActive
            ? absUtcDateToLocalMoment(params.row.lastActive).format('MM.DD.YY')
            : t('never', { ns: 'analytics' })}
        </span>
      )
    }
  ];

  return <AnalyticsDataGrid columns={columns} rows={data} rowId="employeeId" />;
};

const ZeroValueFontStyle: CSSProperties = {
  color: '#FF0000'
};

export default GroupEngagementDetailTable;

import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import ModalSkillGrid from './modals/ModalSkillGrid';
import {
  getDownloadColumn,
  getFileNameColumn,
  getRemoveColumn
} from './utils/utilSkillsColumnDefinition';
import { CompetenceAttachment } from '../../entities/Achievements';
import {
  actionCreators,
  actionCreators as achievementsActions
} from '../../actions/achievements';
import ConfirmationModal from '../../components/core/ConfirmationModal/ConfirmationModal';
import { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';

interface OverdueListProps {
  fileNames?: CompetenceAttachment[] | null;
}

const AttachedFilesList = ({ fileNames }: OverdueListProps) => {
  const { t } = useTranslation(['skills']);
  const dispatch = useAppDispatch();
  const [competenceAttachmentToDelete, setCompetenceAttachmentToDelete] =
    useState<CompetenceAttachment | null>(null);
  const onDeletePressed = (competenceAttachment: CompetenceAttachment) => {
    setCompetenceAttachmentToDelete(competenceAttachment);
  };

  const handleDeleteAttachment = () => {
    if (competenceAttachmentToDelete?.id) {
      dispatch(
        achievementsActions.deleteCompetenceAttachment(
          competenceAttachmentToDelete
        )
      );
      dispatch(
        actionCreators.getCompetenceDetail(
          competenceAttachmentToDelete.competenceId
        )
      );
      handleClose();
    }
  };

  const columns: GridColDef[] = (() => [
    getFileNameColumn(t('files', { ns: 'skills' })),
    getDownloadColumn(t('download', { ns: 'skills' })),
    getRemoveColumn(t('remove', { ns: 'skills' }), onDeletePressed)
  ])();

  const handleClose = () => {
    setCompetenceAttachmentToDelete(null);
  };

  return (
    <>
      <ModalSkillGrid rows={fileNames} columns={columns} />
      <ConfirmationModal
        show={!!competenceAttachmentToDelete}
        showSave={true}
        showCancel={true}
        maxWidth="sm"
        saveText={t('remove')}
        cancelText={t('cancel', { ns: 'common' })}
        title={
          t('remove') + ' ' + competenceAttachmentToDelete?.competenceFile.name
        }
        onSave={handleDeleteAttachment}
        onCancel={handleClose}
        showModalContentCentered={true}
        isGrayCancelButton={true}
        hasButtonsSameWidth={true}
      >
        <p>{t('areYouSure', { ns: 'common' })}</p>
      </ConfirmationModal>
    </>
  );
};

export default AttachedFilesList;

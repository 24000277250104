import DatePickerInput from '../../../components/core/DatePickerInput/DatePickerInput';

interface ExpirationDateEditorProps {
  expirationDate: Date | null;
  onChangeExpirationDate: (value: Date) => void;
}

export const ExpirationDateEditor: React.FC<ExpirationDateEditorProps> = ({
  expirationDate,
  onChangeExpirationDate
}) => (
  <div className="d-flex flex-row px-2" style={{ marginTop: '-7px' }}>
    <DatePickerInput
      disablePastDays={true}
      onChange={onChangeExpirationDate}
      value={expirationDate}
      backgroundColorBlack={true}
      smallVersion={true}
    />
  </div>
);

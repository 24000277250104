import React, { CSSProperties } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton
} from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import TrashIcon from '../Icons/TrashIcon';
import { SxProps, useTheme } from '@mui/material/styles';
export interface AccordionProps {
  title: string;
  onDelete: () => void;
  expanded: boolean;
  handleChange: (event: any, isExpanded: boolean) => void;
  variant?: 'light' | 'dark' | undefined;
}

const trashIconStyle: CSSProperties = {
  background: '#999999',
  width: '20px',
  height: '20px',
  marginTop: '2px',
  marginRight: '6px'
};

const AccordionComponent: React.FC<React.PropsWithChildren<AccordionProps>> = ({
  title,
  children,
  onDelete,
  expanded,
  handleChange,
  variant = 'light'
}) => {
  const theme = useTheme();
  const onDeleteButton = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onDelete();
  };

  const bgColor: SxProps = {
    backgroundColor:
      variant === 'light'
        ? theme.palette.secondary.main
        : theme.palette.common.black
  };

  const fontcolor: SxProps = {
    color: theme.palette.common.white
  };

  const accordionStyle: SxProps = {
    color: theme.palette.common.white,
    borderBottomColor: theme.palette.grey[100],
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px'
  };

  return (
    <div>
      <Accordion
        sx={accordionStyle}
        expanded={expanded}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<ExpandCircleDownIcon sx={fontcolor} />}
          aria-controls={`Panel for ${title}`}
          id={title}
          sx={bgColor}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          <div style={divAccordion}>
            <Typography>{title}</Typography>
            <IconButton
              color="default"
              onClick={onDeleteButton}
              aria-label="Delete"
              style={trashIconStyle}
              size="small"
            >
              <TrashIcon viewBox="-9 -9 34 34" />
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={bgColor}
          style={{ paddingLeft: '0px', paddingRight: '0px' }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const divAccordion: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%'
};

export default AccordionComponent;

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/certifications';
import { Certification, MyCertification } from '../entities/Certification';
import { GridResponse, PagedResult } from '../entities/Common';
import {
  CERTIFICATIONS_SET_CERTIFICATIONS,
  CERTIFICATIONS_SET_ISLOADING,
  CERTIFICATIONS_REFETCH_CERTIFICATIONS,
  CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS,
  CERTIFICATIONS_SET_CERTIFICATION_NAMES,
  CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION,
  CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING,
  CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET,
  CERTIFICATIONS_SET_MY_CERTIFICATIONS,
  CERTIFICATIONS_SET_TRANSACTION_ERROR,
  CERTIFICATIONS_SET_EMPLOYEES_NAMES,
  CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS
} from '../actions/actionTypes';
import { EmployeeCertification } from '../entities/EmployeeCertification';

export interface CertificationsState {
  certifications: PagedResult<Certification> | null;
  isLoading: boolean;
  refetchCertifications: boolean;
  employeeCertifications: GridResponse<EmployeeCertification> | null;
  certificationNames: string[];
  employeesByCertification: GridResponse<EmployeeCertification> | null;
  employeesByCertificationIsLoading: boolean;
  employeesToAward: {
    loading: boolean;
    employees: EmployeeCertification[] | [];
    totalCount: number;
  };
  myCertificationsPageResult: PagedResult<MyCertification> | null;
  transactionError: boolean;
  employeesNames: string[];
  forceFetchEmployeeCertifications: boolean;
}

const unloadedState: CertificationsState = {
  certifications: null,
  isLoading: false,
  refetchCertifications: false,
  employeeCertifications: null,
  certificationNames: [],
  employeesByCertification: null,
  employeesByCertificationIsLoading: false,
  employeesToAward: { loading: false, employees: [], totalCount: 0 },
  myCertificationsPageResult: null,
  transactionError: false,
  employeesNames: [],
  forceFetchEmployeeCertifications: false
};

export const CertificationsReducer: Reducer<CertificationsState> = (
  state: CertificationsState | undefined,
  incomingAction: Action
): CertificationsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case CERTIFICATIONS_SET_CERTIFICATIONS:
      return {
        ...state,
        certifications: action.certifications
      };
    case CERTIFICATIONS_SET_ISLOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case CERTIFICATIONS_REFETCH_CERTIFICATIONS:
      return {
        ...state,
        refetchCertifications: action.refetchCertifications
      };
    case CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS:
      return {
        ...state,
        employeeCertifications: action.employeeCertifications
      };
    case CERTIFICATIONS_SET_CERTIFICATION_NAMES:
      return {
        ...state,
        certificationNames: action.certificationNames
      };
    case CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION:
      return {
        ...state,
        employeesByCertification: action.employeesByCertification
      };
    case CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING:
      return {
        ...state,
        employeesByCertificationIsLoading:
          action.employeesByCertificationIsLoading
      };
    case CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD:
      if (action.employeesToBeAward.concatenateFlag) {
        return {
          ...state,
          employeesToAward: {
            ...state.employeesToAward,
            employees: [
              ...state.employeesToAward.employees,
              ...action.employeesToBeAward.data.rows
            ],
            totalCount: action.employeesToBeAward.data.totalCount
          }
        };
      }
      return {
        ...state,
        employeesToAward: {
          ...state.employeesToAward,
          employees: action.employeesToBeAward.data.rows,
          totalCount: action.employeesToBeAward.data.totalCount
        }
      };
    case CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING:
      return {
        ...state,
        employeesToAward: {
          ...state.employeesToAward,
          loading: action.isLoadingEmployeesToAward
        }
      };
    case CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET:
      return {
        ...state,
        employeesToAward: {
          employees: [],
          totalCount: 0,
          loading: false
        }
      };
    case CERTIFICATIONS_SET_MY_CERTIFICATIONS: {
      if (!action.myCertificationsPageResult) {
        return {
          ...state,
          myCertificationsPageResult: action.myCertificationsPageResult
        };
      }

      const incrementMyCertifications = state.myCertificationsPageResult
        ? state.myCertificationsPageResult.pageItems
            .slice()
            .concat(action.myCertificationsPageResult.pageItems)
        : action.myCertificationsPageResult.pageItems;

      return {
        ...state,
        myCertificationsPageResult: {
          ...action.myCertificationsPageResult,
          pageItems: incrementMyCertifications,
          totalItems: action.myCertificationsPageResult.totalItems
        }
      };
    }
    case CERTIFICATIONS_SET_TRANSACTION_ERROR:
      return {
        ...state,
        transactionError: action.transactionError
      };
    case CERTIFICATIONS_SET_EMPLOYEES_NAMES:
      return {
        ...state,
        employeesNames: action.employeesNames
      };
    case CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS:
      return {
        ...state,
        forceFetchEmployeeCertifications:
          action.forceFetchEmployeeCertifications
      };
    default:
      return state;
  }
};

import React, { useState, useEffect, CSSProperties } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';

import { Course } from '../../entities/Course';
import { LearningPlan } from '../../entities/LearningPlan';
import { Lesson } from '../../entities/Lesson';
import AssignEmployeesPanel, {
  AssignEmployeesPanelProps
} from '../../components/AssignEmployeesPanel/AssignEmployeesPanel';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import AssignGroupsPanel from '../AssignGroupsPanel/AssignGroupsPanel';
import {
  CourseGroupAssignment,
  EmployeeGroupAssignmentDefinition,
  LearningPlanGroupAssignment,
  LessonGroupAssignment
} from '../../entities/Assignment';
import useUserRole from '../../hooks/useUserRole';
import { UserRoles } from '../../core/constants';

export interface AssignModalProps extends AssignEmployeesPanelProps {
  showModal: boolean;
  assignToEntity?: Course | LearningPlan | Lesson | null;
  errorMessage: string;
  savingInProgress: boolean;
  groupAssignment:
    | LessonGroupAssignment
    | CourseGroupAssignment
    | LearningPlanGroupAssignment;
  groupAssignmentDefinitions?: EmployeeGroupAssignmentDefinition[] | null;
  onAssign: () => void;
  onClose: () => void;
  onSaveGroupAssignment: () => void;
  setGroupAssignment: (groupAssignment: any) => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
  }
`;

const AssignModal: React.FC<AssignModalProps> = ({
  showModal,
  assignToEntity,
  errorMessage,
  employees,
  employeeGroups,
  assignment,
  groupAssignment,
  savingInProgress,
  learningUnitAssigmentStatus,
  groupAssignmentDefinitions,
  setAssignment,
  setGroupAssignment,
  onAssign,
  onSaveGroupAssignment,
  onClose
}) => {
  const theme = useTheme();
  const [userHasRole] = useUserRole();
  const [title, setTitle] = useState<string>('');
  const [valueTab, setValueTab] = useState<string>('employeesTab');
  const defaultDialogWidth: DialogProps['maxWidth'] = 'lg';
  const { t } = useTranslation([
    'common',
    'assignments',
    'lessons',
    'courses',
    'learningPlans',
    'employees'
  ]);
  const viewGroupAssignments = userHasRole([
    UserRoles.CompanyAdmin,
    UserRoles.GroupLead
  ]);

  useEffect(() => {
    let nameOrTitle = '';
    if (assignToEntity) {
      const entity: any = assignToEntity;
      if (entity.title) nameOrTitle = entity.title;
      else if (entity.name) nameOrTitle = entity.name;

      setTitle(`${t('assign', { ns: 'common' })} ${nameOrTitle}`);
    }
  }, [assignToEntity]);

  const learningUnitName = (() => {
    const objectAssignment: any = assignment;

    if (objectAssignment.learningPlanId)
      return t('learningPlan', { ns: 'learningPlans' });
    else if (objectAssignment.lessonId) return t('lesson', { ns: 'lessons' });
    else if (objectAssignment.courseId) return t('course', { ns: 'courses' });

    return '';
  })();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  const titleStyle: CSSProperties = {
    color: theme.palette.grey[100],
    fontSize: '15px'
  };

  const mandatoryMarkStyle: CSSProperties = {
    color: 'red'
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth={defaultDialogWidth}
      fullWidth={true}
    >
      <DialogTitle style={{ fontSize: '24px', paddingLeft: '48px' }}>
        {title}
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p className="text-danger">{errorMessage}</p>
        <TabContext value={valueTab}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="assignments tab"
            sx={theme => ({
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '18px'
              },
              borderBottom: `1px solid ${theme.palette.grey[100]}`,
              marginLeft: '1.5rem',
              marginRight: '1.5rem'
            })}
          >
            <Tab
              value="employeesTab"
              label={t('employees', { ns: 'employees' })}
            />
            {viewGroupAssignments && (
              <Tab value="groupsTab" label={t('groups', { ns: 'employees' })} />
            )}
          </TabList>
          <TabPanel
            value="employeesTab"
            sx={{
              paddingTop: 0
            }}
          >
            <div className="pt-2 pb-3">
              <span style={titleStyle}>
                {t('selectEmployeesToAssign', { ns: 'assignments' })}{' '}
                <span style={mandatoryMarkStyle}>*</span>
              </span>
            </div>
            <AssignEmployeesPanel
              employees={employees ?? []}
              employeeGroups={employeeGroups ?? []}
              assignment={assignment}
              setAssignment={setAssignment}
              learningUnitAssigmentStatus={learningUnitAssigmentStatus}
            />
          </TabPanel>
          <TabPanel
            value="groupsTab"
            sx={{
              paddingTop: 0
            }}
          >
            <AssignGroupsPanel
              groupAssignment={groupAssignment}
              employeeGroups={employeeGroups}
              groupAssignmentDefinitions={groupAssignmentDefinitions}
              subscribedEmployees={employees}
              setGroupAssignment={setGroupAssignment}
            />
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions>
        {savingInProgress && (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        )}
        {!savingInProgress && (
          <BasicButton
            color="primary"
            width="160px"
            height="45px"
            onClick={
              valueTab === 'employeesTab' ? onAssign : onSaveGroupAssignment
            }
          >
            {`${t('assign', { ns: 'common' })} ${learningUnitName}`}
          </BasicButton>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

export default AssignModal;

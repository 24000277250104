import { CSSProperties } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTheme, SxProps } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { AnalyticsContentLibrary } from '../../../entities/Analytics';
import { lowerCaseFirstLetter } from '../../../utils/stringUtils';
import { AnalyticsDataGrid } from '../../../components/Analytics';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';
import { PercentageIndicator } from '../../../components/PercentageIndicator/PercentageIndicator';

interface ContentLibraryTableProps {
  data?: AnalyticsContentLibrary[] | null;
  learningUnitType: string;
  onClickActionButton: (
    assignmentStatus: string,
    learningUnitId: string
  ) => void;
  onGotoDetailView: (learningUnitId: string, learningUnitName: string) => void;
}

const ContentLibraryTable = ({
  data,
  learningUnitType,
  onClickActionButton,
  onGotoDetailView
}: ContentLibraryTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'analytics']);
  const {
    variables: { showCompletionStats }
  } = useDevCycleSdk();

  const buttonStyle: SxProps = {
    height: '30px',
    borderRadius: '35px',
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.grey[50],
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 15
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      color: theme.palette.grey[100]
    }
  };

  let columns: GridColDef[] = [
    {
      field: 'learningUnitName',
      headerName: t(`${lowerCaseFirstLetter(learningUnitType)}Name`, {
        ns: 'analytics'
      }),
      minWidth: 500,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span>{params.row.learningUnitName}</span>
      )
    },
    {
      field: 'completion',
      headerName: t('completion', {
        ns: 'analytics'
      }),
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const percent: number =
          params.row.employeesAssigned !== 0
            ? Math.floor(
                (params.row.completed / params.row.employeesAssigned) * 100
              )
            : 0;
        const formattedText = `${percent}%`;
        return (
          <PercentageIndicator
            percent={percent}
            formattedText={formattedText}
          />
        );
      }
    },
    {
      field: 'employeesAssigned',
      headerName: t('employeesAssigned', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span>{params.row.employeesAssigned}</span>
      )
    },
    {
      field: 'pendingCompletion',
      headerName: t('pendingCompletion', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <div style={cellButtonsStyle}>
          <span
            style={
              params.row.pendingCompletion > 0
                ? pendingCompletionStyle
                : noValueStyle
            }
          >
            {params.row.pendingCompletion}
          </span>
          <IconButton
            aria-label="pendingCompletion"
            size="large"
            color="info"
            disabled={!params.row.pendingCompletion}
            sx={buttonStyle}
            onClick={() =>
              onClickActionButton(
                'pendingCompletions',
                params.row.learningUnitId
              )
            }
          >
            {t('manage', { ns: 'analytics' })}
          </IconButton>
        </div>
      )
    },
    {
      field: 'completed',
      headerName: t('completed', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <div style={cellButtonsStyle}>
          <span
            style={params.row.completed > 0 ? completedStyle : noValueStyle}
          >
            {params.row.completed}
          </span>
          <IconButton
            aria-label="completed"
            size="large"
            color="info"
            disabled={!params.row.completed}
            sx={buttonStyle}
            onClick={() =>
              onClickActionButton('completed', params.row.learningUnitId)
            }
          >
            {t('view', { ns: 'analytics' })}
          </IconButton>
        </div>
      )
    },
    {
      field: 'overdue',
      headerName: t('overdue', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <div style={cellButtonsStyle}>
          <span style={params.row.overdue > 0 ? overdueStyle : noValueStyle}>
            {params.row.overdue}
          </span>
          <IconButton
            aria-label="overdue"
            size="large"
            color="info"
            disabled={!params.row.overdue}
            sx={buttonStyle}
            onClick={() =>
              onClickActionButton('overdues', params.row.learningUnitId)
            }
          >
            {t('manage', { ns: 'analytics' })}
          </IconButton>
        </div>
      )
    },
    {
      field: 'buttons',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <IconButton
          aria-label="buttons"
          size="large"
          color="info"
          style={circularButton}
          onClick={() =>
            onGotoDetailView(
              params.row.learningUnitId,
              params.row.learningUnitName
            )
          }
        >
          <ArrowForwardIosIcon fontSize="medium" sx={{ color: '#1B1C1C' }} />
        </IconButton>
      )
    }
  ];

  if (
    !showCompletionStats.showCompletionStats ||
    (learningUnitType !== 'Course' && showCompletionStats.showCompletionStats)
  ) {
    columns = columns.filter(column => column.field !== 'completion');
  }

  return (
    <AnalyticsDataGrid columns={columns} rows={data} rowId="learningUnitId" />
  );
};

const noValueStyle: CSSProperties = {
  color: '#999999'
};

const pendingCompletionStyle: CSSProperties = {
  color: '#FF9E00'
};

const completedStyle: CSSProperties = {
  color: '#2ED47A'
};

const overdueStyle: CSSProperties = {
  color: '#FF2A20DE'
};

const cellButtonsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 180
};

const circularButton: CSSProperties = {
  backgroundColor: '#C4C4C4',
  width: '35px',
  height: '35px'
};

export default ContentLibraryTable;

import { useEffect, useMemo, useReducer, useState } from 'react';
import {
  DialogTitle,
  ThemeProvider,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Typography,
  Grid,
  Stack,
  CircularProgress
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  AwaitableDialog,
  defaultTheme,
  DialogHeader
} from '@buildwitt/component-library';
import { actions, defaultState, reducer } from './state';
import {
  getAssignmentFetcher,
  getDueDateColumns,
  getUnassignmentCall,
  getWorkloadColumns
} from './utils';
import { EmployeeAssignmentsStatus } from '../../entities/Employee';
import { Alert } from '@mui/lab';
import { Assignment, DueDateModal } from './DueDateModal';
import { styled, SxProps } from '@mui/material/styles';
import ConfirmationModal from '../core/ConfirmationModal/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import BasicButton from '../core/BasicButton/BasicButton';
import { actionCreators as actionStudent } from '../../actions/student';
import { useLogEvent } from '../../hooks/useLogEvent';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';

interface Props {
  assignmentType: string;
  employeeRow?: EmployeeAssignmentsStatus;
  clearSelectedEmployeeId: () => void;
  refreshData: () => void;
}

const ManageEmployeeAssignmentsModal = ({
  assignmentType,
  employeeRow,
  clearSelectedEmployeeId,
  refreshData
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentAssignment, setCurrentAssignment] = useState<Assignment>();
  const [selectedAssignment, setSelectedAssignment] = useState<any>();
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState<string>();
  const [
    showCompleteAssignmentWarningModal,
    setShowCompleteAssignmentWarningModal
  ] = useState<boolean>(false);
  const [showConfirmationDelete, setShowConfirmationDelete] =
    useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { t } = useTranslation(['common', 'assignments', 'settings']);
  const toggleIsOpen = () => {
    clearSelectedEmployeeId();
    setIsOpen(x => !x);
  };
  const [state, dispatch] = useReducer(reducer, defaultState);
  const logEvent = useLogEvent();
  const appDispatch = useAppDispatch();

  useEffect(() => {
    if (employeeRow) {
      dispatch({ type: actions.GET_ASSINGMENTS });
      getAssignmentFetcher(assignmentType, employeeRow.employeeId)
        .then((response: any) => {
          dispatch({ type: actions.SET_ASSIGNMENTS, payload: response.data });
        })
        .catch(error => {
          dispatch({ type: actions.SET_ASSIGNMENTS, payload: [] });
          dispatch({
            type: actions.SET_ERROR,
            payload: 'Something went wrong. Try again.'
          });
        });
    }
  }, [employeeRow?.employeeId, assignmentType]);

  useEffect(() => {
    if (employeeRow && !isOpen) setIsOpen(true);
  }, [employeeRow?.employeeId]);

  const handleUnassign = (assignmentType: string, assignmentId: string) => {
    setShowConfirmationDelete(true);
    setSelectedAssignmentType(assignmentType);
    setAssignmentId(assignmentId);
  };

  const deleteAssignmentConfirmed = () => {
    setIsDeleting(true);
    dispatch({ type: actions.GET_ASSINGMENTS });
    getUnassignmentCall(assignmentType, assignmentId)
      .then(() => {
        dispatch({ type: actions.UNASSIGN_ASSIGNMENT, payload: assignmentId });
        resetStates();
        enqueueSnackbar(t('assignmentDeletedMsg', { ns: 'settings' }), {
          variant: 'success'
        });
      })
      .catch(() => {
        dispatch({
          type: actions.SET_ERROR,
          payload: 'Could not unassign assignment. Try again.'
        });
        resetStates();
        const message = 'An error ocurred while deleting the assignment';
        enqueueSnackbar(message, { variant: 'error' });
      });
  };

  const resetStates = () => {
    setAssignmentId('');
    setShowConfirmationDelete(false);
    setIsDeleting(false);
  };

  const handleAssignmentSelect = (assignment: Assignment) => {
    setCurrentAssignment(assignment);
  };
  const removeCurrentAssingment = () => setCurrentAssignment(undefined);

  const handleCompleteAssignment = async () => {
    setShowCompleteAssignmentWarningModal(false);
    switch (selectedAssignmentType) {
      case 'Lessons':
      case 'Quizzes':
        try {
          await appDispatch<Promise<boolean>>(
            actionStudent.markLessonAsCompleted(
              selectedAssignment.lessonId,
              selectedAssignment.courseId,
              undefined,
              selectedAssignment.employeeId
            )
          );

          logEvent.logCompleteLessonAdminEvent(
            selectedAssignment.lesson.id,
            selectedAssignment.lesson.title,
            selectedAssignment.employeeId,
            selectedAssignment.course?.id,
            undefined,
            undefined
          );
          const message = 'Lesson was completed succesfully.';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message =
            'An error ocurred while completing the lesson.Please try again.';
          enqueueSnackbar(message, { variant: 'error' });
          dispatch({
            type: actions.SET_ERROR,
            payload: 'Could not complete lesson. Try again.'
          });
        } finally {
          toggleIsOpen();
          refreshData();
        }
        break;
      case 'Courses':
        try {
          await appDispatch<Promise<boolean>>(
            actionStudent.completeCourse(
              selectedAssignment.courseId,
              selectedAssignment.course.revisionNumber,
              selectedAssignment.id,
              selectedAssignment.employeeId
            )
          );

          logEvent.logCompleteCourseAdminEvent(
            selectedAssignment.employeeId,
            selectedAssignment.course.id,
            selectedAssignment.course.title
          );
          const message = 'Course was completed succesfully.';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message =
            'An error ocurred while completing the course. Please try again.';
          enqueueSnackbar(message, { variant: 'error' });
          dispatch({
            type: actions.SET_ERROR,
            payload: 'Could not complete lesson. Try again.'
          });
        } finally {
          toggleIsOpen();
          refreshData();
        }
        break;
      case 'LearningPlans':
        try {
          await appDispatch<Promise<boolean>>(
            actionStudent.completeLearningPlan(
              selectedAssignment.learningPlanId,
              selectedAssignment.learningPlanRevision.revisionNumber,
              selectedAssignment.id,
              selectedAssignment.employeeId
            )
          );

          logEvent.logCompleteLearningPlanAdminEvent(
            selectedAssignment.employeeId,
            selectedAssignment.learningPlanId,
            selectedAssignment.learningPlanRevision.name
          );
          const message = 'Learning Plan was completed succesfully.';
          enqueueSnackbar(message, { variant: 'success' });
        } catch (e) {
          const message =
            'An error ocurred while completing the learning plan. Please try again.';
          enqueueSnackbar(message, { variant: 'error' });
          dispatch({
            type: actions.SET_ERROR,
            payload: 'Could not complete lesson. Try again.'
          });
        } finally {
          toggleIsOpen();
          refreshData();
        }
        break;
    }
  };

  const showCompleteAssignmentWarning = (
    assignmentType: string,
    assignment: any
  ) => {
    setSelectedAssignmentType(assignmentType);
    setSelectedAssignment(assignment);
    setShowCompleteAssignmentWarningModal(true);
  };

  const columns: GridColDef[] = useMemo(() => {
    if (state.dueType === 'assigned')
      return getDueDateColumns(
        assignmentType,
        handleUnassign,
        handleAssignmentSelect,
        showCompleteAssignmentWarning,
        t('completeAssignmentHelp', { ns: 'assignments' })
      );
    else
      return getWorkloadColumns(
        assignmentType,
        handleUnassign,
        showCompleteAssignmentWarning,
        t('completeAssignmentHelp', { ns: 'assignments' })
      );
  }, [assignmentType, state.dueType]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <AwaitableDialog
        isLoading={state.isLoading}
        open={isOpen}
        sx={{ height: 'auto' }}
        maxWidth={'lg'}
      >
        <DialogHeader onClose={toggleIsOpen}>
          <DialogTitle>Manage Assignments</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant={'h6'}>
              {employeeRow?.firstName} {employeeRow?.lastName}
            </Typography>
            <ToggleButtonGroup
              exclusive
              value={state.dueType}
              onChange={(_, newValue) =>
                dispatch({ type: actions.SET_DUE_TYPE, payload: newValue })
              }
            >
              <ToggleButton value={'assigned'}>Assigned</ToggleButton>
              <ToggleButton value={'workload'}>Workload</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </DialogContent>
        <Box
          sx={{
            height: '500px',
            width: '100%'
          }}
        >
          {state.error && <Alert severity={'error'}>{state.error}</Alert>}
          <CustomDataGrid
            rows={state.filteredAssignments}
            columns={columns}
            rowSelection={false}
            getRowClassName={params => {
              return params.indexRelativeToCurrentPage % 2 !== 0
                ? 'row-odd-style'
                : 'row-peer-style';
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'createdTimestampUtc', sort: 'desc' }]
              }
            }}
          />
        </Box>
      </AwaitableDialog>
      <DueDateModal
        assignment={currentAssignment}
        removeCurrentAssingment={removeCurrentAssingment}
        assignmentType={assignmentType}
        dispatch={dispatch}
      />
      <ConfirmationModal
        show={showCompleteAssignmentWarningModal}
        showSave={false}
        showCancel={false}
        maxWidth="sm"
        onSave={handleCompleteAssignment}
        onCancel={() => setShowCompleteAssignmentWarningModal(false)}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography variant="h6">
              {t('areYouSureToCompleteAssignment', { ns: 'assignments' })}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <BasicButton
              onClick={() => setShowCompleteAssignmentWarningModal(false)}
              color="grayButton"
              width={'200px'}
              height={'50px'}
            >
              {t('cancel', { ns: 'common' })}
            </BasicButton>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <BasicButton
              onClick={handleCompleteAssignment}
              color="secondary"
              width={'240px'}
              height={'50px'}
            >
              {t('confirmCompleteAssignment', { ns: 'assignments' })}
            </BasicButton>
          </Grid>
        </Grid>
      </ConfirmationModal>
      <ConfirmationModal
        show={showConfirmationDelete}
        showSave={false}
        showCancel={false}
        maxWidth="xs"
        onCancel={() => setShowConfirmationDelete(false)}
      >
        <Stack spacing={3}>
          <Stack direction="row" sx={{ justifyContent: 'center' }}>
            <span>{t('confirmDeleteMsg', { ns: 'settings' })}</span>
          </Stack>

          <Stack direction="row" spacing={3} sx={{ justifyContent: 'center' }}>
            {isDeleting ? (
              <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <BasicButton
                  onClick={() => setShowConfirmationDelete(false)}
                  color="grayButton"
                >
                  {t('cancel', { ns: 'settings' })}
                </BasicButton>
                <BasicButton
                  onClick={deleteAssignmentConfirmed}
                  color="confirm"
                >
                  {t('delete', { ns: 'settings' })}
                </BasicButton>
              </>
            )}
          </Stack>
        </Stack>
      </ConfirmationModal>
    </ThemeProvider>
  );
};

export default ManageEmployeeAssignmentsModal;

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  borderTop: `1px solid ${theme.palette.grey['100']}4d`,
  borderRadius: '0px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey['100']}4d`
  },
  '@ .MuiDataGrid-columnHeaderTitle': {
    fontWeight: `600`,
    fontSize: `1.0rem`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-cell:not(:last-child)': {
    borderRight: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: `${theme.palette.grey['100']}4d`
  },
  '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
    {
      display: 'none'
    },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-row.row-peer-style': {
    backgroundColor: `${theme.palette.grey[400]}B8`
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
}));

const gridItemStyles: SxProps = {
  textAlign: 'center'
};

import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { actionCreators } from '../../../actions/certifications';
import CertificationsTable from './CertificationsTable';
import {
  pageCertificationsState,
  searchAutocompleteEvents
} from '../../../core/constants';
import { useAppDispatch } from '../../../store/hooks';
import EditCertificaton from '../EditCertification';
import { Certification } from '../../../entities/Certification';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CustomAutoComplete } from '@buildwitt/component-library';
import EmployeesCertificationsModal from '../EmployeesCertificationsModal/EmployeesCertificationsModal';
import EmployeesWithCertifications from '../EmployeesCertificationsModal/EmployeesWithCertifications';
import AwardCertification from '../AwardCertification/AwardCertification';

export const CertificationsTab = () => {
  const { t } = useTranslation(['certifications']);
  const dispatch = useAppDispatch();
  const { page, pageSize, sortBy } = pageCertificationsState;
  const { events, numberOfcharactersToTriggerApi } = searchAutocompleteEvents;
  const [openEditCertificationModal, setOpenEditCertificationModal] =
    useState<boolean>(false);
  const [selectedCertification, setSelectedCertification] =
    useState<Certification | null>();
  const [lastSearch, setLastSearch] = useState<string>('');
  const [fetchBySearch, setFetchBySearch] = useState<boolean>(false);
  const [
    selectedCertificationForAwardees,
    setSelectedCertificationForAwardees
  ] = useState<Certification | null>();

  let searchTimeout: NodeJS.Timeout;
  const certificationNames = useSelector(
    (state: ApplicationState) => state.certifications?.certificationNames
  );
  const [openAwardModal, setOpenAwardModal] = useState<boolean>(false);
  useEffect(() => {
    fetchCertifications();
    return () => {
      dispatch(actionCreators.resetCertifications());
      dispatch(actionCreators.resetCertificationsNames());
    };
  }, []);

  const fetchCertifications = () => {
    dispatch(
      actionCreators.getCertifications(page, pageSize, sortBy.createdAt, '')
    );
  };

  const handleEditCertification = (certification: Certification) => {
    setSelectedCertification(certification);
    setOpenEditCertificationModal(true);
  };

  const handleCloseEditCertificationModal = () => {
    setSelectedCertification(null);
    setOpenEditCertificationModal(false);
  };

  const handleOpenAwardModal = (certification: Certification) => {
    setOpenAwardModal(true);
    setSelectedCertification(certification);
  };

  const handleCloseAwardModal = () => {
    setSelectedCertification(null);
    setOpenAwardModal(false);
  };

  const handleOnInputChangeSearch = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (
        (event.type === events.click || event.type === events.keydown) &&
        value.length >= numberOfcharactersToTriggerApi
      ) {
        dispatch(
          actionCreators.getCertifications(
            page,
            pageSize,
            sortBy.createdAt,
            value
          )
        );
        setLastSearch(value);
        setFetchBySearch(true);
        return;
      }

      if (
        event.type === events.change &&
        value.length >= numberOfcharactersToTriggerApi
      ) {
        dispatch(actionCreators.getCertificationNames(value));
        setLastSearch(value);
        return;
      }

      if (
        value.length < numberOfcharactersToTriggerApi &&
        lastSearch.length >= numberOfcharactersToTriggerApi
      ) {
        setFetchBySearch(false);
        dispatch(actionCreators.resetCertificationsNames());
        setLastSearch('');
        if (fetchBySearch)
          dispatch(
            actionCreators.getCertifications(
              page,
              pageSize,
              sortBy.createdAt,
              ''
            )
          );
      }
    }, 200);
  };

  const onHandleKey = (event: any) => {
    const value = event.target.value;
    if (
      value.length >= numberOfcharactersToTriggerApi &&
      event.key === events.enter
    ) {
      dispatch(
        actionCreators.getCertifications(
          page,
          pageSize,
          sortBy.createdAt,
          value
        )
      );
      setLastSearch(value);
      setFetchBySearch(true);
    }
  };

  const handleOnShowAwardees = (certification: Certification) => {
    setSelectedCertificationForAwardees(certification);
  };
  const closeAwardeesModal = () => {
    setSelectedCertificationForAwardees(null);
  };

  return (
    <Grid>
      <Box
        flexDirection={'row'}
        display={'flex'}
        gap={2}
        flexWrap={'wrap'}
        justifyContent={'start'}
        alignItems={'end'}
      >
        <CustomAutoComplete
          id="search"
          options={certificationNames ?? []}
          autoCompleteSxProps={{
            width: '410px',
            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              '&:hover': { background: '#333333' },
              '&.Mui-focusVisible': {
                backgroundColor: '#333333'
              }
            }
          }}
          handleOnInputChangeSearch={handleOnInputChangeSearch}
          onHandleKeyDown={onHandleKey}
          inputLabel={t('search')}
        />
      </Box>
      <Box style={{ paddingTop: '20px', height: '75vh' }}>
        <CertificationsTable
          onEditCertification={handleEditCertification}
          searchValue={lastSearch}
          onShowAwardees={handleOnShowAwardees}
          onOpenAwardModal={handleOpenAwardModal}
        />
      </Box>
      {openEditCertificationModal && selectedCertification && (
        <EditCertificaton
          closeModal={handleCloseEditCertificationModal}
          certification={selectedCertification}
        />
      )}
      {openAwardModal && selectedCertification && (
        <AwardCertification
          openAwardModal={openAwardModal}
          handleCloseAwardModal={handleCloseAwardModal}
          certification={selectedCertification}
        />
      )}
      <EmployeesCertificationsModal
        showModal={!!selectedCertificationForAwardees}
        onCloseModal={closeAwardeesModal}
        title={t('employeesWithCertification')}
        maxWidth={'lg'}
        height={'60vh'}
      >
        <EmployeesWithCertifications
          certification={selectedCertificationForAwardees}
        />
      </EmployeesCertificationsModal>
    </Grid>
  );
};

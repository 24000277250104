import moment from 'moment';

export enum AwardDurationType {
  Default,
  ExpirationDate,
  NeverExpires,
  Duration
}

export interface FileInfo {
  name: string;
  fileType: string;
  fileExtension: string;
  url: string;
}

export type DurationUnit = 'years' | 'days' | 'months';
interface AwardedItem {
  awardDurationType: AwardDurationType;
  expirationDate?: Date;
  expirationDateIsoString: string;
  employeeCompetenceFiles: File[];
  durationUnit?: DurationUnit;
  durationValue?: number;
  isValid: boolean;
  employeeId: string;
  employeeCompetenceFilesInfo: FileInfo[];
}

type AwardedItemState = {
  [key: string]: AwardedItem;
};

type Action =
  | { type: 'add'; employeeId: string }
  | { type: 'remove'; employeeId: string }
  | {
      type: 'update_award_duration_type';
      employeeId: string;
      awardDurationType: AwardDurationType;
    }
  | {
      type: 'set_expiration_date';
      employeeId: string;
      date: Date;
    }
  | {
      type: 'set_date_from_duration';
      employeeId: string;
      durationUnit: DurationUnit;
      durationValue: number;
    }
  | { type: 'add_attachment'; employeeId: string; file: File; fileName: string }
  | { type: 'remove_attachment'; employeeId: string; index: number };

export const emptyAwardedItem: AwardedItem = {
  awardDurationType: AwardDurationType.Default,
  employeeCompetenceFiles: [],
  expirationDateIsoString: '',
  isValid: true,
  durationUnit: 'days',
  durationValue: 1,
  employeeId: '',
  employeeCompetenceFilesInfo: []
};

export function reducer(
  state: AwardedItemState,
  action: Action
): AwardedItemState {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        [action.employeeId]: {
          awardDurationType: AwardDurationType.Default,
          employeeCompetenceFiles: [],
          expirationDateIsoString: '',
          durationUnit: 'months',
          durationValue: 1,
          isValid: true,
          employeeId: action.employeeId,
          employeeCompetenceFilesInfo: []
        }
      };
    case 'remove': {
      const newState = { ...state };
      delete newState[action.employeeId];

      return newState;
    }
    case 'update_award_duration_type':
      return {
        ...state,
        [action.employeeId]: {
          ...state[action.employeeId],
          awardDurationType: action.awardDurationType,
          durationUnit: 'months',
          durationValue: 1,
          expirationDateIsoString: moment().utc().add(1, 'months').format(),
          employeeId: action.employeeId
        }
      };
    case 'set_expiration_date':
      return {
        ...state,
        [action.employeeId]: {
          ...state[action.employeeId],
          expirationDate: action.date,
          expirationDateIsoString: moment(action.date).utc().format(),
          employeeId: action.employeeId
        }
      };

    case 'set_date_from_duration': {
      const expirationDate = moment()
        .utc()
        .add(action.durationValue, action.durationUnit)
        .format();
      return {
        ...state,
        [action.employeeId]: {
          ...state[action.employeeId],
          expirationDateIsoString: expirationDate,
          employeeId: action.employeeId,
          durationUnit: action.durationUnit,
          durationValue: action.durationValue
        }
      };
    }
    case 'add_attachment': {
      const fileExists = state[action.employeeId].employeeCompetenceFiles.find(
        file => file.name === action.fileName
      );

      if (fileExists) {
        return state;
      }

      return {
        ...state,
        [action.employeeId]: {
          ...state[action.employeeId],
          employeeCompetenceFiles: [
            ...state[action.employeeId].employeeCompetenceFiles,
            action.file
          ]
        }
      };
    }
    case 'remove_attachment':
      return {
        ...state,
        [action.employeeId]: {
          ...state[action.employeeId],
          employeeCompetenceFiles: state[
            action.employeeId
          ].employeeCompetenceFiles.filter(
            (file, index) => index !== action.index
          )
        }
      };
  }
  return state;
}

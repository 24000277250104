import {
  $createLineBreakNode,
  $createTextNode,
  $isTextNode,
  DOMConversionMap,
  DOMConversionOutput,
  EditorConfig,
  NodeKey,
  SerializedTextNode,
  TextFormatType,
  TextNode
} from 'lexical';

export class CustomTextNode extends TextNode {
  __text: string;

  constructor(text: string, key?: NodeKey) {
    super(text, key);
    this.__text = text;
    this.__mode = 2;
    this.__style = `background: #D9E6FF; border-radius: 8px; padding: 2px; margin: 0 2px;`;
  }
  static getType(): string {
    return 'custom-text';
  }

  updateDOM(
    prevNode: CustomTextNode,
    dom: HTMLElement,
    config: EditorConfig
  ): boolean {
    return super.updateDOM(prevNode, dom, config);
  }

  createDOM(config: EditorConfig) {
    return super.createDOM(config);
  }

  static clone(node: CustomTextNode): CustomTextNode {
    return new CustomTextNode(node.__text, node.__key);
  }

  static importJSON(serializedNode: SerializedTextNode): CustomTextNode {
    const node = $createTextNode(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedTextNode {
    return {
      detail: this.getDetail(),
      format: this.getFormat(),
      mode: this.getMode(),
      style: this.getStyle(),
      text: this.getTextContent(),
      type: 'custom-text',
      version: 1
    };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      '#text': () => ({
        conversion: convertTextDOMNode,
        priority: 2
      }),
      b: () => ({
        conversion: convertBringAttentionToElement,
        priority: 2
      }),
      br: () => ({
        conversion: convertLineBreakToElement,
        priority: 2
      }),
      code: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      em: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      i: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      span: () => ({
        conversion: convertSpanElement,
        priority: 2
      }),
      strong: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      sub: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      sup: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      }),
      u: () => ({
        conversion: convertTextFormatElement,
        priority: 2
      })
    };
  }
}

const nodeNameToTextFormat: Record<string, TextFormatType> = {
  code: 'code',
  em: 'italic',
  i: 'italic',
  strong: 'bold',
  sub: 'subscript',
  sup: 'superscript',
  u: 'underline'
};

function convertTextFormatElement(domNode: Node): DOMConversionOutput {
  const format = nodeNameToTextFormat[domNode.nodeName.toLowerCase()];
  const b = domNode as HTMLElement;
  if (format === undefined || b.tagName === 'STRONG' || b.tagName === 'EM') {
    return { node: null };
  }
  return {
    forChild: lexicalNode => {
      if ($isTextNode(lexicalNode)) {
        lexicalNode.toggleFormat(format);
      }
      return lexicalNode;
    },
    node: null
  };
}

function convertTextDOMNode(
  domNode: Node,
  _parent?: Node,
  preformatted?: boolean
): DOMConversionOutput {
  let textContent = domNode.textContent || '';
  if (!preformatted && /\n/.test(textContent)) {
    textContent = textContent.replace(/\r?\n/gm, ' ');
    if (textContent.trim().length === 0) {
      return { node: null };
    }
  }
  return { node: $createTextNode(textContent) };
}

function convertBringAttentionToElement(domNode: Node): DOMConversionOutput {
  // domNode is a <b> since we matched it by nodeName
  const b = domNode as HTMLElement;
  // Google Docs wraps all copied HTML in a <b> with font-weight normal
  const hasNormalFontWeight = b.style.fontWeight === 'normal';
  return {
    forChild: lexicalNode => {
      if ($isTextNode(lexicalNode) && !hasNormalFontWeight) {
        lexicalNode.toggleFormat('bold');
      }

      return lexicalNode;
    },
    node: null
  };
}

function convertLineBreakToElement(): DOMConversionOutput {
  return {
    node: $createLineBreakNode()
  };
}

function convertSpanElement(domNode: Node): DOMConversionOutput {
  // domNode is a <span> since we matched it by nodeName
  const span = domNode as HTMLSpanElement;
  // Google Docs uses span tags + font-weight for bold text
  const hasBoldFontWeight = span.style.fontWeight === '700';
  // Google Docs uses span tags + text-decoration: line-through for strikethrough text
  const hasLinethroughTextDecoration =
    span.style.textDecoration === 'line-through';
  // Google Docs uses span tags + font-style for italic text
  const hasItalicFontStyle = span.style.fontStyle === 'italic';
  // Google Docs uses span tags + text-decoration: underline for underline text
  const hasUnderlineTextDecoration = span.style.textDecoration === 'underline';
  // Google Docs uses span tags + vertical-align to specify subscript and superscript
  const verticalAlign = span.style.verticalAlign;
  //const hasColorStyle = span.style.color;

  return {
    forChild: lexicalNode => {
      if (!$isTextNode(lexicalNode)) {
        return lexicalNode;
      }
      if (hasBoldFontWeight) {
        lexicalNode.toggleFormat('bold');
      }
      if (hasLinethroughTextDecoration) {
        lexicalNode.toggleFormat('strikethrough');
      }
      if (hasItalicFontStyle) {
        lexicalNode.toggleFormat('italic');
      }
      if (hasUnderlineTextDecoration) {
        lexicalNode.toggleFormat('underline');
      }
      if (verticalAlign === 'sub') {
        lexicalNode.toggleFormat('subscript');
      }
      if (verticalAlign === 'super') {
        lexicalNode.toggleFormat('superscript');
      }
      // if (hasColorStyle) {
      //   lexicalNode.setStyle('color: ' + span.style.color);
      // }
      return lexicalNode;
    },
    node: null
  };
}

import { Stack, Typography, createTheme, ThemeProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import EmployeeCertificationsActionToolbar from './EmployeeCertificationsActionToolbar';
import EditEmployeeCertificationsTable from './EditEmployeeCertificationsTable';
import FooterActionButtons from './FooterActionButtons';
import DeleteCertificationConfirmationModal from '../../Common/DeleteCertificationConfirmationModal';
import useEditEmployeeCertifications from '../../../../hooks/useEditEmployeeCertifications';
import { ViewFileModal } from '../../../lessons/categories/FileSection';
import themeConfig from '../../../../themes/theme';

interface EditEmployeeCertificationsProps {
  employeeId: string;
  onClose: () => void;
}

const EditEmployeeCertifications = ({
  employeeId,
  onClose
}: EditEmployeeCertificationsProps) => {
  const { t } = useTranslation(['certifications']);
  const theme = createTheme(themeConfig);
  const { enqueueSnackbar } = useSnackbar();
  const {
    ref: { pageRef },
    state: {
      myCertifications,
      fileProcessInProgres,
      selectedCertifications,
      showDeleteConfirmationModal,
      deletingInProgress,
      urlFileToView
    },
    api: {
      setSearchKey,
      setSelectedCertifications,
      fetchMyCertifications,
      handleAddFile,
      handleRemoveFile,
      handleInitDeleteCertification,
      setShowDeleteConfirmationModal,
      handleDeleteCertification,
      handleFileClick,
      handleCloseFileViewer
    }
  } = useEditEmployeeCertifications(employeeId);

  const deleteCertification = async () => {
    const noDeletedCertificationNames = await handleDeleteCertification();

    enqueueSnackbar(
      noDeletedCertificationNames.length ? (
        <DeleteEmployeeCertificationsError
          certificationNames={noDeletedCertificationNames}
        />
      ) : (
        t('certificationsDeleted')
      ),
      {
        variant: noDeletedCertificationNames.length ? 'error' : 'success'
      }
    );
  };

  const handleSearchChange = (value: string) => {
    setSearchKey(value);
  };

  return (
    <Stack gap={2}>
      <EmployeeCertificationsActionToolbar
        onUpdateToolbarActionValues={handleSearchChange}
      />
      <EditEmployeeCertificationsTable
        myCertifications={myCertifications}
        fileProcessInProgress={fileProcessInProgres}
        page={pageRef}
        selectedCertifications={selectedCertifications}
        setSelectedCertifications={setSelectedCertifications}
        onFetchCertifications={fetchMyCertifications}
        onFileAdded={handleAddFile}
        onFileRemoved={handleRemoveFile}
        onFileClick={handleFileClick}
      />
      <FooterActionButtons
        fileProcessInProgress={fileProcessInProgres}
        onDeleteCertification={handleInitDeleteCertification}
        onClose={onClose}
      />
      <DeleteCertificationConfirmationModal
        show={showDeleteConfirmationModal}
        deletingInProgress={deletingInProgress}
        title={t('deletingCertifications', { ns: 'certifications' })}
        content={t('doYouWantToDeleteThisCertifications', {
          ns: 'certifications'
        })}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onDelete={deleteCertification}
      />
      {!!urlFileToView.length && (
        <ThemeProvider theme={theme}>
          <ViewFileModal
            open={!!urlFileToView.length}
            onClose={handleCloseFileViewer}
            title={t('viewFile', { ns: 'certifications' })}
            learningMaterialUrl={urlFileToView}
            backdropColor="transparent"
          />
        </ThemeProvider>
      )}
    </Stack>
  );
};

interface DeleteEmployeeCertificationsErrorProps {
  certificationNames: string[];
}

const DeleteEmployeeCertificationsError = ({
  certificationNames
}: DeleteEmployeeCertificationsErrorProps) => {
  const { t } = useTranslation(['certifications']);

  return (
    <Stack gap={1}>
      <Typography>{t('errorWhileDeletingCerts')}</Typography>
      <ul>
        {certificationNames.map(name => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </Stack>
  );
};

export default EditEmployeeCertifications;

import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import { dropDownItem } from '../../../entities/Common';
import { useTranslation } from 'react-i18next';
import { useBwBreakpoints } from '../../../hooks/useBwBreakpoints';

export interface BasicSelectProps extends SelectProps {
  options: dropDownItem[];
  value?: string[] | '';
  handleOnChange?: (items: string[]) => void;
  theme?: string;
  leftIcon?: React.ReactNode;
}

const basicSelectTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.Mui-focused': {
            border: '2px solid none',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            }
          },
          backgroundColor: 'white',
          height: '38px',
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    }
  }
});

export const darkSelectTheme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: '100%',
          '&.Mui-focused': {
            border: '2px solid white',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'white'
            },
            '& .MuiSvgIcon-root': {
              color: 'white'
            }
          },
          backgroundColor: '#000',
          border: '1px solid #999',
          color: 'white',
          height: '38px',
          fontFamily: 'inherit',
          fontSize: '14px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '#3d3d3d'
          },
          '& .MuiSvgIcon-root': {
            color: '#878787'
          },
          '&.Mui-disabled': {
            color: '#999'
          }
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: '#999'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'inherit',
          fontSize: '14px'
        }
      }
    }
  }
});

const BasicSelect = React.forwardRef<HTMLSelectElement, BasicSelectProps>(
  (
    {
      labelId,
      id,
      options,
      defaultValue,
      handleOnChange,
      placeholder = 'Select',
      multiple = true,
      value,
      leftIcon,
      theme = '',
      ...props
    },
    ref
  ) => {
    const [is640] = useBwBreakpoints();
    const [values, setValues] = useState<string[] | '' | undefined>(
      value ?? []
    );
    const { t } = useTranslation(['common']);

    useEffect(() => {
      setValues(value);
    }, [value]);

    const handleChange = (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value }
      } = event;
      const selected = typeof value === 'string' ? value.split(',') : value;

      setValues(selected);
      handleOnChange?.(selected);
    };

    const getGroupNames = (groupIds: string[]) => {
      if (!groupIds?.length)
        return placeholder === 'Select'
          ? t('select', { ns: 'common' })
          : placeholder;

      const selectedOptions = options.filter(option =>
        groupIds.includes(option.value)
      );
      return selectedOptions.map(option => option.label).join(', ');
    };

    let menuHeight = options.length * 33 + 16;
    menuHeight = menuHeight > 180 ? 180 : menuHeight;

    const getTheme = (theme: string) => {
      switch (theme) {
        case 'dark':
          return darkSelectTheme;
        default:
          return basicSelectTheme;
      }
    };

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: menuHeight,
          width: 100
        }
      },
      MenuListProps: {
        style: {
          backgroundColor: 'white',
          fontFamily: 'inherit'
        }
      }
    };

    return (
      <div>
        <ThemeProvider theme={getTheme(theme)}>
          <Select
            {...props}
            ref={ref}
            labelId={labelId}
            id={id}
            multiple={multiple}
            displayEmpty
            value={values}
            onChange={handleChange}
            input={<OutlinedInput />}
            MenuProps={MenuProps}
            placeholder={
              placeholder === 'Select'
                ? t('select', { ns: 'common' })
                : placeholder
            }
            renderValue={(selected: string[]) => (
              <>
                {leftIcon && !is640 && leftIcon}
                <span
                  dangerouslySetInnerHTML={{ __html: getGroupNames(selected) }}
                />
              </>
            )}
          >
            {options.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                hidden={option.hidden}
              >
                <span dangerouslySetInnerHTML={{ __html: option.label }} />
              </MenuItem>
            ))}
          </Select>
        </ThemeProvider>
      </div>
    );
  }
);

export default BasicSelect;

import React, { CSSProperties } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { actionCreators } from '../../../actions/courses';
import { ApplicationState } from '../../../store';
import { ClickSafetyAssignableUnit } from '../../../entities/ClickSafetyAssignableUnit';
import BackwardIcon from '../../../assets/backward.svg';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import config from '../../../config';
import PageContainer from '../../../components/core/PageContainer/PageContainer';
import { Grid, ThemeProvider } from '@mui/material';
import { Alert } from '@mui/lab';
import { defaultTheme } from '@buildwitt/component-library';
import { useAppDispatch } from '../../../store/hooks';

const ClickSafetyCourse: React.FC = () => {
  const { courseId } = useParams<any>();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const selectedClickSafetyCourseLessons = useSelector(
    (state: ApplicationState) => state.courses?.clickSafetyCourseLessons
  );
  const selectedClickSafetyLessonsSessionId = useSelector(
    (state: ApplicationState) => state.courses?.clickSafetyLessonSessionId
  );
  const courseError = useSelector(
    (state: ApplicationState) => state.courses?.error
  );

  const [courseLessons, setCourseLessons] = React.useState<
    ClickSafetyAssignableUnit[]
  >([]);

  React.useEffect(() => {
    if (courseId) {
      dispatch(actionCreators.setClickSafetySelectedCourseLessons(courseId));
      dispatch(actionCreators.requestCourseById(courseId));
    }
  }, [courseId]);

  React.useEffect(() => {
    if (selectedClickSafetyCourseLessons) {
      setCourseLessons(selectedClickSafetyCourseLessons);
    }
  }, [selectedClickSafetyCourseLessons]);

  React.useEffect(() => {
    if (courseLessons) {
      loadFirstLesson(courseLessons);
    }
  }, [courseLessons]);

  React.useEffect(() => {
    if (courseLessons && courseLessons.length > 0) {
      const lessonUrl = buildCourseLessonUrl(courseLessons[0]);
      window.open(lessonUrl, 'contentFrame');
    }
  }, [selectedClickSafetyLessonsSessionId]);

  const buildCourseLessonUrl = (cslesson: any) => {
    const callbackUrl = encodeURIComponent(
      `${config.STUDENT_API_URL}aicc/callback`
    );
    const aiccId =
      selectedClickSafetyLessonsSessionId &&
      selectedClickSafetyLessonsSessionId.sessionId
        ? selectedClickSafetyLessonsSessionId.sessionId
        : '';
    return `${cslesson.fileName}?${cslesson.webLaunch}&AICC_SID=${aiccId}&AICC_URL=${callbackUrl}`;
  };

  const loadFirstLesson = async (csLessons: ClickSafetyAssignableUnit[]) => {
    if (csLessons && csLessons.length > 0) {
      const csLesson = csLessons[0];
      await dispatch<Promise<void>>(
        actionCreators.setClickSafetyLessonSessionId(
          csLesson.lesson.id,
          employeeId
        )
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Grid container>
          {courseError && (
            <Grid item xs={12}>
              <Alert severity={'error'}>{courseError}</Alert>
            </Grid>
          )}
          <Grid item md={12}>
            <div>
              <h1 className="color-white">
                <i
                  className="fas fa-sm fa-fw mr-2 text-gray-400"
                  style={BackwardIconStyle}
                  onClick={() => {
                    navigate(-1);
                  }}
                />{' '}
                {course?.title}
              </h1>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} style={{ ...sidebar }}>
            {courseLessons && selectedClickSafetyLessonsSessionId && (
              <iframe
                id="contentFrame"
                name="contentFrame"
                src={''}
                width={'100%'}
                height={'100%'}
                title="course content"
                style={{
                  backgroundColor: '#fff'
                }}
              />
            )}
          </Grid>
        </Grid>
      </PageContainer>
    </ThemeProvider>
  );
};

const sidebar: CSSProperties = {
  borderRadius: '5px',
  minHeight: '620px',
  overflow: 'none',
  backgroundColor: '#212121'
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 10,
  height: 18,
  cursor: 'pointer',
  backgroundImage: `url(${BackwardIcon})`
};

export default ClickSafetyCourse;

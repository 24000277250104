import { Box, CircularProgress } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../CustomDataGrid/CustomDataGrid';

interface ModalDataGridProps {
  rows?: GridValidRowModel[] | null;
  columns: GridColDef[];
}

const ModalDataGrid = ({ rows, columns }: ModalDataGridProps) => (
  <>
    {!rows && (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )}
    {!!rows && (
      <Box
        sx={{
          height: '60vh',
          width: '100%'
        }}
      >
        <CustomDataGrid
          getRowId={row => row.assignmentId}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          getRowClassName={getCustomRowClassName}
          rowSelection={false}
          disableColumnMenu
        />
      </Box>
    )}
  </>
);

export default ModalDataGrid;

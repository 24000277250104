import { Dispatch, SetStateAction, useState } from 'react';
import ModalComponent from '../../../../components/core/ModalPage/ModalComponent';
import { CollapsibleDataTable as CustomDataGridPremium } from '@buildwitt/component-library';
import { useTranslation } from 'react-i18next';
import {
  EmployeeCertification,
  AttachmentDto
} from '../../../../entities/EmployeeCertification';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';
import { Box, Grid } from '@mui/material';
import SearchBox from '../../../../components/core/SearchBox/SearchBox';
import { GridColDef } from '@mui/x-data-grid-premium';
import { getFilesColumn } from './ColumnDefinitions';
import { uploadSkillAttachmentToBlob } from '../../../../services/blobStorage-service';
import { useSnackbar } from 'notistack';
import { Guid } from 'guid-typescript';
import { actionCreators as certificationActions } from '../../../../actions/certifications';
import SkeletonLoadingOverlay from '../../SkeletonLoadingOverlay';
import { getEmployeesFullName } from '../ColumnDefinitions';

interface UploadCertifyingModalProps {
  openModal: boolean;
  employeesSelectedToBeAwarded: EmployeeCertification[];
  handleCloseModal: () => void;
  setEmployeesSelected: Dispatch<SetStateAction<EmployeeCertification[]>>;
  handleShowNextModal: () => void;
  handleBack: () => void;
}

const UploadCertifyingModal = ({
  openModal,
  employeesSelectedToBeAwarded,
  handleCloseModal,
  setEmployeesSelected,
  handleShowNextModal,
  handleBack
}: UploadCertifyingModalProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  const [search, setSearch] = useState<string>('');
  const [fileProcessInProgress, setFileProcessInProgress] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const handleAddFile =
    (employeeCertification: EmployeeCertification) =>
    async (fileName: string, file: File) => {
      if (!file) return;

      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      const fileType = file.type;
      setFileProcessInProgress(true);
      try {
        const url = await uploadSkillAttachmentToBlob(file);
        const employeesSelectedNew = employeesSelectedToBeAwarded.map(
          empCert => {
            if (employeeCertification.employeeId === empCert.employeeId) {
              const newAttachment: AttachmentDto = {
                id: Guid.EMPTY,
                fileExtension: fileExtension,
                fileType: fileType,
                url: url,
                name: fileName
              };
              return {
                ...empCert,
                attachmentsDto: [...empCert.attachmentsDto, newAttachment]
              };
            }
            return empCert;
          }
        );
        setEmployeesSelected(employeesSelectedNew);
        enqueueSnackbar(t('fileUploaded', { ns: 'certifications' }), {
          variant: 'success'
        });
      } catch (e) {
        enqueueSnackbar(t('errorUploadinFile', { ns: 'certifications' }), {
          variant: 'error'
        });
      } finally {
        setFileProcessInProgress(false);
      }
    };

  const handleRemoveFile =
    (employeeCertification: EmployeeCertification) => async (index: number) => {
      if (index < 0 || index >= employeeCertification.attachmentsDto.length) {
        return;
      }

      setFileProcessInProgress(true);

      try {
        const attachmentToDelete = employeeCertification.attachmentsDto[index];

        if (attachmentToDelete.id !== Guid.EMPTY) {
          await certificationActions.deleteEmployeeCertificationAttachment(
            attachmentToDelete.id
          );
        }

        const updatedEmployeesSelected = updateEmployeeCertifications(
          employeesSelectedToBeAwarded,
          employeeCertification.employeeId,
          index
        );

        setEmployeesSelected(updatedEmployeesSelected);
        enqueueSnackbar(t('fileWasDeleted', { ns: 'certifications' }), {
          variant: 'success'
        });
      } catch (e) {
        enqueueSnackbar(t('errorDeletingFiles', { ns: 'certifications' }), {
          variant: 'error'
        });
      } finally {
        setFileProcessInProgress(false);
      }
    };

  const updateEmployeeCertifications = (
    employees: EmployeeCertification[],
    employeeId: string,
    attachmentIndex: number
  ) => {
    return employees.map(empCert =>
      empCert.employeeId === employeeId
        ? {
            ...empCert,
            attachmentsDto: empCert.attachmentsDto.filter(
              (_, i) => i !== attachmentIndex
            )
          }
        : empCert
    );
  };

  const columns: GridColDef[] = (() => [
    getEmployeesFullName(
      t('employeeName', { ns: 'certifications' }),
      t('previouslyAwarded', { ns: 'certifications' }),
      true,
      300,
      345,
      0
    ),
    getFilesColumn(
      t('fileUpload', { ns: 'certifications' }),
      fileProcessInProgress,
      handleAddFile,
      handleRemoveFile
    )
  ])();

  return (
    <ModalComponent
      showModal={openModal}
      title={t('uploadCertifyingDocuments', { ns: 'certifications' })}
      maxWidth="md"
      saveButtonText={t('save', { ns: 'common' })}
      showCloseIcon={true}
      showCancelButton={false}
      showSaveButton={false}
      closeWhenClickBackdrop={false}
      closeWhenPressingEscape={false}
      onClose={handleCloseModal}
      savingInProgress={false}
      backdropColor="transparent"
    >
      <Grid>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          flexWrap={'wrap'}
          justifyContent={'start'}
          alignItems={'end'}
        >
          <SearchBox
            id="search"
            value={search}
            name="search"
            width="220px"
            height="40px"
            placeholderText={t('search', { ns: 'common' })}
            variant="dark"
            onChangeValue={(value: any) => {
              setSearch(value);
            }}
          />
        </Box>
        <Box style={{ paddingTop: '20px' }}>
          <CustomDataGridPremium
            rows={
              fileProcessInProgress
                ? []
                : employeesSelectedToBeAwarded.filter(emp =>
                    emp.employeeFullName
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase())
                  ) ?? []
            }
            columns={columns}
            getRowHeight={() => 'auto'}
            getRowId={row => row.employeeId}
            rowCount={employeesSelectedToBeAwarded?.length}
            loading={fileProcessInProgress}
            slots={{
              loadingOverlay: SkeletonLoadingOverlay
            }}
            sx={{
              height: '450px',
              '& .MuiDataGrid-row': {},
              '& .Mui-selected': {
                backgroundColor: '#FF9E0020 !important'
              }
            }}
            disableRowSelectionOnClick
          />
        </Box>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          sx={{ paddingTop: '25px' }}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'end'}
        >
          <BasicButton
            color="primary"
            style={{
              height: '45px',
              width: '88px',
              minWidth: '80px'
            }}
            onClick={handleShowNextModal}
          >
            {t('nextLabel', { ns: 'certifications' })}
          </BasicButton>
          <BasicButton
            color="primaryOutlined"
            style={{
              width: '88px',
              minWidth: '80px',
              height: '43px'
            }}
            onClick={handleBack}
          >
            {t('back', { ns: 'certifications' })}
          </BasicButton>
        </Box>
      </Grid>
    </ModalComponent>
  );
};

export default UploadCertifyingModal;

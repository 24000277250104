import { CSSProperties, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import {
  IconButton,
  Typography,
  Box,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CollapsibleDataTable } from '@buildwitt/component-library';
import { useTranslation } from 'react-i18next';
import { uploadSkillAttachmentToBlob } from '../../../services/blobStorage-service';
import { enqueueSnackbar } from 'notistack';
import {
  actionCreators as certificationActions,
  actionCreators
} from '../../../actions/certifications';
import { useSelector } from 'react-redux';
import ProfilePicture from '../../../assets/profilePicture.png';
import { ApplicationState } from '../../../store';
import {
  EmployeeAttachmentPost,
  EmployeeCompetence
} from '../../../entities/Achievements';
import { useAppDispatch } from '../../../store/hooks';
import RemoveIcon from '@buildwitt/component-library/src/icons/trash.svg';
import { GridPaginationModel } from '@mui/x-data-grid-premium';
import {
  pageCertificationsState,
  systemGeneratedPdf
} from '../../../core/constants';
import { Certification } from '../../../entities/Certification';
import DeleteCertificationConfirmationModal from '../Common/DeleteCertificationConfirmationModal';
import SkeletonLoadingOverlay from '../SkeletonLoadingOverlay';
import { AxiosError } from 'axios';
import { Guid } from 'guid-typescript';
import { ViewFileModal } from '../../lessons/categories/FileSection';
import themeConfig from '../../../themes/theme';
import FileUploader from '../../../components/core/FileUploader/FileUploader';

interface EmployeesWithCertificationsContentListProps {
  certification: Certification | undefined | null;
}
const EmployeesWithCertifications = ({
  certification
}: EmployeesWithCertificationsContentListProps) => {
  const { t } = useTranslation(['skills', 'certifications']);
  const theme = createTheme(themeConfig);
  const [certificationAwardeeToRemove, setCertificationAwardeeToRemove] =
    useState<EmployeeCompetence | null>();
  const [viewFileUrl, setViewFileUrl] = useState('');
  const { page, pageSize } = pageCertificationsState;
  const [isProgressing, setIsProgressing] = useState<boolean>(false);
  const [pageModel, setPageModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page,
    pageSize
  });

  const dispatch = useAppDispatch();
  const awardeesIsLoading = useSelector(
    (state: ApplicationState) =>
      state?.certifications?.employeesByCertificationIsLoading
  );

  const employeesByCertification = useSelector(
    (state: ApplicationState) => state?.certifications?.employeesByCertification
  );
  const getContentNameColumn = (headerName: string): GridColDef => ({
    field: 'name',
    headerName: headerName,
    maxWidth: 300,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <div style={{ paddingLeft: 20 }}>
        <span>{headerName}</span>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div style={containerCell} className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <Box display="flex" flexDirection="row" alignItems={'center'}>
            <img
              alt="employee"
              src={params.row.employeeProfileImageUrl || ProfilePicture}
              style={profileImage}
            />
            <div>
              <Typography
                noWrap
                style={{
                  fontSize: 14,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
                width={184}
              >
                {params.row.employeeFullName}
              </Typography>
              {params.row.employeeJobTitle && (
                <Typography
                  width={184}
                  style={{
                    fontSize: 12,
                    color: '#999999',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                  }}
                >
                  {params.row.employeeJobTitle}
                </Typography>
              )}
            </div>
          </Box>
        </div>
      </div>
    )
  });

  const onFileAdded =
    (employeeCompetenceId: string, employeeId: string) =>
    async (fileName: string, file: File) => {
      if (!file || !employeeCompetenceId) {
        return;
      }
      const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
      const fileType = file.type;
      setIsProgressing(true);
      try {
        const url = await uploadSkillAttachmentToBlob(file);
        const data: EmployeeAttachmentPost = {
          employeeCompeteceId: employeeCompetenceId,
          employeeId: employeeId,
          attachment: {
            fileExtension,
            fileType,
            url,
            name: fileName
          }
        };

        if (url) {
          await certificationActions.employeeAwardeeAddAttachment(data);
          getEmployeesByCertification();
          enqueueSnackbar(t('attachedFiles', { ns: 'skills' }), {
            variant: 'success'
          });
        }
      } catch (e) {
        enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
          variant: 'error',
          autoHideDuration: 3000
        });
      } finally {
        setIsProgressing(false);
      }
    };

  const handleRemoveFile = async (fileId: string) => {
    setIsProgressing(true);

    try {
      if (fileId !== Guid.EMPTY) {
        await certificationActions.deleteEmployeeCertificationAttachment(
          fileId
        );
      }
      getEmployeesByCertification();
      enqueueSnackbar(t('fileWasDeleted', { ns: 'certifications' }), {
        variant: 'success'
      });
    } catch (e) {
      enqueueSnackbar(t('errorDeletingFiles', { ns: 'certifications' }), {
        variant: 'error'
      });
    } finally {
      setIsProgressing(false);
    }
  };

  const handleOnClickFile = async (fileUrl: string) => {
    if (fileUrl === systemGeneratedPdf) {
      return;
    }
    setViewFileUrl(fileUrl);
  };

  const getAttachmentsColumns = (headerName: string): GridColDef => ({
    field: 'attachments',
    headerName: headerName,
    minWidth: 300,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <FileUploader
        progressing={isProgressing}
        employeeFiles={params.row.attachmentsDto}
        shouldIncludeSystemGeneratedFile={params.row.generateSystemCertificate}
        addButtonLabel={t('upload', { ns: 'skills' })}
        onFileAdd={onFileAdded(params.row.id, params.row.employeeId)}
        onFileClick={handleOnClickFile}
        onFileRemove={handleRemoveFile}
        validTypes={'.pdf,image/*'}
      />
    )
  });

  const getValidTillColumn = (headerName: string): GridColDef => ({
    field: 'validTill',
    headerName: headerName,
    maxWidth: 100,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div style={containerCell} className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <span style={{ fontSize: 14, color: '#FFFFFF' }}>
            {params.row.expiresAt && !params.row.certificationNeverExpires
              ? moment(params.row.expiresAt).format('MM.DD.YY')
              : t('never', { ns: 'skills' })}
          </span>
        </div>
      </div>
    )
  });

  const getRemoveColumn = (headerName: string): GridColDef => ({
    field: 'remove',
    headerName: headerName,
    maxWidth: 120,
    sortable: false,
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <IconButton
        aria-label="remove"
        color="info"
        onClick={() => setCertificationAwardeeToRemove(params.row)}
      >
        <img src={RemoveIcon} alt={params.row.name} />
      </IconButton>
    )
  });

  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('employee', { ns: 'skills' })),
    getAttachmentsColumns(t('attachments', { ns: 'skills' })),
    getValidTillColumn(t('validTill', { ns: 'skills' })),
    getRemoveColumn(t('remove', { ns: 'skills' }))
  ])();

  const getEmployeesByCertification = useCallback(() => {
    if (certification?.id) {
      dispatch(
        actionCreators.getEmployeesByCertification(
          certification.id,
          pageModel.page,
          pageModel.pageSize
        )
      );
    }
  }, [certification?.id, dispatch, pageModel.page, pageModel.pageSize]);

  useEffect(() => {
    if (certification?.id) {
      getEmployeesByCertification();
    } else {
      dispatch(actionCreators.resetEmployeesByCertification());
    }
  }, [certification?.id, dispatch, getEmployeesByCertification]);

  const onHandlePagination = (model: GridPaginationModel) => {
    setPageModel({ page: model.page + 1, pageSize: model.pageSize });
  };

  const getAxiosError = (error: unknown) => {
    const axiosError = error as AxiosError;
    if (axiosError) {
      const errorData = axiosError.response?.data as any;
      return errorData?.detail ?? '';
    }

    return '';
  };

  const onRemoveAwardee = async () => {
    if (!certificationAwardeeToRemove?.id) {
      return;
    }
    setIsProgressing(true);
    try {
      await certificationActions.deleteAwardeeById(
        certificationAwardeeToRemove?.id
      );
      dispatch(certificationActions.refetchCertifications(true));
      getEmployeesByCertification();
      enqueueSnackbar(t('certificationDeleted', { ns: 'certifications' }), {
        variant: 'success'
      });
      onCloseRemoveAwardeeModal();
    } catch (e) {
      const errorDetail = getAxiosError(e);
      enqueueSnackbar(
        errorDetail.length
          ? errorDetail
          : t('errorDeletingCertification', { ns: 'certifications' }),
        { variant: 'error' }
      );
    } finally {
      setIsProgressing(false);
    }
  };

  const onCloseRemoveAwardeeModal = () => {
    setCertificationAwardeeToRemove(null);
  };

  const handleCloseViewFile = () => {
    setViewFileUrl('');
  };

  return (
    <Box
      sx={{
        height: '99%',
        width: '100%',
        '& .row-odd-style': {
          bgcolor: 'rgba(40, 40, 40, 0.72)'
        },
        '& .row-peer-style': {
          bgcolor: '#1B1C1C'
        }
      }}
    >
      <CustomDataGrid
        getRowId={row => row.id}
        rows={awardeesIsLoading ? [] : employeesByCertification?.rows ?? []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: pageModel.page - 1,
              pageSize: pageModel.pageSize
            }
          }
        }}
        getRowHeight={() => 'auto'}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? 'row-odd-style'
            : 'row-peer-style'
        }
        rowSelection={false}
        loading={awardeesIsLoading}
        disableColumnMenu
        slots={{
          loadingOverlay: SkeletonLoadingOverlay
        }}
        onPaginationModelChange={onHandlePagination}
        rowCount={employeesByCertification?.totalCount ?? 0}
        pagination
        paginationMode={'server'}
      />
      <DeleteCertificationConfirmationModal
        show={!!certificationAwardeeToRemove}
        deletingInProgress={isProgressing}
        title={t('deletingCertification', { ns: 'certifications' })}
        content={t('doYouWantToDeleteThisCertification', {
          ns: 'certifications'
        })}
        onCancel={onCloseRemoveAwardeeModal}
        onDelete={onRemoveAwardee}
      />
      <ThemeProvider theme={theme}>
        <ViewFileModal
          open={!!viewFileUrl}
          onClose={handleCloseViewFile}
          title={t('viewFile', { ns: 'lessons' })}
          learningMaterialUrl={viewFileUrl}
        />
      </ThemeProvider>
    </Box>
  );
};

export const CustomDataGrid = styled(CollapsibleDataTable)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  minWidth: '800px',
  backgroundColor: theme.palette.grey['700'],
  borderRadius: '8px',
  fontSize: '16px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid rgba(153, 153, 153, 0.30)`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid rgba(153, 153, 153, 0.30)`
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  },
  '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
    outline: 'none !important'
  }
}));

const profileImage: CSSProperties = {
  borderRadius: 50,
  width: 40,
  height: 40,
  marginRight: 10
};

const containerCell: CSSProperties = {
  position: 'absolute',
  justifyContent: 'center'
};

export default EmployeesWithCertifications;

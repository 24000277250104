import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import { useAnalyticsEngagementModal } from '../../../hooks/useAnalyticsEngagementModal';
import GroupCurrentAssignmentList from './GroupCurrentAssignmentList';
import UpdateDueDateModal from '../common/UpdateDueDateModal';

interface GroupCurrentAssignmentsModalProps {
  groupId?: string;
  groupName?: string;
  onCloseModal: () => void;
}

const GroupCurrentAssignmentsModal = ({
  groupId,
  groupName,
  onCloseModal
}: GroupCurrentAssignmentsModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: {
      sortOptions,
      openModal,
      assignmentId,
      dueDate,
      learningUnitType,
      groupCurrentAssignmentsData
    },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved,
      handleDownloadReport
    }
  } = useAnalyticsEngagementModal('groupCurrentAssignments', groupId);

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t(`currentAssignments`)}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
        onDownloadReport={handleDownloadReport}
        subtitle={groupName}
      >
        <GroupCurrentAssignmentList
          groupAssignmentsData={groupCurrentAssignmentsData}
          onEditDueDate={handleEditDueDate}
        />
      </AssignmentStatusModal>
      <UpdateDueDateModal
        learningUnitType={learningUnitType}
        assignmentId={assignmentId}
        dueDate={dueDate}
        onClose={handleCloseUpdateDueDateModal}
        onSuccessfulSaved={handleSuccessfulSaved}
      />
    </>
  );
};

export default GroupCurrentAssignmentsModal;

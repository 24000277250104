import React, { FC, CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import MovieLineIcon from '../../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../../assets/flash-card-pdf.svg';
import QuizCardIcon from '../../../assets/quiz.svg';
import { NewLessonType } from '../../../entities/LessonFormModel';
import { useTranslation } from 'react-i18next';
import { iconStyle } from '../../CourseCard/CourseCardStyles';

interface ChipLessonTypeProps {
  lessonType: number;
}

const ChipLessonType: FC<ChipLessonTypeProps> = ({ lessonType }) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  let lessonTypeImg = '';

  switch (lessonType) {
    case NewLessonType.Video:
      lessonTypeImg = MovieLineIcon;
      break;
    case NewLessonType.File:
      lessonTypeImg = FlashCardPdfIcon;
      break;
    case NewLessonType.Quiz:
      lessonTypeImg = QuizCardIcon;
      break;
    case NewLessonType.Audio:
      lessonTypeImg = MovieLineIcon;
      break;
    default:
      break;
  }
  const lessonTypeStyle: CSSProperties = {
    color: `${theme.palette.common.white}`,
    display: 'inline-block',
    fontStyle: 'normal',
    fontSize: '12px',
    marginRight: '12px',
    marginBottom: '10px'
  };

  const getLessonTypeName = (ltype: number) => {
    switch (ltype) {
      case NewLessonType.Video:
        return `Video`;
      case NewLessonType.Quiz:
        return `${t('lessonQuiz', { ns: 'lessons' })}`;
      case NewLessonType.File:
        return `${t('files', { ns: 'lessons' })}`;
      case NewLessonType.Audio:
        return `Audio`;
      default:
        return '';
    }
  };

  return (
    <div>
      <img alt="MovieLine" src={lessonTypeImg} style={iconStyle} />
      <span style={lessonTypeStyle} className="mt-1 align-top">
        {getLessonTypeName(lessonType)}
      </span>
    </div>
  );
};

export default ChipLessonType;

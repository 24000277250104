import { CollapsibleDataTable } from '@buildwitt/component-library';
import { actionCreators } from '../../../actions/certifications';
import {
  GridColDef,
  GridPaginationModel,
  GridValidRowModel
} from '@mui/x-data-grid-premium';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  GetAwardEmployee,
  getCertificationDuration,
  getCertificationName,
  GetEditCertification,
  getEmployeesCertified
} from './ColumDefinitions';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import RichTextInput from '../../../components/core/RichTextInput/RichTextInput';
import { useAppDispatch } from '../../../store/hooks';
import { pageCertificationsState } from '../../../core/constants';
import SkeletonLoadingOverlay from '../SkeletonLoadingOverlay';
import { Certification } from '../../../entities/Certification';

interface CertificationsTableProps {
  onEditCertification: (selectedCertification: Certification) => void;
  searchValue: string;
  onShowAwardees: (selectedCertification: Certification) => void;
  onOpenAwardModal: (selectedCertification: Certification) => void;
}

const CertificationsTable = ({
  onEditCertification,
  searchValue,
  onShowAwardees,
  onOpenAwardModal
}: CertificationsTableProps) => {
  const certifications = useSelector(
    (state: ApplicationState) => state.certifications?.certifications
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.certifications?.isLoading
  );
  const refetchCertifications = useSelector(
    (state: ApplicationState) => state.certifications?.refetchCertifications
  );

  const { t } = useTranslation(['certifications', 'common']);
  const dispatch = useAppDispatch();
  const { page, pageSize, sortBy } = pageCertificationsState;
  const [pageModel, setPageModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page,
    pageSize
  });

  useEffect(() => {
    if (refetchCertifications) {
      dispatch(
        actionCreators.getCertifications(
          pageModel.page,
          pageModel.pageSize,
          sortBy.createdAt,
          ''
        )
      );

      dispatch(actionCreators.refetchCertifications(false));
    }
  }, [refetchCertifications]);

  const columns_detail_row_component: GridColDef[] = useMemo(
    () => [
      getCertificationName(t('certificateName')),
      getCertificationDuration(t('expirationDuration'), t('neverExpiresAt')),
      getEmployeesCertified(
        t('numberOfCertifiedEmployees'),
        t('showAwardees'),
        onShowAwardees
      ),
      GetAwardEmployee(t('awardEmployee'), onOpenAwardModal),
      GetEditCertification(
        t('editCertification'),
        t('disabledPermissions', { ns: 'common' }),
        onEditCertification
      )
    ],
    []
  );

  const onHandlePagination = (model: GridPaginationModel) => {
    setPageModel({ page: model.page + 1, pageSize: model.pageSize });
    dispatch<Promise<void>>(
      actionCreators.getCertifications(
        model.page + 1,
        model.pageSize,
        sortBy.createdAt,
        searchValue
      )
    );
  };

  return (
    <CollapsibleDataTable
      columns={columns_detail_row_component}
      rows={isLoading ? [] : certifications?.pageItems ?? []}
      loading={isLoading ?? false}
      disableColumnMenu={true}
      sx={{
        '& .MuiTablePagination-displayedRows': { margin: 0 },
        '& .MuiDataGrid-overlayWrapper': { background: '#1B1C1C' }
      }}
      sortingOrder={['desc', 'asc']}
      hideFooterSelectedRowCount={true}
      disableRowSelectionOnClick
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 20, 50]}
      rowCount={certifications?.totalItems ?? 0}
      paginationModel={{
        page: pageModel.page - 1,
        pageSize: pageModel.pageSize
      }}
      getDetailPanelContent={props =>
        DetailPanel({ descriptionLabel: t('description'), ...props })
      }
      getDetailPanelHeight={() => 'auto'}
      slots={{
        detailPanelExpandIcon: ArrowExpandIcon,
        detailPanelCollapseIcon: ArrowCollapseIcon,
        loadingOverlay: SkeletonLoadingOverlay
      }}
      onPaginationModelChange={onHandlePagination}
      columnBuffer={6}
    />
  );
};

export default CertificationsTable;

const ArrowExpandIcon = () => {
  return <ArrowForwardIos sx={{ transform: 'rotate(0deg)' }} />;
};
const ArrowCollapseIcon = () => {
  return <ArrowForwardIos sx={{ transform: 'rotate(90deg)' }} />;
};

interface DetailPanelProps extends GridValidRowModel {
  descriptionLabel: string;
}

const DetailPanel: React.FC<DetailPanelProps> = ({ ...props }) => {
  return (
    <Grid
      container
      display={'flex'}
      direction={'row'}
      style={{ padding: '20px' }}
      spacing={5}
      justifyContent={'start'}
    >
      <Grid item>
        <Typography>{`${props.descriptionLabel}: `}</Typography>
      </Grid>
      <Grid item>
        <RichTextInput value={props.row.description} disabled={true} />
      </Grid>
    </Grid>
  );
};

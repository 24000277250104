import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { SortingOptions, SortingSpanishOptions } from '../../../core/constants';
import { selectStyle, selectStyleResponsive } from './filtersStyles';
import { SvgIcon, createSvgIcon } from '@mui/material';
import { CSSProperties } from 'react';
import { useBwBreakpoints } from '../../../hooks/useBwBreakpoints';

interface SortContentSelectProps {
  value: string;
  onChange: (items: string[]) => void;
  style?: CSSProperties;
}
const SortContentSelect: React.FC<SortContentSelectProps> = ({
  value,
  onChange,
  style
}) => {
  const userLang = navigator.language.substring(0, 2);
  const [is640] = useBwBreakpoints();
  const SortingFilterOptions =
    userLang === 'es' ? SortingSpanishOptions : SortingOptions;

  const finalStyle = style
    ? style
    : is640
      ? selectStyleResponsive
      : selectStyle;

  return (
    <BasicSelect
      labelId="sortOptions"
      id="sortOptions"
      options={SortingFilterOptions}
      defaultValue=""
      value={[value]}
      handleOnChange={onChange}
      style={finalStyle}
      theme="dark"
      leftIcon={<SvgIcon component={SortIcon} inheritViewBox />}
      multiple={false}
      sx={{ backgroundColor: 'transparent' }}
    />
  );
};

export default SortContentSelect;

export const SortIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <g id="Group_2">
        <path
          id="Vector"
          d="M8 16L12 20L16 16L13 16L13 1L11 1L11 16L8 16Z"
          fill="#999999"
        />
        <path
          id="Vector_2"
          d="M8 4L4 -1.74846e-07L-1.74846e-07 4L3 4L3 19L5 19L5 4L8 4Z"
          fill="#999999"
        />
      </g>
    </g>
  </svg>,
  'SortIcon'
);

import axios from 'axios';
import { AppThunk } from '../store/index';
import config from './../config';
import {
  ANALYTICS_SET_CONTENT_LIBRARY_DATA,
  ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS,
  ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS,
  ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS,
  ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA,
  ANALYTICS_SET_GROUP_ENGAGEMENT_DATA,
  ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA,
  ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA,
  ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA,
  ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA,
  ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA,
  ANALYTICS_SET_QUIZZES_DATA,
  ANALYTICS_SET_QUIZ_DETAIL_DATA,
  ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS,
  ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS,
  ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE,
  ANALYTICS_SET_QUIZ_RESULT,
  ANALYTICS_SET_SKILLS_DATA
} from './actionTypes';
import {
  AnalyticsCompletedAssignmentDetail,
  AnalyticsOverdueAssignmentDetail,
  AnalyticsPendingCompletionDetail,
  AnalyticsContentLibrary,
  AnalyticsContentLibraryDetail,
  AnalyticsGroupEngagement,
  AnalyticsGroupEngagementDetail,
  AnalyticsCurrentAssignmentsForGroups,
  AnalyticsEmployeeCompletedAssignment,
  AnalyticsEmployeeOverdueAssignment,
  AnalyticsEmployeeCurrentAssignment,
  AnalyticsGroupCompletedAssigments,
  AnalyticsEmployeeSelfAssignment,
  AnalyticsQuiz,
  AnalyticsQuizDetail,
  AnalyticsPendingCompletionQuizAssignments,
  AnalyticsCompletedQuizAssignments,
  AnalyticsSkills
} from '../entities/Analytics';
import {
  AssessmentsAverage,
  AssessmentResultWithQuestions
} from '../entities/Assessment';
import { EntityTypeEnum } from '../core/enums';
import { handleGenericBackendError } from '../utils/errorHandling';
export interface AnalyticsQueryParamsOptions {
  contentType: string;
  librarySource: string;
  includeSelfAssignments: boolean;
  activeOnly: boolean;
  subscribedOnly: boolean;
  groupId: string;
  supervisorId: string;
  lessonType?: string;
}

export interface PaginationQueryParams {
  page: number;
  pageSize: number;
  searchKey: string;
  sortKey: string;
  sortOrientation: string;
}

export interface SkillsQueryParams extends PaginationQueryParams {
  activeOnly: boolean;
  subscribedOnly: boolean;
  groupId: string;
  supervisorId: string;
  onlyCertificates: boolean;
}

export const actionCreators = {
  getContentLibraryData:
    (params: AnalyticsQueryParamsOptions): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      const {
        contentType,
        librarySource,
        includeSelfAssignments,
        activeOnly,
        subscribedOnly,
        groupId,
        supervisorId,
        lessonType
      } = params;

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/contentLibrary/${contentType}/${librarySource}/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/${
              lessonType ?? ''
            }?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_CONTENT_LIBRARY_DATA,
            contentLibraryData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setContentLibraryData: (data: AnalyticsContentLibrary[] | null) => ({
    type: ANALYTICS_SET_CONTENT_LIBRARY_DATA,
    contentLibraryData: data
  }),
  getOverdueLearningUnitAssignments:
    (
      id: string,
      learningUnitType: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/overdue/${learningUnitType}/${includeSelfAssignments}/${id}/${activeOnly}/${subscribedOnly}/assignments?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS,
            overdueLearningUnitAssignments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setOverdueLearningUnitAssignments: (
    data: AnalyticsOverdueAssignmentDetail[] | null
  ) => ({
    type: ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS,
    overdueLearningUnitAssignments: data
  }),
  getPendingCompletionAssignments:
    (
      id: string,
      learningUnitType: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/pending/completions/${learningUnitType}/${includeSelfAssignments}/${id}/${activeOnly}/${subscribedOnly}/assignments?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS,
            pendingCompletionAssignments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setPendingCompletionAssignments: (
    data: AnalyticsPendingCompletionDetail[] | null
  ) => ({
    type: ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS,
    pendingCompletionAssignments: data
  }),
  getCompletedLearningUnitAssignments:
    (
      id: string,
      learningUnitType: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/complete/${learningUnitType}/${includeSelfAssignments}/${id}/${activeOnly}/${subscribedOnly}/assignments?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS,
            completedLearningUnitAssignments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCompletedLearningUnitAssignments: (
    data: AnalyticsCompletedAssignmentDetail[] | null
  ) => ({
    type: ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS,
    completedLearningUnitAssignments: data
  }),
  getContentLibraryDetailData:
    (
      contentType: string,
      includeSelfAssignments: boolean,
      learningUnitId: string,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/contentLibrary/${contentType}/${includeSelfAssignments}/${learningUnitId}/${activeOnly}/${subscribedOnly}/detail?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA,
            contentLibraryDetailData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setContentLibraryDetailData: (
    data: AnalyticsContentLibraryDetail[] | null
  ) => ({
    type: ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA,
    contentLibraryDetailData: data
  }),
  getGroupEngagementData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/groups/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}?groupId=${groupId}`
          );

          dispatch({
            type: ANALYTICS_SET_GROUP_ENGAGEMENT_DATA,
            groupEngagementData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setGroupEngagementData: (data: AnalyticsGroupEngagement[] | null) => ({
    type: ANALYTICS_SET_GROUP_ENGAGEMENT_DATA,
    groupEngagementData: data
  }),
  getGroupEngagementDetailData:
    (
      groupId: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/groups/${groupId}/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/detail`
          );

          dispatch({
            type: ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA,
            groupEngagementDetailData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setGroupEngagementDetailData: (
    data: AnalyticsGroupEngagementDetail[] | null
  ) => ({
    type: ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA,
    groupEngagementDetailData: data
  }),
  getEmployeeEngagementData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/employees/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA,
            employeeEngagementData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeEngagementData: (
    data: AnalyticsGroupEngagementDetail[] | null
  ) => ({
    type: ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA,
    employeeEngagementData: data
  }),
  getGroupCurrentAssignmentsData:
    (
      groupId: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/group/${groupId}/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/current/assignments`
          );

          dispatch({
            type: ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA,
            groupCurrentAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setGroupCurrentAssignmentsData: (
    data: AnalyticsCurrentAssignmentsForGroups[] | null
  ) => ({
    type: ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA,
    groupCurrentAssignmentsData: data
  }),
  getEmployeeCompletedAssignmentsData:
    (employeeId: string, includeSelfAssignments: boolean): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/employee/${employeeId}/completedAssignments/${includeSelfAssignments}`
          );

          dispatch({
            type: ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA,
            employeeCompletedAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeCompletedAssignmentsData: (
    data: AnalyticsEmployeeCompletedAssignment[] | null
  ) => ({
    type: ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA,
    employeeCompletedAssignmentsData: data
  }),
  getEmployeeOverdueAssignmentsData:
    (
      employeeId: string,
      includeSelfAssignments: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/employee/${employeeId}/${includeSelfAssignments}/overdue/assignments`
          );

          dispatch({
            type: ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA,
            employeeOverdueAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeOverdueAssignmentsData: (
    data: AnalyticsEmployeeOverdueAssignment[] | null
  ) => ({
    type: ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA,
    employeeOverdueAssignmentsData: data
  }),
  getEmployeeCurrentAssignmentsData:
    (
      employeeId: string,
      includeSelfAssignments: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/employee/${employeeId}/${includeSelfAssignments}/current/assignments`
          );

          dispatch({
            type: ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA,
            employeeCurrentAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeCurrentAssignmentsData: (
    data: AnalyticsEmployeeCurrentAssignment[] | null
  ) => ({
    type: ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA,
    employeeCurrentAssignmentsData: data
  }),
  getGroupCompletedAssignmentsData:
    (
      groupId: string,
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/group/${groupId}/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/completed/assignments`
          );

          dispatch({
            type: ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA,
            groupCompletedAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setGroupCompletedAssignmentsData: (
    data: AnalyticsGroupCompletedAssigments[] | null
  ) => ({
    type: ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA,
    groupCompletedAssignmentsData: data
  }),
  getEmployeeSelfAssignmentsData:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/engagement/employee/${employeeId}/self/assignments`
          );

          dispatch({
            type: ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA,
            employeeSelfAssignmentsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeSelfAssignmentsData: (
    data: AnalyticsEmployeeCompletedAssignment[] | null
  ) => ({
    type: ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA,
    employeeSelfAssignmentsData: data
  }),
  getQuizzesData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      groupId: string,
      supervisorId: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/quizzes/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZZES_DATA,
            quizzesData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setQuizzesData: (data: AnalyticsQuiz[] | null) => ({
    type: ANALYTICS_SET_QUIZZES_DATA,
    quizzesData: data
  }),
  getQuizDetailData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      quizLessonId: string,
      assessmentId: string,
      groupId: string,
      supervisorId: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/quizzes/detail/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/${quizLessonId}/${assessmentId}?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZ_DETAIL_DATA,
            quizDetailData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setQuizDetailData: (data: AnalyticsQuizDetail[] | null) => ({
    type: ANALYTICS_SET_QUIZ_DETAIL_DATA,
    quizDetailData: data
  }),
  getPendingCompletionQuizAssignmentsData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      quizLessonId: string,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/quizzes/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/${quizLessonId}/pending/completion/assignments?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS,
            pendingCompletionQuizAssignments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setPendingCompletionQuizAssignmentsData: (
    data: AnalyticsPendingCompletionQuizAssignments[] | null
  ) => ({
    type: ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS,
    pendingCompletionQuizAssignments: data
  }),
  getCompletedQuizAssignmentsData:
    (
      includeSelfAssignments: boolean,
      activeOnly: boolean,
      subscribedOnly: boolean,
      quizLessonId: string,
      groupId: string,
      supervisorId: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}analytics/quizzes/${includeSelfAssignments}/${activeOnly}/${subscribedOnly}/${quizLessonId}/completed/assignments?groupId=${groupId}&supervisorId=${supervisorId}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS,
            completedQuizAssignments: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCompletedQuizAssignmentsData: (
    data: AnalyticsCompletedQuizAssignments[] | null
  ) => ({
    type: ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS,
    completedQuizAssignments: data
  }),
  getAssessmentResultsAverage:
    (assessmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}analytics/assessmentsAverage/${assessmentId}`
          );

          dispatch({
            type: ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE,
            assessmentResultsAverage: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setAssessmentResultsAverage: (data: AssessmentsAverage | null) => ({
    type: ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE,
    assessmentResultsAverage: data
  }),
  getQuizResult:
    (resultId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}analytics/assessmentresultbyid/${resultId}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZ_RESULT,
            quizResult: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getOverdueAssignmentLessons:
    (
      contentType: EntityTypeEnum.Lesson,
      learningUnitId: string,
      includeSelfAssignments = true,
      activeOnly = true,
      subscribedOnly = true
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}analytics/overdue/${contentType}/${includeSelfAssignments}/${learningUnitId}/${activeOnly}/${subscribedOnly}`
          );

          dispatch({
            type: ANALYTICS_SET_QUIZ_RESULT,
            quizResult: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setQuizResult: (data: AssessmentResultWithQuestions | null) => ({
    type: ANALYTICS_SET_QUIZ_RESULT,
    quizResult: data
  }),
  getSkillsData:
    (params: SkillsQueryParams): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        const {
          page,
          pageSize,
          searchKey,
          sortKey,
          sortOrientation,
          activeOnly,
          subscribedOnly,
          groupId,
          supervisorId,
          onlyCertificates
        } = params;

        try {
          const res = await axios.get(
            `${config.ACHIEVEMENTS_API_URL}employees/analytics?page=${page}&pageSize=${pageSize}&searchKey=${searchKey}&sortKey=${sortKey}&sortOrientation=${sortOrientation}&activeOnly=${activeOnly}&subscribedOnly=${subscribedOnly}&groupId=${groupId}&supervisorId=${supervisorId}&onlyCertificates=${onlyCertificates}`
          );

          dispatch({
            type: ANALYTICS_SET_SKILLS_DATA,
            skillsData: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setSkillsData: (data: AnalyticsSkills | null) => ({
    type: ANALYTICS_SET_SKILLS_DATA,
    skillsData: data
  })
};

export interface GetContentLibraryData {
  type: 'ANALYTICS_SET_CONTENT_LIBRARY_DATA';
  contentLibraryData: AnalyticsContentLibrary[] | null;
}

export interface GetOverdueLearningUnitAssignments {
  type: 'ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS';
  overdueLearningUnitAssignments: AnalyticsOverdueAssignmentDetail[] | null;
}

export interface GetPendingCompletionsAssignments {
  type: 'ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS';
  pendingCompletionAssignments: AnalyticsPendingCompletionDetail[] | null;
}

export interface GetCompletedLearningUnitAssignments {
  type: 'ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS';
  completedLearningUnitAssignments: AnalyticsCompletedAssignmentDetail[] | null;
}

export interface GetContentLibraryDetailData {
  type: 'ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA';
  contentLibraryDetailData: AnalyticsContentLibraryDetail[] | null;
}

export interface GetGroupEngagementData {
  type: 'ANALYTICS_SET_GROUP_ENGAGEMENT_DATA';
  groupEngagementData: AnalyticsGroupEngagement[] | null;
}

export interface GetGroupEngagementDetailData {
  type: 'ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA';
  groupEngagementDetailData: AnalyticsGroupEngagementDetail[] | null;
}

export interface GetEmployeeEngagementData {
  type: 'ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA';
  employeeEngagementData: AnalyticsGroupEngagementDetail[] | null;
}

export interface GetGroupCurrentAssignmentsData {
  type: 'ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA';
  groupCurrentAssignmentsData: AnalyticsCurrentAssignmentsForGroups[] | null;
}

export interface GetEmployeeCompletedAssignmentsData {
  type: 'ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA';
  employeeCompletedAssignmentsData:
    | AnalyticsEmployeeCompletedAssignment[]
    | null;
}

export interface GetEmployeeOverdueAssignmentsData {
  type: 'ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA';
  employeeOverdueAssignmentsData: AnalyticsEmployeeOverdueAssignment[] | null;
}

export interface GetEmployeeCurrentAssignmentsData {
  type: 'ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA';
  employeeCurrentAssignmentsData: AnalyticsEmployeeCurrentAssignment[] | null;
}

export interface GetGroupCompletedAssignmentsData {
  type: 'ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA';
  groupCompletedAssignmentsData: AnalyticsGroupCompletedAssigments[] | null;
}

export interface GetEmployeeSelfAssignmentsData {
  type: 'ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA';
  employeeSelfAssignmentsData: AnalyticsEmployeeSelfAssignment[] | null;
}

export interface GetQuizzesData {
  type: 'ANALYTICS_SET_QUIZZES_DATA';
  quizzesData: AnalyticsQuiz[] | null;
}

export interface GetQuizDetailData {
  type: 'ANALYTICS_SET_QUIZ_DETAIL_DATA';
  quizDetailData: AnalyticsQuizDetail[] | null;
}

export interface GetPendingCompletionQuizAssignmentsData {
  type: 'ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS';
  pendingCompletionQuizAssignments:
    | AnalyticsPendingCompletionQuizAssignments[]
    | null;
}

export interface GetCompletedQuizAssignmentsData {
  type: 'ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS';
  completedQuizAssignments: AnalyticsCompletedQuizAssignments[] | null;
}

export interface GetAssessmentResultsAverage {
  type: 'ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE';
  assessmentResultsAverage: AssessmentsAverage | null;
}

export interface GetQuizResult {
  type: 'ANALYTICS_SET_QUIZ_RESULT';
  quizResult: AssessmentResultWithQuestions | null;
}

export interface GetSkillsData {
  type: 'ANALYTICS_SET_SKILLS_DATA';
  skillsData: AnalyticsSkills | null;
}

export type KnownAction =
  | GetContentLibraryData
  | GetOverdueLearningUnitAssignments
  | GetPendingCompletionsAssignments
  | GetCompletedLearningUnitAssignments
  | GetContentLibraryDetailData
  | GetGroupEngagementData
  | GetGroupEngagementDetailData
  | GetEmployeeEngagementData
  | GetGroupCurrentAssignmentsData
  | GetEmployeeCompletedAssignmentsData
  | GetEmployeeOverdueAssignmentsData
  | GetEmployeeCurrentAssignmentsData
  | GetGroupCompletedAssignmentsData
  | GetEmployeeSelfAssignmentsData
  | GetQuizzesData
  | GetQuizDetailData
  | GetPendingCompletionQuizAssignmentsData
  | GetCompletedQuizAssignmentsData
  | GetAssessmentResultsAverage
  | GetQuizResult
  | GetSkillsData;

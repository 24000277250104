import React, { FC } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { defaultTheme } from '@buildwitt/component-library';
import { ThemeProvider } from '@mui/material/styles';

interface ChipAttendanceOnsiteProps {
  attendanceText: string;
  color: string;
}

const ChipAttendanceOnsite: FC<ChipAttendanceOnsiteProps> = ({
  attendanceText,
  color
}) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Stack direction="row" spacing={1}>
        <Chip
          color={color === 'Attend' ? 'success' : 'error'}
          label={attendanceText}
          size="small"
        />
      </Stack>
    </ThemeProvider>
  );
};

export default ChipAttendanceOnsite;

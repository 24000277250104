import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignmentTypes } from '../../entities/LearningPlaylist';
import AllAssignmentsTabContainer from './AssignmentsTabContainer';
import AssignmentCardCompleteList from './AssignmentCardCompleteList';

export interface CompleteAssignmentsTabProps {
  learningUnits: Array<AssignmentTypes>;
  assignmentTypeFilter: string;
}

const CompleteAssignmentsTab: React.FC<CompleteAssignmentsTabProps> = ({
  learningUnits,
  assignmentTypeFilter
}) => {
  const [allLearningUnits, setAllLearningUnits] =
    useState<Array<AssignmentTypes>>();
  const [learningUnitsToShow, setAllLearningUnitsToShow] =
    useState<Array<AssignmentTypes>>();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    if (learningUnits) {
      setAllLearningUnits(learningUnits);
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        learningUnits
      );
      setAllLearningUnitsToShow(filteredLearningUnits);
    }
  }, [learningUnits]);

  useEffect(() => {
    if (allLearningUnits) {
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        allLearningUnits
      );
      setAllLearningUnitsToShow(filteredLearningUnits);
    }
  }, [assignmentTypeFilter]);

  const filterByAssignmentType = (
    assignmentType: string,
    source: Array<AssignmentTypes>
  ) => {
    return source.filter(
      learningUnit =>
        learningUnit.assignmentType === assignmentType || assignmentType === ''
    );
  };

  return (
    <AllAssignmentsTabContainer
      isLoading={!learningUnitsToShow}
      isEmpty={!!allLearningUnits && !allLearningUnits.length}
      listIsReady={!!learningUnitsToShow && !!learningUnitsToShow.length}
      emptyText={t('youDontHaveAssignments', { ns: 'assignments' })}
    >
      <AssignmentCardCompleteList
        assignedLearningUnits={learningUnitsToShow!}
      />
    </AllAssignmentsTabContainer>
  );
};

export default CompleteAssignmentsTab;

import { CSSProperties, useState, useContext, useEffect, FC } from 'react';
import { useTheme, Grid, Box, Container } from '@mui/material';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { useTranslation } from 'react-i18next';
import FormControl from '@mui/material/FormControl';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  UserClaims,
  UserRoles,
  DefaultSearchDebounceTime
} from '../../core/constants';
import { useLesson } from '../lessons/useLesson';
import { CompanyCourseContentLibrary } from './Company/CompanyCourseContentLibrary';
import { CompanyLearningPlanContentLibrary } from './Company/CompanyLearningPlanContentLibrary';
import CompanyLessonContentLibrary from './Company/CompanyLessonContentLibrary';
import ImportCourses from './Company/ImportCourses';
import LearningUnitTypeFilter from './common/LearningUnitTypeFilter';
import ContentStatusFilter from './common/ContentStatusFilter';
import LangContentFilter from './common/LangContentFilter';
import SortContentSelect from './common/SortContentSelect';
import { UserNavigationContext } from './UserNavigationContext';
import SearchInput from './common/SearchInput';
import useLearningUnitFilter from './hooks/useLearningUnitFilter';
import useLearningUnitStatusFilter from './hooks/useLearningUnitStatusFilter';
import useLearningUnitSorting from './hooks/useLearningUnitSorting';
import useLearningUnitLangFilter from './hooks/useLearningUnitLangFilter';
import { useDebounce } from '../../hooks/useDebounce';
import { useLogEvent } from '../../hooks/useLogEvent';
import useLearningUnitTag from './hooks/useLearningUnitTag';
import { TaggingField } from '../../components/TagInput/TaggingField';
import useLearningUnitTagFilter from './hooks/useLearningUnitTagFilter';

interface CompanyUserNewProps {
  encodedLessonId: string;
}

export const CompanyUserNew: FC<CompanyUserNewProps> = ({
  encodedLessonId
}) => {
  const user = useCurrentUser();
  const [userHasRole] = useUserRole();
  const logEvent = useLogEvent();
  const companyId = user?.profile[UserClaims.CompanyId] as string;

  const userCompanyId = user?.profile[UserClaims.CompanyId] as string;

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );
  const takenTimesLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLearningPlans
  );
  const companyLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allLessons
  );
  const companyCourses = useSelector(
    (state: ApplicationState) => state.courses?.courses
  );

  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;

  const theme = useTheme();
  const { t } = useTranslation([
    'contentLibrary',
    'common',
    'courses',
    'lessons',
    'learningPlans'
  ]);

  const [learningUnitType, handleChangeLearningUnitType] =
    useLearningUnitFilter();

  const [filterLearningUnitStatus, handleChangeLearningUnitStatus] =
    useLearningUnitStatusFilter();

  const [activeSort, handleChangeSorting] = useLearningUnitSorting();

  const [langFilter, handleChangeLangFilter] = useLearningUnitLangFilter();

  const [tagFilter, handleChangeLearningUnitTag] = useLearningUnitTagFilter();

  const [searchTerm, setSearchTerm] = useState<string>(
    userNavigation?.search ?? ''
  );

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  const tags = useLearningUnitTag(
    learningUnitType,
    companyLessons,
    companyCourses
  );

  const [showImportCoursesModal, setShowImportCoursesModal] =
    useState<boolean>(false);

  const {
    state: { refresh }
  } = useLesson(false);

  useEffect(() => {
    if (searchTerm) {
      logEvent.logLibrarySearchEvent(searchTerm);
    }
  }, [searchTerm]);

  const handleChangeLearningUnitTypeEvent = (items: string[]) => {
    handleChangeLearningUnitTag([]);
    handleChangeLearningUnitType(items);
  };

  const screenSizeMatch = useMediaQuery('(max-width:640px)');
  const screenSizeMatchContainer = useMediaQuery('(max-width:1080px)');
  return (
    <>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Container
          style={{
            padding: 0,
            margin: '0 0',
            display: 'flex',
            maxWidth: '100%',
            justifyContent: screenSizeMatchContainer
              ? 'space-around'
              : 'space-between'
          }}
        >
          <Grid
            container
            display={'flex'}
            justifyContent={
              screenSizeMatchContainer ? 'space-between' : 'start'
            }
            spacing={screenSizeMatch ? 1 : 2}
            width={'100%'}
          >
            <Grid item>
              <FormControl
                variant="standard"
                style={{ width: screenSizeMatch ? '165px' : '200px' }}
              >
                <LearningUnitTypeFilter
                  value={learningUnitType}
                  onChange={handleChangeLearningUnitTypeEvent}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl
                variant="standard"
                style={{ width: screenSizeMatch ? '165px' : '200px' }}
              >
                <SortContentSelect
                  value={activeSort}
                  onChange={handleChangeSorting}
                />
              </FormControl>
            </Grid>
            <Grid item width={screenSizeMatch ? '175px' : '216px'}>
              <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
            </Grid>
            {learningUnitType !== 'lessons' &&
              userHasRole(UserRoles.CompanyAdmin) && (
                <Grid item>
                  <FormControl
                    variant="standard"
                    style={{ width: screenSizeMatch ? '165px' : '200px' }}
                  >
                    <ContentStatusFilter
                      value={filterLearningUnitStatus}
                      onChange={handleChangeLearningUnitStatus}
                    />
                  </FormControl>
                </Grid>
              )}
            {learningUnitType !== 'learningPlans' && (
              <>
                <Grid item>
                  <FormControl
                    variant="standard"
                    style={{ width: screenSizeMatch ? '165px' : '200px' }}
                  >
                    <LangContentFilter
                      value={langFilter}
                      onChange={handleChangeLangFilter}
                    />
                  </FormControl>
                </Grid>
                <Grid item sx={{ width: { xs: '100%', sm: 'unset' } }}>
                  <FormControl
                    variant="standard"
                    sx={{
                      width: { xs: '100%', sm: '400px' }
                    }}
                  >
                    <TaggingField
                      options={tags}
                      value={tagFilter}
                      onChange={handleChangeLearningUnitTag}
                      showAddTagsLabel={false}
                      allowNewTags={false}
                      minHeight="45px"
                      placeholder={t('searchTagsHere', {
                        ns: 'contentLibrary'
                      })}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            {userHasRole(UserRoles.CompanyAdmin) && (
              <Grid item>
                <BasicButton
                  style={buttonStyle}
                  width={screenSizeMatch ? '165px' : '200px'}
                  height="45px"
                  color="primary"
                  onClick={() => setShowImportCoursesModal(true)}
                >
                  {t('importCourses', { ns: 'contentLibrary' })}
                </BasicButton>
              </Grid>
            )}
          </Grid>
        </Container>
        {learningUnitType === 'courses' && (
          <CompanyCourseContentLibrary
            filterContent={filterLearningUnitStatus}
            filterOrder={activeSort}
            filterLanguageOption={langFilter}
            searchFilter={searchTerm}
            userCompanyId={userCompanyId}
            takenTimesCourses={takenTimesCourses}
            filterTags={tagFilter}
          />
        )}
        {learningUnitType === 'lessons' && (
          <CompanyLessonContentLibrary
            filterOrder={activeSort}
            filterLanguageOption={langFilter}
            searchFilter={searchTerm}
            takenTimesLessons={takenTimesLessons}
            encodedLessonId={encodedLessonId}
            filterTags={tagFilter}
          />
        )}

        {learningUnitType === 'learningPlans' && (
          <CompanyLearningPlanContentLibrary
            filterContent={filterLearningUnitStatus}
            filterOrder={activeSort}
            searchFilter={searchTerm}
            refresh={refresh}
            companyId={companyId}
            userCompanyId={userCompanyId}
            takenTimesLearningPlans={takenTimesLearningPlans}
          />
        )}
      </Box>
      <ImportCourses
        companyId={companyId}
        showModal={showImportCoursesModal}
        setShowModal={setShowImportCoursesModal}
      />
    </>
  );
};

const buttonStyle: CSSProperties = {
  minWidth: '150px'
};

import React, { useState, CSSProperties, useEffect } from 'react';
import { Question, Answer, Option } from '../../entities/Assessment';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export interface MultipleChoiceQuestionProps {
  question: Question;
  questionResult?: Answer;
  setAssessmentResult: (answer: Answer) => void;
}

const imgStyles: CSSProperties = {
  marginBottom: '20px',
  height: '200px',
  objectFit: 'contain',
  marginLeft: '-15px'
};

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  question,
  questionResult,
  setAssessmentResult
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['assessments']);
  const hasValidImg = !!question.imageUrl;

  const [selectionKey, setSelectionKey] = useState<string | undefined>(
    questionResult?.selectedOptionId
  );

  useEffect(() => {
    if (questionResult) {
      if (question && question.options) {
        setSelectionKey(questionResult?.selectedOptionId);
      }
    }
  }, [questionResult]);

  const isIncorrectStyle: CSSProperties = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderColor: theme.palette.error.light
  };

  const isCorrectStyle: CSSProperties = {
    border: '1px solid rgba(0, 0, 0, 0.05)',
    borderColor: theme.palette.success.light
  };

  const selectableItemsStyle: CSSProperties = {
    borderRadius: '5px',
    padding: '24px',
    backgroundColor: '#3e3e3e',
    flex: 1,
    color: 'white'
  };

  const container: CSSProperties = {
    display: 'flex',
    flexDirection: hasValidImg ? 'row' : 'column'
  };

  const boxWithImages: CSSProperties = {
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '45%'
  };

  const boxWithoutImages: CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap'
  };

  const imageAndTitleBox: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    width: '55%'
  };

  const answerMessageStyle: CSSProperties = {
    color: theme.palette.common.white,
    fontSize: '14px'
  };

  const buttonContainer: CSSProperties = {
    width: hasValidImg ? '100%' : '50%',
    padding: hasValidImg ? '0px 0px 14px 14px' : '20px 20px 0px 0px'
  };

  const questionTextStyle: CSSProperties = {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '155%'
  };

  const answerStyle: CSSProperties = {
    color: '#FBFBFB',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '110.5%'
  };

  const handleSelection = (
    event: React.MouseEvent<HTMLDivElement>,
    selectedOption: Option
  ) => {
    if (questionResult) return;

    setSelectionKey(selectedOption.id);

    setAssessmentResult({
      questionId: question.id,
      isCorrect: selectedOption.isCorrect,
      type: question && question.type ? question.type : '',
      selectedOptionId: selectedOption?.id,
      order: question?.order
    });
  };

  return (
    <>
      <div style={container}>
        {hasValidImg ? (
          <div style={imageAndTitleBox}>
            <img
              style={imgStyles}
              src={question.imageUrl}
              alt="Img for question"
            />
            <Typography style={questionTextStyle}>
              {question.questionText}
            </Typography>
          </div>
        ) : (
          <div>
            <Typography style={questionTextStyle}>
              {question.questionText}
            </Typography>
          </div>
        )}
        <div style={hasValidImg ? boxWithImages : boxWithoutImages}>
          {question.options?.map((answer: any) => {
            let finalStyle = { ...selectableItemsStyle };
            if (answer.id == selectionKey) {
              if (answer.isCorrect) {
                finalStyle = { ...finalStyle, ...isCorrectStyle };
              } else {
                finalStyle = { ...finalStyle, ...isIncorrectStyle };
              }
            }
            return (
              <div style={buttonContainer}>
                <div
                  style={finalStyle}
                  key={answer.id}
                  onClick={e => handleSelection(e, answer)}
                >
                  <Typography style={answerStyle}>
                    {answer.optionText}
                  </Typography>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {questionResult && (
        <div style={answerMessageStyle}>
          {questionResult.isCorrect
            ? t('correctAnswer', { ns: 'assessments' })
            : `${t('correctAnswerIs', { ns: 'assessments' })} ` +
              question.options?.find(option => option.isCorrect)?.optionText}
        </div>
      )}
    </>
  );
};

export default MultipleChoiceQuestion;

import React, { useState, useEffect, CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams
} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import SearchBox from '../../components/core/SearchBox/SearchBox';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { actionCreators as employeeActions } from '../../actions/employees';
import { ApplicationState } from '../../store';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { EmployeeAssignmentsStatus } from '../../entities/Employee';
import { UserRoles } from '../../core/constants';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import { lowerCaseFirstLetter } from '../../utils/stringUtils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ManageEmployeeAssignmentsModal from '../../components/ManageEmployeeAssignmentsModal';
import { InfoBubble } from '../../components/InfoBubble/InfoBubble';
import config from '../../config';
import SupervisorFilter from '../../components/CommonFilters/SupervisorFilter';
import useUserRole from '../../hooks/useUserRole';
import useCommonFilters from '../../components/CommonFilters/useCommonFilters';
import GroupFilter from '../../components/CommonFilters/GroupFilter';
import { Col, Row } from 'react-bootstrap';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import { downloadAssignmentsStatusReport } from '../../services/report-service';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../components/CustomDataGrid/CustomDataGrid';

const LearningTable: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation([
    'common',
    'lessons',
    'courses',
    'learningPlans',
    'employees',
    'assignments',
    'quizzes'
  ]);
  const dispatch = useAppDispatch();
  const [userHasRole] = useUserRole();
  const [selectedAssignmentType, setSelectedAssignmentType] =
    useState<string>('Lessons');
  const [selectedEmployeeRow, setSelectedEmployeeRow] =
    useState<EmployeeAssignmentsStatus>();
  const [exportingReport, setExportingReport] = useState<boolean>(false);
  const [includeAllDataToExport, setIncludeAllDataToExport] =
    useState<boolean>(false);
  const {
    supervisorItems,
    supervisorFilterValue,
    groupFilterValue,
    groupItems,
    handleChangeGroup,
    handleChangesupervisor,
    makeFilterValueEmptyIfDefault
  } = useCommonFilters();

  const clearSelectedEmployee = () => setSelectedEmployeeRow(undefined);
  const [dataSource, setDataSource] = useState<EmployeeAssignmentsStatus[]>([]);
  const employeeAssignmentsStatus = useSelector(
    (state: ApplicationState) => state?.employees?.employeeAssignmentsStatus
  );
  const dataIsLoading = useSelector(
    (state: ApplicationState) => state?.employees?.dataIsLoading
  );
  let searchTimeout: any = null;

  useEffect(() => {
    dispatch(
      employeeActions.requestEmployeeAssignmentsStatus(
        lowerCaseFirstLetter(selectedAssignmentType),
        makeFilterValueEmptyIfDefault(groupFilterValue),
        makeFilterValueEmptyIfDefault(supervisorFilterValue)
      )
    );
  }, [selectedAssignmentType, supervisorFilterValue, groupFilterValue]);

  useEffect(() => {
    if (employeeAssignmentsStatus) {
      setDataSource(employeeAssignmentsStatus);
    }
  }, [employeeAssignmentsStatus]);

  const entityTypes = [
    { label: t('lessons', { ns: 'lessons' }), value: 'Lessons' },
    { label: t('courses', { ns: 'courses' }), value: 'Courses' },
    {
      label: t('learningPlans', { ns: 'learningPlans' }),
      value: 'LearningPlans'
    },
    { label: t('quizzes', { ns: 'quizzes' }), value: 'Quizzes' }
  ];

  const columns: GridColDef[] = [
    {
      field: 'employeeId',
      flex: 1,
      minWidth: 350,
      renderHeader: (params: GridColumnHeaderParams<any, string>) => {
        return (
          <>
            {t('employee', { ns: 'employees' })}
            <InfoBubble
              placement="right"
              isSmall={true}
              text={t('employeeColumnHeaderTooltip', { ns: 'employees' })}
            />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div className="d-flex ps-1">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../assets/user.png')
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: theme.palette.grey[100] }}>
              {params.row.jobTitle}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'assigned',
      headerName: t(`totalAssigned${selectedAssignmentType}`, {
        ns: 'assignments'
      }),
      minWidth: 220,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span style={dataGridColumnStyle}>{params.value}</span>
      )
    },
    {
      field: 'overdue',
      headerName: t(`overdue${selectedAssignmentType}`, { ns: 'assignments' }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span style={dataGridOverdueColumnStyle}>{params.value}</span>
      )
    },
    {
      field: 'completed',
      headerName: t(`completedAssignments`, {
        ns: 'assignments'
      }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span style={dataGridColumnStyle}>{params.value}</span>
      )
    },
    {
      field: 'completedTrainingItems',
      headerName: t(`completed${selectedAssignmentType}`, {
        ns: 'assignments'
      }),
      minWidth: 170,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span style={dataGridColumnStyle}>{params.value}</span>
      )
    },
    {
      field: '',
      headerName: '',
      minWidth: 170,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<any, number, EmployeeAssignmentsStatus>
      ) => {
        return (
          <BasicButton
            color="secondary"
            endIcon={<ArrowForwardIcon />}
            onClick={() => setSelectedEmployeeRow(params.row)}
          >
            {t(`manage${selectedAssignmentType}`, { ns: 'assignments' })}
          </BasicButton>
        );
      }
    }
  ];

  const handleSearch = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const searchResult = employeeAssignmentsStatus?.filter(employee =>
        `${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`.includes(
          value.toLowerCase()
        )
      );
      setDataSource(searchResult ?? []);
    }, 1000);
  };

  const handleChangeAssignmentType = (items: string[]) => {
    setSelectedAssignmentType(items[0]);
  };

  const handleRefreshData = () => {
    dispatch(
      employeeActions.requestEmployeeAssignmentsStatus(
        lowerCaseFirstLetter(selectedAssignmentType),
        makeFilterValueEmptyIfDefault(groupFilterValue),
        makeFilterValueEmptyIfDefault(supervisorFilterValue)
      )
    );
  };

  const handleDownloadExcel = async () => {
    try {
      setExportingReport(true);
      await downloadAssignmentsStatusReport(
        includeAllDataToExport,
        lowerCaseFirstLetter(selectedAssignmentType),
        makeFilterValueEmptyIfDefault(groupFilterValue),
        makeFilterValueEmptyIfDefault(supervisorFilterValue)
      );
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'warning' });
    } finally {
      setExportingReport(false);
    }
  };

  return (
    <>
      <Grid
        container
        sx={containerStyle}
        columns={{ xs: 1, md: 6, lg: 6, xl: 6 }}
      >
        <Grid item xs={1} md={3} lg={2} xl={2} sx={gridSearchStyle}>
          <SearchBox
            id="searchEmployees"
            name="searchEmployees"
            value=""
            onChangeValue={handleSearch}
            placeholderText={t('search', { ns: 'common' })}
            width="100%"
            height="45px"
          />
        </Grid>
        <Grid item xs={1} md={2} lg={2} xl={1} sx={gridSelecteStyle}>
          <BasicSelect
            labelId="lessons"
            id="lessons"
            options={entityTypes}
            defaultValue=""
            value={[selectedAssignmentType]}
            handleOnChange={handleChangeAssignmentType}
            style={selectStyle}
            theme={'dark'}
            multiple={false}
            disabled={dataIsLoading}
            sx={{ backgroundColor: 'transparent' }}
          />
        </Grid>

        {userHasRole([
          UserRoles.CompanyAdmin,
          UserRoles.GroupLead,
          UserRoles.BuildWittAdmin
        ]) && (
          <Grid item xs={1} md={2} lg={2} xl={1} sx={gridSelecteStyle}>
            <GroupFilter
              items={groupItems}
              filterValue={groupFilterValue}
              onChange={items => {
                handleChangeGroup(items);
              }}
              isDisabled={dataIsLoading}
            />
          </Grid>
        )}
        {config.SUPERVISORS_FILTER && (
          <Grid item xs={1} md={2} lg={2} xl={1} sx={gridSelecteStyle}>
            <SupervisorFilter
              items={supervisorItems}
              filterValue={supervisorFilterValue}
              onChange={items => {
                handleChangesupervisor(items);
              }}
              isDisabled={dataIsLoading}
            />
          </Grid>
        )}
        <Grid item xs></Grid>
        <Grid item xs={'auto'} sx={gridButtonStyle}>
          <BasicButton
            color="secondary"
            style={buttonStyle}
            onClick={handleRefreshData}
          >
            <Typography sx={fontButton}>
              {t('refreshData', { ns: 'common' })}
            </Typography>
          </BasicButton>
        </Grid>
      </Grid>
      <Grid item>
        <Box
          sx={{
            height: '50vh',
            width: '100%',
            border: `1px solid ${theme.palette.grey[100]}`,
            backgroundColor: theme.palette.grey.A200,
            borderRadius: '8px'
          }}
        >
          {!dataIsLoading && dataSource.length > 0 && (
            <ModifiedCustomDataGrid
              getRowId={row => row.employeeId}
              rows={dataSource}
              columns={columns}
              pageSizeOptions={[10]}
              sortingOrder={['desc', 'asc']}
              initialState={{
                sorting: {
                  sortModel: [
                    {
                      field: 'assigned',
                      sort: 'desc'
                    }
                  ]
                },
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              getRowClassName={getCustomRowClassName}
              rowSelection={false}
              disableColumnMenu
              components={{
                ColumnSortedDescendingIcon: () => {
                  return <ArrowDropDownIcon height="5px" width="10px" />;
                },
                ColumnSortedAscendingIcon: () => {
                  return <ArrowDropUpIcon height="5px" width="10px" />;
                }
              }}
            />
          )}
          {!dataIsLoading && dataSource.length === 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <span>{t('noRecords', { ns: 'common' })}</span>
            </Box>
          )}
          {dataIsLoading && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="50vh"
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Grid>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} style={bottomButtonContainer}>
          <FormCheckBox
            label={t('includeAllData', { ns: 'employees' })}
            checked={includeAllDataToExport}
            onChange={e => setIncludeAllDataToExport(e.target.checked)}
            disabled={false}
          />
          <BasicButton
            color="secondary"
            style={buttonExportExcelStyle}
            loading={exportingReport}
            onClick={handleDownloadExcel}
          >
            {t('exportAssignmentsStatustoExcel', {
              ns: 'employees'
            })}
          </BasicButton>
        </Col>
      </Row>
      <ManageEmployeeAssignmentsModal
        assignmentType={selectedAssignmentType}
        employeeRow={selectedEmployeeRow}
        clearSelectedEmployeeId={clearSelectedEmployee}
        refreshData={handleRefreshData}
      />
    </>
  );
};

const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeadersInner .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator':
    {
      display: 'none'
    }
}));

const containerStyle: SxProps = {
  paddingBottom: '1.5rem'
};

const gridSelecteStyle: SxProps = {
  paddingBottom: {
    sm: '0.5rem'
  },
  paddingLeft: {
    md: '0.5rem'
  }
};

const gridSearchStyle: SxProps = {
  paddingBottom: {
    sm: '0.5rem'
  }
};

const gridButtonStyle: SxProps = {
  textAlign: {
    xs: 'start',
    lg: 'end'
  }
};

const selectStyle: CSSProperties = {
  minWidth: '200px',
  height: '45px'
};

const buttonExportExcelStyle: CSSProperties = {
  height: '45px'
};

const bottomButtonContainer: CSSProperties = {
  textAlign: 'right',
  marginTop: '20px'
};

const dataGridColumnStyle: CSSProperties = {
  fontSize: '20px'
};

const dataGridOverdueColumnStyle: CSSProperties = {
  ...dataGridColumnStyle,
  color: 'rgba(255, 42, 32, 0.87)'
};

export default LearningTable;

import { SxProps } from '@mui/material/styles';
import BasicSelect from '../core/BasicSelect/BasicSelect';
import { useTranslation } from 'react-i18next';
import React from 'react';

export interface LastDaysFilterProps {
  filterValue: string;
  onChange: (items: string[]) => void;
  isDisabled?: boolean;
}

const LastDaysFilter: React.FC<LastDaysFilterProps> = ({
  onChange,
  filterValue,
  isDisabled
}) => {
  const { t } = useTranslation(['dashboard']);
  const value = filterValue ? [filterValue] : [];

  return (
    <BasicSelect
      labelId="lastDaysFilter"
      id="lastDaysFilter"
      options={[
        {
          label: t('last90days', { ns: 'dashboard' }),
          value: '90',
          disabled: false,
          hidden: false
        },
        {
          label: t('last60days', { ns: 'dashboard' }),
          value: '60',
          disabled: false,
          hidden: false
        },
        {
          label: t('last30days', { ns: 'dashboard' }),
          value: '30',
          disabled: false,
          hidden: false
        },
        {
          label: t('all', { ns: 'dashboard' }),
          value: '0',
          disabled: false,
          hidden: false
        }
      ]}
      value={value}
      handleOnChange={onChange}
      placeholder="last days"
      theme="dark"
      multiple={false}
      sx={filterStyle}
      disabled={isDisabled}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: 'transparent',
  minWidth: '200px',
  height: '45px'
};

export default LastDaysFilter;

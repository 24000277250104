import { CSSProperties } from 'react';
import { SelfAssignButton } from '../SelfAssignButton/SelfAssignButton';

interface ButtonsSelfAssignSectionProps {
  onClickStart: () => void;
  startButtonCaption: string;
  startButtonColor:
    | 'primary'
    | 'secondary'
    | 'alternative'
    | 'delete'
    | 'confirm'
    | 'revisit'
    | 'grayButton'
    | 'trial';
  resourceType: 'Lesson' | 'Course' | 'Learning Plan';
  id: string;
  companyId: string;
  title: string;
}

const ButtonsSelfAssignSection: React.FC<ButtonsSelfAssignSectionProps> = ({
  onClickStart,
  startButtonCaption,
  startButtonColor,
  resourceType,
  companyId,
  title,
  id
}) => {
  return (
    <div>
      <SelfAssignButton
        type={resourceType}
        style={{ ...selfAssignButtonStyle }}
        id={id}
        companyId={companyId}
        title={title}
        width="105px"
        height="35px"
      />
    </div>
  );
};

const selfAssignButtonStyle: CSSProperties = {
  minWidth: '0'
};

export default ButtonsSelfAssignSection;

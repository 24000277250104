import React, { CSSProperties } from 'react';
import moment from 'moment';
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Theme,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { Notification } from '../../entities/Notification';
import { NotificationType } from '../../core/constants';
import { DailyOverseerSummaryNotification } from './DailyOverseerSummaryNotification';
import { WeeklyOverseerSummaryNotification } from './WeeklyOverseerSummaryNotification';

interface NotificationItemProps {
  notification: Notification;
  onDismissNotification: (notificationId: string) => void;
}

const resourceNameStyle: CSSProperties = {
  color: '#fff',
  textDecoration: 'underline'
};

const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  onDismissNotification
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const date = moment(notification.createdTimestampUtc);

  const timestampStyle: CSSProperties = {
    fontSize: '11px',
    color: '#999999'
  };

  const onNotificationClick = () => {
    navigate('/assignments');
  };

  const clickableAreaStyle: CSSProperties = {
    cursor: 'pointer'
  };

  return (
    <>
      <Card sx={{ maxWidth: 345 }}>
        <CardContent>
          <Stack>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item style={timestampStyle}>
                {date.fromNow()}
              </Grid>
              <Grid item>
                <div>
                  <IconButton
                    aria-label="dismiss-notification"
                    size="small"
                    onClick={() => onDismissNotification(notification.id)}
                    sx={{
                      color: '#999999',
                      '&.MuiIconButton-root': {
                        padding: '0'
                      }
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
            <div
              onClick={() => onNotificationClick()}
              style={clickableAreaStyle}
            >
              <div>{getBody(notification)}</div>
              <div>{getFooter(notification, theme)}</div>
            </div>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};

export default NotificationItem;

const getTotalOverdueAssignments = (payload: any): number => {
  return Object.keys(payload).reduce(
    (previousValue, currentKey) =>
      previousValue +
      (Number.isInteger(payload[currentKey]) ? payload[currentKey] : 0),
    0
  );
};

const getBody = (notification: Notification) => {
  switch (notification.notificationType) {
    case NotificationType.NewAssignment:
      return (
        <Typography variant="body2" color="#C4C4C4">
          You were assigned the{' '}
          <span style={resourceNameStyle}>
            {' '}
            {notification.payload.AssignmentType}{' '}
            {notification.payload.AssignmentTitle}
          </span>
        </Typography>
      );
    case NotificationType.PendingWorkload:
      return (
        <Typography variant="body2" color="#C4C4C4">
          You have{' '}
          <span style={resourceNameStyle}>
            {notification.payload.PendingWorkloadCount}
          </span>{' '}
          pending workload tasks
        </Typography>
      );
    case NotificationType.OverdueAssignments:
      return (
        <Typography variant="body2" color="#C4C4C4">
          You have{' '}
          <span style={resourceNameStyle}>
            {getTotalOverdueAssignments(notification.payload)}
          </span>{' '}
          overdue assignments
        </Typography>
      );
    case NotificationType.DailyOverseerSummary:
      return (
        <DailyOverseerSummaryNotification
          payload={notification.payload}
          overseerScope={notification.payload.OverseerScope}
        />
      );
    case NotificationType.WeeklyOverseerSummary:
      return (
        <WeeklyOverseerSummaryNotification
          overseerScope={notification.payload.OverseerScope}
          inactiveUsersCount={notification.payload.InactiveUsersCount}
        />
      );

    default:
      return null;
  }
};

const getFooter = (notification: Notification, theme: Theme) => {
  const dueDateStyle: CSSProperties = {
    color: theme.palette.primary.main
  };

  const footerStyle: CSSProperties = {
    fontSize: '13px',
    color: '#999999'
  };

  switch (notification.notificationType) {
    case NotificationType.NewAssignment:
      return (
        <Typography variant="body2" color="#C4C4C4" style={footerStyle}>
          Due on{' '}
          <span style={dueDateStyle}>
            {moment(notification.payload.dueDate).format('MM/DD/YYYY')}
          </span>
        </Typography>
      );
    default:
      return null;
  }
};

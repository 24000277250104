import OverdueModal from './OverdueModal';
import PendingCompletionModal from './PendingCompletionModal';
import CompletedModal from './CompletedModal';
import PendingCompletionModalQuiz from '../quizzes/PendingCompletionModal';
import CompletedModalQuiz from '../quizzes/CompletedModal';

interface ModalFactoryProps {
  modalType: string;
  learningUnitId?: string;
  learningUnitType: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onCloseModal: () => void;
}

const ModalFactory = ({
  modalType,
  learningUnitId,
  learningUnitType,
  includeSelfAssignments,
  filterOption,
  onCloseModal
}: ModalFactoryProps) => {
  switch (modalType) {
    case 'overdues':
      return (
        <OverdueModal
          learningUnitId={learningUnitId}
          learningUnitType={learningUnitType}
          includeSelfAssignments={includeSelfAssignments}
          filterOption={filterOption}
          onCloseModal={onCloseModal}
        />
      );
    case 'pendingCompletions':
      return (
        <PendingCompletionModal
          learningUnitId={learningUnitId}
          learningUnitType={learningUnitType}
          includeSelfAssignments={includeSelfAssignments}
          filterOption={filterOption}
          onCloseModal={onCloseModal}
        />
      );
    case 'completed':
      return (
        <CompletedModal
          learningUnitId={learningUnitId}
          learningUnitType={learningUnitType}
          includeSelfAssignments={includeSelfAssignments}
          filterOption={filterOption}
          onCloseModal={onCloseModal}
        />
      );
    case 'pendingCompletionQuiz':
      return (
        <PendingCompletionModalQuiz
          quizLessonId={learningUnitId}
          includeSelfAssignments={includeSelfAssignments}
          filterOption={filterOption}
          onCloseModal={onCloseModal}
        />
      );
    case 'completedQuiz':
      return (
        <CompletedModalQuiz
          quizLessonId={learningUnitId}
          includeSelfAssignments={includeSelfAssignments}
          filterOption={filterOption}
          onCloseModal={onCloseModal}
        />
      );
    default:
      return <></>;
  }
};

export default ModalFactory;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import { Tag } from '../entities/Tag';
import { Employee } from '../entities/Employee';

export const useCertifications = () => {
  const { t } = useTranslation(['certifications', 'common']);
  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );

  const getEmployeeName = (employee: Employee) => {
    const name = employee.email?.length ? employee.email : employee.phoneNumber;
    return name ?? '';
  };

  const employeeTagNames = useMemo(() => {
    if (activeEmployees) {
      return activeEmployees.map((employee): Tag => {
        const name = employee.firstName.length
          ? `${employee.firstName} ${employee.lastName}`
          : getEmployeeName(employee);

        return {
          id: employee.id,
          name
        };
      });
    }
  }, [activeEmployees]);

  const durations = useMemo(() => {
    const baseArray = Array.from({ length: 24 }, (_, i) => i + 1);
    return baseArray.map(item => ({
      label: item.toString(),
      value: item.toString()
    }));
  }, []);

  const durationUnits = [
    {
      label: t('years', { ns: 'skills' }),
      value: 'Year'
    },
    {
      label: t('months', { ns: 'skills' }),
      value: 'Month'
    }
  ];

  const notificationDaysTags: Tag[] = [
    { id: '7', name: '7 days before' },
    { id: '30', name: '30 days before' },
    { id: '90', name: '90 days before' }
  ];

  return {
    constants: {
      durationUnits,
      durations,
      notificationDaysTags,
      employeeTagNames
    }
  };
};

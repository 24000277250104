import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AzureBlobImageInfo } from '../entities/AzureBlobInfo';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';

import {
  OnsiteTraining,
  OnsiteTrainingFieldsValidations
} from '../entities/OnsiteTraining';
import { actionCreators as tagActions } from '../actions/tags';
import { actionCreators as onsiteTrainingActions } from '../actions/onsiteTrainings';
import { ApplicationState } from '../store';
import { validImageFormat } from '../utils/validationsUtils';
import { Tag } from '../entities/Tag';
import { useAppDispatch } from '../store/hooks';

export const useOnsiteTraining = (
  actionType: 'create' | 'edit',
  onsiteTrainingToEdit?: OnsiteTraining
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['common', 'onsiteTraining']);
  const onsiteTrainingInitialValue: OnsiteTraining = useMemo(() => {
    if (actionType === 'create') {
      return {
        id: '',
        name: '',
        description: '',
        hideAttachments: false,
        requireApprovalForAward: true,
        requireSignatureForAttendance: true,
        requireSignatureForCompletion: true,
        trainingDays: 1,
        thumbnail: '',
        isDraft: true,
        tags: [],
        attachments: [],
        filesToAttach: []
      };
    }

    return onsiteTrainingToEdit!;
  }, [actionType, onsiteTrainingToEdit]);
  const validationInititalValue: OnsiteTrainingFieldsValidations = {
    nameIsValid: true,
    descriptionIsValid: true,
    tagsAreValid: true,
    trainingDaysIsvalid: true,
    thumbnailIsValid: true
  };
  const [onsiteTraining, setOnsiteTraining] = useState<OnsiteTraining>(
    onsiteTrainingInitialValue
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );
  const [formValidationResult, setFormValidationResult] =
    useState<OnsiteTrainingFieldsValidations>(validationInititalValue);
  const tagsLoading = useSelector(
    (state: ApplicationState) => state.tags?.loading
  );
  const [attachmentNames, setAttachmentNames] = useState<string[]>(
    onsiteTraining.attachments.map(attachment => attachment.fileName)
  );

  useEffect(() => {
    dispatch(tagActions.requestTags());
  }, []);

  const updateEntityFieldValue = (fieldName: string, value: any) => {
    const entity = { ...onsiteTraining };
    entity[fieldName as keyof OnsiteTraining] = value as never;
    setOnsiteTraining(entity);
  };

  const handleChangeTextBox =
    (fieldName: string, isNumber = false) =>
    (value: any) => {
      const numericValue: number =
        (isNumber && isNaN(value)) || !(value as string).trim().length
          ? 0
          : new Number(value).valueOf();
      updateEntityFieldValue(fieldName, isNumber ? numericValue : value);
    };

  const handleDropDownChange = (fieldName: string) => (items: string[]) => {
    updateEntityFieldValue(fieldName, items[0] == 'yes');
  };

  const handleCheckBoxChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setOnsiteTraining(previous => ({ ...previous, hideAttachments: checked }));
  };

  const handleUpdatedFiles = (fileName: string, file: File) => {
    setAttachmentNames(previous => [...previous, fileName]);
    const copyFilesToAttach = onsiteTraining.filesToAttach ?? [];
    copyFilesToAttach.push(file);
    setOnsiteTraining(previous => ({
      ...previous,
      filesToAttach: copyFilesToAttach
    }));
  };

  const handleRemoveFile = (index: number) => {
    const fileNameToRemove = attachmentNames[index];
    const filteredExistingAttachments = onsiteTraining.attachments.filter(
      attachment => attachment.fileName !== fileNameToRemove
    );
    const filteredExistingFiles = onsiteTraining.filesToAttach
      ? onsiteTraining.filesToAttach.filter(
          file => file.name !== fileNameToRemove
        )
      : [];
    const updatedAttachmentNames = attachmentNames.slice();
    updatedAttachmentNames.splice(index, 1);
    setAttachmentNames(updatedAttachmentNames);
    setOnsiteTraining(previous => ({
      ...previous,
      attachments: filteredExistingAttachments,
      filesToAttach: filteredExistingFiles
    }));
  };

  const handleTagging = (selectedTags: Tag[]) => {
    setOnsiteTraining(previous => ({ ...previous, tags: selectedTags }));
  };

  const handleThumbnailFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      if (!validImageFormat(file.type)) {
        enqueueSnackbar(t('imageInvalidFormatMsg', { ns: 'common' }), {
          variant: 'warning',
          autoHideDuration: 3000
        });
        return;
      }
      setOnsiteTraining(previous => ({
        ...previous,
        thumbnailFile: file,
        thumbnail: ''
      }));
    }
  };

  const handleLibraryThumbnailSelected = (item: AzureBlobImageInfo) => {
    if (item) {
      setOnsiteTraining(previous => ({
        ...previous,
        thumbnail: item.url,
        thumbnailFile: null
      }));
    }
  };

  const handleRemoveUploadedThumbnail = () => {
    setOnsiteTraining(previous => ({ ...previous, thumbnailFile: null }));
  };

  const handleValidationFormWhenPublishing = () => {
    const validationResult: OnsiteTrainingFieldsValidations = {
      nameIsValid: !!onsiteTraining.name.trim().length,
      descriptionIsValid: !!onsiteTraining.description.trim().length,
      tagsAreValid: !!onsiteTraining.tags.length,
      trainingDaysIsvalid:
        onsiteTraining.trainingDays > 0 &&
        Number.isInteger(onsiteTraining.trainingDays),
      thumbnailIsValid: onsiteTraining.thumbnailFile
        ? validImageFormat(onsiteTraining.thumbnailFile.type)
        : true
    };

    setFormValidationResult(validationResult);
    const isValid =
      validationResult.nameIsValid &&
      validationResult.descriptionIsValid &&
      validationResult.tagsAreValid &&
      validationResult.trainingDaysIsvalid &&
      validationResult.thumbnailIsValid;

    if (!isValid) {
      setIsSaving(false);
      enqueueSnackbar(t('validationFormErrorMsg', { ns: 'onsiteTraining' }), {
        variant: 'error',
        autoHideDuration: 4000
      });
    }
    return isValid;
  };

  const handleValidationFormWhenSavingAsDraft = () => {
    const validationResult: OnsiteTrainingFieldsValidations = {
      nameIsValid: !!onsiteTraining.name.trim().length,
      descriptionIsValid: true,
      tagsAreValid: true,
      trainingDaysIsvalid:
        onsiteTraining.trainingDays > 0 &&
        Number.isInteger(onsiteTraining.trainingDays),
      thumbnailIsValid: true
    };

    setFormValidationResult(validationResult);
    const isValid =
      validationResult.nameIsValid && validationResult.trainingDaysIsvalid;

    if (!isValid) {
      setIsSaving(false);
      enqueueSnackbar(
        t('validationFormErrorMsgWhenSavingAsDraft', { ns: 'onsiteTraining' }),
        { variant: 'error', autoHideDuration: 4000 }
      );
    }
    return isValid;
  };

  const handlePublish = async () => await handleSaveOnsiteTraining(false);
  const handleSaveAsDraft = async () => await handleSaveOnsiteTraining(true);

  const handleSaveOnsiteTraining = async (isDraft: boolean) => {
    setIsSaving(true);
    if (
      (!isDraft && !handleValidationFormWhenPublishing()) ||
      (isDraft && !handleValidationFormWhenSavingAsDraft())
    ) {
      return;
    }

    const onsiteTrainingToSave = { ...onsiteTraining };
    onsiteTrainingToSave.isDraft = isDraft;
    const isNew = !onsiteTrainingToSave.id.length;

    try {
      if (isNew)
        await dispatch<Promise<void>>(
          onsiteTrainingActions.createOnsiteTraining(onsiteTrainingToSave)
        );
      else
        await dispatch<Promise<void>>(
          onsiteTrainingActions.editOnsiteTraining(onsiteTrainingToSave)
        );

      enqueueSnackbar(
        t(
          isNew
            ? 'onsiteTrainingCreatedSuccessfully'
            : 'onsiteTrainingEditedSuccessfully',
          { ns: 'onsiteTraining' }
        ),
        { variant: 'success', autoHideDuration: 3000 }
      );
      if (location.state?.prevPath?.includes('/onsite-training-preview')) {
        navigate(`/onsite-training-preview/${onsiteTrainingToSave.id}`, {
          state: {
            onsiteTraining: onsiteTrainingToSave,
            prevPath: location.pathname
          }
        });
      }
      navigate(-1);
    } catch (e) {
      enqueueSnackbar(
        t('saveNewOnsiteTrainingError', { ns: 'onsiteTraining' }),
        { variant: 'error', autoHideDuration: 3000 }
      );
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfirmDelete = async () => {
    const onsiteTrainingToSave = { ...onsiteTraining };
    try {
      await dispatch<Promise<void>>(
        onsiteTrainingActions.deleteOnsiteTraining(onsiteTrainingToSave.id)
      );
      enqueueSnackbar(t('confirmationDeleteOnsite', { ns: 'onsiteTraining' }), {
        variant: 'success',
        autoHideDuration: 3000
      });
      navigate(-1);
    } catch (e) {
      enqueueSnackbar(t('errorDeleteOnsite', { ns: 'onsiteTraining' }), {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  return {
    const: {
      onsiteTraining,
      attachmentNames
    },
    state: {
      companyTags,
      formValidationResult,
      isSaving,
      tagsLoading
    },
    api: {
      handleChangeTextBox,
      handleUpdatedFiles,
      handleRemoveFile,
      handleCheckBoxChange,
      handleTagging,
      handleDropDownChange,
      handleLibraryThumbnailSelected,
      handleThumbnailFileChange,
      handlePublish,
      handleSaveAsDraft,
      handleRemoveUploadedThumbnail,
      handleConfirmDelete
    }
  };
};

import { useTheme } from '@mui/material/styles';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsPendingCompletionQuizAssignments } from '../../../entities/Analytics';
import { ModalDataGrid } from '../../../components/Analytics';
import {
  getFullNameColumn,
  getAssignedOnColumn,
  getDueDateColumn,
  getFormatedValueColumn
} from '../common/utilAnalyticsColumnDefinition';

interface PendingCompletionListProps {
  pedingCompletionData?: AnalyticsPendingCompletionQuizAssignments[] | null;
  onEditDueDate: (assignmentId: string, dueDate: Date) => void;
}

const GetCompletionStatus = (params: GridRenderCellParams<any>) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const { isOverdue } = params.row;
  const status = isOverdue ? 'overdue' : 'pending';

  return (
    <span
      style={{
        color: isOverdue ? theme.palette.error.main : theme.palette.primary.main
      }}
    >
      {t(status)}
    </span>
  );
};

const PendingCompletionList = ({
  pedingCompletionData,
  onEditDueDate
}: PendingCompletionListProps) => {
  const { t } = useTranslation([
    'employees',
    'analytics',
    'assignments',
    'common'
  ]);

  const getTooltipText = (params: GridRenderCellParams<any>) => {
    const { courseAssignmentId, learningPlanAssignmentId } = params.row;
    if (params.row.editableDueDate) {
      return '';
    }

    let levelName = '';

    if (learningPlanAssignmentId) {
      levelName = 'learningPlan';
    } else if (courseAssignmentId) {
      levelName = 'course';
    }

    return levelName.length
      ? t('assignedAt', {
          ns: 'analytics',
          level: t(levelName, { ns: 'assignments' })
        })
      : levelName;
  };

  const columns: GridColDef[] = (() => [
    getFullNameColumn(t('employee', { ns: 'employees' })),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getFormatedValueColumn(
      '',
      t('completionStatus', { ns: 'analytics' }),
      150,
      GetCompletionStatus,
      false
    ),
    getDueDateColumn(
      t('dueDate', { ns: 'assignments' }),
      onEditDueDate,
      '',
      t('edit', { ns: 'common' }),
      getTooltipText
    )
  ])();

  return <ModalDataGrid rows={pedingCompletionData} columns={columns} />;
};

export default PendingCompletionList;

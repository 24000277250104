import { useEffect, useContext, useState } from 'react';
import { Box, Grid, IconButton, Stack, useTheme } from '@mui/material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import GradeOverViewModalAllEmployees from '../../../components/Analytics/GradeOverviewModal/GradeOverViewModalAllEmployees';
import GoBackComponent from '../common/GoBackComponent';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { useAnalyticsQuizzes } from '../../../hooks/useAnalyticsQuizzes';
import { AnalyticsQuizzesViewType } from '../../../core/enums';
import { actionCreators as analytcsActions } from '../../../actions/analytics';
import { AnalyticsFilterContext } from '../../../contexts/AnalyticsContext';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import QuizzesDetailTable from './QuizzesDetailTable';
import { AnalyticsQuizDetail } from '../../../entities/Analytics';
import AllGradesModal from './AllGradesModal';
import GradeOverViewModalSingleUser from '../../../components/Analytics/GradeOverviewModal/GradeOverViewModalSingleUser';
import { AnalyticsCommonFilterContext } from '../../../contexts/AnalyticsContext';
import { useAppDispatch } from '../../../store/hooks';

interface QuizzesDetailProps {
  quizLessonId: string;
  quizId: string;
  quizName: string;
  onBackToQuizzes: () => void;
}

export interface AllGradesModalDataType {
  data: AnalyticsQuizDetail[] | null;
  employeeId: string;
  quizName: string;
  employeeName: string;
  quizAverage: string;
}

const QuizzesDetail = ({
  quizLessonId,
  quizId,
  quizName,
  onBackToQuizzes
}: QuizzesDetailProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const { includeSelfAssignments, activeOnly, subscribedOnly } = useContext(
    AnalyticsFilterContext
  );
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const {
    state: { sortOptions, sortOption, searchFilter, downloadingReport, data },
    api: { handleChangeSortOption, handleSearchData, handleDownloadCsvReport }
  } = useAnalyticsQuizzes(AnalyticsQuizzesViewType.Detail);

  const [allGradesModalData, setAllGradesModalData] =
    useState<AllGradesModalDataType | null>(null);
  const [modalGradeOverview, setModalGradeOverview] = useState<boolean>(false);
  const answerQuizAverage = useSelector(
    (state: ApplicationState) => state.analytics?.assessmentResultsAverage
  );
  const [employeeName, seEmployeeName] = useState<string>('');
  const [modalSingleQuiz, setModalSingleQuiz] = useState<boolean>(false);
  const quizResult = useSelector(
    (state: ApplicationState) => state.analytics?.quizResult
  );
  useEffect(() => {
    dispatch(
      analytcsActions.getQuizDetailData(
        includeSelfAssignments,
        activeOnly,
        subscribedOnly,
        quizLessonId,
        quizId,
        groupId,
        supervisorId
      )
    );

    return () => {
      dispatch(analytcsActions.setQuizDetailData(null));
    };
  }, [groupId, supervisorId]);

  const handleOpenGradeOverviewModal = () => {
    dispatch(analytcsActions.getAssessmentResultsAverage(quizId));
    setModalGradeOverview(true);
  };

  const handleCloseGradeOverviewModal = () => {
    setModalGradeOverview(false);
    dispatch(analytcsActions.setAssessmentResultsAverage(null));
  };

  const handleClickActionButton = (
    action: string,
    employeeId: string,
    employeeName: string,
    resultId: string
  ) => {
    switch (action) {
      case 'viewQuestionResponses':
        dispatch(analytcsActions.getQuizResult(resultId));
        seEmployeeName(employeeName);
        setModalSingleQuiz(true);
        break;
      case 'viewAllGrades':
        setAllGradesModalData({
          employeeId,
          employeeName,
          data: data ? (data as AnalyticsQuizDetail[]) : null,
          quizName: quizName,
          quizAverage: '50'
        });
        break;
    }
  };

  const handleCloseSingleQuizModal = () => {
    setModalSingleQuiz(false);
    dispatch(analytcsActions.setQuizResult(null));
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container direction="row">
        <Grid item flex={1}>
          <GoBackComponent
            onBackTo={onBackToQuizzes}
            linkText={t('backToAllQuizzes', { ns: 'analytics' })}
          />
        </Grid>
        <Grid item>
          <Stack direction="row" spacing={1} flexWrap="wrap">
            <BasicSelect
              labelId="sortOptions"
              id="sortOptions"
              options={sortOptions}
              defaultValue=""
              value={[sortOption]}
              handleOnChange={handleChangeSortOption}
              style={{ height: '45px', width: '230px' }}
              theme="dark"
              multiple={false}
              disabled={!data}
              sx={{ backgroundColor: 'transparent' }}
            />
            <SearchBox
              id="searchContent"
              name="searchContent"
              value={searchFilter}
              onChangeValue={handleSearchData}
              placeholderText={t('search', { ns: 'common' })}
              width="250px"
              height="45px"
            />
            <IconButton
              aria-label="downloadCSV"
              size="large"
              onClick={handleDownloadCsvReport}
              disabled={!data || downloadingReport}
              sx={{ color: theme.palette.grey[100], paddingTop: 0 }}
            >
              <DownloadForOfflineRoundedIcon style={{ fontSize: 45 }} />
            </IconButton>
            <BasicButton
              onClick={handleOpenGradeOverviewModal}
              color="primary"
              style={{ height: '45px', width: '160px' }}
            >
              {t('gradeOverview', { ns: 'analytics' })}
            </BasicButton>
          </Stack>
        </Grid>
      </Grid>
      <br />
      <QuizzesDetailTable
        data={data ? (data as AnalyticsQuizDetail[]) : data}
        quizName={quizName}
        onClickActionButton={handleClickActionButton}
      />
      {allGradesModalData && (
        <AllGradesModal
          AllGradesModalData={allGradesModalData}
          onClose={() => {
            setAllGradesModalData(null);
          }}
        />
      )}
      {answerQuizAverage && (
        <GradeOverViewModalAllEmployees
          showModal={modalGradeOverview}
          onClose={handleCloseGradeOverviewModal}
          answerQuizAverage={answerQuizAverage}
        />
      )}
      {quizResult && (
        <GradeOverViewModalSingleUser
          employeeName={employeeName}
          asessmentResult={quizResult}
          showModal={modalSingleQuiz}
          onClose={handleCloseSingleQuizModal}
        />
      )}
    </Box>
  );
};

export default QuizzesDetail;

import { Grid, SxProps } from '@mui/material';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Course } from '../../../entities/Course';
import { actionCreators as coursesActions } from '../../../actions/courses';
import { ApplicationState } from '../../../store';
import AssignCourse from '../../courses/AssignCourse';
import React from 'react';
import { generateCoursesToShow } from '../../../utils/contentLibraryUtils';
import { TakenTimesObject } from '../../../entities/CompletedLessonInfo';
import { UserNavigationContext } from '../UserNavigationContext';
import {
  ContentStatus,
  LangContent,
  Sorting
} from '../../../hooks/useContentLibraryNavigation';
import ContentLibraryVirtualList from '../../../components/ContentLibraryVirtualList/ContentLibraryVirtualList';
import CourseVirtualRow from './CourseVirtualRow';
import ContentIndicators from '../common/ContentIndicators';
import CourseCardNew from '../../../components/CourseCard/CourseCardNew';
import { CourseActionsArea } from '../common/CourseActionsArea';
import { secondsToHHMMSS } from '../../../utils/dateUtils';
import { Tag } from '../../../entities/Tag';
import { useAppDispatch } from '../../../store/hooks';

interface CompanyCourseContentLibraryProps {
  filterContent: string;
  filterOrder: string;
  filterLanguageOption: string;
  searchFilter: string;
  userCompanyId: string;
  takenTimesCourses?: TakenTimesObject | null;
  filterTags: Tag[];
}

export const CompanyCourseContentLibrary = ({
  filterContent,
  filterOrder,
  filterLanguageOption,
  searchFilter,
  userCompanyId,
  takenTimesCourses,
  filterTags
}: CompanyCourseContentLibraryProps) => {
  //All, published or drafted Courses
  const [coursesToShow, setCoursesToShow] = useState<Course[]>();
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigationApi = userNavigationState?.api;
  const [showAssignCourseModal, setShowAssignCourseModal] =
    React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isLoading = useSelector(
    (state: ApplicationState) => state.courses?.isLoading
  );
  const companyCourses = useSelector(
    (state: ApplicationState) => state.courses?.courses
  );

  useEffect(() => {
    if (!isLoading) {
      userNavigationApi?.changeSearch(searchFilter);

      if (companyCourses && companyCourses.length) {
        const courses = generateCoursesToShow(
          companyCourses,
          searchFilter,
          filterContent as ContentStatus,
          filterLanguageOption as LangContent,
          filterOrder as Sorting,
          filterTags
        );
        setCoursesToShow(courses);
      } else {
        setCoursesToShow([]);
      }
    }
  }, [
    isLoading,
    companyCourses,
    filterLanguageOption,
    filterOrder,
    filterContent,
    searchFilter,
    filterTags
  ]);

  const handleOpenAssignModal = (id?: string) => {
    dispatch(coursesActions.requestCourseById(id!));
    setShowAssignCourseModal(true);
  };

  const handleCloseAssignmodal = () => {
    dispatch(coursesActions.setCurrentCourse(null));
    setShowAssignCourseModal(false);
  };

  return (
    <Grid item xs={12} sx={coursesContainer}>
      <ContentIndicators
        isLoading={isLoading ?? false}
        totalItems={companyCourses?.length ?? 0}
        noContentMessage={t('noCoursesAvailableMsg', { ns: 'courses' })}
      />
      <ContentLibraryVirtualList
        items={coursesToShow}
        learningPlanUnit={'courses'}
        isLoading={isLoading ?? true}
        itemSize={341}
      >
        {({ data, index, style }) => (
          <CourseVirtualRow index={index} style={style} data={data}>
            {course => (
              <CourseCardNew
                isPreviewMode={true}
                averageLessonTime={
                  course.avgLessonTime
                    ? secondsToHHMMSS(course.avgLessonTime)
                    : '00:00:00'
                }
                isDraft={course.isDraft}
                status={'Clear'}
                hasSpanishSubtitles={course.hasSpanishSubtitles}
                course={course}
                isAdmin={course.companyId === userCompanyId}
                takenTimes={
                  takenTimesCourses ? takenTimesCourses[course.id] : undefined
                }
              >
                <CourseActionsArea
                  course={course}
                  cardAuxiliarFunction={handleOpenAssignModal}
                />
              </CourseCardNew>
            )}
          </CourseVirtualRow>
        )}
      </ContentLibraryVirtualList>

      {showAssignCourseModal && (
        <AssignCourse
          open={showAssignCourseModal}
          onClose={handleCloseAssignmodal}
        />
      )}
    </Grid>
  );
};

const coursesContainer: SxProps = {
  paddingTop: '2rem'
};

import React, { CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { QuizGradeNotice } from './QuizGradeNotice';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  isQuestionAnswered,
  orderStyle,
  renderQuestions
} from './takeQuizHelpers';
import { LinearProgress, useTheme } from '@mui/material';
import { useQuiz } from './useQuiz';
import { useTranslation } from 'react-i18next';

const TakeQuiz: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: {
      currentLesson,
      isFeedback,
      quizResult,
      percentageToPass,
      isMarkingCompleteInProgress
    },
    api: {
      saveAnswer,
      backToPreviousPage,
      onRetry,
      submitQuiz,
      isRedirectToLibrary
    }
  } = useQuiz(
    location.state.tabOrigin,
    location.state.assignmentId,
    location.state.courseContext
  );
  const { t } = useTranslation(['common', 'quizzes']);

  const backwardIconStyle: CSSProperties = {
    display: 'inline-block',
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundImage: `url(${BackwardIconGray})`
  };

  const handleBackToPreviousPage = () => {
    if (isRedirectToLibrary(true)) {
      if (location.state.tabOrigin) {
        window.location.href = `/content-library?tab=${location.state.tabOrigin}`;
      }
      navigate(-1);
    } else {
      backToPreviousPage(true);
    }
  };

  const backToStyle: CSSProperties = {
    color: theme.palette.grey[50]
  };

  const percentageStyle: CSSProperties = {
    color: theme.palette.success.main
  };

  const separatorStyle: CSSProperties = {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    color: theme.palette.grey[100]
  };

  const isSubmitButtonDisabled = quizResult.answers.some(
    a => !isQuestionAnswered(a)
  );

  return (
    <PageContainer>
      <Grid container>
        <Grid item xs={12} style={backToStyle}>
          <div className="d-flex flex-row mb-3 mt-3">
            <div className="pt-1 pe-2">
              <i
                className="fas fa-sm fa-fw mr-2 mt-2 text-gray-400"
                style={backwardIconStyle}
                onClick={handleBackToPreviousPage}
              />
            </div>
            <span className="mt-3">{t('backToLessons', { ns: 'common' })}</span>
          </div>
        </Grid>

        <Grid item xs={12} style={titleStyle}>
          {currentLesson?.title}
        </Grid>
        <Grid item xs={12} style={quizDetailStyle}>
          <span>
            {`${currentLesson?.questions?.length ?? 0} ${t('questions', {
              ns: 'quizzes'
            }).toLowerCase()} `}
            <span style={separatorStyle}>|</span>
            {` ${t('toPass', { ns: 'quizzes' })} `}
            <span style={percentageStyle}>{`${percentageToPass}%`}</span>
            {percentageToPass < 100 && ` ${t('orHigher', { ns: 'quizzes' })} `}
          </span>
        </Grid>
        <Grid item xs={12} sx={beforeQuestionsSpaceStyle}>
          {isFeedback && quizResult.passed !== undefined && (
            <QuizGradeNotice
              passed={quizResult.passed!}
              grade={quizResult.grade!}
              onContinue={handleBackToPreviousPage}
              onRetry={() => onRetry()}
            />
          )}
        </Grid>
        <Grid container item md={9}>
          {renderQuestions(
            saveAnswer,
            quizResult,
            isFeedback,
            currentLesson?.questions,
            quizResult.passed
          )}
        </Grid>
        <Grid item xs={12} sx={submitGridStyle}>
          <Grid container>
            <Grid item xs="auto" style={orderStyle}></Grid>
            <Grid item xs>
              {isMarkingCompleteInProgress && (
                <div className="d-flex flex-column w-100 mb-4">
                  <LinearProgress />
                </div>
              )}
              {!isFeedback && (
                <BasicButton
                  onClick={submitQuiz}
                  color="primary"
                  width="179px"
                  height="45px"
                  disable={
                    isSubmitButtonDisabled || isMarkingCompleteInProgress
                  }
                >
                  {t('submit', { ns: 'common' })}
                </BasicButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageContainer>
  );
};

const titleStyle: CSSProperties = {
  fontSize: '30px'
};

const quizDetailStyle: CSSProperties = {
  fontSize: '18px'
};

const submitGridStyle: SxProps = {
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem'
};

const beforeQuestionsSpaceStyle: SxProps = {
  paddingBottom: '5rem'
};

export default TakeQuiz;

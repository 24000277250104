import { PaginationOptions } from './PaginationOptions';

export interface OnsiteTrainingPagingQueryParameters extends PaginationOptions {
  search: string;
  sort: string;
  filterContentStatus: string;
}

export const pagingToQueryParams = (
  params: OnsiteTrainingPagingQueryParameters
) => {
  return `page=${params.page}&pageSize=${params.pageSize}&search=${params.search}&sort=${params.sort}&filterContentStatus=${params.filterContentStatus}`;
};

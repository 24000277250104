import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@buildwitt/component-library';
import { TabConfig } from '@buildwitt/component-library/dist/components/Tabs/Tabs';
import { Typography, ThemeProvider } from '@mui/material';
import { defaultTheme } from '@buildwitt/component-library';
import CompanyCalendar from './calendar/CompanyCalendar';
import config from '../../config';
import { NotificationPreferencesTab } from './Tabs/NotificationPreferencesTab';
import EmployeeInfoTab from './Tabs/EmployeeInfoTab';

const Settings: React.FC = () => {
  const { t } = useTranslation(['common', 'settings']);

  const getTabConfig = () => {
    const commonTabs: TabConfig[] = [
      {
        label: t('employeeInfo', { ns: 'settings' }),
        component: <EmployeeInfoTab />,
        hide: false
      },
      {
        label: t('notificationPreferences', { ns: 'settings' }),
        component: <NotificationPreferencesTab />,
        hide: false
      },
      {
        label: t('calendar', { ns: 'settings' }),
        component: <CompanyCalendar />,
        hide: !config.SETTINGS_CALENDAR
      },
      {
        label: t('contentPermissions', { ns: 'settings' }),
        component: <div>TODO... content permissions</div>,
        hide: true
      }
    ];
    return commonTabs;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Row>
          <Col>
            <Typography sx={fontTab}>
              {t('settings', { ns: 'settings' })}
            </Typography>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Tabs tabConfig={getTabConfig()} />
          </Col>
        </Row>
      </PageContainer>
    </ThemeProvider>
  );
};
const fontTab: CSSProperties = {
  fontSize: '24px',
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 1)'
};

export default Settings;

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TakenTimesContext } from '../../contexts/TakenTimesContext';
import { LearningPlan } from '../../entities/LearningPlan';
import { useLogEvent } from '../../hooks/useLogEvent';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { useAppDispatch } from '../../store/hooks';

const useLearningPlanCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const { takenTimesLearningPlans } = useContext(TakenTimesContext);

  const handleStartLearningPlan = (
    learningPlan: LearningPlan,
    dueDate: string,
    assignmentId: string
  ) => {
    dispatch(learningPlanActions.setCurrentLearningPlan(learningPlan));
    logEvent.logStartLearningPlanEvent(learningPlan.id, learningPlan.name);
    navigate(`/learning-plans/assignment/play/${assignmentId}`, {
      state: {
        dueDate,
        assignmentId
      }
    });
  };

  return { takenTimesLearningPlans, handleStartLearningPlan };
};

export default useLearningPlanCard;

import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';

export const lessonWrapperStyle: SxProps = {
  maxWidth: '370px',
  width: '100%'
};

export const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

export const divButtonStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '89%'
};

export const spanDateStyle = {
  marginTop: '10px'
};

import React, { useState, CSSProperties, useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Lesson } from '../../../entities/Lesson';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@mui/material';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '../../../assets/downloadIcon.svg';
import AttachmentSection from './AttachmentSection';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Document, Page } from 'react-pdf';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Assessment } from '../../../entities/Assessment';
import { enqueueSnackbar } from 'notistack';

const DialogContentStyle = styled(Dialog)(() => ({
  '& .MuiDialogContent-root': {
    height: '700px !important',
    overflowY: 'hidden !important'
  }
}));

interface FileSectionProps {
  currentLesson: Lesson;
  onFileEnded?: () => void;
  assessment: Assessment | null | undefined;
  isPreviewMode?: boolean;
}

const observerConfig = {
  threshold: 0
};

function PageWithObserver({
  pageNumber,
  setPageVisibility,
  width,
  ...otherProps
}: {
  pageNumber: number;
  setPageVisibility: (pageNumber: number, isIntersecting: boolean) => void;
  width: number;
}) {
  const [page, setPage] = useState();
  const onIntersectionChange = useCallback(
    ([entry]: any[]) => {
      setPageVisibility(pageNumber, entry.isIntersecting);
    },
    [pageNumber, setPageVisibility]
  );

  useIntersectionObserver(
    page ? page : null,
    observerConfig,
    onIntersectionChange
  );

  return (
    <div style={{ paddingBottom: '10px' }}>
      <Page
        canvasRef={setPage as any}
        pageNumber={pageNumber}
        {...otherProps}
        width={width}
      />
    </div>
  );
}

export function ViewFileModal({
  open,
  onClose,
  learningMaterialUrl,
  title,
  backdropColor = 'rgba(0, 0, 0, 0.5)'
}: Readonly<{
  open: boolean;
  onClose: () => void;
  learningMaterialUrl: string;
  title: string;
  backdropColor?: string;
}>) {
  const { t } = useTranslation(['lessons', 'common']);
  const [numPages, setNumPages] = useState(0);
  const [urlPdf, setUrlPdf] = useState({ url: '' });
  const [loading, setLoading] = useState(true);
  const DownloadFileUrl = (url: string) => {
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const fileName = url.split('/').pop();
        const aTag = document.createElement('a');
        aTag.href = blobUrl;
        aTag.setAttribute('download', fileName!);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    const fileExt = learningMaterialUrl.split('.').pop();
    if (fileExt && fileExt.toUpperCase() === 'PDF') {
      setUrlPdf({ url: learningMaterialUrl });
    } else {
      setUrlPdf({ url: '' });
    }
  }, [learningMaterialUrl]);

  const setPageVisibility = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (pageNumber: any, isIntersecting: any) => {},
    []
  );

  return (
    <DialogContentStyle
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: backdropColor
          }
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {title}{' '}
        <Button onClick={() => DownloadFileUrl(learningMaterialUrl)}>
          <img src={DownloadIcon} alt="" />
        </Button>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {learningMaterialUrl ? (
          <Box>
            {urlPdf.url ? (
              <div style={{ height: '680px', overflow: 'auto' }}>
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <Document
                      file={urlPdf}
                      onLoadSuccess={onDocumentLoadSuccess}
                    >
                      {Array.from(new Array(numPages), (el, index) => (
                        <PageWithObserver
                          key={`page_${index + 1}`}
                          pageNumber={index + 1}
                          setPageVisibility={setPageVisibility}
                          width={width - 10}
                        />
                      ))}
                    </Document>
                  )}
                </AutoSizer>
              </div>
            ) : (
              <Box overflow={'auto'} height={'100%'}>
                {loading && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="50vh"
                  >
                    <CircularProgress />
                  </Box>
                )}
                <img
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain'
                  }}
                  src={learningMaterialUrl}
                  alt="fileImage"
                  onLoad={() => setLoading(false)}
                  onError={() => {
                    setLoading(false);
                    enqueueSnackbar(t('anErrorOccurred', { ns: 'common' }), {
                      variant: 'error'
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        ) : (
          <h3>{t('noLearningMaterial', { ns: 'common' })}</h3>
        )}
      </DialogContent>
    </DialogContentStyle>
  );
}

const FileSection: React.FC<FileSectionProps> = ({
  currentLesson,
  onFileEnded,
  assessment,
  isPreviewMode
}) => {
  const { t } = useTranslation(['lessons', 'common']);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableTakeAssestment, setDisableTakeAssestment] = useState(true);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDisableTakeAssestment(false);
  };
  const handleOpenAssesment = () => {
    if (onFileEnded) onFileEnded();
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div
        style={divBlurStyle}
        className="rounded d-flex flex-column justify-content-center"
      >
        <Grid container>
          <Grid xs={12} sm={12} md={12}>
            <Row>
              <Col>
                <BasicButton
                  color="primary"
                  style={customButtonStyle}
                  onClick={handleOpenDialog}
                >
                  {t('viewFile', { ns: 'lessons' })}
                </BasicButton>
              </Col>
            </Row>
            {(isPreviewMode && assessment) || !isPreviewMode ? (
              <>
                <Row>
                  <Col>
                    <BasicButton
                      color="grayButton"
                      style={customButtonStyle}
                      onClick={handleOpenAssesment}
                      disable={disableTakeAssestment}
                    >
                      {t(assessment ? 'takeAssessment' : 'completeLesson', {
                        ns: 'lessons'
                      })}
                    </BasicButton>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Typography>
                      {t(assessment ? 'infoI' : 'infoINoAssessment', {
                        ns: 'lessons'
                      })}
                    </Typography>
                    <Typography>
                      {t(assessment ? 'infoII' : 'infoIINoAssessment', {
                        ns: 'lessons'
                      })}
                    </Typography>
                  </Col>
                </Row>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>

      <AttachmentSection currentLesson={currentLesson} />

      <ViewFileModal
        open={openDialog}
        onClose={handleCloseDialog}
        title={t('lessonFile', { ns: 'lessons' })}
        learningMaterialUrl={currentLesson.learningMaterialUrl}
      />
    </>
  );
};

const divBlurStyle: CSSProperties = {
  width: '100%',
  height: '620px',
  backgroundColor: '#212121',
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '14px',
  display: 'flex',
  flexDirection: 'column' as const,
  textAlign: 'center'
};

const customButtonStyle: CSSProperties = {
  width: '35%',
  height: '45px',
  marginBottom: '10px'
};

export default FileSection;

import React from 'react';

export interface HorizontalLineProps {
  color: string;
  line: number;
  withSize?: string;
}

const HorizontalLine: React.FC<HorizontalLineProps> = ({
  color,
  line,
  withSize
}) => {
  return (
    <hr
      style={{
        color: color,
        backgroundColor: color,
        height: line,
        width: withSize
      }}
    />
  );
};

export default HorizontalLine;

import { Lesson } from '../entities/Lesson';
import { Course } from '../entities/Course';
import { Languages } from '../core/constants';
import { LearningPlan } from '../entities/LearningPlan';
import {
  ContentStatus,
  LangContent,
  Sorting
} from '../hooks/useContentLibraryNavigation';
import { Tag } from '../entities/Tag';

export const sortLessons = <T>(
  lessons: T[],
  criteria: string,
  titleSelector: (item: T) => string,
  createAtSelector: (item: T) => Date
) => {
  if (!lessons) return [];
  const comparisonDelegate = (a: T, b: T) =>
    titleSelector(a).trim() > titleSelector(b).trim() ? 1 : -1;
  const comparisonDateDelegate = (a: T, b: T) =>
    createAtSelector(a) > createAtSelector(b) ? 1 : -1;
  switch (criteria) {
    case 'A-Z':
      return lessons.slice().sort(comparisonDelegate);
    case 'Z-A':
      return lessons.slice().sort(comparisonDelegate).reverse();
    case 'asc':
      return lessons.slice().sort(comparisonDateDelegate);
    case 'desc':
      return lessons.slice().sort(comparisonDateDelegate).reverse();
    default:
      return lessons.slice();
  }
};

export const sortCoursesOrLearninpPlans = <T>(
  entities: T[],
  criteria: string,
  titleSelector: (item: T) => string,
  createAtSelector: (item: T) => Date,
  isDraftSelector: (item: T) => boolean
) => {
  const comparisonDelegate = (a: T, b: T) =>
    titleSelector(a).trim() > titleSelector(b).trim() ||
    isDraftSelector(a) < isDraftSelector(b)
      ? 1
      : -1;
  const comparisonDateDelegate = (a: T, b: T) =>
    createAtSelector(a) > createAtSelector(b) ||
    isDraftSelector(a) < isDraftSelector(b)
      ? 1
      : -1;

  switch (criteria) {
    case 'A-Z':
      return entities.slice().sort(comparisonDelegate);
    case 'Z-A':
      return entities.slice().sort(comparisonDelegate).reverse();
    case 'asc':
      return entities.slice().sort(comparisonDateDelegate);
    case 'desc':
      return entities.slice().sort(comparisonDateDelegate).reverse();
    default:
      return entities.slice();
  }
};

export const filterLearningUnitsByContentStatus = <T>(
  learningUnits: T[],
  isDrafSelector: (item: T) => boolean,
  status: string
) => {
  switch (status) {
    case 'published':
      return learningUnits.filter(
        learningUnit => !isDrafSelector(learningUnit)
      );
    case 'draft':
      return learningUnits.filter(learningUnit => isDrafSelector(learningUnit));
    default:
      return learningUnits;
  }
};

export const filterLearningUnitsBySearchTerm = <T>(
  searchTerm: string,
  titleSelector: (item: T) => string,
  descriptionSelector: (item: T) => string,
  entities: T[],
  tagSelector?: (item: T) => Tag[]
) => {
  if (!searchTerm) {
    return entities;
  }

  if (!entities?.length) {
    return [];
  }

  const searchKey = searchTerm.toLowerCase();

  return entities.filter(
    entity =>
      titleSelector(entity).toLowerCase().includes(searchKey) ||
      descriptionSelector(entity).toLowerCase().includes(searchKey) ||
      (tagSelector
        ? tagSelector(entity).some(tag =>
            tag.name.toLowerCase().includes(searchKey)
          )
        : false)
  );
};

export const filterLearningUnitsByTags = <T>(
  tags: Tag[],
  entitySelector: (item: T) => Tag[],
  entities: T[]
) => {
  if (!tags.length || !entities?.length) return entities;
  const tagNames = tags.map(tag => tag.name);

  return entities.filter(entity =>
    entitySelector(entity).some(tag =>
      tagNames.includes(tag.name.trim().toLowerCase())
    )
  );
};

export const filterCoursesByLang = (courses: Course[], lang: string) => {
  switch (lang) {
    case 'spanishContent':
      return courses.filter(course => course.hasSpanishSubtitles);
    case 'all':
      return courses;
    default:
      return courses;
  }
};

export const filterLessonsByLang = (lessons: Lesson[], option: string) => {
  switch (option) {
    case 'spanishContent':
      return lessons.filter(lesson =>
        lesson.subtitles?.some(
          subtitle => subtitle.language === Languages.spanish
        )
      );
    case 'all':
      return lessons;
    default:
      return lessons;
  }
};

const learningPlanSelectors = {
  name: (item: LearningPlan) => item.name,
  description: (item: LearningPlan) => item.description,
  isDraft: (item: LearningPlan) => item.isDraft,
  createdAtSelector: (item: LearningPlan) => item.createdTimestampUtc
};

export const generateLearningPlansToShow = (
  learningPlans: LearningPlan[],
  searchTerm: string | null,
  filterLearningUnitStatus: ContentStatus | null,
  activeSort: Sorting
) => {
  let lpsOutput = learningPlans;

  if (searchTerm) {
    lpsOutput = filterLearningUnitsBySearchTerm<LearningPlan>(
      searchTerm,
      learningPlanSelectors.name,
      learningPlanSelectors.description,
      lpsOutput
    ) as LearningPlan[];
  }

  if (filterLearningUnitStatus && filterLearningUnitStatus !== 'allContent') {
    lpsOutput = filterLearningUnitsByContentStatus(
      lpsOutput,
      item => item.isDraft,
      filterLearningUnitStatus
    ) as LearningPlan[];
  }

  return sortCoursesOrLearninpPlans<LearningPlan>(
    lpsOutput,
    activeSort,
    item => item.name,
    item => item.createdTimestampUtc!,
    item => item.isDraft
  );
};

export const generateCoursesToShow = (
  courses: Course[],
  searchTerm: string | null,
  filterLearningUnitStatus: ContentStatus | null,
  filterLearningUnitLang: LangContent | null,
  activeSort: Sorting,
  filterTags: Tag[]
) => {
  let coursesOutput = courses;

  if (searchTerm) {
    coursesOutput = filterLearningUnitsBySearchTerm(
      searchTerm,
      item => item.title,
      item => item.description,
      coursesOutput,
      item => item.tags
    ) as Course[];
  }
  if (filterLearningUnitStatus) {
    coursesOutput = filterLearningUnitsByContentStatus(
      coursesOutput,
      item => item.isDraft,
      filterLearningUnitStatus
    ) as Course[];
  }
  if (filterLearningUnitLang) {
    coursesOutput = filterCoursesByLang(coursesOutput, filterLearningUnitLang);
  }
  if (filterTags.length) {
    coursesOutput = filterLearningUnitsByTags<Course>(
      filterTags,
      item => item.tags,
      coursesOutput
    );
  }

  return sortCoursesOrLearninpPlans(
    coursesOutput,
    activeSort,
    item => item.title,
    item => item.createdAt!,
    item => item.isDraft
  );
};

export const generateLessonsToShow = (
  lessons: Lesson[],
  searchTerm: string | null,
  filterLearningUnitLang: LangContent | null,
  activeSort: Sorting,
  filterTags: Tag[]
) => {
  let lessonOutput = lessons;

  if (searchTerm) {
    lessonOutput = filterLearningUnitsBySearchTerm<Lesson>(
      searchTerm,
      item => item.title,
      item => item.description,
      lessonOutput,
      item => item.tags
    ) as Lesson[];
  }

  if (filterLearningUnitLang && filterLearningUnitLang !== 'all') {
    lessonOutput = filterLessonsByLang(lessonOutput, filterLearningUnitLang);
  }

  if (filterTags.length) {
    lessonOutput = filterLearningUnitsByTags<Lesson>(
      filterTags,
      item => item.tags,
      lessonOutput
    );
  }

  return sortLessons(
    lessonOutput,
    activeSort,
    item => item.title,
    item => item.createdAt!
  );
};

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TakenTimesContext } from '../../contexts/TakenTimesContext';
import { CourseUnitTypes } from '../../core/constants';
import { useLogEvent } from '../../hooks/useLogEvent';

const useCourseCard = () => {
  const navigate = useNavigate();
  const logEvent = useLogEvent();

  const { takenTimesCourses } = useContext(TakenTimesContext);
  const getCourseType = (isAicc: boolean) => {
    if (isAicc) return CourseUnitTypes.clickSafetyCourse;
  };

  const handleStartCourse = (
    courseId: string,
    revisionNumber: number,
    courseTitle: string,
    isAicc: boolean,
    assignmentId?: string
  ) => {
    logEvent.logStartCourseEvent(courseId, revisionNumber, courseTitle);
    if (isAicc) {
      navigate(`/click-safety-course/${courseId}`);
    } else {
      if (assignmentId) {
        navigate(`/courses/assignment/play/${assignmentId}`);
      } else {
        navigate(`/courses/${courseId}/revision/${revisionNumber}`);
      }
    }
  };

  return { getCourseType, takenTimesCourses, handleStartCourse };
};

export default useCourseCard;

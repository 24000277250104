import { useEffect } from 'react';
import { Lesson, LessonUnit } from '../entities/Lesson';

export const useEncodingStatusUpdate = (
  lessonIdData: string,
  lessons: Lesson[] | null | undefined,
  lessonUnits: LessonUnit[] | null | undefined,
  stateCallback: any
) => {
  useEffect(() => {
    if (lessonIdData) {
      const lessonId = lessonIdData.split(';')[0];
      const duration = lessonIdData.split(';')[1];
      updateLessonEncodingStatus(
        lessonId,
        duration,
        lessons,
        lessonUnits,
        stateCallback
      );
    }
  }, [lessonIdData]);
};

const updateLessonEncodingStatus = (
  lssId: string,
  duration: string,
  lessons: Lesson[] | null | undefined,
  lessonUnits: LessonUnit[] | null | undefined,
  stateCallback: any
) => {
  // if it's a lesson collection handle the isEncoding flag otherwise get it from lessonUnits
  if (lessons) {
    const lessonToUpdate = lessons.find(x => x.id == lssId);
    if (lessonToUpdate && lessonToUpdate.isVideoEncoding) {
      lessonToUpdate.isVideoEncoding = false;
      lessonToUpdate.lessonDuration = duration;
      const updatedList = lessons?.filter(x => x.id != lssId).slice();
      updatedList?.push(lessonToUpdate);
      stateCallback(updatedList);
    }
  } else {
    const lessonUnit = lessonUnits?.find(x => x.lesson.id == lssId);
    if (lessonUnit && lessonUnit.lesson && lessonUnit.lesson.isVideoEncoding) {
      lessonUnit.lesson.isVideoEncoding = false;
      lessonUnit.lesson.lessonDuration = duration;
      const updatedUnitList = lessonUnits
        ?.filter(x => x.lesson.id != lssId)
        .slice();
      updatedUnitList?.push(lessonUnit);
      stateCallback(updatedUnitList);
    }
  }
};

import { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import AssessmentAccordion from '../../AssessmentAccordion/AssessmentAccordion';
import HorizontalLine from '../../core/HorizontalLine/HorizontalLine';
import Counter from '../../core/Counter/Counter';
import { QuestionTypes, LessonQuiz } from '../../../core/constants';
import { Lesson } from '../../../entities/Lesson';
import { Question } from '../../../entities/Assessment';

export interface QuestionEditMode extends Question {
  _id?: string;
}
export interface AssessmentSectionProps {
  lesson: Lesson;
  lessonType: string;
  questions: QuestionEditMode[];
  correctAnswersNumber: number;
  setQuestions: (question: QuestionEditMode[]) => void;
  setAssessmentValidationMsg: (message: string) => void;
  setCorrectAnswers: (correctAnswersNumber: number) => void;
  getNextQuestionId: () => string;
}

const isNullOrEmpty = (str: string) => {
  return (
    !str ||
    false ||
    (Object.prototype.hasOwnProperty.call(str, 'trim') &&
      str.trim().length === 0)
  );
};

const questionHaveOptions = (question: Question) => {
  return question.options && question.options.length > 0;
};

const isInvalidQuestion = (question: Question) => {
  if (isNullOrEmpty(question.questionText)) {
    return true;
  }
  switch (question.type) {
    case QuestionTypes.MultipleChoiceText:
      return (
        !questionHaveOptions(question) ||
        !question.options!.some(option => option.isCorrect) ||
        question.options!.some(option => isNullOrEmpty(option.optionText))
      );
    case QuestionTypes.MultipleChoiceImage:
      return (
        !questionHaveOptions(question) ||
        !question.options!.some(option => option.isCorrect) ||
        question.options!.every(option => !option.imageFile && !option.imageUrl)
      );
    case QuestionTypes.Number:
      return isNullOrEmpty(question.numberCorrectAnswer);
  }
};

const AssessmentSection: React.FC<AssessmentSectionProps> = ({
  lesson,
  lessonType,
  questions,
  correctAnswersNumber,
  setQuestions,
  setAssessmentValidationMsg,
  setCorrectAnswers,
  getNextQuestionId
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['assessments']);
  const assessmentValidationMsgRef = useRef<string>('');
  const hasRendered = useRef<boolean>(false);

  useEffect(() => {
    hasRendered.current = true;
  }, []);

  (() => {
    if (hasRendered.current) {
      let message = '';
      const anyQuestionInvalid = questions.some(q => isInvalidQuestion(q));
      if (anyQuestionInvalid) {
        message = t('invalidQuestionsMsg');
      }

      if (lessonType === LessonQuiz) {
        if (questions.length === 0) {
          message = t('mustEnterQuestions');
        } else if (correctAnswersNumber === 0) {
          message = t('mustChooseCorrectAnswers');
        }
      }
      if (message !== assessmentValidationMsgRef.current) {
        assessmentValidationMsgRef.current = message;
        setAssessmentValidationMsg(message);
      }
    }
  })();

  return (
    <>
      <AssessmentAccordion
        questions={questions}
        setQuestions={setQuestions}
        lessonType={lessonType}
        variant="dark"
        getNextQuestionId={getNextQuestionId}
      />
      {lessonType === LessonQuiz && (
        <>
          <HorizontalLine
            color={theme.palette.grey[100]}
            line={3}
            withSize={'100%'}
          />
          <Counter
            numberQuestions={questions.length}
            setCorrectAnswers={setCorrectAnswers}
            numberCorrectAnswers={lesson.correctAnswersToPass}
          />
          <HorizontalLine
            color={theme.palette.grey[100]}
            line={3}
            withSize={'100%'}
          />
        </>
      )}
    </>
  );
};

export default AssessmentSection;

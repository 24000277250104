import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionLessons } from '../../actions/lessons';
import { actionCreators as actionAssessments } from '../../actions/assessments';
import { ApplicationState } from '../../store';
import FlashCardSection from './categories/FlashCardSection';
import QuizSection from './categories/QuizSection';
import VideoSection from './categories/VideoSection';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import { Lesson } from '../../entities/Lesson';
import LessonAssessments from './LessonAssessments';
import { TinyLessonCard } from '../../components/LessonCard/TinyLessonCard';
import { Guid } from 'guid-typescript';
import { useLogEvent } from '../../hooks/useLogEvent';
import { matchYoutubeUrl } from '../../utils/matchYoutubeUrl';
import { NewLessonType } from '../../entities/LessonFormModel';
import FileSection from './categories/FileSection';
import AudioSection from './categories/AudioSection';
import PreviewModeBanner from '../../components/PreviewMode/PreviewModeBanner';
import { EntityTypeEnum } from '../../core/enums';
import { useAppDispatch } from '../../store/hooks';

const LessonPreview: React.FC = () => {
  const params = useParams<{
    id: string;
    revision: string;
  }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const course = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );
  const assessment = useSelector(
    (state: ApplicationState) => state.assessments?.currentAssessment
  );
  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedQuizzes
  );
  const location = useLocation();
  const [lessonType, setLessonType] = useState<number>(0);
  const [currentLesson, setCurrentLesson] = useState<Lesson>();
  const [activeLesson, setActiveLessonLesson] = useState<string>('');
  const [showAssessment, setShowAssessment] = useState<boolean>(false);
  const selectedIndexLessonRef = useRef<number>();
  const isPreviewMode = location?.state?.isPreviewMode;

  const getLesson = (
    lesson: Lesson,
    lessonid: string,
    lessonType: string,
    index: number,
    activeLesson: string
  ) => {
    if (currentLesson?.id === lessonid) {
      return;
    }

    if (
      (lesson.lessonContentType === NewLessonType.Video &&
        lessonid !== activeLesson) ||
      lesson.lessonContentType === NewLessonType.Quiz ||
      lesson.lessonContentType === NewLessonType.File ||
      lesson.lessonContentType === NewLessonType.Audio
    ) {
      setCurrentLesson(lesson);
      setLessonType(lesson.lessonContentType);
      setActiveLessonLesson(lessonid);
      selectedIndexLessonRef.current = index;

      if (lesson.lessonContentType === NewLessonType.Video) {
        dispatch(actionLessons.setCurrentLesson(null));
        dispatch(actionAssessments.requestAssessment(lessonid));
      }

      if (lesson.lessonContentType === NewLessonType.File) {
        dispatch(actionLessons.setCurrentLesson(null));
        dispatch(actionAssessments.requestAssessment(lessonid));
      }
      if (lesson.lessonContentType === NewLessonType.Audio) {
        dispatch(actionLessons.setCurrentLesson(null));
        dispatch(actionAssessments.requestAssessment(lessonid));
      }
      if (lesson.lessonContentType === NewLessonType.Quiz) {
        dispatch(actionLessons.setCurrentLesson(lesson));
      }

      logEvent.logPlayLessonEvent(lessonid, lesson.title);
    }
  };

  useEffect(() => {
    if (location.state) {
      setCurrentLesson(location.state.lesson);
      setLessonType(location.state.lesson.lessonContentType);
      setActiveLessonLesson(location.state.lesson.id);

      if (location.state.lesson.lessonContentType === NewLessonType.Video)
        dispatch(actionAssessments.requestAssessment(location.state.lesson.id));

      if (location.state.lesson.lessonContentType === NewLessonType.File)
        dispatch(actionAssessments.requestAssessment(location.state.lesson.id));

      if (location.state.lesson.lessonContentType === NewLessonType.Audio)
        dispatch(actionAssessments.requestAssessment(location.state.lesson.id));

      if (location.state.lesson.lessonContentType === NewLessonType.Quiz) {
        dispatch(actionLessons.setCurrentLesson(location.state.lesson));
      }
    }
    if (params && params.id && params.id !== Guid.EMPTY && params.revision) {
      dispatch(actionCreators.requestCourseById(params.id));
      dispatch(
        actionLessons.requestCourseLessonsByRevision(
          params.id,
          parseInt(params.revision)
        )
      );
    }
  }, [params]);

  const backToPreviousPage = () => {
    navigate(-1);
  };

  const handleLessonEnded = (lesson: Lesson) => async () => {
    if (assessment && assessment.questions.length) {
      setShowAssessment(true);
    } else {
      await handlePlayNextLesson();
    }
  };

  const handlePlayNextLessonAfterAssessment = async () => {
    await handlePlayNextLesson();
    setShowAssessment(false);
  };

  const handlePlayNextLesson = async () => {
    if (lessons && lessons.length) {
      if (!selectedIndexLessonRef.current) {
        selectedIndexLessonRef.current = lessons.findIndex(
          lesson => lesson.id === currentLesson?.id
        );
      }

      if (selectedIndexLessonRef.current < lessons.length - 1) {
        const newIndex = selectedIndexLessonRef.current + 1;
        const nextLesson = lessons[newIndex];

        getLesson(
          nextLesson,
          nextLesson.id,
          nextLesson.lessonType,
          newIndex,
          activeLesson
        );
      }
    }
  };

  if (!course && params && params.id !== Guid.EMPTY && params.id !== undefined)
    return <></>;
  else
    return (
      <PageContainer>
        {isPreviewMode && currentLesson && (
          <PreviewModeBanner
            contentToAssign={currentLesson}
            contentType={EntityTypeEnum.Lesson}
          />
        )}
        <Row className="pb-0">
          <Col md={12}>
            <div>
              <h1 className="color-white">
                <i
                  className="fas fa-sm fa-fw mr-2 text-gray-400"
                  style={BackwardIconStyle}
                  onClick={backToPreviousPage}
                />{' '}
                {course?.title}
              </h1>
            </div>
          </Col>
          <Col xl={9} lg={8}>
            {lessonType === NewLessonType.Quiz && (
              <QuizSection
                origin={location.state.origin}
                isPreviewMode={isPreviewMode}
              />
            )}
            {lessonType === NewLessonType.Video && (
              <VideoSection
                key={currentLesson?.id}
                currentlesson={currentLesson!}
                onVideoEnded={handleLessonEnded(currentLesson!)}
                isPreviewMode={isPreviewMode}
              />
            )}
            {lessonType === NewLessonType.Audio && (
              <AudioSection
                key={currentLesson?.id}
                currentlesson={currentLesson!}
                onAudioEnded={handleLessonEnded(currentLesson!)}
              />
            )}
            {lessonType === NewLessonType.Flashcard && <FlashCardSection />}
            {lessonType === NewLessonType.File && (
              <FileSection
                currentLesson={currentLesson!}
                onFileEnded={handleLessonEnded(currentLesson!)}
                assessment={assessment}
                isPreviewMode={isPreviewMode}
              />
            )}
          </Col>
          <Col style={{ ...sidebar }} xl={3} lg={4}>
            {lessons
              ?.filter(
                x => !x.isVideoEncoding || matchYoutubeUrl(x.externalVideoUrl)
              )
              .map((lesson, index) => {
                return (
                  <TinyLessonCard
                    key={lesson.id}
                    lesson={lesson}
                    listFailedQuiz={listFailedQuiz!}
                    onClick={() =>
                      getLesson(
                        lesson,
                        lesson.id,
                        lesson.lessonType,
                        index,
                        activeLesson
                      )
                    }
                    isCompleted={false}
                    index={index}
                    activeLesson={activeLesson}
                  />
                );
              })}
          </Col>
        </Row>
        {assessment && (
          <LessonAssessments
            show={showAssessment}
            assessment={assessment}
            goNextLesson={handlePlayNextLessonAfterAssessment}
            isInPlayMode={false}
            isModalBusy={false}
          />
        )}
      </PageContainer>
    );
};

const sidebar: CSSProperties = {
  borderRadius: '5px',
  height: '650px',
  overflow: 'auto',
  backgroundColor: '#1D1D1E'
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 30,
  height: 30,
  cursor: 'pointer',
  backgroundImage: `url(${BackwardIconGray})`
};

export default LessonPreview;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    load: 'languageOnly',
    detection: {
      order: [
        'navigator',
        'querystring',
        'cookie',
        'sessionStorage',
        'htmlTag',
        'path',
        'subdomain',
        'localStorage'
      ]
    }
  });

export default i18n;

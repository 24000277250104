import { Zoom } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1d1d1e',
    fontSize: '16px'
  }
});

interface CustomTooltipProps extends TooltipProps {
  popperOptions?: Partial<any>;
}

export const LightStyleTooltip = styled(
  ({ className, popperOptions, ...props }: CustomTooltipProps) => (
    <Tooltip
      TransitionComponent={Zoom}
      arrow
      {...props}
      classes={{ popper: className }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: popperOptions ?? {
                offset: [0, -10]
              }
            }
          ]
        }
      }}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[50],
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[50]
  }
}));

import React, { ReactNode, CSSProperties, useState, useEffect } from 'react';
import { AppBar, Grid, Toolbar, Typography, InputBase } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import BasicButton from '../components/core/BasicButton/BasicButton';
import TopMenuAccount from '../components/TopMenu/TopMenuAccount';
import Box from '@mui/material/Box';
import AppLoader from '../components/core/AppLoader/AppLoader';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { defaultTheme } from '@buildwitt/component-library';
import { actionCreators as employeeActionCreators } from '../actions/employees';
import { useSelector } from 'react-redux';
import useCurrentUser, { useSignOut } from '../hooks/useCurrentUser';
import { ApplicationState } from '../store';
import { TransactionStatusEnum } from '../core/enums';
import { isValidChars } from '../utils/stringUtils';
import { UserClaims } from '../core/constants';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../store/hooks';
import { withDevCycleProvider } from '@devcycle/react-client-sdk';
import config from '../config';

const InputCode = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    position: 'relative',
    border: '1px solid #3D3D3D',
    width: '250px',
    height: '35px',
    paddingLeft: '0.5rem',
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const InputName = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    position: 'relative',
    border: '1px solid #3D3D3D',
    width: '250px',
    height: '35px',
    paddingLeft: '0.5rem',
    '&:focus': {
      boxShadow: `${(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));

const AccessDeniedContainer = ({ children }: { children: ReactNode }) => {
  return (
    <React.Suspense fallback="loading">
      <AppBar position={'static'}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <div>
            <img
              src={'https://cdn.fs.brandfolder.com/4qGXTNoS46tZUdvFZpXg'}
              width={'10%'}
              alt={'BuildWitt logo'}
            />
          </div>
          <Box className={'topbar'} style={topMenuStyle}>
            <TopMenuAccount />
          </Box>
        </Toolbar>
      </AppBar>
      {children}
    </React.Suspense>
  );
};

export const AccessDeniedLoadingScreen = () => {
  return (
    <React.Suspense fallback="loading">
      <AppLoader variant={'linear'} />
    </React.Suspense>
  );
};

export const AccessDenied = withDevCycleProvider({
  sdkKey: config.DEVCYCLE_SDK_KEY,
  options: {
    deferInitialization: true
  }
})(() => {
  const { t } = useTranslation(['common', 'settings'], { useSuspense: false });
  const [companyInviteCode, setCompanyInviteCode] = useState('');
  const [currentEmployee, setCurrentEmployee] = React.useState<any>({});
  const user = useCurrentUser();
  const isFreeTrial = user?.profile[UserClaims.IsFreeTrial];
  const freeTrialExpirationDate =
    user?.profile[UserClaims.FreeTrialExpirationDate];
  const expirationDate = moment(freeTrialExpirationDate as Date).format(
    'YYYY-MM-DD'
  );
  const currentDate = moment().startOf('day');
  const daysUntil = moment
    .duration(moment(expirationDate).diff(currentDate))
    .asDays();

  const auth = useAuth();
  const { signOut } = useSignOut();

  const employeeAssociated = useSelector(
    (state: ApplicationState) => state.employees?.employeeAssociated
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        employeeActionCreators.resetEmployeeAssociateWithCompanyInviteCodeAction()
      );
    };
  }, []);

  useEffect(() => {
    if (employeeAssociated === TransactionStatusEnum.Successfull) {
      enqueueSnackbar(t('empAssociated', { ns: 'settings' }), {
        variant: 'success'
      });
      dispatch(
        employeeActionCreators.resetEmployeeAssociateWithCompanyInviteCodeAction()
      );
      signOut();
    } else if (employeeAssociated === TransactionStatusEnum.Failed)
      enqueueSnackbar(t('empAssociatedException', { ns: 'settings' }), {
        variant: 'error'
      });
    dispatch(
      employeeActionCreators.resetEmployeeAssociateWithCompanyInviteCodeAction()
    );
  }, [employeeAssociated]);

  const onInputCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyInviteCode(e.target.value);
  };

  const onInputFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmployee({
      ...currentEmployee,
      firstName: e.target.value
    });
  };

  const onInputLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentEmployee({
      ...currentEmployee,
      lastName: e.target.value
    });
  };

  const handleCodeValidation = () => {
    if (
      companyInviteCode.length &&
      currentEmployee?.firstName?.length &&
      currentEmployee?.lastName?.length &&
      isValidChars(currentEmployee.firstName) &&
      isValidChars(currentEmployee.lastName)
    ) {
      const username = auth.user?.profile[UserClaims.Username] as string;
      const emailOrPhoneNumber = username.includes('@')
        ? (auth.user?.profile[UserClaims.UserEmail] as string)
        : (auth.user?.profile[UserClaims.UserPhoneNumber] as string);

      dispatch(
        employeeActionCreators.associateWithCompanyInviteCode(
          companyInviteCode,
          currentEmployee.firstName,
          currentEmployee.lastName,
          emailOrPhoneNumber
        )
      );
    } else {
      enqueueSnackbar(t('inviteCodeMsgErr', { ns: 'common' }), {
        variant: 'error'
      });
    }
  };

  if (auth.user && (window as any).ReactNativeWebView) {
    const payload = {
      access_token: auth.user.access_token,
      access_token_expires: auth.user.expires_at,
      refresh_token: auth.user.refresh_token,
      claims: Object.keys(auth.user.profile).map(claimKey => ({
        key: claimKey,
        value: auth.user?.profile[claimKey]
      }))
    };

    (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload));
  }

  return (
    <AccessDeniedContainer>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        height={'calc(100vh - 70px)'}
        display={'flex'}
        flexDirection={'column'}
      >
        {isFreeTrial && daysUntil <= 0 ? (
          <Grid item textAlign={'center'}>
            <Typography variant={'h4'} style={{ marginBottom: '20px' }}>
              {t('endTrialMessage', { ns: 'common' })}
            </Typography>
            <BasicButton
              onClick={() => {
                window.open('https://www.buildwitt.com/contact');
              }}
              color={'primary'}
            >
              {t('contactUs', { ns: 'common' })}
            </BasicButton>
          </Grid>
        ) : (
          <>
            <Grid item textAlign={'center'}>
              <Typography variant={'h4'}>
                {t('noSubscriptionMsg', { ns: 'common' })}
              </Typography>
              <Typography variant={'h6'}>
                {t('launchBWAppQuestion', { ns: 'common' })}
              </Typography>
              <BasicButton href={'buildwitt://app/login'} color={'primary'}>
                {t('launchApp', { ns: 'common' })}
              </BasicButton>
            </Grid>
            <br />
            <Grid item textAlign={'center'}>
              <Typography variant={'h6'}>or</Typography>
            </Grid>
            <br />
            <Grid item textAlign={'center'}>
              <Typography variant={'h4'}>
                {t('inviteCodeMsg', { ns: 'common' })}
              </Typography>
            </Grid>
            <Grid item textAlign={'center'}>
              <div style={divCodeToValidate}>
                <ThemeProvider theme={defaultTheme}>
                  <span className="text-danger">*</span>
                  <InputName
                    key="firstName"
                    onChange={onInputFirstName}
                    placeholder={t('firstName', { ns: 'common' })}
                    value={currentEmployee.firstName}
                  />
                  <span className="text-danger">*</span>
                  <InputName
                    key="lastName"
                    onChange={onInputLastName}
                    placeholder={t('lastName', { ns: 'common' })}
                    value={currentEmployee.lastName}
                  />
                  <span className="text-danger">*</span>
                  <InputCode
                    key="inviteCode"
                    placeholder={t('codeToValidate', { ns: 'common' })}
                    value={companyInviteCode}
                    onChange={onInputCode}
                  />
                  <BasicButton
                    color={'primary'}
                    style={btnStyle}
                    disable={isLoading}
                    onClick={handleCodeValidation}
                  >
                    {t('validateCode', { ns: 'common' })}
                  </BasicButton>
                </ThemeProvider>
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </AccessDeniedContainer>
  );
});

const divCodeToValidate: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginTop: '10px',
  justifyContent: 'center'
};

const topMenuStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: '15px'
};

const btnStyle: CSSProperties = {
  marginLeft: '10px'
};

import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';

interface FooterActionButtonsProps {
  fileProcessInProgress: boolean;
  onDeleteCertification: () => void;
  onClose: () => void;
}

const FooterActionButtons = ({
  fileProcessInProgress,
  onDeleteCertification,
  onClose
}: FooterActionButtonsProps) => {
  const { t } = useTranslation(['certifications', 'common']);

  return (
    <Stack direction="row" sx={{ justifyContent: 'center' }} gap={2}>
      <BasicButton
        color="deleteFilled"
        disabled={fileProcessInProgress}
        onClick={onDeleteCertification}
        style={{
          height: '45px',
          minWidth: '150px'
        }}
      >
        {t('deleteCertification', { ns: 'certifications' })}
      </BasicButton>
      <BasicButton
        color="primaryOutlined"
        disabled={fileProcessInProgress}
        onClick={onClose}
        style={{
          height: '45px',
          minWidth: '80px'
        }}
      >
        {t('close', { ns: 'common' })}
      </BasicButton>
    </Stack>
  );
};

export default FooterActionButtons;

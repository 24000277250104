import React, {
  useEffect,
  useRef,
  useState,
  CSSProperties,
  useMemo
} from 'react';
import { Box, Stack, SxProps } from '@mui/material';
import Wavesurfer, { WaveSurferOptions } from 'wavesurfer.js';
import Hls from 'hls.js';

import PlayIcon from '../../../assets/playIcon.svg';
import PauseIcon from '../../../assets/pauseIcon.svg';
import { Lesson } from '../../../entities/Lesson';
import AttachmentSection from './AttachmentSection';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';

interface AudioSectionProps {
  currentlesson: Lesson;
  onAudioEnded?: () => void;
}

const AudioSection: React.FC<AudioSectionProps> = ({
  currentlesson,
  onAudioEnded
}) => {
  const waveform = useRef<Wavesurfer>();
  const audio = useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>('');
  const [currentTime, setCurrentTime] = useState<string>('');
  const playWasPerformForTheFirstTimeRef = useRef<boolean>(false);
  const {
    variables: { mediaServiceMigration }
  } = useDevCycleSdk();

  const useHls = useMemo(
    () => mediaServiceMigration || !currentlesson.audioUrl,
    [mediaServiceMigration]
  );

  useEffect(() => {
    if (!waveform.current) {
      if (useHls) {
        const hls = new Hls();
        hls.loadSource(currentlesson.hlsUrl);
        hls.attachMedia(audio.current!);
      }

      let options: WaveSurferOptions = {
        container: '#waveform',
        waveColor: '#999999',
        barGap: 8,
        barWidth: 9,
        barRadius: 7,
        cursorWidth: 1,
        cursorColor: 'white',
        height: 150,
        progressColor: '#FF9E00',
        backend: 'MediaElement',
        normalize: true
      };

      if (useHls) {
        options = {
          ...options,
          peaks: [
            [
              0, 0.0023595101665705442, 0.012107174843549728,
              0.005919494666159153, -0.31324470043182373, 0.1511787623167038,
              0.2473851442337036, 0.11443428695201874, -0.036057762801647186,
              -0.0968964695930481, -0.03033737652003765, 0.10682467371225357,
              0.23974689841270447, 0.013210971839725971, -0.12377244979143143,
              0.046145666390657425, -0.015757400542497635, 0.10884027928113937,
              0.06681904196739197, 0.09432944655418396, -0.17105795443058014,
              -0.023439358919858932, -0.10380347073078156,
              0.0034454423002898693, 0.08061369508504868, 0.026129156351089478,
              0.18730352818965912, 0.020447958260774612, -0.15030759572982788,
              0.05689578503370285, -0.0009095853311009705, 0.2749626338481903,
              0.2565386891365051, 0.07571295648813248, 0.10791446268558502,
              -0.06575305759906769, 0.15336275100708008, 0.07056761533021927,
              0.03287476301193237, -0.09044631570577621, 0.01777501218020916,
              -0.04906218498945236, -0.04756792634725571, -0.006875281687825918,
              0.04520256072282791, -0.02362387254834175, -0.0668797641992569,
              0.12266506254673004, -0.10895221680402756, 0.03791835159063339,
              -0.0195105392485857, -0.031097881495952606, 0.04252675920724869,
              -0.09187793731689453, 0.0829525887966156, -0.003812957089394331,
              0.0431736595928669, 0.07634212076663971, -0.05335947126150131,
              0.0345163568854332, -0.049201950430870056, 0.02300390601158142,
              0.007677287794649601, 0.015354577451944351, 0.007677287794649601,
              0.007677288725972176
            ]
          ],
          duration: currentlesson.lessonDuration as unknown as number,
          media: audio.current!
        };
      }

      waveform.current = Wavesurfer.create(options);

      if (!useHls) {
        waveform.current.load(currentlesson.audioUrl);
      }
      setPlaying(true);
    }

    return () => waveform.current!.destroy();
  }, []);

  useEffect(() => {
    if (waveform) {
      waveform.current!.on('finish', () => {
        setPlaying(false);
        if (onAudioEnded) onAudioEnded();
      });
      waveform.current!.on('ready', () => {
        setCurrentTime('0:00');
        setDuration(timeCalculator(waveform.current!.getDuration()));
        setPlaying(true);
        waveform.current
          ?.playPause()
          .then(_ => (playWasPerformForTheFirstTimeRef.current = true));
      });
      waveform.current!.on('audioprocess', () => {
        setCurrentTime(timeCalculator(waveform.current!.getCurrentTime()));
      });
    }
  }, [waveform]);

  const timeCalculator = (time: number) => {
    const second = Math.floor(time % 60);
    let strSeconds = `${second}`;
    const minute = Math.floor((time / 60) % 60);
    if (second < 10) {
      strSeconds = `0${second}`;
    }
    return `${minute}:${strSeconds}`;
  };

  const playAudio = () => {
    if (!playWasPerformForTheFirstTimeRef.current) {
      return;
    }

    if (waveform.current?.isPlaying()) {
      waveform.current.pause();
      setPlaying(false);
    } else {
      waveform.current!.play();
      setPlaying(true);
    }
  };

  return (
    <>
      <Box sx={mainComponent}>
        <Stack sx={waveSurferContainer}>
          <Box id="waveform" sx={{ width: '100%' }} />
          <audio ref={audio} style={{ display: 'none' }} />
          <Stack direction="row" sx={waveSurferDuration}>
            <Box>{currentTime}</Box>
            <Box>{duration}</Box>
          </Stack>
        </Stack>
        <Box sx={playPauseRow}>
          <button style={buttonPlay}>
            <img
              width="80px"
              height="80px"
              src={playing ? PauseIcon : PlayIcon}
              onClick={playAudio}
              alt=""
              data-testid="PlayPauseTestId"
            />
          </button>
        </Box>
      </Box>
      <AttachmentSection currentLesson={currentlesson} />
    </>
  );
};

const mainComponent: SxProps = {
  background: '#2B2A2A',
  borderColor: 'fa9e04',
  height: '650px',
  borderRadius: '14px'
};

const playPauseRow: SxProps = {
  paddingTop: '30px',
  display: 'flex',
  justifyContent: 'center'
};

const waveSurferContainer: SxProps = {
  paddingTop: '90px',
  paddingRight: '15%',
  paddingLeft: '15%'
};

const waveSurferDuration: SxProps = {
  justifyContent: 'space-between',
  paddingTop: '2rem'
};

const buttonPlay: CSSProperties = {
  background: 'none',
  border: 'none',
  paddingTop: '30px'
};

export default AudioSection;

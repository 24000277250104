import { CSSProperties, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { StyledCompetenceDetailContainer } from '../SkillComponentsHelper';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import {
  Competence,
  CompetencePost,
  CompetencePut
} from '../../../entities/Achievements';
import { CRUDAction, ErrorCode, HttpStatusEnum } from '../../../core/enums';
import SkillCertificateForm from '../createSkill/SkillCertificateForm';
import { ApplicationState } from '../../../store';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { uploadSkillAttachmentToBlob } from '../../../services/blobStorage-service';
import { AxiosError } from 'axios';
import { Tag } from '../../../entities/Tag';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import SkillIcon from '../../../assets/skill.svg';
import CertificateIcon from '../../../assets/certificate.svg';
import { useAppDispatch } from '../../../store/hooks';

interface EditSkillCertificateProps {
  action: CRUDAction;
  onClose: () => void;
  competence: Competence | undefined;
  onSelectSkill: (id: string) => void;
}

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  padding: '1.5rem !important'
}));

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '1.5rem !important'
}));

const buttonContainerStyle: CSSProperties = {
  display: 'flex',
  alignContent: 'flex-end',
  columnGap: '10px',
  marginLeft: 'auto'
};

const headerContentStyle: CSSProperties = {
  margin: '0 auto',
  width: '90%',
  display: 'flex',
  flexDirection: 'row'
};

const getUnitTypeValue = (unitTypeName: string) => {
  switch (unitTypeName) {
    case 'CourseSkill':
    case 'CourseCertificate':
      return '1';
    case 'LearningPlanSkill':
    case 'LearningPlanCertificate':
      return '2';
    default:
      return '0';
  }
};

const castToDto = (comp: Competence) => {
  const dto: CompetencePut = {
    id: comp.id,
    name: comp.name,
    description: comp.description,
    duration: comp.duration,
    durationUnit: comp.durationUnit,
    notifyDaysBeforeExpiration: comp.notifyDaysBeforeExpiration,
    learningUnitId: comp.learningUnitId,
    governingBody: comp.governingBody ? comp.governingBody : '',
    competenceType: comp.competenceType,
    learningUnitType: getUnitTypeValue(comp.competenceLearningUnitType),
    attachments: [],
    awarders: comp.awarders.map(a => a.employeeId),
    awarderTags: comp.awarders
      ? comp.awarders.map(a => ({ id: a.employeeId, name: a.email }) as Tag)
      : [],
    durationNeverExpires: comp.durationNeverExpires,
    filesToAttach: comp.attachments.map(x => new File([], x.name)),
    generatePDF: comp.generatePDF,
    learningUnitName: comp.learningUnitName,
    onsiteTrainingsLinked:
      comp.onsiteTrainingsLinked?.map(oT => oT.onsiteTrainingId) ?? [],
    onsiteTrainingToAssociate: comp.onsiteTrainingsLinked
      ? comp.onsiteTrainingsLinked.map(
          oT => ({ id: oT.onsiteTrainingId, name: oT.name }) as Tag
        )
      : []
  };
  return dto;
};

const EditSkillCertificate = ({
  action,
  onClose,
  competence,
  onSelectSkill
}: EditSkillCertificateProps) => {
  const { t } = useTranslation(['skills', 'common']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const isEditing = action === CRUDAction.Edit;
  const [lcompetence, setLcompetence] = useState<
    CompetencePut | CompetencePost | undefined
  >(isEditing && competence ? castToDto(competence) : undefined);
  const [compName, setCompName] = useState<string>(
    isEditing && competence ? competence.name : ''
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );
  const availableLearningUnits = useSelector(
    (state: ApplicationState) => state.achievements?.availableLearningUnits
  );
  const availableOnsiteTrainings = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingsAll
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
    dispatch(achievementActions.getAvailableLearningUnits(competence?.id));
    dispatch(onsiteTrainingActions.loadAllOnsiteTrainings());

    return () => {
      dispatch(achievementActions.setAvailableLearningUnits(null));
    };
  }, []);

  useEffect(() => {
    if (action === CRUDAction.Edit && competence) {
      setLcompetence(castToDto(competence));
      setCompName(competence.name);
    }
  }, [action, competence]);

  const handleSave = async () => {
    setIsSaving(true);

    const competenceToSave = { ...lcompetence! };
    for (let i = 0; i < lcompetence!.filesToAttach.length; i++) {
      const file = lcompetence!.filesToAttach[i];
      try {
        let blobName = '';
        if (
          competence?.attachments.filter(x => x.name === file.name).length == 0
        ) {
          blobName = await uploadSkillAttachmentToBlob(file);
        }
        competenceToSave.attachments.push({
          name: file.name,
          fileType: file.type,
          url: blobName
        });
      } catch (e) {
        enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
          variant: 'error',
          autoHideDuration: 3000
        });
        setIsSaving(false);
        return;
      }
    }

    try {
      await dispatch<Promise<void>>(
        achievementActions.updateCompetence({
          ...competenceToSave,
          id: competence!.id
        })
      );
      dispatch(achievementActions.forceGetAllCompetencies(true));
      enqueueSnackbar(t('skillUpdatedSuccessfully', { ns: 'skills' }), {
        variant: 'success',
        autoHideDuration: 3000
      });
      onClose();
    } catch (e) {
      const error = e as AxiosError;
      let errorKey = 'saveSkillCertitifcateError';
      if (error.response?.status === HttpStatusEnum.UnprocessableEntity) {
        errorKey =
          (error.response?.data as any).errorCode === ErrorCode.DuplicatedName
            ? 'skillNameAlreadyExists'
            : 'learningUnitIsAlreadyInUse';
      }
      enqueueSnackbar(t(errorKey, { ns: 'skills' }), {
        variant: 'error',
        autoHideDuration: 4000
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    await dispatch<Promise<void>>(
      achievementActions.deleteCompetence(competence!.id)
    );
    dispatch(achievementActions.forceGetAllCompetencies(true));
    setShowDeleteModal(false);
    enqueueSnackbar(t('skillDeletedSuccessfully', { ns: 'skills' }), {
      variant: 'success',
      autoHideDuration: 3000
    });
    if (onSelectSkill) onSelectSkill('');
    onClose();
  };

  if (
    !activeEmployees ||
    !availableLearningUnits ||
    !availableOnsiteTrainings
  ) {
    return (
      <StyledCompetenceDetailContainer
        sx={{
          marginLeft: { md: '1rem' },
          marginTop: { xs: '1rem', md: 0 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '652px'
        }}
      >
        <CircularProgress />
      </StyledCompetenceDetailContainer>
    );
  }

  return (
    <>
      <HeaderBox>
        <div style={headerContentStyle}>
          <div>
            <Typography variant="h5" style={{ marginLeft: '5px' }}>
              {compName}
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <img
                src={
                  competence?.competenceType == 'Skill'
                    ? SkillIcon
                    : CertificateIcon
                }
                style={{ width: 24, height: 24 }}
                alt="Icon for Skill"
              />
              <span>
                {lcompetence?.competenceType == 'Skill'
                  ? t('skill', { ns: 'skills' })
                  : t('certificate', { ns: 'skills' })}
              </span>
            </div>
          </div>
          <div style={buttonContainerStyle}>
            <BasicButton
              width="160px"
              height="42px"
              color="secondary"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                setShowDeleteModal(true);
              }}
              disable={isSaving}
            >
              {lcompetence?.competenceType == 'Skill'
                ? t('deleteSkill', { ns: 'skills' })
                : t('deleteCertificate', { ns: 'skills' })}
            </BasicButton>
            <BasicButton
              width="115px"
              height="42px"
              color="secondary"
              onClick={onClose}
              style={{ fontWeight: 'bold' }}
              disable={isSaving}
            >
              {t('cancel', { ns: 'common' })}
            </BasicButton>
          </div>
        </div>
      </HeaderBox>

      <ContentBox id="formContainer" style={{ paddingLeft: '20px' }}>
        <SkillCertificateForm
          competence={lcompetence}
          setCompetence={setLcompetence}
          onSaveForm={handleSave}
          isSaving={isSaving}
          isEdit={true}
        />
      </ContentBox>
      {showDeleteModal && (
        <ConfirmationModal
          show={true}
          showSave={true}
          showCancel={true}
          maxWidth="sm"
          saveText={'Ok'}
          cancelText={t('cancel', { ns: 'common' })}
          title={t('deleteSkill', { ns: 'skills' })}
          onSave={() => {
            handleDelete();
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          isGrayCancelButton={true}
          showModalContentCentered={true}
        >
          <p>{t('confirmDeleteSkill', { ns: 'skills' })}</p>
        </ConfirmationModal>
      )}
    </>
  );
};

export default EditSkillCertificate;

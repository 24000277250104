import config from '../config';
const YT_API_KEY = config.YOUTUBE_API_KEY;

// To Know If it's a Youtube Video or Uploaded
export const matchYoutubeUrl = (url: string) => {
  if (!url) {
    return false;
  }
  const path =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w-]{10,20})/i;
  return !!url.match(path);
};

// To send Get Request and check if ID is valid
export const validateYouTubeVideoId = (youTubeVideoId: string) => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=id&id=${youTubeVideoId}&key=${YT_API_KEY}`;
  return fetch(url)
    .then(response => response.json())
    .catch(e => console.log('Error', e));
};

// To Get the ID from Youtube Url
export function getYouTubeVideoIdByUrl(url: string) {
  if (!url) {
    return '';
  }
  const reg =
    /^(https?:)?(\/\/)?((www\.|m\.)?youtube(-nocookie)?\.com\/((watch)?\?(feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w-]{10,20})/i;
  const match = url.match(reg);
  if (match) {
    return match[9];
  } else {
    return '';
  }
}

export const getYoutubeVideoDuration = (youTubeVideoId: string) => {
  const url = `https://www.googleapis.com/youtube/v3/videos?part=contentDetails&id=${youTubeVideoId}&key=${YT_API_KEY}`;
  return fetch(url)
    .then(response => response.json())
    .catch(e => console.log('Error', e));
};

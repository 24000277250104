import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { actionCreators as onsiteTrainingActions } from '../actions/onsiteTrainings';
import {
  DefaultPageSize,
  DefaultSearchDebounceTime,
  SortingOptions
} from '../core/constants';
import { useDebounce } from '../hooks/useDebounce';
import { Sorting } from '../hooks/useContentLibraryNavigation';
import { ApplicationState } from '../store';
import { useAppDispatch } from '../store/hooks';

const useOnsiteTrainingList = () => {
  const dispatch = useAppDispatch();
  const [searchKey, setSearchKey] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>(SortingOptions[3].value);
  const scheduledListPage = useRef<number>(1);
  const onsiteTrainingClasses = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingClasses
  );
  const forceRefreshOnsiteTrainingClasses = useSelector(
    (state: ApplicationState) =>
      state.onsiteTrainings?.forceRefreshScheduleOnsiteTrainingClasses
  );

  useEffect(() => {
    fetchOnsiteTrainingClasses();
  }, [searchKey, sortKey]);

  useEffect(() => {
    if (forceRefreshOnsiteTrainingClasses) {
      fetchOnsiteTrainingClasses();
    }
  }, [forceRefreshOnsiteTrainingClasses]);

  const fetchOnsiteTrainingClasses = () => {
    scheduledListPage.current = 1;
    dispatch(onsiteTrainingActions.setOnsiteTrainingClasses(null));
    dispatch(
      onsiteTrainingActions.loadOnsiteTrainingClasses(
        1,
        DefaultPageSize,
        searchKey,
        sortKey,
        'incomplete'
      )
    );
  };

  const handleSearch = useDebounce<string>(
    value => setSearchKey(value),
    DefaultSearchDebounceTime
  );

  const handleSort = (values: string[]) => {
    const orderCriteria = values[0] as Sorting;
    setSortKey(orderCriteria);
  };

  return {
    api: {
      handleSearch,
      handleSort
    },
    state: {
      onsiteTrainingClasses,
      searchKey,
      sortKey
    },
    ref: {
      scheduledListPage
    }
  };
};
export default useOnsiteTrainingList;

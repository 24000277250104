import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import {
  AnalyticsCompletedAssignmentDetail,
  AnalyticsCompletedQuizAssignments
} from '../../../entities/Analytics';
import { ModalDataGrid } from '../../../components/Analytics';
import {
  getFullNameColumn,
  getAssignmentMethodColumn,
  getCompletedOnColumn
} from '../common/utilAnalyticsColumnDefinition';

interface CompletedListProps {
  completedData?:
    | AnalyticsCompletedAssignmentDetail[]
    | AnalyticsCompletedQuizAssignments[]
    | null;
}

const CompletedList = ({ completedData }: CompletedListProps) => {
  const { t } = useTranslation(['employees', 'analytics']);

  const columns: GridColDef[] = (() => [
    getFullNameColumn(t('employee', { ns: 'employees' })),
    getAssignmentMethodColumn(t('assignmentMethod', { ns: 'analytics' })),
    getCompletedOnColumn(
      'completedOn',
      t('completedOn', { ns: 'analytics' }),
      130
    )
  ])();

  return <ModalDataGrid rows={completedData} columns={columns} />;
};

export default CompletedList;

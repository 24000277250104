import { EmployeeCompetenceAttachment } from './Achievements';
import { Tag } from './Tag';
export interface CertificationBase {
  id: string;
  name: string;
  durationNeverExpires: boolean;
  thirdPartyCertificateRequired: boolean;
  description?: string | null;
  awarders: Awarder[];
  certifyingBody: string;
}

export interface Certification extends CertificationBase {
  durationUnit: DurationUnit;
  employeesCertified: number;
  createdAt: Date;
  duration: number;
  expiresAt: Date;
  notifyDaysBeforeExpiration?: string | null;
}

export interface CertificationPostPut extends CertificationBase {
  durationUnit: string;
  duration: string;
  awardersTags: Tag[];
  notifyDaysBeforeExpirationTags: Tag[];
}

export interface Awarder {
  id: string;
  employeeId: string;
  createdAt: Date;
}

export interface MyCertification {
  employeeCertificationId: string;
  certificationName: string;
  duration: number;
  durationUnit: DurationUnit;
  expirationDate: Date | null;
  certifyingBody: string;
  createdAt: Date;
  status: MyCertificationStatus;
  awarderId: string;
  awarderFullName: string;
  generateSystemCertificate: boolean;
  attachedFiles: EmployeeCompetenceAttachment[];
}

export interface PdfCertification {
  companyLogoUrl: string;
  name: string;
  employeeName: string;
  formattedCreatedAt: string;
  formattedExpirationDate: string;
  awarderName: string;
}

export enum DurationUnit {
  Year = 'Year',
  Month = 'Month',
  None = 'None'
}

export enum MyCertificationStatus {
  None = 'None',
  Expired = 'Expired',
  Expiring = 'Expiring',
  Pending = 'Pending',
  New = 'New'
}

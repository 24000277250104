import { CSSProperties } from 'react';
import { IconButton, Stack } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { enqueueSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';

const TextLinkSpan = styled('span')(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '14px',
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export const getFileNameColumn = (headerName: string): GridColDef => ({
  field: 'fileName',
  headerName: headerName,
  minWidth: 250,
  sortable: false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, string>) => {
    return (
      <div className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <TextLinkSpan
            onClick={() =>
              window.open(
                params.row.competenceFile.url,
                '_blank',
                'noopener,noreferrer'
              )
            }
          >
            {params.row.competenceFile.name}
          </TextLinkSpan>
        </div>
      </div>
    );
  }
});

export const getDownloadColumn = (headerName: string): GridColDef => ({
  field: 'dowloadButton',
  headerName: headerName,
  minWidth: 100,
  sortable: false,
  align: 'center',
  renderCell: (params: GridRenderCellParams<any, string>) => {
    const handleDownloadFile = (url: string, fileName: string) => {
      fetch(url)
        .then(res => res.blob())
        .then(blob => {
          const blobUrl = window.URL.createObjectURL(new Blob([blob]));
          const aTag = document.createElement('a');
          aTag.href = blobUrl;
          aTag.setAttribute('download', fileName!);
          document.body.appendChild(aTag);
          aTag.click();
          aTag.remove();
        })
        .catch(error =>
          enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 3000
          })
        );
    };
    return (
      <Stack direction="row" spacing={2}>
        <IconButton
          aria-label="download"
          size="large"
          color="info"
          style={circularButton}
          onClick={() =>
            handleDownloadFile(
              params.row.competenceFile.url,
              params.row.competenceFile.name
            )
          }
        >
          <DownloadForOfflineRoundedIcon
            fontSize="medium"
            sx={{ color: '#1B1C1C' }}
          />
        </IconButton>
      </Stack>
    );
  }
});

export const getRemoveColumn = (
  headerName: string,
  onDeletePressed?: (competenceAttachment: any) => void
): GridColDef => ({
  field: 'removeButton',
  headerName: headerName,
  minWidth: 100,
  sortable: false,
  align: 'center',
  renderCell: (params: GridRenderCellParams<any, string>) => {
    return (
      <IconButton
        aria-label="remove"
        size="large"
        color="info"
        style={circularButton}
        onClick={() => {
          if (onDeletePressed) {
            onDeletePressed(params.row);
          }
        }}
      >
        <DeleteRoundedIcon fontSize="medium" sx={{ color: '#1B1C1C' }} />
      </IconButton>
    );
  }
});

export const getContentNameColumn = (headerName: string): GridColDef => ({
  field: 'contentName',
  headerName: headerName,
  minWidth: 250,
  sortable: false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <div className="d-flex ps-1 pt-2 pb-2">
      <div className="d-flex flex-column px-3">
        <span style={{ fontSize: 14 }}>{params.row.name}</span>
        <span style={{ fontSize: 12, color: '#999999' }}>
          {params.row.learningUnitType}
        </span>
      </div>
    </div>
  )
});

const circularButton: CSSProperties = {
  backgroundColor: '#C4C4C4',
  width: '35px',
  height: '35px'
};

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Certification } from '../../../entities/Certification';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { AwardEmployeeIcon } from '@buildwitt/component-library';
import { styled } from '@mui/system';
import EditIcon from '@buildwitt/component-library/src/icons/edit.svg';
import { UserRoles } from '../../../core/constants';
import useUserRole from '../../../hooks/useUserRole';
import Tooltip from '@mui/material/Tooltip';

const CustomButton = styled(Button)({
  borderRadius: '20px',
  border: '2px solid #FF9E00',
  background: '#1E1E1E',
  textTransform: 'none',
  color: 'white',
  '&.Mui-disabled': {
    border: '1px solid rgba(255, 255, 255, 0.12)',
    background: '#B0B0B0',
    color: '#414141',
    opacity: 1
  }
});
export const getCertificationName = (headerName: string) => ({
  headerName: headerName,
  field: 'name',
  width: 200,
  flex: 1,
  renderCell: (params: GridRenderCellParams<Certification>) => (
    <Typography>{params.row.name}</Typography>
  ),
  minWidth: 100
});

export const getCertificationDuration = (
  headerName: string,
  neverExpiresLabel: string
) => ({
  headerName: headerName,
  field: 'duration',
  renderCell: (params: GridRenderCellParams<Certification>) => (
    <Typography>
      {!params.row.durationNeverExpires
        ? `${params.row.duration} ${params.row.durationUnit}`
        : neverExpiresLabel}
    </Typography>
  ),
  width: 150
});

export const getEmployeesCertified = (
  headerName: string,
  buttonLabel: string,
  onClick?: (selectedCertification: Certification) => void
) => ({
  headerName: headerName,
  field: 'employeesCertified',
  renderCell: (params: GridRenderCellParams<Certification>) => (
    <Grid
      container
      display={'flex'}
      style={{ width: '100%' }}
      justifyContent={'space-between'}
      direction={'row'}
      alignItems={'center'}
    >
      <Grid item>
        <Typography>{params.row.employeesCertified}</Typography>
      </Grid>
      <Grid item>
        <CustomButton
          disabled={params.row.employeesCertified <= 0}
          onClick={() => {
            if (onClick) onClick(params.row);
          }}
        >
          {buttonLabel}
        </CustomButton>
      </Grid>
    </Grid>
  ),
  width: 260
});

export const GetAwardEmployee = (
  headerName: string,
  onClick?: (certification: Certification) => void
) => {
  return {
    headerName: headerName,
    field: 'awardEmployee',
    renderCell: (params: GridRenderCellParams<Certification>) => (
      <Grid
        display={'flex'}
        style={{ width: '100%' }}
        justifyContent={'center'}
      >
        <IconButton
          onClick={() => {
            if (onClick) onClick(params.row);
          }}
        >
          <AwardEmployeeIcon />
        </IconButton>
      </Grid>
    ),
    sortable: false,
    width: 150
  };
};

export const GetEditCertification = (
  headerName: string,
  disabledTooltipText: string,
  onClick?: (selectedCertification: Certification) => void
) => {
  const [userHasRole] = useUserRole();
  const isAdminUser = userHasRole([UserRoles.CompanyAdmin]);
  return {
    headerName: headerName,
    field: 'edit',
    renderCell: (params: GridRenderCellParams<Certification>) => (
      <Grid
        display={'flex'}
        style={{ width: '100%' }}
        justifyContent={'center'}
      >
        {isAdminUser ? (
          <IconButton
            onClick={() => {
              if (onClick) onClick(params.row);
            }}
          >
            <img src={EditIcon} alt={params.row.name} />
          </IconButton>
        ) : (
          <Tooltip title={disabledTooltipText}>
            <div>
              <IconButton disabled={true}>
                <img src={EditIcon} alt={params.row.name} />
              </IconButton>
            </div>
          </Tooltip>
        )}
      </Grid>
    ),
    sortable: false,
    width: 65
  };
};

import Box from '@mui/material/Box';
import { t } from 'i18next';

import { AssignedLearningPlan } from '../../entities/LearningPlaylist';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import NewLearningPlanCard from './NewLearningPlanCard';
import useLearningPlanCard from './useLearningPlanCard';
import { learningPlanWrapperStyle } from './CommonStyles';
import ButtonsSelfAssignSection from '../AssignmentsButtonsSection/ButtonsSelfAssignSection';

interface CompleteLearningPlanCardProps {
  assignedLearningPlan: AssignedLearningPlan;
}

const CompleteLearningPlanCard: React.FC<CompleteLearningPlanCardProps> = ({
  assignedLearningPlan
}) => {
  const { learningPlan, dueDateMoment, assignmentId } = assignedLearningPlan;
  const { id, companyId, name } = learningPlan;
  const fomatedDueDate = dueDateMoment ? dueDateMoment!.format('MM.DD.YY') : '';

  const { takenTimesLearningPlans, handleStartLearningPlan } =
    useLearningPlanCard();

  return (
    <Box sx={learningPlanWrapperStyle}>
      <NewLearningPlanCard
        key={id}
        learningPlan={learningPlan}
        isComplete={true}
        showEdit={false}
        isDraft={false}
        dueDate={fomatedDueDate}
        showCompletedAsNormal={true}
        averageLessonTime={
          learningPlan.avgLessonTime
            ? secondsToHHMMSS(learningPlan.avgLessonTime)
            : '00:00:00'
        }
        wrapperWidth="100%"
        takenTimes={
          takenTimesLearningPlans ? takenTimesLearningPlans[id] : undefined
        }
      >
        <ButtonsSelfAssignSection
          onClickStart={() =>
            handleStartLearningPlan(learningPlan, fomatedDueDate, assignmentId!)
          }
          startButtonCaption={t('reenroll', { ns: 'common' })}
          startButtonColor={'revisit'}
          resourceType={'Learning Plan'}
          id={id}
          companyId={companyId}
          title={name}
        />
      </NewLearningPlanCard>
    </Box>
  );
};

export default CompleteLearningPlanCard;

import { CollapsibleDataTable } from '@buildwitt/component-library';
import { actionCreators } from '../../../actions/certifications';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridPaginationModel,
  GridRow,
  GridRowProps
} from '@mui/x-data-grid-premium';
import { ArrowForwardIos } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { useState } from 'react';
import { pageCertificationsState } from '../../../core/constants';
import { useTranslation } from 'react-i18next';
import {
  getAttachFiles,
  getCertificationName,
  getEditCol,
  getEmployeeId,
  getExpirationDate
} from './ColumnDefinitions';
import { useAppDispatch } from '../../../store/hooks';
import { Typography } from '@mui/material';
import SkeletonLoadingOverlay from '../SkeletonLoadingOverlay';

const EmployeeCertificationsTable: React.FC<{
  includeAllEmployees: boolean;
  searchString: string | undefined;
  onEditEmployeeCertifications: (
    employeeId: string,
    employeeName: string
  ) => void;
}> = ({ includeAllEmployees, onEditEmployeeCertifications, searchString }) => {
  const employeeCertifications = useSelector(
    (state: ApplicationState) => state.certifications?.employeeCertifications
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.certifications?.isLoading
  );
  const { t } = useTranslation(['certifications']);
  const dispatch = useAppDispatch();
  const { page, pageSize, sortBy } = pageCertificationsState;
  const [pageModel, setPageModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page,
    pageSize
  });

  const columns_row_grouping: GridColDef[] = [
    getEmployeeId(t('employeeName'), employeeCertifications?.rows ?? []),
    getCertificationName(t('certifications')),
    getExpirationDate(t('Expiration Date'), t('neverExpiresAt')),
    getAttachFiles(t('attachedFiles'), t('clickEditButton')),
    getEditCol(onEditEmployeeCertifications, employeeCertifications?.rows ?? [])
  ];

  const onHandlePagination = (model: GridPaginationModel) => {
    setPageModel({ page: model.page + 1, pageSize: model.pageSize });
    dispatch(
      actionCreators.getEmployeeCertifications(
        model.page + 1,
        model.pageSize,
        sortBy.employeeName,
        includeAllEmployees,
        searchString
      )
    );
  };

  return (
    <CollapsibleDataTable
      columns={columns_row_grouping}
      rows={isLoading ? [] : employeeCertifications?.rows ?? []}
      loading={isLoading ?? false}
      disableColumnMenu={true}
      rowGroupingModel={['employeeId']}
      sx={{
        '& .MuiTablePagination-displayedRows': { margin: 0 },
        '& .MuiDataGrid-overlayWrapper': { background: '#1B1C1C' }
      }}
      groupingColDef={{
        hideDescendantCount: true,
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <Typography style={{ paddingLeft: '50px' }}>
              {params.colDef.headerName}
            </Typography>
          );
        }
      }}
      hideFooterSelectedRowCount={true}
      disableRowSelectionOnClick
      pagination
      paginationMode="server"
      pageSizeOptions={[10, 20, 50]}
      rowCount={employeeCertifications?.totalCount ?? 0}
      paginationModel={{
        page: pageModel.page - 1,
        pageSize: pageModel.pageSize
      }}
      columnVisibilityModel={{ employeeId: false }}
      slots={{
        groupingCriteriaCollapseIcon: ArrowCollapseIcon,
        groupingCriteriaExpandIcon: ArrowExpandIcon,
        row: props => ExpandableRow({ ...props }),
        loadingOverlay: SkeletonLoadingOverlay
      }}
      onPaginationModelChange={onHandlePagination}
    />
  );
};

export default EmployeeCertificationsTable;

const ExpandableRow: React.FC<GridRowProps> = ({ ...props }) => {
  const { row } = props;
  const stringRowType = typeof row!.id !== 'string';

  return (
    <GridRow
      {...props}
      rowHeight={stringRowType ? props.rowHeight : 35}
      style={{
        borderTop: stringRowType ? '1px solid #5E5E5E' : '0px',
        borderBottom: stringRowType ? '1px solid #5E5E5E' : '0px',
        background: !stringRowType ? '#232424' : ''
      }}
    />
  );
};

const ArrowExpandIcon = () => (
  <ArrowForwardIos sx={{ transform: 'rotate(0deg)' }} />
);

const ArrowCollapseIcon = () => (
  <ArrowForwardIos sx={{ transform: 'rotate(90deg)' }} />
);

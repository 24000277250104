import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import Layout from './components/layouts/Layout';
import * as firebase from './firebase';
import LessonPreview from './pages/lessons/LessonPreview';
import Settings from './pages/settings/Settings';
import Employees from './pages/employees/Employees';
import Assignments from './pages/assignments/Assignments';
import LearningPlanPreview from './pages/learningPlans/LearningPlanPreview';
import TakeQuiz from './pages/Quizzes/TakeQuiz';
import Dashboard from './pages/dashboard/Dashboard';
import ContentLibrary from './pages/contentLibrary/ContentLibrary';
import ClickSafetyCourse from './pages/contentLibrary/ClickSafety/ClickSafetyCourse';
import { Mobile } from './pages/Mobile';
import { actionCreators as notificationsActions } from './actions/notifications';
import { actionCreators as contentLibraryActions } from './actions/contentLibrary';
import { actionCreators as coursesActions } from './actions/courses';
import config from './config';
import CreateEditLesson from './pages/lessons/v1.2/CreateEditLesson';
import CreateEditCourse from './pages/courses/v1.2/CreateEditCourse';

import { courseRoutes } from './routes/CoursesRoutes';
import CreateEditLearningPlan from './pages/learningPlans/v1.2/CreateEditLearningPlan';

import './site.css';
import './custom-site.css';
import { useSelector } from 'react-redux';
import { ApplicationState } from './store';
import Analytics from './pages/analytics/Analytics';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import LearningPlanPlayAssignment from './pages/learningPlans/LearningPlanPlayAssignment';
import LessonPlayAssignment from './pages/lessons/LessonPlayAssignment';
import ProtectedRoute from './components/core/ProtectedRoute/ProtectedRoute';
import { UserRoles, appPermissions } from './core/constants';
import useUserRole from './hooks/useUserRole';
import SkillsPage from './pages/skills/SkillsPage';
import OnsiteTraining from './pages/onsiteTraining';
import AwardSkill from './pages/skills/bulkAward/AwardSkill';
import CreateEditOnsiteTraining from './pages/onsiteTraining/createEditOnsiteTraining/CreateEditOnsiteTraining';
import { OnsiteTrainingPreviewDetails } from './pages/onsiteTraining/onsiteTrainingDetails/OnsiteTrainingPreviewDetails';
import { OnsiteTrainingClassDetails } from './pages/onsiteTraining/onsiteTrainingDetails/OnsiteTrainingClassDetails';
import OnsiteTrainingCheckIn from './pages/onsiteTraining/onsiteTrainingCheckIn/onsiteTrainingCheckIn';
import { withDevCycleProvider } from '@devcycle/react-client-sdk';
import { useDevCycleIdentifier } from './hooks/useDevCycleSdk';
import PageDoesntExist from './pages/PageDoesntExist';
import Profile from './pages/profile/Profile';
import { useAppDispatch } from './store/hooks';
import Certifications from './pages/certifications/Certifications';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const App = () => {
  useDevCycleIdentifier();
  firebase.analytics.app.automaticDataCollectionEnabled = true;
  const thirdPartyCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.thirdPartyCompanies
  );

  const dispatch = useAppDispatch();
  const [userHasRole] = useUserRole();

  React.useEffect(() => {
    dispatch(notificationsActions.requestNotifications());
    if (!thirdPartyCompanies) {
      dispatch(contentLibraryActions.requestThirdPartyCompanies());
      dispatch(coursesActions.setCoursesByCompany(null));
    }
  }, []);

  const isAdminUser = userHasRole([
    UserRoles.CompanyAdmin,
    UserRoles.GroupLead,
    config.USE_ORG_CHART ? UserRoles.Supervisor : ''
  ]);

  const defaultEntryPoint = !isAdminUser ? '/assignments' : '/dashboard';

  return (
    <div className="App" id="wrapper">
      <Layout>
        <Routes>
          <Route
            path="/"
            element={<Navigate to={defaultEntryPoint} replace />}
          />
          {courseRoutes.map(route => route)}
          <Route path="/lessons/:id/preview" element={<LessonPreview />} />
          <Route path="/lessons/play" element={<LessonPreview />} />
          <Route
            path="/lessons/assignment/play/:id"
            element={<LessonPlayAssignment />}
          />
          <Route path="/lessons/preview" element={<LessonPreview />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/assignments" element={<Assignments />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute bwAction={appPermissions.VIEW_DASHBOARD}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route path="/content-library" element={<ContentLibrary />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/onsite-training" element={<OnsiteTraining />} />
          <Route
            path="/onsite-training/:action"
            element={<CreateEditOnsiteTraining />}
          />
          <Route
            path="/onsite-training-preview/:onsiteTrainingId"
            element={<OnsiteTrainingPreviewDetails />}
          />
          <Route
            path="/onsite-training-class/:onsiteTrainingClassId"
            element={<OnsiteTrainingClassDetails />}
          />
          <Route
            path="/onsite-training-check-in/:classId"
            element={<OnsiteTrainingCheckIn />}
          />
          <Route path="/employees" element={<Employees />} />
          <Route
            path="/learning-plans/preview/:id"
            element={<LearningPlanPreview />}
          />
          <Route
            path="/learning-plans/assignment/play/:id"
            element={<LearningPlanPlayAssignment />}
          />
          <Route path="/lesson/quiz" element={<TakeQuiz />} />
          <Route path="/lesson/quiz/results" element={<TakeQuiz />} />
          <Route path="/Mobile" element={<Mobile />} />
          <Route
            path="/click-safety-course/:courseId"
            element={<ClickSafetyCourse />}
          />
          <Route
            path="/content-library/lessons/create"
            element={<CreateEditLesson />}
          />
          <Route
            path="/content-library/lessons/:id/edit"
            element={<CreateEditLesson />}
          />
          <Route
            path="/content-library/courses/create"
            element={<CreateEditCourse />}
          />
          <Route
            path="/content-library/courses/:id/edit"
            element={<CreateEditCourse />}
          />
          <Route
            path="/content-library/learning-plans/create"
            element={<CreateEditLearningPlan />}
          />
          <Route
            path="/content-library/learning-plans/:id/edit"
            element={<CreateEditLearningPlan />}
          />
          <Route path="/skills" element={<SkillsPage />} />
          <Route path="/grant/:skillId/:skillName" element={<AwardSkill />} />
          <Route
            path="/certifications"
            element={
              <ProtectedRoute
                showDeniedIfNotAllowed
                bwAction={appPermissions.VIEW_CERTIFICATIONS}
              >
                <Certifications />
              </ProtectedRoute>
            }
          />
          <Route path="/profile" element={<Profile />} />
          <Route element={<PageDoesntExist />} />
          <Route path="/skills" element={<SkillsPage />} />
          <Route path="/grant/:skillId/:skillName" element={<AwardSkill />} />
        </Routes>
      </Layout>
    </div>
  );
};

export default withDevCycleProvider({
  sdkKey: config.DEVCYCLE_SDK_KEY,
  options: {
    deferInitialization: true
  }
})(App);

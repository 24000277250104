import { Stack, Typography, useTheme } from '@mui/material';
import { AssignedOnsiteTrainingClass } from '../../entities/LearningPlaylist';
import moment from 'moment';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import React from 'react';

interface AssignmentDate {
  dateUtc: Date;
  isComplete: boolean;
}

export const OnsiteTrainingDates = ({
  assignedClass,
  caption
}: {
  assignedClass: AssignedOnsiteTrainingClass;
  caption: string;
}) => {
  const theme = useTheme();
  const assignmentDates = assignedClass.assignments
    .map(item => {
      return {
        dateUtc: item.dateUtc,
        isComplete: item.isComplete
      } as AssignmentDate;
    })
    .sort((a: AssignmentDate, b: AssignmentDate) =>
      a.dateUtc > b.dateUtc ? 1 : -1
    );
  const currentDate = moment().format('MM.DD.YYYY');
  return (
    <Stack
      direction="row"
      spacing={0.5}
      sx={{ flexWrap: 'wrap' }}
      alignItems="center"
    >
      <Typography
        sx={{
          fontSize: '13px',
          color: theme.palette.grey[50]
        }}
      >
        {caption}
      </Typography>
      {assignmentDates.map(
        (
          assignmentDate: AssignmentDate,
          index: number,
          array: AssignmentDate[]
        ) => {
          const formattedDate = absUtcDateToLocalMoment(
            assignmentDate.dateUtc
          ).format('MM.DD.YYYY');
          const color =
            formattedDate === currentDate && !assignmentDate.isComplete
              ? theme.palette.primary.main
              : theme.palette.common.white;

          return (
            <React.Fragment key={assignmentDate.toString()}>
              <Typography sx={{ fontSize: '13px', color: color }}>
                {absUtcDateToLocalMoment(assignmentDate.dateUtc).format(
                  'MM.DD.YYYY'
                )}
              </Typography>
              {!!(index < array.length - 1) && <span>&#8226;</span>}
            </React.Fragment>
          );
        }
      )}
    </Stack>
  );
};

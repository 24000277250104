import { useContext, useState } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';
import { FilterOptions } from '../../../core/constants';
import { LangContent } from '../../../hooks/useContentLibraryNavigation';

const useLearningUnitLangFilter = () => {
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const [langFilter, setLangFilter] = useState<string>(
    userNavigation?.langFilter ?? FilterOptions[0].value
  );

  const handleChangeLangFilter = (items: string[]) => {
    const option = items[0] as LangContent;
    userNavigationApi?.changeLangFilter(option);
    setLangFilter(option);
  };

  return [langFilter, handleChangeLangFilter] as const;
};

export default useLearningUnitLangFilter;

import { FC } from 'react';
import TextArea from '../core/TextArea/TextArea';
import FormLabel from '../core/FormLabel/FormLabel';
import { Question } from '../../entities/Assessment';
import { useTranslation } from 'react-i18next';
import NumericInput from '../core/NumericInput/NumericInput';

const panelStyle = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column' as const
};

export interface NumberQuestionDefinitionProps {
  question: Question;
  changeHandler: (prop: string, value: any) => void;
  variant?: 'light' | 'dark' | undefined;
  index: number;
}

const NumberQuestionDefinition: FC<NumberQuestionDefinitionProps> = ({
  question,
  changeHandler,
  index,
  variant = 'light'
}) => {
  const { t } = useTranslation(['assessments']);

  return (
    <div style={panelStyle}>
      <FormLabel>
        {t('question', { ns: 'assessments' })}{' '}
        <span className="text-danger">*</span>
      </FormLabel>
      <TextArea
        id="assessmentQuestion"
        name="questionText[]"
        dataTestId={`numberPromptInput${index}`}
        dataTrackId={`numberPromptInput${index}`}
        value={question.questionText}
        onChangeValue={value => changeHandler('questionText', value)}
        placeholderText={t('writeQuestionHere', { ns: 'assessments' })}
        width="100%"
        rows={5}
        variant={variant}
      />
      <br />
      <FormLabel>
        {t('correctNumber', { ns: 'assessments' })}:{' '}
        <span className="text-danger">*</span>
      </FormLabel>
      <div style={panelStyle}>
        <NumericInput
          id="number"
          name="numberCorrectAnswer[]"
          value={question.numberCorrectAnswer}
          dataTestId={`numberCorrectAnswerInput${index}`}
          dataTrackId={`numberCorrectAnswerInput${index}`}
          onChangeValue={value => changeHandler('numberCorrectAnswer', value)}
          placeholderText={t('writeYourAnswer', { ns: 'assessments' })}
          width="100%"
          height="35px"
          variant={variant}
          minValue={0}
          maxLength={10}
        />
      </div>
      <br />
    </div>
  );
};

export default NumberQuestionDefinition;

export const getFormattedTime = (seconds: number) => {
  const hours = Math.floor(seconds / 60 / 60);
  let minutes = ((seconds / 60) % 60).toFixed();
  minutes = minutes.length === 1 ? '0' + minutes : minutes;

  return `${hours}:${minutes}`;
};

export const dataURLtoFile = (dataURL: string, filename: string) => {
  const stringArray = dataURL.split(',');
  const mime = stringArray[0].match(/:(.*?);/)![1];
  const baseString = atob(stringArray[1]);
  let n = baseString.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = baseString.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

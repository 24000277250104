import axios from 'axios';
import config from '../config';

const updateEmployeeProfilePhoto = (
  employeeId: string,
  profilePictureUrl: string
) => {
  return axios.put(`${config.IDENTITY_URL}/api/employee/updateProfilePicture`, {
    EmployeeId: employeeId,
    ProfilePictureUrl: profilePictureUrl
  });
};

const getEmployeeInfoById = (employeeId: string) => {
  return axios.get(`${config.IDENTITY_URL}/api/employee/${employeeId}`);
};

export { updateEmployeeProfilePhoto, getEmployeeInfoById };

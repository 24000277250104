export const shortenString = (str: string, length: number) => {
  if (str.length < length) return str;
  return str.substring(0, length - 1).concat('...');
};

export const truncateAzureUrl = (url: string) => {
  return url.substring(url.lastIndexOf('/'), url.length);
};

export const getVideoOrAudioNameFromUrl = (
  videoUrl: string,
  hlsUrl?: string
) => {
  if (videoUrl) return truncateAzureUrl(videoUrl);
  if (hlsUrl) {
    const hlsUrlSegments = hlsUrl.slice(8).split('/');
    if (hlsUrlSegments.length >= 2) {
      return `${hlsUrlSegments[1]}.mp4`;
    }
  }
  return '';
};

export const truncateLargeAzureUrl = (url: string) => {
  const newSlice = url.lastIndexOf('/');
  const firstPart = url.substring(newSlice, newSlice + 25);
  const secondPart = url.substring(url.length - 15, url.length);

  if (url.length > 120) {
    return firstPart + '...' + secondPart;
  } else {
    return url.substring(url.lastIndexOf('/'), url.length);
  }
};

export const lowerCaseFirstLetter = (text: string) => {
  return text ? `${text.charAt(0).toLowerCase()}${text.slice(1)}` : '';
};

export const getExtension = (filename: string) => {
  return filename.substring(filename.lastIndexOf('.'));
};
export const upperCaseFirstLetter = (text: string) => {
  return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const isValidChars = (str: string) => {
  const regex = /^[a-zA-Z\s]*$/;
  return regex.test(str);
};

export const pluralizeText = (numberOfItems: number, baseText: string) =>
  numberOfItems > 1 ? `${baseText}s` : baseText;

import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { useTranslation } from 'react-i18next';

interface QuizGradeNoticeProps {
  grade: number;
  passed: boolean;
  onContinue: (goToNext?: boolean) => void;
  onRetry: () => void;
}

export const QuizGradeNotice: React.FC<QuizGradeNoticeProps> = ({
  grade,
  passed,
  onContinue,
  onRetry
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common', 'quizzes']);
  const highlightColor = passed
    ? theme.palette.success.main
    : theme.palette.error.light;

  const header = passed
    ? t('congratsYouPassed', { ns: 'quizzes' })
    : t('tryAgainWhenReady', { ns: 'quizzes' });

  const gradeStyle = {
    fontSize: '18px'
  };
  return (
    <Container
      fluid
      style={{ border: `1px solid ${highlightColor}` }}
      className="p-3 rounded mt-3"
    >
      <Row>
        <Col>
          <h3>{header}</h3>
          <span style={gradeStyle}>
            {t('gradeReceived', { ns: 'quizzes' })}{' '}
            <span style={{ color: highlightColor }}>{grade.toFixed(2)}%</span>
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end">
          {passed && (
            <BasicButton
              onClick={() => onContinue(true)}
              color="primary"
              width="179px"
              height="45px"
            >
              {t('continue', { ns: 'common' })}
            </BasicButton>
          )}
          {!passed && (
            <>
              <BasicButton
                onClick={() => onContinue()}
                color="secondary"
                width="179px"
                height="45px"
                style={{ marginRight: '13px' }}
              >
                {t('reviewLessons', { ns: 'quizzes' })}
              </BasicButton>
              <BasicButton
                onClick={onRetry}
                color="primary"
                width="179px"
                height="45px"
              >
                {t('tryAgain', { ns: 'common' })}
              </BasicButton>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

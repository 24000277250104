import React, { useState, useEffect, CSSProperties } from 'react';
import Panel from '../core/Panel/Panel';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DroppableProvided,
  DroppableStateSnapshot
} from 'react-beautiful-dnd';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicButton from '../core/BasicButton/BasicButton';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import arrow from '../../assets/arrow.png';
import { shortenString } from '../../utils/stringUtils';
import { EntityTypeEnum } from '../../core/enums';
import LessonType from '../LessonType/LessonType';
import { LessonTypeViewEnum } from '../../core/enums';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface DragDropListProps {
  items: DraggableListItem[];
  itemType: EntityTypeEnum;
  editItem?: (id: string) => void;
  deleteItem?: (id: string) => void;
  children?: JSX.Element;
  getItems?: (items: DraggableListItem[]) => void;
}

export interface DraggableListItem {
  id: string;
  title: string;
  type?: string;
  videosCount?: number;
  flashCardsCount?: number;
  quizesCount?: number;
  isEditable?: boolean;
}

const ItemDragStyle = {
  display: 'inline-flex'
};

const ItemDragIconStyle = {
  width: '20px',
  height: '20px',
  verticalAlign: 'middle',
  paddingRight: '1rem',
  cursor: 'move',
  marginLeft: '10px'
};

const DragHandle = (
  props: JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLDivElement> &
    React.HTMLAttributes<HTMLDivElement>
) => {
  return (
    <div {...props} style={ItemDragStyle}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        style={ItemDragIconStyle}
      >
        {' '}
        <MenuIcon />
      </IconButton>
    </div>
  );
};

const DragDropList: React.FC<DragDropListProps> = ({
  items,
  itemType,
  editItem,
  deleteItem,
  children,
  getItems
}) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [list, setList] = useState<DraggableListItem[]>([]);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    setList(items);
  }, [items]);

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const innerPanelStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column' as const
  };

  const containerStyle = {
    width: '100%',
    paddingBottom: '15px'
  };

  const SpanStyle = {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    color: '#FFF'
  };

  const iconStyle = {
    marginLeft: '5px'
  };

  const listStyle = {
    width: '100%'
  };

  const InstructionsStyle = {
    color: theme.palette.grey[500],
    fontFamily: theme.typography.fontFamily,
    fontBold: 'bold'
  };

  const deleteButtonStyle: CSSProperties = {
    minWidth: '15px',
    height: '30px',
    padding: '4px 9px 4px 4px'
  };

  const orderStyle = {
    padding: '0 8px 0 0',
    fontSize: '22px'
  };

  const titleStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexWrap: 'nowrap'
  };

  const containerLessonTypeStyle: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    paddingLeft: isMdScreen ? 0 : '55px'
  };

  const containerLessonTypesActionsStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  };

  const basicCourseInfoContainerStyle: CSSProperties = {
    display: 'flex',
    width: '100%',
    flexWrap: isMdScreen ? 'nowrap' : 'wrap'
  };

  const actionButtonsContainerStyle: CSSProperties = {
    flexWrap: 'nowrap'
  };

  return (
    <Panel width="100%" height="100%">
      <div style={innerPanelStyle}>
        <div style={containerStyle}>
          <DragDropContext
            onDragEnd={(result: DropResult, provided: ResponderProvided) => {
              const srcI = result.source.index;
              const desI = result.destination?.index;
              if (desI !== undefined && desI !== null) {
                list.splice(desI, 0, list.splice(srcI, 1)[0]);
                setList(list);

                if (getItems) getItems(list);
              }
            }}
          >
            <div style={listStyle}>
              <Droppable droppableId="droppable-1">
                {(
                  provided: DroppableProvided,
                  snapshot: DroppableStateSnapshot
                ) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {list.map((item: DraggableListItem, i: number) => (
                      <Draggable
                        key={item.id}
                        draggableId={'draggable-' + item.id}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={{
                              ...provided.draggableProps.style,
                              color: '#999',
                              padding: '0.8rem 0.5rem',
                              background: i % 2 == 0 ? '#282828' : '#1B1C1C',
                              boxShadow: snapshot.isDragging
                                ? '0 0 .4rem #666'
                                : 'none',
                              display: 'flex',
                              flexDirection: 'row'
                            }}
                          >
                            <div style={basicCourseInfoContainerStyle}>
                              <div style={titleStyle}>
                                <DragHandle {...provided.dragHandleProps} />
                                <span style={orderStyle}>{i + 1}</span>
                                <span style={SpanStyle}>
                                  {shortenString(item.title, 50)}
                                </span>
                              </div>
                              <div style={containerLessonTypesActionsStyle}>
                                <div style={containerLessonTypeStyle}>
                                  {!isXs &&
                                  itemType === EntityTypeEnum.Lesson ? (
                                    <LessonType
                                      viewType={LessonTypeViewEnum.LessonType}
                                      type={item.type}
                                    />
                                  ) : (
                                    !isXs && (
                                      <LessonType
                                        viewType={
                                          LessonTypeViewEnum.CourseLessonTypesCount
                                        }
                                        videosCount={item.videosCount}
                                        flashCardsCount={item.flashCardsCount}
                                        quizesCount={item.quizesCount}
                                      />
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center"
                              style={actionButtonsContainerStyle}
                            >
                              {editItem &&
                                (typeof item.isEditable === 'undefined' ||
                                  item.isEditable) && (
                                  <div className="pt-1 pb-1">
                                    <BasicButton
                                      onClick={async () => {
                                        await editItem(item.id);
                                      }}
                                      color="secondary"
                                      width="80px"
                                      height="30px"
                                    >
                                      {t('edit', { ns: 'common' })}
                                      {'   '}
                                      <FontAwesomeIcon
                                        icon={faPen}
                                        color="black"
                                        style={iconStyle}
                                      />
                                    </BasicButton>
                                  </div>
                                )}
                              {deleteItem && (
                                <div className="ps-2">
                                  <BasicButton
                                    onClick={() => {
                                      deleteItem(item.id);
                                    }}
                                    color="secondary"
                                    style={deleteButtonStyle}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      color="black"
                                      style={iconStyle}
                                    />
                                  </BasicButton>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        </div>
        {children}
        <div>
          <img src={arrow} alt="arrow" />
          <span style={InstructionsStyle}>
            {t('clickDragMsg', { ns: 'common' })}
          </span>
        </div>
      </div>
    </Panel>
  );
};

export default DragDropList;

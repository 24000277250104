import { Employee } from '../../entities/Employee';
import themeConfig from '../../themes/theme';
import { createTheme, SxProps, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import SearchBox from '../core/SearchBox/SearchBox';
import TextDropdownList from '../core/TextDropdownList/TextDropdownList';
import { SortEmployeeOption } from '../../entities/SortEmployeeOption';
import { CircularProgress } from '@mui/material';
import EmployeesHaltTable from './EmployeesHaltTable';
import { useMemo, useState } from 'react';
import { sortByKey } from '../../utils/sorting';
export interface EmployeesHaltPanelProps {
  employees: Employee[];
  setSelectedEmployeeIds: (employeeIds: string[]) => void;
  selectedEmployeeIds: string[];
  savingInProgress: boolean;
}

const EmployeesHaltPanel: React.FC<EmployeesHaltPanelProps> = ({
  employees,
  setSelectedEmployeeIds,
  selectedEmployeeIds,
  savingInProgress
}) => {
  let searchTimeout: NodeJS.Timeout;
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['common', 'assignments']);
  const [employeesToShow, setEmployeesToShow] = useState<Employee[]>([]);
  const handleSearch = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      const searchKey = value.toLowerCase();
      const employeesSearched = employees.filter(
        emp =>
          emp.firstName.toLowerCase().includes(searchKey) ||
          emp.lastName.toLocaleLowerCase().includes(searchKey)
      );
      setEmployeesToShow(employeesSearched);
    }, 1000);
  };

  useMemo(() => {
    if (employees) {
      setEmployeesToShow(employees);
    }
  }, [employees]);

  const handleSortChange = (e: any) => {
    const option = parseInt(e.target.value);
    switch (option) {
      case SortEmployeeOption.Az:
        setEmployeesToShow(
          employeesToShow.slice().sort(sortByKey<Employee>('firstName'))
        );
        break;
      case SortEmployeeOption.Za:
        setEmployeesToShow(
          employeesToShow
            .slice()
            .sort(sortByKey<Employee>('firstName'))
            .reverse()
        );
    }
  };

  const boxContainerStyle: SxProps = {
    border: `1px solid ${theme.palette.grey[100]}`,
    padding: '1rem 0 !important',
    borderRadius: '10px',
    backgroundColor: theme.palette.common.black
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={boxContainerStyle}>
        <Grid item sx={containerStyle}>
          <SearchBox
            id="searchEmployees"
            value=""
            name="searchEmployees"
            width="250px"
            height="38px"
            placeholderText={t('search', { ns: 'common' })}
            variant="dark"
            onChangeValue={handleSearch}
          />
          <Grid
            item
            sx={{
              textAlign: 'right',
              paddingRight: '1rem',
              position: 'absolute',
              top: '14.7rem',
              right: '3rem',
              zIndex: 3
            }}
          >
            <TextDropdownList
              onChange={handleSortChange}
              label={`${t('sort', { ns: 'common' })}:`}
              items={[
                { value: SortEmployeeOption.Az, text: 'A-Z' },
                { value: SortEmployeeOption.Za, text: 'Z-A' }
              ]}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ textAlign: 'center', height: '400px' }}>
          {!!employees && !!employees.length && !savingInProgress ? (
            <EmployeesHaltTable
              employees={employeesToShow}
              setSelectedEmployeeIds={setSelectedEmployeeIds}
              selectedEmployeeIds={selectedEmployeeIds}
            />
          ) : (
            <Grid
              style={{ height: '400px' }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export const containerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  paddingLeft: '1rem',
  paddingRight: '1rem'
};

export default EmployeesHaltPanel;

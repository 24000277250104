import { Avatar, Checkbox, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export const getFullNameColumnCalendar = (
  headerName: string,
  onClickFunc?: (e: React.ChangeEvent<HTMLInputElement>) => void,
  enableCheckBox?: boolean
) => ({
  field: 'firstName',
  headerName: headerName,
  minWidth: 250,
  sortable: false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, string>) => {
    return (
      <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
        {enableCheckBox && (
          <Checkbox
            id={params.row.id}
            onChange={onClickFunc}
            checked={params.row.isSelected}
          />
        )}
        <Avatar
          src={
            params.row.profileImageUrl && params.row.profileImageUrl.length
              ? params.row.profileImageUrl
              : require('../../../assets/user.png')
          }
          sx={{ width: 32, height: 32 }}
        />
        <Stack>
          <span style={{ fontWeight: 600 }}>
            {`${params.row.firstName} ${params.row.lastName}`}
          </span>
          <span style={{ color: '#999', fontSize: '12px' }}>
            {params.row.email ? params.row.email : params.row.phoneNumber ?? ''}
          </span>
        </Stack>
      </Stack>
    );
  }
});

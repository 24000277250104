import { GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsGroupCompletedAssigments } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getStandardColumn,
  getCompletedOnColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';

interface GroupCompletedAssignmentListProps {
  completedAssignmentsData?: AnalyticsGroupCompletedAssigments[] | null;
}

const GroupCompletedAssignmentList = ({
  completedAssignmentsData
}: GroupCompletedAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      300,
      learningUnitNames
    ),
    getStandardColumn(
      'employeeName',
      t('completedBy', { ns: 'analytics' }),
      300
    ),
    getCompletedOnColumn(
      'completionDate',
      t('completionDate', { ns: 'analytics' }),
      150
    )
  ])();

  return <ModalDataGrid rows={completedAssignmentsData} columns={columns} />;
};

export default GroupCompletedAssignmentList;

import { CSSProperties } from 'react';
import BasicButton from '../core/BasicButton/BasicButton';
import { RemoveAssignButton } from '../RemoveAssignButton/RemoveAssignButton';
import { EntityTypeEnum } from '../../core/enums';
import {
  AssignedCourse,
  AssignedLearningPlan,
  AssignedLesson
} from '../../entities/LearningPlaylist';

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

interface ButtonsSectionProps {
  onClick: () => void;
  startButtonCaption: string;
  startButtonColor:
    | 'primary'
    | 'secondary'
    | 'alternative'
    | 'delete'
    | 'confirm'
    | 'revisit'
    | 'grayButton'
    | 'trial';
  assignment?: AssignedCourse | AssignedLearningPlan | AssignedLesson;
  entityType?: number;
}

const ButtonsSection: React.FC<ButtonsSectionProps> = ({
  onClick,
  startButtonCaption,
  startButtonColor,
  assignment,
  entityType
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection:
          entityType === EntityTypeEnum.LearningPlan ? 'row' : 'column-reverse',
        alignItems: 'center',
        gap: '8px'
      }}
    >
      {assignment && (
        <RemoveAssignButton
          assignment={assignment}
          assignmentType={entityType}
          style={startButtonStyle}
        />
      )}
      <BasicButton
        onClick={onClick}
        color={startButtonColor}
        style={startButtonStyle}
      >
        {startButtonCaption}
      </BasicButton>
    </div>
  );
};

export default ButtonsSection;

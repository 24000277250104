import { AppThunk } from '../store/index';
import axios, { AxiosError, Method } from 'axios';
import {
  CommunityLesson,
  EmployeeLessonAssignment,
  Lesson,
  LessonAssignment
} from '../entities/Lesson';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import {
  LESSON_SET_LESSONS,
  LESSON_BY_ID,
  LESSON_SET_ALL_LESSONS,
  LESSON_SET_ALL_BW_LESSONS,
  LESSONS_SET_ASSESSMENT_QUESTIONS,
  LESSON_UPDATE_TRANSACTION_STATUS,
  LESSON_RESET_TRANSACTION_STATUS,
  LESSON_SET_EMPLOYEE_ASSIGNMENTS,
  LESSONS_SET_QUIZ_QUESTIONS,
  LESSONS_SET_COMMUNITY_LESSONS,
  LESSONS_IS_LOADING,
  LESSONS_SET_ASSIGNMENT_STATUS,
  LESSONS_SET_PARTNERS_LESSONS,
  LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  LESSON_PLAY,
  LESSON_PLAY_MARK_AS_COMPLETE
} from './actionTypes';
import config from './../config';
import {
  uploadLessonThumbToBlob,
  uploadLessonDocToBlob,
  checkUploadImageQuestion
} from '../services/blobStorage-service';
import { ContentMedium } from '../entities/LessonFormModel';
import {
  AttachmentDTO,
  CreateLessonDto
} from '../entities/Dto/createLessonDto';
import { UpdateLessonDto } from '../entities/Dto/UpdateLessonDto';
import { Question } from '../entities/Assessment';
import { Guid } from 'guid-typescript';
import HttpStatusError from '../core/CustomErrors/HttpRequestError';
import {
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition,
  LessonGroupAssignment,
  LessonPlayAssignment
} from '../entities/Assignment';
import { handleGenericBackendError } from '../utils/errorHandling';
import { SelfAssignResponse } from '../components/SelfAssignButton/types';

export interface RequestLessonsAction {
  type: 'LESSON_SET_LESSONS';
  lessons: Lesson[];
}

export interface SetEditingLessonAction {
  type: 'LESSON_SET_EDITING';
  lesson: Lesson;
}

export interface RequestLessonByIdAction {
  type: 'LESSON_BY_ID';
  lesson: Lesson | null;
}

export interface RequestAllLessonsAction {
  type: 'LESSON_SET_ALL_LESSONS';
  allLessons: Lesson[];
}

export interface RequestAllBWLessonsAction {
  type: 'LESSON_SET_ALL_BW_LESSONS';
  allBWLessons: Lesson[];
}

export interface RequestPartnersLessons {
  type: 'LESSONS_SET_PARTNERS_LESSONS';
  partnersLessons: Lesson[];
}

export interface RequestAssessmentQuestionsAction {
  type: 'LESSONS_SET_ASSESSMENT_QUESTIONS';
  questions: Question[];
  assessmentId: string;
  correctAnswersToPass: number;
}

export interface UpdateLessonTransactionStatusAction {
  type: 'LESSON_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetLessonTransactionStatusAction {
  type: 'LESSON_RESET_TRANSACTION_STATUS';
}

export interface RequestEmployeeLessonAssigment {
  type: 'LESSON_SET_EMPLOYEE_ASSIGNMENTS';
  employeeLessonAssigment: EmployeeLessonAssignment[];
}

export interface RequestQuizQuestionsAction {
  type: 'LESSONS_SET_QUIZ_QUESTIONS';
  questions: Question[];
  correctAnswersToPass: number;
}

export interface RequestCommunityLessonsAction {
  type: 'LESSONS_SET_COMMUNITY_LESSONS';
  communityLessons: CommunityLesson[] | null;
}

export interface SetLessonIsLoadingAction {
  type: 'LESSONS_IS_LOADING';
  isLoading: boolean;
}

export interface SetLessonAssignmentStatusAction {
  type: 'LESSONS_SET_ASSIGNMENT_STATUS';
  lessonAssignmentStatus: EmployeeAssignmentStatus[] | null;
}
export interface SetLessonGroupAssignmentDefinitions {
  type: 'LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS';
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
}

export interface RequestLessonPlayAction {
  type: 'LESSON_PLAY';
  lessonPlayAssignment: LessonPlayAssignment | null;
}

export interface SetLessonAssignmentAsCompleteAction {
  type: 'LESSON_PLAY_MARK_AS_COMPLETE';
}

export const actionCreators = {
  requestLessons:
    (courseId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.lessons?.lessons && Guid.isGuid(courseId)) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/${courseId}/lesson`
        );
        dispatch({
          type: LESSON_SET_LESSONS,
          lessons: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  requestLessonByEntryId:
    (lessonEntryId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}lessons/byEntry?lessonEntryId=${lessonEntryId}`
        );
        dispatch({
          type: LESSON_BY_ID,
          lesson: { ...res.data, lessonEntryId: lessonEntryId }
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  requestLessonById:
    (lessonId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}lessons?lessonId=${lessonId}`
        );
        dispatch({
          type: LESSON_BY_ID,
          lesson: { ...res.data }
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  saveLessonForCourse:
    (
      lessonDto: CreateLessonDto,
      thumbnailFile: File,
      lessonFile: File,
      courseId: string,
      attachments: File[],
      audioFile: File
    ): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          if (thumbnailFile) {
            const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
            lessonDto.thumbnailUrl = thumbUrl;
          }
          if (lessonDto.contentMedium === ContentMedium.File && lessonFile) {
            lessonDto.documentUrl = await uploadLessonDocToBlob(lessonFile);
            lessonDto.lessonFileName = lessonFile.name;
            lessonDto.lessonFileContentType = lessonFile.type;
          }
          if (lessonDto.contentMedium === ContentMedium.Audio && audioFile) {
            lessonDto.lessonFileName = audioFile.name;
            lessonDto.lessonFileContentType = audioFile.type;
          }
          const res = await axios.post(
            `${config.COURSES_API_URL}courses/${courseId}/lesson`,
            lessonDto,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          if (res.status === HttpStatusEnum.OK) {
            await dispatch({
              type: LESSON_BY_ID,
              lesson: res.data
            });

            return res.data.id;
          }

          return '';
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }

      return '';
    },
  saveLesson:
    (
      lessonDto: CreateLessonDto,
      thumbnailFile: File,
      lessonFile: File,
      attachments: File[],
      audioFile: File
    ): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          if (thumbnailFile) {
            const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
            lessonDto.thumbnailUrl = thumbUrl;
          }
          if (lessonDto.contentMedium === ContentMedium.File && lessonFile) {
            lessonDto.documentUrl = await uploadLessonDocToBlob(lessonFile);
            lessonDto.lessonFileName = lessonFile.name;
            lessonDto.lessonFileContentType = lessonFile.type;
          }
          if (lessonDto.contentMedium === ContentMedium.Audio && audioFile) {
            lessonDto.lessonFileName = audioFile.name;
            lessonDto.lessonFileContentType = audioFile.type;
          }
          //save attachments
          const attachmentsDtos: AttachmentDTO[] = [];

          for await (const attch of attachments) {
            const fileUrl = await uploadLessonDocToBlob(attch);
            const newAttch: AttachmentDTO = {
              fileName: attch.name,
              fileUrl: fileUrl
            };
            attachmentsDtos.push(newAttch);
          }
          lessonDto.attachments = attachmentsDtos;
          const lessonId = await axios
            .post(`${config.COURSES_API_URL}lessons`, lessonDto, {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            })
            .then(async res => {
              await dispatch({
                type: LESSON_BY_ID,
                lesson: res.data
              });

              return res.data.id;
            })
            .catch((e: AxiosError) => {
              if (e.response?.status === HttpStatusEnum.Conflict) {
                throw new HttpStatusError(
                  e.response.data as string,
                  HttpStatusEnum.Conflict
                );
              }
            });

          return lessonId;
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }

      return '';
    },
  updateLesson:
    (
      lessonDto: UpdateLessonDto,
      thumbnailFile: File,
      lessonFile: File,
      attachments: File[],
      audioFile: File
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          if (thumbnailFile) {
            const thumbUrl = await uploadLessonThumbToBlob(thumbnailFile);
            lessonDto.thumbnailUrl = thumbUrl;
          }
          if (lessonDto.contentMedium === ContentMedium.File && lessonFile) {
            lessonDto.documentUrl = await uploadLessonDocToBlob(lessonFile);
            lessonDto.lessonFileName = lessonFile.name;
            lessonDto.lessonFileContentType = lessonFile.type;
          }
          if (lessonDto.contentMedium === ContentMedium.Audio && audioFile) {
            lessonDto.lessonFileName = audioFile.name;
            lessonDto.lessonFileContentType = audioFile.type;
          }
          //save attachments
          const attachmentsDtos: AttachmentDTO[] = [];

          for await (const attch of attachments) {
            const fileUrl = await uploadLessonDocToBlob(attch);
            const newAttch: AttachmentDTO = {
              fileName: attch.name,
              fileUrl: fileUrl
            };
            attachmentsDtos.push(newAttch);
          }
          lessonDto.attachments = attachmentsDtos;
          await axios.put(`${config.COURSES_API_URL}lessons`, lessonDto, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          });
          const res = await axios.get(
            `${config.COURSES_API_URL}lessons/company`
          );
          dispatch({
            type: LESSON_SET_ALL_LESSONS,
            allLessons: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },

  deleteLesson:
    (lessonId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          await axios.delete(
            `${config.COURSES_API_URL}lessons/?lessonId=${lessonId}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setCurrentLessons: (lessons: Lesson[]) => ({
    type: LESSON_SET_LESSONS,
    lessons: lessons
  }),
  setCurrentLesson: (lesson: Lesson | null) => ({
    type: LESSON_BY_ID,
    lesson: lesson
  }),
  requestAllLessons: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL}lessons/all`);
      dispatch({
        type: LESSON_SET_ALL_LESSONS,
        allLessons: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },
  requestAllBWLessons:
    (bwCompanyId: string, hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: LESSONS_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}lessons/${bwCompanyId}/bycompany?hideSpanishContent=${hideSpanishContent}`
          );
          dispatch({
            type: LESSON_SET_ALL_BW_LESSONS,
            allBWLessons: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: LESSONS_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  requestCompanyLessons:
    (hideSpanishContent = false): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch({
          type: LESSONS_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}lessons/company?hideSpanishContent=${hideSpanishContent}`
          );
          dispatch({
            type: LESSON_SET_ALL_LESSONS,
            allLessons: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: LESSONS_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  requestPartnersLessons:
    (companyPartnersIds: string[], hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let partnersLessons: Lesson[] = [];
        try {
          dispatch(appActions.setIsLoading(true));
          dispatch({
            type: LESSONS_IS_LOADING,
            isLoading: true
          });
          for (const id of companyPartnersIds) {
            const res = await axios.get(
              `${config.COURSES_API_URL}lessons/${id}/bycompany?hideSpanishContent=${hideSpanishContent}`
            );
            if (res.status === HttpStatusEnum.OK) {
              partnersLessons = partnersLessons.concat(res.data);
            }
          }
          dispatch({
            type: LESSONS_SET_PARTNERS_LESSONS,
            partnersLessons: partnersLessons
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: LESSONS_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  requestAssessmentQuestions:
    (lessonId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState && appState.lessons?.currentLesson) {
        dispatch(appActions.setIsLoading(true));
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}assessments/lesson/${lessonId}`
          );

          dispatch({
            type: LESSONS_SET_ASSESSMENT_QUESTIONS,
            questions: res.data.questions,
            assessmentId: res.data.id,
            correctAnswersToPass: res.data.correctAnswersToPass
          });
        } catch (e) {
          const error = e as AxiosError;
          if (error?.response?.status === 404) {
            dispatch({
              type: LESSONS_SET_ASSESSMENT_QUESTIONS,
              questions: [],
              assessmentId: '',
              correctAnswersToPass: 0
            });
          }
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  saveAssessment:
    (
      questionsDto: Question[],
      lessonId: string,
      isEditing: boolean,
      assessmentId: string | null,
      correctAnswersToPass: number | null,
      updateAssessmentIdInLesson: boolean
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          const method: Method = isEditing ? 'PUT' : 'POST';
          const newQuestions = await checkUploadImageQuestion(questionsDto);
          const data = {
            lessonId,
            questions: newQuestions,
            id: assessmentId,
            correctAnswersToPass: correctAnswersToPass
          };
          const backendResponse = await axios({
            method,
            headers: {
              'Access-Control-Allow-Origin': '*'
            },
            url: `${config.ASSESSMENT_API_URL}assessments/lesson`,
            data
          });

          if (updateAssessmentIdInLesson) {
            await axios.put(
              `${config.COURSES_API_URL}lessons/assessment/${lessonId}/${backendResponse.data.id}/`,
              null
            );
          }
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestPositionTracking:
    (videoId: string): AppThunk<Promise<number>> =>
    async (dispatch, getState) => {
      const appState = getState();
      let lastTrackingPosition = 0;
      if (appState && appState.lessons?.lessons) {
        dispatch(appActions.setIsLoading(true));
        let res = null;

        try {
          res = await axios.get(
            `${config.LEADERS_API_URL}Playback/tracking/${videoId}/`
          );
          if (res.status === HttpStatusEnum.OK && res.data.data != null) {
            lastTrackingPosition = res.data.data.playbackPosition;
          }
        } catch (err) {
          handleGenericBackendError(err);
        }

        dispatch(appActions.setIsLoading(false));
      }

      return lastTrackingPosition;
    },

  savePositionTracking:
    (videoId: string, playbackPosition: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const data = {
          videoId: videoId,
          playbackPosition: Math.floor(playbackPosition)
        };
        try {
          await axios.post(`${config.LEADERS_API_URL}Playback/tracking/`, data);
        } catch (err) {
          console.error(err);
        }
        dispatch(appActions.setIsLoading(false));
      }
    },
  assignLessonToEmployees:
    (
      lessonAssignment: LessonAssignment
    ): AppThunk<Promise<SelfAssignResponse | null>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let httpStatus = 0;
        let resMessage = '';
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}assignments/assign/lesson/employees/`,
            lessonAssignment
          );
          httpStatus = res.status;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const errorData = e.response?.data as {
              Data: { assigmentId: string };
            };
            if (errorData.Data.assigmentId) {
              resMessage = errorData.Data.assigmentId;
            }
          }
        } finally {
          dispatch({
            type: LESSON_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              httpStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: resMessage
          });
        }

        return { status: httpStatus, message: resMessage };
      }

      return null;
    },
  requestEmployeeLessonAssignments:
    (lessonId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/lesson/${lessonId}`
          );
          dispatch({
            type: LESSON_SET_EMPLOYEE_ASSIGNMENTS,
            employeeLessonAssigment: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestCourseLessonsByRevision:
    (courseId: string, revisionNumber: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.lessons?.lessons && Guid.isGuid(courseId)) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/${courseId}/${revisionNumber}/lesson`
        );
        dispatch({
          type: LESSON_SET_LESSONS,
          lessons: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  resetTransactionStatus: () => ({
    type: LESSON_RESET_TRANSACTION_STATUS
  }),
  requestQuizQuestions:
    (assessmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState && appState.lessons?.currentLesson) {
        dispatch(appActions.setIsLoading(true));
        try {
          const res = await axios.get(
            `${config.ASSESSMENT_API_URL}assessments/lesson/${assessmentId}/assessment`
          );

          dispatch({
            type: LESSONS_SET_QUIZ_QUESTIONS,
            questions: res.data.questions,
            correctAnswersToPass: res.data.correctAnswersToPass
          });
        } catch (e) {
          const error = e as AxiosError;
          if (error?.response?.status === 404) {
            dispatch({
              type: LESSONS_SET_QUIZ_QUESTIONS,
              questions: [],
              correctAnswersToPass: 0
            });
          }
        }

        dispatch(appActions.setIsLoading(false));
      }
    },
  assignDefaultLesson: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));

      try {
        await axios.post(
          `${config.STUDENT_API_URL}assignments/assign/lesson/default`
        );
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch(appActions.setIsLoading(false));
      }
    }
  },
  requestCommunityLessons:
    (hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}community/lessons/all?hideSpanishContent=${hideSpanishContent}`
          );

          dispatch({
            type: LESSONS_SET_COMMUNITY_LESSONS,
            communityLessons: res.status === HttpStatusEnum.OK ? res.data : []
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCommunityLessons:
    (communityLessons: CommunityLesson[] | null): AppThunk =>
    async (dispatch, getState) => {
      dispatch({
        type: LESSONS_SET_COMMUNITY_LESSONS,
        communityLessons
      });
    },
  requestStatusOfEmployeesLessonAssigments:
    (lessonId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/lesson/${lessonId}/status`
          );

          dispatch({
            type: LESSONS_SET_ASSIGNMENT_STATUS,
            lessonAssignmentStatus: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  SetStatusOfEmployeesLessonAssigments: (
    assignmentStatus: EmployeeAssignmentStatus[] | null
  ) => ({
    type: LESSONS_SET_ASSIGNMENT_STATUS,
    lessonAssignmentStatus: assignmentStatus
  }),
  requestLessonAssignmentDefinitions:
    (lessonId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employee-group-assignments/assigned/lesson/${lessonId}`
          );

          dispatch({
            type: LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
            groupAssignmentDefinitions: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setLessonAssignmentDefinitions: (
    definitions: EmployeeGroupAssignmentDefinition[] | null
  ) => ({
    type: LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
    groupAssignmentDefinitions: definitions
  }),
  saveLessonGroupAssignments:
    (lessonGroupAssignments: LessonGroupAssignment): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        const { lessonId, assignBy, groups } = lessonGroupAssignments;
        const assignmentDefinitionIds: string[] = [];

        try {
          for (let i = 0; i < groups.length; i++) {
            const response = await axios.post(
              `${config.STUDENT_API_URL}employee-group-assignments/assign/lesson`,
              {
                lessonId,
                employeeGroupId: groups[i].groupId,
                assignBy,
                employeeIds: groups[i].employeeIds
              }
            );
            assignmentDefinitionIds.push(response.data);
          }
        } catch (e) {
          for (let j = 0; j < assignmentDefinitionIds.length; j++) {
            await axios.delete(
              `${config.STUDENT_API_URL}employee-group-assignments/${assignmentDefinitionIds[j]}`
            );
          }

          throw e;
        }
      }
    },
  createDefaultAssignments:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          await axios.post(
            `${config.STUDENT_API_URL}assignments/default/assignments`
          );
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  getLessonAssignment:
    (lessonAssignmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.STUDENT_API_URL}assignment-progress/lesson/${lessonAssignmentId}`
        );

        dispatch({
          type: LESSON_PLAY,
          lessonPlayAssignment: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  clearLessonAssignment: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: LESSON_PLAY,
        lessonPlayAssignment: null
      });
    }
  },
  markLessonAssignmentAsCompleteLocally:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: LESSON_PLAY_MARK_AS_COMPLETE
        });
      }
    }
};

export type KnownAction =
  | SetLoadingAction
  | RequestLessonsAction
  | RequestLessonByIdAction
  | SetEditingLessonAction
  | RequestAllLessonsAction
  | RequestAllBWLessonsAction
  | RequestAssessmentQuestionsAction
  | UpdateLessonTransactionStatusAction
  | ResetLessonTransactionStatusAction
  | RequestEmployeeLessonAssigment
  | RequestAssessmentQuestionsAction
  | RequestQuizQuestionsAction
  | RequestCommunityLessonsAction
  | SetLessonIsLoadingAction
  | SetLessonAssignmentStatusAction
  | RequestPartnersLessons
  | SetLessonGroupAssignmentDefinitions
  | RequestLessonPlayAction
  | SetLessonAssignmentAsCompleteAction;

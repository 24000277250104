import { useSelector } from 'react-redux';
import { ListChildComponentProps } from 'react-window';
import NewLessonCard from '../../../components/LessonCard/NewLessonCard';
import { CommunityLessonCardActions } from '../../../components/LessonCardActions';
import { CommunityLesson, Lesson } from '../../../entities/Lesson';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { ApplicationState } from '../../../store';
import { restrictedLessonShouldShow } from '../../../utils/lessonUtils';
import LessonRowWrapper from '../common/LessonRowWrapper';
import { Grid } from '@mui/material';

interface LessonVirtualRowProps
  extends ListChildComponentProps<CommunityLesson[][]> {
  onLessonStart: (lesson: Lesson, isPreviewMode?: boolean) => void;
  onOpenAssignModal: (lessonId: string) => void;
}

const LessonVirtualRow: React.FC<LessonVirtualRowProps> = ({
  data,
  index,
  style,
  onLessonStart,
  onOpenAssignModal
}) => {
  const user = useCurrentUser();
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="lessContainer">
        {data[index]
          .filter((x: CommunityLesson) =>
            restrictedLessonShouldShow(x, userRoles)
          )
          .map((communityLesson: CommunityLesson) => (
            <LessonRowWrapper key={communityLesson.id}>
              <NewLessonCard
                height="300px"
                lesson={communityLesson}
                lessonCardType={'Community'}
                takenTimes={
                  takenTimesLessons
                    ? takenTimesLessons[communityLesson.id]
                    : undefined
                }
                onStartPreviewMode={() => onLessonStart(communityLesson, true)}
              >
                <CommunityLessonCardActions
                  communityLesson={communityLesson}
                  onPlay={() => onLessonStart(communityLesson)}
                  onAssign={() => onOpenAssignModal(communityLesson.id)}
                />
              </NewLessonCard>
            </LessonRowWrapper>
          ))}
      </Grid>
    </div>
  );
};

export default LessonVirtualRow;

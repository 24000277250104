import React, { CSSProperties, useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { actionCreators as actionCourses } from '../../actions/courses';
import { actionCreators as actionLessons } from '../../actions/lessons';
import { actionCreators as actionAssessments } from '../../actions/assessments';
import { actionCreators as actionStudent } from '../../actions/student';
import { ApplicationState } from '../../store';
import FlashCardSection from './categories/FlashCardSection';
import QuizSection from './categories/QuizSection';
import VideoSection from './categories/VideoSection';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import { Lesson } from '../../entities/Lesson';
import LessonAssessments from './LessonAssessments';
import { TinyLessonCard } from '../../components/LessonCard/TinyLessonCard';
import { useLogEvent } from '../../hooks/useLogEvent';
import { Grid, LinearProgress, Typography, useMediaQuery } from '@mui/material';
import { matchYoutubeUrl } from '../../utils/matchYoutubeUrl';
import { NewLessonType } from '../../entities/LessonFormModel';
import FileSection from './categories/FileSection';
import AudioSection from './categories/AudioSection';
import PreviewModeBanner from '../../components/PreviewMode/PreviewModeBanner';
import { CompleteUnit, EntityTypeEnum } from '../../core/enums';
import {
  CoursePlayAssignment,
  LessonPlayAssignment
} from '../../entities/Assignment';
import CompleteCourseNoticeModal from '../../components/CompleteCourseNoticeModal/CompleteCourseNoticeModal';
import CompleteLearningPlanNoticeModal from '../../components/CompleteLearningPlanNoticeModal/CompleteLearningPlanNoticeModal';
import { useAppDispatch } from '../../store/hooks';

interface LessonPlayerProps {
  lessons: Lesson[];
  lessonAssignments?: LessonPlayAssignment[];
  courseContext?: CoursePlayAssignment | null;
  entryPointLessonId: string;
  isPreview: boolean;
}

const LessonPlayer: React.FC<LessonPlayerProps> = ({
  lessons,
  lessonAssignments,
  entryPointLessonId,
  courseContext,
  isPreview = false
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const isMdScreen = useMediaQuery('(max-width:990px)');
  const assessment = useSelector(
    (state: ApplicationState) => state.assessments?.currentAssessment
  );

  const startingLesson = lessons?.find(l => l.id === entryPointLessonId);

  const completeLessons = lessonAssignments
    ?.filter(l => l.isComplete)
    .map(l => l.lesson);

  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedAssignmentQuizzes
  );

  const [showCompleteCourse, setShowCompleteCourse] = useState<boolean>(false);
  const [showCompleteLearningPlan, setShowCompleteLearningPlan] =
    useState<boolean>(false);

  const [isMarkingCompleteInProgress, setIsMarkingCompleteInProgress] =
    useState(false);
  const [lessonType, setLessonType] = useState<number | undefined>(
    startingLesson?.lessonContentType
  );
  const [currentLesson, setCurrentLesson] = useState<Lesson | undefined>(
    startingLesson
  );
  const [isSwitchingLesson, switchLesson] = useState(false);
  const [activeLesson, setActiveLessonLesson] =
    useState<string>(entryPointLessonId);
  const [showAssessment, setShowAssessment] = useState<boolean>(false);
  const selectedIndexLessonRef = useRef<number>();
  const isInPlayMode = !isPreview;
  const isPreviewMode = isPreview;
  const currentAssignment = lessonAssignments?.find(
    l => l.lesson.id === currentLesson?.id
  );

  useEffect(() => {
    if (currentLesson) {
      dispatch(actionLessons.setCurrentLesson(currentLesson));

      if (currentLesson.lessonContentType !== NewLessonType.Quiz) {
        dispatch(actionAssessments.requestAssessment(currentLesson.id));
      }
    }
  }, [currentLesson]);

  useEffect(() => {
    if (isSwitchingLesson) {
      const lesson = currentLesson!;
      const lessonId = currentLesson!.id;
      if (
        (lesson.lessonContentType === NewLessonType.Video &&
          lessonId !== activeLesson) ||
        lesson.lessonContentType === NewLessonType.Quiz ||
        lesson.lessonContentType === NewLessonType.File ||
        lesson.lessonContentType === NewLessonType.Audio
      ) {
        const isLessonQuiz = lesson.lessonContentType === NewLessonType.Quiz;

        if (!isLessonQuiz) {
          dispatch(actionLessons.setCurrentLesson(null));
          dispatch(actionAssessments.requestAssessment(lessonId));
        } else {
          dispatch(actionLessons.setCurrentLesson(lesson));
        }

        logEvent.logPlayLessonEvent(lessonId, lesson.title);
      }

      switchLesson(false);
    }
  }, [isSwitchingLesson]);

  const getLesson = (
    lesson: Lesson,
    lessonid: string,
    index: number,
    activeLesson: string
  ) => {
    if (currentLesson?.id === lesson.id) {
      return;
    }

    if (courseContext) {
      navigate(
        `/courses/assignment/play/${courseContext?.id}/lessons/${lesson.id}`,
        {
          replace: true
        }
      );
    }

    setCurrentLesson(lesson);
    setLessonType(lesson.lessonContentType);
    setActiveLessonLesson(lessonid);
    selectedIndexLessonRef.current = index;

    switchLesson(true);
  };

  const backToPreviousPage = () => {
    navigate(-1);
  };

  const markLessonAsCompleted = async (lessonAssignmentId: string) => {
    setIsMarkingCompleteInProgress(true);
    const wasUnitComplete = await dispatch<Promise<CompleteUnit>>(
      actionStudent.markLessonAssignmentAsCompleted(lessonAssignmentId)
    );

    if (courseContext) {
      await dispatch<Promise<void>>(
        actionCourses.markLessonAssignmentCompleteLocally(lessonAssignmentId)
      );
    } else {
      await dispatch<Promise<void>>(
        actionLessons.markLessonAssignmentAsCompleteLocally()
      );
    }

    if (wasUnitComplete > CompleteUnit.Lesson) {
      logEvent.logCompleteCourseEvent(
        courseContext?.course.id,
        courseContext?.course.revisionNumber
      );

      setTimeout(() => {
        if (wasUnitComplete === CompleteUnit.Course) {
          setShowCompleteCourse(true);
        } else {
          setShowCompleteLearningPlan(true);
        }
      }, 500);
    }

    setIsMarkingCompleteInProgress(false);
  };

  const handleLessonEnded =
    (lesson: Lesson, currentAssignment?: LessonPlayAssignment) => async () => {
      if (assessment && assessment.questions.length) {
        setShowAssessment(true);
      } else {
        if (isInPlayMode && !isPreviewMode) {
          if (currentAssignment) {
            await markLessonAsCompleted(currentAssignment.id!);

            logEvent.logCompleteLessonEvent(
              lesson.id,
              lesson.title,
              courseContext?.course?.id,
              courseContext?.course?.revisionNumber,
              courseContext?.course?.title
            );
          }
        }
        await handlePlayNextLesson();
      }
    };

  const handlePlayNextLessonAfterAssessment = async () => {
    if (isInPlayMode && !isPreviewMode && currentAssignment) {
      await markLessonAsCompleted(currentAssignment.id!);
    }
    await handlePlayNextLesson();
    setShowAssessment(false);
  };

  const handlePlayNextLesson = async () => {
    if (lessons && lessons.length) {
      if (!selectedIndexLessonRef.current) {
        selectedIndexLessonRef.current = lessons.findIndex(
          lesson => lesson.id === currentLesson?.id
        );
      }

      if (selectedIndexLessonRef.current < lessons.length - 1) {
        const newIndex = selectedIndexLessonRef.current + 1;
        const nextLesson = lessons[newIndex];

        getLesson(nextLesson, nextLesson.id, newIndex, activeLesson);
      }
    }
  };
  const isSmScreen = useMediaQuery('(max-width:640px)');

  const BackwardIconStyle: CSSProperties = {
    display: 'inline-block',
    width: isSmScreen ? 24 : 30,
    height: isSmScreen ? 24 : 30,
    cursor: 'pointer',
    backgroundImage: `url(${BackwardIconGray})`,
    backgroundSize: 'contain'
  };

  const sidebar: CSSProperties = {
    borderRadius: '5px',
    overflow: 'auto',
    backgroundColor: '#1D1D1E',
    height: isMdScreen ? '100%' : '650px',
    marginTop: isMdScreen ? '15px' : '0px'
  };

  return (
    <PageContainer>
      {isPreviewMode && currentLesson && (
        <PreviewModeBanner
          contentToAssign={currentLesson}
          contentType={EntityTypeEnum.Lesson}
        />
      )}
      <Row className="pb-0">
        <Grid
          container
          style={{ margin: '15px 0px' }}
          justifyContent={'start'}
          display={'flex'}
          alignItems={'center'}
        >
          <Grid item xs={12} md={1} lg={0.5}>
            <i style={BackwardIconStyle} onClick={backToPreviousPage} />
          </Grid>
          <Grid item xs={12} md={11} lg={11.5}>
            <Typography style={titleStyle}>
              {courseContext?.course?.title}
            </Typography>
          </Grid>
        </Grid>
        <Col xl={9} lg={8} style={{ padding: isMdScreen ? '0' : '0px 12px' }}>
          {lessonType === NewLessonType.Quiz && (
            <QuizSection
              origin={'?'}
              isPreviewMode={isPreviewMode}
              assignmentId={currentAssignment?.id}
              courseContext={courseContext}
            />
          )}
          {lessonType === NewLessonType.Video && !isSwitchingLesson && (
            <VideoSection
              currentlesson={currentLesson!}
              onVideoEnded={handleLessonEnded(
                currentLesson!,
                currentAssignment
              )}
              isPreviewMode={isPreviewMode}
            />
          )}
          {lessonType === NewLessonType.Audio && (
            <AudioSection
              key={currentLesson?.id}
              currentlesson={currentLesson!}
              onAudioEnded={handleLessonEnded(
                currentLesson!,
                currentAssignment
              )}
            />
          )}
          {lessonType === NewLessonType.Flashcard && <FlashCardSection />}
          {lessonType === NewLessonType.File && (
            <FileSection
              currentLesson={currentLesson!}
              onFileEnded={handleLessonEnded(currentLesson!, currentAssignment)}
              assessment={assessment}
              isPreviewMode={isPreviewMode}
            />
          )}
          {showCompleteLearningPlan && (
            <CompleteLearningPlanNoticeModal
              onClose={() => setShowCompleteLearningPlan(false)}
            />
          )}
          {showCompleteCourse && (
            <CompleteCourseNoticeModal
              contextLearningPlanAssignmentId={
                courseContext?.learningPlanAssignmentId
              }
              nextCourseAssignmentId={courseContext?.nextCourseAssignmentId}
              onClose={() => setShowCompleteCourse(false)}
            />
          )}
          {isMarkingCompleteInProgress && (
            <div className="d-flex flex-column w-100">
              <LinearProgress />
            </div>
          )}
        </Col>
        <Col style={sidebar} xl={3} lg={4}>
          {lessons
            ?.filter(
              x => !x.isVideoEncoding || matchYoutubeUrl(x.externalVideoUrl)
            )
            .map((lesson, index) => {
              const isCompleted =
                completeLessons?.some(
                  completeLesson =>
                    lesson.id === completeLesson.id || lesson.isComplete
                ) ?? false;
              return (
                <TinyLessonCard
                  key={lesson.id}
                  lesson={lesson}
                  listFailedQuiz={listFailedQuiz!}
                  onClick={() =>
                    getLesson(lesson, lesson.id, index, activeLesson)
                  }
                  isCompleted={isCompleted && isInPlayMode && !isPreviewMode}
                  index={index}
                  activeLesson={activeLesson}
                />
              );
            })}
        </Col>
      </Row>
      {assessment && (
        <LessonAssessments
          show={showAssessment}
          assessment={assessment}
          goNextLesson={handlePlayNextLessonAfterAssessment}
          isInPlayMode={isInPlayMode}
          isModalBusy={isMarkingCompleteInProgress}
        />
      )}
    </PageContainer>
  );
};

const titleStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: '0.15px'
};

export default LessonPlayer;

import React, { ReactNode, useEffect } from 'react';
import { AuthProvider, useAuth } from 'react-oidc-context';
import axios from 'axios';

import {
  AccessDenied,
  AccessDeniedLoadingScreen
} from '../../pages/AccessDenied';
import { UserClaims } from '../../core/constants';
import { actionCreators } from '../../actions/appState';
import { actionCreators as studentActionCreators } from '../../actions/student';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as studentActions } from '../../actions/student';
import { actionCreators as employeeActions } from '../../actions/employees';
import { User } from 'oidc-client-ts';
import config from '../../config';
import moment from 'moment';
import { useAppDispatch } from '../../store/hooks';
import { handleGenericBackendError } from '../../utils/errorHandling';
export interface IPrivateRouteProps {
  children: any;
}

const LogIn = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const trainingSubClaim =
    auth.user?.profile[UserClaims.TrainingEmployeeSubscription];
  const isLoading = auth.isLoading || !auth.user || auth.user.expired === true;
  const freeTrialExpirationDate =
    auth.user?.profile[UserClaims.FreeTrialExpirationDate];
  const expirationDate = moment(freeTrialExpirationDate as Date).format(
    'YYYY-MM-DD'
  );
  const currentDate = moment().startOf('day');
  const daysUntil = moment
    .duration(moment(expirationDate).diff(currentDate))
    .asDays();

  if (auth.user && auth.user?.profile[UserClaims.CompanyId]) {
    localStorage.setItem(
      'company',
      auth.user.profile[UserClaims.CompanyId] as string
    );
  }

  useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addUserSignedOut(() => {
      auth.clearStaleState();
      auth.removeUser();
      window.location.href = '/';
    });
  }, [auth, auth.events, auth.signoutRedirect]);

  useEffect(() => {
    if (!auth.isLoading && (auth.user?.expired || !auth.isAuthenticated)) {
      let existingCompanyId = localStorage.getItem('company') ?? '';
      const companyIdInClaims = auth.user?.profile[UserClaims.CompanyId];
      existingCompanyId =
        existingCompanyId.length && !companyIdInClaims ? '' : existingCompanyId;

      auth
        .signinSilent({
          extraTokenParams: { existingCompanyId: existingCompanyId }
        })
        .then(response => {
          if (response === null) {
            throw Error('login_required');
          }

          return response;
        })
        .catch(err => {
          console.log('ERRORRRR', err);
          if (err?.message.match(/login_required/)) {
            localStorage.removeItem('company');
            auth.signinRedirect({ state: window.location.href });
          } else if (err?.message.match(/invalid_grant/)) {
            auth.signinRedirect({
              state: window.location.href,
              extraTokenParams: { existingCompanyId: existingCompanyId }
            });
          }
        });
    }
  }, [auth.isLoading]);

  useEffect(() => {
    dispatch(actionCreators.setIsLoading(isLoading));
  }, [isLoading]);

  useEffect(() => {
    if (auth.user && trainingSubClaim && trainingSubClaim === 'True') {
      const isFirstLogin = auth.user?.profile[UserClaims.IsFirstTrainingLogin];
      if (isFirstLogin && isFirstLogin === 'True') {
        firstLoginActions();
      }
    }
  }, [auth.user, trainingSubClaim]);

  const firstLoginActions = async () => {
    const employeeId = auth.user?.profile[UserClaims.EmployeeId] as string;

    try {
      const response = await axios.get(
        `${config.STUDENT_API_URL}lessonprogress/complete/byCurrentEmployee/ids`
      );

      dispatch(
        studentActionCreators.createLearningProfile(
          auth.user?.profile[UserClaims.EmployeeId] as string,
          auth.user?.profile[UserClaims.CompanyId] as string
        )
      );

      if (response.data) {
        // The logic to identify first login doesn't work for old users,
        // so, validating the number of lessons completed is an attempt to identify these cases.
        if (!response.data.length) {
          await dispatch<Promise<void>>(
            lessonActions.createDefaultAssignments()
          );
          dispatch(studentActions.getEmployeePlaylist(employeeId));
        }
      }
    } catch (e) {
      handleGenericBackendError(e);
    } finally {
      dispatch(employeeActions.updateFirsLoginAt(employeeId));
    }
  };

  if (isLoading) {
    return <AccessDeniedLoadingScreen />;
  }

  if (
    auth.user &&
    trainingSubClaim &&
    trainingSubClaim === 'True' &&
    daysUntil >= 0
  )
    return <>{children}</>;
  else {
    return <AccessDenied />;
  }
};

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ children }) => {
  const onSigninCallback = (user: User | void) => {
    if (user) {
      localStorage.setItem(
        'company',
        user.profile[UserClaims.CompanyId] as string
      );

      if (user.state && typeof user.state === 'string') {
        window.location.href = user.state;
      } else {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      }
    }
  };

  return (
    <AuthProvider
      authority={config.AUTHORITY ?? ''}
      client_id={config.CLIENT_ID ?? ''}
      redirect_uri={config.REDIRECT_URI}
      client_secret={config.CLIENT_SECRET}
      monitorSession={true}
      loadUserInfo={true}
      onSigninCallback={onSigninCallback}
      scope={config.SCOPES}
      automaticSilentRenew
      post_logout_redirect_uri={config.LOGOUT_CALLBACK}
    >
      <LogIn>{children}</LogIn>
    </AuthProvider>
  );
};

export default PrivateRoute;

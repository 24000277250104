import moment, { Moment } from 'moment';

// This function will take a Date with Timezone information and
// Will convert it to a localized moment date, ignoring the time/timezone information
export const absUtcDateToLocalMoment = (utcDate: Date) => {
  return moment.utc(utcDate).local();
};

export const formatIsoDurationToTime = (duration: string) => {
  const durationInSeconds = moment.duration(duration).asSeconds() + 1;
  const date = new Date(0);
  date.setSeconds(durationInSeconds);

  return date.toISOString().substring(11, 19);
};

export const isNewContent = (
  date: Moment,
  granularity?: moment.unitOfTime.StartOf | undefined
) => {
  const today = moment();
  const todayLess60 = moment().subtract(60, 'days');
  return date.isBetween(
    todayLess60,
    today,
    granularity ? granularity : 'day',
    '[]'
  );
};

export const isOverdue = (
  date: Moment,
  granularity?: moment.unitOfTime.StartOf | undefined
) => {
  const today = moment();
  const pastDueDate = moment(date);
  return pastDueDate.isBefore(today, granularity ? granularity : 'day');
};

export const toHoursAndMinutes = (totalMinutes: number) => {
  if (totalMinutes < 0) {
    return '00:00:00';
  }
  // Hours
  const hours = totalMinutes / 60;
  const integerHours = Math.trunc(hours);
  const decimalHours = getDecimalPart(hours);
  // Minutes
  const minutes = decimalHours * 60;
  const integerMinutes = Math.trunc(minutes);
  const decimalMinutes = getDecimalPart(minutes);

  // Seconds
  const seconds = Math.trunc(decimalMinutes * 60);

  const integerHoursString =
    integerHours < 10 ? '0' + integerHours : integerHours;
  const integerMinutesString =
    integerMinutes < 10 ? '0' + integerMinutes : integerMinutes;
  const integerSecondsString = seconds < 10 ? '0' + seconds : seconds;

  return (
    integerHoursString + ':' + integerMinutesString + ':' + integerSecondsString
  );
};

export const secondsToHHMMSS = (seconds: number) => {
  try {
    if (seconds < 0) {
      return '00:00:00';
    }
    const res = new Date(seconds * 1000).toISOString().slice(11, 19);
    return res;
  } catch (e) {
    console.log(`error converting ${seconds} seconds to hh:mm:ss`);
    return '00:00:00';
  }
};

const getDecimalPart = (num: number) => {
  if (Number.isInteger(num)) {
    return 0;
  }

  const decimalStr = num.toString().split('.')[1];
  return Number(Number('.' + decimalStr).toFixed(6));
};

export const convertDateToUtcAndAddTime = (date: Date, time: string) => {
  const hours = parseInt(time.split(':')[0]);
  const minutes = parseInt(time.split(':')[1]);
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return moment(date).utc().toDate();
};

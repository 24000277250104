import { ReactNode, useRef } from 'react';
import {
  AutoSizer,
  WindowScroller,
  List,
  ListRowProps
} from 'react-virtualized';
import { ListChildComponentProps } from 'react-window';
import { LearningUnitType } from '../../hooks/useContentLibraryNavigation';
import { useVirtualizedContentLibraryList } from '../../hooks/useVirtualizedContentLibraryList';
import NoResultsFound from '../../pages/contentLibrary/common/NoResultsFound';

interface ContentLibraryVirtualListProps<T> {
  items?: T[];
  learningPlanUnit: LearningUnitType;
  isLoading: boolean;
  itemSize: number;
  children: (props: ListChildComponentProps<T[][]>) => ReactNode;
}

function ContentLibraryVirtualList<T>({
  items,
  learningPlanUnit,
  isLoading,
  itemSize,
  children
}: ContentLibraryVirtualListProps<T>) {
  const list = useRef<any>(null);

  const [matrix, onScroll] = useVirtualizedContentLibraryList(
    list,
    items,
    learningPlanUnit,
    isLoading
  );

  const rowRenderer = ({ index, style }: ListRowProps) => {
    return children({ data: matrix!, index, style });
  };

  const noResultsFound = !isLoading && matrix?.length === 0;
  return (
    <>
      {noResultsFound && <NoResultsFound />}
      <WindowScroller scrollElement={window} onScroll={onScroll}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <div
            style={{
              flex: '1 1 auto'
            }}
          >
            <AutoSizer disableHeight>
              {({ width }) => (
                <div ref={() => registerChild()}>
                  <List
                    ref={list}
                    autoHeight
                    className="window-scroller-override"
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    overscanRowCount={2}
                    rowCount={matrix?.length ?? 0}
                    rowHeight={itemSize}
                    rowRenderer={rowRenderer}
                    scrollTop={scrollTop}
                    width={width}
                  />
                </div>
              )}
            </AutoSizer>
          </div>
        )}
      </WindowScroller>
    </>
  );
}

export default ContentLibraryVirtualList;

import { forwardRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';

import TextBox from '../../components/core/TextBox/TextBox';
import TagInput from '../../components/TagInput/TagInput';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import { useCertifications } from '../../hooks/useCertifications';
import { CertificationPostPut } from '../../entities/Certification';
import TextArea from '../../components/core/TextArea/TextArea';
import { Tag } from '../../entities/Tag';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';

const FormSection = styled(Stack)(() => ({
  paddingTop: '0.5rem'
}));

const ErrorLabel = ({ text }: { text: string }) => {
  return (
    <Typography variant="subtitle2" sx={{ color: '#FF0000' }}>
      {text}
    </Typography>
  );
};

interface CertificationFormProps {
  certification: CertificationPostPut;
  onSubmit: (data: CertificationPostPut) => void;
}

const CertificationForm = forwardRef<HTMLButtonElement, CertificationFormProps>(
  ({ certification, onSubmit }, ref) => {
    const [disableDurationComponents, setDisableDurationComponents] =
      useState<boolean>(false);
    const { t } = useTranslation(['certifications', 'common']);
    const {
      constants: {
        durationUnits,
        durations,
        notificationDaysTags,
        employeeTagNames
      }
    } = useCertifications();

    const user = useCurrentUser();
    const companyNameClaim = user?.profile[UserClaims.CompanyName];
    const companyName: string = companyNameClaim
      ? companyNameClaim.toString()
      : '';

    const formReturn = useForm<CertificationPostPut>({
      defaultValues: { ...certification, certifyingBody: companyName }
    });

    const {
      control,
      formState: { errors },
      watch,
      setValue,
      clearErrors,
      handleSubmit
    } = formReturn;

    useEffect(() => {
      if (employeeTagNames) {
        const awarderTags: Tag[] = [];
        certification.awarders.forEach(awarder => {
          const tag = employeeTagNames.find(
            tag => tag.id === awarder.employeeId
          );
          if (tag) awarderTags.push(tag);
        });

        if (awarderTags.length) setValue('awardersTags', awarderTags);
      }
    }, [employeeTagNames]);

    const handleCheckCertificationNeverExpires = (isChecked: boolean) => {
      setDisableDurationComponents(isChecked);
    };

    useEffect(() => {
      handleCheckCertificationNeverExpires(certification.durationNeverExpires);
      //set default value for certifying body
      if (
        certification.certifyingBody &&
        certification.thirdPartyCertificateRequired
      ) {
        setValue('certifyingBody', certification.certifyingBody);
      }
    }, []);

    const handleCertBodyChk = (checked: boolean) => {
      setValue('certifyingBody', checked ? '' : companyName);
    };

    const watchRequiredPartyChk = watch('thirdPartyCertificateRequired');

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormSection>
          <Typography variant="caption">
            {t('nameId', { ns: 'certifications' })}
          </Typography>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                {...field}
                id="name"
                name="name"
                placeholderText={t('certificationName', {
                  ns: 'certifications'
                })}
                width="100%"
                height="40px"
                maxLength={300}
                variant="dark"
                value={field.value}
                onChangeValue={value => {
                  field.onChange(value);
                }}
              />
            )}
          />
          {errors.name?.type === 'required' && (
            <ErrorLabel text={t('nameIsRequired', { ns: 'certifications' })} />
          )}
        </FormSection>
        <FormSection>
          <Typography variant="caption">
            {t('awarder', { ns: 'certifications' })}
          </Typography>
          <Controller
            name="awardersTags"
            control={control}
            render={({ field }) => (
              <TagInput
                disablePortal
                value={field.value}
                options={employeeTagNames ?? []}
                placeholder={t('beginTypingName', { ns: 'certifications' })}
                onChange={(_, tags) => {
                  field.onChange(tags);
                }}
              />
            )}
          />
        </FormSection>
        <FormSection>
          <Typography variant="caption">{t('certificateDuration')}</Typography>
          <Stack direction="row" spacing={{ md: 2 }} sx={{ flexWrap: 'wrap' }}>
            <Controller
              name="durationUnit"
              control={control}
              rules={{ required: !watch('durationNeverExpires') }}
              render={({ field }) => (
                <BasicSelect
                  {...field}
                  labelId="durationUnit"
                  id="durationUnit"
                  options={durationUnits}
                  value={field.value.length ? [field.value] : ''}
                  handleOnChange={items => {
                    if (items.length) {
                      field.onChange(items[0]);
                    }
                  }}
                  placeholder={t('select', { ns: 'common' })}
                  theme="dark"
                  multiple={false}
                  disabled={disableDurationComponents}
                  sx={{ ...filterStyle, width: '140px' }}
                />
              )}
            />
            <Controller
              name="duration"
              control={control}
              rules={{ required: !watch('durationNeverExpires') }}
              render={({ field }) => (
                <BasicSelect
                  {...field}
                  labelId="duration"
                  id="duration"
                  options={durations}
                  value={field.value.length ? [field.value] : ''}
                  handleOnChange={items => {
                    if (items.length) {
                      field.onChange(items[0]);
                    }
                  }}
                  placeholder={t('select', { ns: 'common' })}
                  theme="dark"
                  multiple={false}
                  disabled={disableDurationComponents}
                  sx={{ ...filterStyle, width: '140px' }}
                />
              )}
            />
            <Controller
              name="durationNeverExpires"
              control={control}
              render={({ field }) => (
                <FormCheckBox
                  {...field}
                  id="durationNeverExpires"
                  name="durationNeverExpires"
                  data-testid="durationNeverExpires"
                  checked={field.value}
                  onChange={value => {
                    const isChecked = value.target.checked;
                    field.onChange(isChecked);
                    handleCheckCertificationNeverExpires(isChecked);
                    if (isChecked) {
                      setValue('duration', '');
                      setValue('durationUnit', '');
                      setValue('notifyDaysBeforeExpirationTags', []);
                      clearErrors('durationUnit');
                      clearErrors('duration');
                    }
                  }}
                  label={t('neverExpires', { ns: 'certifications' })}
                />
              )}
            />
          </Stack>
          {(errors.durationUnit?.type === 'required' ||
            errors.duration?.type === 'required') && (
            <ErrorLabel
              text={t('certificationDurationIsRequired', {
                ns: 'certifications'
              })}
            />
          )}
        </FormSection>
        <FormSection>
          <Typography variant="caption">
            {t('wouldYouLikeToBeNotified')}
          </Typography>
          <Controller
            name="notifyDaysBeforeExpirationTags"
            control={control}
            render={({ field }) => (
              <TagInput
                {...field}
                disablePortal
                disabled={disableDurationComponents}
                value={field.value}
                options={notificationDaysTags}
                placeholder={t('selectDaysBeforeExpiration', {
                  ns: 'certifications'
                })}
                onChange={(_, tags) => {
                  field.onChange(tags);
                }}
                sx={{ maxWidth: '530px' }}
              />
            )}
          />
        </FormSection>
        <FormSection>
          <Controller
            name="thirdPartyCertificateRequired"
            control={control}
            render={({ field }) => (
              <FormCheckBox
                {...field}
                id="thirdPartyCertificateRequired"
                data-testid="thirdPartyCertificateRequired"
                checked={field.value}
                label={t('thirdPartyCertificateRequired', {
                  ns: 'certifications'
                })}
                onChange={value => {
                  field.onChange(value.target.checked);
                  handleCertBodyChk(value.target.checked);
                }}
              />
            )}
          />
        </FormSection>

        <FormSection>
          <Typography variant="caption">
            {t('certifyingBody', { ns: 'certifications' })}
          </Typography>
          <Controller
            name="certifyingBody"
            control={control}
            rules={{ required: watchRequiredPartyChk }}
            render={({ field }) => (
              <TextBox
                {...field}
                id="certifyingBody"
                name="certifyingBody"
                placeholderText={t('certificationBodyName', {
                  ns: 'certifications'
                })}
                width="100%"
                height="40px"
                maxLength={300}
                variant="dark"
                value={field.value}
                disabled={!watchRequiredPartyChk}
                customStyle={{
                  color: !watchRequiredPartyChk ? 'rgb(153,153,153)' : 'white'
                }}
                onChangeValue={value => {
                  field.onChange(value);
                }}
              />
            )}
          />
          {errors.certifyingBody?.type === 'required' && (
            <ErrorLabel
              text={t('certificationBodyIsRequired', { ns: 'certifications' })}
            />
          )}
        </FormSection>

        <FormSection>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                {...field}
                id="description"
                name="description"
                width="100%"
                rows={4}
                variant="dark"
                value={field.value!}
                placeholderText={t('description', { ns: 'certifications' })}
                onChangeValue={value => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </FormSection>
        <button
          type="submit"
          id="submitForm"
          style={{ display: 'none' }}
          ref={ref}
        />
      </form>
    );
  }
);

const filterStyle: SxProps = {
  backgroundColor: '#000',
  maxWidth: '300px',
  width: '100%',
  height: '40px'
};

export default CertificationForm;

import React, { useState, useEffect, CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormLabel from '../core/FormLabel/FormLabel';
import { Question, Answer, Option } from '../../entities/Assessment';
import BasicButton from '../core/BasicButton/BasicButton';
import { ImageList, ImageListItemBarProps, Typography } from '@mui/material';
import { MultipleQuestionImageListItem } from './MultipleQuestionImageListItem';
import { useTranslation } from 'react-i18next';

export interface MultipleChoiceImageQuestionProps {
  question: Question;
  questionResult?: Answer;
  setAssessmentResult: (answer: Answer) => void;
}

export interface ImageListItemBarResultProps extends ImageListItemBarProps {
  type: string;
}

const MultipleChoiceImageQuestion: React.FC<
  MultipleChoiceImageQuestionProps
> = ({ question, questionResult, setAssessmentResult }) => {
  const [answer, setAnswer] = useState<Option | undefined>();
  const { t } = useTranslation(['assessments']);

  useEffect(() => {
    setAnswer(undefined);
  }, [question]);

  useEffect(() => {
    if (questionResult) {
      if (question && question.options) {
        const optionResult = question.options.find(
          option => option.id === questionResult?.selectedOptionId
        );
        setAnswer(optionResult);
      }
    }
  }, [questionResult]);

  const onSubmitResponse = () => {
    if (questionResult) return;

    setAssessmentResult({
      selectedOptionId: answer?.id,
      questionId: question.id,
      isCorrect: answer?.isCorrect,
      type: question && question.type ? question.type : '',
      order: question?.order
    });
  };

  const selectImage =
    (option: Option) => (e: React.MouseEvent<HTMLElement>) => {
      if (questionResult) return;
      setAnswer({ ...option });
    };

  const renderImageList = () => {
    if (question && question.options) {
      return (
        <ImageList cols={2}>
          {question.options.map(option => {
            return (
              <MultipleQuestionImageListItem
                key={option.id}
                option={option}
                isAnswerSubmitted={!!questionResult}
                answer={answer}
                onSelectImage={selectImage(option)}
              />
            );
          })}
        </ImageList>
      );
    }
  };
  const isSubmitButtonDisabled = !!questionResult || !answer;
  return (
    <Row>
      <Col>
        <Row className="mt-3">
          <Col lg={3}>
            <FormLabel>
              {t('selectCorrectImage', { ns: 'assessments' })}
            </FormLabel>
            <Typography style={questionTextStyle}>
              {question.questionText}
            </Typography>
          </Col>
          <Col lg={9}>{renderImageList()}</Col>
        </Row>
        <Row className="mt-3">
          <Col lg={12}>
            <BasicButton
              color="primary"
              onClick={onSubmitResponse}
              disable={isSubmitButtonDisabled}
            >
              {t('submitResponse', { ns: 'assessments' })}
            </BasicButton>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const questionTextStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '24px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '155%'
};

export default MultipleChoiceImageQuestion;

import { useMemo, useRef, useState } from 'react';
import { Box, CardContent, Stack } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import fileDownload from 'js-file-download';

import {
  LinkStyle,
  StyledCardActions,
  StyledTitle
} from './CertificationCardComponents';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';
import {
  MyCertification,
  PdfCertification
} from '../../../../entities/Certification';
import {
  downloadBlob,
  downloadAndCreateZipBlob
} from '../../../../services/blobStorage-service';
import config from '../../../../config';
import { LightStyleTooltip } from '../../../../components/core/StyledTooltip/StyledTooltip';
import { AttachmentFile } from '../../../../entities/Achievements';
import { Guid } from 'guid-typescript';
import { pdf } from '@react-pdf/renderer';
import CertificatePDFTemplate from '../../../certifications/Common/CertificationPDFTemplate';
import { useCalendarHelper } from '../../../../hooks/useCalendarHelper';
import { absUtcDateToLocalMoment } from '../../../../utils/dateUtils';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import {
  UserClaims,
  systemGeneratedPdf,
  systemGeneratedPdfName
} from '../../../../core/constants';

interface AttachedFilesViewProps {
  employeeCertification: MyCertification;
  onBackToOverview: () => void;
}

const AttachedFilesView = ({
  employeeCertification,
  onBackToOverview
}: AttachedFilesViewProps) => {
  const { t } = useTranslation(['certifications']);
  const user = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();
  const {
    constants: { monthNames }
  } = useCalendarHelper();
  const { certificationName } = employeeCertification;
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [showTitleTooltip, setShowTitleTooltip] = useState<boolean>(false);
  const downloadingBlobRef = useRef<boolean>(false);
  const systemPdfRef = useRef<Blob | null>(null);
  const awardeeFirstName = user?.profile[UserClaims.FirstName] as string;
  const awardeeLastName = user?.profile[UserClaims.LastName] as string;
  const companyLogo = user?.profile[UserClaims.CompanyLogoUrl] as string;
  const attachedFiles = useMemo(() => {
    const tempAttachedFiles = employeeCertification.attachedFiles.slice();
    if (employeeCertification.generateSystemCertificate) {
      const systemGeneratedFile: AttachmentFile = {
        id: Guid.create().toString(),
        name: systemGeneratedPdfName,
        url: systemGeneratedPdf
      };

      tempAttachedFiles.unshift({
        id: `${systemGeneratedPdf}-${Guid.create()}`,
        competenceFile: systemGeneratedFile
      });
    }

    return tempAttachedFiles;
  }, [employeeCertification.attachedFiles]);

  const rowsPerPage = 4;
  const pages = Math.ceil(attachedFiles.length / 4);

  const fileList = useMemo(() => {
    const startIndex = (pageNumber - 1) * 4;
    const endIndex = rowsPerPage * pageNumber;
    return attachedFiles.slice(startIndex, endIndex);
  }, [pageNumber]);

  const handleShouldShowTooltip = ({
    currentTarget
  }: React.MouseEvent<Element>) => {
    setShowTitleTooltip(
      currentTarget.scrollHeight > currentTarget.clientHeight
    );
  };

  const createSystemBlob = async () => {
    const { certificationName, awarderFullName, createdAt, expirationDate } =
      employeeCertification;

    const momentCreatedAt = absUtcDateToLocalMoment(createdAt);
    const momentExpirationDate = expirationDate
      ? absUtcDateToLocalMoment(expirationDate)
      : null;

    const pdfData: PdfCertification = {
      name: certificationName,
      awarderName: awarderFullName,
      formattedCreatedAt: `${
        monthNames[momentCreatedAt.month()]
      } ${momentCreatedAt.date()}, ${momentCreatedAt.year()}`,
      formattedExpirationDate: momentExpirationDate
        ? `${
            monthNames[momentExpirationDate.month()]
          } ${momentExpirationDate.date()}, ${momentExpirationDate.year()}`
        : '',
      companyLogoUrl: companyLogo,
      employeeName: `${awardeeFirstName} ${awardeeLastName}`
    };

    return await pdf(<CertificatePDFTemplate pdfData={pdfData} />).toBlob();
  };

  const handleDownloadFile = async (url: string, fileName: string) => {
    if (downloadingBlobRef.current) return;
    try {
      downloadingBlobRef.current = true;
      if (url == systemGeneratedPdf && !systemPdfRef.current) {
        const blob = await createSystemBlob();
        systemPdfRef.current = blob;
      }

      if (url == systemGeneratedPdf && systemPdfRef.current) {
        fileDownload(systemPdfRef.current, systemGeneratedPdfName);
      } else {
        await downloadBlob(
          url,
          fileName,
          config.BLOB_SKILLATTACHMENTS_CONTAINER!
        );
      }
    } catch (e) {
      enqueueSnackbar(
        t('errorWhileDownloadingCertFiles', { ns: 'certifications' }),
        { variant: 'error' }
      );
    } finally {
      downloadingBlobRef.current = false;
    }
  };

  const handleDownloadAllFiles = async () => {
    if (downloadingBlobRef.current) return;
    const blobsInfo = attachedFiles
      .filter(file => file.competenceFile.url !== systemGeneratedPdf)
      .map(file => ({
        fileName: file.competenceFile.name,
        url: file.competenceFile.url
      }));

    const systemBlobs: { blob: Blob; name: string }[] = [];
    const systemPdf = attachedFiles.find(
      file => file.competenceFile.url === systemGeneratedPdf
    );
    if (systemPdf) {
      const blob = await createSystemBlob();
      systemBlobs.push({ blob, name: systemGeneratedPdfName });
    }

    if (blobsInfo.length) {
      try {
        downloadingBlobRef.current = true;
        await downloadAndCreateZipBlob(
          blobsInfo,
          config.BLOB_SKILLATTACHMENTS_CONTAINER!,
          `ZIP-${employeeCertification.certificationName}-Files.zip`,
          systemBlobs
        );
      } catch (e) {
        enqueueSnackbar(
          t('errorWhileDownloadingCertFiles', { ns: 'certifications' }),
          { variant: 'error' }
        );
      } finally {
        downloadingBlobRef.current = false;
      }
    }
  };

  return (
    <>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            height: '30px'
          }}
        >
          <Box onClick={handleDownloadAllFiles}>
            <DownloadForOfflineIcon
              data-testid="downloadAllTestId"
              fontSize="large"
              color="secondary"
              sx={{ cursor: 'pointer' }}
            />
          </Box>
        </Box>
        <LightStyleTooltip title={showTitleTooltip ? certificationName : ''}>
          <StyledTitle
            onMouseEnter={handleShouldShowTooltip}
            className="breaking-text"
          >
            {certificationName}
          </StyledTitle>
        </LightStyleTooltip>
        <Stack>
          <ul>
            {fileList.map(file => (
              <LightStyleTooltip
                key={file.id}
                title={showTitleTooltip ? file.competenceFile.name : ''}
                popperOptions={{
                  offset: [0, 0]
                }}
              >
                <li>
                  <LinkStyle
                    key={file.id}
                    onMouseEnter={handleShouldShowTooltip}
                    onClick={() =>
                      handleDownloadFile(
                        file.competenceFile.url,
                        file.competenceFile.name
                      )
                    }
                    className="breaking-text-one-line"
                  >
                    {file.competenceFile.name}
                  </LinkStyle>
                </li>
              </LightStyleTooltip>
            ))}
          </ul>
        </Stack>
      </CardContent>
      <StyledCardActions>
        <BasicButton
          color="primaryOutlined"
          height="42px"
          style={{ minWidth: '60px' }}
          onClick={onBackToOverview}
          data-testid="backToOverview"
        >
          {t('back')}
        </BasicButton>
        <Stack direction="row" spacing={1}>
          {pageNumber > 1 && (
            <BasicButton
              color="primary"
              height="42px"
              style={{ minWidth: '60px' }}
              onClick={() => setPageNumber(value => value - 1)}
              data-testid="backToPreviousPage"
            >
              {t('back')}
            </BasicButton>
          )}
          {pages > 1 && pageNumber < pages && (
            <BasicButton
              color="primary"
              height="42px"
              style={{ minWidth: '60px' }}
              onClick={() => setPageNumber(value => value + 1)}
              data-testid="goToNextPage"
            >
              {t('next')}
            </BasicButton>
          )}
        </Stack>
      </StyledCardActions>
    </>
  );
};

export default AttachedFilesView;

import React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import EmptyCard from '../../components/EmptyCard/EmptyCard';

export interface AllAssignmentsTabContainerProps {
  isLoading: boolean;
  isEmpty: boolean;
  listIsReady: boolean;
  emptyText: string;
}

const AllAssignmentsTabContainer: React.FC<
  React.PropsWithChildren<AllAssignmentsTabContainerProps>
> = ({ isLoading, isEmpty, listIsReady, emptyText, children }) => {
  return (
    <>
      {listIsReady && children}
      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {isEmpty && (
        <EmptyCard width="370px" height="325px">
          <h5>{emptyText}</h5>
        </EmptyCard>
      )}
    </>
  );
};

export default AllAssignmentsTabContainer;

import React, { useState, CSSProperties, useImperativeHandle } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { dataURLtoFile } from '../../../utils/commonUtils';
import { uploadOnsiteTrainingSignaturesToBlob } from '../../../services/blobStorage-service';

interface SignatureCanvaProps {
  width: number;
  height: number;
  handleSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignatureCanva = React.forwardRef(
  ({ width, height, handleSaveButton }: SignatureCanvaProps, ref) => {
    const [sign, setSign] = useState<any>();

    useImperativeHandle(ref, () => ({
      handleClear() {
        sign.clear();
        handleSaveButton(true);
      },
      async handleGenerate() {
        const signatureDataUrl = sign.getTrimmedCanvas().toDataURL('image/png');
        const file = dataURLtoFile(signatureDataUrl, 'signature.png');
        return await uploadOnsiteTrainingSignaturesToBlob(file);
      }
    }));

    return (
      <div style={{ ...containerCanva, width: width, height: height }}>
        <SignatureCanvas
          canvasProps={{ width: width, height: height, className: 'sigCanvas' }}
          ref={data => setSign(data)}
          onEnd={() => {
            handleSaveButton(false);
          }}
        />
      </div>
    );
  }
);

const containerCanva: CSSProperties = {
  border: '1px solid white',
  borderRadius: '10px',
  backgroundColor: '#FFF',
  marginBottom: '10px',
  marginTop: '10px'
};

export default SignatureCanva;

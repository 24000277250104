import { CSSProperties, useEffect, useState } from 'react';
import { actionCreators as achievementActions } from '../../actions/achievements';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Guid } from 'guid-typescript';
import { Tag } from '../../entities/Tag';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import { validImageFormat } from '../../utils/validationsUtils';
import LearningPlanInputsForm from './LearningPlanInputsForm';
import { LearningPlan } from '../../entities/LearningPlan';
import DragDropList, {
  DraggableListItem
} from '../../components/DragDropList/DragDropList';
import { EntityTypeEnum, LearningUnitType } from '../../core/enums';
import BasicButton from '../core/BasicButton/BasicButton';
import { buttonStyle, fontButton } from '../../utils/buttonStyles';
import FooterActionButtons from './FooterActionButtons';
import { Course } from '../../entities/Course';
import { FormErrors } from '../core/FormErrors/FormErrors';
import { enqueueSnackbar } from 'notistack';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import { useAppDispatch } from '../../store/hooks';
export interface LearningPlanFormProps {
  learningPlan: LearningPlan;
  learningPlanCourses: Array<Course>;
  isEdit: boolean;
  setOpenSelectCourseModal: (open: boolean) => void;
  openDeleteLPConfirmationModal: (open: boolean) => void;
  openDeleteCourseConfirmationModal: (courseId: string) => void;
  saveLearningPlan: (
    learningPlan: LearningPlan,
    imgFromLibrary: string,
    saveAndStay: boolean,
    isPublishing: boolean,
    thumbnailFile?: File | null
  ) => Promise<void>;
}

export interface ValidationFieldsState {
  nameMsg: string;
  descriptionMsg: string;
  thumbnailMsg: string;
}

enum CourseChangeType {
  AddFirstCourse,
  AddCourse,
  ChangeOrder,
  DeleteCourse
}

const LearningPlanForm: React.FC<LearningPlanFormProps> = ({
  learningPlan,
  learningPlanCourses,
  isEdit,
  setOpenSelectCourseModal,
  openDeleteLPConfirmationModal,
  openDeleteCourseConfirmationModal,
  saveLearningPlan
}) => {
  const {
    variables: { skillsAndCertifications }
  } = useDevCycleSdk();
  const dispatch = useAppDispatch();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [learningPlanData, setLearningPlanData] =
    useState<LearningPlan>(learningPlan);
  const [list, setList] = useState<DraggableListItem[]>([]);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>();
  const [displaySaveOrderButton, setDisplaySaveOrderButton] =
    useState<boolean>(false);
  const [imgFromLib, setImgFromLib] = useState<string>('');
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [skillsCerts, setSkillsCerts] = useState<Array<Tag>>([]);
  const [skillsCertsDeleted, setSkillsCertsDeleted] = useState<Array<Tag>>([]);
  const { t } = useTranslation(['learningPlans', 'common', 'courses']);
  const validationMessages = {
    NAME_MADATORY: t('nameIsRequired', { ns: 'common' }),
    DESCRIPTION_MADATORY: t('descriptionIsRequired', { ns: 'common' }),
    THUMBNAIL_MADATORY: t('thumbnailIsRequired', { ns: 'common' }),
    FILE_FORMAT_ERROR: t('invalidImageFormat', { ns: 'common' }),
    CERTIFICATE_OPTION_MANDATORY: t('selectedOptionIsRequired', {
      ns: 'common'
    }),
    NAME_MAX_CHARACTER_LIMIT: `${t('name', {
      ns: 'common'
    })} ${t('exceedCharacterLimitMsg', { ns: 'common' })} (150)`,
    DESCRIPTION_MAX_CHARACTER_LIMIT: `${t('description', {
      ns: 'common'
    })} ${t('exceedCharacterLimitMsg', { ns: 'common' })} (800)`
  };

  useEffect(() => {
    if (learningPlan) {
      setLearningPlanData(learningPlan);
      setImgFromLib(learningPlan.thumbnailUrl);
    }

    if (learningPlanCourses) {
      setList(mapCourses(learningPlanCourses));
    }
  }, [learningPlan, learningPlanCourses.length]);

  const mapCourses = (courses: Course[]) => {
    const res: DraggableListItem[] = [];
    let lpCourses = learningPlan?.entries.filter(e => e.courseId.length);

    if (lpCourses) {
      lpCourses = lpCourses.sort((lp1, lp2) => lp1.order - lp2.order);
    }

    lpCourses?.forEach(lpCourse => {
      const course = courses.find(course => course.id === lpCourse.courseId);

      if (course)
        res.push({
          id: course.id,
          title: course.title,
          videosCount: course.videosCount,
          flashCardsCount: course.flashCardsCount,
          quizesCount: course.quizesCount
        });
    });

    return res;
  };

  const getItemsFromDDList = (items: DraggableListItem[]) => {
    if (learningPlan && learningPlan.entries) {
      const entries = [...learningPlan.entries];
      const orderedEntries = entries.map(entry => {
        return {
          ...entry,
          order: items.findIndex(item => item.id === entry.courseId) + 1
        };
      });

      const courseEntries = learningPlan?.entries.filter(
        e => e.courseId.length > 0
      );
      let isDifferent = false;
      courseEntries?.forEach(entry => {
        if (
          !orderedEntries.find(
            orderedEntry =>
              orderedEntry.courseId === entry.courseId &&
              orderedEntry.order === entry.order
          )
        )
          isDifferent = true;
      });
      const learningPlanTemp = { ...learningPlan, entries: orderedEntries };
      setLearningPlanData(learningPlanTemp);
      setDisplaySaveOrderButton(isDifferent);
    }
  };

  const validateFields = (componentId: string, value: any) => {
    let msg = '';

    switch (componentId) {
      case 'name':
        if (!value.trim().length) msg = validationMessages.NAME_MADATORY;
        else if (value.trim().length > 150)
          msg = validationMessages.NAME_MAX_CHARACTER_LIMIT;
        break;

      case 'description':
        if (!value.trim().length) msg = validationMessages.DESCRIPTION_MADATORY;
        break;

      case 'fileInput':
        if (!value || !value.length)
          msg = validationMessages.THUMBNAIL_MADATORY;
        else if (!validImageFormat(value[0].type))
          msg = validationMessages.FILE_FORMAT_ERROR;
        break;
      default:
        break;
    }

    return msg;
  };

  const learningPlanFormHasChanged = () => {
    return (
      learningPlan.name !== learningPlanData.name ||
      learningPlan.description !== learningPlanData.description ||
      !!thumbnailFile ||
      learningPlan.thumbnailUrl !== imgFromLib
    );
  };

  const handleOpenSelectCourseModal = async () => {
    if (learningPlanData.id === Guid.EMPTY) {
      const createResult = await handleSaveCoursesChanges(
        CourseChangeType.AddFirstCourse
      );
      if (!createResult) {
        return;
      }
    } else if (learningPlanFormHasChanged()) {
      const updateResult = await handleSaveCoursesChanges(
        CourseChangeType.AddCourse
      );
      if (!updateResult) {
        return;
      }
    }

    setOpenSelectCourseModal(true);
  };

  const handleSaveCourseOrder = async () => {
    await handleSaveCoursesChanges(CourseChangeType.ChangeOrder);
    setDisplaySaveOrderButton(false);
  };

  const handleDeleteCourse = async (courseId: string) => {
    if (learningPlanFormHasChanged()) {
      const result = await handleSaveCoursesChanges(
        CourseChangeType.DeleteCourse
      );
      if (!result) {
        return;
      }
    }

    if (list.length === 1 && !learningPlanData.isDraft) {
      enqueueSnackbar(
        t('publishedLPMustHaveAtLeastOneCourse', { ns: 'learningPlans' }),
        { variant: 'warning' }
      );
      return;
    }

    openDeleteCourseConfirmationModal(courseId);
  };

  const displayValidationAlerts = (courseChangeType: CourseChangeType) => {
    switch (courseChangeType) {
      case CourseChangeType.AddFirstCourse:
      case CourseChangeType.AddCourse:
        enqueueSnackbar(
          t('mustFillLPFormBeforeAddingCourses', { ns: 'learningPlans' }),
          { variant: 'warning' }
        );
        break;
      case CourseChangeType.ChangeOrder:
        enqueueSnackbar(
          t('mustFillLPFormBeforeSavingCoursesOrder', { ns: 'learningPlans' }),
          { variant: 'warning' }
        );
        break;
      case CourseChangeType.DeleteCourse:
        enqueueSnackbar(
          t('mustFillLPFormBeforeDeletingCourses', { ns: 'learningPlans' }),
          { variant: 'warning' }
        );
        break;
      default:
        break;
    }
  };

  const displayExceptionAlerts = (courseChangeType: CourseChangeType) => {
    switch (courseChangeType) {
      case CourseChangeType.AddFirstCourse:
        enqueueSnackbar(
          t('createLPToAddCoursesErrorMsg', { ns: 'learningPlans' }),
          { variant: 'error' }
        );
        break;
      case CourseChangeType.AddCourse:
        enqueueSnackbar(
          t('updateLPToAddCoursesErrorMsg', { ns: 'learningPlans' }),
          { variant: 'error' }
        );
        break;
      case CourseChangeType.ChangeOrder:
        enqueueSnackbar(
          t('saveCoursesOrderErrorMsg', { ns: 'learningPlans' }),
          { variant: 'error' }
        );
        break;
      case CourseChangeType.DeleteCourse:
        enqueueSnackbar(
          t('updateLPToDeleteCoursesErrorMsg', { ns: 'learningPlans' }),
          { variant: 'error' }
        );
        break;
      default:
        break;
    }
  };

  const handleSaveCoursesChanges = async (
    courseChangeType: CourseChangeType
  ) => {
    setFormErrors([]);
    if (!validateLearningPlanBeforeSaving(learningPlanData)) {
      showLearningPlanErrors(learningPlanData);
      displayValidationAlerts(courseChangeType);
      return false;
    }

    try {
      await saveLearningPlan(
        learningPlanData,
        imgFromLib,
        true,
        false,
        thumbnailFile
      );
      return true;
    } catch (e) {
      displayExceptionAlerts(courseChangeType);
      return false;
    }
  };

  const handleSaveLearningPlan = async (
    learningPlanToSave: LearningPlan,
    isPublishing = false
  ) => {
    const crudAction =
      learningPlanToSave.id === Guid.EMPTY ? 'create' : 'update';
    setFormErrors([]);

    if (validateLearningPlanBeforeSaving(learningPlanToSave)) {
      try {
        await saveLearningPlan(
          learningPlanToSave,
          imgFromLib,
          false,
          isPublishing,
          thumbnailFile
        );
      } catch (e) {
        setFormErrors([t(`${crudAction}LPErrorMsg`, { ns: 'learningPlans' })]);
        scrollUp();
      }
    } else {
      showLearningPlanErrors(learningPlanToSave);
    }
  };

  const handleSave = async () => {
    if (!learningPlanData.isDraft && !list.length) {
      enqueueSnackbar(
        t('mustIncludeCoursesBeforePublishing', { ns: 'learningPlans' }),
        { variant: 'warning' }
      );
      return;
    }

    if (!saveInProgress) {
      setSaveInProgress(true);
      await handleSaveLearningPlan(learningPlanData);
      if (skillsAndCertifications.skillsAndCertifications) {
        dispatch(
          achievementActions.associateCompetenceToLearningUnit(
            learningPlan.id,
            LearningUnitType.learningPlan,
            skillsCerts
          )
        );
        isEdit &&
          (await dispatch<Promise<void>>(
            achievementActions.deleteLearningUnitToCompetence(
              skillsCertsDeleted,
              LearningUnitType.learningPlan
            )
          ));
      }
    }
  };

  const handleSaveAndPublish = async () => {
    if (!list.length) {
      enqueueSnackbar(
        t('mustIncludeCoursesBeforePublishing', { ns: 'learningPlans' }),
        { variant: 'warning' }
      );
      return;
    }

    const learningPlanToPublish: LearningPlan = {
      ...learningPlanData,
      isDraft: false
    };
    if (!saveInProgress) {
      setSaveInProgress(true);
      await handleSaveLearningPlan(learningPlanToPublish, true);
      if (skillsAndCertifications.skillsAndCertifications) {
        dispatch(
          achievementActions.associateCompetenceToLearningUnit(
            learningPlan.id,
            LearningUnitType.learningPlan,
            skillsCerts
          )
        );
      }
    }
  };

  const getSkillsCerts = (skillsCerts: any) => {
    setSkillsCerts(skillsCerts);
  };

  const getSkillsCertsDeleted = (skillsCertsDeleted: any) => {
    setSkillsCertsDeleted(skillsCertsDeleted);
  };

  const validateLearningPlanBeforeSaving = (
    learningPlanToValidate: LearningPlan
  ) => {
    return (
      !validateFields('name', learningPlanToValidate.name).length &&
      !validateFields('description', learningPlanToValidate.description)
        .length &&
      (thumbnailFile ||
        learningPlanToValidate.thumbnailUrl.length ||
        imgFromLib.length)
    );
  };

  const showLearningPlanErrors = (learningPlanToValidate: LearningPlan) => {
    const formErrorsArray: string[] = [];

    const validationResult: ValidationFieldsState = {
      nameMsg: validateFields('name', learningPlanToValidate.name),
      descriptionMsg: validateFields(
        'description',
        learningPlanToValidate.description
      ),
      thumbnailMsg:
        !thumbnailFile &&
        !learningPlanToValidate.thumbnailUrl.length &&
        !imgFromLib.length
          ? validationMessages.THUMBNAIL_MADATORY
          : ''
    };

    if (validationResult.nameMsg.length) {
      formErrorsArray.push(validationResult.nameMsg);
    }

    if (validationResult.descriptionMsg.length) {
      formErrorsArray.push(validationResult.descriptionMsg);
    }

    if (validationResult.thumbnailMsg.length) {
      formErrorsArray.push(validationResult.thumbnailMsg);
    }

    setFormErrors(formErrorsArray);
    scrollUp();
  };

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Grid container spacing={2}>
      {!!formErrors.length && (
        <Grid item xs={12}>
          <FormErrors
            errors={formErrors}
            title={t('followingErrorsWerefound', { ns: 'common' })}
          />
        </Grid>
      )}
      <LearningPlanInputsForm
        learningPlan={learningPlanData}
        imgFromLib={imgFromLib}
        setLearningPlan={setLearningPlanData}
        validateFields={validateFields}
        setThumbnailFile={setThumbnailFile}
        setImgFromLib={setImgFromLib}
        getSkillsCerts={getSkillsCerts}
        getSkillsCertsDeleted={getSkillsCertsDeleted}
        isEdit={isEdit}
      />
      <Grid item xs={12}>
        <FormLabel>
          {t('addCoursesToLearningPlan', { ns: 'learningPlans' })}
          <span className="text-danger">*</span>{' '}
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <DragDropList
          itemType={EntityTypeEnum.Course}
          items={list}
          deleteItem={handleDeleteCourse}
          getItems={getItemsFromDDList}
        >
          <div style={dragDropButtonsContainerStyle}>
            <BasicButton
              onClick={handleOpenSelectCourseModal}
              color="primary"
              style={buttonStyle}
            >
              <Typography sx={fontButton}>
                {t('selectCourses', { ns: 'courses' })}
              </Typography>
            </BasicButton>
            <span className="ps-2" />
            {displaySaveOrderButton && (
              <BasicButton
                onClick={handleSaveCourseOrder}
                color="secondary"
                width="175px"
                height="45px"
              >
                {t('saveCourseOrder', { ns: 'courses' })}
              </BasicButton>
            )}
          </div>
        </DragDropList>
      </Grid>
      <Grid item xs={12} sx={buttonsContainerStyle}>
        <FooterActionButtons
          learningPlanData={learningPlanData}
          isEdit={isEdit}
          saveInProgress={saveInProgress}
          onSave={handleSave}
          onPublish={handleSaveAndPublish}
          onConfirmationModalOpen={openDeleteLPConfirmationModal}
        />
      </Grid>
      <Grid item xs={12} paddingBottom="1rem" />
    </Grid>
  );
};

const buttonsContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap'
};

const dragDropButtonsContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row' as const,
  minHeight: '100px',
  paddingTop: '0'
};

export default LearningPlanForm;

import React, { PropsWithChildren } from 'react';
import usePermissions from '../../../hooks/usePermissions';
import DeniedAccessPage from '../../../pages/DeniedAccessPage';

type ProtectedRouteProps = {
  showDeniedIfNotAllowed?: boolean;
  bwAction: string;
};

export const ProtectedRoute: React.FC<
  React.PropsWithChildren<ProtectedRouteProps>
> = ({
  bwAction,
  showDeniedIfNotAllowed,
  children
}: PropsWithChildren<ProtectedRouteProps>) => {
  const hasPermission = usePermissions();

  const canDoAction = hasPermission(bwAction);

  if (canDoAction) {
    return <>{children}</>;
  }

  if (showDeniedIfNotAllowed) {
    return <DeniedAccessPage />;
  }

  return null;
};

export default ProtectedRoute;

import { useRef, useState } from 'react';
import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { FilterIcon } from '../../contentLibrary/common/ContentStatusFilter';
import { SortIcon } from '../../contentLibrary/common/SortContentSelect';
import SearchBox from '../../../components/core/SearchBox/SearchBox';

interface CardListActionsToolbarProps {
  onUpdateToolbarActionValues: (fieldName: string) => (value: any) => void;
}

const CardListActionsToolbar = ({
  onUpdateToolbarActionValues
}: CardListActionsToolbarProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  let searchTimeout: NodeJS.Timeout;
  const statusFilterOptions = [
    { value: 'New', label: t('new', { ns: 'certifications' }) },
    { value: 'Pending', label: t('pending', { ns: 'certifications' }) },
    { value: 'Expiring', label: t('expiring', { ns: 'certifications' }) },
    { value: 'Expired', label: t('expired', { ns: 'certifications' }) }
  ];
  const sortOptions = [
    {
      value: 'createdAt:asc',
      label: t('createdDateAsc', { ns: 'certifications' })
    },
    {
      value: 'createdAt:desc',
      label: t('createdDateDesc', { ns: 'certifications' })
    }
  ];
  const [sortOption, setSortOption] = useState<string[]>([
    sortOptions[0].value
  ]);
  const [filterOption, setFilterOption] = useState<string[]>([]);
  const statusFilterHasChangedRef = useRef<boolean>(false);

  const handleSortOptionSelection = (option: string[]) => {
    setSortOption(option);
    onUpdateToolbarActionValues('sortOption')(option[0]);
  };

  const handleCloseStatusFilter = () => {
    if (statusFilterHasChangedRef.current) {
      onUpdateToolbarActionValues('filterOption')(filterOption);
      statusFilterHasChangedRef.current = false;
    }
  };

  const handleStatusFilterSelection = (option: string[]) => {
    setFilterOption(option);
    statusFilterHasChangedRef.current = true;
  };

  const handleSearchKeyChange = (value: string) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      onUpdateToolbarActionValues('searchKey')(value);
    }, 1000);
  };

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      sx={{ paddingTop: '1rem', paddingBottom: '2rem' }}
    >
      <Box sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}>
        <BasicSelect
          id="sortOptions"
          options={sortOptions}
          value={sortOption}
          handleOnChange={handleSortOptionSelection}
          placeholder={t('sort', { ns: 'common' })}
          theme="dark"
          multiple={false}
          leftIcon={<SortIcon />}
          sx={{
            height: '40px',
            minWidth: '200px',
            maxWidth: '300px'
          }}
        />
      </Box>
      <Box sx={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}>
        <BasicSelect
          id="statusFilter"
          data-testid="statusFilter"
          options={statusFilterOptions}
          value={filterOption}
          handleOnChange={handleStatusFilterSelection}
          onClose={handleCloseStatusFilter}
          placeholder={t('filter', { ns: 'common' })}
          theme="dark"
          leftIcon={<FilterIcon />}
          sx={{
            height: '40px',
            minWidth: '150px',
            maxWidth: '330px'
          }}
        />
      </Box>
      <SearchBox
        id="searchContent"
        name="searchContent"
        value={''}
        onChangeValue={handleSearchKeyChange}
        placeholderText={t('search', { ns: 'common' })}
        height="40px"
        width="100%"
        minWidth="200px"
        maxWidth="300px"
      />
    </Stack>
  );
};

export default CardListActionsToolbar;

import React, { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions, CourseUnitTypes } from '../../core/constants';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Stack, useTheme } from '@mui/material';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CourseCardWrapper } from './CourseCardWrapper';
import { ICourseCardProps } from '../../entities/Card';
import {
  cardTitle,
  courseCardContainerStyle,
  EditSection,
  editStyle,
  InfoSection,
  labelStyle,
  spanStyle,
  styleIcon
} from './CourseCardStyles';
import {
  NewContentHeader,
  OverdueHeader
} from '../core/InfoCardHeader/InfoCardHeader';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import { Subtitles } from '../core/ChipSubtitles/ChipSubtitles';
import ChipAverageTime from '../core/ChipAverageTime/ChipAverageTime';
import BasicButton from '../core/BasicButton/BasicButton';
import { useGetCardOwnerShip } from '../../hooks/useGetCardOwnership';
import { isOverdue, isNewContent } from '../../utils/dateUtils';
import ChipTakenTimes from '../core/ChipTakenTimes/ChipTakenTimes';
import { useLogEvent } from '../../hooks/useLogEvent';
import MovieIcon from '../Icons/MovieIcon';

const buttonSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'space-between',
  alignItems: 'end'
};
const buttonSectionDraft: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'end',
  alignItems: 'end'
};

const CourseCardNew: React.FC<React.PropsWithChildren<ICourseCardProps>> = ({
  course,
  hasSpanishSubtitles,
  averageLessonTime,
  isAdmin,
  assignmentDueDate,
  status,
  isDraft,
  type,
  takenTimes,
  showCompletedAsNormal = false,
  isPreviewMode,
  children
}) => {
  const thumbnail = course.thumbnailUrl
    ? course.thumbnailUrl
    : BackgroundCoursePattern;

  const navigate = useNavigate();
  const theme = useTheme();
  const logEvent = useLogEvent();

  const isComplete = status === 'Completed';
  const pastDueDate = moment(assignmentDueDate);
  const isOverdueCourse = isOverdue(pastDueDate);
  const creationCourseDate = moment(course?.createdAt).local();
  const isNewContentCourse = isNewContent(creationCourseDate);

  const completeCourse =
    isComplete && !showCompletedAsNormal
      ? theme.palette.success.light
      : `${theme.palette.grey['100']}`;
  const newCourse =
    isNewContentCourse && !isComplete && !isDraft && !isOverdueCourse
      ? theme.palette.primary.main
      : `${theme.palette.grey['100']}`;
  const overdueCourse = isOverdueCourse
    ? theme.palette.error.main
    : `${theme.palette.grey['100']}`;

  const { t } = useTranslation(['common', 'assignments', 'courses', 'lessons']);
  const ownership = useGetCardOwnerShip(course);
  const editStyleForCompletedOrNewContent =
    isComplete || isNewContentCourse
      ? { ...editStyle, marginTop: '25px' }
      : editStyle;

  const handleEditCourse = (courseId: string) => {
    navigate(`/content-library/courses/${courseId}/edit`, {
      state: { isEditing: true }
    });
  };

  const renderOwnership = () => {
    if (ownership) {
      return (
        <InfoBySpan
          leftText={ownership.key}
          rightText={ownership.value}
          showProfilePicture={false}
          isOverdue={false}
        />
      );
    }

    return null;
  };

  const handleStartPreviewMode = () => {
    if (isPreviewMode) {
      if (type && type === CourseUnitTypes.clickSafetyCourse) {
        navigate(`/click-safety-course/${course.id}`);
      } else {
        navigate(`/courses/${course.id}/revision/${course.revisionNumber}`, {
          state: {
            isPreviewMode,
            isClickSafetyCourse: false
          }
        });
      }

      logEvent.logStartCourseEvent(
        course.id,
        course.revisionNumber,
        course.title
      );
    }
  };

  const handleStartPreviewModeCard = (event: any) => {
    if (event.target === event.currentTarget) {
      handleStartPreviewMode();
    }
  };

  return (
    <CourseCardWrapper
      thumbnail={thumbnail}
      isComplete={isComplete}
      isOverdueCourse={overdueCourse}
      isNewContentCourse={newCourse}
      completeCourse={completeCourse}
      isOverdue={isOverdueCourse}
      isPreviewMode={isPreviewMode === undefined ? false : isPreviewMode}
      theme={theme}
    >
      {isOverdueCourse && !isComplete && !isDraft && (
        <OverdueHeader label={t('overdue', { ns: 'assignments' })} />
      )}
      {isNewContentCourse && !isComplete && !isDraft && !isOverdueCourse && (
        <NewContentHeader label={t('newContent', { ns: 'common' })} />
      )}
      <div style={courseCardContainerStyle}>
        <div
          onClick={handleStartPreviewModeCard}
          style={{
            ...InfoSection,
            cursor: isPreviewMode ? 'pointer' : 'default'
          }}
        >
          <span style={labelStyle}>
            {t('course', { ns: 'courses' }).toUpperCase()}
          </span>
          <div
            onClick={handleStartPreviewMode}
            style={{
              ...cardTitle,
              cursor: isPreviewMode ? 'pointer' : 'default'
            }}
            className="breaking-text"
          >
            {course.title}
          </div>
          {!isDraft ? (
            <div style={{ cursor: 'default' }}>
              {renderOwnership()}
              {(type === undefined ||
                (type && type !== CourseUnitTypes.clickSafetyCourse)) && (
                <>
                  <div>
                    <MovieIcon />
                    <span style={spanStyle}>
                      {course.totalLessonCount}{' '}
                      {course.totalLessonCount === 1
                        ? t('lessonOrQuiz', { ns: 'lessons' })
                        : t('lessonsOrQuizzes', { ns: 'lessons' })}
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : (
            <span style={spanStyle}>{t('draftTitle', { ns: 'common' })}</span>
          )}
        </div>
        <div style={EditSection}>
          <ProtectedComponent action={appPermissions.EDIT_COURSE}>
            {isAdmin && !isDraft && (
              <Button
                style={editStyleForCompletedOrNewContent}
                onClick={() => handleEditCourse(course.id)}
              >
                {t('edit', { ns: 'common' })}{' '}
                <FontAwesomeIcon icon={faPen} style={styleIcon} />
              </Button>
            )}
          </ProtectedComponent>
        </div>
      </div>

      {!isDraft ? (
        <div style={buttonSection}>
          <Stack direction="column" alignItems="flex-start" spacing={0.5}>
            {assignmentDueDate && (
              <InfoBySpan
                leftText={t('dueDate', { ns: 'assignments' })}
                rightText={assignmentDueDate!.format('MM.DD.YY')}
                showProfilePicture={false}
                isOverdue={!isComplete && isOverdueCourse}
                showAsComplete={showCompletedAsNormal}
              />
            )}

            {hasSpanishSubtitles && (
              <Subtitles subtitlesText="Spanish Subtitles" />
            )}
            {takenTimes && <ChipTakenTimes takenTimes={takenTimes} />}

            {averageLessonTime && (
              <ChipAverageTime
                showComplete={true}
                averageLessonTime={averageLessonTime}
              />
            )}
          </Stack>
          {children}
        </div>
      ) : (
        <div style={buttonSectionDraft}>
          <ProtectedComponent action={appPermissions.EDIT_COURSE}>
            <BasicButton
              onClick={() => handleEditCourse(course.id)}
              color="primary"
              width="105px"
              height="35px"
              style={{ minWidth: '0' }}
            >
              {t('editDraft', { ns: 'common' })}
            </BasicButton>
          </ProtectedComponent>
        </div>
      )}
    </CourseCardWrapper>
  );
};

export default CourseCardNew;

import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TakenTimesContext } from '../../contexts/TakenTimesContext';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { useLogEvent } from '../../hooks/useLogEvent';
import { Lesson } from '../../entities/Lesson';
import { useAppDispatch } from '../../store/hooks';

const useLessonCard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logEvent = useLogEvent();
  const { takenTimesLessons } = useContext(TakenTimesContext);

  const handlePlayLesson = (lesson: Lesson, assignmentId?: string) => {
    dispatch(lessonActions.setCurrentLessons([lesson]));
    logEvent.logPlayLessonEvent(lesson.id, lesson.title);

    if (assignmentId) {
      navigate(`/lessons/assignment/play/${assignmentId}`);
    } else {
      navigate(`/lessons/play`, {
        state: { lesson, isRevisit: lesson.isComplete }
      });
    }
  };

  return { takenTimesLessons, handlePlayLesson };
};

export default useLessonCard;

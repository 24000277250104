import { useTranslation } from 'react-i18next';
import { Stack, useTheme } from '@mui/material';

import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import DatePickerInput from '../../../components/core/DatePickerInput/DatePickerInput';
import { useRef, useState } from 'react';

interface CreateHaltModalProps {
  showModal: boolean;
  savingInProgress: boolean;
  datePickerLabel?: string;
  datePickerTitle?: string;
  onClose: () => void;
  onSave: (startDate: Date, endDate: Date) => void;
}

type DateRangeType = {
  startDate: Date | null;
  endDate: Date | null;
};

const CreateHaltModal = ({
  showModal,
  savingInProgress,
  datePickerLabel,
  datePickerTitle,
  onClose,
  onSave
}: CreateHaltModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['settings']);
  const [error, setError] = useState<string>('');
  const dateRangeRef = useRef<DateRangeType>();

  const handleChangeDateRange = (startDate: Date, endDate: Date) => {
    dateRangeRef.current = {
      startDate,
      endDate
    };
  };

  const handleSaveHalt = () => {
    if (error.length) setError('');

    if (!dateRangeRef.current?.startDate || !dateRangeRef.current?.endDate) {
      setError(t('noDatesForHaltErrorMsg'));
      return;
    }

    onSave(dateRangeRef.current!.startDate!, dateRangeRef.current!.endDate!);
  };

  return (
    <ModalComponent
      showModal={showModal}
      title={datePickerTitle ? datePickerTitle : t('newCompanyTrainingHalt')}
      savingInProgress={savingInProgress}
      maxWidth="md"
      saveButtonText={t('saveHalt')}
      onClose={onClose}
      onSave={handleSaveHalt}
    >
      <Stack spacing={1}>
        {!!error.length && (
          <span
            style={{
              color: theme.palette.error.main
            }}
          >
            {error}
          </span>
        )}
        <span style={{ fontSize: '15px', color: theme.palette.common.white }}>
          {datePickerLabel}
        </span>
        <DatePickerInput
          disablePastDays={true}
          selectsRange={true}
          backgroundColorBlack={true}
          onChangeDateRange={handleChangeDateRange}
        />
      </Stack>
    </ModalComponent>
  );
};

export default CreateHaltModal;

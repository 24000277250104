import Grid from '@mui/material/Grid';
import { AiccCourse } from '../../../entities/Course';
import CourseRowWrapper from '../common/CourseRowWrapper';
import CourseCardNew from '../../../components/CourseCard/CourseCardNew';
import { ListChildComponentProps } from 'react-window';
import { CourseUnitTypes, appPermissions } from '../../../core/constants';
import { CompletedCourseBasicInfo } from '../../../entities/CompletedCourseInfo';
import { TakenTimesObject } from '../../../entities/CompletedLessonInfo';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { CSSProperties } from 'react';

interface CourseRowVirtualProps
  extends ListChildComponentProps<AiccCourse[][]> {
  completedCourses?: CompletedCourseBasicInfo[] | undefined;
  takenTimesCourses?: TakenTimesObject | null;
  onOpenAssignModal: (value: boolean) => void;
  onSelectCourse: (course: AiccCourse) => void;
}

const CourseRowVirtual: React.FC<CourseRowVirtualProps> = ({
  data,
  index,
  style,
  takenTimesCourses,
  completedCourses,
  onOpenAssignModal,
  onSelectCourse
}) => {
  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="parentContainer">
        {data[index].map(course => {
          return (
            <CourseRowWrapper key={course.id}>
              <CourseCardNew
                isDraft={false}
                status={'Clear'}
                hasSpanishSubtitles={course.hasSpanishSubtitles}
                course={course}
                type={CourseUnitTypes.clickSafetyCourse}
                isAdmin={false}
                takenTimes={
                  takenTimesCourses ? takenTimesCourses[course.id] : undefined
                }
              >
                <ActionsArea
                  course={course}
                  openAssignmentModal={onOpenAssignModal}
                  setCourse={onSelectCourse}
                />
              </CourseCardNew>
            </CourseRowWrapper>
          );
        })}
      </Grid>
    </div>
  );
};

interface ActionsAreaProps {
  course: AiccCourse;
  openAssignmentModal: (open: boolean) => void;
  setCourse: (course: AiccCourse) => void;
}

const ActionsArea: React.FC<ActionsAreaProps> = ({
  course,
  openAssignmentModal,
  setCourse
}: ActionsAreaProps) => {
  const { t } = useTranslation(['common', 'contentLibrary']);
  const navigate = useNavigate();
  const redirectToCourse = () => {
    navigate(`/click-safety-course/${course.id}`);
  };

  const handleOpenAssignmentModal = () => {
    openAssignmentModal(true);
    setCourse(course);
  };

  return (
    <div style={divContainerStyle}>
      <div style={divButtonStyle}>
        <ProtectedComponent action={appPermissions.ASSIGN_COURSE}>
          <BasicButton
            style={buttonStyle}
            width="105px"
            height="35px"
            color="primary"
            onClick={handleOpenAssignmentModal}
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
        <div style={startButtonContainerStyle}>
          <BasicButton
            style={buttonStyle}
            width="105px"
            height="35px"
            color="grayButton"
            onClick={() => {
              redirectToCourse();
            }}
          >
            {t('start', { ns: 'common' })}
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

const divContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap'
};

const divButtonStyle: CSSProperties = {
  display: 'flex'
};

const buttonStyle: CSSProperties = {
  minWidth: '0'
};

const startButtonContainerStyle: CSSProperties = {
  paddingLeft: '0.5rem'
};

export default CourseRowVirtual;

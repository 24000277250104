import { AppThunk } from '../store/index';
import axios from 'axios';
import { CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES } from './actionTypes';
import config from './../config';
import { Company } from '../entities/Company';
import { HttpStatusEnum } from '../core/enums';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { handleGenericBackendError } from '../utils/errorHandling';

export interface RequestThirdPartyCompaniesAction {
  type: 'CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES';
  thirdPartyCompanies: Company[];
}

export const actionCreators = {
  requestThirdPartyCompanies: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      let thirdPartyCompanies: Company[] = [];

      try {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(`${config.AUTHORITY_API_URL}company`, {
          params: { isPublicLibrary: true }
        });

        if (res.status === HttpStatusEnum.OK) {
          thirdPartyCompanies = res.data.map(
            (company: {
              id: string;
              companyName: string;
              isActivePartner: boolean;
              isContentLibraryPartner: boolean;
            }) => ({
              id: company.id,
              name: company.companyName,
              isActivePartner: company.isActivePartner,
              isContentLibraryPartner: company.isContentLibraryPartner
            })
          );
        }
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch({
          type: CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES,
          thirdPartyCompanies
        });
        dispatch(appActions.setIsLoading(false));
      }
    }
  }
};

export type KnownAction = RequestThirdPartyCompaniesAction | SetLoadingAction;

import useMediaQuery from '@mui/material/useMediaQuery';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { FilterOptions, FilterSpanishOptions } from '../../../core/constants';
import { filterSelectStyle, selectStyleResponsive } from './filtersStyles';
interface LangContentFilterProps {
  value: string;
  onChange: (items: string[]) => void;
}
const LangContentFilter: React.FC<LangContentFilterProps> = ({
  value,
  onChange
}) => {
  const userLang = navigator.language.substring(0, 2);
  const screenSizeSmall = useMediaQuery('(min-width:910px)');

  const TypeFilterOptions =
    userLang === 'es' ? FilterSpanishOptions : FilterOptions;

  return (
    <BasicSelect
      labelId="filterOptions"
      id="filterOptions"
      options={TypeFilterOptions}
      defaultValue=""
      value={[value]}
      handleOnChange={onChange}
      style={!screenSizeSmall ? selectStyleResponsive : filterSelectStyle}
      theme="dark"
      multiple={false}
      sx={{ backgroundColor: 'transparent' }}
    />
  );
};

export default LangContentFilter;

import { Grid } from '@mui/material';
import { useGetNumberOfColumns } from '../../../hooks/useVirtualizedContentLibraryList';

const CourseRowWrapper: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const numberOfColumns = useGetNumberOfColumns('courses');

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={numberOfColumns === 4 ? 3 : 4}
    >
      {children}
    </Grid>
  );
};

export default CourseRowWrapper;

import { CSSProperties, useEffect } from 'react';
import { Typography, ThemeProvider, Grid } from '@mui/material';
import { defaultTheme } from '@buildwitt/component-library';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { Tabs } from '@buildwitt/component-library';
import { useTranslation } from 'react-i18next';
import MySkills from './MySkills';
import ManageSkills from './ManageSkills';
import { actionCreators as achievementActions } from '../../actions/achievements';
import useUserRole from '../../hooks/useUserRole';
import { UserRoles } from '../../core/constants';
import { useAppDispatch } from '../../store/hooks';

const SkillsPage = () => {
  const dispatch = useAppDispatch();
  const [userRole] = useUserRole();
  const { t } = useTranslation(['skills']);
  const hasAdminPermissions = userRole([UserRoles.CompanyAdmin]);

  useEffect(() => {
    return () => {
      dispatch(achievementActions.setMyCompetences(null));
      dispatch(achievementActions.selectCompetence(null));
    };
  }, []);

  const getTabConfig = () => {
    return hasAdminPermissions
      ? [
          {
            label: `${t('manage', { ns: 'skills' })}`,
            component: <ManageSkills />
          },
          {
            label: `${t('mySkills', { ns: 'skills' })}`,
            component: <MySkills />
          }
        ]
      : [
          {
            label: `${t('mySkills', { ns: 'skills' })}`,
            component: <MySkills />
          }
        ];
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Grid container direction="column">
          <Grid item>
            <Typography sx={fontTab}>
              {t('skills', { ns: 'skills' })}
            </Typography>
          </Grid>
          <Grid item id="skillsTabContainer">
            <Tabs tabConfig={getTabConfig()} />
          </Grid>
        </Grid>
      </PageContainer>
    </ThemeProvider>
  );
};

const fontTab: CSSProperties = {
  fontSize: '24px',
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 1)'
};

export default SkillsPage;

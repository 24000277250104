import { enqueueSnackbar } from 'notistack';

interface PersistentStorage {
  getItem(key: string): string | null;
  setItem(key: string, value: any): void;
}

class SessionStorage implements PersistentStorage {
  getItem(key: string) {
    const item = sessionStorage.getItem(key);

    if (item === null) return undefined;

    if (item === 'null') return null;
    if (item === 'undefined') return undefined;

    try {
      return JSON.parse(item);
    } catch {
      enqueueSnackbar(
        'There was an error while getting info from session storage. Please try again.',
        {
          variant: 'error',
          autoHideDuration: 3000
        }
      );
    }

    return item;
  }
  setItem(key: string, value: any) {
    if (value === undefined) {
      sessionStorage.removeItem(key);
    } else {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setItem() {}
}

export const persistentStorage = window?.sessionStorage
  ? new SessionStorage()
  : new MockStorage();

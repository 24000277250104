import Box from '@mui/material/Box';

import {
  AssignedCourse,
  AssignedLearningPlan,
  AssignedLesson,
  AssignedOnsiteTrainingClass,
  AssignmentTypes
} from '../../entities/LearningPlaylist';
import AssignmentCourseCard from '../../components/CourseCard/AssignmentCourseCard';
import AssignmentLessonCard from '../../components/LessonCard/AssignmentLessonCard';
import AssignmentLearningPlanCard from '../../components/LearningPlanCard/AssignmentLearningPlanCard';
import AssignmentOnsiteTrainingCard from '../../components/OnsiteTrainingAssignmentCard/OnsiteTrainingAssignmentCard';

export interface AssignmentCardListProps {
  assignedLearningUnits: Array<AssignmentTypes>;
}

const AssignmentCardList: React.FC<AssignmentCardListProps> = ({
  assignedLearningUnits
}) => {
  const boxStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    justifyContent: 'center',
    justifyItems: 'center'
  };
  return (
    <Box sx={boxStyle}>
      {assignedLearningUnits.map((learningUnit: AssignmentTypes) => {
        const assignedLesson = learningUnit as AssignedLesson;
        const assignedCourse = learningUnit as AssignedCourse;
        const assignedLearningPlan = learningUnit as AssignedLearningPlan;
        const assignedOnsiteTraining =
          learningUnit as AssignedOnsiteTrainingClass;

        if (assignedLesson.lesson) {
          return (
            <AssignmentLessonCard
              assignedLesson={assignedLesson}
              key={assignedLesson.assignmentId}
            />
          );
        }

        if (assignedCourse.course) {
          return (
            <AssignmentCourseCard
              assignedCourse={assignedCourse}
              key={assignedCourse.assignmentId}
            />
          );
        }

        if (assignedLearningPlan.learningPlan) {
          return (
            <AssignmentLearningPlanCard
              assignedLearningPlan={assignedLearningPlan}
              key={assignedLearningPlan.assignmentId}
            />
          );
        }

        if (assignedOnsiteTraining.onsiteTrainingClass) {
          return (
            <AssignmentOnsiteTrainingCard
              assignedClass={assignedOnsiteTraining}
              key={assignedOnsiteTraining.assignmentId}
            />
          );
        }
      })}
    </Box>
  );
};

export default AssignmentCardList;

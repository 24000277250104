import { Grid, Typography, useTheme } from '@mui/material';
import { Answer, Question, Option } from '../../../entities/Assessment';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import redCross from '../../../assets/red-cross.png';
import greenCheck from '../../../assets/green-check.png';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';

export interface MultipleChoiceQuestionAnalyticsProps {
  answer: Answer;
  question: Question;
}

const MultipleChoiceQuestionAnalytics: React.FC<
  MultipleChoiceQuestionAnalyticsProps
> = ({ answer, question }) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;
  const checkIcon = (
    <img
      src={greenCheck}
      alt="status"
      style={{ ...iconStyle, paddingRight: '26px' }}
    />
  );
  const redCrossIcon = <img src={redCross} alt="status" style={iconStyle} />;
  const noIcon = <div style={noIconStyle}></div>;

  const renderCorrectResponseIcon = (option: Option) => {
    const isCorrectOption = option.isCorrect;
    const isThisOptionSelected = option.id === answer.selectedOptionId;

    if (isCorrectOption && isThisOptionSelected) {
      return checkIcon;
    }

    if (!isCorrectOption && isThisOptionSelected) {
      return redCrossIcon;
    }

    return noIcon;
  };

  const getBorderStyle = (option: Option) => {
    const isCorrectOption = option.isCorrect;
    const isThisOptionSelected = option.id === answer.selectedOptionId;

    if (isThisOptionSelected && isCorrectOption) {
      return `solid 1px ${greenColor}`;
    }

    if (isThisOptionSelected && !isCorrectOption) {
      return `solid 1px ${redColor}`;
    }

    return '0px';
  };

  const correctResponseWithIconComp = () =>
    question.options?.map(option => (
      <Grid
        key={option.id}
        container
        item
        direction={'row'}
        style={gridCorrectReponseIcon}
        display={'flex'}
        alignItems={'center'}
      >
        <Typography style={correctResponseLabelStyle}>
          {option.isCorrect ? correctReponseLabel : ''}
        </Typography>
        {renderCorrectResponseIcon(option)}
        <Grid
          style={{
            paddingLeft: '20px',
            paddingRight: '22px',
            borderLeft: '1px solid #363636'
          }}
        >
          <Grid
            display={'flex'}
            style={{
              ...borderTrueFalseStyle,
              border: getBorderStyle(option),
              width: '479px'
            }}
          >
            <Grid style={{ padding: '10px 10px 10px 10px' }}>
              <Typography>{option.optionText}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ));

  const backgroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  return (
    <Grid style={{ backgroundColor: backgroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
        image={question.imageUrl}
      />
      <Grid container direction={'row'} style={{ paddingTop: '12px' }}>
        <Grid
          container
          item
          direction={'column'}
          style={gridContainerCorrectResponse}
        >
          {correctResponseWithIconComp()}
        </Grid>
      </Grid>
    </Grid>
  );
};

const gridCorrectReponseIcon: CSSProperties = {
  borderRight: '1px solid #363636'
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const iconStyle: CSSProperties = {
  paddingLeft: '20px',
  paddingRight: '23px'
};

const noIconStyle: CSSProperties = {
  paddingLeft: '55px',
  paddingRight: '10px'
};

const gridContainerCorrectResponse: CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};

const borderTrueFalseStyle: CSSProperties = {
  borderRadius: '5px'
};
export default MultipleChoiceQuestionAnalytics;

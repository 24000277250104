import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  SxProps,
  TextFieldProps
} from '@mui/material';
import TextField from '@mui/material/TextField';
import { defaultTheme } from '@buildwitt/component-library';
import { Tag } from '../../entities/Tag';
export type TagEntity = Tag;

export interface TagInputProps<AllowNew extends boolean | undefined = false>
  extends Pick<TextFieldProps, 'placeholder'>,
    Omit<
      AutocompleteProps<Tag, true, undefined, AllowNew>,
      | 'freeSolo'
      | 'renderInput'
      | 'multiple'
      | 'getOptionLabel'
      | 'noOptionsText'
      | 'isOptionEqualToValue'
    > {
  noTagsText?: React.ReactNode;
  allowNew?: AllowNew;
  onBlurChange?: (value: string) => void;
  minHeight?: string;
  dataTestPrefix?: string;
  dataTrackPrefix?: string;
}

const TagInput = <AllowNew extends boolean | undefined = false>({
  noTagsText,
  allowNew,
  onBlurChange,
  minHeight,
  dataTestPrefix,
  dataTrackPrefix,
  placeholder,
  ...props
}: TagInputProps<AllowNew>) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleOnBlurChange = () => {
    if (onBlurChange && inputValue.trim()) {
      onBlurChange(inputValue);
      setInputValue('');
    }
  };

  const AutocompleteStyle: SxProps = minHeight
    ? {
        '& .MuiInputBase-root': {
          minHeight: '45px'
        }
      }
    : {};

  return (
    <ThemeProvider theme={defaultTheme}>
      <Autocomplete
        freeSolo={allowNew}
        multiple
        sx={AutocompleteStyle}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.name
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={params => (
          <TextField
            {...params}
            data-testid={`${dataTestPrefix}TagsInput`}
            data-trackid={`${dataTrackPrefix}TagsInput`}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        noOptionsText={noTagsText}
        ChipProps={{ color: 'primary', style: { height: '25px' } }}
        onInputChange={(_, value) => setInputValue(value)}
        onBlur={handleOnBlurChange}
        clearOnBlur={!!onBlurChange}
        componentsProps={{
          popper: {
            modifiers: [
              {
                name: 'computeStyles',
                options: {
                  adaptive: false
                }
              }
            ]
          }
        }}
        {...props}
      />
    </ThemeProvider>
  );
};

export default TagInput;

import { AppThunk } from '../store/index';
import {
  Course,
  CourseLessonName,
  ImportLessonDto,
  LessonOrder,
  ImportCourseDto,
  AssignCourseToEmployeeDto,
  AiccCourse
} from '../entities/Course';
import { Tag } from '../entities/Tag';
import axios, { AxiosError } from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  COURSES_SET_COURSES,
  COURSES_SET_BW_COURSES,
  COURSES_SET_TAGS,
  COURSE_BY_ID,
  COURSES_SET_LESSON_NAMES,
  COURSES_UPDATE,
  COURSE_RESET_ERROR,
  COURSES_SET_COURSE_LIST,
  COURSES_UPDATE_TRANSACTION_STATUS,
  COURSES_RESET_TRANSACTION_STATUS,
  COURSES_SET_THIRD_PARTY_COURSES,
  COURSE_SET_CLICKSAFETY_COURSE_LESSONS,
  COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID,
  COURSE_ERROR,
  COURSE_IS_LOADING,
  COURSES_SET_ASSIGNMENT_STATUS,
  COURSES_SET_PARTNERS_COURSES,
  COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  COURSE_PLAY,
  COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE
} from './actionTypes';
import config from './../config';
import { uploadCourseThumbToBlob } from '../services/blobStorage-service';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { Guid } from 'guid-typescript';
import {
  CourseGroupAssignment,
  CoursePlayAssignment,
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition
} from '../entities/Assignment';
import { handleGenericBackendError } from '../utils/errorHandling';
import { SelfAssignResponse } from '../components/SelfAssignButton/types';

export interface RequestCoursesAction {
  type: 'COURSES_SET_COURSES';
  courses: Course[];
}

export interface RequestBWCoursesAction {
  type: 'COURSES_SET_BW_COURSES';
  coursesBW: Course[];
}

export interface RequestPartnersCourses {
  type: 'COURSES_SET_PARTNERS_COURSES';
  partnersCourses: Course[];
}

export interface SetEditingCourseAction {
  type: 'COURSES_SET_EDITING';
  course: Course;
}

export interface UpdateCourseAction {
  type: 'COURSES_UPDATE';
  course: Course;
}

export interface RequestTagsAction {
  type: 'COURSES_SET_TAGS';
  tags: Tag[];
}

export interface RequestCourseByIdAction {
  type: 'COURSE_BY_ID';
  course: Course | null;
}

export interface RequestCourseLessonNamesAction {
  type: 'COURSES_SET_LESSON_NAMES';
  lessonNames: CourseLessonName[];
}

export interface UpdateCourseLessonsOrderAction {
  type: 'COURSES_UPDATE_LESSON_ORDER';
  lessonsOrder: LessonOrder[];
}

export interface SetErrorAction {
  type: 'COURSE_ERROR';
  error: string;
}

export interface ResetErrorAction {
  type: 'COURSE_RESET_ERROR';
}

export interface RequestCourseListAction {
  type: 'COURSES_SET_COURSE_LIST';
  courseList: Course[];
}

export interface UpdateCourseTransactionStatusAction {
  type: 'COURSES_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetCourseTransactionStatusAction {
  type: 'COURSES_RESET_TRANSACTION_STATUS';
}

export interface RequestThirdPartyCoursesAction {
  type: 'COURSES_SET_THIRD_PARTY_COURSES';
  aiccCourses: AiccCourse[] | null;
}

export interface RequestClickSafetyCourseAction {
  type: 'COURSE_SET_CLICKSAFETY_COURSE_LESSONS';
  clickSafetyCourseLessons: any;
}

export interface RequestClickSafetyLessonSessionId {
  type: 'COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID';
  clickSafetyLessonSessionId: any;
}

export interface SetCourseIsLoadingAction {
  type: 'COURSE_IS_LOADING';
  isLoading: boolean;
}

export interface SetCourseAssignmentStatusAction {
  type: 'COURSES_SET_ASSIGNMENT_STATUS';
  courseAssignmentStatus: EmployeeAssignmentStatus[] | null;
}

export interface SetCourseGroupAssignmentDefinitions {
  type: 'COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS';
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
}

export interface RequestCoursePlayAction {
  type: 'COURSE_PLAY';
  coursePlayAssignment: CoursePlayAssignment | null;
}

export interface SetLessonAssignmentAsComplete {
  type: 'COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE';
  lessonAssignmentId: string | null;
}

export const actionCreators = {
  requestCourses:
    (hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      dispatch({
        type: COURSE_BY_ID,
        course: null
      });

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch({
          type: COURSE_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}courses?hideSpanishContent=${hideSpanishContent}`
          );
          dispatch({
            type: COURSES_SET_COURSES,
            courses: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: COURSE_IS_LOADING,
            isLoading: false
          });
        }
      }
    },

  requestBuildWittCoursesForMainCoursesStorage:
    (): AppThunk => async (dispatch, getState) => {
      const appState = getState();
      dispatch({
        type: COURSE_BY_ID,
        course: null
      });

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/buildwitt`
        );
        dispatch({
          type: COURSES_SET_COURSES,
          courses: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },

  requestAllCoursesForCourseListStorage:
    (): AppThunk => async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(`${config.COURSES_API_URL}courses/all`);
        dispatch({
          type: COURSES_SET_COURSE_LIST,
          courseList: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  requestBuildwittCoursesForCourseListStorage:
    (): AppThunk => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/buildwitt`
        );
        dispatch({
          type: COURSES_SET_COURSE_LIST,
          courseList: res.data
        });
      }
    },
  setCourseList: (courseList: Course[]) => ({
    type: COURSES_SET_COURSE_LIST,
    courseList
  }),
  filterCourses:
    (text: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && appState.courses?.courses) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/filter?criteria=${text}`
        );
        dispatch({
          type: COURSES_SET_COURSES,
          courses: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },

  requestCourseTags: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();
    if (appState && appState.courses?.tags) {
      dispatch(appActions.setIsLoading(true));
      const res = await axios.get(`${config.COURSES_API_URL}courses/tags`);

      dispatch({
        type: COURSES_SET_TAGS,
        tags: res.data
      });
      dispatch(appActions.setIsLoading(false));
    }
  },

  requestCourseById:
    (id: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && Guid.isGuid(id)) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(`${config.COURSES_API_URL}courses/${id}`);
        dispatch({
          type: COURSE_BY_ID,
          course: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },

  requestCourseLessonNames:
    (id: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/${id}/lesson/names`
        );
        dispatch({
          type: COURSES_SET_LESSON_NAMES,
          lessonNames: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },

  updateLessonsOrder:
    (courseId: string, lessonsOrder: LessonOrder[]): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        await axios.put(
          `${config.COURSES_API_URL}courses/${courseId}/lesson/order`,
          lessonsOrder
        );
        dispatch(appActions.setIsLoading(false));
      }
    },

  saveCourseAndEditLessons:
    (
      courseDto: Course,
      file: File,
      history: any,
      imageUrl: string
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      let NewThumbnailUrl;
      if (appState) {
        dispatch({ type: COURSE_RESET_ERROR });
        dispatch(appActions.setIsLoading(true));

        try {
          if (file) {
            const blobUrl = await uploadCourseThumbToBlob(file);
            NewThumbnailUrl = blobUrl;
          }
          if (imageUrl) {
            NewThumbnailUrl = imageUrl;
          }

          const formData = new FormData();
          formData.append(
            'courseJson',
            JSON.stringify({
              title: courseDto.title,
              description: courseDto.description,
              ThumbnailUrl: NewThumbnailUrl,
              companyId: courseDto.companyId,
              isDraft: courseDto.isDraft,
              Tags: courseDto.tags,
              visibleForAdminsOnly: courseDto.visibleForAdminsOnly,
              courseCode: courseDto.courseCode
            })
          );

          const res = await axios.post(
            `${config.COURSES_API_URL}courses/`,
            formData
          );

          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              res.status === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });

          if (res.status === HttpStatusEnum.OK) {
            dispatch({
              type: COURSES_UPDATE,
              course: res.data
            });
          }

          history.push(`/courses/${res.data.id}/edit`);
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  editCourse:
    (courseDto: Course, imgUrl: string, file?: File): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          if (file) {
            const blobUrl = await uploadCourseThumbToBlob(file);
            courseDto.thumbnailUrl = blobUrl;
          } else if (imgUrl) {
            courseDto.thumbnailUrl = imgUrl;
          }

          const res = await axios.put(
            `${config.COURSES_API_URL}courses/`,
            courseDto
          );

          dispatch({
            type: COURSE_BY_ID,
            course: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  deleteCourse:
    (courseId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          await axios.delete(
            `${config.COURSES_API_URL}courses/?courseId=${courseId}`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  deleteFullCourse:
    (courseId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          await axios.delete(
            `${config.COURSES_API_URL}courses/?courseId=${courseId}&isFull=true`,
            {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }
          );
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  importLessonsBatch:
    (id: string, selectedLessons: ImportLessonDto[]): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        await axios.put(
          `${config.COURSES_API_URL}courses/${id}/import/lessons/batch`,
          {
            list: selectedLessons
          }
        );

        dispatch(appActions.setIsLoading(false));
      }
    },
  importCoursesBatch:
    (selectedCourses: ImportCourseDto[]): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          await axios.put(
            `${config.COURSES_API_URL}courses/import/courses/batch`,
            {
              list: selectedCourses
            }
          );

          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus: TransactionStatusEnum.Successfull,
            errorMessage: ''
          });
        } catch (e) {
          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus: TransactionStatusEnum.Failed,
            errorMessage: ''
          });
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  assignCourseToEmployees:
    (
      courseAssignment: AssignCourseToEmployeeDto
    ): AppThunk<Promise<SelfAssignResponse | null>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let httpStatus = 0;
        let resMessage = '';
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}assignments/assign/courses/employees/`,
            courseAssignment
          );
          httpStatus = res.status;
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const errorData = e.response?.data as {
              Data: { assigmentId: string };
            };
            if (errorData.Data.assigmentId) {
              resMessage = errorData.Data.assigmentId;
            }
          }
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              httpStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: resMessage
          });
        }

        return { status: httpStatus, message: resMessage };
      }

      return null;
    },

  requestCourseByIdAndRevisionNumber:
    (id: string, revisionNumber: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState && Guid.isGuid(id)) {
        dispatch(appActions.setIsLoading(true));
        const res = await axios.get(
          `${config.COURSES_API_URL}courses/${id}/${revisionNumber}`
        );
        dispatch({
          type: COURSE_BY_ID,
          course: res.data
        });
        dispatch(appActions.setIsLoading(false));
      }
    },

  resetCourseTransactionStatus: () => ({
    type: COURSES_RESET_TRANSACTION_STATUS
  }),

  setCurrentCourse: (course: Course | null) => ({
    type: COURSE_BY_ID,
    course
  }),
  deleteLessonFromCourse:
    (courseId: string, lessonId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          await axios.put(
            `${config.COURSES_API_URL}courses/${courseId}/remove/${lessonId}`,
            null
          );
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setCoursesByCompany:
    (courses: AiccCourse[] | null): AppThunk =>
    (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        dispatch({
          type: COURSES_SET_THIRD_PARTY_COURSES,
          aiccCourses: courses
        });
      }
    },

  setClickSafetySelectedCourseLessons:
    (courseId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      try {
        if (appState) {
          dispatch(appActions.setIsLoading(true));
          dispatch({ type: COURSE_RESET_ERROR });
          const res = await axios.get(
            `${config.COURSES_API_URL}aicc/course/${courseId}/assignableUnits`
          );
          dispatch({
            type: COURSE_SET_CLICKSAFETY_COURSE_LESSONS,
            clickSafetyCourseLessons: res.data
          });
          dispatch(appActions.setIsLoading(false));
        }
      } catch (e) {
        dispatch(appActions.setIsLoading(false));
      }
    },
  setClickSafetyLessonSessionId:
    (lessonId: string, employeeId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      try {
        if (appState) {
          dispatch(appActions.setIsLoading(true));
          const res = await axios.get(
            `${config.STUDENT_API_URL}aicc/session/${lessonId}/${employeeId}`
          );
          dispatch({
            type: COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID,
            clickSafetyLessonSessionId: res.data
          });
          dispatch(appActions.setIsLoading(false));
        }
      } catch (e: any) {
        const error = e as AxiosError<any>;
        dispatch(appActions.setIsLoading(false));
        dispatch({ type: COURSE_ERROR, error: error.response?.data.detail });
      }
    },
  requestBuildWittCourses:
    (hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      dispatch(appActions.setIsLoading(true));
      dispatch({
        type: COURSE_IS_LOADING,
        isLoading: true
      });
      try {
        if (appState) {
          const res = await axios.get(
            `${config.COURSES_API_URL}courses/buildwitt?hideSpanishContent=${hideSpanishContent}`
          );
          dispatch({
            type: COURSES_SET_BW_COURSES,
            coursesBW: res.data
          });
        }
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch(appActions.setIsLoading(false));
        dispatch({
          type: COURSE_IS_LOADING,
          isLoading: false
        });
      }
    },
  requestAiccCourses:
    (companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch({
          type: COURSE_IS_LOADING,
          isLoading: true
        });

        try {
          const res = await axios.get(
            `${config.COURSES_API_URL}aicc/courses/${companyId}`
          );
          dispatch({
            type: COURSES_SET_THIRD_PARTY_COURSES,
            aiccCourses: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: COURSE_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  requestPartnersCourses:
    (companyPartnersIds: string[], hideSpanishContent = false): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        let partnersCourses: Course[] = [];
        try {
          dispatch(appActions.setIsLoading(true));
          dispatch({
            type: COURSE_IS_LOADING,
            isLoading: true
          });
          for (const id of companyPartnersIds) {
            const res = await axios.get(
              `${config.COURSES_API_URL}courses/${id}/bycompany?hideSpanishContent=${hideSpanishContent}`
            );
            if (res.status === HttpStatusEnum.OK) {
              partnersCourses = partnersCourses.concat(res.data);
            }
          }
          dispatch({
            type: COURSES_SET_PARTNERS_COURSES,
            partnersCourses: partnersCourses
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
          dispatch({
            type: COURSE_IS_LOADING,
            isLoading: false
          });
        }
      }
    },
  assignClickSafetyCourseToEmployees:
    (
      courseAssignment: AssignCourseToEmployeeDto,
      subscriptionId: string,
      employeesIds: string[],
      originalEmployeesIds: string[],
      companyId: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          if (
            subscriptionId &&
            employeesIds.length > originalEmployeesIds.length
          ) {
            await axios.put(
              `${config.AUTHORITY_API_URL}subscriptions/${subscriptionId}/employees?companyId=${companyId}`,
              employeesIds
            );
          }

          await axios.post(
            `${config.STUDENT_API_URL}assignments/assign/courses/employees/`,
            courseAssignment
          );

          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus: TransactionStatusEnum.Successfull,
            errorMessage: ''
          });
        } catch (e) {
          // remove susbcriptions on error.
          if (
            subscriptionId &&
            employeesIds.length > originalEmployeesIds.length
          ) {
            await axios.put(
              `${config.AUTHORITY_API_URL}subscriptions/${subscriptionId}/employees?companyId=${companyId}`,
              originalEmployeesIds
            );
          }

          dispatch({
            type: COURSES_UPDATE_TRANSACTION_STATUS,
            transactionStatus: TransactionStatusEnum.Failed,
            errorMessage: ''
          });
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setCourseLessonNames: (courseLessonNames: CourseLessonName[]) => ({
    type: COURSES_SET_LESSON_NAMES,
    lessonNames: courseLessonNames
  }),
  saveCourse:
    (
      courseDto: Course,
      file: File,
      imageUrl: string
    ): AppThunk<Promise<string>> =>
    async (dispatch, getState) => {
      const appState = getState();
      let NewThumbnailUrl;
      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          if (file) {
            const blobUrl = await uploadCourseThumbToBlob(file);
            NewThumbnailUrl = blobUrl;
          }
          if (imageUrl) {
            NewThumbnailUrl = imageUrl;
          }

          const formData = new FormData();
          formData.append(
            'courseJson',
            JSON.stringify({
              title: courseDto.title,
              description: courseDto.description,
              ThumbnailUrl: NewThumbnailUrl,
              companyId: courseDto.companyId,
              isDraft: courseDto.isDraft,
              Tags: courseDto.tags,
              visibleForAdminsOnly: courseDto.visibleForAdminsOnly,
              IsPartnerCompany: courseDto.isPartnerCompany,
              courseCode: courseDto.courseCode
            })
          );

          const courseId = await axios
            .post(`${config.COURSES_API_URL}courses/`, formData)
            .then(async res => {
              await dispatch({
                type: COURSES_UPDATE,
                course: res.data
              });

              return res.data.id;
            })
            .catch(error => {
              throw error;
            });

          return courseId;
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
      return '';
    },
  requestStatusOfEmployeesCourseAssigments:
    (courseId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/course/${courseId}/status`
          );

          dispatch({
            type: COURSES_SET_ASSIGNMENT_STATUS,
            courseAssignmentStatus: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  SetStatusOfEmployeesCourseAssigments: (
    assignmentStatus: EmployeeAssignmentStatus[] | null
  ) => ({
    type: COURSES_SET_ASSIGNMENT_STATUS,
    courseAssignmentStatus: assignmentStatus
  }),
  requestCourseAssignmentDefinitions:
    (courseId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employee-group-assignments/assigned/course/${courseId}`
          );

          dispatch({
            type: COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS,
            groupAssignmentDefinitions: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setCourseAssignmentDefinitions: (
    definitions: EmployeeGroupAssignmentDefinition[] | null
  ) => ({
    type: COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS,
    groupAssignmentDefinitions: definitions
  }),
  saveCourseGroupAssignments:
    (courseGroupAssignments: CourseGroupAssignment): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        const { courseId, assignBy, groups } = courseGroupAssignments;
        const assignmentDefinitionIds: string[] = [];

        try {
          for (let i = 0; i < groups.length; i++) {
            const response = await axios.post(
              `${config.STUDENT_API_URL}employee-group-assignments/assign/course`,
              {
                courseId,
                employeeGroupId: groups[i].groupId,
                assignBy,
                employeeIds: groups[i].employeeIds
              }
            );
            assignmentDefinitionIds.push(response.data);
          }
        } catch (e) {
          for (let j = 0; j < assignmentDefinitionIds.length; j++) {
            await axios.delete(
              `${config.STUDENT_API_URL}employee-group-assignments/${assignmentDefinitionIds[j]}`
            );
          }

          throw e;
        }
      }
    },
  getCourseAssignment:
    (courseAssignmentId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.STUDENT_API_URL}assignment-progress/course/${courseAssignmentId}`
        );

        dispatch({
          type: COURSE_PLAY,
          coursePlayAssignment: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  clearCourseAssignment: (): AppThunk => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      dispatch({
        type: COURSE_PLAY,
        coursePlayAssignment: null
      });
    }
  },
  markLessonAssignmentCompleteLocally:
    (lessonAssignmentId: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE,
          lessonAssignmentId: lessonAssignmentId
        });
      }
    }
};

export type KnownAction =
  | RequestCoursesAction
  | RequestBWCoursesAction
  | UpdateCourseAction
  | SetLoadingAction
  | RequestTagsAction
  | RequestCourseByIdAction
  | SetEditingCourseAction
  | RequestCourseLessonNamesAction
  | UpdateCourseLessonsOrderAction
  | SetErrorAction
  | RequestCourseListAction
  | UpdateCourseTransactionStatusAction
  | ResetCourseTransactionStatusAction
  | ResetErrorAction
  | RequestThirdPartyCoursesAction
  | RequestClickSafetyCourseAction
  | RequestClickSafetyLessonSessionId
  | SetCourseIsLoadingAction
  | SetCourseAssignmentStatusAction
  | RequestPartnersCourses
  | SetCourseGroupAssignmentDefinitions
  | RequestCoursePlayAction
  | SetLessonAssignmentAsComplete;

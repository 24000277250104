import { AppThunk } from '../store';

export const APP_STATE_LOADING = 'APP_STATE_LOADING';

export interface SetLoadingAction {
  type: 'APP_STATE_LOADING';
  isLoading: boolean;
}

export type KnownAction = SetLoadingAction;

export const actionCreators = {
  setIsLoading:
    (isLoading: boolean): AppThunk =>
    async dispatch =>
      dispatch({
        type: APP_STATE_LOADING,
        isLoading: isLoading
      })
};

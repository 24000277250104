import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import CompletedList from '../contentLibrary/CompletedList';
import { useAnalyticsModal } from '../../../hooks/useAnalyticsModal';

interface CompletedModalProps {
  quizLessonId?: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onCloseModal: () => void;
}

const CompletedModal = ({
  quizLessonId,
  includeSelfAssignments,
  filterOption,
  onCloseModal
}: CompletedModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { completedQuizAssignments, sortOptions, openModal },
    api: { closeModal, handleSorting }
  } = useAnalyticsModal(
    'completedQuiz',
    'Lesson',
    includeSelfAssignments,
    filterOption,
    quizLessonId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <AssignmentStatusModal
      showModal={openModal}
      title={t('quizCompletion')}
      sortOptions={sortOptions}
      onClose={handleCloseModal}
      onChangeSortOption={handleSorting}
    >
      <CompletedList completedData={completedQuizAssignments} />
    </AssignmentStatusModal>
  );
};

export default CompletedModal;

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/halts';
import {
  HALTS_SET_MONTH_HALTS_DATA,
  HALTS_SET_TODAY_HALTS_DATA,
  HALTS_UPDATE_PICKED_CALENDAR_DATE,
  HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA,
  HALTS_SET_YOUR_HALTS_DATA,
  HALTS_SET_REFRESH_CALENDAR,
  HALTS_SET_HALT_CREATED_FLAG
} from '../actions/actionTypes';
import { Halt, HaltEmployee } from '../entities/Halt';
import { TransactionStatusEnum } from '../core/enums';

export interface HaltsState {
  monthHalts: Halt[] | null;
  todayHalts: HaltEmployee[] | null;
  pickedCalendarDate: string;
  pickedCalendarDateHalts: HaltEmployee[] | null;
  yourHalts: Halt[] | null;
  refreshCalendar: boolean;
  haltCreatedState: TransactionStatusEnum;
}

const unloadedState: HaltsState = {
  monthHalts: null,
  todayHalts: null,
  pickedCalendarDate: '',
  pickedCalendarDateHalts: null,
  yourHalts: null,
  refreshCalendar: false,
  haltCreatedState: TransactionStatusEnum.None
};

export const HaltsReducer: Reducer<HaltsState> = (
  state: HaltsState | undefined,
  incomingAction: Action
): HaltsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case HALTS_SET_MONTH_HALTS_DATA:
      return {
        ...state,
        monthHalts: action.monthHalts
      };
    case HALTS_SET_TODAY_HALTS_DATA:
      return {
        ...state,
        todayHalts: action.todayHalts
      };
    case HALTS_UPDATE_PICKED_CALENDAR_DATE:
      return {
        ...state,
        pickedCalendarDate: action.pickedCalendarDate
      };
    case HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA:
      return {
        ...state,
        pickedCalendarDateHalts: action.pickedCalendarDateHalts
      };
    case HALTS_SET_YOUR_HALTS_DATA:
      return {
        ...state,
        yourHalts: action.yourHalts
      };
    case HALTS_SET_REFRESH_CALENDAR:
      return {
        ...state,
        refreshCalendar: action.refreshCalendar
      };
    case HALTS_SET_HALT_CREATED_FLAG:
      return {
        ...state,
        haltCreatedState: action.haltCreatedState
      };
    default:
      return state;
  }
};

import React, { useEffect, CSSProperties } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { actionCreators } from '../../actions/courses';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { actionCreators as actionStudent } from '../../actions/student';
import { ApplicationState } from '../../store';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import CourseInfoPanel from '../../components/CourseInfoPanel/CourseInfoPanel';
import { CommunityLesson, Lesson } from '../../entities/Lesson';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import NewLessonCard from '../../components/LessonCard/NewLessonCard';
import {
  CommunityLessonCardActions,
  LessonCardActions
} from '../../components/LessonCardActions';
import { Course } from '../../entities/Course';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { Box, Grid, Stack, Typography } from '@mui/material';
import LessonRowWrapper from '../contentLibrary/common/LessonRowWrapper';
import { useAppDispatch } from '../../store/hooks';

const emptyCourse: Course = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: '',
  companyId: '',
  isDraft: true,
  tags: [],
  videosCount: 0,
  flashCardsCount: 0,
  quizesCount: 0,
  revisionNumber: 0,
  fileCount: 0,
  isAicc: false,
  totalLessonCount: 0,
  audioCount: 0,
  visibleForAdminsOnly: false,
  isPartnerCompany: false,
  courseCode: ''
};

const CoursePlayAssignment: React.FC = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  const coursePlayAssignment = useSelector(
    (state: ApplicationState) => state.courses?.coursePlayAssignment
  );

  const listFailedQuiz = useSelector(
    (state: ApplicationState) => state.assessments?.failedAssignmentQuizzes
  );

  const course = coursePlayAssignment?.course;
  const completedLessons = coursePlayAssignment?.lessonAssignments.filter(
    c => c.isComplete
  );
  const courseCompletedLessons = coursePlayAssignment?.lessonAssignments.filter(
    c => c.isComplete
  );

  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );

  const lessons = coursePlayAssignment?.lessonAssignments.map(c => c.lesson);

  const { t } = useTranslation(['common', 'lessons']);

  const BackwardIconStyle: CSSProperties = {
    display: 'inline-block',
    width: 30,
    height: 30,
    cursor: 'pointer',
    backgroundImage: `url(${BackwardIconGray})`
  };

  const ContainerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity',
    paddingBottom: 30
  };

  useEffect(() => {
    return () => {
      dispatch(actionCreators.clearCourseAssignment());
    };
  }, []);

  useEffect(() => {
    if (params && params.id) {
      dispatch(actionCreators.getCourseAssignment(params.id));
    }
  }, [params?.id]);

  useEffect(() => {
    dispatch(actionStudent.getTakenTimesLessons(employeeId));
  }, [employeeId]);

  useEffect(() => {
    if (coursePlayAssignment?.lessonAssignments) {
      const quizzes = coursePlayAssignment?.lessonAssignments.filter(
        l => l.lesson.lessonType === 'Quiz'
      );
      const assessmentIds = quizzes.map(q => q.lesson.assessmentId!);
      const assignmentIds = quizzes.map(q => q.id!);
      dispatch(
        actionAssesments.failedAssignmentQuizzesResult(
          assessmentIds,
          assignmentIds
        )
      );
    }
  }, [coursePlayAssignment?.lessonAssignments]);

  const backToCourses = () => {
    navigate(-1);
  };

  const isCommunityLesson = (lesson: Lesson) => {
    if ((lesson as CommunityLesson).userProfileName) {
      return true;
    }
    return false;
  };

  const handlePlayLesson = (lesson: Lesson) => {
    logEvent.logPlayLessonEvent(lesson.id, lesson.title);
    logEvent.logStartCourseEvent(
      course?.id,
      course?.revisionNumber,
      course?.title
    );

    navigate(
      `/courses/assignment/play/${coursePlayAssignment?.id}/lessons/${lesson.id}`
    );
  };

  return (
    <PageContainer style={ContainerStyle}>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{ paddingTop: '1rem' }}
      >
        <Box>
          <i
            className="fas fa-sm fa-fw mr-2 text-gray-400"
            style={BackwardIconStyle}
            onClick={backToCourses}
          />
        </Box>
        <Stack sx={{ width: '100%' }}>
          <Typography style={{ fontSize: '24px' }}>{course?.title}</Typography>
          <CourseInfoPanel
            course={course ?? emptyCourse}
            totalLessonsCount={lessons?.length ?? 0}
            completedLessonsCount={courseCompletedLessons?.length ?? 0}
            isLoadingInfo={studentIsLoading || appIsLoading}
            isPreviewMode={false}
          />
          <Box sx={{ pt: '3rem' }}>
            <h4>{t('lessons', { ns: 'lessons' })}</h4>
          </Box>
          <Box sx={{ pt: '1.5rem' }}>
            <Grid container spacing={2} id="parentContainer">
              {lessons?.map(lesson => {
                const isLessonComplete =
                  completedLessons?.some(
                    completeLesson => completeLesson.lesson.id === lesson.id
                  ) ?? false;
                return (
                  <LessonRowWrapper>
                    <div className="pt-2">
                      {isCommunityLesson(lesson) ? (
                        <NewLessonCard
                          lesson={lesson}
                          lessonCardType={'Community'}
                          listFailedQuiz={listFailedQuiz!}
                          isComplete={isLessonComplete}
                          onStartPreviewMode={() => handlePlayLesson(lesson)}
                          takenTimes={
                            takenTimesLessons
                              ? takenTimesLessons[lesson.id]
                              : undefined
                          }
                        >
                          <CommunityLessonCardActions
                            communityLesson={lesson as CommunityLesson}
                            isPreviewMode={false}
                            isComplete={isLessonComplete}
                            onPlay={() => handlePlayLesson(lesson)}
                          />
                        </NewLessonCard>
                      ) : (
                        <NewLessonCard
                          lesson={lesson}
                          lessonCardType={'Company'}
                          isComplete={isLessonComplete}
                          listFailedQuiz={listFailedQuiz!}
                          takenTimes={
                            takenTimesLessons
                              ? takenTimesLessons[lesson.id]
                              : undefined
                          }
                        >
                          <LessonCardActions
                            lesson={lesson}
                            isPreviewMode={false}
                            isComplete={isLessonComplete}
                            onStart={() => handlePlayLesson(lesson)}
                          />
                        </NewLessonCard>
                      )}
                    </div>
                  </LessonRowWrapper>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      </Stack>
    </PageContainer>
  );
};

export default CoursePlayAssignment;

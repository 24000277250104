import React, { useEffect, useState } from 'react';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import { SxProps } from '@mui/system';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import {
  AssignedOnsiteTrainingClass,
  DueOnsiteTraining,
  LearningPlaylistItems,
  AssignmentTypes
} from '../../entities/LearningPlaylist';
import { EnrollmentTypes } from '../../core/constants';
import {
  AssignedLesson,
  AssignedCourse,
  AssignedLearningPlan,
  DueLesson,
  DueCourse,
  DueLearningPlan
} from '../../entities/LearningPlaylist';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import OverdueAssignmentsTab from './OverdueAssignmentsTab';
import RecentlyAssignmentsTab from './RecentlyAssignmentsTab';
import DueSoonAssignmentsTab from './DueSoonAssignmentsTab';
import AllAssignmentsTab from './AllAssignmentsTab';
import { isOverdue } from '../../utils/dateUtils';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import CompleteAssignmentsTab from './CompleteAssignmentsTab';

export interface AssignmentTabsProps {
  learningPlaylist: LearningPlaylistItems;
}

const AssignmentTabs: React.FC<AssignmentTabsProps> = ({
  learningPlaylist
}) => {
  const { t } = useTranslation([
    'assignments',
    'onsiteTraining',
    'assessments'
  ]);
  const [valueTab, setValueTab] = useState<string>('allAssignments');
  const assignmentFilters = [
    { value: '', label: t('viewAll', { ns: 'assignments' }) },
    {
      value: EnrollmentTypes.DueDate,
      label: t('dueDate', { ns: 'assignments' })
    },
    {
      value: EnrollmentTypes.NoDueDate,
      label: t('noDueDate', { ns: 'assignments' })
    },
    {
      value: EnrollmentTypes.SelfAssign,
      label: t('selfAssign', { ns: 'assignments' })
    },
    {
      value: EnrollmentTypes.OnsiteTraining,
      label: t('onsiteTraining', { ns: 'onsiteTraining' })
    }
  ];

  const useStyles = makeStyles(() => ({
    tabList: {
      display: 'flex',
      '& .MuiTab-root': {
        textTransform: 'none',
        fontSize: '16px',
        minWidth: 'auto',
        minHeight: 50
      },
      '& .MuiTabScrollButton-root:first-child::before': {
        content: ''
      },
      '& .MuiTabScrollButton-root:last-child::after': {
        content: ''
      }
    }
  }));

  const [assignmentFilterValue, setAssignmentFilterValue] =
    useState<string>('');
  const [allAssignments, setAllAssignments] = useState<Array<AssignmentTypes>>(
    []
  );
  const [allDueDateAssignments, setAllDueDateAssignments] = useState<
    Array<AssignmentTypes>
  >([]);
  const [allAssignmentsForDueSoon, setAllAssignmentsForDueSoon] = useState<
    Array<AssignmentTypes>
  >([]);
  const [allCompletedAssignments, setAllCompletedAssignments] = useState<
    Array<AssignmentTypes>
  >([]);
  const [overdueNumber, setOverdueNumber] = useState<number>(0);
  const [dueSoonNumber, setDueSoonNumber] = useState<number>(0);
  const isMobileOrTablet = useMediaQuery('(max-width: 1200px)');
  const classes = useStyles();

  useEffect(() => {
    if (learningPlaylist) {
      const dueLessons: AssignedLesson[] = learningPlaylist.dueLessons.map(
        (lesson: DueLesson) => ({
          ...lesson,
          dueDateMoment: absUtcDateToLocalMoment(lesson.dueDateUtc),
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            lesson.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.DueDate
        })
      );

      const dueCourses: AssignedCourse[] = learningPlaylist.dueCourses.map(
        (course: DueCourse) => ({
          ...course,
          dueDateMoment: absUtcDateToLocalMoment(course.dueDateUtc),
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            course.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.DueDate
        })
      );

      const dueLearningPlans: AssignedLearningPlan[] =
        learningPlaylist.dueLearningPlans.map(
          (learningPlan: DueLearningPlan) => ({
            ...learningPlan,
            dueDateMoment: absUtcDateToLocalMoment(learningPlan.dueDateUtc),
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              learningPlan.createdTimestampUtc
            ),
            assignmentType: EnrollmentTypes.DueDate
          })
        );

      const noDueLessons: AssignedLesson[] = learningPlaylist.noDueLessons.map(
        (lesson: DueLesson) => ({
          ...lesson,
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            lesson.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.NoDueDate
        })
      );

      const noDueCourses: AssignedCourse[] = learningPlaylist.noDueCourses.map(
        (course: DueCourse) => ({
          ...course,
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            course.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.NoDueDate
        })
      );

      const noDueLearningPlans: AssignedLearningPlan[] =
        learningPlaylist.noDueLearningPlans.map(
          (learningPlan: DueLearningPlan) => ({
            ...learningPlan,
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              learningPlan.createdTimestampUtc
            ),
            assignmentType: EnrollmentTypes.NoDueDate
          })
        );

      const selfLessons: AssignedLesson[] = learningPlaylist.selfLessons.map(
        (lesson: DueLesson) => ({
          ...lesson,
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            lesson.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.SelfAssign
        })
      );

      const selfCourses: AssignedCourse[] = learningPlaylist.selfCourses.map(
        (course: DueCourse) => ({
          ...course,
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            course.createdTimestampUtc
          ),
          assignmentType: EnrollmentTypes.SelfAssign
        })
      );

      const selfLearningPlans: AssignedLearningPlan[] =
        learningPlaylist.selfLearningPlans.map(
          (learningPlan: DueLearningPlan) => ({
            ...learningPlan,
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              learningPlan.createdTimestampUtc
            ),
            assignmentType: EnrollmentTypes.SelfAssign
          })
        );

      const onsiteTrainingsClasses: AssignedOnsiteTrainingClass[] =
        learningPlaylist.dueOnsiteTrainings.map(
          (item: AssignedOnsiteTrainingClass) => ({
            ...item,
            dueDateMoment: item.dueDateUtc
              ? absUtcDateToLocalMoment(item.dueDateUtc)
              : undefined,
            assignmentType: EnrollmentTypes.OnsiteTraining,
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              item.createdTimestampUtc
            )
          })
        );

      const allAssignedLessons = dueLessons
        .concat(noDueLessons)
        .concat(selfLessons);
      const allAssignedCourses = dueCourses
        .concat(noDueCourses)
        .concat(selfCourses);
      const allAssignedLearningPlans = dueLearningPlans
        .concat(noDueLearningPlans)
        .concat(selfLearningPlans);
      const allAssignedLearningUnits: Array<AssignmentTypes> = [];
      const allDueDateAssignedLearningUnits: Array<AssignmentTypes> = [];
      const allCompletedLearningUnits: Array<AssignmentTypes> = [];
      const allDueDateAndOsiteTrainingAssignedLearningUnits: Array<AssignmentTypes> =
        [];
      allAssignedLearningUnits.push(...allAssignedLessons);
      allAssignedLearningUnits.push(...allAssignedCourses);
      allAssignedLearningUnits.push(...allAssignedLearningPlans);
      allAssignedLearningUnits.push(...onsiteTrainingsClasses);
      setAllAssignments(allAssignedLearningUnits);

      allDueDateAssignedLearningUnits.push(...dueLessons);
      allDueDateAssignedLearningUnits.push(...dueCourses);
      allDueDateAssignedLearningUnits.push(...dueLearningPlans);
      setAllDueDateAssignments(allDueDateAssignedLearningUnits);

      const onsiteTrainingsWithDueDates = onsiteTrainingsClasses.filter(
        cl => !!cl.dueDateMoment
      );
      allDueDateAndOsiteTrainingAssignedLearningUnits.push(
        ...allDueDateAssignedLearningUnits
      );
      allDueDateAndOsiteTrainingAssignedLearningUnits.push(
        ...onsiteTrainingsWithDueDates
      );
      setAllAssignmentsForDueSoon(
        allDueDateAndOsiteTrainingAssignedLearningUnits
      );

      const completedLessons: AssignedLesson[] =
        learningPlaylist.completedLessons?.map((lesson: DueLesson) => ({
          ...lesson,
          dueDateMoment: absUtcDateToLocalMoment(lesson.dueDateUtc),
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            lesson.createdTimestampUtc
          ),
          completionDateUtcMoment: absUtcDateToLocalMoment(
            lesson.completionDateUtc
          ),
          assignmentType: lesson.assignmentType
        })) ?? [];

      const completedCourses: AssignedCourse[] =
        learningPlaylist.completedCourses?.map((course: DueCourse) => ({
          ...course,
          dueDateMoment: absUtcDateToLocalMoment(course.dueDateUtc),
          createdTimestampUtcMoment: absUtcDateToLocalMoment(
            course.createdTimestampUtc
          ),
          completionDateUtcMoment: absUtcDateToLocalMoment(
            course.completionDateUtc
          ),
          assignmentType: course.assignmentType
        })) ?? [];

      const completedLearningPlans: AssignedLearningPlan[] =
        learningPlaylist.completedLearningPlans?.map(
          (learningPlan: DueLearningPlan) => ({
            ...learningPlan,
            dueDateMoment: absUtcDateToLocalMoment(learningPlan.dueDateUtc),
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              learningPlan.createdTimestampUtc
            ),
            completionDateUtcMoment: absUtcDateToLocalMoment(
              learningPlan.completionDateUtc
            ),
            assignmentType: learningPlan.assignmentType
          })
        ) ?? [];

      const completedOnsiteTrainings: AssignedOnsiteTrainingClass[] =
        learningPlaylist.completedOnsiteTrainings?.map(
          (item: AssignedOnsiteTrainingClass) => ({
            ...item,
            createdTimestampUtcMoment: absUtcDateToLocalMoment(
              item.createdTimestampUtc
            ),
            completionDateUtcMoment: absUtcDateToLocalMoment(
              item.completionDateUtc
            ),
            assignmentType: EnrollmentTypes.OnsiteTraining,
            assignments: item.assignments.map(
              (assignment: DueOnsiteTraining) => ({
                ...assignment,
                assignmentType: EnrollmentTypes.OnsiteTraining,
                completionDateUtcMoment: absUtcDateToLocalMoment(
                  assignment.completionDateUtc
                ),
                createdTimestampUtcMoment: absUtcDateToLocalMoment(
                  assignment.createdTimestampUtc
                )
              })
            )
          })
        ) ?? [];

      allCompletedLearningUnits.push(...completedLessons);
      allCompletedLearningUnits.push(...completedCourses);
      allCompletedLearningUnits.push(...completedLearningPlans);
      allCompletedLearningUnits.push(...completedOnsiteTrainings);

      setAllCompletedAssignments(
        allCompletedLearningUnits.sort((a, b) =>
          b.completionDateUtcMoment.diff(a.completionDateUtcMoment)
        )
      );

      const overdues = allDueDateAssignedLearningUnits.filter(
        learningUnit =>
          isOverdue(moment(learningUnit.dueDateMoment!)) &&
          !learningUnit.isComplete
      );
      setOverdueNumber(overdues.length);

      const today = moment().startOf('day');
      const next30daysDate = moment().add(30, 'd').endOf('day');
      const dueSoon = allDueDateAndOsiteTrainingAssignedLearningUnits.filter(
        learningUnit =>
          moment(learningUnit.dueDateMoment) >= today &&
          moment(learningUnit.dueDateMoment) <= next30daysDate &&
          !learningUnit.isComplete
      );
      setDueSoonNumber(dueSoon.length);
    }
  }, [learningPlaylist]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const handleChangeFilterValue = (items: string[]) => {
    const value = items[0];
    setAssignmentFilterValue(value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiTabPanel-root': {
          padding: '25px 0'
        }
      }}
    >
      <TabContext value={valueTab}>
        <Box sx={boxTabListStyle} id="boxOfTabList">
          <TabList
            allowScrollButtonsMobile
            variant={isMobileOrTablet ? 'scrollable' : 'standard'}
            scrollButtons="auto"
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="assignments tab"
            className={classes.tabList}
            id="tabList"
            sx={{
              '& .MuiTabScrollButton-horizontal.MuiTabs-scrollButtons': {
                width: { xs: '18px' }
              },
              '& .MuiSvgIcon-root': {
                width: { xs: '2em' },
                height: { xs: '2em' }
              }
            }}
          >
            <Tab
              value="allAssignments"
              label={t('allAssignments', { ns: 'assignments' })}
            />
            <Tab
              value="recentlyAssigned"
              label={t('recentlyAssigned', { ns: 'assignments' })}
            />
            <Tab
              value="dueSoon"
              label={t('dueSoon', { ns: 'assignments' })}
              icon={
                dueSoonNumber ? (
                  <Chip label={dueSoonNumber} color="primary" />
                ) : (
                  <></>
                )
              }
              iconPosition="end"
            />
            <Tab
              value="overdue"
              label={t('overdue', { ns: 'assignments' })}
              icon={
                overdueNumber ? (
                  <Chip label={overdueNumber} color="error" />
                ) : (
                  <></>
                )
              }
              iconPosition="end"
            />
            <Tab
              value="completed"
              label={t('completed', { ns: 'assignments' })}
            />
          </TabList>
          <BasicSelect
            labelId="groupFilter"
            id="groupFilter"
            options={assignmentFilters}
            value={assignmentFilterValue ? [assignmentFilterValue] : []}
            handleOnChange={handleChangeFilterValue}
            placeholder={t('filterBy', { ns: 'assignments' })}
            theme="dark"
            multiple={false}
            sx={filterStyle}
            data-testid="groupFilter"
          />
        </Box>
        <HorizontalLine color="white" line={2} />
        <TabPanel value="allAssignments">
          <AllAssignmentsTab
            learningUnits={allAssignments}
            assignmentTypeFilter={assignmentFilterValue}
          />
        </TabPanel>
        <TabPanel value="recentlyAssigned">
          <RecentlyAssignmentsTab
            learningUnits={allAssignments}
            assignmentTypeFilter={assignmentFilterValue}
          />
        </TabPanel>
        <TabPanel value="dueSoon">
          <DueSoonAssignmentsTab
            dueDateLearningUnits={allAssignmentsForDueSoon}
            assignmentTypeFilter={assignmentFilterValue}
          />
        </TabPanel>
        <TabPanel value="overdue">
          <OverdueAssignmentsTab dueDateLearningUnits={allDueDateAssignments} />
        </TabPanel>
        <TabPanel value="completed">
          <CompleteAssignmentsTab
            learningUnits={allCompletedAssignments}
            assignmentTypeFilter={assignmentFilterValue}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const boxTabListStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: {
    xs: 'column',
    sm: 'row',
    md: 'row'
  }
};

const filterStyle: SxProps = {
  backgroundColor: 'transparent',
  minWidth: '200px',
  marginTop: { xs: '12px' }
};

export default AssignmentTabs;

import React, { CSSProperties, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { AutocompleteChangeReason, Typography } from '@mui/material';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import TextBox from '../../components/core/TextBox/TextBox';
import ThumbnailLibraryPanel from '../../components/ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { LearningPlan } from '../../entities/LearningPlan';
import { Tag } from '../../entities/Tag';
import TagInput from '../../components/TagInput/TagInput';
import { useStringLengthControl } from '../../hooks/useFieldsValidation';
import { ApplicationState } from '../../store';

import { actionCreators as achievementActions } from '../../actions/achievements';
import { AzureBlobImageInfo } from '../../entities/AzureBlobInfo';
import { truncateAzureUrl } from '../../utils/stringUtils';
import RichTextInput from '../core/RichTextInput/RichTextInput';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import { useAppDispatch } from '../../store/hooks';

export interface LearningPlanInputsFormProps {
  learningPlan: LearningPlan;
  imgFromLib: string;
  setLearningPlan: (learningPlan: LearningPlan) => void;
  validateFields: (componentId: string, value: any) => string;
  setThumbnailFile: (file: File | null) => void;
  setImgFromLib: (img: string) => void;
  getSkillsCerts: (skillsCertsArray: any[]) => void;
  getSkillsCertsDeleted: (skillsCertsArray: any[]) => void;
  isEdit: boolean;
}

export interface ValidationFieldsState {
  nameMsg: string;
  descriptionMsg: string;
  thumbnailMsg: string;
}

const initValidationFieldsState: ValidationFieldsState = {
  nameMsg: '',
  descriptionMsg: '',
  thumbnailMsg: ''
};

const LearningPlanInputsForm: React.FC<LearningPlanInputsFormProps> = ({
  learningPlan,
  imgFromLib,
  setLearningPlan,
  validateFields,
  setThumbnailFile,
  setImgFromLib,
  getSkillsCerts,
  getSkillsCertsDeleted,
  isEdit
}) => {
  const {
    variables: { skillsAndCertifications }
  } = useDevCycleSdk();
  const dispatch = useAppDispatch();
  const [validationFields, setValidationFields] =
    useState<ValidationFieldsState>(initValidationFieldsState);
  const competenciesNotAssigned = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesNotAssigned
  );
  const competenciesAssignedById = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesAssignedById
  );
  const [skillsCerts, setSkillsCerts] = useState<Array<Tag>>([]);
  const [skillsCertsNotAssigned, setSkillsCertsNotAssigned] =
    useState<Array<Tag>>();
  const [skillsCertsDeleted, setSkillCertsDeleted] = useState<Array<Tag>>([]);
  const [nameWarningLength, updateNameWarningLength] = useStringLengthControl(
    150,
    125,
    learningPlan.name
  );
  const { nameMsg, descriptionMsg } = validationFields;
  const { t } = useTranslation(['learningPlans', 'common', 'skills']);
  const [isCompetenceLoading, setIsCompetenceLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(achievementActions.getCompetenciesNotAssigned());
    if (learningPlan) {
      dispatch(achievementActions.getCompetenciesAssignedById(learningPlan.id));
    }
    return () => {
      dispatch(achievementActions.setCompetenciesNotAssigned());
      dispatch(achievementActions.setCompetenciesAssignedById([]));
      setSkillsCerts([]);
    };
  }, []);

  useEffect(() => {
    getSkillsCerts(skillsCerts);
  }, [skillsCerts]);

  useEffect(() => {
    getSkillsCertsDeleted(skillsCertsDeleted);
  }, [skillsCertsDeleted]);

  useEffect(() => {
    if (competenciesNotAssigned) {
      setSkillsCertsNotAssigned(competenciesNotAssigned);
      setIsCompetenceLoading(false);
    }
  }, [competenciesNotAssigned]);

  useEffect(() => {
    if (isEdit) {
      setSkillsCerts(competenciesAssignedById!);
    }
  }, [competenciesAssignedById]);

  useEffect(() => {
    dispatch(achievementActions.getCompetenciesNotAssigned());
    return () => {
      dispatch(achievementActions.setCompetenciesNotAssigned());
    };
  }, []);

  const handleNameChange = (value: string) => {
    setLearningPlan({ ...learningPlan, name: value });
    updateNameWarningLength(value);
    setValidationFields({
      ...validationFields,
      nameMsg: validateFields('name', value)
    });
  };

  const handleDescriptionChange = (value: string) => {
    setLearningPlan({ ...learningPlan, description: value });
    setValidationFields({
      ...validationFields,
      descriptionMsg: validateFields('description', value)
    });
  };

  const handleLearningPlanCode = (value: string) => {
    setLearningPlan({
      ...learningPlan,
      learningPlanCode: value.replace(/\s/g, '')
    });
  };

  const handlefileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errorMsg = validateFields(e.target.id, e.target.files);

    if (e.target.files) {
      const file = e.target.files[0];
      setThumbnailFile(file);

      if (learningPlan.thumbnailUrl && learningPlan.thumbnailUrl.length) {
        setLearningPlan({ ...learningPlan, thumbnailUrl: '' });
      }
    }
    setValidationFields({ ...validationFields, thumbnailMsg: errorMsg });
  };

  const handleTextBoxBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let id = '';
    let value = '';

    if (e.target) {
      id = e.target.id;
      value = e.target.value;
    }
    const message = validateFields(id, value);
    setValidationFields({ ...validationFields, [`${id}Msg`]: message });
  };

  const onSkillCertChange = (
    _: React.SyntheticEvent,
    value: Tag[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'removeOption') {
      const deleted = skillsCerts.filter(
        ({ id }) => !value.some(({ id: id2 }) => id === id2)
      );
      setSkillCertsDeleted(prevState => [...prevState, ...deleted]);
    }
    setSkillsCerts(value);
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid className="gridInputSkillsCerts" item paddingBottom="0.5rem">
          <FormLabel>
            {t('learningPlanName', { ns: 'learningPlans' })}{' '}
            <span className="text-danger">*</span>
          </FormLabel>
          <TextBox
            id="name"
            name="name"
            placeholderText={t('writeLPName', { ns: 'learningPlans' })}
            width="100%"
            height="40px"
            value={learningPlan.name}
            maxLength={150}
            onChangeValue={handleNameChange}
            onBlur={handleTextBoxBlur}
            variant="dark"
          />
          <span className="text-danger">{nameMsg}</span>
          <span className="text-danger">{nameWarningLength}</span>
        </Grid>
        <Grid
          item
          style={{ paddingLeft: '10px !important' }}
          id="ovContainer"
          xs={12}
          md={12}
          lg={12}
        >
          <Stack direction="row" spacing={1}>
            <FormControlLabel
              componentsProps={{
                typography: { fontSize: { xs: '14px', md: '16px' } }
              }}
              control={
                <Checkbox
                  id="visibleForAdminsOnly"
                  name="visibleForAdminsOnly"
                  checked={learningPlan.visibleForAdminsOnly}
                  onChange={(e, checked) => {
                    setLearningPlan({
                      ...learningPlan,
                      visibleForAdminsOnly: checked
                    });
                  }}
                />
              }
              label={t('hideLps', { ns: 'learningPlans' })}
            />
          </Stack>
        </Grid>
        <Grid item paddingBottom="0.5rem">
          <FormLabel>
            {t('learningPlanDescription', { ns: 'learningPlans' })}{' '}
            <span className="text-danger">*</span>
          </FormLabel>
          <RichTextInput
            value={learningPlan?.description}
            onChangeValue={handleDescriptionChange}
            placeholderText={t('writeLPDescription', { ns: 'learningPlans' })}
            onBlur={handleTextBoxBlur}
            id="description"
          />
          <span className="text-danger">{descriptionMsg}</span>
        </Grid>
        <Grid item paddingBottom="0.5rem">
          <FormLabel>
            {t('learningPlanCode', { ns: 'learningPlans' })}{' '}
          </FormLabel>
          <TextBox
            id="learningPlanCode"
            name="learningPlanCode"
            placeholderText={t('learningPlanCode', { ns: 'learningPlans' })}
            width="100%"
            height="40px"
            value={learningPlan.learningPlanCode}
            maxLength={30}
            onChangeValue={handleLearningPlanCode}
            onBlur={handleTextBoxBlur}
            variant="dark"
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          style={
            imgFromLib == '' || imgFromLib == null
              ? gridThumbnail
              : gridThumbnailPath
          }
        >
          <FormLabel>
            {t('learningPlanThumbnail', { ns: 'learningPlans' })}
            <span className="text-danger">*</span>
          </FormLabel>
          <div style={thumbnailPanelContainerStyle}>
            <ThumbnailLibraryPanel
              onChange={handlefileChange}
              thumbnailUrl={imgFromLib}
              onItemSelected={(item: AzureBlobImageInfo) => {
                setThumbnailFile(null);
                setImgFromLib(item.url);
              }}
            />
          </div>
          {learningPlan.thumbnailUrl && (
            <span>{truncateAzureUrl(learningPlan.thumbnailUrl)}</span>
          )}
        </Grid>
        {skillsAndCertifications.skillsAndCertifications && (
          <Grid className="gridInputLp">
            <FormLabel>
              {t('earnedSkillsCertificatesCompletion', { ns: 'skills' })}
            </FormLabel>
            <TagInput
              value={skillsCerts ?? []}
              options={skillsCertsNotAssigned ?? []}
              onChange={onSkillCertChange}
              placeholder={t('writeSkillCertificateHere', { ns: 'skills' })}
              noTagsText={t('noMatchingSkillsCertificates', {
                ns: 'skills'
              })}
              loading={isCompetenceLoading}
            />
            {!!skillsCertsNotAssigned &&
              skillsCertsNotAssigned?.length == 0 && (
                <Typography className="text-danger">
                  {t('noSkillCertAvailable', { ns: 'skills' })}
                </Typography>
              )}
          </Grid>
        )}
      </Grid>
    </>
  );
};

const thumbnailPanelContainerStyle: CSSProperties = {
  height: '327px'
};
const gridThumbnail: CSSProperties = {
  marginBottom: 27
};
const gridThumbnailPath: CSSProperties = {
  marginBottom: 2
};

export default LearningPlanInputsForm;

import { Grid, Typography, useTheme } from '@mui/material';
import { AnswerAverage, Question } from '../../../entities/Assessment';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';

export interface MultipleChoiceQuestionAverageProps {
  question: Question;
  answerAverage: AnswerAverage;
}

const MultipleChoiceQuestionAverage: React.FC<
  MultipleChoiceQuestionAverageProps
> = ({ question, answerAverage }) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;

  const correctResponseWithIconComp = () =>
    question.options?.map(option => {
      const isCorrectOption = option.isCorrect;
      const optionIndexedAv = answerAverage.optionsAverage.filter(
        item => item.optionId === option.id
      )[0];
      const colorAverage = isCorrectOption ? greenColor : redColor;
      const colorText = isCorrectOption ? greenColor : '';
      return (
        <Grid
          key={option.id}
          container
          item
          direction={'row'}
          style={gridCorrectReponseIcon}
          display={'flex'}
          alignItems={'center'}
        >
          <Typography style={correctResponseLabelStyle}>
            {isCorrectOption ? correctReponseLabel : ''}
          </Typography>
          <Typography
            style={{ ...gradeLabelStyle, color: colorAverage }}
            align={'right'}
          >
            {`${optionIndexedAv.optionSelectedAverage}%`}
          </Typography>
          <Grid
            style={{
              paddingLeft: '10px',
              paddingRight: '22px',
              borderLeft: '1px solid #363636'
            }}
          >
            <Grid style={{ padding: '10px 0px 10px 10px' }}>
              <Typography style={{ color: colorText }}>
                {option.optionText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    });

  const backGroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;

  return (
    <Grid style={{ backgroundColor: backGroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
        image={question.imageUrl}
      />
      <Grid container direction={'row'} style={{ paddingTop: '12px' }}>
        <Grid
          container
          item
          direction={'column'}
          style={gridContainerCorrectResponse}
        >
          {correctResponseWithIconComp()}
        </Grid>
      </Grid>
    </Grid>
  );
};

const gradeLabelStyle: CSSProperties = {
  width: '75px',
  fontSize: '24px',
  height: '35px',
  fontWeight: '600',
  paddingRight: '15px'
};
const gridCorrectReponseIcon: CSSProperties = {
  borderRight: '1px solid #363636'
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const gridContainerCorrectResponse: CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};

export default MultipleChoiceQuestionAverage;

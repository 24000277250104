import { utils, writeFileXLSX } from 'xlsx';
import moment from 'moment';
import axios from 'axios';

import { absUtcDateToLocalMoment } from '../utils/dateUtils';
import {
  AssignmentsStatusDto,
  LearningPlanAssignmentStatusDto,
  LessonAssignmentStatusDto,
  CourseAssignmentStatusDto
} from '../entities/Assignment';
import {
  AnalyticsContentLibrary,
  AnalyticsContentLibraryDetail,
  AnalyticsCurrentAssignmentsForGroups,
  AnalyticsGroupEngagement,
  AnalyticsGroupEngagementDetail,
  AnalyticsQuiz
} from '../entities/Analytics';
import config from '../config';
import { EnrollmentTypes } from '../core/constants';
import { getFormattedTime } from '../utils/commonUtils';
import { DashboardDetailsType } from '../core/enums';
import { GridColDef } from '@mui/x-data-grid';
import { DashboardDetailsSubscribedUsersType } from '../core/enums';

const mapCommonFields = (
  item:
    | LessonAssignmentStatusDto
    | CourseAssignmentStatusDto
    | LearningPlanAssignmentStatusDto
) => {
  return {
    employeeId: item.employeeId,
    code: item.code,
    firstName: item.firstName,
    lastName: item.lastName,
    email: item.email,
    phone: item.phoneNumber,
    assignmentType: item.assignmentType,
    dueDateOn: item.dueDateOn
      ? absUtcDateToLocalMoment(item.dueDateOn).format('MM.DD.YY')
      : '',
    assignedOn: absUtcDateToLocalMoment(item.assignedOn).format('MM.DD.YY'),
    completedOn: item.completedOn
      ? absUtcDateToLocalMoment(item.completedOn).format('MM.DD.YY')
      : '',
    groupNames: item.groupNames
  };
};

export const downloadAssignmentsStatusReport = async (
  includeAllData: boolean,
  type: string,
  filterGroupId: string,
  filterSupervisorId: string
) => {
  const res = await axios.get(
    `${config.STUDENT_API_URL!}assignments/status/employees/assignmentsFiltered`,
    {
      params: {
        includeAllData: includeAllData,
        contentType: type,
        filterGroupId: filterGroupId,
        filterSupervisorId: filterSupervisorId
      }
    }
  );
  const assignmentsStatus: AssignmentsStatusDto = res.data;

  const lessonsAssignmentsData = assignmentsStatus.lessonsAssignmentsStatus.map(
    item => ({
      ...mapCommonFields(item),
      lessonName: item.learningUnitName,
      lessonCode: item.lessonCode,
      lessonType: item.lessonType,
      duration: item.duration
    })
  );
  const coursesAssignmentsData = mapCourseAssignmentsToReportModel(
    assignmentsStatus.coursesAssignmentsStatus
  );
  const learningPlansAssignmentsData = mapLpAssignmentsToReportModel(
    assignmentsStatus.learningPlansAssignmentsStatus
  );

  const lessonsSheet = utils.json_to_sheet(lessonsAssignmentsData);
  utils.sheet_add_aoa(
    lessonsSheet,
    [
      [
        'Employee Id',
        'Code',
        'First Name',
        'Last Name',
        'Email',
        'Phone',
        'Assignment Type',
        'Due Date On',
        'Assigned On',
        'Completed On',
        'Groups',
        'Lesson Name',
        'Lesson Code',
        'Lesson Type',
        'Duration (seconds)'
      ]
    ],
    { origin: 'A1' }
  );
  const coursesSheet = utils.json_to_sheet(coursesAssignmentsData);
  utils.sheet_add_aoa(
    coursesSheet,
    [
      [
        'Employee Id',
        'Code',
        'First Name',
        'Last Name',
        'Email',
        'Phone',
        'Assignment Type',
        'Due Date On',
        'Assigned On',
        'Completed On',
        'Groups',
        'Course Name',
        'Course Code'
      ]
    ],
    { origin: 'A1' }
  );
  const learningPlansSheet = utils.json_to_sheet(learningPlansAssignmentsData);
  utils.sheet_add_aoa(
    learningPlansSheet,
    [
      [
        'Employee Id',
        'Code',
        'First Name',
        'Last Name',
        'Email',
        'Phone',
        'Assignment Type',
        'Due Date On',
        'Assigned On',
        'Completed On',
        'Groups',
        'Learning Plan Name',
        'Learning Plan Code'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, lessonsSheet, 'Lessons');
  utils.book_append_sheet(book, coursesSheet, 'Courses');
  utils.book_append_sheet(book, learningPlansSheet, 'Learning Plans');

  writeFileXLSX(
    book,
    `assignments-status-Report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

const mapCourseAssignmentsToReportModel = (
  collection: CourseAssignmentStatusDto[]
) => {
  return collection.map(item => ({
    ...mapCommonFields(item),
    courseName: item.learningUnitName,
    courseCode: item.courseCode
  }));
};

const mapLpAssignmentsToReportModel = (
  collection: LearningPlanAssignmentStatusDto[]
) => {
  return collection.map(item => ({
    ...mapCommonFields(item),
    LearningPlanName: item.learningUnitName,
    learningPlanCode: item.learningPlanCode
  }));
};

export const downloadAnalyticsContentLibraryReport = (
  data: AnalyticsContentLibrary[],
  learningUnitType: string
) => {
  const dataForReport = data.map((item: AnalyticsContentLibrary) => ({
    name: item.learningUnitName,
    employeesAssigned: item.employeesAssigned,
    pendingCompletion: item.pendingCompletion,
    completed: item.completed,
    overdue: item.overdue
  }));

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `${learningUnitType} Name`,
        'Employees Assigned',
        'Pending Completion',
        'Completed',
        'Overdue'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Content Library');

  writeFileXLSX(
    book,
    `analytics-content-library-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

export const downloadAnalyticsContentLibraryDetailReport = (
  data: AnalyticsContentLibraryDetail[],
  learningUnitType: string
) => {
  let dataForReport: any[] = [];
  data.forEach(item => {
    dataForReport = dataForReport.concat(
      item.assignmentsInfo.map(assignment => ({
        firstName: item.firstName,
        lastName: item.lastName,
        numberTimesCompleted: item.numberTimesCompleted,
        assignedOn: absUtcDateToLocalMoment(assignment.assignedOn).format(
          'MM.DD.YY'
        ),
        dueDate:
          assignment.dueDate &&
          assignment.assignmentType === EnrollmentTypes.DueDate
            ? absUtcDateToLocalMoment(assignment.dueDate).format('MM.DD.YY')
            : assignment.assignmentType === 'Workload'
              ? 'Workload'
              : 'No Due Date',
        completionDate: assignment.completionDate
          ? absUtcDateToLocalMoment(assignment.completionDate).format(
              'MM.DD.YY'
            )
          : assignment.isPending
            ? 'Pending'
            : 'Overdue',
        completionStatus:
          assignment.completionDate && assignment.isCompletedOnTime
            ? 'Completed On Time'
            : assignment.completionDate && !assignment.isCompletedOnTime
              ? 'Completed Late'
              : ''
      }))
    );
  });

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `First Name`,
        'Last Name',
        'Number Of Times Completed',
        'Assigned On',
        'Due Date',
        'Completion Date',
        'Completion Status'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Content Library Detail');

  writeFileXLSX(
    book,
    `analytics-content-library-detail-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsGroupEngagementReport = (
  data: AnalyticsGroupEngagement[]
) => {
  const dataForReport = data.map((item: AnalyticsGroupEngagement) => ({
    groupName: item.employeeGroupName,
    currentAssignments: item.currentAssignments,
    totalCompletedAssignments: item.totalCompletedAssignments,
    timeSpentInTraining:
      item.timeSpentInTraining > 0
        ? getFormattedTime(item.timeSpentInTraining)
        : '0',
    employeesInGroup: item.employeesInGroup
  }));

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Group Name`,
        'Current Assignments',
        'Total Completed Assignments',
        'Time Spent in Training',
        'Employees In Group'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Group Engagement');

  writeFileXLSX(
    book,
    `analytics-group-engagement-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

export const downloadAnalyticsGroupEngagementDetailReport = (
  data: AnalyticsGroupEngagementDetail[]
) => {
  const dataForReport = data.map((item: AnalyticsGroupEngagementDetail) => ({
    employeeName: `${item.firstName} ${item.lastName}`,
    currentAssignments: item.currentAssignments,
    selfAssigned: item.selfAssigned,
    overdueAssignments: item.overdueAssignments,
    totalCompleted: item.totalCompleted,
    timeSpentInTraining:
      item.timeSpentInTraining > 0
        ? getFormattedTime(item.timeSpentInTraining)
        : '0',
    lastActive: item.lastActive
      ? absUtcDateToLocalMoment(item.lastActive).format('MM.DD.YY')
      : 'Never'
  }));

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Employee Name`,
        'Current Assignments',
        'Self Assigned',
        'Overdue Assignments',
        'Total Completed',
        'Time Spent in Training',
        'Last Active'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Group Engagement Detail');

  writeFileXLSX(
    book,
    `analytics-group-engagement-detail-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsGroupCurrentAssignmentReport = (
  data: AnalyticsCurrentAssignmentsForGroups[]
) => {
  const dataForReport = data.map(
    (item: AnalyticsCurrentAssignmentsForGroups) => ({
      assigmentName: item.learningUnitName,
      leaningUnitType: item.learningUnitType,
      assignedOn: absUtcDateToLocalMoment(item.assignedOn).format('MM.DD.YY'),
      timesCompleted: item.timesCompleted
    })
  );

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Assignment Name`,
        'Learning Unit Type',
        'Assigned On',
        'Times Completed'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Current Assignments');

  writeFileXLSX(
    book,
    `analytics-group-current-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsEmployeeCompletedAssignmentReport = (
  data: any
) => {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Assignment Name`,
        'Learning Unit Type',
        'Assigned On',
        'Completion Date',
        'Completion Info'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Completed Assignments');

  writeFileXLSX(
    book,
    `analytics-employee-completed-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsEmployeeOverdueAssignmentReport = (data: any) => {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(
    sheet,
    [[`Assignment Name`, 'Learning Unit Type', 'Assigned On', 'Due Date']],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Overdue Assignments');

  writeFileXLSX(
    book,
    `analytics-employee-overdue-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsEmployeeCurrentAssignmentReport = (data: any) => {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Assignment Name`,
        'Learning Unit Type',
        'Assigned On',
        'Completion Status',
        'Due Date'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Current Assignments');

  writeFileXLSX(
    book,
    `analytics-employee-current-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsGroupCompletedAssignmentReport = (data: any) => {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Assignment Name`,
        'Learning Unit Type',
        'Completed By',
        'Completion Date'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Completed Assignments');

  writeFileXLSX(
    book,
    `analytics-group-completed-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsEmployeeSelfAssignmentReport = (data: any) => {
  const sheet = utils.json_to_sheet(data);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Content Name`,
        'Learning Unit Type',
        'Self Assigned On',
        'Completion Date'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Self Assignments');

  writeFileXLSX(
    book,
    `analytics-employee-self-assignments-report_${moment().format(
      'MMDDYY_HHmmss'
    )}.xlsx`
  );
};

export const downloadAnalyticsQuizzesReport = (data: AnalyticsQuiz[]) => {
  const dataForReport = data.map((item: AnalyticsQuiz) => ({
    quizName: item.quizName,
    employeeAssigned: item.employeesAssigned,
    pendingCompletion: item.pendingCompletion,
    completed: item.completed,
    averageGrade: item.averageGrade.toFixed(0),
    numberOfquestions: item.numberOfQuestions
  }));

  const sheet = utils.json_to_sheet(dataForReport);
  utils.sheet_add_aoa(
    sheet,
    [
      [
        `Quiz Name`,
        'Employees Assigned',
        'Pending Completion',
        'Completed',
        'Average Grade (%)',
        'Number of Questions'
      ]
    ],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Quizzes');

  writeFileXLSX(
    book,
    `analytics-quizzes-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

export const downloadAnalyticsQuizDetailReport = (
  data: any,
  additionalHeaderNames: string[]
) => {
  const sheet = utils.json_to_sheet(data);
  const headerNames = ['Employee Name', 'Passed', 'Taken At', 'Grade %'].concat(
    additionalHeaderNames
  );
  utils.sheet_add_aoa(sheet, [headerNames], { origin: 'A1' });
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Quiz Detail');

  writeFileXLSX(
    book,
    `analytics-quiz-detail-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

export const downloadOverLessonAssignmentReport = (
  data: any,
  columns: GridColDef[],
  modalType: DashboardDetailsType
) => {
  const dataForReport = data.map((item: any) => ({
    name: item.lessonName,
    employeesAssignedCount:
      modalType === DashboardDetailsType.TotalCompletedLessons
        ? item.completedTimes
        : item.employees.length,
    employeesAssignedNames: item.employees
      .map((e: { firstName: string; lastName: string }) => {
        return e.firstName + ' ' + e.lastName;
      })
      .join(', ')
  }));

  const sheet = utils.json_to_sheet(dataForReport);

  utils.sheet_add_aoa(
    sheet,
    [[columns[0].headerName, columns[1].headerName, 'Users Name']],
    { origin: 'A1' }
  );
  const book = utils.book_new();
  utils.book_append_sheet(book, sheet, 'Lesson Assignments');

  writeFileXLSX(
    book,
    `lesson-assignments-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

export const downloadSubscribedUsersReport = (
  data: any,
  columns: GridColDef[],
  modalType: DashboardDetailsSubscribedUsersType
) => {
  const dataForReport = data.map((item: any) => ({
    employee: `${item.firstName} ${item.lastName}`,
    [modalType === DashboardDetailsSubscribedUsersType.LearningUsers
      ? 'lastActive'
      : 'createdAt']: item.createdAt
  }));

  const sheet = utils.json_to_sheet(dataForReport);

  utils.sheet_add_aoa(sheet, [[columns[0].headerName, columns[1].headerName]], {
    origin: 'A1'
  });
  const book = utils.book_new();
  utils.book_append_sheet(
    book,
    sheet,
    modalType === DashboardDetailsSubscribedUsersType.LearningUsers
      ? 'Users Subscribed'
      : 'Subscribed Non-Learning Users'
  );

  writeFileXLSX(
    book,
    `users-subscribed-report_${moment().format('MMDDYY_HHmmss')}.xlsx`
  );
};

import { Route } from 'react-router';
import CoursePreview from '../pages/courses/CoursePreview';
import LessonPreview from '../pages/lessons/LessonPreview';
import CoursePlayAssignment from '../pages/courses/CoursePlayAssignment';
import CoursePlayAssignmentLessons from '../pages/courses/CoursePlayAssignmentLessons';

export const courseRoutes = [
  <Route
    key="courses/assignment/play/:id"
    path={`/courses/assignment/play/:id`}
    element={<CoursePlayAssignment />}
  />,
  <Route
    key="courses/:id/revision/:revisionNumber"
    path={`/courses/:id/revision/:revisionNumber`}
    element={<CoursePreview />}
  />,
  <Route
    key="courses/assignment/play/:id/lessons/:startingLessonId"
    path={`/courses/assignment/play/:id/lessons/:startingLessonId`}
    element={<CoursePlayAssignmentLessons />}
  />,
  <Route
    key="courses/:id/revision/:revisionNumber"
    path={`/courses/:id/revision/:revisionNumber`}
    element={<CoursePreview />}
  />,
  <Route
    key="courses/:id/revision/:revisionNumber/preview"
    path={`/courses/:id/revision/:revisionNumber/preview`}
    element={<LessonPreview />}
  />,
  <Route
    key="courses/:id/revision/:revisionNumber/play"
    path={`/courses/:id/revision/:revisionNumber/play`}
    element={<LessonPreview />}
  />
];

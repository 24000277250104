import { AppThunk } from '../store/index';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { TAGS_SET_TAGS, TAGS_LOADING } from './actionTypes';
import config from './../config';
import { Tag } from '../entities/Tag';
import { handleGenericBackendError } from '../utils/errorHandling';

export interface RequestTagsAction {
  type: 'TAGS_SET_TAGS';
  tags: Tag[];
}

export interface TagsLoadingAction {
  type: 'TAGS_LOADING';
  loading: boolean;
}

export const actionCreators = {
  requestTags: (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch(appActions.setIsLoading(true));
      dispatch({
        type: TAGS_LOADING,
        loading: true
      });

      try {
        const res = await axios.get(`${config.COURSES_API_URL}tag`);
        dispatch({
          type: TAGS_SET_TAGS,
          tags: res.data
        });
      } catch (e) {
        handleGenericBackendError(e);
      } finally {
        dispatch(appActions.setIsLoading(false));
        dispatch({
          type: TAGS_LOADING,
          loading: false
        });
      }
    }
  }
};

export type KnownAction =
  | RequestTagsAction
  | TagsLoadingAction
  | SetLoadingAction;

import { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import {
  Course,
  CourseLessonName,
  ImportLessonDto,
  LessonOrder
} from '../../../entities/Course';
import CourseForm from '../../../components/CourseForm/v1.2/CourseForm';
import { ApplicationState } from '../../../store';
import { actionCreators as tagActions } from '../../../actions/tags';
import { actionCreators as courseActions } from '../../../actions/courses';
import { actionCreators as appActions } from '../../../actions/appState';
import { actionCreators as lessonActions } from '../../../actions/lessons';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { useLesson } from '../../lessons/useLesson';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import SelectLessons from '../../lessons/SelectLessons';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useLogEvent } from '../../../hooks/useLogEvent';
import config from '../../../config';
import { ServerEventsNames } from '../../../core/constants';
import { actionCreators as coursesActions } from '../../../actions/courses';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';
import { enqueueSnackbar } from 'notistack';
import { LearningUnitType } from '../../../core/enums';
import { useAppDispatch } from '../../../store/hooks';
import { handleGenericBackendError } from '../../../utils/errorHandling';

const emptyCourse: Course = {
  id: '',
  title: '',
  description: '',
  thumbnailUrl: '',
  companyId: '',
  isDraft: true,
  tags: [],
  videosCount: 0,
  flashCardsCount: 0,
  quizesCount: 0,
  revisionNumber: 0,
  fileCount: 0,
  isAicc: false,
  totalLessonCount: 0,
  audioCount: 0,
  visibleForAdminsOnly: false,
  isPartnerCompany: false,
  courseCode: ''
};

const CreateEditCourse: React.FC = () => {
  const logEvent = useLogEvent();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams<{ id?: string }>();
  const [course, setCourse] = useState<Course>();
  const [lessonNames, setLessonNames] = useState<Array<CourseLessonName>>();
  const [showSelectLessons, setShowSelectLessons] = useState<boolean>(false);
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const selectedLessonEntryId = useRef<string>('');
  const forceUpdateCourseState = useRef<boolean>(false);
  const forceUpdateLessonNamesState = useRef<boolean>(false);
  const user = useCurrentUser();
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );
  const isPartnerCompany = partnerCompanies?.some(
    company => company.id === companyId
  );
  const [
    isDeleteLessonConfirmationModalOpen,
    setIsDeleteLessonConfirmationModalOpen
  ] = useState<boolean>(false);
  const [
    isDeleteCourseConfirmationModalOpen,
    setIsDeleteCourseConfirmationModalOpen
  ] = useState<boolean>(false);
  const currentCourse = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const courseLessonNames = useSelector(
    (state: ApplicationState) => state.courses?.courseLessonNames
  );
  const companyTags = useSelector(
    (state: ApplicationState) => state.tags?.tags
  );
  const competenciesAssignedById = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesAssignedById
  );

  const { t } = useTranslation([
    'common',
    'courses',
    'lessons',
    'contentLibrary'
  ]);
  const {
    api: { handleEditTrainingLesson }
  } = useLesson(true);
  const isEdit = location.state?.isEditing ?? false;

  useEffect(() => {
    if (!currentCourse && isEdit && params.id) {
      dispatch(courseActions.requestCourseById(params.id));
    }

    if (!lessonNames && isEdit && params.id) {
      dispatch(courseActions.requestCourseLessonNames(params.id));
    }

    if (!companyTags?.length) {
      dispatch(tagActions.requestTags());
    }

    const connect = new HubConnectionBuilder()
      .withUrl(`${config.COURSES_BASE_URL}/notifications`)
      .withAutomaticReconnect()
      .build();

    setConnection(connect);

    return () => {
      dispatch(courseActions.setCurrentCourse(null));
      dispatch(courseActions.setCourseLessonNames([]));
    };
  }, []);

  useEffect(() => {
    if (!isEdit && (!course || !lessonNames)) {
      setCourse(emptyCourse);
      setLessonNames([]);
      return;
    }

    if (
      (currentCourse && !course && isEdit) ||
      (currentCourse && forceUpdateCourseState.current)
    ) {
      setCourse(currentCourse);
      forceUpdateCourseState.current = false;
    }

    if ((courseLessonNames && isEdit) || forceUpdateLessonNamesState.current) {
      setLessonNames(courseLessonNames);
      forceUpdateLessonNamesState.current = false;
    }
  }, [currentCourse, courseLessonNames?.length, isEdit]);

  const handleGoBack = () => {
    navigate('/content-library');
  };

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on(
            ServerEventsNames.LessonVideoEncodingComplete,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            (message: any) => {}
          );
          connection.on(
            ServerEventsNames.LessonVideoEncodingFailed,
            (error: any) => {
              const message = `${t('videoEncodingError', {
                ns: 'common'
              })}: ${error}`;
              enqueueSnackbar(message, { variant: 'warning' });
            }
          );
        })
        .catch((error: any) => console.log(error));
    }
  }, [connection]);

  const openDeleteConfirmationModal = (lessonEntryId: string) => {
    selectedLessonEntryId.current = lessonEntryId;
    setIsDeleteLessonConfirmationModalOpen(true);
  };

  const handleDeleteLessonFromCourse = async () => {
    try {
      const lessonId = lessonNames?.find(
        entry => entry.id === selectedLessonEntryId.current
      )?.lessonId;
      forceUpdateLessonNamesState.current = true;
      await dispatch<Promise<void>>(
        courseActions.deleteLessonFromCourse(course!.id!, lessonId!)
      );
      await dispatch<Promise<void>>(
        courseActions.requestCourseLessonNames(course!.id!)
      );
    } catch (e) {
      forceUpdateLessonNamesState.current = false;
      enqueueSnackbar(t('deleteLessonException', { ns: 'lessons' }), {
        variant: 'warning'
      });
    } finally {
      selectedLessonEntryId.current = '';
    }
    setIsDeleteLessonConfirmationModalOpen(false);
  };

  const closeDeleteConfirmationModal = () => {
    selectedLessonEntryId.current = '';
    setIsDeleteLessonConfirmationModalOpen(false);
  };

  const handleSaveImportLessons = async (
    selectedLessons: ImportLessonDto[]
  ) => {
    if (selectedLessons && course) {
      await dispatch<Promise<void>>(
        courseActions.importLessonsBatch(course.id, selectedLessons)
      );

      forceUpdateLessonNamesState.current = true;
      await dispatch<Promise<void>>(
        courseActions.requestCourseLessonNames(course.id)
      );
      setShowSelectLessons(false);
    }
  };

  const handleSaveLessonsOrder = async (lessonsOrder: Array<LessonOrder>) => {
    dispatch(appActions.setIsLoading(true));
    await dispatch<Promise<void>>(
      courseActions.updateLessonsOrder(course!.id!, lessonsOrder)
    );
    await dispatch<Promise<void>>(
      courseActions.requestCourseLessonNames(course!.id!)
    );
    dispatch(appActions.setIsLoading(false));
  };

  const handleCreateCourse = async (
    courseToCreate: Course,
    file: File,
    imgUrl: string,
    createToAddLessons: boolean
  ) => {
    try {
      forceUpdateCourseState.current = true;
      courseToCreate.isPartnerCompany = isPartnerCompany!;
      const courseId = await dispatch<Promise<string>>(
        courseActions.saveCourse(courseToCreate, file, imgUrl)
      );
      logEvent.logCreateCourseEvent(courseId, courseToCreate.title);
      dispatch(tagActions.requestTags());
      if (createToAddLessons && courseId) {
        return courseId;
      } else {
        enqueueSnackbar(t('courseCreatedSucessfully', { ns: 'courses' }), {
          variant: 'success'
        });
        dispatch(coursesActions.requestCourses());
        dispatch(lessonActions.requestCompanyLessons());
        handleGoBack();
      }
    } catch (e) {
      forceUpdateCourseState.current = false;
      throw e;
    }
  };

  const handleUpdateCourse = async (
    courseToUpdate: Course,
    imgUrl: string,
    isPublishing: boolean,
    saveAndStay: boolean,
    file?: File
  ) => {
    try {
      if (isPublishing) courseToUpdate.isPartnerCompany = isPartnerCompany!;
      await dispatch<Promise<void>>(
        courseActions.editCourse(courseToUpdate, imgUrl, file)
      );
      dispatch(tagActions.requestTags());

      if (isPublishing) {
        logEvent.logPublishCourseEvent(
          courseToUpdate.id,
          courseToUpdate.revisionNumber,
          courseToUpdate.title
        );
      }
      if (!saveAndStay) {
        enqueueSnackbar(t('courseSucessfullyUpdated', { ns: 'courses' }), {
          variant: 'success'
        });
        dispatch(coursesActions.requestCourses());
        dispatch(lessonActions.requestCompanyLessons());
        handleGoBack();
      }
    } catch (e) {
      handleGenericBackendError(e);
    }
  };

  const handleDeleteCourse = async (fullDelete = false) => {
    try {
      if (fullDelete) {
        dispatch(
          achievementActions.deleteLearningUnitToCompetence(
            competenciesAssignedById,
            LearningUnitType.course
          )
        );
        await dispatch<Promise<void>>(
          courseActions.deleteFullCourse(course!.id!)
        );
      } else {
        dispatch(
          achievementActions.deleteLearningUnitToCompetence(
            competenciesAssignedById,
            LearningUnitType.course
          )
        );
        await dispatch<Promise<void>>(courseActions.deleteCourse(course!.id!));
      }
      dispatch(courseActions.setCurrentCourse(null));
      setIsDeleteCourseConfirmationModalOpen(false);
      handleGoBack();
    } catch (e) {
      enqueueSnackbar(t('deleteCourseErrorMsg', { ns: 'courses' }), {
        variant: 'error'
      });
    }
  };

  const deleteCourseAndAllLessonsButton = () => {
    return (
      <BasicButton
        onClick={() => {
          handleDeleteCourse(true);
        }}
        color="primary"
        disable={false}
      >
        {t('deleteAll', { ns: 'common' })}
      </BasicButton>
    );
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        paddingLeft: { xs: '10px', sm: '1.5rem' },
        paddingRight: { xs: '10px', sm: '1.5rem' }
      }}
    >
      <Grid item paddingBottom="0.5rem" paddingTop="1rem">
        <GoBackOption
          textLink={t('backContentLibrary', { ns: 'contentLibrary' })}
          onGoBack={handleGoBack}
        />
      </Grid>
      <Grid item paddingBottom="1.5rem" paddingTop="0.5rem">
        <h4>
          {isEdit
            ? t('editACourse', { ns: 'courses' })
            : t('createACourse', { ns: 'courses' })}
        </h4>
      </Grid>
      <Grid item id="courseFormContainer" sx={{ width: { xs: '100%' } }}>
        {!course && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="50vh"
          >
            <CircularProgress />
          </Box>
        )}
        {course && lessonNames && (
          <>
            <CourseForm
              course={course}
              companyTags={companyTags ?? []}
              isEdit={isEdit}
              lessonNames={lessonNames}
              openDeleteLessonConfirmationModal={openDeleteConfirmationModal}
              openDeleteCourseConfirmationModal={
                setIsDeleteCourseConfirmationModalOpen
              }
              openSelectLessonsModal={setShowSelectLessons}
              saveLessonsOrder={handleSaveLessonsOrder}
              createCourse={handleCreateCourse}
              editCourse={handleUpdateCourse}
              editLesson={handleEditTrainingLesson}
            />
            <ConfirmationModal
              show={isDeleteLessonConfirmationModalOpen}
              showSave={true}
              showCancel={true}
              maxWidth="sm"
              saveText={t('deleteLesson', { ns: 'lessons' })}
              cancelText={t('cancel', { ns: 'common' })}
              title={t('deleteLessonFromCourse', { ns: 'lessons' })}
              onSave={handleDeleteLessonFromCourse}
              onCancel={closeDeleteConfirmationModal}
            >
              <p>
                {' '}
                {t('deleteLessonConfirmation', { ns: 'lessons' })}: &quot;
                {selectedLessonEntryId.current.length
                  ? lessonNames?.find(
                      c => c.id === selectedLessonEntryId.current
                    )?.title
                  : ''}{' '}
                {t('fromThisCourse', { ns: 'course' })}&quot;?
              </p>
            </ConfirmationModal>
            <ConfirmationModal
              show={isDeleteCourseConfirmationModalOpen}
              showSave={true}
              showCancel={true}
              maxWidth="sm"
              saveText={t('deleteCourse', { ns: 'courses' })}
              cancelText={t('cancel', { ns: 'common' })}
              title={t('courseDeletion', { ns: 'courses' })}
              onSave={handleDeleteCourse}
              onCancel={() => setIsDeleteCourseConfirmationModalOpen(false)}
              extraOptions={[deleteCourseAndAllLessonsButton()]}
            >
              <p>
                {' '}
                {t('removeCourseConfirmation', { ns: 'courses' })}: &quot;
                {course?.title}&quot;?
              </p>
            </ConfirmationModal>
            {showSelectLessons && (
              <SelectLessons
                closeModal={() => setShowSelectLessons(false)}
                showModal={showSelectLessons}
                currentLessons={lessonNames!}
                onSave={handleSaveImportLessons}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateEditCourse;

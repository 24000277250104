import React, { CSSProperties, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressLine from '../../components/ProgressLine/ProgressLine';
import { useSelector } from 'react-redux';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { ApplicationState } from '../../store';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { actionCreators as studentActions } from '../../actions/student';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import ProgressIcon from '../../components/core/ProgressIcon/ProgressIcon';
import { useTranslation } from 'react-i18next';
import { useLogEvent } from '../../hooks/useLogEvent';
import CourseCardListNew from '../../components/CourseCardList/CourseCardListNew';
import { Course } from '../../entities/Course';
import { Box } from '@mui/material';
import RichTextInput from '../../components/core/RichTextInput/RichTextInput';
import { CourseAssignmentsContext } from './CourseAssignmentsContext';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import MovieIcon from '../../components/Icons/MovieIcon';
import Group_1253 from '../../assets/icons/Group_1253.png';
import { useAppDispatch } from '../../store/hooks';

interface ProgressAndActionButtonsProps {
  course: Course;
  isComplete: boolean;
}

const ProgressAndActionButtons: React.FC<ProgressAndActionButtonsProps> = ({
  course,
  isComplete
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const logEvent = useLogEvent();
  const courseAssignments = useContext(CourseAssignmentsContext);
  const buttonSection: CSSProperties = {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-end',
    alignItems: 'end'
  };

  const boxStyle: CSSProperties = {
    marginLeft: '10px'
  };

  return (
    <div style={buttonSection}>
      <Box style={boxStyle}>
        <BasicButton
          width="105px"
          height="34px"
          color={isComplete ? 'revisit' : 'primary'}
          style={startButtonStyle}
          onClick={() => {
            const courseAssignment = courseAssignments?.find(
              c => c.course.id === course.id
            );

            navigate(`/courses/assignment/play/${courseAssignment?.id}`);

            logEvent.logStartCourseEvent(
              course.id,
              course.revisionNumber,
              course.title
            );
          }}
        >
          {isComplete
            ? t('revisit', { ns: 'common' })
            : t('start', { ns: 'common' })}
        </BasicButton>
      </Box>
    </div>
  );
};

const LearningPlanPreview: React.FC = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const params = useParams<{ id: string; assignmentId: string }>();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );

  const currentLearningPlanAssignment = useSelector(
    (state: ApplicationState) => state.learningPlans?.learningPlanPlayAssignment
  );

  const learningPlan = currentLearningPlanAssignment?.learningPlanRevision;

  const completedCourses =
    currentLearningPlanAssignment?.courseAssignments.filter(c => c.isComplete);

  const completedCoursesInfo = completedCourses?.map(c => ({
    courseName: c.course.title,
    completedOn: c.completionDateUtc,
    courseId: c.course.id
  }));

  const learningPlanCourses =
    currentLearningPlanAssignment?.courseAssignments.map(c => c.course);

  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const { t } = useTranslation(['common', 'lessons', 'courses', 'assignments']);

  useEffect(() => {
    if (params.id) {
      dispatch(learningPlanActions.getLearningPlanAssignment(params.id));
    }

    return () => {
      dispatch(learningPlanActions.clearLearningPlanAssignment());
    };
  }, [params.id]);

  useEffect(() => {
    dispatch(studentActions.getTakenTimesCourses(employeeId));
  }, [employeeId]);

  const backToPreviousPage = () => {
    navigate(-1);
  };

  const coursesCountStyle: CSSProperties = {
    fontSize: '11px',
    color: theme.palette.grey[500]
  };

  const descriptionStyle: CSSProperties = {
    color: theme.palette.grey[500],
    paddingBottom: '2rem'
  };

  const containerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'contain',
    paddingBottom: 30,
    paddingLeft: 0,
    paddingRight: 0
  };

  return (
    <PageContainer style={containerStyle}>
      <div className="d-flex flex-column flex-md-row">
        <div className="pt-2">
          <img
            src={Group_1253}
            style={BackwardIconStyle}
            onClick={backToPreviousPage}
          ></img>
        </div>
        <Grid
          className="ps-4 w-100"
          sx={{
            paddingLeft: {
              xs: '0px !important',
              sm: '1.5rem !important',
              md: '1.5rem !important'
            },
            paddingTop: { xs: '10px' }
          }}
        >
          <Grid container>
            <Grid item xs={12} lg={8} className="pb-2">
              <h3 className="mb-0" style={{ fontSize: '24px' }}>
                {learningPlan?.name}
              </h3>
            </Grid>
            <Grid item xs={12} lg={8} className="pb-4">
              <MovieIcon />
              <span style={coursesCountStyle}>
                {` ${learningPlan?.courseCount ?? ''} ${t('courses', {
                  ns: 'courses'
                })}`}
              </span>
            </Grid>
            <Grid item xs={12} lg={8} sx={descriptionStyle}>
              <RichTextInput
                value={
                  learningPlan?.description ? learningPlan?.description : ''
                }
                disabled={true}
              />
            </Grid>

            <Grid
              item
              xs={12}
              lg={8}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '0.5rem',
                color: theme.palette.grey[500]
              }}
            >
              <span>{t('progress', { ns: 'common' })}</span>
              <span>{`${appIsLoading ? '?' : completedCourses?.length} / ${
                learningPlanCourses?.length ?? '0'
              } ${t('courses', {
                ns: 'courses'
              })}`}</span>
            </Grid>

            <Grid item xs={12} lg={8}>
              {appIsLoading && <LinearProgress />}
              {!appIsLoading && learningPlanCourses && completedCourses && (
                <ProgressLine
                  color={'#44d47a'}
                  value={
                    (completedCourses.length / learningPlanCourses.length) * 100
                  }
                  width="100%"
                />
              )}
            </Grid>
            <Grid item xs={12} className="pt-5 pb-2">
              <h4>{t('courses', { ns: 'courses' })}</h4>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                columns={{ xs: 1, md: 12, lg: 12 }}
                sx={coursesContainerStyle}
              >
                {learningPlanCourses && !appIsLoading ? (
                  <CourseAssignmentsContext.Provider
                    value={currentLearningPlanAssignment?.courseAssignments}
                  >
                    <CourseCardListNew
                      takenTimesCourses={takenTimesCourses}
                      isPreviewMode={false}
                      completedCourses={completedCoursesInfo ?? []}
                      courses={learningPlanCourses || []}
                      shouldShowDrafCourses={true}
                      cardComplement={ProgressAndActionButtons}
                    />
                  </CourseAssignmentsContext.Provider>
                ) : (
                  <ProgressIcon />
                )}
                {!appIsLoading &&
                  learningPlanCourses &&
                  !learningPlanCourses.length && (
                    <Grid item>
                      <span>{t('noCourses', { ns: 'courses' })}</span>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </PageContainer>
  );
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 20,
  height: 20,
  cursor: 'pointer'
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const coursesContainerStyle: SxProps = {
  paddingTop: '2rem',
  paddingLeft: '2rem'
};

export default LearningPlanPreview;

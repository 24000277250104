import { Paper, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SkillIndicatorProps {
  name: string;
  value: number;
}

const SkillIndicator = ({ name, value }: SkillIndicatorProps) => {
  const { t } = useTranslation(['analytics']);
  const theme = useTheme();
  const colors: { [attr: string]: string } = {
    current: theme.palette.success.main,
    expireSoon: theme.palette.primary.main,
    incomplete: theme.palette.grey[100],
    expired: theme.palette.error.light
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        borderColor: colors[name],
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '150px',
        height: '40px',
        marginRight: '0.5rem',
        marginBottom: '0.5rem'
      }}
    >
      {`${value} ${t(name)}`}
    </Paper>
  );
};

export default SkillIndicator;

import { Box } from '@mui/material';

import BasicButton from '../../../components/core/BasicButton/BasicButton';

interface NoOnsiteTrainingsProps {
  message: string;
  captionButton: string;
  onClick: () => void;
}

const NoOnsiteTrainings = ({
  message,
  captionButton,
  onClick
}: NoOnsiteTrainingsProps) => (
  <Box
    sx={{
      flexGrow: 1,
      marginTop: '100px',
      width: 507,
      margin: '60px auto auto',
      textAlign: 'center'
    }}
  >
    <div style={{ fontSize: '30px' }}>{message}</div>
    <BasicButton
      onClick={onClick}
      color="primary"
      style={{ marginTop: 20, fontSize: 16 }}
    >
      {captionButton}
    </BasicButton>
  </Box>
);

export default NoOnsiteTrainings;

import { CSSProperties } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import BasicButton from '../../core/BasicButton/BasicButton';
import { buttonStyle, fontDeleteButton } from '../../../utils/buttonStyles';
import { Course } from '../../../entities/Course';

export interface FooterActionButtonsProps {
  courseData: Course;
  isEdit: boolean;
  saveInProgress: boolean;
  onSave: () => void;
  onPublish: () => void;
  onConfirmationModalOpen?: (open: boolean) => void;
}

const FooterActionButtons: React.FC<FooterActionButtonsProps> = ({
  courseData,
  isEdit,
  saveInProgress,
  onSave,
  onPublish,
  onConfirmationModalOpen
}) => {
  const { t } = useTranslation(['courses', 'common']);
  const isMobileOrTablet = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      {isEdit && (
        <BasicButton
          onClick={() =>
            onConfirmationModalOpen && onConfirmationModalOpen(true)
          }
          color="delete"
          disable={saveInProgress}
          style={buttonStyle}
        >
          <Typography sx={fontDeleteButton}>
            {t('deleteCourse', { ns: 'courses' })}
          </Typography>
        </BasicButton>
      )}
      {courseData.isDraft && (
        <div>
          <BasicButton
            onClick={onSave}
            color="secondary"
            disable={saveInProgress}
            style={customButtonStyle}
          >
            {t('saveAsDraft', { ns: 'courses' })}
          </BasicButton>

          <BasicButton
            onClick={onPublish}
            color="primary"
            disable={saveInProgress}
            style={
              isMobileOrTablet
                ? customButtonStyle
                : { ...customButtonStyle, marginLeft: '1rem' }
            }
          >
            {t('publish', { ns: 'common' })}
          </BasicButton>
        </div>
      )}
      {!courseData.isDraft && (
        <BasicButton
          onClick={onSave}
          color="primary"
          disable={saveInProgress}
          style={customButtonStyle}
        >
          {t('save', { ns: 'common' })}
        </BasicButton>
      )}
    </>
  );
};

const customButtonStyle: CSSProperties = {
  minWidth: '170px',
  height: '45px',
  marginLeft: '5px'
};

export default FooterActionButtons;

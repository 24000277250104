import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';

import {
  AiccCourse,
  AssignCourseToEmployeeDto
} from '../../../entities/Course';
import { Employee } from '../../../entities/Employee';
import { ApplicationState } from '../../../store';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useUserRole from '../../../hooks/useUserRole';

import { actionCreators as employeeActions } from '../../../actions/employees';
import { actionCreators as courseActions } from '../../../actions/courses';
import { actionCreators as subscriptionActions } from '../../../actions/subscriptions';
import {
  EnrollmentTypes,
  UserRoles,
  UserClaims
} from '../../../core/constants';
import { TransactionStatusEnum } from '../../../core/enums';
import { newAssignCourseToEmployeeDto } from '../../../reducers/CoursesReducer';
import { useLogEvent } from '../../../hooks/useLogEvent';
import { AssignClickSafetyModal } from '../../../components/ClickSafety';
import { SubscriptionData } from '../../../entities/Subscription';
import config from '../../../config';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import InfoModal from '../../../components/core/InfoModal/InfoModal';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';

export interface AssignClickSafetyProps {
  show: boolean;
  clickSafetyCourse: AiccCourse;
  onClose: () => void;
}

const AssignClickSafety: React.FC<AssignClickSafetyProps> = ({
  show,
  clickSafetyCourse,
  onClose
}) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const transactionStatus = useSelector(
    (state: ApplicationState) => state.courses?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const courseAssignmentStatus = useSelector(
    (state: ApplicationState) => state?.courses?.courseAssignmentStatus
  );
  const subscriptionIsLoading = useSelector(
    (state: ApplicationState) => state?.subscriptions?.isLoading
  );
  const subscriptionsSubscribers = useSelector(
    (state: ApplicationState) => state?.subscriptions?.subscriptionsSubscribers
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [courseAssignment, setCourseAssignment] =
    useState<AssignCourseToEmployeeDto>({ ...newAssignCourseToEmployeeDto });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const [subscriptionData, setSubscriptionData] = useState<SubscriptionData[]>(
    []
  );
  const [subscribedEmployees, setSubscribedEmployees] = useState<
    Employee[] | null
  >(null);
  const [employeesToShow, setEmployeesToShow] = useState<Employee[] | null>(
    null
  );
  const [selectedSubscriptionId, setSelectedSubscriptionId] =
    useState<string>('');
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation(['common']);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(
      subscriptionActions.requestSubscriptionsSubscribers(
        companyId,
        clickSafetyCourse.handles
      )
    );
    return () => {
      dispatch(subscriptionActions.setSubscriptionsSubscribers([]));
    };
  }, []);

  useEffect(() => {
    if (!subscriptionIsLoading && subscriptionsSubscribers?.length) {
      const subscriptions: SubscriptionData[] = [];
      let employeesWithActiveSubscription: Employee[] = [];
      subscriptionsSubscribers.forEach(subscription => {
        const subscriptionsRemaining =
          subscription.count - subscription.employees.length;
        const expiresAt = absUtcDateToLocalMoment(
          subscription.expiresAt
        ).startOf('day');
        const currentDate = moment().startOf('day');

        if (expiresAt >= currentDate) {
          employeesWithActiveSubscription =
            employeesWithActiveSubscription.concat(subscription.employees);
        }

        if (subscriptionsRemaining && expiresAt >= currentDate) {
          subscriptions.push({
            id: subscription.id,
            label: `${
              subscription.name
            } - <span class='main-primary-color'> Exp ${expiresAt.format(
              'MM.DD.YY'
            )}</span>`,
            subscriptionsRemaining
          });
        }
      });
      setSubscriptionData(subscriptions);
      setSubscribedEmployees(employeesWithActiveSubscription);
    }
  }, [subscriptionIsLoading, subscriptionsSubscribers?.length]);

  useEffect(() => {
    if (clickSafetyCourse && !courseAssignment.courseId.length)
      dispatch(
        courseActions.requestStatusOfEmployeesCourseAssigments(
          clickSafetyCourse.id
        )
      );
    setCourseAssignment({
      ...newAssignCourseToEmployeeDto,
      courseId: clickSafetyCourse.id,
      assignBy: ''
    });
  }, [clickSafetyCourse]);

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }

    return () => {
      dispatch(courseActions.SetStatusOfEmployeesCourseAssigments(null));
    };
  }, [companyId]);

  useEffect(() => {
    if (employees) {
      const updatedEmployees = employees.map(employee => ({
        ...employee,
        hasSubscription: subscribedEmployees
          ? subscribedEmployees.findIndex(emp => emp.id === employee.id) >= 0
          : false
      }));
      setEmployeesToShow(updatedEmployees);
    }
  }, [employees?.length, subscribedEmployees]);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionStatus === TransactionStatusEnum.Failed) {
        const message = t('anExceptionOccurred', { ns: 'common' });
        setErrorMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      } else if (transactionStatus === TransactionStatusEnum.Successfull) {
        setShowAssignmentConfirmation(true);
        setErrorMessage('');
      }
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(courseActions.resetCourseTransactionStatus());

    return () => {
      dispatch(courseActions.resetCourseTransactionStatus());
    };
  }, [transactionStatus]);

  const validateAssignment = (
    subscriberIds: string[],
    addNewSubscribers: boolean
  ) => {
    let message = '';
    const subscription = subscriptionsSubscribers?.find(
      subs => subs.id === selectedSubscriptionId
    );

    if (addNewSubscribers && !selectedSubscriptionId.trim().length) {
      message = t('mustChooseASubscription', { ns: 'assignments' });
    } else if (!courseAssignment.employeeIds.length) {
      message = t('selectEmployee', { ns: 'common' });
    } else if (
      courseAssignment.assignBy === EnrollmentTypes.DueDate &&
      !courseAssignment.dueDate
    ) {
      message = t('mustSelectDueDate', { ns: 'assignments' });
    } else if (subscriberIds.length > (subscription?.count ?? 0)) {
      message = t('notEnoughSubscriptions', { ns: 'assignments' });
    }

    return message;
  };

  const validatePendingAssignments = async () => {
    let message = '';

    try {
      const response = await axios.get(
        `${config.STUDENT_API_URL}assignments/${courseAssignment.courseId}/pending`
      );

      if (
        response.data.length &&
        response.data.some((assignment: { employeeId: string }) =>
          courseAssignment.employeeIds.includes(assignment.employeeId)
        )
      ) {
        const assignments = response.data.filter(
          (assignment: { employeeId: string }) =>
            courseAssignment.employeeIds.includes(assignment.employeeId)
        );

        message = `<span>${t('employeesWithPendingCompletionMsg', {
          ns: 'assignments'
        })}</span>`;
        message += '<ul>';
        assignments.forEach((assignment: { employeeId: string }) => {
          const employee = employees?.find(
            employee => employee.id === assignment.employeeId
          );

          message +=
            employee?.firstName && employee.lastName
              ? `<li>${employee?.firstName} ${employee?.lastName}</li>`
              : employee?.email
                ? `<li>${employee?.email}</li>`
                : employee?.phoneNumber
                  ? `<li>${employee?.phoneNumber}</li>`
                  : '';
        });
        message += '</ul>';
        message += `<span>${t('unselectEmployeesMsg', {
          ns: 'assignments'
        })}</span>`;
      }
    } catch (e) {
      message = t('validationSubscriptionErrorMsg', { ns: 'assignments' });
    }

    return message;
  };

  const validateSubscriptionChanges = async (
    subscriptionEmployeesIds: string[]
  ) => {
    let message = '';

    try {
      const response = await axios.put(
        `${config.AUTHORITY_API_URL}subscriptions/${selectedSubscriptionId}/employees/preview?companyId=${companyId}`,
        subscriptionEmployeesIds
      );

      if (response.data.allocationPreview) {
        message = t('noSubscriptionsMsg', { ns: 'assignments' });
      } else if (response.data.employeesToRemove.length) {
        message = t('remainingSubscriptionErrorMsg', { ns: 'assignments' });
      }
    } catch (e) {
      message = t('validationSubscriptionErrorMsg', { ns: 'assignments' });
    }

    return message;
  };

  const handleAssign = async () => {
    setSaveInProgress(true);
    const notSubscribedEmployees = employeesToShow?.filter(
      employee =>
        !employee.hasSubscription &&
        courseAssignment.employeeIds.includes(employee.id)
    );

    let subscriptionEmployeesIds: string[] = [];
    let originalSubscriptionEmployeesIds: string[] = [];
    const subscription = subscriptionsSubscribers?.find(
      subs => subs.id === selectedSubscriptionId
    );
    if (subscription) {
      originalSubscriptionEmployeesIds = subscription.employees.map(
        employee => employee.id
      );
      subscriptionEmployeesIds = [...originalSubscriptionEmployeesIds];
      notSubscribedEmployees?.forEach(employee => {
        if (
          !subscriptionEmployeesIds.find(
            subscriberId => subscriberId === employee.id
          )
        ) {
          subscriptionEmployeesIds.push(employee.id);
        }
      });
    }

    let alertMessage = '';
    let message = validateAssignment(
      subscriptionEmployeesIds,
      !!notSubscribedEmployees?.length
    );
    if (!message.length) {
      message = await validatePendingAssignments();
      if (message.length) {
        alertMessage = t('employeesWithPendingCompletionAlert', {
          ns: 'assignments'
        });
      }
    }

    if (
      !courseAssignment.assignBy ||
      (courseAssignment.assignBy === EnrollmentTypes.DueDate &&
        !courseAssignment.dueDate)
    ) {
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });
    }

    if (!message.length && !!selectedSubscriptionId) {
      message = await validateSubscriptionChanges(subscriptionEmployeesIds);
    }

    if (message.length) {
      const messageToast = alertMessage.length ? alertMessage : message;
      enqueueSnackbar(messageToast, { variant: 'warning' });
      setErrorMessage(message);
      setSaveInProgress(false);
      return;
    }

    dispatch(
      courseActions.assignClickSafetyCourseToEmployees(
        courseAssignment,
        selectedSubscriptionId,
        subscriptionEmployeesIds,
        originalSubscriptionEmployeesIds,
        companyId
      )
    );
    courseAssignment.employeeIds.forEach(employeeId => {
      logEvent.logAssignCourseEvent(courseAssignment.courseId, employeeId);
    });
  };
  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };
  return (
    <>
      <AssignClickSafetyModal
        showModal={show}
        assignToEntity={clickSafetyCourse}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onClose={onClose}
        employees={employeesToShow || []}
        employeeGroups={employeeGroups || []}
        assignment={courseAssignment}
        subscriptionData={subscriptionData}
        setAssignment={setCourseAssignment}
        setSelectSubscription={setSelectedSubscriptionId}
        isLoading={!!subscriptionIsLoading || !!isLoading}
        learningUnitAssigmentStatus={courseAssignmentStatus}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignClickSafety;

import { CSSProperties, useEffect, useState, useContext } from 'react';
import {
  Box,
  FormControl,
  Grid,
  Stack,
  ThemeProvider,
  createTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import themeConfig from '../../themes/theme';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import SkillIndicator from './skills/SkillIndicator';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import SkillTable from './skills/SkillTable';
import { actionCreators as analyticsActions } from '../../actions/analytics';
import { ApplicationState } from '../../store';
import { AnalyticsCommonFilterContext } from '../../contexts/AnalyticsContext';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import { useAppDispatch } from '../../store/hooks';

interface SortInfo {
  field: string;
  sort: string;
}

interface SkillsProps {
  onlyCertificates?: boolean;
}

const Skills = ({ onlyCertificates }: SkillsProps) => {
  const theme = createTheme(themeConfig);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['analytics']);
  const filterOptions = [
    { label: t('viewAll', { ns: 'analytics' }), value: '' },
    { label: t('active', { ns: 'analytics' }), value: 'active' },
    {
      label: t('subscribedAndActive', { ns: 'analytics' }),
      value: 'subscribed'
    }
  ];
  const commonfilters = useContext(AnalyticsCommonFilterContext);
  const [filterOption, setFilterOption] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [sortInfo, setSortInfo] = useState<SortInfo>({ field: '', sort: '' });
  const [page, setPage] = useState<number>(1);
  const skillsData = useSelector(
    (state: ApplicationState) => state.analytics?.skillsData
  );
  const indicators = [
    {
      name: 'current',
      value: skillsData?.employeeCompetenciesIndicators?.current ?? 0
    },
    {
      name: 'expireSoon',
      value: skillsData?.employeeCompetenciesIndicators?.expireSoon ?? 0
    },
    {
      name: 'incomplete',
      value: skillsData?.employeeCompetenciesIndicators?.incomplete ?? 0
    },
    {
      name: 'expired',
      value: skillsData?.employeeCompetenciesIndicators?.expired ?? 0
    }
  ];
  if (onlyCertificates) {
    indicators.splice(2, 1);
  }
  const showActiveOnly = filterOption === 'active';
  const showSubscribedOnly = filterOption === 'subscribed';
  const PAGE_SIZE = 50;
  let searchTimeout: NodeJS.Timeout;
  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();

  useEffect(() => {
    loadData(1, sortInfo);
  }, []);

  useEffect(() => {
    if (!skillsData) return;

    loadData(1, sortInfo, true);
  }, [
    searchKey,
    filterOption,
    commonfilters.groupId,
    commonfilters.supervisorId
  ]);

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
    loadData(pageNumber, sortInfo, true);
  };

  const handleSortChange = (field: string, sort: string) => {
    const newSortInfo = { field, sort };
    setSortInfo(newSortInfo);
    loadData(page, newSortInfo, true);
  };

  const loadData = (
    pageNumber: number,
    sort: SortInfo,
    forceResetData = false
  ) => {
    if (forceResetData) {
      dispatch(analyticsActions.setSkillsData(null));
    }

    dispatch(
      analyticsActions.getSkillsData({
        page: pageNumber,
        pageSize: PAGE_SIZE,
        searchKey,
        sortKey: sort.field,
        sortOrientation: sort.sort,
        activeOnly: showActiveOnly,
        subscribedOnly: showSubscribedOnly,
        groupId: commonfilters.groupId,
        supervisorId: commonfilters.supervisorId,
        onlyCertificates: !!onlyCertificates
      })
    );
  };

  const handleChangeFilterOption = (items: string[]) =>
    setFilterOption(items[0]);

  const handleSearchData = (value: string) => {
    if (!skillsData) return;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setSearchKey(value);
    }, 1000);
  };

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="column">
          <Grid item flex={1}>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                {indicators.map(item => (
                  <SkillIndicator
                    key={item.name}
                    name={item.name}
                    value={item.value}
                  />
                ))}
              </Stack>
              <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                {!employeeActiveUpdates.employeeActiveUpdates && (
                  <FormControl
                    variant="standard"
                    sx={{
                      marginRight: '0.5rem',
                      marginBottom: '0.5rem'
                    }}
                    data-testid="filterOptionsTestId"
                    data-trackid="filterOptionsTrackId"
                  >
                    <BasicSelect
                      labelId="filterOptions"
                      id="filterOptions"
                      options={filterOptions}
                      value={filterOption.length ? [filterOption] : []}
                      placeholder={t('filter', { ns: 'analytics' })}
                      handleOnChange={handleChangeFilterOption}
                      style={selectStyle}
                      theme="dark"
                      multiple={false}
                      sx={{ backgroundColor: 'transparent' }}
                      disabled={!skillsData}
                    />
                  </FormControl>
                )}
                {employeeActiveUpdates.employeeActiveUpdates && (
                  <FormCheckBox
                    data-testid="excludeUnsubscribedEmployeesTestId"
                    data-trackid="excludeUnsubscribedEmployeesTrackId"
                    label={t('excludeUnsubscribedEmployees', {
                      ns: 'analytics'
                    })}
                    checked={filterOption === filterOptions[2].value}
                    onChange={e =>
                      handleChangeFilterOption(
                        e.target.checked
                          ? [filterOptions[2].value]
                          : [filterOptions[0].value]
                      )
                    }
                    disabled={!skillsData}
                  />
                )}
                <SearchBox
                  data-testid="searchContentTestId"
                  data-trackid="searchContentTrackId"
                  id="searchContent"
                  name="searchContent"
                  value={searchKey}
                  onChangeValue={handleSearchData}
                  placeholderText={t('search', { ns: 'common' })}
                  width="250px"
                  height="45px"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item sx={{ paddingTop: '1rem' }}>
            <SkillTable
              data={skillsData?.analyticsEmployeeCompetencies}
              pageSize={PAGE_SIZE}
              onPageChange={handlePageChange}
              onSortModelChange={handleSortChange}
              onlyCertificates={!!onlyCertificates}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const selectStyle: CSSProperties = {
  width: '210px',
  height: '45px'
};

export const buttonModalStyle: CSSProperties = {
  maxWidth: '210px',
  width: '210px',
  height: '30px'
};

export const fontButton: CSSProperties = {
  fontSize: '16px',
  color: '#0E0D0D',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px'
};

export default Skills;

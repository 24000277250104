import { useContext, useState } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';
import { Tag } from '../../../entities/Tag';

const useLearningUnitTagFilter = () => {
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const defaultInitialValue = userNavigation?.tagFilter ?? [];

  const [tagFilter, setTagFilter] = useState<Tag[]>(defaultInitialValue);

  const handleChangeLearningUnitTag = (tags: Tag[]) => {
    userNavigationApi?.changeTagFilter(tags);
    setTagFilter(tags);
  };

  return [tagFilter, handleChangeLearningUnitTag] as const;
};

export default useLearningUnitTagFilter;

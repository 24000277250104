import GroupCurrentAssignmentsModal from './GroupCurrentAssignmentsModal';
import EmployeeCompletedAssignmentsModal from './EmployeeCompletedAssignmentsModal';
import EmployeeOverdueAssignmentsModal from './EmployeeOverdueAssignmentsModal';
import EmployeeCurrentAssignmentsModal from './EmployeeCurrentAssignmentsModal';
import GroupCompletedAssignmentsModal from './GroupCompletedAssignmentsModal';
import EmployeeSelfAssignmentsModal from './EmployeeSelfAssignmentsModal';

interface ModalFactoryProps {
  modalType: string;
  groupId?: string;
  groupName?: string;
  employeeId?: string;
  employeeName?: string;
  onCloseModal: () => void;
}

const ModalFactory = ({
  modalType,
  groupId,
  groupName,
  employeeId,
  employeeName,
  onCloseModal
}: ModalFactoryProps) => {
  switch (modalType) {
    case 'groupCurrentAssignments':
      return (
        <GroupCurrentAssignmentsModal
          groupId={groupId}
          groupName={groupName}
          onCloseModal={onCloseModal}
        />
      );
    case 'employeeCompletedAssignments':
      return (
        <EmployeeCompletedAssignmentsModal
          employeeId={employeeId}
          employeeName={employeeName}
          onCloseModal={onCloseModal}
        />
      );
    case 'employeeOverdueAssignments':
      return (
        <EmployeeOverdueAssignmentsModal
          employeeId={employeeId}
          employeeName={employeeName}
          onCloseModal={onCloseModal}
        />
      );
    case 'employeeCurrentAssignments':
      return (
        <EmployeeCurrentAssignmentsModal
          employeeId={employeeId}
          employeeName={employeeName}
          onCloseModal={onCloseModal}
        />
      );
    case 'groupCompletedAssignments':
      return (
        <GroupCompletedAssignmentsModal
          groupId={groupId}
          groupName={groupName}
          onCloseModal={onCloseModal}
        />
      );
    case 'employeeSelfAssignments':
      return (
        <EmployeeSelfAssignmentsModal
          employeeId={employeeId}
          employeeName={employeeName}
          onCloseModal={onCloseModal}
        />
      );
    default:
      return <></>;
  }
};

export default ModalFactory;

import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { appPermissions } from '../../../core/constants';
import ProtectedComponent from '../../core/ProtectedComponent/ProtectedComponent';
import { divEditButtonStyle, editStyle, styleIcon } from '../LessonCardStyle';

interface EditInfoLessonProps {
  id: string;
  onEdit: (id: string) => void;
}

const EditInfoLesson: React.FC<EditInfoLessonProps> = ({ id, onEdit }) => {
  const { t } = useTranslation(['common']);

  return (
    <div style={divEditButtonStyle}>
      <div>
        <ProtectedComponent action={appPermissions.EDIT_LESSON}>
          <Button onClick={() => onEdit(id)} style={editStyle}>
            {t('edit', { ns: 'common' })}{' '}
            <FontAwesomeIcon icon={faPen} style={styleIcon} />
          </Button>
        </ProtectedComponent>
      </div>
    </div>
  );
};

export default EditInfoLesson;

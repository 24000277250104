import React, { CSSProperties, useState, useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Row, Col } from 'react-bootstrap';
import Panel from '../../../components/core/Panel/Panel';
import EmployeeList from '../../../components/EmployeeList/EmployeeList';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { Employee, EmployeeGroup } from '../../../entities/Employee';

import { dropDownItem } from '../../../entities/Common';
import { SortEmployeeOption } from '../../../entities/SortEmployeeOption';
import TextDropdownList from '../../../components/core/TextDropdownList/TextDropdownList';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { useTranslation } from 'react-i18next';
import FormCheckBox from '../../../components/core/FormCheckBox/FormCheckBox';
import { EmployeeAssignmentStatus } from '../../../entities/Assignment';
import {
  AssignOnsiteTrainingDto,
  OnsiteTraining
} from '../../../entities/OnsiteTraining';
import TagInput from '../../../components/TagInput/TagInput';
import TextBox from '../../../components/core/TextBox/TextBox';
import MultiDatesPicker from '../../../components/core/MultiDatesPicker/MultiDatesPicker';
import MultiTimePicker from '../../../components/core/MultiTimePicker/MultiTimePicker';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Tag } from '../../../entities/Tag';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { InfoBubble } from '../../../components/InfoBubble/InfoBubble';
import { useAppDispatch } from '../../../store/hooks';

export interface AssignOnsiteTrainingEmployeesPanelProps {
  employees: Employee[];
  employeeGroups: EmployeeGroup[];
  assignment: AssignOnsiteTrainingDto;
  layoutType?: 'standard' | 'clickSafety';
  learningUnitAssigmentStatus?: EmployeeAssignmentStatus[] | null;
  setAssignment: (assigment: any) => void;
  onsiteTraining: OnsiteTraining;
}

const AssignmentModalBottomLabel: React.FC<any> = ({ children }) => {
  const theme = useTheme();
  const labelStyle = {
    color: theme.palette.common.white,
    lineHeight: '18px',
    fontSize: '15px',
    marginTop: '5px'
  };
  return <label style={labelStyle}>{children}</label>;
};

const AssignOnsiteTrainingEmployeesPanel: React.FC<
  AssignOnsiteTrainingEmployeesPanelProps
> = ({
  employees,
  employeeGroups,
  assignment,
  learningUnitAssigmentStatus,
  layoutType = 'standard',
  setAssignment,
  onsiteTraining
}) => {
  let searchTimeOut: NodeJS.Timeout;
  const [groups, setGroups] = useState<dropDownItem[]>([]);
  const [groupIds, setGroupIds] = useState<string[]>([]);
  const [employeesToShow, setEmployeesToShow] = useState<Employee[]>([]);
  const [sortOrder, setSortOrder] = useState<number>(
    SortEmployeeOption.Assigned
  );
  const [assignedEmployees, setAssignedEmployees] = useState<string[]>(
    assignment.employeesIds
  );
  const [applyAllChecked, setApplyAllChecked] = useState<boolean>(false);
  const { t } = useTranslation([
    'learningPlans',
    'courses',
    'lessons',
    'common',
    'assignments',
    'employees',
    'onsiteTraining'
  ]);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();

  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );

  const employeeTags = useMemo(() => {
    if (activeEmployees) {
      return activeEmployees.map((employee): Tag => {
        const name = employee.firstName.length
          ? `${employee.firstName} ${employee.lastName}`
          : employee.email && employee.email.length
            ? employee.email
            : employee.phoneNumber!;
        return {
          id: employee.id,
          name
        };
      });
    }
  }, [activeEmployees]);

  const [selectedInstructor, setSelectedInstructor] = useState<Tag[]>([]);

  useEffect(() => {
    setEmployeesToShow(sortEmployees(sortOrder, employees));
  }, [employees]);

  useEffect(() => {
    setGroups(getListItems(employeeGroups));
  }, [employeeGroups.length]);

  useEffect(() => {
    if (employeesToShow.length) {
      setEmployeesToShow(prevEmployeesToShow =>
        sortEmployees(sortOrder, prevEmployeesToShow)
      );
    }
  }, [sortOrder]);

  useEffect(() => {
    setAssignment({ ...assignment, employeesIds: [...assignedEmployees] });
  }, [assignedEmployees.length]);

  useEffect(() => {
    setAssignedEmployees(assignment.employeesIds);
  }, [assignment.employeesIds.length]);
  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
  }, []);

  const getListItems = (list: EmployeeGroup[]) => {
    return list
      .filter(
        group =>
          group.employees &&
          group.employees.some(employee => employee.hasTrainingSubscription)
      )
      .map(item => ({ label: item.name, value: item.id }));
  };

  const dropdownContainerStyle: CSSProperties = {
    width: '100%',
    textAlign: 'right',
    paddingTop: '0px'
  };

  const handleOnChangeGroupList = (values: string[]) => {
    setGroupIds(values);
  };

  const handleSearch = (value: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    searchTimeOut = setTimeout(() => {
      const safeValue = value.toLowerCase();
      const filtered = employees.filter(
        employee =>
          `${employee.firstName} ${employee.lastName}`
            .toLowerCase()
            .includes(safeValue) ||
          (employee.email ?? '').toLowerCase().includes(safeValue) ||
          (employee.phoneNumber ?? '').toLowerCase().includes(safeValue) ||
          employee.groups.some(group =>
            group.name.toLowerCase().includes(safeValue)
          )
      );

      setEmployeesToShow(sortEmployees(sortOrder, filtered));
    }, 1000);
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      const employeeIds = employees.map(employee => employee.id);
      setGroupIds([]);
      setAssignedEmployees(employeeIds);
      setAssignment({ ...assignment, employeeIds: employeeIds });
    } else {
      setAssignedEmployees([]);
      setAssignment({ ...assignment, employeeIds: [] });
    }

    setApplyAllChecked(checked);
    setAssignment({ ...assignment, assignToAllEmployees: checked });
  };

  const handleSortChange = (e: any) => {
    setSortOrder(parseInt(e.target.value));
  };

  const orderByAssigned = (emps: Employee[]) => {
    const assignedEmps = emps.slice().map(emp => {
      return {
        employee: emp,
        assigned: assignedEmployees.includes(emp.id)
      };
    });
    const sorted = assignedEmps.sort(
      (a, b) => Number(a.assigned) - Number(b.assigned)
    );
    return sorted.map(x => x.employee);
  };

  const sortEmployees = (option: number, employeesToSort: Employee[]) => {
    switch (option) {
      case SortEmployeeOption.Assigned:
        return orderByAssigned(employeesToSort).reverse();
      case SortEmployeeOption.Unassigned:
        return orderByAssigned(employeesToSort);
      case SortEmployeeOption.Az:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()));
      case SortEmployeeOption.Za:
        return employeesToSort
          .slice()
          .sort((a, b) => a.firstName.trim().localeCompare(b.firstName.trim()))
          .reverse();
      default:
        return employeesToSort;
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Panel width="100%" height="100%" backgroundColor="#1B1C1C">
            <Row className="pb-1">
              <Col md={12} lg={6} className="pt-1">
                <SearchBox
                  id="search"
                  value=""
                  name="search"
                  width="100%"
                  height="38px"
                  placeholderText={t('search', { ns: 'common' })}
                  variant="dark"
                  onChangeValue={handleSearch}
                />
              </Col>
              <Col md={12} lg={3} className="pt-1">
                <BasicSelect
                  labelId="groups"
                  id="groups"
                  options={groups}
                  defaultValue=""
                  value={groupIds}
                  placeholder={t('quickAssignToGroup', { ns: 'assignments' })}
                  handleOnChange={handleOnChangeGroupList}
                  disabled={applyAllChecked}
                />
              </Col>
              <Col md={12} lg={3} className="pt-1">
                <FormCheckBox
                  onChange={handleCheckBoxChange}
                  checked={applyAllChecked}
                  label={t('applyToAllEmployees', { ns: 'employees' })}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={dropdownContainerStyle}>
                  <TextDropdownList
                    onChange={handleSortChange}
                    label={`${t('sort', { ns: 'common' })}:`}
                    items={[
                      {
                        value: SortEmployeeOption.Assigned,
                        text: t('assigned', { ns: 'common' })
                      },
                      {
                        value: SortEmployeeOption.Unassigned,
                        text: t('unassigned', { ns: 'common' })
                      },
                      { value: SortEmployeeOption.Az, text: 'a-z' },
                      { value: SortEmployeeOption.Za, text: 'z-a' }
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                {employeesToShow && (
                  <EmployeeList
                    employees={employeesToShow}
                    groupIds={groupIds}
                    assignedEmployeeIds={assignedEmployees}
                    onUpdateAssignedEmployees={setAssignedEmployees}
                    applyToAllChecked={applyAllChecked}
                    learningUnitAssigmentStatus={learningUnitAssigmentStatus!}
                    layoutType={layoutType}
                  />
                )}
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <AssignmentModalBottomLabel>
                  {t('certifiyingBody', { ns: 'onsiteTraining' })}{' '}
                  <span className="text-danger">*</span>
                </AssignmentModalBottomLabel>
                <div>
                  <TagInput
                    options={employeeTags ?? []}
                    value={selectedInstructor}
                    onChange={(_, value, reason) => {
                      if (reason === 'removeOption' || reason === 'clear') {
                        setSelectedInstructor([]);
                        setAssignment({ ...assignment, instructor: '' });
                      } else if (reason === 'selectOption') {
                        setSelectedInstructor([value[value.length - 1]]);
                        setAssignment({
                          ...assignment,
                          adminLeadingId: value[value.length - 1].id
                        });
                      }
                    }}
                    placeholder={t('searchUsers', { ns: 'onsiteTraining' })}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <AssignmentModalBottomLabel>
                  {t('onsiteTrainingDaysLong', { ns: 'onsiteTraining' })}
                  <span className="text-danger">*</span>
                </AssignmentModalBottomLabel>
                <div>
                  <TextBox
                    id="onsiteTrainingDays"
                    name="onsiteTrainingDays"
                    width="100%"
                    height="40px"
                    variant="dark"
                    value={`${assignment.numberOfDays}`}
                    placeholderText={t('enterDayslong', {
                      ns: 'onsiteTraining'
                    })}
                    onChangeValue={(val: any) => {
                      setAssignment({ ...assignment, numberOfDays: val });
                    }}
                    isTypeNumber
                    isNumber
                    minNumericValue={1}
                    maxLength={3}
                    maxNumericValue={999}
                    allowEmptyNumber={false}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <AssignmentModalBottomLabel>
                  {`${t('select', { ns: 'common' })} ${
                    assignment.numberOfDays
                  } ${t('daysFor', { ns: 'common' })} ${
                    onsiteTraining.name
                  }`}{' '}
                  <span className="text-danger">*</span>
                  <InfoBubble
                    text={t('selectDays', { ns: 'onsiteTraining' })}
                  />
                </AssignmentModalBottomLabel>
                <div>
                  <MultiDatesPicker
                    variant="dark"
                    selectedDates={[]}
                    maxDatesSelected={assignment.numberOfDays}
                    onDatesUpdated={(dates: Date[]) => {
                      setAssignment({ ...assignment, classesDatesUtc: dates });
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <AssignmentModalBottomLabel>
                  {t('selectStartTime', { ns: 'onsiteTraining' })}{' '}
                  <span className="text-danger">*</span>
                </AssignmentModalBottomLabel>
                <div>
                  <MultiTimePicker
                    variant="dark"
                    selectedTimes={[]}
                    onTimesSelected={(days: any) => {
                      setAssignment({ ...assignment, times: days });
                    }}
                    maxTimesSelected={assignment.numberOfDays}
                  />
                </div>
              </Col>
            </Row>
            <Row className="pt-2">
              <Col>
                <AssignmentModalBottomLabel>
                  {t('trainingLocation', { ns: 'onsiteTraining' })}{' '}
                </AssignmentModalBottomLabel>
                <div>
                  <TextBox
                    id="txtLocation"
                    value=""
                    width="100%"
                    height="40px"
                    name="txtLocation"
                    variant="dark"
                    placeholderText={t('typeLocation', {
                      ns: 'onsiteTraining'
                    })}
                    onChangeValue={(val: any) => {
                      setAssignment({ ...assignment, location: val });
                    }}
                  ></TextBox>
                </div>
              </Col>
            </Row>
          </Panel>
        </Col>
      </Row>
    </>
  );
};

export default AssignOnsiteTrainingEmployeesPanel;

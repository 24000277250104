import React, { CSSProperties } from 'react';
import MovieLineIcon from '../../assets/movie-line.svg';

const MovieIcon: React.FC = () => {
  const iconStyle: CSSProperties = {
    display: 'inline-block',
    marginRight: 8,
    width: 16,
    height: 16
  };
  return <img alt="MovieLine" src={MovieLineIcon} style={iconStyle} />;
};

export default MovieIcon;

import { CSSProperties } from 'react';

export const fontButton: CSSProperties = {
  fontSize: '15px',
  color: '#0E0D0D'
};

export const fontAddButton: CSSProperties = {
  fontSize: '20px',
  color: '#0E0D0D'
};

export const buttonStyle: CSSProperties = {
  maxWidth: '160px',
  width: 'auto',
  height: '45px'
};

export const buttonSaveStyle: CSSProperties = {
  width: '160px',
  height: '45px'
};

export const buttonLargeStyle: CSSProperties = {
  width: '216px',
  height: '45px'
};

export const buttonMediumStyle: CSSProperties = {
  width: '170px',
  height: '45px'
};

export const fontDeleteButton: CSSProperties = {
  fontSize: '15px',
  color: '#FFFFFF'
};

export const fontDeleteButtonLP: CSSProperties = {
  fontSize: '13px',
  color: '#FFFFFF'
};

export const tableButton: CSSProperties = {
  width: '86px',
  height: '34px'
};

export const cardButtons: CSSProperties = {
  maxWidth: '95px',
  width: '105px',
  height: '35px',
  padding: '10px'
};

export const rowReverse: CSSProperties = {
  justifyContent: 'center',
  padding: '20px 0'
};

export const addLanguageStyle: CSSProperties = {
  width: '100px',
  height: '35px'
};

export const buttonAddContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

import { useTranslation } from 'react-i18next';

import ModalComponent from '../../../../components/core/ModalPage/ModalComponent';
import EditEmployeeCertifications from './EditEmployeeCertifications';

interface EditEmployeeCertificationModalProps {
  employeeId: string;
  employeeFullName: string;
  onCloseModal: () => void;
}

const EditEmployeeCertificationModal = ({
  employeeId,
  employeeFullName,
  onCloseModal
}: EditEmployeeCertificationModalProps) => {
  const { t } = useTranslation(['certifications']);
  const modalTitle = t('editingEmployeeEntry', {
    employeeName: employeeFullName
  });

  return (
    <ModalComponent
      showModal={true}
      title={modalTitle}
      savingInProgress={false}
      maxWidth="md"
      showSaveButton={false}
      showCancelButton={false}
      closeWhenClickBackdrop={false}
      closeWhenPressingEscape={false}
      showDeleteButton={false}
      onClose={onCloseModal}
      saveButtonText=""
      showCloseIcon={false}
    >
      <EditEmployeeCertifications
        employeeId={employeeId}
        onClose={onCloseModal}
      />
    </ModalComponent>
  );
};

export default EditEmployeeCertificationModal;

import React from 'react';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export interface ProgressLineProps {
  value: number;
  width: any;
  color?: string;
}

const ProgressLine: React.FC<ProgressLineProps> = ({ value, width, color }) => {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey['100']
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: color ? color : theme.palette.primary.main
    }
  }));
  return (
    <BorderLinearProgress
      variant="determinate"
      value={value}
      style={{ width: width }}
    />
  );
};

export default ProgressLine;

import { createContext } from 'react';

import { TakenTimesObject } from '../entities/CompletedLessonInfo';

export interface ITakenTimes {
  takenTimesLessons?: TakenTimesObject | null;
  takenTimesCourses?: TakenTimesObject | null;
  takenTimesLearningPlans?: TakenTimesObject | null;
}

export const TakenTimesContext = createContext<ITakenTimes>({});

import { Grid, SxProps } from '@mui/material';
import { t } from 'i18next';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  actionCreators as learningPlanActions,
  selectLearningPlansByCompany
} from '../../../actions/learningPlans';
import { ApplicationState } from '../../../store';
import React from 'react';
import { generateLearningPlansToShow } from '../../../utils/contentLibraryUtils';
import { LearningPlan } from '../../../entities/LearningPlan';
import AssignLearningPlan from '../../learningPlans/AssignLearningPlan';
import { useNavigate } from 'react-router';
import { useLogEvent } from '../../../hooks/useLogEvent';
import { TakenTimesObject } from '../../../entities/CompletedLessonInfo';
import { UserNavigationContext } from '../UserNavigationContext';
import {
  ContentStatus,
  Sorting
} from '../../../hooks/useContentLibraryNavigation';
import useScrollToLastTabPosition from '../hooks/useScrollToLastTabPosition';
import ContentLibraryVirtualList from '../../../components/ContentLibraryVirtualList/ContentLibraryVirtualList';
import LearningPlanVirtualRow from './LearningPlanVirtualRow';
import ContentIndicators from '../common/ContentIndicators';
import { secondsToHHMMSS } from '../../../utils/dateUtils';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../../core/constants';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { assignButtonStyle } from '../../../components/LearningPlanCard/LearningPlanStyles';
import { SelfAssignButton } from '../../../components/SelfAssignButton/SelfAssignButton';
import NewLearningPlanCard from '../../../components/LearningPlanCard/NewLearningPlanCard';
import { useAppDispatch } from '../../../store/hooks';

interface CompanyLearningPlanContentLibraryProps {
  filterContent: string;
  filterOrder: string;
  searchFilter: string;
  refresh: number;
  companyId: string;
  userCompanyId: string;
  takenTimesLearningPlans?: TakenTimesObject | null;
}

export const CompanyLearningPlanContentLibrary = ({
  filterContent,
  filterOrder,
  searchFilter,
  refresh,
  companyId,
  userCompanyId,
  takenTimesLearningPlans
}: CompanyLearningPlanContentLibraryProps) => {
  //All, published or drafted Courses
  const [learningPlansToShow, setLearningPlansToShow] = useState<
    LearningPlan[]
  >([]);
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigationApi = userNavigationState?.api;

  const navigate = useNavigate();
  const logEvent = useLogEvent();

  const [selectedPlan, setSelectedPlan] = useState<LearningPlan>();
  const [showAssignLearningPlanModal, setShowAssignLearningPlanModal] =
    React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const isLoading = useSelector(
    (state: ApplicationState) => state.learningPlans?.isLoading
  );
  const companyLearningPlans = useSelector((state: ApplicationState) =>
    selectLearningPlansByCompany(state, companyId)
  );

  useEffect(() => {
    userNavigationApi?.changeSearch(searchFilter);

    if (companyLearningPlans && companyLearningPlans.length) {
      const learningPlans = generateLearningPlansToShow(
        companyLearningPlans,
        searchFilter,
        filterContent as ContentStatus,
        filterOrder as Sorting
      );
      setLearningPlansToShow(learningPlans);
    }
  }, [companyLearningPlans, filterOrder, filterContent, searchFilter]);

  useScrollToLastTabPosition(learningPlansToShow !== undefined && !isLoading);

  const handleAssign = (learningPlan: LearningPlan) => {
    setSelectedPlan(learningPlan);
    setShowAssignLearningPlanModal(true);
  };

  const handleCloseAssignmodal = () => {
    setShowAssignLearningPlanModal(false);
    dispatch(learningPlanActions.setCurrentLearningPlan(null));
    setSelectedPlan(undefined);
  };

  const handleStartLearningPlan = (
    learningPlan: LearningPlan,
    isPreviewMode?: boolean
  ) => {
    dispatch(learningPlanActions.setCurrentLearningPlan(learningPlan));
    logEvent.logStartLearningPlanEvent(learningPlan.id, learningPlan.name);
    navigate(`/learning-plans/preview/${learningPlan.id}`, {
      state: {
        isPreviewMode
      }
    });
  };
  const handleEditLearningPlan = (learningPlan: LearningPlan) => {
    dispatch(learningPlanActions.requestPlan(learningPlan.id));
    navigate(`/content-library/learning-plans/${learningPlan.id}/edit`, {
      state: {
        isEditing: true
      }
    });
  };

  return (
    <Grid item xs={12} sx={learningPlansContainer}>
      <ContentIndicators
        isLoading={isLoading ?? false}
        totalItems={companyLearningPlans?.length ?? 0}
        noContentMessage={t('noLearningPlansAvailableMsg', {
          ns: 'learningPlans'
        })}
      />
      <ContentLibraryVirtualList
        items={learningPlansToShow}
        learningPlanUnit={'learningPlans'}
        isLoading={isLoading ?? false}
        itemSize={375}
      >
        {({ data, index, style }) => (
          <LearningPlanVirtualRow index={index} style={style} data={data}>
            {learningPlan => (
              <NewLearningPlanCard
                key={learningPlan.id}
                averageLessonTime={
                  learningPlan.avgLessonTime
                    ? secondsToHHMMSS(learningPlan.avgLessonTime)
                    : '00:00:00'
                }
                isDraft={learningPlan.isDraft}
                learningPlan={learningPlan}
                showEdit={learningPlan.companyId === userCompanyId}
                takenTimes={
                  takenTimesLearningPlans
                    ? takenTimesLearningPlans[learningPlan.id]
                    : undefined
                }
                onEdit={() => handleEditLearningPlan(learningPlan)}
                onStartPreviewMode={(learningPlan: LearningPlan) => {
                  handleStartLearningPlan(learningPlan, true);
                }}
                wrapperWidth="100%"
              >
                {!learningPlan.isDraft && (
                  <div style={buttonSection}>
                    <ProtectedComponent
                      action={appPermissions.ASSIGN_LEARNING_PLANS}
                    >
                      <BasicButton
                        onClick={() => {
                          handleAssign(learningPlan);
                        }}
                        color="primary"
                        style={assignButtonStyle}
                      >
                        {t('assign', { ns: 'common' })}
                      </BasicButton>
                    </ProtectedComponent>
                    <SelfAssignButton
                      type="Learning Plan"
                      id={learningPlan.id}
                      companyId={learningPlan.companyId}
                      title={learningPlan.name}
                      style={assignButtonStyle}
                      width="105px"
                      height="35px"
                      color="grayButton"
                    />
                  </div>
                )}
              </NewLearningPlanCard>
            )}
          </LearningPlanVirtualRow>
        )}
      </ContentLibraryVirtualList>
      {showAssignLearningPlanModal && selectedPlan && (
        <AssignLearningPlan
          show={showAssignLearningPlanModal}
          learningPlan={selectedPlan}
          onClose={handleCloseAssignmodal}
        />
      )}
    </Grid>
  );
};

const learningPlansContainer: SxProps = {
  paddingTop: '2rem'
};

const buttonSection: CSSProperties = {
  display: 'flex',
  flexDirection: 'row' as const,
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '8px'
};

import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { OnsiteTrainingAttachment } from '../../../entities/OnsiteTraining';
import DownloadIcon from '@mui/icons-material/Download';

export const OnsiteTrainingDetailsAttachments = ({
  attachments
}: {
  attachments: Array<OnsiteTrainingAttachment>;
}) => {
  return (
    <Grid
      container
      display={'flex'}
      style={{ padding: '16px 20px 0px 20px' }}
      gap={1}
    >
      {attachments &&
        attachments.map((attachment: any) => (
          <div key={attachment.id}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              sx={{
                width: 110,
                height: 32,
                background: '#484848',
                borderRadius: 16
              }}
            >
              <Tooltip title={attachment.fileName}>
                <span
                  style={{
                    color: 'white',
                    fontSize: 14,
                    marginLeft: 12
                  }}
                >
                  {attachment.fileName.substring(0, 7) + '...'}
                </span>
              </Tooltip>
              <a
                href={attachment.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton aria-label="download" size="large" color="info">
                  <DownloadIcon fontSize="small" sx={{ color: '#fff' }} />
                </IconButton>
              </a>
            </Box>
          </div>
        ))}
    </Grid>
  );
};

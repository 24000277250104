import { CSSProperties } from 'react';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import BasicButton from '../core/BasicButton/BasicButton';
import { appPermissions } from '../../core/constants';
import { Lesson } from '../../entities/Lesson';
import { SelfAssignButton } from '../SelfAssignButton/SelfAssignButton';
import { useMatch } from 'react-router';

export interface LessonCardActionsProps {
  lesson: Lesson;
  onEdit?: (lessonId: string) => void;
  onStart?: (lesson: Lesson) => void;
  onAssign?: (lessonId: string) => void;
  isComplete?: boolean;
  isPreviewMode?: boolean;
}

const LessonCardActions = ({
  lesson,
  onEdit,
  onStart,
  onAssign,
  isComplete,
  isPreviewMode
}: LessonCardActionsProps) => {
  const { t } = useTranslation(['common']);
  const isCourseDetailsPath = useMatch('/courses/:id/revision/:revisionNumber');
  const isCoursePlayPath = useMatch('/courses/assignment/play/:id');

  const handleStart = () => {
    if (onStart) {
      onStart(lesson);
    }
  };

  const handleAssign = () => {
    if (onAssign) {
      onAssign(lesson.id);
    }
  };

  const getButtonKeyText = () => {
    if (isPreviewMode) {
      return 'preview';
    }

    if (isComplete) {
      return 'revisit';
    }

    return 'start';
  };

  return (
    <Grid container sx={divContainerStyle} id="lessonCardActionsContainer">
      {!isCourseDetailsPath && !isCoursePlayPath && (
        <div className="assignButtonContainer">
          <ProtectedComponent action={appPermissions.ASSIGN_LESSON}>
            <BasicButton
              style={buttonStyle}
              width="105px"
              height="35px"
              color="primary"
              onClick={handleAssign}
            >
              {t('assign', { ns: 'common' })}
            </BasicButton>
          </ProtectedComponent>
        </div>
      )}
      {!isCourseDetailsPath && !isCoursePlayPath ? (
        <SelfAssignButton
          type="Lesson"
          style={{ ...buttonStyle }}
          id={lesson.id}
          companyId={lesson.companyId}
          title={lesson.title}
          width="105px"
          height="35px"
          color="grayButton"
        />
      ) : (
        <BasicButton
          style={{ ...buttonStyle }}
          width="105px"
          height="35px"
          color={isPreviewMode || !isComplete ? 'primary' : 'revisit'}
          onClick={handleStart}
        >
          {t(getButtonKeyText(), { ns: 'common' })}
        </BasicButton>
      )}
    </Grid>
  );
};

const buttonStyle: CSSProperties = {
  minWidth: '0',
  display: 'block'
};

const divContainerStyle: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  gap: '8px',
  justifyContent: 'flex-end',
  paddingRight: '18px',
  paddingBottom: '8px'
};

export default LessonCardActions;

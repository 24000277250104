import { CSSProperties, useState } from 'react';
import VolumeUp from '../../../../assets/volumeUp.svg';

interface VolumeControlProps {
  volumeOpen: boolean;
  toggleVolume: () => void;
  handleVolumeChange: (e: React.ChangeEvent) => void;
  volume: number;
}

const VolumeControl: React.FC<VolumeControlProps> = ({
  volumeOpen,
  handleVolumeChange,
  toggleVolume,
  volume
}) => {
  const [enableVolumeSlider, setEnableVolumeSlider] = useState<boolean>(false);

  const onMouseOver = () => {
    setEnableVolumeSlider(true);
  };
  const onMouseLeave = () => {
    setEnableVolumeSlider(false);
  };

  return (
    <div
      className={`d-flex align-items-center videoProgress ${
        volumeOpen ? 'volume' : ''
      }`}
    >
      <div
        className="volume-control"
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      >
        <img
          src={VolumeUp}
          alt=""
          onClick={() => toggleVolume()}
          style={{ position: 'relative', cursor: 'pointer' }}
        />
        {volumeOpen && enableVolumeSlider && (
          <input
            style={{ ...inputVolume, transform: 'rotate(270deg)' }}
            className="slider"
            type="range"
            min={0}
            max={1}
            step="any"
            value={volume}
            onChange={handleVolumeChange}
          />
        )}
      </div>
    </div>
  );
};

const inputVolume: CSSProperties = {
  width: '120px',
  height: '8px',
  backgroundColor: '#f0f0f0',
  cursor: 'pointer',
  position: 'absolute',
  marginLeft: '-70px',
  marginTop: '-62px'
};

export default VolumeControl;

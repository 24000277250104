import React, { CSSProperties, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import ProgressLine from '../../components/ProgressLine/ProgressLine';
import { useSelector } from 'react-redux';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import BackwardIconGray from '../../assets/backButtonGray.svg';
import { ApplicationState } from '../../store';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import { actionCreators as studentActions } from '../../actions/student';
import ProgressIcon from '../../components/core/ProgressIcon/ProgressIcon';
import { useTranslation } from 'react-i18next';
import { useLogEvent } from '../../hooks/useLogEvent';
import CourseCardListNew from '../../components/CourseCardList/CourseCardListNew';
import { Course } from '../../entities/Course';
import { Box, Typography } from '@mui/material';
import RichTextInput from '../../components/core/RichTextInput/RichTextInput';
import PreviewModeBanner from '../../components/PreviewMode/PreviewModeBanner';
import { EntityTypeEnum } from '../../core/enums';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import MovieIcon from '../../components/Icons/MovieIcon';
import { useAppDispatch } from '../../store/hooks';

interface ProgressAndActionButtonsProps {
  course: Course;
  cardAuxiliarFunction?: (id?: string) => void;
  isComplete: boolean;
  isPreviewMode: boolean;
}

const ProgressAndActionButtons: React.FC<ProgressAndActionButtonsProps> = ({
  course,
  cardAuxiliarFunction,
  isComplete,
  isPreviewMode
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common']);
  const logEvent = useLogEvent();

  const buttonSection: CSSProperties = {
    width: '100%',
    height: '20%',
    display: 'flex',
    flexDirection: 'row' as const,
    justifyContent: 'flex-end',
    alignItems: 'end'
  };

  const boxStyle: CSSProperties = {
    marginLeft: '10px'
  };

  return (
    <div style={buttonSection}>
      <Box style={boxStyle}>
        <BasicButton
          width="90px"
          height="30px"
          color={isPreviewMode ? 'primary' : isComplete ? 'revisit' : 'primary'}
          style={startButtonStyle}
          onClick={() => {
            navigate(
              `/courses/${course.id}/revision/${course.revisionNumber}`,
              {
                state: {
                  isPreviewMode
                }
              }
            );

            logEvent.logStartCourseEvent(
              course.id,
              course.revisionNumber,
              course.title
            );
          }}
        >
          {isPreviewMode
            ? t('preview', { ns: 'common' })
            : isComplete
              ? t('revisit', { ns: 'common' })
              : t('start', { ns: 'common' })}
        </BasicButton>
      </Box>
    </div>
  );
};

const LearningPlanPreview: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string; assignmentId: string }>();
  const theme = useTheme();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const dispatch = useAppDispatch();
  const isPreviewMode = location?.state?.isPreviewMode;

  const currentLearningPlan = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlan
  );
  const learningPlanCourses = useSelector(
    (state: ApplicationState) => state.learningPlans?.currentLearningPlanCourses
  );
  const completedCourses = useSelector(
    (state: ApplicationState) => state.student?.completedCourses
  );
  const completedCoursesInfo = useSelector(
    (state: ApplicationState) => state.student?.completedCoursesBasicInfo
  );
  const studentIsLoading = useSelector(
    (state: ApplicationState) => state.student?.isLoading
  );
  const appIsLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );

  const { t } = useTranslation(['common', 'lessons', 'courses', 'assignments']);

  useEffect(() => {
    if (currentLearningPlan?.entries) {
      if (location.state.assignmentId) {
        dispatch(
          learningPlanActions.getCoursesByLearningPlanAssignment(
            location.state.assignmentId
          )
        );
      } else {
        const entryCourses = currentLearningPlan.entries.filter(
          entry => !!entry.courseId.length
        );
        const entries = entryCourses.map(entry => entry.courseId);
        const courseIds = entries.join(',');

        dispatch(
          learningPlanActions.getLearningPlanCourses(
            courseIds,
            true,
            entryCourses
          )
        );
      }
      dispatch(
        studentActions.getCompletedCoursesByLearningPlanId(
          currentLearningPlan.id,
          currentLearningPlan.revisionNumber
        )
      );
      dispatch(studentActions.getCompletedCoursesBasicInfo());
    }
  }, [currentLearningPlan]);

  useEffect(() => {
    if (!currentLearningPlan && params && params.id) {
      dispatch(learningPlanActions.requestPlan(params.id));
    }

    return () => {
      dispatch(studentActions.setCompletedCourses([]));
    };
  }, []);

  useEffect(() => {
    dispatch(studentActions.getTakenTimesCourses(employeeId));
  }, [employeeId]);

  const backToPreviousPage = () => {
    navigate(-1);
  };

  const coursesCountStyle: CSSProperties = {
    fontSize: '11px',
    color: theme.palette.grey[500]
  };

  const descriptionStyle: CSSProperties = {
    color: theme.palette.grey[500],
    paddingBottom: '2rem'
  };

  const titleStyle: CSSProperties = {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.15px'
  };

  const containerStyle: CSSProperties = {
    color: '#999',
    backgroundColor: '#000',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundBlendMode: 'luminosity',
    backgroundSize: 'contain',
    paddingBottom: 30
  };

  return (
    <PageContainer style={containerStyle}>
      {isPreviewMode && currentLearningPlan && !currentLearningPlan.isDraft && (
        <PreviewModeBanner
          contentToAssign={currentLearningPlan}
          contentType={EntityTypeEnum.LearningPlan}
        />
      )}
      <div className="d-flex flex-row">
        <div className="pt-1">
          <i
            className="fas fa-sm fa-fw mr-2 text-gray-400"
            style={BackwardIconStyle}
            onClick={backToPreviousPage}
          />
        </div>
        <div className="ps-4 w-100">
          <Grid container>
            <Grid item xs={12} lg={8} className="pb-2">
              <Typography style={titleStyle}>
                {currentLearningPlan?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} lg={8} className="pb-4">
              <MovieIcon />
              <span style={coursesCountStyle}>
                {` ${currentLearningPlan?.courseCount ?? ''} ${t('courses', {
                  ns: 'courses'
                })}`}
              </span>
            </Grid>
            <Grid item xs={12} lg={8} sx={descriptionStyle}>
              <RichTextInput
                value={
                  currentLearningPlan?.description
                    ? currentLearningPlan?.description
                    : ''
                }
                disabled={true}
              />
            </Grid>
            {!isPreviewMode && (
              <Grid
                item
                xs={12}
                lg={8}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingBottom: '0.5rem',
                  color: theme.palette.grey[500]
                }}
              >
                <span>{t('progress', { ns: 'common' })}</span>
                <span>{`${
                  !studentIsLoading ? completedCourses?.length : '?'
                } / ${learningPlanCourses?.length ?? '0'} ${t('courses', {
                  ns: 'courses'
                })}`}</span>
              </Grid>
            )}
            {!isPreviewMode && (
              <Grid item xs={12} lg={8}>
                {(appIsLoading || studentIsLoading) && <LinearProgress />}
                {!appIsLoading &&
                  !studentIsLoading &&
                  learningPlanCourses &&
                  completedCourses && (
                    <ProgressLine
                      color={'#44d47a'}
                      value={
                        (completedCourses.length / learningPlanCourses.length) *
                        100
                      }
                      width="100%"
                    />
                  )}
              </Grid>
            )}
            <Grid item xs={12} className="pt-5 pb-2">
              <h4>{t('courses', { ns: 'courses' })}</h4>
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                columns={{ xs: 1, md: 12, lg: 12 }}
                sx={coursesContainerStyle}
              >
                {learningPlanCourses && !appIsLoading && !studentIsLoading ? (
                  <CourseCardListNew
                    takenTimesCourses={takenTimesCourses}
                    isPreviewMode={isPreviewMode}
                    completedCourses={completedCoursesInfo ?? []}
                    courses={learningPlanCourses || []}
                    shouldShowDrafCourses={true}
                    cardComplement={ProgressAndActionButtons}
                  />
                ) : (
                  <ProgressIcon />
                )}
                {!appIsLoading &&
                  !studentIsLoading &&
                  learningPlanCourses &&
                  !learningPlanCourses.length && (
                    <Grid item>
                      <span>{t('noCourses', { ns: 'courses' })}</span>
                    </Grid>
                  )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </PageContainer>
  );
};

const BackwardIconStyle: CSSProperties = {
  display: 'inline-block',
  width: 30,
  height: 30,
  cursor: 'pointer',
  backgroundImage: `url(${BackwardIconGray})`
};

const startButtonStyle: CSSProperties = {
  height: '34px',
  width: '108px',
  fontSize: '15px'
};

const coursesContainerStyle: SxProps = {
  paddingTop: '2rem',
  paddingLeft: '2rem'
};

export default LearningPlanPreview;

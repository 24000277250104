import React, { CSSProperties } from 'react';
import { Course } from '../../entities/Course';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ProtectedComponent from '../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../core/constants';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import BlackCheck from '../../assets/blackcheck.svg';
import { Theme, useTheme } from '@mui/material';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MovieIcon from '../Icons/MovieIcon';

export interface ICouseCardProps {
  course: Course;
  width: string;
  height: string;
  topTitle?: string;
  children?: React.ReactNode;
  showEdit?: boolean;
  isComplete?: boolean;
  dueDate?: Date;
}

const escapeProps = ['thumbnail', 'width', 'height', 'isComplete', 'theme'];

const CourseCardWrapper = styled('div', {
  shouldForwardProp: prop => !escapeProps.includes(prop as string)
})<{
  thumbnail: string;
  width: string;
  height: string;
  isComplete: boolean;
  theme: Theme;
}>(props => ({
  background: `linear-gradient(90deg, #0E0D0D 1.9%, rgba(0, 0, 0, 0) 72.76%), linear-gradient(0deg, #1B1C1C 0%, #1B1C1C 8.35%, rgba(27, 28, 28, 0) 107.84%), url('${props.thumbnail}')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '14px',
  borderColor: props.isComplete
    ? props.theme.palette.success.light
    : props.theme.palette.grey[100],
  borderStyle: 'solid',
  borderWidth: '1px',
  display: 'flex',
  minWidth: '200px',
  minHeight: '200px',
  flexDirection: 'column' as const,
  paddingTop: '15px',
  paddingBottom: '15px',
  paddingLeft: '20px',
  paddingRight: '20px',
  width: props.width,
  height: props.height,
  position: 'relative'
}));

const CompleteCardHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-1px',
  background: '#2ED47A',
  color: '#0E0D0D',
  width: '117px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem'
});

const OverdueCardHeaderComponent = styled('div')({
  position: 'absolute',
  right: '-1px',
  background: '#FF2A20',
  color: '#0E0D0D',
  width: '117px',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px',
  minHeight: '38px',
  paddingTop: '0.5rem'
});

const CompletedCourseHeader = ({ label }: { label: string }) => (
  <CompleteCardHeaderComponent>
    <img
      alt="BlackCheck"
      src={BlackCheck}
      style={{ width: '10px', margin: '-3px 4px 0px' }}
    />
    {label}
  </CompleteCardHeaderComponent>
);

const OverdueCourseHeader = ({ label }: { label: string }) => (
  <OverdueCardHeaderComponent>
    <span style={overdueSpanStyle}>{label}</span>
  </OverdueCardHeaderComponent>
);

const CourseCard: React.FC<ICouseCardProps> = ({
  course,
  width,
  height,
  topTitle = 'COURSE',
  showEdit = true,
  children,
  isComplete = false,
  dueDate
}) => {
  const thumbnail = course.thumbnailUrl
    ? course.thumbnailUrl
    : BackgroundCoursePattern;

  const navigate = useNavigate();
  const theme = useTheme();

  const today = moment();
  const pastDueDate = moment(dueDate);
  const { t } = useTranslation(['common', 'assignments', 'courses', 'lessons']);
  const editStyleForCompleted = isComplete
    ? { ...editStyle, marginTop: '25px' }
    : editStyle;

  return (
    <CourseCardWrapper
      thumbnail={thumbnail}
      width={width}
      height={height}
      isComplete={isComplete}
      theme={theme}
    >
      {isComplete && (
        <CompletedCourseHeader label={t('completed', { ns: 'assignments' })} />
      )}
      {!isComplete && pastDueDate.isBefore(today, 'day') && (
        <OverdueCourseHeader label={t('overdue', { ns: 'assignments' })} />
      )}
      <div style={courseCardContainerStyle}>
        <div style={InfoSection}>
          <span style={labelStyle}>
            {topTitle === 'COURSE'
              ? t('course', { ns: 'courses' }).toUpperCase()
              : topTitle}
          </span>
          <div style={cardTitle} className="breaking-text">
            {course.title}
          </div>
          <div>
            <MovieIcon />
            <span style={spanStyle}>
              {course.totalLessonCount}{' '}
              {course.totalLessonCount === 1
                ? t('Lesson', { ns: 'lessons' })
                : t('Lessons', { ns: 'lessons' })}
            </span>
          </div>
        </div>
        <div style={EditSection}>
          <ProtectedComponent action={appPermissions.EDIT_COURSE}>
            {showEdit && (
              <Button
                style={editStyleForCompleted}
                onClick={() => {
                  navigate(`/courses/${course.id}/edit`);
                }}
              >
                {t('edit', { ns: 'common' })}{' '}
                <FontAwesomeIcon icon={faPen} style={styleIcon} />
              </Button>
            )}
          </ProtectedComponent>
        </div>
      </div>
      {children}
    </CourseCardWrapper>
  );
};

const courseCardContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  height: '100%'
};

const InfoSection: CSSProperties = {
  display: 'flex',
  width: '100%',
  flexDirection: 'column' as const
};

const EditSection: CSSProperties = {
  display: 'flex',
  flexFlow: 'wrap',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: '20%'
};

const labelStyle: CSSProperties = {
  color: '#c4c4c4',
  fontSize: '12px'
};

const cardTitle: CSSProperties = {
  color: '#fff',
  fontSize: '20px',
  fontWeight: '600',
  marginBottom: '10px'
};

const editStyle: CSSProperties = {
  backgroundColor: 'transparent',
  borderColor: 'transparent',
  textDecoration: 'underline',
  height: '30px',
  padding: '0'
};

const styleIcon: CSSProperties = {
  marginLeft: '3px',
  fontSize: '12px'
};

const spanStyle: CSSProperties = {
  fontSize: '14px'
};

const overdueSpanStyle: CSSProperties = {
  fontSize: '17px'
};

export default CourseCard;

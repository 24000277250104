import { CSSProperties, useRef, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTheme, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Grid, Tooltip } from '@mui/material';
import TrashIcon from '../../../assets/trashIcon.svg';
import UploadIcon from '../../../assets/uploadIcon.svg';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import {
  CompetenceExpirationPut,
  EmployeeAnalyticsCompetence,
  EmployeeAttachmentPost,
  EmployeeCompetenceAttachment
} from '../../../entities/Achievements';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { uploadSkillAttachmentToBlob } from '../../../services/blobStorage-service';
import { enqueueSnackbar } from 'notistack';
import { DatePickerButton } from '../../../components/core/DatePickerButton/DatePickerButton';
import moment from 'moment';
import { useAppDispatch } from '../../../store/hooks';
import CustomDataGrid from '../../../components/CustomDataGrid/CustomDataGrid';

interface EmployeeSkillCertTableProps {
  data?: EmployeeAnalyticsCompetence[] | null;
  onlyCertificates: boolean;
}

const EmployeeSkillCertTable = ({
  data,
  onlyCertificates
}: EmployeeSkillCertTableProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'analytics', 'skills']);
  const [selectEmployee, setSelectEmployee] =
    useState<EmployeeAnalyticsCompetence | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const loadingEmployeeInfo = useSelector(
    (state: ApplicationState) => state?.achievements?.loadingEmployeeInfo
  );

  const colorMap = {
    Current: theme.palette.success.main,
    Expired: theme.palette.error.dark,
    Incomplete: theme.palette.text.primary,
    ExpiresSoon: theme.palette.primary.main
  };

  const deleteSkillAwarded = (id: string) => {
    dispatch(achievementActions.deleteEmployeeFromCompetenceAnalytics(id));
  };
  const updateExpirationDate = (id: string, value: Date) => {
    const dateToUtc = moment(value).utc().toDate();
    const updateExpirationDateDto: CompetenceExpirationPut = {
      employeeCompetenceId: id,
      expirationDate: dateToUtc
    };
    dispatch(
      achievementActions.updateExpirationCompetence(updateExpirationDateDto)
    );
  };

  const deleteFileAttachment = (
    empCompetenceAttch: EmployeeCompetenceAttachment,
    elementId: string
  ) => {
    dispatch(
      achievementActions.deleteEmployeeAnalyticsAttachment(
        empCompetenceAttch,
        elementId
      )
    );
  };

  const upload = (employee: EmployeeAnalyticsCompetence) => {
    fileInputRef?.current?.click();
    setSelectEmployee(employee);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target?.files?.[0];

    if (!file || !selectEmployee) {
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    const fileType = file.type;

    try {
      const url = await uploadSkillAttachmentToBlob(file);
      const data: EmployeeAttachmentPost = {
        employeeCompeteceId: selectEmployee.id,
        employeeId: selectEmployee.employeeId,
        attachment: {
          fileExtension,
          fileType,
          url,
          name: fileName
        }
      };

      if (url) {
        dispatch(achievementActions.createEmployeeAnalyticsAttachment(data));
      }
    } catch (e) {
      enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'skillName',
      headerName: onlyCertificates
        ? t('certificationName', { ns: 'certifications' })
        : t('skillCertName', { ns: 'skills' }),
      width: 250,
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span>{params.row.name}</span>
      )
    },
    {
      field: 'attachments',
      headerName: t('attachments', { ns: 'skills' }),
      minWidth: 600,
      sortable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <Grid container spacing={2}>
          <Grid
            item
            xs={11}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <div
              style={{ flexWrap: 'wrap', gap: 6 }}
              className="d-flex ps-1 pt-2 pb-2"
            >
              {params.row.employeeCompetenceAttachments.map((att: any) => (
                <div key={att.id}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-around"
                    sx={{
                      width: 'fit-content',
                      height: 32,
                      background: '#484848',
                      borderRadius: 16,
                      margin: '5px'
                    }}
                  >
                    <Tooltip title={att.competenceFile.name ?? ''}>
                      <span
                        style={{ color: 'white', fontSize: 14, marginLeft: 12 }}
                      >
                        {att.competenceFile.name.substring(0, 10) + '...' ?? ''}
                      </span>
                    </Tooltip>
                    <div style={buttonsAttachments}>
                      <a
                        href={att.competenceFile.url ?? ''}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DownloadIcon
                          fontSize="small"
                          sx={{
                            color: '#999999',
                            marginRight:
                              params.row.status === 'Incomplete'
                                ? '10px'
                                : '-5px',
                            marginLeft: '10px'
                          }}
                        />
                      </a>
                      {params.row.status !== 'Incomplete' && (
                        <IconButton
                          aria-label="buttons"
                          size="large"
                          color="info"
                          onClick={() =>
                            deleteFileAttachment(att, params.row.id)
                          }
                        >
                          <DeleteIcon
                            fontSize="small"
                            sx={{ color: '#999999' }}
                          />
                        </IconButton>
                      )}
                    </div>
                  </Box>
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={1} style={attachmentsDeleteStyle}>
            {params.row.status !== 'Incomplete' && (
              <>
                <IconButton
                  aria-label="buttons"
                  size="large"
                  color="info"
                  style={circularButton}
                  onClick={() => upload(params.row)}
                >
                  <img
                    src={UploadIcon}
                    style={{
                      width: 70,
                      height: 70
                    }}
                    alt="Icon for Upload element"
                  />
                </IconButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={e => handleFileChange(e)}
                />
              </>
            )}
          </Grid>
        </Grid>
      )
    },
    {
      field: 'status',
      headerName: t('status', { ns: 'common' }),
      width: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <span
          style={{ color: colorMap[params.row.status as keyof typeof data] }}
        >
          {params.row.status}
        </span>
      )
    },
    {
      field: 'validTill',
      headerName: t('validTill', { ns: 'skills' }),
      width: 200,
      sortable: true,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div style={cellButtonsStyle}>
          {params.row.status === 'Incomplete' ? (
            <span
              style={{
                color: colorMap[params.row.status as keyof typeof data]
              }}
            >
              {t('na', { ns: 'skills' })}
            </span>
          ) : (
            <>
              {params.row.expirationDate === undefined ? (
                <span
                  style={{
                    color: colorMap[params.row.status as keyof typeof data]
                  }}
                >
                  {t('neverExpire', { ns: 'skills' })}
                </span>
              ) : (
                <span
                  style={{
                    color: colorMap[params.row.status as keyof typeof data]
                  }}
                >
                  {absUtcDateToLocalMoment(params.row.expirationDate).format(
                    'MM.DD.YYYY'
                  )}
                </span>
              )}
              <span>
                <DatePickerButton
                  disabled={
                    params.row.status === 'Incomplete' ||
                    params.row.expirationDate === undefined
                  }
                  onChange={value => updateExpirationDate(params.row.id, value)}
                  currentExpirationDate={
                    params.row.expirationDate
                      ? absUtcDateToLocalMoment(
                          params.row.expirationDate
                        ).toDate()
                      : new Date()
                  }
                />
              </span>
            </>
          )}
        </div>
      )
    },
    {
      field: 'buttons',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>
          {params.row.status !== 'Incomplete' && (
            <IconButton
              aria-label="buttons"
              size="large"
              color="info"
              style={circularButton}
              onClick={() => deleteSkillAwarded(params.row.id)}
            >
              <img
                src={TrashIcon}
                style={{
                  width: 70,
                  height: 70
                }}
                alt="Icon for Skill"
              />
            </IconButton>
          )}
        </>
      )
    }
  ];

  let rowCounter = 0;

  return (
    <Box
      sx={{
        height: '60vh',
        width: '100%'
      }}
    >
      {loadingEmployeeInfo && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {data && !!data.length && (
        <ModifiedCustomDataGrid
          getRowId={data => data.id}
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          getRowClassName={() => {
            rowCounter++;
            return rowCounter % 2 !== 0 ? 'row-odd-style' : 'row-peer-style';
          }}
          rowSelection={false}
          disableColumnMenu
          slots={{
            columnSortedDescendingIcon: ColumnSortedDescendingIcon,
            columnSortedAscendingIcon: ColumnSortedAscendingIcon
          }}
        />
      )}
    </Box>
  );
};

const ColumnSortedDescendingIcon = () => {
  return <ArrowDropDownIcon height="5px" width="10px" />;
};

const ColumnSortedAscendingIcon = () => {
  return <ArrowDropUpIcon height="5px" width="10px" />;
};

const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  fontSize: '16px',
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`,
    maxHeight: 'none !important',
    lineHeight: 'unset !important',
    whiteSpace: 'normal'
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.grey.A100}`,
    maxHeight: 'none !important',
    lineHeight: 'unset !important',
    whiteSpace: 'normal'
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'auto',
    overflowY: 'auto'
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: 'none !important'
  },
  '& .MuiDataGrid-row': {
    maxHeight: 'none !important'
  }
}));

const cellButtonsStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  minWidth: 180
};

const attachmentsDeleteStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignContent: 'center'
};

const circularButton: CSSProperties = {
  width: '35px',
  height: '35px',
  marginTop: '15px'
};

const buttonsAttachments: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignContent: 'center'
};

export default EmployeeSkillCertTable;

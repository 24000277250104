import { Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';

interface ModalForCloningProps {
  showModal: boolean;
  onsiteTraining?: OnsiteTraining | null;
  onCloseModal: () => void;
}

const Container = styled(Stack)(() => ({
  justifyContent: 'center',
  alignItems: 'center'
}));

const ButtonsContainer = styled(Stack)(() => ({
  justifyContent: 'center',
  paddingTop: '1.5rem'
}));

const Title = styled(Typography)(() => ({ fontSize: '24px' }));
const Message = styled(Typography)(() => ({ fontSize: '18px' }));

const ModalForCloning = ({
  showModal,
  onsiteTraining,
  onCloseModal
}: ModalForCloningProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['onsiteTraining']);
  const [isCloning, setIsCloning] = useState<boolean>(false);

  const handleCloneWithoutDelete = async () => {
    const { id } = onsiteTraining!;
    try {
      setIsCloning(true);
      const clonedOnsiteTraining = (await dispatch<Promise<OnsiteTraining>>(
        onsiteTrainingActions.cloneOnsiteTraining(id)
      )) as unknown as OnsiteTraining;
      navigate('/onsite-training/edit', {
        state: {
          onsiteTraining: clonedOnsiteTraining,
          prevPath: location.pathname
        }
      });
    } catch (e) {
      enqueueSnackbar(
        t('cloneOnsiteTrainingErrorMsg', { ns: 'onsiteTraining' }),
        { variant: 'error', autoHideDuration: 3000 }
      );
    } finally {
      setIsCloning(false);
    }
  };

  return (
    <ConfirmationModal
      show={showModal}
      showSave={false}
      showCancel={false}
      maxWidth="sm"
      onCancel={onCloseModal}
    >
      <Container spacing={1}>
        <Title>{`${t('duplicate', { ns: 'onsiteTraining' })} ${
          onsiteTraining?.name ?? ''
        }`}</Title>
        <Message>
          {t('wantToDeleteOriginalOnsiteTraining', { ns: 'onsiteTraining' })}
        </Message>
        <ButtonsContainer direction="row" spacing={3}>
          <BasicButton color="secondary" loading={isCloning}>
            {t('delete', { ns: 'onsiteTraining' })}
          </BasicButton>
          <BasicButton
            onClick={handleCloneWithoutDelete}
            color="grayButton"
            loading={isCloning}
          >
            {t('continueWithoutDeleting', { ns: 'onsiteTraining' })}
          </BasicButton>
        </ButtonsContainer>
      </Container>
    </ConfirmationModal>
  );
};

export default ModalForCloning;

import React, { useEffect, useState } from 'react';
import { Question, Answer, AssessmentResult } from '../../entities/Assessment';
import { QuestionTypes } from '../../core/constants';
import TrueFalseQuestion from '../AssessmentQuestions/TrueFalseQuestion';
import MultipleChoiceImageQuestion from '../AssessmentQuestions/MultipleChoiceImageQuestion';
import NumberQuestion from '../AssessmentQuestions/NumberQuestion';
import MultipleChoiceQuestion from '../AssessmentQuestions/MultipleChoiceQuestion';

export interface AssessmentFactoryProps {
  question: Question;
  assessmentResults: AssessmentResult;
  setAssessmentResult: (answer: Answer) => void;
}

const AssessmentFactory: React.FC<AssessmentFactoryProps> = ({
  question,
  assessmentResults,
  setAssessmentResult
}) => {
  const [answer, setAnswer] = useState<Answer | undefined>();

  useEffect(() => {
    setAnswer(
      assessmentResults.answers.find(q => q.questionId === question.id)
    );
  }, [question, assessmentResults.answers.length]);

  let questionComponent = null;

  switch (question.type) {
    case QuestionTypes.TrueFalse:
      questionComponent = (
        <TrueFalseQuestion
          question={question}
          questionResult={answer}
          setAssessmentResult={setAssessmentResult}
        />
      );
      break;
    case QuestionTypes.Number:
      questionComponent = (
        <NumberQuestion
          question={question}
          questionResult={answer}
          setAssessmentResult={setAssessmentResult}
        />
      );
      break;

    case QuestionTypes.MultipleChoiceImage:
      questionComponent = (
        <MultipleChoiceImageQuestion
          question={question}
          questionResult={answer}
          setAssessmentResult={setAssessmentResult}
        />
      );
      break;
    case QuestionTypes.MultipleChoiceText:
      questionComponent = (
        <MultipleChoiceQuestion
          question={question}
          questionResult={answer}
          setAssessmentResult={setAssessmentResult}
        />
      );
      break;
  }

  return questionComponent;
};

export default AssessmentFactory;

import { createContext } from 'react';
import {
  ContentLibraryNavigationApi,
  ContentLibraryTabState
} from '../../hooks/useContentLibraryNavigation';
interface UserNavigationState {
  state: ContentLibraryTabState;
  api: ContentLibraryNavigationApi;
  containerRef: React.RefObject<HTMLDivElement>;
}

export const UserNavigationContext = createContext<
  UserNavigationState | undefined
>(undefined);

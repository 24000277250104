import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actionCreators } from '../../actions/lessons';
import { actionCreators as actionAssesments } from '../../actions/assessments';
import { ApplicationState } from '../../store';
import LessonPlayer from './LessonPlayer';
import { useAppDispatch } from '../../store/hooks';

const LessonPlayAssignment: React.FC = () => {
  const params: any = useParams();
  const dispatch = useAppDispatch();
  const lessonPlayAssignment = useSelector(
    (state: ApplicationState) => state.lessons?.lessonPlayAssignment
  );

  const lessons = lessonPlayAssignment ? [lessonPlayAssignment!.lesson] : [];
  const lesson = lessonPlayAssignment?.lesson;

  useEffect(() => {
    if (params && params.id) {
      dispatch(actionCreators.getLessonAssignment(params.id));
    }

    return () => {
      dispatch(actionCreators.clearLessonAssignment());
    };
  }, [params]);

  useEffect(() => {
    if (lessonPlayAssignment) {
      if (lessonPlayAssignment?.lesson?.lessonType === 'Quiz') {
        const quizAssesmentId: string[] = [];
        quizAssesmentId.push(lessonPlayAssignment!.lesson!.assessmentId!);
        dispatch(actionAssesments.failedQuizzesResult(quizAssesmentId));
      }
    }
  }, [lessonPlayAssignment]);

  if (!lessonPlayAssignment) {
    return null;
  }

  return (
    <LessonPlayer
      lessons={lessons!}
      courseContext={null}
      lessonAssignments={[lessonPlayAssignment]}
      entryPointLessonId={lesson!.id}
      isPreview={false}
    />
  );
};

export default LessonPlayAssignment;

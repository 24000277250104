import { ContentSelectorView } from './types';
import { Course } from '../../entities/Course';
import React, { useContext, useEffect, useState } from 'react';
import CourseVirtualRow from '../../pages/contentLibrary/Company/CourseVirtualRow';
import ContentLibraryVirtualList from '../ContentLibraryVirtualList/ContentLibraryVirtualList';
import { useSelector } from 'react-redux';
import { SelectableCourseCard } from '../CourseCard/SelectableCourseCard';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import { SelectableContext } from './SelectContentModal';
import { SelectContentViewContainer } from './SelectContentViewContainer';
import useLearningUnitStatusFilter from '../../pages/contentLibrary/hooks/useLearningUnitStatusFilter';
import useLearningUnitSorting from '../../pages/contentLibrary/hooks/useLearningUnitSorting';
import { useDebounce } from '../../hooks/useDebounce';
import { DefaultSearchDebounceTime } from '../../core/constants';
import SearchInput from '../../pages/contentLibrary/common/SearchInput';
import ContentStatusFilter from '../../pages/contentLibrary/common/ContentStatusFilter';
import SortContentSelect from '../../pages/contentLibrary/common/SortContentSelect';
import { Stack } from '@mui/material';
import { generateCoursesToShow } from '../../utils/contentLibraryUtils';
import {
  ContentStatus,
  Sorting
} from '../../hooks/useContentLibraryNavigation';

export const SelectCourseView = ({ selector }: ContentSelectorView<Course>) => {
  const courses = useSelector(selector);
  const [filteredCourses, setFilteredCourses] = useState<Course[]>([]);
  const { onSelect, internalSelectionHash } = useContext(SelectableContext);

  const [filterLearningUnitStatus, handleChangeLearningUnitStatus] =
    useLearningUnitStatusFilter();
  const [activeSort, handleChangeSorting] = useLearningUnitSorting();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  useEffect(() => {
    if (courses && courses.length) {
      const newlyFilteredCourses = generateCoursesToShow(
        courses,
        searchTerm,
        filterLearningUnitStatus as ContentStatus,
        null,
        activeSort as Sorting,
        []
      );
      setFilteredCourses(newlyFilteredCourses);
    }
  }, [courses, activeSort, filterLearningUnitStatus, searchTerm]);

  return (
    <SelectContentViewContainer
      filtersSection={
        <Stack direction="row" spacing={2}>
          <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
          <ContentStatusFilter
            value={filterLearningUnitStatus}
            onChange={handleChangeLearningUnitStatus}
          />
          <SortContentSelect
            value={activeSort}
            onChange={handleChangeSorting}
          />
        </Stack>
      }
      contentSection={
        <ContentLibraryVirtualList
          items={filteredCourses ?? []}
          learningPlanUnit={'courses'}
          isLoading={false}
          itemSize={341}
        >
          {({ data, index, style }) => (
            <CourseVirtualRow index={index} style={style} data={data}>
              {course => (
                <SelectableCourseCard
                  selected={internalSelectionHash.has(course.id)}
                  onSelect={onSelect}
                  selectable={{
                    label: course.title,
                    id: course.id,
                    type: 'course'
                  }}
                  averageLessonTime={
                    course.avgLessonTime
                      ? secondsToHHMMSS(course.avgLessonTime)
                      : '00:00:00'
                  }
                  hasSpanishSubtitles={course.hasSpanishSubtitles}
                  course={course}
                />
              )}
            </CourseVirtualRow>
          )}
        </ContentLibraryVirtualList>
      }
    />
  );
};

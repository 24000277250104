import { Button, Grid, Stack, SxProps, useTheme } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowHeightParams,
  GridRowHeightReturnValue
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  AnalyticsContentLibraryDetail,
  AnalyticsContentLibraryDetailAssignmentInfo
} from '../../../entities/Analytics';

import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { EnrollmentTypes } from '../../../core/constants';
import { AnalyticsDataGrid } from '../../../components/Analytics';
import { StyledTooltip } from '../../../components/core/StyledTooltip/StyledTooltip';
import { CSSProperties } from 'react';
import { GreenSpan, RedSpan, OrangeSpan } from '../common/StyledSpan';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';
import { PercentageIndicator } from '../../../components/PercentageIndicator/PercentageIndicator';

interface ContentLibraryDetailTableProps {
  data?: AnalyticsContentLibraryDetail[] | null;
  learningUnitName: string;
  onEditDueDate: (assignmentId: string, dueDate: Date) => void;
}

const ContentLibraryDetailTable = ({
  data,
  learningUnitName,
  onEditDueDate
}: ContentLibraryDetailTableProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const {
    variables: { showCompletionStats }
  } = useDevCycleSdk();

  const handleSetRowHeight = (
    params: GridRowHeightParams
  ): GridRowHeightReturnValue => {
    if (params.model.assignmentsInfo.length > 1) {
      return 42 * params.model.assignmentsInfo.length;
    }

    return null;
  };

  const buttonStyle: SxProps = {
    height: '23px',
    width: '60px',
    borderRadius: '35px',
    '&.MuiButton-root': {
      backgroundColor: theme.palette.grey[50],
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: 15,
      textTransform: 'none'
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      color: theme.palette.grey[100]
    }
  };

  const disableEditButtonStyle: CSSProperties = {
    height: '23px',
    width: '60px',
    borderRadius: '35px',
    fontSize: 15,
    textTransform: 'none',
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    color: theme.palette.grey[100],
    display: 'block',
    textAlign: 'center'
  };

  const getAssignmentLevel = (
    item: AnalyticsContentLibraryDetailAssignmentInfo
  ) => {
    let assignmentLevel = 'Lesson';
    if (item.courseAssignmentId) {
      assignmentLevel = 'Course';
    } else if (item.learningPlanAssignmentId) {
      assignmentLevel = 'LP';
    }

    return `Assigned at ${assignmentLevel} level`;
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: learningUnitName,
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div className="d-flex ps-1 pt-2 pb-2">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../../assets/user.png')
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: theme.palette.grey[100] }}>
              {params.row.jobTitle}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'numberTimesCompleted',
      headerName: t('numberTimesCompleted', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <span>{params.row.numberTimesCompleted}</span>
      )
    },
    {
      field: 'assignedOn',
      headerName: t('assignedOn', { ns: 'analytics' }),
      minWidth: 200,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <Stack direction="column" spacing={2}>
          {params.row.assignmentsInfo.map(
            (item: AnalyticsContentLibraryDetailAssignmentInfo) => (
              <span>
                {absUtcDateToLocalMoment(item.assignedOn).format('MM.DD.YYYY')}
              </span>
            )
          )}
        </Stack>
      )
    },
    {
      field: 'dueDate',
      headerName: t('dueDate', { ns: 'analytics' }),
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <Stack direction="column" spacing={2}>
          {params.row.assignmentsInfo.map(
            (item: AnalyticsContentLibraryDetailAssignmentInfo) => (
              <span>
                {item.dueDate && item.assignmentType === EnrollmentTypes.DueDate
                  ? absUtcDateToLocalMoment(item.dueDate).format('MM.DD.YYYY')
                  : item.assignmentType === 'Workload'
                    ? t('workload', { ns: 'assignments' })
                    : t('noDueDate', { ns: 'assignments' })}
              </span>
            )
          )}
        </Stack>
      )
    },
    {
      field: '',
      headerName: t('completionDate', { ns: 'analytics' }),
      minWidth: 400,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <Stack direction="column" width="100%" spacing={2}>
          {params.row.assignmentsInfo.map(
            (item: AnalyticsContentLibraryDetailAssignmentInfo) => {
              const isDueDateAssignment =
                item.assignmentType === EnrollmentTypes.DueDate;

              return (
                <Grid container direction="row">
                  <Grid item xs={4}>
                    {!!item.completionDate && item.isCompletedOnTime && (
                      <GreenSpan>
                        {absUtcDateToLocalMoment(item.completionDate).format(
                          'MM.DD.YYYY'
                        )}
                      </GreenSpan>
                    )}
                    {!!item.completionDate && !item.isCompletedOnTime && (
                      <RedSpan>
                        {absUtcDateToLocalMoment(item.completionDate).format(
                          'MM.DD.YYYY'
                        )}
                      </RedSpan>
                    )}
                    {!!item.isPending && (
                      <OrangeSpan>
                        {t('pending', { ns: 'assignments' })}
                      </OrangeSpan>
                    )}
                    {!!item.isOverdue && (
                      <RedSpan>{t('overdue', { ns: 'assignments' })}</RedSpan>
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    {!!item.completionDate && item.isCompletedOnTime && (
                      <GreenSpan>
                        {t('completedOnTime', { ns: 'analytics' })}
                      </GreenSpan>
                    )}
                    {!!item.completionDate && !item.isCompletedOnTime && (
                      <RedSpan>
                        {t('completedLate', { ns: 'analytics' })}
                      </RedSpan>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {!item.editableDueDate && isDueDateAssignment && (
                      <StyledTooltip title={getAssignmentLevel(item)}>
                        <span style={disableEditButtonStyle}>
                          {t('edit', { ns: 'common' })}
                        </span>
                      </StyledTooltip>
                    )}

                    {item.editableDueDate && (
                      <Button
                        onClick={() =>
                          onEditDueDate(item.assignmentId, item.dueDate!)
                        }
                        sx={buttonStyle}
                      >
                        {item.editableDueDate && t('edit', { ns: 'common' })}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            }
          )}
        </Stack>
      )
    }
  ];

  const getDataTableCols = () => {
    if (showCompletionStats?.showCompletionStats) {
      columns.splice(2, 0, {
        field: 'percentCompleted',
        headerName: t('completion', {
          ns: 'analytics'
        }),
        minWidth: 100,
        sortable: false,
        renderCell: (params: GridRenderCellParams<any, number>) => {
          const percent = Number(params.row.percentCompleted);
          const formattedText = `${params.row.percentCompleted}%`;
          return (
            <PercentageIndicator
              percent={percent}
              formattedText={formattedText}
            />
          );
        }
      });
    }
    return columns;
  };

  return (
    <AnalyticsDataGrid
      columns={getDataTableCols()}
      rows={data}
      rowId="employeeId"
      rowHeightFunc={handleSetRowHeight}
    />
  );
};

export default ContentLibraryDetailTable;

import { LessonAttachment } from './Lesson';
import { Tag } from './Tag';

export interface LessonFormModel {
  id: string;
  title: string;
  description: string;
  contentMedium: number;
  thumbnailFile: File | null;
  thumbnailUrl: string;
  video: File | null;
  videoUrl: string;
  lessonFile: File | null;
  lessonFileUrl: string;
  audioFile: File | null;
  audioUrl: string;
  externalVideoUrl: string;
  tags: Array<Tag>;
  assessmentId?: string;
  subtitles?: Array<Subtitles>;
  youTubeVideoId?: string;
  attachments: Array<File>;
  deletedAttachments: Array<string>;
  savedAttachments: Array<LessonAttachment>;
  visibleForAdminsOnly: boolean;
  lessonDuration: string;
  lessonCode: string;
  hlsUrl?: string;
}

export interface Subtitles {
  id?: string;
  language: string;
  url?: string | null;
  subtitleFile?: File | null;
  fileType?: string | null;
}

//old enum used for check lesson type content
export const ContentMedium = {
  Video: 0,
  File: 1,
  Quiz: 3,
  FlashCard: 4,
  Audio: 6
};

export interface SubtitleEditMode extends Subtitles {
  _id?: string;
  type?: string;
}

//new lesson type enum that matches BE
export const NewLessonType = {
  Video: 1,
  Quiz: 2,
  File: 3,
  Audio: 4,
  Flashcard: 5
};

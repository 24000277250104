import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AssignCourseToEmployeeDto } from '../../entities/Course';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import { actionCreators as employeeActions } from '../../actions/employees';
import { actionCreators as courseActions } from '../../actions/courses';
import { EnrollmentTypes, UserRoles, UserClaims } from '../../core/constants';
import { TransactionStatusEnum } from '../../core/enums';
import {
  newAssignCourseToEmployeeDto,
  newCourseGroupAssignment
} from '../../reducers/CoursesReducer';
import AssignModal from '../../components/AssignModal/AssignModal';
import { useTranslation } from 'react-i18next';
import { useLogEvent } from '../../hooks/useLogEvent';
import InfoModal from '../../components/core/InfoModal/InfoModal';
import { CourseGroupAssignment } from '../../entities/Assignment';
import useUserRole from '../../hooks/useUserRole';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';

export interface AssignCourseProps {
  open: boolean;
  onClose: () => void;
}

const AssignCourse: React.FC<AssignCourseProps> = ({ open, onClose }) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const currentCourse = useSelector(
    (state: ApplicationState) => state.courses?.currentCourse
  );
  const transactionStatus = useSelector(
    (state: ApplicationState) => state.courses?.transactionStatus
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const courseAssignmentStatus = useSelector(
    (state: ApplicationState) => state?.courses?.courseAssignmentStatus
  );
  const groupAssignmentDefinitions = useSelector(
    (state: ApplicationState) => state?.courses?.groupAssignmentDefinitions
  );

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [courseAssignment, setCourseAssignment] =
    useState<AssignCourseToEmployeeDto>({ ...newAssignCourseToEmployeeDto });
  const [courseGroupAssignment, setCourseGroupAssignment] =
    useState<CourseGroupAssignment>({
      ...newCourseGroupAssignment
    });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const { t } = useTranslation(['common', 'assignments']);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);

  useEffect(() => {
    if (currentCourse && !courseAssignment.courseId.length) {
      dispatch(
        courseActions.requestStatusOfEmployeesCourseAssigments(currentCourse.id)
      );
      dispatch(
        courseActions.requestCourseAssignmentDefinitions(currentCourse.id)
      );

      setCourseAssignment({
        ...newAssignCourseToEmployeeDto,
        courseId: currentCourse.id,
        assignBy: ''
      });
      setCourseGroupAssignment({
        ...newCourseGroupAssignment,
        courseId: currentCourse.id,
        assignBy: ''
      });
    }
  }, [currentCourse]);

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }
  }, [companyId]);

  useEffect(() => {
    return () => {
      dispatch(courseActions.SetStatusOfEmployeesCourseAssigments(null));
      dispatch(courseActions.setCourseAssignmentDefinitions(null));
    };
  }, []);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionStatus === TransactionStatusEnum.Failed) {
        const message = t('anExceptionOccurred', { ns: 'common' });
        setErrorMessage(message);
        enqueueSnackbar(message, { variant: 'error' });
      } else if (transactionStatus === TransactionStatusEnum.Successfull) {
        setShowAssignmentConfirmation(true);
        setErrorMessage('');
      }
    }

    if (transactionStatus !== TransactionStatusEnum.None)
      dispatch(courseActions.resetCourseTransactionStatus());

    return () => {
      dispatch(courseActions.resetCourseTransactionStatus());
    };
  }, [transactionStatus]);

  const handleAssign = () => {
    let message = '';

    if (!courseAssignment.employeeIds.length)
      message = t('selectEmployee', { ns: 'common' });
    else if (
      !courseAssignment.assignBy ||
      (courseAssignment.assignBy === EnrollmentTypes.DueDate &&
        !courseAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });

    if (message.length) {
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    dispatch(courseActions.assignCourseToEmployees(courseAssignment));
    courseAssignment.employeeIds.forEach(employeeId => {
      logEvent.logAssignCourseEvent(courseAssignment.courseId, employeeId);
    });
  };

  const handleSaveGroupAssignment = async () => {
    let message = '';

    if (!courseGroupAssignment.groups.length) {
      message = t('mustSelectAtLeastOneGroup', { ns: 'assignments' });
    } else if (
      !courseGroupAssignment.assignBy ||
      (courseGroupAssignment.assignBy === EnrollmentTypes.DueDate &&
        !courseGroupAssignment.dueDate)
    ) {
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });
    }

    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    try {
      await dispatch<Promise<void>>(
        courseActions.saveCourseGroupAssignments(courseGroupAssignment)
      );
      setShowAssignmentConfirmation(true);
      setErrorMessage('');
      courseGroupAssignment.groups.forEach(group => {
        logEvent.logAssignCourseToGroupEvent(
          courseGroupAssignment.courseId,
          group.groupId
        );
      });
    } catch (e) {
      message = t('saveGroupAssignmentsErrorMsg', { ns: 'assignments' });
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
      setSaveInProgress(false);
    }
  };

  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };

  const setCourseAssignmentProxy = (
    value: Partial<AssignCourseToEmployeeDto>
  ) => {
    setCourseAssignment(previous => ({
      ...previous,
      ...value,
      courseId: value.courseId || previous.courseId
    }));
  };

  return (
    <>
      <AssignModal
        showModal={open}
        assignToEntity={currentCourse}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onSaveGroupAssignment={handleSaveGroupAssignment}
        onClose={onClose}
        employees={employees ?? []}
        employeeGroups={employeeGroups ?? []}
        assignment={courseAssignment}
        groupAssignment={courseGroupAssignment}
        setAssignment={setCourseAssignmentProxy}
        setGroupAssignment={setCourseGroupAssignment}
        learningUnitAssigmentStatus={courseAssignmentStatus}
        groupAssignmentDefinitions={groupAssignmentDefinitions}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignCourse;

import { FormHelperText, Typography } from '@mui/material';
import React, { CSSProperties, useMemo } from 'react';
import { SxProps } from '@mui/system';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NotificationPreferences } from '../../../entities/NotificationPreferences';
import { ApplicationState } from '../../../store';
import { actionCreators } from '../../../actions/notificationPreferences';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useUserRole from '../../../hooks/useUserRole';

import { UserClaims } from '../../../core/constants';
import { useSelector } from 'react-redux';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { UserRoles } from '../../../core/constants';
import config from '../../../config';
import { InfoBubble } from '../../../components/InfoBubble/InfoBubble';
import { enqueueSnackbar } from 'notistack';
import { TransactionStatusEnum } from '../../../core/enums';
import { actionCreators as haltActions } from '../../../actions/halts';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';
import { useAppDispatch } from '../../../store/hooks';

const defaultPreferences: NotificationPreferences = {
  sendSms: false,
  sendEmail: false,
  sendPushNotification: true,
  sendWeb: false,
  receiveNewAssignmentsNotification: false,
  receiveOverdueAssignmentsNotification: false,
  receivePendingWorkloadNotification: false,
  receiveCompleteLpNotification: false,
  receiveCompleteCourseNotification: false,
  receiveCompleteLessonNotification: false,
  receiveOverdueLpNotification: false,
  receiveOverdueCourseNotification: false,
  receiveOverdueLessonNotification: false,
  receiveInactiveUserNotification: false,
  receiveAwardedUserNotification: false,
  employeeId: ''
};

export const NotificationPreferencesTab = () => {
  const {
    variables: { skillsAndCertifications }
  } = useDevCycleSdk();
  const { t } = useTranslation([
    'common',
    'settings',
    'lessons',
    'courses',
    'learningPlans'
  ]);
  const [preferences, setPreferences] =
    React.useState<NotificationPreferences>(defaultPreferences);
  const notificationPreferences = useSelector(
    (state: ApplicationState) =>
      state.notificationPreferences?.notificationPreferences
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.appState?.isLoading
  );

  const notificationPreferencesSaved = useSelector(
    (state: ApplicationState) =>
      state.notificationPreferences?.notificationPreferencesSaved
  );

  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const dispatch = useAppDispatch();
  const [userHasRole] = useUserRole();

  React.useEffect(() => {
    if (employeeId) {
      dispatch(actionCreators.getUserPreferences(employeeId));
    }
  }, [dispatch, employeeId]);

  React.useEffect(() => {
    if (notificationPreferences) {
      setPreferences(notificationPreferences);
    }
  }, [notificationPreferences]);

  const isAdminUser = userHasRole([
    UserRoles.CompanyAdmin,
    UserRoles.GroupLead,
    config.USE_ORG_CHART ? UserRoles.Supervisor : ''
  ]);

  const isEmployee = !isAdminUser;

  const handleSave = () => {
    if (preferences) {
      if (
        !preferences.sendEmail &&
        !preferences.sendPushNotification &&
        !preferences.sendSms &&
        !preferences.sendWeb
      ) {
        enqueueSnackbar(t('atLeastOneDeliveryMethod', { ns: 'settings' }), {
          variant: 'error'
        });
        return;
      }
      dispatch(
        actionCreators.savePreferences({
          ...preferences,
          employeeId: employeeId
        })
      );
    }
  };

  useMemo(() => {
    if (notificationPreferencesSaved === TransactionStatusEnum.Successfull) {
      enqueueSnackbar(t('savedSucessfully'), {
        variant: 'success',
        autoHideDuration: 3000
      });
      dispatch(haltActions.resetHaltCreatedState());
    }
    if (notificationPreferencesSaved === TransactionStatusEnum.Failed) {
      enqueueSnackbar(t('anErrorOccurred'), {
        variant: 'error',
        autoHideDuration: 3000
      });
      dispatch(haltActions.resetHaltCreatedState());
    }
  }, [notificationPreferencesSaved]);

  return (
    <>
      {(isAdminUser || isEmployee) && (
        <>
          <Typography sx={fontTab}>
            {t('deliveryMethod', { ns: 'settings' })}
            <InfoBubble
              text={t('deliveryMethodDescription', { ns: 'settings' })}
            />
          </Typography>
          <Stack direction="row" alignItems={'start'}>
            <FormControlLabel
              control={
                <Checkbox
                  id="email"
                  name="email"
                  checked={preferences.sendEmail}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      sendEmail: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>{'Email'}</Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="push"
                  data-testid={'sendPushNotificationCheckboxTestId'}
                  name="push"
                  checked={preferences.sendPushNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      sendPushNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {'Push Notification'}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="push"
                  name="push"
                  checked={preferences.sendWeb}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      sendWeb: e.target.checked
                    })
                  }
                />
              }
              label={<Typography style={secondTitleStyle}>{'Web'}</Typography>}
            />
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    id="sms"
                    name="sms"
                    checked={preferences.sendSms}
                    sx={checkBoxStyle}
                    onChange={e =>
                      setPreferences({
                        ...preferences,
                        sendSms: e.target.checked
                      })
                    }
                  />
                }
                label={
                  <Typography style={secondTitleStyle}>{'SMS'}</Typography>
                }
              />
              {preferences.sendSms && (
                <FormHelperText style={{ maxWidth: '200px' }}>
                  {t('smsTermsOfUse', { ns: 'settings' })}{' '}
                  <a
                    href="https://info.buildwitt.com/training-mobile-terms-of-use"
                    id="text-terms-of-use"
                    target="_blank"
                    style={{ fontSize: '0.75rem' }}
                    rel="noreferrer"
                  >
                    Mobile Terms of Use
                  </a>
                </FormHelperText>
              )}
            </div>
          </Stack>
          <hr style={horSeparator} />
        </>
      )}

      {(isAdminUser || isEmployee) && (
        <>
          <Typography sx={fontTab}>
            {' '}
            {t('assignmentNotifications', { ns: 'settings' })}
            <InfoBubble
              text={t('assignmentNotificationsDescription', { ns: 'settings' })}
            />
          </Typography>
          <Stack direction="column">
            <FormControlLabel
              control={
                <Checkbox
                  id="chkNewAssignments"
                  name="chkNewAssignments"
                  checked={preferences.receiveNewAssignmentsNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveNewAssignmentsNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('receiveForNewAssignments', { ns: 'settings' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkoverdueAssignments"
                  name="chkoverdueAssignments"
                  checked={preferences.receiveOverdueAssignmentsNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveOverdueAssignmentsNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('receiveForOverdue', { ns: 'settings' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkoverdueAssignments"
                  name="chkoverdueAssignments"
                  checked={preferences.receivePendingWorkloadNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receivePendingWorkloadNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('receiveForPendingWorkload', { ns: 'settings' })}
                </Typography>
              }
            />
          </Stack>
          <hr style={horSeparator} />
        </>
      )}
      {isAdminUser && (
        <>
          <Typography sx={fontTab}>
            {t('adminNotifications', { ns: 'settings' })}
            <InfoBubble
              text={t('adminNotificationsDescription', { ns: 'settings' })}
            />
          </Typography>
          <label>
            <Typography style={secondTitleStyle}>
              {t('receiveCompleted', { ns: 'settings' })}
            </Typography>
          </label>
          <Stack direction="column">
            <FormControlLabel
              control={
                <Checkbox
                  id="chkCompleteLp"
                  name="chkCompleteLp"
                  checked={preferences.receiveCompleteLpNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveCompleteLpNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('learningPlan', { ns: 'learningPlans' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkCompleteCourse"
                  name="chkCompleteCourse"
                  checked={preferences.receiveCompleteCourseNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveCompleteCourseNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('course', { ns: 'courses' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkCompleteLesson"
                  name="chkCompleteLesson"
                  checked={preferences.receiveCompleteLessonNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveCompleteLessonNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('lesson', { ns: 'lessons' })}
                </Typography>
              }
            />
          </Stack>
          <hr style={horSeparator} />
          <label>
            {
              <Typography style={{ ...secondTitleStyle }}>
                {t('receiveOverdue', { ns: 'settings' })}
              </Typography>
            }
          </label>
          <Stack direction="column">
            <FormControlLabel
              control={
                <Checkbox
                  id="chkOverdueLp"
                  name="chkOverdueLp"
                  checked={preferences.receiveOverdueLpNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveOverdueLpNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('learningPlan', { ns: 'learningPlans' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkOverdueCourse"
                  name="chkOverdueCourse"
                  checked={preferences.receiveOverdueCourseNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveOverdueCourseNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('course', { ns: 'courses' })}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  id="chkOverdueLesson"
                  name="chkOverdueLesson"
                  checked={preferences.receiveOverdueLessonNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveOverdueLessonNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('lesson', { ns: 'lessons' })}
                </Typography>
              }
            />
          </Stack>
          <hr style={horSeparator} />
          <label>
            <Typography style={secondTitleStyle}>
              {t('weeklyAdminNotificationDescription', { ns: 'settings' })}
            </Typography>
          </label>
          <Stack direction="column">
            <FormControlLabel
              control={
                <Checkbox
                  id="chkInactive"
                  name="chkInactive"
                  checked={preferences.receiveInactiveUserNotification}
                  sx={checkBoxStyle}
                  onChange={e =>
                    setPreferences({
                      ...preferences,
                      receiveInactiveUserNotification: e.target.checked
                    })
                  }
                />
              }
              label={
                <Typography style={secondTitleStyle}>
                  {t('receiveInactive', { ns: 'settings' })}
                </Typography>
              }
            />
            {skillsAndCertifications.skillsAndCertifications && (
              <FormControlLabel
                control={
                  <Checkbox
                    id="chkAwarded"
                    name="chkAwarded"
                    checked={preferences.receiveAwardedUserNotification}
                    sx={checkBoxStyle}
                    onChange={e =>
                      setPreferences({
                        ...preferences,
                        receiveAwardedUserNotification: e.target.checked
                      })
                    }
                  />
                }
                label={
                  <Typography style={secondTitleStyle}>
                    {t('receiveAwardedEmployees', { ns: 'settings' })}
                  </Typography>
                }
              />
            )}
          </Stack>
        </>
      )}

      <br></br>
      <br></br>
      <BasicButton
        disable={isLoading}
        color="primary"
        onClick={() => handleSave()}
      >
        {t('save', { ns: 'common' })}
      </BasicButton>
    </>
  );
};

const fontTab: CSSProperties = {
  fontSize: '19px',
  textTransform: 'capitalize',
  color: '#ffffff'
};

const secondTitleStyle: CSSProperties = {
  fontSize: '16px',
  fontWeight: '100',
  color: '#e1e0e0'
};

const horSeparator: CSSProperties = {
  height: '1px',
  color: '#e1e0e0'
};

const checkBoxStyle: SxProps = {
  color: '#a2a1a1',
  '&.Mui-checked': {
    color: '#fa9e03'
  }
};

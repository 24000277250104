import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

interface EmployeesAssignedModalProps {
  showModal: boolean;
  title: string;
  onCloseModal: () => void;
  children: React.ReactNode;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const EmployeesAssignedModal = ({
  showModal,
  title,
  onCloseModal,
  children
}: EmployeesAssignedModalProps) => {
  const theme = useTheme();
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onCloseModal}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{ ...iconStyle }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </CustomDialog>
  );
};

export default EmployeesAssignedModal;

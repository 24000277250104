import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { AutocompleteChangeReason, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Course,
  CourseLessonName,
  LessonOrder
} from '../../../entities/Course';
import FormLabel from '../../../components/core/FormLabel/FormLabel';
import { Tag } from '../../../entities/Tag';
import TagInput from '../../TagInput/TagInput';
import ThumbnailLibraryPanel from '../../ThumbnailLibraryPanel/ThumbnailLibraryPanel';
import { AzureBlobImageInfo } from '../../../entities/AzureBlobInfo';
import DragDropList, {
  DraggableListItem
} from '../../../components/DragDropList/DragDropList';
import { EntityTypeEnum, LearningUnitType } from '../../../core/enums';
import FooterActionButtons from './FooterActionButtons';
import { ApplicationState } from '../../../store';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import CourseInputsForm from './CourseInputsForm';
import BasicButton from '../../core/BasicButton/BasicButton';
import { buttonStyle, fontButton } from '../../../utils/buttonStyles';
import BackgroundCoursePattern from '../../../assets/BackgroundCoursePattern.svg';
import { FormErrors } from '../../core/FormErrors/FormErrors';
import { validImageFormat } from '../../../utils/validationsUtils';
import { truncateAzureUrl } from '../../../utils/stringUtils';
import { enqueueSnackbar } from 'notistack';
import { actionCreators as lessonActions } from '../../../actions/lessons';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims, UserRoles } from '../../../core/constants';
import useUserRole from '../../../hooks/useUserRole';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';
import config from '../../../config';
import { useAppDispatch } from '../../../store/hooks';

export interface CourseFormProps {
  course: Course;
  companyTags: Array<Tag>;
  isEdit: boolean;
  lessonNames: Array<CourseLessonName>;
  openDeleteLessonConfirmationModal: (lessonId: string) => void;
  openDeleteCourseConfirmationModal: (open: boolean) => void;
  openSelectLessonsModal: (open: boolean) => void;
  saveLessonsOrder: (lessonsOrder: Array<LessonOrder>) => void;
  createCourse: (
    course: Course,
    file: File,
    imgUrl: string,
    createToAddLessons: boolean
  ) => Promise<string | undefined>;
  editCourse: (
    course: Course,
    imgUrl: string,
    isPublishing: boolean,
    saveAndStay: boolean,
    file?: File
  ) => Promise<void>;
  editLesson: (lessonId: string, courseId?: string) => void;
}

const CourseForm: React.FC<CourseFormProps> = ({
  course,
  companyTags,
  isEdit,
  lessonNames,
  openDeleteLessonConfirmationModal,
  openDeleteCourseConfirmationModal,
  openSelectLessonsModal,
  saveLessonsOrder,
  createCourse,
  editCourse,
  editLesson
}) => {
  const {
    variables: { skillsAndCertifications }
  } = useDevCycleSdk();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [userHasRole] = useUserRole();
  const [courseFile, setCourseFile] = React.useState<any>(null);
  const [courseData, setCourseData] = React.useState<Course>(course);
  const [tags, setTags] = React.useState<Array<Tag>>([]);
  const { t } = useTranslation(['courses', 'common', 'skills']);
  const [imgUrl, setImgUrl] = React.useState<string>('');
  const [list, setList] = React.useState<DraggableListItem[]>([]);
  const [formErrors, setFormErrors] = React.useState<string[]>([]);
  const [fieldErrors, setFieldErrors] = React.useState<string[]>([]);
  const [displaySaveOrderButton, setDisplaySaveOrderButton] =
    useState<boolean>(false);
  const competenciesNotAssigned = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesNotAssigned
  );
  const partnerLessons = useSelector(
    (state: ApplicationState) => state.lessons?.partnersLessons
  );
  const partnerCompanies = useSelector(
    (state: ApplicationState) => state.contentLibrary?.partnersCompanies
  );

  const competenciesAssignedById = useSelector(
    (state: ApplicationState) => state.achievements?.competenciesAssignedById
  );
  const [isCompetenceLoading, setIsCompetenceLoading] = useState<boolean>(true);
  const [skillsCerts, setSkillsCert] = React.useState<Tag[]>([]);
  const [skillsCertsDeleted, setSkillCertsDeleted] = React.useState<Tag[]>([]);
  const [skillsCertsNotAssigned, setSkillsCertsNotAssigned] =
    React.useState<Array<Tag>>();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    if (course) {
      setCourseData(course);
      setTags(course.tags);
      setImgUrl(course.thumbnailUrl);
    }

    if (lessonNames && partnerLessons) {
      setList(mapLessonNames(lessonNames));
    }
  }, [course, lessonNames.length, partnerLessons]);

  React.useEffect(() => {
    if (
      partnerLessons?.length === 0 &&
      partnerCompanies &&
      partnerCompanies.length > 0
    ) {
      const companyPartnersIds = partnerCompanies.map(company => company.id);
      dispatch(lessonActions.requestPartnersLessons(companyPartnersIds));
    }
  }, [dispatch, partnerCompanies, partnerLessons?.length]);

  React.useEffect(() => {
    dispatch(achievementActions.getCompetenciesNotAssigned());
    if (course.id !== '') {
      dispatch(achievementActions.getCompetenciesAssignedById(course.id));
    }
    return () => {
      dispatch(achievementActions.setCompetenciesNotAssigned());
      dispatch(achievementActions.setCompetenciesAssignedById([]));
      setSkillsCert([]);
    };
  }, []);

  React.useEffect(() => {
    if (competenciesNotAssigned) {
      setSkillsCertsNotAssigned(competenciesNotAssigned);
      setIsCompetenceLoading(false);
    }
  }, [competenciesNotAssigned]);

  React.useEffect(() => {
    if (isEdit) {
      setSkillsCert(competenciesAssignedById!);
    }
  }, [competenciesAssignedById]);

  const mapLessonNames = (lessonNames: CourseLessonName[]) => {
    const userCompanyId = user?.profile[UserClaims.CompanyId] as string;
    const isAdminUser = userHasRole([
      UserRoles.CompanyAdmin,
      UserRoles.GroupLead,
      config.USE_ORG_CHART ? UserRoles.Supervisor : ''
    ]);

    const list: DraggableListItem[] = [];
    lessonNames.forEach(lesson => {
      const partnerLesson = partnerLessons?.find(
        partnerLesson => partnerLesson.id === lesson.lessonId
      );

      list.push({
        id: lesson.id,
        title: lesson.title,
        type: lesson.lessonType,
        isEditable:
          (!lesson.isFromLibrary && !partnerLesson) ||
          (!!partnerLesson &&
            isAdminUser &&
            userCompanyId === partnerLesson.companyId)
      });
    });
    return list;
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setCourseFile(file);
    }
  };

  const handleChangeImage = (img: string) => {
    if (img) {
      setImgUrl(img);
      setCourseFile(null);
    }
  };

  const getItemsFromDDList = (items: DraggableListItem[]) => {
    if (lessonNames) {
      let isDifferent = false;
      for (let i = 0; i < lessonNames.length; i++) {
        const indexItem = items.findIndex(
          item => item.id === lessonNames[i].id
        );
        if (i !== indexItem) isDifferent = true;
      }
      setList(items);
      setDisplaySaveOrderButton(isDifferent);
    }
  };

  const validateCourseBeforeSaving = () => {
    return (
      courseData &&
      courseData.title &&
      courseData.description &&
      courseData.tags.length &&
      (courseFile === null && !imgUrl
        ? BackgroundCoursePattern
        : (courseFile && validImageFormat(courseFile.type)) || imgUrl)
    );
  };

  const showCourseFormErrors = () => {
    const fieldErrorsArray: string[] = [];
    const formErrorsArray: string[] = [];

    if (!courseData.title) {
      fieldErrorsArray.push(t('titleRequired', { ns: 'common' }));
    }
    if (!courseData.description) {
      fieldErrorsArray.push(t('descriptionIsRequired', { ns: 'common' }));
    }
    if (!courseData.tags.length) {
      fieldErrorsArray.push(t('oneTagRequired', { ns: 'common' }));
    }
    if (courseFile && !validImageFormat(courseFile.type)) {
      formErrorsArray.push(t('invalidImageFormat', { ns: 'common' }));
    }
    setFieldErrors(fieldErrorsArray);
    setFormErrors(formErrorsArray);
    scrollUp();
  };

  const handleSave = async () => {
    if (!courseData.isDraft && !list.length) {
      enqueueSnackbar(t('mustIncludeLessonsBeforeSaving', { ns: 'courses' }), {
        variant: 'warning'
      });
      return;
    }

    await handleSaveCourse(courseData);
    if (skillsAndCertifications.skillsAndCertifications) {
      dispatch(
        achievementActions.associateCompetenceToLearningUnit(
          course.id,
          LearningUnitType.course,
          skillsCerts
        )
      );
      isEdit &&
        (await dispatch<Promise<void>>(
          achievementActions.deleteLearningUnitToCompetence(
            skillsCertsDeleted,
            LearningUnitType.course
          )
        ));
    }
  };

  const handleSaveAndPublish = async () => {
    if (!list.length) {
      enqueueSnackbar(
        t('mustIncludeLessonsBeforePublishing', { ns: 'courses' }),
        { variant: 'warning' }
      );
      return;
    }

    const courseToPublish: Course = { ...courseData, isDraft: false };
    await handleSaveCourse(courseToPublish, true);
    if (skillsAndCertifications.skillsAndCertifications) {
      dispatch(
        achievementActions.associateCompetenceToLearningUnit(
          course.id,
          LearningUnitType.course,
          skillsCerts
        )
      );
    }
  };

  const handleSaveCourse = async (
    courseToSave: Course,
    isPublishing = false
  ) => {
    let crudAction = 'create';
    setFormErrors([]);
    try {
      if (validateCourseBeforeSaving()) {
        if (displaySaveOrderButton) {
          await handleSaveLessonsOrder();
        }
        if (!isEdit && !courseToSave.id.length) {
          await createCourse(courseToSave, courseFile, imgUrl, false);
        } else if (isEdit || courseToSave.id.length) {
          crudAction = 'update';
          await editCourse(
            courseToSave,
            imgUrl,
            isPublishing,
            false,
            courseFile
          );
        }
      } else {
        showCourseFormErrors();
      }
    } catch (e) {
      setFormErrors([t(`${crudAction}CourseErrorMsg`, { ns: 'courses' })]);
      scrollUp();
    }
  };

  const handleSaveLessonsOrder = async () => {
    const lessonsOrder: LessonOrder[] = [];
    list.map((item: any, index: number) => {
      lessonsOrder.push({ id: item.id, order: index + 1 });
    });
    try {
      await saveLessonsOrder(lessonsOrder);
    } catch (e) {
      setFormErrors([t('saveLessonsOrderErrorMsg', { ns: 'course' })]);
      scrollUp();
    } finally {
      setDisplaySaveOrderButton(false);
    }
  };

  const handleOpenSelectLessonsModal = async () => {
    if (!courseData.id.length) {
      const courseId = await createCourseToAddLesson();
      if (!courseId) {
        return;
      }
    }
    openSelectLessonsModal(true);
  };

  const handleCreateLesson = async () => {
    let courseId = courseData.id;

    if (!courseId.length) {
      const id = await createCourseToAddLesson();
      if (id) {
        courseId = id;
      }
    } else {
      try {
        await editCourse(courseData, imgUrl, false, true, courseFile);
      } catch (e) {
        enqueueSnackbar(t('updateCourseErrorMsg', { ns: 'courses' }), {
          variant: 'error'
        });
        return;
      }
    }

    if (courseId.length) {
      navigate(`/content-library/lessons/create`, {
        state: {
          isEditing: false,
          isInCourses: true,
          courseId
        }
      });
    }
  };

  const createCourseToAddLesson = async () => {
    if (!validateCourseBeforeSaving()) {
      showCourseFormErrors();
      enqueueSnackbar(
        t('mustFillCourseFormBeforeAddingLessons', { ns: 'courses' }),
        { variant: 'warning' }
      );
      return;
    }

    try {
      const courseId = await createCourse(courseData, courseFile, imgUrl, true);
      if (courseId) {
        return courseId;
      }
      throw new Error();
    } catch (e) {
      enqueueSnackbar(
        t('createCourseToAddLessonsErrorMsg', { ns: 'courses' }),
        { variant: 'error' }
      );
      return;
    }
  };

  const handleEditLesson = async (lessonId: string) => {
    try {
      await editCourse(courseData, imgUrl, false, true, courseFile);
      editLesson(lessonId, courseData.id);
    } catch (e) {
      enqueueSnackbar(t('updateCourseErrorMsg', { ns: 'courses' }), {
        variant: 'error'
      });
    }
  };

  const scrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const onSkillCertChange = (
    _: React.SyntheticEvent,
    value: Tag[],
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'removeOption') {
      const deleted = skillsCerts.filter(
        ({ id }) => !value.some(({ id: id2 }) => id === id2)
      );
      setSkillCertsDeleted(prevState => [...prevState, ...deleted]);
    }
    setSkillsCert(value);
  };

  const buttonsDragDropStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row' as const,
    minHeight: '150px',
    paddingTop: '50px',
    justifyContent: isXs ? 'center' : 'flex-start'
  };

  return (
    <Grid container spacing={2}>
      {!!fieldErrors.length && (
        <Grid item xs={12}>
          <FormErrors errors={fieldErrors} />
        </Grid>
      )}
      {!!formErrors.length && (
        <Grid item xs={12}>
          <FormErrors
            errors={formErrors}
            title={t('followingErrorsWerefound', { ns: 'common' })}
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        <CourseInputsForm
          courseData={courseData}
          tags={tags}
          companyTags={companyTags}
          setTags={setTags}
          setCourseData={setCourseData}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid
          style={
            imgUrl === '' || imgUrl == null ? gridThumbnail : gridThumbnailPath
          }
        >
          <FormLabel>{t('courseThumbnail', { ns: 'courses' })}</FormLabel>
          <div style={thumbnailPanelContainerStyle}>
            <ThumbnailLibraryPanel
              onChange={handleChangeFile}
              thumbnailUrl={imgUrl}
              onItemSelected={(item: AzureBlobImageInfo) => {
                handleChangeImage(item.url);
              }}
            />
          </div>
          {courseData.thumbnailUrl && (
            <span>{truncateAzureUrl(courseData.thumbnailUrl)}</span>
          )}
        </Grid>
        {skillsAndCertifications.skillsAndCertifications && (
          <Grid className="gridInputSkillsCerts" item paddingBottom="0.5rem">
            <FormLabel>
              {t('earnedSkillsCertificatesCompletion', { ns: 'skills' })}
            </FormLabel>
            <TagInput
              value={skillsCerts ?? []}
              options={skillsCertsNotAssigned ?? []}
              onChange={onSkillCertChange}
              placeholder={t('writeSkillCertificateHere', { ns: 'skills' })}
              noTagsText={t('noMatchingSkillsCertificates', {
                ns: 'skills'
              })}
              loading={isCompetenceLoading}
            />
            {!!skillsCertsNotAssigned &&
              skillsCertsNotAssigned?.length === 0 && (
                <Typography className="text-danger">
                  {t('noSkillCertAvailable', { ns: 'skills' })}
                </Typography>
              )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <FormLabel>
          {t('addLessonToCourse', { ns: 'courses' })}
          <span className="text-danger">*</span>{' '}
        </FormLabel>
      </Grid>
      <Grid item xs={12}>
        <DragDropList
          itemType={EntityTypeEnum.Lesson}
          items={list}
          editItem={handleEditLesson}
          deleteItem={openDeleteLessonConfirmationModal}
          getItems={getItemsFromDDList}
        >
          <div style={buttonsDragDropStyle}>
            <BasicButton
              onClick={handleOpenSelectLessonsModal}
              color="primary"
              style={{ ...buttonStyle, ...btnCreateLessonStyle }}
            >
              <Typography sx={fontButton}>
                {t('addLesson', { ns: 'lessons' })}
              </Typography>
            </BasicButton>
            <BasicButton
              onClick={handleCreateLesson}
              color="secondary"
              style={{ ...buttonStyle, ...btnCreateLessonStyle }}
            >
              <Typography sx={fontButton}>
                {t('createLesson', { ns: 'lessons' })}
              </Typography>
            </BasicButton>
            {displaySaveOrderButton && (
              <BasicButton
                onClick={handleSaveLessonsOrder}
                color="secondary"
                width="175px"
                height="45px"
                style={btnCreateLessonStyle}
              >
                {t('saveLessonOrder', { ns: 'lessons' })}
              </BasicButton>
            )}
          </div>
        </DragDropList>
      </Grid>
      <Grid item xs={12} sx={buttonsContainerStyle}>
        <FooterActionButtons
          courseData={course}
          isEdit={isEdit}
          saveInProgress={false}
          onSave={handleSave}
          onPublish={handleSaveAndPublish}
          onConfirmationModalOpen={openDeleteCourseConfirmationModal}
        />
      </Grid>
      <Grid item />
    </Grid>
  );
};

const buttonsContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between'
};

const btnCreateLessonStyle: CSSProperties = {
  marginLeft: '15px'
};

const thumbnailPanelContainerStyle: CSSProperties = {
  height: '327px'
};

const gridThumbnail: CSSProperties = {
  marginBottom: 28.19
};

const gridThumbnailPath: CSSProperties = {
  marginBottom: 4.19
};

export default CourseForm;

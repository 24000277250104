import {
  Skeleton,
  Card,
  CardContent,
  useMediaQuery,
  Grid as MuiGrid
} from '@mui/material';
import { CSSProperties, MutableRefObject, ReactNode, useState } from 'react';
import {
  Index,
  ListRowProps,
  WindowScroller,
  InfiniteLoader,
  AutoSizer,
  List
} from 'react-virtualized';
import { actionCreators as onsiteTrainingActions } from '../../actions/onsiteTrainings';
import { useLocation, useNavigate } from 'react-router';

import { DefaultPageSize, UserRoles } from '../../core/constants';
import { PagedResult } from '../../entities/Common';
import useUserRole from '../../hooks/useUserRole';

import { OnsiteTraining } from '../../entities/OnsiteTraining';
import ModalForCloning from './cloneOnsiteTraining/ModalForCloning';
import AssignOnsiteTraining from './assignments/AssignOnsiteTraining';
import OnsiteTrainingCardLibrary from '../../components/OnsiteTrainingCard/OnsiteTrainingCardLibrary';
import { useAppDispatch } from '../../store/hooks';

const useGetNumberOfColumns = () => {
  const isOneColumn = useMediaQuery('(max-width:640px)');
  const twoColumns = useMediaQuery('(max-width:1100px)');

  if (isOneColumn) {
    return 1;
  } else if (twoColumns) {
    return 2;
  } else {
    return 3;
  }
};

interface OnsiteTrainingsCardListProps {
  onsiteTrainings: PagedResult<OnsiteTraining> | null | undefined;
  currentSearch: string;
  currentSort: string;
  currentFilter: string;
  page: MutableRefObject<number>;
}

const OnsiteTrainingsCardList: React.FC<OnsiteTrainingsCardListProps> = ({
  onsiteTrainings,
  currentSearch,
  currentSort,
  currentFilter,
  page
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const numberOfColumns = useGetNumberOfColumns();
  const [showModalToClone, setShowModalToClone] = useState<boolean>(false);
  const [selectedOnsiteTraining, setSelectedOnsiteTraining] =
    useState<OnsiteTraining | null>();
  const [userHasRole] = useUserRole();
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [selectedTraining, setSelectedTraining] = useState<OnsiteTraining>();
  const location = useLocation();
  let rowCount = 0;
  if (onsiteTrainings) {
    rowCount = Math.ceil(onsiteTrainings.totalItems / numberOfColumns);
    if (onsiteTrainings.hasNextPage) {
      rowCount += 1;
    }
  }

  const isRowLoaded = ({ index }: Index) => {
    const realIndex = index * numberOfColumns;
    return realIndex < (onsiteTrainings?.pageItems.length ?? 0);
  };

  const handleOpenModalToClone = (onsiteTraining: OnsiteTraining) => {
    setShowModalToClone(true);
    setSelectedOnsiteTraining(onsiteTraining);
  };

  const handleCloseModalToClone = () => {
    setShowModalToClone(false);
    setSelectedOnsiteTraining(null);
  };

  const loadNextPage = async () => {
    page.current += 1;
    await dispatch<Promise<void>>(
      onsiteTrainingActions.loadOnsiteTrainings({
        page: page.current,
        pageSize: DefaultPageSize,
        search: currentSearch,
        sort: currentSort,
        filterContentStatus: currentFilter
      })
    );
  };

  const renderRowWrapper = (
    content: ReactNode,
    style: CSSProperties,
    key: string
  ) => {
    return (
      <div style={style} key={key}>
        <MuiGrid container spacing={2}>
          {content}
        </MuiGrid>
      </div>
    );
  };

  const loadMoreRows = !onsiteTrainings
    ? () => new Promise<any>(resolve => resolve)
    : loadNextPage;

  const renderRow = (props: ListRowProps) => {
    const { index, style, key } = props;
    const data = onsiteTrainings ? onsiteTrainings.pageItems : [];
    let content;

    if (!isRowLoaded({ index })) {
      content = Array.from({ length: numberOfColumns }, (v, i) => i).map(() => (
        <MuiGrid item xs={12 / numberOfColumns}>
          <Card sx={{ width: '100%' }}>
            <Skeleton
              sx={{ height: 190 }}
              animation="wave"
              variant="rectangular"
            />

            <CardContent>
              <>
                <Skeleton
                  animation="wave"
                  height={10}
                  style={{ marginBottom: 6 }}
                />
                <Skeleton animation="wave" height={10} width="80%" />
              </>
            </CardContent>
          </Card>
        </MuiGrid>
      ));

      return renderRowWrapper(content, style, key);
    }
    const handleOnsiteTrainingPreview = (item: OnsiteTraining) => {
      if (
        userHasRole([
          UserRoles.CompanyAdmin,
          UserRoles.GroupLead,
          UserRoles.Supervisor
        ])
      ) {
        navigate(`/onsite-training-preview/${item.id}`, {
          state: {
            onsiteTraining: item,
            prevPath: location.pathname
          }
        });
      }
    };
    const realIndex = index * numberOfColumns;
    const startItemIndex = realIndex;
    const endIndex = startItemIndex + numberOfColumns;

    const items = [];
    for (let i = startItemIndex; i < endIndex && i < data.length; i++) {
      if (!data[i]) {
        break;
      }

      items.push(data[i]);
    }

    content = items.map(item => (
      <MuiGrid item xs={12 / numberOfColumns}>
        <OnsiteTrainingCardLibrary
          onEdit={() =>
            navigate('/onsite-training/edit', {
              state: {
                onsiteTraining: item,
                prevPath: location.pathname
              }
            })
          }
          onClone={() => handleOpenModalToClone(item)}
          onsiteTraining={item}
          onShowPreview={handleOnsiteTrainingPreview}
          onAssign={() => {
            handleAssignOnsiteTraining(item);
          }}
        />
      </MuiGrid>
    ));

    return renderRowWrapper(content, style, key);
  };

  const handleAssignOnsiteTraining = (item: OnsiteTraining) => {
    setSelectedTraining(item);
    setShowAssignModal(true);
  };

  return (
    <>
      <WindowScroller scrollElement={window}>
        {({ height, onChildScroll, scrollTop, isScrolling }) => (
          <div
            style={{
              flex: '1 1 auto'
            }}
          >
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={rowCount}
              threshold={10}
            >
              {({ onRowsRendered, registerChild }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <List
                      autoHeight
                      className="window-scroller-override"
                      height={height}
                      ref={registerChild}
                      onRowsRendered={onRowsRendered}
                      onScroll={onChildScroll}
                      rowCount={rowCount}
                      rowHeight={291}
                      isScrolling={isScrolling}
                      rowRenderer={renderRow}
                      scrollTop={scrollTop}
                      width={width}
                    />
                  )}
                </AutoSizer>
              )}
            </InfiniteLoader>
          </div>
        )}
      </WindowScroller>
      <ModalForCloning
        showModal={showModalToClone}
        onsiteTraining={selectedOnsiteTraining}
        onCloseModal={handleCloseModalToClone}
      />
      {showAssignModal && (
        <AssignOnsiteTraining
          onClose={() => setShowAssignModal(false)}
          open={showAssignModal}
          onsiteTraining={selectedTraining}
        />
      )}
    </>
  );
};

export default OnsiteTrainingsCardList;

import { SvgIcon, styled, Box, Stack } from '@mui/material';

export const SvgSkillIcon = () => (
  <SvgIcon viewBox="0 0 21 21">
    <path
      d="M13.1 0.810059L10.27 3.64006C9.50002 4.42006 9.50002 5.69006 10.27 6.47006L11.68 7.88006L7.13001 12.4301L4.31001 9.60006L2.89001 11.0001C-2.05999 16.0001 1.50001 19.5001 1.50001 19.5001C1.50001 19.5001 5.00001 23.0001 10 18.0901L11.41 16.6801L8.61001 13.8801L13.15 9.34006L14.54 10.7301C15.32 11.5001 16.59 11.5001 17.37 10.7301L20.2 7.90006L13.1 0.810059ZM15.93 9.28006L14.55 7.90006L13.11 6.46006L11.71 5.06006L13.12 3.65006L17.35 7.88006L15.93 9.28006Z"
      fill="#929292"
    />
  </SvgIcon>
);

export const SvgBigSkillIcon = () => (
  <SvgIcon
    viewBox="0 0 128 135"
    sx={{ width: '125px', height: '152px', color: '#1B1C1C' }}
  >
    <path
      d="M23.0309 82.7114L23.0309 31.3815M23.0309 141.938L42.7732 122.196L42.7732 82.7114L3.2886 82.7114L3.28861 122.196L23.0309 141.938ZM42.7732 3.74228L3.2886 3.74229L3.2886 7.69074C3.28453 10.2845 3.7924 12.8535 4.7831 15.2506C5.7738 17.6477 7.22785 19.8257 9.0619 21.6597C10.896 23.4938 13.0739 24.9478 15.471 25.9385C17.8681 26.9292 20.4371 27.4371 23.0309 27.433C25.6246 27.4371 28.1937 26.9292 30.5907 25.9385C32.9878 24.9478 35.1658 23.4938 36.9998 21.6597C38.8339 19.8257 40.288 17.6477 41.2787 15.2506C42.2694 12.8535 42.7772 10.2845 42.7732 7.69074L42.7732 3.74228Z"
      stroke="#999999"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export const StyledCompetenceDetailContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 480px)',
  minWidth: '450px',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '10px',
  backgroundColor: theme.palette.secondary.main,
  [`@media (max-width: 1300px)`]: {
    maxWidth: '100%',
    width: '100%'
  }
}));

export const StyledDetailSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: `1px solid ${theme.palette.grey[100]}`
}));

export const StyledSpanTitle = styled('span')(({ theme }) => ({
  color: theme.palette.grey[100],
  fontSize: '15px'
}));

export const StyledStackContent = styled(Stack)(({ theme }) => ({
  padding: '0.8rem'
}));

import { useState } from 'react';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { CourseUnit, AiccCourse } from '../../entities/Course';
import { CourseUnitTypes } from '../../core/constants';
import { CompletedCourseBasicInfo } from '../../entities/CompletedCourseInfo';
import React from 'react';
import AssignCourse from '../../pages/courses/AssignCourse';
import { actionCreators as coursesActions } from '../../actions/courses';
import AssignClickSafety from '../../pages/contentLibrary/ClickSafety/AssignClickSafety';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import ContentLibraryVirtualList from '../ContentLibraryVirtualList/ContentLibraryVirtualList';
import CourseVirtualRow from '../ContentLibraryVirtualList/CourseVirtualRow';
import ContentIndicators from '../../pages/contentLibrary/common/ContentIndicators';
import { useAppDispatch } from '../../store/hooks';

export interface AllCoursesProps {
  courseUnits: Array<CourseUnit>;
  totalItems: number;
  isLoading: boolean;
  completedCourses?: Array<CompletedCourseBasicInfo> | undefined;
  takenTimesCourses?: TakenTimesObject | null;
}

const AllCoursesNew = ({
  courseUnits,
  isLoading,
  completedCourses,
  takenTimesCourses,
  totalItems
}: AllCoursesProps) => {
  const { t } = useTranslation(['courses']);
  const [showAssignCourseModal, setShowAssignCourseModal] =
    React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [showClickSafetyAssignmentModal, setShowClickSafetyAssignmentModal] =
    useState<boolean>(false);
  const [selectedClickSafetyCourse, setSelectedClickSafetyCourse] =
    useState<AiccCourse | null>(null);

  const handleOpenAssignModal = (courseUnitType: string) => (id?: string) => {
    switch (courseUnitType) {
      case CourseUnitTypes.companyCourse:
      case CourseUnitTypes.partnerCourse:
      case CourseUnitTypes.bWCourse:
        dispatch(coursesActions.requestCourseById(id!));
        setShowAssignCourseModal(true);
        break;
      case CourseUnitTypes.clickSafetyCourse:
        setShowClickSafetyAssignmentModal(true);
        break;
    }
  };

  const handleCloseCourseAssignModal = (courseUnitType: string) => {
    switch (courseUnitType) {
      case CourseUnitTypes.companyCourse:
      case CourseUnitTypes.partnerCourse:
      case CourseUnitTypes.bWCourse:
        dispatch(coursesActions.setCurrentCourse(null));
        setShowAssignCourseModal(false);
        break;
      case CourseUnitTypes.clickSafetyCourse:
        setShowClickSafetyAssignmentModal(false);
        setSelectedClickSafetyCourse(null);
        break;
      default:
        break;
    }
  };

  const handleSelectClickSafetyCourse = (course: AiccCourse) => {
    setSelectedClickSafetyCourse(course as AiccCourse);
  };

  return (
    <Grid item xs={12} sx={containerStyle}>
      <ContentIndicators
        isLoading={isLoading}
        totalItems={totalItems}
        noContentMessage={t('noCoursesAvailableMsg', { ns: 'courses' })}
      />
      <ContentLibraryVirtualList
        items={courseUnits}
        learningPlanUnit={'courses'}
        isLoading={isLoading}
        itemSize={341}
      >
        {({ data, index, style }) => (
          <CourseVirtualRow
            key={index}
            onOpenAssignModal={handleOpenAssignModal}
            onSelectClickSafetyCourse={handleSelectClickSafetyCourse}
            completedCourses={completedCourses}
            takenTimesCourses={takenTimesCourses}
            index={index}
            style={style}
            data={data}
          />
        )}
      </ContentLibraryVirtualList>

      {showAssignCourseModal && (
        <AssignCourse
          open={showAssignCourseModal}
          onClose={() =>
            handleCloseCourseAssignModal(CourseUnitTypes.companyCourse)
          }
        />
      )}
      {showClickSafetyAssignmentModal && selectedClickSafetyCourse && (
        <AssignClickSafety
          show={showClickSafetyAssignmentModal}
          clickSafetyCourse={selectedClickSafetyCourse}
          onClose={() =>
            handleCloseCourseAssignModal(CourseUnitTypes.clickSafetyCourse)
          }
        />
      )}
    </Grid>
  );
};

const containerStyle: SxProps = {
  paddingTop: '2rem'
};

export default AllCoursesNew;

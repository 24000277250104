import React, { useState, useEffect, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import { useTheme } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

export interface DatePickerProps {
  onChange?: (date: Date) => void;
  onChangeDateRange?: (startDate: Date, endDate: Date) => void;
  disablePastDays?: boolean;
  selectsRange?: boolean;
  value?: Date | null | undefined;
  backgroundColorBlack?: boolean;
  smallVersion?: boolean;
}

const ReactDatePickerCustomInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>((props, ref) => (
  <input ref={ref} {...props} className="custom-datepicker-input" />
));

const ReactDatePickerCustomSmallInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>((props, ref) => (
  <input ref={ref} {...props} className="custom-datepicker-small-input" />
));

const DatePickerInput: React.FC<DatePickerProps> = ({
  onChange,
  onChangeDateRange,
  disablePastDays = false,
  selectsRange = false,
  value,
  backgroundColorBlack = false,
  smallVersion = false
}) => {
  registerLocale('en-US', enUS);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const theme = useTheme();

  useEffect(() => {
    if (value) setSelectedDate(moment(value).toDate());
  }, [value]);

  const dateText = (() => {
    if (!selectsRange && selectedDate)
      return moment(selectedDate).format('MM.DD.YYYY');
    else if (selectsRange && selectedStartDate && selectedEndDate)
      return `${moment(selectedStartDate).format('MM.DD.YYYY')} - ${moment(selectedEndDate).format('MM.DD.YYYY')}`;
    return '';
  })();

  const filterDate = (date: Date) => {
    if (!disablePastDays) return true;

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return date >= currentDate;
  };

  const handleOnChange = (date: Date) => {
    setSelectedDate(date);

    if (onChange) onChange(date);
  };

  const handleOnChangeDates = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setSelectedStartDate(start);
    setSelectedEndDate(end);

    if (onChangeDateRange) onChangeDateRange(start, end);
  };
  const background = backgroundColorBlack ? 'black' : '#fff';
  const textColor = backgroundColorBlack ? 'white' : '#495057';
  return (
    <div className="custom-datepicker">
      <DatePicker
        locale="en-US"
        selected={selectedDate}
        selectsRange={selectsRange}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        onChange={selectsRange ? handleOnChangeDates : handleOnChange}
        filterDate={filterDate}
        customInput={
          smallVersion ? (
            <ReactDatePickerCustomSmallInput />
          ) : (
            <ReactDatePickerCustomInput />
          )
        }
        popperProps={{
          strategy: 'fixed'
        }}
      />
      <label
        className={
          smallVersion
            ? 'custom-datepicker-small-label'
            : 'custom-datepicker-label'
        }
        style={{ backgroundColor: background, color: textColor }}
        htmlFor="customDatePicker"
      >
        <FontAwesomeIcon
          icon={faCalendarMinus}
          color={theme.palette.grey[100]}
        />
        <span className="ps-2">{dateText}</span>
      </label>
    </div>
  );
};

export default DatePickerInput;

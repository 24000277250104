import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SelectCourses from '../../courses/SelectCourses';
import { ImportCourseDto } from '../../../entities/Course';
import { actionCreators as coursesActions } from '../../../actions/courses';
import { actionCreators as studentActions } from '../../../actions/student';
import { LibraryTypesEnum, TransactionStatusEnum } from '../../../core/enums';
import { ApplicationState } from '../../../store';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../../store/hooks';
import ModalComponent from '../../../components/core/ModalPage/ModalComponent';

interface ImportCoursesProps {
  companyId: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const ImportCourses = ({
  companyId,
  showModal,
  setShowModal
}: ImportCoursesProps) => {
  const { t } = useTranslation(['courses', 'common']);
  const dispatch = useAppDispatch();
  const coursesToImportRef = useRef<string[]>([]);
  const refreshCoursesRef = useRef<boolean>(false);
  const [importingCoursesInProgress, setImportingCoursesInProgress] =
    useState<boolean>(false);
  const courseTransactionStatus = useSelector(
    (state: ApplicationState) => state.courses?.transactionStatus
  );

  useEffect(() => {
    if (refreshCoursesRef.current) {
      refreshCoursesRef.current = false;
      dispatch(coursesActions.requestCourses());
      dispatch(coursesActions.requestCourseTags());
      dispatch(studentActions.getCompletedCoursesBasicInfo());
    }
  }, [refreshCoursesRef.current]);

  useEffect(() => {
    if (
      courseTransactionStatus !== TransactionStatusEnum.None &&
      importingCoursesInProgress
    ) {
      if (courseTransactionStatus === TransactionStatusEnum.Successfull) {
        refreshCoursesRef.current = true;
        enqueueSnackbar(
          t('coursesImportedSuccessfullyMsg', { ns: 'courses' }),
          { variant: 'success' }
        );
      } else if (courseTransactionStatus === TransactionStatusEnum.Failed) {
        enqueueSnackbar(t('importingCoursesErrorMsg', { ns: 'courses' }), {
          variant: 'error'
        });
      }

      dispatch(coursesActions.resetCourseTransactionStatus());
      setImportingCoursesInProgress(false);
      setShowModal(false);
    }
  }, [courseTransactionStatus, importingCoursesInProgress]);

  const handleImportCourses = () => {
    if (!coursesToImportRef.current.length) {
      enqueueSnackbar(t('noCoursesSelected', { ns: 'courses' }), {
        variant: 'warning'
      });
      return;
    }

    const importCourses: ImportCourseDto[] = coursesToImportRef.current.map(
      courseId => {
        return { courseId, companyId };
      }
    );

    dispatch(coursesActions.importCoursesBatch(importCourses));
    coursesToImportRef.current = [];
    setImportingCoursesInProgress(true);
  };

  const handleCoursesIdsSelection = (courseIds: string[]) => {
    coursesToImportRef.current = courseIds;
  };

  return (
    <ModalComponent
      showModal={showModal}
      title={t('importCourse', { ns: 'courses' })}
      savingInProgress={importingCoursesInProgress}
      maxWidth="md"
      saveButtonText={t('import', { ns: 'common' })}
      showCloseIcon={true}
      showCancelButton={false}
      closeWhenClickBackdrop={true}
      closeWhenPressingEscape={false}
      onSave={handleImportCourses}
      onClose={() => setShowModal(false)}
    >
      <SelectCourses
        coursesLibraryType={LibraryTypesEnum.Buildwitt}
        getSelectedCoursesIds={handleCoursesIdsSelection}
      />
    </ModalComponent>
  );
};

export default ImportCourses;

import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ChipAverageTime from '../core/ChipAverageTime/ChipAverageTime';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import { NewContentHeader } from '../core/InfoCardHeader/InfoCardHeader';
import { ILearningPlanReadonlyCard } from '../../entities/Card';
import { useGetCardOwnerShip } from '../../hooks/useGetCardOwnership';
import { isNewContent } from '../../utils/dateUtils';
import {
  coursesCount,
  leftDivStyle,
  planTitlePointer,
  planTitleDefault,
  infoSection
} from './LearningPlanStyles';
import { LearningPlanBaseWrapper } from './LearningPlanWrapper';
import ChipTakenTimes from '../core/ChipTakenTimes/ChipTakenTimes';
import MovieIcon from '../Icons/MovieIcon';

export const LearningPlanReadonlyCard: React.FC<
  React.PropsWithChildren<ILearningPlanReadonlyCard>
> = ({
  learningPlan,
  dueDate,
  children,
  isDraft,
  averageLessonTime,
  wrapperWidth,
  takenTimes,
  onStartPreviewMode
}) => {
  const { t } = useTranslation([
    'assignments',
    'common',
    'learningPlans',
    'courses'
  ]);
  const theme = useTheme();
  const ownership = useGetCardOwnerShip(learningPlan);
  const isPreviewMode = !!onStartPreviewMode;
  const creationLPDate = moment(learningPlan?.createdTimestampUtc).local();
  const isNewContentLP = isNewContent(creationLPDate);

  const newLearningPlan =
    isNewContentLP && !isDraft
      ? theme.palette.primary.main
      : `${theme.palette.grey['100']}`;

  const rightDivStyle: CSSProperties = {
    width: '2%',
    minWidth: '30px',
    minHeight: '325px',
    height: '100%',
    backgroundColor: `${theme.palette.primary.main}`,
    borderTopRightRadius: '14px',
    borderBottomRightRadius: '14px',
    textAlign: 'center' as const
  };

  const rightHeaderDivStyle: CSSProperties = {
    width: '3%',
    minWidth: '30px',
    minHeight: '200px',
    marginTop: '40px',
    backgroundColor: `${theme.palette.primary.main}`,
    borderBottomRightRadius: '14px',
    textAlign: 'center' as const
  };

  const cardSelector: CSSProperties = {
    textTransform: 'uppercase' as const,
    marginLeft: '15px',
    width: '100%',
    minWidth: '325px',
    textAlign: 'center' as const,
    paddingRight: '25px',
    color: theme.palette.grey[800],
    fontWeight: '600'
  };

  const cardHeaderSelector: CSSProperties = {
    textTransform: 'uppercase' as const,
    marginLeft: '16px',
    width: '100%',
    minWidth: '240px',
    textAlign: 'center' as const,
    paddingRight: '25px',
    color: theme.palette.grey[800],
    fontWeight: '600'
  };

  const learningPlanTitle: CSSProperties = {
    fontSize: '15px',
    fontWeight: '400',
    color: theme.palette.primary.main
  };

  const separatorStyle: CSSProperties = {
    padding: '0 0.7rem',
    color: theme.palette.grey[100]
  };

  const headerInfoAndEditStyle: CSSProperties = {
    display: 'flex',
    justifyContent: ownership === null ? 'flex-end' : 'space-between'
  };

  const renderOwnership = () => {
    if (!isDraft && ownership) {
      return (
        <InfoBySpan
          leftText={ownership.key}
          rightText={ownership.value}
          showProfilePicture={false}
          isOverdue={false}
        />
      );
    }
    return null;
  };

  return (
    <LearningPlanBaseWrapper
      thumbnail={learningPlan?.thumbnailUrl}
      newLearningPlan={newLearningPlan}
      width={wrapperWidth}
    >
      {isNewContentLP && !isDraft && (
        <NewContentHeader label={t('newContent', { ns: 'common' })} />
      )}
      <div
        style={{
          ...leftDivStyle,
          cursor: isPreviewMode ? 'pointer' : 'default'
        }}
      >
        <div style={headerInfoAndEditStyle}>{renderOwnership()}</div>
        <div style={{ cursor: 'default' }}>
          <span style={learningPlanTitle}>
            {t('learningPlan', { ns: 'learningPlans' })}
          </span>
          <br />
          <span style={isPreviewMode ? planTitlePointer : planTitleDefault}>
            {learningPlan?.name}
          </span>
          {isDraft === false ? (
            <div>
              <div style={infoSection}>
                <MovieIcon />
                <span style={coursesCount}>
                  {` ${learningPlan?.courseCount} ${t('courses', {
                    ns: 'courses'
                  })}`}
                </span>
                {dueDate && moment(dueDate).isValid() && (
                  <>
                    <span style={separatorStyle}>|</span>
                    <InfoBySpan
                      leftText={t('dueDate', { ns: 'assignments' })}
                      rightText={dueDate}
                      showProfilePicture={false}
                    />
                  </>
                )}
                <br />
              </div>
              {takenTimes && (
                <div style={{ paddingBottom: '4px' }}>
                  <ChipTakenTimes takenTimes={takenTimes} />
                </div>
              )}
              {averageLessonTime && (
                <ChipAverageTime
                  showComplete={true}
                  averageLessonTime={averageLessonTime}
                />
              )}
            </div>
          ) : (
            <div style={infoSection}>
              {' '}
              <span style={{ fontSize: '12px' }}>
                {t('draftTitle', { ns: 'common' })}
              </span>
            </div>
          )}
          <br />
          {children}
        </div>
      </div>
      {isDraft ? (
        <div style={rightDivStyle}>
          <p className="vertical-text" style={cardSelector}>
            {t('learningPlan', { ns: 'learningPlans' })}
          </p>
        </div>
      ) : (
        <div style={isNewContentLP ? rightHeaderDivStyle : rightDivStyle}>
          <p
            className="vertical-text"
            style={isNewContentLP ? cardHeaderSelector : cardSelector}
          >
            {t('learningPlan', { ns: 'learningPlans' })}
          </p>
        </div>
      )}
    </LearningPlanBaseWrapper>
  );
};

import { useMemo, useState } from 'react';
import { CardContent, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useCurrentUser from '../../../../hooks/useCurrentUser';

import {
  MyCertification,
  MyCertificationStatus
} from '../../../../entities/Certification';
import { absUtcDateToLocalMoment } from '../../../../utils/dateUtils';
import BasicButton from '../../../../components/core/BasicButton/BasicButton';
import {
  DetailedInfoStyle,
  StyledTitle,
  ItemDetailSection,
  StyledCardActions,
  PillContainer,
  CertificationPill
} from './CertificationCardComponents';
import { lowerCaseFirstLetter } from '../../../../utils/stringUtils';
import { UserClaims } from '../../../../core/constants';
import { LightStyleTooltip } from '../../../../components/core/StyledTooltip/StyledTooltip';

interface CertificationCardOverviewProps {
  employeeCertification: MyCertification;
  onViewAttachedFiles: () => void;
}

const CertificationCardOverview = ({
  employeeCertification,
  onViewAttachedFiles
}: CertificationCardOverviewProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['certifications']);
  const user = useCurrentUser();
  const companyName = user?.profile[UserClaims.CompanyName] as string;
  const [showTitleTooltip, setShowTitleTooltip] = useState(false);
  const {
    certificationName,
    duration,
    durationUnit,
    expirationDate,
    certifyingBody,
    status
  } = employeeCertification;
  const certificationDuration = useMemo(() => {
    if (!duration) return 'N/A';
    const unit = duration > 1 ? `${durationUnit}s` : durationUnit;

    return `${duration} ${t(lowerCaseFirstLetter(unit))}`;
  }, [duration]);

  const handleShouldShowTooltip = ({
    currentTarget
  }: React.MouseEvent<Element>) => {
    setShowTitleTooltip(
      currentTarget.scrollHeight > currentTarget.clientHeight
    );
  };

  const expirationDateColor =
    status === MyCertificationStatus.Expired ||
    status === MyCertificationStatus.Expiring
      ? theme.palette.error.main
      : theme.palette.common.white;

  const certificationExpirationDate = expirationDate
    ? absUtcDateToLocalMoment(expirationDate).format('MM/DD/YY')
    : t('neverExpiresLabel');

  return (
    <>
      <CardContent>
        <PillContainer>
          <CertificationPill status={status} />
        </PillContainer>
        <LightStyleTooltip title={showTitleTooltip ? certificationName : ''}>
          <StyledTitle
            id="title"
            className="breaking-text"
            onMouseEnter={handleShouldShowTooltip}
          >
            {certificationName}
          </StyledTitle>
        </LightStyleTooltip>
        <Stack>
          <ItemDetailSection>
            <DetailedInfoStyle>{t('duration')}</DetailedInfoStyle>
            <DetailedInfoStyle>{certificationDuration}</DetailedInfoStyle>
          </ItemDetailSection>
          <ItemDetailSection>
            <DetailedInfoStyle>{t('expirationDate')}</DetailedInfoStyle>
            <DetailedInfoStyle color={expirationDateColor}>
              {certificationExpirationDate}
            </DetailedInfoStyle>
          </ItemDetailSection>
          <ItemDetailSection>
            <DetailedInfoStyle>{t('certifyingBody')}</DetailedInfoStyle>
            <LightStyleTooltip title={showTitleTooltip ? certifyingBody : ''}>
              <DetailedInfoStyle
                id="certifyingBody"
                color={theme.palette.primary.main}
                className="breaking-text"
                onMouseEnter={handleShouldShowTooltip}
              >
                {!certifyingBody ? companyName : certifyingBody}
              </DetailedInfoStyle>
            </LightStyleTooltip>
          </ItemDetailSection>
        </Stack>
      </CardContent>
      <StyledCardActions>
        <BasicButton
          color="primaryOutlined"
          height="42px"
          style={{ minWidth: '80px' }}
          onClick={onViewAttachedFiles}
        >
          {t('viewAttachedFiles')}
        </BasicButton>
      </StyledCardActions>
    </>
  );
};

export default CertificationCardOverview;

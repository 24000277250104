import React from 'react';
import RadioButtonImage from '../../../assets/RadioChecked.svg';

export interface RadioButtonProps {
  id: string;
  name: string;
  value: any;
  checked: boolean;
  onChange: (e: any) => void;
  labelText: string;
  disabled?: boolean;
  dataTestId?: string;
  dataTrackId?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  labelText,
  dataTestId,
  dataTrackId,
  disabled = false
}) => {
  const divStyle = {
    display: 'flex',
    flexDirection: 'row' as const
  };
  const rLabelStyle = {
    paddingLeft: '10px',
    fontWeight: 'bold',
    color: '#fff',
    opacity: disabled ? 0.4 : 1
  };

  const lblImgStyle = {
    backgroundImage: `url(${RadioButtonImage})`,
    opacity: disabled ? 0.4 : 1
  };
  return (
    <div style={divStyle}>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        className="radio-button"
        disabled={disabled}
        data-testid={dataTestId}
        data-trackid={dataTrackId}
      ></input>
      <label
        className="radio-button-label"
        htmlFor={id}
        style={lblImgStyle}
      ></label>
      <label
        className="radio-button-label-text"
        htmlFor={id}
        style={rLabelStyle}
      >
        {labelText}
      </label>
    </div>
  );
};
export default RadioButton;

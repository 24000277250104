import { useState } from 'react';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useSelector } from 'react-redux';

import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { useCalendarHelper } from '../../../hooks/useCalendarHelper';
import EmployeeHalts from './EmployeeHalts';
import { ApplicationState } from '../../../store';
import { actionCreators as haltActions } from '../../../actions/halts';
import { useAppDispatch } from '../../../store/hooks';

const HaltsOfPickedDay = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['settings']);
  const pickedCalendarDate = useSelector(
    (state: ApplicationState) => state.halts?.pickedCalendarDate
  );
  const {
    constants: { monthNames }
  } = useCalendarHelper();
  const [searchKey, setSearchKey] = useState<string>('');
  const title = pickedCalendarDate?.length
    ? `${t('trainingHaltsOn')} ${
        monthNames[moment(pickedCalendarDate, 'YYYY-DD-MM').month()]
      } ${moment(pickedCalendarDate, 'YYYY-DD-MM').date()}th`
    : '';

  const handleSearch = (value: any) => {
    const key = value as string;
    setSearchKey(key);
  };

  const handleHideComponent = () => {
    dispatch(haltActions.setPickedCalendarDate(''));
    dispatch(haltActions.setHaltsByDate(null));
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRadius: '5px',
        border: `1px solid ${theme.palette.grey[100]}`,
        marginLeft: { xs: 0, lg: '1rem' },
        marginTop: { xs: '1rem', lg: 0 }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#1D1D1E',
          border: `0.5px solid ${theme.palette.grey[100]}`
        }}
      >
        <Typography sx={{ paddingLeft: '1rem' }}>{title}</Typography>
        <Stack direction="row" sx={{ margin: '0.5rem', flexWrap: 'wrap' }}>
          <SearchBox
            id="searchEmployees"
            name="searchEmployees"
            value={searchKey}
            placeholderText={t('search')}
            width="100%"
            height="40px"
            minWidth="180px"
            variant="dark"
            onChangeValue={handleSearch}
            onSearchClick={handleSearch}
          />
          <IconButton onClick={handleHideComponent}>
            <CloseIcon sx={{ color: theme.palette.grey[100] }} />
          </IconButton>
        </Stack>
      </Box>
      <EmployeeHalts mode="pickedDateHalts" searchKey={searchKey} />
    </Box>
  );
};

export default HaltsOfPickedDay;

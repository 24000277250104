import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';
import { pluralizeText } from '../../utils/stringUtils';
import { OverseerScope } from '../../core/enums';

const resourceNameStyle: CSSProperties = {
  color: '#fff',
  textDecoration: 'underline'
};

interface DailyOverseerSummaryNotificationProps {
  payload: any;
  overseerScope: OverseerScope;
}

const friendlyOverseerScope = {
  [OverseerScope.Admin]: 'in your Company',
  [OverseerScope.Supervisor]: 'within people you supervised',
  [OverseerScope.GroupLead]: 'within a group you lead'
};

export const DailyOverseerSummaryNotification: React.FC<
  DailyOverseerSummaryNotificationProps
> = ({ payload, overseerScope }) => {
  const friendlyScope = friendlyOverseerScope[overseerScope];
  return (
    <Typography variant="body2" color="#C4C4C4">
      Updates {friendlyScope}: <br />
      <br />
      {(payload.CompleteLearningPlanAssignments ||
        payload.CompleteCourseAssignments ||
        payload.CompleteLessonAssigments) && (
        <>
          Completed assignments: <br />
          {payload.CompleteLearningPlanAssignments && (
            <DailySummaryItem
              count={payload.CompleteLearningPlanAssignments}
              text={'Learning Plan'}
            />
          )}
          {payload.CompleteCourseAssignments && (
            <DailySummaryItem
              count={payload.CompleteCourseAssignments}
              text={'Course'}
            />
          )}
          {payload.CompleteLessonAssigments && (
            <DailySummaryItem
              count={payload.CompleteLessonAssigments}
              text={'Lesson'}
            />
          )}
        </>
      )}
      {(payload.OverdueLearningPlanAssignments ||
        payload.OverdueCourseAssignments ||
        payload.OverdueLessonAssigments) && (
        <>
          Overdue assignments: <br />
          {payload.OverdueLessonAssigments && (
            <DailySummaryItem
              count={payload.OverdueLessonAssigments}
              text={'Learning Plan'}
            />
          )}
          {payload.OverdueCourseAssignments && (
            <DailySummaryItem
              count={payload.OverdueCourseAssignments}
              text={'Course'}
            />
          )}
          {payload.OverdueLessonAssigments && (
            <DailySummaryItem
              count={payload.OverdueLessonAssigments}
              text={'Lesson'}
            />
          )}
        </>
      )}
    </Typography>
  );
};

interface DailySummaryItemProps {
  count: number;
  text: string;
}
const DailySummaryItem: React.FC<DailySummaryItemProps> = ({ count, text }) => {
  return (
    <>
      <span style={resourceNameStyle}>{count} </span>
      {pluralizeText(count, text)}
      <br />
    </>
  );
};

import { AppThunk } from '../store/index';
import axios from 'axios';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import {
  EMPLOYEE_UPDATE_PASSWORD,
  RESET_UPDATE_PASSWORD,
  EMPLOYEE_SET_EMPLOYEES,
  EMPLOYEE_SET_EMPLOYEE_GROUPS,
  EMPLOYEE_SET_EMPLOYEES_WORKLOAD,
  EMPLOYEE_UPDATE_TRANSACTION_STATUS,
  EMPLOYEE_RESET_TRANSACTION_STATUS,
  EMPLOYEE_SET_LEARNING_PROFILE,
  EMPLOYEE_SET_ASSIGNMENTS_STATUS,
  EMPLOYEE_DATA_IS_LOADING,
  EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
  RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
  EMPLOYEE_SUPERVISORS,
  EMPLOYEE_SET_ACTIVE_EMPLOYEES
} from './actionTypes';
import config from './../config';
import {
  UpdatePasswordModel,
  Employee,
  EmployeeGroup,
  EmployeeWorkload,
  EmployeeAssignmentsStatus,
  EmployeeSupervisor
} from '../entities/Employee';
import { TransactionStatusEnum, HttpStatusEnum } from '../core/enums';
import { UserClaims } from '../core/constants';
import { handleGenericBackendError } from '../utils/errorHandling';

export interface UpdatePasswordAction {
  type: 'EMPLOYEE_UPDATE_PASSWORD';
  passwordUpdated: TransactionStatusEnum;
}

export interface ResetUpdatePasswordAction {
  type: 'RESET_UPDATE_PASSWORD';
}

export interface RequestEmployeesAction {
  type: 'EMPLOYEE_SET_EMPLOYEES';
  employees: Employee[];
}

export interface RequestEmployeeGroupsAction {
  type: 'EMPLOYEE_SET_EMPLOYEE_GROUPS';
  employeeGroups: EmployeeGroup[];
}

export interface RequestEmployeesWorkloadAction {
  type: 'EMPLOYEE_SET_EMPLOYEES_WORKLOAD';
  employeeWorkload: EmployeeWorkload[];
}

export interface UpdateEmployeeTransactionStatusAction {
  type: 'EMPLOYEE_UPDATE_TRANSACTION_STATUS';
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
}

export interface ResetEmployeeTransactionStatusAction {
  type: 'EMPLOYEE_RESET_TRANSACTION_STATUS';
}

export interface RequestEmployeeLearningProfileAction {
  type: 'EMPLOYEE_SET_LEARNING_PROFILE';
  employeesLearningProfile: Employee[];
}

export interface RequestEmployeeAssignmentsStatusAction {
  type: 'EMPLOYEE_SET_ASSIGNMENTS_STATUS';
  employeeAssignmentsStatus: EmployeeAssignmentsStatus[];
}

export interface EmployeeDataIsLoadingAction {
  type: 'EMPLOYEE_DATA_IS_LOADING';
  dataIsLoading: boolean;
}

export interface EmployeeAssociateWithCompanyInviteCodeAction {
  type: 'EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE';
  employeeAssociated: TransactionStatusEnum;
}

export interface ResetEmployeeAssociateWithCompanyInviteCodeAction {
  type: 'RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE';
}

export interface requestEmployeeSupervisors {
  type: 'EMPLOYEE_SUPERVISORS';
  employeeSupervisors: EmployeeSupervisor[];
}

export interface requestActiveEmployees {
  type: 'EMPLOYEE_SET_ACTIVE_EMPLOYEES';
  activeEmployees: Employee[];
}

export const actionCreators = {
  updatePassword:
    (updatePassword: UpdatePasswordModel): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch({ type: RESET_UPDATE_PASSWORD });

        const res = await axios.put(
          `${config.AUTHORITY_API_URL}users/updatePasword`,
          updatePassword
        );
        dispatch({
          type: EMPLOYEE_UPDATE_PASSWORD,
          passwordUpdated: res.data
            ? TransactionStatusEnum.Successfull
            : TransactionStatusEnum.Failed
        });
        dispatch(appActions.setIsLoading(false));
      }
    },
  associateWithCompanyInviteCode:
    (
      companyInviteCode: string,
      firstName: string,
      lastName: string,
      emailOrPhoneNumber: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        try {
          const res = await axios.post(
            `${config.AUTHORITY_API_URL}subscriptions/subscribeByCompanyInviteCode`,
            {
              companyInviteCode,
              subscriptionType: UserClaims.TrainingEmployeeSubscription,
              firstName,
              lastName,
              emailOrPhoneNumber
            }
          );
          dispatch({
            type: EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
            employeeAssociated: res.data
              ? TransactionStatusEnum.Successfull
              : TransactionStatusEnum.Failed
          });
          dispatch(appActions.setIsLoading(false));
        } catch (e) {
          dispatch({
            type: EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
            employeeAssociated: TransactionStatusEnum.Failed
          });
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployees:
    (companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.AUTHORITY_API_URL}employee/employee-list?companyId=${companyId}`
        );

        dispatch({
          type: EMPLOYEE_SET_EMPLOYEES,
          employees: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  requestEmployeeGroups:
    (companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.AUTHORITY_API_URL}employee-groups/${companyId}`
        );

        dispatch({
          type: EMPLOYEE_SET_EMPLOYEE_GROUPS,
          employeeGroups: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  requestEmployeeSupervisors:
    (companyId: string, employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.AUTHORITY_API_URL}org/hierarchy/supervisors?companyId=${companyId}&employeeId=${employeeId}`
        );

        dispatch({
          type: EMPLOYEE_SUPERVISORS,
          employeeSupervisors: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  requestEmployeesWorkload:
    (companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeworkloads/by-company/${companyId}`
          );

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEES_WORKLOAD,
            employeeWorkload: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setGroupWorkload:
    (employeeList: string[], lesssonPerDay: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let resStatus = 0;
        try {
          const res = await axios.put(
            `${config.STUDENT_API_URL}employeeworkloads/batch/${lesssonPerDay}`,
            employeeList
          );

          resStatus = res.status;
          const resGet = await axios.get(
            `${config.STUDENT_API_URL}employeeLearningProfile/all`
          );

          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: resGet.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setEmployeeWorkload:
    (employeeId: string, lesssonPerDay: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let resStatus = 0;
        try {
          const res = await axios.put(
            `${config.STUDENT_API_URL}employeeworkloads/${employeeId}/${lesssonPerDay}`,
            undefined
          );

          resStatus = res.status;
          if (resStatus == HttpStatusEnum.OK) {
            const resGet = await axios.get(
              `${config.STUDENT_API_URL}employeeLearningProfile/all`
            );

            dispatch({
              type: EMPLOYEE_SET_LEARNING_PROFILE,
              employeesLearningProfile: resGet.data
            });
          }
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployeesLearningProfile:
    (): AppThunk<Promise<void>> => async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}employeeLearningProfile/all`
          );

          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployeesLearningProfileWithParams:
    (searchKey: string, sortKey: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();
      let url = `${config.STUDENT_API_URL}employeeLearningProfile/all`;

      if (searchKey !== '' && sortKey === '') {
        url += `?searchKey=${searchKey}`;
      }

      if (searchKey !== '' && sortKey !== '') {
        url += `?searchKey=${searchKey}&sortBy=${sortKey}`;
      }

      if (searchKey === '' && sortKey !== '') {
        url += `?sortBy=${sortKey}`;
      }

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(url);

          dispatch({
            type: EMPLOYEE_SET_LEARNING_PROFILE,
            employeesLearningProfile: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },

  requestEmployeeAssignmentsStatus:
    (
      assignmentType: string,
      filterGroupId?: string,
      filterSupervisorId?: string
    ): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch({
          type: EMPLOYEE_DATA_IS_LOADING,
          dataIsLoading: true
        });

        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}assignments/${assignmentType}/status?filterGroupId=${filterGroupId}&filterSupervisorId=${filterSupervisorId}`
          );

          dispatch({
            type: EMPLOYEE_SET_ASSIGNMENTS_STATUS,
            employeeAssignmentsStatus: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: EMPLOYEE_DATA_IS_LOADING,
            dataIsLoading: false
          });
        }
      }
    },
  setEmployeeStartTime:
    (employeeId: string, dailyStartTimeMinutesUtc: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let resStatus = 0;
        try {
          const res = await axios.put(
            `${config.STUDENT_API_URL}employeetimeshiftstart/${employeeId}/${dailyStartTimeMinutesUtc}`,
            undefined
          );

          resStatus = res.status;
          if (resStatus == HttpStatusEnum.OK) {
            const resGet = await axios.get(
              `${config.STUDENT_API_URL}employeeLearningProfile/all`
            );

            dispatch({
              type: EMPLOYEE_SET_LEARNING_PROFILE,
              employeesLearningProfile: resGet.data
            });
          }
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  setGroupStartTime:
    (employeeList: string[], startTimeMinutes: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        let resStatus = 0;
        try {
          const res = await axios.put(
            `${config.STUDENT_API_URL}employeetimeshiftstart/batch/${startTimeMinutes}`,
            employeeList
          );

          resStatus = res.status;
          if (resStatus === HttpStatusEnum.OK) {
            const resGet = await axios.get(
              `${config.STUDENT_API_URL}employeeLearningProfile/all`
            );

            dispatch({
              type: EMPLOYEE_SET_LEARNING_PROFILE,
              employeesLearningProfile: resGet.data
            });
          }
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch({
            type: EMPLOYEE_UPDATE_TRANSACTION_STATUS,
            transactionStatus:
              resStatus === HttpStatusEnum.OK
                ? TransactionStatusEnum.Successfull
                : TransactionStatusEnum.Failed,
            errorMessage: ''
          });
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  updateFirsLoginAt:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.put(
            `${config.AUTHORITY_API_URL}employee/updateFirstTrainingLogin`,
            {
              employeeId
            }
          );
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  resetTransactionStatus: () => ({
    type: EMPLOYEE_RESET_TRANSACTION_STATUS
  }),
  resetUpdatePassword: () => ({
    type: RESET_UPDATE_PASSWORD
  }),
  resetEmployeeAssociateWithCompanyInviteCodeAction: () => ({
    type: RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE
  }),
  requestEmployeeGroupsByGroupLeadId:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.AUTHORITY_API_URL}employee-groups/${employeeId}/byGroupLeadId`
          );

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEE_GROUPS,
            employeeGroups: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployeesAndGroupsByGroupLeadId:
    (employeeId: string, companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.AUTHORITY_API_URL}employee-groups/include/employees/${employeeId}/${companyId}/byGroupLeadId`
          );

          const employees: Employee[] = [];
          res.data.forEach((group: any) => {
            group.employees
              .filter((e: Employee) => e.hasTrainingSubscription)
              .forEach((employee: Employee) => {
                if (!employees.find(item => item.id === employee.id)) {
                  employees.push({
                    ...employee,
                    groups: res.data.filter((gp: any) =>
                      gp.employees.some((emp: any) => emp.id === employee.id)
                    )
                  });
                }
              });
          });

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEES,
            employees
          });

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEE_GROUPS,
            employeeGroups: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployeesThatReportToSupervisor:
    (supervisorId: string, companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const res = await axios.get(
            `${config.AUTHORITY_API_URL}employee/reportTo/${companyId}/supervisor/${supervisorId}`
          );

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEES,
            employees: res.data
              .filter((emp: Employee) => emp.hasTrainingSubscription)
              .map((employee: Employee) => ({
                ...employee,
                groups: []
              }))
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestEmployeesByGroupLeadAndBySupervisor:
    (employeeId: string, companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        try {
          const response1 = await axios.get(
            `${config.AUTHORITY_API_URL}employee-groups/include/employees/${employeeId}/${companyId}/byGroupLeadId`
          );

          let employees: Employee[] = [];
          response1.data.forEach((group: any) => {
            group.employees
              .filter((e: Employee) => e.hasTrainingSubscription)
              .forEach((employee: Employee) => {
                if (!employees.find(item => item.id === employee.id)) {
                  employees.push({
                    ...employee,
                    groups: response1.data.filter((gp: any) =>
                      gp.employees.some((emp: any) => emp.id === employee.id)
                    )
                  });
                }
              });
          });

          const response2 = await axios.get(
            `${config.AUTHORITY_API_URL}employee/reportTo/${companyId}/supervisor/${employeeId}`
          );

          const employeesReportToSupervisor = response2.data
            .filter((emp: Employee) => emp.hasTrainingSubscription)
            .map((employee: Employee) => ({
              ...employee,
              groups: []
            }));

          employees = employees.length
            ? employees.concat(
                employeesReportToSupervisor.filter(
                  (employee: Employee) =>
                    !employees.some(emp => emp.id === employee.id)
                )
              )
            : employeesReportToSupervisor;

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEES,
            employees
          });

          dispatch({
            type: EMPLOYEE_SET_EMPLOYEE_GROUPS,
            employeeGroups: response1.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    },
  requestActiveEmployees:
    (companyId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));

        const res = await axios.get(
          `${config.AUTHORITY_API_URL}employee/?companyId=${companyId}&search=&areActive=true`
        );

        dispatch({
          type: EMPLOYEE_SET_ACTIVE_EMPLOYEES,
          activeEmployees: res.data
        });

        dispatch(appActions.setIsLoading(false));
      }
    },
  setActiveEmployees: (employees: Employee[] | null) => ({
    type: EMPLOYEE_SET_ACTIVE_EMPLOYEES,
    activeEmployees: employees
  })
};

export type KnownAction =
  | UpdatePasswordAction
  | ResetUpdatePasswordAction
  | RequestEmployeesAction
  | RequestEmployeeGroupsAction
  | RequestEmployeesWorkloadAction
  | UpdateEmployeeTransactionStatusAction
  | ResetEmployeeTransactionStatusAction
  | RequestEmployeeLearningProfileAction
  | RequestEmployeeAssignmentsStatusAction
  | EmployeeDataIsLoadingAction
  | SetLoadingAction
  | EmployeeAssociateWithCompanyInviteCodeAction
  | ResetEmployeeAssociateWithCompanyInviteCodeAction
  | requestEmployeeSupervisors
  | requestActiveEmployees;

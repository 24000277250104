import { Action } from 'redux';
import { KnownAction } from '../actions/achievements';
import {
  ACHIEVEMENTS_SET_IS_LOADING,
  ACHIEVEMENTS_SET_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_SELECT_MY_COMPETENCE,
  ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
  ACHIEVEMENTS_GET_ALL,
  ACHIEVEMENTS_SELECT_COMPETENCE,
  CLEAN_ALL_COMPETENCES,
  ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS,
  ACHIEVEMENTS_FORCE_GET_ALL,
  SET_COMPETENCE_ATTACHMENTS,
  CLEAN_COMPETENCE_ATTACHMENTS,
  SET_COMPETENCE_ATTACHMENT_REMOVED,
  ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL,
  COMPETENCIES_NOT_ASSIGNED,
  COMPETENCIES_ASSIGNED_BY_ID,
  ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE,
  ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT,
  ACHIEVEMENTS_LOADING_EMPLOYEE_INFO,
  ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE,
  ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA,
  ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA,
  ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT,
  ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE,
  ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE
} from '../actions/actionTypes';
import {
  Competence,
  CompetencePaginatedListItem,
  LearningUnit,
  CompetenceAttachment,
  EmployeeWithAssignedSkill,
  EmployeeCompetence,
  EmployeeAnalyticsCompetence
} from '../entities/Achievements';
import { PagedResult } from '../entities/Common';
import { createSelector } from 'reselect';
import { ApplicationState } from '../store';

export interface AchievementsState {
  myCompetences: PagedResult<CompetencePaginatedListItem> | null;
  myCompetencesAnalytics: PagedResult<EmployeeAnalyticsCompetence> | null;
  selectedCompetence: CompetencePaginatedListItem | null;
  competences: PagedResult<Competence> | null;
  competencesById: Record<string, Competence>;
  competenceAttachments: CompetenceAttachment[] | null;
  availableLearningUnits: LearningUnit[] | null;
  competenciesNotAssigned: any | undefined;
  competenciesAssignedById: any | undefined;
  forceGetAllCompetencies: boolean;
  employeesWithAssignedSkill: EmployeeWithAssignedSkill[] | null;
  isLoading: boolean;
  employeesByCompetence: PagedResult<EmployeeCompetence> | null;
  loadingEmployeeInfo: boolean;
}

const unloadedState: AchievementsState = {
  myCompetences: null,
  myCompetencesAnalytics: null,
  selectedCompetence: null,
  competences: null,
  competencesById: {},
  competenceAttachments: null,
  availableLearningUnits: null,
  competenciesNotAssigned: undefined,
  competenciesAssignedById: undefined,
  forceGetAllCompetencies: false,
  employeesWithAssignedSkill: null,
  isLoading: false,
  employeesByCompetence: null,
  loadingEmployeeInfo: false
};

export const AchievementsReducer = (
  state: AchievementsState,
  incomingAction: Action
): AchievementsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ACHIEVEMENTS_SET_MY_COMPETENCES_DATA:
      return {
        ...state,
        myCompetences: action.myCompetences
      };
    case ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA: {
      const items = state.myCompetences
        ? state.myCompetences.pageItems
            .slice()
            .concat(action.myCompetences!.pageItems)
        : action.myCompetences!.pageItems;

      return {
        ...state,
        myCompetences: {
          ...action.myCompetences!,
          pageItems: items,
          totalItems: items.length
        }
      };
    }
    case ACHIEVEMENTS_SELECT_MY_COMPETENCE:
      return {
        ...state,
        selectedCompetence: action.selectedCompetence
      };
    case ACHIEVEMENTS_GET_ALL: {
      let competences = action.competences;

      if (state.competences) {
        const items = [
          ...state.competences.pageItems,
          ...(action.competences?.pageItems || [])
        ];
        competences = action.competences
          ? {
              ...action.competences,
              pageItems: items,
              totalItems: items.length
            }
          : state.competences;
      }
      return {
        ...state,
        competences: competences
      };
    }
    case ACHIEVEMENTS_SELECT_COMPETENCE:
      return {
        ...state,
        competencesById: {
          ...state.competencesById,
          [action.competence.id]: action.competence
        }
      };
    case CLEAN_ALL_COMPETENCES:
      return {
        ...state,
        competences: null
      };
    case ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS:
      return {
        ...state,
        availableLearningUnits: action.availableLearningUnits
      };
    case COMPETENCIES_NOT_ASSIGNED:
      return {
        ...state,
        competenciesNotAssigned: action.competenciesNotAssigned || undefined
      };
    case COMPETENCIES_ASSIGNED_BY_ID:
      return {
        ...state,
        competenciesAssignedById: action.competenciesAssignedById || undefined
      };
    case ACHIEVEMENTS_FORCE_GET_ALL:
      return {
        ...state,
        forceGetAllCompetencies: action.forceGetAllCompetencies
      };
    case SET_COMPETENCE_ATTACHMENTS:
      return {
        ...state,
        competenceAttachments: action.competenceAttachments
      };
    case CLEAN_COMPETENCE_ATTACHMENTS:
      return {
        ...state,
        competenceAttachments: null
      };
    case SET_COMPETENCE_ATTACHMENT_REMOVED:
      return {
        ...state,
        competenceAttachments: state.competenceAttachments
          ? state.competenceAttachments.filter(
              item => item.id !== action.competenceAttachment.id
            )
          : [],
        competencesById: {
          ...state.competencesById,
          [action.competenceAttachment.competenceId]: {
            ...state.competencesById[action.competenceAttachment.competenceId],
            attachments:
              state.competencesById[
                action.competenceAttachment.competenceId
              ].attachments.slice(1)
          }
        }
      };
    case ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL:
      return {
        ...state,
        employeesWithAssignedSkill: action.employeesWithAssignedSkill
      };
    case ACHIEVEMENTS_SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE:
      return {
        ...state,
        employeesByCompetence: action.employees,
        loadingEmployeeInfo: false
      };
    case ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT: {
      let employeeUpdated = state.employeesByCompetence?.pageItems.find(
        employee => employee.employeeId === action.employee
      );

      if (!employeeUpdated) {
        return state;
      }

      employeeUpdated = {
        ...employeeUpdated,
        attachments:
          (employeeUpdated.attachments || []).concat(action.attachment) || []
      };

      const newEmployees = {
        ...state.employeesByCompetence,
        pageItems: [
          ...(state.employeesByCompetence?.pageItems?.filter(
            (item: EmployeeCompetence) => item.employeeId !== action.employee
          ) || []),
          employeeUpdated
        ],
        page: state.employeesByCompetence?.page || 0,
        pageSize: state.employeesByCompetence?.pageSize || 50,
        hasNextPage: state.employeesByCompetence?.hasNextPage || false,
        totalItems: state.employeesByCompetence?.totalItems || 0
      };

      return {
        ...state,
        employeesByCompetence: newEmployees,
        loadingEmployeeInfo: false
      };
    }
    case ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE:
      if (state.employeesByCompetence?.pageItems) {
        return {
          ...state,
          employeesByCompetence: {
            ...state.employeesByCompetence,
            pageItems: state.employeesByCompetence?.pageItems?.filter(
              employee => employee.id !== action.employee
            )
          },
          loadingEmployeeInfo: false
        };
      }
      return state;
    case ACHIEVEMENTS_LOADING_EMPLOYEE_INFO: {
      return {
        ...state,
        loadingEmployeeInfo: true
      };
    }
    case ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA: {
      return {
        ...state,
        myCompetencesAnalytics: action.myCompetencesAnalytics,
        loadingEmployeeInfo: false
      };
    }
    case ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA: {
      const itemsAnalytics = state.myCompetencesAnalytics
        ? state.myCompetencesAnalytics.pageItems
            .slice()
            .concat(action.myCompetencesAnalytics!.pageItems)
        : action.myCompetencesAnalytics!.pageItems;

      return {
        ...state,
        myCompetencesAnalytics: {
          ...action.myCompetencesAnalytics!,
          pageItems: itemsAnalytics,
          totalItems: itemsAnalytics.length
        }
      };
    }
    case ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA: {
      return {
        ...state,
        myCompetencesAnalytics: null
      };
    }
    case ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA:
      if (state.myCompetencesAnalytics?.pageItems) {
        return {
          ...state,
          myCompetencesAnalytics: {
            ...state.myCompetencesAnalytics,
            pageItems: state.myCompetencesAnalytics?.pageItems?.filter(
              employee => employee.id !== action.employee
            )
          },
          loadingEmployeeInfo: false
        };
      }
      return state;

    case ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT: {
      let employeeAnalyticsUpdated =
        state.myCompetencesAnalytics?.pageItems.find(
          employee => employee.id === action.employee
        );

      if (!employeeAnalyticsUpdated) {
        return state;
      }

      employeeAnalyticsUpdated = {
        ...employeeAnalyticsUpdated,
        employeeCompetenceAttachments:
          (employeeAnalyticsUpdated.employeeCompetenceAttachments || []).concat(
            action.employeeCompetenceAttachments
          ) || []
      };

      const newEmployeesAnalytics = {
        ...state.myCompetencesAnalytics,
        pageItems: [
          ...(state.myCompetencesAnalytics?.pageItems?.filter(
            (item: EmployeeAnalyticsCompetence) => item.id !== action.employee
          ) || []),
          employeeAnalyticsUpdated
        ],
        page: state.myCompetencesAnalytics?.page || 0,
        pageSize: state.myCompetencesAnalytics?.pageSize || 50,
        hasNextPage: state.myCompetencesAnalytics?.hasNextPage || false,
        totalItems: state.myCompetencesAnalytics?.totalItems || 0
      };

      return {
        ...state,
        myCompetencesAnalytics: newEmployeesAnalytics,
        loadingEmployeeInfo: false
      };
    }

    case ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE: {
      // Get the employeeCompetence to update
      const indexUpdatedCompetence =
        state.myCompetencesAnalytics?.pageItems.findIndex(
          item => item.id === action.employeeCompetence.id
        );
      if (indexUpdatedCompetence && state.myCompetencesAnalytics?.pageItems) {
        const employeeAnalyticsUpdated =
          state.myCompetencesAnalytics?.pageItems.map((item, index) =>
            index === indexUpdatedCompetence ? action.employeeCompetence : item
          );

        const newEmployeesAnalytics = {
          ...state.myCompetencesAnalytics,
          pageItems: employeeAnalyticsUpdated,
          page: state.myCompetencesAnalytics?.page || 0,
          pageSize: state.myCompetencesAnalytics?.pageSize || 50,
          hasNextPage: state.myCompetencesAnalytics?.hasNextPage || false,
          totalItems: state.myCompetencesAnalytics?.totalItems || 0
        };

        return {
          ...state,
          myCompetencesAnalytics: newEmployeesAnalytics,
          loadingEmployeeInfo: false
        };
      } else {
        return state;
      }
    }
    case ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE: {
      let removeEmployeeAttachment =
        state.myCompetencesAnalytics?.pageItems.find(
          employee => employee.id === action.elementId
        );

      if (!removeEmployeeAttachment) {
        return state;
      }

      removeEmployeeAttachment = {
        ...removeEmployeeAttachment,
        employeeCompetenceAttachments:
          (removeEmployeeAttachment.employeeCompetenceAttachments || []).slice(
            1
          ) || []
      };

      const removeEmployeesAnalytics = {
        ...state.myCompetencesAnalytics,
        pageItems: [
          ...(state.myCompetencesAnalytics?.pageItems?.filter(
            (item: EmployeeAnalyticsCompetence) => item.id !== action.elementId
          ) || []),
          removeEmployeeAttachment
        ],
        page: state.myCompetencesAnalytics?.page || 0,
        pageSize: state.myCompetencesAnalytics?.pageSize || 50,
        hasNextPage: state.myCompetencesAnalytics?.hasNextPage || false,
        totalItems: state.myCompetencesAnalytics?.totalItems || 0
      };

      return {
        ...state,
        myCompetencesAnalytics: removeEmployeesAnalytics,
        loadingEmployeeInfo: false
      };
    }
    default:
      return state;
  }
};

const base = createSelector(
  (state: ApplicationState) => state,
  state => state.achievements
);
export const selectAvailableLearningUnits = createSelector(
  base,
  state => state.availableLearningUnits
);
export const selectAvailableCourses = createSelector(
  selectAvailableLearningUnits,
  availableLearningUnits =>
    availableLearningUnits?.filter(
      learningUnit => learningUnit.learningUnitType === 'Course'
    ) ?? []
);

import { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AssignmentTypes } from '../../entities/LearningPlaylist';
import AssignmentCardList from './AssignmentCardList';
import AllAssignmentsTabContainer from './AssignmentsTabContainer';

export interface DueSoonAssignmentsTabProps {
  dueDateLearningUnits?: Array<AssignmentTypes>;
  assignmentTypeFilter: string;
}

const DueSoonAssignmentsTab: React.FC<DueSoonAssignmentsTabProps> = ({
  dueDateLearningUnits,
  assignmentTypeFilter
}) => {
  const [dueSoonAssignedLearningUnits, setDueSoonAssignedLearningUnits] =
    useState<Array<AssignmentTypes>>();
  const [learningUnitsToShow, setLearningUnitsToShow] =
    useState<Array<AssignmentTypes>>();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    if (dueDateLearningUnits) {
      const today = moment().startOf('day');
      const next30daysDate = moment().add(30, 'd').endOf('day');

      let dueSoonAssigned = dueDateLearningUnits.filter(
        learningUnit =>
          moment(learningUnit.dueDateMoment) >= today &&
          moment(learningUnit.dueDateMoment) <= next30daysDate &&
          !learningUnit.isComplete
      );

      dueSoonAssigned = dueSoonAssigned.sort((a, b) =>
        a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );

      setDueSoonAssignedLearningUnits(dueSoonAssigned);
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        dueSoonAssigned
      );

      filteredLearningUnits.sort((a: AssignmentTypes, b: AssignmentTypes) =>
        a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );

      setLearningUnitsToShow(filteredLearningUnits);
    }
  }, [dueDateLearningUnits]);

  useEffect(() => {
    if (dueSoonAssignedLearningUnits) {
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        dueSoonAssignedLearningUnits
      );
      setLearningUnitsToShow(filteredLearningUnits);
    }
  }, [assignmentTypeFilter]);

  const filterByAssignmentType = (
    assignmentType: string,
    source: Array<AssignmentTypes>
  ) => {
    return source.filter(
      learningUnit =>
        learningUnit.assignmentType === assignmentType || assignmentType === ''
    );
  };

  return (
    <AllAssignmentsTabContainer
      isLoading={!dueSoonAssignedLearningUnits}
      isEmpty={
        !!dueSoonAssignedLearningUnits && !dueSoonAssignedLearningUnits.length
      }
      listIsReady={
        !!dueSoonAssignedLearningUnits && !!dueSoonAssignedLearningUnits.length
      }
      emptyText={t('youDontAnyAssignmentOverdueNextMonth', {
        ns: 'assignments'
      })}
    >
      <AssignmentCardList assignedLearningUnits={learningUnitsToShow!} />
    </AllAssignmentsTabContainer>
  );
};

export default DueSoonAssignmentsTab;

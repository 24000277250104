import { useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { ApplicationState } from '../../../store';
import { HaltsDataGrid } from '../../../components/Settings';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  getButtonColumn,
  getFormatedValueColumn
} from '../../analytics/common/utilAnalyticsColumnDefinition';
import { actionCreators as haltActions } from '../../../actions/halts';
import { HaltType } from '../../../core/enums';
import { useCalendarHelper } from '../../../hooks/useCalendarHelper';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { Halt } from '../../../entities/Halt';
import { SettingsContext } from '../../../contexts/SettingsContext';
import { useAppDispatch } from '../../../store/hooks';

interface YourHaltsProps {
  searchKey: string;
}

const getFormattedDate = (params: GridRenderCellParams<any>) => {
  return (
    <span style={{ fontWeight: 600 }}>{params.row.formattedHaltDate}</span>
  );
};

const YourHalts = ({ searchKey }: YourHaltsProps) => {
  const dispatch = useAppDispatch();
  const { initDeleteHalt } = useContext(SettingsContext);
  const { t } = useTranslation(['settings']);
  const [haltsToshow, setHaltsToshow] = useState<Halt[]>();
  const yourHalts = useSelector(
    (state: ApplicationState) => state.halts?.yourHalts
  );
  const {
    constants: { monthNames }
  } = useCalendarHelper();

  const getFormattedHaltDate = (date: Moment) => {
    return `${monthNames[date.month()]} ${date.date()}th, ${date.year()}`;
  };

  const halts = useMemo(() => {
    if (yourHalts) {
      return yourHalts.map(halt => ({
        ...halt,
        formattedHaltDate: getFormattedHaltDate(
          absUtcDateToLocalMoment(halt.haltDateUtc)
        )
      }));
    }
  }, [yourHalts]);

  useEffect(() => {
    if (!yourHalts) {
      dispatch(haltActions.getEmployeeHalts(HaltType.PersonalHalt));
    }
  }, []);

  useEffect(() => {
    if (halts) setHaltsToshow(halts);
  }, [halts]);

  useEffect(() => {
    if (!yourHalts) return;

    setTimeout(() => {
      const filteredData = halts!.filter(halt =>
        halt.formattedHaltDate?.toLowerCase().includes(searchKey.toLowerCase())
      );
      setHaltsToshow(filteredData);
    }, 1000);
  }, [searchKey]);

  const handleInitDeleteHalt = async (params: GridRenderCellParams<any>) => {
    if (initDeleteHalt)
      initDeleteHalt(
        [params.row.id],
        'yourHalts',
        absUtcDateToLocalMoment(params.row.haltDateUtc)
      );
  };
  const columns: GridColDef[] = [
    getFormatedValueColumn('date', '', 200, getFormattedDate, false, 1),
    getButtonColumn(
      'id',
      '',
      t('removeHalt', { ns: 'settings' }),
      150,
      handleInitDeleteHalt
    )
  ];

  return (
    <>
      <HaltsDataGrid
        rowId="id"
        rows={haltsToshow}
        columns={columns}
        noRowsMessage={t('noHaltsToDisplay', { ns: 'settings' })}
      />
    </>
  );
};

export default YourHalts;

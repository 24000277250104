import React, { CSSProperties, FC } from 'react';
import GreenCheck from '../../assets/greenCheck.svg';
import Cross from '../../assets/cross.svg';
import { Lesson } from '../../entities/Lesson';
import { Row, Col, Container } from 'react-bootstrap';

const lessonTitle: CSSProperties = {
  padding: '16px',
  marginLeft: '16px',
  marginRight: '16px',
  marginBottom: '12px',
  marginTop: '16px',
  borderRadius: '7px',
  cursor: 'pointer'
};

const lessonTitleStyle: CSSProperties = {
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: '0.15px'
};

const stylelessonSelected: CSSProperties = {
  backgroundColor: '#fa9e04',
  color: '#0E0D0D'
};

const stylelessonTitleProgress: CSSProperties = {
  backgroundColor: '#44d47a',
  border: '2px solid #44d47a'
};

const styleQuizFailedProgress: CSSProperties = {
  border: '2px solid #FF2A20'
};

const separator: CSSProperties = {};

const styleCheck: CSSProperties = {
  marginTop: '8px'
};

const styleTypeLesson: CSSProperties = {
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal'
};

const styleColorType: CSSProperties = {
  color: '#C4C4C4'
};

const styleColorTypeSelected: CSSProperties = {
  color: '#0E0D0D'
};

export interface TinyLessonCardProps {
  lesson: Lesson;
  onClick: () => void;
  isCompleted: boolean;
  index: number;
  activeLesson: string;
  listFailedQuiz?: string[];
}

export const TinyLessonCard: FC<TinyLessonCardProps> = ({
  lesson,
  onClick,
  isCompleted,
  activeLesson,
  listFailedQuiz
}) => {
  const isFailed =
    listFailedQuiz?.includes(lesson.assessmentId!) && !isCompleted;

  const stylelessonTitle: CSSProperties = {
    backgroundColor: isCompleted ? '#1B1C1C' : '#3E3E3E'
  };

  const getLessonTitleStyle = () => {
    if (isCompleted) {
      return stylelessonTitleProgress;
    } else if (activeLesson === lesson.id) {
      return stylelessonSelected;
    } else if (lesson.lessonType === 'Quiz' && isFailed) {
      return styleQuizFailedProgress;
    } else {
      return stylelessonTitle;
    }
  };

  return (
    <React.Fragment key={lesson.id}>
      <div
        onClick={onClick}
        style={{ ...lessonTitle, ...getLessonTitleStyle() }}
      >
        <Container className="p-0">
          <Row className="justify-content-md-center">
            {isCompleted && !isFailed && (
              <Col xs={2} md="auto">
                <img style={styleCheck} src={GreenCheck} alt="check icon" />
              </Col>
            )}
            {lesson.lessonType === 'Quiz' && isFailed && (
              <Col xs={10} md="auto">
                <img style={styleCheck} src={Cross} alt="cross icon fail" />
              </Col>
            )}
            <Col>
              <span style={lessonTitleStyle}>{lesson.title}</span>
              <div>
                <span></span>
                <span
                  className="mt-1 align-top"
                  style={{
                    ...styleTypeLesson,
                    ...(activeLesson === lesson.id
                      ? styleColorTypeSelected
                      : styleColorType)
                  }}
                >
                  {lesson.lessonType === 'PDF' ? 'File' : lesson.lessonType}
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div style={separator}></div>
    </React.Fragment>
  );
};

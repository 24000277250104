import {
  GreenSpan,
  OrangeSpan,
  RedSpan
} from '../../pages/analytics/common/StyledSpan';

export interface PercentageIndicatorProps {
  percent: number;
  formattedText: string;
}
export const PercentageIndicator: React.FC<PercentageIndicatorProps> = ({
  percent,
  formattedText
}) => {
  if (percent < 50) {
    return <RedSpan>{formattedText}</RedSpan>;
  } else if (percent < 80) {
    return <OrangeSpan>{formattedText}</OrangeSpan>;
  } else {
    return <GreenSpan>{formattedText}</GreenSpan>;
  }
};

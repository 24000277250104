import { useTheme } from '@mui/material';
import { FC } from 'react';
import DefaultProfile from '../../../assets/user.png';

interface InfoBySpanProps {
  leftText: string;
  rightText: string;
  showProfilePicture: boolean;
  isOverdue?: boolean;
  fontSize?: string;
  profilePicture?: string;
  showAsComplete?: boolean;
}

const InfoBySpan: FC<InfoBySpanProps> = ({
  leftText,
  rightText,
  showProfilePicture,
  isOverdue = false,
  fontSize = '14px',
  profilePicture,
  showAsComplete = false
}) => {
  const theme = useTheme();
  let color = isOverdue ? theme.palette.error.main : theme.palette.primary.main;

  if (showAsComplete) {
    color = theme.palette.success.main;
  }

  return (
    <div className="d-flex">
      {showProfilePicture && (
        <img
          className={`img-profile rounded-circle`}
          width="35"
          height="35"
          alt="img"
          src={profilePicture ? profilePicture : DefaultProfile}
        />
      )}
      <div
        className={
          showProfilePicture
            ? 'd-flex flex-column px-2 pt-1'
            : 'd-flex flex-column'
        }
      >
        <span style={{ color: theme.palette.common.white, fontSize: fontSize }}>
          {leftText}:{' '}
          <span
            style={{
              color: color,
              fontSize: fontSize
            }}
          >
            {rightText}
          </span>
        </span>
      </div>
    </div>
  );
};

export default InfoBySpan;

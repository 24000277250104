declare let window: any;

const config = {
  AUTHORITY: window.AppConfig ? window.AppConfig.IDENTITY_URL : '',
  AUTHORITY_API_URL: window.AppConfig
    ? window.AppConfig.IDENTITY_URL + '/api/'
    : '',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE,
  REDIRECT_URI: window.AppConfig ? window.AppConfig.APP_URL + '/' : '',
  SILENT_REDIRECT_URI: window.AppConfig
    ? window.AppConfig.APP_URL + '/silentrenew'
    : '',
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  SCOPES: process.env.REACT_APP_SCOPES,
  COURSES_API_URL: window.AppConfig
    ? window.AppConfig.COURSE_API_URL + '/api/v1/'
    : '',
  STUDENT_API_URL: window.AppConfig
    ? window.AppConfig.STUDENT_API_URL + '/api/v1/'
    : '',
  ASSESSMENT_API_URL: window.AppConfig
    ? window.AppConfig.ASSESSMENT_API_URL + '/api/v1/'
    : '',
  LEADERS_API_URL: window.AppConfig
    ? window.AppConfig.LEADERS_API_URL + '/api/v1/'
    : '',
  LOGOUT_CALLBACK: window.AppConfig ? window.AppConfig.APP_URL + '/' : '',
  IDENTITY_URL: window.AppConfig ? window.AppConfig.IDENTITY_URL : '',
  ENVIRONMENT: window.AppConfig ? window.AppConfig.ENVIRONMENT : '',
  BLOB_SAS_TOKEN: process.env.REACT_APP_BLOB_SAS_TOKEN,
  BLOB_COURSEIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_COURSEIMAGES,
  BLOB_LESSONIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LESSONIMAGES,
  BLOB_LESSONSUBTITLES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LESSONSUBTITLES,
  BLOB_LEARNINGPLANIMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_LEARNINGPLANIMAGES,
  BLOB_LESSONDOCS_CONTAINER: process.env.REACT_APP_BLOB_CONTAINER_LESSONDOCS,
  BLOB_STORAGE_ACCOUNT_NAME: process.env.REACT_APP_BLOB_STORAGE_ACCOUNT_NAME,
  COURSES_BASE_URL: window.AppConfig ? window.AppConfig.COURSE_API_URL : '',
  BLOB_STORAGE_COLORCONTAINER_LIST_KEY:
    process.env.REACT_APP_BLOB_STORAGE_COLORCONTAINER_LIST_KEY,
  BLOB_STORAGE_BWCONTAINER_LIST_KEY:
    process.env.REACT_APP_BLOB_STORAGE_BWCONTAINER_LIST_KEY,
  BLOB_LESSONGALLERY_COLORCONTAINER:
    process.env.REACT_APP_BLOB_LESSONGALLERY_COLORCONTAINER,
  BLOB_LESSONGALLERY_BWCONTAINER:
    process.env.REACT_APP_BLOB_LESSONGALLERY_BWCONTAINER,
  CL_PARTNER: window.AppConfig ? window.AppConfig.CL_PARTNER === 'true' : false,
  YOUTUBE_API_KEY: window.AppConfig ? window.AppConfig.YOUTUBE_API_KEY : '',
  WELCOME_VIDEO_URL: window.AppConfig ? window.AppConfig.WELCOME_VIDEO_URL : '',
  SUPERVISORS_FILTER: window.AppConfig
    ? window.AppConfig.SUPERVISORS_FILTER === 'true'
    : false,
  RECENTLY_WORKED_ON_LIST: window.AppConfig
    ? window.AppConfig.RECENTLY_WORKED_ON_LIST === 'true'
    : false,
  USE_ORG_CHART: window.AppConfig
    ? window.AppConfig.USE_ORG_CHART === 'true'
    : false,
  ENGAGEMENT_ANALYTICS: window.AppConfig
    ? window.AppConfig.ENGAGEMENT_ANALYTICS === 'true'
    : false,
  QUIZ_ANALYTICS: window.AppConfig
    ? window.AppConfig.QUIZ_ANALYTICS === 'true'
    : false,
  NEW_SETTINGS: window.AppConfig
    ? window.AppConfig.NEW_SETTINGS === 'true'
    : false,
  SETTINGS_CALENDAR: window.AppConfig
    ? window.AppConfig.SETTINGS_CALENDAR === 'true'
    : false,
  ACHIEVEMENTS_API_URL: window.AppConfig
    ? window.AppConfig.ACHIEVEMENTS_API_URL + '/api/v1/'
    : '',
  BLOB_STORAGE_ACCOUNT: window.AppConfig
    ? window.AppConfig.BLOB_STORAGE_ACCOUNT
    : '',
  BLOB_STORAGE_SAS_TOKEN: window.AppConfig
    ? window.AppConfig.BLOB_STORAGE_SAS_TOKEN
    : '',
  BLOB_SKILLATTACHMENTS_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_SKILLATTACHMENTS,
  UPLOADPOLICYLINK: process.env.REACT_APP_UPLOADPOLICYURL,
  ONSITE_TRAINING: window.AppConfig
    ? window.AppConfig.ONSITE_TRAINING === 'true'
    : false,
  BLOB_ONSITE_TRAINING_ATTACHMENTS_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_ONSITE_TRAINING_ATTACHMENTS,
  BLOB_ONSITE_TRAINING_IMAGES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_ONSITE_TRAINING_IMAGES,
  BLOB_ONSITE_TRAINING_SIGNATURES_CONTAINER:
    process.env.REACT_APP_BLOB_CONTAINER_ONSITE_TRAINING_SIGNATURES,
  BLOB_CONTAINER_COMPANY_LOGOS:
    process.env.REACT_APP_BLOB_CONTAINER_COMPANY_LOGOS,
  BLOB_BW_STORAGE_ACCOUNT: process.env.REACT_APP_BLOB_STORAGE_ACCOUNT_NAME,
  BLOB_BW_STORAGE_SAS_TOKEN: process.env.REACT_APP_BLOB_SAS_TOKEN,
  DEVCYCLE_SDK_KEY: window.AppConfig ? window.AppConfig.DEVCYCLE_SDK_KEY : '',
  MEDIA_SERVICE_BLOB_SAS_TOKEN:
    process.env.REACT_APP_MEDIA_SERVICE_BLOB_SAS_TOKEN,
  MEDIA_SERVICE_BLOB_STORAGE_ACCOUNT_NAME:
    process.env.REACT_APP_MEDIA_SERVICE_BLOB_STORAGE_ACCOUNT_NAME
};
export default config;

import {
  Grid,
  ThemeProvider,
  Typography,
  useTheme,
  Skeleton
} from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router';
import { useSelector } from 'react-redux';

import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import AssignEmployeesToOnsiteTraining from '../assignments/AssignEmployeesToOnsiteTraining';
import {
  OnsiteTraining,
  OnsiteTrainingClass
} from '../../../entities/OnsiteTraining';
import QRCodePanel from './QRCodePanel';
import ClassEmployeesPanel from './ClassEmployeesPanel';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { ApplicationState } from '../../../store';
import { OnsiteTrainingCheckInDto } from '../../../entities/OnsiteTraining';
import { getUser } from '../../../interceptors';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import config from '../../../config';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import moment from 'moment';
import { useAppDispatch } from '../../../store/hooks';

export const StyledGridContainer = styled(Grid)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '5px',
  backgroundColor: theme.palette.common.black,
  display: 'inline-table',
  minHeight: '680px'
}));

interface NavigationState {
  onsiteTraining?: OnsiteTraining;
  onsiteTrainingClass?: OnsiteTrainingClass;
}

const OnsiteTrainingCheckIn = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams<{ classId: string }>();
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['onsiteTraining', 'common']);
  const [showAddEmpsModal, setShowAddEmpsModal] = useState(false);
  const [parentOnsiteTraining, setParentOnsiteTraining] =
    useState<OnsiteTraining | null>(null);
  const [onsiteTrainingClass, setOnsiteTrainingClass] =
    useState<OnsiteTrainingClass | null>(null);
  const [showAddEmpsButton, setShowAddEmpsButton] = useState(false);

  const location = useLocation();
  const state = location.state as NavigationState;
  const onsiteTraining = state?.onsiteTraining;
  const stOnsiteTrainingClass = state?.onsiteTrainingClass;
  const currentDate = moment().format('MM.DD.YYYY');
  const user = getUser();
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const classAssignmentsPerday = useSelector(
    (state: ApplicationState) =>
      state.onsiteTrainings?.onsiteTrainingClassAssignmentsPerDay
  );
  const handleAssignEmployees = () => {
    setShowAddEmpsModal(true);
  };

  useEffect(() => {
    if (onsiteTraining) {
      setParentOnsiteTraining(onsiteTraining);
    }
  }, [onsiteTraining]);

  useEffect(() => {
    if (stOnsiteTrainingClass) {
      setOnsiteTrainingClass(stOnsiteTrainingClass);
      setShowAddEmpsButton(!stOnsiteTrainingClass.isFirstClassDayCompleted);
    }
  }, [stOnsiteTrainingClass]);
  const onsiteTrainingClassState = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingClass
  );

  useEffect(() => {
    connection?.on(
      'OnsiteTrainingCheckInMessage',
      (onsiteTrainingCheckIn: OnsiteTrainingCheckInDto) => {
        const assignmentsPerday = classAssignmentsPerday?.pageItems;
        const updatedAssignmentsPerday = assignmentsPerday?.map(item => {
          if (
            item.onsiteTrainingAssignmentDay.id ===
            onsiteTrainingCheckIn.onsiteTrainingAssignmentId
          ) {
            return {
              ...item,
              onsiteTrainingAssignmentDay: {
                ...item.onsiteTrainingAssignmentDay,
                attendance: onsiteTrainingCheckIn.attendance
              }
            };
          }
          return item;
        });

        dispatch(
          onsiteTrainingActions.setAllClassAssignmentsPerDay({
            ...classAssignmentsPerday!,
            pageItems: updatedAssignmentsPerday!
          })
        );
      }
    );
  }, [connection, classAssignmentsPerday]);

  useEffect(() => {
    const connector = new HubConnectionBuilder()
      .withUrl(`${config.STUDENT_API_URL}hubs/onsiteTrainingCheckIn`, {
        accessTokenFactory: () => {
          return user!.access_token!;
        }
      })
      .withAutomaticReconnect()
      .build();
    connector.start().catch(err => {
      document.write(err);
    });
    setConnection(connector);
    return () => {
      connector.stop();
      dispatch(onsiteTrainingActions.resetSingleOnsiteTrainingClass());
    };
  }, []);

  useEffect(() => {
    if (params.classId) {
      dispatch(
        onsiteTrainingActions.retrieveSingleOnsiteTrainingClass(params.classId)
      );
    }
  }, [dispatch, params.classId]);

  const fetchOnsitetrainingAssigments = async () => {
    const dayNumber = onsiteTrainingClassState!.assignments.map(
      (item, index) => {
        if (
          absUtcDateToLocalMoment(item.dateUtc).format('MM.DD.YYYY') ===
          currentDate
        ) {
          return index;
        }
      }
    );
    await dispatch<Promise<void>>(
      onsiteTrainingActions.retreiveAllClassAssignmentsPerDay(
        onsiteTrainingClassState!.onsiteTrainingClass?.id,
        dayNumber[0]! + 1,
        1,
        ''
      )
    );
  };

  const clickGoBack = () => {
    connection!.stop();
    navigate(-1);
  };
  const renderHeader = () => (
    <Grid
      container
      direction={{ xs: 'column', md: 'row' }}
      style={{
        justifyContent: 'space-between',
        marginTop: '15px',
        marginBottom: '15px'
      }}
      gap={2}
    >
      <Grid item>
        <GoBackOption
          textLink={t('back', { ns: 'common' })}
          onGoBack={() => clickGoBack()}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={7} lg={6} xl={5}>
        <Grid
          container
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="center"
        >
          {showAddEmpsButton ? (
            <>
              <Grid item xs={12} sm={6}>
                <BasicButton color="primary" style={{ width: '100%' }}>
                  <Typography
                    sx={[buttonLabel, { color: theme.palette.common.black }]}
                  >
                    {t('completeOnsiteTraining', { ns: 'onsiteTraining' })}
                  </Typography>
                </BasicButton>
              </Grid>

              <Grid item xs={12} sm={6}>
                <BasicButton
                  color="grayButton"
                  style={{ minWidth: '100%' }}
                  onClick={handleAssignEmployees}
                  disable={!parentOnsiteTraining || !onsiteTrainingClass}
                >
                  <Typography
                    sx={[buttonLabel, { color: theme.palette.common.black }]}
                  >
                    {t('addEmployeeTraining', { ns: 'onsiteTraining' })}
                  </Typography>
                </BasicButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <BasicButton color="primary" style={{ width: '100%' }}>
                  <Typography
                    sx={[buttonLabel, { color: theme.palette.common.black }]}
                  >
                    {t('completeOnsiteTraining', { ns: 'onsiteTraining' })}
                  </Typography>
                </BasicButton>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={theme}>
      {renderHeader()}
      <Grid container direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
          {!!onsiteTraining && !!stOnsiteTrainingClass && (
            <QRCodePanel
              onsiteTraining={onsiteTraining}
              onsiteTrainingClassId={stOnsiteTrainingClass.id}
            />
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={8}>
          {onsiteTrainingClassState?.onsiteTrainingClass.onsiteTraining ? (
            <ClassEmployeesPanel
              theme={theme}
              classObject={onsiteTrainingClassState}
            />
          ) : (
            <Skeleton animation="wave" height={600} width="100%" />
          )}
        </Grid>
      </Grid>
      {showAddEmpsModal && (
        <AssignEmployeesToOnsiteTraining
          onClose={async () => {
            setShowAddEmpsModal(false);
            await fetchOnsitetrainingAssigments();
          }}
          open={showAddEmpsModal}
          onsiteTraining={parentOnsiteTraining}
          onsiteTrainingClassId={onsiteTrainingClass!.id!}
        />
      )}
    </ThemeProvider>
  );
};

const buttonLabel: CSSProperties = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  fontSize: 14
};

export default OnsiteTrainingCheckIn;

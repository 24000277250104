import { CSSProperties, PropsWithChildren, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useTranslation } from 'react-i18next';
import {
  DashboardContentType,
  DashboardDetailsType
} from '../../../core/enums';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Employee } from '../../../entities/Employee';
import ProfilePicture from '../../../assets/profilePicture.png';
import CircleDownIcon from '../../../assets/circleArrowDown.svg';
import CircleUpIcon from '../../../assets/circleArrowUp.svg';
import { useNavigate } from 'react-router';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../CustomDataGrid/CustomDataGrid';

export interface OverLessonAssignmentModalProps {
  modalType: DashboardDetailsType;
  onClose: () => void;
  groupLabel?: string;
  supervisorLabel?: string;
  daysFilter?: string;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onDownloadingReport: (columns: GridColDef[]) => Promise<any>;
  isDownloadingReport: boolean;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const OverLessonAssignmentModal = ({
  modalType,
  onClose,
  groupLabel,
  supervisorLabel,
  daysFilter,
  page,
  pageSize,
  onPageChange,
  onDownloadingReport,
  isDownloadingReport
}: PropsWithChildren<OverLessonAssignmentModalProps>) => {
  const [expandEmployees, setExpandEmployees] = useState<
    Record<string, boolean>
  >({});
  const theme = useTheme();
  const navigate = useNavigate();
  const dashboardOverviewDetail = useSelector(
    (state: ApplicationState) => state?.student?.dashboardOverviewDetail
  );
  const loading = useSelector(
    (state: ApplicationState) => state?.student?.isLoading
  );
  const { t } = useTranslation(['dashboard']);
  const supervisorString =
    supervisorLabel == undefined || supervisorLabel == ''
      ? ''
      : `${supervisorLabel}, `;
  const groupString =
    groupLabel == undefined || groupLabel == '' ? '' : `${groupLabel}, `;
  const daysFilterString =
    daysFilter == '0'
      ? t('all', { ns: 'dashboard' })
      : `${t('lastIndividualDay', { ns: 'dashboard' })} ${daysFilter} ${t(
          'individualDays',
          { ns: 'dashboard' }
        )}`;

  const [rows, totalItems] = useMemo(() => {
    if (dashboardOverviewDetail) {
      const data = dashboardOverviewDetail[modalType as DashboardContentType];
      if (data) {
        const { pageItems, totalItems } = data;
        return [pageItems, totalItems];
      }
    }
    // eslint-disable-next-line no-sparse-arrays
    return [, 0];
  }, [dashboardOverviewDetail, modalType]);

  const mapColumnTitle: Record<DashboardDetailsType, string> = {
    [DashboardDetailsType.TotalCompletedLessons]: t(
      'numberOfUsersWithCompletedLessons',
      { ns: 'dashboard' }
    ),
    [DashboardDetailsType.TotalOverdueLessons]: t('numberOfUserWithOverdue', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.TotalAssignedLesson]: t(
      'numberOfUsersWithLessonAssignment',
      { ns: 'dashboard' }
    ),
    [DashboardDetailsType.None]: ''
  };

  const mapModalTitle: Record<DashboardDetailsType, string> = {
    [DashboardDetailsType.TotalCompletedLessons]: t(
      'totalCompletedLessonsTitle',
      { ns: 'dashboard' }
    ),
    [DashboardDetailsType.TotalOverdueLessons]: t('totalOverdueLessonsTitle', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.TotalAssignedLesson]: t('totalAssignedLessonsTitle', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.None]: ''
  };

  const mapModalSubTitle: Record<DashboardDetailsType, string> = {
    [DashboardDetailsType.TotalCompletedLessons]: `${supervisorString}${groupString}${daysFilterString}`,
    [DashboardDetailsType.TotalOverdueLessons]: `${supervisorString}${groupString}${daysFilterString}`,
    [DashboardDetailsType.TotalAssignedLesson]: `${supervisorString}${groupString}${daysFilterString}`,
    [DashboardDetailsType.None]: ''
  };

  const mapManageButtonTitle: Record<DashboardDetailsType, string> = {
    [DashboardDetailsType.TotalCompletedLessons]: t('manageCompletedLessons', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.TotalOverdueLessons]: t('manageOverdue', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.TotalAssignedLesson]: t('manageAssignedLesson', {
      ns: 'dashboard'
    }),
    [DashboardDetailsType.None]: ''
  };

  const mapUsersCountFieldColor = {
    [DashboardDetailsType.TotalCompletedLessons]: '#2ED47A',
    [DashboardDetailsType.TotalOverdueLessons]: '#FF2A20',
    [DashboardDetailsType.TotalAssignedLesson]: '#FF9E00',
    [DashboardDetailsType.None]: ''
  };

  const navigateToLearning = () =>
    navigate('/employees', {
      state: { tab: 'learning' }
    });

  const mapButtonActions = {
    [DashboardDetailsType.TotalCompletedLessons]: () => navigateToLearning(),
    [DashboardDetailsType.TotalOverdueLessons]: () => navigateToLearning(),
    [DashboardDetailsType.TotalAssignedLesson]: () => navigateToLearning(),
    [DashboardDetailsType.None]: () => undefined
  };

  const handlePageChange = (page: number) => {
    onPageChange(page + 1);
  };

  const getContentNameColumn = (headerName: string): GridColDef => ({
    field: 'LessonName',
    headerName: headerName,
    minWidth: 220,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <div style={{ paddingLeft: 20 }}>
        <span>{headerName}</span>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <Box display="flex" flexDirection="row">
            <div>
              <Typography style={{ fontSize: 14 }}>
                {params.row.lessonName}
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    )
  });

  const getEmployeesColumn = (headerName: string): GridColDef => ({
    field: 'EmployeesOverdue',
    headerName: headerName,
    minWidth: 220,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <div style={{ paddingLeft: 20 }}>
        <span>{headerName}</span>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        paddingLeft={2}
        paddingY={2}
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography
            fontWeight={600}
            fontSize={20}
            color={mapUsersCountFieldColor[modalType]}
          >
            {modalType === DashboardDetailsType.TotalCompletedLessons
              ? params.row.completedTimes
              : params.row.employees.length}
          </Typography>
          <IconButton
            aria-label="expand"
            onClick={() =>
              setExpandEmployees({
                ...expandEmployees,
                [params.row.id]: !expandEmployees[params.row.id]
              })
            }
          >
            <img
              style={{
                background: 'white',
                padding: 2,
                width: 30,
                height: 30,
                borderRadius: 50
              }}
              src={
                expandEmployees[params.row.id] ? CircleUpIcon : CircleDownIcon
              }
              alt="show employee"
            />
          </IconButton>
        </Box>
        <div>
          {!!expandEmployees[params.row.id] &&
            params.row.employees.map((employee: Employee) => (
              <Box display="flex" alignItems="center" gap={1} marginTop={1}>
                <img
                  alt="employee"
                  src={employee.photoUrl || ProfilePicture}
                  style={profileImage}
                />
                <Typography>
                  {employee.firstName} {employee.lastName}
                </Typography>
              </Box>
            ))}
        </div>
      </Box>
    )
  });

  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('lessonName', { ns: 'dashboard' })),
    getEmployeesColumn(mapColumnTitle[modalType])
  ])();

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  const handleDownloadExcel = async () => {
    await onDownloadingReport(columns);
  };

  return (
    <CustomDialog
      open={modalType !== DashboardDetailsType.None}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography fontSize={24} fontWeight="bold">
          {mapModalTitle[modalType]}
          <br />
          <Typography fontSize={14} fontWeight="bold">
            {mapModalSubTitle[modalType]}
          </Typography>
        </Typography>
        <Box display="flex" sx={{ alignItems: 'center', gap: 1 }}>
          <IconButton
            sx={{
              width: 30,
              height: 30,
              background: '#c4c4c4',
              borderRadius: 50
            }}
            onClick={handleDownloadExcel}
            disabled={isDownloadingReport}
          >
            <SimCardDownloadIcon sx={{ fontSize: 20, color: 'black' }} />
          </IconButton>
          <Box
            display="flex"
            onClick={() => mapButtonActions[modalType]()}
            sx={{
              background: '#c4c4c4',
              borderRadius: 30,
              alignItems: 'center',
              padding: 1,
              cursor: 'pointer'
            }}
          >
            <Typography fontWeight={600} fontSize={13} color="black">
              {mapManageButtonTitle[modalType]}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ ...iconStyle }}
          >
            <CloseIcon sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box
        sx={{
          height: '60vh',
          width: '100%'
        }}
      >
        {(!rows || loading) && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {rows && (
          <CustomDataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            getRowId={row => row.id}
            rows={rows}
            columns={columns}
            paginationModel={{ page: page - 1, pageSize: pageSize }}
            pageSizeOptions={[50]}
            getRowClassName={getCustomRowClassName}
            rowSelection={false}
            disableColumnMenu
            paginationMode="server"
            onPaginationModelChange={(model, details) =>
              handlePageChange(model.page)
            }
            rowCount={totalItems}
          />
        )}
      </Box>
    </CustomDialog>
  );
};

const profileImage: CSSProperties = {
  borderRadius: 50,
  width: 26,
  height: 26
};

export default OverLessonAssignmentModal;

import { CSSProperties, PropsWithChildren, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  DashboardContentTypeSubUsers,
  DashboardDetailsSubscribedUsersType
} from '../../../core/enums';
import { downloadSubscribedUsersReport } from '../../../services/report-service';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import ProfilePicture from '../../../assets/profilePicture.png';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import useDevCycleSdk from '../../../hooks/useDevCycleSdk';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../CustomDataGrid/CustomDataGrid';

export interface SubscribedUsersModalProps {
  modalType: DashboardDetailsSubscribedUsersType;
  onClose: () => void;
  groupLabel?: string;
  supervisorLabel?: string;
  daysFilter?: string;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const SubscribedUsersModal = ({
  modalType,
  onClose,
  groupLabel,
  supervisorLabel,
  daysFilter
}: PropsWithChildren<SubscribedUsersModalProps>) => {
  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();
  const [exportingReport, setExportingReport] = useState<boolean>(false);
  const subscribedUsersDetail =
    useSelector(
      (state: ApplicationState) => state?.student?.subscribedUsersDetail
    ) || [];
  const theme = useTheme();
  const loading = useSelector(
    (state: ApplicationState) => state?.student?.isLoading
  );
  const { t } = useTranslation(['dashboard', 'employees']);
  const supervisorString = !supervisorLabel ? '' : `${supervisorLabel}, `;
  const groupString = !groupLabel ? '' : `${groupLabel}, `;
  const daysFilterString =
    daysFilter === '0'
      ? t('all', { ns: 'dashboard' })
      : `${t('lastIndividualDay', { ns: 'dashboard' })} ${daysFilter} ${t(
          'individualDays',
          { ns: 'dashboard' }
        )}`;
  const rows: any =
    subscribedUsersDetail[modalType as DashboardContentTypeSubUsers];
  const mapModalTitle: Record<DashboardDetailsSubscribedUsersType, string> = {
    [DashboardDetailsSubscribedUsersType.LearningUsers]:
      employeeActiveUpdates.employeeActiveUpdates
        ? t('activeUsersLabel', { ns: 'dashboard' })
        : t('activeUsers', { ns: 'dashboard' }),
    [DashboardDetailsSubscribedUsersType.NonLearningUsers]:
      employeeActiveUpdates.employeeActiveUpdates
        ? t('inactiveUsersLabel', { ns: 'dashboard' })
        : t('inactiveUsers', { ns: 'dashboard' }),
    [DashboardDetailsSubscribedUsersType.None]: ''
  };

  const mapModalSubTitle: Record<DashboardDetailsSubscribedUsersType, string> =
    {
      [DashboardDetailsSubscribedUsersType.LearningUsers]: `${supervisorString}${groupString}${daysFilterString}`,
      [DashboardDetailsSubscribedUsersType.NonLearningUsers]: `${supervisorString}${groupString}${daysFilterString}`,
      [DashboardDetailsSubscribedUsersType.None]: ''
    };

  const handleDownloadExcel = async () => {
    try {
      setExportingReport(true);
      await downloadSubscribedUsersReport(rows, columns, modalType);
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'warning' });
    } finally {
      setExportingReport(false);
    }
  };

  const getContentNameColumn = (headerName: string): GridColDef => ({
    field: 'Employee',
    headerName: headerName,
    minWidth: 220,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <Typography paddingLeft="20px" fontWeight={600}>
        {headerName}
      </Typography>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <Box display="flex" flexDirection="row" gap={2} alignItems="center">
            <img
              alt="employee"
              src={params.row.photoUrl || ProfilePicture}
              style={profileImage}
            />
            <div>
              <Typography style={{ fontSize: 14 }}>
                {`${params.row.firstName} ${params.row.lastName}`}
                <br></br>
                {params.row.title || ''}
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    )
  });

  const getCratedDateColumn = (headerName: string): GridColDef => ({
    field: 'CreatedDate',
    headerName: headerName,
    minWidth: 220,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <Typography paddingLeft="20px" fontWeight={600}>
        {headerName}
      </Typography>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <Box display="flex" flexDirection="row">
            <div>
              <Typography style={{ fontSize: 14 }}>
                {`${moment(
                  modalType ===
                    DashboardDetailsSubscribedUsersType.LearningUsers
                    ? params.row.lastActive
                    : params.row.createdAt
                ).format('MM.DD.YYYY')}`}
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    )
  });

  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('employee', { ns: 'employees' })),
    getCratedDateColumn(
      t(
        `${
          modalType === DashboardDetailsSubscribedUsersType.LearningUsers
            ? 'lastActive'
            : 'createdDate'
        }`,
        { ns: 'dashboard' }
      )
    )
  ])();

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={modalType !== DashboardDetailsSubscribedUsersType.None}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography fontSize={24} fontWeight="bold">
          {mapModalTitle[modalType]}
          <br />
          <Typography component="span" fontSize={14} fontWeight="bold">
            {mapModalSubTitle[modalType]}
          </Typography>
        </Typography>
        <IconButton
          sx={{
            width: 30,
            height: 30,
            background: '#c4c4c4',
            borderRadius: 50
          }}
          onClick={handleDownloadExcel}
          disabled={exportingReport}
        >
          <SimCardDownloadIcon sx={{ fontSize: 20, color: 'black' }} />
        </IconButton>
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon sx={{ fontSize: 30 }} />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          height: '60vh',
          width: '100%'
        }}
      >
        {(!rows || loading) && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {rows && (
          <CustomDataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            getRowId={row => row.id}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 50
                }
              }
            }}
            pageSizeOptions={[50]}
            getRowClassName={getCustomRowClassName}
            rowSelection={false}
            disableColumnMenu
          />
        )}
      </Box>
    </CustomDialog>
  );
};

const profileImage: CSSProperties = {
  borderRadius: 50,
  width: 40,
  height: 40
};

export default SubscribedUsersModal;

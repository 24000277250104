import React, { useEffect, useState, CSSProperties } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { dropDownItem } from '../../entities/Common';
import FormLabel from '../../components/core/FormLabel/FormLabel';
import { LessonsPerDay } from '../../core/constants';
import { EmployeeGroup } from '../../entities/Employee';
import { GroupWorkloadModel } from '../../entities/Employee';
import { useTranslation } from 'react-i18next';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';

export interface GroupWorkloadProps {
  employeeGroups: EmployeeGroup[];
  groupWorkLoad: GroupWorkloadModel;
  errorMessage: string;
  setGroupWorkload: (groupWorkload: GroupWorkloadModel) => void;
}

const containerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column'
};

const itemStyle: SxProps = {
  paddingBottom: '1rem'
};

const GroupWorkload: React.FC<GroupWorkloadProps> = ({
  employeeGroups,
  groupWorkLoad,
  errorMessage,
  setGroupWorkload
}) => {
  const theme = useTheme();
  const [groups, setGroups] = useState<dropDownItem[]>([]);
  const [applyAllChecked, setApplyAllChecked] = useState<boolean>(false);
  const { t } = useTranslation(['employees']);

  useEffect(() => {
    setGroupWorkload({ groupId: '', applyToAll: false, lessonsPerDay: 2 });
  }, []);

  useEffect(() => {
    if (employeeGroups) {
      const items = employeeGroups.map(group => {
        return { label: group.name, value: group.id };
      });
      setGroups(items);
    }
  }, [employeeGroups]);

  const handleGroupChange = (items: string[]) => {
    if (items.length) setGroupWorkload({ ...groupWorkLoad, groupId: items[0] });
  };

  const handleDayChange = (items: string[]) => {
    if (items.length) {
      const day = items[0] as unknown;
      setGroupWorkload({ ...groupWorkLoad, lessonsPerDay: day as number });
    }
  };

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newGroupWorkload = { ...groupWorkLoad, applyToAll: checked };
    if (checked) {
      newGroupWorkload.groupId = '';
    }
    setGroupWorkload(newGroupWorkload);
    setApplyAllChecked(checked);
  };

  const mandatoryStyle: CSSProperties = {
    color: theme.palette.error.main
  };

  const errorStyle: CSSProperties = {
    color: theme.palette.error.main,
    paddingBottom: '1rem'
  };

  const checkBoxGridStyle: SxProps = {
    paddingLeft: '1rem'
  };

  return (
    <Grid container sx={containerStyle}>
      {errorMessage.length > 0 && (
        <span style={errorStyle}>{errorMessage}</span>
      )}
      <Grid item sx={itemStyle}>
        <FormLabel>
          {t('chooseGroup', { ns: 'employees' })}
          <span style={mandatoryStyle}>*</span>
        </FormLabel>
        <Grid container xs={12}>
          <Grid item xs={6}>
            <BasicSelect
              options={groups}
              multiple={false}
              value={
                groupWorkLoad.groupId === '' ? [] : [groupWorkLoad.groupId]
              }
              placeholder={t('groupName', { ns: 'employees' })}
              handleOnChange={handleGroupChange}
              disabled={applyAllChecked}
              theme="dark"
            />
          </Grid>
          <Grid item xs={6} sx={checkBoxGridStyle}>
            <FormCheckBox
              onChange={handleCheckBoxChange}
              checked={applyAllChecked}
              label={t('applyToAllEmployees', { ns: 'employees' })}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sx={itemStyle}>
        <FormLabel>
          {t('numberLessonsPerDay', { ns: 'employees' })}
          <span style={mandatoryStyle}>*</span>
        </FormLabel>
        <BasicSelect
          options={LessonsPerDay}
          value={[groupWorkLoad.lessonsPerDay.toString()]}
          multiple={false}
          handleOnChange={handleDayChange}
          theme="dark"
        />
      </Grid>
    </Grid>
  );
};

export default GroupWorkload;

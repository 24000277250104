import Play from '../../../../assets/play.svg';
import Pause from '../../../../assets/pause.svg';
import { CSSProperties } from 'react';
import { useMediaQuery } from '@mui/material';

interface PlayPauseControlProps {
  playing: boolean;
  handlePlayPause: () => void;
}
const PlayPauseControl: React.FC<PlayPauseControlProps> = ({
  playing,
  handlePlayPause
}) => {
  const isSmScreen = useMediaQuery('(max-width:640px)');
  return (
    <div className=" pl-1 d-flex align-items-center ">
      <button
        style={buttonPlay}
        type="button"
        onClick={() => handlePlayPause()}
        className="play-pause"
      >
        {playing ? (
          <img width={isSmScreen ? '25' : '30'} src={Pause} alt="Pause video" />
        ) : (
          <img width={isSmScreen ? '25' : '30'} src={Play} alt="Play video" />
        )}
      </button>
    </div>
  );
};

const buttonPlay: CSSProperties = {
  background: 'none',
  border: 'none'
};

export default PlayPauseControl;

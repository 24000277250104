import { useEffect, useState } from 'react';
import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import { useTranslation } from 'react-i18next';
import AwardCertificationTable from './AwardCertificationTable';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { Box, Button, Grid, Stack, SxProps, Typography } from '@mui/material';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { useAppDispatch } from '../../../store/hooks';
import { actionCreators as certificationActions } from '../../../actions/certifications';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { Certification } from '../../../entities/Certification';
import {
  UserClaims,
  pageInitialStateEmployeesToAward
} from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { ApplicationState } from '../../../store';
import { dropDownItem } from '../../../entities/Common';
import { useSelector } from 'react-redux';
import { EmployeeGroup } from '../../../entities/Employee';
import { GridRowId } from '@mui/x-data-grid-premium';
import { EmployeeCertification } from '../../../entities/EmployeeCertification';
import { useSnackbar } from 'notistack';
import { Guid } from 'guid-typescript';
import AwardCertificationModalFactory, {
  ModalKeys
} from './AwardCertificationModalFactory';

interface AwardCertificationProps {
  openAwardModal: boolean;
  handleCloseAwardModal: () => void;
  certification: Certification;
}

const AwardCertification = ({
  openAwardModal,
  handleCloseAwardModal,
  certification
}: AwardCertificationProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { initialPageNumber, initialPageSize } =
    pageInitialStateEmployeesToAward;
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [employeesSelected, setEmployeesSelected] = useState<
    EmployeeCertification[]
  >([]);
  const [selectedModalKey, setSelectedModalKey] = useState<ModalKeys>(
    ModalKeys.none
  );
  const [groups, setGroups] = useState<dropDownItem[]>([
    {
      label: t('selectGroup', { ns: 'certifications' }),
      value: ''
    }
  ]);
  const [groupIds, setGroupIds] = useState<string[]>(['']);
  const employeesToAward = useSelector(
    (state: ApplicationState) => state.certifications?.employeesToAward
  );
  const user = useCurrentUser();
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const employeeFirstName = user?.profile[UserClaims.FirstName] as string;
  const employeeLastName = user?.profile[UserClaims.LastName] as string;
  const [pageModel, setPageModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: initialPageNumber,
    pageSize: initialPageSize
  });
  let searchTimeout: NodeJS.Timeout;
  const employeeGroups =
    useSelector(
      (state: ApplicationState) => state?.employees?.employeeGroups
    ) ?? [];

  useEffect(() => {
    dispatch(
      certificationActions.getEmployeesToAward(
        certification.id,
        pageModel.page,
        pageModel.pageSize,
        { value: searchValue, firstSearch: false },
        groupIds[0]
      )
    );
    dispatch(employeeActions.requestEmployeeGroups(companyId));
    return () => {
      dispatch(certificationActions.resetEmployeesToAward());
    };
  }, []);

  useEffect(() => {
    const filteredGroups = getListItems(employeeGroups ?? []);
    setGroups(prev => [...prev, ...filteredGroups]);
  }, [employeeGroups.length]);

  const getListItems = (list: EmployeeGroup[]) => {
    return list
      .filter(
        group =>
          group.employees &&
          group.employees.some(employee => employee.hasTrainingSubscription)
      )
      .map(item => ({ label: item.name, value: item.id }));
  };

  const onHandleSearch = async (value: any) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(async () => {
      setSearchValue(value);
      dispatch(
        certificationActions.getEmployeesToAward(
          certification.id,
          initialPageNumber,
          initialPageSize,
          { value: value, firstSearch: true },
          groupIds[0]
        )
      );
      setPageModel({ page: initialPageNumber, pageSize: initialPageSize });
    }, 800);
  };

  const handleOnChangeGroupList = (values: string[]) => {
    setGroupIds(values);
    setPageModel({ page: initialPageNumber, pageSize: initialPageSize });
    dispatch(
      certificationActions.getEmployeesToAward(
        certification.id,
        initialPageNumber,
        initialPageSize,
        { value: searchValue, firstSearch: true },
        values[0]
      )
    );
  };

  const handleRowSelectionModelChange = (newRowSelectedIds: GridRowId[]) => {
    const allRows = employeesToAward?.employees ?? [];
    if (newRowSelectedIds.length > selectedRowIds.length) {
      const newIds = findDifference(newRowSelectedIds, selectedRowIds);
      const selectedRows = allRows.filter(row =>
        newIds.includes(row.employeeId)
      );
      setEmployeesSelected(prev => [...prev, ...selectedRows]);
      setSelectedRowIds(newRowSelectedIds);
      return;
    }
    const deletedIds = findDifference(selectedRowIds, newRowSelectedIds);
    setEmployeesSelected(prev =>
      prev.filter(row => !deletedIds.includes(row.employeeId))
    );
    setSelectedRowIds(newRowSelectedIds);
  };

  const findDifference = (list1: GridRowId[], list2: GridRowId[]) => {
    const set2 = new Set(list2);
    const difference = list1.filter(element => !set2.has(element));
    return difference;
  };

  const handleOpenUploadDocumentsModal = () => {
    if (!employeesSelected.length) {
      enqueueSnackbar(t('selectAtLeastOneEmployee', 'certifications'), {
        variant: 'warning'
      });
      return;
    }

    if (certification.thirdPartyCertificateRequired) {
      setSelectedModalKey(ModalKeys.uploadFiles);
      return;
    }

    const validate = employeesSelected.some(
      emp => emp.certificationId !== Guid.EMPTY
    );
    const key = validate
      ? ModalKeys.certificationOverride
      : ModalKeys.certificationSignOff;
    setSelectedModalKey(key);
  };

  const handleSave = async () => {
    setSavingInProgress(true);

    try {
      await certificationActions.awardCertification(
        employeesSelected,
        certification.id,
        employeeId,
        `${employeeFirstName} ${employeeLastName}`
      );
      enqueueSnackbar(
        t('certificationSuccessfullyAwarded', { ns: 'certifications' }),
        {
          variant: 'success'
        }
      );
      dispatch(certificationActions.refetchCertifications(true));
      if (!certification.thirdPartyCertificateRequired) {
        setSavingInProgress(false);
        setSelectedModalKey(ModalKeys.certificationPreview);
        return;
      }
      handleCloseAwardModal();
    } catch (e) {
      enqueueSnackbar(
        t('failedCertificationAwarding', { ns: 'certifications' }),
        {
          variant: 'error'
        }
      );
    } finally {
      setSavingInProgress(false);
    }
  };

  return (
    <ModalComponent
      showModal={openAwardModal}
      title={t('selectEmployeesToAwardTitle', { ns: 'certifications' })}
      maxWidth="md"
      saveButtonText={t('save', { ns: 'common' })}
      showCloseIcon={true}
      showCancelButton={false}
      showSaveButton={false}
      closeWhenClickBackdrop={false}
      closeWhenPressingEscape={false}
      onClose={handleCloseAwardModal}
      savingInProgress={false}
    >
      <Grid>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          flexWrap={'wrap'}
          justifyContent={'start'}
          alignItems={'end'}
        >
          <SearchBox
            id="search"
            value={searchValue}
            name="search"
            width="220px"
            height="40px"
            placeholderText={t('search', { ns: 'common' })}
            variant="dark"
            onChangeValue={onHandleSearch}
          />
          <Stack>
            <Typography style={{ color: '#BBBBBB', fontSize: '14px' }}>
              {t('employeeGroup', { ns: 'certifications' })}
            </Typography>
            <BasicSelect
              id="GroupsFilter"
              options={groups}
              defaultValue=""
              value={groupIds}
              placeholder={t('selectGroup', { ns: 'certifications' })}
              handleOnChange={handleOnChangeGroupList}
              theme="dark"
              multiple={false}
              sx={filterStyle}
            />
          </Stack>
        </Box>
        <Box style={{ paddingTop: '20px' }}>
          <AwardCertificationTable
            certification={certification}
            setPageModel={setPageModel}
            pageModel={pageModel}
            searchValue={searchValue}
            groupId={groupIds[0]}
            handleRowSelectionModelChange={handleRowSelectionModelChange}
          />
        </Box>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={2}
          sx={{ paddingTop: '25px' }}
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignItems={'end'}
        >
          <BasicButton
            color="primary"
            style={{
              height: '45px',
              width: '88px',
              minWidth: '80px'
            }}
            data-testid="uploadFileNextButtonTestId"
            data-trackid="uploadFileNextButtonTrackId"
            onClick={handleOpenUploadDocumentsModal}
          >
            {t('nextLabel', { ns: 'certifications' })}
          </BasicButton>
          <Button
            style={{
              borderRadius: '20px',
              border: '2px solid #FF9E00',
              background: '#1E1E1E',
              color: 'white',
              width: '88px',
              minWidth: '80px',
              height: '43px'
            }}
            onClick={handleCloseAwardModal}
          >
            {t('cancelLabel', { ns: 'certifications' })}
          </Button>
        </Box>
      </Grid>
      {selectedModalKey !== ModalKeys.none && (
        <AwardCertificationModalFactory
          employeesToAward={employeesSelected}
          savingInProgress={savingInProgress}
          modalKey={selectedModalKey}
          certification={certification}
          setModalKey={setSelectedModalKey}
          handleSave={handleSave}
          setEmployeesToAward={setEmployeesSelected}
          handleCloseAwardModal={handleCloseAwardModal}
        />
      )}
    </ModalComponent>
  );
};
const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '200px',
  width: '100%',
  height: '40px'
};
export default AwardCertification;

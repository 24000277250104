import React, { CSSProperties, useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  GridColDef,
  GridRenderCellParams,
  GridColumnHeaderParams,
  GridRowHeightParams
} from '@mui/x-data-grid';
import { Employee, EmployeeGroup } from '../../entities/Employee';
import { Box, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import { dropDownItem } from '../../entities/Common';
import {
  LessonsPerDay,
  StartTimes,
  DefaultStartTimeShiftInMinutesUtc
} from '../../core/constants';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import moment from 'moment';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../components/CustomDataGrid/CustomDataGrid';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';

export interface EmployeeTableProps {
  employeesLearningProfile: Employee[];
  employeeGroups: dropDownItem[];
  isLoading: boolean;
  onChangeEmployeeWorkload: (employeeId: string, lessonsPerDay: number) => void;
  onChangeStartTime: (employeeId: string, minutes: string) => void;
}

const EmployeeTable: React.FC<EmployeeTableProps> = ({
  employeesLearningProfile,
  employeeGroups,
  isLoading,
  onChangeEmployeeWorkload,
  onChangeStartTime
}) => {
  const theme = useTheme();
  const {
    variables: { workloadcapacityfeed }
  } = useDevCycleSdk();
  const [dataSource, setDataSource] = useState<Employee[]>([]);
  const [groupFilter, setGroupFilter] = useState('');
  const { t } = useTranslation(['common', 'employees']);
  const defaultGroupfilterOption: dropDownItem = {
    label: t('filterGroup', { ns: 'common' }),
    value: ''
  };

  useEffect(() => {
    setGroupFilter(defaultGroupfilterOption.value);
    setDataSource(employeesLearningProfile);
  }, [employeesLearningProfile]);

  const getLocalStartTime = (minutes?: number) => {
    const startTimeMinutes = minutes
      ? minutes
      : DefaultStartTimeShiftInMinutesUtc;
    let utcDateTime = moment.utc().startOf('day').add(startTimeMinutes, 'm');
    utcDateTime = utcDateTime.local();

    return (utcDateTime.hours() * 60 + utcDateTime.minutes()).toString();
  };

  const handleFilterGroup = (groupId: string) => {
    let filtered = employeesLearningProfile;
    if (groupId.length > 0) {
      filtered = employeesLearningProfile?.filter((profile: Employee) =>
        profile.groups?.some((group: EmployeeGroup) => group.id === groupId)
      );
    }

    setDataSource(filtered);
    setGroupFilter(groupId);
  };

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: t('employee', { ns: 'employees' }),
      flex: 1,
      minWidth: 350,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <div className="d-flex ps-1 pt-2 pb-2">
          <img
            className={`img-profile rounded-circle`}
            width="36"
            height="36"
            alt={`${params.row.firstName} ${params.row.lastName}`}
            src={
              params.row.photoUrl && params.row.photoUrl.length
                ? params.row.photoUrl
                : require('../../assets/user.png')
            }
          />
          <div className="d-flex flex-column px-3">
            <span
              style={{ fontSize: 14 }}
            >{`${params.row.firstName} ${params.row.lastName}`}</span>
            <span style={{ fontSize: 12, color: theme.palette.grey[100] }}>
              {params.row.title}
            </span>
          </div>
        </div>
      )
    },
    {
      field: 'isCompanyAdmin',
      headerName: t('admin', { ns: 'common' }),
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<any, boolean>) => (
        <span style={dataGridColumnStyle}>
          {params.value ? t('yes', { ns: 'common' }) : 'No'}
        </span>
      )
    },
    {
      field: 'groups',
      minWidth: 350,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, EmployeeGroup[]>) => (
        <span>{params.value?.map(group => group.name).join(', ')}</span>
      ),
      renderHeader: (params: GridColumnHeaderParams) => (
        <>
          <span style={{ paddingRight: '4rem' }}>
            {t('groups', { ns: 'employees' })}
          </span>
          <BasicSelect
            labelId="groups"
            id="groups"
            options={employeeGroups}
            defaultValue=""
            value={[groupFilter]}
            handleOnChange={(e: string[]) => handleFilterGroup(e[0])}
            style={filterSelectStyle}
            theme={'dark'}
            multiple={false}
            sx={{ backgroundColor: 'transparent' }}
          />
        </>
      )
    },
    ...(workloadcapacityfeed.workloadcapacityfeed
      ? []
      : [
          {
            field: 'lessonsPerDay',
            headerName: t('dailyWorkload', { ns: 'employees' }),
            minWidth: 140,
            sortable: false,
            renderCell: (params: GridRenderCellParams<any, number>) => (
              <BasicSelect
                labelId="dailyWorkload"
                id="dailyWorkload"
                options={LessonsPerDay}
                defaultValue=""
                value={[params.row.lessonsPerDay.toString()]}
                handleOnChange={(e: string[]) =>
                  onChangeEmployeeWorkload(params.row.id, Number(e[0]))
                }
                style={selectStyle}
                theme={'dark'}
                multiple={false}
                sx={{ backgroundColor: 'transparent' }}
              />
            )
          }
        ]),
    {
      field: 'dailyStartTimeMinutes',
      headerName: t('startTime', { ns: 'employees' }),
      minWidth: 140,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <BasicSelect
          labelId="startTime"
          id="startTime"
          options={StartTimes}
          defaultValue=""
          value={[getLocalStartTime(params.value)]}
          handleOnChange={(e: string[]) =>
            onChangeStartTime(params.row.id, e[0])
          }
          style={selectStyle}
          theme={'dark'}
          multiple={false}
          sx={{ backgroundColor: 'transparent' }}
        />
      )
    }
  ];

  return (
    <Box
      sx={{
        height: '50vh',
        width: '100%',
        border: `1px solid ${theme.palette.grey[100]}`,
        backgroundColor: theme.palette.grey.A200,
        borderRadius: '8px'
      }}
    >
      {!isLoading && dataSource.length > 0 && (
        <CustomDataGrid
          getRowId={row => row.id}
          rows={dataSource}
          columns={columns}
          pageSizeOptions={[10]}
          sortingOrder={['desc', 'asc']}
          rowSelection={false}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'firstName',
                  sort: 'asc'
                }
              ]
            },
            pagination: {
              paginationModel: {
                pageSize: 10
              }
            }
          }}
          getRowClassName={getCustomRowClassName}
          disableColumnMenu
          getRowHeight={(params: GridRowHeightParams) => {
            if (params.model.groups.length > 1) {
              return 'auto';
            }
            return null;
          }}
          components={{
            ColumnSortedDescendingIcon: () => {
              return <ArrowDropDownIcon height="5px" width="10px" />;
            },
            ColumnSortedAscendingIcon: () => {
              return <ArrowDropUpIcon height="5px" width="10px" />;
            }
          }}
          columnBuffer={5}
        />
      )}
      {!isLoading && dataSource.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <span>{t('noRecords', { ns: 'common' })}</span>
        </Box>
      )}
      {isLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const dataGridColumnStyle: CSSProperties = {
  fontSize: '17px'
};

const filterSelectStyle: CSSProperties = {
  width: '170px',
  height: '35px'
};

const selectStyle: CSSProperties = {
  width: '120px',
  height: '35px'
};

export default EmployeeTable;

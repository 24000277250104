import { CSSProperties, MutableRefObject, ReactNode } from 'react';
import {
  Index,
  ListRowProps,
  WindowScroller,
  InfiniteLoader,
  AutoSizer,
  List
} from 'react-virtualized';
import {
  useMediaQuery,
  Grid as MuiGrid,
  Skeleton,
  CardContent,
  Stack,
  styled,
  SkeletonProps
} from '@mui/material';

import {
  MyCertification,
  MyCertificationStatus
} from '../../../entities/Certification';
import { PagedResult } from '../../../entities/Common';
import { actionCreators as certificationActions } from '../../../actions/certifications';
import { useAppDispatch } from '../../../store/hooks';
import CertificationCard from './CertificationCard/CertificationCard';
import {
  StyledCard,
  StyledCardActions
} from './CertificationCard/CertificationCardComponents';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';

const useGetNumberOfColumns = () => {
  const isOneColumn = useMediaQuery('(max-width:640px)');
  const twoColumns = useMediaQuery('(max-width:1100px)');

  if (isOneColumn) {
    return 1;
  } else if (twoColumns) {
    return 2;
  } else {
    return 3;
  }
};

interface MyCertificationListProps {
  myCertificationsPageResult: PagedResult<MyCertification>;
  searchKey: string;
  sortOption: string;
  filterOption: MyCertificationStatus[];
  page: MutableRefObject<number>;
  pageSize: number;
}

const MyCertificationList = ({
  myCertificationsPageResult,
  searchKey,
  sortOption,
  filterOption,
  page,
  pageSize
}: MyCertificationListProps) => {
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const numberOfColumns = useGetNumberOfColumns();
  let rowCount = 0;
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  if (myCertificationsPageResult) {
    rowCount = myCertificationsPageResult.totalItems / numberOfColumns;

    if (rowCount < 1) {
      rowCount = 1;
    }

    if (myCertificationsPageResult.hasNextPage) {
      rowCount += 1;
    }
    rowCount = Math.ceil(rowCount);
  }

  const isRowLoaded = ({ index }: Index) => {
    const realIndex = index * numberOfColumns;
    return (
      !myCertificationsPageResult?.hasNextPage ||
      realIndex < (myCertificationsPageResult?.pageItems.length ?? 0)
    );
  };

  const loadNextPage = async () => {
    page.current += 1;
    await dispatch<Promise<void>>(
      certificationActions.getMyCertifications(
        page.current,
        pageSize,
        sortOption,
        searchKey,
        filterOption,
        employeeId
      )
    );
  };

  const renderRowWrapper = (
    content: ReactNode,
    style: CSSProperties,
    key: string
  ) => {
    return (
      <div style={style} key={key}>
        <MuiGrid container spacing={2} key={`grid-item-${key}`}>
          {content}
        </MuiGrid>
      </div>
    );
  };

  const loadMoreRows = !myCertificationsPageResult
    ? () => new Promise<any>(resolve => resolve)
    : loadNextPage;

  const renderRow = (props: ListRowProps) => {
    const { index, style, key } = props;
    const data = myCertificationsPageResult
      ? myCertificationsPageResult.pageItems
      : [];
    let content;

    if (!isRowLoaded({ index })) {
      content = Array.from({ length: numberOfColumns }, (v, i) => i).map(j => (
        <MuiGrid item xs={12 / numberOfColumns} key={`skeleton-item-${j}`}>
          <StyledCard>
            <CardContent>
              <Skeleton
                sx={{ height: 50, marginBottom: '20px' }}
                animation="pulse"
                variant="text"
              />
              <Stack gap={1}>
                <Stack direction="row" gap={2}>
                  <SkeletonDetailSection />
                  <SkeletonDetailSection />
                </Stack>
                <Stack direction="row" gap={2}>
                  <SkeletonDetailSection />
                  <SkeletonDetailSection />
                </Stack>
                <Stack direction="row" gap={2}>
                  <SkeletonDetailSection />
                  <SkeletonDetailSection />
                </Stack>
              </Stack>
            </CardContent>
            <StyledCardActions>
              <SkeletonButton />
              <SkeletonButton />
            </StyledCardActions>
          </StyledCard>
        </MuiGrid>
      ));

      return renderRowWrapper(content, style, key);
    }

    const realIndex = index * numberOfColumns;
    const startItemIndex = realIndex;
    const endIndex = startItemIndex + numberOfColumns;

    const items = [];
    for (let i = startItemIndex; i < endIndex && i < data.length; i++) {
      if (!data[i]) {
        break;
      }

      items.push(data[i]);
    }

    content = items.map(item => (
      <MuiGrid
        item
        xs={12 / numberOfColumns}
        key={`$cert-item-${item.employeeCertificationId}`}
      >
        <CertificationCard
          key={item.employeeCertificationId}
          employeeCertification={item}
        />
      </MuiGrid>
    ));

    return renderRowWrapper(content, style, key);
  };

  return (
    <WindowScroller scrollElement={window}>
      {({ height, onChildScroll, scrollTop, isScrolling }) => (
        <div
          style={{
            flex: '1 1 auto'
          }}
        >
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={rowCount}
            threshold={3}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    autoHeight
                    className="window-scroller-override"
                    height={height}
                    ref={registerChild}
                    onRowsRendered={onRowsRendered}
                    onScroll={onChildScroll}
                    rowCount={rowCount}
                    rowHeight={306}
                    isScrolling={isScrolling}
                    rowRenderer={renderRow}
                    scrollTop={scrollTop}
                    width={width}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
      )}
    </WindowScroller>
  );
};

const SkeletonDetailSection = styled(({ ...props }: SkeletonProps) => (
  <Skeleton animation="pulse" variant="text" {...props} />
))(() => ({
  height: '16px',
  width: '100px'
}));

const SkeletonButton = styled(({ ...props }: SkeletonProps) => (
  <Skeleton animation="pulse" variant="rounded" {...props} />
))(() => ({
  height: '40px',
  width: '100px'
}));

export default MyCertificationList;

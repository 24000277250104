import { CSSProperties, useEffect, useState, useContext } from 'react';
import { Box, IconButton, Stack, useTheme } from '@mui/material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { actionCreators as analyticsActions } from '../../../actions/analytics';
import { ApplicationState } from '../../../store';
import ContentLibraryDetailTable from '../Tables/ContentLibraryDetailTable';
import { AnalyticsContentLibraryDetail } from '../../../entities/Analytics';
import { sortByKey } from '../../../utils/sorting';
import { downloadAnalyticsContentLibraryDetailReport } from '../../../services/report-service';
import UpdateDueDateModal from '../common/UpdateDueDateModal';
import { lowerCaseFirstLetter } from '../../../utils/stringUtils';
import GoBackComponent from '../common/GoBackComponent';
import { enqueueSnackbar } from 'notistack';
import { AnalyticsCommonFilterContext } from '../../../contexts/AnalyticsContext';
import { useAppDispatch } from '../../../store/hooks';

interface ContentLibraryAnalyticsDetailProps {
  learningUnitId: string;
  learningUnitName: string;
  learningUnitType: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onBackToContentLibrary: () => void;
}

const ContentLibraryAnalyticsDetail = ({
  learningUnitId,
  learningUnitName,
  learningUnitType,
  includeSelfAssignments,
  filterOption,
  onBackToContentLibrary
}: ContentLibraryAnalyticsDetailProps) => {
  let searchTimeout: NodeJS.Timeout;
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const { t } = useTranslation(['common', 'analytics']);
  const sortOptions = [
    {
      label: t('mostTimesCompleted', { ns: 'analytics' }),
      value: 'mostTimesCompleted'
    },
    { label: t('completedLate', { ns: 'analytics' }), value: 'completedLate' },
    { label: t('overdue', { ns: 'analytics' }), value: 'overdue' },
    {
      label: t('pendingCompletion', { ns: 'analytics' }),
      value: 'pendingCompletion'
    },
    {
      label: t('completedOnTime', { ns: 'analytics' }),
      value: 'completedOnTime'
    },
    { label: t('dueDate', { ns: 'analytics' }), value: 'dueDate' }
  ];
  const [sortOption, setSortOption] = useState<string>(sortOptions[0].value);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const [data, setData] = useState<AnalyticsContentLibraryDetail[] | null>();
  const [assignmentId, setAssignmentId] = useState<string>();
  const [dueDate, setDueDate] = useState<Date>();
  const contentLibraryDetail = useSelector(
    (state: ApplicationState) => state.analytics?.contentLibraryDetailData
  );
  const activeOnly = filterOption === 'active';
  const subscribedOnly = filterOption === 'subscribed';
  useEffect(() => {
    dispatch(
      analyticsActions.getContentLibraryDetailData(
        learningUnitType,
        includeSelfAssignments,
        learningUnitId,
        activeOnly,
        subscribedOnly,
        groupId,
        supervisorId
      )
    );

    return () => {
      dispatch(analyticsActions.setContentLibraryDetailData(null));
    };
  }, [learningUnitId, groupId, supervisorId]);

  useEffect(() => {
    const contentData = contentLibraryDetail
      ? sortData(contentLibraryDetail, sortOption)
      : contentLibraryDetail;

    setData(contentData);
  }, [contentLibraryDetail]);

  const handleSearch = (searchFilter: string) => {
    if (!contentLibraryDetail) {
      return;
    }

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      const filteredData = contentLibraryDetail.filter(item =>
        `${item.firstName} ${item.lastName}`
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      );
      const sortedData = sortData(filteredData, sortOption);
      setData(sortedData);
    }, 1000);
  };

  const handleChangeSortOption = (values: string[]) => {
    if (!data) {
      return;
    }

    const sortOption = values[0] as string;
    const sortedData = sortData(data, sortOption);
    setData(sortedData);
    setSortOption(sortOption);
  };

  const sortData = (
    data: AnalyticsContentLibraryDetail[],
    sortOption: string
  ) => {
    switch (sortOption) {
      case 'mostTimesCompleted':
        return data
          .slice()
          .sort(
            sortByKey<AnalyticsContentLibraryDetail>('numberTimesCompleted')
          )
          .reverse();
      case 'completedLate': {
        const completedLate = data.filter(item =>
          item.assignmentsInfo.some(
            assignment =>
              !assignment.isCompletedOnTime && !!assignment.completionDate
          )
        );
        const remainig1 = data.filter(item => !completedLate.includes(item));
        return completedLate.concat(remainig1);
      }
      case 'overdue': {
        const overdue = data.filter(item =>
          item.assignmentsInfo.some(assignment => assignment.isOverdue)
        );
        const remainig2 = data.filter(item => !overdue.includes(item));
        return overdue.concat(remainig2);
      }
      case 'pendingCompletion': {
        const pending = data.filter(item =>
          item.assignmentsInfo.some(assignment => assignment.isPending)
        );
        const remainig3 = data.filter(item => !pending.includes(item));
        return pending.concat(remainig3);
      }
      case 'completedOnTime': {
        const completedOnTime = data.filter(item =>
          item.assignmentsInfo.some(
            assignment => assignment.isCompletedOnTime && !assignment.isPending
          )
        );
        const remainig4 = data.filter(item => !completedOnTime.includes(item));
        return completedOnTime.concat(remainig4);
      }
      case 'dueDate': {
        let dueDateRecords: any[] = [];
        data.forEach(item => {
          dueDateRecords = dueDateRecords.concat(
            item.assignmentsInfo.map(assignment => ({
              employeeId: item.employeeId,
              dueDate: assignment.dueDate!
            }))
          );
        });

        const sortedDueDates = dueDateRecords.sort((a, b) =>
          a.dueDate < b.dueDate ? 1 : -1
        );
        const employeesIds = sortedDueDates.map(
          item => item.employeeId
        ) as string[];
        const uniqueEmployeesIds = employeesIds.filter(
          (value, index, array) => array.indexOf(value) === index
        );
        return uniqueEmployeesIds.map(
          id => data.find(item => item.employeeId === id)!
        );
      }
      default:
        return data;
    }
  };

  const handleCreateReport = () => {
    if (!data) {
      return;
    }

    setDownloadingReport(true);
    try {
      downloadAnalyticsContentLibraryDetailReport(data, learningUnitType);
    } catch (e) {
      const message = 'An exception occurred while creating the report.';
      enqueueSnackbar(message, { variant: 'error' });
    } finally {
      setDownloadingReport(false);
    }
  };

  const handleEditDueDate = (assignmentId: string, dueDate: Date) => {
    setAssignmentId(assignmentId);
    setDueDate(dueDate);
  };

  const handleCloseUpdateDueDateModal = () => {
    setAssignmentId(undefined);
    setDueDate(undefined);
  };

  const handleSuccessfulDueDateSaved = async () => {
    await dispatch<Promise<void>>(
      analyticsActions.getContentLibraryDetailData(
        learningUnitType,
        includeSelfAssignments,
        learningUnitId,
        activeOnly,
        subscribedOnly,
        groupId,
        supervisorId
      )
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <GoBackComponent
          onBackTo={onBackToContentLibrary}
          linkText={t('backToAll', {
            ns: 'analytics',
            learningUnitType: t(`${lowerCaseFirstLetter(learningUnitType)}s`, {
              ns: 'analytics'
            })
          })}
        />
        <Stack direction="row" spacing={1} paddingBottom={2} flexWrap="wrap">
          <BasicSelect
            labelId="sortOptions"
            id="sortOptions"
            options={sortOptions}
            defaultValue=""
            value={[sortOption]}
            handleOnChange={handleChangeSortOption}
            style={selectStyle}
            theme="dark"
            multiple={false}
            sx={{ backgroundColor: 'transparent' }}
          />
          <SearchBox
            id="searchContent"
            name="searchContent"
            value=""
            onChangeValue={handleSearch}
            placeholderText={t('search', { ns: 'common' })}
            width="250px"
            height="45px"
          />
          <IconButton
            aria-label="downloadCSV"
            size="large"
            onClick={handleCreateReport}
            disabled={downloadingReport}
            sx={{ color: theme.palette.grey[100], paddingTop: 0 }}
          >
            <DownloadForOfflineRoundedIcon style={{ fontSize: 45 }} />
          </IconButton>
        </Stack>
      </Box>
      <ContentLibraryDetailTable
        data={data}
        onEditDueDate={handleEditDueDate}
        learningUnitName={learningUnitName}
      />
      <UpdateDueDateModal
        learningUnitType={learningUnitType}
        assignmentId={assignmentId}
        dueDate={dueDate}
        onClose={handleCloseUpdateDueDateModal}
        onSuccessfulSaved={handleSuccessfulDueDateSaved}
      />
    </Box>
  );
};

const selectStyle: CSSProperties = {
  width: '230px',
  height: '45px'
};

export default ContentLibraryAnalyticsDetail;

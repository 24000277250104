import { CSSProperties } from 'react';
import { Typography, ThemeProvider, Grid, Stack, Box } from '@mui/material';
import { defaultTheme } from '@buildwitt/component-library';
import { Tabs } from '@buildwitt/component-library';
import { TabConfig } from '@buildwitt/component-library/dist/components/Tabs/Tabs';
import { useTranslation } from 'react-i18next';

import PageContainer from '../../components/core/PageContainer/PageContainer';
import ContentLibraryAnalytics from './ContentLibraryAnalytics';
import Engagement from './Engagement';
import Skills from './Skills';
import Quizzes from './Quizzes';
import config from '../../config';
import useCommonFilters from '../../components/CommonFilters/useCommonFilters';
import SupervisorFilter from '../../components/CommonFilters/SupervisorFilter';
import GroupFilter from '../../components/CommonFilters/GroupFilter';
import { UserRoles } from '../../core/constants';
import useUserRole from '../../hooks/useUserRole';
import { AnalyticsCommonFilterContext } from '../../contexts/AnalyticsContext';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';

const Analytics = () => {
  const {
    variables: { skillsAndCertifications, newCertificatesFlow }
  } = useDevCycleSdk();
  const { t } = useTranslation(['analytics']);
  const [userHasRole] = useUserRole();
  const {
    supervisorItems,
    supervisorFilterValue,
    groupFilterValue,
    groupItems,
    handleChangeGroup,
    handleChangesupervisor,
    makeFilterValueEmptyIfDefault
  } = useCommonFilters();
  const getTabConfig = () => {
    const commonTabs: TabConfig[] = [
      {
        label: `${t('contentLibrary', { ns: 'analytics' })}`,
        component: <ContentLibraryAnalytics />
      },
      {
        label: `${t('engagement', { ns: 'analytics' })}`,
        component: <Engagement />,
        hide: !config.ENGAGEMENT_ANALYTICS
      },
      {
        label: `${t('skillsAndCertificates', { ns: 'analytics' })}`,
        component: <Skills />,
        hide: !skillsAndCertifications.skillsAndCertifications
      },
      {
        label: `${t('certificates', { ns: 'skills' })}`,
        component: <Skills onlyCertificates={true} />,
        hide: !newCertificatesFlow.newCertificatesFlow
      },
      {
        label: `${t('quizzes', { ns: 'analytics' })}`,
        component: <Quizzes />,
        hide: !config.QUIZ_ANALYTICS
      }
    ];
    return commonTabs;
  };

  const cleanFilterValue = (value: string) => {
    const safeValue = makeFilterValueEmptyIfDefault(value);
    if (safeValue === '-') return '';

    return safeValue;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer>
        <Grid container direction="column">
          <Grid item>
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                flexWrap: 'wrap'
              }}
            >
              <Typography sx={fontTab}>
                {t('analytics', { ns: 'analytics' })}
              </Typography>
              <Stack
                direction="row"
                sx={{
                  marginTop: '0.5rem',
                  flexWrap: 'wrap'
                }}
              >
                <Box sx={{ margin: '0 0.5rem 0.5rem' }}>
                  <SupervisorFilter
                    items={supervisorItems}
                    filterValue={supervisorFilterValue}
                    onChange={items => {
                      handleChangesupervisor(items);
                    }}
                  />
                </Box>
                {userHasRole([
                  UserRoles.CompanyAdmin,
                  UserRoles.GroupLead,
                  UserRoles.BuildWittAdmin
                ]) && (
                  <GroupFilter
                    items={groupItems}
                    filterValue={groupFilterValue}
                    onChange={items => {
                      handleChangeGroup(items);
                    }}
                  />
                )}
              </Stack>
            </Stack>
          </Grid>
          <Grid item id="analyticsTabsContainer" style={responsiveTabsStyle}>
            <AnalyticsCommonFilterContext.Provider
              value={{
                supervisorId: makeFilterValueEmptyIfDefault(
                  supervisorFilterValue
                ),
                groupId: cleanFilterValue(groupFilterValue)
              }}
            >
              <Tabs tabConfig={getTabConfig()} />
            </AnalyticsCommonFilterContext.Provider>
          </Grid>
        </Grid>
      </PageContainer>
    </ThemeProvider>
  );
};

const fontTab: CSSProperties = {
  fontSize: '24px',
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 1)'
};

const responsiveTabsStyle: CSSProperties = {
  width: '100%'
};

export default Analytics;

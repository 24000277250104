import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SearchBox from '../../../../components/core/SearchBox/SearchBox';

interface EmployeeCertificationsActionToolbarProps {
  onUpdateToolbarActionValues: (value: string) => void;
}

const EmployeeCertificationsActionToolbar = ({
  onUpdateToolbarActionValues
}: EmployeeCertificationsActionToolbarProps) => {
  const { t } = useTranslation(['certifications', 'common']);
  let searchTimeout: NodeJS.Timeout;

  const handleSearchKeyChange = (value: any) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      onUpdateToolbarActionValues(value);
    }, 1000);
  };

  return (
    <Stack
      direction="row"
      flexWrap={'wrap'}
      sx={{ paddingTop: '1rem', paddingBottom: '2rem' }}
    >
      <SearchBox
        id="searchContent"
        name="searchContent"
        value={''}
        onChangeValue={handleSearchKeyChange}
        placeholderText={t('search', { ns: 'common' })}
        height="40px"
        width="100%"
        minWidth="200px"
        maxWidth="300px"
      />
    </Stack>
  );
};

export default EmployeeCertificationsActionToolbar;

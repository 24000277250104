import {
  Page,
  Text,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';

import {
  TitilliumWebBold,
  TitilliumWebNormal,
  TitilliumWebSemiBold
} from '../../../core/constants';
import BwTrainingLogo from '../../../assets/BWTrainingLogo.png';

Font.register({
  family: 'Titillium Web',
  fonts: [
    {
      src: TitilliumWebNormal
    },
    {
      src: TitilliumWebSemiBold,
      fontWeight: 600
    },
    {
      src: TitilliumWebBold,
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '45px'
  },
  logo: {
    width: '256px',
    height: '256px',
    objectFit: 'contain'
  },
  text: {
    fontFamily: 'Titillium Web',
    fontSize: '22px',
    textAlign: 'center',
    fontWeight: 600,
    paddingTop: '64px',
    paddingBottom: '16px'
  },
  bwTrainingLogo: {
    width: '180px',
    height: '120px',
    objectFit: 'contain'
  },
  viewAboveText: {
    paddingTop: '32px'
  }
});

const QRCodePdf = (qRCodeBase64: string) => {
  const QRCodePdfDocument = (
    <Document>
      <Page style={styles.page} orientation="landscape">
        <Image source={qRCodeBase64} style={styles.logo} />
        <Text style={styles.text}>
          Scan the QR code to check-in to your On-site Training
        </Text>
        <Image style={styles.bwTrainingLogo} source={BwTrainingLogo} />
      </Page>
    </Document>
  );

  return QRCodePdfDocument;
};

export default QRCodePdf;

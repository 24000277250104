import { useContext, useState } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';
import { ContentOptions } from '../../../core/constants';
import { ContentStatus } from '../../../hooks/useContentLibraryNavigation';

const useLearningUnitStatusFilter = () => {
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const [filterLearningUnitStatus, setFilterLearningUnitStatus] =
    useState<string>(
      userNavigation?.contentStatusFilter ?? ContentOptions[0].value
    );

  const handleChangeLearningUnitStatus = (values: string[]) => {
    const status = values[0] as ContentStatus;
    userNavigationApi?.changeContentStatus(status);
    setFilterLearningUnitStatus(status);
  };

  return [filterLearningUnitStatus, handleChangeLearningUnitStatus] as const;
};

export default useLearningUnitStatusFilter;

import { Grid, Typography, useTheme } from '@mui/material';
import { Answer, Question } from '../../../entities/Assessment';
import { useTranslation } from 'react-i18next';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';
import { CSSProperties } from 'react';
import redCross from '../../../assets/red-cross.png';
import greenCheck from '../../../assets/green-check.png';

export interface NumberQuestionAnalyticsProps {
  answer: Answer;
  question: Question;
}

const NumberQuestionAnalytics: React.FC<NumberQuestionAnalyticsProps> = ({
  answer,
  question
}) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;
  const borderColor = answer.isCorrect
    ? `solid 1px ${greenColor}`
    : `solid 1px ${redColor}`;
  const backgroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;
  const CheckIcon = (
    <img
      src={greenCheck}
      alt="status"
      style={{ ...iconStyle, paddingRight: '26px' }}
    />
  );
  const redCrossIcon = <img src={redCross} alt="status" style={iconStyle} />;
  return (
    <Grid style={{ backgroundColor: backgroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
      />
      <Grid
        direction={'row'}
        display={'flex'}
        alignItems={'center'}
        style={gridContainerCorrectResponse}
      >
        <Typography style={correctResponseLabelStyle}>
          {answer.isCorrect ? correctReponseLabel : ''}
        </Typography>
        {answer.isCorrect ? CheckIcon : redCrossIcon}
        <Grid
          style={{
            paddingLeft: '20px',
            paddingRight: '22px',
            borderLeft: '1px solid #363636'
          }}
        >
          <Grid
            display={'flex'}
            style={{
              ...borderTrueFalseStyle,
              border: borderColor,
              width: '479px'
            }}
          >
            <Grid style={{ padding: '7px 10px 10px 10px' }}>
              <Typography>{answer.answerNumber}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const gridContainerCorrectResponse: CSSProperties = {
  paddingTop: '22px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};

const iconStyle: CSSProperties = {
  paddingLeft: '20px',
  paddingRight: '23px'
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const borderTrueFalseStyle: CSSProperties = {
  height: '39px',
  borderRadius: '5px'
};
export default NumberQuestionAnalytics;

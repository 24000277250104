import { CSSProperties, useState, useEffect, useContext, FC } from 'react';
import { defaultTheme } from '@buildwitt/component-library';
import { ThemeProvider, useTheme, Box } from '@mui/material';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import FormControl from '@mui/material/FormControl';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';
import {
  UserClaims,
  UserRoles,
  LessonUnitTypes,
  CourseUnitTypes,
  LearningPlanUnitTypes,
  DefaultSearchDebounceTime
} from '../../core/constants';
import { Lesson, LessonUnit } from '../../entities/Lesson';
import { Course, CourseUnit } from '../../entities/Course';
import { LearningPlan, LearningPlanUnit } from '../../entities/LearningPlan';
import {
  generateLearningPlansToShow,
  generateCoursesToShow,
  generateLessonsToShow
} from '../../utils/contentLibraryUtils';
import AllCoursesNew from '../../components/ContentLibrary/AllCoursesNew';
import AllLearningPlansNew from '../../components/ContentLibrary/AllLearningPlansNew';
import AllLessonsNew from '../../components/ContentLibrary/AllLessonsNew';
import { ContentLibraryTabNames } from '../../core/enums';
import LearningUnitTypeFilter from './common/LearningUnitTypeFilter';
import ContentStatusFilter from './common/ContentStatusFilter';
import LangContentFilter from './common/LangContentFilter';
import SortContentSelect from './common/SortContentSelect';
import SearchInput from './common/SearchInput';
import { UserNavigationContext } from './UserNavigationContext';
import useLearningUnitFilter from './hooks/useLearningUnitFilter';
import useLearningUnitStatusFilter from './hooks/useLearningUnitStatusFilter';
import useLearningUnitSorting from './hooks/useLearningUnitSorting';
import useLearningUnitLangFilter from './hooks/useLearningUnitLangFilter';
import {
  ContentStatus,
  LangContent,
  Sorting
} from '../../hooks/useContentLibraryNavigation';
import useScrollToLastTabPosition from './hooks/useScrollToLastTabPosition';
import { useDebounce } from '../../hooks/useDebounce';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useTranslation } from 'react-i18next';
import { useEncodingStatusUpdate } from '../../hooks/useEncodingStatusUpdate';
import useLearningUnitTag from './hooks/useLearningUnitTag';
import useLearningUnitTagFilter from './hooks/useLearningUnitTagFilter';
import { TaggingField } from '../../components/TagInput/TaggingField';

interface BuildWittNewProps {
  encodedLessonId: string;
}

export const BuildWittNew: FC<BuildWittNewProps> = ({ encodedLessonId }) => {
  const user = useCurrentUser();
  const [userHasRole] = useUserRole();
  const logEvent = useLogEvent();
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const bWCompanyId = user?.profile[UserClaims.BWLibraryCompanyId] as string;
  const isCompanyAdmin = userHasRole(UserRoles.CompanyAdmin);
  const isBuildwittAdmin = companyId === bWCompanyId && isCompanyAdmin;
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const bwCourses = useSelector(
    (state: ApplicationState) => state.courses?.coursesBW
  );
  const bwLessons = useSelector(
    (state: ApplicationState) => state.lessons?.allBWLessons
  );
  const bwLearningPlans = useSelector(
    (state: ApplicationState) => state.learningPlans?.learningPlans
  );
  const completedCourses = useSelector(
    (state: ApplicationState) => state.student?.completedCoursesBasicInfo
  );

  const completedLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.completedLearningPlans
  );

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );
  const takenTimesCourses = useSelector(
    (state: ApplicationState) => state.student?.takenTimesCourses
  );
  const takenTimesLearningPlans = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLearningPlans
  );

  const theme = useTheme();
  const [learningUnitType, handleChangeLearningUnitType] =
    useLearningUnitFilter();

  const [filterLearningUnitStatus, handleChangeLearningUnitStatus] =
    useLearningUnitStatusFilter();

  const [activeSort, handleChangeSorting] = useLearningUnitSorting();

  const [langFilter, handleChangeLangFilter] = useLearningUnitLangFilter();

  const [tagFilter, handleChangeLearningUnitTag] = useLearningUnitTagFilter();

  const tags = useLearningUnitTag(learningUnitType, bwLessons, bwCourses);

  const [searchTerm, setSearchTerm] = useState<string>(
    userNavigation?.search ?? ''
  );

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  const [allLessons, setAllLessons] = useState<Lesson[]>();
  const [allCourses, setAllCourses] = useState<Course[]>();
  const [allLearningPlans, setAllLearningPlans] = useState<LearningPlan[]>();
  const [lessonsToShow, setLessonsToShow] = useState<Lesson[]>();
  const [coursesToShow, setCoursesToShow] = useState<Course[]>();
  const [learningPlansToShow, setLearningPlansToShow] =
    useState<LearningPlan[]>();

  useScrollToLastTabPosition(
    lessonsToShow !== undefined ||
      coursesToShow !== undefined ||
      learningPlansToShow !== undefined
  );
  const { t } = useTranslation([
    'common',
    'lessons',
    'quizzes',
    'contentLibrary'
  ]);

  useEffect(() => {
    if (searchTerm) {
      logEvent.logLibrarySearchEvent(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (bwLessons) {
      setAllLessons(bwLessons);
    }
  }, [bwLessons]);

  useEncodingStatusUpdate(encodedLessonId, bwLessons, null, setAllLessons);

  useEffect(() => {
    if (bwCourses) {
      setAllCourses(bwCourses);
    }
  }, [bwCourses]);

  useEffect(() => {
    if (bwLearningPlans) {
      const bwPlans = bwLearningPlans.filter(
        lp => lp.companyId === bWCompanyId
      );
      setAllLearningPlans(bwPlans);
    }
  }, [bwLearningPlans]);

  useEffect(() => {
    userNavigationApi?.changeSearch(searchTerm);

    switch (learningUnitType) {
      case 'lessons':
        if (allLessons) {
          const lessons = generateLessonsToShow(
            allLessons,
            searchTerm,
            null,
            activeSort as Sorting,
            tagFilter
          );
          setLessonsToShow(lessons);
        }
        break;
      case 'courses':
        if (allCourses) {
          const courses = generateCoursesToShow(
            allCourses,
            searchTerm,
            filterLearningUnitStatus as ContentStatus,
            langFilter as LangContent,
            activeSort as Sorting,
            tagFilter
          );
          setCoursesToShow(courses);
        }
        break;
      case 'learningPlans':
        if (allLearningPlans) {
          const learningPlans = generateLearningPlansToShow(
            allLearningPlans,
            searchTerm,
            'allContent',
            activeSort as Sorting
          );
          setLearningPlansToShow(learningPlans);
        }
        break;
      default:
        break;
    }
  }, [
    learningUnitType,
    allLessons,
    allCourses,
    allLearningPlans,
    activeSort,
    langFilter,
    searchTerm,
    filterLearningUnitStatus,
    tagFilter
  ]);

  const lessonUnits = (() => {
    let lessons: Array<LessonUnit> = [];
    if (lessonsToShow) {
      lessons = lessonsToShow!.map(lesson => ({
        lesson,
        lessonType: LessonUnitTypes.bWLesson
      }));
    }
    return lessons;
  })();

  const CoursesUnits = (() => {
    let courses: Array<CourseUnit> = [];
    if (coursesToShow) {
      courses = coursesToShow!.map(course => ({
        course,
        courseType: CourseUnitTypes.bWCourse
      }));
    }
    return courses;
  })();

  const LearningPlansUnits = (() => {
    let learningPlans: Array<LearningPlanUnit> = [];
    if (learningPlansToShow) {
      learningPlans = learningPlansToShow!.map(learningPlan => ({
        learningPlan,
        learningPlanType: LearningPlanUnitTypes.bWLearningPlan
      }));
    }
    return learningPlans;
  })();

  const handleChangeLearningUnitTypeEvent = (items: string[]) => {
    handleChangeLearningUnitTag([]);
    handleChangeLearningUnitType(items);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          flexDirection={'row'}
          display={'flex'}
          justifyContent={'space-between'}
          sx={{
            flexDirection: {
              '@media (max-width: 1080px)': {
                flexDirection: 'column'
              },
              lg: 'row'
            }
          }}
          gap={2}
        >
          <Box
            flexDirection={'row'}
            display={'flex'}
            gap={2}
            justifyContent={'space-between'}
          >
            <FormControl variant="standard" style={formControlContainer}>
              <LearningUnitTypeFilter
                value={learningUnitType}
                onChange={handleChangeLearningUnitTypeEvent}
              />
            </FormControl>
            <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
          </Box>
          <Box
            flexDirection={'row'}
            display={'flex'}
            gap={2}
            flexWrap={'wrap'}
            justifyContent={'space-between'}
          >
            {learningUnitType !== 'lessons' && isBuildwittAdmin && (
              <FormControl variant="standard" style={formControlContainer}>
                <ContentStatusFilter
                  value={filterLearningUnitStatus}
                  onChange={handleChangeLearningUnitStatus}
                />
              </FormControl>
            )}
            {learningUnitType !== 'learningPlans' && (
              <FormControl variant="standard" style={formControlContainer}>
                <LangContentFilter
                  value={langFilter}
                  onChange={handleChangeLangFilter}
                />
              </FormControl>
            )}

            <FormControl variant="standard" style={formControlContainer}>
              <SortContentSelect
                value={activeSort}
                onChange={handleChangeSorting}
              />
            </FormControl>
            {learningUnitType !== 'learningPlans' && (
              <FormControl
                sx={{
                  width: { xs: '100%', md: '400px' }
                }}
              >
                <TaggingField
                  options={tags}
                  value={tagFilter}
                  onChange={handleChangeLearningUnitTag}
                  showAddTagsLabel={false}
                  allowNewTags={false}
                  minHeight="45px"
                  placeholder={t('searchTagsHere', { ns: 'contentLibrary' })}
                />
              </FormControl>
            )}
          </Box>
        </Box>
        {learningUnitType === 'courses' && (
          <AllCoursesNew
            totalItems={allCourses?.length ?? 0}
            completedCourses={completedCourses}
            courseUnits={CoursesUnits}
            isLoading={!coursesToShow}
            takenTimesCourses={takenTimesCourses}
          />
        )}
        {learningUnitType === 'lessons' && (
          <AllLessonsNew
            totalItems={allLessons?.length ?? 0}
            lessonUnits={lessonUnits}
            isLoading={!lessonsToShow}
            origin={ContentLibraryTabNames.Buildwitt}
            takenTimesLessons={takenTimesLessons}
          />
        )}
        {learningUnitType === 'learningPlans' && (
          <AllLearningPlansNew
            totalItems={allLearningPlans?.length ?? 0}
            learningPlanUnits={LearningPlansUnits}
            isLoading={!learningPlansToShow}
            completedLearningPlans={completedLearningPlans}
            takenTimesLearningPlans={takenTimesLearningPlans}
          />
        )}
      </Box>
    </ThemeProvider>
  );
};

const formControlContainer: CSSProperties = {
  width: '200px'
};

import { Stack, ThemeProvider, createTheme } from '@mui/material';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import themeConfig from '../../themes/theme';
import SkillCertificateList from './SkillCertificateList';
import MyCompetenceDetailFactory from './MyCompetenceDetailFactory';

const MySkills = () => {
  const theme = createTheme(themeConfig);

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 3 }}>
        <SkillCertificateList mode="mySkills" />
        <MyCompetenceDetailFactory />
      </Stack>
    </ThemeProvider>
  );
};

export default MySkills;

import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { TooltipProps, Tooltip, tooltipClasses } from '@mui/material';

export const InfoBubble = ({
  text,
  placement,
  onHover,
  isSmall
}: {
  text: string;
  placement?: TooltipProps['placement'];
  onHover?: () => void;
  isSmall?: boolean;
}) => (
  <BubbleTooltip
    onMouseOver={onHover}
    onTouchStart={onHover}
    placement={placement || 'right'}
    title={
      <StyledUl>
        <li>
          <StyledSpan>{text}</StyledSpan>
        </li>
      </StyledUl>
    }
  >
    <StyledIconButton style={{ paddingLeft: isSmall ? '16px' : '24px' }}>
      <HelpIcon />
    </StyledIconButton>
  </BubbleTooltip>
);

const BubbleTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[50]
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.grey[50]
  }
}));

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '13px'
}));

const StyledUl = styled('ul')({
  listStyle: 'none',
  margin: 0,
  padding: 0
});

const StyledIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.grey[200]
}));

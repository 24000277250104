import { ListChildComponentProps } from 'react-window';
import { CourseUnitTypes } from '../../core/constants';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useUserCanEditContent } from '../../hooks/useUserCanEditContent';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import { restrictedCourseShouldShow } from '../../utils/lessonUtils';
import Grid from '@mui/material/Grid';
import React from 'react';
import { CompletedCourseBasicInfo } from '../../entities/CompletedCourseInfo';
import { CourseUnit, AiccCourse, Course } from '../../entities/Course';
import { CourseActionsArea } from '../../pages/contentLibrary/common/CourseActionsArea';
import CourseRowWrapper from '../../pages/contentLibrary/common/CourseRowWrapper';
import CourseCardNew from '../CourseCard/CourseCardNew';
import { ClickSafetyCourseCardActions } from '../CourseCardActions';

interface CourseVirtualRowProps
  extends ListChildComponentProps<CourseUnit[][]> {
  completedCourses?: CompletedCourseBasicInfo[] | undefined;
  takenTimesCourses?: TakenTimesObject | null;
  onOpenAssignModal: (courseUnitType: string) => (id?: string) => void;
  onSelectClickSafetyCourse: (course: AiccCourse) => void;
}

const CourseVirtualRow: React.FC<CourseVirtualRowProps> = ({
  data,
  index,
  style,
  takenTimesCourses,
  completedCourses,
  onOpenAssignModal,
  onSelectClickSafetyCourse
}) => {
  const [userCanEditContent] = useUserCanEditContent();
  const user = useCurrentUser();
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="parentContainer">
        {data[index]
          .filter((x: { course: Course }) =>
            restrictedCourseShouldShow(x.course, userRoles)
          )
          .map((courseUnit: { course: Course; courseType: string }) => {
            return (
              <CourseRowWrapper key={courseUnit.course.id}>
                {courseUnit.courseType === CourseUnitTypes.companyCourse ||
                courseUnit.courseType === CourseUnitTypes.bWCourse ||
                courseUnit.courseType === CourseUnitTypes.partnerCourse ? (
                  <CourseCardNew
                    isPreviewMode={true}
                    averageLessonTime={
                      courseUnit.course.avgLessonTime
                        ? secondsToHHMMSS(courseUnit.course.avgLessonTime)
                        : '00:00:00'
                    }
                    isDraft={courseUnit.course.isDraft}
                    status={'Clear'}
                    hasSpanishSubtitles={courseUnit.course.hasSpanishSubtitles}
                    course={courseUnit.course}
                    type={courseUnit.courseType}
                    isAdmin={userCanEditContent(courseUnit.course.companyId)}
                    takenTimes={
                      takenTimesCourses
                        ? takenTimesCourses[courseUnit.course.id]
                        : undefined
                    }
                  >
                    <CourseActionsArea
                      course={courseUnit.course}
                      cardAuxiliarFunction={onOpenAssignModal(
                        courseUnit.courseType
                      )}
                    />
                  </CourseCardNew>
                ) : (
                  <CourseCardNew
                    isPreviewMode={true}
                    isDraft={false}
                    status={'Clear'}
                    hasSpanishSubtitles={courseUnit.course.hasSpanishSubtitles}
                    course={courseUnit.course}
                    type={courseUnit.courseType}
                    isAdmin={false}
                    takenTimes={
                      takenTimesCourses
                        ? takenTimesCourses[courseUnit.course.id]
                        : undefined
                    }
                  >
                    <ClickSafetyCourseCardActions
                      course={courseUnit.course as AiccCourse}
                      openAssignmentModal={onOpenAssignModal(
                        CourseUnitTypes.clickSafetyCourse
                      )}
                      setCourse={onSelectClickSafetyCourse}
                    />
                  </CourseCardNew>
                )}
              </CourseRowWrapper>
            );
          })}
      </Grid>
    </div>
  );
};

export default CourseVirtualRow;

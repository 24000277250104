import { ThemeOptions } from '@mui/material/styles';
/* TODO: complete theme */
const themeConfig: ThemeOptions = {
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#FF9E00',
      light: '#C4C4C4',
      dark: '',
      contrastText: '#fff'
    },
    secondary: {
      main: '#1B1C1C',
      dark: '#282828'
    },
    error: {
      main: '#FF0000',
      light: '#FF2A20',
      dark: '#FF2A20DE'
    },
    success: {
      main: '#2ED47A'
    },
    text: {
      primary: '#FFFFFFDE',
      secondary: 'rgba(255, 255, 255, 0.75)'
    },
    grey: {
      '50': '#C4C4C4',
      '100': '#999999',
      '200': '#8f8f8f',
      '300': '#A9B3B7',
      '400': '#282828',
      '500': '#929292',
      '600': '#3E3E3E',
      '700': '#EBEBEB',
      '800': '#0E0D0D',
      '900': '#5B5B5B',
      A100: '#3C4444',
      A200: '#232424',
      A400: '#3D3D3D',
      A700: 'rgba(255, 255, 255, 0.56)'
    },
    background: {
      default: '#E0E0E0'
    }
  },
  typography: {
    fontFamily: '"Titillium Web", "IBM Plex Sans" , "Open Sans", sans-serif',
    subtitle1: {
      fontSize: '16px',
      color: '#C4C4C4'
    },
    subtitle2: {
      fontSize: '16px',
      color: '#FFF'
    },
    caption: {
      fontSize: '14px',
      color: '#BBB'
    },
    h5: {
      fontSize: '22px',
      fontWeight: 600
    },
    h6: {
      fontSize: '24px',
      fontWeight: 600
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1d1d1e'
        }
      }
    }
  }
};

export default themeConfig;

import { styled } from '@mui/material/styles';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { EmployeeData } from '../../components/EmployeeData/EmployeeData';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../components/CustomDataGrid/CustomDataGrid';

export const getContentNameColumn = (headerName: string): GridColDef => ({
  field: 'employee',
  headerName: headerName,
  minWidth: 250,
  sortable: false,
  flex: 1,
  renderHeader: () => (
    <div style={{ paddingLeft: 20 }}>
      <span>{headerName}</span>
    </div>
  ),
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <EmployeeData
      firstName={params.row.employee.firstName}
      lastName={params.row.employee.lastName}
      photoUrl={params.row.employee.photoUrl}
      jobTitle={params.row.employee.jobTitle}
    />
  )
});

export const getTaskNameColumn = (headerName: string): GridColDef => ({
  field: 'learningUnitName',
  headerName: headerName,
  minWidth: 250,
  sortable: false,
  flex: 1,
  renderHeader: () => (
    <div style={{ paddingLeft: 20 }}>
      <span>{headerName}</span>
    </div>
  ),
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <div className="d-flex ps-1 pt-2 pb-2">
      <div className="d-flex flex-column px-3">
        <span style={{ fontSize: 14 }}>{params.row.learningUnitName}</span>
      </div>
    </div>
  )
});

const EmployeeSkillContentList = () => {
  const { t } = useTranslation(['skills']);
  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('employee', { ns: 'skills' })),
    getTaskNameColumn(t('taskName', { ns: 'skills' }))
  ])();
  const employeesWithAssignedSkill = useSelector(
    (state: ApplicationState) => state?.achievements?.employeesWithAssignedSkill
  );

  const isLoading = useSelector(
    (state: ApplicationState) => state?.achievements?.isLoading
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="50vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box
      sx={{
        height: '60vh',
        width: '100%'
      }}
    >
      {employeesWithAssignedSkill && !!employeesWithAssignedSkill.length && (
        <ModifiedCustomDataGrid
          getRowId={row => row.employee.id}
          rows={employeesWithAssignedSkill}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          getRowClassName={getCustomRowClassName}
          disableColumnMenu
        />
      )}
    </Box>
  );
};

export const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  fontSize: '16px',
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  }
}));

export default EmployeeSkillContentList;

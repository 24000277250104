import { CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';

interface VideoPlayerControlsProps {
  visible_button_refresh: boolean;
}
const VideoPlayerControls: React.FC<
  React.PropsWithChildren<VideoPlayerControlsProps>
> = ({ visible_button_refresh, children }) => {
  return (
    <div style={divPlayerStyle}>
      <Row className="justify-content-center h-100 align-items-center">
        <Col>
          <div className="player-wrapper h-100 d-flex justify-content-center">
            {visible_button_refresh && (
              <div
                style={videoController}
                className="video-controller justify-content-between"
              >
                {children}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const videoController: CSSProperties = {
  bottom: '0',
  width: '100%',
  margin: '0',
  display: 'flex'
};

const divPlayerStyle: CSSProperties = {
  backgroundColor: '#1D1D1E',
  height: '50px',
  borderRadius: '0px 0px 5px 5px'
};

export default VideoPlayerControls;

export interface EmployeeDataProps {
  firstName: string;
  lastName: string;
  photoUrl: string;
  jobTitle: number;
}

export const EmployeeData = ({
  firstName,
  lastName,
  photoUrl,
  jobTitle
}: EmployeeDataProps) => (
  <div className="d-flex ps-1 pt-2 pb-2">
    <img
      className={`img-profile rounded-circle`}
      width="36"
      height="36"
      alt={`${firstName} ${lastName}`}
      src={
        photoUrl && photoUrl.length
          ? photoUrl
          : require('../../assets/user.png')
      }
    />
    <div className="d-flex flex-column px-3">
      <span style={{ fontSize: 14 }}>{`${firstName} ${lastName}`}</span>
      <span style={{ fontSize: 12, color: '#999999' }}>{jobTitle}</span>
    </div>
  </div>
);

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/assessments';
import { Assessment } from '../entities/Assessment';
import { TransactionStatusEnum } from '../core/enums';

import {
  ASSESSMENT_SET_ASSESSMENT,
  ASSESSMENT_RESET_TRANSACTION_STATUS,
  ASSESSMENT_LIST_FAILED_QUIZ,
  ASSESSMENT_MARK_QUIZ_AS_FAILED,
  ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES,
  ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED
} from '../actions/actionTypes';

export interface AssessmentsState {
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  currentAssessment: Assessment | null;
  failedQuizzes: string[] | null;
  failedAssignmentQuizzes: string[] | null;
}

const unloadedState: AssessmentsState = {
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  currentAssessment: null,
  failedQuizzes: null,
  failedAssignmentQuizzes: null
};

export const AssessmentsReducer: Reducer<AssessmentsState> = (
  state: AssessmentsState | undefined,
  incomingAction: Action
): AssessmentsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case ASSESSMENT_SET_ASSESSMENT:
      return { ...state, currentAssessment: action.assessment };
    case ASSESSMENT_LIST_FAILED_QUIZ:
      return { ...state, failedQuizzes: action.failedQuiz };
    case ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES:
      return {
        ...state,
        failedAssignmentQuizzes: action.failedQuizzes
      };
    case ASSESSMENT_MARK_QUIZ_AS_FAILED:
      return {
        ...state,
        failedQuizzes: [...state.failedQuizzes!, action.failedQuiz]
      };
    case ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED: {
      const newFailedQuizzes = state.failedAssignmentQuizzes
        ? [...state.failedAssignmentQuizzes!]
        : [action.failedQuiz];
      return {
        ...state,
        failedAssignmentQuizzes: newFailedQuizzes
      };
    }
    case ASSESSMENT_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    default:
      return state;
  }
};

import { useMediaQuery } from '@mui/material';
import { CSSProperties } from 'react';
import { Dropdown } from 'react-bootstrap';
import { TrackProps } from 'react-player/file';

interface SubtitlesControlProps {
  langShow: boolean;
  setLangShow: (value: boolean) => void;
  videoTracks: TrackProps[];
  selectedLanguage: string;
  handleSwitchLanguage: (e: React.MouseEvent) => void;
}

const SubtitlesControl: React.FC<SubtitlesControlProps> = ({
  langShow,
  handleSwitchLanguage,
  selectedLanguage,
  setLangShow,
  videoTracks
}) => {
  const isSmScreen = useMediaQuery('(max-width:640px)');
  const responsiveStyles: CSSProperties = {
    textAlign: 'center',
    width: isSmScreen ? '70px' : '101px',
    height: isSmScreen ? '15px' : '34px',
    fontSize: isSmScreen ? '8px' : '13px',
    lineHeight: isSmScreen ? '0' : 'normal',
    marginLeft: isSmScreen ? '10px' : '0px'
  };

  return (
    <div style={{ marginRight: '8px' }}>
      <Dropdown autoClose="inside" show={langShow}>
        <Dropdown.Toggle
          //style={toggleRate}
          style={{ ...toggleRate, ...responsiveStyles }}
          onClick={() => setLangShow(!langShow)}
          disabled={!videoTracks || videoTracks.length === 0}
          size="sm"
        >
          {videoTracks && videoTracks.length > 0 && selectedLanguage
            ? selectedLanguage
            : 'No captions'}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <button
            style={styleBtnPlaybackRate}
            onClick={handleSwitchLanguage}
            value={'No captions'}
          >
            {'No captions'}
          </button>
          {videoTracks &&
            videoTracks.map(t => (
              <button
                key={t.label}
                style={styleBtnPlaybackRate}
                onClick={handleSwitchLanguage}
                value={t.label}
              >
                {t.label}
              </button>
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const styleBtnPlaybackRate: CSSProperties = {
  width: '100%',
  margin: '5px 0px',
  border: 'none',
  color: '#0E0D0D'
};

const toggleRate: CSSProperties = {
  background: '#fa9e04',
  borderColor: '#fa9e04',
  color: '#0E0D0D',
  textAlign: 'right',
  fontFamily: 'Titillium Web',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px'
};

export default SubtitlesControl;

import TagInput, { TagInputProps } from './TagInput';
import { Tag } from '../../entities/Tag';
import React from 'react';
import { Guid } from 'guid-typescript';
import { useTranslation } from 'react-i18next';
import FormLabel from '../core/FormLabel/FormLabel';

interface TaggingFieldProps extends Pick<TagInputProps<true>, 'options'> {
  onChange: (value: Tag[]) => void;
  value: Tag[];
  required?: boolean;
  allowNewTags?: boolean;
  showAddTagsLabel?: boolean;
  minHeight?: string;
  placeholder?: string;
  dataTestPrefix?: string;
  dataTrackPrefix?: string;
}

export const TaggingField = ({
  options,
  value,
  onChange,
  required,
  allowNewTags = true,
  showAddTagsLabel = true,
  minHeight,
  placeholder,
  dataTestPrefix,
  dataTrackPrefix
}: TaggingFieldProps) => {
  const { t } = useTranslation(['common']);

  const createTag = (name: string): Tag => ({
    id: Guid.create().toString(),
    name
  });

  const handleTagChange = (value: (Tag | string)[]) => {
    const newTagsValue = value.map(tag => {
      if (typeof tag === 'string') return createTag(tag);
      else return tag;
    });
    onChange(newTagsValue);
  };

  const onTagChange = (_: React.SyntheticEvent, value: (Tag | string)[]) => {
    handleTagChange(value);
  };

  const onTagBlur = (inputText: string) => {
    if (!allowNewTags) return;
    const newTags = [...value, inputText];
    handleTagChange(newTags);
  };

  return (
    <>
      {showAddTagsLabel && (
        <FormLabel>
          {t('addTags', { ns: 'common' })}
          {required && <span className="text-danger">*</span>}
        </FormLabel>
      )}
      <TagInput
        allowNew={allowNewTags}
        value={value}
        options={options}
        onChange={onTagChange}
        onBlurChange={onTagBlur}
        placeholder={placeholder ?? t('writeTagHere', { ns: 'common' })}
        minHeight={minHeight}
        dataTestPrefix={dataTestPrefix}
        dataTrackPrefix={dataTrackPrefix}
      />
    </>
  );
};

export default TaggingField;

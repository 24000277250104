import { useState } from 'react';
import { Stack, ThemeProvider, Typography, useTheme } from '@mui/material';
import { TabConfig, Tabs, defaultTheme } from '@buildwitt/component-library';
import { useTranslation } from 'react-i18next';
import { createTheme } from '@mui/material/styles';

import PageContainer from '../../components/core/PageContainer/PageContainer';
import MyCertificationTab from './myCertifications/MyCertificationTab';

enum ProfileTabKey {
  myCertifications = 'myCertifications'
}

const Profile = () => {
  const theme = useTheme();
  const customTheme = createTheme(defaultTheme, {
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            height: '3px'
          },
          root: {
            marginBottom: '1em',
            borderBottom: `1px solid #999999`
          }
        }
      }
    }
  });
  const { t } = useTranslation(['profile', 'common']);
  const [tabKeyValue, setTabKeyValue] = useState<string>(
    ProfileTabKey.myCertifications
  );
  const justifyContentStyle =
    tabKeyValue === ProfileTabKey.myCertifications
      ? 'space-between'
      : 'flex-start';

  const getTabConfig = (): TabConfig[] => [
    {
      label: `${t(ProfileTabKey.myCertifications, { ns: 'profile' })}`,
      key: ProfileTabKey.myCertifications,
      component: <MyCertificationTab />
    }
  ];

  const handleSelectedTabKeyValue = (value?: string | number) => {
    if (value) setTabKeyValue(value as string);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <PageContainer>
        <Stack
          direction="row"
          sx={{
            justifyContent: justifyContentStyle,
            alignItems: 'center',
            height: '70px'
          }}
        >
          <Typography sx={theme.typography.h6}>{t(tabKeyValue)}</Typography>
        </Stack>
        <Stack>
          <Tabs
            tabConfig={getTabConfig()}
            getSelectedTabKeyValue={handleSelectedTabKeyValue}
          />
        </Stack>
      </PageContainer>
    </ThemeProvider>
  );
};

export default Profile;

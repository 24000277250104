import React, { useState, useRef, CSSProperties } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Lesson, LessonAttachment } from '../../../entities/Lesson';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadIcon from '../../../assets/downloadIcon.svg';
import RichTextInput from '../../../components/core/RichTextInput/RichTextInput';

const MenuItemStyled = styled(MenuItem)(() => ({
  '& .Mui-selected': {
    backgroundColor: '#ff9e00 !important',
    color: '#000 !important'
  }
}));

interface AttachmentSectionProps {
  currentLesson: Lesson;
}

const AttachmentSection: React.FC<AttachmentSectionProps> = ({
  currentLesson
}) => {
  const { t } = useTranslation(['lessons', 'common']);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const newAttachmentList = currentLesson.attachments;
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = (event: Event) => {
    setOpen(false);
  };
  const DownloadFileUrl = (file: LessonAttachment) => {
    fetch(file.fileUrl)
      .then(res => res.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(new Blob([blob]));
        const aTag = document.createElement('a');
        aTag.href = blobUrl;
        aTag.setAttribute('download', file.fileName!);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
      });
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    url: string
  ) => {
    if (currentLesson.attachments.length !== 0) {
      setOpen(false);
      DownloadFileUrl(currentLesson.attachments[index]);
    }
  };
  const isSmScreen = useMediaQuery('(max-width:640px)');
  return (
    <>
      <Grid container style={containerGrid}>
        <Grid item xs={12} sm={12} md={6} style={leftSection}>
          <Col>
            <Row xs={12} sm={12}>
              <Typography style={{ fontSize: '20px' }}>
                {currentLesson.title}
              </Typography>
            </Row>
            <Row xs={12} sm={12}>
              <RichTextInput
                value={currentLesson.description}
                disabled={true}
              />
            </Row>
          </Col>
        </Grid>
        {newAttachmentList.length > 0 && (
          <Grid
            xs={12}
            sm={12}
            md={6}
            style={{
              ...rightSection,
              justifyContent: isSmScreen ? 'center' : 'flex-end'
            }}
          >
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              style={{ marginTop: isSmScreen ? '15px' : '0px' }}
            >
              <Button style={buttonRightStyle} onClick={handleToggle}>
                {t('attachments', { ns: 'lessons' })}
              </Button>
              <Button
                style={buttonLeftStyle}
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label={t('attachments', { ns: 'lessons' })}
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDownIcon />
              </Button>
            </ButtonGroup>

            <Popper
              sx={{
                zIndex: 1
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center bottom' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {newAttachmentList.map((option, index) => (
                          <MenuItemStyled
                            style={{ color: '#EBEBEB' }}
                            key={option.fileName}
                            onClick={event =>
                              handleMenuItemClick(event, index, option.fileUrl)
                            }
                          >
                            <ListItemText primary={option.fileName} />
                            <ListItemIcon>
                              <img
                                style={{ marginLeft: '15px' }}
                                src={DownloadIcon}
                                alt=""
                              />
                            </ListItemIcon>
                          </MenuItemStyled>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const containerGrid: CSSProperties = {
  paddingTop: '20px',
  paddingLeft: '5px',
  paddingRight: '5px'
};

const rightSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  alignItems: 'center'
};

const leftSection: CSSProperties = {
  width: '100%',
  height: '20%',
  display: 'flex',
  flexDirection: 'row' as const,
  justifyContent: 'flex-start',
  alignItems: 'center'
};
const buttonStyle: CSSProperties = {
  backgroundColor: '#C4C4C4',
  color: '#0E0D0D',
  textTransform: 'none' as const,
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '22px'
};
const buttonRightStyle: CSSProperties = {
  ...buttonStyle,
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px'
};
const buttonLeftStyle: CSSProperties = {
  ...buttonStyle,
  backgroundColor: '#999999',
  borderTopRightRadius: '25px',
  borderBottomRightRadius: '25px'
};

export default AttachmentSection;

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../store';
import { Tag } from '../entities/Tag';
import { OnsiteTraining } from '../entities/OnsiteTraining';

export const useSkills = () => {
  const { t } = useTranslation(['skills', 'common']);
  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );
  const availableOnsiteTrainings = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingsAll
  );

  const employeeTags = useMemo(() => {
    if (activeEmployees) {
      return activeEmployees.map((employee): Tag => {
        const name = employee.firstName.length
          ? `${employee.firstName} ${employee.lastName}`
          : employee.email && employee.email.length
            ? employee.email
            : employee.phoneNumber!;
        return {
          id: employee.id,
          name
        };
      });
    }
  }, [activeEmployees]);

  const onsiteTrainingTags = useMemo(() => {
    if (availableOnsiteTrainings) {
      return availableOnsiteTrainings.map((onsiteTraining: OnsiteTraining) => ({
        id: onsiteTraining.id,
        name: onsiteTraining.name
      }));
    }
  }, [availableOnsiteTrainings]);

  const isCertificateOptions = [
    {
      label: t('skill', { ns: 'skills' }),
      value: 'Skill'
    },
    {
      label: t('certificate', { ns: 'skills' }),
      value: 'Certificate'
    }
  ];
  const generatePDFOptions = [
    {
      label: t('yes', { ns: 'common' }),
      value: 'Yes'
    },
    {
      label: 'No',
      value: 'No'
    }
  ];
  const durationUnitOptions = [
    {
      label: t('years', { ns: 'skills' }),
      value: 'Year'
    },
    {
      label: t('months', { ns: 'skills' }),
      value: 'Month'
    }
  ];
  const durationOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' }
  ];
  const notificationDaysOptions = ['90', '30', '7'];

  return {
    constants: {
      isCertificateOptions,
      generatePDFOptions,
      durationUnitOptions,
      durationOptions,
      notificationDaysOptions,
      employeeTags,
      onsiteTrainingTags
    },
    state: {},
    api: {}
  };
};

import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { AssignedCourse } from '../../entities/LearningPlaylist';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import CourseCardNew from './CourseCardNew';
import useCourseCard from './useCourseCard';
import ButtonsSection from '../AssignmentsButtonsSection/ButtonSection';
import { EntityTypeEnum } from '../../core/enums';

const courseLessonWrapperStyle: SxProps = {
  maxWidth: '370px',
  width: '100%'
};

interface AssignmentCourseCardProps {
  assignedCourse: AssignedCourse;
}

const AssignmentCourseCard: React.FC<AssignmentCourseCardProps> = ({
  assignedCourse
}) => {
  const { course, isComplete, dueDateMoment, assignmentId } = assignedCourse;
  const { id, revisionNumber } = course;

  const { getCourseType, takenTimesCourses, handleStartCourse } =
    useCourseCard();
  const { t } = useTranslation(['common']);
  const buttonCaption = isComplete
    ? t('revisit', { ns: 'common' })
    : t('start', { ns: 'common' });

  return (
    <Box sx={courseLessonWrapperStyle}>
      <CourseCardNew
        key={id}
        averageLessonTime={
          course.avgLessonTime
            ? secondsToHHMMSS(course.avgLessonTime)
            : '00:00:00'
        }
        isDraft={course.isDraft}
        status={isComplete ? 'Completed' : 'Clear'}
        hasSpanishSubtitles={course.hasSpanishSubtitles}
        course={course}
        isAdmin={false}
        assignmentDueDate={dueDateMoment!}
        type={getCourseType(course.isAicc)}
        takenTimes={takenTimesCourses ? takenTimesCourses[id] : undefined}
      >
        <ButtonsSection
          onClick={() =>
            handleStartCourse(
              id,
              revisionNumber,
              course.title,
              course.isAicc,
              assignmentId
            )
          }
          startButtonCaption={buttonCaption}
          startButtonColor={isComplete ? 'revisit' : 'primary'}
          entityType={EntityTypeEnum.Course}
          assignment={assignedCourse}
        />
      </CourseCardNew>
    </Box>
  );
};

export default AssignmentCourseCard;

import { useEffect, useState } from 'react';
import { Stack, ThemeProvider, createTheme } from '@mui/material';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import themeConfig from '../../themes/theme';
import SkillManageList from './SkillManageList';
import SkillDetail from './SkillDetail';
import CreateSkillCertificate from './createSkill/CreateSkillCertificate';
import { CRUDAction } from '../../core/enums';
import { actionCreators as coursesActions } from '../../actions/courses';
import { actionCreators as learningPlansActions } from '../../actions/learningPlans';
import { useAppDispatch } from '../../store/hooks';

const ManageSkills = () => {
  const dispatch = useAppDispatch();
  const [skillSelected, setSkill] = useState<string>('');
  const theme = createTheme(themeConfig);
  const [showCreateSkillForm, setShowCreateSkillForm] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(coursesActions.requestCourses());
    dispatch(learningPlansActions.requestPlans());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 3 }}>
        <SkillManageList
          onSelectSkill={setSkill}
          onCreateSkill={() => setShowCreateSkillForm(true)}
          creatingSkillInProgress={showCreateSkillForm}
          skillSelected={skillSelected}
        />
        {showCreateSkillForm && (
          <CreateSkillCertificate
            action={CRUDAction.Create}
            onClose={() => setShowCreateSkillForm(false)}
          />
        )}
        {!showCreateSkillForm && (
          <SkillDetail skillSelected={skillSelected} onSelectSkill={setSkill} />
        )}
      </Stack>
    </ThemeProvider>
  );
};

export default ManageSkills;

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font
} from '@react-pdf/renderer';

import {
  TitilliumWebBold,
  TitilliumWebNormal,
  TitilliumWebSemiBold
} from '../../core/constants';
import { PdfCertificate } from '../../entities/Achievements';
import BwTrainingLogo from '../../assets/BWTrainingLogo.png';
import BWlogo from '../../assets/buildwitt-logo-dark.png';

interface CertificatePDFTemplateProps {
  pdfData?: PdfCertificate;
}

Font.register({
  family: 'Titillium Web',
  fonts: [
    {
      src: TitilliumWebNormal
    },
    {
      src: TitilliumWebSemiBold,
      fontWeight: 600
    },
    {
      src: TitilliumWebBold,
      fontWeight: 'bold'
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentVieW: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '490px',
    width: '90%',
    border: '1px solid #999',
    paddingLeft: '32px',
    paddingRight: '32px',
    paddingTop: '20px'
  },
  logo: {
    width: '200px',
    height: '40px',
    objectFit: 'contain'
  },
  certificateName: {
    fontWeight: 700,
    fontSize: '30px',
    paddingTop: '16px',
    paddingBottom: '25px',
    fontFamily: 'Titillium Web'
  },
  standardText: {
    fontFamily: 'Titillium Web',
    fontSize: '14px'
  },
  employeeName: {
    fontFamily: 'Titillium Web',
    color: '#FF9E00',
    fontSize: '25px',
    fontWeight: 600
  },
  mainDivSeparator: {
    width: '80%',
    borderBottom: '0.5px solid #999',
    paddingTop: '12px'
  },
  subContentView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '30px',
    width: '100%'
  },
  contentItemView: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '250px'
  },
  itemView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '160px',
    borderBottom: '0.5px solid #999'
  },
  learningUnitText: {
    fontFamily: 'Titillium Web',
    fontSize: '18px',
    textAlign: 'center'
  },
  styledText1: {
    fontFamily: 'Titillium Web',
    fontSize: '16px'
  },
  bwTrainingLogo: {
    width: '130px',
    height: '70px',
    objectFit: 'contain'
  },
  subContentViewAlternative: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
    padding: '30px',
    width: '100%'
  }
});

const ContentWithExpirationDate = ({
  pdfData
}: CertificatePDFTemplateProps) => {
  return (
    <View style={styles.subContentView}>
      <View style={styles.contentItemView}>
        <View style={styles.itemView}>
          <Text style={styles.styledText1}>{pdfData?.formattedCreatedAt}</Text>
        </View>
        <Text style={styles.standardText}>DATE Awarded</Text>
      </View>
      <View style={styles.contentItemView}>
        <View style={styles.itemView}>
          <Text style={styles.styledText1}>
            {pdfData?.formattedExpirationDate}
          </Text>
        </View>
        <Text style={styles.standardText}>EXPIRATION DATE</Text>
      </View>
      <View style={styles.contentItemView}>
        <View style={styles.itemView}>
          {pdfData?.awarderNames.map(awarder => (
            <Text key={awarder} style={styles.styledText1}>
              {awarder}
            </Text>
          ))}
        </View>
        <Text style={styles.standardText}>AWARDER</Text>
      </View>
    </View>
  );
};

const ContentWithoutExpirationDate = ({
  pdfData
}: CertificatePDFTemplateProps) => {
  return (
    <View style={styles.subContentViewAlternative}>
      <View style={styles.contentItemView}>
        <View style={styles.itemView}>
          <Text style={styles.styledText1}>{pdfData?.formattedCreatedAt}</Text>
        </View>
        <Text style={styles.standardText}>DATE Awarded</Text>
      </View>
      <View style={styles.contentItemView}>
        <View style={styles.itemView}>
          {pdfData?.awarderNames.map(awarder => (
            <Text key={awarder} style={styles.styledText1}>
              {awarder}
            </Text>
          ))}
        </View>
        <Text style={styles.standardText}>AWARDER</Text>
      </View>
    </View>
  );
};

const CertificatePDFTemplate = ({ pdfData }: CertificatePDFTemplateProps) => {
  const ContentSection = pdfData?.formattedExpirationDate.length
    ? ContentWithExpirationDate
    : ContentWithoutExpirationDate;
  const logoURL = pdfData?.companyLogoUrl ? pdfData?.companyLogoUrl : BWlogo;
  return (
    <Document>
      <Page style={styles.page} orientation="landscape">
        <View style={styles.contentVieW}>
          <Image source={logoURL} style={styles.logo} />
          <Text style={styles.certificateName}>{pdfData?.name}</Text>
          <Text style={styles.standardText}>PRESENTED TO</Text>
          <Text style={styles.employeeName}>{pdfData?.employeeName}</Text>
          <View style={styles.mainDivSeparator} />
          <Text
            style={styles.learningUnitText}
          >{`For completing ${pdfData?.learningUnitName}`}</Text>
          <ContentSection pdfData={pdfData} />
          <Image style={styles.bwTrainingLogo} source={BwTrainingLogo} />
        </View>
      </Page>
    </Document>
  );
};

export default CertificatePDFTemplate;

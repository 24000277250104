import { CSSProperties, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  CustomIconButton,
  getCompletedOnColumn,
  getGenericValueColumn
} from '../common/utilAnalyticsColumnDefinition';
import { useTranslation } from 'react-i18next';
import { AllGradesModalDataType } from './QuizzesDetail';
import { QuizResult } from '../../../entities/Analytics';
import { GreenSpan, OrangeSpan, RedSpan } from '../common/StyledSpan';
import GradeOverViewModalSingleUser from '../../../components/Analytics/GradeOverviewModal/GradeOverViewModalSingleUser';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as analytcsActions } from '../../../actions/analytics';
import { useAppDispatch } from '../../../store/hooks';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../../components/CustomDataGrid/CustomDataGrid';
export interface AllGradesModalProps {
  AllGradesModalData: AllGradesModalDataType;
  onClose: () => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const AllGradesModal = ({
  AllGradesModalData,
  onClose
}: AllGradesModalProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const [gradesData, setGradesData] = useState<QuizResult[]>([]);
  const quizResult = useSelector(
    (state: ApplicationState) => state.analytics?.quizResult
  );
  const dispatch = useAppDispatch();
  const [modalSingleQuiz, setModalSingleQuiz] = useState<boolean>(false);
  const getGrade = (params: GridRenderCellParams<any>) => {
    const grade = (
      params.row.grade ? params.row.grade.toFixed(0) : 0
    ) as number;
    const Container =
      grade <= 55 ? RedSpan : grade <= 70 ? OrangeSpan : GreenSpan;
    return <Container>{`${grade}%`}</Container>;
  };

  const subTitleStyle: CSSProperties = {
    fontSize: '20px',
    color: theme.palette.primary.main
  };

  const titleStyle: CSSProperties = {
    fontSize: '24px',
    color: theme.palette.common.white
  };

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  const handleClickModalSingleResult = (params: GridRenderCellParams<any>) => {
    dispatch(analytcsActions.getQuizResult(params.row.resultId));
    setModalSingleQuiz(true);
  };
  const handleCloseSingleQuizModal = () => {
    setModalSingleQuiz(false);
    dispatch(analytcsActions.setQuizResult(null));
  };
  const columns: GridColDef[] = (() => [
    getGenericValueColumn(
      'grade',
      t('grade', { ns: 'analytics' }),
      450,
      getGrade
    ),
    getCompletedOnColumn(
      'createdTimestampUtc',
      t('completedOn', { ns: 'analytics' }),
      150
    ),
    {
      field: 'buttons',
      headerName: '',
      minWidth: 280,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any>) => (
        <CustomIconButton
          size="large"
          color="info"
          onClick={() => handleClickModalSingleResult(params)}
        >
          {t('viewQuestionResponses', { ns: 'analytics' })}
        </CustomIconButton>
      )
    }
  ])();

  useEffect(() => {
    if (AllGradesModalData.data && AllGradesModalData.data.length > 0) {
      setGradesData(
        AllGradesModalData.data.filter(
          (item: { employeeId: string }) =>
            item.employeeId === AllGradesModalData.employeeId
        )[0].quizResults
      );
    }
  }, [AllGradesModalData.data, AllGradesModalData.employeeId]);

  const average =
    gradesData.reduce(
      (total, next) => total + (next.grade ? next.grade : 0),
      0
    ) / gradesData.length;

  return (
    <CustomDialog
      open={!!AllGradesModalData}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <IconButton aria-label="close" onClick={onClose} sx={{ ...iconStyle }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex'
          }}
        >
          <Stack
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 1.5rem'
            }}
          >
            <span style={titleStyle}>{AllGradesModalData.quizName}</span>
            <span style={subTitleStyle}>{AllGradesModalData.employeeName}</span>
          </Stack>
          <Stack
            direction="column"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '0 1.5rem',
              borderLeft: 1,
              borderColor: theme.palette.grey['100'],
              marginLeft: '30px',
              paddingLeft: '50px'
            }}
          >
            <span
              style={{
                ...titleStyle,
                color:
                  average <= 55
                    ? theme.palette.error.main
                    : average <= 70
                      ? theme.palette.primary.main
                      : theme.palette.success.main
              }}
            >
              {average.toFixed() + '%'}
            </span>
            <span
              style={{ ...subTitleStyle, color: theme.palette.common.white }}
            >
              {t('averageQuizGrade', { ns: 'analytics' })}
            </span>
          </Stack>
        </Box>
        <Box
          sx={{
            height: '60vh',
            width: '100%',
            textAlign: 'center'
          }}
        >
          <CustomDataGrid
            getRowId={row => row.createdTimestampUtc}
            rows={gradesData}
            columns={columns}
            pageSizeOptions={[100]}
            getRowClassName={getCustomRowClassName}
            rowSelection={false}
            disableColumnMenu
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'grade',
                    sort: 'desc'
                  }
                ]
              },
              pagination: {
                paginationModel: {
                  pageSize: 100
                }
              }
            }}
          />
        </Box>
        {quizResult && (
          <GradeOverViewModalSingleUser
            employeeName={AllGradesModalData.employeeName}
            asessmentResult={quizResult}
            showModal={modalSingleQuiz}
            onClose={handleCloseSingleQuizModal}
          />
        )}
      </DialogContent>
    </CustomDialog>
  );
};

export default AllGradesModal;

import { Grid, IconButton, ThemeProvider, createTheme } from '@mui/material';
import themeConfig from '../../themes/theme';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../components/core/SearchBox/SearchBox';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { EngagementDataType } from '../../hooks/useAnalyticsEngagement';
import { SetStateAction } from 'react';
import { AnalyticsContentLibrary } from '../../entities/Analytics';
import { QuizDataType } from '../../hooks/useAnalyticsQuizzes';

interface SearchControlPanelProps {
  employeeActiveUpdatesFlag: boolean;
  data:
    | AnalyticsContentLibrary[]
    | EngagementDataType
    | QuizDataType
    | null
    | undefined;
  downloadingReport: boolean;
  handleSearchData: (searchFilter: string) => void;
  searchText: string;
  handleDownloadCsvReport: () => void;
  includeSelfAssignments: boolean;
  setIncludeSelfAssignments: (value: SetStateAction<boolean>) => void;
  filterOption: string;
  filterOptions: {
    label: string;
    value: string;
  }[];
  handleChangeFilterOption: (items: string[]) => void;
}
const SearchControlPanel: React.FC<SearchControlPanelProps> = ({
  employeeActiveUpdatesFlag,
  data,
  downloadingReport,
  handleSearchData,
  searchText,
  handleDownloadCsvReport,
  includeSelfAssignments,
  setIncludeSelfAssignments,
  filterOption,
  filterOptions,
  handleChangeFilterOption
}) => {
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['analytics']);

  const filterOptionsStatus = {
    viewAll: filterOptions[0].value,
    AciveOnly: filterOptions[1].value,
    subscribedAndActive: filterOptions[2].value
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container flexDirection={'row'}>
        {employeeActiveUpdatesFlag && (
          <FormCheckBox
            data-testid="excludeUnsubscribedEmployeesTestId"
            data-trackid="excludeUnsubscribedEmployeesTrackId"
            label={t('excludeUnsubscribedEmployees', { ns: 'analytics' })}
            checked={filterOption === filterOptionsStatus.subscribedAndActive}
            onChange={e =>
              handleChangeFilterOption(
                e.target.checked
                  ? [filterOptionsStatus.subscribedAndActive]
                  : [filterOptionsStatus.viewAll]
              )
            }
            disabled={!data}
          />
        )}
        <FormCheckBox
          data-testid="includeSelfAssignmentsTestId"
          data-trackid="includeSelfAssignmentsTrackId"
          label={t('includeSelfAssignments', { ns: 'analytics' })}
          checked={includeSelfAssignments}
          onChange={e => setIncludeSelfAssignments(e.target.checked)}
          disabled={!data}
        />
        <SearchBox
          data-testid="searchContentTestId"
          data-trackid="searchContentTrackId"
          id="searchContent"
          name="searchContent"
          value={searchText}
          onChangeValue={handleSearchData}
          placeholderText={t('search', { ns: 'common' })}
          width="250px"
          height="45px"
        />
        <IconButton
          aria-label="downloadCSV"
          size="large"
          onClick={handleDownloadCsvReport}
          disabled={!data || downloadingReport}
          sx={{ color: theme.palette.grey[100], paddingTop: 0 }}
        >
          <DownloadForOfflineRoundedIcon style={{ fontSize: 45 }} />
        </IconButton>
      </Grid>
    </ThemeProvider>
  );
};

export default SearchControlPanel;

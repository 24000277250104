import { Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { OnsiteTrainingPreviewDetailsBanner } from './OnsiteTrainingPreviewDetailsBanner';
import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import { useTranslation } from 'react-i18next';

export const OnsiteTrainingDetailsBanner = ({
  includePreview
}: {
  includePreview: boolean;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['onsiteTraining', 'common']);
  return (
    <Grid item sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <Grid container direction={'column'} gap={2}>
        {includePreview && <OnsiteTrainingPreviewDetailsBanner />}
        <Grid item xs={6} md={4} style={{ marginLeft: '24px' }}>
          <GoBackOption
            textLink={t('backToOnsiteTraining', { ns: 'onsiteTraining' })}
            onGoBack={() => navigate(-1)}
          />
        </Grid>
        <Grid item xs={0} md={4}></Grid>
      </Grid>
    </Grid>
  );
};

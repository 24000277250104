import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { AssignmentTypes } from '../../entities/LearningPlaylist';
import AssignmentCardList from './AssignmentCardList';
import EmptyCard from '../../components/EmptyCard/EmptyCard';

export interface RecentlyAssignmentsTabProps {
  learningUnits?: Array<AssignmentTypes>;
  assignmentTypeFilter: string;
}

const RecentlyAssignmentsTab: React.FC<RecentlyAssignmentsTabProps> = ({
  learningUnits,
  assignmentTypeFilter
}) => {
  const [recentlyAssignedLearningUnits, setRecentlyAssignedLearningUnits] =
    useState<Array<AssignmentTypes>>();
  const [learningUnitsToShow, setLearningUnitsToShow] =
    useState<Array<AssignmentTypes>>();
  const { t } = useTranslation(['assignments']);

  useEffect(() => {
    if (learningUnits) {
      const past30daysDate = moment().add(-30, 'd').startOf('day');

      let recentlyAssigned = learningUnits.filter(learningUnit =>
        moment(learningUnit.createdTimestampUtcMoment).isAfter(past30daysDate)
      );

      recentlyAssigned = recentlyAssigned.sort(
        (a: AssignmentTypes, b: AssignmentTypes) =>
          a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );

      setRecentlyAssignedLearningUnits(recentlyAssigned);
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        recentlyAssigned
      );

      filteredLearningUnits.sort((a: AssignmentTypes, b: AssignmentTypes) =>
        a.dueDateUtc > b.dueDateUtc ? 1 : -1
      );
      setLearningUnitsToShow(filteredLearningUnits);
    }
  }, [learningUnits]);

  useEffect(() => {
    if (recentlyAssignedLearningUnits) {
      const filteredLearningUnits = filterByAssignmentType(
        assignmentTypeFilter,
        recentlyAssignedLearningUnits
      );
      setLearningUnitsToShow(filteredLearningUnits);
    }
  }, [assignmentTypeFilter]);

  const filterByAssignmentType = (
    assignmentType: string,
    source: Array<AssignmentTypes>
  ) => {
    return source.filter(
      learningUnit =>
        learningUnit.assignmentType === assignmentType || assignmentType === ''
    );
  };

  return (
    <>
      {!!recentlyAssignedLearningUnits &&
        !!recentlyAssignedLearningUnits.length && (
          <AssignmentCardList assignedLearningUnits={learningUnitsToShow!} />
        )}
      {!recentlyAssignedLearningUnits && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {!!recentlyAssignedLearningUnits &&
        !recentlyAssignedLearningUnits.length && (
          <EmptyCard width="370px" height="325px">
            <h5>
              {t('youDontHaveAssignmentsSincePastMonth', { ns: 'assignments' })}
            </h5>
          </EmptyCard>
        )}
    </>
  );
};

export default RecentlyAssignmentsTab;

//courses
export const COURSES_SET_COURSES = 'COURSES_SET_COURSES';
export const COURSES_SET_BW_COURSES = 'COURSES_SET_BW_COURSES';
export const COURSES_SET_EDITING = 'COURSES_SET_EDITING';
export const COURSES_SET_TAGS = 'COURSES_SET_TAGS';
export const COURSE_BY_ID = 'COURSE_BY_ID';
export const COURSES_SET_LESSON_NAMES = 'COURSES_SET_LESSON_NAMES';
export const COURSES_UPDATE_LESSON_ORDER = 'COURSES_UPDATE_LESSON_ORDER';
export const COURSES_UPDATE = 'COURSES_UPDATE';
export const COURSE_ERROR = 'COURSE_ERROR';
export const COURSE_RESET_ERROR = 'COURSE_RESET_ERROR';
export const COURSES_SET_COURSE_LIST = 'COURSES_SET_COURSE_LIST';
export const COURSES_UPDATE_TRANSACTION_STATUS =
  'COURSES_UPDATE_TRANSACTION_STATUS';
export const COURSES_RESET_TRANSACTION_STATUS =
  'COURSES_RESET_TRANSACTION_STATUS';
export const COURSES_SET_THIRD_PARTY_COURSES =
  'COURSES_SET_THIRD_PARTY_COURSES';
export const COURSE_SET_CLICKSAFETY_COURSE_LESSONS =
  'COURSE_SET_CLICKSAFETY_COURSE_LESSONS';
export const COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID =
  'COURSE_SET_CLICKSAFETY_LESSONS_SESSIONID';
export const COURSE_IS_LOADING = 'COURSE_IS_LOADING';
export const COURSES_SET_ASSIGNMENT_STATUS = 'COURSES_SET_ASSIGNMENT_STATUS';
export const COURSES_SET_PARTNERS_COURSES = 'COURSES_SET_PARTNERS_COURSES';
export const COURSES_SET_ASSIGNED_GROUPS = 'COURSES_SET_ASSIGNED_GROUPS';
export const COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS =
  'COURSES_SET_GROUP_ASSIGNMENT_DEFINITIONS';
export const COURSE_PLAY = 'COURSE_PLAY';
export const COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE =
  'COURSE_PLAY_SET_LESSON_ASSIGNMENT_COMPLETE';

//lessons
export const LESSON_SET_LESSONS = 'LESSON_SET_LESSONS';
export const LESSON_SET_EDITING = 'LESSON_SET_EDITING';
export const LESSON_BY_ID = 'LESSON_BY_ID';
export const LESSON_SET_ALL_LESSONS = 'LESSON_SET_ALL_LESSONS';
export const LESSON_SET_ALL_BW_LESSONS = 'LESSON_SET_ALL_BW_LESSONS';
export const LESSONS_SET_ASSESSMENT_QUESTIONS =
  'LESSONS_SET_ASSESSMENT_QUESTIONS';
export const LESSON_UPDATE_TRANSACTION_STATUS =
  'LESSON_UPDATE_TRANSACTION_STATUS';
export const LESSON_RESET_TRANSACTION_STATUS =
  'LESSON_RESET_TRANSACTION_STATUS';
export const LESSON_SET_EMPLOYEE_ASSIGNMENTS =
  'LESSON_SET_EMPLOYEE_ASSIGNMENTS';
export const LESSONS_SET_QUIZ_QUESTIONS = 'LESSONS_SET_QUIZ_QUESTIONS';
export const LESSONS_SET_COMMUNITY_LESSONS = 'LESSONS_SET_COMMUNITY_LESSONS';
export const LESSONS_IS_LOADING = 'LESSONS_IS_LOADING';
export const LESSONS_SET_ASSIGNMENT_STATUS = 'LESSONS_SET_ASSIGNMENT_STATUS';
export const LESSONS_SET_PARTNERS_LESSONS = 'LESSONS_SET_PARTNERS_LESSONS';
export const LESSONS_SET_ASSIGNED_GROUPS = 'LESSONS_SET_ASSIGNED_GROUPS';
export const LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS =
  'LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS';
export const LESSON_PLAY = 'LESSON_PLAY';
export const LESSON_PLAY_MARK_AS_COMPLETE = 'LESSON_PLAY_MARK_AS_COMPLETE';

//tags
export const TAGS_SET_TAGS = 'TAGS_SET_TAGS';
export const TAGS_LOADING = 'TAGS_LOADING';

//empployees
export const EMPLOYEE_UPDATE_PASSWORD = 'EMPLOYEE_UPDATE_PASSWORD';
export const RESET_UPDATE_PASSWORD = 'RESET_UPDATE_PASSWORD';
export const EMPLOYEE_SET_EMPLOYEES = 'EMPLOYEE_SET_EMPLOYEES';
export const EMPLOYEE_SET_EMPLOYEE_GROUPS = 'EMPLOYEE_SET_EMPLOYEE_GROUPS';
export const EMPLOYEE_SET_EMPLOYEES_WORKLOAD =
  'EMPLOYEE_SET_EMPLOYEES_WORKLOAD';
export const EMPLOYEE_UPDATE_TRANSACTION_STATUS =
  'EMPLOYEE_UPDATE_TRANSACTION_STATUS';
export const EMPLOYEE_RESET_TRANSACTION_STATUS =
  'EMPLOYEE_RESET_TRANSACTION_STATUS';
export const EMPLOYEE_SET_LEARNING_PROFILE = 'EMPLOYEE_SET_LEARNING_PROFILE';
export const EMPLOYEE_SET_ASSIGNMENTS_STATUS =
  'EMPLOYEE_SET_ASSIGNMENTS_STATUS';
export const EMPLOYEE_DATA_IS_LOADING = 'EMPLOYEE_DATA_IS_LOADING';
export const EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE =
  'EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE';
export const RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE =
  'RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE';

export const EMPLOYEE_SUPERVISORS = 'EMPLOYEE_SUPERVISORS';
export const EMPLOYEE_SET_ACTIVE_EMPLOYEES = 'EMPLOYEE_SET_ACTIVE_EMPLOYEES';

//learning plans
export const LEARNING_PLAN_NEW = 'LEARNING_PLAN_NEW';
export const LEARNING_PLAN_UPDATE = 'LEARNING_PLAN_UPDATE';
export const LEARNING_PLAN_SET_PLANS = 'LEARNING_PLAN_SET_PLANS';
export const LEARNING_PLAN_SAVE = 'LEARNING_PLAN_SAVE';
export const LEARNING_PLAN_UPDATE_TRANSACTION_STATUS =
  'LEARNING_PLAN_UPDATE_TRANSACTION_STATUS';
export const LEARNING_PLAN_RESET_TRANSACTION_STATUS =
  'LEARNING_PLAN_RESET_TRANSACTION_STATUS';
export const LEARNING_PLAN_SET_COURSES = 'LEARNING_PLAN_SET_COURSES';
export const LEARNING_PLAN_UPDATE_ASSIGNMENT =
  'LEARNING_PLAN_UPDATE_ASSIGNMENT';
export const LEARNING_PLAN_IS_LOADING = 'LEARNING_PLAN_IS_LOADING';
export const LEARNING_PLAN_SET_ASSIGNMENT_STATUS =
  'LEARNING_PLAN_SET_ASSIGNMENT_STATUS';
export const LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS =
  'LEARNING_PLAN_SET_PARTNERS_LEARNING_PLANS';
export const LEARNING_PLAN_SET_ASSIGNED_GROUPS =
  'LEARNING_PLAN_SET_ASSIGNED_GROUPS';
export const LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS =
  'LEARNING_PLAN_SET_GROUP_ASSIGNMENT_DEFINITIONS';
export const LEARNING_PLAN_PLAY = 'LEARNING_PLAN_PLAY';

//assessments
export const ASSESSMENT_SET_ASSESSMENT = 'ASSESSMENT_SET_ASSESSMENT';
export const ASSESSMENT_UPDATE_TRANSACTION_STATUS =
  'ASSESSMENT_UPDATE_TRANSACTION_STATUS';
export const ASSESSMENT_RESET_TRANSACTION_STATUS =
  'ASSESSMENT_RESET_TRANSACTION_STATUS';
export const ASSESSMENT_LIST_FAILED_QUIZ = 'ASSESSMENT_LIST_FAILED_QUIZ';
export const ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES =
  'ASSESSMENT_LIST_FAILED_ASSIGNMENT_QUIZZES';
export const ASSESSMENT_MARK_QUIZ_AS_FAILED = 'ASSESSMENT_MARK_QUIZ_AS_FAILED';
export const ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED =
  'ASSESSMENT_MARK_QUIZ_ASSIGNMENT_AS_FAILED';

//student
export const STUDENT_SET_IS_LOADING = 'STUDENT_SET_IS_LOADING';
export const STUDENT_SET_COURSE_COMPLETED_LESSONS =
  'STUDENT_SET_COURSE_COMPLETE_LESSONS';
export const STUDENT_MARK_LESSON_AS_COMPLETED =
  'STUDENT_MARK_LESSON_AS_COMPLETED';
export const STUDENT_SET_LEARNING_PLAY_LIST = 'STUDENT_SET_LEARNING_PLAY_LIST';
export const STUDENT_SET_ALL_LEARNING_PLAY_LIST =
  'STUDENT_SET_ALL_LEARNING_PLAY_LIST';
export const STUDENT_SET_LEARNING_PROFILE = 'STUDENT_SET_LEARNING_PROFILE';
export const STUDENT_UPDATE_TRANSACTION_STATUS =
  'STUDENT_UPDATE_TRANSACTION_STATUS';
export const STUDENT_RESET_TRANSACTION_STATUS =
  'STUDENT_RESET_TRANSACTION_STATUS';
export const STUDENT_WEEKLY_STATISTICS = 'STUDENT_WEEKLY_STATISTICS';
export const STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO =
  'STUDENT_SET_COMPLETED_LESSONS_BASIC_INFO';
export const STUDENT_SET_COMPLETED_COURSES_BASIC_INFO =
  'STUDENT_SET_COMPLETED_COURSES_BASIC_INFO';
export const STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO =
  'STUDENT_SET_COMPLETED_LEARNING_PLANS_BASIC_INFO';
export const STUDENT_RECENTLY_WORKED_ON_PLAY_LIST =
  'STUDENT_RECENTLY_WORKED_ON_PLAY_LIST';
export const STUDENT_SET_DASHBOARD_OVERVIEW_DATA =
  'STUDENT_SET_DASHBOARD_OVERVIEW_DATA';
export const STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA =
  'STUDENT_SET_DASHBOARD_TOP_EMPLOYEES_DATA';
export const STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA =
  'STUDENT_SET_DASHBOARD_OVERVIEW_DETAIL_DATA';
export const STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA =
  'STUDENT_SET_DASHBOARD_SUBSCRIBED_USERS_DETAIL_DATA';
export const STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA =
  'STUDENT_RESET_DASHBOARD_OVERVIEW_DETAIL_DATA';

//assignments
export const STUDENT_SET_ASSIGNMENT = 'STUDENT_SET_ASSIGNMENT';
export const STUDENT_SET_COMPLETED_COURSES = 'STUDENT_SET_COMPLETED_COURSES';
export const STUDENT_SET_COMPLETED_LEARNING_PLANS =
  'STUDENT_SET_COMPLETED_LEARNING_PLANS';
export const STUDENT_SET_TAKEN_TIMES_LESSONS =
  'STUDENT_SET_TAKEN_TIMES_LESSONS';
export const STUDENT_SET_TAKEN_TIMES_COURSES =
  'STUDENT_SET_TAKEN_TIMES_COURSES';
export const STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS =
  'STUDENT_SET_TAKEN_TIMES_LEARNING_PLANS';

// notifications
export const NOTIFICATIONS_SET_NOTIFICATIONS =
  'NOTIFICATIONS_SET_NOTIFICATIONS';
export const NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED =
  'NOTIFICATIONS_MARK_EXISTINGS_AS_VIEWED';
export const NOTIFICATIONS_DISMISS_NOTIFICATION =
  'NOTIFICATIONS_DISMISS_NOTIFICATION';

// content library
export const CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES =
  'CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES';

// subscriptions
export const SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS =
  'SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS';
export const SUBSCRIPTION_IS_LOADING = 'SUBSCRIPTION_IS_LOADING';

// analytics
export const ANALYTICS_SET_CONTENT_LIBRARY_DATA =
  'ANALYTICS_SET_CONTENT_LIBRARY_DATA';
export const ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS =
  'ANALYTICS_SET_OVERDUE_LEARNING_UNIT_ASSIGNMENTS';
export const ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS =
  'ANALYTICS_SET_PENDING_COMPLETIONS_ASSIGNMENTS';
export const ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS =
  'ANALYTICS_SET_COMPLETED_LEARNING_UNIT_ASSIGNMENTS';
export const ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA =
  'ANALYTICS_SET_CONTENT_LIBRARY_DETAIL_DATA';
export const ANALYTICS_SET_GROUP_ENGAGEMENT_DATA =
  'ANALYTICS_SET_GROUP_ENGAGEMENT_DATA';
export const ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA =
  'ANALYTICS_SET_GROUP_ENGAGEMENT_DETAIL_DATA';
export const ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA =
  'ANALYTICS_SET_EMPLOYEE_ENGAGEMENT_DATA';
export const ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_GROUP_CURRENT_ASSIGNMENTS_DATA';
export const ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_EMPLOYEE_COMPLETED_ASSIGNMENTS_DATA';
export const ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_EMPLOYEE_OVERDUE_ASSIGNMENTS_DATA';

export const ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_EMPLOYEE_CURRENT_ASSIGNMENTS_DATA';
export const ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_GROUP_COMPLETED_ASSIGNMENTS_DATA';
export const ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA =
  'ANALYTICS_SET_EMPLOYEE_SELF_ASSIGNMENTS_DATA';
export const ANALYTICS_SET_QUIZZES_DATA = 'ANALYTICS_SET_QUIZZES_DATA';
export const ANALYTICS_SET_QUIZ_DETAIL_DATA = 'ANALYTICS_SET_QUIZ_DETAIL_DATA';
export const ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS =
  'ANALYTICS_SET_QUIZ_PENDING_COMPLETION_ASSIGNMENTS';
export const ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS =
  'ANALYTICS_SET_QUIZ_COMPLETED_ASSIGNMENTS';
export const ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE =
  'ANALYTICS_SET_ASSESSMENTS_RESULTS_AVERAGE';
export const ANALYTICS_SET_QUIZ_RESULT = 'ANALYTICS_SET_QUIZ_RESULT';
export const ANALYTICS_SET_SKILLS_DATA = 'ANALYTICS_SET_SKILLS_DATA';

//halts
export const HALTS_SET_MONTH_HALTS_DATA = 'HALTS_SET_MONTH_HALTS_DATA';
export const HALTS_SET_TODAY_HALTS_DATA = 'HALTS_SET_TODAY_HALTS_DATA';
export const HALTS_DELETE_HALT = 'HALTS_DELETE_HALT';
export const HALTS_UPDATE_PICKED_CALENDAR_DATE =
  'HALTS_UPDATE_PICKED_CALENDAR_DATE';
export const HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA =
  'HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA';
export const HALTS_SET_YOUR_HALTS_DATA = 'HALTS_SET_YOUR_HALTS_DATA';
export const HALTS_SET_REFRESH_CALENDAR = 'HALTS_SET_REFRESH_CALENDAR';
export const HALTS_SET_HALT_CREATED_FLAG = 'HALTS_SET_HALT_CREATED_FLAG';

//achievements
export const COMPETENCIES_NOT_ASSIGNED = 'COMPETENCIES_NOT_ASSIGNED';
export const COMPETENCIES_ASSIGNED_BY_ID = 'COMPETENCIES_ASSIGNED_BY_ID';
export const ACHIEVEMENTS_SET_MY_COMPETENCES_DATA =
  'ACHIEVEMENTS_SET_MY_COMPETENCES_DATA';
export const ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA =
  'ACHIEVEMENTS_SET_MY_COMPETENCES_NEXT_PAGE_DATA';
export const ACHIEVEMENTS_SELECT_MY_COMPETENCE =
  'ACHIEVEMENTS_SELECT_MY_COMPETENCE';
export const ACHIEVEMENTS_GET_ALL = 'ACHIEVEMENTS_GET_ALL';
export const CLEAN_ALL_COMPETENCES = 'CLEAN_ALL_COMPETENCES';
export const ACHIEVEMENTS_SELECT_COMPETENCE = 'ACHIEVEMENTS_SELECT_COMPETENCE';
export const NOTIFICATION_PREFERENCES_SET = 'NOTIFICATION_PREFERENCES_SET';
export const NOTIFICATION_PREFERENCES_SAVED = 'NOTIFICATION_PREFERENCES_SAVED';
export const ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS =
  'ACHIEVEMENTS_SET_AVAILABLE_LEARNING_UNITS';
export const ACHIEVEMENTS_FORCE_GET_ALL = 'ACHIEVEMENTS_FORCE_GET_ALL';

export const SET_COMPETENCE_ATTACHMENTS = 'SET_COMPETENCE_ATTACHMENTS';
export const CLEAN_COMPETENCE_ATTACHMENTS = 'CLEAN_COMPETENCE_ATTACHMENTS';
export const SET_COMPETENCE_ATTACHMENT_REMOVED =
  'SET_COMPETENCE_ATTACHMENT_REMOVED';
export const ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL =
  'ACHIEVEMENTS_SET_EMPLOYEES_WITH_ASSIGNED_SKILL';
export const ACHIEVEMENTS_SET_IS_LOADING = 'ACHIEVEMENTS_SET_IS_LOADING';

export const ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE =
  'ACHIEVEMENTS_EMPLOYEES_BY_COMPETENCE';
export const ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT =
  'ACHIEVEMENTS_ADD_EMPLOYEE_ATTACHMENT';
export const ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE =
  'ACHIEVEMENTS_REMOVE_EMPLOYEE_COMPETENCE';
export const ACHIEVEMENTS_LOADING_EMPLOYEE_INFO =
  'ACHIEVEMENTS_LOADING_EMPLOYEE_INFO';

//achievements/analytics
export const ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA =
  'ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_DATA';
export const ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA =
  'ACHIEVEMENTS_ANALYTICS_SET_MY_COMPETENCES_NEXT_PAGE_DATA';
export const ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA =
  'ACHIEVEMENTS_ANALYTICS_CLEAR_MY_COMPETENCES_DATA';
export const ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA =
  'ACHIEVEMENTS_ANALYTICS_DELETE_MY_COMPETENCES_DATA';
export const ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT =
  'ACHIEVEMENTS_ADD_EMPLOYEE_ANALYTICS_ATTACHMENT';
export const ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE =
  'ACHIEVEMENTS_UPDATE_EMPLOYEE_ANALYTICS_EXPIRATION_DATE';
export const ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE =
  'ACHIEVEMENTS_ANALYTICS_ATTACHMENT_REMOVE';

//onsite trainings
export const ONSITE_TRAININGS_LOAD_PAGE = 'ONSITE_TRAININGS_LOAD_PAGE';
export const ONSITE_TRAININGS_CLEAR_ITEMS = 'ONSITE_TRAININGS_CLEAR_ITEMS';
export const ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING =
  'ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING';
export const ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS =
  'ONSITE_TRAININGS_SINGLE_ONSITE_TRAINING_CLASS';
export const ONSITE_TRAININGS_SET_CURRENT = 'ONSITE_TRAININGS_SET_CURRENT';
export const ONSITE_TRAINING_DELETE_SITE = 'ONSITE_TRAINING_DELETE_SITE';

export const ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS =
  'ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS';
export const ONSITE_TRAININGS_RESET_TRANSACTION_STATUS =
  'ONSITE_TRAININGS_UPDATE_TRANSACTION_STATUS';
export const ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS =
  'ONSITE_TRAININGS_SET_ASSIGNMENT_STATUS';
export const ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS =
  'ONSITE_TRAININGS_SET_GROUP_ASSIGNMENT_DEFINITIONS';
export const ONSITE_TRAININGS_SET_CLASSES = 'ONSITE_TRAININGS_SET_CLASSES';
export const ONSITE_TRAININGS_LOAD_ALL = 'ONSITE_TRAININGS_LOAD_ALL';
export const ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES =
  'ONSITE_TRAININGS_FORCE_REFRESH_SCHEDULED_CLASSES';
export const ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY =
  'ONSITE_TRAINING_LOAD_ALL_CLASS_ASSIGNMENTS_PER_DAY';

//Certifications
export const CERTIFICATIONS_SET_CERTIFICATIONS =
  'CERTIFICATIONS_SET_CERTIFICATIONS';
export const CERTIFICATIONS_REFETCH_CERTIFICATIONS =
  'CERTIFICATIONS_REFETCH_CERTIFICATIONS';
export const CERTIFICATIONS_SET_ISLOADING = 'CERTIFICATIONS_SET_ISLOADING';
export const CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS =
  'CERTIFICATIONS_SET_EMPLOYEECERTIFICATIONS';
export const CERTIFICATIONS_SET_CERTIFICATION_NAMES =
  'CERTIFICATIONS_SET_CERTIFICATION_NAMES';
export const CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION =
  'CERTIFICATIONS_GET_EMPLOYEES_BY_CERTIFICATION';
export const CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING =
  'CERTIFICATIONS_EMPLOYEES_BY_CERTIFICATION_IS_LOADING';
export const CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD =
  'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD';
export const CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING =
  'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_LOADING';
export const CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET =
  'CERTIFICATIONS_SET_EMPLOYEES_TO_AWARD_RESET';
export const CERTIFICATIONS_SET_MY_CERTIFICATIONS =
  'CERTIFICATIONS_SET_MY_CERTIFICATIONS';
export const CERTIFICATIONS_SET_TRANSACTION_ERROR =
  'CERTIFICATIONS_SET_TRANSACTION_ERROR';
export const CERTIFICATIONS_SET_EMPLOYEES_NAMES =
  'CERTIFICATIONS_SET_EMPLOYEES_NAMES';
export const CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS =
  'CERTIFICATIONS_FORCE_FETCH_EMPLOYEE_CERTIFICATIONS';

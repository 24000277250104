import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { pdf } from '@react-pdf/renderer';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { Document, Page } from 'react-pdf';

import ModalComponent from '../../../components/core/ModalPage/ModalComponent';
import CertificatePDFTemplate from '../Common/CertificationPDFTemplate';
import {
  Certification,
  DurationUnit,
  PdfCertification
} from '../../../entities/Certification';
import { EmployeeCertification } from '../../../entities/EmployeeCertification';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims } from '../../../core/constants';
import { useCalendarHelper } from '../../../hooks/useCalendarHelper';
import AutoSizer from 'react-virtualized-auto-sizer';

interface CertificatePdfPreviewModalProps {
  certification: Certification;
  employee: EmployeeCertification;
  onCloseModal: () => void;
}

const CertificatePdfPreviewModal = ({
  certification,
  employee,
  onCloseModal
}: CertificatePdfPreviewModalProps) => {
  const { t } = useTranslation(['certifications']);
  const theme = useTheme();
  const user = useCurrentUser();
  const {
    constants: { monthNames }
  } = useCalendarHelper();
  const [blobUrl, setBlobUrl] = useState<string>('');
  const { name, durationNeverExpires, duration, durationUnit } = certification;

  const { employeeFullName } = employee;
  const momentCreatedAt = moment();
  const timeUnit = durationUnit === DurationUnit.Month ? 'month' : 'year';
  const momentExpirationDate = !durationNeverExpires
    ? moment().add(duration, timeUnit)
    : null;
  const awardeeFirstName = user?.profile[UserClaims.FirstName] as string;
  const awardeeLastName = user?.profile[UserClaims.LastName] as string;

  const createPdfData = (): PdfCertification => {
    return {
      name,
      companyLogoUrl: user?.profile[UserClaims.CompanyLogoUrl] as string,
      employeeName: employeeFullName,
      awarderName: `${awardeeFirstName} ${awardeeLastName}`,
      formattedCreatedAt: `${
        monthNames[momentCreatedAt.month()]
      } ${momentCreatedAt.date()}, ${momentCreatedAt.year()}`,
      formattedExpirationDate: momentExpirationDate
        ? `${
            monthNames[momentExpirationDate.month()]
          } ${momentExpirationDate.date()}, ${momentExpirationDate.year()}`
        : ''
    };
  };

  const generatePdfFile = async () => {
    const pdfData = createPdfData();
    const document = <CertificatePDFTemplate pdfData={pdfData} />;
    const blob = await pdf(document).toBlob();
    const blobUrl = window.URL.createObjectURL(blob);
    setBlobUrl(blobUrl);
  };

  useEffect(() => {
    generatePdfFile();

    return () => {
      if (blobUrl) {
        window.URL.revokeObjectURL(blobUrl);
      }
    };
  }, []);

  return (
    <ModalComponent
      showModal={true}
      title={t('certificationPreview')}
      savingInProgress={false}
      maxWidth="md"
      showSaveButton={true}
      showCancelButton={false}
      closeWhenClickBackdrop={false}
      closeWhenPressingEscape={false}
      showDeleteButton={false}
      onClose={onCloseModal}
      onSave={onCloseModal}
      saveButtonText="OK"
      showCloseIcon={false}
      backdropColor="transparent"
    >
      <Stack gap={1}>
        <Typography sx={theme.typography.subtitle2}>
          {t('certificationPreviewDescription')}
        </Typography>
        <Box
          className="verticalScroll"
          sx={{
            width: '100%',
            height: '400px',
            justifyContent: 'center',
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <AutoSizer disableHeight>
            {({ width }) => (
              <Document file={blobUrl} loading={<Loading />} noData="">
                <Page pageNumber={1} width={width} />
              </Document>
            )}
          </AutoSizer>
        </Box>
      </Stack>
    </ModalComponent>
  );
};

const Loading = () => (
  <Box
    sx={{
      width: '840px',
      height: '400px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CircularProgress />
  </Box>
);

export default CertificatePdfPreviewModal;

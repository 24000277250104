import Box from '@mui/material/Box';

import {
  AssignedCourse,
  AssignedLearningPlan,
  AssignedLesson,
  AssignedOnsiteTrainingClass,
  AssignmentTypes
} from '../../entities/LearningPlaylist';
import CompleteLearningPlanCard from '../../components/LearningPlanCard/CompleteLearningPlanCard';
import CompleteCourseCard from '../../components/CourseCard/CompleteCourseCard';
import CompleteLessonCard from '../../components/LessonCard/CompleteLessonCard';
import AssignmentOnsiteTrainingCard from '../../components/OnsiteTrainingAssignmentCard/OnsiteTrainingAssignmentCard';

export interface AssignmentCardCompleteListProps {
  assignedLearningUnits: Array<AssignmentTypes>;
}

const AssignmentCardCompleteList: React.FC<AssignmentCardCompleteListProps> = ({
  assignedLearningUnits
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '30px'
      }}
    >
      {assignedLearningUnits.map(learningUnit => {
        const key =
          (learningUnit as AssignedLesson).lesson?.id ||
          (learningUnit as AssignedCourse).course?.id ||
          (learningUnit as AssignedLearningPlan).learningPlan?.id ||
          (learningUnit as AssignedOnsiteTrainingClass).onsiteTrainingClass?.id;

        if ((learningUnit as AssignedLesson).lesson) {
          return (
            <CompleteLessonCard
              key={key}
              assignedLesson={learningUnit as AssignedLesson}
            />
          );
        }

        if ((learningUnit as AssignedCourse).course) {
          return (
            <CompleteCourseCard
              key={key}
              assignedCourse={learningUnit as AssignedCourse}
            />
          );
        }

        if (
          (learningUnit as AssignedOnsiteTrainingClass).onsiteTrainingClass
            ?.onsiteTraining
        ) {
          return (
            <AssignmentOnsiteTrainingCard
              assignedClass={learningUnit as AssignedOnsiteTrainingClass}
              key={key}
            />
          );
        }

        return (
          <CompleteLearningPlanCard
            key={key}
            assignedLearningPlan={learningUnit as AssignedLearningPlan}
          />
        );
      })}
    </Box>
  );
};

export default AssignmentCardCompleteList;

import { useContext, useState } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';
import { SortingOptions } from '../../../core/constants';
import { Sorting } from '../../../hooks/useContentLibraryNavigation';

const useLearningUnitSorting = () => {
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const [activeSort, setActiveSort] = useState<string>(
    userNavigation?.sorting ?? SortingOptions[3].value
  );

  const handleChangeSorting = (values: string[]) => {
    const orderCriteria = values[0] as Sorting;
    userNavigationApi?.changeSorting(orderCriteria);
    setActiveSort(orderCriteria);
  };
  return [activeSort, handleChangeSorting] as const;
};

export default useLearningUnitSorting;

import { useSelector } from 'react-redux';
import { LessonUnitTypes } from '../../core/constants';
import { Lesson, CommunityLesson, LessonUnit } from '../../entities/Lesson';
import useCurrentUser from '../../hooks/useCurrentUser';
import LessonRowWrapper from '../../pages/contentLibrary/common/LessonRowWrapper';
import { ApplicationState } from '../../store';
import { restrictedLessonShouldShow } from '../../utils/lessonUtils';

import NewLessonCard from '../LessonCard/NewLessonCard';
import {
  CommunityLessonCardActions,
  LessonCardActions
} from '../LessonCardActions';
import Grid from '@mui/material/Grid';
import { ListChildComponentProps } from 'react-window';

interface LessonVirtualRowProps
  extends ListChildComponentProps<LessonUnit[][]> {
  onLessonStart: (lesson: Lesson, isPreviewMode?: boolean) => void;
  onOpenAssignModal: (lessonId: string) => void;
}

const LessonVirtualRow: React.FC<LessonVirtualRowProps> = ({
  data,
  index,
  style,
  onLessonStart,
  onOpenAssignModal
}) => {
  const user = useCurrentUser();

  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="lessContainer">
        {data[index]
          .filter((x: { lesson: Lesson }) =>
            restrictedLessonShouldShow(x.lesson, userRoles)
          )
          .map((lessonUnit: { lesson: Lesson; lessonType: string }) => (
            <LessonRowWrapper key={lessonUnit.lesson.id}>
              {lessonUnit.lessonType === LessonUnitTypes.communityLesson ? (
                <NewLessonCard
                  height="300px"
                  lesson={lessonUnit.lesson}
                  showLessonType={false}
                  showEdit={!lessonUnit.lesson.isVideoEncoding}
                  lessonCardType={'Community'}
                  takenTimes={
                    takenTimesLessons
                      ? takenTimesLessons[lessonUnit.lesson.id]
                      : undefined
                  }
                  onStartPreviewMode={() =>
                    onLessonStart(lessonUnit.lesson, true)
                  }
                >
                  <CommunityLessonCardActions
                    communityLesson={lessonUnit.lesson as CommunityLesson}
                    onPlay={() => onLessonStart(lessonUnit.lesson)}
                    onAssign={() => onOpenAssignModal(lessonUnit.lesson.id)}
                  />
                </NewLessonCard>
              ) : (
                <NewLessonCard
                  height="300px"
                  lesson={lessonUnit.lesson}
                  lessonCardType={'BuildWitt'}
                  takenTimes={
                    takenTimesLessons
                      ? takenTimesLessons[lessonUnit.lesson.id]
                      : undefined
                  }
                  showEdit={!lessonUnit.lesson.isVideoEncoding}
                  onStartPreviewMode={() =>
                    onLessonStart(lessonUnit.lesson, true)
                  }
                >
                  <LessonCardActions
                    lesson={lessonUnit.lesson as Lesson}
                    onStart={() => onLessonStart(lessonUnit.lesson)}
                    onAssign={() => onOpenAssignModal(lessonUnit.lesson.id)}
                  />
                </NewLessonCard>
              )}
            </LessonRowWrapper>
          ))}
      </Grid>
    </div>
  );
};

export default LessonVirtualRow;

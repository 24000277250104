import { Box } from '@mui/material';
import { CSSProperties } from 'react';

interface NoContentNoticeProps {
  message: string;
}

const NoContentNotice: React.FC<NoContentNoticeProps> = ({ message }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="50vh"
  >
    <span style={messageStyle}>{message}</span>
  </Box>
);

const messageStyle: CSSProperties = {
  fontSize: '17px'
};

export default NoContentNotice;

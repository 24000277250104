import { styled } from '@mui/system';
import { Theme } from '@mui/material';
const escapeProps = [
  'thumbnail',
  'height',
  'isComplete',
  'theme',
  'isOverdueLP',
  'completeLearningPlan',
  'newLearningPlan',
  'failedOverdueLearningPlan',
  'width'
];

export const LearningPlanBaseWrapper = styled('div')<{
  newLearningPlan: string;
  thumbnail: string;
  width?: string;
}>(({ theme, newLearningPlan, thumbnail, width }) => {
  return {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderLeftWidth: '1px',
    borderTopWidth: '1px',
    borderBottomWidth: '1px',
    borderRightWidth: '1px',
    borderTopRightRadius: '14px',
    borderBottomRightRadius: '14px',
    borderTopLeftRadius: '14px',
    borderBottomLeftRadius: '14px',
    borderStyle: 'solid',
    minHeight: '325px',
    display: 'flex',
    flexDirection: 'row' as const,
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('${thumbnail}')`,
    backgroundPosition: '50% 50%',
    position: 'relative',
    marginBottom: '2rem',
    width: width ? width : '75%',
    borderColor: newLearningPlan
      ? newLearningPlan
      : `${theme.palette.grey['100']}`
  };
});

export const LearningPlanWrapper = styled(LearningPlanBaseWrapper, {
  shouldForwardProp: prop => !escapeProps.includes(prop as string)
})<{
  thumbnail: string;
  isComplete: boolean | null;
  isOverdueLP: boolean;
  theme: Theme;
  completeLearningPlan: string;
  newLearningPlan: string;
  failedOverdueLearningPlan: string;
  width?: string;
}>(props => ({
  borderColor: props.isComplete
    ? props.completeLearningPlan
    : props.isOverdueLP
      ? props.failedOverdueLearningPlan
      : props.newLearningPlan
        ? props.newLearningPlan
        : `${props.theme.palette.grey['100']}`
}));

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

import { AnalyticsEmployeeCurrentAssignment } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getAssignedOnColumn,
  getGenericValueActionColumn,
  getFormatedValueColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { EnrollmentTypes } from '../../../core/constants';
import { lowerCaseFirstLetter } from '../../../utils/stringUtils';

interface EmployeeCurrentAssignmentListProps {
  currentAssignmentsData?: AnalyticsEmployeeCurrentAssignment[] | null;
  onEditDueDate: (
    assignmentId: string,
    learningUnitType: string,
    dueDate: Date
  ) => void;
}

const GetNodeValue = (params: GridRenderCellParams<any>) => {
  const { t } = useTranslation(['assignments']);

  return (
    <span>
      {params.row.assignmentType === EnrollmentTypes.DueDate &&
        absUtcDateToLocalMoment(params.row.dueDate).format('MM.DD.YYYY')}
      {params.row.assignmentType !== EnrollmentTypes.DueDate &&
        t(lowerCaseFirstLetter(params.row.assignmentType))}
    </span>
  );
};

const GetCompletionStatus = (params: GridRenderCellParams<any>) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const { isOverdue } = params.row;
  const status = isOverdue ? 'overdue' : 'pending';

  return (
    <span
      style={{
        color: isOverdue ? theme.palette.error.main : theme.palette.primary.main
      }}
    >
      {t(status)}
    </span>
  );
};

const EmployeeCurrentAssignmentList = ({
  currentAssignmentsData,
  onEditDueDate
}: EmployeeCurrentAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const handleEditDueDate = (params: GridRenderCellParams<any>) => {
    const { assignmentId, learningUnitType, dueDate } = params.row;
    onEditDueDate(assignmentId, learningUnitType, dueDate);
  };

  const getTooltipText = (params: GridRenderCellParams<any>) => {
    const { courseAssignmentId, learningPlanAssignmentId } = params.row;
    let levelName = '';
    if (
      params.row.editableDueDate ||
      params.row.assignmentType !== EnrollmentTypes.DueDate
    ) {
      return '';
    }

    switch (params.row.learningUnitType) {
      case 'Lesson':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        else if (courseAssignmentId) levelName = 'course';
        break;
      case 'Course':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        break;
      default:
        break;
    }

    return levelName.length
      ? t('assignedAt', {
          ns: 'analytics',
          level: t(levelName, { ns: 'assignments' })
        })
      : levelName;
  };

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      400,
      learningUnitNames
    ),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getFormatedValueColumn(
      'isOverdue',
      t('completionStatus', { ns: 'analytics' }),
      150,
      GetCompletionStatus,
      false
    ),
    getGenericValueActionColumn(
      'editableDueDate',
      t('dueDate', { ns: 'analytics' }),
      t('edit', { ns: 'common' }),
      200,
      handleEditDueDate,
      GetNodeValue,
      getTooltipText
    )
  ])();

  return <ModalDataGrid rows={currentAssignmentsData} columns={columns} />;
};

export default EmployeeCurrentAssignmentList;

import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import NewLessonCard from '../../../components/LessonCard/NewLessonCard';
import { Lesson } from '../../../entities/Lesson';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { ApplicationState } from '../../../store';
import { restrictedLessonShouldShow } from '../../../utils/lessonUtils';
import { SelfAssignButton } from '../../../components/SelfAssignButton/SelfAssignButton';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../../core/constants';
import { CSSProperties } from 'react';
import { SxProps } from '@mui/material';
import LessonRowWrapper from '../common/LessonRowWrapper';
import { ListChildComponentProps } from 'react-window';

interface LessonVirtualRowProps extends ListChildComponentProps<Lesson[][]> {
  onLessonPreview: (lesson: Lesson, isPreviewMode?: boolean) => void;
  onOpenAssignModal: (lessonId: string) => void;
}

const LessonVirtualRow: React.FC<LessonVirtualRowProps> = ({
  data,
  index,
  style,
  onLessonPreview,
  onOpenAssignModal
}) => {
  const user = useCurrentUser();

  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  const takenTimesLessons = useSelector(
    (state: ApplicationState) => state.student?.takenTimesLessons
  );

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="lessContainer">
        {data[index]
          .filter(x => restrictedLessonShouldShow(x, userRoles))
          .map((lesson: Lesson) => {
            return (
              <LessonRowWrapper key={lesson.id}>
                <NewLessonCard
                  height="300px"
                  lesson={lesson}
                  lessonCardType={'Company'}
                  takenTimes={
                    takenTimesLessons ? takenTimesLessons[lesson.id] : undefined
                  }
                  onStartPreviewMode={() => onLessonPreview(lesson, true)}
                >
                  <NewActionsArea
                    id={lesson.id}
                    title={lesson.title}
                    companyId={lesson.companyId}
                    onPreview={() => onLessonPreview(lesson)}
                    onAssign={onOpenAssignModal}
                  />
                </NewLessonCard>
              </LessonRowWrapper>
            );
          })}
      </Grid>
    </div>
  );
};

const NewActionsArea: React.FC<actionsAreaProps> = ({
  id,
  title,
  companyId,
  onPreview,
  onAssign
}) => {
  const { t } = useTranslation(['common']);

  return (
    <Grid container sx={divContainerStyle} id="newActionsAreaContainer">
      <Grid className="assignButtonContainer">
        <ProtectedComponent action={appPermissions.ASSIGN_LESSON}>
          <BasicButton
            style={{ ...buttonStyleChldren }}
            width="105px"
            height="35px"
            onClick={() => onAssign!(id)}
            color="primary"
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
      </Grid>
      <Grid>
        <SelfAssignButton
          type="Lesson"
          id={id}
          companyId={companyId}
          title={title}
          style={{ ...buttonStyleChldren }}
          width="105px"
          height="35px"
          color="grayButton"
        />
      </Grid>
    </Grid>
  );
};

interface actionsAreaProps {
  id: string;
  title: string;
  companyId: string;
  onEdit?: (id: string) => void;
  onPreview: (lessonId: string) => void;
  onAssign: (id: string) => void;
}

const divContainerStyle: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  gap: '8px',
  justifyContent: 'flex-end',
  paddingRight: '18px'
};

const buttonStyleChldren: CSSProperties = {
  minWidth: '0',
  display: 'block'
};

export default LessonVirtualRow;

import React, { CSSProperties } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import MovieLineIcon from '../../assets/movie-line.svg';
import FlashCardPdfIcon from '../../assets/flash-card-pdf.svg';
import QuizCardIcon from '../../assets/quiz.svg';
import { Lesson } from '../../entities/Lesson';
import { useTheme } from '@mui/material';
import LessonCardLoader from '../LessonCardLoader/LessonCardLoader';
import BlackCheck from '../../assets/blackcheck.svg';
import BlackCross from '../../assets/blackCross.svg';
import { styled } from '@mui/system';

import LessonBackground from '../../assets/LessonBackground.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { matchYoutubeUrl } from '../../utils/matchYoutubeUrl';

export interface ILessonCardProps {
  lesson: Lesson;
  isComplete?: boolean;
  children?: React.ReactNode;
  dueDate?: Date;
  listFailedQuiz?: string[];
  isWorkloadAssignment?: boolean;
}

const titleStyle: CSSProperties = {
  color: '#FBFBFB',
  fontFamily: 'Titillium Web',
  fontStyle: 'normal',
  fontSize: '20px',
  whiteSpace: 'nowrap',
  marginBottom: '0rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const CompleteCardHeaderComponent = styled(Card.Header)({
  position: 'absolute',
  right: '-1px',
  background: '#2ED47A',
  color: '#0E0D0D',
  width: 'auto',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px'
});

const OverdueCardHeaderComponent = styled(Card.Header)({
  position: 'absolute',
  right: '-1px',
  background: '#FF2A20',
  color: '#0E0D0D',
  width: 'auto',
  textAlign: 'center',
  borderRadius: '0px 14px !important',
  top: '-1px'
});

const CompletedLessonHeader = () => {
  const { t } = useTranslation(['assignments']);

  return (
    <CompleteCardHeaderComponent>
      <img
        src={BlackCheck}
        style={{ width: '10px', margin: '-3px 4px 0px' }}
        alt="Black icon check"
      />
      {t('completed', { ns: 'assignments' })}
    </CompleteCardHeaderComponent>
  );
};

const PassQuizLessonHeader = () => {
  const { t } = useTranslation(['assignments']);
  return (
    <CompleteCardHeaderComponent>
      <img
        src={BlackCheck}
        style={{ width: '10px', margin: '-3px 4px 0px' }}
        alt="Black icon check"
      />
      {t('quizPass', { ns: 'assignments' })}
    </CompleteCardHeaderComponent>
  );
};

const FailQuizLessonHeader = () => {
  const { t } = useTranslation(['assignments']);
  return (
    <OverdueCardHeaderComponent>
      <img
        src={BlackCross}
        style={{ width: '10px', margin: '-3px 4px 0px' }}
        alt="Black icon cross"
      />
      {t('quizFail', { ns: 'assignments' })}
    </OverdueCardHeaderComponent>
  );
};

const OverdueLessonHeader = () => {
  const { t } = useTranslation(['assignments']);

  return (
    <OverdueCardHeaderComponent>
      <span style={overdueSpanStyle}>
        {t('overdue', { ns: 'assignments' })}
      </span>
    </OverdueCardHeaderComponent>
  );
};

const LessonCard: React.FC<ILessonCardProps> = ({
  lesson,
  isComplete = false,
  children,
  dueDate,
  listFailedQuiz,
  isWorkloadAssignment = false
}: ILessonCardProps) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isFailed = listFailedQuiz?.includes(lesson.assessmentId!);
  const imgStyle: CSSProperties = {
    backgroundImage: lesson.thumbnailUrl
      ? `url('${lesson.thumbnailUrl}')`
      : `url('${LessonBackground}')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    minHeight: '148px',
    width: '100%',
    borderRadius: '5px',
    backgroundSize: 'cover'
  };

  const cardStyle = {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '14px',
    borderColor:
      lesson.lessonType === 'Quiz' && isFailed
        ? '#FF2A20'
        : isComplete
          ? `${theme.palette.success.light}`
          : `${theme.palette.grey['100']}`,
    borderStyle: 'solid',
    borderWidth: '1px',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: `${theme.palette.grey['400']} !important`
  };

  const lessonTypeStyle: CSSProperties = {
    color: `${theme.palette.grey['500']}`,
    display: 'inline-block',
    fontStyle: 'normal',
    fontSize: '12px'
  };

  const loadingTextStyle: CSSProperties = {
    color: `${theme.palette.grey['500']}`,
    paddingBottom: '5px'
  };

  let lessonTypeImg = '';

  switch (lesson.lessonType.toLowerCase()) {
    case 'video':
      lessonTypeImg = MovieLineIcon;
      break;
    case 'pdf':
    case 'image':
      lessonTypeImg = FlashCardPdfIcon;
      break;
    case 'quiz':
      lessonTypeImg = QuizCardIcon;
      break;
    default:
      break;
  }

  const videoLessonTypeStyle: CSSProperties = {
    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    width: '16px',
    height: '16px',
    marginTop: '8px'
  };

  const today = moment();
  const pastDueDate = moment(dueDate);

  const completeHeader = lesson.lessonType !== 'Quiz' && isComplete && (
    <CompletedLessonHeader />
  );
  const passedQuizHeader = lesson.lessonType === 'Quiz' && isComplete && (
    <PassQuizLessonHeader />
  );
  const overdueHeader = !isComplete &&
    !isFailed &&
    pastDueDate.isBefore(today, isWorkloadAssignment ? 'minute' : 'day') && (
      <OverdueLessonHeader />
    );

  const failedQuizHeader = lesson.lessonType === 'Quiz' && isFailed && (
    <FailQuizLessonHeader />
  );

  return (
    <Card className="p-1" style={cardStyle}>
      {completeHeader}
      {passedQuizHeader}
      {overdueHeader}
      {failedQuizHeader}
      <Card.Body>
        <div className="w-100 mb-3" style={imgStyle} />
        <Row>
          <Col>
            <div style={titleStyle}>{lesson.title}</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <i
              className="fas fa-sm fa-fw mr-1 text-gray-400"
              style={{
                ...videoLessonTypeStyle,
                backgroundImage: `url(${lessonTypeImg})`
              }}
            />
            <span style={lessonTypeStyle} className="mt-1 align-top">
              {lesson.lessonType === 'PDF' ? 'File' : lesson.lessonType}
            </span>
          </Col>
        </Row>
        <Row>
          <Col className="text-right">
            {lesson.isVideoEncoding &&
            !matchYoutubeUrl(lesson.externalVideoUrl) ? (
              <>
                <Row>
                  <span style={loadingTextStyle}>
                    {t('videoIsEncoding', { ns: 'common' })}
                  </span>
                </Row>
                <Row>
                  <LessonCardLoader variant="linear" />
                </Row>
              </>
            ) : (
              <>
                {lesson.isEncodingFailed && (
                  <span style={loadingTextStyle}>
                    {t('videoEncodingFailed', { ns: 'common' })}.
                  </span>
                )}
                {children}
              </>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const overdueSpanStyle: CSSProperties = {
  fontSize: '17px'
};

export default LessonCard;

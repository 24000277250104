import React from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '../../../core/FormLabel/FormLabel';
import TextBox from '../../../core/TextBox/TextBox';
import { LessonCommonInputProps } from './LessonDurationInput';

const LessonCodeInput: React.FC<LessonCommonInputProps<string>> = ({
  value,
  onChange,
  isDisabled
}) => {
  const { t } = useTranslation(['common', 'lessons']);
  return (
    <>
      <FormLabel>{t('lessonCode', { ns: 'lessons' })}</FormLabel>
      <TextBox
        id="lessonCode"
        name="lessonCode"
        value={value}
        onChangeValue={onChange}
        placeholderText={t('lessonCode', { ns: 'common' })}
        width="100%"
        height="35px"
        disabled={isDisabled}
        variant="dark"
        maxLength={30}
        dataTestId="lessonCodeInput"
        dataTrackId="lessonCodeInput"
      />
    </>
  );
};

export default LessonCodeInput;

import { UserClaims } from '../core/constants';
import useCurrentUser from './useCurrentUser';
import { OnsiteTrainingClass } from '../entities/OnsiteTraining';
import { useNavigate } from 'react-router';

export const useOnsiteTrainingClassStart = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const goToOnsiteTrainingCheckIn = (
    onsiteTrainingClass: OnsiteTrainingClass
  ) => {
    if (onsiteTrainingClass.instructorId === employeeId) {
      navigate(`/onsite-training-check-in/${onsiteTrainingClass.id}`, {
        state: {
          onsiteTraining: onsiteTrainingClass.onsiteTraining,
          onsiteTrainingClass: onsiteTrainingClass
        }
      });
    } else {
      navigate(`/onsite-training-class/${onsiteTrainingClass.id}`);
    }
  };

  return { start: goToOnsiteTrainingCheckIn };
};

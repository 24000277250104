import { Box, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import BasicButton from '../../../components/core/BasicButton/BasicButton';

interface DeleteCertificationConfirmationModalProps {
  show: boolean;
  deletingInProgress: boolean;
  title: string;
  content: string;
  onCancel: () => void;
  onDelete: () => void;
}

const DeleteCertificationConfirmationModal = ({
  show,
  deletingInProgress,
  title,
  content,
  onCancel,
  onDelete
}: DeleteCertificationConfirmationModalProps) => {
  const { t } = useTranslation(['certifications', 'common']);

  return (
    <ConfirmationModal
      title={title}
      show={show}
      showSave={false}
      showCancel={false}
      maxWidth="sm"
      onCancel={onCancel}
      showCloseIcon={false}
      extraOptions={[
        <Stack
          direction="row"
          spacing={1}
          key="actionButtons"
          sx={{ width: '100%', justifyContent: 'center' }}
        >
          <BasicButton
            style={{
              minWidth: '100px',
              height: '42px'
            }}
            onClick={onDelete}
            color="deleteFilled"
            data-testid="confirmDeleteCertificationButton"
            loading={deletingInProgress}
          >
            {t('deleteCertification', { ns: 'certifications' })}
          </BasicButton>
          <BasicButton
            style={{
              minWidth: '80px',
              height: '42px'
            }}
            onClick={onCancel}
            color="primaryOutlined"
            disable={deletingInProgress}
          >
            {t('cancel', { ns: 'common' })}
          </BasicButton>
        </Stack>
      ]}
    >
      <Box sx={{ paddingTop: '1rem' }}>{content}</Box>
    </ConfirmationModal>
  );
};

export default DeleteCertificationConfirmationModal;

import { CSSProperties, useEffect, useRef, useState } from 'react';
import {
  ThemeProvider,
  styled,
  Tab,
  Grid as MuiGrid,
  Typography,
  Box,
  useTheme,
  Stack
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';

import { defaultTheme } from '@buildwitt/component-library';
import PageContainer from '../../components/core/PageContainer/PageContainer';
import { useTranslation } from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { actionCreators as onsiteTrainingActions } from '../../actions/onsiteTrainings';
import { actionCreators as employeeActions } from '../../actions/employees';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';

import {
  ContentOptions,
  DefaultPageSize,
  DefaultSearchDebounceTime,
  SortingOptions,
  UserClaims,
  UserRoles
} from '../../core/constants';
import { ApplicationState } from '../../store';
import OnsiteTrainingsCardList from './OnsiteTrainingsCardList';
import SortContentSelect from '../contentLibrary/common/SortContentSelect';
import {
  ContentStatus,
  Sorting
} from '../../hooks/useContentLibraryNavigation';
import { useDebounce } from '../../hooks/useDebounce';
import SearchInput from '../contentLibrary/common/SearchInput';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import ContentStatusFilter from '../contentLibrary/common/ContentStatusFilter';
import { useBwBreakpoints } from '../../hooks/useBwBreakpoints';
import LoadingContentIndicator from '../contentLibrary/common/LoadingContentIndicator';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import ScheduledOnsiteTrainingTab from './scheduledOnsiteTrainings/ScheduledOnsiteTrainingTab';
import useOnsiteTrainingList from '../../hooks/useOnsiteTrainingList';
import OnsiteTrainingModal from './modal/OnsiteTrainingModal';
import ApproveEmployeeOnsiteTable from './ApproveEmployeeOnsiteTable';
import SignatureOnsiteTrainingModal from './modal/SignatureOnsiteTrainingModal';
import { useAppDispatch } from '../../store/hooks';

enum Tabs {
  ScheduledOnsiteTrainings = 'scheduledOnsiteTrainings',
  OnsiteTrainingLibrary = 'onsiteTrainingLibrary'
}

export const selectStyleResponsive: CSSProperties = {
  height: '45px',
  width: '90px'
};

export const selectStyle: CSSProperties = {
  height: '45px'
};

const useStyles = makeStyles(() => ({
  tabList: {
    display: 'flex',
    '& .MuiTab-root': {
      textTransform: 'none',
      fontSize: '16px',
      minWidth: 'auto',
      minHeight: 50
    },
    '& .MuiTab-root:first-child': {
      paddingLeft: 0
    }
  }
}));

const defaultNs = 'onsiteTraining';

const useSorting = () => {
  const [activeSort, setActiveSort] = useState<string>(SortingOptions[3].value);

  const handleChangeSorting = (values: string[]) => {
    const orderCriteria = values[0] as Sorting;
    setActiveSort(orderCriteria);
  };

  return [activeSort, handleChangeSorting] as const;
};

const useContentStatusFilter = () => {
  const [filterLearningUnitStatus, setFilterLearningUnitStatus] =
    useState<ContentStatus>(ContentOptions[0].value as ContentStatus);

  const handleChangeLearningUnitStatus = (values: string[]) => {
    const status = values[0] as ContentStatus;
    setFilterLearningUnitStatus(status);
  };

  return [filterLearningUnitStatus, handleChangeLearningUnitStatus] as const;
};

const IndexOnsiteTraining = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [is640] = useBwBreakpoints();
  const dispatch = useAppDispatch();
  const [userHasRole] = useUserRole();
  const { t } = useTranslation(['onsiteTraining']);
  const [activeSort, handleChangeSorting] = useSorting();
  const [filterContentStatus, handleChangeContentStatus] =
    useContentStatusFilter();
  const [isDoingInitialLoad, setIsDoingInitialLoad] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const theme = useTheme();
  const page = useRef<number>(1);
  const {
    api: { handleSearch, handleSort },
    state: { onsiteTrainingClasses, searchKey, sortKey },
    ref: { scheduledListPage }
  } = useOnsiteTrainingList();

  useEffect(() => {
    return () => {
      dispatch(employeeActions.setActiveEmployees(null));
    };
  }, []);

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  const [currentTab, setCurrentTab] = useState(
    Tabs.ScheduledOnsiteTrainings as string
  );
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const onsiteTrainings = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainings
  );
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const loadFirstPage = async () => {
    setIsDoingInitialLoad(true);

    page.current = 1;
    await dispatch<Promise<void>>(onsiteTrainingActions.resetOnsiteTrainings());
    await dispatch<Promise<void>>(
      onsiteTrainingActions.loadOnsiteTrainings({
        page: 1,
        pageSize: DefaultPageSize,
        search: searchTerm,
        sort: activeSort,
        filterContentStatus: filterContentStatus
      })
    );

    setIsDoingInitialLoad(false);
  };

  useEffect(() => {
    loadFirstPage();
  }, [companyId, searchTerm, activeSort, filterContentStatus]);

  const classes = useStyles();
  const noRecordsFound =
    (!!searchTerm || filterContentStatus !== 'allContent') &&
    (onsiteTrainings?.totalItems === 0 ?? 0);
  const isEmptyState =
    !isDoingInitialLoad &&
    (onsiteTrainings?.totalItems === 0 ?? 0) &&
    !noRecordsFound;

  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);

  const handleCloseModal = () => {
    setShowCompleteModal(false);
  };

  const handleCloseSignatureModal = () => {
    setShowSignatureModal(false);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <PageContainer
        style={{
          height: '100%'
        }}
      >
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', marginTop: '1rem' }}
        >
          <Typography sx={fontTab}>
            {t('onsiteTraining', { ns: defaultNs })}
          </Typography>
          {userHasRole(UserRoles.CompanyAdmin) && (
            <BasicButton
              onClick={() => navigate('/onsite-training/create')}
              color="primary"
            >
              {t('createOnsiteTraining', { ns: defaultNs })}
            </BasicButton>
          )}
        </Stack>
        <TabContext value={currentTab}>
          <TabList
            allowScrollButtonsMobile
            scrollButtons="auto"
            onChange={handleChangeTab}
            className={classes.tabList}
          >
            <Tab
              label={t(Tabs.ScheduledOnsiteTrainings, { ns: defaultNs })}
              value={Tabs.ScheduledOnsiteTrainings}
            />
            <Tab
              label={t(Tabs.OnsiteTrainingLibrary, { ns: defaultNs })}
              value={Tabs.OnsiteTrainingLibrary}
            />
          </TabList>
          <StyledTabPanel value={Tabs.ScheduledOnsiteTrainings}>
            <ScheduledOnsiteTrainingTab
              goToLibraryTab={() =>
                setCurrentTab(Tabs.OnsiteTrainingLibrary as string)
              }
              onSearch={handleSearch}
              onSort={handleSort}
              page={scheduledListPage}
              searchKey={searchKey}
              sortKey={sortKey}
              onsiteTrainingClasses={onsiteTrainingClasses}
            />
          </StyledTabPanel>
          <StyledTabPanel value={Tabs.OnsiteTrainingLibrary}>
            <HorizontalLine color={theme.palette.common.white} line={2} />

            <Box sx={{ flexGrow: 1, marginBottom: '34px' }}>
              <MuiGrid container spacing={1}>
                <MuiGrid item xs={'auto'} flexShrink={1}>
                  <SortContentSelect
                    style={is640 ? selectStyleResponsive : selectStyle}
                    value={activeSort}
                    onChange={handleChangeSorting}
                  />
                </MuiGrid>
                {userHasRole(UserRoles.CompanyAdmin) && (
                  <MuiGrid item xs={'auto'} flexShrink={1}>
                    <ContentStatusFilter
                      style={is640 ? selectStyleResponsive : selectStyle}
                      value={filterContentStatus}
                      onChange={handleChangeContentStatus}
                    />
                  </MuiGrid>
                )}
                <MuiGrid
                  container
                  item
                  xs={'auto'}
                  justifyContent={'end'}
                  marginLeft={'auto'}
                >
                  <SearchInput
                    onSearch={searchHandler}
                    defaultValue={searchTerm}
                  />
                </MuiGrid>
              </MuiGrid>
            </Box>
            {isDoingInitialLoad && <LoadingContentIndicator />}
            {isEmptyState && <NoOnsiteTrainings />}
            {noRecordsFound && <NoRecordsFound />}
            {!isDoingInitialLoad && !isEmptyState && !noRecordsFound && (
              <OnsiteTrainingsCardList
                onsiteTrainings={onsiteTrainings}
                currentSearch={searchTerm}
                currentSort={activeSort}
                currentFilter={filterContentStatus}
                page={page}
              />
            )}
          </StyledTabPanel>
          <OnsiteTrainingModal
            showModal={showCompleteModal}
            savingInProgress={false}
            onCloseModal={handleCloseModal}
            nextButtonText={t('next', { ns: 'common' })}
          >
            <ApproveEmployeeOnsiteTable />
          </OnsiteTrainingModal>
          <SignatureOnsiteTrainingModal
            showModal={showSignatureModal}
            onCloseModal={handleCloseSignatureModal}
            nextButtonText={t('completeOnsiteTraining', {
              ns: 'onsiteTraining'
            })}
            signatureWidth={750}
            signatureHeight={260}
          />
        </TabContext>
      </PageContainer>
    </ThemeProvider>
  );
};

const fontTab: CSSProperties = {
  fontSize: '24px',
  textTransform: 'capitalize',
  color: 'rgba(255, 255, 255, 1)'
};

const StyledTabPanel = styled(TabPanel)`
  &.MuiTabPanel-root {
    padding: 0px;
  }
`;
export default IndexOnsiteTraining;

const NoRecordsFound = () => {
  const { t } = useTranslation(['onsiteTraining']);
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: '100px',
        width: 400,
        margin: '60px auto auto',
        textAlign: 'center'
      }}
    >
      <div style={{ fontSize: '30px' }}>
        {t('noOnsightTrainings', { ns: defaultNs })} <br />
        {t('matchingWithYourCriteria', { ns: defaultNs })}
      </div>
    </Box>
  );
};

const NoOnsiteTrainings = () => {
  const { t } = useTranslation(['onsiteTraining']);
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: '100px',
        width: 507,
        margin: '60px auto auto',
        textAlign: 'center'
      }}
    >
      <div style={{ fontSize: '30px' }}>
        {t('yourCompanyHasNoOnsiteTrainings', { ns: defaultNs })}
      </div>
      <BasicButton
        onClick={() => undefined}
        color="primary"
        style={{ marginTop: 20, fontSize: 16 }}
      >
        {t('viewOnsiteTrainingLibrary', { ns: defaultNs })}
      </BasicButton>
    </Box>
  );
};

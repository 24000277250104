import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/contentLibrary';
import { CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES } from '../actions/actionTypes';
import { Company } from '../entities/Company';
export interface ContentLibraryState {
  thirdPartyCompanies: Company[] | null;
  partnersCompanies: Company[] | null;
}

const unloadedState: ContentLibraryState = {
  thirdPartyCompanies: null,
  partnersCompanies: []
};

export const ContentLibraryReducer: Reducer<ContentLibraryState> = (
  state: ContentLibraryState | undefined,
  incomingAction: Action
): ContentLibraryState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case CONTENT_LIBRARY_SET_THIRD_PARTY_COMPANIES:
      return {
        ...state,
        thirdPartyCompanies: action.thirdPartyCompanies,
        partnersCompanies: action.thirdPartyCompanies.filter(
          company => company.isContentLibraryPartner
        )
      };
    default:
      return state;
  }
};

import {
  useDevCycleClient,
  useVariableValue,
  useIsDevCycleInitialized
} from '@devcycle/react-client-sdk';
import useCurrentUser from './useCurrentUser';

import { UserClaims } from '../core/constants';

const useDevCycleSdk = () => {
  const variables = {
    newLayoutStyle: {
      headerBackgroundColor: useVariableValue(
        'header-background-color',
        '#1d1d1e'
      )
    },
    skillsAndCertifications: {
      skillsAndCertifications: useVariableValue(
        'skills-and-certifications',
        false
      )
    },
    employeeActiveUpdates: {
      employeeActiveUpdates: useVariableValue('employee-active-updates', false)
    },
    newCertificatesFlow: {
      newCertificatesFlow: useVariableValue('new-certificates-flow', false)
    },
    showCompletionStats: {
      showCompletionStats: useVariableValue('show-completion-stats', false)
    },
    workloadcapacityfeed: {
      workloadcapacityfeed: useVariableValue('workload-capacity-feed', false)
    },
    mediaServiceMigration: useVariableValue('media-service-migration', false)
  };

  return {
    variables
  };
};

export const useDevCycleIdentifier = () => {
  const user = useCurrentUser();
  const devCycleClient = useDevCycleClient();
  const isDevCycleInitialized = useIsDevCycleInitialized();

  if (isDevCycleInitialized) {
    return;
  }

  const userId = (user?.profile[UserClaims.UserId] ??
    'unidentified-user') as string;
  const companyId = (user?.profile[UserClaims.CompanyId] ?? '') as string;
  const email = (user?.profile[UserClaims.UserEmail] ?? '') as string;
  const phoneNumber = (user?.profile[UserClaims.UserPhoneNumber] ??
    '') as string;
  const customData = {
    companyId: companyId,
    phoneNumber: phoneNumber
  };

  devCycleClient.identifyUser({
    user_id: userId,
    isAnonymous: false,
    email: email,
    customData
  });
};

export default useDevCycleSdk;

import React from 'react';
import { useAuth } from 'react-oidc-context';

export const Mobile = () => {
  const auth = useAuth();
  if (auth.user) {
    const payload = {
      access_token: auth.user.access_token,
      access_token_expires: auth.user.expires_at,
      refresh_token: auth.user.refresh_token,
      claims: Object.keys(auth.user.profile).map(claimKey => ({
        key: claimKey,
        value: auth.user?.profile[claimKey]
      }))
    };
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(JSON.stringify(payload));
    }
  }

  return <>Loading</>;
};

import { CSSProperties, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { EmployeeGroupAssignmentDefinition } from '../../entities/Assignment';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import { lowerCaseFirstLetter } from '../../utils/stringUtils';

const CustomPopover = ({
  anchorEl,
  children,
  ...props
}: Omit<
  PopoverProps,
  'open' | 'anchorOrigin' | 'transformOrigin' | 'disableRestoreFocus'
>) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: 'none'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableRestoreFocus
      {...props}
    >
      {children}
    </Popover>
  );
};

const CurrentlyAssignmentContent = ({
  assignmentDefinition
}: {
  assignmentDefinition: EmployeeGroupAssignmentDefinition;
}) => {
  const { t } = useTranslation(['assignments']);
  const { assignmentType, createdAtUtc } = assignmentDefinition;

  return (
    <div style={contentStyle}>
      <span>
        <span style={titleStyle}>{`${t('assignmentMode')}: `}</span>
        {t(lowerCaseFirstLetter(assignmentType))}
      </span>
      {createdAtUtc && (
        <span>
          <span style={titleStyle}>{`${t('createdAt')}: `}</span>
          {absUtcDateToLocalMoment(createdAtUtc).format('MM.DD.YY')}
        </span>
      )}
    </div>
  );
};

export interface ChipGroupAssignmentInfoProps {
  groupAssignmentDefinition: EmployeeGroupAssignmentDefinition;
}

const ChipGroupAssignmentInfo = ({
  groupAssignmentDefinition
}: ChipGroupAssignmentInfoProps) => {
  const { t } = useTranslation(['assignments']);
  const [currentlyAssignedElement, setCurrentlyAssignedElement] =
    useState<HTMLElement | null>(null);
  const { employeeGroupId } = groupAssignmentDefinition;

  const handleOpenCurrentlyAssignedPopover = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setCurrentlyAssignedElement(event.currentTarget);
  };

  const handleCloseCurrentlyAssignedPopover = () => {
    setCurrentlyAssignedElement(null);
  };

  return (
    <Stack direction="row" spacing={1}>
      <div
        aria-owns={`c-assigned-${employeeGroupId}-popover`}
        onMouseEnter={handleOpenCurrentlyAssignedPopover}
        onMouseLeave={handleCloseCurrentlyAssignedPopover}
      >
        <Chip
          label={t('currentlyAssigned')}
          color="primary"
          sx={{
            '&.MuiChip-root': {
              color: 'black'
            }
          }}
        />
      </div>
      <CustomPopover
        id={`c-assigned-${employeeGroupId}-popover`}
        anchorEl={currentlyAssignedElement}
        onClose={handleCloseCurrentlyAssignedPopover}
      >
        <Box
          padding="0.8rem"
          display="flex"
          flexDirection="column"
          fontSize={13}
          sx={boxStyle}
        >
          <CurrentlyAssignmentContent
            assignmentDefinition={groupAssignmentDefinition}
          />
        </Box>
      </CustomPopover>
    </Stack>
  );
};

const contentStyle: CSSProperties = {
  color: '#0E0D0D',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'column'
};

const titleStyle: CSSProperties = {
  color: '#0E0D0D',
  fontWeight: '400'
};

const boxStyle: SxProps = {
  backgroundColor: '#C4C4C4'
};

export default ChipGroupAssignmentInfo;

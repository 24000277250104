import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useState
} from 'react';
import { styled } from '@mui/material/styles';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { AssessmentsAverage } from '../../../entities/Assessment';
import { QuestionTypes } from '../../../core/constants';
import TrueFalseAverageQuestion from '../Questions/TrueFalseAverageQuestion';
import MultipleChoiceQuestionAverage from '../Questions/MultipleChoiceQuestionAverage';
import NumberAverageQuestion from '../Questions/NumberAverageQuestion';

export interface GradeOverViewModalAllEmployeesProps {
  showModal: boolean;
  answerQuizAverage: AssessmentsAverage;
  onClose: () => void;
}

const CustomDialog = styled(Dialog)`
    & .MuiDialog-root {
        
    }
    & .MuiPaper-root {
        width: 745px
        height: auto;
        border: 1px solid #999;
        border-radius: 10px;
    }
    & .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        padding: 0;
    }
    & .MuiDialogContent-root {
        padding: 0 0;
    }`;

const GradeOverViewModalAllEmployees = ({
  showModal,
  answerQuizAverage,
  onClose
}: PropsWithChildren<GradeOverViewModalAllEmployeesProps>) => {
  const theme = useTheme();
  const { t } = useTranslation(['analytics']);
  const [colorGrade, setColorGrade] = useState<string>(
    theme.palette.primary.main
  );
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  useEffect(() => {
    if (answerQuizAverage.gradeAverage <= 59) {
      setColorGrade(theme.palette.error.light);
    } else if (
      answerQuizAverage.gradeAverage >= 60 &&
      answerQuizAverage.gradeAverage <= 79
    ) {
      setColorGrade(theme.palette.primary.main);
    } else {
      setColorGrade(theme.palette.success.main);
    }
  }, []);

  return (
    <CustomDialog open={showModal} onClose={onClose} maxWidth="md">
      <DialogTitle>
        <Grid container>
          <Grid item style={{ width: '340px', paddingBottom: '35px' }}>
            <Stack
              direction={'column'}
              style={{ paddingLeft: '27px', paddingTop: '25px' }}
            >
              <Typography style={{ fontSize: '24px', height: '30px' }}>
                {`${t('gradeOverview', { ns: 'analytics' })}`}
              </Typography>
              {/* <span style={subTitleStyle}>{employeeName}</span> */}
            </Stack>
          </Grid>
          <Grid item style={gridSeparatorTitle}></Grid>
          <Grid item style={{ width: '200px' }}>
            <Stack direction={'column'}>
              <Typography style={{ ...gradeLabelStyle }}>
                {answerQuizAverage.numberOfTimesTaken}
              </Typography>
              <Typography style={gradeTextLabelStyle}>
                {`${t('numberOfTimesTaken', { ns: 'analytics' })}`}
              </Typography>
            </Stack>
          </Grid>
          <Grid item style={gridSeparatorTitle}></Grid>
          <Grid item style={{ width: '200px' }}>
            <Stack direction={'column'}>
              <Typography style={{ ...gradeLabelStyle, color: colorGrade }}>
                {`${answerQuizAverage.gradeAverage}%`}
              </Typography>
              <Typography style={gradeTextLabelStyle}>
                {`${t('averageQuizGrade', { ns: 'analytics' })}`}
              </Typography>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ ...iconStyle }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <hr style={horSeparator} />
      <DialogContent>
        {answerQuizAverage.questions?.map(question => {
          const answerAverage = answerQuizAverage.answersAverage.filter(
            item => item.questionId === question.id
          )[0];
          switch (question.type) {
            case QuestionTypes.TrueFalse:
              return (
                <TrueFalseAverageQuestion
                  answerAverage={answerAverage}
                  question={question}
                />
              );
            case QuestionTypes.MultipleChoiceText:
              return (
                <MultipleChoiceQuestionAverage
                  answerAverage={answerAverage}
                  question={question}
                />
              );
            case QuestionTypes.Number:
              return (
                <NumberAverageQuestion
                  answerAverage={answerAverage}
                  question={question}
                />
              );
            default:
              return <></>;
          }
        })}
      </DialogContent>
    </CustomDialog>
  );
};
const gradeLabelStyle: CSSProperties = {
  fontSize: '24px',
  height: '30px',
  paddingLeft: '13px',
  paddingTop: '21px'
};
const gradeTextLabelStyle: CSSProperties = {
  fontSize: '13px',
  height: '16px',
  paddingLeft: '13px',
  paddingTop: '21px',
  color: '#C4C4C4'
};
const horSeparator: CSSProperties = {
  height: '1px',
  color: '#999999',
  margin: '0px'
};
const gridSeparatorTitle: CSSProperties = {
  width: '2px',
  borderRight: '1px solid #363636'
};

export default GradeOverViewModalAllEmployees;

import React, { CSSProperties, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faClock } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from '@mui/material/styles';
import './MultiTimePicker.css';
import { useTranslation } from 'react-i18next';

export interface MultiTimePickerProps {
  selectedTimes: string[];
  onTimesSelected: (times: string[]) => void;
  variant: string;
  maxTimesSelected: number;
  preExistingNonRemovableTimes?: string[];
}

const MultiTimePicker: React.FC<MultiTimePickerProps> = ({
  selectedTimes,
  onTimesSelected,
  variant,
  maxTimesSelected,
  preExistingNonRemovableTimes = []
}) => {
  const [selectedTime, setSelectedTime] = useState(null);
  const [savedTimes, setSavedTimes] = useState<string>(
    selectedTimes.toString()
  );
  const dateRef = useRef<DatePicker>(null);
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
    const formattedTime = moment(time).format('HH:mm');

    //update joined value
    let times = savedTimes;
    if (times.length > 0) {
      times += ', ' + formattedTime;
    } else {
      times = formattedTime;
    }
    setSavedTimes(times);
    if (onTimesSelected) {
      onTimesSelected(times.split(','));
    }
  };

  const clearSelection = () => {
    const updatedSavedTimes = preExistingNonRemovableTimes.length
      ? savedTimes
          .split(',')
          .slice(0, preExistingNonRemovableTimes.length)
          .join(',')
      : '';

    setSavedTimes(updatedSavedTimes);
    if (onTimesSelected) {
      onTimesSelected(updatedSavedTimes.split(','));
    }
  };

  const inputStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    borderColor: theme.palette.grey[100],
    borderRadius: '5px',
    borderRight: 'none',
    color: theme.palette.grey[100],
    width: '100%',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottomWidth: '1px',
    cursor: 'pointer',
    minHeight: '41px'
  };

  const clearOptionStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    borderColor: 'rgb(153, 153, 153)',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderLeft: 'none',
    height: '100%',
    minHeight: '40px',
    marginTop: '1px',
    cursor: 'pointer'
  };

  const iconStyle: CSSProperties = {
    backgroundColor: theme.palette.common.black,
    marginTop: '5px',
    cursor: 'pointer',
    height: '99%'
  };

  const containerStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  };
  const yellowSection: CSSProperties = {
    backgroundColor: theme.palette.primary.main,
    width: '155px',
    borderRadius: '5px',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0px',
    height: '100%',
    minHeight: '40px',
    marginTop: '1px',
    cursor: 'pointer',
    textAlign: 'center'
  };

  const yellowSectionIcon: CSSProperties = {
    color: theme.palette.common.black,
    marginTop: '5px'
  };

  const yellowSectionSpan: CSSProperties = {
    fontSize: '15px',
    marginRight: '5px',
    fontWeight: 'bold'
  };

  return (
    <div style={containerStyle} className="hide-datepicker">
      <DatePicker
        selected={selectedTime}
        onChange={handleTimeChange}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        dateFormat="h:mm aa"
        timeCaption="Time"
        popperClassName="date-picker-time"
        ref={dateRef}
        className="time-selector"
      />
      <input
        type="text"
        defaultValue={savedTimes}
        style={inputStyle}
        placeholder={`-${t('select', { ns: 'common' })}-`}
        onClick={() => {
          const selTimes = savedTimes ? savedTimes.split(',') : [];
          if (
            dateRef &&
            dateRef.current &&
            selTimes.length < maxTimesSelected
          ) {
            dateRef.current.setOpen(true);
          }
        }}
      />

      <div style={clearOptionStyle}>
        <IconButton
          style={iconStyle}
          color="default"
          onClick={() => {
            clearSelection();
          }}
          aria-label="Clear"
          size="small"
        >
          <FontAwesomeIcon icon={faClose} color={theme.palette.grey[100]} />
        </IconButton>
      </div>
      <div style={yellowSection}>
        <IconButton
          style={yellowSectionIcon}
          color="default"
          onClick={() => {
            const selTimes = savedTimes ? savedTimes.split(',') : [];
            if (
              dateRef &&
              dateRef.current &&
              selTimes.length < maxTimesSelected
            ) {
              dateRef.current.setOpen(true);
            }
          }}
          aria-label="Clear"
          size="small"
        >
          <>
            <span style={yellowSectionSpan}>{`${t('select', {
              ns: 'common'
            })} `}</span>
            <FontAwesomeIcon
              icon={faClock}
              color={theme.palette.common.black}
            />
          </>
        </IconButton>
      </div>
    </div>
  );
};

export default MultiTimePicker;

import { Grid, Typography } from '@mui/material';
import { OnsiteTrainingPreviewDetailsNameActions } from './OnsiteTrainingPreviewDetailsNameActions';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';

export const OnsiteTrainingDetailsName = ({
  onsiteTraining,
  includePreviewActions
}: {
  onsiteTraining: OnsiteTraining;
  includePreviewActions: boolean;
}) => {
  return (
    <Grid
      container
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      style={{ padding: '16px 20px 0px 20px' }}
    >
      <Grid item>
        <Typography style={{ fontSize: '24px' }}>
          {onsiteTraining?.name}
        </Typography>
      </Grid>
      {includePreviewActions && (
        <Grid item>
          <OnsiteTrainingPreviewDetailsNameActions
            onsiteTraining={onsiteTraining}
          />
        </Grid>
      )}
    </Grid>
  );
};

import { CSSProperties } from 'react';
import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { CommunityLesson } from '../../entities/Lesson';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import BasicButton from '../core/BasicButton/BasicButton';
import { appPermissions } from '../../core/constants';
import { SelfAssignButton } from '../SelfAssignButton/SelfAssignButton';

export interface CommunityLessonCardActionsProps {
  communityLesson: CommunityLesson;
  onPlay?: (communityLesson: CommunityLesson) => void;
  onAssign?: (lessonId: string) => void;
  isComplete?: boolean;
  isPreviewMode?: boolean;
}

const CommunityLessonCardActions = ({
  communityLesson,
  onPlay,
  onAssign,
  isComplete,
  isPreviewMode
}: CommunityLessonCardActionsProps) => {
  const { t } = useTranslation(['common', 'lessons']);

  const handleAssign = () => {
    if (onAssign) {
      onAssign(communityLesson.id);
    }
  };

  return (
    <Grid container sx={divContainerStyle}>
      <div className="assignButtonContainer">
        <ProtectedComponent action={appPermissions.ASSIGN_LESSON}>
          <BasicButton
            style={{ ...buttonStyle }}
            width="105px"
            height="35px"
            color="primary"
            onClick={handleAssign}
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
      </div>
      <SelfAssignButton
        type="Lesson"
        style={{ ...buttonStyle }}
        id={communityLesson.id}
        companyId={communityLesson.companyId}
        title={communityLesson.title}
        width="105px"
        height="35px"
        color="grayButton"
      />
    </Grid>
  );
};

const divContainerStyle: SxProps = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  alignSelf: 'flex-end',
  gap: '8px',
  justifyContent: 'flex-end',
  paddingRight: '18px'
};

const buttonStyle: CSSProperties = {
  minWidth: '0'
};

export default CommunityLessonCardActions;

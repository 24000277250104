import { CSSProperties, useEffect, useState, useContext } from 'react';
import {
  Box,
  ThemeProvider,
  createTheme,
  Grid,
  FormControl
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import BasicSelect from '../../components/core/BasicSelect/BasicSelect';
import themeConfig from '../../themes/theme';
import GroupEngagementTable from './engagement/GroupEngagementTable';
import { actionCreators as analyticsActions } from '../../actions/analytics';
import GroupEngagementDetail from './engagement/GroupEngagementDetail';
import { useAnalyticsEngagement } from '../../hooks/useAnalyticsEngagement';
import { EngagementType } from '../../core/enums';
import {
  AnalyticsGroupEngagement,
  AnalyticsGroupEngagementDetail
} from '../../entities/Analytics';
import GroupEngagementDetailTable from './engagement/GroupEngagementDetailTable';
import usePermissions from '../../hooks/usePermissions';
import { appPermissions } from '../../core/constants';
import { AnalyticsFilterContext } from '../../contexts/AnalyticsContext';
import ModalFactory from './engagement/ModalFactory';
import { AnalyticsCommonFilterContext } from '../../contexts/AnalyticsContext';
import useDevCycleSdk from '../../hooks/useDevCycleSdk';
import SearchControlPanel from './SearchControlPanel';
import { useAppDispatch } from '../../store/hooks';

type GroupData = {
  id: string;
  name: string;
};

const Engagement = () => {
  const dispatch = useAppDispatch();
  const theme = createTheme(themeConfig);
  const { t } = useTranslation(['analytics']);
  const hasPermission = usePermissions();
  const canViewGroups = hasPermission(
    appPermissions.VIEW_ANALYTICS_GROUP_ASSIGNMENTS
  );
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const defaultEngagementViewOption = canViewGroups
    ? EngagementType.Groups
    : EngagementType.Employees;
  const engagementViewOptions = (() => {
    const options = [
      { label: t('employees', { ns: 'analytics' }), value: 'employees' }
    ];
    if (canViewGroups) {
      options.unshift({
        label: t('groups', { ns: 'analytics' }),
        value: 'groups'
      });
    }

    return options;
  })();
  const filterOptions = [
    { label: t('viewAll', { ns: 'analytics' }), value: '' },
    { label: t('active', { ns: 'analytics' }), value: 'active' },
    {
      label: t('subscribedAndActive', { ns: 'analytics' }),
      value: 'subscribed'
    }
  ];
  const [engagementView, setEngagementView] = useState<EngagementType>(
    defaultEngagementViewOption
  );
  const [groupForDetailView, setGroupForDetailView] = useState<GroupData>();
  const {
    state: {
      data,
      sortOptions,
      searchFilter,
      sortOption,
      filterOption,
      includeSelfAssignments,
      downloadingReport,
      selectedGroupId,
      selectedGroupName,
      selectedEmployeeId,
      selectedEmployeeName,
      modalType
    },
    api: {
      fetchDataForEngagementOverview,
      handleSearchData,
      handleChangeSortOption,
      handleChangeFilterOption,
      setIncludeSelfAssignments,
      handleDownloadCsvReport,
      resetData,
      handleClickGroupActionButton,
      handleClickEmployeeActionButton,
      handleCloseModal
    }
  } = useAnalyticsEngagement(engagementView);
  const contextValue = {
    includeSelfAssignments,
    activeOnly: filterOption === 'active',
    subscribedOnly: filterOption === 'subscribed',
    engagementViewType: engagementView,
    employeesGroupId: groupForDetailView?.id
  };
  const {
    variables: { employeeActiveUpdates }
  } = useDevCycleSdk();

  useEffect(() => {
    loadData();
  }, [
    includeSelfAssignments,
    filterOption,
    engagementView,
    groupId,
    supervisorId
  ]);

  const loadData = () => {
    dispatch(analyticsActions.setGroupEngagementData(null));
    if (!groupForDetailView) {
      dispatch(analyticsActions.setEmployeeEngagementData(null));
    }

    fetchDataForEngagementOverview();
  };

  const handleChangeEngagementView = (items: string[]) => {
    if (items[0] !== engagementView) {
      resetData();
    }
    setEngagementView(items[0] as EngagementType);
  };

  const handleGotoDetailView = (groupId: string, groupName: string) => {
    setGroupForDetailView({ id: groupId, name: groupName });
  };

  const handleBackToGroupEngagement = () => {
    setGroupForDetailView(undefined);
  };

  const getGroupEngagementData = () => {
    if (data) return data as unknown as AnalyticsGroupEngagement[];
  };

  const getEmployeeEngagementData = () => {
    if (data) return data as unknown as AnalyticsGroupEngagementDetail[];
  };

  if (groupForDetailView) {
    return (
      <ThemeProvider theme={theme}>
        <HorizontalLine color={theme.palette.common.white} line={2} />
        <AnalyticsFilterContext.Provider value={contextValue}>
          <GroupEngagementDetail
            groupId={groupForDetailView.id}
            groupName={groupForDetailView.name}
            includeSelfAssignments={includeSelfAssignments}
            filterOption={filterOption}
            onBackToGroupEngagement={handleBackToGroupEngagement}
          />
        </AnalyticsFilterContext.Provider>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container direction="row">
          <Grid item flex={1}>
            <FormControl
              sx={{ mr: 2, pb: 1 }}
              variant="standard"
              data-testid="engagementViewOptionsTestId"
              data-trackid="engagementViewOptionsTrackId"
            >
              <BasicSelect
                labelId="engagementViewOptions"
                id="engagementViewOptions"
                options={engagementViewOptions}
                defaultValue=""
                value={[engagementView]}
                handleOnChange={handleChangeEngagementView}
                style={selectStyle}
                theme="dark"
                multiple={false}
                disabled={!data}
                sx={{ backgroundColor: 'transparent' }}
              />
            </FormControl>
            <FormControl
              sx={{ mr: 2, pb: 1 }}
              variant="standard"
              data-testid="sortOptionsTestId"
              data-trackid="sortOptionsTrackId"
            >
              <BasicSelect
                labelId="sortOptions"
                id="sortOptions"
                options={sortOptions}
                value={sortOption ? [sortOption] : []}
                placeholder={t('sort', { ns: 'analytics' })}
                handleOnChange={handleChangeSortOption}
                style={{ ...selectStyle, width: '300px' }}
                theme="dark"
                multiple={false}
                disabled={!data}
                sx={{ backgroundColor: 'transparent' }}
              />
            </FormControl>
            {!employeeActiveUpdates.employeeActiveUpdates && (
              <FormControl
                sx={{ mr: 2, pb: 1 }}
                variant="standard"
                data-testid="filterOptionsTestId"
                data-trackid="filterOptionsTrackId"
              >
                <BasicSelect
                  labelId="filterOptions"
                  id="filterOptions"
                  options={filterOptions}
                  value={filterOption.length ? [filterOption] : []}
                  placeholder={t('filter', { ns: 'analytics' })}
                  handleOnChange={handleChangeFilterOption}
                  style={selectStyle}
                  theme="dark"
                  multiple={false}
                  disabled={!data}
                  sx={{ backgroundColor: 'transparent' }}
                />
              </FormControl>
            )}
          </Grid>
          <Grid item>
            <SearchControlPanel
              employeeActiveUpdatesFlag={
                employeeActiveUpdates.employeeActiveUpdates
              }
              data={data}
              downloadingReport={downloadingReport}
              handleSearchData={handleSearchData}
              searchText={searchFilter}
              handleDownloadCsvReport={handleDownloadCsvReport}
              includeSelfAssignments={includeSelfAssignments}
              setIncludeSelfAssignments={setIncludeSelfAssignments}
              filterOption={filterOption}
              filterOptions={filterOptions}
              handleChangeFilterOption={handleChangeFilterOption}
            />
          </Grid>
        </Grid>
        <br />
        {engagementView === EngagementType.Groups && (
          <GroupEngagementTable
            data={getGroupEngagementData()}
            onClickActionButton={handleClickGroupActionButton}
            onGotoDetailView={handleGotoDetailView}
          />
        )}
        {engagementView === EngagementType.Employees && (
          <GroupEngagementDetailTable
            data={getEmployeeEngagementData()}
            onClickActionButton={handleClickEmployeeActionButton}
          />
        )}
        <AnalyticsFilterContext.Provider value={contextValue}>
          <ModalFactory
            groupId={selectedGroupId}
            groupName={selectedGroupName}
            employeeId={selectedEmployeeId}
            employeeName={selectedEmployeeName}
            modalType={modalType}
            onCloseModal={handleCloseModal}
          />
        </AnalyticsFilterContext.Provider>
      </Box>
    </ThemeProvider>
  );
};

const selectStyle: CSSProperties = {
  width: '210px',
  height: '45px'
};

export default Engagement;

import { CSSProperties, useEffect } from 'react';
import { Box, IconButton, Stack, useTheme } from '@mui/material';
import DownloadForOfflineRoundedIcon from '@mui/icons-material/DownloadForOfflineRounded';
import { useTranslation } from 'react-i18next';

import GoBackComponent from '../common/GoBackComponent';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { actionCreators as analyticsActions } from '../../../actions/analytics';
import GroupEngagementDetailTable from './GroupEngagementDetailTable';
import config from '../../../config';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { UserClaims, appPermissions } from '../../../core/constants';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { useAnalyticsEngagement } from '../../../hooks/useAnalyticsEngagement';
import { EngagementType } from '../../../core/enums';
import { AnalyticsGroupEngagementDetail } from '../../../entities/Analytics';
import ModalFactory from './ModalFactory';
import { useAppDispatch } from '../../../store/hooks';

interface GroupEngagementDetailProps {
  groupId: string;
  groupName: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onBackToGroupEngagement: () => void;
}

const GroupEngagementDetail = ({
  groupId,
  groupName,
  includeSelfAssignments,
  filterOption,
  onBackToGroupEngagement
}: GroupEngagementDetailProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const user = useCurrentUser();
  const { t } = useTranslation(['analytics']);
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const {
    state: {
      data,
      sortOptions,
      searchFilter,
      sortOption,
      downloadingReport,
      selectedEmployeeId,
      selectedEmployeeName,
      modalType
    },
    api: {
      fetchDataForGroupEngagementDetail,
      handleSearchData,
      handleChangeSortOption,
      handleDownloadCsvReport,
      handleClickEmployeeActionButton,
      handleCloseModal
    }
  } = useAnalyticsEngagement(EngagementType.GroupDetail);

  useEffect(() => {
    fetchDataForGroupEngagementDetail(
      groupId,
      includeSelfAssignments,
      filterOption
    );

    return () => {
      dispatch(analyticsActions.setGroupEngagementDetailData(null));
    };
  }, [groupId]);

  const openManageGroup = () => {
    window.open(
      `${config.AUTHORITY}/Employees/${companyId}#/${companyId}/employees/groups`
    );
  };

  const getGroupEngagementDetailData = () => {
    if (data) return data as unknown as AnalyticsGroupEngagementDetail[];
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '1rem',
          flexWrap: 'wrap'
        }}
      >
        <span style={{ fontSize: 24 }}>{groupName}</span>
        <ProtectedComponent action={appPermissions.CREATE_GROUP}>
          <BasicButton
            onClick={openManageGroup}
            color="secondary"
            style={customButtonStyle}
          >
            {t('manageGroup', { ns: 'analytics' })}
          </BasicButton>
        </ProtectedComponent>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <GoBackComponent
          onBackTo={onBackToGroupEngagement}
          linkText={t('backToAllGroups', { ns: 'analytics' })}
        />
        <Stack direction="row" spacing={1} paddingBottom={2} flexWrap="wrap">
          <BasicSelect
            labelId="sortOptions"
            id="sortOptions"
            options={sortOptions}
            defaultValue=""
            value={[sortOption]}
            handleOnChange={handleChangeSortOption}
            style={selectStyle}
            theme="dark"
            multiple={false}
            sx={{ backgroundColor: 'transparent' }}
          />
          <SearchBox
            id="searchContent"
            name="searchContent"
            value={searchFilter}
            onChangeValue={handleSearchData}
            placeholderText={t('search', { ns: 'common' })}
            width="250px"
            height="45px"
          />
          <IconButton
            aria-label="downloadCSV"
            size="large"
            onClick={handleDownloadCsvReport}
            disabled={downloadingReport}
            sx={{ color: theme.palette.grey[100], paddingTop: 0 }}
          >
            <DownloadForOfflineRoundedIcon style={{ fontSize: 45 }} />
          </IconButton>
        </Stack>
      </Box>
      <GroupEngagementDetailTable
        data={getGroupEngagementDetailData()}
        onClickActionButton={handleClickEmployeeActionButton}
      />
      <ModalFactory
        employeeId={selectedEmployeeId}
        employeeName={selectedEmployeeName}
        modalType={modalType}
        onCloseModal={handleCloseModal}
      />
    </Box>
  );
};

const selectStyle: CSSProperties = {
  width: '230px',
  height: '45px'
};

const customButtonStyle: CSSProperties = {
  width: '150px',
  height: '45px'
};

export default GroupEngagementDetail;

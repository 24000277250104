import React from 'react';

const FormLabel: React.FC<any> = ({ children }) => {
  const labelStyle = {
    color: '#999999',
    lineHeight: '18px',
    fontSize: '15px',
    marginTop: '5px'
  };
  return <label style={labelStyle}>{children}</label>;
};

export default FormLabel;

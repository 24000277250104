import {
  DataGridPremiumProps,
  GridColDef,
  GridRowId
} from '@mui/x-data-grid-premium';
import { CollapsibleDataTable as CustomDataGridPremium } from '@buildwitt/component-library';
import React, { useEffect } from 'react';
import { getEmployeesFullName } from './ColumnDefinitions';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { useAppDispatch } from '../../../store/hooks';
import { actionCreators as certificationActions } from '../../../actions/certifications';
import { LinearProgress } from '@mui/material';
import { Certification } from '../../../entities/Certification';
import { useTranslation } from 'react-i18next';

interface AwardCertificationTableProps {
  certification: Certification;
  setPageModel: React.Dispatch<
    React.SetStateAction<{
      page: number;
      pageSize: number;
    }>
  >;
  pageModel: {
    page: number;
    pageSize: number;
  };
  searchValue: string;
  groupId: string;
  handleRowSelectionModelChange: (newRowSelectedIds: GridRowId[]) => void;
}

const AwardCertificationTable = ({
  certification,
  setPageModel,
  pageModel,
  searchValue,
  groupId,
  handleRowSelectionModelChange
}: AwardCertificationTableProps) => {
  const { t } = useTranslation(['certifications']);
  const columns: GridColDef[] = [
    getEmployeesFullName(
      t('employeeName', { ns: 'certifications' }),
      t('previouslyAwarded', { ns: 'certifications' }),
      false,
      100,
      Infinity,
      1
    )
  ];

  const employeesToAward = useSelector(
    (state: ApplicationState) => state.certifications?.employeesToAward
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(certificationActions.resetEmployeesToAward());
    };
  }, []);

  const handleOnRowsScrollEnd: DataGridPremiumProps['onRowsScrollEnd'] = () => {
    if (!employeesToAward?.employees.length) {
      return;
    }

    if (employeesToAward.employees.length < employeesToAward.totalCount) {
      dispatch(
        certificationActions.getEmployeesToAward(
          certification.id,
          pageModel.page + 1,
          pageModel.pageSize,
          { value: searchValue, firstSearch: false },
          groupId
        )
      );
      setPageModel({
        page: pageModel.page + 1,
        pageSize: pageModel.pageSize
      });
    }
  };

  return (
    <CustomDataGridPremium
      rows={employeesToAward?.employees ?? []}
      columns={columns}
      getRowId={row => row.employeeId}
      rowCount={employeesToAward?.totalCount}
      loading={employeesToAward?.loading}
      checkboxSelection
      onRowSelectionModelChange={handleRowSelectionModelChange}
      keepNonExistentRowsSelected
      sx={{
        height: '450px',
        '& .MuiDataGrid-row': {},
        '& .Mui-selected': {
          backgroundColor: '#FF9E0020 !important'
        }
      }}
      slots={{
        loadingOverlay: LinearProgress
      }}
      onRowsScrollEnd={handleOnRowsScrollEnd}
      disableRowSelectionOnClick
    />
  );
};

export default AwardCertificationTable;

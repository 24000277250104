import { Answer, Question } from '../../entities/Assessment';

export interface Question2Props<T> {
  questionText: string;
  showFeedback: boolean;
  answerStatus: AnswerStatus;
  correctAnswer: T;
  receivedAnswer?: T;
  onAnswerReceived: (answer?: T) => void;
}

export enum AnswerStatus {
  NoAnswer,
  AnsweredSelected,
  AnswerSubmitted
}

export function renderFeedback<T>(
  correctAnswer: T,
  correctAnswerAsString: string,
  correctAnswerMsg: string,
  incorrectAnswerMsg: string,
  receivedAnswer?: T
) {
  if (correctAnswer === receivedAnswer) {
    return correctAnswerMsg;
  }

  return `'${correctAnswerAsString}' ${incorrectAnswerMsg}`;
}

export interface Question2ContainerProps {
  question: Question;
  savedAnswer?: Answer;
  setAnswer: (answer: Answer) => void;
  isShowingResultsView: boolean;
  shouldShowCorrectAnswer: boolean;
}

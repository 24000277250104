import { CSSProperties } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

import OnsiteTrainingCardBase from './OnsiteTrainingCardBase';
import useUserRole from '../../hooks/useUserRole';
import { UserRoles } from '../../core/constants';
import cloneIcon from '../../assets/clone.svg';
import BasicButton from '../core/BasicButton/BasicButton';
import { OnsiteTraining } from '../../entities/OnsiteTraining';

interface OnsiteTrainingCardLibraryProps {
  onsiteTraining: OnsiteTraining;
  onShowPreview: (item: OnsiteTraining) => void;
  onEdit: () => void;
  onClone: () => void;
  onAssign: () => void;
}

interface ContentOverThumnbnailProps
  extends Omit<
    OnsiteTrainingCardLibraryProps,
    'onsiteTraining' | 'onShowPreview' | 'onAssign'
  > {
  onsiteTraining: OnsiteTraining;
}

const OnsiteTrainingCardLibrary = ({
  onsiteTraining,
  onShowPreview,
  onClone,
  onEdit,
  onAssign
}: OnsiteTrainingCardLibraryProps) => {
  return (
    <OnsiteTrainingCardBase
      onsiteTraining={onsiteTraining}
      contentOverThumbnail={
        <ContentOverThumnbnail
          onEdit={onEdit}
          onClone={onClone}
          onsiteTraining={onsiteTraining}
        />
      }
      cardActions={
        <ActionsButtons
          onAssign={onAssign}
          onEdit={onEdit}
          onsiteTraining={onsiteTraining}
        />
      }
      onShowPreview={onShowPreview}
    />
  );
};

const ActionsButtons = ({
  onAssign,
  onEdit,
  onsiteTraining
}: {
  onAssign: () => void;
  onEdit: () => void;
  onsiteTraining: OnsiteTraining;
}) => {
  const { t } = useTranslation(['common']);
  const [userHasRole] = useUserRole();

  if (
    userHasRole([
      UserRoles.CompanyAdmin,
      UserRoles.GroupLead,
      UserRoles.Supervisor
    ])
  ) {
    return (
      <BasicButton
        onClick={onsiteTraining.isDraft ? () => onEdit() : () => onAssign()}
        color="primary"
        style={assignButton}
      >
        {onsiteTraining.isDraft ? t('editDraft') : t('assign')}
      </BasicButton>
    );
  }

  return <></>;
};

const ContentOverThumnbnail = ({
  onEdit,
  onClone,
  onsiteTraining
}: ContentOverThumnbnailProps) => {
  const [userHasRole] = useUserRole();
  const { t } = useTranslation(['common']);

  return (
    <Box display="flex" gap={1}>
      <div style={actionButton}>
        <Typography fontSize={12}>{t('clone')}</Typography>
        <IconButton onClick={onClone}>
          <img alt="clone" src={cloneIcon} style={cloneIconStyle} />
        </IconButton>
      </div>
      {userHasRole(UserRoles.CompanyAdmin) && !onsiteTraining.isDraft && (
        <div style={actionButton}>
          <Typography fontSize={12}>{t('edit')}</Typography>
          <IconButton onClick={onEdit}>
            <EditIcon style={editIconStyle} />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

const actionButton: CSSProperties = {
  paddingLeft: '10px',
  background: '#1b1c1c',
  borderRadius: 22,
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center'
};

const editIconStyle: CSSProperties = {
  color: 'white',
  fontSize: 14
};

const cloneIconStyle: CSSProperties = {
  width: '10px',
  height: '10px'
};

const assignButton: CSSProperties = {
  height: '34px',
  width: '108px',
  minWidth: '108px',
  fontSize: '15px',
  fontWeight: 600,
  bottom: 0,
  marginLeft: '12px'
};

export default OnsiteTrainingCardLibrary;

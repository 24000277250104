import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../../components/CustomDataGrid/CustomDataGrid';

interface ModalSkillGridProps {
  rows?: GridValidRowModel[] | null;
  columns: GridColDef[];
}

const ModalSkillGrid = ({ rows, columns }: ModalSkillGridProps) => (
  <>
    {!rows && (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    )}
    {!!rows && (
      <Box
        sx={{
          height: '60vh',
          width: '100%'
        }}
      >
        <ModifiedCustomDataGrid
          getRowId={row => row.id}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          getRowClassName={getCustomRowClassName}
          disableColumnMenu
        />
      </Box>
    )}
  </>
);

const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  color: theme.palette.common.white,
  border: `none`,
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '8px',
  fontSize: '16px',
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-cell': {
    borderRight: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiIconButton-root': {
    color: theme.palette.common.white
  },
  '& .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey.A100}`
  },
  '& .MuiDataGrid-iconSeparator': {
    color: theme.palette.grey.A100
  },
  '& .MuiTablePagination-displayedRows': {
    paddingTop: '1rem'
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#333333'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
    width: '10px',
    background: '#3e3e3e'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 5px #3e3e3e',
    borderRadius: '10px'
  },
  '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
    background: theme.palette.common.white,
    width: '10px',
    borderRadius: '7px'
  },
  '& .MuiDataGrid-virtualScroller': {
    overflow: 'auto'
  }
}));

export default ModalSkillGrid;

import { useMemo } from 'react';
import {
  Stack,
  Typography,
  useTheme,
  IconButton,
  styled,
  Chip
} from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { useTranslation } from 'react-i18next';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';

import {
  StyledCompetenceDetailContainer,
  StyledDetailSection,
  StyledStackContent,
  StyledSpanTitle
} from './SkillComponentsHelper';
import {
  CompetencePaginatedListItem,
  PdfCertificate
} from '../../entities/Achievements';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import CertificatePDFTemplate from './CertificatePDFTemplate';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { useCalendarHelper } from '../../hooks/useCalendarHelper';
import DownloadIcon from '@mui/icons-material/Download';
import { downloadBlob } from '../../services/blobStorage-service';
import config from '../../config';

interface MyCertificateDetailProps {
  competenceItem?: CompetencePaginatedListItem;
}

const StyleChipFile = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.16)',
  color: theme.palette.common.white
}));
const MyCertificateDetail = ({ competenceItem }: MyCertificateDetailProps) => {
  const user = useCurrentUser();
  const theme = useTheme();
  const { t } = useTranslation(['skills']);
  const {
    constants: { monthNames }
  } = useCalendarHelper();

  const handleDownloadFile = (url: string, fileName: string) => {
    downloadBlob(url, fileName, config.BLOB_SKILLATTACHMENTS_CONTAINER!);
  };

  const pdfData: PdfCertificate | undefined = useMemo(() => {
    if (!competenceItem) {
      return;
    }

    const { name } = competenceItem.competence;
    const {
      learningUnitName,
      awardedDate,
      expirationDate,
      employeeCompetenceAwarders
    } = competenceItem.employeeCompetence!;
    const momentCreatedAt = absUtcDateToLocalMoment(awardedDate);
    const momentExpDate = expirationDate
      ? absUtcDateToLocalMoment(expirationDate)
      : null;

    return {
      companyLogoUrl: (user?.profile[UserClaims.CompanyLogoUrl] ??
        '') as string,
      name,
      employeeName: `${user?.profile[UserClaims.FirstName]} ${
        user?.profile[UserClaims.LastName]
      }`,
      learningUnitName,
      formattedCreatedAt: `${
        monthNames[momentCreatedAt.month()]
      } ${momentCreatedAt.date()}, ${momentCreatedAt.year()}`,
      formattedExpirationDate: momentExpDate
        ? `${
            monthNames[momentExpDate.month()]
          } ${momentExpDate.date()}, ${momentExpDate.year()}`
        : '',
      awarderNames: employeeCompetenceAwarders.map(
        awarder => `${awarder.firstName} ${awarder.lastName}`
      )
    };
  }, [competenceItem]);

  if (!competenceItem) {
    return (
      <StyledCompetenceDetailContainer
        sx={{
          display: 'flex'
        }}
      />
    );
  }

  const { name, description, governingBody, generatePDF } =
    competenceItem.competence;
  const { employeeCompetenceAttachments, expirationDate } =
    competenceItem.employeeCompetence!;

  return (
    <StyledCompetenceDetailContainer>
      <StyledDetailSection
        sx={{
          justifyContent: 'space-between',
          '&.MuiBox-root': {
            padding: '0.8rem !important'
          }
        }}
      >
        <Stack spacing={1}>
          <Typography sx={theme.typography.h5}>{name}</Typography>
          <Stack
            direction="row"
            spacing={1}
            sx={{ color: '#929292', fontSize: '15px' }}
          >
            <WorkspacePremiumIcon />
            <span>{t('certificate')}</span>
          </Stack>
        </Stack>
        {generatePDF && (
          <PDFDownloadLink
            document={<CertificatePDFTemplate pdfData={pdfData} />}
            fileName={`Training-certificate-${moment().format(
              'MMDDYY_HHmmss'
            )}`}
          >
            <IconButton>
              <DownloadForOfflineIcon
                sx={{ fontSize: 42, color: theme.palette.grey[100] }}
              />
            </IconButton>
          </PDFDownloadLink>
        )}
      </StyledDetailSection>
      {generatePDF && (
        <StyledDetailSection sx={{ minHeight: '400px' }}>
          <PDFViewer showToolbar={false} width="100%">
            <CertificatePDFTemplate pdfData={pdfData} />
          </PDFViewer>
        </StyledDetailSection>
      )}
      <StyledDetailSection>
        <StyledStackContent
          sx={{
            width: '100%',
            borderRight: `1px solid ${theme.palette.grey[100]}`
          }}
        >
          <StyledSpanTitle>{t('description')}</StyledSpanTitle>
          {description}
        </StyledStackContent>
        <StyledStackContent sx={{ width: '200px' }}>
          <StyledSpanTitle>{t('expirationDate')}</StyledSpanTitle>
          {expirationDate
            ? absUtcDateToLocalMoment(expirationDate).format('MM.DD.YYYY')
            : t('none')}
        </StyledStackContent>
      </StyledDetailSection>
      <StyledDetailSection>
        <StyledStackContent>
          <StyledSpanTitle>{t('governingBody')}</StyledSpanTitle>
          {governingBody}
        </StyledStackContent>
      </StyledDetailSection>
      {employeeCompetenceAttachments.length > 0 && (
        <StyledDetailSection sx={{ borderBottom: 'none' }}>
          <StyledStackContent spacing={1}>
            <StyledSpanTitle>{t('attachments')}</StyledSpanTitle>
            <StyledStackContent spacing={1} direction="row" sx={{ padding: 0 }}>
              {employeeCompetenceAttachments.map(file => {
                const { name, url } = file.competenceFile;

                return (
                  <StyleChipFile
                    icon={<DownloadIcon />}
                    label={name}
                    onClick={() => handleDownloadFile(url, name)}
                  />
                );
              })}
            </StyledStackContent>
          </StyledStackContent>
        </StyledDetailSection>
      )}
    </StyledCompetenceDetailContainer>
  );
};

export default MyCertificateDetail;

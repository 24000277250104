import React, { CSSProperties, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import BasicButton from '../core/BasicButton/BasicButton';
import { UserClaims } from '../../core/constants';
import { EntityTypeEnum } from '../../core/enums';
import ConfirmationModal from '../core/ConfirmationModal/ConfirmationModal';
import { getUnassignmentCall } from '../ManageEmployeeAssignmentsModal/utils';
import { actionCreators as studentActions } from '../../actions/student';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  AssignedCourse,
  AssignedLearningPlan,
  AssignedLesson
} from '../../entities/LearningPlaylist';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';

export interface RemoveAssignButtonProps {
  assignment: AssignedCourse | AssignedLearningPlan | AssignedLesson;
  assignmentType: number | undefined;
  style: CSSProperties;
}

export const RemoveAssignButton: React.FC<RemoveAssignButtonProps> = ({
  assignment,
  assignmentType,
  style
}: RemoveAssignButtonProps) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [isWorking, setIsWorking] = useState<boolean>(false);

  const employeeId = user?.profile[UserClaims.EmployeeId] as string;

  const handleUnAssign = useCallback(() => {
    if (assignment.assignmentId) {
      const contentType =
        assignmentType === EntityTypeEnum.Lesson
          ? 'Lessons'
          : assignmentType === EntityTypeEnum.Course
            ? 'Courses'
            : 'LearningPlans';
      getUnassignmentCall(contentType, assignment.assignmentId)
        .then(() => {
          setIsWorking(false);
          dispatch(studentActions.getEmployeePlaylist(employeeId));
        })
        .catch(() => {
          setIsWorking(false);
          enqueueSnackbar(t('anExceptionOccurred', { ns: 'common' }), {
            variant: 'error'
          });
        });
    }
  }, [assignment.assignmentId, assignmentType, dispatch, employeeId, t]);

  const handleClose = () => {
    setIsWorking(false);
  };
  return (
    <>
      {assignment.assignmentId && assignment.assignmentType === 'SelfAssign' ? (
        <BasicButton
          onClick={() => setIsWorking(true)}
          style={style}
          color="grayButton"
          loading={isWorking}
        >
          {t('remove')}
        </BasicButton>
      ) : null}
      <ConfirmationModal
        show={isWorking}
        showSave={true}
        showCancel={true}
        maxWidth="sm"
        saveText={t('remove')}
        cancelText={t('cancel')}
        title={t('removeAssignment', { ns: 'assignments' })}
        onSave={handleUnAssign}
        onCancel={handleClose}
        showModalContentCentered={true}
        isGrayCancelButton={true}
        hasButtonsSameWidth={true}
      >
        <p>{t('areYouSure')}</p>
      </ConfirmationModal>
    </>
  );
};

import { useState } from 'react';
import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import SearchBox from '../../../components/core/SearchBox/SearchBox';
import { useCalendarHelper } from '../../../hooks/useCalendarHelper';
import EmployeeHalts from './EmployeeHalts';
import { appPermissions } from '../../../core/constants';
import usePermissions from '../../../hooks/usePermissions';
import YourHalts from './YourHalts';

const CustomTabPanel = styled(TabPanel)(() => ({
  '&.MuiTabPanel-root': {
    padding: 0
  }
}));

const HaltsDetail = () => {
  const theme = useTheme();
  const hasPermission = usePermissions();
  const { t } = useTranslation(['settings']);
  const canViewTodayHaltsTab = hasPermission(
    appPermissions.VIEW_TODAYS_HALTS_TAB
  );
  const {
    constants: { monthNames }
  } = useCalendarHelper();
  const [valueTab, setValueTab] = useState<string>(
    canViewTodayHaltsTab ? 'todaysHalts' : 'yourHalts'
  );
  const [searchKey, setSearchKey] = useState<string>('');
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSearchKey('');
    setValueTab(newValue);
  };

  const handleSearch = (value: any) => {
    const key = value as string;
    setSearchKey(key);
  };

  const getCurrentDateLabel = () => {
    const currentDate = moment();
    return `${monthNames[currentDate.month()]} ${currentDate.date()}th`;
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        borderRadius: '5px',
        border: `1px solid ${theme.palette.grey[100]}`,
        marginLeft: { xs: 0, lg: '1rem' },
        marginTop: { xs: '1rem', lg: 0 }
      }}
    >
      <TabContext value={valueTab}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#1D1D1E',
            border: `0.5px solid ${theme.palette.grey[100]}`
          }}
        >
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            aria-label="assignments tab"
            sx={{
              margin: '0.5rem',
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '18px'
              },
              '&.MuiTabs-root': {
                marginBottom: 0
              }
            }}
          >
            {canViewTodayHaltsTab && (
              <Tab value="todaysHalts" label={t('todaysHalts')} />
            )}
            <Tab value="yourHalts" label={t('yourHalts')} />
          </TabList>
          <Stack direction="row" sx={{ margin: '0.5rem', flexWrap: 'wrap' }}>
            <Typography
              sx={{ alignSelf: 'center', marginRight: '1rem' }}
            >{`${t('todaysDate')}: ${getCurrentDateLabel()}`}</Typography>
            <SearchBox
              id="searchEmployees"
              name="searchEmployees"
              value={searchKey}
              placeholderText={t('search')}
              width="100%"
              height="40px"
              minWidth="180px"
              variant="dark"
              onChangeValue={handleSearch}
              onSearchClick={handleSearch}
            />
          </Stack>
        </Box>
        <CustomTabPanel value="todaysHalts">
          <EmployeeHalts mode="todaysHalts" searchKey={searchKey} />
        </CustomTabPanel>
        <CustomTabPanel value="yourHalts">
          <YourHalts searchKey={searchKey} />
        </CustomTabPanel>
      </TabContext>
    </Box>
  );
};

export default HaltsDetail;

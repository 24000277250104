import { Box, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../store/hooks';
import { actionCreators } from '../../../actions/certifications';
import {
  DefaultSearchDebounceTime,
  pageCertificationsState,
  searchAutocompleteEvents,
  UserClaims
} from '../../../core/constants';
import FormCheckBox from '../../../components/core/FormCheckBox/FormCheckBox';
import { ApplicationState } from '../../../store';
import EmployeeCertificationsTable from './EmployeeCertificationsTable';
import { CustomAutoComplete } from '@buildwitt/component-library';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useDebounce } from '../../../hooks/useDebounce';
import EditEmployeeCertificationModal from './EditEmployeeCertification/EditEmployeeCertificationsModal';

interface EmployeeData {
  id: string;
  name: string;
}

const EmployeeCertificationsTab = () => {
  const { t } = useTranslation(['certifications']);
  const dispatch = useAppDispatch();
  const { events, numberOfcharactersToTriggerApi } = searchAutocompleteEvents;
  const [lastSearch, setLastSearch] = useState<string>('');
  const [fetchBySearch, setFetchBySearch] = useState<boolean>(false);
  const employeeCertifications = useSelector(
    (state: ApplicationState) => state.certifications?.employeeCertifications
  );
  const isLoading = useSelector(
    (state: ApplicationState) => state.certifications?.isLoading
  );
  const forceFetchEmployeeCertifications = useSelector(
    (state: ApplicationState) =>
      state.certifications?.forceFetchEmployeeCertifications
  );
  const { page, pageSize, sortBy } = pageCertificationsState;
  const [includeAllEmployees, setIncludeAllEmployees] =
    useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<EmployeeData | null>();
  const [searchString, setSearchString] = useState<string | undefined>(
    undefined
  );
  const employeesNames = useSelector(
    (state: ApplicationState) => state.certifications?.employeesNames
  );
  const user = useCurrentUser();

  let searchTimeout: NodeJS.Timeout;

  useEffect(() => {
    if (forceFetchEmployeeCertifications) {
      fetchEmployeeCertifications();
      dispatch(actionCreators.forceFetchEmployeeCertifications(false));
    }
  }, [forceFetchEmployeeCertifications]);

  const fetchEmployeeCertifications = useCallback(() => {
    dispatch(
      actionCreators.getEmployeeCertifications(
        page,
        pageSize,
        sortBy.employeeName,
        includeAllEmployees,
        searchString
      )
    );
  }, [
    dispatch,
    includeAllEmployees,
    page,
    pageSize,
    searchString,
    sortBy.employeeName
  ]);

  useEffect(() => {
    fetchEmployeeCertifications();
    return () => {
      dispatch(actionCreators.resetEmployeeCertifications());
      dispatch(actionCreators.resetEmployeesNames());
    };
  }, [dispatch, fetchEmployeeCertifications]);

  const searchHandler = useDebounce<string>(value => {
    setSearchString(value);
    setLastSearch(value);
    setFetchBySearch(true);
  }, DefaultSearchDebounceTime);

  const handleOnInputChangeSearch = (
    event: React.SyntheticEvent,
    value: string
  ) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      if (
        (event.type === events.click || event.type === events.keydown) &&
        value.length >= numberOfcharactersToTriggerApi
      ) {
        searchHandler(value);
        return;
      }

      if (
        event.type === events.change &&
        value.length >= numberOfcharactersToTriggerApi
      ) {
        const companyId = user?.profile[UserClaims.CompanyId] as string;
        dispatch(actionCreators.getEmployeesNames(companyId, value));
        setLastSearch(value);
        return;
      }

      if (
        value.length < numberOfcharactersToTriggerApi &&
        lastSearch.length >= numberOfcharactersToTriggerApi
      ) {
        setFetchBySearch(false);
        dispatch(actionCreators.resetEmployeesNames());
        setLastSearch('');
        if (fetchBySearch) {
          setSearchString(undefined);
        }
      }
    }, 500);
  };

  const onHandleKey = (event: any) => {
    const value = event.target.value;
    if (
      value.length >= numberOfcharactersToTriggerApi &&
      event.key === events.enter
    ) {
      searchHandler(value);
    }
  };

  const handleEditEmployeeCertifications = (
    employeeId: string,
    employeeName: string
  ) => {
    if (employeeId.length) {
      setEmployeeData({
        id: employeeId,
        name: employeeName
      });
    }
  };

  const handleCloseEmployeeCertificationsModal = () => {
    setEmployeeData(null);
  };

  return (
    <>
      <Grid>
        <Box
          flexDirection={'row'}
          display={'flex'}
          gap={3}
          flexWrap={'wrap'}
          justifyContent={'start'}
          alignItems={'center'}
        >
          <CustomAutoComplete
            id="search"
            options={employeesNames ?? []}
            autoCompleteSxProps={{
              width: '410px',
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                '&:hover': { background: '#333333' },
                '&.Mui-focusVisible': {
                  backgroundColor: '#333333'
                }
              }
            }}
            handleOnInputChangeSearch={handleOnInputChangeSearch}
            onHandleKeyDown={onHandleKey}
            inputLabel={t('search')}
          />
          <FormCheckBox
            data-testid="icludeAllemployeesTestId"
            data-trackid="icludeAllemployeesTrackId"
            label={t('includeAllEmployees')}
            checked={includeAllEmployees}
            onChange={event => setIncludeAllEmployees(event.target.checked)}
            disabled={!employeeCertifications?.rows || isLoading}
            formControlLabelSx={{ margin: 0, height: '40px' }}
          />
        </Box>
        <Box style={{ paddingTop: '20px', height: '75vh' }}>
          <EmployeeCertificationsTable
            includeAllEmployees={includeAllEmployees}
            searchString={searchString}
            onEditEmployeeCertifications={handleEditEmployeeCertifications}
          />
        </Box>
      </Grid>
      {!!employeeData && (
        <EditEmployeeCertificationModal
          employeeId={employeeData.id}
          employeeFullName={employeeData.name}
          onCloseModal={handleCloseEmployeeCertificationsModal}
        />
      )}
    </>
  );
};

export default EmployeeCertificationsTab;

import { Stack } from '@mui/material';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import ProtectedComponent from '../../../components/core/ProtectedComponent/ProtectedComponent';
import { Course } from '../../../entities/Course';
import { appPermissions } from '../../../core/constants';
import { SelfAssignButton } from '../../../components/SelfAssignButton/SelfAssignButton';
import useMediaQuery from '@mui/material/useMediaQuery';

interface CourseActionsAreaProps {
  course: Course;
  cardAuxiliarFunction?: (id?: string) => void;
}
export const CourseActionsArea: React.FC<CourseActionsAreaProps> = ({
  course,
  cardAuxiliarFunction
}) => {
  const { t } = useTranslation(['common']);
  const screenSizeMatch = useMediaQuery('(min-width:1200px)');
  const handleAssign = (id: string) => {
    if (cardAuxiliarFunction) cardAuxiliarFunction(id);
  };

  const courseButtonStyles: CSSProperties = {
    width: '105px',
    height: '35px',
    minWidth: '90px'
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      id="cardActionsButtons"
      style={
        screenSizeMatch
          ? {
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center'
            }
          : {}
      }
    >
      {!course.isDraft && (
        <ProtectedComponent action={appPermissions.ASSIGN_COURSE}>
          <BasicButton
            style={
              screenSizeMatch
                ? { ...courseButtonStyles, marginLeft: '8px' }
                : courseButtonStyles
            }
            color="primary"
            onClick={() => handleAssign(course.id)}
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
      )}
      <SelfAssignButton
        type="Course"
        style={{ ...courseButtonStyles, marginLeft: '0.5rem' }}
        id={course.id}
        companyId={course.companyId}
        title={course.title}
        width="105px"
        height="35px"
        color="grayButton"
      />
    </Stack>
  );
};

import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { AnalyticsEmployeeOverdueAssignment } from '../../../entities/Analytics';
import {
  getAssignmentNameColumn,
  getAssignedOnColumn,
  getGenericValueActionColumn
} from '../common/utilAnalyticsColumnDefinition';
import { KeyValuePair } from '../../../entities/Common';
import { ModalDataGrid } from '../../../components/Analytics';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { EnrollmentTypes } from '../../../core/constants';

interface EmployeeOverdueAssignmentListProps {
  overdueAssignmentsData?: AnalyticsEmployeeOverdueAssignment[] | null;
  onEditDueDate: (
    assignmentId: string,
    learningUnitType: string,
    dueDate: Date
  ) => void;
}

const getNodeValue = (params: GridRenderCellParams<any>) => {
  return (
    <span style={{ fontSize: '16px' }}>
      {absUtcDateToLocalMoment(params.row.dueDate).format('MM.DD.YYYY')}
    </span>
  );
};

const EmployeeOverdueAssignmentList = ({
  overdueAssignmentsData,
  onEditDueDate
}: EmployeeOverdueAssignmentListProps) => {
  const { t } = useTranslation(['analytics', 'assignments', 'common']);
  const learningUnitNames: KeyValuePair[] = [
    { key: 'Lesson', value: t('lesson', { ns: 'assignments' }) },
    { key: 'Course', value: t('course', { ns: 'assignments' }) },
    { key: 'LearningPlan', value: t('learningPlan', { ns: 'assignments' }) }
  ];

  const handleEditDueDate = (params: GridRenderCellParams<any>) => {
    const { assignmentId, learningUnitType, dueDate } = params.row;
    onEditDueDate(assignmentId, learningUnitType, dueDate);
  };

  const getTooltipText = (params: GridRenderCellParams<any>) => {
    const { courseAssignmentId, learningPlanAssignmentId } = params.row;
    let levelName = '';
    if (params.row.editableDueDate) {
      return '';
    }

    if (params.row.assignmentType !== EnrollmentTypes.DueDate) {
      return t('workload', { ns: 'assignments' });
    }

    switch (params.row.learningUnitType) {
      case 'Lesson':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        else if (courseAssignmentId) levelName = 'course';
        break;
      case 'Course':
        if (learningPlanAssignmentId) levelName = 'learningPlan';
        break;
      default:
        break;
    }

    return levelName.length
      ? t('assignedAt', {
          ns: 'analytics',
          level: t(levelName, { ns: 'assignments' })
        })
      : levelName;
  };

  const columns: GridColDef[] = (() => [
    getAssignmentNameColumn(
      'learningUnitName',
      'learningUnitType',
      t('assignment', { ns: 'analytics' }),
      400,
      learningUnitNames
    ),
    getAssignedOnColumn(t('assignedOn', { ns: 'analytics' })),
    getGenericValueActionColumn(
      'editableDueDate',
      t('dueDate', { ns: 'analytics' }),
      t('edit', { ns: 'common' }),
      200,
      handleEditDueDate,
      getNodeValue,
      getTooltipText
    )
  ])();

  return <ModalDataGrid rows={overdueAssignmentsData} columns={columns} />;
};

export default EmployeeOverdueAssignmentList;

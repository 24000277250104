import React, { CSSProperties } from 'react';
import { Spinner } from 'react-bootstrap';
import { Typography } from '@mui/material';

const FallbackLoader = () => {
  return (
    <div style={divStyle}>
      <Spinner animation="border" variant="warning" />
      <Typography>Loading elements...</Typography>
    </div>
  );
};

const divStyle: CSSProperties = {
  textAlign: 'center',
  marginTop: window.screen.height / 2
};

export default FallbackLoader;

import { Box } from '@mui/material';

interface NoRecordsFoundProps {
  primaryMessage: string;
  secondayMessage?: string;
}

const NoRecordsFound = ({
  primaryMessage,
  secondayMessage
}: NoRecordsFoundProps) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginTop: '100px',
        width: 400,
        margin: '60px auto auto',
        textAlign: 'center'
      }}
    >
      <div style={{ fontSize: '30px' }}>
        {primaryMessage}
        <br />
        {secondayMessage}
      </div>
    </Box>
  );
};

export default NoRecordsFound;

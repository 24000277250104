import Typography from '@mui/material/Typography';
import { CSSProperties } from 'react';
import { pluralizeText } from '../../utils/stringUtils';
import { OverseerScope } from '../../core/enums';

const resourceNameStyle: CSSProperties = {
  color: '#fff',
  textDecoration: 'underline'
};

interface WeeklyOverseerSummaryNotificationProps {
  inactiveUsersCount: any;
  overseerScope: OverseerScope;
}

const friendlyOverseerScope = {
  [OverseerScope.Admin]: 'In your Company',
  [OverseerScope.Supervisor]: 'Within people you supervised',
  [OverseerScope.GroupLead]: 'Within a group you lead'
};

export const WeeklyOverseerSummaryNotification: React.FC<
  WeeklyOverseerSummaryNotificationProps
> = ({ inactiveUsersCount, overseerScope }) => {
  const friendlyScope = friendlyOverseerScope[overseerScope];
  return (
    <Typography variant="body2" color="#C4C4C4">
      {friendlyScope}: <br />
      <br />
      <span style={resourceNameStyle}>{inactiveUsersCount}</span>{' '}
      {pluralizeText(inactiveUsersCount, 'employee')} have been inactive during
      the last week.
    </Typography>
  );
};

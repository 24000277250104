import { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actionCreators as analyticsActions } from '../actions/analytics';
import { ApplicationState } from '../store';
import { sortByKey } from '../utils/sorting';
import {
  AnalyticsOverdueAssignmentDetail,
  AnalyticsPendingCompletionDetail,
  AnalyticsCompletedAssignmentDetail,
  AnalyticsPendingCompletionQuizAssignments,
  AnalyticsCompletedQuizAssignments
} from '../entities/Analytics';
import { AnalyticsCommonFilterContext } from '../contexts/AnalyticsContext';
import { useAppDispatch } from '../store/hooks';

export const useAnalyticsModal = (
  modalType:
    | 'overdues'
    | 'pendingCompletions'
    | 'completed'
    | 'pendingCompletionQuiz'
    | 'completedQuiz',
  learningUnitType: string,
  includeSelfAssignments: boolean,
  filterOption: string,
  learningUnitId?: string
) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['analytics']);
  const { groupId, supervisorId } = useContext(AnalyticsCommonFilterContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<string>();
  const [dueDate, setDueDate] = useState<Date>();
  const overdueData = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.overdueLearningUnitAssignments
  );
  const pendingCompletionData = useSelector(
    (state: ApplicationState) => state?.analytics?.pendingCompletionAssignments
  );
  const completedData = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.completedLearningUnitAssignments
  );
  const pendingCompletionQuizAssignments = useSelector(
    (state: ApplicationState) =>
      state?.analytics?.pendingCompletionQuizAssignments
  );
  const completedQuizAssignments = useSelector(
    (state: ApplicationState) => state?.analytics?.completedQuizAssignments
  );
  const activeOnly = filterOption === 'active';
  const subscribedOnly = filterOption === 'subscribed';
  useEffect(() => {
    if (learningUnitId && learningUnitId.length) {
      switch (modalType) {
        case 'overdues':
          dispatch(
            analyticsActions.getOverdueLearningUnitAssignments(
              learningUnitId,
              learningUnitType,
              includeSelfAssignments,
              activeOnly,
              subscribedOnly,
              groupId,
              supervisorId
            )
          );
          setOpenModal(true);
          break;
        case 'pendingCompletions':
          dispatch(
            analyticsActions.getPendingCompletionAssignments(
              learningUnitId,
              learningUnitType,
              includeSelfAssignments,
              activeOnly,
              subscribedOnly,
              groupId,
              supervisorId
            )
          );
          setOpenModal(true);
          break;
        case 'completed':
          dispatch(
            analyticsActions.getCompletedLearningUnitAssignments(
              learningUnitId,
              learningUnitType,
              includeSelfAssignments,
              activeOnly,
              subscribedOnly,
              groupId,
              supervisorId
            )
          );
          setOpenModal(true);
          break;
        case 'pendingCompletionQuiz':
          dispatch(
            analyticsActions.getPendingCompletionQuizAssignmentsData(
              includeSelfAssignments,
              activeOnly,
              subscribedOnly,
              learningUnitId,
              groupId,
              supervisorId
            )
          );
          setOpenModal(true);
          break;
        case 'completedQuiz':
          dispatch(
            analyticsActions.getCompletedQuizAssignmentsData(
              includeSelfAssignments,
              activeOnly,
              subscribedOnly,
              learningUnitId,
              groupId,
              supervisorId
            )
          );
          setOpenModal(true);
          break;
        default:
          break;
      }
    }
  }, [learningUnitId]);

  const sortOptions = (() => {
    switch (modalType) {
      case 'overdues':
      case 'pendingCompletions':
      case 'pendingCompletionQuiz':
        return [
          { label: t('assignedDateAsc'), value: 'assignedDateAsc' },
          { label: t('assignedDateDesc'), value: 'assignedDateDesc' },
          { label: t('dueDateAsc'), value: 'dueDateAsc' },
          { label: t('dueDateDesc'), value: 'dueDateDesc' }
        ];
      case 'completed':
      case 'completedQuiz':
        return [
          { label: t('assigned'), value: 'Assigned' },
          { label: t('selfAssigned'), value: 'selfAssigned' },
          { label: t('completedDateAsc'), value: 'completedDateAsc' },
          { label: t('completedDateDesc'), value: 'completedDatedesc' }
        ];
    }
  })();

  const handleSorting = (option: string) => {
    let sortedData: any;
    switch (modalType) {
      case 'overdues':
        if (overdueData) {
          sortedData = handleSortingData<AnalyticsOverdueAssignmentDetail>(
            option,
            overdueData
          );
          dispatch(
            analyticsActions.setOverdueLearningUnitAssignments(sortedData)
          );
        }
        break;
      case 'pendingCompletions':
        if (pendingCompletionData) {
          sortedData = handleSortingData<AnalyticsPendingCompletionDetail>(
            option,
            pendingCompletionData
          );
          dispatch(
            analyticsActions.setPendingCompletionAssignments(sortedData)
          );
        }
        break;
      case 'completed':
        if (completedData) {
          sortedData = handleSortingData<AnalyticsCompletedAssignmentDetail>(
            option,
            completedData
          );
          dispatch(
            analyticsActions.setCompletedLearningUnitAssignments(sortedData)
          );
        }
        break;
      case 'pendingCompletionQuiz':
        if (pendingCompletionQuizAssignments) {
          sortedData =
            handleSortingData<AnalyticsPendingCompletionQuizAssignments>(
              option,
              pendingCompletionQuizAssignments
            );
          dispatch(
            analyticsActions.setPendingCompletionQuizAssignmentsData(sortedData)
          );
        }
        break;
      case 'completedQuiz':
        if (completedQuizAssignments) {
          sortedData = handleSortingData<AnalyticsCompletedQuizAssignments>(
            option,
            completedQuizAssignments
          );
          dispatch(
            analyticsActions.setCompletedQuizAssignmentsData(sortedData)
          );
        }
        break;
      default:
        break;
    }
  };

  const handleSortingData = <T>(option: string, data: any) => {
    switch (option) {
      case 'assignedDateAsc':
        return data.slice().sort(sortByKey<T>('assignedOn' as keyof T));
      case 'assignedDateDesc':
        return data
          .slice()
          .sort(sortByKey<T>('assignedOn' as keyof T))
          .reverse();
      case 'dueDateAsc':
        return data.slice().sort(sortByKey<T>('dueDate' as keyof T));
      case 'dueDateDesc':
        return data
          .slice()
          .sort(sortByKey<T>('dueDate' as keyof T))
          .reverse();
      case 'Assigned':
        return data.slice().sort(sortByKey<T>('assignmentMethod' as keyof T));
      case 'selfAssigned':
        return data
          .slice()
          .sort(sortByKey<T>('assignmentMethod' as keyof T))
          .reverse();
      case 'completedDateAsc':
        return data.slice().sort(sortByKey<T>('completedOn' as keyof T));
      case 'completedDatedesc':
        return data
          .slice()
          .sort(sortByKey<T>('completedOn' as keyof T))
          .reverse();
      default:
        return data.slice();
    }
  };

  const closeModal = () => {
    switch (modalType) {
      case 'overdues':
        dispatch(analyticsActions.setOverdueLearningUnitAssignments(null));
        break;
      case 'pendingCompletions':
        dispatch(analyticsActions.setPendingCompletionAssignments(null));
        break;
      case 'completed':
        dispatch(analyticsActions.setCompletedLearningUnitAssignments(null));
        break;
      case 'pendingCompletionQuiz':
        dispatch(
          analyticsActions.setPendingCompletionQuizAssignmentsData(null)
        );
        break;
      case 'completedQuiz':
        dispatch(analyticsActions.setCompletedQuizAssignmentsData(null));
        break;
      default:
        break;
    }
    setOpenModal(false);
  };

  const handleEditDueDate = (assignmentId: string, dueDate: Date) => {
    setAssignmentId(assignmentId);
    setDueDate(dueDate);
  };

  const handleCloseUpdateDueDateModal = () => {
    setAssignmentId(undefined);
    setDueDate(undefined);
  };

  const handleSuccessfulSaved = async () => {
    switch (modalType) {
      case 'overdues':
        await dispatch<Promise<void>>(
          analyticsActions.getOverdueLearningUnitAssignments(
            learningUnitId!,
            learningUnitType,
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            groupId,
            supervisorId
          )
        );
        break;
      case 'pendingCompletions':
        await dispatch<Promise<void>>(
          analyticsActions.getPendingCompletionAssignments(
            learningUnitId!,
            learningUnitType,
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            groupId,
            supervisorId
          )
        );
        break;
      case 'completed':
        await dispatch<Promise<void>>(
          analyticsActions.getCompletedLearningUnitAssignments(
            learningUnitId!,
            learningUnitType,
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            groupId,
            supervisorId
          )
        );
        break;
      case 'pendingCompletionQuiz':
        await dispatch<Promise<void>>(
          analyticsActions.getPendingCompletionQuizAssignmentsData(
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            learningUnitId!,
            groupId,
            supervisorId
          )
        );
        break;
      case 'completedQuiz':
        await dispatch<Promise<void>>(
          analyticsActions.getCompletedQuizAssignmentsData(
            includeSelfAssignments,
            activeOnly,
            subscribedOnly,
            learningUnitId!,
            groupId,
            supervisorId
          )
        );
        break;
      default:
        break;
    }
  };

  return {
    state: {
      sortOptions,
      openModal,
      assignmentId,
      dueDate,
      overdueData,
      pendingCompletionData,
      completedData,
      pendingCompletionQuizAssignments,
      completedQuizAssignments
    },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved
    }
  };
};

import { useEffect, useMemo, useState } from 'react';
import { Box, Chip, Stack, Typography, styled, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { QRCodeCanvas } from 'qrcode.react';
import { BlobProvider } from '@react-pdf/renderer';
import print from 'print-js';
import PrintIcon from '@mui/icons-material/Print';
import Fab from '@mui/material/Fab';

import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import { downloadBlob } from '../../../services/blobStorage-service';
import config from '../../../config';
import QRCodePdf from './QRCodePdf';

interface QRCodePanelProps {
  onsiteTraining: OnsiteTraining;
  onsiteTrainingClassId: string;
}

const QRCodePanel = ({
  onsiteTraining,
  onsiteTrainingClassId
}: QRCodePanelProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining']);
  const [qRCodeBase64, setQRCodeBase64] = useState<string>('');
  const { name, description, attachments } = onsiteTraining;

  useEffect(() => {
    const qrCodeCanvas = document.querySelector('canvas');
    const qrCodeDataUri = qrCodeCanvas!.toDataURL();
    setQRCodeBase64(qrCodeDataUri);
  }, []);

  const QRCorePdfFile = useMemo(() => {
    if (qRCodeBase64.length) {
      return QRCodePdf(qRCodeBase64);
    }
  }, [qRCodeBase64]);

  const handleDownloadAttachment = (url: string, name: string) => {
    downloadBlob(url, name, config.BLOB_ONSITE_TRAINING_ATTACHMENTS_CONTAINER!);
  };

  return (
    <Container>
      <Typography sx={theme.typography.h5}>{name}</Typography>
      <DescriptionParagraph>{description}</DescriptionParagraph>
      <Stack direction="row" flexWrap="wrap">
        {attachments.map(attachment => {
          const { fileName, fileUrl } = attachment;

          return (
            <AttachmentChip
              icon={<DownloadIcon />}
              label={fileName}
              onClick={() => handleDownloadAttachment(fileUrl, fileName)}
            />
          );
        })}
      </Stack>
      <QRCodeContainer>
        <QRCodeCanvas
          value={JSON.stringify({ onsiteTrainingClassId })}
          size={220}
        />
        {!!QRCorePdfFile && (
          <BlobProvider document={QRCorePdfFile}>
            {({ url, loading }) => {
              return !loading && !!url ? (
                <PrintButton size="small" onClick={() => print(url)}>
                  <PrintIcon sx={{ color: '#fff' }} />
                </PrintButton>
              ) : (
                <></>
              );
            }}
          </BlobProvider>
        )}
      </QRCodeContainer>
      <FooterParagraph>{t('QRCodefooterText')}</FooterParagraph>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: '5px',
  width: '100%',
  padding: '1.5rem',
  minHeight: '680px'
}));

const DescriptionParagraph = styled(Typography)(({ theme }) => ({
  paddingTop: '1rem',
  paddingBottom: '1rem',
  color: theme.palette.grey[50],
  fontSize: '16px',
  textAlign: 'justify'
}));

const FooterParagraph = styled(Typography)(({ theme }) => ({
  paddingTop: '1rem',
  color: theme.palette.grey[50],
  fontSize: '16px'
}));

const AttachmentChip = styled(Chip)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.16)',
  color: theme.palette.common.white,
  marginRight: '1rem',
  marginBottom: '0.5rem'
}));

const QRCodeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: '5px',
  paddingTop: '3rem',
  paddingBottom: '3rem',
  marginTop: '2rem',
  position: 'relative'
}));

const PrintButton = styled(Fab)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  position: 'absolute',
  top: '5px',
  right: '5px'
}));

export default QRCodePanel;

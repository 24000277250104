import { useState, useEffect, CSSProperties, useContext, FC } from 'react';
import Grid from '@mui/material/Grid';
import { Box, FormControl, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import HorizontalLine from '../../components/core/HorizontalLine/HorizontalLine';
import { ApplicationState } from '../../store';
import { CommunityLesson, Lesson } from '../../entities/Lesson';
import { DefaultSearchDebounceTime } from '../../core/constants';
import { actionCreators } from '../../actions/lessons';
import { useNavigate } from 'react-router-dom';
import { useLesson } from '../lessons/useLesson';
import AssignLesson from '../../pages/lessons/AssignLesson';
import { ContentLibraryTabNames } from '../../core/enums';
import SortContentSelect from './common/SortContentSelect';
import { Sorting } from '../../hooks/useContentLibraryNavigation';
import { enqueueSnackbar } from 'notistack';
import {
  filterLearningUnitsBySearchTerm,
  filterLearningUnitsByTags,
  sortLessons
} from '../../utils/contentLibraryUtils';
import SearchInput from './common/SearchInput';
import useLearningUnitSorting from './hooks/useLearningUnitSorting';
import { UserNavigationContext } from './UserNavigationContext';
import { useDebounce } from '../../hooks/useDebounce';
import ContentLibraryVirtualList from '../../components/ContentLibraryVirtualList/ContentLibraryVirtualList';
import LessonVirtualRow from './Community/LessonRowVirtual';
import ContentIndicators from './common/ContentIndicators';
import { useLogEvent } from '../../hooks/useLogEvent';
import { useEncodingStatusUpdate } from '../../hooks/useEncodingStatusUpdate';
import useLearningUnitTag from './hooks/useLearningUnitTag';
import useLearningUnitTagFilter from './hooks/useLearningUnitTagFilter';
import { Tag } from '../../entities/Tag';
import { TaggingField } from '../../components/TagInput/TaggingField';
import { useAppDispatch } from '../../store/hooks';

interface CommunityProps {
  encodedLessonId: string;
}

export const Community: FC<CommunityProps> = ({ encodedLessonId }) => {
  const { t } = useTranslation(['common', 'courses', 'contentLibrary']);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const [communityLessonsToShow, setCommunityLessonsToShow] = useState<
    CommunityLesson[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeSort, handleChangeSorting] = useLearningUnitSorting();
  const [tagFilter, handleChangeLearningUnitTag] = useLearningUnitTagFilter();
  const userNavigationState = useContext(UserNavigationContext);
  const userNavigation = userNavigationState?.state;
  const userNavigationApi = userNavigationState?.api;

  const communityLessons = useSelector(
    (state: ApplicationState) => state.lessons?.communityLessons
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    userNavigation?.search ?? ''
  );

  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  const tags = useLearningUnitTag('lessons', communityLessons);

  const {
    api: { handleOpenAssignLessonModal, handleCloseAssignLessonModal },
    state: { showAssignLessonModal }
  } = useLesson(false);
  const navigate = useNavigate();

  const updateLessonsToShow = (updatedList: CommunityLesson[]) => {
    const lessons = generateLessonsToShow(
      updatedList,
      searchTerm,
      activeSort as Sorting,
      tagFilter
    );
    setCommunityLessonsToShow(lessons);
  };
  useEncodingStatusUpdate(
    encodedLessonId,
    communityLessonsToShow,
    null,
    updateLessonsToShow
  );

  useEffect(() => {
    userNavigationApi?.changeSearch(searchTerm);

    if (communityLessons) {
      const lessons = generateLessonsToShow(
        communityLessons,
        searchTerm,
        activeSort as Sorting,
        tagFilter
      );
      setCommunityLessonsToShow(lessons);
      setIsLoading(false);
    }
  }, [communityLessons, searchTerm, activeSort, tagFilter]);

  useEffect(() => {
    if (searchTerm) {
      logEvent.logLibrarySearchEvent(searchTerm);
    }
  }, [searchTerm]);

  const generateLessonsToShow = (
    lessons: CommunityLesson[],
    searchTerm: string | null,
    activeSort: Sorting,
    filterTags: Tag[]
  ) => {
    let lessonOutput = lessons;

    if (searchTerm) {
      lessonOutput = filterLearningUnitsBySearchTerm<CommunityLesson>(
        searchTerm,
        item => item.title,
        item => item.description,
        lessonOutput,
        item => item.tags
      ) as CommunityLesson[];
    }

    if (filterTags.length) {
      lessonOutput = filterLearningUnitsByTags<CommunityLesson>(
        filterTags,
        item => item.tags,
        lessonOutput
      );
    }

    return sortLessons(
      lessonOutput,
      activeSort,
      item => item.title,
      item => item.createdAt!
    );
  };

  const handleLessonStart = (lesson: Lesson, isPreviewMode?: boolean) => {
    if (lesson && lesson.isEncodingFailed) {
      enqueueSnackbar(t('videoLessonEcodingFailed', { ns: 'lessons' }), {
        variant: 'error'
      });
    } else {
      dispatch(actionCreators.setCurrentLessons([lesson]));
      navigate(`/lessons/preview`, {
        state: {
          lesson,
          origin: ContentLibraryTabNames.Community,
          isPreviewMode
        }
      });
    }
  };

  return (
    <>
      <HorizontalLine color={theme.palette.common.white} line={2} />
      <Box sx={{ flexGrow: 1 }}>
        <Box
          flexDirection={{ xs: 'column', md: 'row' }}
          display={'flex'}
          gap={2}
          flexWrap={'wrap'}
          justifyContent={'space-between'}
        >
          <FormControl style={formControlContainer} variant="standard">
            <SortContentSelect
              value={activeSort}
              onChange={handleChangeSorting}
            />
          </FormControl>
          <Stack direction="row" gap={1} flexWrap={'wrap'}>
            <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
            <FormControl
              sx={{
                minWidth: { xs: '100%', md: '300px' },
                maxWidth: { xs: '100%', md: '600px' }
              }}
            >
              <TaggingField
                options={tags}
                value={tagFilter}
                onChange={handleChangeLearningUnitTag}
                showAddTagsLabel={false}
                allowNewTags={false}
                minHeight="45px"
                placeholder={t('searchTagsHere', { ns: 'contentLibrary' })}
              />
            </FormControl>
          </Stack>
        </Box>
        <Grid item xs={12} sx={lessonsContainer}>
          <ContentIndicators
            isLoading={isLoading}
            totalItems={communityLessons?.length ?? 0}
            noContentMessage={t('noLessonsAvailable', { ns: 'lessons' })}
          />
          {showAssignLessonModal && (
            <AssignLesson
              onClose={handleCloseAssignLessonModal}
              open={showAssignLessonModal}
            />
          )}
          <ContentLibraryVirtualList
            items={communityLessonsToShow}
            learningPlanUnit={'lessons'}
            isLoading={isLoading}
            itemSize={316}
          >
            {({ data, index, style }) => (
              <LessonVirtualRow
                onOpenAssignModal={handleOpenAssignLessonModal}
                onLessonStart={handleLessonStart}
                index={index}
                style={style}
                data={data}
              />
            )}
          </ContentLibraryVirtualList>
          {showAssignLessonModal && (
            <AssignLesson
              onClose={handleCloseAssignLessonModal}
              open={showAssignLessonModal}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

const lessonsContainer: SxProps = {
  paddingTop: '3rem'
};

const formControlContainer: CSSProperties = {
  width: '200px'
};

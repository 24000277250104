import { CSSProperties } from 'react';

export const selectStyleResponsive: CSSProperties = {
  width: '100%',
  height: '45px'
};

export const selectStyle: CSSProperties = {
  width: '200px',
  height: '45px'
};

export const filterSelectStyle: CSSProperties = {
  width: '200px',
  height: '45px'
};

import { MutableRefObject, useMemo, useState } from 'react';
import { CollapsibleDataTable } from '@buildwitt/component-library';
import {
  GridColDef,
  DataGridPremiumProps,
  GridRowSelectionModel
} from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import { Box, LinearProgress } from '@mui/material';

import {
  getAttachmentsColumns,
  getCertificationName
} from './ColumnDefinitions';
import { MyCertification } from '../../../../entities/Certification';
import { PagedResult } from '../../../../entities/Common';
import SkeletonLoadingOverlay from '../../SkeletonLoadingOverlay';

interface EditEmployeeCertificationsTableProps {
  myCertifications?: PagedResult<MyCertification> | null;
  fileProcessInProgress: boolean;
  page: MutableRefObject<number>;
  selectedCertifications: GridRowSelectionModel;
  setSelectedCertifications: (
    selectedCertifications: GridRowSelectionModel
  ) => void;
  onFetchCertifications: () => void;
  onFileAdded: (
    myCertification: MyCertification
  ) => (fileName: string, file: File) => void;
  onFileRemoved: (fileId: string) => void;
  onFileClick: (url: string) => void;
}

const EditEmployeeCertificationsTable = ({
  myCertifications,
  fileProcessInProgress,
  page,
  selectedCertifications,
  setSelectedCertifications,
  onFetchCertifications,
  onFileAdded,
  onFileRemoved,
  onFileClick
}: EditEmployeeCertificationsTableProps) => {
  const [loadingMoreCertifications, setLoadingMoreCertifications] =
    useState<boolean>(true);
  const { t } = useTranslation(['certifications']);
  const certifications = useMemo(() => {
    if (myCertifications) {
      setLoadingMoreCertifications(false);
      return myCertifications;
    }
  }, [myCertifications]);

  const columns: GridColDef[] = [
    getCertificationName(t('certificationName')),
    getAttachmentsColumns(
      t('attachedFiles'),
      fileProcessInProgress,
      t('upload'),
      onFileAdded,
      onFileRemoved,
      onFileClick
    )
  ];

  const handleLoadFetchCertifications = () => {
    page.current += 1;
    onFetchCertifications();
  };

  const handleOnRowsScrollEnd: DataGridPremiumProps['onRowsScrollEnd'] = () => {
    if (certifications?.hasNextPage && !loadingMoreCertifications) {
      setLoadingMoreCertifications(true);
      handleLoadFetchCertifications();
    }
  };

  const handleCertificationSelection = (
    rowSelectionModel: GridRowSelectionModel
  ) => {
    setSelectedCertifications(rowSelectionModel);
  };

  return (
    <Box
      sx={{
        height: '500px',
        width: '100%'
      }}
    >
      <CollapsibleDataTable
        getRowId={row => row.employeeCertificationId}
        columns={columns}
        rows={certifications?.pageItems ?? []}
        loading={!certifications || loadingMoreCertifications}
        disableColumnMenu={true}
        pagination={false}
        checkboxSelection
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        hideFooter
        onRowsScrollEnd={handleOnRowsScrollEnd}
        rowSelectionModel={selectedCertifications}
        onRowSelectionModelChange={handleCertificationSelection}
        slots={{
          loadingOverlay: !certifications
            ? SkeletonLoadingOverlay
            : LinearProgress
        }}
        sx={{
          '& .Mui-selected': {
            backgroundColor: '#000 !important'
          }
        }}
      />
    </Box>
  );
};

export default EditEmployeeCertificationsTable;

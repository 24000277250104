import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { Answer, AssessmentResult } from '../../entities/Assessment';
import { ApplicationState } from '../../store';
import { buildAnswer } from './takeQuizHelpers';
import { actionCreators as lessonActions } from '../../actions/lessons';
import { actionCreators as coursesActions } from '../../actions/courses';
import { actionCreators as assessmentActions } from '../../actions/assessments';
import { actionCreators as actionStudent } from '../../actions/student';
import { Lesson } from '../../entities/Lesson';
import { useLogEvent } from '../../hooks/useLogEvent';
import { CoursePlayAssignment } from '../../entities/Assignment';
import { CompleteUnit } from '../../core/enums';
import { useAppDispatch } from '../../store/hooks';

export const useQuiz = (
  origin?: string,
  assignmentId?: string,
  courseContext?: CoursePlayAssignment
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logEvent = useLogEvent();
  const location = useLocation();
  const locationState = location.state as {
    courseId: string;
    revisionNumber: number;
    currentLesson: Lesson;
    isFeedback?: boolean;
    results?: AssessmentResult;
    backPath: string;
    isInPlayMode: boolean;
    courseCompletedWithQuiz?: boolean;
    isPreviewMode?: boolean;
    assignmentId?: string;
    courseContext?: CoursePlayAssignment;
  };
  const [isFeedback, setIsFeedback] = useState(false);
  const [percentageToPass, setPercentageToPass] = useState<number>(0);
  const currentLesson = useSelector(
    (state: ApplicationState) => state.lessons?.currentLesson
  );
  const lessons = useSelector(
    (state: ApplicationState) => state.lessons?.lessons
  );
  const completeLessons = useSelector(
    (state: ApplicationState) => state.student?.completedLessons
  );

  const [quizResult, setQuizResult] = useState<AssessmentResult>({
    assessmentId: 'string',
    answers: []
  });

  const [isMarkingCompleteInProgress, setIsMarkingCompleteInProgress] =
    useState(false);

  useEffect(() => {
    if (locationState?.currentLesson) {
      dispatch(lessonActions.setCurrentLesson(locationState.currentLesson));
      setIsFeedback(locationState.isFeedback ?? false);
    }
  }, [locationState]);

  useEffect(() => {
    if (currentLesson) {
      const percentage = Math.floor(
        (currentLesson.correctAnswersToPass! * 100) /
          currentLesson.questions!.length
      );
      setPercentageToPass(percentage);

      const newAnswers = currentLesson.questions!.map(q =>
        buildAnswer(q, locationState.results)
      );

      setQuizResult({
        assessmentId: currentLesson.assessmentId!,
        answers: newAnswers,
        passed: locationState.results?.passed ?? undefined,
        grade: locationState.results?.grade ?? undefined
      });
    }
  }, [currentLesson, location]);

  const saveAnswer = (answer: Omit<Answer, 'type' | 'order'>) => {
    const existingAnswer = quizResult.answers.find(
      a => a.questionId === answer.questionId!
    );

    const newAnswers: Answer[] = [
      ...quizResult.answers.filter(a => a.questionId !== answer.questionId),
      { ...existingAnswer!, ...answer }
    ];

    setQuizResult({
      ...quizResult,
      answers: newAnswers
    });
  };

  const backToPreviousPage = (goToNext = false) => {
    const currIndex = lessons?.findIndex(x => x.id === currentLesson?.id) ?? -1;
    let nextLesson = currentLesson;
    if (
      goToNext &&
      lessons &&
      lessons?.length > currIndex + 1 &&
      currIndex !== -1
    ) {
      nextLesson = lessons[currIndex + 1];
      navigate(locationState.backPath, {
        state: {
          lesson: nextLesson,
          courseCompletedWithQuiz:
            locationState.courseCompletedWithQuiz ?? false,
          isPreviewMode: location?.state?.isPreviewMode
        }
      });
    }
  };

  const isRedirectToLibrary = (goToNext = false) => {
    const currIndex = lessons?.findIndex(x => x.id === currentLesson?.id) ?? -1;
    return !(
      goToNext &&
      lessons &&
      lessons?.length > currIndex + 1 &&
      currIndex !== -1
    );
  };

  const submitQuiz = async () => {
    const correctAnswerCount = quizResult.answers.filter(
      a => a.isCorrect
    ).length;
    const grade = (correctAnswerCount / quizResult.answers.length) * 100;
    const passed = grade >= percentageToPass;
    quizResult.passed = passed;
    quizResult.grade = grade;
    quizResult.assignmentId = assignmentId;

    let courseIsCompleted = false;
    if (locationState.isInPlayMode) {
      await dispatch<Promise<void>>(
        assessmentActions.saveAssessmentResult(quizResult)
      );

      if (quizResult.passed) {
        if (!location?.state?.isPreviewMode) {
          if (assignmentId) {
            const wasUnitComplete = await dispatch<Promise<CompleteUnit>>(
              actionStudent.markLessonAssignmentAsCompleted(assignmentId)
            );

            courseIsCompleted = wasUnitComplete > CompleteUnit.Lesson;
          } else {
            courseIsCompleted = await markLessonAsCompleted(
              locationState.currentLesson.id,
              locationState.courseId,
              locationState.revisionNumber
            );
          }

          if (courseContext) {
            dispatch(
              coursesActions.markLessonAssignmentCompleteLocally(assignmentId!)
            );
          } else {
            dispatch(lessonActions.markLessonAssignmentAsCompleteLocally());
          }
        }
      } else {
        const completedLesson = completeLessons?.find(
          c => c.lessonId === locationState.currentLesson.id
        );

        if (!completedLesson) {
          if (assignmentId) {
            dispatch(
              assessmentActions.markQuizAssignmentAsFailed(
                quizResult.assessmentId
              )
            );
          } else {
            dispatch(
              assessmentActions.markQuizAsFailed(quizResult.assessmentId)
            );
          }
        }
      }
    }

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    navigate('/lesson/quiz/results', {
      state: {
        courseId: locationState.courseId,
        revisionNumber: locationState.revisionNumber,
        currentLesson: locationState.currentLesson,
        results: quizResult,
        isFeedback: true,
        backPath: locationState.backPath,
        isInPlayMode: locationState.isInPlayMode,
        courseCompletedWithQuiz: courseIsCompleted,
        tabOrigin: origin,
        isPreviewMode: location?.state?.isPreviewMode,
        assignmentId: locationState.assignmentId,
        courseContext: locationState.courseContext
      },
      replace: true
    });
  };

  const markLessonAsCompleted = async (
    lessonId: string,
    courseId?: string,
    revisionNumber?: number
  ): Promise<boolean> => {
    setIsMarkingCompleteInProgress(true);
    const wasCourseCompleted = await dispatch<Promise<boolean>>(
      actionStudent.markLessonAsCompleted(lessonId, courseId, revisionNumber)
    );

    setIsMarkingCompleteInProgress(false);

    if (wasCourseCompleted)
      logEvent.logCompleteCourseEvent(courseId, revisionNumber);

    return wasCourseCompleted;
  };

  const onRetry = () => {
    navigate('/lesson/quiz', {
      state: {
        courseId: locationState.courseId,
        revisionNumber: locationState.revisionNumber,
        currentLesson: locationState.currentLesson,
        results: undefined,
        isFeedback: false,
        backPath: locationState.backPath,
        courseCompletedWithQuiz: undefined,
        isInPlayMode: locationState.isInPlayMode,
        assignmentId: locationState.assignmentId,
        courseContext: locationState.courseContext
      },
      replace: true
    });
  };

  return {
    state: {
      currentLesson,
      isFeedback,
      quizResult,
      percentageToPass,
      isMarkingCompleteInProgress
    },
    api: {
      submitQuiz,
      markLessonAsCompleted,
      backToPreviousPage,
      onRetry,
      saveAnswer,
      isRedirectToLibrary
    }
  };
};

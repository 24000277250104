import { CSSProperties, useRef, useState } from 'react';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import {
  IconButton,
  Stack,
  Typography,
  CircularProgress,
  Tooltip,
  Box
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';
import {
  GridColDef,
  GridRenderCellParams,
  GridValidRowModel
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { uploadSkillAttachmentToBlob } from '../../services/blobStorage-service';
import { enqueueSnackbar } from 'notistack';
import { actionCreators } from '../../actions/achievements';
import { useSelector } from 'react-redux';
import ProfilePicture from '../../assets/profilePicture.png';
import { ApplicationState } from '../../store';
import {
  EmployeeAttachmentPost,
  EmployeeCompetence
} from '../../entities/Achievements';
import { useAppDispatch } from '../../store/hooks';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../components/CustomDataGrid/CustomDataGrid';

interface EmployeeWithSkillContentListProps {
  rows?: GridValidRowModel[] | null;
}

const EmployeeWithSkill = ({ rows }: EmployeeWithSkillContentListProps) => {
  const { t } = useTranslation(['skills']);
  const [selectEmployee, setSelectEmployee] =
    useState<EmployeeCompetence | null>(null);
  const dispatch = useAppDispatch();
  const loadingEmployeeInfo = useSelector(
    (state: ApplicationState) => state?.achievements?.loadingEmployeeInfo
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const getContentNameColumn = (headerName: string): GridColDef => ({
    field: 'name',
    headerName: headerName,
    minWidth: 220,
    sortable: false,
    flex: 1,
    renderHeader: () => (
      <div style={{ paddingLeft: 20 }}>
        <span>{headerName}</span>
      </div>
    ),
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div style={containerCell} className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <Box display="flex" flexDirection="row">
            <img
              alt="employee"
              src={params.row.profileImageUrl || ProfilePicture}
              style={profileImage}
            />
            <div>
              <Typography style={{ fontSize: 14 }}>
                {params.row.firstName} {params.row.lastName}
              </Typography>
              <Typography style={{ fontSize: 12, color: '#999999' }}>
                {params.row.jobTitle}
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    )
  });

  const getAttachmentsColumns = (headerName: string): GridColDef => ({
    field: 'attachments',
    headerName: headerName,
    minWidth: 270,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div
        style={{ flexWrap: 'wrap', gap: 6 }}
        className="d-flex ps-1 pt-2 pb-2"
      >
        {params.row?.attachments?.map((attachment: any) => (
          <div key={attachment.id}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-around"
              sx={{
                width: 110,
                height: 32,
                background: '#484848',
                borderRadius: 16
              }}
            >
              <Tooltip title={attachment.competenceFile.name}>
                <span style={{ color: 'white', fontSize: 14, marginLeft: 12 }}>
                  {attachment.competenceFile.name.substring(0, 7) + '...'}
                </span>
              </Tooltip>
              <a
                href={attachment.competenceFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton aria-label="download" size="large" color="info">
                  <DownloadIcon fontSize="small" sx={{ color: '#fff' }} />
                </IconButton>
              </a>
            </Box>
          </div>
        ))}
      </div>
    )
  });

  const getValidTillColumn = (headerName: string): GridColDef => ({
    field: 'validTill',
    headerName: headerName,
    minWidth: 110,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <div style={containerCell} className="d-flex ps-1 pt-2 pb-2">
        <div className="d-flex flex-column px-3">
          <span style={{ fontSize: 14, color: '#FFFFFF' }}>
            {params.row.expirationDate
              ? moment(params.row.expirationDate).format('MM.DD.YY')
              : t('neverExpire', { ns: 'skills' })}
          </span>
        </div>
      </div>
    )
  });

  const getRemoveColumn = (headerName: string): GridColDef => ({
    field: 'remove',
    headerName: headerName,
    minWidth: 70,
    sortable: false,
    flex: 1,
    align: 'center',
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Stack alignSelf="flex-start" paddingTop={1}>
        <IconButton
          aria-label="remove"
          size="large"
          color="info"
          style={circularButton}
          onClick={() =>
            dispatch(actionCreators.deleteEmployeeFromCompetence(params.row.id))
          }
        >
          <DeleteRoundedIcon fontSize="medium" sx={{ color: '#1B1C1C' }} />
        </IconButton>
      </Stack>
    )
  });

  const upload = (employee: EmployeeCompetence) => {
    fileInputRef?.current?.click();
    setSelectEmployee(employee);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target?.files?.[0];

    if (!file || !selectEmployee) {
      return;
    }

    const fileName = file.name;
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    const fileType = file.type;

    try {
      const url = await uploadSkillAttachmentToBlob(file);
      const data: EmployeeAttachmentPost = {
        employeeCompeteceId: selectEmployee.id,
        employeeId: selectEmployee.employeeId,
        attachment: {
          fileExtension,
          fileType,
          url,
          name: fileName
        }
      };

      if (url) {
        dispatch(actionCreators.createEmployeeAttachment(data));
      }
    } catch (e) {
      enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
        variant: 'error',
        autoHideDuration: 3000
      });
    }
  };
  const getAttachFileColumn = (headerName: string): GridColDef => ({
    field: 'attachFile',
    headerName: headerName,
    minWidth: 100,
    sortable: false,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any, string>) => (
      <Stack alignSelf="flex-start" paddingTop={1}>
        <IconButton
          aria-label="remove"
          size="large"
          color="info"
          style={circularButton}
          onClick={() => upload(params.row)}
        >
          <UploadFileIcon fontSize="medium" sx={{ color: '#1B1C1C' }} />
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={e => handleFileChange(e)}
        />
      </Stack>
    )
  });

  const columns: GridColDef[] = (() => [
    getContentNameColumn(t('employee', { ns: 'skills' })),
    getAttachmentsColumns(t('attachments', { ns: 'skills' })),
    getAttachFileColumn(t('attachFile', { ns: 'skills' })),
    getValidTillColumn(t('validTill', { ns: 'skills' })),
    getRemoveColumn(t('remove', { ns: 'skills' }))
  ])();

  return (
    <Box
      sx={{
        height: '60vh',
        width: '100%'
      }}
    >
      {(!rows || loadingEmployeeInfo) && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {rows && !!rows.length && (
        <ModifiedCustomDataGrid
          getRowId={row => row.id}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50
              }
            }
          }}
          pageSizeOptions={[50]}
          getRowClassName={getCustomRowClassName}
          getRowHeight={() => 'auto'}
          rowSelection={false}
          disableColumnMenu
        />
      )}
    </Box>
  );
};

export const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  fontSize: '16px',
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-cell .d-flex.ps-1.pt-2.pb-2': {
    position: 'relative !important'
  },
  '& .MuiDataGrid-cell[data-field="name"], .MuiDataGrid-cell[data-field="validTill"]':
    {
      flexDirection: 'column !important'
    }
}));

const circularButton: CSSProperties = {
  backgroundColor: '#C4C4C4',
  width: '35px',
  height: '35px'
};

const profileImage: CSSProperties = {
  borderRadius: 50,
  width: 40,
  height: 40,
  marginRight: 10
};

const containerCell: CSSProperties = {
  position: 'absolute',
  top: 0
};

export default EmployeeWithSkill;

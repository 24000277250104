import useMediaQuery from '@mui/material/useMediaQuery';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { TypeOptions, TypeSpanishOptions } from '../../../core/constants';
import { selectStyle, selectStyleResponsive } from './filtersStyles';
interface LearningUnitTypeFilterProps {
  value: string;
  onChange: (items: string[]) => void;
}
const LearningUnitTypeFilter: React.FC<LearningUnitTypeFilterProps> = ({
  value,
  onChange
}) => {
  const userLang = navigator.language.substring(0, 2);
  const screenXsSizeSmall = useMediaQuery('(max-width:640px)');

  const TypeESENOptions = userLang === 'es' ? TypeSpanishOptions : TypeOptions;

  return (
    <BasicSelect
      labelId="filterByType"
      id="filterByType"
      options={TypeESENOptions}
      defaultValue=""
      value={[value]}
      handleOnChange={onChange}
      style={screenXsSizeSmall ? selectStyleResponsive : selectStyle}
      theme="dark"
      multiple={false}
      sx={{ backgroundColor: 'transparent' }}
    />
  );
};

export default LearningUnitTypeFilter;

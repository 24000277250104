import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/notificationPreferences';
import {
  NOTIFICATION_PREFERENCES_SAVED,
  NOTIFICATION_PREFERENCES_SET
} from '../actions/actionTypes';
import { NotificationPreferences } from '../entities/NotificationPreferences';
import { TransactionStatusEnum } from '../core/enums';

export interface PreferencesState {
  notificationPreferences: NotificationPreferences;
  notificationPreferencesSaved: TransactionStatusEnum;
}

const unloadedState: PreferencesState = {
  notificationPreferences: {
    sendSms: false,
    sendEmail: false,
    sendPushNotification: false,
    sendWeb: false,
    receiveNewAssignmentsNotification: false,
    receiveOverdueAssignmentsNotification: false,
    receivePendingWorkloadNotification: false,
    receiveCompleteLpNotification: false,
    receiveCompleteCourseNotification: false,
    receiveCompleteLessonNotification: false,
    receiveOverdueLpNotification: false,
    receiveOverdueCourseNotification: false,
    receiveOverdueLessonNotification: false,
    receiveInactiveUserNotification: false,
    employeeId: '',
    receiveAwardedUserNotification: false
  },
  notificationPreferencesSaved: TransactionStatusEnum.None
};

export const PreferencesReducer: Reducer<PreferencesState> = (
  state: PreferencesState | undefined,
  incomingAction: Action
): PreferencesState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case NOTIFICATION_PREFERENCES_SET:
      return { ...state, notificationPreferences: action.preferences };
    case NOTIFICATION_PREFERENCES_SAVED:
      return {
        ...state,
        notificationPreferencesSaved: action.notificationPreferencesSaved
      };
    default:
      return state;
  }
};

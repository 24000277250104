import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';
import { ContentOptions, ContentSpanishOptions } from '../../../core/constants';
import { selectStyle, selectStyleResponsive } from './filtersStyles';
import createSvgIcon from '@mui/material/utils/createSvgIcon';
import SvgIcon from '@mui/material/SvgIcon';
import { CSSProperties } from 'react';
import { useBwBreakpoints } from '../../../hooks/useBwBreakpoints';
interface ContentStatusFilterProps {
  value: string;
  onChange: (items: string[]) => void;
  style?: CSSProperties;
}
const ContentStatusFilter: React.FC<ContentStatusFilterProps> = ({
  value,
  onChange,
  style
}) => {
  const userLang = navigator.language.substring(0, 2);
  const [is640] = useBwBreakpoints();

  const ContentFilterOptions =
    userLang === 'es' ? ContentSpanishOptions : ContentOptions;

  const finalStyle = style
    ? style
    : is640
      ? selectStyleResponsive
      : selectStyle;

  return (
    <BasicSelect
      labelId="contentBy"
      id="contentBy"
      options={ContentFilterOptions}
      defaultValue=""
      value={[value]}
      handleOnChange={onChange}
      style={finalStyle}
      theme="dark"
      leftIcon={<SvgIcon component={FilterIcon} />}
      multiple={false}
      sx={{ backgroundColor: 'transparent' }}
    />
  );
};

export default ContentStatusFilter;

export const FilterIcon = createSvgIcon(
  <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path
        id="Vector"
        d="M0.250056 1.61C2.27006 4.2 6.00006 9 6.00006 9V15C6.00006 15.55 6.45006 16 7.00006 16H9.00006C9.55006 16 10.0001 15.55 10.0001 15V9C10.0001 9 13.7201 4.2 15.7401 1.61C16.2501 0.95 15.7801 0 14.9501 0H1.04006C0.210056 0 -0.259944 0.95 0.250056 1.61Z"
        fill="#9B9B9B"
      />
    </g>
  </svg>,
  'FilterIcon'
);

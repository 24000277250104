import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AiccCourse } from '../../entities/Course';
import BasicButton from '../core/BasicButton/BasicButton';
import ProtectedComponent from '../core/ProtectedComponent/ProtectedComponent';
import { appPermissions } from '../../core/constants';

interface ClickSafetyCourseCardActionsProps {
  course: AiccCourse;
  openAssignmentModal: (id?: string) => void;
  setCourse: (course: AiccCourse) => void;
}

const ClickSafetyCourseCardActions: React.FC<
  ClickSafetyCourseCardActionsProps
> = ({
  course,
  openAssignmentModal,
  setCourse
}: ClickSafetyCourseCardActionsProps) => {
  const { t } = useTranslation(['common', 'contentLibrary']);
  const navigate = useNavigate();
  const redirectToCourse = () => {
    navigate(`/click-safety-course/${course.id}`);
  };

  const handleOpenAssignmentModal = () => {
    openAssignmentModal();
    setCourse(course);
  };

  return (
    <div style={divContainerStyle}>
      <div style={divButtonStyle}>
        <ProtectedComponent action={appPermissions.ASSIGN_COURSE}>
          <BasicButton
            style={buttonStyle}
            width="105px"
            height="35px"
            color="primary"
            onClick={handleOpenAssignmentModal}
          >
            {t('assign', { ns: 'common' })}
          </BasicButton>
        </ProtectedComponent>
        <div style={startButtonContainerStyle}>
          <BasicButton
            style={buttonStyle}
            width="105px"
            height="35px"
            color="grayButton"
            onClick={() => {
              redirectToCourse();
            }}
          >
            {t('start', { ns: 'common' })}
          </BasicButton>
        </div>
      </div>
    </div>
  );
};

const divContainerStyle: CSSProperties = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexWrap: 'wrap'
};

const divButtonStyle: CSSProperties = {
  display: 'flex'
};

const buttonStyle: CSSProperties = {
  minWidth: '0'
};

const startButtonContainerStyle: CSSProperties = {
  paddingLeft: '0.5rem'
};

export default ClickSafetyCourseCardActions;

import { ListChildComponentProps } from 'react-window';
import { Course } from '../../../entities/Course';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { restrictedCourseShouldShow } from '../../../utils/lessonUtils';
import CourseRowWrapper from '../common/CourseRowWrapper';
import { Grid } from '@mui/material';
import React from 'react';

interface CourseVirtualRowProps extends ListChildComponentProps<Course[][]> {
  children: (course: Course) => React.ReactNode;
}

const CourseVirtualRow: React.FC<CourseVirtualRowProps> = ({
  data,
  index,
  style,
  children
}) => {
  const user = useCurrentUser();
  const userRoles = user?.profile['BuildWitt.User.Role'] as string[];

  return (
    <div key={index} style={style}>
      <Grid container spacing={2} id="parentContainer">
        {data[index]
          .filter(x => restrictedCourseShouldShow(x, userRoles))
          .map(course => {
            return (
              <CourseRowWrapper key={course.id}>
                {children(course)}
              </CourseRowWrapper>
            );
          })}
      </Grid>
    </div>
  );
};

export default CourseVirtualRow;

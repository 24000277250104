import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { EmployeeCertification } from '../../../../entities/EmployeeCertification';
import MultipleFilesInput from '../../../../components/core/MultipleFilesInput/MultipleFilesInput';

export const getFilesColumn = (
  headerName: string,
  isProgressing: boolean,
  onFileAgregated: (
    employeeCertification: EmployeeCertification
  ) => (fileName: string, file: File) => void,
  onFileDeleted: (
    employeeCertification: EmployeeCertification
  ) => (index: number) => void
): GridColDef => ({
  field: 'attachmentsDto',
  headerName: headerName,
  minWidth: 300,
  sortable: false,
  flex: 1,
  renderCell: (params: GridRenderCellParams<any, string>) => (
    <div
      style={{ flexWrap: 'wrap', gap: 6, width: '100%' }}
      className="d-flex ps-1 pt-2 pb-2"
    >
      <MultipleFilesInput
        validTypes="*"
        alternativeBehavior={false}
        rowGridDesign={true}
        isWorking={isProgressing}
        dataTestPrefix="certification"
        dataTrackPrefix="certification"
        width={'83%'}
        variant="dark"
        disabled={false}
        onFileAdded={onFileAgregated(params.row)}
        tags={params.row?.attachmentsDto?.map(
          (attachment: any) => attachment.name
        )}
        onRemoveSavedFile={onFileDeleted(params.row)}
      />
    </div>
  )
});

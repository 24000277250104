import { styled } from '@mui/material';

export const GreenSpan = styled('span')(({ theme }) => ({
  color: theme.palette.success.main
}));

export const RedSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main
}));

export const OrangeSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main
}));

export const GreySpan = styled('span')(({ theme }) => ({
  color: theme.palette.grey[100]
}));

import { Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { actionCreators } from '../../actions/lessons';
import { Lesson, LessonUnit } from '../../entities/Lesson';
import { TakenTimesObject } from '../../entities/CompletedLessonInfo';
import ContentLibraryVirtualList from '../ContentLibraryVirtualList/ContentLibraryVirtualList';
import LessonVirtualRow from '../ContentLibraryVirtualList/LessonVirtualRow';
import { useLesson } from '../../pages/lessons/useLesson';
import { useNavigate } from 'react-router-dom';
import AssignLesson from '../../pages/lessons/AssignLesson';
import ContentIndicators from '../../pages/contentLibrary/common/ContentIndicators';
import { useAppDispatch } from '../../store/hooks';

export interface AllLessonsProps {
  lessonUnits: Array<LessonUnit>;
  isLoading: boolean;
  origin: string;
  totalItems: number;
  takenTimesLessons?: TakenTimesObject | null;
}

const AllLessonsNew = ({
  lessonUnits,
  isLoading,
  totalItems
}: AllLessonsProps) => {
  const { t } = useTranslation(['lessons']);
  const {
    api: { handleOpenAssignLessonModal, handleCloseAssignLessonModal },
    state: { showAssignLessonModal }
  } = useLesson(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLessonStart = (lesson: Lesson, isPreviewMode?: boolean) => {
    if (lesson && lesson.isEncodingFailed) {
      alert(t('videoLessonEcodingFailed', { ns: 'lessons' }));
    } else {
      dispatch(actionCreators.setCurrentLessons([lesson]));
      navigate(`/lessons/preview`, {
        state: { lesson, origin, isPreviewMode }
      });
    }
  };

  return (
    <Grid item xs={12} sx={containerStyle}>
      <ContentIndicators
        isLoading={isLoading}
        totalItems={totalItems}
        noContentMessage={t('noLessonsAvailable', { ns: 'lessons' })}
      />
      <ContentLibraryVirtualList
        items={lessonUnits}
        learningPlanUnit={'lessons'}
        isLoading={isLoading}
        itemSize={316}
      >
        {({ data, index, style }) => (
          <LessonVirtualRow
            key={`item-${data[index].map(i => i.lesson.id).join('-')}`}
            index={index}
            style={style}
            data={data}
            onLessonStart={handleLessonStart}
            onOpenAssignModal={handleOpenAssignLessonModal}
          />
        )}
      </ContentLibraryVirtualList>

      {showAssignLessonModal && (
        <AssignLesson
          onClose={handleCloseAssignLessonModal}
          open={showAssignLessonModal}
        />
      )}
    </Grid>
  );
};

const containerStyle: SxProps = {
  paddingTop: '2rem'
};

export default AllLessonsNew;

import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/lessons';
import {
  CommunityLesson,
  EmployeeLessonAssignment,
  Lesson,
  LessonAssignment
} from '../entities/Lesson';
import { TransactionStatusEnum } from '../core/enums';

import {
  LESSON_SET_LESSONS,
  LESSON_SET_EDITING,
  LESSON_BY_ID,
  LESSON_SET_ALL_LESSONS,
  LESSON_SET_ALL_BW_LESSONS,
  LESSONS_SET_ASSESSMENT_QUESTIONS,
  LESSON_UPDATE_TRANSACTION_STATUS,
  LESSON_RESET_TRANSACTION_STATUS,
  LESSON_SET_EMPLOYEE_ASSIGNMENTS,
  LESSONS_SET_QUIZ_QUESTIONS,
  LESSONS_SET_COMMUNITY_LESSONS,
  LESSONS_IS_LOADING,
  LESSONS_SET_ASSIGNMENT_STATUS,
  LESSONS_SET_PARTNERS_LESSONS,
  LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS,
  LESSON_PLAY,
  LESSON_PLAY_MARK_AS_COMPLETE
} from '../actions/actionTypes';
import { Question } from '../entities/Assessment';
import {
  EmployeeAssignmentStatus,
  EmployeeGroupAssignmentDefinition,
  LessonGroupAssignment,
  LessonPlayAssignment
} from '../entities/Assignment';

export interface LessonsState {
  lessons: Lesson[];
  allLessons: Lesson[];
  allBWLessons: Lesson[];
  partnersLessons: Lesson[] | null;
  currentLesson: Lesson | null;
  currentEmployeeLessonAssignments: EmployeeLessonAssignment[];
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  communityLessons: CommunityLesson[] | null;
  isLoading: boolean;
  lessonAssignmentStatus: EmployeeAssignmentStatus[] | null;
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[] | null;
  lessonPlayAssignment: LessonPlayAssignment | null;
}

const unloadedState: LessonsState = {
  lessons: [],
  allLessons: [],
  partnersLessons: [],
  allBWLessons: [],
  currentLesson: null,
  currentEmployeeLessonAssignments: [],
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  communityLessons: null,
  isLoading: false,
  lessonAssignmentStatus: null,
  groupAssignmentDefinitions: null,
  lessonPlayAssignment: null
};

export const newLessonAssignment: LessonAssignment = {
  lessonId: '',
  employeeIds: [],
  applyToAll: false
};

export const newLessonGroupAssignment: LessonGroupAssignment = {
  lessonId: '',
  groups: [],
  applyToAll: false
};

export const LessonsReducer: Reducer<LessonsState> = (
  state: LessonsState | undefined,
  incomingAction: Action
): LessonsState => {
  if (!state) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case LESSON_SET_LESSONS:
      return {
        ...state,
        lessons: action.lessons
      };
    case LESSON_SET_EDITING:
      return { ...state, currentLesson: action.lesson };
    case LESSON_BY_ID:
      return { ...state, currentLesson: action.lesson };
    case LESSON_SET_ALL_LESSONS:
      return {
        ...state,
        allLessons: action.allLessons
      };
    case LESSON_SET_ALL_BW_LESSONS:
      return {
        ...state,
        allBWLessons: action.allBWLessons
      };
    case LESSONS_SET_PARTNERS_LESSONS:
      return {
        ...state,
        partnersLessons: action.partnersLessons
      };
    case LESSON_SET_EMPLOYEE_ASSIGNMENTS:
      return {
        ...state,
        currentEmployeeLessonAssignments: action.employeeLessonAssigment
      };
    case LESSONS_SET_ASSESSMENT_QUESTIONS: {
      const mappedQuestions: Question[] = action.questions.map(q => {
        return {
          id: q.id,
          questionText: q.questionText,
          type: q.type,
          trueIsCorrectAnswer: q.trueIsCorrectAnswer,
          options: q.options,
          imageUrl: q.imageUrl,
          imageFile: q.imageFile,
          numberCorrectAnswer: q.numberCorrectAnswer
        };
      });

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson!,
          questions: mappedQuestions,
          assessmentId: action.assessmentId,
          correctAnswersToPass: action.correctAnswersToPass
        }
      };
    }
    case LESSON_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case LESSON_RESET_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: TransactionStatusEnum.None,
        errorMessage: ''
      };
    case LESSONS_SET_QUIZ_QUESTIONS: {
      const mappedQuizQuestions: Question[] = action.questions.map(q => {
        return {
          id: q.id,
          questionText: q.questionText,
          type: q.type,
          trueIsCorrectAnswer: q.trueIsCorrectAnswer,
          options: q.options,
          imageUrl: q.imageUrl,
          imageFile: q.imageFile,
          numberCorrectAnswer: q.numberCorrectAnswer,
          order: q.order
        };
      });

      return {
        ...state,
        currentLesson: {
          ...state.currentLesson!,
          questions: mappedQuizQuestions,
          correctAnswersToPass: action.correctAnswersToPass
        }
      };
    }
    case LESSONS_SET_COMMUNITY_LESSONS:
      return { ...state, communityLessons: action.communityLessons };
    case LESSONS_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case LESSONS_SET_ASSIGNMENT_STATUS:
      return {
        ...state,
        lessonAssignmentStatus: action.lessonAssignmentStatus
      };
    case LESSONS_SET_GROUP_ASSIGNMENT_DEFINITIONS:
      return {
        ...state,
        groupAssignmentDefinitions: action.groupAssignmentDefinitions
      };
    case LESSON_PLAY:
      return { ...state, lessonPlayAssignment: action.lessonPlayAssignment };
    case LESSON_PLAY_MARK_AS_COMPLETE:
      return {
        ...state,
        lessonPlayAssignment: {
          ...state.lessonPlayAssignment!,
          isComplete: true
        }
      };
    default:
      return state;
  }
};

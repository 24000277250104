import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { useEffect, useState } from 'react';
import { dropDownItem } from '../../entities/Common';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims, UserRoles } from '../../core/constants';
import { actionCreators as employeeActions } from '../../actions/employees';
import { useTranslation } from 'react-i18next';
import useUserRole from '../../hooks/useUserRole';
import { useAppDispatch } from '../../store/hooks';

const useCommonFilters = () => {
  const [userHasRole] = useUserRole();
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const { t } = useTranslation(['common', 'dashboard', 'assignments']);
  const companyId = user?.profile[UserClaims.CompanyId] as string;
  const employeeId = user?.profile[UserClaims.EmployeeId] as string;
  const [supervisorItems, setSupervisorItems] = useState<dropDownItem[]>([]);
  const [groupFilterValue, setGroupFilterValue] = useState<string>('default');
  const [supervisorFilterValue, setSupervisorFilterValue] =
    useState<string>('defaultValue'); // All supervisors by default

  const [groupItems, setGroupItems] = useState<dropDownItem[]>([]);

  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const employeeSupervisors = useSelector(
    (state: ApplicationState) => state?.employees?.employeeSupervisors
  );

  useEffect(() => {
    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(
        employeeActions.requestEmployeeSupervisors(companyId, employeeId)
      );
    } else if (userHasRole(UserRoles.GroupLead)) {
      dispatch(employeeActions.requestEmployeeGroupsByGroupLeadId(employeeId));
    } else if (
      !userHasRole(UserRoles.CompanyAdmin) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeeSupervisors(companyId, employeeId)
      );
    }
  }, []);

  useEffect(() => {
    if (employeeGroups) {
      const groups: dropDownItem[] = [
        {
          label: t('filterByGroup', { ns: 'dashboard' }),
          value: 'default',
          disabled: true,
          hidden: true
        }
      ];
      groups.push({ label: t('viewAll', { ns: 'dashboard' }), value: '-' });
      employeeGroups.map(group => {
        groups.push({ label: group.name, value: group.id });
      });
      setGroupItems(groups);
    }
  }, [employeeGroups]);

  useEffect(() => {
    if (employeeSupervisors) {
      const supervisors: dropDownItem[] = [
        {
          label: t('filterBySupervisor', { ns: 'dashboard' }),
          value: 'defaultValue',
          disabled: true,
          hidden: true
        }
      ];
      employeeSupervisors.map(emp => {
        supervisors.push({
          label: `${emp.firstName} ${emp.lastName}`,
          value: emp.id
        });
      });
      setSupervisorItems(supervisors);
    }
  }, [employeeSupervisors]);

  const handleChangeGroup = (items: string[]) => {
    setGroupFilterValue(items[0]);
    setSupervisorFilterValue('defaultValue');
  };
  const handleChangesupervisor = (items: string[]) => {
    setSupervisorFilterValue(items[0]);
    setGroupFilterValue('default');
  };

  const makeFilterValueEmptyIfDefault = (filterValue: string) => {
    return filterValue.toLowerCase().includes('default') ? '' : filterValue;
  };

  return {
    supervisorItems,
    supervisorFilterValue,
    groupFilterValue,
    groupItems,
    handleChangeGroup,
    handleChangesupervisor,
    makeFilterValueEmptyIfDefault
  };
};

export default useCommonFilters;

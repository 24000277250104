import axios from 'axios';
import { User } from 'oidc-client-ts';
import config from './config';

export function getUser() {
  const oidcStorage = sessionStorage.getItem(
    `oidc.user:${config.AUTHORITY}:${config.CLIENT_ID}`
  );

  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const user = getUser();
    const token = user?.access_token;
    config.headers = config.headers ?? {};
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

import { Box, IconButton, Typography, styled } from '@mui/material';
import { CSSProperties, useState } from 'react';
import useUserRole from '../../../hooks/useUserRole';
import { useLocation, useNavigate } from 'react-router';
import { UserRoles } from '../../../core/constants';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import EditIcon from '@mui/icons-material/Edit';
import cloneIcon from '../../../assets/icons/clone-black.svg';
import { useTranslation } from 'react-i18next';
import ModalForCloning from '../cloneOnsiteTraining/ModalForCloning';
const StyledTypography = styled(Typography)(() => ({
  '&.MuiTypography-root:hover': {
    cursor: 'pointer'
  }
}));

const StyledBox = styled(Box)(() => ({
  '&.MuiBox-root:hover': {
    cursor: 'pointer'
  },
  paddingLeft: '10px',
  background: '#C4C4C4',
  borderRadius: 22,
  padding: '0 8px',
  display: 'flex',
  alignItems: 'center'
}));

export const OnsiteTrainingPreviewDetailsNameActions = ({
  onsiteTraining
}: {
  onsiteTraining: OnsiteTraining;
}) => {
  const { t } = useTranslation(['onsiteTraining', 'common']);
  const [showModalToClone, setShowModalToClone] = useState<boolean>(false);
  const [userHasRole] = useUserRole();
  const navigate = useNavigate();
  const location = useLocation();
  const handleOpenCloneModal = () => {
    if (userHasRole(UserRoles.CompanyAdmin)) {
      setShowModalToClone(true);
    }
  };

  const handleOpenEditForm = () => {
    if (userHasRole(UserRoles.CompanyAdmin)) {
      navigate('/onsite-training/edit', {
        state: {
          onsiteTraining: onsiteTraining,
          prevPath: location.pathname
        }
      });
    }
  };
  return (
    <Box display="flex" gap={1}>
      <StyledBox onClick={handleOpenCloneModal}>
        <StyledTypography fontSize={12} color={'black'}>
          {t('clone', { ns: 'common' })}
        </StyledTypography>
        <IconButton>
          <img alt="clone" src={cloneIcon} style={cloneIconStyle} />
        </IconButton>
      </StyledBox>
      <StyledBox onClick={handleOpenEditForm}>
        <StyledTypography fontSize={12} color={'black'}>
          {t('edit', { ns: 'common' })}
        </StyledTypography>
        <IconButton>
          <EditIcon style={editIconStyle} />
        </IconButton>
      </StyledBox>
      <ModalForCloning
        showModal={showModalToClone}
        onsiteTraining={onsiteTraining}
        onCloseModal={() => {
          setShowModalToClone(false);
        }}
      />
    </Box>
  );
};

const editIconStyle: CSSProperties = {
  color: 'black',
  fontSize: 14
};

const cloneIconStyle: CSSProperties = {
  width: '10px',
  height: '10px'
};

import React, { CSSProperties } from 'react';
import { Grid, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';

export interface InputFileUploadProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  initialText: string;
  thumbnailUrl: string;
  acceptedTypes?: string;
  selectedFileName: string;
  setSelectedFileName: (fileName: string) => void;
  onRemoveFile?: () => void;
}

const uploadContainerStyle: CSSProperties = {
  display: 'inline-block',
  padding: '10px 60px',
  cursor: 'pointer',
  margin: '20px',
  borderRadius: '20px',
  minWidth: '240px'
};

const hideInputFileStyle: CSSProperties = { display: 'none' };

const inputContainerStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center'
};

const indicationStyle: CSSProperties = { paddingBottom: '30px' };

export const InputFileUpload: React.FC<InputFileUploadProps> = ({
  onChange,
  initialText,
  acceptedTypes = '*.*',
  selectedFileName,
  setSelectedFileName,
  onRemoveFile
}: InputFileUploadProps) => {
  const handleFileChange = (e: any) => {
    const parts = e.target.value.split('\\');
    const fileName = parts[parts.length - 1];
    const extension = fileName.split('.').pop();
    const cutFileName =
      fileName.length > 30
        ? fileName.substring(0, 31).split('.')[0] + '...' + extension
        : fileName;
    setSelectedFileName(cutFileName);
    onChange(e);
  };

  const handleRemoveFile = (event: React.MouseEvent<HTMLInputElement>) => {
    setSelectedFileName('');

    if (onRemoveFile) {
      onRemoveFile();
    }
  };

  const theme = useTheme();

  const primaryStyle: CSSProperties = {
    background: theme.palette.primary.main,
    color: theme.palette.common.black
  };

  const finalStyle = {
    ...uploadContainerStyle,
    ...primaryStyle,
    maxHeight: '45px'
  };
  const removeButtonStyle = {
    ...uploadContainerStyle,
    background: theme.palette.grey[50],
    color: theme.palette.common.black
  };

  return (
    <div style={inputContainerStyle}>
      {!!selectedFileName.length && (
        <Chip
          data-testid="thumbnailLibrarySelectedThumbnailFileName"
          data-trackid="thumbnailLibrarySelectedThumbnailFileName"
          label={selectedFileName}
          color="primary"
          sx={{ color: theme.palette.common.black }}
        />
      )}
      {(!selectedFileName.length || !onRemoveFile) && (
        <Grid id="add" sx={{ marginLeft: { xs: '-15px' } }}>
          <label style={finalStyle}>
            <input
              type="file"
              style={hideInputFileStyle}
              onChange={handleFileChange}
              accept={acceptedTypes}
              data-testid="thumbnailLibraryThumbnailFileInput"
              data-trackid="thumbnailLibraryThumbnailFileInput"
            />
            <p style={{ maxHeight: '45px', minWidth: '120px' }}>
              Upload an Image
            </p>
          </label>
        </Grid>
      )}
      {!!selectedFileName.length && onRemoveFile && (
        <label style={removeButtonStyle}>
          <input
            type="button"
            style={hideInputFileStyle}
            onClick={handleRemoveFile}
            data-testid="thumbnailLibraryDeleteThumbnailButton"
            data-trackid="thumbnailLibraryDeleteThumbnailButton"
          />{' '}
          Remove Image
        </label>
      )}
      <p style={indicationStyle}>
        Color photos work best for Learning Plans and Lessons. <br /> Black &
        White photos are recommended for Courses.
      </p>
    </div>
  );
};

export default InputFileUpload;

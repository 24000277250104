import { useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';

export interface ButtonMenuItem {
  component: React.ReactNode;
  value: any;
}

export interface MenuButtonProps {
  id: string;
  buttonText: string;
  menuItems: Array<ButtonMenuItem>;
  onClickOption: (option: any) => void;
  height?: string;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

const MenuButton: React.FC<MenuButtonProps> = ({
  id,
  buttonText,
  height = '45px',
  menuItems,
  onClickOption
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: any) => {
    setAnchorEl(null);
    if (onClickOption) {
      onClickOption(value);
    }
  };

  return (
    <>
      <Button
        id={id}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          height: height,
          borderRadius: '10px',
          color: '#0E0D0D',
          textTransform: 'none'
        }}
      >
        {buttonText}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuItems.map((item: ButtonMenuItem, index: number) => {
          const { component: Component, value } = item;

          return (
            <MenuItem
              key={`menu-item-${index}`}
              onClick={() => handleClose(value)}
              disableRipple
            >
              {Component}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default MenuButton;

import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import { EmployeeGroup } from '../../entities/Employee';
import {
  EmployeeGroupAssignmentDefinition,
  EmployeeGroupInfo
} from '../../entities/Assignment';
import ChipGroupAssignmentInfo from './ChipGroupAssignmentInfo';
import { GroupTypeList } from '../../core/constants';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.grey[500],
    fontSize: 14,
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    borderWidth: '1px',
    borderColor: '#2D2D2D'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '& td:first-child': {
    borderRight: 0,
    paddingRight: 0
  },
  '& td:nth-child(2)': {
    borderLeft: 0
  }
}));

export interface GroupListProps {
  employeeGroups: EmployeeGroup[];
  groupAssignmentDefinitions: EmployeeGroupAssignmentDefinition[];
  assignedGroups: EmployeeGroupInfo[];
  onGroupSelection: (isChecked: boolean, groupId: string) => void;
}

const GroupList: React.FC<GroupListProps> = ({
  employeeGroups,
  groupAssignmentDefinitions,
  assignedGroups,
  onGroupSelection
}) => {
  const { t } = useTranslation(['assignments']);

  const handleChangeAssign = (e: React.ChangeEvent<HTMLInputElement>) => {
    onGroupSelection(e.currentTarget.checked, e.currentTarget.id);
  };

  return (
    <TableContainer
      component={Paper}
      className="card-container me-2-half"
      sx={containerStyle}
    >
      <Table stickyHeader sx={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell colSpan={2}>
              {t('groupName', { ns: 'assignments' })}
            </StyledTableCell>
            <StyledTableCell>
              {t('NumberOfMembers', { ns: 'assignments' })}
            </StyledTableCell>
            <StyledTableCell>
              {t('groupType', { ns: 'assignments' })}
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employeeGroups.map((group: EmployeeGroup) => {
            const assignmentDefinition = groupAssignmentDefinitions.find(
              definition => group.id === definition.employeeGroupId
            );
            const isRecentlyAssigned = assignedGroups.some(
              assignedGroup => assignedGroup.groupId === group.id
            );

            return (
              <StyledTableRow
                key={group.id}
                sx={theme => ({
                  '&.MuiTableRow-root': {
                    backgroundColor: isRecentlyAssigned
                      ? theme.palette.secondary.main
                      : theme.palette.common.black
                  }
                })}
              >
                <StyledTableCell align="left" width={50}>
                  <Checkbox
                    id={group.id}
                    onChange={handleChangeAssign}
                    disabled={!!assignmentDefinition}
                    checked={isRecentlyAssigned}
                    sx={theme => ({
                      color: theme.palette.primary.main,
                      '&.Mui-disabled': {
                        color: theme.palette.grey[600]
                      }
                    })}
                  />
                </StyledTableCell>
                <StyledTableCell align="left">{group.name}</StyledTableCell>
                <StyledTableCell align="left">
                  {group.employees.length}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {GroupTypeList[group.groupType]}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {!!assignmentDefinition && (
                    <>
                      <ChipGroupAssignmentInfo
                        groupAssignmentDefinition={assignmentDefinition}
                      />
                    </>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const containerStyle: SxProps = {
  width: '100%',
  maxHeight: 300,
  '&.MuiPaper-root': {
    border: 0
  }
};

export default GroupList;

import { useState, useEffect, useReducer, CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Employee } from '../../../entities/Employee';
import MultipleFilesInput from '../../../components/core/MultipleFilesInput/MultipleFilesInput';
import SelectDuration from './SelectDuration';
import {
  AwardDurationType,
  DurationUnit,
  emptyAwardedItem,
  reducer
} from './state';
import { ExpirationDateEditor } from './ExpirationDateEditor';
import { DurationEditor } from './DurationEditor';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { uploadSkillAttachmentToBlob } from '../../../services/blobStorage-service';
import { enqueueSnackbar } from 'notistack';
import { actionCreators } from '../../../actions/achievements';
import { AwardedItems, CompetenceBulk } from '../../../entities/Achievements';
import { Guid } from 'guid-typescript';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import { useAppDispatch } from '../../../store/hooks';
import CustomDataGrid, {
  getCustomRowClassName
} from '../../../components/CustomDataGrid/CustomDataGrid';

interface ListEmployeesAwardSkillProps {
  employeesLearningProfile: Employee[];
  isLoading: boolean;
  skillId: string;
  checkAllEmployees: boolean;
}

const ListEmployeesAwardSkill = ({
  employeesLearningProfile,
  skillId,
  isLoading,
  checkAllEmployees
}: ListEmployeesAwardSkillProps) => {
  const dispatchToSave = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['skills', 'settings']);
  const [dataSource, setDataSource] = useState<Employee[]>([]);
  const [awardedState, dispatch] = useReducer(reducer, {});
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);
  const [isAwarding, setIsAwarding] = useState<boolean>(false);
  useEffect(() => {
    setDataSource(employeesLearningProfile);
  }, [employeesLearningProfile]);

  useEffect(() => {
    if (checkAllEmployees) {
      onCheckAllEmployees(employeesLearningProfile, checkAllEmployees);
    }
  }, [checkAllEmployees]);

  const onCheckEmployee = (employeeId: string, check: boolean) =>
    dispatch({ type: check ? 'add' : 'remove', employeeId: employeeId });

  const onCheckAllEmployees = (employees: Employee[], check: boolean) => {
    employees.forEach(emp => {
      dispatch({
        type: check ? 'add' : 'remove',
        employeeId: emp.id
      });
    });
  };

  const handleAttachFiles = (
    employeeId: string,
    fileName: string,
    file: File
  ) =>
    dispatch({
      type: 'add_attachment',
      employeeId: employeeId,
      fileName: fileName,
      file: file
    });

  const handleRemoveFile = (employeeId: string, index: number) => {
    dispatch({
      type: 'remove_attachment',
      employeeId: employeeId,
      index: index
    });
  };

  const onDurationChange = (
    employeeId: string,
    awardDurationType: AwardDurationType
  ) =>
    dispatch({
      type: 'update_award_duration_type',
      employeeId: employeeId,
      awardDurationType: awardDurationType
    });

  const onFileAdded = (employeeId: string) => (fileName: string, file: File) =>
    handleAttachFiles(employeeId, fileName, file);

  const onFileRemoved = (employeeId: string) => (index: number) => {
    handleRemoveFile(employeeId, index);
  };

  const handleSaveAward = async () => {
    setIsAwarding(true);
    const payload: CompetenceBulk = {
      awardedItems: [],
      competenceId: skillId
    };

    const keys = Object.keys(awardedState);
    for (let j = 0; j < keys.length; j++) {
      const employeeId = keys[j];
      const awardedItemState = awardedState[employeeId];

      const awaredItem: AwardedItems = {
        employeeId: employeeId,
        awardDurationType: awardedItemState.awardDurationType,
        expirationDate: new Date(awardedItemState.expirationDateIsoString),
        employeeCompetenceFiles: []
      };

      for (
        let i = 0;
        i < awardedState[employeeId].employeeCompetenceFiles.length;
        i++
      ) {
        const file = awardedState[employeeId].employeeCompetenceFiles[i];
        try {
          const blobName = await uploadSkillAttachmentToBlob(file);
          awaredItem.employeeCompetenceFiles!.push({
            id: Guid.create().toString(),
            url: blobName,
            name: file.name,
            fileType: file.type,
            fileExtension: file.name.slice(-4)
          });
        } catch (e) {
          enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
            variant: 'error',
            autoHideDuration: 3000
          });
          return;
        }
      }

      payload.awardedItems.push(awaredItem);
    }

    dispatchToSave(actionCreators.awardBulkCompetence(payload, navigate));
  };

  const columns: GridColDef[] = [
    {
      field: 'employees',
      headerName: t('employee', { ns: 'skills' }),
      minWidth: 400,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const employeeId = params.row.id;
        const isChecked = awardedState[employeeId] !== undefined;
        return (
          <div className="d-flex ps-1 pt-3 pb-2">
            <div className="d-flex flex-column">
              <FormControlLabel
                control={
                  <Checkbox
                    id="selectEmployee"
                    name="selectEmployee"
                    checked={isChecked}
                    onChange={() => onCheckEmployee(employeeId, !isChecked)}
                  />
                }
                label={''}
              />
            </div>
            <img
              className={`img-profile rounded-circle`}
              width="50"
              height="50"
              alt={`${params.row.firstName} ${params.row.lastName}`}
              src={
                params.row.photoUrl && params.row.photoUrl.length
                  ? params.row.photoUrl
                  : require('../../../assets/user.png')
              }
            />
            <div className="d-flex flex-column px-3 pt-1">
              <span
                style={{ fontSize: 14 }}
              >{`${params.row.firstName} ${params.row.lastName}`}</span>
              <span style={{ fontSize: 12, color: '#999999' }}>
                {params.row.email}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      field: 'groupNames',
      headerName: t('group', { ns: 'skills' }),
      minWidth: 200,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string[]>) => (
        <span>
          {params.row.groups
            .map((group: { name: any }) => group.name)
            .join(', ')}
        </span>
      )
    },
    {
      field: 'skillDuration',
      headerName: t('skillDuration', { ns: 'skills' }),
      minWidth: 450,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const employeeId = params.row.id;
        const row = awardedState[employeeId] ?? { ...emptyAwardedItem };
        const isSelected = awardedState[employeeId] !== undefined;
        const handleChangeDuration = (
          durationUnit: DurationUnit,
          durationValue: number
        ) => {
          dispatch({
            type: 'set_date_from_duration',
            durationUnit: durationUnit,
            durationValue: durationValue,
            employeeId: employeeId
          });
        };

        const handleChangeExpirationDate = (date: Date) => {
          dispatch({
            type: 'set_expiration_date',
            date: date,
            employeeId: employeeId
          });
        };
        return (
          <>
            <SelectDuration
              id="selectEmployee"
              labelId="selectEmployee"
              disabled={!isSelected}
              selectValue={row.awardDurationType.toString()}
              onChange={items =>
                onDurationChange(employeeId, parseInt(items[0]))
              }
            />
            {row.awardDurationType === AwardDurationType.Duration && (
              <DurationEditor
                durationUnit={row.durationUnit ?? 'days'}
                durationValue={row.durationValue ?? 1}
                onChangeDurationUnit={(durationUnit: DurationUnit) => {
                  handleChangeDuration(durationUnit, row.durationValue ?? 1);
                }}
                onChangeDurationValue={(durationValue: number) => {
                  handleChangeDuration(
                    row.durationUnit ?? 'days',
                    durationValue
                  );
                }}
              />
            )}
            {row.awardDurationType === AwardDurationType.ExpirationDate && (
              <ExpirationDateEditor
                expirationDate={row.expirationDate ?? new Date()}
                onChangeExpirationDate={(value: Date) => {
                  handleChangeExpirationDate(value);
                }}
              />
            )}
          </>
        );
      }
    },
    {
      field: 'attachFiles',
      headerName: t('attachFiles', { ns: 'skills' }),
      minWidth: 400,
      sortable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        const employeeId = params.row.id;
        const isSelected = awardedState[employeeId] !== undefined;
        const row = awardedState[employeeId] ?? { ...emptyAwardedItem };
        return (
          <div style={{ width: '100%', padding: '20px' }}>
            <MultipleFilesInput
              variant="dark"
              disabled={!isSelected}
              onFileAdded={onFileAdded(employeeId)}
              tags={row.employeeCompetenceFiles.map(file => file.name)}
              onRemoveSavedFile={onFileRemoved(employeeId)}
              validTypes=".pdf,image/*"
              alternativeBehavior={true}
            />
          </div>
        );
      }
    }
  ];

  return (
    <Box
      sx={{
        height: '60vh',
        width: '100%'
      }}
    >
      {dataSource.length > 0 && (
        <ModifiedCustomDataGrid
          getRowId={row => row.id}
          rows={dataSource}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              }
            }
          }}
          pageSizeOptions={[100]}
          rowSelection={false}
          getRowClassName={getCustomRowClassName}
          disableColumnMenu
          getRowHeight={() => 'auto'}
        />
      )}

      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '20px 10px',
          marginBottom: '20px'
        }}
      >
        <BasicButton
          color="primary"
          style={buttonStyle}
          onClick={() => {
            setOpenConfirmationModal(true);
          }}
          disable={!Object.keys(awardedState).length}
        >
          <Typography sx={fontButton}>
            {t('awardSkill', { ns: 'skills' })}
          </Typography>
        </BasicButton>
      </Grid>
      <ConfirmationModal
        show={openConfirmationModal}
        showSave={false}
        showCancel={false}
        maxWidth="xs"
        onCancel={() => {
          setOpenConfirmationModal(false);
        }}
      >
        <Stack spacing={3}>
          <span>{t('awardSkillConfirmation', { ns: 'skills' })}</span>
          <Stack direction="row" spacing={3} sx={{ justifyContent: 'center' }}>
            {isAwarding ? (
              <Grid
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <BasicButton
                  onClick={() => {
                    setOpenConfirmationModal(false);
                  }}
                  color="secondary"
                  style={{ width: '97px' }}
                >
                  {t('cancel', { ns: 'settings' })}
                </BasicButton>
                <BasicButton
                  onClick={handleSaveAward}
                  color="primary"
                  style={{ width: '97px' }}
                >
                  {t('confirmAwardSkill', { ns: 'skills' })}
                </BasicButton>
              </>
            )}
          </Stack>
        </Stack>
      </ConfirmationModal>
    </Box>
  );
};

export const buttonStyle: CSSProperties = {
  maxWidth: '250px',
  width: '150px',
  height: '42px'
};

export const fontButton: CSSProperties = {
  fontSize: '16px',
  color: '#0E0D0D',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px'
};

const ModifiedCustomDataGrid = styled(CustomDataGrid)(({ theme }) => ({
  fontSize: '16px',
  '& .MuiDataGrid-overlay': {
    backgroundColor: theme.palette.secondary.main
  },
  '& .MuiDataGrid-virtualScroller': {
    overflowX: 'auto',
    overflowY: 'auto'
  }
}));

export default ListEmployeesAwardSkill;

import React, { CSSProperties } from 'react';
import { Row, Col } from 'react-bootstrap';
import FormLabel from '../core/FormLabel/FormLabel';
import { styled } from '@mui/system';
import { AnswerStatus, Question2Props, renderFeedback } from './Types';
import redCross from '../../assets/red-cross.png';
import greenCheck from '../../assets/green-check.png';
import { CorrectAnswerNotice } from './CorrectAnswerNotice';
import { useTranslation } from 'react-i18next';
import NumericInput from '../core/NumericInput/NumericInput';

const NumberQuestion2: React.FC<Question2Props<string>> = ({
  questionText,
  showFeedback,
  receivedAnswer,
  correctAnswer,
  answerStatus,
  onAnswerReceived
}) => {
  const { t } = useTranslation(['assessments']);

  const renderStatusIcon = (
    answerStatus: AnswerStatus,
    correctAnswer: number | string,
    receivedAnswer?: number | string
  ) => {
    if (answerStatus !== AnswerStatus.AnswerSubmitted) {
      return null;
    }

    const icon = correctAnswer === receivedAnswer ? greenCheck : redCross;
    return (
      <img
        src={icon}
        alt="status"
        style={{
          marginLeft: '15px',
          marginTop: '15px',
          position: 'absolute' as const
        }}
      />
    );
  };

  let customStyle: CSSProperties = { fontSize: '16px' };

  if (answerStatus === AnswerStatus.AnswerSubmitted) {
    customStyle = { ...customStyle, paddingLeft: '45px', fontWeight: 'bold' };
  }

  return (
    <Row>
      <Col>
        <FormLabel>{t('enterCorrectAnswer', { ns: 'assessments' })}</FormLabel>
        <h5>{questionText}</h5>
        <Row className="mt-3">
          <Col lg={12}>
            <AnswerWrapper
              isAnswerSubmitted={answerStatus === AnswerStatus.AnswerSubmitted}
              isCorrect={receivedAnswer === correctAnswer}
            >
              {renderStatusIcon(answerStatus, correctAnswer, receivedAnswer)}
              <NumericInput
                id="number"
                name="answer"
                value={receivedAnswer?.toString() || ''}
                onChangeValue={value => onAnswerReceived(value.toString())}
                placeholderText={t('writeYourAnswer', { ns: 'assessments' })}
                width="100%"
                height="48px"
                disabled={answerStatus === AnswerStatus.AnswerSubmitted}
                customStyle={customStyle}
                minValue={0}
                maxLength={10}
              />
            </AnswerWrapper>
          </Col>
        </Row>
        <Row className="mb-3 mt-3">
          <Col>
            <CorrectAnswerNotice>
              {receivedAnswer !== undefined &&
                showFeedback &&
                renderFeedback<string>(
                  correctAnswer,
                  correctAnswer.toString(),
                  t('correctAnswer', { ns: 'assessments' }),
                  t('isTheCorrectAnswer', { ns: 'assessments' }),
                  receivedAnswer
                )}
            </CorrectAnswerNotice>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const AnswerWrapper = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'isCorrect' && prop !== 'isAnswerSubmitted'
})<{
  isCorrect: boolean;
  isAnswerSubmitted: boolean;
}>(props => ({
  outlineColor:
    props.isAnswerSubmitted && props.isCorrect
      ? props.theme.palette.success.main
      : props.theme.palette.error.light,
  outlineStyle: props.isAnswerSubmitted ? 'solid' : 'none',
  outlineWidth: props.isAnswerSubmitted ? '2px' : '0px',
  borderRadius: '5px'
}));

export default NumberQuestion2;

import React, { useEffect, useState } from 'react';
import ExampleTheme from './themes/ExampleTheme';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ListItemNode, ListNode } from '@lexical/list';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot, TextNode, $getSelection, $isRangeSelection } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, ParagraphNode } from 'lexical';
import { CustomParagraphNode } from './nodes/CustomParagraphNode';
import './styles.css';
import { CustomTextNode } from './nodes/CustomTextNode';
import { enqueueSnackbar } from 'notistack';

export interface RickTextInputProps {
  value: string;
  placeholderText?: string;
  onChangeValue?: (value: any) => void;
  disabled?: boolean;
  onBlur?: (e: any) => void;
  id?: string;
  dataTestId?: string;
  dataTrackId?: string;
}

const Placeholder = ({ placeholderData }: any) => {
  return <div className="editor-placeholder">{placeholderData}</div>;
};

const editorConfig = {
  namespace: 'FormEditor',
  theme: ExampleTheme,
  onError(error: any) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    LinkNode,
    AutoLinkNode,
    CustomTextNode,
    CustomParagraphNode,
    {
      replace: ParagraphNode,
      with: () => {
        return new CustomParagraphNode();
      }
    },
    {
      replace: TextNode,
      with: (node: TextNode) => {
        return new CustomTextNode(node.__text, node.__key);
      }
    }
  ]
};

const UpdatePlugin = ({ id, name, value, disabled, onBlur }: any) => {
  const [editor] = useLexicalComposerContext();
  const [valueDisplayed, setValueDisplayed] = useState<boolean>(false);
  useEffect(() => {
    if (value && !valueDisplayed) {
      editor.update(() => {
        try {
          const parser = new DOMParser();
          const dom = parser.parseFromString(value, 'text/html');

          const nodes = $generateNodesFromDOM(editor, dom);
          $getRoot().select();
          // Insert them at a selection.
          const selection = $getSelection();
          if (selection && $isRangeSelection(selection)) {
            $getRoot().clear();
            selection.insertNodes(nodes);
          }
        } catch (error) {
          enqueueSnackbar(
            'There was an error while updating the editor. Please try again.',
            {
              variant: 'error',
              autoHideDuration: 3000
            }
          );
        }
      });
      setValueDisplayed(true);
    }
    editor.setEditable(!disabled);
  }, [editor, value, valueDisplayed, disabled]);

  useEffect(() => {
    return editor.registerCommand(
      BLUR_COMMAND,
      payload => {
        const ev = new CustomEvent('BLUR_COMMAND', {
          detail: { id, value }
        });

        if (onBlur) onBlur(ev);
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, id, name, onBlur, value]);

  return null;
};

const RichTextInput: React.FC<RickTextInputProps> = ({
  value,
  placeholderText,
  onChangeValue,
  disabled,
  onBlur,
  id,
  dataTestId = '',
  dataTrackId = ''
}) => {
  const onChange = (editorState: any, editor: any) => {
    editor.update(() => {
      const rawHTML = $generateHtmlFromNodes(editor, null);
      if (onChangeValue) {
        onChangeValue(rawHTML);
      }
    });
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className={!disabled ? 'editor-container' : ''}>
        {!disabled && <ToolbarPlugin />}
        <div className={!disabled ? 'editor-inner' : ''}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                data-trackid={dataTrackId}
                data-testid={dataTestId}
                className={!disabled ? 'editor-input' : ''}
              />
            }
            placeholder={<Placeholder placeholderData={placeholderText} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <ListPlugin />
          <LinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
      </div>
      <OnChangePlugin
        ignoreSelectionChange
        ignoreHistoryMergeTagChange
        onChange={onChange}
      />
      <UpdatePlugin id={id} value={value} disabled={disabled} onBlur={onBlur} />
    </LexicalComposer>
  );
};

export default RichTextInput;

import {
  styled,
  Typography,
  Card,
  Stack,
  CardActions,
  Box,
  StackProps
} from '@mui/material';
import { Pill } from '@buildwitt/component-library';
import { useTranslation } from 'react-i18next';

import { MyCertificationStatus } from '../../../../entities/Certification';
import { lowerCaseFirstLetter } from '../../../../utils/stringUtils';

export const DetailedInfoStyle = styled(Typography)(() => ({
  fontSize: '14px'
}));

export const StyledCard = styled(Card)(() => ({
  width: '100%',
  height: '290px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}));

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '21px',
  fontWeight: 'bold',
  width: 'fit-content',
  paddingBottom: '0.4rem'
}));

export const ItemDetailSection = styled(
  ({ ...props }: Omit<StackProps, 'direction' | 'spacing'>) => (
    <Stack spacing={2} direction="row" {...props} />
  )
)(() => ({}));

export const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: 'space-between',
  padding: '16px'
}));

export const PillContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: '19.5px'
}));

interface CertificationPillProps {
  status: MyCertificationStatus;
}

export const CertificationPill = ({ status }: CertificationPillProps) => {
  const { t } = useTranslation(['certifications']);

  switch (status) {
    case MyCertificationStatus.Expired:
      return <Pill label={t(lowerCaseFirstLetter(status))} color="error" />;
    case MyCertificationStatus.Expiring:
      return <Pill label={t(lowerCaseFirstLetter(status))} color="primary" />;
    case MyCertificationStatus.Pending:
      return <Pill label={t(lowerCaseFirstLetter(status))} color="info" />;
    case MyCertificationStatus.New:
      return <Pill label={t(lowerCaseFirstLetter(status))} color="success" />;
    default:
      return <></>;
  }
};

export const LinkStyle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

import { matchIsValidTel } from 'mui-tel-input';

export const validEmail = (email: string): boolean => {
  const regexp =
    /^[a-zA-Z0-9][-_.+!#$%&'*/=?^`{|]?([a-zA-Z0-9][-_.+!#$%&'*/=?^`{|]?)*[a-zA-Z0-9]@[a-zA-Z0-9](?:[-.][a-zA-Z])*(?:[a-zA-Z0-9](?:[-.][a-zA-Z])*)?[a-zA-Z0-9]$/;
  return regexp.test(email);
};

export const validPhoneNumber = (phoneNumber: string): boolean => {
  const regexp = /^\+\d*$/;

  return regexp.test(phoneNumber);
};

export const validPasswordRules = (password: string) => {
  /* Password Rules => at least 8 characters, one upper case, one lower case, one number and one special character*/
  const regexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*[-@$!%#*?=+.,_\\:;<>|^`'/&]).{8,}/;

  return regexp.test(password);
};

export const validImageFormat = (fileType: string) => {
  const validImageTypes = [
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml'
  ];
  return validImageTypes.includes(fileType);
};

export const isValidURL = (url: string) => {
  const regex = /(^$|(http(s)?:\/\/)([\w-]+\.)+[\w-]+([\w- ;,./?%&=]*))/;
  const res = regex.exec(url);
  return !!res;
};

export const IsValidVideoFormat = (fileType: string) => {
  const validVideoTypes = [
    'video/mp4',
    'video/x-flv',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv'
  ];
  return validVideoTypes.includes(fileType);
};

export const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  return matchIsValidTel(phoneNumber ?? '');
};

export const IsValidAudioFormat = (fileType: string) => {
  const validVideoTypes = [
    'audio/mp3',
    'audio/mpeg',
    'audio/wav',
    'audio/x-wav',
    'audio/x-m4a',
    'audio/ogg'
  ];
  return validVideoTypes.includes(fileType);
};

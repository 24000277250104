import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import FormLabel from '../../../components/core/FormLabel/FormLabel';
import TextBox from '../../../components/core/TextBox/TextBox';
import { Tag } from '../../../entities/Tag';
import { Course } from '../../../entities/Course';
import RichTextInput from '../../core/RichTextInput/RichTextInput';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { TaggingField } from '../../TagInput';

export interface CourseInputsFormProps {
  courseData: Course;
  tags: Array<Tag>;
  companyTags: Array<Tag>;
  setTags: (tags: Array<Tag>) => void;
  setCourseData: (course: Course) => void;
}

const CourseInputsForm: React.FC<CourseInputsFormProps> = ({
  courseData,
  tags,
  companyTags,
  setTags,
  setCourseData
}) => {
  const { t } = useTranslation(['courses', 'common']);
  const [titleError, setTitleError] = useState<boolean>(false);
  const [descriptionError, setDescriptionError] = useState<boolean>(false);
  const [tagError, setTagError] = useState<boolean>(false);

  const handleChangeTitle = (e: string) => {
    if (e) {
      setTitleError(false);
      setCourseData({ ...courseData, tags: tags, title: e });
    } else {
      setTitleError(true);
    }
  };

  const handleChangeDescription = (e: string) => {
    if (e) {
      setCourseData({
        ...courseData,
        tags: tags,
        description: e
      });

      setDescriptionError(false);
    } else {
      setDescriptionError(true);
    }
  };

  const handleCourseCode = (e: string) => {
    setCourseData({
      ...courseData,
      tags: tags,
      courseCode: e.replace(/\s/g, '')
    });
  };

  const handleTagging = (selectedTags: Tag[]) => {
    if (!selectedTags.length) {
      setTagError(true);
    } else if (tagError) {
      setTagError(false);
    }
    setTags(selectedTags);
    setCourseData({ ...courseData, tags: selectedTags });
  };

  return (
    <>
      <Grid item paddingBottom="0.5rem">
        <FormLabel>
          {t('courseName', { ns: 'courses' })}{' '}
          <span className="text-danger">*</span>
        </FormLabel>
        <TextBox
          id="courseName"
          name="courseName"
          value={courseData.title}
          onChangeValue={handleChangeTitle}
          placeholderText={t('exampleName', { ns: 'courses' })}
          width="100%"
          height="41.2px"
          variant="dark"
        />
        {titleError && (
          <span className="text-danger">
            {t('nameIsRequired', { ns: 'common' })}
          </span>
        )}
      </Grid>
      <Grid
        item
        style={{ paddingLeft: '10px !important' }}
        id="ovContainer"
        xs={12}
        md={12}
        lg={12}
      >
        <Stack direction="row" spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                id="visibleForAdminsOnly"
                name="visibleForAdminsOnly"
                data-testid="visibleCheck"
                checked={courseData.visibleForAdminsOnly}
                onChange={(e, checked) => {
                  setCourseData({
                    ...courseData,
                    tags: tags,
                    visibleForAdminsOnly: checked
                  });
                }}
              />
            }
            label={t('hideCourse', { ns: 'courses' })}
          />
        </Stack>
      </Grid>
      <Grid item paddingBottom="0.5rem">
        <FormLabel>
          {t('courseDescription', { ns: 'courses' })}
          <span className="text-danger">*</span>
        </FormLabel>
        <RichTextInput
          value={courseData.description}
          onChangeValue={handleChangeDescription}
          placeholderText={t('writeDescriptionHere', { ns: 'common' })}
        />
        {descriptionError && (
          <span className="text-danger">
            {t('descriptionIsRequired', { ns: 'common' })}
          </span>
        )}
      </Grid>
      <Grid item paddingBottom="0.5rem">
        <TaggingField
          required
          options={companyTags}
          value={tags}
          onChange={handleTagging}
        />
        {tagError && (
          <span className="text-danger">
            {t('oneTagRequired', { ns: 'common' })}
          </span>
        )}
      </Grid>
      <Grid item paddingBottom="0.5rem">
        <FormLabel>{t('courseCode', { ns: 'courses' })} </FormLabel>
        <TextBox
          id="courseCode"
          name="courseCode"
          value={courseData.courseCode}
          onChangeValue={handleCourseCode}
          placeholderText={t('courseCode', { ns: 'courses' })}
          width="100%"
          height="41.2px"
          variant="dark"
          maxLength={30}
        />
      </Grid>
    </>
  );
};

export default CourseInputsForm;

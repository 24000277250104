import { NotificationPreferences } from '../entities/NotificationPreferences';
import {
  NOTIFICATION_PREFERENCES_SAVED,
  NOTIFICATION_PREFERENCES_SET
} from './actionTypes';
import axios from 'axios';
import { AppThunk } from '../store/index';
import config from './../config';
import { HttpStatusEnum, TransactionStatusEnum } from '../core/enums';
import { actionCreators as appActions, SetLoadingAction } from './appState';
import { handleGenericBackendError } from '../utils/errorHandling';

export const actionCreators = {
  getUserPreferences:
    (employeeId: string): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();
      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}notifications/preferences?employeeId=${employeeId}`
          );

          dispatch({
            type: NOTIFICATION_PREFERENCES_SET,
            preferences: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  savePreferences:
    (preferences: NotificationPreferences): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        dispatch(appActions.setIsLoading(true));
        dispatch({
          type: NOTIFICATION_PREFERENCES_SAVED,
          notificationPreferencesSaved: TransactionStatusEnum.None
        });
        try {
          const res = await axios.post(
            `${config.STUDENT_API_URL}notifications/preferences`,
            preferences
          );
          if (res.status !== HttpStatusEnum.OK) {
            dispatch({
              type: NOTIFICATION_PREFERENCES_SET,
              preferences: res.data
            });
            dispatch({
              type: NOTIFICATION_PREFERENCES_SAVED,
              notificationPreferencesSaved: TransactionStatusEnum.Failed
            });
          } else {
            dispatch({
              type: NOTIFICATION_PREFERENCES_SAVED,
              notificationPreferencesSaved: TransactionStatusEnum.Successfull
            });
          }
        } catch (e) {
          dispatch({
            type: NOTIFICATION_PREFERENCES_SAVED,
            notificationPreferencesSaved: TransactionStatusEnum.Failed
          });
          throw e;
        } finally {
          dispatch(appActions.setIsLoading(false));
        }
      }
    }
};

export interface GetUserPreferences {
  type: 'NOTIFICATION_PREFERENCES_SET';
  preferences: NotificationPreferences;
}

export interface SetNotificationPreferencesSaved {
  type: 'NOTIFICATION_PREFERENCES_SAVED';
  notificationPreferencesSaved: TransactionStatusEnum;
}

export type KnownAction =
  | GetUserPreferences
  | SetLoadingAction
  | SetNotificationPreferencesSaved;

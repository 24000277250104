import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import Checkbox from '@mui/material/Checkbox';
import { Avatar, Box, Chip, Stack } from '@mui/material';
import { Employee } from '../../entities/Employee';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.grey[500],
    fontSize: 14,
    border: 0
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.common.white,
    fontSize: 16,
    borderWidth: '1px',
    borderColor: '#2D2D2D'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '& td:first-child': {
    borderRight: 0,
    paddingRight: 0
  },
  '& td:nth-child(2)': {
    borderLeft: '1px solid #2D2D2D'
  }
}));

export interface EmployeesHaltTableProps {
  employees: Employee[];
  setSelectedEmployeeIds: (employeeIds: string[]) => void;
  selectedEmployeeIds: string[];
}

const EmployeesHaltTable: React.FC<EmployeesHaltTableProps> = ({
  employees,
  setSelectedEmployeeIds,
  selectedEmployeeIds
}) => {
  const { t } = useTranslation(['common', 'employees']);
  const handleSelectEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    let updatedSelectedIds: string[];
    if (checked) {
      updatedSelectedIds = [...selectedEmployeeIds, id];
      setSelectedEmployeeIds(updatedSelectedIds);
      return;
    }
    updatedSelectedIds = selectedEmployeeIds.filter(
      existingId => existingId !== id
    );
    setSelectedEmployeeIds(updatedSelectedIds);
  };
  return (
    <TableContainer
      component={Paper}
      className="card-container me-2-half"
      sx={containerStyle}
    >
      <Table stickyHeader sx={{ width: '100%' }}>
        <TableHead>
          <StyledTableCell sx={{ width: '60%' }}>
            {t('employee', { ns: 'employees' })}
          </StyledTableCell>
          <StyledTableCell sx={{ width: '40%' }}>
            {t('role', { ns: 'employees' })}
          </StyledTableCell>
        </TableHead>
        <TableBody>
          {employees.map((employee: Employee) => {
            const isRecentlyAssigned = selectedEmployeeIds.some(
              employeeId => employeeId === employee.id
            );
            return (
              <StyledTableRow
                key={employee.id}
                sx={theme => ({
                  '&.MuiTableRow-root': {
                    backgroundColor: isRecentlyAssigned
                      ? theme.palette.secondary.main
                      : theme.palette.common.black,
                    height: '24px'
                  }
                })}
                hover={true}
              >
                <StyledTableCell align="left">
                  <Stack direction="row" spacing={2}>
                    <Checkbox
                      id={employee.id}
                      onChange={handleSelectEmployee}
                      checked={isRecentlyAssigned}
                    />
                    <Avatar
                      src={employee.photoUrl ?? ''}
                      sx={{ width: '50px', height: '50px' }}
                    />
                    <Stack direction="column">
                      <span>
                        {`${employee.firstName} ${employee.lastName}`}
                      </span>
                      <span style={{ color: '#999999' }}>
                        {employee.email ?? employee.phoneNumber}
                      </span>
                    </Stack>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Chip
                      label={employee.isCompanyAdmin ? 'Company Admin' : 'User'}
                      sx={{ backgroundColor: '#363636', color: 'white' }}
                    />
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const containerStyle: SxProps = {
  width: '100%',
  maxHeight: 400,
  '&.MuiPaper-root': {
    border: 0
  }
};

export default EmployeesHaltTable;

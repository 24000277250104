import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/employees';
import {
  EMPLOYEE_UPDATE_PASSWORD,
  RESET_UPDATE_PASSWORD,
  EMPLOYEE_SET_EMPLOYEES,
  EMPLOYEE_SET_EMPLOYEE_GROUPS,
  EMPLOYEE_SET_EMPLOYEES_WORKLOAD,
  EMPLOYEE_UPDATE_TRANSACTION_STATUS,
  EMPLOYEE_RESET_TRANSACTION_STATUS,
  EMPLOYEE_SET_LEARNING_PROFILE,
  EMPLOYEE_SET_ASSIGNMENTS_STATUS,
  EMPLOYEE_DATA_IS_LOADING,
  EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
  RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE,
  EMPLOYEE_SUPERVISORS,
  EMPLOYEE_SET_ACTIVE_EMPLOYEES
} from '../actions/actionTypes';
import { TransactionStatusEnum } from '../core/enums';
import {
  Employee,
  EmployeeAssignmentsStatus,
  EmployeeGroup,
  EmployeeSupervisor,
  EmployeeWorkload
} from '../entities/Employee';

export interface EmployeesState {
  passwordUpdated: TransactionStatusEnum;
  employeeAssociated: TransactionStatusEnum;
  employees: Employee[];
  employeeGroups: EmployeeGroup[];
  employeesWorkload: EmployeeWorkload[];
  transactionStatus: TransactionStatusEnum;
  errorMessage: string;
  employeesLearningProfile: Employee[];
  employeeAssignmentsStatus: EmployeeAssignmentsStatus[];
  dataIsLoading: boolean;
  employeeSupervisors: EmployeeSupervisor[];
  activeEmployees: Employee[] | null;
}

const unloadedState: EmployeesState = {
  passwordUpdated: TransactionStatusEnum.None,
  employeeAssociated: TransactionStatusEnum.None,
  employees: [],
  employeeGroups: [],
  employeesWorkload: [],
  transactionStatus: TransactionStatusEnum.None,
  errorMessage: '',
  employeesLearningProfile: [],
  employeeAssignmentsStatus: [],
  dataIsLoading: false,
  employeeSupervisors: [],
  activeEmployees: null
};

export const EmployeesReducer: Reducer<EmployeesState> = (
  state: EmployeesState | undefined,
  incomingAction: Action
): EmployeesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case EMPLOYEE_UPDATE_PASSWORD:
      return { ...state, passwordUpdated: action.passwordUpdated };
    case EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE:
      return { ...state, employeeAssociated: action.employeeAssociated };
    case RESET_EMPLOYEE_ASSOCIATE_WITH_COMPANY_INVITE_CODE:
      return {
        ...state,
        employeeAssociated: TransactionStatusEnum.None
      };
    case RESET_UPDATE_PASSWORD:
      return { ...state, passwordUpdated: TransactionStatusEnum.None };
    case EMPLOYEE_SET_EMPLOYEES:
      return { ...state, employees: action.employees };
    case EMPLOYEE_SET_EMPLOYEE_GROUPS:
      return { ...state, employeeGroups: action.employeeGroups };
    case EMPLOYEE_SET_EMPLOYEES_WORKLOAD:
      return { ...state, employeesWorkload: action.employeeWorkload };
    case EMPLOYEE_UPDATE_TRANSACTION_STATUS:
      return {
        ...state,
        transactionStatus: action.transactionStatus,
        errorMessage: action.errorMessage
      };
    case EMPLOYEE_RESET_TRANSACTION_STATUS:
      return { ...state, transactionStatus: TransactionStatusEnum.None };
    case EMPLOYEE_SET_LEARNING_PROFILE:
      return {
        ...state,
        employeesLearningProfile: action.employeesLearningProfile
      };
    case EMPLOYEE_SET_ASSIGNMENTS_STATUS:
      return {
        ...state,
        employeeAssignmentsStatus: action.employeeAssignmentsStatus
      };
    case EMPLOYEE_DATA_IS_LOADING:
      return { ...state, dataIsLoading: action.dataIsLoading };
    case EMPLOYEE_SUPERVISORS:
      return { ...state, employeeSupervisors: action.employeeSupervisors };
    case EMPLOYEE_SET_ACTIVE_EMPLOYEES:
      return { ...state, activeEmployees: action.activeEmployees };
    default:
      return state;
  }
};

import { appPermissions, UserClaims, UserRoles } from '../core/constants';
import useCurrentUser from './useCurrentUser';
import config from '../config';
import { useCertifierRole } from './useCertifierRole';

const permissionMap: { [key: string]: string[] } = {
  [UserRoles.CompanyAdmin]: [],
  [UserRoles.Employee]: [
    appPermissions.PREVIEW_COURSE,
    appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
    appPermissions.VIEW_ASSIGNMENTS,
    appPermissions.VIEW_COURSES,
    appPermissions.VIEW_PROGRESS,
    appPermissions.VIEW_MESSAGES,
    appPermissions.VIEW_SETTINGS,
    appPermissions.VIEW_LEARNING_PLANS,
    appPermissions.VIEW_CONTENT_LIBRARY,
    appPermissions.CREATE_ONLY_PERSONAL_HALTS,
    appPermissions.VIEW_SKILLS
  ],
  [UserRoles.GroupLead]: [
    appPermissions.VIEW_DASHBOARD,
    appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
    appPermissions.VIEW_CONTENT_LIBRARY,
    appPermissions.VIEW_EMPLOYEES,
    appPermissions.VIEW_ANALYTICS,
    appPermissions.VIEW_SETTINGS,
    appPermissions.ASSIGN_LESSON,
    appPermissions.ASSIGN_COURSE,
    appPermissions.ASSIGN_LEARNING_PLANS,
    appPermissions.PREVIEW_COURSE,
    appPermissions.VIEW_ANALYTICS_GROUP_ASSIGNMENTS,
    appPermissions.CREATE_COMPANY_TRAINING_HALTS,
    appPermissions.VIEW_TODAYS_HALTS_TAB,
    appPermissions.VIEW_SKILLS,
    appPermissions.VIEW_ONSITE_TRAINING
  ],
  [UserRoles.Supervisor]: [
    appPermissions.VIEW_DASHBOARD,
    appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
    appPermissions.VIEW_CONTENT_LIBRARY,
    appPermissions.VIEW_EMPLOYEES,
    appPermissions.VIEW_ANALYTICS,
    appPermissions.VIEW_SETTINGS,
    appPermissions.ASSIGN_LESSON,
    appPermissions.ASSIGN_COURSE,
    appPermissions.ASSIGN_LEARNING_PLANS,
    appPermissions.PREVIEW_COURSE,
    appPermissions.CREATE_COMPANY_TRAINING_HALTS,
    appPermissions.VIEW_TODAYS_HALTS_TAB,
    appPermissions.VIEW_SKILLS,
    appPermissions.VIEW_ONSITE_TRAINING
  ],
  [UserRoles.Certifier]: [
    appPermissions.PREVIEW_COURSE,
    appPermissions.VIEW_EMPLOYEE_ASSIGNMENTS,
    appPermissions.VIEW_ASSIGNMENTS,
    appPermissions.VIEW_COURSES,
    appPermissions.VIEW_PROGRESS,
    appPermissions.VIEW_MESSAGES,
    appPermissions.VIEW_SETTINGS,
    appPermissions.VIEW_LEARNING_PLANS,
    appPermissions.VIEW_CONTENT_LIBRARY,
    appPermissions.CREATE_ONLY_PERSONAL_HALTS,
    appPermissions.VIEW_SKILLS,
    appPermissions.VIEW_CERTIFICATIONS
  ]
};

const adminExclusionsMap = [appPermissions.CREATE_ONLY_PERSONAL_HALTS];

const permissionIsInRole = (userRole: string) => (action: string) => {
  // For now, admin user will have access to anything
  if (userRole === UserRoles.CompanyAdmin) {
    return !adminExclusionsMap.includes(action);
  }

  /* workaround to allow access to certifier pages until we fix/refactor permissions overwriting */
  const [userIsAdminOrCertifier] = useCertifierRole();
  if (userIsAdminOrCertifier) {
    permissionMap[userRole].push(appPermissions.VIEW_CERTIFICATIONS);
  }
  return permissionMap[userRole].includes(action);
};

const usePermissions = () => {
  const user = useCurrentUser();
  const userRole = user?.profile[UserClaims.UserRole] as string | string[];
  let finalUserRole = '';

  if (config.USE_ORG_CHART) {
    if (Array.isArray(userRole)) {
      const employeeRoles = userRole;
      if (employeeRoles.includes(UserRoles.CompanyAdmin))
        finalUserRole = UserRoles.CompanyAdmin;
      else if (employeeRoles.includes(UserRoles.GroupLead))
        finalUserRole = UserRoles.GroupLead;
      else if (
        config.USE_ORG_CHART &&
        employeeRoles.includes(UserRoles.Supervisor)
      )
        finalUserRole = UserRoles.Supervisor;
      else finalUserRole = UserRoles.Employee;
    } else {
      finalUserRole = userRole as string;
    }
  } else {
    let isAdmin = false;
    if (Array.isArray(userRole)) {
      isAdmin = userRole.includes(UserRoles.CompanyAdmin);
    } else {
      isAdmin = userRole === UserRoles.CompanyAdmin;
    }

    finalUserRole = isAdmin ? UserRoles.CompanyAdmin : UserRoles.Employee;
  }
  return permissionIsInRole(finalUserRole);
};

export default usePermissions;

import 'bootstrap/dist/css/bootstrap.css';

import * as React from 'react';
import './interceptors';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import { unregister } from './registerServiceWorker';
import PrivateRoute from './components/Authorization/PrivateRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import themeConfig from './themes/theme';
import './i18n';
import FallbackLoader from './components/core/FallbackLoader/FallbackLoader';
import { SnackbarProvider } from 'notistack';
import NotificationToast from './components/NotificationToast/NotificationToast';
import { setupStore, mainStore } from './store/configureStore';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme(themeConfig);
setupStore();
const { persistor, store, history } = mainStore;

root.render(
  <Provider store={store!}>
    <Router history={history!}>
      <PersistGate loading={null} persistor={persistor!}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            autoHideDuration={5000}
            Components={{
              default: NotificationToast,
              success: NotificationToast,
              error: NotificationToast,
              warning: NotificationToast
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <PrivateRoute>
              <React.Suspense fallback={<FallbackLoader />}>
                <App />
              </React.Suspense>
            </PrivateRoute>
          </SnackbarProvider>
        </ThemeProvider>
      </PersistGate>
    </Router>
  </Provider>
);

unregister();

import Box from '@mui/material/Box';
import { t } from 'i18next';
import { AssignedLesson } from '../../entities/LearningPlaylist';
import InfoBySpan from '../core/InfoByCardSpan/InfoBySpan';
import NewLessonCard from './NewLessonCard';
import moment from 'moment';
import {
  divButtonStyle,
  lessonWrapperStyle,
  spanDateStyle
} from './CommonStyles';
import useLessonCard from './useLessonCard';
import ButtonsSection from '../AssignmentsButtonsSection/ButtonSection';
import { EntityTypeEnum } from '../../core/enums';

interface AssignmentLessonCardProps {
  assignedLesson: AssignedLesson;
}

const AssignmentLessonCard: React.FC<AssignmentLessonCardProps> = ({
  assignedLesson
}) => {
  const { handlePlayLesson, takenTimesLessons } = useLessonCard();
  const { lesson, isComplete, dueDateMoment, dueDateUtc, assignmentId } =
    assignedLesson;
  const { id } = lesson;
  const today = moment();
  return (
    <Box sx={lessonWrapperStyle}>
      <NewLessonCard
        key={id}
        lesson={lesson}
        lessonCardType={'Assigned'}
        isComplete={isComplete ?? false}
        showEdit={false}
        dueDate={dueDateUtc}
        height="327px"
        takenTimes={takenTimesLessons ? takenTimesLessons[id] : undefined}
      >
        <div style={divButtonStyle}>
          <div style={spanDateStyle}>
            {dueDateMoment && dueDateMoment.isValid() && (
              <InfoBySpan
                leftText={t('dueDate', { ns: 'assignments' })}
                rightText={
                  dueDateMoment ? dueDateMoment!.format('MM.DD.YY') : ''
                }
                showProfilePicture={false}
                isOverdue={
                  dueDateMoment ? dueDateMoment!.isBefore(today, 'day') : false
                }
              />
            )}
          </div>
          <div style={{ position: 'absolute', bottom: 18, right: 18 }}>
            <ButtonsSection
              onClick={() => handlePlayLesson(lesson, assignmentId)}
              startButtonCaption={t('start', { ns: 'common' })}
              startButtonColor={'primary'}
              entityType={EntityTypeEnum.Lesson}
              assignment={assignedLesson}
            />
          </div>
        </div>
      </NewLessonCard>
    </Box>
  );
};

export default AssignmentLessonCard;

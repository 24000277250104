import { useTranslation } from 'react-i18next';

import { AssignmentStatusModal } from '../../../components/Analytics';
import OverdueList from './OverdueList';
import { useAnalyticsModal } from '../../../hooks/useAnalyticsModal';
import UpdateDueDateModal from '../common/UpdateDueDateModal';

interface OverdueModalProps {
  learningUnitId?: string;
  learningUnitType: string;
  includeSelfAssignments: boolean;
  filterOption: string;
  onCloseModal: () => void;
}

const OverdueModal = ({
  learningUnitId,
  learningUnitType,
  includeSelfAssignments,
  filterOption,
  onCloseModal
}: OverdueModalProps) => {
  const { t } = useTranslation(['analytics']);
  const {
    state: { overdueData, sortOptions, openModal, assignmentId, dueDate },
    api: {
      closeModal,
      handleSorting,
      handleEditDueDate,
      handleCloseUpdateDueDateModal,
      handleSuccessfulSaved
    }
  } = useAnalyticsModal(
    'overdues',
    learningUnitType,
    includeSelfAssignments,
    filterOption,
    learningUnitId
  );

  const handleCloseModal = () => {
    closeModal();
    onCloseModal();
  };

  return (
    <>
      <AssignmentStatusModal
        showModal={openModal}
        title={t('manageDueDates')}
        sortOptions={sortOptions}
        onClose={handleCloseModal}
        onChangeSortOption={handleSorting}
      >
        <OverdueList
          overdueData={overdueData}
          onEditDueDate={handleEditDueDate}
        />
      </AssignmentStatusModal>
      <UpdateDueDateModal
        learningUnitType={learningUnitType}
        assignmentId={assignmentId}
        dueDate={dueDate}
        onClose={handleCloseUpdateDueDateModal}
        onSuccessfulSaved={handleSuccessfulSaved}
      />
    </>
  );
};

export default OverdueModal;

import React, { CSSProperties, PropsWithChildren } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EmployeeSkillCertModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  onlyCertificates: boolean;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const EmployeeSkillCertModal = ({
  showModal,
  children,
  onCloseModal,
  onlyCertificates
}: PropsWithChildren<EmployeeSkillCertModalProps>) => {
  const theme = useTheme();
  const { t } = useTranslation(['skills']);
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={onCloseModal}
      maxWidth="xl"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography style={modalTitle}>
          {onlyCertificates
            ? t('manageCerts', { ns: 'skills' })
            : t('manageSkillsCerts', { ns: 'skills' })}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{ ...iconStyle }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </CustomDialog>
  );
};

export const modalTitle: CSSProperties = {
  color: '#FFF',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.2px'
};

export default EmployeeSkillCertModal;

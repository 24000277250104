import { useState, useEffect, useContext } from 'react';
import { UserNavigationContext } from '../UserNavigationContext';

const useScrollToLastTabPosition = (isContentReady: boolean | undefined) => {
  const [isScrollInit, setIsScrollInit] = useState(false);
  const userNavigationState = useContext(UserNavigationContext);

  useEffect(() => {
    if (isContentReady && !isScrollInit) {
      setIsScrollInit(true);
      setTimeout(() => {
        userNavigationState?.containerRef.current?.scroll({
          top: userNavigationState.state.scrollPosition
        });
      }, 100);
    }
  }, [isContentReady, isScrollInit]);
};

export default useScrollToLastTabPosition;

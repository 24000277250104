import React, { CSSProperties, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Employee } from '../../entities/Employee';
import FormCheckBox from '../../components/core/FormCheckBox/FormCheckBox';
import { EmployeeAssignmentStatus } from '../../entities/Assignment';
import AssignmentStatusChips from '../AssignmentStatusChips/AssignmentStatusChips';

export interface EmployeeListItemProps {
  employee: Employee;
  isAssgined: boolean;
  learningUnitAssigmentStatus: EmployeeAssignmentStatus | undefined;
  handleChangeAssign: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EmployeeListItem: React.FC<EmployeeListItemProps> = ({
  employee,
  isAssgined,
  learningUnitAssigmentStatus,
  handleChangeAssign
}) => {
  const { t } = useTranslation(['common', 'employees']);
  const theme = useTheme();
  const [isSelected, setIsSelected] = useState<boolean>(isAssgined);
  const { firstName, lastName, email, phoneNumber, photoUrl, title, groups } =
    employee;
  const emailOrPhoneNumber = email ?? phoneNumber;
  let groupNames = '';

  useEffect(() => {
    setIsSelected(isAssgined);
  }, [isAssgined]);

  const handleSelectEmployee = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSelected(e.target.checked);
    handleChangeAssign(e);
  };

  groups.forEach(
    g => (groupNames += !groupNames.length ? g.name : `, ${g.name}`)
  );

  const grayStyle: CSSProperties = {
    color: theme.palette.grey[100]
  };

  const subscribedLabelStyle: CSSProperties = {
    color: theme.palette.primary.main
  };

  return (
    <tr>
      <td>
        <div className="d-inline-flex">
          <FormCheckBox
            id={employee.id}
            label=""
            checked={isSelected}
            onChange={handleSelectEmployee}
          />
          <img
            className={`img-profile rounded-circle ${title ? 'my-4' : 'my-3'}`}
            width="40"
            height="40"
            alt={`${firstName} ${lastName}`}
            src={photoUrl?.length ? photoUrl : require('../../assets/user.png')}
          />
          <div className="d-flex flex-column ps-3 justify-content-center">
            <div className="d-flex flex-column">
              <span>{`${firstName} ${lastName}`}</span>
              <span style={grayStyle}>{emailOrPhoneNumber}</span>
            </div>
          </div>
        </div>
      </td>
      <td className="align-middle">{groupNames}</td>
      {employee.hasSubscription !== undefined && (
        <td className="align-middle">
          {employee.hasSubscription ? (
            <span style={subscribedLabelStyle}>
              {t('subscribed', { ns: 'employees' })}
            </span>
          ) : (
            <span>{t('notsubscribed', { ns: 'employees' })}</span>
          )}
        </td>
      )}
      <td className="text-center align-middle">
        {!!learningUnitAssigmentStatus && (
          <AssignmentStatusChips
            learningUnitAssigmentStatus={learningUnitAssigmentStatus}
          />
        )}
      </td>
    </tr>
  );
};

export default EmployeeListItem;

import { UserClaims, UserRoles } from '../core/constants';
import useCurrentUser from './useCurrentUser';
import useUserRole from './useUserRole';

export const useUserCanEditContent = () => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();

  const userCanEditContent = (resourceCompanyId: string) => {
    const companyId = user?.profile[UserClaims.CompanyId];
    const isCompanyAdmin = userHasRole(UserRoles.CompanyAdmin);
    return resourceCompanyId === companyId && isCompanyAdmin;
  };

  return [userCanEditContent];
};

import React, { useState } from 'react';
import {
  Stack,
  Tab,
  ThemeProvider,
  Typography,
  useTheme,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AsteriskIcon, defaultTheme, Pill } from '@buildwitt/component-library';

import PageContainer from '../../components/core/PageContainer/PageContainer';
import { CertificationsTab } from './CertificationsTab/CertificationsTab';
import BasicButton from '../../components/core/BasicButton/BasicButton';
import CreateCertificaton from './CreateCertification';
import EmployeeCertificationsTab from './EmployeeCertificationsTab/EmployeeCertificationTab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useUserRole from '../../hooks/useUserRole';
import { UserRoles } from '../../core/constants';
import Tooltip from '@mui/material/Tooltip';
import { InfoBubble } from '../../components/InfoBubble/InfoBubble';

const Certifications = () => {
  const theme = useTheme();
  const { t } = useTranslation(['certifications', 'common']);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [valueTab, setValueTab] = useState<string>('certifications');
  const [userHasRole] = useUserRole();

  const isAdminUser = userHasRole([UserRoles.CompanyAdmin]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateModal(false);
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <PageContainer>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '70px'
            }}
          >
            <Box
              gap={1}
              flexDirection={'row'}
              alignItems={'center'}
              display={'flex'}
            >
              <Pill
                label={'Beta'}
                color="primary"
                icon={
                  <Box>
                    <AsteriskIcon />
                  </Box>
                }
              />
              <Typography sx={theme.typography.h6}>
                {t('Certifications')}
              </Typography>
              <InfoBubble
                text={t('betaCertificationsToolTip')}
                isSmall={true}
              />
            </Box>
            {isAdminUser ? (
              <BasicButton
                color="primary"
                height="42px"
                style={{ minWidth: '120px' }}
                disable={false}
                onClick={() => setOpenCreateModal(true)}
              >
                {t('createNew')}
              </BasicButton>
            ) : (
              <Tooltip title={t('disabledPermissions', { ns: 'common' })}>
                <div>
                  <BasicButton
                    color="primary"
                    height="42px"
                    style={{ minWidth: '120px' }}
                    disable={true}
                  >
                    {t('createNew')}
                  </BasicButton>
                </div>
              </Tooltip>
            )}
          </Stack>
          <Stack>
            <TabContext value={valueTab}>
              <TabList
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="primary"
                sx={() => ({
                  '& .MuiTab-root': {
                    textTransform: 'none',
                    fontSize: '18px'
                  },
                  borderBottom: `1px solid #999999`,
                  '& .MuiTabs-indicator': { height: '4px' }
                })}
              >
                <Tab
                  value="certifications"
                  label={`${t('certifications')}`}
                  style={{}}
                />
                {isAdminUser && (
                  <Tab
                    value="employees"
                    label={`${t('employees')}`}
                    style={{}}
                  />
                )}
              </TabList>
              <TabPanel value="certifications" sx={{ padding: 0 }}>
                <CertificationsTab />
              </TabPanel>
              {isAdminUser && (
                <TabPanel value="employees" sx={{ padding: 0 }}>
                  <EmployeeCertificationsTab />
                </TabPanel>
              )}
            </TabContext>
          </Stack>
        </PageContainer>
      </ThemeProvider>
      {openCreateModal && (
        <CreateCertificaton closeModal={handleCloseCreateModal} />
      )}
    </>
  );
};

export default Certifications;

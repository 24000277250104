import React, { CSSProperties } from 'react';
export interface PageContainerProps {
  style?: CSSProperties;
}
const PageContainer: React.FC<React.PropsWithChildren<PageContainerProps>> = ({
  children,
  style = {}
}) => {
  const finalStyle = { ...pageContainerStyle, ...style };
  return (
    <div className="page-container" style={finalStyle}>
      {children}
    </div>
  );
};
const pageContainerStyle: CSSProperties = {
  height: '100%',
  padding: '5px',
  backgroundColor: '#000'
};
export default PageContainer;

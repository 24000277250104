import { dropDownItem } from '../entities/Common';

export const TemporalNewId = '00000000-0000-0000-0000-000000000000';
export const UserClaims = {
  ProfileImageUrl: 'BuildWitt.User.ProfileImageUrl',
  CompanyName: 'BuildWitt.Company.CompanyName',
  CompanyId: 'BuildWitt.Company.CompanyId',
  EmployeeId: 'BuildWitt.Company.EmployeeId',
  UserEmail: 'BuildWitt.User.Email',
  UserPhoneNumber: 'BuildWitt.User.PhoneNumber',
  Username: 'BuildWitt.User.Username',
  UserRole: 'BuildWitt.User.Role',
  UserId: 'BuildWitt.User.Guid',
  TrainingEmployeeSubscription: 'BuildWitt.Employee.Subscription.Training',
  CompanyLogoUrl: 'BuildWitt.Company.CompanyLogoUrl',
  BWLibraryCompanyId: 'BuildWitt.Library.CompanyId',
  IsFirstTrainingLogin: 'BuildWitt.Company.Employee.IsFirstTrainingLogin',
  IsFreeTrial: 'BuildWitt.Company.Subscription.Training.FreeTrial',
  FreeTrialExpirationDate:
    'BuildWitt.Company.Subscription.Training.FreeTrial.ExpirationDate',
  FreeTrialMaxUsers:
    'BuildWitt.Company.Subscription.Training.FreeTrial.MaxUsers',
  FirstName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  LastName: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
};

export const UserRoles = {
  CompanyAdmin: 'CompanyAdmin',
  BuildWittAdmin: 'BuildWittAdmin',
  Employee: 'Employee',
  GroupLead: 'GroupLead',
  Supervisor: 'Supervisor',
  Certifier: 'Certifier'
};

export const appPermissions = {
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  VIEW_ASSIGNMENTS: 'VIEW_ASSIGMENTS',
  VIEW_COURSES: 'VIEW_COURSES',
  VIEW_COURSE: 'VIEW_COURSE',
  VIEW_PROGRESS: 'VIEW_PROGRESS',
  VIEW_MESSAGES: 'VIEW_MESSAGES',
  VIEW_LEARNING_PLANS: 'VIEW_LEARNING_PLANS',
  VIEW_LESSONS: 'VIEW_LESSONS',
  VIEW_EMPLOYEES: 'VIEW_EMPLOYEES',
  VIEW_ANALYTICS: 'VIEW_ANALYTICS',
  VIEW_SKILLS: 'VIEW_SKILLS',
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  VIEW_CONTENT_LIBRARY: 'VIEW_CONTENT_LIBRARY',
  VIEW_ONSITE_TRAINING: 'VIEW_ONSITE_TRAINING',
  CREATE_COURSE: 'CREATE_COURSE',
  PREVIEW_COURSE: 'PREVIEW_COURSE',
  PLAY_COURSE: 'PLAY_COURSE',
  PLAY_COURSE_ASSIGNMENT: 'PLAY_COURSE_ASSIGNMENT',
  EDIT_COURSE: 'EDIT_COURSE',
  VIEW_COURSE_DETAILS: 'VIEW_COURSE_DETAILS',
  IMPORT_COURSE: 'IMPORT_COURSE',
  EDIT_LESSON: 'EDIT_LESSON',
  ADD_LESSON: 'ADD_LESSON',
  ADD_NEW_EMPLOYEE: 'ADD_NEW_EMPLOYEE',
  CREATE_GROUP: 'CREATE_GROUP',
  VIEW_EMPLOYEE_ASSIGNMENTS: 'VIEW_EMPLOYEE_ASSIGNMENTS',
  VIEW_DRAFT_COURSE: 'VIEW_DRAFT_COURSE',
  ASSIGN_COURSE: 'ASSIGN_COURSE',
  ASSIGN_LEARNING_PLANS: 'ASSIGN_LEARNING_PLANS',
  MANAGE_COMPANY: 'MANAGE_COMPANY',
  ASSIGN_LESSON: 'ASSIGN_LESSON',
  CREATE_LESSON: 'CREATE_LESSON',
  CREATE_LEARNING_PLAN: 'CREATE_LEARNING_PLAN',
  CREATE_LEARNING_UNITS: 'CREATE_LEARNING_UNITS',
  PURCHASE_CLICKSAFETY_SUBSCRIPTIONS: 'PURCHASE_CLICKSAFETY_SUBSCRIPTIONS',
  VIEW_ANALYTICS_GROUP_ASSIGNMENTS: 'VIEW_ANALYTICS_GROUP_ASSIGNMENTS',
  CREATE_COMPANY_TRAINING_HALTS: 'CREATE_COMPANY_TRAINING_HALTS',
  VIEW_TODAYS_HALTS_TAB: 'VIEW_TODAYS_HALTS_TAB',
  CREATE_ONLY_PERSONAL_HALTS: 'CREATE_ONLY_PERSONAL_HALTS',
  VIEW_CERTIFICATIONS: 'VIEW_CERTIFICATIONS'
};

export const EnrollmentTypes = {
  DueDate: 'DueDate',
  EmployeesWorkloadBudget: 'WorkloadBudget',
  SelfAssign: 'SelfAssign',
  NoDueDate: 'NoDueDate',
  OnsiteTraining: 'OnsiteTraining'
};

export const QuestionTypes = {
  TrueFalse: 'TrueFalse',
  MultipleChoiceText: 'MultipleChoiceText',
  MultipleChoiceImage: 'MultipleChoiceImage',
  Number: 'Number'
};

export const LabelDropdown = {
  TrueFalse: 'True or False',
  MultipleChoiceText: 'Multiple choice',
  MultipleChoiceImage: 'Choose an Image',
  Number: 'Text number response'
};

export const Buildwitt_Value = 'buildwitt';
export const Partners_Value = 'partners';
export const Company_Value = 'company';
export const HttpStatusOK = 200;

export const ServerEventsNames = {
  LessonVideoEncodingComplete: 'VideoEncodingFinished',
  LessonVideoEncodingFailed: 'VideoEncodingFailed'
};

export const LessonsPerDay = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' }
];

export const BuildWittName = 'BuildWitt';
export const Partners = 'Partners';
export const LessonVideo = 'Video';
export const LessonQuiz = 'Quiz';
export const LessonAssessment = 'Assessment';
export const LessonPdf = 'PDF';
export const LessonImage = 'Image';
export const LessonAudio = 'Audio';

export const LanguagesSubtitles = [
  { label: 'English', value: 'English', srcLang: 'EN' },
  { label: 'Spanish', value: 'Spanish', srcLang: 'ES' }
];

export const NotificationType = {
  NewAssignment: 'NewAssignment',
  PendingWorkload: 'PendingWorkload',
  OverdueAssignments: 'OverdueAssignments',
  WeeklyOverseerSummary: 'WeeklyOverseerSummary',
  DailyOverseerSummary: 'DailyOverseerSummary'
};

export const NotificationStatus = {
  New: 'New',
  Viewed: 'Viewed',
  Dismissed: 'Dismissed',
  Sent: 'Sent'
};

export const DefaultStartTimeShiftInMinutesUtc = 570;
export const StartTimes = [
  { label: '12:00am', value: '0' },
  { label: '12:30am', value: '30' },
  { label: '1:00am', value: '60' },
  { label: '1:30am', value: '90' },
  { label: '2:00am', value: '120' },
  { label: '2:30am', value: '150' },
  { label: '3:00am', value: '180' },
  { label: '3:30am', value: '210' },
  { label: '4:00am', value: '240' },
  { label: '4:30am', value: '270' },
  { label: '5:00am', value: '300' },
  { label: '5:30am', value: '330' },
  { label: '6:00am', value: '360' },
  { label: '6:30am', value: '390' },
  { label: '7:00am', value: '420' },
  { label: '7:30am', value: '450' },
  { label: '8:00am', value: '480' },
  { label: '8:30am', value: '510' },
  { label: '9:00am', value: '540' },
  { label: '9:30am', value: '570' },
  { label: '10:00am', value: '600' },
  { label: '10:30am', value: '630' },
  { label: '11:00am', value: '660' },
  { label: '11:30am', value: '690' },
  { label: '12:00pm', value: '720' },
  { label: '12:30pm', value: '750' },
  { label: '1:00pm', value: '780' },
  { label: '1:30pm', value: '810' },
  { label: '2:00pm', value: '840' },
  { label: '2:30pm', value: '870' },
  { label: '3:00pm', value: '900' },
  { label: '3:30pm', value: '930' },
  { label: '4:00pm', value: '960' },
  { label: '4:30pm', value: '990' },
  { label: '5:00pm', value: '1020' },
  { label: '5:30pm', value: '1050' },
  { label: '6:00pm', value: '1080' },
  { label: '6:30pm', value: '1110' },
  { label: '7:00pm', value: '1140' },
  { label: '7:30pm', value: '1170' },
  { label: '8:00pm', value: '1200' },
  { label: '8:30pm', value: '1230' },
  { label: '9:00pm', value: '1260' },
  { label: '9:30pm', value: '1290' },
  { label: '10:00pm', value: '1320' },
  { label: '10:30pm', value: '1350' },
  { label: '11:00pm', value: '1380' },
  { label: '11:30pm', value: '1410' }
];

export const AssignmentType = {
  lesson: 'lessons',
  course: 'courses',
  learningPlan: 'learningPlans'
};

export const WeeklyStatisticsDefault = [
  {
    dayOfWeek: 'Sunday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Monday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Tuesday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Wednesday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Thursday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Friday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  },
  {
    dayOfWeek: 'Saturday',
    date: '',
    lessonsCompleted: 0,
    coursesCompleted: 0,
    learningPlansCompleted: 0
  }
];

export const TrainingItemType = {
  lesson: 'lesson',
  course: 'course',
  learningPlan: 'learningPlan'
};

export const ThirdPartyCompanies = {
  clickSafety: 'ClickSafety'
};

export const SortOptions: dropDownItem[] = [
  { label: 'A-Z', value: 'A-Z' },
  { label: 'Z-A', value: 'Z-A' }
];

export const FilterOptions: dropDownItem[] = [
  { label: 'All', value: 'all' },
  { label: 'Spanish enabled content', value: 'spanishContent' }
];

export const FilterSpanishOptions: dropDownItem[] = [
  { label: 'Todo', value: 'all' },
  { label: 'Contenido en Español', value: 'spanishContent' }
];

export const SortingOptions: dropDownItem[] = [
  { label: 'A-Z', value: 'A-Z' },
  { label: 'Z-A', value: 'Z-A' },
  { label: 'Date (oldest to newest)', value: 'asc' },
  { label: 'Date (newest to oldest)', value: 'desc' }
];

export const SortingSpanishOptions: dropDownItem[] = [
  { label: 'A-Z', value: 'A-Z' },
  { label: 'Z-A', value: 'Z-A' },
  { label: 'Fecha (antiguo a nuevo)', value: 'asc' },
  { label: 'Fecha (nuevo a antiguo)', value: 'desc' }
];

export const SortByDate: dropDownItem[] = [
  { label: 'Date (old to new)', value: 'asc' },
  { label: 'Date (new to old)', value: 'desc' }
];

export const SortBySpanishDate: dropDownItem[] = [
  { label: 'Fecha (antiguo a nuevo)', value: 'asc' },
  { label: 'Fecha (nuevo a antiguo)', value: 'desc' }
];

export const SortByAllOrDate: dropDownItem[] = [
  { label: 'All Content', value: 'allContent' },
  { label: 'Newest Content', value: 'newestContent' }
];

export const SortBySpanishAllOrDate: dropDownItem[] = [
  { label: 'Todo el contenido', value: 'allContent' },
  { label: 'Contenido Nuevo', value: 'newestContent' }
];

export const TypeOptions: dropDownItem[] = [
  { label: 'Courses', value: 'courses' },
  { label: 'Lessons', value: 'lessons' },

  {
    label: 'Learning Plans',
    value: 'learningPlans'
  }
];

export const TypeSpanishOptions: dropDownItem[] = [
  { label: 'Cursos', value: 'courses' },
  { label: 'Lecciones', value: 'lessons' },

  {
    label: 'Planes de Aprendizaje',
    value: 'learningPlans'
  }
];

export const TypeOptionsAlph: dropDownItem[] = [
  { label: 'Courses', value: 'courses' },
  { label: 'Lessons', value: 'lessons' },

  {
    label: 'Learning Plans',
    value: 'learningPlans'
  }
];

export const ContentOptions: dropDownItem[] = [
  {
    label: 'All Content',
    value: 'allContent'
  },
  {
    label: 'Published',
    value: 'published'
  },
  {
    label: `Draft`,
    value: 'draft'
  }
];

export const ContentSpanishOptions: dropDownItem[] = [
  {
    label: 'Todo el Contenido',
    value: 'allContent'
  },
  {
    label: 'Publicados',
    value: 'published'
  },
  {
    label: `Borradores`,
    value: 'draft'
  }
];

export const CommunityLibraryCompanyId = 'c77d7634-d211-4d48-04a2-08d9e04a965b';
export const Languages = {
  english: 'English',
  spanish: 'Spanish'
};

export const LessonUnitTypes = {
  companyLesson: 'CompanyLesson',
  bWLesson: 'BWLesson',
  communityLesson: 'CommunityLesson',
  partnerLesson: 'PartnerLesson'
};

export const CourseUnitTypes = {
  companyCourse: 'CompanyCourse',
  bWCourse: 'BWCourse',
  clickSafetyCourse: 'ClickSafetyCourse',
  partnerCourse: 'PartnerCourse'
};

export const LearningPlanUnitTypes = {
  companyLearningPlan: 'CompanyLearningPlan',
  bWLearningPlan: 'BWLearningPlan',
  partnerLearningPlan: 'PartnerLearningPlan'
};

export const DefaultLearningPlanForSelfAssignment =
  'e9f8a952-1966-42e1-b341-3987757cc40c';

export const GroupTypeList = ['Employee', 'Division', 'Project'];

export const FilterDropdownOptions: dropDownItem[] = [
  {
    label: 'All',
    value: 'All'
  },
  {
    label: 'BuildWitt Content',
    value: 'BuildWitt'
  },
  {
    label: 'Company Content',
    value: 'Company'
  },
  {
    label: 'Partnert Content',
    value: 'Partner'
  }
];

export const FilterDropdownSpanishOptions: dropDownItem[] = [
  {
    label: 'Todo',
    value: 'All'
  },
  {
    label: 'Contenido de BuildWitt',
    value: 'BuildWitt'
  },
  {
    label: 'Contenido de la Compañia',
    value: 'Company'
  },
  {
    label: `Contenido de Socios`,
    value: 'Partner'
  }
];

export const SortDropdownOptions: dropDownItem[] = [
  {
    label: 'Most Employees Assigned',
    value: 'mostEmployeesAssigned'
  },
  {
    label: 'Least Employees Assigned',
    value: 'leastEmployeesAssigned'
  },
  {
    label: 'Most Overdue',
    value: 'mostOverdue'
  },
  {
    label: 'Most Pending Completion',
    value: 'mostPendingCompletion'
  },
  {
    label: 'Most Completed',
    value: 'mostCompleted'
  }
];

export const SortSpanishDropdownOptions: dropDownItem[] = [
  {
    label: 'Most Employees Assigned',
    value: 'mostEmployeesAssigned'
  },
  {
    label: 'Least Employees Assigned',
    value: 'leastEmployeesAssigned'
  },
  {
    label: 'Most Overdue',
    value: 'mostOverdue'
  },
  {
    label: 'Most Pending Completion',
    value: 'mostPendingCompletion'
  },
  {
    label: 'Most Completed',
    value: 'mostCompleted'
  }
];

export const ContentLibraryTabs = {
  AllContent: 'allcontent',
  BuildWitt: 'buildwitt',
  Partner: 'partner',
  ClickSafety: 'clicksafety',
  Community: 'community'
};

export const SkillsModalTypes = {
  LinkedTraining: 'linkedTraining',
  AttachedFiles: 'attachedFiles',
  EmployeesSkill: 'employeesSkill',
  EmployeesAssigned: 'employeesAssigned'
};

export const DefaultSearchDebounceTime = 1000;

export const TitilliumWebNormal =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf';
export const TitilliumWebSemiBold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffBzCKIx5YrSYqWM.ttf';
export const TitilliumWebBold =
  'https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffHjDKIx5YrSYqWM.ttf';

export const ClientTimezoneOffset = new Date().getTimezoneOffset();

export const DefaultPageSize = 50;

export const monthsOptions: dropDownItem[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
  { label: '32', value: '32' },
  { label: '33', value: '33' },
  { label: '34', value: '34' },
  { label: '35', value: '35' },
  { label: '36', value: '36' }
];

export const daysOptions: dropDownItem[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
  { label: '18', value: '18' },
  { label: '19', value: '19' },
  { label: '20', value: '20' },
  { label: '21', value: '21' },
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' }
];

export const yearOptions: dropDownItem[] = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' }
];
export const pageCertificationsState = {
  page: 1,
  pageSize: 10,
  sortBy: {
    createdAt: 'createdAt:desc',
    employeeName: 'employeeName:asc'
  }
};

export const pageInitialStateEmployeesToAward = {
  initialPageNumber: 1,
  initialPageSize: 20
};

export const searchAutocompleteEvents = {
  events: {
    click: 'click',
    keydown: 'keydown',
    change: 'change',
    enter: 'Enter'
  },
  numberOfcharactersToTriggerApi: 3
};

export const systemGeneratedPdf = 'systemGeneratedPdf';
export const systemGeneratedPdfName = 'System Generated Certificate.pdf';

/*
 * helper to execute local/focused api calls for data
 * that don't need to be in the app state(redux)
 *
 */
import axios from 'axios';

export const getCall = (url: string) => {
  return axios.get(url, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  });
};

import React from 'react';
import { SxProps } from '@mui/material/styles';
import BasicSelect from '../../../components/core/BasicSelect/BasicSelect';

export interface SelectDurationProps {
  selectValue: string;
  onChange: (items: string[]) => void;
}

const DurationSelect: React.FC<SelectDurationProps> = ({
  onChange,
  selectValue
}) => {
  const value = selectValue ? [selectValue] : [];

  return (
    <BasicSelect
      labelId="skillsCategoriesFilter"
      id="skillsCategoriesFilter"
      options={[
        {
          label: 'Days',
          value: 'days',
          disabled: false,
          hidden: false
        },
        {
          label: 'Months',
          value: 'months',
          disabled: false,
          hidden: false
        },
        {
          label: 'Years',
          value: 'years',
          disabled: false,
          hidden: false
        }
      ]}
      value={value}
      defaultValue={'days'}
      handleOnChange={onChange}
      placeholder="Duration"
      theme="dark"
      multiple={false}
      sx={filterStyle}
    />
  );
};

const filterStyle: SxProps = {
  backgroundColor: '#000000',
  minWidth: '80px',
  height: '45px'
};

export default DurationSelect;

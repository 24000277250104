import { CSSProperties, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Stack,
  Box,
  Typography,
  ListItem,
  CircularProgress,
  SxProps,
  Tooltip
} from '@mui/material';
import { StyledGridContainer } from './onsiteTrainingCheckIn';
import { useTranslation } from 'react-i18next';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import MenuItem from '@mui/material/MenuItem';
import { dropDownItem } from '../../../entities/Common';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import CheckGreen from '../../../assets/checkGreen.svg';
import { OnsiteTrainingAttendanceType } from '../../../core/enums';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider } from '@mui/material/styles';
import { darkSelectTheme } from '../../../components/core/BasicSelect/BasicSelect';
import ConfirmationModal from '../../../components/core/ConfirmationModal/ConfirmationModal';
import { AssignedOnsiteTrainingClass } from '../../../entities/LearningPlaylist';
import moment from 'moment';
import { absUtcDateToLocalMoment } from '../../../utils/dateUtils';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { Index, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import SignatureOnsiteTrainingModal from '../modal/SignatureOnsiteTrainingModal';
import { OnsiteTraining } from '../../../entities/OnsiteTraining';
import { useAppDispatch } from '../../../store/hooks';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:nth-of-type(odd)': {},
  '&:nth-of-type(even)': {
    borderTop: `1px solid rgba(228, 228, 228, 0.15)`,
    borderBottom: `1px solid rgba(228, 228, 228, 0.15)`
  },
  height: 65
}));

const ClassEmployeesPanel = (props: {
  theme: any;
  classObject: AssignedOnsiteTrainingClass;
}) => {
  const { t } = useTranslation(['onsiteTraining', 'common']);

  const dispatch = useAppDispatch();
  const currentDate = moment().format('MM.DD.YYYY');
  const currentDateMomentFormat = moment();
  const [selectedDay, setSelectedDay] = useState<string>(currentDate);
  const [selectOptions, setSelectOptions] = useState<Array<dropDownItem>>([]);
  const [
    showConfirmationCurrentDayChange,
    setShowConfirmationCurrentDayChange
  ] = useState<boolean>(false);
  const classAssignmentsPerday = useSelector(
    (state: ApplicationState) =>
      state.onsiteTrainings?.onsiteTrainingClassAssignmentsPerDay
  );
  const onsiteTrainingDef: OnsiteTraining =
    props.classObject?.onsiteTrainingClass?.onsiteTraining;
  const [searchKey, setSearchKey] = useState<string>('');
  const page = useRef<number>(1);
  const [assigmentPerDay, setAssignmetPerDay] = useState<string>('');
  const rowCount =
    classAssignmentsPerday && classAssignmentsPerday.hasNextPage
      ? classAssignmentsPerday.totalItems + 1
      : classAssignmentsPerday?.totalItems ?? 0;

  let searchTimeout: NodeJS.Timeout;
  const [isLoadingAssignmentIdCheckIn, setIsLoadingAssignmentIdCheckIn] =
    useState<string>('');
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);

  const loadNextPage = async () => {
    page.current += 1;
    const dayNumber = props.classObject.assignments.map((item, index) => {
      if (
        absUtcDateToLocalMoment(item.dateUtc).format('MM.DD.YYYY') ===
        currentDate
      ) {
        return index;
      }
    });
    await dispatch<Promise<void>>(
      onsiteTrainingActions.retreiveAllClassAssignmentsPerDay(
        props.classObject.onsiteTrainingClass.id,
        dayNumber[0]! + 1,
        1,
        searchKey
      )
    );

    return new Promise<any>(resolve => {
      return resolve;
    });
  };

  const isRowLoaded = ({ index }: Index) => {
    return (
      !classAssignmentsPerday?.hasNextPage ||
      index < (classAssignmentsPerday?.pageItems.length ?? 0)
    );
  };

  const loadMoreRows = !classAssignmentsPerday
    ? () => new Promise<any>(resolve => resolve)
    : loadNextPage;

  const onAfterSavingSignature = (signatureURL: string) => {
    onCheckIn(isLoadingAssignmentIdCheckIn, signatureURL);
  };

  const onCheckIn = async (
    selectedId: string,
    signature: string | undefined
  ) => {
    await dispatch<Promise<void>>(
      onsiteTrainingActions.setCheckInToClassAssignment(
        selectedId,
        props.classObject.onsiteTrainingClass.instructorId,
        OnsiteTrainingAttendanceType.Attended,
        signature
      )
    );
    setIsLoadingAssignmentIdCheckIn('');
  };

  // checkin function
  const handleClickCheckIn = async (selectedAssignmentId: string) => {
    setAssignmetPerDay(selectedAssignmentId);
    setIsLoadingAssignmentIdCheckIn(selectedAssignmentId);
    if (selectedDay !== currentDate) {
      setShowConfirmationCurrentDayChange(true);
      return;
    }
    setAssignmetPerDay(selectedAssignmentId);
    if (onsiteTrainingDef?.requireSignatureForAttendance) {
      setShowSignatureModal(true);
      return;
    }
    onCheckIn(selectedAssignmentId, undefined);
  };

  const onCloseSignatureModal = () => {
    setShowSignatureModal(false);
    setIsLoadingAssignmentIdCheckIn('');
  };

  useEffect(() => {
    const optionsArray: Array<dropDownItem> = [];
    let numberOfDay = 1;
    props.classObject.assignments.forEach(item => {
      const option = {
        label: `${t('day', { ns: 'common' })} ${numberOfDay.toString()}`,
        value: absUtcDateToLocalMoment(item.dateUtc).format('MM.DD.YYYY')
      };
      if (!optionsArray.find(item => item.value === option.value)) {
        optionsArray.push(option);
        numberOfDay = numberOfDay + 1;
      }
    });
    setSelectOptions(optionsArray);
  }, [props.classObject.assignments]);

  useEffect(() => {
    if (props.classObject) {
      fetchOnsitetrainingAssigments(1, currentDate);
    }
    return () => {
      dispatch(onsiteTrainingActions.resetAllClassAssignmentsPerDay());
    };
  }, [props.classObject?.onsiteTrainingClass?.id]);

  useEffect(() => {
    if (!classAssignmentsPerday) return;

    resetAndFetchOnsiteTrainingsAssignments();
  }, [searchKey]);

  const resetAndFetchOnsiteTrainingsAssignments = () => {
    dispatch(onsiteTrainingActions.resetAllClassAssignmentsPerDay());
    fetchOnsitetrainingAssigments(1, selectedDay);
  };

  const fetchOnsitetrainingAssigments = async (
    pageNumber: number,
    date: string
  ) => {
    let indexDayNumber = 0;
    props.classObject.assignments.map((item, index) => {
      if (absUtcDateToLocalMoment(item.dateUtc).format('MM.DD.YYYY') === date) {
        indexDayNumber = index + 1;
      }
    });
    await dispatch<Promise<void>>(
      onsiteTrainingActions.retreiveAllClassAssignmentsPerDay(
        props.classObject.onsiteTrainingClass?.id,
        indexDayNumber,
        pageNumber,
        searchKey
      )
    );
  };

  const handleSearch = (value: string) => {
    if (!classAssignmentsPerday) return;

    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setSearchKey(value);
    }, 1000);
  };

  const handleChange = async (event: SelectChangeEvent) => {
    setSelectedDay(event.target.value);
    dispatch(onsiteTrainingActions.resetAllClassAssignmentsPerDay());
    await fetchOnsitetrainingAssigments(1, event.target.value);
  };

  const renderRow =
    (selectedId: string, onClick: (id: string) => void) =>
    (propsRender: ListRowProps) => {
      const { index, style, key } = propsRender;
      const data = classAssignmentsPerday
        ? classAssignmentsPerday.pageItems
        : [];
      const item = data[index];
      const { employee, onsiteTrainingAssignmentDay } = item;
      return (
        <StyledListItem
          style={{
            ...style,
            backgroundColor:
              onsiteTrainingAssignmentDay.attendance ===
              OnsiteTrainingAttendanceType.Requested
                ? props.theme.palette.primary.main
                : index % 2 === 0
                  ? props.theme.palette.common.black
                  : props.theme.palette.grey.A200
          }}
          key={key}
          disablePadding
        >
          <div className="d-flex ps-1 pt-2 pb-2">
            <img
              className={`img-profile rounded-circle`}
              width="50"
              height="50"
              alt={`${employee.firstName} ${employee.lastName}`}
              src={
                employee.profileImageUrl && employee.profileImageUrl.length
                  ? employee.profileImageUrl
                  : require('../../../assets/user.png')
              }
            />
            <div className="d-flex flex-column px-3">
              <span
                style={{
                  fontSize: 16,
                  color:
                    onsiteTrainingAssignmentDay.attendance ===
                    OnsiteTrainingAttendanceType.Requested
                      ? props.theme.palette.grey[800]
                      : props.theme.palette.text.primary
                }}
              >
                {`${employee.firstName} ${employee.lastName}`}
              </span>
              <span
                style={{
                  fontSize: 12,
                  color:
                    onsiteTrainingAssignmentDay.attendance ===
                    OnsiteTrainingAttendanceType.Requested
                      ? props.theme.palette.grey[800]
                      : props.theme.palette.grey[100]
                }}
              >
                {employee.email}
              </span>
            </div>
          </div>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
            width={'100%'}
            style={{ paddingRight: 20 }}
          >
            {!!onsiteTrainingAssignmentDay.missedDays &&
              onsiteTrainingAssignmentDay.attendance !==
                OnsiteTrainingAttendanceType.Missed &&
              absUtcDateToLocalMoment(
                onsiteTrainingAssignmentDay.dateUtc
              ).format('MM.DD.YYYY') === currentDate && (
                <span
                  style={{
                    fontSize: 14,
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  {onsiteTrainingAssignmentDay.missedDays === 1
                    ? t('singleDayMissed', { ns: 'onsiteTraining' })
                    : t('singleDayMissed', { ns: 'onsiteTraining' }).replace(
                        '{number}',
                        onsiteTrainingAssignmentDay.missedDays.toString()
                      )}
                </span>
              )}
            {!!onsiteTrainingAssignmentDay.missedDays &&
              onsiteTrainingAssignmentDay.attendance ===
                OnsiteTrainingAttendanceType.Missed && (
                <span
                  style={{
                    fontSize: 14,
                    color: 'red',
                    fontWeight: 'bold'
                  }}
                >
                  {t('missedThisDay', { ns: 'onsiteTraining' })}
                </span>
              )}
            {onsiteTrainingAssignmentDay.attendance ===
              OnsiteTrainingAttendanceType.Requested && (
              <Typography style={typoStyleRequested}>
                {t('requestCheckIn', { ns: 'onsiteTraining' })}
              </Typography>
            )}
            {(onsiteTrainingAssignmentDay.attendance ===
              OnsiteTrainingAttendanceType.Pending ||
              onsiteTrainingAssignmentDay.attendance ===
                OnsiteTrainingAttendanceType.Requested ||
              onsiteTrainingAssignmentDay.attendance ===
                OnsiteTrainingAttendanceType.Missed) && (
              <BasicButton
                color="grayButton"
                style={{ width: '100px', height: '35px' }}
                onClick={() => onClick(item.onsiteTrainingAssignmentDay.id)}
                loading={
                  item.onsiteTrainingAssignmentDay.id ===
                  isLoadingAssignmentIdCheckIn
                }
                disable={currentDateMomentFormat.isBefore(
                  onsiteTrainingAssignmentDay.dateUtc
                )}
              >
                {t('checkIn', { ns: 'onsiteTraining' })}
              </BasicButton>
            )}
            {onsiteTrainingAssignmentDay.attendance ===
              OnsiteTrainingAttendanceType.Attended && (
              <img src={CheckGreen} alt="Black icon check" />
            )}
          </Stack>
        </StyledListItem>
      );
    };

  return (
    <StyledGridContainer container>
      <Grid
        item
        direction="column"
        xs={12}
        sx={{
          padding: '1rem',
          borderBottom: `1px solid ${props.theme.palette.grey[100]}`,
          height: 'fit-content'
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography style={typoStyle}>
            {t('attendanceCheckIn', { ns: 'onsiteTraining' })}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <ThemeProvider theme={darkSelectTheme}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedDay}
                label="Age"
                onChange={handleChange}
              >
                {selectOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Tooltip title={option.value} placement="right">
                      <span
                        dangerouslySetInnerHTML={{ __html: option.label }}
                      />
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </ThemeProvider>
            <SearchBox
              width="100%"
              height="45px"
              placeholderText={t('search', { ns: 'common' })}
              variant="dark"
              onChangeValue={handleSearch}
              value={''}
              id={''}
              name={''}
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '10px 10px'
        }}
      >
        <Box
          sx={{
            height: 560,
            width: '100%'
          }}
        >
          {classAssignmentsPerday && !!classAssignmentsPerday.totalItems && (
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={rowCount}
              threshold={10}
            >
              {({ onRowsRendered, registerChild }) => (
                <List
                  containerStyle={{
                    width: '100%',
                    maxWidth: '100%'
                  }}
                  style={{
                    width: '100%'
                  }}
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  rowRenderer={renderRow(
                    assigmentPerDay ?? '',
                    handleClickCheckIn
                  )}
                  height={575}
                  width={448}
                  rowHeight={72}
                  rowCount={classAssignmentsPerday?.totalItems ?? 0}
                />
              )}
            </InfiniteLoader>
          )}
          {(!classAssignmentsPerday || !classAssignmentsPerday.totalItems) && (
            <Box sx={loaderContainerStyle}>
              {!classAssignmentsPerday ? (
                <CircularProgress />
              ) : (
                <>
                  <span
                    style={{
                      fontWeight: 600,
                      fontSize: '24px',
                      paddingTop: '1rem'
                    }}
                  >
                    {t('employeeNotFound', { ns: 'onsiteTraining' })}
                  </span>
                </>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <ConfirmationModal
        show={showConfirmationCurrentDayChange}
        showSave={true}
        showCancel={true}
        showModalContentCentered={true}
        isGrayCancelButton={true}
        hasButtonsSameWidth={true}
        maxWidth="sm"
        saveText={t('continue', { ns: 'common' })}
        cancelText={t('cancel', { ns: 'common' })}
        title={t('notCurrentDayClass', { ns: 'onsiteTraining' })}
        onSave={() => {
          setShowSignatureModal(true);
          setShowConfirmationCurrentDayChange(false);
        }}
        onCancel={() => {
          setIsLoadingAssignmentIdCheckIn('');
          setShowConfirmationCurrentDayChange(false);
        }}
      >
        <p>{t('doYouWantToContinue', { ns: 'onsiteTraining' })}</p>
      </ConfirmationModal>
      <SignatureOnsiteTrainingModal
        showModal={showSignatureModal}
        onCloseModal={onCloseSignatureModal}
        nextButtonText={t('completeCheckIn', {
          ns: 'onsiteTraining'
        })}
        signatureWidth={750}
        signatureHeight={260}
        key={Math.random()}
        onAfterSavingSignature={onAfterSavingSignature}
      />
    </StyledGridContainer>
  );
};

const typoStyle: CSSProperties = {
  fontSize: '20px'
};

const typoStyleRequested: CSSProperties = {
  fontSize: '14px',
  color: '#0E0D0D'
};

const loaderContainerStyle: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  flexDirection: 'column'
};

export default ClassEmployeesPanel;

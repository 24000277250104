import React, { CSSProperties } from 'react';
import { useTheme } from '@mui/material/styles';
export interface TextAreaProps {
  value: string;
  onChangeValue?: (value: any) => void;
  placeholderText?: string;
  id: string;
  name: string;
  width: string;
  rows: number;
  maxLength?: number;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  variant?: 'dark' | 'light' | undefined;
  height?: string;
  dataTestId?: string;
  dataTrackId?: string;
}
const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      name,
      value,
      onChangeValue,
      placeholderText,
      width,
      rows,
      maxLength = 10000,
      onBlur,
      disabled = false,
      variant = 'light',
      height = '132px',
      dataTestId = '',
      dataTrackId = ''
    },
    ref
  ) => {
    const [inputValue, setInputValue] = React.useState(value);
    React.useEffect(() => {
      if (value) {
        setInputValue(value);
      }
    }, [value]);
    const theme = useTheme();

    const preStyle: CSSProperties = {
      borderRadius: '5px',
      fontFamily: theme.typography.fontFamily,
      width: width,
      resize: 'none' as const,
      padding: '5px',
      paddingLeft: '8px',
      outline: 'none' as const,
      height: height
    };

    const lightStyle = {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.secondary.main,
      border: 'none' as const
    };

    const darkStyle = {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.grey[100]
    };

    const variantStyle = variant === 'light' ? lightStyle : darkStyle;

    const inputStyle: CSSProperties = {
      ...preStyle,
      ...variantStyle
    };

    const handleChange = (e: any) => {
      setInputValue(e.target.value);
      if (onChangeValue) {
        onChangeValue(e.target.value);
      }
    };
    return (
      <textarea
        id={id}
        name={name}
        value={inputValue}
        onChange={e => handleChange(e)}
        onBlur={onBlur}
        style={inputStyle}
        placeholder={placeholderText}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
        data-testid={dataTestId}
        data-trackid={dataTrackId}
        ref={ref}
      />
    );
  }
);

export default TextArea;

import { CSSProperties, useMemo } from 'react';
import { Box, Skeleton, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Guid } from 'guid-typescript';

import {
  systemGeneratedPdf,
  systemGeneratedPdfName
} from '../../../core/constants';

interface FileDto {
  id: string;
  name: string;
  url: string;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  color: `${theme.palette.primary.main}`,
  border: `1px solid ${theme.palette.primary.main}`,
  marginLeft: '8px',
  backgroundColor: '#1E1E1E',
  '& .MuiChip-deleteIcon': {
    color: `${theme.palette.primary.main}`
  }
}));
const FileUploader = ({
  progressing,
  employeeFiles,
  addButtonLabel,
  shouldIncludeSystemGeneratedFile,
  onFileAdd,
  onFileClick,
  onFileRemove,
  validTypes
}: Readonly<{
  progressing: boolean;
  employeeFiles: FileDto[];
  addButtonLabel: string;
  shouldIncludeSystemGeneratedFile: boolean;
  onFileAdd: (fileName: string, file: File) => void;
  onFileClick: (fileUrl: string) => void;
  onFileRemove: (fileId: string) => void;
  validTypes: string;
}>) => {
  const attachedFiles = useMemo(() => {
    const tempAttachedFiles = employeeFiles.slice();
    if (shouldIncludeSystemGeneratedFile) {
      const systemGeneratedFile: FileDto = {
        id: `${systemGeneratedPdf}-${Guid.create().toString()}`,
        name: systemGeneratedPdfName,
        url: systemGeneratedPdf
      };

      tempAttachedFiles.unshift(systemGeneratedFile);
    }

    return tempAttachedFiles;
  }, []);

  return (
    <Box
      flex={1}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        backgroundColor: '#0E0D0D',
        margin: '10px 0',
        width: '100%'
      }}
    >
      {!progressing ? (
        <Box
          flexDirection={'row'}
          display={'flex'}
          alignItems={'center'}
          sx={{
            overflow: 'auto',
            height: '45px',
            width: '88%',
            '&::-webkit-scrollbar': {
              background: '#3E3E3E',
              height: '8px'
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 5px #3e3e3e',
              borderRadius: '5',
              background: '#3E3E3E'
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#D8D8D8',
              height: '8px',
              borderRadius: '5px'
            }
          }}
        >
          {attachedFiles?.map((attachment: FileDto) => {
            let icon;
            const canBeDeleted = attachment.url !== systemGeneratedPdf;

            return (
              <div key={attachment.id}>
                <StyledChip
                  icon={icon}
                  label={attachment.name}
                  onClick={() => onFileClick(attachment.url)}
                  onDelete={
                    canBeDeleted ? () => onFileRemove(attachment.id) : undefined
                  }
                />
              </div>
            );
          })}
        </Box>
      ) : (
        <Skeleton
          sx={{ marginLeft: '10px', marginRight: '10px' }}
          variant="rectangular"
          width={'80%'}
          height={25}
        />
      )}
      <label
        style={{
          ...addButtonStyle,
          ...{
            opacity: progressing ? 0.5 : 1,
            cursor: progressing ? 'none' : 'pointer'
          }
        }}
      >
        {addButtonLabel}
        <input
          type="file"
          accept={validTypes}
          style={{ display: 'none' }}
          onChange={event => {
            if (!event.target.files) return;
            const parts = event.target.value.split('\\');
            const fileName = parts[parts.length - 1];
            const selectedFile = event.target.files[0];
            onFileAdd(fileName, selectedFile);
            event.target.value = '';
          }}
          data-testid="fileUploaderTestId"
        />
      </label>
    </Box>
  );
};

const addButtonStyle: CSSProperties = {
  backgroundColor: '#FF9E00',
  height: '100%',
  borderTopRightRadius: '5px',
  borderBottomRightRadius: '5px',
  width: '70px',
  borderStyle: 'solid',
  borderWidth: '1px',
  cursor: 'pointer',
  fontSize: '16px',
  borderLeftStyle: 'none',
  minHeight: '45px',
  color: 'black',
  textAlign: 'center',
  marginBottom: 0,
  alignContent: 'center'
};

export default FileUploader;

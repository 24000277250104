import { ContentSelectorView } from './types';
import { LearningPlan } from '../../entities/LearningPlan';
import ContentLibraryVirtualList from '../ContentLibraryVirtualList/ContentLibraryVirtualList';
import { useSelector } from 'react-redux';
import LearningPlanVirtualRow from '../../pages/contentLibrary/Company/LearningPlanVirtualRow';
import React, { useContext, useEffect, useState } from 'react';
import { secondsToHHMMSS } from '../../utils/dateUtils';
import { SelectableLearningPlanCard } from '../LearningPlanCard/SelectableLearningPlanCard';
import { SelectableContext } from './SelectContentModal';
import useLearningUnitStatusFilter from '../../pages/contentLibrary/hooks/useLearningUnitStatusFilter';
import useLearningUnitSorting from '../../pages/contentLibrary/hooks/useLearningUnitSorting';
import { useDebounce } from '../../hooks/useDebounce';
import { DefaultSearchDebounceTime } from '../../core/constants';
import SearchInput from '../../pages/contentLibrary/common/SearchInput';
import ContentStatusFilter from '../../pages/contentLibrary/common/ContentStatusFilter';
import SortContentSelect from '../../pages/contentLibrary/common/SortContentSelect';
import { Stack } from '@mui/material';
import { generateLearningPlansToShow } from '../../utils/contentLibraryUtils';
import {
  ContentStatus,
  Sorting
} from '../../hooks/useContentLibraryNavigation';
import { SelectContentViewContainer } from './SelectContentViewContainer';

export const SelectLearningPlanView = ({
  selector
}: ContentSelectorView<LearningPlan>) => {
  const learningPlans = useSelector(selector);
  const [filteredLearningPlans, setFilteredLearningPlans] = useState<
    LearningPlan[]
  >([]);
  const { onSelect, internalSelectionHash } = useContext(SelectableContext);

  const [filterLearningUnitStatus, handleChangeLearningUnitStatus] =
    useLearningUnitStatusFilter();
  const [activeSort, handleChangeSorting] = useLearningUnitSorting();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const searchHandler = useDebounce<string>(
    value => setSearchTerm(value),
    DefaultSearchDebounceTime
  );

  useEffect(() => {
    if (learningPlans && learningPlans.length) {
      const newlyFilteredLearningPlans = generateLearningPlansToShow(
        learningPlans,
        searchTerm,
        filterLearningUnitStatus as ContentStatus,
        activeSort as Sorting
      );
      setFilteredLearningPlans(newlyFilteredLearningPlans);
    }
  }, [learningPlans, activeSort, filterLearningUnitStatus, searchTerm]);

  return (
    <SelectContentViewContainer
      filtersSection={
        <Stack direction="row" spacing={2}>
          <SearchInput onSearch={searchHandler} defaultValue={searchTerm} />
          <ContentStatusFilter
            value={filterLearningUnitStatus}
            onChange={handleChangeLearningUnitStatus}
          />
          <SortContentSelect
            value={activeSort}
            onChange={handleChangeSorting}
          />
        </Stack>
      }
      contentSection={
        <ContentLibraryVirtualList
          items={filteredLearningPlans ?? []}
          learningPlanUnit={'learningPlans'}
          isLoading={false}
          itemSize={375}
        >
          {({ data, index, style }) => (
            <LearningPlanVirtualRow index={index} style={style} data={data}>
              {learningPlan => (
                <SelectableLearningPlanCard
                  selected={internalSelectionHash.has(learningPlan.id)}
                  onSelect={onSelect}
                  selectable={{
                    label: learningPlan.name,
                    id: learningPlan.id,
                    type: 'LearningPlan'
                  }}
                  averageLessonTime={
                    learningPlan.avgLessonTime
                      ? secondsToHHMMSS(learningPlan.avgLessonTime)
                      : '00:00:00'
                  }
                  isDraft={learningPlan.isDraft}
                  learningPlan={learningPlan}
                  wrapperWidth="100%"
                />
              )}
            </LearningPlanVirtualRow>
          )}
        </ContentLibraryVirtualList>
      }
    />
  );
};

export const actions = {
  SET_ASSIGNMENTS: 'set_assignments',
  GET_ASSINGMENTS: 'get_assignments',
  SET_DUE_TYPE: 'set_duetype',
  SET_ERROR: 'set_error',
  UNASSIGN_ASSIGNMENT: 'unassign_assignment',
  UPDATE_DUEDATE: 'update_duedate'
};

type stateType = {
  assignments: any[];
  filteredAssignments: any[];
  dueType: 'assigned' | 'workload';
  isLoading: boolean;
  error?: string;
};

export const defaultState: stateType = {
  assignments: [],
  filteredAssignments: [],
  dueType: 'assigned',
  isLoading: true
};

export const getFilteredAssignments = (
  assignments: any[],
  dueType: stateType['dueType']
) =>
  assignments.filter(
    x =>
      (dueType === 'assigned' && x.assignmentType !== 'Workload') ||
      (dueType === 'workload' && x.assignmentType === 'Workload')
  );

export const reducer = (
  state: stateType,
  action: { type: string; payload?: any }
) => {
  switch (action.type) {
    case actions.SET_ASSIGNMENTS: {
      return {
        ...state,
        isLoading: false,
        assignments: action.payload,
        filteredAssignments: getFilteredAssignments(
          action.payload,
          state.dueType
        )
      };
    }
    case actions.GET_ASSINGMENTS: {
      return { ...state, isLoading: true, error: undefined };
    }
    case actions.SET_DUE_TYPE: {
      return {
        ...state,
        dueType: action.payload,
        filteredAssignments: getFilteredAssignments(
          state.assignments,
          action.payload
        )
      };
    }
    case actions.SET_ERROR: {
      return { ...state, error: action.payload, isLoading: false };
    }
    case actions.UNASSIGN_ASSIGNMENT: {
      const newAssignments = state.assignments.filter(
        x => x.id !== action.payload
      );
      return {
        ...state,
        assignments: newAssignments,
        filteredAssignments: getFilteredAssignments(
          newAssignments,
          state.dueType
        ),
        isLoading: false
      };
    }
    case actions.UPDATE_DUEDATE: {
      const assignmentIndex = state.assignments.findIndex(
        x => x.id === action.payload.id
      );
      const newAssignments = [...state.assignments];
      newAssignments[assignmentIndex].dueDateUtc = action.payload.dueDateUtc;
      return {
        ...state,
        assignments: newAssignments,
        filteredAssignments: getFilteredAssignments(
          newAssignments,
          state.dueType
        )
      };
    }
    default:
      return state;
  }
};

import React, { CSSProperties } from 'react';
import {
  Box,
  Card,
  CardMedia,
  styled,
  Chip,
  CardContent,
  CardActions,
  Typography,
  useTheme,
  Stack
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import { AssignedOnsiteTrainingClass } from '../../entities/LearningPlaylist';
import BackgroundCoursePattern from '../../assets/BackgroundCoursePattern.svg';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import BasicButton from '../core/BasicButton/BasicButton';
import { useNavigate } from 'react-router';
import useCurrentUser from '../../hooks/useCurrentUser';
import { UserClaims } from '../../core/constants';
import { OnsiteTrainingAttendanceType } from '../../core/enums';

const CustomCard = styled(Card)(({ theme }) => ({
  width: 370,
  minHeight: 325,
  borderRadius: 15,
  position: 'relative',
  border: `1px solid ${theme.palette.grey[100]}`
}));

const ContentOverThumbnail = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  margin: '12px'
}));

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white
}));

const OnsiteTrainingDates = ({
  assignedClass
}: {
  assignedClass: AssignedOnsiteTrainingClass;
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining']);
  const assignmentDates = assignedClass.assignments
    .map(item => item.dateUtc)
    .sort((a: Date, b: Date) => (a > b ? 1 : -1));
  const currentDate = moment().format('MM.DD.YYYY');
  return (
    <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap' }}>
      <Typography
        sx={{
          fontSize: '13px',
          color: theme.palette.grey[50]
        }}
      >
        {t('onsiteTrainingDates')}:
      </Typography>
      {assignmentDates.map((date: Date, index: number, array: Date[]) => {
        const formattedDate =
          absUtcDateToLocalMoment(date).format('MM.DD.YYYY');
        const color =
          formattedDate === currentDate
            ? theme.palette.primary.main
            : theme.palette.common.white;

        return (
          <React.Fragment
            key={`${date.toString()}-${assignedClass.onsiteTrainingClass.id}`}
          >
            <Typography sx={{ fontSize: '13px', color: color }}>
              {absUtcDateToLocalMoment(date).format('MM.DD.YYYY')}
            </Typography>
            {!!(index < array.length - 1) && <span>&#8226;</span>}
          </React.Fragment>
        );
      })}
    </Stack>
  );
};

interface OnsiteTrainingAssignmentCardProps {
  assignedClass: AssignedOnsiteTrainingClass;
}

const AssignmentOnsiteTrainingCard = ({
  assignedClass
}: OnsiteTrainingAssignmentCardProps) => {
  const { t } = useTranslation(['onsiteTraining', 'common']);
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { thumbnail, name } = assignedClass.onsiteTrainingClass.onsiteTraining;
  const startButtonIsEnabled = (() => {
    const currentDate = moment();
    const assignmentDates = assignedClass.assignments
      .map(item => absUtcDateToLocalMoment(item.dateUtc))
      .sort((a: Moment, b: Moment) => (a.isAfter(b) ? 1 : -1));
    const currentDayAssignment = assignedClass.assignments.filter(
      item =>
        absUtcDateToLocalMoment(item.dateUtc).format('MM.DD.YYYY') ===
        currentDate.format('MM.DD.YYYY')
    )[0];

    return assignmentDates.some(
      date =>
        currentDate.format('MM.DD.YYYY') === date.format('MM.DD.YYYY') &&
        currentDate.isSameOrAfter(date) &&
        currentDayAssignment?.attendance !==
          OnsiteTrainingAttendanceType.Requested &&
        currentDayAssignment?.attendance !==
          OnsiteTrainingAttendanceType.Attended
    );
  })();

  const goToOnsiteTrainingCheckIn = () => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;
    if (assignedClass.onsiteTrainingClass.instructorId === employeeId) {
      navigate(
        `/onsite-training-check-in/${assignedClass.onsiteTrainingClass.id}`,
        {
          state: {
            onsiteTraining: assignedClass.onsiteTrainingClass.onsiteTraining,
            onsiteTrainingClass: assignedClass.onsiteTrainingClass
          }
        }
      );
    } else {
      navigate(
        `/onsite-training-class/${assignedClass.onsiteTrainingClass.id}`
      );
    }
  };

  return (
    <CustomCard>
      <ContentOverThumbnail>
        <CustomChip label={t('onsiteTraining', { ns: 'onsiteTraining' })} />
      </ContentOverThumbnail>
      <CardMedia
        image={thumbnail ?? BackgroundCoursePattern}
        title={name}
        sx={{
          width: '100%',
          height: '150px'
        }}
      />
      <CardContent>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>
          {name}
        </Typography>
        <OnsiteTrainingDates assignedClass={assignedClass} />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {!assignedClass.isComplete && (
          <BasicButton
            onClick={() => {
              goToOnsiteTrainingCheckIn();
            }}
            color="primary"
            style={ButtonStyle}
            disable={!startButtonIsEnabled}
          >
            {t('start', { ns: 'common' })}
          </BasicButton>
        )}
      </CardActions>
    </CustomCard>
  );
};

const ButtonStyle: CSSProperties = {
  width: '108px',
  height: '34px',
  position: 'absolute',
  bottom: '18px',
  right: '18px'
};

export default AssignmentOnsiteTrainingCard;

import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

import { MyCertification } from '../../../../entities/Certification';
import FileUploader from '../../../../components/core/FileUploader/FileUploader';

export const getCertificationName = (headerName: string) => ({
  headerName: headerName,
  field: 'certificationName',
  sortable: true,
  minWidth: 300,
  renderCell: (params: GridRenderCellParams<MyCertification>) => (
    <Typography>{params.row.certificationName}</Typography>
  )
});

export const getAttachmentsColumns = (
  headerName: string,
  inProgress: boolean,
  FileUploadButtonCaption: string,
  onFileAdded: (
    myCertification: MyCertification
  ) => (fileName: string, file: File) => void,
  onFileRemoved: (fileId: string) => void,
  onFileClick: (url: string) => void
) => ({
  field: 'attachments',
  headerName: headerName,
  width: 487,
  sortable: false,
  renderCell: (params: GridRenderCellParams<MyCertification>) => {
    const files = params.row.attachedFiles.map(file => ({
      id: file.competenceFile.id,
      name: file.competenceFile.name,
      url: file.competenceFile.url
    }));

    return (
      <div
        style={{
          display: 'flex',
          width: '98%',
          paddingTop: '0.5rem',
          paddingBottom: '0.5rem'
        }}
      >
        <FileUploader
          progressing={inProgress}
          employeeFiles={files}
          shouldIncludeSystemGeneratedFile={
            params.row.generateSystemCertificate
          }
          addButtonLabel={FileUploadButtonCaption}
          onFileAdd={onFileAdded(params.row)}
          onFileClick={onFileClick}
          onFileRemove={onFileRemoved}
          validTypes={'.pdf,image/*'}
        />
      </div>
    );
  }
});

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tab,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { actionCreators as employeeActions } from '../../actions/employees';
import { UserRoles, UserClaims } from '../../core/constants';
import useCurrentUser from '../../hooks/useCurrentUser';
import useUserRole from '../../hooks/useUserRole';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import EmployeesHaltPanel from './EmployeesHaltPanel';
import GroupsHaltPanel from './GroupsHaltPanel';
import DatePickerInput from '../core/DatePickerInput/DatePickerInput';
import BasicButton from '../core/BasicButton/BasicButton';
import { enqueueSnackbar } from 'notistack';
import { TransactionStatusEnum } from '../../core/enums';
import { actionCreators as haltActions } from '../../actions/halts';
import { useAppDispatch } from '../../store/hooks';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-root {
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
    background: #1b1c1c;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

export interface NewEmployeeTrainingHaltModalProps {
  showModal: boolean;
  onClose: () => void;
  onSave: (
    startDate: Date,
    endDate: Date,
    employeeIds: string[],
    groupIds: string[]
  ) => Promise<void>;
  savingInProgress: boolean;
}

type DateRangeType = {
  startDate: Date | null;
  endDate: Date | null;
};

const NewEmployeeTrainingHaltModal: React.FC<
  NewEmployeeTrainingHaltModalProps
> = ({ showModal, onClose, onSave, savingInProgress }) => {
  const [userHasRole] = useUserRole();
  const user = useCurrentUser();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation(['settings']);
  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const haltCreatedFlag = useSelector(
    (state: ApplicationState) => state.halts?.haltCreatedState
  );
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState<string[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);
  const [valueTab, setValueTab] = useState<string>('employees');
  const dateRangeRef = useRef<DateRangeType>();
  const [error, setError] = useState<string>('');
  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;
    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(employeeActions.requestEmployeeGroups(companyId));
      dispatch(employeeActions.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        employeeActions.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }
  }, []);

  useMemo(() => {
    if (haltCreatedFlag === TransactionStatusEnum.Successfull) {
      enqueueSnackbar(t('haltSaved'), {
        variant: 'success',
        autoHideDuration: 3000
      });
      dispatch(haltActions.resetHaltCreatedState());
    }
  }, [haltCreatedFlag]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValueTab(newValue);
  };

  const handleChangeDateRange = (startDate: Date, endDate: Date) => {
    dateRangeRef.current = {
      startDate,
      endDate
    };
  };

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  const onSaveDates = async () => {
    if (
      dateRangeRef.current?.startDate === undefined ||
      dateRangeRef.current?.endDate === undefined
    ) {
      return;
    }

    if (error.length) setError('');

    if (!dateRangeRef.current?.startDate || !dateRangeRef.current?.endDate) {
      setError(t('noDatesForHaltErrorMsg'));
      enqueueSnackbar(t('noDatesForHaltErrorMsg'), {
        variant: 'error',
        autoHideDuration: 3000
      });
      return;
    }
    switch (valueTab) {
      case 'employees':
        if (!selectedEmployeeIds.length) {
          enqueueSnackbar(t('noEmployeesSelectedMsg'), {
            variant: 'error',
            autoHideDuration: 3000
          });
          return;
        }
        await onSave(
          dateRangeRef.current.startDate,
          dateRangeRef.current.endDate,
          selectedEmployeeIds,
          []
        );
        break;
      case 'groups':
        if (!selectedGroupIds.length) {
          enqueueSnackbar(t('noGroupsSelectedMsg'), {
            variant: 'error',
            autoHideDuration: 3000
          });
          return;
        }
        onSave(
          dateRangeRef.current.startDate,
          dateRangeRef.current.endDate,
          [],
          selectedGroupIds
        );
        break;
    }
  };
  return (
    <CustomDialog
      open={showModal}
      onClose={onClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <Grid>
          <Stack direction={'column'}>
            <Typography style={titleStyle}>
              {t('newEmployeeTrainingHalt')}
            </Typography>
            <Typography style={subTitleStyle}>
              {t('newEmployeeTrainingHaltMessage')}
              <span style={{ color: 'red', fontSize: '15px' }}>*</span>
            </Typography>
          </Stack>
        </Grid>
        <IconButton aria-label="close" onClick={onClose} sx={iconStyle}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={dialogContentStyle}>
        <TabContext value={valueTab}>
          <TabList
            onChange={handleChangeTab}
            textColor="primary"
            indicatorColor="primary"
            sx={() => ({
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '20px'
              },
              borderBottom: `1px solid #999999`,
              '& .MuiTabs-indicator': { height: '4px' }
            })}
          >
            <Tab
              value="employees"
              label={t('employees')}
              style={{ paddingLeft: 0 }}
            />
            <Tab
              value="groups"
              label={t('groups')}
              style={{ paddingLeft: 0 }}
            />
          </TabList>
          <TabPanel value="employees" sx={{ padding: '15px 0 5px 0' }}>
            <EmployeesHaltPanel
              employees={employees ?? []}
              setSelectedEmployeeIds={setSelectedEmployeeIds}
              selectedEmployeeIds={selectedEmployeeIds}
              savingInProgress={savingInProgress}
            />
          </TabPanel>
          <TabPanel value="groups" sx={{ padding: '15px 0 5px 0' }}>
            <GroupsHaltPanel
              groupsList={employeeGroups ?? []}
              setSelectedGroupIds={setSelectedGroupIds}
              selectedGroupIds={selectedGroupIds}
              savingInProgress={savingInProgress}
            />
          </TabPanel>
        </TabContext>
        <Typography style={{ ...subTitleStyle, paddingLeft: 0 }}>
          {t('newEmployeeTrainingHaltMsgDate')}
          <span style={{ color: 'red', fontSize: '15px' }}>*</span>
        </Typography>
        <Grid item style={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <DatePickerInput
            disablePastDays={true}
            selectsRange={true}
            onChangeDateRange={handleChangeDateRange}
            backgroundColorBlack={true}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: '22px', justifyContent: 'center' }}>
        <BasicButton
          color="primary"
          width="160px"
          height="45px"
          onClick={onSaveDates}
          loading={savingInProgress}
        >
          {t('saveHalt')}
        </BasicButton>
      </DialogActions>
    </CustomDialog>
  );
};
const titleStyle: CSSProperties = {
  fontSize: '20px',
  paddingLeft: '31px',
  paddingTop: '21px'
};

const subTitleStyle: CSSProperties = {
  paddingTop: '15px',
  fontSize: '15px',
  paddingLeft: '31px'
};

const dialogContentStyle: CSSProperties = {
  paddingLeft: '31px',
  paddingRight: '31px',
  paddingTop: '10px'
};
export default NewEmployeeTrainingHaltModal;

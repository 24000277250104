import React from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '../../../core/FormLabel/FormLabel';
import TimeInput from '../../../core/TimeInput/TimeInput';

export interface LessonCommonInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  isDisabled?: boolean;
}

const LessonDurationInput: React.FC<LessonCommonInputProps<string>> = ({
  value,
  onChange,
  isDisabled
}) => {
  const { t } = useTranslation(['common', 'lessons']);

  return (
    <>
      <FormLabel> {t('lessonDuration', { ns: 'lessons' })}</FormLabel>
      <TimeInput
        value={value}
        onChangeValue={onChange}
        width="100%"
        height="35px"
        variant="dark"
        dataTestId="lessonDurationInput"
        dataTrackId="lessonDurationInput"
      />
    </>
  );
};

export default LessonDurationInput;

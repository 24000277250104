import { createContext } from 'react';
import { EngagementType } from '../core/enums';

export interface IAnalyticsFilter {
  includeSelfAssignments: boolean;
  activeOnly: boolean;
  subscribedOnly: boolean;
  engagementViewType?: EngagementType;
  employeesGroupId?: string;
}

export interface IAnalyticsCommonFilter {
  supervisorId: string;
  groupId: string;
}

export const AnalyticsFilterContext = createContext<IAnalyticsFilter>({
  includeSelfAssignments: false,
  activeOnly: false,
  subscribedOnly: false
});

export const AnalyticsCommonFilterContext =
  createContext<IAnalyticsCommonFilter>({
    supervisorId: '',
    groupId: ''
  });

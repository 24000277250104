import React, { CSSProperties } from 'react';
import { AnswerAverage, Question } from '../../../entities/Assessment';
import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QuestionTitleAnalytics from './QuestionTitleAnalytics';

export interface TrueFalseAverageQuestionProps {
  answerAverage: AnswerAverage;
  question: Question;
}

const TrueFalseAverageQuestion: React.FC<TrueFalseAverageQuestionProps> = ({
  answerAverage,
  question
}) => {
  const { t } = useTranslation(['assessments']);
  const theme = useTheme();
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.light;
  const falseLabel = `${t('false', { ns: 'assessments' })}`;
  const trueLabel = `${t('true', { ns: 'assessments' })}`;
  const correctReponseLabel = `${t('correctResponse', { ns: 'assessments' })}`;
  const options = [true, false];
  const backGroundColor =
    (question.order! + 1) % 2 === 0
      ? theme.palette.secondary.main
      : theme.palette.secondary.dark;
  const correctResponseWithIconComp = () => (
    <Grid>
      {options.map(option => {
        const isCorrectAnswer = question.trueIsCorrectAnswer === option;
        const colorAverage = isCorrectAnswer ? greenColor : redColor;
        const colorText = isCorrectAnswer ? greenColor : '';
        return (
          <Grid
            key={option ? trueLabel : falseLabel}
            container
            item
            direction={'row'}
            style={gridCorrectReponseIcon}
            display={'flex'}
            alignItems={'center'}
          >
            <Typography style={correctResponseLabelStyle}>
              {isCorrectAnswer ? correctReponseLabel : ''}
            </Typography>
            <Typography
              style={{ ...gradeLabelStyle, color: colorAverage }}
              align={'right'}
            >
              {`${option ? answerAverage.trueAnswerAverage : answerAverage.falseAnswerAverage}%`}
            </Typography>
            <Grid
              style={{
                paddingLeft: '10px',
                paddingRight: '22px',
                borderLeft: '1px solid #363636'
              }}
            >
              <Grid style={{ padding: '10px 0px 10px 10px' }}>
                <Typography style={{ color: colorText }}>
                  {option ? trueLabel : falseLabel}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Grid style={{ backgroundColor: backGroundColor }}>
      <QuestionTitleAnalytics
        index={question.order! + 1}
        questionText={question.questionText}
      />
      <Grid container direction={'row'} style={{ paddingTop: '12px' }}>
        <Grid
          container
          item
          direction={'column'}
          style={gridContainerCorrectResponse}
        >
          {correctResponseWithIconComp()}
        </Grid>
      </Grid>
    </Grid>
  );
};

const gradeLabelStyle: CSSProperties = {
  width: '75px',
  fontSize: '24px',
  height: '35px',
  fontWeight: '600',
  paddingRight: '15px'
};
const gridCorrectReponseIcon: CSSProperties = {
  borderRight: '1px solid #363636'
};

const correctResponseLabelStyle: CSSProperties = {
  width: '135px',
  fontSize: '13px',
  color: '#C4C4C4'
};

const gridContainerCorrectResponse: CSSProperties = {
  paddingTop: '10px',
  paddingBottom: '30px',
  paddingLeft: '40px'
};
export default TrueFalseAverageQuestion;

import axios, { AxiosError } from 'axios';
import { AppThunk } from '../store';
import config from './../config';
import {
  HALTS_SET_MONTH_HALTS_DATA,
  HALTS_SET_TODAY_HALTS_DATA,
  HALTS_UPDATE_PICKED_CALENDAR_DATE,
  HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA,
  HALTS_SET_YOUR_HALTS_DATA,
  HALTS_SET_REFRESH_CALENDAR,
  HALTS_SET_HALT_CREATED_FLAG
} from './actionTypes';
import { Halt, HaltEmployee } from '../entities/Halt';
import moment from 'moment';
import { TransactionStatusEnum } from '../core/enums';
import { handleGenericBackendError } from '../utils/errorHandling';

export const actionCreators = {
  getMonthHalts:
    (year: number, month: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}halts/by/year/${year}/month/${month}`
          );

          dispatch({
            type: HALTS_SET_MONTH_HALTS_DATA,
            monthHalts: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setMonthHalts: (data: Halt[] | null) => ({
    type: HALTS_SET_MONTH_HALTS_DATA,
    monthHalts: data
  }),
  getTodayHalts: (): AppThunk<Promise<void>> => async (dispatch, getState) => {
    const appState = getState();

    if (appState) {
      try {
        const momentDate = moment();
        const day =
          momentDate.date() < 10
            ? '0' + momentDate.date()
            : momentDate.date().toString();
        const month =
          momentDate.month() < 10
            ? '0' + (momentDate.month() + 1).toString()
            : (momentDate.month() + 1).toString();
        const strDate = `${momentDate.year()}-${day}-${month}`;

        const res = await axios.get(
          `${config.STUDENT_API_URL}halts/byDate/${strDate}`
        );

        dispatch({
          type: HALTS_SET_TODAY_HALTS_DATA,
          todayHalts: res.data
        });
      } catch (e) {
        handleGenericBackendError(e);
      }
    }
  },
  setTodayHalts: (data: HaltEmployee[] | null) => ({
    type: HALTS_SET_TODAY_HALTS_DATA,
    todayHalts: data
  }),
  deleteHalts:
    (haltIds: string[]): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        await axios.put(`${config.STUDENT_API_URL}halts/deleteHalts`, haltIds);
      }
    },
  getMonthHaltsForEmployee:
    (year: number, month: number): AppThunk =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}halts/employee/by/year/${year}/month/${month}`
          );

          dispatch({
            type: HALTS_SET_MONTH_HALTS_DATA,
            monthHalts: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  getHaltsByDate:
    (date: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}halts/byDate/${date}`
          );

          dispatch({
            type: HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA,
            pickedCalendarDateHalts: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setPickedCalendarDate: (data: string) => ({
    type: HALTS_UPDATE_PICKED_CALENDAR_DATE,
    pickedCalendarDate: data
  }),
  setHaltsByDate: (data: HaltEmployee[] | null) => ({
    type: HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA,
    pickedCalendarDateHalts: data
  }),
  getEmployeeHalts:
    (haltType?: string): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          const res = await axios.get(
            `${config.STUDENT_API_URL}halts/employee/all/${haltType ?? ''}`
          );

          dispatch({
            type: HALTS_SET_YOUR_HALTS_DATA,
            yourHalts: res.data
          });
        } catch (e) {
          handleGenericBackendError(e);
        }
      }
    },
  setEmployeeHalts: (data: Halt[] | null) => ({
    type: HALTS_SET_YOUR_HALTS_DATA,
    yourHalts: data
  }),
  saveHaltsForAllCompanyMembers:
    (startDate: Date, endDate: Date): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.post(`${config.STUDENT_API_URL}halts/all/company`, {
            startDateUtc: startDate,
            endDateUtc: endDate
          });
        } catch (e) {
          throw new Error((e as AxiosError).message);
        }
      }
    },
  savePersonalHalt:
    (startDate: Date, endDate: Date): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.post(`${config.STUDENT_API_URL}halts/personal`, {
            startDateUtc: startDate,
            endDateUtc: endDate
          });
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.data) {
            const dataError = new Error(e.response.data as string);
            Object.assign(dataError, { isDataError: true });
            throw dataError;
          } else {
            throw new Error((e as AxiosError).message);
          }
        }
      }
    },
  saveCompanyHaltsForEmployees:
    (
      startDate: Date,
      endDate: Date,
      employeeIds: string[]
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.post(
            `${config.STUDENT_API_URL}halts/companyHaltsForEmployees`,
            {
              startDateUtc: startDate,
              endDateUtc: endDate,
              employeesIds: employeeIds
            }
          );
          dispatch({
            type: HALTS_SET_HALT_CREATED_FLAG,
            haltCreatedState: TransactionStatusEnum.Successfull
          });
        } catch (e) {
          throw new Error((e as AxiosError).message);
        }
      }
    },
  saveCompanyHaltsForGroups:
    (
      startDate: Date,
      endDate: Date,
      groupsIds: string[]
    ): AppThunk<Promise<void>> =>
    async (dispatch, getState) => {
      const appState = getState();

      if (appState) {
        try {
          await axios.post(
            `${config.STUDENT_API_URL}halts/companyHaltsForGroups`,
            {
              startDateUtc: startDate,
              endDateUtc: endDate,
              groupIds: groupsIds
            }
          );
          dispatch({
            type: HALTS_SET_HALT_CREATED_FLAG,
            haltCreatedState: TransactionStatusEnum.Successfull
          });
        } catch (e) {
          throw new Error((e as AxiosError).message);
        }
      }
    },
  resetHaltCreatedState: (): AppThunk => (dispatch, getState) => {
    const appState = getState();
    if (appState) {
      dispatch({
        type: HALTS_SET_HALT_CREATED_FLAG,
        haltCreatedState: TransactionStatusEnum.None
      });
    }
  },
  refreshCalendar: (refresh: boolean) => ({
    type: HALTS_SET_REFRESH_CALENDAR,
    refreshCalendar: refresh
  })
};
export interface GetMonthHalts {
  type: 'HALTS_SET_MONTH_HALTS_DATA';
  monthHalts: Halt[] | null;
}

export interface GetTodayHalts {
  type: 'HALTS_SET_TODAY_HALTS_DATA';
  todayHalts: HaltEmployee[] | null;
}

export interface UpdatePickedCalendarDate {
  type: 'HALTS_UPDATE_PICKED_CALENDAR_DATE';
  pickedCalendarDate: string;
}

export interface GetPickedCalendarDateHalts {
  type: 'HALTS_SET_PICKED_CALENDAR_DATE_HALTS_DATA';
  pickedCalendarDateHalts: HaltEmployee[] | null;
}

export interface GetYourHalts {
  type: 'HALTS_SET_YOUR_HALTS_DATA';
  yourHalts: Halt[] | null;
}

export interface RefreshCalendar {
  type: 'HALTS_SET_REFRESH_CALENDAR';
  refreshCalendar: boolean;
}

export interface SetHaltCreatedFlag {
  type: 'HALTS_SET_HALT_CREATED_FLAG';
  haltCreatedState: TransactionStatusEnum;
}

export type KnownAction =
  | GetMonthHalts
  | GetTodayHalts
  | UpdatePickedCalendarDate
  | GetPickedCalendarDateHalts
  | GetYourHalts
  | RefreshCalendar
  | SetHaltCreatedFlag;

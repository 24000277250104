import * as firebase from '../firebase';
import { logEvent } from 'firebase/analytics';
import useCurrentUser from '../hooks/useCurrentUser';
import { UserClaims } from '../core/constants';
import config from '../config';

export const useLogEvent = () => {
  const user = useCurrentUser();
  const employeeId: string = user
    ? (user?.profile[UserClaims.EmployeeId] as string)
    : '';
  const userId: string = user
    ? (user?.profile[UserClaims.UserId] as string)
    : '';
  const companyId: string = user
    ? (user?.profile[UserClaims.CompanyId] as string)
    : '';
  const companyName: string = user
    ? (user?.profile[UserClaims.CompanyName] as string)
    : '';
  const bwtEnv: string = config.ENVIRONMENT;

  const isFreeTrial = user?.profile[UserClaims.IsFreeTrial];

  const bwtStatus = isFreeTrial ? 'trialing' : 'active';

  const logEventObject = {
    logPlayLessonEvent: (lessonId: string, lessonTitle: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'play_lesson', {
          lesson_id: lessonId,
          lesson_title: lessonTitle,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logStartCourseEvent: (
      courseId?: string,
      revisionNumber?: number,
      courseTitle?: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'start_course', {
          course_id: courseId,
          course_title: courseTitle,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logStartLearningPlanEvent: (
      learningPlanId?: string,
      learningPlanName?: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'start_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteLessonEvent: (
      lessonId: string,
      lessonTitle: string,
      courseId?: string,
      courseRevision?: number,
      courseTitle?: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_lesson', {
          lesson_id: lessonId,
          lesson_title: lessonTitle,
          course_id: courseId,
          course_title: courseTitle,
          course_revision: courseRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteCourseEvent: (courseId?: string, courseRevision?: number) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_course', {
          course_id: courseId,
          course_revision: courseRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteLearningPlanEvent: (
      learningPlanId: string,
      learningPlanName: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteLessonAdminEvent: (
      lessonId: string,
      lessonTitle: string,
      employeeId: string,
      courseId?: string,
      courseRevision?: number,
      courseTitle?: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_lesson', {
          lesson_id: lessonId,
          lesson_title: lessonTitle,
          course_id: courseId,
          course_title: courseTitle,
          course_revision: courseRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteCourseAdminEvent: (
      employeeId: string,
      courseId?: string,
      courseRevision?: number
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_course', {
          course_id: courseId,
          course_revision: courseRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCompleteLearningPlanAdminEvent: (
      employeeId: string,
      learningPlanId: string,
      learningPlanName: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'complete_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logPublishLearningPlanEvent: (
      learningPlanId: string,
      learningPlanCompanyId: string,
      learningPlanName: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'publish_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          learning_plan_company_id: learningPlanCompanyId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logPublishCourseEvent: (
      courseId: string,
      courseRevision: number,
      courseTitle: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'publish_course', {
          course_id: courseId,
          course_title: courseTitle,
          course_revision: courseRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCreateCourseEvent: (courseId: string, courseTitle: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'create_course', {
          course_id: courseId,
          course_title: courseTitle,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignLearningPlanEvent: (
      learningPlanId: string,
      learningPlanCompanyId: string,
      assignedToEmployeeId: string,
      learningPlanName: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          learning_plan_company_id: learningPlanCompanyId,
          assigned_to_employee_id: assignedToEmployeeId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCreateLearningPlanEvent: (
      learningPlanId: string,
      learningPlanCompanyId: string,
      learningPlanName: string,
      learningPlanRevision: number
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'create_learning_plan', {
          learning_plan_id: learningPlanId,
          learning_plan_name: learningPlanName,
          learning_plan_company_id: learningPlanCompanyId,
          learning_plan_revision: learningPlanRevision,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAddCourseToLearningPlanEvent: (
      courseId: string,
      learningPlanId: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'add_course_to_learning_plan', {
          course_id: courseId,
          learning_plan_id: learningPlanId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignLessonEvent: (
      lessonId: string,
      assignedToEmployeeId: string,
      lessonTitle?: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_lesson', {
          lesson_id: lessonId,
          lesson_title: lessonTitle,
          assigned_to_employee_id: assignedToEmployeeId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logCreateLessonEvent: (lessonId: string, lessonTitle: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'create_lesson', {
          lesson_id: lessonId,
          lesson_title: lessonTitle,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignCourseEvent: (courseId: string, assignedToEmployeeId: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_course', {
          course_id: courseId,
          assigned_to_employee_id: assignedToEmployeeId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logViewDailyLessonsHelpEvent: () => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'view_daily_lessons_help', {
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logViewAssignmentsHelpEvent: () => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'view_assignments_help', {
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logViewNewContentHelpEvent: () => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'view_new_content_help', {
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtStatus
        });
      }
    },
    logViewDueTodayHelpEvent: () => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'view_due_today_help', {
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logViewRecentlyWorkedOnHelpEvent: () => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'view_recently_worked_on_help', {
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignCourseToGroupEvent: (courseId: string, groupId: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_course_to_group', {
          course_id: courseId,
          assigned_to_group_id: groupId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignLessonToGroupEvent: (lessonId: string, groupId: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_lesson_to_group', {
          lesson_id: lessonId,
          assigned_to_group_id: groupId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logAssignLearningPlanToGroupEvent: (
      learningPlanId: string,
      groupId: string
    ) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'assign_learning_plan_to_group', {
          learning_plan_id: learningPlanId,
          assigned_to_group_id: groupId,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    },
    logLibrarySearchEvent: (searchTerm: string) => {
      if (employeeId.length && userId.length) {
        logEvent(firebase.analytics, 'library_search', {
          search_term: searchTerm,
          employee_id: employeeId,
          user_guid: userId,
          company_id: companyId,
          company_name: companyName,
          bwt_env: bwtEnv,
          bwt_status: bwtStatus
        });
      }
    }
  };

  return logEventObject;
};

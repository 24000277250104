import React, { CSSProperties, useState } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import Popover, { PopoverProps } from '@mui/material/Popover';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import {
  AssignmentStatusDto,
  EmployeeAssignmentStatus
} from '../../entities/Assignment';
import { absUtcDateToLocalMoment } from '../../utils/dateUtils';
import { EnrollmentTypes } from '../../core/constants';
import { lowerCaseFirstLetter } from '../../utils/stringUtils';

export interface AssignmentStatusChipsProps {
  learningUnitAssigmentStatus: EmployeeAssignmentStatus;
}

const titleStyle: CSSProperties = {
  color: '#0E0D0D',
  fontWeight: '400'
};

const contentStyle: CSSProperties = {
  color: '#0E0D0D',
  fontWeight: 'bold',
  display: 'flex',
  flexDirection: 'column'
};

const itemStyle: CSSProperties = {
  color: '#0E0D0D',
  fontWeight: 'bold'
};

const boxStyle: SxProps = {
  backgroundColor: '#C4C4C4'
};

const CustomPopover = ({
  anchorEl,
  children,
  ...props
}: Omit<
  PopoverProps,
  'open' | 'anchorOrigin' | 'transformOrigin' | 'disableRestoreFocus'
>) => {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      sx={{
        pointerEvents: 'none'
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disableRestoreFocus
      {...props}
    >
      {children}
    </Popover>
  );
};

const CurrentlyAssignmentContent = ({
  assignment
}: {
  assignment: AssignmentStatusDto;
}) => {
  const { t } = useTranslation(['assignments']);
  const { assignmentLevel, assignmentDateUtc, assignmentType } = assignment;

  const assignmentTypeLocationKey = (() => {
    if (assignmentType === EnrollmentTypes.EmployeesWorkloadBudget) {
      return 'workloadBudget';
    } else {
      return lowerCaseFirstLetter(assignmentType);
    }
  })();

  return (
    <div style={contentStyle}>
      <span>
        <span style={titleStyle}>{`${t('assignmentLevel')}: `}</span>
        {`${t(assignmentLevel)}`}
      </span>
      <span>
        <span style={titleStyle}>{`${t('assignmentMode')}: `}</span>
        {t(assignmentTypeLocationKey)}
      </span>
      {assignmentDateUtc && (
        <span>
          <span style={titleStyle}>{`${t('assignmentDate')}: `}</span>
          {absUtcDateToLocalMoment(assignmentDateUtc).format('MM.DD.YY')}
        </span>
      )}
    </div>
  );
};

const AssignmentsContent = ({
  assignment,
  index
}: {
  assignment: AssignmentStatusDto;
  index: number;
}) => {
  const { t } = useTranslation(['assignments']);
  const {
    assignmentLevel,
    assignmentDateUtc,
    completionDateUtc,
    assignmentType
  } = assignment;

  const assignmentTypeLocationKey = (() => {
    if (assignmentType === EnrollmentTypes.EmployeesWorkloadBudget) {
      return 'workloadBudget';
    } else {
      return lowerCaseFirstLetter(assignmentType);
    }
  })();

  return (
    <div style={contentStyle}>
      {index > 0 && (
        <span style={{ paddingTop: '0.5rem' }}>
          <hr />
        </span>
      )}
      <span>
        <span style={titleStyle}>{`${t('assignmentLevel')}: `}</span>
        {t(assignmentLevel)}
      </span>
      <span>
        <span style={titleStyle}>{`${t('assignmentMode')}: `}</span>
        {t(assignmentTypeLocationKey)}
      </span>
      <span>
        {assignmentDateUtc && (
          <span>
            <span style={titleStyle}>{`${t('assignmentDate')}: `}</span>
            {absUtcDateToLocalMoment(assignmentDateUtc).format('MM.DD.YY')}
          </span>
        )}
      </span>
      <span>
        <span style={titleStyle}>{`${t('completionDate')}: `}</span>
        {absUtcDateToLocalMoment(completionDateUtc!).year() > 0
          ? absUtcDateToLocalMoment(completionDateUtc!).format('MM.DD.YY')
          : t('pending')}
      </span>
    </div>
  );
};

const AssignmentStatusChips: React.FC<AssignmentStatusChipsProps> = ({
  learningUnitAssigmentStatus
}) => {
  const [overdueElement, setOverdueElement] = useState<HTMLElement | null>(
    null
  );
  const [currentlyAssignedElement, setCurrentlyAssignedElement] =
    useState<HTMLElement | null>(null);
  const [assignmentsElement, setAssignmentsElement] =
    useState<HTMLElement | null>(null);
  const { assignments } = learningUnitAssigmentStatus;
  const lastAssignment = assignments[0];
  const { t } = useTranslation(['assignments']);

  const completedAssignments = assignments.filter(
    assignment => assignment.isComplete
  );
  const assignmentsToShow =
    completedAssignments.length > 2
      ? completedAssignments.slice(0, 2)
      : completedAssignments;

  const handleOpenOverduePopover = (event: React.MouseEvent<HTMLElement>) => {
    setOverdueElement(event.currentTarget);
  };

  const handleCloseOverduePopover = () => {
    setOverdueElement(null);
  };

  const handleOpenCurrentlyAssignedPopover = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setCurrentlyAssignedElement(event.currentTarget);
  };

  const handleCloseCurrentlyAssignedPopover = () => {
    setCurrentlyAssignedElement(null);
  };

  const handleOpenAssignmentsPopover = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAssignmentsElement(event.currentTarget);
  };

  const handleCloseAssignmentsPopover = () => {
    setAssignmentsElement(null);
  };

  return (
    <Stack spacing={1} alignItems="flex-start">
      {!lastAssignment.isComplete && (
        <Stack direction="row" spacing={1}>
          {lastAssignment.isOverdue && (
            <>
              <div
                aria-owns={`overdue-${learningUnitAssigmentStatus.employeeId}-popover`}
                onMouseEnter={handleOpenOverduePopover}
                onMouseLeave={handleCloseOverduePopover}
              >
                <Chip label={t('overdue')} color="error" />
              </div>
              <CustomPopover
                id={`overdue-${learningUnitAssigmentStatus.employeeId}-popover`}
                anchorEl={overdueElement}
                onClose={handleCloseOverduePopover}
              >
                <Box padding="0.8rem" fontSize={13} sx={boxStyle}>
                  <span style={itemStyle}>
                    <span style={titleStyle}>{`${t('dueDate')}: `}</span>
                    {absUtcDateToLocalMoment(
                      lastAssignment.dueDateUtc
                        ? lastAssignment.dueDateUtc
                        : lastAssignment.workloadAssignmentDueDate!
                    ).format('MM.DD.YY')}
                  </span>
                </Box>
              </CustomPopover>
            </>
          )}
          <div
            aria-owns={`c-assigned-${learningUnitAssigmentStatus.employeeId}-popover`}
            onMouseEnter={handleOpenCurrentlyAssignedPopover}
            onMouseLeave={handleCloseCurrentlyAssignedPopover}
          >
            <Chip
              label={t('currentlyAssigned')}
              color="primary"
              sx={{
                '&.MuiChip-root': {
                  color: 'black'
                }
              }}
            />
          </div>
          <CustomPopover
            id={`c-assigned-${learningUnitAssigmentStatus.employeeId}-popover`}
            anchorEl={currentlyAssignedElement}
            onClose={handleCloseCurrentlyAssignedPopover}
          >
            <Box
              padding="0.8rem"
              display="flex"
              flexDirection="column"
              fontSize={13}
              sx={boxStyle}
            >
              <CurrentlyAssignmentContent assignment={lastAssignment} />
            </Box>
          </CustomPopover>
        </Stack>
      )}
      {!!completedAssignments.length && (
        <Stack direction="row" spacing={1}>
          <div
            aria-owns={`assignments-${learningUnitAssigmentStatus.employeeId}-popover`}
            onMouseEnter={handleOpenAssignmentsPopover}
            onMouseLeave={handleCloseAssignmentsPopover}
          >
            <Chip
              label={`${t('taken')} ${completedAssignments.length} ${completedAssignments.length === 1 ? t('time') : t('times')}`}
              sx={{
                '&.MuiChip-root': {
                  color: 'white',
                  backgroundColor: '#363636'
                }
              }}
            />
          </div>
          <CustomPopover
            id={`assignments-${learningUnitAssigmentStatus.employeeId}-popover`}
            anchorEl={assignmentsElement}
            onClose={handleCloseAssignmentsPopover}
          >
            <Box
              padding="0.8rem"
              display="flex"
              flexDirection="column"
              fontSize={13}
              sx={boxStyle}
            >
              <>
                {assignmentsToShow.map(
                  (assignment: AssignmentStatusDto, index) => (
                    <AssignmentsContent
                      key={`assignment-${assignment.assignmentLevel}-${assignment.assignmentType}-${assignment.assignmentDateUtc}-${assignment.completionDateUtc}-${assignment.isOverdue}`}
                      assignment={assignment}
                      index={index}
                    />
                  )
                )}
              </>
            </Box>
          </CustomPopover>
        </Stack>
      )}
    </Stack>
  );
};

export default AssignmentStatusChips;

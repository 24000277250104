import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, CircularProgress, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';

import { StyledCompetenceDetailContainer } from '../SkillComponentsHelper';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import { CompetencePost } from '../../../entities/Achievements';
import { CRUDAction, ErrorCode, HttpStatusEnum } from '../../../core/enums';
import SkillCertificateForm from './SkillCertificateForm';
import { ApplicationState } from '../../../store';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { actionCreators as achievementActions } from '../../../actions/achievements';
import { actionCreators as onsiteTrainingActions } from '../../../actions/onsiteTrainings';
import { UserClaims } from '../../../core/constants';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { uploadSkillAttachmentToBlob } from '../../../services/blobStorage-service';
import { AxiosError } from 'axios';
import { useAppDispatch } from '../../../store/hooks';

interface CreateSkillCertificateProps {
  action: CRUDAction;
  onClose: () => void;
}

const HeaderBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.grey[100]}`,
  padding: '1rem 2rem !important'
}));

const ContentBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '2rem 2rem !important',
  overflow: 'auto',
  height: '930px'
}));

const CreateSkillCertificate = ({
  action,
  onClose
}: CreateSkillCertificateProps) => {
  const { t } = useTranslation(['skills', 'common']);
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [competence, setCompetence] = useState<CompetencePost>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const activeEmployees = useSelector(
    (state: ApplicationState) => state.employees?.activeEmployees
  );
  const availableLearningUnits = useSelector(
    (state: ApplicationState) => state.achievements?.availableLearningUnits
  );
  const availableOnsiteTrainings = useSelector(
    (state: ApplicationState) => state.onsiteTrainings?.onsiteTrainingsAll
  );
  useEffect(() => {
    const companyId = user?.profile[UserClaims.CompanyId] as string;
    dispatch(employeeActions.requestActiveEmployees(companyId));
    dispatch(achievementActions.getAvailableLearningUnits());
    dispatch(onsiteTrainingActions.loadAllOnsiteTrainings());

    return () => {
      dispatch(achievementActions.setAvailableLearningUnits(null));
    };
  }, []);

  useEffect(() => {
    if (action === CRUDAction.Create && !competence) {
      setCompetence({
        id: '',
        name: '',
        description: '',
        duration: 0,
        durationUnit: 'None',
        notifyDaysBeforeExpiration: '',
        learningUnitId: null,
        governingBody: '',
        competenceType: 'Skill',
        learningUnitType: 'None',
        attachments: [],
        awarders: [],
        awarderTags: [],
        durationNeverExpires: false,
        filesToAttach: [],
        onsiteTrainingsLinked: [],
        onsiteTrainingToAssociate: []
      });

      return;
    }
  }, [action]);

  const handleSave = async () => {
    setIsSaving(true);

    const competenceToSave = { ...competence! };
    for (let i = 0; i < competence!.filesToAttach.length; i++) {
      const file = competence!.filesToAttach[i];
      try {
        const blobName = await uploadSkillAttachmentToBlob(file);
        competenceToSave.attachments.push({
          name: file.name,
          fileType: file.type,
          url: blobName
        });
      } catch (e) {
        enqueueSnackbar(t('uploadingAttachmentsError', { ns: 'skills' }), {
          variant: 'error',
          autoHideDuration: 3000
        });
        setIsSaving(false);
        return;
      }
    }

    try {
      await dispatch<Promise<void>>(
        achievementActions.createCompetence(competenceToSave)
      );
      dispatch(achievementActions.forceGetAllCompetencies(true));
      enqueueSnackbar(t('skillCreatedSuccessfully', { ns: 'skills' }), {
        variant: 'success',
        autoHideDuration: 3000
      });
      onClose();
    } catch (e) {
      const error = e as AxiosError;
      let errorKey = 'saveSkillCertitifcateError';
      if (error.response?.status === HttpStatusEnum.UnprocessableEntity) {
        errorKey =
          (error.response?.data as any).errorCode === ErrorCode.DuplicatedName
            ? 'skillNameAlreadyExists'
            : 'learningUnitIsAlreadyInUse';
      }
      enqueueSnackbar(t(errorKey, { ns: 'skills' }), {
        variant: 'error',
        autoHideDuration: 4000
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (
    !activeEmployees ||
    !availableLearningUnits ||
    !availableOnsiteTrainings
  ) {
    return (
      <StyledCompetenceDetailContainer
        sx={{
          marginTop: { xs: '1rem', md: 0 },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '930px'
        }}
      >
        <CircularProgress />
      </StyledCompetenceDetailContainer>
    );
  }

  return (
    <StyledCompetenceDetailContainer
      sx={{
        marginTop: { xs: '1rem', md: 0 },
        minWidth: '300px',
        height: '930px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <HeaderBox>
        <Typography variant="h5">{t('newSkill', { ns: 'skills' })}</Typography>
        <BasicButton
          width="115px"
          height="42px"
          color="secondary"
          onClick={onClose}
          disable={isSaving}
        >
          {t('cancel', { ns: 'common' })}
        </BasicButton>
      </HeaderBox>
      <ContentBox>
        <SkillCertificateForm
          competence={competence}
          setCompetence={setCompetence}
          onSaveForm={handleSave}
          isSaving={isSaving}
          isEdit={false}
        />
      </ContentBox>
    </StyledCompetenceDetailContainer>
  );
};

export default CreateSkillCertificate;

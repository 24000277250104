import { CSSProperties, useState, useEffect } from 'react';
import GoBackOption from '../../../components/GoBackOption/GoBackOption';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoBubble } from '../../../components/InfoBubble/InfoBubble';
import {
  Grid,
  Typography,
  Stack,
  createTheme,
  SxProps,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchBox from '../../../components/core/SearchBox/SearchBox';
import themeConfig from '../../../themes/theme';
import SortSkills from '../SortSkills';
import ListEmployeesAwardSkill from './ListEmployeesAwardSkill';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Employee } from '../../../entities/Employee';
import { actionCreators as employeeActions } from '../../../actions/employees';
import { useAppDispatch } from '../../../store/hooks';

const AwardSkill = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(['skills', 'common']);
  const [sortOption, setSortOption] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [awardAllEmployees, setAwardAllEmployees] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Employee[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const theme = createTheme(themeConfig);
  const handleSort = (items: string[]) => setSortOption(items[0]);
  const params = useParams<{
    skillId: string;
    skillName: string;
  }>();

  const handleGoBack = () => {
    navigate('/skills');
  };

  let searchTimeout: NodeJS.Timeout;

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, [sortOption, searchKey]);

  const loadData = async () => {
    await dispatch<Promise<void>>(
      employeeActions.requestEmployeesLearningProfileWithParams(
        searchKey,
        sortOption
      )
    );
    setIsLoading(false);
  };

  const employeesLearningProfile = useSelector(
    (state: ApplicationState) => state?.employees?.employeesLearningProfile
  );
  useEffect(() => {
    if (employeesLearningProfile?.length) {
      setDataSource(employeesLearningProfile);
    }
  }, [employeesLearningProfile]);

  const handleSearch = (value: any) => {
    if (!employeesLearningProfile) return;

    if (searchTimeout) clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      setSearchKey(value as string);
    }, 1000);
  };

  const StyledGridContainer = styled(Grid)(({ theme }) => ({
    width: '100%',
    height: 'fit-content',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main
  }));

  return (
    <>
      <Grid
        container
        direction="column"
        paddingLeft="1.5rem"
        paddingRight="1.5rem"
      >
        <Grid item paddingBottom="0.5rem" paddingTop="1rem">
          <GoBackOption
            textLink={t('backToSkills', { ns: 'skills' })}
            onGoBack={handleGoBack}
          />
        </Grid>
        <Grid item paddingBottom="1.5rem" paddingTop="0.5rem">
          <h4>
            {t('awardSkillTo', { ns: 'skills' })} {params.skillName}
          </h4>
        </Grid>
        <Grid item paddingBottom="1.5rem" paddingTop="0.5rem">
          <Typography style={typoStyle}>
            {t('selectEmployee', { ns: 'skills' })} *
            <InfoBubble text={t('skillInfoBubble', { ns: 'skills' })} />
          </Typography>
        </Grid>
      </Grid>

      <StyledGridContainer container>
        <Grid
          item
          direction="column"
          xs={12}
          sx={{
            padding: '1rem',
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
            height: 'fit-content'
          }}
        >
          <Stack direction="row" spacing={2} sx={{ marginTop: '16px' }}>
            <SearchBox
              id="search"
              value={searchKey}
              name="search"
              width="250px"
              height="45px"
              placeholderText={t('search', { ns: 'common' })}
              variant="dark"
              onChangeValue={handleSearch}
            />
            <SortSkills filterValue={sortOption} onChange={handleSort} />
            <FormControlLabel
              control={
                <Checkbox
                  id="awardAll"
                  name="awardAll"
                  checked={awardAllEmployees}
                  onChange={() => setAwardAllEmployees(!awardAllEmployees)}
                />
              }
              label={t('checkBoxAward', { ns: 'skills' })}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sx={listContainer}>
          <ListEmployeesAwardSkill
            employeesLearningProfile={dataSource}
            isLoading={isLoading}
            skillId={params.skillId!}
            checkAllEmployees={awardAllEmployees}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '20px 10px',
            marginBottom: '5px'
          }}
        ></Grid>
      </StyledGridContainer>
    </>
  );
};

const typoStyle: CSSProperties = {
  color: '#FFF',
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.15px'
};

const listContainer: SxProps = {
  paddingBottom: '30px'
};

export const buttonStyle: CSSProperties = {
  maxWidth: '250px',
  width: '150px',
  height: '42px'
};

export const fontButton: CSSProperties = {
  fontSize: '16px',
  color: '#0E0D0D',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px'
};

export default AwardSkill;

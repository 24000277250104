import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme, styled } from '@mui/material/styles';
import { SxProps, Breakpoint, Stack } from '@mui/system';
import { useTranslation } from 'react-i18next';

import BasicButton from '../../core/BasicButton/BasicButton';

export interface ModalComponentProps {
  showModal: boolean;
  title: string;
  savingInProgress: boolean;
  maxWidth: Breakpoint | false;
  saveButtonText: string;
  showSaveButton?: boolean;
  showCloseIcon?: boolean;
  showCancelButton?: boolean;
  showDeleteButton?: boolean;
  closeWhenClickBackdrop?: boolean;
  closeWhenPressingEscape?: boolean;
  deleteButtonCaption?: string;
  backdropColor?: string;
  onClose: () => void;
  onSave?: () => void;
  onDelete?: () => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogActions-root {
    justify-content: center;
    padding: 0 24px 20px;
  }
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 4px;
  }
`;

const dialogActionStyle: SxProps = {
  marginBottom: '1rem'
};

const ModalComponent: React.FC<
  React.PropsWithChildren<ModalComponentProps>
> = ({
  showModal,
  title,
  savingInProgress,
  maxWidth,
  saveButtonText,
  onClose,
  onSave,
  onDelete,
  children,
  showSaveButton = true,
  showCloseIcon = true,
  showCancelButton = false,
  showDeleteButton = false,
  closeWhenClickBackdrop = true,
  closeWhenPressingEscape = true,
  deleteButtonCaption,
  backdropColor = 'rgba(0, 0, 0, 0.5)'
}) => {
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };

  return (
    <CustomDialog
      open={showModal}
      onClose={closeWhenClickBackdrop ? onClose : undefined}
      maxWidth={maxWidth}
      fullWidth={true}
      disableEscapeKeyDown={!closeWhenPressingEscape}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: backdropColor
          }
        }
      }}
    >
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px'
        }}
      >
        {title}
        <Stack spacing={2}>
          {showDeleteButton && (
            <BasicButton
              color="delete"
              disable={savingInProgress}
              onClick={onDelete}
              style={{
                height: '45px',
                minWidth: '100px',
                backgroundColor: theme.palette.error.main,
                color: theme.palette.common.black
              }}
            >
              {deleteButtonCaption ?? t('delete')}
            </BasicButton>
          )}
          {showCloseIcon && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{ ...iconStyle }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      {(showSaveButton || showCancelButton) && (
        <DialogActions sx={dialogActionStyle}>
          {showSaveButton && (
            <BasicButton
              color="primary"
              onClick={onSave}
              loading={savingInProgress}
              style={{
                height: '45px',
                minWidth: '160px'
              }}
            >
              {saveButtonText}
            </BasicButton>
          )}
          {showCancelButton && (
            <BasicButton
              color="primary"
              onClick={onClose}
              disable={savingInProgress}
              style={{
                height: '45px',
                minWidth: '140px'
              }}
            >
              {t('cancel')}
            </BasicButton>
          )}
        </DialogActions>
      )}
    </CustomDialog>
  );
};

export default ModalComponent;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators } from '../../actions/employees';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  LearningPlan,
  LearningPlanAssignment
} from '../../entities/LearningPlan';
import {
  newLearningPlanAssignment,
  newLearningPlanGroupAssignment
} from '../../reducers/LearningPlansReducer';
import { EnrollmentTypes, UserRoles, UserClaims } from '../../core/constants';
import { actionCreators as learningPlanActions } from '../../actions/learningPlans';
import { TransactionStatusEnum } from '../../core/enums';
import AssignModal from '../../components/AssignModal/AssignModal';
import { useTranslation } from 'react-i18next';
import { useLogEvent } from '../../hooks/useLogEvent';
import InfoModal from '../../components/core/InfoModal/InfoModal';
import { LearningPlanGroupAssignment } from '../../entities/Assignment';
import useUserRole from '../../hooks/useUserRole';
import { enqueueSnackbar } from 'notistack';
import { useAppDispatch } from '../../store/hooks';

export interface AssignLearningPlanProps {
  show: boolean;
  learningPlan: LearningPlan;
  onClose: () => void;
}

const AssignLearningPlan: React.FC<AssignLearningPlanProps> = ({
  show,
  learningPlan,
  onClose
}) => {
  const [userHasRole] = useUserRole();
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const logEvent = useLogEvent();
  const employeeGroups = useSelector(
    (state: ApplicationState) => state?.employees?.employeeGroups
  );
  const employees = useSelector(
    (state: ApplicationState) => state?.employees?.employees
  );
  const learningPlanAssignmentStatus = useSelector(
    (state: ApplicationState) =>
      state?.learningPlans?.learningPlanAssignmentStatus
  );
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [learningPlanAssignment, setLearningPlanAssignment] =
    useState<LearningPlanAssignment>({ ...newLearningPlanAssignment });
  const [learningPlanGroupAssignment, setLearningPlanGroupAssignment] =
    useState<LearningPlanGroupAssignment>({
      ...newLearningPlanGroupAssignment
    });
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const transactionSatus = useSelector(
    (state: ApplicationState) => state.learningPlans?.transactionStatus
  );
  const groupAssignmentDefinitions = useSelector(
    (state: ApplicationState) =>
      state?.learningPlans?.groupAssignmentDefinitions
  );
  const { t } = useTranslation(['common', 'assignments']);
  const [showAssignmentConfirmation, setShowAssignmentConfirmation] =
    useState<boolean>(false);

  const companyId = user?.profile['BuildWitt.Company.CompanyId'] as string;

  useEffect(() => {
    const employeeId = user?.profile[UserClaims.EmployeeId] as string;

    if (userHasRole(UserRoles.CompanyAdmin)) {
      dispatch(actionCreators.requestEmployeeGroups(companyId));
      dispatch(actionCreators.requestEmployees(companyId));
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      !userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        actionCreators.requestEmployeesAndGroupsByGroupLeadId(
          employeeId,
          companyId
        )
      );
    } else if (
      !userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        actionCreators.requestEmployeesThatReportToSupervisor(
          employeeId,
          companyId
        )
      );
    } else if (
      userHasRole(UserRoles.GroupLead) &&
      userHasRole(UserRoles.Supervisor)
    ) {
      dispatch(
        actionCreators.requestEmployeesByGroupLeadAndBySupervisor(
          employeeId,
          companyId
        )
      );
    }

    return () => {
      learningPlanActions.SetStatusOfEmployeesLearningPlanAssigments(null);
      learningPlanActions.setLearningPlanAssignmentDefinitions(null);
    };
  }, []);

  useEffect(() => {
    if (learningPlan) {
      dispatch(
        learningPlanActions.requestStatusOfEmployeesLearningPlanAssigments(
          learningPlan.id
        )
      );
      dispatch(
        learningPlanActions.requestLearningPlanAssignmentDefinitions(
          learningPlan.id
        )
      );

      setLearningPlanAssignment({
        ...newLearningPlanAssignment,
        learningPlanId: learningPlan.id,
        learningPlanCompanyId: learningPlan.companyId,
        assignBy: ''
      });
      setLearningPlanGroupAssignment({
        ...newLearningPlanGroupAssignment,
        learningPlanId: learningPlan.id,
        assignBy: ''
      });
    }
  }, [learningPlan]);

  useEffect(() => {
    if (saveInProgress) {
      setSaveInProgress(false);
      if (transactionSatus === TransactionStatusEnum.Successfull) {
        setShowAssignmentConfirmation(true);
      } else if (transactionSatus === TransactionStatusEnum.Failed) {
        enqueueSnackbar(t('anExceptionOccurred', { ns: 'common' }), {
          variant: 'error'
        });
      }
    }

    if (transactionSatus !== TransactionStatusEnum.None)
      dispatch(learningPlanActions.resetLearningPlanTransactionStatus());

    return () => {
      dispatch(learningPlanActions.resetLearningPlanTransactionStatus());
    };
  }, [transactionSatus]);

  const handleAssign = () => {
    let message = '';

    if (!learningPlanAssignment.employeeIds.length)
      message = t('selectEmployee', { ns: 'common' });
    else if (
      !learningPlanAssignment.assignBy ||
      (learningPlanAssignment.assignBy === EnrollmentTypes.DueDate &&
        !learningPlanAssignment.dueDate)
    )
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });

    if (message.length) {
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
      return;
    }

    dispatch(
      learningPlanActions.assignLearningPlanToEmployees(learningPlanAssignment)
    );
    learningPlanAssignment.employeeIds.forEach(employeeId => {
      logEvent.logAssignLearningPlanEvent(
        learningPlanAssignment.learningPlanId,
        learningPlanAssignment.learningPlanCompanyId,
        employeeId,
        learningPlan.name
      );
    });
    setSaveInProgress(true);
    setErrorMessage('');
  };

  const handleSaveGroupAssignment = async () => {
    let message = '';

    if (!learningPlanGroupAssignment.groups.length) {
      message = t('mustSelectAtLeastOneGroup', { ns: 'assignments' });
    } else if (
      !learningPlanGroupAssignment.assignBy ||
      (learningPlanGroupAssignment.assignBy === EnrollmentTypes.DueDate &&
        !learningPlanGroupAssignment.dueDate)
    ) {
      message = t('requiredFieldsMustBeFilled', { ns: 'common' });
    }

    if (message.length) {
      enqueueSnackbar(message, { variant: 'warning' });
      setErrorMessage(message);
      return;
    }
    setSaveInProgress(true);
    try {
      await dispatch(
        learningPlanActions.saveLearningPlanGroupAssignments(
          learningPlanGroupAssignment
        )
      );
      setSaveInProgress(false);
      setShowAssignmentConfirmation(true);
      setErrorMessage('');
      learningPlanGroupAssignment.groups.forEach(group => {
        logEvent.logAssignLearningPlanToGroupEvent(
          learningPlanGroupAssignment.learningPlanId,
          group.groupId
        );
      });
    } catch (e) {
      message = t('saveGroupAssignmentsErrorMsg', { ns: 'assignments' });
      enqueueSnackbar(message, { variant: 'error' });
      setErrorMessage(message);
      setSaveInProgress(false);
    }
  };

  const onCloseConfirmationModal = () => {
    setShowAssignmentConfirmation(false);
  };

  const setLearningPlanAssignmentProxy = (
    value: Partial<LearningPlanAssignment>
  ) => {
    setLearningPlanAssignment(previous => ({
      ...previous,
      ...value,
      learningPlanId: value.learningPlanId || previous.learningPlanId,
      learningPlanCompanyId:
        value.learningPlanCompanyId || previous.learningPlanCompanyId
    }));
  };

  return (
    <>
      <AssignModal
        showModal={show}
        assignToEntity={learningPlan}
        errorMessage={errorMessage}
        savingInProgress={saveInProgress}
        onAssign={handleAssign}
        onSaveGroupAssignment={handleSaveGroupAssignment}
        onClose={onClose}
        employees={employees ?? []}
        employeeGroups={employeeGroups ?? []}
        assignment={learningPlanAssignment}
        groupAssignment={learningPlanGroupAssignment}
        setAssignment={setLearningPlanAssignmentProxy}
        setGroupAssignment={setLearningPlanGroupAssignment}
        learningUnitAssigmentStatus={learningPlanAssignmentStatus}
        groupAssignmentDefinitions={groupAssignmentDefinitions}
      />
      <InfoModal
        showModal={showAssignmentConfirmation}
        content={t('assignmentSuccessful', { ns: 'common' })}
        onClose={onCloseConfirmationModal}
        onSave={onClose}
      />
    </>
  );
};

export default AssignLearningPlan;

import React, {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import BasicButton from '../../../components/core/BasicButton/BasicButton';
import SignatureCanva from '../../../components/core/SignatureCanva/SignatureCanva';

interface SignatureOnsiteTrainingModalProps {
  showModal: boolean;
  onCloseModal: () => void;
  nextButtonText: string;
  signatureWidth: number;
  signatureHeight: number;
  onAfterSavingSignature?: (signature: string) => void;
}

const CustomDialog = styled(Dialog)`
  & .MuiPaper-root {
    height: auto;
    border: 1px solid #999;
    border-radius: 10px;
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: space-between;
    padding-right: 4rem;
  }
  & .MuiDialogContent-root {
    padding: 0 0;
  }
`;

const dialogActionStyle: SxProps = {
  marginBottom: '1rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
};

const SignatureOnsiteTrainingModal = ({
  showModal,
  onCloseModal,
  nextButtonText,
  signatureWidth,
  signatureHeight,
  onAfterSavingSignature
}: PropsWithChildren<SignatureOnsiteTrainingModalProps>) => {
  const theme = useTheme();
  const { t } = useTranslation(['onsiteTraining']);
  const signComponentRef = useRef<any>();
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const [isSavingSignature, setIsSavingSignature] = useState<boolean>(false);
  const iconStyle = {
    position: 'absolute' as const,
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  };
  const handleClickErase = () => {
    signComponentRef.current.handleClear();
  };
  useEffect(() => {
    return () => {
      setDisableSaveButton(true);
    };
  }, []);
  const handleSaveSignature = async () => {
    setIsSavingSignature(true);
    const signatureUrl = await signComponentRef.current.handleGenerate();
    setIsSavingSignature(false);
    onAfterSavingSignature && onAfterSavingSignature(signatureUrl);
    onCloseModal();
  };
  return (
    <CustomDialog
      open={showModal}
      onClose={onCloseModal}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography style={modalTitle}>
          {t('signatureFor', { ns: 'onsiteTraining' })}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onCloseModal}
          sx={{ ...iconStyle }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent style={childreContainer}>
        <SignatureCanva
          width={signatureWidth}
          height={signatureHeight}
          handleSaveButton={setDisableSaveButton}
          ref={signComponentRef}
        />
      </DialogContent>
      <DialogActions sx={dialogActionStyle}>
        <Grid display={'flex'} gap={5}>
          <BasicButton
            color="secondary"
            width="auto"
            height="45px"
            onClick={handleClickErase}
            loading={isSavingSignature}
          >
            {t('eraseSignature', { ns: 'onsiteTraining' })}
          </BasicButton>
          <BasicButton
            color="primary"
            width="auto"
            height="45px"
            onClick={handleSaveSignature}
            loading={isSavingSignature}
            disable={disableSaveButton}
          >
            {nextButtonText}
          </BasicButton>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};

export const modalTitle: CSSProperties = {
  color: '#FFF',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  letterSpacing: '0.2px'
};
export const childreContainer: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center'
};

export default SignatureOnsiteTrainingModal;

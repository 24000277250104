import { Action, Reducer } from 'redux';
import { KnownAction } from '../actions/subscriptions';
import { SubscriptionWithSubscribers } from '../entities/Subscription';

import {
  SUBSCRIPTION_IS_LOADING,
  SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS
} from '../actions/actionTypes';

export interface SubscriptionsState {
  isLoading: boolean;
  subscriptionsSubscribers: SubscriptionWithSubscribers[];
}

const unloadedState: SubscriptionsState = {
  isLoading: false,
  subscriptionsSubscribers: []
};

export const SubscriptionsReducer: Reducer<SubscriptionsState> = (
  state: SubscriptionsState | undefined,
  incomingAction: Action
): SubscriptionsState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case SUBSCRIPTION_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SUBSCRIPTION_SET_SUBSCRIPTIONS_SUBSCRIBERS:
      return {
        ...state,
        subscriptionsSubscribers: action.subscriptionsSubscribers
      };
    default:
      return state;
  }
};
